import { LayoutTypes, ScheduleEntityResponse } from "src/graphql/generated";
import EmptyText from "src/components/EmptyText/EmptyText";
import { useTranslation } from "react-i18next";
import { TimetableWrapper } from "./styles";
import DaysOfWeekRowComponent from "../DaysOfWeekRowComponent/DaysOfWeekRowComponent";
import CustomTimeRowComponent from "../CustomTimeRowComponent/CustomTimeRowComponent";

interface ITimeTableComponent {
  timeTableItems: { id: number; type: LayoutTypes }[];
  cabinets: { item: string; value: string }[];
  schedules: ScheduleEntityResponse[];
  doctorId: string;
  handleRemoveTableItem: (id: number | string) => () => void;
}

function TimeTableComponent({
  timeTableItems,
  cabinets,
  schedules,
  doctorId,
  handleRemoveTableItem,
}: ITimeTableComponent) {
  const { t } = useTranslation();
  return (
    <TimetableWrapper>
      <>
        {!!timeTableItems.length &&
          timeTableItems.map((item) => {
            if (item?.type === LayoutTypes.DayOfWeek) {
              return (
                <DaysOfWeekRowComponent
                  key={item.id}
                  removeItem={handleRemoveTableItem(item.id)}
                  newItem={item}
                  cabinets={cabinets}
                  doctorId={doctorId}
                />
              );
            }
            if (item?.type === LayoutTypes.Custom) {
              return (
                <CustomTimeRowComponent
                  key={item.id}
                  removeItem={handleRemoveTableItem(item.id)}
                  newItem={item}
                  cabinets={cabinets}
                  doctorId={doctorId}
                />
              );
            }
            return null;
          })}
        {schedules.length ? (
          schedules.map((scheduleItem) => {
            if (scheduleItem?.layoutType === LayoutTypes.DayOfWeek) {
              return (
                <DaysOfWeekRowComponent
                  key={scheduleItem.id}
                  scheduleItem={scheduleItem}
                  removeItem={handleRemoveTableItem(scheduleItem.id)}
                  cabinets={cabinets}
                  doctorId={doctorId}
                />
              );
            }
            if (scheduleItem?.layoutType === LayoutTypes.Custom) {
              return (
                <CustomTimeRowComponent
                  key={scheduleItem.id}
                  removeItem={handleRemoveTableItem(scheduleItem.id)}
                  scheduleItem={scheduleItem}
                  cabinets={cabinets}
                  doctorId={doctorId}
                />
              );
            }
            return null;
          })
        ) : (
          <EmptyText text={t("app.schedule_empty_text")} />
        )}
      </>
    </TimetableWrapper>
  );
}

export default TimeTableComponent;
