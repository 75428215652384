import { useDispatch, useSelector } from "react-redux";
import { useCallback, useMemo } from "react";
import { TemplateEntityResponse } from "src/graphql/generated";
import {
  resetSelectedTemplate,
  setSelectedTemplate,
  setClinicTemplate,
} from "./templatesSlice";
import { AppDispatch, RootState } from "../index";

export const getTemplates = (state: RootState) => state.templates;

export function useTemplatesAction() {
  const dispatch = useDispatch<AppDispatch>();

  const onResetSelectedTemplate = useCallback(() => {
    dispatch(resetSelectedTemplate());
  }, [dispatch]);

  const onSetSelectedTemplate = useCallback(
    (template: TemplateEntityResponse) => {
      dispatch(setSelectedTemplate(template));
    },
    [dispatch]
  );

  const onSetClinicTemplate = useCallback(
    (clinicsTemplates: TemplateEntityResponse["clinicsTemplates"]) => {
      dispatch(setClinicTemplate(clinicsTemplates));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      onResetSelectedTemplate,
      onSetSelectedTemplate,
      onSetClinicTemplate,
    }),
    [onResetSelectedTemplate, onSetSelectedTemplate, onSetClinicTemplate]
  );
}

export function useTemplatesState() {
  return useSelector(getTemplates);
}
