import { allClinicsItemId } from "src/constants/constants";
import { useAuthContext } from "src/context/AuthContext";
import {
  useCountScheduleAppointmentsByStatusesByClientQuery,
  useCountScheduleAppointmentsByStatusesByDoctorQuery,
} from "src/graphql/generated";
import { useClinicsState } from "src/store/clinics/hooks";
import { IAppointmentsFilters } from "src/types";

interface IGetAppointmentsByRole {
  filters: IAppointmentsFilters;
}

export function useCountStatusesByRole({ filters }: IGetAppointmentsByRole) {
  const { isDoctor, currentUserData } = useAuthContext();
  const { currentClinicId: clinicId } = useClinicsState();

  const {
    data: countStatusesByClientData,
    loading: countStatusesByClientLoading,
  } = useCountScheduleAppointmentsByStatusesByClientQuery({
    variables: {
      schema: {
        ...(filters.dateFrom && { dateFrom: filters.dateFrom }),
        ...(filters.dateTo && { dateTo: filters.dateTo }),
        ...(filters.doctorId && { doctorId: filters.doctorId }),
        ...(clinicId !== allClinicsItemId && { clinicId }),
        ...(filters.minuteFrom && { minuteFrom: filters.minuteFrom }),
        ...(filters.minuteTo && { minuteTo: filters.minuteTo }),
      },
    },
    skip: isDoctor || !clinicId || !currentUserData,
    fetchPolicy: "no-cache",
  });

  const {
    data: countStatusesByDoctorData,
    loading: countStatusesByDoctorLoading,
  } = useCountScheduleAppointmentsByStatusesByDoctorQuery({
    variables: {
      schema: {
        ...(filters.dateFrom && { dateFrom: filters.dateFrom }),
        ...(filters.dateTo && { dateTo: filters.dateTo }),
        ...(clinicId !== allClinicsItemId && { clinicId }),
        ...(filters.minuteFrom && { minuteFrom: filters.minuteFrom }),
        ...(filters.minuteTo && { minuteTo: filters.minuteTo }),
      },
    },
    skip: !isDoctor || !currentUserData || !clinicId,
    fetchPolicy: "no-cache",
  });

  const countStatusesByRole = isDoctor
    ? countStatusesByDoctorData?.countScheduleAppointmentsByStatusesByDoctor
    : countStatusesByClientData?.countScheduleAppointmentsByStatusesByClient;

  const countStatusesByRoleLoading =
    countStatusesByDoctorLoading || countStatusesByClientLoading;

  return {
    countStatusesByRole,
    countStatusesByRoleLoading,
  };
}
