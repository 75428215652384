import styled from "styled-components";
import DropdownInputRHF from "src/UI/DropdownInput/DropdownInputRHF";
import { TemplatesComponentWrapper } from "../styled";

export const StyledTemplatesComponentWrapper = styled(
  TemplatesComponentWrapper
)`
  padding: 10px 20px 20px 20px;
`;

export const StyledDropdownInputRHF = styled(DropdownInputRHF)`
  width: 100%;
`;
