import { formatISO } from "date-fns";

import { LanguageIsoCodes, PatientGenders } from "src/graphql/generated";
import {
  IFieldStep3,
  PatientBookingInfo,
} from "src/pages/PatientBookingPages/StepsPage/types";
import {
  SelectedBookingClinic,
  SelectedBookingDoctor,
  SelectedBookingService,
  SelectedWaitListSlot,
} from "src/store/booking/types";

export const transformReqSchemaCreateWaitingOrder = (
  currentDateWaitListSlot: SelectedWaitListSlot,
  currentClinic: SelectedBookingClinic,
  currentDoctor: SelectedBookingDoctor,
  currentService: SelectedBookingService,
  formattedFields: IFieldStep3[] | undefined,
  patientInfoSelected: PatientBookingInfo | undefined,
  formAnswers:
    | {
        fieldId: string;
        order: number;
        value: string;
      }[]
    | undefined,
  languageISOCode: LanguageIsoCodes
) => {
  return {
    dateFrom: currentDateWaitListSlot?.slot.dateFrom as string,
    dateTo: currentDateWaitListSlot?.slot.dateTo as string,
    minuteFrom: currentDateWaitListSlot?.slot.minuteFrom as number,
    minuteTo: currentDateWaitListSlot?.slot.minuteTo as number,
    clinicId: currentClinic?.clinic.id as string,
    doctorId: currentDoctor?.doctor.doctorId as string,
    serviceId: currentService?.service.id as string,
    ...(formattedFields?.length && {
      formAnswer: {
        answers: formAnswers || [],
        formId: formattedFields[0]?.formId as string,
      },
    }),
    patient: {
      ...patientInfoSelected,
      dateOfBirth: formatISO(patientInfoSelected?.dateOfBirth as Date),
      address: patientInfoSelected?.address?.description as string,
      phoneNumber: `+${patientInfoSelected?.phoneNumber}`,
      email: patientInfoSelected?.email as string,
      firstName: patientInfoSelected?.firstName as string,
      lastName: patientInfoSelected?.lastName as string,
      gender: patientInfoSelected?.gender as PatientGenders,
      passportNumber: patientInfoSelected?.passportNumber as string,
      postCode: patientInfoSelected?.postCode as string,
    },
    languageISOCode,
  };
};
