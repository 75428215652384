import styled from "styled-components";

export const Wrapper = styled.div<{ opacity: number }>`
  position: relative;
  padding: 20px;
  gap: 15px;
  display: flex;
  align-items: center;
  align-content: center;
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  width: 100%;
  flex-direction: column;
  opacity: ${({ opacity }) => opacity};
`;

export const DragButton = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
`;

export const RowInput = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 20px;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  gap: 24px;
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 20px;
  }
`;

export const ButtonIcon = styled.button`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;

    path {
      stroke: ${({ theme }) => theme.colors.gray};
    }
  }
`;

export const Toggles = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 30px;
`;

export const ToggleBlock = styled.div`
  padding: 10px;
  gap: 16px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
`;

export const Label = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.black};
  svg {
    width: 16px;
    height: 16px;
    path {
      stroke: ${({ theme }) => theme.colors.gray};
    }
  }
`;

export const CheckBox = styled.div`
  display: flex;
  width: 20px;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 20px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.03);
  border-radius: 3px;
  cursor: auto;
`;

export const NewVariantButton = styled.div`
  color: ${({ theme }) => theme.colors.gray};
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  cursor: pointer;
  padding: 15px 0px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  & span {
    color: ${({ theme }) => theme.colors.green};
    text-decoration-line: underline;
  }
`;

export const ErrorMessage = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: right;
  color: ${({ theme }) => theme.colors.red};
  position: absolute;
  top: 0;
  right: 0;
`;
