import { CombinedUserTypeWithAdditionalKeys } from "src/context/reducers/authReducer";
import { AggregatedDoctorEntityResponse } from "src/graphql/generated";
import { DoctorName, Row } from "./styles";

function EventDayDoctorComponent({
  doctor,
}: {
  doctor: CombinedUserTypeWithAdditionalKeys | AggregatedDoctorEntityResponse;
}) {
  return (
    <Row>
      <DoctorName>{`${doctor?.firstName} ${doctor?.lastName}`}</DoctorName>
    </Row>
  );
}

export default EventDayDoctorComponent;
