import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { ITemplateCreateEdit } from "src/types/templates";
import { LanguageIsoCodes } from "src/graphql/generated";
import { InputRHF } from "src/UI/Input";
import { EButtonVariants } from "src/UI/Button";
import TemplatesComponentRichEditor from "src/components/TemplatesComponents/TemplatesComponentRichEditor/TemplatesComponentRichEditor";
import { useTemplateContext } from "src/context/TemplatesContext";
import EmptyText from "src/components/EmptyText/EmptyText";
import { useNavigate } from "react-router-dom";
import { PATHS } from "src/constants/constants";
import {
  ButtonFooter,
  FooterButtons,
  Header,
  HeadingStyled,
  TabsHeader,
  TabsStyled,
  Wrapper,
  TabsHeaderWrapper,
  TabsContainer,
} from "./styles";

function TemplatesEditView() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    watch,
    register,
    formState: { errors },
  } = useFormContext<ITemplateCreateEdit>();
  const { onSelectLang, selectedTemplate, selectedLang } = useTemplateContext();

  const titleValue = watch("title");

  const handleTabChange = (id: number) => {
    onSelectLang(id === 0 ? LanguageIsoCodes.En : LanguageIsoCodes.Pl);
  };

  const handleCancel = () => {
    navigate(`${PATHS.cp}${PATHS.templates}`);
  };

  if (!selectedTemplate?.selectedEvent) return <Wrapper $isNoBg />;

  if (!selectedTemplate?.selectedAction) {
    return (
      <Wrapper>
        <EmptyText text={t("templates.action.edit.no_action_selected")} />
      </Wrapper>
    );
  }

  const activeTabValue = selectedLang === LanguageIsoCodes.En ? 0 : 1;

  return (
    <Wrapper>
      <Header>
        <HeadingStyled as="h3">
          {t("templates.action.edit.title")}
        </HeadingStyled>
      </Header>

      <TabsContainer>
        <InputRHF
          label={t("templates.action.edit.labels.title") as string}
          val={titleValue}
          inputType="text"
          errorMessage={errors?.title}
          register={register("title")}
        />

        <TabsStyled
          activeTabValue={activeTabValue}
          PaddingWrapperEl={TabsHeaderWrapper}
          onTabChange={handleTabChange}
          headers={[
            <TabsHeader>{LanguageIsoCodes.En.toLocaleLowerCase()}</TabsHeader>,
            <TabsHeader>{LanguageIsoCodes.Pl.toLocaleLowerCase()}</TabsHeader>,
          ]}
          contents={[
            <TemplatesComponentRichEditor />,
            <TemplatesComponentRichEditor />,
          ]}
        />
      </TabsContainer>

      <FooterButtons>
        <ButtonFooter
          variant={EButtonVariants.Text}
          type="button"
          onClick={handleCancel}
        >
          {t("templates.action.edit.buttons.cancel")}
        </ButtonFooter>
        <ButtonFooter variant={EButtonVariants.Default}>
          {t("templates.action.edit.buttons.save")}
        </ButtonFooter>
      </FooterButtons>
    </Wrapper>
  );
}

export default TemplatesEditView;
