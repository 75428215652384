import { parsePhoneNumber } from "awesome-phonenumber";
import * as yup from "yup";

export const createDoctorInvitionSchema = yup.object().shape({
  firstName: yup
    .string()
    .required("auth.reg.name_error")
    .min(1, "auth.reg.name_error")
    .max(100, "validation.field_max_100"),
  lastName: yup
    .string()
    .required("auth.reg.surname_error")
    .min(1, "auth.reg.surname_error")
    .max(100, "validation.field_max_100"),
  phoneNumber: yup
    .string()
    .required("validation.field_required")
    .test("is-valid", "validation.invalid_phone_number_required", (val) => {
      return parsePhoneNumber(`+${val}` as string).possible;
    }),
  email: yup
    .string()
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "auth.login.email_error"
    )
    .required("validation.field_required"),
  description: yup
    .string()
    .max(300, "validation.field_max_300")
    .test("check-min-length-5", "validation.field_min_5", (value) => {
      if (value && value.length < 5) return false;
      return true;
    })
    .nullable(),
  doctorTypeId: yup.string().required("validation.field_required"),
  clinicId: yup.string().required("validation.field_required"),
  passportNumber: yup
    .string()
    .max(11, "validation.field_max_11")
    .test("check-min-length-9", "validation.field_min_9", (value) => {
      if (value && value.length < 9) return false;
      return true;
    })
    .nullable(),
  medicalCertificateNumber: yup
    .string()
    .max(100, "validation.field_max_100")
    .test("check-min-length-2", "validation.field_min_2", (value) => {
      if (value && value.length < 2) return false;
      return true;
    })
    .required("validation.field_required"),
  promoCode: yup.string().max(10, "validation.field_max_10").notRequired(),
});
