export * from "./translate.enum";

export enum EActivityComponentSize {
  small,
  mid,
  large,
}

export enum EUserComponentSize {
  small,
  mid,
  large,
}
