import React from "react";

import { useTranslation } from "react-i18next";
import {
  DateInputRHF,
  InputAutosizeRHF,
  InputRHF,
  PhoneInputSmallRHF,
} from "src/UI/Input";

import { FieldError, useFormContext } from "react-hook-form";
import DropdownInputRHF from "src/UI/DropdownInput/DropdownInputRHF";
import { PatientGenders } from "src/graphql/generated";
import { Form, InputsRow, Wrapper } from "./styled";

function MainDataComponent() {
  const { t } = useTranslation();

  const {
    watch,
    register,
    formState: { errors },
  } = useFormContext();

  const data = watch();

  return (
    <Wrapper>
      <Form>
        <InputsRow>
          <InputRHF
            val={data.first_name}
            label={t("patients.add.labels.first_name") as string}
            inputType="text"
            errorMessage={errors.first_name as FieldError}
            register={register("first_name")}
          />
          <InputRHF
            val={data.last_name}
            label={t("patients.add.labels.last_name") as string}
            inputType="text"
            errorMessage={errors.last_name as FieldError}
            register={register("last_name")}
          />
        </InputsRow>
        <InputsRow>
          <InputRHF
            val={data.email}
            label={t("patients.add.labels.email") as string}
            inputType="email"
            errorMessage={errors.email as FieldError}
            register={register("email")}
          />
          <PhoneInputSmallRHF
            label={t("auth.reg.phone") || ""}
            value={data.phone}
            name="phone"
          />
        </InputsRow>
        <InputsRow>
          <DateInputRHF
            value={data.dateOfBirth}
            name="dateOfBirth"
            placeholder=""
            label={t("patients.add.labels.birth") as string}
            errorMessage={errors.dateOfBirth as FieldError}
            isMonthAndYear
          />
          <DropdownInputRHF
            name="gender"
            value={data.gender}
            placeholder=""
            options={[
              {
                item: t("common.gender_male"),
                value: PatientGenders.Male,
              },
              {
                item: t("common.gender_female"),
                value: PatientGenders.Female,
              },
            ]}
            label={t("patients.add.labels.gender") as string}
            adaptedForMobile={false}
            errorMessage={errors.gender as FieldError}
          />
        </InputsRow>
        <InputsRow>
          <InputRHF
            val={data.passportNumber}
            label={t("add_doctor.label.passport") as string}
            inputType="text"
            errorMessage={errors.passportNumber as FieldError}
            register={register("passportNumber")}
          />
        </InputsRow>
        <InputsRow>
          <InputAutosizeRHF
            val={data.comment}
            label={t("patients.add.labels.description") as string}
            inputType="text"
            errorMessage={errors.comment as FieldError}
            register={register("comment")}
          />
        </InputsRow>
      </Form>
    </Wrapper>
  );
}

export default MainDataComponent;
