import { addDays, format, isSameDay } from "date-fns";
import { useMemo } from "react";
import {
  ViewProps,
  DateLocalizer,
  TitleOptions,
  NavigateAction,
  Navigate,
} from "react-big-calendar";
import { DayEventsList } from "src/components/CalendarComponents";
import { useTranslation } from "react-i18next";
import { getFNSLocaleByLang } from "src/utils";
import { DayWrapperStyled, MonthDateHeaderWrapper, Wrapper } from "./styles";

function MonthView({ date, events, onNavigate, onView }: ViewProps) {
  const { i18n } = useTranslation();
  const monthDays = useMemo(() => {
    const startMonth = new Date(date);

    return Array.from({ length: 35 }, (_, i) => {
      const dayDate = addDays(startMonth, i);
      const dayEvents = events?.filter((event) =>
        isSameDay(new Date(event?.start as Date), dayDate)
      );

      return { dayDate, events: dayEvents || [] };
    });
  }, [date, events]);

  const handleShowMore = (dayDate: Date) => {
    onNavigate("DATE", dayDate);
    if (onView) onView("day");
  };

  return (
    <Wrapper>
      {monthDays?.map((day) => {
        return (
          <DayWrapperStyled key={day.dayDate.getTime()}>
            <MonthDateHeaderWrapper>
              {format(day.dayDate, "EEEEEE, dd MMM", {
                locale: getFNSLocaleByLang(i18n.resolvedLanguage),
              })}
            </MonthDateHeaderWrapper>

            <DayEventsList
              date={day.dayDate}
              events={day?.events}
              maxLength={5}
              onShowMore={handleShowMore}
            />
          </DayWrapperStyled>
        );
      })}
    </Wrapper>
  );
}

MonthView.navigate = (
  date: Date,
  action: NavigateAction,
  { localizer }: { localizer: DateLocalizer }
) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -35, "day");

    case Navigate.NEXT:
      return localizer.add(date, 35, "day");

    default:
      return date;
  }
};

MonthView.title = (
  date: Date,
  options: TitleOptions & { localizer: DateLocalizer }
) => {
  const { localizer } = options;
  const endDate = addDays(date, 34);
  return `${localizer.format(date, "MMMM dd")} - ${localizer.format(
    endDate,
    "MMMM dd"
  )}`;
};

MonthView.range = (targetStartDate: Date) => {
  const endDate = addDays(targetStartDate, 34);
  return [targetStartDate, endDate];
};

export default MonthView;
