import styled from "styled-components";
import { Button } from "../../../UI/Button";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

export const ButtonHeader = styled(Button)`
  padding: 8px 10px;
  gap: 7px;
  font-size: 16px;
  line-height: 100%;
  border-radius: 5px;
  width: fit-content;
`;
