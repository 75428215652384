import { gql } from "@apollo/client";

export const GET_FIND_SCHEDULES_BY_CLIENT = gql`
  query FindManySchedulesByClient($schema: FindManySchedulesByClientSchema!) {
    findManySchedulesByClient(schema: $schema) {
      schedules {
        id
        doctorId
        clinicId
        roomId
        eventType
        layoutType
        daysOfWeek
        dateFrom
        dateTo
        minuteFrom
        minuteTo
        createdAt
      }
    }
  }
`;
