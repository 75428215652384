import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";

import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { FieldError, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LocationGeo } from "src/pages/DashboardPages/MapPages/types";

import { ErrorMessage, GeocoderStyled, InputBlock, Label } from "./styled";

interface GeocoderInputProps {
  className?: string;
  label?: string;
  name: string;
  errorMessage?: FieldError;
  onSelect?: (val: { result: LocationGeo }) => void;
}

export interface GeocoderContext {
  id: string;
  mapbox_id: string;
  text?: string;
}

export interface LocationValue {
  place_type: string[];
  context?: GeocoderContext[];
  city?: string;
  index?: string;
}

function InputGeocoderRHF({
  className,
  label,
  name,
  errorMessage,
  onSelect,
}: GeocoderInputProps) {
  const { t, i18n } = useTranslation();

  const inputId = "inputGC";
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isShowBtn, setIsShowBtn] = useState<boolean>(false);
  const [geoInputEl, setGeoInputEl] = useState<HTMLInputElement | null>(null);
  const geocoderContainerRef = useRef<HTMLDivElement | null>(null);
  const geocoderRef = useRef<MapboxGeocoder | null>(null);
  const wrapper = useRef<HTMLDivElement | null>(null);
  const { setValue, setError, trigger, watch } = useFormContext();

  const val = watch(name);

  const getInputFromGeocoderContainer = () => {
    return geocoderContainerRef.current?.querySelector(
      ".mapboxgl-ctrl-geocoder input"
    ) as HTMLInputElement | null;
  };

  const handleFocus = () => {
    const currentValue = watch(name);
    setIsShowBtn(currentValue ? true : false);
    setIsFocused(true);
  };

  const handleBlur = () => {
    const inputGeoElement = getInputFromGeocoderContainer();
    if (inputGeoElement && !inputGeoElement?.value) {
      setIsFocused(false);
    }
    trigger(name);
  };

  const handleChange = () => {
    const inputGeoElement = getInputFromGeocoderContainer();
    if (inputGeoElement && !inputGeoElement?.value) {
      setValue(name, null);
      trigger(name);
    }
    setError(name, {
      type: "not correct address",
      message: t("app.geocoder.not_correct_address_error") as string,
    });
  };

  const initializeGeocoder = (language: string) => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX as string;
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl,
      language,
      types: "poi, address",
    });

    geocoderRef.current = geocoder;

    geocoder.on("result", (event) => {
      if (onSelect) onSelect(event);

      setIsShowBtn(true);

      const city = event.result?.context?.find((it: GeocoderContext) =>
        it.id.includes("place")
      )?.text;

      const index = event.result?.context?.find((it: GeocoderContext) =>
        it.id.includes("postcode")
      )?.text;

      setValue(name, { ...event.result, city, index });
      trigger(name);
    });

    if (geocoderContainerRef.current) {
      geocoder.addTo(geocoderContainerRef.current);
    }
  };

  useEffect(() => {
    if (i18n.language) {
      if (geocoderRef.current) {
        geocoderRef.current.onRemove();
      }

      initializeGeocoder(i18n.language);
    }
    const inputGeoElement = getInputFromGeocoderContainer();

    if (inputGeoElement) {
      setGeoInputEl(inputGeoElement as HTMLInputElement);
    }

    if (geocoderRef.current) {
      geocoderRef.current.on("clear", () => {
        setIsShowBtn(false);
        setValue(name, null);
        trigger(name);
      });
    }

    if (inputGeoElement) {
      inputGeoElement.addEventListener("focus", handleFocus);
      inputGeoElement.addEventListener("blur", handleBlur);
      inputGeoElement.addEventListener("input", handleChange);
    }
  }, [i18n.language]);

  useEffect(() => {
    if (!geocoderRef?.current) {
      initializeGeocoder(i18n.language);
    }

    const inputGeoElement = getInputFromGeocoderContainer();

    if (inputGeoElement) {
      setGeoInputEl(inputGeoElement as HTMLInputElement);
    }

    if (geocoderRef.current) {
      geocoderRef.current.on("clear", () => {
        setIsShowBtn(false);
        setValue(name, null);
        trigger(name);
      });
    }

    if (inputGeoElement) {
      inputGeoElement.addEventListener("focus", handleFocus);
      inputGeoElement.addEventListener("blur", handleBlur);
      inputGeoElement.addEventListener("input", handleChange);
    }

    return () => {
      if (inputGeoElement) {
        inputGeoElement.removeEventListener("focus", handleFocus);
        inputGeoElement.removeEventListener("blur", handleBlur);
        inputGeoElement.removeEventListener("input", handleChange);
      }
    };
  }, [val]);

  useEffect(() => {
    if (val && geoInputEl) {
      geoInputEl.value = val?.place_name || "";
      geoInputEl.title = val?.place_name || "";
    }
  }, [val, geoInputEl, i18n.language]);

  useEffect(() => {
    if (!isFocused && val) {
      setIsFocused(!!val);
    }
  }, [val, isFocused]);

  return (
    <InputBlock ref={wrapper} className={className}>
      <Label isFocused={isFocused} htmlFor={inputId}>
        {label}
      </Label>
      <GeocoderStyled
        ref={geocoderContainerRef}
        isError={!!errorMessage}
        isShowBtn={isShowBtn}
      />
      {errorMessage && (
        <ErrorMessage>
          {errorMessage?.message && t(errorMessage.message)}
        </ErrorMessage>
      )}
    </InputBlock>
  );
}

export default InputGeocoderRHF;
