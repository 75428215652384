import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ClinicWorkingDays } from "src/graphql/generated";
import RowDayInput from "./rowDayInput/RowDayInput";

const List = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  flex-direction: column;
  justify-content: flex-start;
`;

function WorktimeComponent() {
  const { t } = useTranslation();
  return (
    <List>
      <RowDayInput
        day={ClinicWorkingDays.Sunday}
        title={t("constructor.work_time.labels.sunday")}
      />
      <RowDayInput
        title={t("constructor.work_time.labels.monday")}
        day={ClinicWorkingDays.Monday}
      />
      <RowDayInput
        title={t("constructor.work_time.labels.tuesday")}
        day={ClinicWorkingDays.Tuesday}
      />
      <RowDayInput
        title={t("constructor.work_time.labels.wednesday")}
        day={ClinicWorkingDays.Wednesday}
      />
      <RowDayInput
        title={t("constructor.work_time.labels.thursday")}
        day={ClinicWorkingDays.Thursday}
      />
      <RowDayInput
        title={t("constructor.work_time.labels.friday")}
        day={ClinicWorkingDays.Friday}
      />
      <RowDayInput
        title={t("constructor.work_time.labels.saturday")}
        day={ClinicWorkingDays.Saturday}
      />
    </List>
  );
}

export default WorktimeComponent;
