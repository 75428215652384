import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Scrollbar } from "react-scrollbars-custom";
import { SvgProps, LogoMiniIcon } from "src/UI/Svg";
import * as IconModule from "src/UI/Svg";
import { menuItems } from "src/config/menu";
import { getMenuItemsByRole } from "src/utils";
import { useNavigate } from "react-router-dom";
import { PATHS } from "src/constants/constants";
import MenuItemComponent from "./MenuItemComponent";

// import MenuItemComponent from "./MenuItemComponent";
// import * as IconModule from "../../../UI/Svg";
// import { menuItems } from "../../../config/menu";
// import { AddAppointment } from "../../../context/AddAppointment";

const Icons = IconModule as unknown as { [key: string]: React.FC<SvgProps> };

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
  height: 100vh;
  max-height: 100vh;
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.03);
  //padding: 36px 0 32px;
  //gap: 40px;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  z-index: 1000;
  ${({ theme }) => theme.mediaQueries.ll} {
    //padding: 10px 0 26px;
    //gap: 10px;
  }

  .NavScrollContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: flex-start;
    justify-content: flex-start;
    gap: 40px;
    padding: 36px 0 32px !important;
    ${({ theme }) => theme.mediaQueries.ll} {
      padding: 10px 0 26px !important;
      gap: 10px;
    }
  }

  .ScrollbarsCustom-TrackY {
    width: 3px !important;
    top: 0 !important;
    height: 100% !important;
  }

  .ScrollbarsCustom-Thumb {
    background: #a5a5a5 !important;
  }

  .trackYVisible .ScrollbarsCustom-Scroller {
    margin-right: -30px !important;
  }
`;

const MenuList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 10px;
  flex: 1;
  width: 100%;
  // ${({ theme }) => theme.mediaQueries.ll} {
  //   gap: 10px;
  // }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  cursor: pointer;
`;

function MenuComponent() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const handleClickLogo = () => {
    navigate(`${PATHS.cp}${PATHS.booking}${PATHS.settings}${PATHS.addVisit}`);
  };
  return (
    <MenuWrapper>
      <Scrollbar
        style={{ width: 100 }}
        contentProps={{
          renderer: (props) => {
            const { elementRef, ...restProps } = props;
            return (
              <div
                {...restProps}
                ref={elementRef}
                className="NavScrollContent"
              />
            );
          },
        }}
      >
        <Logo onClick={handleClickLogo}>
          <LogoMiniIcon height={48} />
        </Logo>
        <MenuList>
          {getMenuItemsByRole(menuItems).map((menuItem) => {
            const Icon = Icons[menuItem.icon];
            const iconElement = <Icon />;

            return (
              <MenuItemComponent
                key={menuItem.link}
                link={menuItem.link}
                tip={t(`menu.${menuItem.tip}`)}
                icon={iconElement}
              />
            );
          })}
        </MenuList>
        {/* <MenuItemComponent
          link="/help"
          tip={t(`menu.help`)}
          icon={<HelpIcon />}
        /> */}
      </Scrollbar>
    </MenuWrapper>
  );
}

export default MenuComponent;
