import { Chart } from "react-chartjs-2";
import { Heading } from "src/UI/Heading";
import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 20px;
  gap: 20px;
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  width: 100%;
  max-height: 416px;
  flex: 1;
`;

export const HeadingStyled = styled(Heading)`
  font-size: 24px;
  line-height: 120%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const Filters = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
`;

export const Tab = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;
  background: ${({ theme, isActive }) =>
    isActive ? theme.colors.lightBiege : "transparent"};
  color: ${({ theme, isActive }) => theme.colors[isActive ? "green" : "black"]};
  border-radius: 6px;
  cursor: pointer;
`;

export const ChartWrapper = styled.div`
  width: 100%;
  max-height: 321px;
`;

export const ChartStyled = styled(Chart)`
  width: 100% !important;
  height: 100% !important;
`;
