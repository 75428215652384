import { TDoctorsGroup } from "src/store/calendar/types";
import { DoctorIcon } from "src/UI/Svg";
import { IDoctorsAgregated } from "src/types";
import { useMemo } from "react";
import {
  ColumnStyled,
  HeadingStyled,
  IconWrapper,
  ImageStyled,
  Text,
  TextNameStyled,
  TextTypeStyled,
  UserOptionStyled,
  WrapperStyled,
} from "./styles";

const HeadingDoctorsComponent = ({
  doctor,
  doctorsList,
  color,
}: {
  doctor: TDoctorsGroup[number];
  doctorsList: IDoctorsAgregated[];
  color?: string;
}) => {
  const currentDoctor = useMemo(() => {
    return doctorsList?.find(
      (doctorItem) => doctorItem.doctorEntity.doctorId === doctor.value
    );
  }, [doctorsList, doctor.value]);

  return (
    <HeadingStyled className="slide-heading" color={color}>
      <WrapperStyled>
        <UserOptionStyled>
          <IconWrapper>
            {!currentDoctor?.avatarUrl ? (
              <DoctorIcon height={32} width={32} />
            ) : (
              <ImageStyled src={currentDoctor.avatarUrl} alt="avatar" />
            )}
          </IconWrapper>
          <ColumnStyled>
            <TextNameStyled>
              {currentDoctor?.doctorEntity.firstName}{" "}
              {currentDoctor?.doctorEntity?.lastName}
            </TextNameStyled>
            <TextTypeStyled>{currentDoctor?.doctorType}</TextTypeStyled>
          </ColumnStyled>
        </UserOptionStyled>
        <Text>{currentDoctor?.doctorEntity?.medicalCertificateNumber}</Text>
      </WrapperStyled>
    </HeadingStyled>
  );
};

export default HeadingDoctorsComponent;
