import { gql } from "@apollo/client";

export const FIND_AND_COUNT_MANY_SCHEDULE_WAITLISTS_BY_CLIENT = gql`
  query FindAndCountManyScheduleWaitlistsByClient(
    $schema: FindAndCountManyScheduleWaitlistsByClientSchema!
  ) {
    findAndCountManyScheduleWaitlistsByClient(schema: $schema) {
      count
      waitlists {
        clinicId
        createdAt
        dateFrom
        dateTo
        doctor {
          id
          doctorId
          avatarFileId
          firstName
          lastName
          email
          phoneNumber
        }
        doctorId
        hash
        id
        minuteFrom
        minuteTo
        patient {
          firstName
          lastName
          phoneNumber
          languageISOCode
        }
        patientId
        service {
          category {
            id
            name
          }
          currencyCode
          description
          durationInMinutes
          id
          name
          price
        }
        serviceId
        status
        uncommittedPatientId
      }
    }
  }
`;
