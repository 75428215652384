import { Button } from "src/UI/Button";
import DropdownInputRHF from "src/UI/DropdownInput/DropdownInputRHF";
import { DateInputRHF } from "src/UI/Input";
import DropdownAutocompleteRHF from "src/UI/Input/dropdownAutocompleteRHF/DropdownAutocompleteRHF";
import styled from "styled-components";

export const IconButton = styled.button`
  border: none;
  background: none;
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
  justify-content: center;
  padding: 0;

  svg {
    width: 24px;
    height: 24px;

    path {
      stroke: ${({ theme }) => theme.colors.gray};
    }
  }
  ${({ theme }) => theme.mediaQueries.ll} {
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const Menu = styled.div<{ isShowMenu: boolean }>`
  width: 500px;
  filter: drop-shadow(0px 5px 25px rgba(0, 0, 0, 0.03));
  top: -10px;
  z-index: 10;
  right: -5px;
  position: absolute;
  opacity: ${({ isShowMenu }) => (isShowMenu ? 1 : 0)};
  visibility: ${({ isShowMenu }) => (isShowMenu ? "visible" : "hidden")};
`;

export const FilterBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 15px;
  gap: 16px;
  background: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  border-radius: 30px;
`;
export const Buttons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  gap: 8px;
`;

export const ButtonStyled = styled(Button)`
  padding: 15px 20px;
  font-size: 20px;
  line-height: 100%;
  width: fit-content;
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 16px;
    padding: 12px 16px;
  }
`;

export const DropdownInputRHFStyled = styled(DropdownInputRHF)`
  width: 100%;

  & > :first-child {
    padding: 8px;
    padding-right: 25px;
  }
`;
export const DropdownAutocompleteRHFStyled = styled(DropdownAutocompleteRHF)`
  width: 100%;

  & > :first-child {
    padding: 8px;
    padding-right: 25px;
  }
`;

export const RowInput = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`;

export const Label = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.gray};
  margin-right: 20px;
`;

export const MenuInput = styled.input`
  border: none;
  max-width: 686px;
  height: 32px;
  padding: 5px 10px;
  width: 100%;
  background: ${({ theme }) => theme.colors.lightBiege};
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  outline: none;
  color: ${({ theme }) => theme.colors.darkGrey};
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 5px 10px;
    font-size: 12px;
  }
`;

export const DateInputRHFStyled = styled(DateInputRHF)`
  width: 130px;
  background: #f8f8f8;
  border-radius: 5px;
  border: none;

  & svg {
    display: none;
  }
  & .react-datepicker-wrapper {
    height: 32px;
  }

  & .react-datepicker__input-container > div {
    padding: 0 10px;
    min-height: 32px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    border: none;
    color: ${({ theme }) => theme.colors.darkGrey};
  }
`;

export const Dates = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
  font-weight: 400;
  font-size: 16px;
  max-width: 686px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.darkGrey};
`;
