import styled from "styled-components";

export const Label = styled.label`
  position: relative;
`;

export const Switch = styled.div`
  position: relative;
  box-sizing: border-box;
  min-width: 49px;
  display: flex;
  align-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.grayC4};
  cursor: pointer;
  border-radius: 48px;
  padding: 4px;
  gap: 10px;
  width: 48px;
  height: 24px;

  &::after {
    content: "";
    position: absolute;
    transition: all 0.25s ease;
    top: 50%;
    left: 4px;
    transform: translateY(-50%);
    background: ${({ theme }) => theme.colors.background};
    border-radius: 50%;
    width: 16px;
    height: 16px;
  }
`;

export const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Switch} {
    background: ${({ theme }) => theme.colors.green};

    &::after {
      left: calc(100% - 20px);
    }
  }
`;
