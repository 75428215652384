import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { CameraIcon, DoctorIcon } from "src/UI/Svg";

import { useAuthContext } from "src/context/AuthContext";
import {
  useUpdateUserAvatarFileId,
  useUploadFile,
} from "src/graphql/hooks/useMutations";
import { toast } from "react-toastify";
import { Loading } from "src/UI/Loading";
import { useFindManyFilesByIdsLazy } from "src/graphql/hooks/useQueries";
import {
  InputLogo,
  NameBlock,
  Photo,
  PhotoButton,
  ProfilePhoto,
  Title,
  Wrapper,
  LoadingWrap,
} from "./styled";

function ProfileComponent() {
  const { t } = useTranslation();
  const { currentUserData, refetchUser, loading, localUsers } =
    useAuthContext();
  const [uploadPhoto, { loading: uploadPhotoLoading }] = useUploadFile();
  const [updateUserFileId, { loading: updateUserFileIdLoading }] =
    useUpdateUserAvatarFileId();
  const [getFile, { loading: getFileLoading }] = useFindManyFilesByIdsLazy();
  const [preview, setPreview] = useState<string | undefined>("");

  useEffect(() => {
    if (!currentUserData?.avatarFileId) {
      setPreview("");
      return;
    }

    getFile({
      variables: {
        schema: {
          ids: [currentUserData?.avatarFileId],
        },
      },
      fetchPolicy: "network-only",
      onCompleted: (response) => {
        const logoUrl = response?.findManyFilesByIds?.files?.[0]?.url;
        if (logoUrl) setPreview(logoUrl as string);
      },
    });
  }, [currentUserData?.avatarFileId]);

  const handleChangePhoto = (photo: File | null) => {
    if (photo) {
      uploadPhoto({
        variables: {
          schema: { file: photo },
        },
        onCompleted: (response) => {
          updateUserFileId({
            variables: {
              schema: {
                avatarFileId: response.uploadFile.id,
              },
            },
            onCompleted: () => {
              toast.success(t("app.successful"));
              const updatedUsers = localUsers?.map((user) => {
                if (user.id === currentUserData?.id) {
                  return { ...user, avatarFileId: response.uploadFile.id };
                }
                return user;
              });
              localStorage.setItem("users", JSON.stringify([...updatedUsers]));
              refetchUser();
            },
          });
        },
      });
    }
  };

  const onSelectFile = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      handleChangePhoto(null);
      return;
    }
    const objectUrl: string = URL.createObjectURL(e.target.files[0]);
    setPreview(objectUrl);
    handleChangePhoto(e.target.files[0]);
  };

  return (
    <Wrapper>
      <ProfilePhoto>
        {loading ||
        uploadPhotoLoading ||
        updateUserFileIdLoading ||
        getFileLoading ? (
          <LoadingWrap>
            <Loading size="32px" type="primary" padding="0" />
          </LoadingWrap>
        ) : (
          <>
            {preview ? <Photo src={preview} /> : <DoctorIcon />}
            <InputLogo id="profile-photo" type="file" onChange={onSelectFile} />
            <PhotoButton htmlFor="profile-photo">
              <CameraIcon />
              {t("user.person_data.profile.button")}
            </PhotoButton>
          </>
        )}
      </ProfilePhoto>

      <NameBlock>
        {loading ? (
          <LoadingWrap>
            <Loading type="primary" padding="0" />
          </LoadingWrap>
        ) : (
          <Title as="h3">{`${
            currentUserData?.firstName ? currentUserData?.firstName : ""
          } ${
            currentUserData?.lastName ? currentUserData?.lastName : ""
          }`}</Title>
        )}
      </NameBlock>
    </Wrapper>
  );
}

export default ProfileComponent;
