import { useTranslation } from "react-i18next";
import { ChevronRightIcon } from "src/UI/Svg";
import { useNavigate } from "react-router-dom";
import {
  APPOINTMENT_TYPE_NAME_BY_CLIENT,
  APPOINTMENT_TYPE_NAME_BY_DOCTOR,
  PATHS,
  PATIENT_TYPE_NAME,
} from "src/constants/constants";
import { TScheduleAppointmentByRole } from "src/types";
import { useAppAction } from "src/store/app/hooks";
import {
  SearchByClientUnion,
  SearchByDoctorUnion,
} from "src/graphql/generated";
import { useAuthContext } from "src/context/AuthContext";
import { Dispatch, SetStateAction } from "react";
import { ListStyled, ShowAllBlock } from "./styled";
import PatientSearchComponent from "../SearchResultComponents/PatientSearchComponent/PatientSearchComponent";
import AppointmentSearchComponent from "../SearchResultComponents/AppointmentSearchComponent/AppointmentSearchComponent";

interface ResultListComponentProps {
  isShow: boolean;
  onClose: () => void;
  autocompleteResultByRole:
    | SearchByClientUnion[]
    | SearchByDoctorUnion[]
    | undefined;
  search: string;
  setIsShow: Dispatch<SetStateAction<boolean>>;
}

function ResultListComponent({
  isShow,
  onClose,
  autocompleteResultByRole,
  search,
  setIsShow,
}: ResultListComponentProps) {
  const { t } = useTranslation();
  const { isDoctor } = useAuthContext();
  const { onShowScheduleAppointmentModal } = useAppAction();
  const navigate = useNavigate();

  const isAutocompleteLength = !!autocompleteResultByRole?.length;

  const handleShowModal = () => {
    if (!isAutocompleteLength) return;
    const searchParams = new URLSearchParams({
      search,
    });
    navigate({
      pathname: `${PATHS.cp}${PATHS.searchResult}`,
      search: searchParams.toString(),
    });
    onClose();
  };

  const handleShowAppointmentModal =
    (appointment: TScheduleAppointmentByRole) => () => {
      onShowScheduleAppointmentModal({
        data: {
          appointment,
        },
      });
    };

  const appointmentTypeNameByRole = isDoctor
    ? APPOINTMENT_TYPE_NAME_BY_DOCTOR
    : APPOINTMENT_TYPE_NAME_BY_CLIENT;

  return (
    <ListStyled isShow={isShow}>
      {autocompleteResultByRole?.map((item) => {
        if (item.__typename === appointmentTypeNameByRole) {
          return (
            <AppointmentSearchComponent
              key={item.id}
              item={item}
              handleShowModal={handleShowAppointmentModal}
            />
          );
        }
        if (item.__typename === PATIENT_TYPE_NAME) {
          return (
            <PatientSearchComponent
              key={item.id}
              item={item}
              setIsShow={setIsShow}
            />
          );
        }
        return null;
      })}

      <ShowAllBlock
        isAutocompleteLength={isAutocompleteLength}
        onClick={handleShowModal}
      >
        {isAutocompleteLength ? (
          <>
            {t("search.show_all")}
            <ChevronRightIcon />
          </>
        ) : (
          t("app.not_found")
        )}
      </ShowAllBlock>
    </ListStyled>
  );
}

export default ResultListComponent;
