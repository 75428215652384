import { gql } from "@apollo/client";

export const DELETE_SERVICE_CATEGORY_BY_ID = gql`
  mutation DeleteServiceCategoryById(
    $schema: DeleteServiceCategoryByIdSchema!
  ) {
    deleteServiceCategoryById(schema: $schema) {
      deleted
    }
  }
`;
