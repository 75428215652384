import { Button } from "src/UI/Button";
import styled from "styled-components";

export const FooterButton = styled(Button)`
  padding: 15px 20px;
  font-size: 20px;
  line-height: 100%;
  max-width: unset;
  width: fit-content;
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 12px 16px;
    font-size: 16px;
  }
`;

export const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
  justify-content: flex-end;
  gap: 8px;
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 7px;
  }
`;
