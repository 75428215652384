import { useTranslation } from "react-i18next";
import { ServiceEntityWithRelationsResponse } from "src/graphql/generated";

import { getCurrencySimbolByCode } from "src/utils";
import { Toggle } from "src/UI/Toggle";
import {
  useAttachDoctorToService,
  useUnattachDoctorService,
} from "src/graphql/hooks/useMutations";
import { GET_FIND_MANY_SERVICES } from "src/graphql/queries";
import { toast } from "react-toastify";
import { Loading } from "src/UI/Loading";
import {
  Content,
  Header,
  HiddenTag,
  Price,
  RightBlock,
  Tag,
  Tags,
  Title,
  Wrapper,
} from "./styles";

function ServiceComponent({
  service,
  doctorId,
}: {
  service: ServiceEntityWithRelationsResponse;
  doctorId: string;
}) {
  const { t } = useTranslation();

  const {
    id,
    hiddenFromPatients,
    durationInMinutes,
    name,
    price,
    description,
    currencyCode,
    doctorIds,
  } = service;

  const isActive = doctorIds?.includes(doctorId);

  const [attachDoctorToService, { loading: attachServiceLoading }] =
    useAttachDoctorToService();

  const [unattachDoctorToService, { loading: unattachServiceLoading }] =
    useUnattachDoctorService();

  const handleAttachDoctorToService = () => {
    if (isActive) {
      unattachDoctorToService({
        variables: {
          schema: {
            doctorId,
            serviceId: id,
          },
        },
        refetchQueries: [GET_FIND_MANY_SERVICES],
        awaitRefetchQueries: true,
        onCompleted: () => {
          toast.success(t("app.successful"));
        },
      });
    } else {
      attachDoctorToService({
        variables: {
          schema: {
            doctorId,
            serviceId: id,
          },
        },
        refetchQueries: [GET_FIND_MANY_SERVICES],
        awaitRefetchQueries: true,
        onCompleted: () => {
          toast.success(t("app.successful"));
        },
      });
    }
  };

  return (
    <Wrapper>
      <Tags>
        {hiddenFromPatients && (
          <HiddenTag>{t("booking.services.hidden")}</HiddenTag>
        )}
        <Tag>{`${durationInMinutes} ${t("booking.services.actions.min")}`}</Tag>
      </Tags>
      <Header>
        <Title>{name}</Title>
        <RightBlock>
          <Price>
            {price?.toLocaleString().replace(",", " ")}{" "}
            {getCurrencySimbolByCode(currencyCode as string)}
          </Price>
          {attachServiceLoading || unattachServiceLoading ? (
            <Loading />
          ) : (
            <Toggle
              isActive={!!isActive as boolean}
              onToggle={handleAttachDoctorToService}
            />
          )}
        </RightBlock>
      </Header>
      <Content isHidden={hiddenFromPatients}>{description}</Content>
    </Wrapper>
  );
}

export default ServiceComponent;
