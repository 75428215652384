import { useAuthContext } from "src/context/AuthContext";
import { SearchTypes, useSearchByDoctorQuery } from "src/graphql/generated";
import { defaultSearchLimit } from "src/constants/constants";
import { toast } from "react-toastify";
import { useFetchMore } from "src/hooks/useFetchMore";

interface IUseSearchByDoctorFetchMore {
  filter: SearchTypes;
  query: string | null;
}

export function useSearchByDoctorFetchMore({
  filter,
  query,
}: IUseSearchByDoctorFetchMore) {
  const { isDoctor, currentUserData } = useAuthContext();

  const { data, loading, fetchMore } = useSearchByDoctorQuery({
    variables: {
      schema: {
        offset: 0,
        limit: defaultSearchLimit,
        type: filter,
        query: query as string,
      },
    },
    onError: (error) => {
      if (error) toast.error(error.message);
    },
    skip: !isDoctor || !query || !currentUserData,
  });

  const { node, isLoading } = useFetchMore(() =>
    fetchMore({
      variables: {
        schema: {
          offset: data?.searchByDoctor.entities?.length,
          limit: defaultSearchLimit,
          type: filter,
          query,
        },
      },
    })
  );

  return {
    data,
    loading,
    node,
    isLoading,
  };
}
