import styled from "styled-components";
import { Button } from "src/UI/Button";
import { TemplatesComponentWrapper } from "../styled";

export const StyledTemplatesComponentWrapper = styled(
  TemplatesComponentWrapper
)`
  align-items: end;
  padding: 20px;
  gap: 20px;
`;

export const SaveActionsButton = styled(Button)`
  padding: 15px 20px;
  font-size: 20px;
  line-height: 100%;
  width: fit-content;
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 16px;
    padding: 12px 16px;
  }
`;

interface IFieldItemProps {
  isSelected: boolean;
}

export const FieldItem = styled.div<IFieldItemProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 20px 15px;
  text-align: center;
  justify-content: center;
  gap: 15px;
  background: ${({ theme, isSelected }) =>
    theme.colors[isSelected ? "greenHover" : "background"]};
  border: 1px solid
    ${({ theme, isSelected }) =>
      theme.colors[isSelected ? "green" : "borderInputDefault"]};
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  height: 100%;
  white-space: nowrap;
  color: ${({ theme, isSelected }) =>
    theme.colors[isSelected ? "green" : "black"]};

  svg {
    width: 40px;
    height: 40px;
    path {
      stroke: ${({ theme, isSelected }) =>
        theme.colors[isSelected ? "green" : "grayC4"]};
    }
  }
`;

export const Title = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 16px;
  }
`;

export const CloseButton = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  cursor: pointer;
`;

export const List = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 16px;
  }
`;
