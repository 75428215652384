import { Button } from "src/UI/Button";
import styled from "styled-components";

export const Item = styled.div<{ isSelected: boolean; opacity: number }>`
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  cursor: pointer;
  background: ${({ theme, isSelected }) =>
    theme.colors[isSelected ? "lightBiege" : "background"]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  border-left: 2px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.colors.green : "transparent"};
  opacity: ${({ opacity }) => opacity};

  &:last-child {
    border-bottom: none;
  }

  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 16px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  gap: 24px;

  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 20px;
  }
`;

export const Info = styled.div<{ isGreen: boolean }>`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 15px;

  svg {
    width: 40px;
    height: 40px;

    path {
      stroke: ${({ theme, isGreen }) =>
        theme.colors[isGreen ? "green" : "grayC4"]};
    }
  }

  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 12px;

    svg {
      width: 36px;
      height: 36px;
    }
  }
`;

export const ButtonIcon = styled(Button)`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;

    path {
      stroke: ${({ theme }) => theme.colors.gray};
    }
  }
`;

export const Desc = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 8px;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.black};

  div {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: ${({ theme }) => theme.colors.darkGrey};
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    gap: 8px;
  }

  span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2px 4.5px;
    font-size: 10px;
    line-height: 100%;
    background: ${({ theme }) => theme.colors.green};
    border-radius: 800px;
    color: ${({ theme }) => theme.colors.white};
  }

  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 7px;
    font-size: 16px;

    div {
      font-size: 12px;
      gap: 7px;
    }
  }
`;
