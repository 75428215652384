import styled from "styled-components";

export const EmptyTextStyled = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.gray};
  padding: 20px;
`;

export const PatientStatusWrapper = styled.div<{ isNewStatus: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ isNewStatus, theme }) =>
    isNewStatus ? theme.colors.green : theme.colors.darkGrey};
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 20px;

  thead {
  }

  th {
    padding: 20px 5px 5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.grayC4};
    text-align: left;
  }

  td {
    padding: 30px 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black};
    text-align: left;
  }
`;

export const LinkStyled = styled.div`
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.green};
`;
