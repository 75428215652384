import { FormFieldTranslationEntityResponse } from "src/graphql/generated";
import { FormFieldTranslationEntityResponseParsed } from "src/types";

export function getTranslationsBySelectedLang(
  selectedLang: string,
  translations: Array<FormFieldTranslationEntityResponse>
): FormFieldTranslationEntityResponse;
export function getTranslationsBySelectedLang(
  selectedLang: string,
  translations: Array<FormFieldTranslationEntityResponseParsed>
): FormFieldTranslationEntityResponseParsed;

export function getTranslationsBySelectedLang(
  selectedLang: string,
  translations: Array<
    | FormFieldTranslationEntityResponse
    | FormFieldTranslationEntityResponseParsed
  >
):
  | FormFieldTranslationEntityResponse
  | FormFieldTranslationEntityResponseParsed {
  const translationByLang = translations.find(
    (item) => item.languageISOCode === selectedLang.toUpperCase() && !!item.name
  );

  if (translationByLang) return translationByLang;

  const defaultTranslation = translations.find((item) => !!item.name);

  return defaultTranslation || translations[0];
}
