import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ClinicRoomEntityResponse,
  CurrencyEntityResponse,
  ServiceCategoryEntityResponse,
  ServiceSortFields,
  SortingOrders,
  useFindManyClinicRoomsByClinicIdLazyQuery,
  useFindManyCurrenciesLazyQuery,
  useFindManyServiceCategoriesByClinicIdLazyQuery,
} from "src/graphql/generated";
import { FieldError, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputAutosizeRHF, InputRHF } from "src/UI/Input";
import { EyeOffIcon } from "src/UI/Svg";
import { EButtonVariants } from "src/UI/Button";
import { useAppAction, useAppState } from "src/store/app/hooks";
import { EModals } from "src/store/app/types";
import { Modal } from "src/UI/Modal";
import { Toggle } from "src/UI/Toggle";
import { createServiceValidationSchema } from "src/validation/schemas/createServiceSchema";
import { transformBeforeEditService } from "src/connectors/Services/transformBeforeEditService";
import { AddCategoryView } from "src/views/MapViews";
import DropdownInputRHF from "src/UI/DropdownInput/DropdownInputRHF";
import DropdownAutocompleteRHF from "src/UI/Input/dropdownAutocompleteRHF/DropdownAutocompleteRHF";
import { getCurrencySimbolByCode } from "src/utils";
import { durationInMinutes } from "src/constants/constants";
import InputNumberFormatRHF from "src/UI/Input/inputNumberFormatRHF/inputNumberFormatRHF";
import { useClinicsState } from "src/store/clinics/hooks";
import { useUpdateServiceById } from "src/graphql/hooks/useMutations";
import { GET_FIND_MANY_SERVICES } from "src/graphql/queries";
import { Loading } from "src/UI/Loading";
import { toast } from "react-toastify";
import {
  Content,
  Footer,
  FooterButton,
  InputsRow,
  LabelBlock,
  ToggleBlock,
  Form,
  DropdownPriceWrap,
  InputsRowPrice,
} from "./styles";

interface FormValues {
  categoryId: string;
  categoryName?: string;
  currencyCode?: string;
  description?: string | null;
  durationInMinutes: number | null;
  hiddenFromPatients: boolean;
  icd10Code?: string;
  icd9Code?: string;
  name: string;
  photoIds?: string[];
  price?: number | null;
  roomIds: string[];
}

function EditServiceModal() {
  const { t } = useTranslation();
  const { currentClinicId: clinicId } = useClinicsState();
  const { onCloseModal } = useAppAction();
  const { modals } = useAppState();
  const [serviceId, setServiceId] = useState("");
  const [currencies, setCurrencies] = useState<
    { value: string; item: string }[]
  >([]);
  const [cabinetsList, setCabinetsList] = useState<
    { value: string; key: string }[]
  >([]);
  const [serviceCategories, setServiceCategories] = useState<
    ServiceCategoryEntityResponse[]
  >([]);

  const methods = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(createServiceValidationSchema),
    defaultValues: {
      categoryId: "",
      categoryName: "",
      description: "",
      durationInMinutes: null,
      hiddenFromPatients: false,
      name: "",
      photoIds: [],
      price: null,
      currencyCode: currencies?.[0]?.value || "",
      roomIds: [],
    },
  });

  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
    reset,
  } = methods;

  const data = watch();

  const [updateService, { loading: updateServiceLoading }] =
    useUpdateServiceById({
      refetchQueries: [
        {
          query: GET_FIND_MANY_SERVICES,
          variables: {
            schema: {
              clinicId,
              sortField: ServiceSortFields.CreatedAt,
              sortOrder: SortingOrders.Descending,
            },
          },
        },
      ],
      awaitRefetchQueries: true,
    });

  const [getCabinets] = useFindManyClinicRoomsByClinicIdLazyQuery({
    variables: {
      schema: {
        clinicId,
      },
    },
    onCompleted(response) {
      const cabinets = response?.findManyClinicRoomsByClinicId?.rooms.map(
        (cabinet: ClinicRoomEntityResponse) => ({
          value: cabinet?.id,
          key: cabinet?.name,
        })
      );
      setCabinetsList(cabinets);
    },
  });

  const [getCategories] = useFindManyServiceCategoriesByClinicIdLazyQuery({
    variables: { schema: { clinicId } },
    onCompleted: (response) => {
      setServiceCategories(
        response.findManyServiceCategoriesByClinicId
          .categories as ServiceCategoryEntityResponse[]
      );
    },
  });

  const [getCurrencies] = useFindManyCurrenciesLazyQuery({
    onCompleted(response) {
      const currenciesList = response?.findManyCurrencies?.currencies?.map(
        (currencie: CurrencyEntityResponse) => ({
          value: currencie?.code,
          item: getCurrencySimbolByCode(currencie?.code),
        })
      );
      setCurrencies(currenciesList);
      setValue("currencyCode", currenciesList?.[0]?.value);
    },
  });

  useEffect(() => {
    if (modals[EModals.EditService]?.data?.service) {
      reset(
        transformBeforeEditService(modals[EModals.EditService]?.data?.service)
      );
      setServiceId((modals[EModals.EditService]?.data?.service).id);
    }
  }, [modals[EModals.EditService]?.data?.service]);

  useEffect(() => {
    if (modals[EModals.EditService].active && clinicId) {
      getCabinets();
      getCategories();
      getCurrencies();
    }
  }, [modals[EModals.EditService].active]);

  const closeModal = useCallback(() => {
    onCloseModal(EModals.EditService);
    reset();
  }, [onCloseModal]);

  const updateServiceHandler = (formData: FormValues) => {
    const params = {
      categoryId: formData.categoryId,
      description: formData.description,
      hiddenFromPatients: formData.hiddenFromPatients,
      name: formData.name,
      currencyCode: formData.currencyCode,
      durationInMinutes: Number(formData.durationInMinutes),
      roomIds: formData.roomIds,
      price: Number(formData.price),
      id: serviceId,
    } as FormValues;

    updateService({
      variables: {
        schema: params as FormValues,
      },
      onCompleted: (response) => {
        if (response) {
          toast.success(t("app.successful"));
          reset();
          closeModal();
        }
      },
    });
  };

  const togglehiddenFromPatientsHnadler = () => {
    setValue("hiddenFromPatients", !data.hiddenFromPatients);
  };

  if (!modals[EModals.EditService].active) return null;
  return (
    <Modal
      modal={EModals.EditService}
      title={t("booking.edit_service.edit")}
      onCloseAction={closeModal}
    >
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(updateServiceHandler)}>
          <Content>
            <InputsRow>
              <InputRHF
                val={data.name}
                label={t("booking.edit_service.label.title") as string}
                register={register("name")}
                inputType="text"
                errorMessage={errors.name}
              />
              <DropdownInputRHF
                name="durationInMinutes"
                value={data.durationInMinutes}
                placeholder=""
                options={durationInMinutes}
                label={t("booking.add_service.label.delay" as string)}
                adaptedForMobile={false}
                errorMessage={errors.durationInMinutes}
              />
            </InputsRow>

            <InputsRow>
              <InputsRowPrice>
                <InputNumberFormatRHF
                  val={data?.price?.toString() as string}
                  name="price"
                  label={t("booking.add_service.label.price") as string}
                  errorMessage={errors.price}
                />

                <DropdownPriceWrap>
                  <DropdownInputRHF
                    name="currencyCode"
                    value={data.currencyCode}
                    placeholder=""
                    options={currencies}
                    label={t("app.currencies" as string)}
                    adaptedForMobile={false}
                    errorMessage={errors.currencyCode}
                    customWidth="70px"
                  />
                </DropdownPriceWrap>
              </InputsRowPrice>

              <AddCategoryView
                name="categoryId"
                category={data.categoryId}
                errorMessage={errors.categoryId || errors.categoryName}
                serviceCategories={serviceCategories}
                setServiceCategories={setServiceCategories}
              />
            </InputsRow>
            {!!cabinetsList?.length && (
              <DropdownAutocompleteRHF
                name="roomIds"
                options={cabinetsList}
                label={t("map.cabinets.title") as string}
              />
            )}
            <InputAutosizeRHF
              val={data.description as string}
              label={t("booking.add_service.label.description") as string}
              inputType="text"
              errorMessage={errors.description as FieldError}
              register={register("description")}
            />
          </Content>
          <Footer>
            <ToggleBlock>
              <LabelBlock>
                <EyeOffIcon />{" "}
                <div>
                  {t("booking.edit_service.hidden") as string}{" "}
                  <span>{t("booking.edit_service.after") as string}</span>
                </div>
              </LabelBlock>
              <Toggle
                isActive={data.hiddenFromPatients}
                onToggle={togglehiddenFromPatientsHnadler}
              />
            </ToggleBlock>

            <FooterButton variant={EButtonVariants.Default}>
              {updateServiceLoading && <Loading padding="0" />}
              {t("booking.edit_service.public") as string}
            </FooterButton>
          </Footer>
        </Form>
      </FormProvider>
    </Modal>
  );
}

export default EditServiceModal;
