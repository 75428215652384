import { Dispatch, ReactNode, SetStateAction, useEffect } from "react";
import {
  ServiceEntityWithRelationsResponse,
  ServiceSortFields,
  SortingOrders,
  useFindManyServicesByPatientLazyQuery,
} from "src/graphql/generated";
import { useBookingState } from "src/store/booking/hooks";

export const useServicesListEffect = (
  setServices: Dispatch<
    SetStateAction<
      {
        item: string | ReactNode;
        value: object;
      }[]
    >
  >
) => {
  const { currentClinic } = useBookingState();
  const [getServices, { loading }] = useFindManyServicesByPatientLazyQuery();

  useEffect(() => {
    if (currentClinic?.clinic?.id) {
      getServices({
        variables: {
          schema: {
            clinicId: currentClinic?.clinic?.id,
            sortField: ServiceSortFields.CreatedAt,
            sortOrder: SortingOrders.Descending,
          },
        },
        onCompleted(response) {
          setServices(
            response?.findManyServicesByPatient?.services?.map(
              (service: ServiceEntityWithRelationsResponse) => ({
                value: service,
                item: service.name,
              })
            )
          );
        },
      });
    }
  }, [currentClinic?.clinic?.id]);

  return { loading };
};
