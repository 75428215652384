import { gql } from "@apollo/client";

export const GET_FIND_MANY_AVAILABLE_SLOTS_BY_DATE_RANGE = gql`
  query FindManyAvailableSlotsByDateRange(
    $schema: FindManyAvailableSlotsByDateRangeSchema!
  ) {
    findManyAvailableSlotsByDateRange(schema: $schema) {
      availableSlots {
        date
        slots {
          from
          to
        }
      }
    }
  }
`;
