import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { Multiselect } from "src/UI/Multiselect";
import { CalendarIcon, DeleteIcon } from "src/UI/Svg";
import "react-datepicker/dist/react-datepicker.css";
import {
  EventTypes,
  LayoutTypes,
  ScheduleEntityResponse,
} from "src/graphql/generated";
import { format } from "date-fns";
import {
  getFNSLocaleByLang,
  getMinutesFromStartDay,
  getDateFromMinutesStartDay,
  getDaysOfWeek,
} from "src/utils";

import { toast } from "react-toastify";
import {
  useCreateSchedule,
  useUpdateScheduleByIdByClient,
} from "src/graphql/hooks/useMutations";
import { GET_FIND_SCHEDULES_BY_CLIENT } from "src/graphql/queries";
import { useClinicsState } from "src/store/clinics/hooks";
import { enUS } from "date-fns/locale";
import {
  ButtonIcon,
  DateInputStyled,
  DropdownStyled,
  DropdownWrapper,
  ErrorStyled,
  FooterRowWrapper,
  FormColumn,
  HeadWrapper,
  InputWrapper,
  Label,
  RowWrapper,
  Wrapper,
} from "./styles";

const CustomInput = forwardRef(
  (
    { value, onClick, placeholder }: React.HTMLProps<HTMLInputElement>,
    ref: React.Ref<HTMLInputElement>
  ) => (
    <DateInputStyled onClick={onClick} ref={ref}>
      {value || placeholder || ""}
    </DateInputStyled>
  )
);

function DaysOfWeekRowComponent({
  newItem,
  scheduleItem,
  cabinets,
  doctorId,
  removeItem = () => {},
}: {
  newItem?: { id: number; type: LayoutTypes };
  scheduleItem?: ScheduleEntityResponse;
  cabinets: { item: string; value: string }[];
  doctorId: string;
  removeItem?: (id: string | number) => void;
}) {
  const { t, i18n } = useTranslation();
  const { currentClinicId: clinicId } = useClinicsState();

  const [createSchedule] = useCreateSchedule();
  const [updateSchedule] = useUpdateScheduleByIdByClient();

  const workingTypeOpts: Array<{
    item: string;
    value: string;
  }> = [
    {
      item: t("app.working"),
      value: EventTypes.Working,
    },
    {
      item: t("app.vacation"),
      value: EventTypes.Vacation,
    },
  ];

  const weekdaysOpts = getDaysOfWeek(i18n.resolvedLanguage).map((day) => {
    return {
      value: format(day, "EEEE", { locale: enUS }),
      shortItem: format(day, "EEE", {
        locale: getFNSLocaleByLang(i18n.resolvedLanguage),
      }),
      item: format(day, "EEEE", {
        locale: getFNSLocaleByLang(i18n.resolvedLanguage),
      }),
    };
  });

  const [data, setData] = useState<{
    eventType: string;
    roomId: string;
    minuteFrom: number | null;
    minuteTo: number | null;
    daysOfWeek: string[] | null;
  }>({
    eventType: workingTypeOpts?.[0].value || "",
    roomId: "",
    minuteFrom: null,
    minuteTo: null,
    daysOfWeek: null,
  });

  const allKeys = Object.keys(data);
  const actulArrayKeys = useMemo(() => {
    return !cabinets?.length
      ? allKeys.filter((key) => key !== "roomId")
      : allKeys;
  }, [cabinets, allKeys]);

  const handleRemoveItem = (id: string | number) => () => {
    removeItem(id);
  };

  const onChange = (key: string, val: any) => {
    setData((prevState) => ({
      ...prevState,
      [key]: val,
    }));

    const filteredKeys = actulArrayKeys.filter((keyItem) => keyItem !== key);

    const allKeysContainValue = filteredKeys.every((keyItem) => {
      const dataKey = keyItem as keyof typeof data;
      return !!data?.[dataKey] || typeof data?.[dataKey] === "number";
    });

    const params = {
      eventType: data?.eventType,
      daysOfWeek: data?.daysOfWeek,
      minuteFrom: data?.minuteFrom,
      minuteTo: data?.minuteTo,
      ...(data?.roomId && { roomId: data?.roomId }),
    };

    const updatedParams = {
      ...params,
      [key]: val,
    };

    if (allKeysContainValue) {
      if (scheduleItem) {
        updateSchedule({
          variables: {
            schema: { fields: updatedParams, id: scheduleItem?.id },
          },
          refetchQueries: [GET_FIND_SCHEDULES_BY_CLIENT],
          awaitRefetchQueries: true,
          onCompleted: () => {
            toast.success(t("app.successful"));
          },
        });
      } else {
        createSchedule({
          variables: {
            schema: {
              ...updatedParams,
              doctorId,
              clinicId,
              layoutType: LayoutTypes.DayOfWeek,
            },
          },
          refetchQueries: [GET_FIND_SCHEDULES_BY_CLIENT],
          onCompleted: () => {
            toast.success(t("app.successful"));
            if (newItem) handleRemoveItem(newItem?.id)();
          },
        });
      }
    }
  };

  useEffect(() => {
    if (scheduleItem) {
      setData({
        eventType: scheduleItem?.eventType,
        daysOfWeek: scheduleItem?.daysOfWeek as string[],
        minuteFrom: scheduleItem?.minuteFrom,
        minuteTo: scheduleItem?.minuteTo,
        roomId: scheduleItem?.roomId || "",
      });
    }
  }, [scheduleItem]);

  return (
    <Wrapper>
      <HeadWrapper>
        <DropdownWrapper>
          <DropdownStyled
            isShowOnlyPlaceholder={false}
            value={data.eventType}
            placeholder={t("templates.history.headers.type")}
            onSelect={(val) => onChange("eventType", val)}
            workingType={data.eventType}
            options={workingTypeOpts}
            label=""
          />
        </DropdownWrapper>
        <ButtonIcon
          onClick={handleRemoveItem(scheduleItem?.id as string)}
          type="button"
        >
          <DeleteIcon />
        </ButtonIcon>
      </HeadWrapper>
      <RowWrapper>
        <FormColumn>
          <Multiselect
            value={data.daysOfWeek || []}
            placeholder={t("booking.timetable.days")}
            options={weekdaysOpts}
            label={t("booking.timetable.days")}
            onSelect={(val) => onChange("daysOfWeek", val)}
            customIcon={<CalendarIcon width={16} height={16} />}
          />
          {!data?.daysOfWeek?.length && (
            <ErrorStyled>{t("validation.field_required")}</ErrorStyled>
          )}
        </FormColumn>
        <FormColumn>
          <InputWrapper>
            <Label>{t("booking.timetable.labels.from")}</Label>
            <DatePicker
              selected={getDateFromMinutesStartDay(data.minuteFrom)}
              onChange={(date) =>
                onChange("minuteFrom", getMinutesFromStartDay(date))
              }
              placeholderText={t("booking.timetable.labels.from") as string}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={10}
              timeCaption={t("app.schedule_time") as string}
              customInput={<CustomInput />}
              timeFormat="HH:mm"
              dateFormat="HH:mm"
              filterTime={(currentTime) => {
                const minuteTo = data?.minuteTo;
                if (minuteTo) {
                  return (
                    currentTime < (getDateFromMinutesStartDay(minuteTo) as Date)
                  );
                }
                return true;
              }}
            />
          </InputWrapper>
          {typeof data.minuteFrom !== "number" && (
            <ErrorStyled>{t("validation.field_required")}</ErrorStyled>
          )}
        </FormColumn>
        <FormColumn>
          <InputWrapper>
            <Label>{t("booking.timetable.labels.to")}</Label>
            <DatePicker
              selected={getDateFromMinutesStartDay(data.minuteTo)}
              onChange={(date) =>
                onChange("minuteTo", getMinutesFromStartDay(date))
              }
              placeholderText={t("booking.timetable.labels.to") as string}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={10}
              timeCaption={t("app.schedule_time") as string}
              customInput={<CustomInput />}
              timeFormat="HH:mm"
              dateFormat="HH:mm"
              filterTime={(currentTime) => {
                const minuteFrom = data?.minuteFrom;
                if (minuteFrom) {
                  return (
                    currentTime >
                    (getDateFromMinutesStartDay(minuteFrom) as Date)
                  );
                }
                return true;
              }}
            />
          </InputWrapper>
          {typeof data.minuteTo !== "number" && (
            <ErrorStyled>{t("validation.field_required")}</ErrorStyled>
          )}
        </FormColumn>
      </RowWrapper>
      <FooterRowWrapper>
        <FormColumn>
          {!!cabinets.length && (
            <>
              <InputWrapper>
                <Label>{t("modals.patient_settings.cabinet")}</Label>
                <DropdownStyled
                  isShowOnlyPlaceholder={false}
                  value={data.roomId}
                  placeholder={t("map.cabinets.title")}
                  onSelect={(val) => onChange("roomId", val)}
                  options={cabinets}
                  label=""
                />
              </InputWrapper>
              {!data?.roomId?.length && (
                <ErrorStyled>{t("validation.field_required")}</ErrorStyled>
              )}
            </>
          )}
        </FormColumn>
      </FooterRowWrapper>
    </Wrapper>
  );
}

export default DaysOfWeekRowComponent;
