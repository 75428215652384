import React, { FunctionComponent } from "react";
import { Svg, SvgProps } from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <g clipPath="url(#clip0_1470_32918)">
      <path
        d="M14.2501 0.974976H9.7501C5.3626 1.87498 1.8751 5.32498 0.975098 9.74998V14.25C1.8751 18.675 5.3626 22.125 9.7501 23.025H14.2501C18.6751 22.125 22.1251 18.6375 23.0251 14.25V9.74998C22.1251 5.32498 18.6376 1.87498 14.2501 0.974976Z"
        fill="white"
      />
      <path
        d="M22.9421 14.625C23.1473 13.7651 23.2506 12.884 23.25 12C23.25 11.0963 23.1435 10.2173 22.9421 9.375H14.625V1.05788C13.7651 0.852743 12.884 0.749409 12 0.750003C11.0963 0.750003 10.2173 0.856503 9.375 1.05788V9.375H1.05788C0.852743 10.2349 0.749409 11.116 0.750003 12C0.750003 12.9038 0.856503 13.7828 1.05788 14.625H9.375V22.9421C10.2349 23.1473 11.116 23.2506 12 23.25C12.9038 23.25 13.7828 23.1435 14.625 22.9421V14.625H22.9421Z"
        fill="#CE1124"
      />
    </g>
    <defs>
      <clipPath id="clip0_1470_32918">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </Svg>
);

export default Icon;
