import { gql } from "@apollo/client";

export const GET_FIND_CLINIC_BY_ID = gql`
  query FindClinicById($schema: FindClinicByIdSchema!) {
    findClinicById(schema: $schema) {
      address
      city
      code
      companyId
      id
      name
      houseNumber
      index
      logoFileId
      licenseNumber
      geographicalCoordinates {
        latitude
        longitude
      }
      hiddenFromPatients
      phoneNumber
      description
      createdAt
      rooms {
        id
        clinicId
        name
      }
      workingDays {
        id
        clinicId
        day
        fromMinute
        toMinute
      }
    }
  }
`;
