import styled from "styled-components";

export const HeadingStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 79px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
`;
