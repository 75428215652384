import { gql } from "@apollo/client";

export const FIND_SERVICE_BY_ID = gql`
  query FindServiceById($schema: FindServiceByIdSchema!) {
    findServiceById(schema: $schema) {
      id
      name
      category {
        id
        name
      }
      description
      icd10Code
      icd9Code
      durationInMinutes
      price
      currencyCode
      hiddenFromPatients
      createdAt
      clinicRooms {
        id
        clinicId
        name
      }
      doctorIds
    }
  }
`;
