import { Box, LoadBlock, Wrapper } from "./styles";

function ScreenLoader() {
  return (
    <Box>
      <Wrapper>
        <LoadBlock>
          <div />
          <div />
          <div />
        </LoadBlock>
      </Wrapper>
    </Box>
  );
}

export default ScreenLoader;
