import { FC, useMemo, useState } from "react";
import DatePicker from "react-datepicker";

import { useTranslation } from "react-i18next";
import { EButtonVariants } from "src/UI/Button";
import { CalendarIcon, ChevronRightIcon } from "src/UI/Svg";
import { Divider } from "src/UI/Divider";
import SliderCarousel from "src/components/SliderCarousel/SliderCarousel";
import { Loading } from "src/UI/Loading";
import { addDays, format, formatISO } from "date-fns";
import { useBookingState } from "src/store/booking/hooks";
import { useMediaQuery } from "src/hooks/useMediaQuery";
import { breakpointMap } from "src/theme/base";
import { BOOKING_PATIENT_SLIDES_COUNT } from "src/constants/constants";
import { ComponentProps } from "./types";
import {
  ArrowLeftStyled,
  ArrowRightStyled,
  ButtonStyled,
  DateSelectionWrapStyled,
  FieldWrapStyled,
  InputWrapper,
  LoadingWrap,
  SliderWrapStyled,
} from "./styles";
import SliderItemComponent from "../SliderItemComponent/SliderItemComponent";

const DatesSelectionComponent: FC<ComponentProps> = ({
  availableDatesArray,
  availableSlotsLoading,
  dateFilter,
  selectedDate,
  setDateFilter,
  setSelectedDate,
}) => {
  const isTablet = useMediaQuery(breakpointMap.md);
  const { t } = useTranslation();
  const { currentService } = useBookingState();
  const [currentSlideIdx, setCurrentSlideIdx] = useState<number>(0);

  const handleSlideChange = (index: number) => {
    setCurrentSlideIdx(index);
    const newSlide = index + BOOKING_PATIENT_SLIDES_COUNT;
    if (
      newSlide >=
      availableDatesArray?.length - BOOKING_PATIENT_SLIDES_COUNT
    ) {
      setDateFilter((prev) => ({
        ...prev,
        dateTo: formatISO(addDays(new Date(dateFilter.dateTo), 30)),
      }));
    }
  };

  const settings = {
    arrows: true,
    dots: false,
    speed: 500,
    infinite: false,
    slidesToShow: BOOKING_PATIENT_SLIDES_COUNT,
    slidesToScroll: BOOKING_PATIENT_SLIDES_COUNT,
    afterChange: handleSlideChange,
    prevArrow: (
      <ArrowLeftStyled isDisable={currentSlideIdx === 0}>
        <ChevronRightIcon />
      </ArrowLeftStyled>
    ),
    nextArrow: (
      <ArrowRightStyled>
        {availableSlotsLoading ? (
          <Loading type="primary" padding="0" />
        ) : (
          <ChevronRightIcon />
        )}
      </ArrowRightStyled>
    ),
  };

  const slides = useMemo(() => {
    const sortedDates = [...availableDatesArray];
    sortedDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    return sortedDates?.map((item) => (
      <SliderItemComponent
        key={item}
        date={item}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
    ));
  }, [availableDatesArray, selectedDate, setDateFilter, currentService]);

  const handleChangeDate = (date: Date) => {
    setSelectedDate(format(new Date(date as Date), "yyyy-MM-dd"));
  };

  return (
    <>
      <DateSelectionWrapStyled>
        {!isTablet && (
          <FieldWrapStyled>
            <InputWrapper>
              <DatePicker
                selected={selectedDate ? new Date(selectedDate) : null}
                onChange={handleChangeDate}
                placeholderText={
                  t("constructor.work_time.labels.from") as string
                }
                minDate={new Date(Date.now())}
                customInput={
                  <ButtonStyled variant={EButtonVariants.Default} type="button">
                    <CalendarIcon width={16} height={16} />
                    {t("app.select_date")}
                  </ButtonStyled>
                }
                dateFormat="d  MMMM"
              />
            </InputWrapper>
          </FieldWrapStyled>
        )}
        <SliderWrapStyled key={currentService?.service?.id}>
          {!slides.length && availableSlotsLoading ? (
            <LoadingWrap>
              <Loading type="primary" padding="0" />
            </LoadingWrap>
          ) : (
            <SliderCarousel settings={settings} slides={slides} />
          )}
        </SliderWrapStyled>
      </DateSelectionWrapStyled>
      <Divider margin="20px 0" />
    </>
  );
};

export default DatesSelectionComponent;
