import { gql } from "@apollo/client";

export const FIND_MANY_SCHEDULE_APPOINTMENT_UPDATES_BY_ID_BY_CLIENT = gql`
  query FindManyScheduleAppointmentUpdatesByIdByClient(
    $schema: FindManyScheduleAppointmentUpdatesByIdByClientSchema!
  ) {
    findManyScheduleAppointmentUpdatesByIdByClient(schema: $schema) {
      appointmentUpdates {
        appointmentId
        createdAt
        id
        original {
          createdAt
          patientId
          scheduleId
          status
          updatedAt
        }
        updated {
          createdAt
          patientId
          status
          updatedAt
          scheduleId
        }
        userId
      }
    }
  }
`;
