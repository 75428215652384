import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  useEnable2FaMutation,
  useGenerate2FaMutation,
} from "src/graphql/generated";
import { toast } from "react-toastify";
import { PATHS } from "src/constants/constants";
import { useAuthContext } from "src/context/AuthContext";
import { Loading } from "src/UI/Loading";
import { ChevronIcon, LogoFullIcon } from "src/UI/Svg";
import { Text } from "src/UI/Text";
import { Heading } from "src/UI/Heading";
import { InputComponent } from "src/components/AuthComponents";
import { EButtonVariants } from "src/UI/Button";

import {
  Auts,
  Breadcrumb,
  ButtonStyled,
  FooterLinkRight,
  FooterLinks,
  FormFA,
  FormWrapper,
  StiledQR,
  TitleBlock,
  WrapperNav,
} from "../AuthPages/styled";

export const TextCenter = styled(Text)`
  text-align: center;
  width: 100%;
`;

interface FormValues {
  code: string;
}

function Create2FAPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [secret, setSecret] = useState("");
  const [QRcode, setQRCode] = useState("");
  const { logout } = useAuthContext();

  const [enable2FA, { loading }] = useEnable2FaMutation({
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
    onCompleted: async (response) => {
      if (response) {
        navigate(`${PATHS.cp}${PATHS.map}`);
      }
    },
  });
  const [generate2FAMutation] = useGenerate2FaMutation({
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
    onCompleted: (response) => {
      if (response) {
        setSecret(response.generate2FA.secret);
        setQRCode(response.generate2FA.qrCodeSVG);
      }
    },
  });

  useEffect(() => {
    generate2FAMutation();
  }, []);

  const {
    register,
    formState: { errors, isValid },
    watch,
    handleSubmit,
  } = useForm<FormValues>({
    mode: "onChange",
  });

  const data = watch();

  const formHandler = () => {
    enable2FA({ variables: { schema: { code: data.code } } });
  };

  const goHome = () => {
    navigate(`${PATHS.cp}${PATHS.dashboard}`);
  };

  return (
    <>
      <WrapperNav onClick={goHome}>
        <LogoFullIcon />
      </WrapperNav>
      <FormWrapper>
        <Breadcrumb to={`${PATHS.auth}${PATHS.login}`} onClick={logout}>
          <ChevronIcon />
          <Text>{t("auth.fa.back")}</Text>
        </Breadcrumb>
        <FormFA onSubmit={handleSubmit(formHandler)}>
          <TitleBlock>
            <Heading as="h2">{t("auth.create2fa.title")}</Heading>
          </TitleBlock>
          <InputComponent
            placeholder={t("auth.create2fa.code")}
            inputType="text"
            val={data.code}
            errorMessage={errors.code}
            register={register("code", {
              required: true,
              minLength: {
                value: 4,
                message: t("auth.create2fa.minLengthError"),
              },
            })}
          />
          <Text>{t("auth.create2fa.describe")}</Text>
          <Text>{t("auth.create2fa.choose")}</Text>
          <Auts>
            <a
              href="https://apps.apple.com/us/app/google-authenticator/id388497605"
              target="_blank"
              rel="noreferrer"
            >
              <div>{t("auth.create2fa.authenticators.google.name")}</div>
              <div>{t("auth.create2fa.authenticators.google.descios")}</div>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
              target="_blank"
              rel="noreferrer"
            >
              <div>{t("auth.create2fa.authenticators.google.name")}</div>
              <div>{t("auth.create2fa.authenticators.google.descandroid")}</div>
            </a>
          </Auts>
          {QRcode && <StiledQR dangerouslySetInnerHTML={{ __html: QRcode }} />}
          <TextCenter>{secret}</TextCenter>
          <ButtonStyled
            variant={EButtonVariants.Default}
            disabled={!isValid || loading}
          >
            {loading ? <Loading type="secondary" padding="0" /> : null}
            {t("auth.create2fa.submit")}
          </ButtonStyled>
        </FormFA>
      </FormWrapper>
      <FooterLinks>
        <FooterLinkRight to={`${PATHS.cp}${PATHS.map}`}>
          {t("auth.create2fa.postpone")}
        </FooterLinkRight>
      </FooterLinks>
    </>
  );
}
export default Create2FAPage;
