import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "src/UI/Modal";
import { useAppAction, useAppState } from "src/store/app/hooks";
import { EModals } from "src/store/app/types";
import { FormProvider, useForm } from "react-hook-form";

import { addPatientSchema } from "src/validation/schemas/addPatientSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCreatePatient } from "src/graphql/hooks/useMutations";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { PATHS } from "src/constants/constants";
import { useAuthContext } from "src/context/AuthContext";
import { usePatientsAction } from "src/store/patients/hooks";
import { useLocation } from "react-router-dom";
import {
  GET_FIND_AND_COUNT_MANY_PATIENTS_BY_CLIENT,
  GET_FIND_AND_COUNT_MANY_PATIENTS_BY_DOCTOR,
} from "src/graphql/queries";
import { LanguageIsoCodes } from "src/graphql/generated";
import AddPatientModalForm from "./AddPatientModalForm";
import { Form } from "./styles";

export interface FormValues {
  first_name: string;
  last_name: string;
  birth: Date | null;
  gender: string;
  email: string | null;
  provider: string;
  description: string;
  phone: string;
  isEmail: boolean;
  isSms: boolean;
  passportNumber: string | null;
}

function AddPatientModal() {
  const { t, i18n } = useTranslation();
  const { modals } = useAppState();
  const { pathname } = useLocation();

  const { isDoctor } = useAuthContext();
  const queryByRole = isDoctor
    ? GET_FIND_AND_COUNT_MANY_PATIENTS_BY_DOCTOR
    : GET_FIND_AND_COUNT_MANY_PATIENTS_BY_CLIENT;

  const { onCloseModal } = useAppAction();
  const { onSetPatient } = usePatientsAction();

  const [addPatient, { loading: addPatientLoading }] = useCreatePatient();

  const methods = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(addPatientSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      birth: null,
      gender: "",
      email: null,
      provider: "",
      description: "",
      phone: "",
      isEmail: false,
      isSms: true,
      passportNumber: null,
    },
  });

  const { handleSubmit, reset } = methods;

  const closeModal = useCallback(() => {
    onCloseModal(EModals.AddPatient);
  }, [onCloseModal]);

  const formHandler = (formData: FormValues) => {
    addPatient({
      variables: {
        schema: {
          firstName: formData.first_name,
          lastName: formData.last_name,
          phoneNumber: `+${formData.phone}`,
          smsDistribution: formData.isSms,
          emailDistribution: formData.isEmail,
          languageISOCode: i18n.language.toUpperCase() as LanguageIsoCodes,
          ...(formData.description
            ? {
                comment: formData.description,
              }
            : {
                comment: null,
              }) /* not required */,
          ...(formData.birth && {
            dateOfBirth: format(formData.birth, "yyyy-MM-dd"),
          }) /* not required */,
          ...(formData.email
            ? {
                email: formData.email,
              }
            : {
                email: null,
              }) /* not required */,
          ...(formData.gender && {
            gender: formData.gender,
          }) /* not required */,
          ...(formData.passportNumber && {
            passportNumber: formData.passportNumber,
          }) /* not required */,
        },
      },
      onCompleted(response) {
        if (pathname.includes(PATHS.addVisit)) {
          onSetPatient(response.createPatient);
        }
        toast.success(t("app.successful"));
        onCloseModal(EModals.AddPatient);
        reset();
      },
      refetchQueries: [queryByRole],
    });
  };

  if (!modals[EModals.AddPatient]) return null;
  return (
    <Modal modal={EModals.AddPatient} title={t("patients.add.title")}>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(formHandler)}>
          <AddPatientModalForm
            onCloseModal={closeModal}
            loading={addPatientLoading}
          />
        </Form>
      </FormProvider>
    </Modal>
  );
}

export default AddPatientModal;
