import { useState } from "react";
import { toast } from "react-toastify";
import { allClinicsItemId } from "src/constants/constants";
import {
  ClinicRoomEntityResponse,
  useFindManyClinicRoomsByClinicIdQuery,
} from "src/graphql/generated";
import { useClinicsState } from "src/store/clinics/hooks";

export function useCabinetsList() {
  const { currentClinicId: clinicId } = useClinicsState();
  const [cabinets, setCabinets] = useState<ClinicRoomEntityResponse[]>([]);

  useFindManyClinicRoomsByClinicIdQuery({
    variables: {
      schema: {
        clinicId,
      },
    },
    onCompleted(data) {
      if (data?.findManyClinicRoomsByClinicId) {
        const { rooms } = data.findManyClinicRoomsByClinicId;

        setCabinets(rooms);
      }
    },
    skip: !clinicId || clinicId === allClinicsItemId,
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
  });

  return {
    cabinetsList: cabinets,
  };
}
