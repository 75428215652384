import { forwardRef, useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { CheckBox } from "src/UI/Input";
import { ClinicWorkingDays } from "src/graphql/generated";
import { useParams } from "react-router-dom";
import {
  useCreateClinicWorkingDay,
  useDeleteClinicWorkingDayById,
  useUpdateClinicWorkingDaysByClinicId,
} from "src/graphql/hooks/useMutations";
import { toast } from "react-toastify";
import { useAppAction } from "src/store/app/hooks";
import { useFormContext } from "react-hook-form";
import {
  formatDateToDateISO,
  getDateFromMinutesStartDay,
  getMinutesFromStartOfDayWithoutTimeZoneOffset,
} from "src/utils";
import {
  DateInputStyled,
  ErrorStyled,
  FieldWrapStyled,
  InputWrapper,
  Label,
  Left,
  Right,
  Row,
} from "./styled";

export interface IScheduleDay {
  day: ClinicWorkingDays;
  id?: string;
  fromMinute?: number;
  toMinute?: number;
}

const CustomInput = forwardRef(
  ({ value, onClick, placeholder }: any, ref: any) => (
    <DateInputStyled onClick={onClick} ref={ref}>
      {value || placeholder || ""}
    </DateInputStyled>
  )
);

function RowDayInput({
  title,
  day,
}: {
  title: string;
  day: ClinicWorkingDays;
}) {
  const { t } = useTranslation();
  const [delDay] = useDeleteClinicWorkingDayById();
  const [addDay] = useCreateClinicWorkingDay();
  const [updateDay] = useUpdateClinicWorkingDaysByClinicId();

  const { onConfirmOpen } = useAppAction();
  const { clinicId } = useParams();
  const { watch, setValue } = useFormContext();
  const schedule = watch("schedule");

  const currentDay = useMemo(() => {
    if (!schedule) return null;

    return schedule.find((item: IScheduleDay) => item.day === day);
  }, [schedule, day, clinicId]);

  const isCurrentDayFromResponse = !!currentDay?.id;

  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [time, setTime] = useState<{
    fromMinute: Date | null;
    toMinute: Date | null;
  }>({
    fromMinute: null,
    toMinute: null,
  });

  useEffect(() => {
    if (currentDay) {
      setIsChecked(!!currentDay);
      if (typeof currentDay?.fromMinute === "number") {
        setTime((prev) => ({
          ...prev,
          fromMinute: getDateFromMinutesStartDay(currentDay?.fromMinute),
        }));
      }
      if (typeof currentDay?.toMinute === "number") {
        setTime((prev) => ({
          ...prev,
          toMinute: getDateFromMinutesStartDay(currentDay?.toMinute),
        }));
      }
    } else {
      setTime({
        toMinute: null,
        fromMinute: null,
      });
      setIsChecked(false);
    }
  }, [currentDay]);

  useEffect(() => {
    if (!isChecked && !isCurrentDayFromResponse) {
      setValue(
        "schedule",
        schedule.filter((item: IScheduleDay) => item.day !== day)
      );
    }

    if (!isCurrentDayFromResponse && isChecked) {
      setValue("schedule", [...schedule, { day }]);
    }

    if (isCurrentDayFromResponse && !isChecked) {
      setIsChecked(true);
      onConfirmOpen({
        accept: () => {
          delDay({
            variables: {
              schema: {
                id: currentDay?.id,
              },
            },
            onCompleted: () => {
              toast.success(t("app.successful"));
              setValue(
                "schedule",
                schedule.filter((item: IScheduleDay) => item.day !== day)
              );
              setTime({
                fromMinute: null,
                toMinute: null,
              });
              setIsChecked(false);
            },
          });
        },
      });
    }
  }, [isChecked]);

  const onChange = (key: string, val: Date) => {
    setTime((prevState) => ({
      ...prevState,
      [key]: val,
    }));

    if (
      ((key === "toMinute" && time.fromMinute) ||
        (key === "fromMinute" && time.toMinute)) &&
      !isCurrentDayFromResponse
    ) {
      addDay({
        variables: {
          schema: {
            clinicId,
            day,
            toMinute: getMinutesFromStartOfDayWithoutTimeZoneOffset(
              formatDateToDateISO(time.toMinute || val)
            ),
            fromMinute: getMinutesFromStartOfDayWithoutTimeZoneOffset(
              formatDateToDateISO(time.fromMinute || val)
            ),
          },
        },
        onCompleted: (response) => {
          setValue(
            "schedule",
            schedule.map((item: IScheduleDay) => {
              if (item.day === day) {
                return {
                  ...item,
                  fromMinute: response?.createClinicWorkingDay?.fromMinute,
                  toMinute: response?.createClinicWorkingDay?.toMinute,
                  id: response?.createClinicWorkingDay?.id,
                };
              }
              return item;
            })
          );
          toast.success(t("app.successful"));
        },
      });
    }

    if (time.fromMinute && time.toMinute && isCurrentDayFromResponse) {
      updateDay({
        variables: {
          schema: {
            clinicId,
            workingDays: schedule.map((item: IScheduleDay) => {
              if (item.day === day) {
                return {
                  ...item,
                  toMinute: getMinutesFromStartOfDayWithoutTimeZoneOffset(
                    formatDateToDateISO(
                      key === "toMinute" ? val : (time.toMinute as Date)
                    )
                  ),
                  fromMinute: getMinutesFromStartOfDayWithoutTimeZoneOffset(
                    formatDateToDateISO(
                      key === "fromMinute" ? val : (time.fromMinute as Date)
                    )
                  ),
                };
              }

              return item;
            }),
          },
        },
        onCompleted: () => {
          setValue(
            "schedule",
            schedule.map((item: IScheduleDay) => {
              if (item.day === day) {
                return {
                  ...item,
                  toMinute: getMinutesFromStartOfDayWithoutTimeZoneOffset(
                    formatDateToDateISO(
                      key === "toMinute" ? val : (time.toMinute as Date)
                    )
                  ),
                  fromMinute: getMinutesFromStartOfDayWithoutTimeZoneOffset(
                    formatDateToDateISO(
                      key === "fromMinute" ? val : (time.fromMinute as Date)
                    )
                  ),

                  id: currentDay?.id,
                };
              }
              return item;
            })
          );
          toast.success(t("app.successful"));
        },
      });
    }
  };

  const handleChangeCheckbox = () => {
    setIsChecked((prev) => !prev);
  };

  return (
    <Row>
      <Left>
        <CheckBox value={isChecked} onChange={handleChangeCheckbox} id="" />
        {title}
      </Left>
      <Right isChecked={isChecked}>
        <FieldWrapStyled>
          <InputWrapper>
            <Label>{t("constructor.work_time.labels.from")}</Label>
            <DatePicker
              disabled={!isChecked}
              selected={time.fromMinute}
              onChange={(date) => onChange("fromMinute", date as Date)}
              showTimeSelect
              showTimeSelectOnly
              placeholderText={t("constructor.work_time.labels.from") as string}
              timeIntervals={5}
              timeCaption="Time"
              customInput={<CustomInput />}
              timeFormat="HH:mm"
              dateFormat="HH:mm"
              filterTime={(currentTime) =>
                time?.toMinute ? currentTime < time?.toMinute : true
              }
            />
          </InputWrapper>
          {isChecked && !time.fromMinute && (
            <ErrorStyled>{t("validation.field_required")}</ErrorStyled>
          )}
        </FieldWrapStyled>

        <FieldWrapStyled>
          <InputWrapper>
            <Label>{t("constructor.work_time.labels.to")}</Label>
            <DatePicker
              disabled={!isChecked}
              selected={time.toMinute}
              onChange={(date) => onChange("toMinute", date as Date)}
              showTimeSelect
              showTimeSelectOnly
              placeholderText={t("constructor.work_time.labels.to") as string}
              timeIntervals={5}
              timeCaption={t("app.schedule_time") as string}
              customInput={<CustomInput />}
              timeFormat="HH:mm"
              dateFormat="HH:mm"
              filterTime={(currentTime) =>
                time.fromMinute ? currentTime > time.fromMinute : true
              }
            />
          </InputWrapper>
          {isChecked && !time.toMinute && (
            <ErrorStyled>{t("validation.field_required")}</ErrorStyled>
          )}
        </FieldWrapStyled>
      </Right>
    </Row>
  );
}

export default RowDayInput;
