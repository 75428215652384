import { Button } from "src/UI/Button";
import { Heading } from "src/UI/Heading";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 5px;
  padding: 20px 20px 0;
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 16px 16px 0;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const HeadingStyled = styled(Heading)`
  font-size: 24px;
  line-height: 100%;
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 20px;
  }
`;

export const ButtonStyled = styled(Button)`
  padding: 8px 10px;
  font-size: 16px;
  line-height: 100%;
  font-weight: 700;
  width: fit-content;
  border-radius: 5px;
  white-space: nowrap;
`;

export const Tabs = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;

export const Tab = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  padding-bottom: 16px;
  color: ${({ theme, isActive }) => theme.colors[isActive ? "green" : "black"]};
  border-bottom: 1px solid
    ${({ theme, isActive }) => (isActive ? theme.colors.green : "transparent")};
  cursor: pointer;
  span {
    min-width: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2px 4.5px;
    background: ${({ theme, isActive }) =>
      theme.colors[isActive ? "green" : "grayC4"]};
    border-radius: 800px;
    color: ${({ theme }) => theme.colors.white};
  }
`;
