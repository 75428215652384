import { useEffect, useMemo, useState } from "react";

import { IEvent } from "src/pages/DashboardPages/CalendarPage/types";
import { useCalendarAction } from "src/store/calendar/hooks";
import {
  endOfDay,
  format,
  formatISO,
  isToday,
  isWithinInterval,
  startOfDay,
} from "date-fns";
import {
  AppointmentStatuses,
  ClinicWorkingDayEntityResponse,
  useFindClinicByIdQuery,
} from "src/graphql/generated";
import { useClinicsState } from "src/store/clinics/hooks";
import { toast } from "react-toastify";
import { getDateFromMinutesStartDay } from "src/utils";
import { allClinicsItemId } from "src/constants/constants";
import { useCalendarScheduleAppointmentsByClientEffect } from "./useCalendarScheduleAppointmentsByClientEffect";
import { useCalendarScheduleAppointmentsByDoctorEffect } from "./useCalendarScheduleAppointmentsByDoctorEffect";
import { useSetDoctorsGroupByEventsListEffect } from "./useSetDoctorsGroupByEventsListEffect";

export function useCalendarScheduleAppointmentsByRole() {
  const [events, setEvents] = useState<IEvent[]>([]);
  const { currentClinicId: clinicId } = useClinicsState();
  const { onSetDoctorsGroup, onSetCabinetsGroup, onSetFilters } =
    useCalendarAction();

  const { data } = useFindClinicByIdQuery({
    variables: { schema: { id: clinicId } },
    skip: !clinicId || clinicId === allClinicsItemId,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
    fetchPolicy: "network-only",
  });

  const isEventWithinClinicSchedule = (
    event: IEvent,
    workingDays: ClinicWorkingDayEntityResponse[]
  ) => {
    const { start, end } = event;
    const isWithinWorkingDays = workingDays?.some((clinicDayObject) => {
      const isCurrentDay = clinicDayObject.day === format(start, "EEEE");

      const clinicStart = getDateFromMinutesStartDay(
        clinicDayObject.fromMinute,
        start
      ) as Date;

      const clinicEnd = getDateFromMinutesStartDay(
        clinicDayObject.toMinute,
        start
      ) as Date;

      const today = isToday(start);
      const now = new Date();

      const clinicInterval = {
        start: now >= clinicStart && today ? now : clinicStart,
        end: clinicEnd,
      };

      return (
        isCurrentDay &&
        clinicInterval.start < clinicInterval.end &&
        isWithinInterval(start, clinicInterval) &&
        isWithinInterval(end, clinicInterval)
      );
    });

    return isWithinWorkingDays;
  };

  const filteredByClinicScheduleEvents = useMemo(() => {
    if (data?.findClinicById) {
      const { workingDays } = data.findClinicById;

      const filteredEvents = events?.filter(
        (event) =>
          isEventWithinClinicSchedule(
            event,
            workingDays as ClinicWorkingDayEntityResponse[]
          ) && event?.eventData?.status !== AppointmentStatuses.Canceled
      );

      return filteredEvents;
    }
    return [];
  }, [data, events]);

  useSetDoctorsGroupByEventsListEffect(filteredByClinicScheduleEvents);
  useCalendarScheduleAppointmentsByClientEffect(setEvents);
  useCalendarScheduleAppointmentsByDoctorEffect(setEvents);

  useEffect(() => {
    return () => {
      onSetDoctorsGroup([]);
      onSetCabinetsGroup([]);
      onSetFilters({
        dateFrom: formatISO(startOfDay(new Date())),
        dateTo: formatISO(endOfDay(new Date())),
        roomIds: [],
        doctorIds: [],
        patientId: "",
        status: "",
      });
    };
  }, []);

  return {
    events: filteredByClinicScheduleEvents,
  };
}
