import styled from "styled-components";

export const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;

  span {
    width: 8px;
    min-width: 8px;
    height: 8px;
    display: flex;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.green};
  }
`;

export const Date = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 1.1;
  color: ${({ theme }) => theme.colors.green};
`;

export const User = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.1;
  color: ${({ theme }) => theme.colors.darkGrey};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
