import { gql } from "@apollo/client";

export const FIND_PATIENT_BY_ID = gql`
  query FindPatientById($schema: FindPatientByIdSchema!) {
    findPatientById(schema: $schema) {
      id
      phoneNumber
      firstName
      lastName
      email
      dateOfBirth
      gender
      companyId
      comment
      emailDistribution
      smsDistribution
      activated
      passportNumber
      createdAt
      status
      allergic
      allergens
      languageISOCode
    }
  }
`;
