import styled from "styled-components";

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

export const InputBlock = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;
`;

// padding: ${({isFocused}) => isFocused ? '20px' : '15px'} 0 15px;
// font-size: ${({isFocused}) => isFocused ? '10px' : '12px'} !important;
// ${({theme}) => theme.mediaQueries.ll} {
//     font-size: 12px;
// }

interface IInputStyledProps {
  isIconLeft: boolean;
  $hasError: boolean;
}

export const InputStyled = styled.textarea<IInputStyledProps>`
  width: 100%;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  resize: none;
  color: ${({ theme }) => theme.colors.black};
  border-bottom: 1px solid
    ${({ theme, $hasError }) =>
      $hasError ? theme.colors.red : theme.colors.borderInputDefault};
  padding: 20px 0 15px 0;

  ${({ isIconLeft }) => (isIconLeft ? "padding-left: 32px;" : "")}
  &:focus {
    border-color: ${({ theme }) => theme.colors.green};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray};
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Label = styled.label<{ isFocused: boolean }>`
  color: ${({ isFocused, theme }) =>
    theme.colors[isFocused ? "grayC4" : "gray"]};
  position: absolute;
  top: ${({ isFocused }) => (isFocused ? "0" : "50%")};
  transform: translateY(-50%);
  left: 0;
  font-weight: 500;
  font-size: ${({ isFocused }) => (isFocused ? "12px" : "16px")};
  line-height: ${({ isFocused }) => (isFocused ? "100%" : "140%")};
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

export const IconBlock = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`;

export const IconPassword = styled(IconWrapper)<{ isShow: boolean }>`
  cursor: pointer;
  svg path {
    stroke: ${({ theme, isShow }) => theme.colors[isShow ? "green" : "gray"]};
  }
`;

export const ErrorMessage = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: right;
  color: ${({ theme }) => theme.colors.red};
  position: absolute;
  top: 0;
  right: 0;
`;
