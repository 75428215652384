import { FC } from "react";
import { DoctorIcon } from "src/UI/Svg";
import {
  DoctorTypeEntityResponse,
  FileEntityResponse,
} from "src/graphql/generated";
import { Loading } from "src/UI/Loading";
import {
  DoctorInfoWrapStiled,
  DoctorNameStyled,
  DoctorSpecialisationStyled,
  IconWrapper,
  ImageStyled,
} from "./styles";
import { ComponentProps } from "./types";

const DoctorInfoComponent: FC<ComponentProps> = ({
  doctor,
  doctorTypesData,
  logoFilesArray,
  logoFielsLoading,
}) => {
  const getSpecialisation = (specialisationTypeId: string) => {
    const specialisation: DoctorTypeEntityResponse | undefined =
      doctorTypesData?.findManyDoctorTypesByLanguageISOCode?.doctorTypes.find(
        (type) => type.id === specialisationTypeId
      );
    return specialisation?.doctorTypeTranslations[0]?.name;
  };

  const getDoctorLogoFile = (id: string) => {
    const doctorLogoFile = logoFilesArray?.find(
      (file: FileEntityResponse) => file.id === id
    );
    return doctorLogoFile?.url;
  };

  return (
    <>
      <IconWrapper>
        {logoFielsLoading ? (
          <Loading type="primary" padding="0" />
        ) : doctor?.avatarFileId ? (
          <ImageStyled
            src={getDoctorLogoFile(doctor?.avatarFileId)}
            alt={`${doctor.firstName} ${doctor.lastName}`}
          />
        ) : (
          <DoctorIcon />
        )}
      </IconWrapper>
      <DoctorInfoWrapStiled>
        <DoctorNameStyled>{`${doctor.firstName} ${doctor.lastName}`}</DoctorNameStyled>
        <DoctorSpecialisationStyled>
          {getSpecialisation(doctor.doctorTypeId)}
        </DoctorSpecialisationStyled>
      </DoctorInfoWrapStiled>
    </>
  );
};

export default DoctorInfoComponent;
