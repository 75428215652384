import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { ESupportedLangs } from "./types/enum";

export const language = i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: [ESupportedLangs.pl, ESupportedLangs.en],
    fallbackLng: [ESupportedLangs.pl, ESupportedLangs.en],
    debug: false,
    defaultNS: "translation",
    ns: "translation",
    interpolation: {
      escapeValue: false,
    },
  });
