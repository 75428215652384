import { Button } from "src/UI/Button";
import { Dropdown } from "src/UI/Dropdown";
import styled from "styled-components";

export const Form = styled.form`
  display: contents;
`;

export const UserOptionStyled = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  gap: 4px;
  svg:first-child {
    margin-right: 6px;
    path {
      fill: ${({ theme }) => theme.colors.gray};
      stroke: ${({ theme }) => theme.colors.gray};
    }
  }
`;

export const Content = styled.div`
  position: relative;
  padding: 20px;
  width: 635px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 20px;
`;
// TODO commeted because it media query maybe be needs in the future
// ${({ theme }) => theme.mediaQueries.ll} {
//   padding: 16px;
//   gap: 16px;
// }

export const ActionBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
`;

export const ButtonStyled = styled(Button)`
  width: fit-content;
  font-size: 20px;
  line-height: 100%;
  padding: 15px 20px;
`;
// TODO commeted because it media query maybe be needs in the future
// ${({ theme }) => theme.mediaQueries.ll} {
//   padding: 12px 16px;
//   font-size: 16px;
// }

export const Text = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.black};
  span {
    color: ${({ theme }) => theme.colors.gray};
  }
`;
// TODO commeted because it media query maybe be needs in the future
// ${({ theme }) => theme.mediaQueries.ll} {
//   font-size: 12px;
// }

export const DropdownStyled = styled(Dropdown)`
  border-radius: 5px;
  min-width: 255px;
  & > div:first-child {
    padding: 8px;
  }
`;

export const ErrorMessage = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: right;
  color: ${({ theme }) => theme.colors.red};
  position: absolute;
  top: 0;
  right: 20px;
`;
