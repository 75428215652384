import React from "react";
import { useTranslation } from "react-i18next";

import AvatarComponent from "src/components/AvatarComponent/AvatarComponent";
import { TScheduleWaitingAppointment } from "src/types";
import {
  AggregatedScheduleWaitlistEntityByClientResponse,
  WaitlistStatuses,
} from "src/graphql/generated";
import { useNavigate } from "react-router-dom";
import { PATHS } from "src/constants/constants";
import { SelectedRescheduleWaitlist } from "src/store/booking/types";
import { getDateFromMinutesStartDay } from "src/utils";
import { parse, set } from "date-fns";
import ScheduleAppointmentUpdateStatusComponent from "./ScheduleUpdatedStatusComponent";
import {
  CardHeader,
  CardPatientStyled,
  DoctorOptInfoWrap,
  DoctorWrapperRow,
  DotSeparator,
  OptItemSubTitle,
  OptItemTitle,
  PatientContent,
} from "../styled";

interface IPatientInfoCardComponent {
  appointment: TScheduleWaitingAppointment;
  isClosedStatus: boolean;
  onSetRescheduleWaitlist: (
    rescheduleAppointment: SelectedRescheduleWaitlist
  ) => void;
  closeModal: () => void;
}

function PatientInfoCardComponent({
  appointment,
  isClosedStatus,
  onSetRescheduleWaitlist,
  closeModal,
}: IPatientInfoCardComponent) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const setAppointmentToStore = () => {
    onSetRescheduleWaitlist(
      appointment as AggregatedScheduleWaitlistEntityByClientResponse
    );
    navigate(`${PATHS.cp}${PATHS.booking}${PATHS.settings}${PATHS.addVisit}`);
    closeModal();
  };

  const isEndDateAlreadyPassed = () => {
    const firstDate = getDateFromMinutesStartDay(appointment?.minuteTo);
    const secondDate = appointment?.dateTo;

    const parsedSecondDate = parse(
      secondDate as string,
      "yyyy-MM-dd",
      new Date()
    );

    const combinedDate = set(parsedSecondDate, {
      hours: firstDate?.getHours(),
      minutes: firstDate?.getMinutes(),
      seconds: firstDate?.getSeconds(),
      milliseconds: firstDate?.getMilliseconds(),
    });

    return combinedDate < new Date();
  };

  return (
    <CardPatientStyled
      title={t("history.patient")}
      headerChildren={
        <CardHeader>{appointment?.patient.passportNumber}</CardHeader>
      }
    >
      <PatientContent>
        <DoctorWrapperRow>
          <AvatarComponent
            firstName={appointment?.patient.firstName as string}
            lastName={appointment?.patient.lastName as string}
            size={56}
          />
          <DoctorOptInfoWrap>
            <OptItemTitle>{`${appointment?.patient.firstName} ${appointment?.patient.lastName}`}</OptItemTitle>
            {(!!appointment?.patient.phoneNumber ||
              !!appointment?.patient.email) && (
              <OptItemSubTitle>
                {appointment?.patient.phoneNumber}
                {!!appointment?.patient.phoneNumber &&
                  !!appointment?.patient.email && <DotSeparator />}
                {appointment?.patient.email}
              </OptItemSubTitle>
            )}
          </DoctorOptInfoWrap>
        </DoctorWrapperRow>

        {!isClosedStatus && !isEndDateAlreadyPassed() ? (
          <ScheduleAppointmentUpdateStatusComponent
            id={appointment?.id as string}
            setAppointmentToStore={setAppointmentToStore}
            status={appointment?.status as WaitlistStatuses}
          />
        ) : null}
      </PatientContent>
    </CardPatientStyled>
  );
}
export default PatientInfoCardComponent;
