import React, { FunctionComponent } from "react";
import { Svg, SvgProps } from "..";

const MapPin: FunctionComponent<SvgProps> = (props) => (
  <Svg
    width="80"
    height="86"
    viewBox="0 0 80 86"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_744_52319)">
      <path
        d="M53.3327 34.6666C53.3327 44.6666 39.9993 54.6666 39.9993 54.6666C39.9993 54.6666 26.666 44.6666 26.666 34.6666C26.666 31.1304 28.0708 27.739 30.5713 25.2385C33.0717 22.738 36.4631 21.3333 39.9993 21.3333C43.5356 21.3333 46.927 22.738 49.4274 25.2385C51.9279 27.739 53.3327 31.1304 53.3327 34.6666Z"
        fill="#0BA495"
        stroke="#0BA495"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40 39.6667C42.7614 39.6667 45 37.4282 45 34.6667C45 31.9053 42.7614 29.6667 40 29.6667C37.2386 29.6667 35 31.9053 35 34.6667C35 37.4282 37.2386 39.6667 40 39.6667Z"
        fill="white"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_744_52319"
        x="-5"
        y="-2"
        width="90"
        height="90"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="5" />
        <feGaussianBlur stdDeviation="12.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_744_52319"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_744_52319"
          result="shape"
        />
      </filter>
    </defs>
  </Svg>
);

export default MapPin;
