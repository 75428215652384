import { useEffect, useState } from "react";

export const useMediaQuery = (breakpoint: any) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setMatches(window.matchMedia(`(max-width: ${breakpoint}px)`).matches);
    };

    handleResize(); // Set the initial value when loading the page

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [breakpoint]);

  return matches;
};
