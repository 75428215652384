import React, { FunctionComponent } from "react";
import { Svg, SvgProps } from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
  <Svg width="220" height="55" viewBox="0 0 220 55" fill="none" {...props}>
    <path
      d="M37.4894 53.5531C42.7177 53.5531 46.0046 50.8113 46.0046 46.724V29.1827H41.7641V32.2482H41.6619C40.64 30.1705 38.6645 28.8081 36.11 28.8081C31.5459 28.8081 28.5996 32.3163 28.5996 38.0215V38.0385C28.5996 43.6585 31.5118 47.1327 36.0419 47.1327C38.6305 47.1327 40.6741 45.9065 41.6789 43.9821H41.7641V46.5877C41.7641 48.8868 40.2484 50.3514 37.4894 50.3514C35.2755 50.3514 33.8279 49.568 33.3681 48.5292L33.317 48.3929H29.1446L29.1786 48.5462C29.6896 51.3733 32.7039 53.5531 37.4894 53.5531ZM37.3702 43.8629C34.6454 43.8629 32.9253 41.666 32.9253 38.1066V38.0896C32.9253 34.5814 34.6454 32.3844 37.3702 32.3844C39.9929 32.3844 41.7811 34.6154 41.7811 38.1066V38.1237C41.7811 41.6319 40.0099 43.8629 37.3702 43.8629Z"
      fill="#0BA495"
    />
    <path
      d="M56.7684 47.5925C62.0649 47.5925 65.505 44.0672 65.505 38.2088V38.1748C65.505 32.3504 62.0138 28.8081 56.7514 28.8081C51.5061 28.8081 48.0319 32.3844 48.0319 38.1748V38.2088C48.0319 44.0502 51.455 47.5925 56.7684 47.5925ZM56.7855 44.1694C54.0777 44.1694 52.3576 42.0066 52.3576 38.2088V38.1748C52.3576 34.4281 54.1117 32.2482 56.7514 32.2482C59.4422 32.2482 61.1793 34.4111 61.1793 38.1748V38.2088C61.1793 41.9895 59.4592 44.1694 56.7855 44.1694Z"
      fill="#0BA495"
    />
    <path
      d="M79.2009 47.5584C83.0667 47.5584 85.9108 45.1231 86.4387 41.8192L86.4558 41.683H84.2248L84.1907 41.8192C83.6628 44.0331 81.8406 45.5488 79.2009 45.5488C75.7607 45.5488 73.4616 42.858 73.4616 38.3961V38.3791C73.4616 33.9852 75.7267 31.2093 79.1838 31.2093C81.9427 31.2093 83.6969 32.8612 84.1907 34.9389L84.2248 35.0752H86.4387L86.4217 34.9219C85.9789 31.8735 83.3052 29.1997 79.1838 29.1997C74.3302 29.1997 71.1455 32.7761 71.1455 38.345V38.362C71.1455 43.9991 74.2791 47.5584 79.2009 47.5584Z"
      fill="#3D3D3D"
    />
    <path
      d="M88.7215 47.2348H90.9695V22.6601H88.7215V47.2348Z"
      fill="#3D3D3D"
    />
    <path
      d="M95.6194 26.3216C96.4369 26.3216 97.1011 25.6574 97.1011 24.8229C97.1011 23.9884 96.4369 23.3413 95.6194 23.3413C94.785 23.3413 94.1208 23.9884 94.1208 24.8229C94.1208 25.6574 94.785 26.3216 95.6194 26.3216ZM94.4784 47.2348H96.7434V29.5233H94.4784V47.2348Z"
      fill="#3D3D3D"
    />
    <path
      d="M107.286 47.5584C111.118 47.5584 113.945 45.3615 114.558 42.3301L114.592 42.1598H112.361L112.327 42.3131C111.748 44.2375 109.943 45.5488 107.337 45.5488C103.846 45.5488 101.581 43.1476 101.513 38.924H114.813V38.0385C114.813 32.742 111.833 29.1997 107.099 29.1997C102.364 29.1997 99.2306 32.8612 99.2306 38.4131V38.4302C99.2306 44.0331 102.313 47.5584 107.286 47.5584ZM107.082 31.2093C110.113 31.2093 112.293 33.27 112.548 37.1359H101.547C101.819 33.3722 104.067 31.2093 107.082 31.2093Z"
      fill="#3D3D3D"
    />
    <path
      d="M117.13 47.2348H119.378V36.7442C119.378 33.4403 121.439 31.2263 124.521 31.2263C127.536 31.2263 129.171 32.9805 129.171 36.2673V47.2348H131.419V35.8926C131.419 31.7372 129.102 29.2168 125.185 29.2168C122.324 29.2168 120.332 30.6303 119.446 32.5377H119.378V29.5233H117.13V47.2348Z"
      fill="#3D3D3D"
    />
    <path
      d="M139.798 47.5584C140.343 47.5584 140.922 47.4903 141.28 47.4222V45.5829C141.024 45.617 140.667 45.668 140.258 45.668C138.606 45.668 137.772 45.0039 137.772 43.0454V31.4137H141.28V29.5233H137.772V24.7378H135.455V29.5233H132.85V31.4137H135.455V43.0794C135.455 46.179 136.886 47.5584 139.798 47.5584Z"
      fill="#3D3D3D"
    />
    <path
      d="M149.813 47.5584C153.696 47.5584 156.506 45.4296 156.506 42.4153V42.3982C156.506 39.9118 155.16 38.5323 151.635 37.6808L148.961 37.0507C146.628 36.4887 145.674 35.5861 145.674 34.1726V34.1555C145.674 32.3503 147.275 31.1071 149.676 31.1071C152.146 31.1071 153.713 32.4525 154.002 34.5132L154.019 34.6324H156.182L156.165 34.4451C155.875 31.3966 153.457 29.1997 149.676 29.1997C145.981 29.1997 143.375 31.2945 143.375 34.2407V34.2577C143.375 36.7101 144.976 38.1747 148.314 38.9751L150.988 39.6223C153.355 40.1843 154.206 41.0188 154.206 42.5515V42.5685C154.206 44.4248 152.503 45.651 149.83 45.651C147.156 45.651 145.504 44.3227 145.146 42.279L145.112 42.1257H142.864L142.881 42.279C143.222 45.4126 145.794 47.5584 149.813 47.5584Z"
      fill="#3D3D3D"
    />
    <path
      d="M168.481 47.5584C169.026 47.5584 169.605 47.4903 169.963 47.4222V45.5829C169.708 45.617 169.35 45.668 168.941 45.668C167.289 45.668 166.455 45.0039 166.455 43.0454V31.4137H169.963V29.5233H166.455V24.7378H164.139V29.5233H161.533V31.4137H164.139V43.0794C164.139 46.179 165.569 47.5584 168.481 47.5584Z"
      fill="#3D3D3D"
    />
    <path
      d="M178.973 47.5584C182.804 47.5584 185.632 45.3615 186.245 42.3301L186.279 42.1598H184.048L184.014 42.3131C183.435 44.2375 181.629 45.5488 179.024 45.5488C175.533 45.5488 173.268 43.1476 173.199 38.924H186.5V38.0385C186.5 32.742 183.52 29.1997 178.785 29.1997C174.051 29.1997 170.917 32.8612 170.917 38.4131V38.4302C170.917 44.0331 174 47.5584 178.973 47.5584ZM178.768 31.2093C181.8 31.2093 183.98 33.27 184.235 37.1359H173.233C173.506 33.3722 175.754 31.2093 178.768 31.2093Z"
      fill="#3D3D3D"
    />
    <path
      d="M195.987 47.5584C199.853 47.5584 202.697 45.1231 203.225 41.8192L203.242 41.683H201.011L200.977 41.8192C200.449 44.0331 198.626 45.5488 195.987 45.5488C192.547 45.5488 190.247 42.858 190.247 38.3961V38.3791C190.247 33.9852 192.512 31.2093 195.97 31.2093C198.729 31.2093 200.483 32.8612 200.977 34.9389L201.011 35.0752H203.225L203.208 34.9219C202.765 31.8735 200.091 29.1997 195.97 29.1997C191.116 29.1997 187.931 32.7761 187.931 38.345V38.362C187.931 43.9991 191.065 47.5584 195.987 47.5584Z"
      fill="#3D3D3D"
    />
    <path
      d="M205.507 47.2348H207.772V36.7952C207.772 33.5084 209.816 31.2263 212.881 31.2263C215.896 31.2263 217.735 33.2019 217.735 36.6931V47.2348H220V36.3354C220 31.9927 217.446 29.2168 213.614 29.2168C210.787 29.2168 208.726 30.5962 207.823 32.7591H207.772V22.6601H205.507V47.2348Z"
      fill="#3D3D3D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.4246 25.1888C46.4773 24.5502 46.5041 23.9043 46.5041 23.2521C46.5041 10.4103 36.0938 0 23.2521 0C10.4103 0 0 10.4103 0 23.2521C0 35.6765 9.74461 45.8249 22.0079 46.4714C21.7546 46.0728 21.6078 45.5998 21.6078 45.0925V34.5782H12.7469C11.3253 34.5782 10.1728 33.4257 10.1728 32.0041V25.8262C10.1728 24.4045 11.3253 23.252 12.7469 23.252H21.6078V12.746C21.6078 11.3243 22.7603 10.1718 24.182 10.1718H30.0616C31.4832 10.1718 32.6357 11.3243 32.6357 12.746V23.252H43.93C45.1317 23.252 46.141 24.0754 46.4246 25.1888Z"
      fill="#0BA495"
    />
  </Svg>
);

export default Icon;
