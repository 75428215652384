import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { EButtonVariants } from "src/UI/Button";
import { Modal } from "src/UI/Modal";
import { useAppAction, useAppState } from "src/store/app/hooks";
import { EModals } from "src/store/app/types";
import { ButtonStyled, Buttons, Content } from "./styles";

function ConfirmModal() {
  const { t } = useTranslation();
  const { modals } = useAppState();
  const { onConfirmClose } = useAppAction();

  const closeModal = useCallback(
    (accept: boolean) => {
      onConfirmClose(accept);
    },
    [onConfirmClose]
  );

  const handleAccept = () => {
    modals[EModals.Confirm].props.accept();
    onConfirmClose(false);
  };

  if (!modals[EModals.Confirm].active) return null;
  return (
    <Modal
      modal={EModals.Confirm}
      title={
        modals[EModals.Confirm].props.title ||
        t("app.confirmation.default_title")
      }
      onCloseAction={() => closeModal(false)}
    >
      <Content>
        {modals[EModals.Confirm].props.text ||
          t("app.confirmation.default_message")}
      </Content>
      <Buttons>
        <ButtonStyled
          variant={EButtonVariants.Text}
          onClick={() => closeModal(false)}
        >
          {t("common.cancel")}
        </ButtonStyled>
        <ButtonStyled variant={EButtonVariants.Red} onClick={handleAccept}>
          {t("common.ok")}
        </ButtonStyled>
      </Buttons>
    </Modal>
  );
}

export default ConfirmModal;
