import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Location } from "./types";

export interface ClinicsState {
  currentLocation: Location;
  currentClinicId: string;
}

const initialState: ClinicsState = {
  currentLocation: null,
  currentClinicId: "",
};

export const clinicsSlice = createSlice({
  name: "clinics",
  initialState,
  reducers: {
    setLocation: (state, action: PayloadAction<Location>) => {
      state.currentLocation = action.payload;
    },
    setClinicId: (state, action: PayloadAction<string>) => {
      state.currentClinicId = action.payload;
    },
  },
});

export const { setLocation, setClinicId } = clinicsSlice.actions;
export default clinicsSlice.reducer;
