import React, { Dispatch, SetStateAction, forwardRef } from "react";
import { useTranslation } from "react-i18next";

import DatePicker from "react-datepicker";
import { getDateFromMinutesStartDay, getMinutesFromStartDay } from "src/utils";
import {
  DateInputStyled,
  FormColumn,
  InputWrapper,
  Label,
  RowWrapper,
} from "../styled";
import { IData, TDateValue } from "../types";

const CustomInput = forwardRef(
  (
    { value, onClick, placeholder }: React.HTMLProps<HTMLInputElement>,
    ref: React.Ref<HTMLInputElement>
  ) => (
    <DateInputStyled onClick={onClick} ref={ref}>
      {value || placeholder || ""}
    </DateInputStyled>
  )
);

interface IScheduleAppointmentDateComponent {
  data: IData;
  onChange: (key: string, val: TDateValue) => void;
  setData: Dispatch<SetStateAction<IData>>;
}

function ScheduleAppointmentDateComponent({
  data,
  onChange,
  setData,
}: IScheduleAppointmentDateComponent) {
  const { t } = useTranslation();

  const handleDateRangeChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    setData((prev) => ({
      ...prev,
      dateFrom: start,
      dateTo: end,
    }));
    if (start && end) {
      onChange("dateFrom", start);
      onChange("dateTo", end);
    }
  };

  const handleDateMinuteFromChange = (date: Date | null) =>
    onChange("minuteFrom", getMinutesFromStartDay(date));

  const handleDateMinuteToChange = (date: Date | null) =>
    onChange("minuteTo", getMinutesFromStartDay(date));

  const handleFilterDateFrom = (currentTime: Date) => {
    const minuteTo = data?.minuteTo;
    if (minuteTo) {
      return currentTime < (getDateFromMinutesStartDay(minuteTo) as Date);
    }
    return true;
  };

  const handleFilterDateTo = (currentTime: Date) => {
    const minuteFrom = data?.minuteFrom;
    if (minuteFrom) {
      return currentTime > (getDateFromMinutesStartDay(minuteFrom) as Date);
    }
    return true;
  };

  return (
    <RowWrapper>
      <FormColumn>
        <DatePicker
          minDate={new Date()}
          placeholderText={t("app.schedule_date_range") as string}
          customInput={<CustomInput />}
          selectsRange={true}
          startDate={data?.dateFrom}
          endDate={data?.dateTo}
          onChange={handleDateRangeChange}
        />
      </FormColumn>
      <FormColumn>
        <InputWrapper>
          <Label>{t("booking.timetable.labels.from")}</Label>
          <DatePicker
            selected={getDateFromMinutesStartDay(data.minuteFrom)}
            onChange={handleDateMinuteFromChange}
            placeholderText={t("booking.timetable.labels.from") as string}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={10}
            timeCaption={t("app.schedule_time") as string}
            customInput={<CustomInput />}
            timeFormat="HH:mm"
            dateFormat="HH:mm"
            filterTime={handleFilterDateFrom}
          />
        </InputWrapper>
      </FormColumn>
      <FormColumn>
        <InputWrapper>
          <Label>{t("booking.timetable.labels.to")}</Label>
          <DatePicker
            selected={getDateFromMinutesStartDay(data.minuteTo)}
            onChange={handleDateMinuteToChange}
            placeholderText={t("booking.timetable.labels.to") as string}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={10}
            timeCaption={t("app.schedule_time") as string}
            customInput={<CustomInput />}
            timeFormat="HH:mm"
            dateFormat="HH:mm"
            filterTime={handleFilterDateTo}
          />
        </InputWrapper>
      </FormColumn>
    </RowWrapper>
  );
}
export default ScheduleAppointmentDateComponent;
