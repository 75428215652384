import {
  EPatientStatus,
  IDoctorBooking,
  IPatientItem,
  IService,
} from "../../types";
import { users } from "../../mock/users";

export const patients: IPatientItem[] = [
  {
    ...users[0],
    birth: "14 czerwca 1985",
    status: EPatientStatus.Success,
    apptCreated: "17 wrz 2021 o 5: 10",
    apptStatus: "17 wrz 2021 o 5: 10",
    provider: "Oskar Sikorska",
    response: "1st Choice",
    charge: true,
  },
  {
    ...users[1],
    birth: "15 lipca 1975",
    status: EPatientStatus.Default,
    apptCreated: "17 wrz 2021 o 5: 10",
    apptStatus: "17 wrz 2021 o 5: 10",
    provider: "Miłosz Tomaszewski",
    response: "1st Choice",
    charge: false,
  },
  {
    ...users[2],
    birth: "5 listopada 1973",
    status: EPatientStatus.Success,
    apptCreated: "17 wrz 2021 o 5: 10",
    apptStatus: "17 wrz 2021 o 5: 10",
    provider: "Miłosz Tomaszewski",
    response: "1st Choice",
    charge: true,
  },
  {
    ...users[3],
    birth: "5 maja 1983",
    status: EPatientStatus.Default,
    apptCreated: "17 wrz 2021 o 5: 10",
    apptStatus: "17 wrz 2021 o 5: 10",
    provider: "George J.r",
    response: "1st Choice",
    charge: false,
  },
  {
    ...users[0],
    birth: "14 czerwca 1985",
    status: EPatientStatus.Success,
    apptCreated: "17 wrz 2021 o 5: 10",
    apptStatus: "17 wrz 2021 o 5: 10",
    provider: "Olaf Szymczak",
    response: "1st Choice",
    charge: true,
  },
  {
    ...users[0],
    birth: "14 czerwca 1985",
    status: EPatientStatus.Default,
    apptCreated: "17 wrz 2021 o 5: 10",
    apptStatus: "17 wrz 2021 o 5: 10",
    provider: "Olaf Szymczak",
    response: "1st Choice",
    charge: false,
  },
  {
    ...users[0],
    birth: "14 czerwca 1985",
    status: EPatientStatus.Success,
    apptCreated: "17 wrz 2021 o 5: 10",
    apptStatus: "17 wrz 2021 o 5: 10",
    provider: "Kuba Włodarczyk",
    response: "1st Choice",
    charge: true,
  },
  {
    ...users[0],
    birth: "14 czerwca 1985",
    status: EPatientStatus.Default,
    apptCreated: "17 wrz 2021 o 5: 10",
    apptStatus: "17 wrz 2021 o 5: 10",
    provider: "Bartosz Błaszczyk",
    response: "1st Choice",
    charge: false,
  },
  {
    ...users[0],
    birth: "14 czerwca 1985",
    status: EPatientStatus.Success,
    apptCreated: "17 wrz 2021 o 5: 10",
    apptStatus: "17 wrz 2021 o 5: 10",
    provider: "Adrian Sikora",
    response: "1st Choice",
    charge: true,
  },
  {
    ...users[1],
    birth: "15 lipca 1975",
    status: EPatientStatus.Default,
    apptCreated: "17 wrz 2021 o 5: 10",
    apptStatus: "17 wrz 2021 o 5: 10",
    provider: "Kamil Michalak",
    response: "1st Choice",
    charge: false,
  },
  {
    ...users[2],
    birth: "5 listopada 1973",
    status: EPatientStatus.Success,
    apptCreated: "17 wrz 2021 o 5: 10",
    apptStatus: "17 wrz 2021 o 5: 10",
    provider: "Jędrzej Maciejewski",
    response: "1st Choice",
    charge: true,
  },
  {
    ...users[3],
    birth: "5 maja 1983",
    status: EPatientStatus.Default,
    apptCreated: "17 wrz 2021 o 5: 10",
    apptStatus: "17 wrz 2021 o 5: 10",
    provider: "Jędrzej Maciejewski",
    response: "1st Choice",
    charge: false,
  },
];

export const services: IService[] = [
  {
    title: "Profilaktyka",
    price: "70 PLN",
    isHidden: false,
    content:
      "Profilaktyka polega na oczyszczeniu zębów z płytki nazębnej i poddziąsłowej ( osadu i kamienia) za pomocą ultradźwięków i odpowiednich piaskarek oraz na zabezpieczeniu szkliwa zębów lakierem lub pianką zawierającymi fluor wzmacniający tkankę zęba.",
    delay: "5–10 min",
  },
  {
    title: "Leczenie zachowawcze",
    price: "870 PLN",
    isHidden: true,
    content:
      "Leczenie zachowawcze polega na zachowawczej odbudowie zębów materiałami kompozytowymi. W naszej klinice używamy wysokoestetycznych wypełnień kompozytowych o najwyższych parametrach , gwarantujących ich trwałość w jamie ustnej.",
    delay: "5–10 min",
  },
  {
    title: "Leczenie endodontyczne",
    price: "3 870 PLN",
    isHidden: false,
    content:
      "W momencie, gdy tkanka zęba jest bardzo zniszczona przez próchnicę lub nastąpiło obumarcie zęba z powodów mechanicznych ( uraz), nadchodzi czas na leczenie endodontyczne.",
    delay: "5–10 min",
  },
  {
    title: "Leczenie protetyczne",
    price: "12 870 PLN",
    isHidden: false,
    content:
      "Leczenie protetyczne to często estetyczna poprawa kształtu i koloru zębów przy użyciu koron ceramicznych i licówek porcelanowych, ale także podwyższenie zaniżonego zwarcia oraz odbudowa zębów zniszczonych patologicznym starciem.",
    delay: "5–10 min",
  },
  {
    title: "Leczenie chirurgiczne",
    price: "18 570 PLN",
    isHidden: false,
    content:
      "Leczenie chirurgiczne to leczenie, które wzbudza najwięcej negatywnych emocji wśród pacjentów. W naszej placówce leczenie to przeprowadzane jest przez wysokiej klasy specjalistów i zawsze wykonywane jest w znieczuleniu miejscowym.",
    delay: "10-40 min",
  },
];

export const doctors: IDoctorBooking[] = [
  {
    ...users[0],
    isVacation: false,
    specialization: "Stomatolog",
    workHours: {
      day1: "07:00 - 15:00",
      day2: "07:00 - 19:00",
      day3: "14:00 - 15:00",
    },
    typesList: [
      "Dentysta",
      "Stomatologia",
      "Ortodonta",
      "Próchnica",
      "Aparat ortodontyczny",
      "Pulpitis",
      "Implanty",
      "Protetyka",
    ],
  },
  {
    ...users[0],
    isVacation: true,
    specialization: "Chirurg jamy ustnej i szczękowy",
    workHours: {
      day1: "07:00 - 15:00",
      day2: "07:00 - 19:00",
      day3: "14:00 - 15:00",
    },
    typesList: [
      "Dentysta",
      "Stomatologia",
      "Ortodonta",
      "Próchnica",
      "Aparat ortodontyczny",
      "Pulpitis",
      "Implanty",
      "Protetyka",
    ],
  },
  {
    ...users[0],
    isVacation: true,
    specialization: "Chirurg jamy ustnej i szczękowy",
    workHours: {
      day1: "07:00 - 15:00",
      day2: "07:00 - 19:00",
      day3: "14:00 - 15:00",
    },
    typesList: [
      "Dentysta",
      "Stomatologia",
      "Ortodonta",
      "Próchnica",
      "Aparat ortodontyczny",
      "Pulpitis",
      "Implanty",
      "Protetyka",
    ],
  },
];
