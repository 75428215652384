import React, {
  ChangeEvent,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { ChevronDownIcon, CloseIcon } from "src/UI/Svg";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import RowForMultipleSelect from "./RowForMultipleSelect";
import {
  ClearButton,
  DropdownStyled,
  EmptyTextStyled,
  IconButton,
  IconStyled,
  InputSearch,
  LabelStyled,
  Menu,
  RowStyled,
  SelectedElement,
  SelectedElements,
} from "./styled";

interface OptionType {
  key: string;
  value: string | number;
}

interface DropdownProps {
  options: Array<OptionType>;
  label: string;
  name: string;
}

function DropdownAutocompleteRHF({
  label,
  name,
  options,
  ...props
}: DropdownProps) {
  const { t } = useTranslation();
  const dropdown = useRef<HTMLDivElement>(null);

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [searchText, setSearchText] = useState("");
  const [initialValue, setInitialValue] = useState("");
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const deferredSearch = useDeferredValue(searchText);

  const { watch, setValue } = useFormContext();

  const fieldValue = watch(name);

  const getIsSelected = (opt: OptionType) =>
    Array.isArray(fieldValue)
      ? fieldValue.includes(opt.value)
      : initialValue === opt.key;

  useEffect(() => {
    if (Array.isArray(fieldValue)) {
      if (!fieldValue?.length) {
        setSearchText("");
        setIsFocused(false);
        return;
      }
      setIsFocused(true);
    } else if (typeof fieldValue === "string" && !fieldValue?.length) {
      setSearchText("");
    } else if (typeof fieldValue === "string" && fieldValue?.length) {
      const currentValue =
        options.find((o) => o.value === fieldValue)?.key || "-";
      setSearchText(currentValue);
      setInitialValue(currentValue);
      setIsFocused(!!fieldValue);
    }
  }, [fieldValue, isFocused]);

  const viewOptions = useMemo(() => {
    if (deferredSearch.trim()?.length)
      return options.filter((o) =>
        o.key.toLowerCase().includes(deferredSearch.trim().toLowerCase())
      );
    return options;
  }, [deferredSearch, options]);

  const onSelectHandler =
    (item: { key: string; value: string | number }) => () => {
      if (Array.isArray(fieldValue)) {
        setSearchText("");
        setInitialValue("");
        setValue(name, [...fieldValue, item.value]);
        setIsFocused(true);
      } else {
        setSearchText(options.find((o) => o.value === item.value)?.key || "-");
        setInitialValue(item.key);
        setValue(name, item.value);
      }
    };

  const onRemoveChipHandler = (value: string) => () => {
    const filteredFieldValue = fieldValue?.filter(
      (item: string) => item !== value
    );
    setValue(name, filteredFieldValue);
  };

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (typeof fieldValue === "string" && !fieldValue?.length) {
      setSearchText(e.target.value);
      setValue(name, e.target.value);
    }
    if (Array.isArray(fieldValue)) {
      setSearchText(e.target.value);
      setInitialValue(e.target.value);
    }
  };

  const onClearHandler = () => {
    if (typeof fieldValue === "string" && fieldValue?.length) {
      setSearchText("");
      setInitialValue("");
      setValue(name, "");
      setIsFocused(false);
    }
    if (Array.isArray(fieldValue)) {
      setSearchText("");
      setInitialValue("");
      setValue(name, []);
      setIsFocused(false);
    }
  };

  const onFocusHandler = () => {
    setSearchText("");
    setShowMenu(true);
    setIsFocused(true);
  };

  const onBlurHandler = () => {
    setTimeout(() => setShowMenu(false), 200);
    if (!searchText && !fieldValue?.length) setIsFocused(false);
  };

  return (
    <DropdownStyled ref={dropdown} {...props}>
      <RowStyled showMenu={showMenu}>
        {label !== "" && (
          <LabelStyled isFocused={isFocused}>{label}</LabelStyled>
        )}
        {Array.isArray(fieldValue) ? (
          <SelectedElements>
            {fieldValue.map((v) => (
              <SelectedElement key={v}>
                <span>{options.find((o) => o.value === v)?.key || "-"}</span>
                <IconButton onClick={onRemoveChipHandler(v)}>
                  <CloseIcon />
                </IconButton>
              </SelectedElement>
            ))}
            <InputSearch
              value={initialValue}
              onChange={(e) => onChangeHandler(e)}
              onFocus={onFocusHandler}
              onBlur={onBlurHandler}
            />
          </SelectedElements>
        ) : (
          <InputSearch
            value={initialValue}
            onChange={(e) => onChangeHandler(e)}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
          />
        )}
        {fieldValue?.length ? (
          <ClearButton onClick={onClearHandler}>
            <CloseIcon />
          </ClearButton>
        ) : null}
        <IconStyled
          showMenu={showMenu}
          onClick={() => setShowMenu((prev) => !prev)}
        >
          <ChevronDownIcon size="16px" />
        </IconStyled>
      </RowStyled>
      <Menu showMenu={showMenu}>
        {viewOptions?.length ? (
          viewOptions.map((opt) => (
            <RowForMultipleSelect
              key={opt.value}
              isSelected={getIsSelected(opt)}
              onChange={
                getIsSelected(opt)
                  ? onRemoveChipHandler(opt.value as string)
                  : onSelectHandler(opt)
              }
              name={opt.key}
            />
          ))
        ) : (
          <EmptyTextStyled>{t("app.not_found")}</EmptyTextStyled>
        )}
      </Menu>
    </DropdownStyled>
  );
}

export default DropdownAutocompleteRHF;
