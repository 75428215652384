import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusIcon } from "src/UI/Svg";
import { EButtonVariants } from "src/UI/Button";
import { CardComponent } from "src/components/UserComponents";
import { useOutsideClick } from "src/hooks/useOutsideClick";
import {
  ClinicRoomEntityResponse,
  LayoutTypes,
  ScheduleEntityResponse,
  useFindManyClinicRoomsByClinicIdLazyQuery,
  useFindManySchedulesByClientLazyQuery,
} from "src/graphql/generated";
import { useClinicsState } from "src/store/clinics/hooks";
import { useDeleteScheduleByIdByClient } from "src/graphql/hooks/useMutations";
import { toast } from "react-toastify";
import { GET_FIND_SCHEDULES_BY_CLIENT } from "src/graphql/queries";
import { useAppAction } from "src/store/app/hooks";
import {
  BtnWrapper,
  ButtonStyled,
  Content,
  Menu,
  MenuItem,
  Wrapper,
} from "./styles";
import TimeTableComponent from "./TimeTableComponent";

function ScheduleComponent({ doctorId }: { doctorId: string }) {
  const { currentClinicId: clinicId } = useClinicsState();
  const { t } = useTranslation();
  const [delScheduleRow] = useDeleteScheduleByIdByClient();
  const { onConfirmOpen } = useAppAction();

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [schedules, setShedules] = useState<ScheduleEntityResponse[]>([]);
  const [cabinets, setCabinets] = useState<{ item: string; value: string }[]>(
    []
  );

  const [timeTableItems, setTimeTableItems] = useState<
    { id: number; type: LayoutTypes }[]
  >([]);
  const dropdown = useRef<HTMLDivElement>(null);

  const [getSchedule] = useFindManySchedulesByClientLazyQuery({
    variables: {
      schema: {
        clinicId,
        doctorId,
      },
    },
    onCompleted: (response) => {
      setShedules(response?.findManySchedulesByClient?.schedules);
    },
  });

  const [getCabinets] = useFindManyClinicRoomsByClinicIdLazyQuery({
    variables: {
      schema: {
        clinicId,
      },
    },
    onCompleted(data) {
      const cabinetsArray = data?.findManyClinicRoomsByClinicId?.rooms?.map(
        (clinic: ClinicRoomEntityResponse) => {
          return { item: clinic?.name, value: clinic?.id };
        }
      );
      setCabinets(cabinetsArray);
    },
  });

  useEffect(() => {
    getSchedule();
    getCabinets();
  }, [doctorId, clinicId]);

  useEffect(() => {
    setTimeTableItems([]);
  }, [clinicId]);

  useOutsideClick({
    ref: dropdown,
    handler: () => setShowMenu(() => false),
  });

  const removeTableItem = (id: number | string) => {
    setTimeTableItems((items) => items.filter((i) => i.id !== id));
  };

  const handleRemoveTableItem = (id: number | string) => () => {
    if (typeof id === "string") {
      onConfirmOpen({
        accept: () => {
          delScheduleRow({
            variables: {
              schema: {
                id,
              },
            },
            refetchQueries: [GET_FIND_SCHEDULES_BY_CLIENT],
            awaitRefetchQueries: true,
            onCompleted: () => {
              toast.success(t("app.successful"));
            },
          });
        },
      });
    } else {
      removeTableItem(id);
    }
  };

  const handleShowMenu = () => {
    setShowMenu((prev) => !prev);
  };

  const handleAddSchedule = (layout: LayoutTypes) => () => {
    setTimeTableItems((prev) => [
      { id: prev.length + 1, type: layout },
      ...prev,
    ]);
    setShowMenu((prev) => !prev);
  };

  return (
    <Wrapper>
      <CardComponent
        title={t("app.schedule")}
        headerChildren={
          <BtnWrapper ref={dropdown}>
            <ButtonStyled
              onClick={handleShowMenu}
              type="button"
              variant={EButtonVariants.Default}
            >
              <PlusIcon /> {t("booking.timetable.add")}
            </ButtonStyled>
            <Menu showMenu={showMenu}>
              <MenuItem onClick={handleAddSchedule(LayoutTypes.DayOfWeek)}>
                {t("app.schedule_by_day_of_week")}
              </MenuItem>
              <MenuItem onClick={handleAddSchedule(LayoutTypes.Custom)}>
                {t("app.schedule_customized")}
              </MenuItem>
            </Menu>
          </BtnWrapper>
        }
      >
        <Content>
          <TimeTableComponent
            timeTableItems={timeTableItems}
            cabinets={cabinets}
            schedules={schedules}
            doctorId={doctorId}
            handleRemoveTableItem={handleRemoveTableItem}
          />
        </Content>
      </CardComponent>
    </Wrapper>
  );
}

export default ScheduleComponent;
