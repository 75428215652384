import { useTranslation } from "react-i18next";
import { AggregatedDoctorEntityResponse } from "src/graphql/generated";
import DoctorInfoComponent from "src/components/DoctorInfoComponent/DoctorInfoComponent";
import {
  getDateByLocale,
  getDateFromMinutesStartDay,
  getDateWithoutTimeZoneOffset,
  getFormatDateByLocale,
} from "src/utils";
import { useBookingState } from "src/store/booking/hooks";
import { format } from "date-fns";
import { ESupportedLangs } from "src/types/enum";
import {
  AppointmentCard,
  Card,
  ClinicInfoTitle,
  InfoSubTitle,
  InfoTitle,
  InfoWrapper,
} from "./styles";

const AppointmentCardComponent = () => {
  const { i18n, t } = useTranslation();
  const { currentClinic, currentDateWaitListSlot, currentDoctor, currentSlot } =
    useBookingState();

  const currentSlotDateFrom = getDateWithoutTimeZoneOffset(
    currentSlot?.slot?.from as string
  );

  const getDate = () => {
    if (currentSlot) {
      return getFormatDateByLocale(
        new Date(currentSlotDateFrom),
        i18n?.resolvedLanguage,
        i18n?.resolvedLanguage === ESupportedLangs.pl
          ? "MMMM, d yyyy HH:mm"
          : "MMMM, d yyyy 'at' hh:mm a"
      );
    }

    if (currentDateWaitListSlot) {
      return `${getDateByLocale(
        currentDateWaitListSlot?.slot?.dateFrom as string,
        {
          year: "numeric",
          month: "long",
          day: "numeric",
        },
        i18n?.resolvedLanguage
      )} - ${getDateByLocale(
        currentDateWaitListSlot?.slot?.dateTo as string,
        {
          year: "numeric",
          month: "long",
          day: "numeric",
        },
        i18n?.resolvedLanguage
      )}, ${format(
        getDateFromMinutesStartDay(currentDateWaitListSlot?.slot?.minuteFrom) ||
          new Date(),
        "hh:mm a"
      )} - ${format(
        getDateFromMinutesStartDay(currentDateWaitListSlot?.slot?.minuteTo) ||
          new Date(),
        "hh:mm a"
      )}`;
    }

    return null;
  };

  return (
    <AppointmentCard>
      <Card>
        <DoctorInfoComponent
          doctor={currentDoctor?.doctor as AggregatedDoctorEntityResponse}
        />
        <InfoWrapper>
          <InfoTitle>{t("forms.modals.fields.address")}</InfoTitle>
          <InfoSubTitle>{currentClinic?.clinic.name}</InfoSubTitle>
          <ClinicInfoTitle>{currentClinic?.clinic.address}</ClinicInfoTitle>
          <ClinicInfoTitle>{currentClinic?.clinic.phoneNumber}</ClinicInfoTitle>
        </InfoWrapper>
        <InfoWrapper>
          <InfoTitle>
            {t("mailing.select_mailing.filter_block.appointment")}
          </InfoTitle>
          <InfoSubTitle>{getDate()}</InfoSubTitle>
        </InfoWrapper>
      </Card>
    </AppointmentCard>
  );
};

export default AppointmentCardComponent;
