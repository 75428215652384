import React, { ReactNode, useCallback } from "react";
import { CloseIcon } from "../Svg";
import { EModals } from "../../store/app/types";
import { useAppAction } from "../../store/app/hooks";
import {
  CloseButton,
  Content,
  Header,
  ModalStyled,
  ModalsWrappers,
  Overflow,
  Row,
  Title,
  Wrapper,
} from "./styles";

interface ModalProps {
  headerChildren?: ReactNode;
  titleChildren?: ReactNode;
  children: ReactNode;
  title?: string | null;
  onCloseAction?: () => void;
  modal: EModals;
  footerContent?: ReactNode;
  isCreateDoctorInvitation?: boolean;
  isPreview?: boolean;
  onPreviewCloseAction?: (() => void) | null;
}

function Modal({
  headerChildren,
  titleChildren,
  children,
  title,
  modal,
  onCloseAction,
  footerContent,
  isCreateDoctorInvitation,
  onPreviewCloseAction,
  ...props
}: ModalProps) {
  const { onCloseModal } = useAppAction();

  const onClose = useCallback(() => {
    if (onPreviewCloseAction) {
      onPreviewCloseAction();
    } else {
      onCloseModal(modal);
      if (onCloseAction) {
        onCloseAction();
      }
    }
  }, [onCloseModal, modal, onCloseAction, onPreviewCloseAction]);

  return (
    <Wrapper isConfirmModal={modal === EModals.Confirm}>
      <Overflow>
        <Row>
          <ModalsWrappers {...props}>
            <ModalStyled>
              {title && (
                <Header>
                  <Title isHeaderChildren={!!headerChildren}>
                    {title}
                    {titleChildren}
                  </Title>
                  {headerChildren}
                </Header>
              )}

              <Content>{children}</Content>
            </ModalStyled>
            {footerContent}
          </ModalsWrappers>

          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
        </Row>
      </Overflow>
    </Wrapper>
  );
}

export default Modal;
