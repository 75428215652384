import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FieldError, FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DateInputRHF, InputRHF, PhoneInputSmallRHF } from "src/UI/Input";
import { PatientGenders } from "src/graphql/generated";
import DropdownInputRHF from "src/UI/DropdownInput/DropdownInputRHF";
import { addPatientBookingStep2Schema } from "src/validation/schemas/addPatientBookingStep2Schema";
import { AddressComponent } from "src/components/AuthComponents";
import { useBookingAction, useBookingState } from "src/store/booking/hooks";
import { EButtonVariants } from "src/UI/Button";
import { Loading } from "src/UI/Loading";
import {
  ButtonBlock,
  Form,
  HeadingStyled,
  InputsRow,
  StepContent,
  StyledButton,
} from "../../styled";
import { IFieldStep3, PatientBookingInfo } from "../../types";

interface IStep2 {
  prevStepHandler: () => void;
  isHasFormBlank: boolean;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  createAppointmentHandler: (
    patientInfoSelected?: PatientBookingInfo,
    languageISOCode?: string,
    fields?: IFieldStep3[]
  ) => void;
  loading: boolean;
}

function Step2({
  prevStepHandler,
  isHasFormBlank,
  setCurrentStep,
  createAppointmentHandler,
  loading,
}: IStep2) {
  const { t, i18n } = useTranslation();
  const { patientInfo } = useBookingState();
  const { onSetPatientInfo } = useBookingAction();

  const methods = useForm<PatientBookingInfo>({
    mode: "onChange",
    resolver: yupResolver(addPatientBookingStep2Schema),
    defaultValues: {
      firstName: patientInfo?.patientInfo.firstName,
      lastName: patientInfo?.patientInfo.lastName,
      email: patientInfo?.patientInfo.email,
      phoneNumber: patientInfo?.patientInfo.phoneNumber,
      postCode: patientInfo?.patientInfo.postCode,
      dateOfBirth: patientInfo?.patientInfo.dateOfBirth,
      passportNumber: patientInfo?.patientInfo.passportNumber,
      gender: patientInfo?.patientInfo.gender,
      address: patientInfo?.patientInfo.address,
      comment: patientInfo?.patientInfo.comment,
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = methods;

  const data = watch();

  const formHandler = (formValues: PatientBookingInfo) => {
    onSetPatientInfo({ patientInfo: formValues });
    if (isHasFormBlank) {
      setCurrentStep((prev) => prev + 1);
    } else {
      createAppointmentHandler(formValues, i18n.resolvedLanguage.toUpperCase());
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HeadingStyled>{t("app.booking.patient.step2_title")}</HeadingStyled>

      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(formHandler)}>
          <StepContent>
            <InputsRow>
              <InputRHF
                val={data.firstName}
                label={t("app.booking.patient.step2_first_name") as string}
                inputType="text"
                errorMessage={errors.firstName as FieldError}
                register={register("firstName")}
              />
              <InputRHF
                val={data.lastName}
                label={t("app.booking.patient.step2_last_name") as string}
                inputType="text"
                errorMessage={errors.lastName as FieldError}
                register={register("lastName")}
              />
            </InputsRow>
            <InputsRow>
              <InputRHF
                val={data.email}
                label={t("app.booking.patient.step2_email") as string}
                inputType="email"
                errorMessage={errors.email as FieldError}
                register={register("email")}
              />
              <PhoneInputSmallRHF
                label={t("app.booking.patient.step2_cell_phone") || ""}
                value={data.phoneNumber}
                name="phoneNumber"
              />
            </InputsRow>
            <InputsRow>
              <InputRHF
                val={data.postCode}
                label={t("app.booking.patient.step2_zip") as string}
                inputType="text"
                errorMessage={errors.postCode as FieldError}
                register={register("postCode")}
              />
              <DateInputRHF
                value={data.dateOfBirth}
                name="dateOfBirth"
                placeholder=""
                label={t("app.booking.patient.step2_birth") as string}
                errorMessage={errors.dateOfBirth as FieldError}
                isMonthAndYear
              />
              <DropdownInputRHF
                name="gender"
                value={data.gender}
                placeholder=""
                options={[
                  {
                    item: t("common.gender_male"),
                    value: PatientGenders.Male,
                  },
                  {
                    item: t("common.gender_female"),
                    value: PatientGenders.Female,
                  },
                ]}
                label={t("app.booking.patient.step2_gender") as string}
                adaptedForMobile={false}
                errorMessage={errors.gender as FieldError}
              />
            </InputsRow>
            <InputsRow>
              <AddressComponent
                value={data.address}
                name="address"
                placeholder={t("app.booking.patient.step2_address")}
                errorMessage={errors.address as FieldError}
              />
              <InputRHF
                val={data.passportNumber}
                label={t("app.booking.patient.step2_passport_number") as string}
                inputType="text"
                errorMessage={errors.passportNumber as FieldError}
                register={register("passportNumber")}
              />
            </InputsRow>
            <InputsRow>
              <InputRHF
                val={data.comment as string}
                label={t("app.booking.patient.step2_comment") as string}
                inputType="text"
                errorMessage={errors.comment as FieldError}
                register={register("comment")}
              />
            </InputsRow>
            <ButtonBlock>
              <StyledButton
                variant={EButtonVariants.Text}
                onClick={prevStepHandler}
              >
                {t("app.booking.patient.step_back")}
              </StyledButton>
              <StyledButton variant={EButtonVariants.Default}>
                {loading && <Loading type="secondary" padding="0" />}
                {t("app.booking.patient.step_next")}
              </StyledButton>
            </ButtonBlock>
          </StepContent>
        </Form>
      </FormProvider>
    </>
  );
}

export default Step2;
