import { gql } from "@apollo/client";

export const UPLOAD_FILE = gql`
  mutation UploadFile($schema: UploadFileSchema!) {
    uploadFile(schema: $schema) {
      id
      resourceKey
      url
      userId
      mimetype
      size
      extension
      usageStatus
      originalName
      createdAt
    }
  }
`;
