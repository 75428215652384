import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 0 30px;
  gap: 32px;
  width: 100%;
`;

export const TextButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  padding: 0;
  gap: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  border: none;
  cursor: pointer;
  background: none;
  color: ${({ theme }) => theme.colors.darkGrey};

  svg path {
    stroke: ${({ theme }) => theme.colors.darkGrey};
  }
`;

export const Pages = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 16px;
`;

export const Page = styled.button<{ isActive?: boolean }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 0;
  gap: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  border: none;
  background: ${({ theme, isActive }) =>
    isActive ? theme.colors.green : "transparent"};
  color: ${({ theme, isActive }) => theme.colors[isActive ? "white" : "black"]};
  cursor: pointer;
`;
