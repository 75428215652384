import { ServiceSortFields, SortingOrders } from "src/graphql/generated";

export const initialValues = {
  title: "",
  clinicCode: "",
  photo: "",
  location: null,
  description: "",
  licenseNumber: "",
  phone: "",
  hiddenFromPatients: false,
  schedule: [],
  cabinets: [],
};

export const sortParams = {
  sortOrder: SortingOrders.Descending,
  sortField: ServiceSortFields.CreatedAt,
};
