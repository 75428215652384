import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  AggregatedDoctorEntityResponse,
  ClinicEntityResponse,
  DoctorSortFields,
  ClinicSortFields,
  LanguageIsoCodes,
  SortingOrders,
  useFindAndCountManyDoctorsByClinicIdLazyQuery,
  useFindManyDoctorTypesByLanguageIsoCodeLazyQuery,
} from "src/graphql/generated";
import { useFindAndCountManyClinics } from "src/graphql/hooks/useQueries";
import { toast } from "react-toastify";
import {
  CardComponent,
  TableListComponent,
} from "src/components/UserComponents";
import { Button, EButtonVariants } from "src/UI/Button";
import { PlusIcon } from "src/UI/Svg";
import { useAppAction } from "src/store/app/hooks";
import { useClinicsState } from "src/store/clinics/hooks";
import { PaginationComponent } from "src/components/ReviewComponents";
import {
  defaultDoctorLimit,
  defaultPatientsLimit,
  localStorageDoctorsPageNumberName,
} from "src/constants/constants";
import { usePagination } from "src/hooks/usePagination";
import { useAuthContext } from "src/context/AuthContext";

const ButtonStyled = styled(Button)`
  padding: 8px 10px;
  font-size: 16px;
  line-height: 100%;
  width: fit-content;
  border-radius: 5px;

  svg {
    path {
      stroke: ${({ theme }) => theme.colors.white};
    }
  }
`;

function PersonalPage() {
  const { t, i18n } = useTranslation();
  const { onShowCreateDoctorInvitationModal } = useAppAction();
  const { currentClinicId: clinicId } = useClinicsState();
  const { currentUserData } = useAuthContext();

  const [clinicsList, setClinicsList] = useState<ClinicEntityResponse[]>([]);

  const [
    findDoctorTypesByLocaleLazyQuery,
    { data: doctorTypesData, loading: doctorTypesLoading },
  ] = useFindManyDoctorTypesByLanguageIsoCodeLazyQuery();

  const [getDoctorsDataLazy, { data: doctorsData, loading }] =
    useFindAndCountManyDoctorsByClinicIdLazyQuery();

  const { data: clinicsData } = useFindAndCountManyClinics({
    variables: {
      schema: {
        sortOrder: SortingOrders.Descending,
        sortField: ClinicSortFields.CreatedAt,
      },
    },
  });

  const { offset, currentPage, pages, setCurrentPage } = usePagination({
    limit: defaultDoctorLimit,
    count: doctorsData?.findAndCountManyDoctorsByClinicId?.count || 0,
    localStorageKey: localStorageDoctorsPageNumberName,
  });

  useEffect(() => {
    if (!i18n) return;
    findDoctorTypesByLocaleLazyQuery({
      variables: {
        schema: {
          languageISOCode: i18n.language.toUpperCase() as LanguageIsoCodes,
        },
      },
      fetchPolicy: "network-only",
    });
  }, [i18n.language]);

  useEffect(() => {
    if (!clinicsData) return;
    setClinicsList(
      clinicsData?.findAndCountManyClinics?.clinics as ClinicEntityResponse[]
    );
  }, [clinicsData]);

  useEffect(() => {
    if (!clinicId || !currentUserData) return;
    getDoctorsDataLazy({
      variables: {
        schema: {
          clinicId,
          offset,
          limit: defaultPatientsLimit,
          sortOrder: SortingOrders.Descending,
          sortField: DoctorSortFields.CreatedAt,
        },
      },
      fetchPolicy: "no-cache",
      onError: (error) => {
        if (error) toast.error(error.message);
      },
    });
  }, [clinicId, currentPage, currentUserData]);

  const handleShowModal = () => {
    onShowCreateDoctorInvitationModal({
      data: {
        clinics: clinicsList,
      },
    });
  };

  return (
    <CardComponent
      title={t("user.personal.title")}
      headerChildren={
        <ButtonStyled
          onClick={handleShowModal}
          variant={EButtonVariants.Default}
          disabled={!clinicId}
        >
          <PlusIcon />
          {t("user.personal.button")}
        </ButtonStyled>
      }
    >
      <TableListComponent
        doctorTypesData={doctorTypesData}
        doctors={
          doctorsData?.findAndCountManyDoctorsByClinicId
            .doctors as AggregatedDoctorEntityResponse[]
        }
        loading={loading}
        doctorTypesLoading={doctorTypesLoading}
      />

      <PaginationComponent
        pages={pages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </CardComponent>
  );
}

export default PersonalPage;
