import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Toggle } from "src/UI/Toggle";
import { ITemplateCreateEdit } from "src/types/templates";
import {
  LabelBlock,
  StyledTemplateComponentWrapper,
  ToggleBlock,
} from "./styled";

function TemplatesComponentActiveTemplateToggle() {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<ITemplateCreateEdit>();

  const isActiveTemplate = watch("isActiveTemplate");

  const handleToggle = () => {
    setValue("isActiveTemplate", !isActiveTemplate);
  };

  return (
    <StyledTemplateComponentWrapper>
      <ToggleBlock>
        <LabelBlock>
          {t("templates.action.active_template_for_event.toggle.label")}
        </LabelBlock>

        <Toggle isActive={!!isActiveTemplate} onToggle={handleToggle} />
      </ToggleBlock>
    </StyledTemplateComponentWrapper>
  );
}

export default TemplatesComponentActiveTemplateToggle;
