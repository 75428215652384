import { gql } from "@apollo/client";

export const FIND_AND_COUNT_MANY_LAST_SMS_BY_CLIENT = gql`
  query FindAndCountManyLastSmsByClient(
    $schema: FindAndCountManyLastSmsByClientSchema!
  ) {
    findAndCountManyLastSmsByClient(schema: $schema) {
      count
      sms {
        clinicId
        clinicToPatient
        content
        createdAt
        from
        id
        patient {
          id
          firstName
          lastName
          phoneNumber
          languageISOCode
        }
        patientId
        to
      }
    }
  }
`;
