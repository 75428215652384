import ReactDOM from "react-dom/client";
import App from "./App";
import Providers from "./Providers";
import { language } from "./i18n";
import "./assets/styles/datepicker.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
language
  .then(() => {
    root.render(
      <Providers>
        <App />
      </Providers>
    );
  })
  .catch(() => {});
