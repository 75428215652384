import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { RawDraftContentState } from "draft-js";
import { DistributionActions } from "src/graphql/generated";
import {
  FormMailTemplate,
  FormSmsTemplate,
  ITemplateCreateEdit,
} from "src/types/templates";
import { useTemplateContext } from "src/context/TemplatesContext";
import DraftEditor from "src/components/DraftEditor/DraftEditor";
import { Input } from "src/UI/Input";
import TemplatesComponentsDelaySelect from "../TemplatesComponentsDelaySelect/TemplatesComponentsDelaySelect";
import TemplatesComponentsVariables from "../TemplatesComponentsVariables/TemplatesComponentsVariables";
import {
  Commands,
  Content,
  EditorWrapper,
  StyledInputAutosize,
  Wrapper,
  StyledErrorMessage,
  SubjectWrapper,
} from "./styled";

const isMailTemplate = (
  template?: FormMailTemplate | FormSmsTemplate | null
): template is FormMailTemplate => {
  return (template as FormMailTemplate)?.subject !== undefined;
};

function TemplatesComponentRichEditor() {
  const { t } = useTranslation();
  const { setValue } = useFormContext<ITemplateCreateEdit>();
  const { selectedTemplate } = useTemplateContext();

  const handleDraftChangeValue = (rawData: RawDraftContentState) => {
    const fieldName = selectedTemplate?.getSelectedTemplateFieldName("text");
    const data = String(rawData);

    if (selectedTemplate && fieldName) {
      setValue(fieldName, data);
    }
  };

  const handleSmsChangeValue = (value: string) => {
    const fieldName = selectedTemplate?.getSelectedTemplateFieldName("text");

    if (selectedTemplate && fieldName) {
      setValue(fieldName, value);
    }
  };

  const handleSubjectChangeValue = (value: string) => {
    const subjectName =
      selectedTemplate?.getSelectedTemplateFieldName("subject");

    if (selectedTemplate && subjectName) {
      setValue(subjectName, value);
    }
  };

  const subjectFiledName =
    selectedTemplate?.getSelectedTemplateFieldName("subject");
  const textFieldName = selectedTemplate?.getSelectedTemplateFieldName("text");

  const subjectError = selectedTemplate?.getSelectedTemplateError("subject");
  const textError = selectedTemplate?.getSelectedTemplateError("text");

  const draftEditorValue = selectedTemplate?.selectedTemplate?.text || "";

  const isMail =
    selectedTemplate?.selectedAction === DistributionActions.SendMail;

  return (
    <Wrapper>
      {isMailTemplate(selectedTemplate?.selectedTemplate) &&
        subjectFiledName && (
          <SubjectWrapper>
            <Input
              id={subjectFiledName}
              label={t("templates.action.edit.labels.theme") as string}
              value={selectedTemplate?.selectedTemplate?.subject || ""}
              onChange={handleSubjectChangeValue}
            />
            {subjectError && (
              <StyledErrorMessage isRegister={false}>
                {subjectError?.message && t(subjectError.message)}
              </StyledErrorMessage>
            )}
          </SubjectWrapper>
        )}

      <Content>
        <EditorWrapper $isError={!!textError}>
          {isMail && (
            <DraftEditor
              value={draftEditorValue}
              onChange={handleDraftChangeValue}
            />
          )}

          {!isMail && textFieldName && (
            <StyledInputAutosize
              id={textFieldName}
              value={draftEditorValue}
              onChange={handleSmsChangeValue}
            />
          )}

          {textError && (
            <StyledErrorMessage
              isRegister={false}
              top={isMail ? "48px" : "16px"}
            >
              {textError?.message && t(textError.message)}
            </StyledErrorMessage>
          )}
        </EditorWrapper>

        <Commands>
          <TemplatesComponentsVariables />

          <TemplatesComponentsDelaySelect />
        </Commands>
      </Content>
    </Wrapper>
  );
}

export default TemplatesComponentRichEditor;
