interface VideoProps {
  src: string;
}

function Video({ src }: VideoProps) {
  return (
    <div>
      <iframe src={src} title=" " allowFullScreen />
    </div>
  );
}
export default Video;
