import React, { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  FormFieldEntityWithRelationsResponse,
  FormFieldTypes,
} from "src/graphql/generated";
import { getTranslationsBySelectedLang } from "src/utils/form";
import { FormWrapper, Label } from "./styled";
import { FormItemComponentFieldByType } from "./FormItemComponentFieldByType";

interface IFormItemComponentProps {
  field: FormFieldEntityWithRelationsResponse;
  isPreview?: boolean;
}

const FormItemComponent: FC<IFormItemComponentProps> = ({
  field,
  isPreview = false,
}) => {
  const { i18n } = useTranslation();
  const {
    watch,

    setValue,
    formState: { errors },
    trigger,
  } = useFormContext();

  const fields = watch("fields");

  useEffect(() => {
    if (!Object.keys(errors).length) return;
    trigger("fields");
  }, [fields[field.order].fillValue]);

  useEffect(() => {
    if (!field || isPreview) return;

    if (field.type === FormFieldTypes.Phone) {
      setValue(
        `fields.${field.order}.fillValue`,
        fields[field.order].fillValue.replace(/\+/g, "")
      );
    }
  }, [fields[field.order].translations[0], i18n.resolvedLanguage]);

  const translationByCurrentLang = getTranslationsBySelectedLang(
    i18n.language,
    field.translations
  );
  const placeholder = translationByCurrentLang?.placeholder || "";
  const fieldName = translationByCurrentLang?.name || "";

  return (
    <FormWrapper isCheckbox={field.type === FormFieldTypes.Checkbox}>
      {field.type !== FormFieldTypes.Checkbox ? (
        <Label>{fieldName} </Label>
      ) : null}

      <FormItemComponentFieldByType
        field={field}
        isPreview={isPreview}
        placeholder={placeholder}
        fieldName={fieldName}
      />
    </FormWrapper>
  );
};

export default FormItemComponent;
