import React, { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { EButtonVariants } from "src/UI/Button";
import { DownloadIcon, PlusIcon } from "src/UI/Svg";
import { useAppAction } from "src/store/app/hooks";
import { EModals } from "src/store/app/types";
import { PatientsTableView } from "src/views/PatientsVies";
import {
  allClinicsItemId,
  defaultPatientsLimit,
  localStoragepatientsPageNumberName,
} from "src/constants/constants";
// import {
//   GET_FIND_AND_COUNT_MANY_PATIENTS_BY_CLIENT,
//   GET_FIND_AND_COUNT_MANY_PATIENTS_BY_DOCTOR,
// } from "src/graphql/queries";
// import { useDeletePatientById } from "src/graphql/hooks/useMutations";
import { toast } from "react-toastify";
import {
  CountedPatientsResponse,
  PatientSortFields,
  SortingOrders,
  useFindAndCountManyPatientsByClientLazyQuery,
  useFindAndCountManyPatientsByDoctorLazyQuery,
} from "src/graphql/generated";
import { useAuthContext } from "src/context/AuthContext";
import { useClinicsState } from "src/store/clinics/hooks";
import { PaginationComponent } from "src/components/ReviewComponents";
import { usePagination } from "src/hooks/usePagination";
import {
  Buttons,
  ButtonStyled,
  ButtonWrapper,
  DownloadButtonStyled,
  Header,
  HeadingStyled,
  Wrapper,
} from "./styles";

function PatientsPage() {
  const { t } = useTranslation();
  const { onShowModal } = useAppAction();

  const { isDoctor, currentUserData } = useAuthContext();
  const { currentClinicId: clinicId } = useClinicsState();

  const [clinicIdParam, setClinicIdParam] = useState("");

  // const queryByRole = isDoctor
  //   ? GET_FIND_AND_COUNT_MANY_PATIENTS_BY_DOCTOR
  //   : GET_FIND_AND_COUNT_MANY_PATIENTS_BY_CLIENT;

  const [
    getPatientsByClientLazy,
    { data: patientsByClientData, loading: patientsByClientLoading },
  ] = useFindAndCountManyPatientsByClientLazyQuery();

  const [
    getPatientsDoctorLazy,
    { data: patientsByDoctorData, loading: patientsByDoctorLoading },
  ] = useFindAndCountManyPatientsByDoctorLazyQuery();

  const patientsByRole = isDoctor
    ? patientsByDoctorData?.findAndCountManyPatientsByDoctor
    : patientsByClientData?.findAndCountManyPatientsByClient;

  const { offset, currentPage, pages, setCurrentPage } = usePagination({
    limit: defaultPatientsLimit,
    count: patientsByRole?.count || 0,
    localStorageKey: localStoragepatientsPageNumberName,
  });

  useEffect(() => {
    if (!currentUserData || isDoctor || !clinicId) return;

    getPatientsByClientLazy({
      variables: {
        schema: {
          offset,
          limit: defaultPatientsLimit,
          sortOrder: SortingOrders.Descending,
          sortField: PatientSortFields.CreatedAt,
          ...(clinicId !== allClinicsItemId && { clinicId }),
        },
      },
      fetchPolicy: "no-cache",
      onError: (error) => {
        if (error) toast.error(error.message);
      },
    });
  }, [currentUserData, currentPage, clinicId]);

  useEffect(() => {
    if (!currentUserData || !isDoctor || !clinicId) return;
    getPatientsDoctorLazy({
      variables: {
        schema: {
          offset,
          limit: defaultPatientsLimit,
          ...(clinicId !== allClinicsItemId && { clinicId }),
          sortOrder: SortingOrders.Descending,
          sortField: PatientSortFields.CreatedAt,
        },
      },
      fetchPolicy: "no-cache",
      onError: (error) => {
        if (error) toast.error(error.message);
      },
    });
  }, [currentUserData, currentPage, clinicId]);

  useEffect(() => {
    if (clinicId !== allClinicsItemId) {
      setClinicIdParam(`?clinicId=${clinicId}`);
    } else {
      setClinicIdParam("");
    }
  }, [clinicId]);

  // TODO temporary comment
  // const [deletePatientById] = useDeletePatientById({
  //   onCompleted: (response) => {
  //     if (response) {
  //       toast.success(t("app.successful"));
  //     }
  //   },
  // });

  const handleShowAddPatientModal = useCallback(() => {
    onShowModal(EModals.AddPatient);
  }, [onShowModal]);

  const getExportURLByRole = () => {
    const urlByDoctor = `${process.env.REACT_APP_API_HOST}/api/patient/csv/doctor${clinicIdParam}`;
    const urlByClient = `${process.env.REACT_APP_API_HOST}/api/patient/csv/client${clinicIdParam}`;
    const urlByRole = isDoctor ? urlByDoctor : urlByClient;
    return urlByRole;
  };

  const exportPatientsToCSV = () => {
    fetch(getExportURLByRole(), {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const anchorElement = document.createElement("a");
        anchorElement.href = href;
        anchorElement.download = "GoClientsPatients.csv";
        document.body.appendChild(anchorElement);
        anchorElement.click();
        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
      });
  };

  // TODO temporary comment
  // const handleRemovePatientById = (id: string) => () => {
  //   onConfirmOpen({
  //     accept: () => {
  //       if (typeof id === "string") {
  //         deletePatientById({
  //           variables: {
  //             schema: {
  //               id,
  //             },
  //           },
  //           refetchQueries: [queryByRole],
  //         });
  //       }
  //     },
  //   });
  // };

  return (
    <Wrapper>
      <Header>
        <HeadingStyled as="h3">{t("patients.title")}</HeadingStyled>
        <ButtonWrapper>
          <DownloadButtonStyled
            variant={EButtonVariants.Gray}
            onClick={exportPatientsToCSV}
            disabled={!patientsByRole?.count}
          >
            <DownloadIcon />
            {t("patients.filters.export")}
          </DownloadButtonStyled>
          {!isDoctor ? (
            <Buttons>
              <ButtonStyled
                onClick={handleShowAddPatientModal}
                variant={EButtonVariants.Default}
              >
                <PlusIcon />
                {t("patients.button")}
              </ButtonStyled>
            </Buttons>
          ) : null}
        </ButtonWrapper>
      </Header>
      <PatientsTableView
        patientsData={patientsByRole as CountedPatientsResponse}
        patientsLoading={patientsByClientLoading || patientsByDoctorLoading}
        // handleRemovePatientById={handleRemovePatientById}
      />

      <PaginationComponent
        pages={pages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </Wrapper>
  );
}

export default PatientsPage;
