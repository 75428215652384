import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import ActivityContentComponent from "src/components/DashboardComponents/ActivityComponents/ActivityContentComponent";
import { useAppAction, useAppState } from "src/store/app/hooks";
import { EActivityComponentSize } from "src/types/enum";
import { EModals } from "src/store/app/types";
import { Modal } from "src/UI/Modal";
import { Content } from "./styled";

function PatientFilledFormPreviewModal() {
  const { t } = useTranslation();
  const { modals } = useAppState();
  const { onCloseModal } = useAppAction();

  const modal = modals[EModals.PatientFilledFormPreviewModal];

  const closeModal = useCallback(() => {
    onCloseModal(EModals.PatientFilledFormPreviewModal);
  }, [onCloseModal]);

  if (!modal.active) return null;

  return (
    <Modal
      modal={EModals.PatientFilledFormPreviewModal}
      title={t("patients.filled_form.modal_title")}
      onCloseAction={closeModal}
    >
      <Content>
        {modal.data?.tDataPreview.map((answer, index) => (
          <ActivityContentComponent
            key={index}
            item={answer}
            isShowMoreDefault={index === 0}
            size={EActivityComponentSize.mid}
          />
        ))}
      </Content>
    </Modal>
  );
}

export default PatientFilledFormPreviewModal;
