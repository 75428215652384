import { gql } from "@apollo/client";

export const UPDATE_DOCOTOR_CLINICS_BY_CLIENT = gql`
  mutation UpdateDoctorClinicsByClient(
    $schema: UpdateDoctorClinicsByClientSchema!
  ) {
    updateDoctorClinicsByClient(schema: $schema) {
      updated
    }
  }
`;
