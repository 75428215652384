import { gql } from "@apollo/client";

export const GET_FIND_MANY_CURRENCIES = gql`
  query FindManyCurrencies {
    findManyCurrencies {
      currencies {
        code
      }
    }
  }
`;
