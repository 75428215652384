import { Button } from "src/UI/Button";
import styled from "styled-components";

export const ComponentWrapper = styled.div<{ isActive?: boolean }>`
  position: relative;
  width: 100%;
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

export const InputStyled = styled.input<{
  isFocused: boolean;
}>`
  width: 100%;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.black};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  padding: 15px 0 15px 0;

  &:focus {
    border-color: ${({ theme }) => theme.colors.green};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray};
  }
`;
export const IconCover = styled.div`
  width: 38px;
  min-width: 38px;
  height: 38px;
  background: #f8f8f8;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 24px;
  }
`;

export const CategoryCover = styled.div<{ isFocused: boolean }>`
  visibility: ${({ isFocused }) => (isFocused ? "visible" : "hidden")};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  gap: 2px;
  padding: 4px 0;
  top: 100%;
  left: 0;
  box-sizing: border-box;
  right: 0;
  transition: all 0.25s ease-out;
  opacity: ${({ isFocused }) => (isFocused ? 1 : 0)};
  z-index: 100;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  border-top: none;
  border-radius: 0 0 5px 5px;
  height: ${({ isFocused }) => (isFocused ? "fit-content" : 0)};
  max-height: 300px;
  width: 100%;
  overflow-y: auto;
`;

export const CategoryItem = styled.div<{
  isActive: boolean;
  isSelected: boolean;
}>`
  font-style: normal;
  padding: 7px 14px;
  font-weight: 500;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme, isActive, isSelected }) =>
    theme.colors[isActive || isSelected ? "green" : "black"]};
  cursor: pointer;
  &:hover {
    background-color: #e7f6f5;
    color: ${({ theme }) => theme.colors.green};

    & button {
      opacity: 1;
    }
  }
`;

export const SelectedCategory = styled.div`
  font-style: normal;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  gap: 4px;
  background: none;
  border-radius: 0;
  color: ${({ theme }) => theme.colors.black};
  white-space: nowrap;

  width: 100%;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.black};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  padding: 15px 0;

  &:focus {
    border-color: ${({ theme }) => theme.colors.green};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray};
  }
`;

export const SelectedCategoryText = styled.div`
  font-size: 16px;
  line-height: 140%;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CategoryItemText = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const CategoryItemEmpty = styled.div`
  padding: 10px 20px 10px 20px;
  cursor: default;
`;

export const EmptyTextStyled = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: ${({ theme }) => theme.colors.gray};
  padding: 0 8px;
`;

export const ErrorMessage = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: right;
  color: ${({ theme }) => theme.colors.red};
  position: absolute;
  top: 0;
  right: 0;
`;

export const Label = styled.label<{
  isFocused: boolean;
  isSelected: boolean;
  isSearchQuery: boolean;
}>`
  color: ${({ isFocused, isSelected, isSearchQuery, theme }) =>
    theme.colors[isFocused || isSelected || isSearchQuery ? "grayC4" : "gray"]};
  position: absolute;
  top: ${({ isFocused, isSelected, isSearchQuery }) =>
    isFocused || isSelected || isSearchQuery ? "0" : "50%"};
  transform: translateY(-50%);
  left: 0;
  font-weight: 500;
  font-size: ${({ isFocused, isSelected, isSearchQuery }) =>
    isFocused || isSelected || isSearchQuery ? "12px" : "16px"};
  line-height: ${({ isFocused, isSelected, isSearchQuery }) =>
    isFocused || isSelected || isSearchQuery ? "100%" : "140%"};
`;

export const FooterButton = styled(Button)`
  padding: 7px;
  max-width: unset;
  width: fit-content;
  border-radius: 50px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  gap: 10px;
`;

export const ButtonIcon = styled.button<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  opacity: 0;

  svg {
    width: 16px;
    height: 16px;

    path {
      stroke: ${({ theme }) => theme.colors.gray};
    }
  }

  &:hover {
    background: ${({ theme, isSelected }) =>
      theme.colors[isSelected ? "background" : "lightBiege"]};
    opacity: 0.8;
  }

  &:first-child {
    svg path {
      stroke: ${({ theme }) => theme.colors.green};
    }
  }
`;

export const SearchQueryWrapper = styled.div`
  max-width: 100%;
  padding: 0 12px;
  word-wrap: break-word;
`;
