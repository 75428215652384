import { useTranslation } from "react-i18next";
import { useTemplateContext } from "src/context/TemplatesContext";
import { VariableItem, VariableContainer } from "./styled";
import { TemplatesLabel, TemplatesInputWrapper } from "../styled";

function TemplatesComponentsVariables() {
  const { t } = useTranslation();
  const { variablesTemplate } = useTemplateContext();

  return (
    <TemplatesInputWrapper>
      <TemplatesLabel>
        {t("templates.action.edit.labels.variables_label")}
      </TemplatesLabel>

      <VariableContainer>
        {variablesTemplate?.map((variable, index, arr) => (
          <VariableItem key={variable as string}>
            {variable}
            {index < arr.length - 1 ? ", " : ""}
          </VariableItem>
        ))}
      </VariableContainer>
    </TemplatesInputWrapper>
  );
}

export default TemplatesComponentsVariables;
