import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Modal } from "../../UI/Modal";
import { useAppState } from "../../store/app/hooks";
import { EModals } from "../../store/app/types";
import { ReviewComponent } from "../ReviewComponents";
import { useReviewAction, useReviewState } from "../../store/review/hooks";

const ReviewStyled = styled(ReviewComponent)`
  max-width: 988px;
`;

function AnswerReviewModal() {
  const { t } = useTranslation();
  const { selectedReview } = useReviewState();
  const { modals } = useAppState();
  const { onUnselectReview } = useReviewAction();
  if (!selectedReview || !modals[EModals.ReviewAnswer]) return null;

  return (
    <Modal
      modal={EModals.ReviewAnswer}
      onCloseAction={onUnselectReview}
      title={t("reviews.modal.title")}
    >
      {selectedReview && (
        <ReviewStyled
          review={selectedReview}
          isAnswer={false}
          isShowAnswer={false}
        />
      )}
    </Modal>
  );
}

export default AnswerReviewModal;
