import { Route, Routes } from "react-router-dom";
import { PATHS } from "src/constants/constants";
import PatientsPage from "./PatientsPage/PatientsPage";
import PatientPage from "./PatientPage/PatientPage";

function Index() {
  return (
    <Routes>
      <Route path={PATHS.home} element={<PatientsPage />} />
      <Route path={`${PATHS.patient}/:id`} element={<PatientPage />} />
    </Routes>
  );
}

export default Index;
