import { Route, Routes } from "react-router-dom";
// import styled from "styled-components";
// import { useTranslation } from "react-i18next";
import { UserRoles } from "src/graphql/generated";
import { ProtectedRoute, ProtectedRouteTypes } from "src/router/protectedRoute";
import { ILinkTypes } from "src/types";
import { getFilteredLinksByRole } from "src/utils";
import { PATHS } from "src/constants/constants";
import { TabsView } from "../../../views/BookingsViews";
import UserPage from "./UserPage";
import PersonalPage from "./PersonalPage";
// import AccountPage from "./AccountPage";
// import { Button, EButtonVariants } from "../../../UI/Button";
// import { PlusIcon } from "../../../UI/Svg";
import AddEmployeeModal from "../../../components/Modals/AddEmployeeModal/AddEmployeeModal";
import EditDoctorPage from "./EditDoctorPage/EditDoctorPage";

const links: Array<ILinkTypes> = [
  {
    title: "user.tabs.person_data",
    link: "/cp/clinic",
    roles: [UserRoles.Doctor, UserRoles.Client],
  },
  // { title: "user.tabs.accounts", link: "/cp/clinic/accounts" },
  {
    title: "user.tabs.personal",
    link: "/cp/clinic/personal",
    roles: [UserRoles.Client],
  },
];

// const HeaderStyled = styled.div`
//   display: flex;
//   align-items: center;
//   align-content: center;
//   justify-content: space-between;
// `;
// const ButtonStyled = styled(Button)`
//   padding: 8px 10px;
//   font-size: 16px;
//   line-height: 100%;
//   width: fit-content;
//   border-radius: 5px;

//   svg {
//     path {
//       stroke: ${({ theme }) => theme.colors.white};
//     }
//   }
//   ${({ theme }) => theme.mediaQueries.ll} {
//     padding: 7px 8px;
//     font-size: 12px;
//   }
// `;

const routes = [
  {
    path: `${PATHS.home}`,
    element: () => (
      <>
        <TabsView links={getFilteredLinksByRole(links)} />
        <UserPage />
      </>
    ),
    roles: [UserRoles.Doctor, UserRoles.Client],
  },
  {
    path: `${PATHS.personal}`,
    element: () => (
      <>
        <TabsView links={getFilteredLinksByRole(links)} />
        <PersonalPage />
      </>
    ),
    roles: [UserRoles.Client],
  },
  {
    path: `${PATHS.personal}/:doctorId`,
    element: () => <EditDoctorPage />,
    roles: [UserRoles.Client],
  },
  // {
  //   path: "/accounts",
  //   element: (
  //     <>
  //       <HeaderStyled>
  //         <TabsView links={links} />
  //         <ButtonStyled
  //           // onClick={() => onShowModal(EModals.NewWaitlist)}
  //           variant={EButtonVariants.Default}
  //         >
  //           <PlusIcon />
  //           {t("user.accounts.button")}
  //         </ButtonStyled>
  //       </HeaderStyled>
  //       <AccountPage />
  //     </>
  //   ),
  //   roles: [],
  // },
];

function Index() {
  // const { t } = useTranslation();
  return (
    <>
      <AddEmployeeModal />
      <Routes>
        {/* <Route
          path="/"
          element={
            <>
              <TabsView links={links} />
              <UserPage />
            </>
          }
        />
        {/* <Route
          path="/accounts"
          element={
            <>
              <HeaderStyled>
                <TabsView links={links} />
                <ButtonStyled
                  // onClick={() => onShowModal(EModals.NewWaitlist)}
                  variant={EButtonVariants.Default}
                >
                  <PlusIcon />
                  {t("user.accounts.button")}
                </ButtonStyled>
              </HeaderStyled>
              <AccountPage />
            </>
          }
        /> 
        <Route
          path="/personal"
          element={
            <>
              <TabsView links={links} />
              <PersonalPage />
            </>
          }
        /> */}
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<ProtectedRoute {...(route as ProtectedRouteTypes)} />}
          />
        ))}
      </Routes>
    </>
  );
}

export default Index;
