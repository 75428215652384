import { FieldError, FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { EButtonVariants } from "src/UI/Button";
import { PolicyAgreementComponent } from "src/components/PolicyAgreementComponents";
import { InputComponent } from "src/components/AuthComponents";
import { PhoneInputBigRHF } from "src/UI/Input";
import { HeaderCover } from "src/UI/Header";
import { Heading } from "src/UI/Heading";
import { LangSelectComponent } from "src/components/LangSelectComponets";
import { ChevronDownIcon, LogoFullIcon } from "src/UI/Svg";
import { registrationValidationSchema } from "src/validation/schemas/registrationSchema";
import { useFindAllCountries } from "src/graphql/hooks/useQueries";
import { CountryEntityResponse } from "src/graphql/generated";
import { useLocalSignUp } from "src/graphql/hooks/useMutations";
import { PATHS } from "src/constants/constants";
import { useEffect } from "react";
import { Loading } from "src/UI/Loading";
import DropdownInputRHF from "src/UI/DropdownInput/DropdownInputRHF";
import { theme } from "src/theme";
import {
  ButtonStyled,
  DividedBlock,
  FooterLink,
  FooterLinkRight,
  FooterLinks,
  Form,
  FormWrapper,
  WrapperNav,
} from "./styled";

interface FormValues {
  name: string;
  surname: string;
  phone: string;
  email: string;
  country: {
    countryId: string;
    languageISOCode: string;
    name: string;
  } | null;
  password: string;
  passwordRepeat: string;
  policy: boolean;
  companyPromo: string;
}

function RegistrationPage() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [signUp, { loading }] = useLocalSignUp();
  const { data: allCountries } = useFindAllCountries();

  const optsCountries = allCountries?.findAllCountries?.countries?.map(
    (countrie: CountryEntityResponse) => ({
      item: countrie.name,
      value: {
        countryId: countrie.id,
        languageISOCode: countrie.isoAlpha2Code,
        name: countrie.name,
      },
    })
  );

  const methods = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(registrationValidationSchema),
    defaultValues: {
      name: "",
      surname: "",
      email: "",
      phone: "",
      country: null,
      password: "",
      passwordRepeat: "",
      policy: false,
      companyPromo: "",
    },
  });

  const {
    watch,
    register,
    trigger,
    handleSubmit,
    formState: { errors, isValid },
  } = methods;

  const data = watch();

  useEffect(() => {
    if (data.policy) trigger();
  }, [data.policy]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const loginHandler = (formData: FormValues) => {
    signUp({
      variables: {
        schema: {
          countryId: formData?.country?.countryId,
          email: formData?.email,
          firstName: formData?.name,
          lastName: formData?.surname,
          password: formData?.password,
          phoneNumber: `+${formData?.phone}`,
          ...(formData?.companyPromo && { promoCode: formData?.companyPromo }),
          languageISOCode: i18n?.resolvedLanguage?.toUpperCase(),
        },
      },
      onCompleted() {
        navigate(`${PATHS.auth}${PATHS.checkEmail}`);
      },
    });
  };

  const goHome = () => {
    navigate(`${PATHS.home}`);
  };

  return (
    <>
      <WrapperNav onClick={goHome}>
        <LogoFullIcon />
      </WrapperNav>
      <FormWrapper>
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(loginHandler)}>
            <HeaderCover>
              <Heading as="h2">{t("auth.reg.title")}</Heading>
              <LangSelectComponent />
            </HeaderCover>
            <DividedBlock>
              <InputComponent
                placeholder={t("auth.reg.name")}
                inputType="text"
                val={data.name}
                errorMessage={errors.name}
                register={register("name")}
              />
              <InputComponent
                placeholder={t("auth.reg.surname")}
                inputType="text"
                val={data.surname}
                errorMessage={errors.surname}
                register={register("surname")}
              />
            </DividedBlock>
            <DividedBlock>
              <PhoneInputBigRHF
                label={t("auth.reg.phone") || ""}
                value={data.phone}
                name="phone"
              />
              <InputComponent
                placeholder={t("auth.login.email")}
                inputType="email"
                val={data.email}
                errorMessage={errors.email}
                register={register("email")}
              />
            </DividedBlock>
            <DropdownInputRHF
              name="country"
              value={data?.country}
              options={optsCountries || []}
              placeholder={t("auth.login.country") as string}
              label=""
              adaptedForMobile={false}
              customIcon={
                <ChevronDownIcon
                  size="20px"
                  strokeWidth="0.7"
                  color={theme.colors.green}
                />
              }
              isRegister
              errorMessage={errors.country as FieldError}
            />
            <InputComponent
              placeholder={t("auth.reg.company_promo")}
              inputType="text"
              val={data.companyPromo}
              errorMessage={errors.companyPromo}
              register={register("companyPromo")}
            />
            <InputComponent
              placeholder={t("auth.reg.password")}
              inputType="password"
              val={data.password}
              errorMessage={errors.password}
              register={register("password")}
              handleChange={() => trigger("passwordRepeat")}
            />
            <InputComponent
              placeholder={t("auth.reg.password_repeat")}
              inputType="password"
              val={data.passwordRepeat}
              errorMessage={errors.passwordRepeat}
              register={register("passwordRepeat")}
              handleChange={() => trigger("passwordRepeat")}
            />
            <PolicyAgreementComponent />
            <ButtonStyled
              variant={EButtonVariants.Default}
              disabled={!isValid || loading}
            >
              {loading && <Loading type="secondary" padding="0" />}
              {t("auth.reg.next")}
            </ButtonStyled>
          </Form>
        </FormProvider>
      </FormWrapper>
      <FooterLinks>
        <FooterLink to={`${PATHS.auth}${PATHS.login}`}>
          {t("auth.reg.have_account")}
        </FooterLink>
        <FooterLinkRight to={`${PATHS.auth}${PATHS.login}`}>
          {t("auth.reg.go_to_login")}
        </FooterLinkRight>
      </FooterLinks>
    </>
  );
}

export default RegistrationPage;
