import { gql } from "@apollo/client";

export const FIND_AND_COUNT_MANY_TEMPLATES_BY_CLIENT_TABLE_VIEW = gql`
  query FindAndCountManyTemplatesByClient(
    $schema: FindAndCountManyTemplatesByClientSchema!
  ) {
    findAndCountManyTemplatesByClient(schema: $schema) {
      templates {
        id
        name
        smsSent
        mailSent
        clinicId
        actions
        mailTemplates {
          id
          subject
          text
          templateId
          delayInHours
          languageISOCode
          createdAt
        }
        smsTemplates {
          id
          text
          templateId
          languageISOCode
          delayInHours
          createdAt
        }
        clinicsTemplates {
          clinicId
          templateId
          event
        }
        event
        semanticLock
        updatedAt
        createdAt
      }
      count
    }
  }
`;

export const GET_TEMPLATES_COUNT = gql`
  query FindAndCountManyTemplatesByClientGetCount(
    $schema: FindAndCountManyTemplatesByClientSchema!
  ) {
    findAndCountManyTemplatesByClient(schema: $schema) {
      count
    }
  }
`;

export const FIND_TEMPLATE_BY_CLIENT = gql`
  query FindTemplateByClient($schema: FindTemplateByClientSchema!) {
    findTemplateByClient(schema: $schema) {
      id
      name
      smsSent
      mailSent
      clinicId
      actions
      mailTemplates {
        id
        subject
        text
        templateId
        delayInHours
        languageISOCode
        createdAt
      }
      smsTemplates {
        id
        text
        templateId
        languageISOCode
        delayInHours
        createdAt
      }
      clinicsTemplates {
        clinicId
        templateId
        event
      }
      event
      semanticLock
      updatedAt
      createdAt
    }
  }
`;

export const FIND_MANY_TEMPLATE_VARIABLES_BY_CLIENT = gql`
  query FindManyTemplateVariablesByClient(
    $schema: FindManyTemplateVariablesByClientSchema!
  ) {
    findManyTemplateVariablesByClient(schema: $schema) {
      variables
    }
  }
`;

export const FIND_DEFAULT_MAIL_TEMPLATE = gql`
  query FindDefaultMailTemplate($schema: FindDefaultMailTemplateSchema!) {
    findDefaultMailTemplate(schema: $schema) {
      subject
      text
    }
  }
`;

export const FIND_DEFAULT_SMS_TEMPLATE = gql`
  query FindDefaultSmsTemplate($schema: FindDefaultSmsTemplateSchema!) {
    findDefaultSmsTemplate(schema: $schema) {
      text
    }
  }
`;

export const UPDATE_TEMPLATE_ACTIONS_BY_CLIENT = gql`
  mutation UpdateTemplateActionsByClient(
    $schema: UpdateTemplateActionsByClientSchema!
  ) {
    updateTemplateActionsByClient(schema: $schema) {
      updated
    }
  }
`;

export const CREATE_TEMPLATE = gql`
  mutation CreateTemplate($schema: CreateTemplateSchema!) {
    createTemplate(schema: $schema) {
      id
      name
      smsSent
      mailSent
      clinicId
      actions
      mailTemplates {
        id
        subject
        text
        templateId
        delayInHours
        languageISOCode
        createdAt
      }
      smsTemplates {
        id
        text
        templateId
        languageISOCode
        delayInHours
        createdAt
      }
      clinicsTemplates {
        clinicId
        templateId
        event
      }
      event
      semanticLock
      updatedAt
      createdAt
    }
  }
`;

export const UPDATE_TEMPLATE_BY_CLIENT = gql`
  mutation UpdateTemplateByClient($schema: UpdateTemplateByClientSchema!) {
    updateTemplateByClient(schema: $schema) {
      updated
    }
  }
`;

export const UPDATE_SMS_TEMPLATE_BY_CLIENT = gql`
  mutation UpdateSmsTemplateByClient(
    $schema: UpdateSmsTemplateByClientSchema!
  ) {
    updateSmsTemplateByClient(schema: $schema) {
      updated
    }
  }
`;

export const UPDATE_MAIL_TEMPLATE_BY_CLIENT = gql`
  mutation UpdateMailTemplateByClient(
    $schema: UpdateMailTemplateByClientSchema!
  ) {
    updateMailTemplateByClient(schema: $schema) {
      updated
    }
  }
`;

export const UPDATE_CURRENT_CLINIC_TEMPLATE = gql`
  mutation UpdateCurrentClinicTemplate(
    $schema: UpdateCurrentClinicTemplateSchema!
  ) {
    updateCurrentClinicTemplate(schema: $schema) {
      updated
    }
  }
`;
