import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppointmentSortFields, SortingOrders } from "src/graphql/generated";
import EmptyText from "src/components/EmptyText/EmptyText";
import { Loading } from "src/UI/Loading";
import { useScheduleAppointmentsByRoleFetchMore } from "src/hooks/appointments/fetchMore/useScheduleAppointmentsByRoleFetchMore";
import { StyledFetchMoreDiv, Wrapper } from "./styled";
import PatientAppointmentsTable from "./PatientAppointmentsTable";

function PatientAppointments() {
  const { t } = useTranslation();
  const { id } = useParams();

  const { data, node, isLoading } = useScheduleAppointmentsByRoleFetchMore({
    filters: {
      sortField: AppointmentSortFields.ScheduleTimeFrom,
      sortOrder: SortingOrders.Ascending,
      patientId: id,
    },
  });

  const tableData = data?.appointments;

  const count = data?.count || 0;

  const apptLength = data?.appointments?.length || 0;

  return (
    <Wrapper>
      {!isLoading && !tableData && (
        <EmptyText text={t("patients.visit.not_visits_yet")} />
      )}
      {tableData && <PatientAppointmentsTable data={tableData} />}
      {isLoading && <Loading />}
      {count > apptLength ? <StyledFetchMoreDiv ref={node} /> : null}
    </Wrapper>
  );
}

export default PatientAppointments;
