import React from "react";
import { useAuthContext } from "src/context/AuthContext";
import ConstraintsView from "src/views/DashboardViews/ConstraintsView/ConstraintsView";
import {
  ActivityView,
  GraphicView,
  RemindersView,
  StatisticView,
} from "../../../views/DashboardViews";
import { Content, RightBlock, TopBlock } from "./styled";

function MainPage() {
  const { isClient } = useAuthContext();

  return (
    <Content>
      {isClient && <ActivityView />}
      <RightBlock>
        <ConstraintsView />
        <RemindersView />
        <StatisticView />
        <TopBlock>
          <GraphicView />
        </TopBlock>
      </RightBlock>
    </Content>
  );
}

export default MainPage;
