import React, {
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";

import { theme } from "src/theme";
import { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import RowItem from "./RowItem";
import { ChevronDownIcon } from "../Svg";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import {
  DropdownInputStyled,
  IconStyled,
  Label,
  Menu,
  Placeholder,
  RegisterErrorMessage,
  RowStyled,
  SelectedValue,
} from "./styles";

interface DropdownInputProps {
  value: string | number | object | null;
  placeholder: string | ReactNode;
  onSelect: (val: any) => void;
  options: Array<{ item: string | ReactNode; value: string | number }>;
  label: string;
  isShowOnlyPlaceholder?: boolean;
  adaptedForMobile?: boolean;
  isRegister?: boolean;
  errorMessage?: FieldError;
  isDisabled?: boolean;
}

function DropdownInput({
  value,
  placeholder,
  onSelect,
  label,
  options,
  isShowOnlyPlaceholder,
  adaptedForMobile = true,
  isRegister = false,
  errorMessage,
  isDisabled = false,
  ...props
}: DropdownInputProps) {
  const { t } = useTranslation();
  const dropdown = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const menu = useRef<HTMLDivElement | null>(null);

  useOutsideClick({ ref: dropdown, handler: () => setShowMenu(false) });

  const selectItem = useMemo(
    () =>
      options.filter(
        (item) => JSON.stringify(item.value) === JSON.stringify(value)
      )[0] || null,
    [options, value]
  );

  const onSelectHandler = useCallback(
    (val: string | number) => {
      onSelect(val);
      setShowMenu(false);
    },
    [onSelect]
  );

  const [selectItemItem] = useMemo(() => {
    if (selectItem) return [selectItem.item, selectItem.value];
    return [placeholder, "-"];
  }, [selectItem, placeholder]);

  const onToggleShow = useCallback(() => {
    setShowMenu(!showMenu);
  }, [showMenu]);
  return (
    <DropdownInputStyled
      ref={dropdown}
      isRegister={isRegister}
      isDisabled={isDisabled}
      {...props}
    >
      {label !== "" && (
        <Label onClick={onToggleShow} isFocused={showMenu || !!value}>
          {label}
        </Label>
      )}
      {errorMessage && (
        <RegisterErrorMessage>
          {errorMessage?.message && t(errorMessage.message)}
        </RegisterErrorMessage>
      )}
      <RowStyled
        onClick={onToggleShow}
        isFocused={showMenu || !!value}
        showMenu={showMenu}
        isRegister={isRegister}
      >
        {selectItem && !isShowOnlyPlaceholder ? (
          <SelectedValue
            isRegister={isRegister}
            adaptedForMobile={adaptedForMobile}
          >
            {selectItemItem}
          </SelectedValue>
        ) : (
          <Placeholder
            isRegister={isRegister}
            adaptedForMobile={adaptedForMobile}
          >
            {placeholder}
          </Placeholder>
        )}
        <IconStyled showMenu={showMenu}>
          <ChevronDownIcon
            size="20px"
            strokeWidth="0.7"
            color={theme.colors.green}
          />
        </IconStyled>
      </RowStyled>
      <Menu ref={menu} showMenu={showMenu}>
        {options.map((opt) => {
          const isSelected = selectItem?.value === opt.value;

          return (
            <RowItem
              isActive={false}
              isSelected={isSelected}
              key={JSON.stringify(opt.value)}
              onSelect={onSelectHandler}
              item={opt.item}
              value={opt.value}
            />
          );
        })}
      </Menu>
    </DropdownInputStyled>
  );
}

export default DropdownInput;
