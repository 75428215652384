import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { memo, useMemo } from "react";
import { ITemplateCreateEdit } from "src/types/templates";
import { useTemplateContext } from "src/context/TemplatesContext";
import { DropdownCustomSelectedProps } from "src/types";
import {
  DropdownStyled,
  SelectItem,
  SelectItemDot,
  StyledErrorMessage,
} from "./styled";
import { TemplatesInputWrapper, TemplatesLabel } from "../styled";

function SelectedItem({ value }: DropdownCustomSelectedProps) {
  const displayValue =
    typeof value === "number" || typeof value === "string" ? value : "";

  return <SelectItem>{displayValue} h</SelectItem>;
}

function TemplatesComponentsDelaySelect() {
  const { t } = useTranslation();
  const { setValue } = useFormContext<ITemplateCreateEdit>();
  const { selectedTemplate } = useTemplateContext();

  const handleSelect = (val: any) => {
    const fieldName =
      selectedTemplate?.getSelectedTemplateFieldName("delayInHours");

    if (fieldName && selectedTemplate) {
      setValue(fieldName, val);
    }
  };

  const options = useMemo(() => {
    const result = [];

    if (selectedTemplate?.selectedAction) {
      let i = 0;

      while (i < 25) {
        result.push({
          item: (
            <SelectItem>
              <SelectItemDot
                $isFill={i === selectedTemplate?.selectedTemplate?.delayInHours}
              />{" "}
              {i}h
            </SelectItem>
          ),
          value: i,
        });

        i += 1;
      }
    }

    return result;
  }, [
    selectedTemplate?.selectedAction,
    selectedTemplate?.selectedTemplate?.delayInHours,
  ]);

  const errorMessage =
    selectedTemplate?.getSelectedTemplateError("delayInHours");
  const value = selectedTemplate?.selectedTemplate?.delayInHours || 0;

  return (
    <TemplatesInputWrapper>
      <TemplatesLabel>
        {t("templates.action.edit.time_delay.label")}
      </TemplatesLabel>

      <DropdownStyled
        label=""
        placeholder={t("templates.action.edit.time_delay.placeholder")}
        onSelect={handleSelect}
        customSelectedItem={SelectedItem}
        options={options}
        value={value}
        isShowOnlyPlaceholder={false}
        isHideBorderOnShowMenu
      />

      {errorMessage && (
        <StyledErrorMessage isRegister={false}>
          {errorMessage?.message && t(errorMessage.message)}
        </StyledErrorMessage>
      )}
    </TemplatesInputWrapper>
  );
}

export default memo(TemplatesComponentsDelaySelect);
