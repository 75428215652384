import styled from "styled-components";

export const SliderWrapStyled = styled.div`
  width: calc(100% - 164px);
  height: fit-content;

  & .slick-slider {
    border-right: 1px solid #ddd;
  }

  & .slick-arrow {
    top: 40px;
    position: absolute;
    border: 1px solid #ededed;
    width: 32px;
    height: 32px;
    border-radius: 50%;

    &.slick-prev {
      left: -55px;
    }
    &.slick-next {
      right: -55px;
    }
  }

  & .slick-slide {
    position: relative;
    border-left: 1px solid #ddd;

    &:nth-of-type(6n + 1) .slide-heading {
      background: rgba(162, 155, 254, 0.15);
    }

    &:nth-of-type(6n + 2) .slide-heading {
      background: rgba(72, 227, 255, 0.15);
    }

    &:nth-of-type(6n + 3) .slide-heading {
      background: rgba(9, 132, 227, 0.15);
    }

    &:nth-of-type(6n + 4) .slide-heading {
      background: rgba(253, 203, 110, 0.15);
    }

    &:nth-of-type(6n + 5) .slide-heading {
      background: rgba(255, 118, 117, 0.15);
    }

    &:nth-of-type(6n + 6) .slide-heading {
      background: rgba(67, 92, 190, 0.15);
    }

    & .rbc-time-gutter,
    .rbc-time-header-gutter {
      display: none;
    }
    & .rbc-time-view {
      border: none;
    }
    & .rbc-time-content > * + * > * {
      border-right: none !important;
      border-left: none !important;
    }
    & .rbc-time-header-content {
      border-right: none !important;
      border-left: none !important;
    }
    & .rbc-current-time-indicator:before {
      display: none;
    }
    & .rbc-time-header.rbc-overflowing {
      margin-right: 0 !important;
      border-right: none !important;
    }
  }
`;

export const RowStyled = styled.div`
  display: flex;
`;

export const TimeGutterWrapper = styled.div`
  width: fit-content;
  display: flex;
  align-items: flex-end;

  & .rbc-time-view {
    border: none;
  }

  & .rbc-events-container {
    border: none !important;
  }

  & .rbc-time-view {
    border: none;
  }

  & .rbc-time-content > * + * > * {
    border-left: none !important;
  }

  & .rbc-time-header-content {
    border-left: none !important;
  }
`;

export const TimeGutterEndWrapper = styled.div`
  width: fit-content;
  display: flex;
  align-items: flex-end;

  & .rbc-time-gutter,
  .rbc-time-header-gutter {
    display: none;
  }
  & .rbc-time-view {
    border: none;
  }
  & .rbc-time-content > * + * > * {
    width: 82px;
  }
  & .rbc-label {
    padding: 0;
  }
  & .rbc-current-time-indicator:before {
    display: none;
  }
  & .rbc-time-content > * + * > * {
    border-left: none !important;
  }
  & .rbc-time-header-content {
    border-left: none !important;
  }
`;

export const ArrowLeftStyled = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;

  & svg {
    transform: rotate(180deg);
  }
  &:before {
    display: none;
  }
`;

export const ArrowRightStyled = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;

  & svg {
    transform: rotate(0deg);
  }
  &:before {
    display: none;
  }
`;
