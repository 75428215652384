import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useClinicsState } from "src/store/clinics/hooks";
import { Loading } from "src/UI/Loading";
import { usePagination } from "src/hooks/usePagination";
import { transformBlankFormToCreateEditForm } from "src/connectors/Forms/transformCreateEditForm";
import {
  useFindAndCountManyBlankFormsByClientLazyQuery,
  useFindBlankFormByServiceIdLazyQuery,
} from "src/graphql/generated";
import { defaultBlankFormsLimit } from "src/constants/constants";
import { useAppAction } from "src/store/app/hooks";
import { EButtonVariants } from "src/UI/Button";
import EmptyText from "src/components/EmptyText/EmptyText";
import { getFormatDateByLocale } from "src/utils";
import { LinkIcon } from "../../../../UI/Svg";
import PaginationComponent from "../../../../components/ReviewComponents/PaginationComponent/PaginationComponent";
import { StyledButton, StyledButtonIcon, Table, Wrapper } from "./styled";

function FormPage() {
  const { t, i18n } = useTranslation();
  const clinic = useClinicsState();
  const { onShowCreateEditForm, onShowPreviewForm } = useAppAction();
  const [getForms, { data, loading }] =
    useFindAndCountManyBlankFormsByClientLazyQuery({ fetchPolicy: "no-cache" });
  const [getFormByServiceId] = useFindBlankFormByServiceIdLazyQuery({
    fetchPolicy: "no-cache",
  });

  const { offset, currentPage, pages, setCurrentPage } = usePagination({
    limit: defaultBlankFormsLimit,
    count: data?.findAndCountManyBlankFormsByClient.count || 0,
  });

  useEffect(() => {
    if (!clinic?.currentClinicId) return;

    getForms({
      variables: {
        schema: {
          clinicId: clinic?.currentClinicId,
          limit: defaultBlankFormsLimit,
          offset,
        },
      },
    });
  }, [currentPage, clinic?.currentClinicId]);

  const handleOpenEditFormClick = (formId: string, serviceId: string) => () => {
    onShowCreateEditForm({
      formId,
      serviceId,
      isEditForm: true,
      defaultServiceId: serviceId,
    });
  };

  const handleOpenPreviewFormClick = (serviceId: string) => async () => {
    const formData = await getFormByServiceId({
      variables: {
        schema: {
          serviceId,
        },
      },
    });

    if (formData.error) {
      toast.error(formData.error?.message);
      return;
    }

    if (formData.data) {
      onShowPreviewForm({
        ...transformBlankFormToCreateEditForm(formData.data, i18n.language),
        isShowCreateEditModalAfterClose: false,
      });
    }
  };

  const isFormsExist =
    !!data?.findAndCountManyBlankFormsByClient?.forms?.length;

  return (
    <Wrapper>
      {loading && !isFormsExist && <Loading />}

      {!loading && !isFormsExist && (
        <EmptyText text={t("forms.forms.headers.no_forms_yet")} />
      )}

      {isFormsExist && (
        <>
          <Table>
            <thead>
              <tr>
                <th>{t("forms.forms.headers.preview")}</th>
                <th>{t("forms.forms.headers.title")}</th>
                <th>{t("forms.forms.headers.submitted")}</th>
                <th>{t("forms.forms.headers.edit_date")}</th>
              </tr>
            </thead>
            <tbody>
              {data?.findAndCountManyBlankFormsByClient?.forms?.map((item) => (
                <tr key={item.id}>
                  <td>
                    <StyledButtonIcon
                      variant={EButtonVariants.Text}
                      onClick={handleOpenPreviewFormClick(
                        item.serviceId as string
                      )}
                    >
                      <LinkIcon />
                    </StyledButtonIcon>
                  </td>

                  <td>
                    <StyledButton
                      variant={EButtonVariants.Text}
                      onClick={handleOpenEditFormClick(
                        item.id,
                        item.serviceId as string
                      )}
                      disabled={!!item.countAnswers}
                    >
                      {item.name}
                    </StyledButton>
                  </td>
                  <td>{item.countAnswers}</td>
                  <td>
                    {getFormatDateByLocale(
                      item.updatedAt as string,
                      i18n?.resolvedLanguage,
                      i18n?.resolvedLanguage !== "pl"
                        ? "d MMM yyyy 'at' h:mm a"
                        : "d MMM yyyy HH:mm"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <PaginationComponent
            pages={pages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </>
      )}
    </Wrapper>
  );
}

export default FormPage;
