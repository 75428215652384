import styled from "styled-components";

const EmptyTextStyled = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.gray};
  padding: 20px;
`;

interface IEmptyTextProps {
  text: string;
  className?: string;
}

function EmptyText({ text, className }: IEmptyTextProps) {
  return <EmptyTextStyled className={className}>{text}</EmptyTextStyled>;
}

export default EmptyText;
