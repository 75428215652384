import React from "react";
import { IUserStorageItem } from "src/store/app/types";
import { useNavigate } from "react-router-dom";
import { PATHS } from "src/constants/constants";
import { Wrapper } from "./styled";
import ProfileComponent from "./ProfileComponent";

interface ProfileComponentProps {
  user: IUserStorageItem;
}

function ProfileItemComponent({ user }: ProfileComponentProps) {
  const navigate = useNavigate();

  const handleOpenModal = () => {
    if (user?.isPinCodeExists) {
      navigate(`${PATHS.cp}${PATHS.pinModal}/${user.id}`);
    } else {
      navigate(`${PATHS.cp}${PATHS.passModal}/${user.id}`);
    }
  };
  return (
    <Wrapper onClick={handleOpenModal}>
      <ProfileComponent user={user} />
    </Wrapper>
  );
}

export default ProfileItemComponent;
