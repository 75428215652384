import { gql } from "@apollo/client";

export const FIND_MANY_SERVICE_CATEGORIES_BY_CLINIC_ID = gql`
  query FindManyServiceCategoriesByClinicId(
    $schema: FindManyServiceCategoriesByClinicIdSchema!
  ) {
    findManyServiceCategoriesByClinicId(schema: $schema) {
      categories {
        id
        name
      }
    }
  }
`;
