import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ChevronIcon, ChevronRightIcon } from "src/UI/Svg";
import { useClinicsState } from "src/store/clinics/hooks";
import { Page, Pages, TextButton, Wrapper } from "./styled";

interface IPaginationComponentProps {
  pages: number[];
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

const before = 2;
const after = 3;

function PaginationComponent({
  pages,
  currentPage,
  setCurrentPage,
}: IPaginationComponentProps) {
  const { t } = useTranslation();
  const { currentClinicId: clinicId } = useClinicsState();
  const [prevClinicId, setPrevClinicId] = useState(clinicId);

  useEffect(() => {
    if (prevClinicId !== clinicId) {
      setPrevClinicId(clinicId);
      setCurrentPage(0);
    }
  }, [clinicId]);

  const startIndex = useMemo(
    () => (currentPage - before <= 0 ? 0 : currentPage - before),
    [currentPage]
  );
  const endIndex = useMemo(() => {
    if (pages?.length)
      return currentPage + after >= pages?.length
        ? pages?.length
        : currentPage + after;
  }, [currentPage, pages?.length]);

  const prevPageHandler = () => {
    setCurrentPage((prevState: number) =>
      prevState - 1 < 0 ? 0 : prevState - 1
    );
  };

  const nextPageHandler = () => {
    setCurrentPage((prevState: number) =>
      prevState + 1 > pages?.length ? pages?.length - 1 : prevState + 1
    );
  };

  const setPageHandler = (page: number) => () => {
    setCurrentPage(page);
  };

  if (pages.length > 1) {
    return (
      <Wrapper>
        {currentPage !== 0 && (
          <TextButton onClick={prevPageHandler} disabled={currentPage === 0}>
            <ChevronIcon /> {t("pagination.preview")}
          </TextButton>
        )}
        <Pages>
          {startIndex !== 0 && (
            <>
              <Page onClick={setPageHandler(pages?.[0])}>{pages?.[0] + 1}</Page>
              <Page>...</Page>
            </>
          )}
          {pages?.slice(startIndex, endIndex)?.map((page: number) => (
            <Page
              isActive={page === currentPage}
              onClick={setPageHandler(page)}
              key={`Page-${page}`}
            >
              {page + 1}
            </Page>
          ))}
          {endIndex !== pages?.length && (
            <>
              <Page>...</Page>
              <Page onClick={setPageHandler(pages?.[pages?.length - 1])}>
                {pages?.[pages?.length - 1] + 1}
              </Page>
            </>
          )}
        </Pages>
        {currentPage !== pages?.length - 1 && (
          <TextButton
            onClick={nextPageHandler}
            disabled={currentPage === pages?.length - 1}
          >
            {t("pagination.next")}
            <ChevronRightIcon />
          </TextButton>
        )}
      </Wrapper>
    );
  }
  return null;
}

export default PaginationComponent;
