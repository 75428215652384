import React from "react";
import { CloseIcon } from "src/UI/Svg";

import { useTranslation } from "react-i18next";
import { Icon, Info, InfoWrapper, Title, Wrapper } from "./styled";

function LateComponent() {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Icon>
        <CloseIcon width="32" height="32" />
      </Icon>
      <Title>{t("app.booking.patient.late_title")}</Title>
      <InfoWrapper>
        <Info>{`${t("app.booking.patient.late_name")}`}</Info>
      </InfoWrapper>
    </Wrapper>
  );
}
export default LateComponent;
