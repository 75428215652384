import styled from "styled-components";

export const InputBlock = styled.div<{ isError: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;

  .react-tel-input .country-list {
    overflow-y: auto;
  }

  .form-control {
    border-bottom: 1px solid
      ${({ theme, isError }) =>
        theme.colors[isError ? "red" : "borderInputDefault"]} !important;
  }

  .form-control:focus {
    border-color: ${({ theme, isError }) =>
      theme.colors[isError ? "red" : "green"]} !important;
  }
`;

export const ErrorMessage = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: block;
  text-align: left;
  color: ${({ theme }) => theme.colors.red};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 12px;
  },
`;
