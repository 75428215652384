import {
  DateLocalizer,
  Navigate,
  NavigateAction,
  TitleOptions,
  ViewProps,
} from "react-big-calendar";
// @ts-ignore
import Week from "react-big-calendar/lib/Week";

import { useCalendarState } from "src/store/calendar/hooks";

function WeekView({
  date,
  localizer,
  scrollToTime = localizer.startOf(new Date(), "day"),
  events = [],
  ...props
}: ViewProps) {
  const { weekTimeRange } = useCalendarState();

  const min = weekTimeRange?.min;
  const max = weekTimeRange?.max;

  return (
    <Week
      events={events}
      date={date}
      localizer={localizer}
      max={max}
      min={min}
      scrollToTime={scrollToTime}
      {...props}
    />
  );
}

WeekView.navigate = (
  date: Date,
  action: NavigateAction,
  options: TitleOptions & { localizer: DateLocalizer }
) => {
  const { localizer } = options;
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -7, "day");

    case Navigate.NEXT:
      return localizer.add(date, 7, "day");

    default:
      return date;
  }
};

WeekView.title = (
  date: Date,
  options: TitleOptions & { localizer: DateLocalizer }
) => {
  const { localizer } = options;
  const endDate = localizer.add(date, 6, "day");
  const formattedStartDate = localizer.format(date, "d");
  const formattedEndDate = localizer.format(endDate, "d");
  const monthName = localizer.format(date, "MMMM");

  return `${monthName} ${formattedStartDate}-${formattedEndDate}`;
};

WeekView.range = (
  date: Date,
  options: TitleOptions & { localizer: DateLocalizer }
) => {
  const { localizer } = options;
  const endDate = localizer.add(date, 6, "day");
  return [date, endDate];
};

export default WeekView;
