import { useTranslation } from "react-i18next";

import { useAuthContext } from "src/context/AuthContext";
import { useClinicsState } from "src/store/clinics/hooks";
import {
  useCalculateClinicConstraintsByClientQuery,
  useCalculateClinicConstraintsByDoctorQuery,
} from "src/graphql/generated";
import { allClinicsItemId } from "src/constants/constants";
import { toast } from "react-toastify";
import { Loading } from "src/UI/Loading";
import {
  Block,
  BlockLabel,
  BlockValue,
  BlockWrapper,
  Header,
  HeadingStyled,
  Wrapper,
} from "./styled";

function ConstraintsView() {
  const { t } = useTranslation();
  const { isDoctor, currentUserData } = useAuthContext();
  const { currentClinicId: clinicId } = useClinicsState();

  const { data: constraintsByClientData, loading: constraintsByClientLoading } =
    useCalculateClinicConstraintsByClientQuery({
      variables: {
        schema: {
          ...(clinicId !== allClinicsItemId && { clinicId }),
        },
      },
      onError: (error) => {
        if (error) toast.error(error.message);
      },
      skip: isDoctor || !clinicId || !currentUserData,
      fetchPolicy: "network-only",
    });

  const { data: constraintsByDoctorData, loading: constraintsByDoctorLoading } =
    useCalculateClinicConstraintsByDoctorQuery({
      variables: {
        schema: {
          ...(clinicId !== allClinicsItemId && { clinicId }),
        },
      },
      onError: (error) => {
        if (error) toast.error(error.message);
      },
      skip: !isDoctor || !clinicId || !currentUserData,
      fetchPolicy: "network-only",
    });

  const constraintsData = isDoctor
    ? constraintsByDoctorData?.calculateClinicConstraintsByDoctor
    : constraintsByClientData?.calculateClinicConstraintsByClient;

  const constraintsLoading =
    constraintsByDoctorLoading || constraintsByClientLoading;

  return (
    <Wrapper>
      <Header>
        <HeadingStyled as="h3">{t("app.constraints_sms_email")}</HeadingStyled>
      </Header>
      {constraintsLoading ? (
        <Loading padding="0" />
      ) : (
        <BlockWrapper>
          <Block>
            <BlockLabel>{t("templates.tabs.sms")}</BlockLabel>
            <BlockValue>
              {constraintsData?.smsSent || 0} / {constraintsData?.smsLimit || 0}
            </BlockValue>
          </Block>
          <Block>
            <BlockLabel>{t("templates.tabs.email")}</BlockLabel>
            <BlockValue>
              {constraintsData?.mailSent || 0} /{" "}
              {constraintsData?.mailLimit || 0}
            </BlockValue>
          </Block>
        </BlockWrapper>
      )}
    </Wrapper>
  );
}

export default ConstraintsView;
