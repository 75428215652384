import React from "react";
import { useTranslation } from "react-i18next";
import {
  getDateFromScheduleAppointment,
  getFormatDateByLocale,
} from "src/utils";
import { useAuthContext } from "src/context/AuthContext";
import { TAppointmentByClient, TScheduleAppointmentByRole } from "src/types";
import { getAppointmetStatusWithTranslation } from "src/utils/translate";
import { LinkStyled } from "./styled";

interface IScheduleAppointmentsTableComponentProps {
  appointment: TScheduleAppointmentByRole;
  handleShowModal: (id: string) => () => void;
}

function RowComponent({
  appointment,
  handleShowModal,
}: IScheduleAppointmentsTableComponentProps) {
  const { t, i18n } = useTranslation();
  const { isClient } = useAuthContext();

  const doctor = (appointment as TAppointmentByClient)?.doctor;

  return (
    <tr>
      <td>
        <LinkStyled onClick={handleShowModal(appointment?.id as string)}>
          {appointment?.patient.firstName} {appointment?.patient.lastName}
        </LinkStyled>
      </td>
      <td>
        {appointment?.patient?.dateOfBirth
          ? getFormatDateByLocale(
              appointment?.patient?.dateOfBirth as string,
              i18n?.resolvedLanguage
            )
          : appointment?.patient?.dateOfBirth}
      </td>
      <td>{appointment?.patient.phoneNumber}</td>
      <td>{appointment?.patient.email}</td>
      <td>{getAppointmetStatusWithTranslation(t, appointment?.status)}</td>
      <td>
        {appointment?.schedule.dateFrom && appointment?.schedule.minuteFrom
          ? getDateFromScheduleAppointment(
              appointment?.schedule.dateFrom,
              appointment?.schedule.minuteFrom,
              i18n
            )
          : null}
      </td>
      {isClient && doctor ? (
        <td>
          {doctor.firstName} {doctor.lastName}
        </td>
      ) : null}
      <td>{appointment?.service?.name}</td>
    </tr>
  );
}

export default RowComponent;
