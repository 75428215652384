import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LangSelectComponent } from "src/components/LangSelectComponets";
import { HeaderCover } from "src/UI/Header";
import { ChevronIcon, LogoFullIcon } from "src/UI/Svg";
import { Text } from "src/UI/Text";
import { Heading } from "src/UI/Heading";
import { EButtonVariants } from "src/UI/Button";
import { PhoneInputBig } from "src/UI/Input";
import {
  Breadcrumb,
  ButtonStyled,
  FormFA,
  FormWrapper,
  TitleBlock,
  WrapperNav,
} from "../styled";

interface FormValues {
  field: string;
  method: string;
}

type PhoneInputBigRef = React.ElementRef<typeof PhoneInputBig>;

function RestoreEmail() {
  const { t } = useTranslation();

  const methods = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      method: "phone",
      field: "",
    },
  });

  const {
    watch,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = methods;

  const phoneRef = useRef<PhoneInputBigRef>(null);

  const data = watch();

  const navigate = useNavigate();
  const formHandler = () => {
    navigate("/auth/restore/new-password");
  };

  return (
    <>
      <WrapperNav>
        <LogoFullIcon />
      </WrapperNav>
      <FormWrapper>
        <HeaderCover>
          <Breadcrumb to="/auth/login">
            <ChevronIcon />
            <Text>{t("common.back")}</Text>
          </Breadcrumb>
          <LangSelectComponent />
        </HeaderCover>
        <FormFA onSubmit={handleSubmit(formHandler)}>
          <TitleBlock>
            <Heading as="h2">{t("restore.email.title")}</Heading>
          </TitleBlock>
          <PhoneInputBig
            value={data.field}
            ref={phoneRef}
            onChange={(val) => setValue("field", val)}
            label={t("auth.reg.phone") || ""}
            errorMessage={t("auth.reg.phone_error") || ""}
            id="phone"
          />
          <ButtonStyled variant={EButtonVariants.Default} disabled={!isValid}>
            {t("restore.password.submit")}
          </ButtonStyled>
        </FormFA>
      </FormWrapper>
    </>
  );
}

export default RestoreEmail;
