import { gql } from "@apollo/client";

export const CONFIRM_SCHEDULE_APPOINTMENT_BY_PATIENT = gql`
  mutation ConfirmScheduleAppointmentByPatient(
    $schema: ConfirmScheduleAppointmentByPatientSchema!
  ) {
    confirmScheduleAppointmentByPatient(schema: $schema) {
      confirmed
    }
  }
`;
