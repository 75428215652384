import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { EButtonVariants } from "src/UI/Button";
import { PlusIcon } from "src/UI/Svg";
import { CountedPatientsResponse } from "src/graphql/generated";
import { useFetchMore } from "src/hooks/useFetchMore";
import { Loading } from "src/UI/Loading";
import {
  AddButton,
  EmptyTextStyled,
  EmptyWrap,
  ListStyled,
  ListStyledInner,
  LoadingWrapStyled,
} from "./styles";
import RowItem from "../RowItem";

interface ResultListComponentProps {
  resultList: Array<{ item: string | ReactNode; value: string | number }>;
  onSelect?: (value: string | number) => void;
  onSearchAddEntity?: () => void;
  isShow: boolean;
  fetchMore: () => Promise<unknown>;
  data: CountedPatientsResponse | null;
}
function ResultListComponent({
  isShow,
  resultList,
  onSelect,
  onSearchAddEntity,
  fetchMore,
  data,
}: ResultListComponentProps) {
  const { t } = useTranslation();

  const { node, isLoading } = useFetchMore(() => fetchMore());

  return (
    <ListStyled isShow={isShow}>
      <ListStyledInner>
        {resultList.length ? (
          <>
            {resultList?.map((opt) => {
              return (
                <RowItem
                  isActive={false}
                  isSelected={false}
                  key={JSON.stringify(opt.value)}
                  onSelect={onSelect}
                  item={opt.item}
                  value={opt.value}
                />
              );
            })}
            {isLoading ? (
              <LoadingWrapStyled>
                <Loading />
              </LoadingWrapStyled>
            ) : null}
            {data && data.count > data.patients.length && !isLoading ? (
              <div ref={node} />
            ) : null}
          </>
        ) : (
          <EmptyWrap>
            {onSearchAddEntity && (
              <AddButton
                type="button"
                variant={EButtonVariants.Default}
                onClick={onSearchAddEntity}
              >
                <PlusIcon />
              </AddButton>
            )}
            <EmptyTextStyled>{t("app.not_found")}</EmptyTextStyled>
          </EmptyWrap>
        )}
      </ListStyledInner>
    </ListStyled>
  );
}

export default ResultListComponent;
