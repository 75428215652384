import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ITemplateCreateEdit } from "src/types/templates";
import { DistributionActions, LanguageIsoCodes } from "src/graphql/generated";
import { useTemplateFormSelected } from "./useTemplateFormSelected";

export const useTemplateFocusOnFormErrors = (
  selectedLang: LanguageIsoCodes,
  onSelectLang: (lang: LanguageIsoCodes) => void
) => {
  const {
    setValue,
    formState: { errors, isSubmitting },
  } = useFormContext<ITemplateCreateEdit>();
  const { getSelectedTemplateError } = useTemplateFormSelected(selectedLang);

  useEffect(() => {
    if (!isSubmitting) {
      if (
        !errors.title &&
        !getSelectedTemplateError("delayInHours") &&
        !getSelectedTemplateError("subject") &&
        !getSelectedTemplateError("text") &&
        errors
      ) {
        const mailErrorIndex =
          Array.isArray(errors.mailTemplates) &&
          errors.mailTemplates?.findIndex((item) => !!item);
        const smsErrorIndex =
          Array.isArray(errors.smsTemplates) &&
          errors.smsTemplates?.findIndex((item) => !!item);

        if (typeof mailErrorIndex === "number" && mailErrorIndex >= 0) {
          setValue("selectedAction", DistributionActions.SendMail);

          if (mailErrorIndex === 0) {
            onSelectLang(LanguageIsoCodes.En);
          } else {
            onSelectLang(LanguageIsoCodes.Pl);
          }

          return;
        }

        if (typeof smsErrorIndex === "number" && smsErrorIndex >= 0) {
          setValue("selectedAction", DistributionActions.SendSms);

          if (smsErrorIndex === 0) {
            onSelectLang(LanguageIsoCodes.En);
          } else {
            onSelectLang(LanguageIsoCodes.Pl);
          }
        }
      }
    }
  }, [isSubmitting]);
};
