import { InputAutosizeRHF, ToggleRHF } from "src/UI/Input";
import { CheckIcon, ClinicIcon } from "src/UI/Svg";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  background-color: #f8f8f8;
  box-shadow: 0px 5px 25px 0px rgba(33, 44, 65, 0.03);
`;

export const CommunicationPreferenceTitle = styled.h3`
  font-size: 24px;
  font-weight: 500;
`;

export const CommunicationPreferenceItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
  padding: 20px;
  border-bottom: 1px solid #ededed;
  border-radius: 10px;
`;

export const CommunicationPreferenceItemName = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  font-weight: 500;
`;

export const StyledCheckIcon = styled(CheckIcon)`
  width: 16px;

  path {
    stroke: #a5a5a5;
  }
`;

export const ToggleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
`;

export const StyledToggleRHF = styled(ToggleRHF)`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const ToggleLabel = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

export const AdditionalInfoTitle = styled.h3`
  font-size: 16px;
  font-weight: 500;
`;

export const AdditionalInfoHead = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 16px;
  border-radius: 10px;
  margin-bottom: 8px;
`;

export const AdditionalInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 20px;
`;

export const AdditionalInfoItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  &:last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  }
`;

export const AdditionalInfoItemName = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  font-weight: 400;
  min-width: 170px;
  color: ${({ theme }) => theme.colors.gray};
`;

export const StyledClinicIcon = styled(ClinicIcon)`
  width: 16px;
  height: 16px;

  path {
    stroke: ${({ theme }) => theme.colors.gray};
  }
`;

export const ToggleContainerAdditionalInfo = styled.div`
  display: flex;
  gap: 16px;
`;

export const InputAutosizeRHFStyled = styled(InputAutosizeRHF)`
  & textarea {
    border: none;
    background: transparent;
  }

  & label {
    font-weight: 400;
  }
`;
