import * as yup from "yup";
import { parsePhoneNumber } from "awesome-phonenumber";

export const registrationValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required("auth.reg.name_error")
    .min(1, "auth.reg.name_error")
    .max(100, "validation.field_max_100"),
  surname: yup
    .string()
    .required("auth.reg.surname_error")
    .min(1, "auth.reg.surname_error")
    .max(100, "validation.field_max_100"),
  email: yup
    .string()
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "auth.login.email_error"
    )
    .required("validation.field_required"),
  country: yup
    .mixed()
    .nullable()
    .test("required", "validation.field_required", (value) => {
      return !!value;
    }),
  companyPromo: yup
    .string()
    .max(10, "auth.reg.company_promo_error_max")
    .notRequired(),
  password: yup
    .string()
    .min(8, "auth.reg.password_error_min")
    .required("validation.field_required"),
  passwordRepeat: yup
    .string()
    .oneOf([yup.ref("password")], "auth.reg.password_must_mach"),

  phone: yup.string().test("is-checked", "auth.reg.phone_error", (val) => {
    return parsePhoneNumber(`+${val}` as string).valid;
  }),
  policy: yup.boolean().test("is-checked", "", (val) => val),
});
