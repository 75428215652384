import { Button } from "src/UI/Button";
import { CardComponent } from "src/components/UserComponents";
import styled from "styled-components";

export const CardStyled = styled(CardComponent)`
  max-width: 696px;
  z-index: 1;

  & > div:first-child {
    padding-bottom: 0;
    border-bottom: none;
  }
`;

export const ButtonStyled = styled(Button)`
  padding: 8px 10px;
  font-size: 16px;
  line-height: 100%;
  width: fit-content;
  border-radius: 5px;

  svg {
    path {
      stroke: ${({ theme }) => theme.colors.white};
    }
  }
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 7px 8px;
    font-size: 12px;
  }
`;

export const LoadingWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

export const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  padding: 20px;
`;

export const EmptyTextStyled = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.gray};
`;
