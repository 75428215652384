import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ClinicEntityResponse,
  Query,
  ServiceEntityWithRelationsResponse,
} from "src/graphql/generated";
import {
  TScheduleAppointmentByRole,
  TScheduleWaitingAppointment,
} from "src/types";
import {
  EModals,
  IAlert,
  INavTip,
  IPatientVisitSettingsFields,
  IPatientVisitSettingsFieldsList,
  IConfirmPropsClose,
  IConfirmProps,
  IPatientSettingsFieldsList,
  IPatientSettingsFields,
  IAddCabinetProps,
  IEdirServiceModal,
  IChangeEmailProps,
  ICreateDoctorInvitationModal,
  EditServiceCategoryProps,
  IScheduleAppointmentModal,
  ICancelAppointmentProps,
  ICancelAppointmentPropsClose,
  IScheduleWaitingAppointmentModal,
  IShowEditFormModalProps,
  IShowPreviewModalProps,
  IAddWaitListProps,
  IShowPatientFilledFormPreviewModalProps,
} from "./types";

export interface AppState {
  modals: {
    [EModals.Pin]: boolean;
    [EModals.PassForPin]: boolean;
    [EModals.ReviewAnswer]: boolean;
    [EModals.Timetable]: boolean;
    [EModals.AddService]: boolean;
    [EModals.EditService]: {
      active: boolean;
      data: {
        service: ServiceEntityWithRelationsResponse | null;
      };
    };
    [EModals.NewWaitlist]: boolean;
    [EModals.Qrcode]: boolean;
    [EModals.CustomizeForm]: boolean;
    [EModals.Preview]: boolean;
    [EModals.Send]: boolean;
    [EModals.AddEmployee]: boolean;
    [EModals.AddPatient]: boolean;
    [EModals.CalendarDetail]: boolean;
    [EModals.DeleteEvent]: boolean;
    [EModals.NewEvent]: boolean;
    [EModals.ForgotPin]: boolean;
    [EModals.ForgotPass]: boolean;
    [EModals.ForgotEmail]: boolean;
    [EModals.CreatePin]: boolean;
    [EModals.AddToWaitListModal]: {
      active: boolean;
      props: IAddWaitListProps;
    };
    [EModals.AddCabinet]: {
      active: boolean;
      data: IAddCabinetProps;
    };
    [EModals.EditServiceCategory]: {
      active: boolean;
      data: EditServiceCategoryProps;
    };
    [EModals.ChangeEmail]: {
      active: boolean;
      data: IChangeEmailProps;
    };
    [EModals.PatientVisitSettings]: {
      active: boolean;
      fields: IPatientVisitSettingsFields;
    };
    [EModals.PatientSettings]: {
      active: boolean;
      fields: IPatientSettingsFields;
    };
    [EModals.Confirm]: {
      active: boolean;
      props: IConfirmProps;
    };
    [EModals.CreateDoctorInvition]: {
      active: boolean;
      data: {
        clinics: ClinicEntityResponse[];
      };
    };
    [EModals.ScheduleAppointment]: {
      active: boolean;
      data: {
        logoFiles?: Query;
        appointment: TScheduleAppointmentByRole;
      };
    };
    [EModals.ScheduleWaitingAppointment]: {
      active: boolean;
      data: {
        logoFiles?: Query;
        appointment: TScheduleWaitingAppointment;
      };
    };
    [EModals.CreateEditForm]: {
      active: boolean;
      data: IShowEditFormModalProps;
    };
    [EModals.CancelAppointment]: {
      active: boolean;
      props: ICancelAppointmentProps;
    };
    [EModals.PreviewForm]: {
      active: boolean;
      data?: IShowPreviewModalProps;
    };
    [EModals.PatientFilledFormPreviewModal]: {
      active: boolean;
      data?: IShowPatientFilledFormPreviewModalProps;
    };
  };
  alert: IAlert | null;
  nav: {
    tip: {
      title: string;
      top: number;
    };
  };
}

const initialState: AppState = {
  modals: {
    [EModals.Pin]: true,
    [EModals.PassForPin]: true,
    [EModals.ReviewAnswer]: false,
    [EModals.Timetable]: false,
    [EModals.AddService]: false,
    [EModals.EditService]: {
      active: false,
      data: {
        service: null,
      },
    },
    [EModals.NewWaitlist]: false,
    [EModals.Qrcode]: false,
    [EModals.CustomizeForm]: false,
    [EModals.Preview]: false,
    [EModals.Send]: false,
    [EModals.AddEmployee]: false,
    [EModals.AddPatient]: false,
    [EModals.CalendarDetail]: false,
    [EModals.DeleteEvent]: false,
    [EModals.NewEvent]: false,
    [EModals.ForgotPin]: false,
    [EModals.ForgotPass]: false,
    [EModals.ForgotEmail]: false,
    [EModals.CreatePin]: false,
    [EModals.AddToWaitListModal]: {
      active: false,
      props: {
        accept: () => {},
      },
    },
    [EModals.AddCabinet]: {
      active: false,
      data: {
        cabinet: null,
        accept: () => {},
      },
    },
    [EModals.EditServiceCategory]: {
      active: false,
      data: {
        category: null,
        accept: () => {},
      },
    },
    [EModals.ChangeEmail]: {
      active: false,
      data: {
        email: "",
        accept: () => {},
      },
    },
    [EModals.PatientVisitSettings]: {
      active: false,
      fields: {
        name: true,
        birth: true,
        doctor: true,
        clinic: false,
        cabinet: true,
        send: true,
        time: false,
        price: true,
      },
    },
    [EModals.PatientSettings]: {
      active: false,
      fields: {
        name: true,
        birth: true,
        phone: true,
        email: false,
        appelation: true,
        status: true,
        pesel: false,
      },
    },
    [EModals.Confirm]: {
      active: false,
      props: {
        title: "",
        text: "",
        accept: () => {},
        cancel: () => {},
      },
    },
    [EModals.CreateDoctorInvition]: {
      active: false,
      data: {
        clinics: [],
      },
    },
    [EModals.ScheduleAppointment]: {
      active: false,
      data: {
        logoFiles: undefined,
        appointment: null,
      },
    },
    [EModals.ScheduleWaitingAppointment]: {
      active: false,
      data: {
        appointment: null,
      },
    },
    [EModals.CreateEditForm]: {
      active: false,
      data: {
        formId: undefined,
        serviceId: undefined,
      },
    },
    [EModals.CancelAppointment]: {
      active: false,
      props: {
        accept: () => {},
        cancel: () => {},
      },
    },
    [EModals.PreviewForm]: {
      active: false,
    },
    [EModals.PatientFilledFormPreviewModal]: {
      active: false,
    },
  },
  alert: null,
  nav: {
    tip: {
      title: "",
      top: 0,
    },
  },
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    showModal: (state, action: PayloadAction<EModals>) => {
      if (typeof state.modals[action.payload] === "boolean") {
        state.modals = {
          ...state.modals,
          [action.payload]: true,
        };
      } else if (action.payload === EModals.PatientVisitSettings) {
        state.modals[action.payload].active = true;
      } else if (action.payload === EModals.PatientSettings) {
        state.modals[action.payload].active = true;
      }
    },
    closeModal: (state, action: PayloadAction<EModals>) => {
      if (typeof state.modals[action.payload] === "boolean") {
        state.modals = {
          ...state.modals,
          [action.payload]: false,
        };
      } else if (action.payload === EModals.PatientVisitSettings) {
        state.modals[action.payload].active = false;
      } else if (action.payload === EModals.PatientSettings) {
        state.modals[action.payload].active = false;
      } else if (action.payload === EModals.AddCabinet) {
        state.modals[action.payload].active = false;
      } else if (action.payload === EModals.ChangeEmail) {
        state.modals[action.payload].active = false;
      } else if (action.payload === EModals.EditService) {
        state.modals[action.payload].active = false;
      } else if (action.payload === EModals.CreateDoctorInvition) {
        state.modals[action.payload].active = false;
      } else if (action.payload === EModals.EditServiceCategory) {
        state.modals[action.payload].active = false;
      } else if (action.payload === EModals.ScheduleAppointment) {
        state.modals[action.payload].active = false;
      } else if (action.payload === EModals.ScheduleWaitingAppointment) {
        state.modals[action.payload].active = false;
      } else if (action.payload === EModals.CancelAppointment) {
        state.modals[action.payload].active = false;
      } else if (action.payload === EModals.AddToWaitListModal) {
        state.modals[action.payload].active = false;
      } else if (action.payload === EModals.CreateEditForm) {
        state.modals[action.payload] = initialState.modals[action.payload];
      } else if (action.payload === EModals.PreviewForm) {
        state.modals[action.payload] = initialState.modals[action.payload];
      } else if (action.payload === EModals.PatientFilledFormPreviewModal) {
        state.modals[action.payload] = initialState.modals[action.payload];
      }
    },
    showAlert: (state, action: PayloadAction<IAlert>) => {
      state.alert = action.payload;
    },
    closeAlert: (state) => {
      state.alert = null;
    },
    togglePatientVisitSettings: (
      state,
      action: PayloadAction<IPatientVisitSettingsFieldsList>
    ) => {
      state.modals[EModals.PatientVisitSettings].fields[action.payload] =
        !state.modals[EModals.PatientVisitSettings].fields[action.payload];
    },
    togglePatientSettings: (
      state,
      action: PayloadAction<IPatientSettingsFieldsList>
    ) => {
      state.modals[EModals.PatientSettings].fields[action.payload] =
        !state.modals[EModals.PatientSettings].fields[action.payload];
    },
    toggleNavTip: (state, action: PayloadAction<INavTip | undefined>) => {
      if (action.payload) {
        state.nav.tip.title = action.payload.title;
        state.nav.tip.top = action.payload.top;
      } else {
        state.nav.tip.title = "";
        state.nav.tip.top = 0;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    confirmClose: (state, action: PayloadAction<IConfirmPropsClose>) => {
      state.modals[EModals.Confirm].active = false;
    },
    closeCancelAppointmentModal: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<ICancelAppointmentPropsClose>
    ) => {
      state.modals[EModals.CancelAppointment].active = false;
    },
    confirmOpen: (state, action: PayloadAction<IConfirmProps>) => {
      state.modals[EModals.Confirm].props.title = action.payload.title;
      state.modals[EModals.Confirm].props.text = action.payload.text;
      state.modals[EModals.Confirm].props.accept = action.payload.accept;
      state.modals[EModals.Confirm].props.cancel = action.payload.cancel;
      state.modals[EModals.Confirm].active = true;
    },
    showAddCabinetModal: (state, action: PayloadAction<IAddCabinetProps>) => {
      state.modals[EModals.AddCabinet].data.accept = action.payload.accept;
      state.modals[EModals.AddCabinet].data.cabinet = action.payload.cabinet;
      state.modals[EModals.AddCabinet].active = true;
    },
    showAddToWaitListModal: (
      state,
      action: PayloadAction<IAddWaitListProps>
    ) => {
      state.modals[EModals.AddToWaitListModal].props.accept =
        action.payload.accept;
      state.modals[EModals.AddToWaitListModal].active = true;
    },
    showEditServiceCategoryModal: (
      state,
      action: PayloadAction<EditServiceCategoryProps>
    ) => {
      state.modals[EModals.EditServiceCategory].data.accept =
        action.payload.accept;
      state.modals[EModals.EditServiceCategory].data.category =
        action.payload.category;
      state.modals[EModals.EditServiceCategory].active = true;
    },

    showChangeEmailModal: (state, action: PayloadAction<IChangeEmailProps>) => {
      state.modals[EModals.ChangeEmail].data.accept = action.payload.accept;
      state.modals[EModals.ChangeEmail].data.email = action.payload.email;
      state.modals[EModals.ChangeEmail].active = true;
    },

    showEditServiceModal: (state, action: PayloadAction<IEdirServiceModal>) => {
      state.modals[EModals.EditService].data.service =
        action.payload.data.service;
      state.modals[EModals.EditService].active = true;
    },
    showCreateDoctorInvitationModal: (
      state,
      action: PayloadAction<ICreateDoctorInvitationModal>
    ) => {
      state.modals[EModals.CreateDoctorInvition].data.clinics =
        action.payload.data.clinics;
      state.modals[EModals.CreateDoctorInvition].active = true;
    },
    showScheduleAppointmentModal: (
      state,
      action: PayloadAction<IScheduleAppointmentModal>
    ) => {
      state.modals[EModals.ScheduleAppointment].data.appointment =
        action.payload.data.appointment;
      state.modals[EModals.ScheduleAppointment].active = true;
    },
    showScheduleWaitingAppointmentModal: (
      state,
      action: PayloadAction<IScheduleWaitingAppointmentModal>
    ) => {
      state.modals[EModals.ScheduleWaitingAppointment].data.appointment =
        action.payload.data.appointment;
      state.modals[EModals.ScheduleWaitingAppointment].active = true;
    },
    showCancelAppointmentModal: (
      state,
      action: PayloadAction<ICancelAppointmentProps>
    ) => {
      state.modals[EModals.CancelAppointment].props.accept =
        action.payload.accept;
      state.modals[EModals.CancelAppointment].props.cancel =
        action.payload.cancel;
      state.modals[EModals.CancelAppointment].active = true;
    },
    showCreateEditFormModal: (
      state,
      action: PayloadAction<IShowEditFormModalProps | undefined>
    ) => {
      state.modals[EModals.CreateEditForm].active = true;
      if (action.payload) {
        state.modals[EModals.CreateEditForm].data = action.payload;
      }
    },
    showPreviewFormModal: (
      state,
      action: PayloadAction<IShowPreviewModalProps>
    ) => {
      state.modals[EModals.PreviewForm].active = true;
      state.modals[EModals.PreviewForm].data = action.payload;
    },
    showPatientFilledFormPreviewModal: (
      state,
      action: PayloadAction<IShowPatientFilledFormPreviewModalProps>
    ) => {
      state.modals[EModals.PatientFilledFormPreviewModal].active = true;
      state.modals[EModals.PatientFilledFormPreviewModal].data = action.payload;
    },
  },
});

export const {
  closeModal,
  showModal,
  showAlert,
  closeAlert,
  toggleNavTip,
  togglePatientVisitSettings,
  togglePatientSettings,
  confirmClose,
  confirmOpen,
  showAddCabinetModal,
  showEditServiceModal,
  showChangeEmailModal,
  showCreateDoctorInvitationModal,
  showEditServiceCategoryModal,
  showScheduleAppointmentModal,
  showScheduleWaitingAppointmentModal,
  showCancelAppointmentModal,
  closeCancelAppointmentModal,
  showCreateEditFormModal,
  showPreviewFormModal,
  showAddToWaitListModal,
  showPatientFilledFormPreviewModal,
} = appSlice.actions;
export default appSlice.reducer;
