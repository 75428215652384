import styled from "styled-components";

export const Chat = styled.div<{ isSelected: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  padding: 20px;
  cursor: pointer;
  gap: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  border-left: 2px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.colors.green : "transparent"};

  svg path {
    stroke: ${({ theme }) => theme.colors.gray};
  }
  &:hover {
    background: ${({ theme }) => theme.colors.lightBiege};
  }
`;

export const InfoBlock = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  width: calc(100% - 66px);
  gap: 10px;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  width: 100%;
`;

export const Name = styled.h4`
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.black};
`;
export const DateBlock = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.gray};
`;

export const MessageBlock = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
`;

export const MessageContent = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  flex: 1;
  color: ${({ theme }) => theme.colors.darkGrey};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CountNewMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2px 4.5px;
  min-width: 14px;
  height: 14px;
  background: ${({ theme }) => theme.colors.green};
  border-radius: 10px;
  font-size: 10px;
  line-height: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
`;

export const ImportantMessage = styled(MessageContent)`
  color: ${({ theme }) => theme.colors.green};
`;
