import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import {
  TableListComponent,
  WaitlistFilterComponent,
} from "src/components/WaitlistComponents";
import {
  AggregatedDoctorEntityResponse,
  AggregatedScheduleWaitlistEntityByClientResponse,
  PatientEntityResponse,
  ServiceEntityWithRelationsResponse,
  ServiceSortFields,
  SortingOrders,
  WaitlistStatuses,
  useFindAndCountManyPatientsInWaitlistsByClientLazyQuery,
  useFindAndCountManyScheduleWaitlistsByClientLazyQuery,
  useFindManyServicesLazyQuery,
} from "src/graphql/generated";
import {
  allClinicsItemId,
  defaultWaitListLimit,
  localStorageWaitListsPageNumberName,
} from "src/constants/constants";
import { useClinicsState } from "src/store/clinics/hooks";
import { toast } from "react-toastify";
import { PaginationComponent } from "src/components/ReviewComponents";
import {
  useFindAndCountManyDoctorsByClinicId,
  useFindManyFilesByIdsLazy,
} from "src/graphql/hooks/useQueries";
import { usePagination } from "src/hooks/usePagination";
import { Header, HeadingStyled, Wrapper } from "./styled";

export interface IWaitListsFilters {
  doctorId: string;
  patientId: string;
  serviceId: string;
  status: WaitlistStatuses;
}

function WaitListPage() {
  const { t } = useTranslation();
  const { currentClinicId: clinicId } = useClinicsState();

  const [filters, setFilters] = useState<IWaitListsFilters>({
    doctorId: "",
    patientId: "",
    serviceId: "",
    status: "" as WaitlistStatuses,
  });

  const isNoClinicId = !clinicId || clinicId === allClinicsItemId;

  const [services, setServices] = useState<
    ServiceEntityWithRelationsResponse[]
  >([]);
  const [patients, setPatients] = useState<PatientEntityResponse[]>([]);

  const [getWaitListsLazyQuery, { data, loading: waitListsLoading }] =
    useFindAndCountManyScheduleWaitlistsByClientLazyQuery();

  const [getFile, { data: logoFilesData }] = useFindManyFilesByIdsLazy();

  const [getServices, { loading: servicesLoading }] =
    useFindManyServicesLazyQuery();

  const [getPatiensByClient, { loading: patientsLoading }] =
    useFindAndCountManyPatientsInWaitlistsByClientLazyQuery();

  const { data: doctorsData, loading: doctorsLoading } =
    useFindAndCountManyDoctorsByClinicId({
      variables: {
        schema: {
          clinicId,
        },
      },
      fetchPolicy: "network-only",
      skip: isNoClinicId,
    });

  const avatarFileIds =
    doctorsData?.findAndCountManyDoctorsByClinicId?.doctors?.reduce(
      (accumulator: string[], doctor: AggregatedDoctorEntityResponse) => {
        if (doctor.avatarFileId) {
          accumulator.push(doctor.avatarFileId);
        }
        return accumulator;
      },
      []
    ) ?? [];

  const { offset, currentPage, pages, setCurrentPage } = usePagination({
    limit: defaultWaitListLimit,
    count: data?.findAndCountManyScheduleWaitlistsByClient.count || 0,
    localStorageKey: localStorageWaitListsPageNumberName,
  });

  useEffect(() => {
    if (isNoClinicId) return;

    getWaitListsLazyQuery({
      variables: {
        schema: {
          offset,
          limit: defaultWaitListLimit,
          clinicId,
          ...(filters.doctorId && { doctorId: filters.doctorId }),
          ...(filters.patientId && { patientId: filters.patientId }),
          ...(filters.serviceId && { serviceId: filters.serviceId }),
          ...(filters.status && { status: filters.status }),
        },
      },
      fetchPolicy: "no-cache",
      onError: (error) => {
        if (error) toast.error(error.message);
      },
    });
  }, [clinicId, filters, currentPage]);

  useEffect(() => {
    if (!doctorsData || !avatarFileIds.length) return;
    getFile({
      variables: {
        schema: {
          ids: avatarFileIds,
        },
      },
    });
  }, [doctorsData]);

  // Services
  useEffect(() => {
    if (isNoClinicId) return;
    getServices({
      variables: {
        schema: {
          clinicId,
          sortField: ServiceSortFields.CreatedAt,
          sortOrder: SortingOrders.Descending,
        },
      },
      onCompleted: (response) => {
        const selectedDoctorServices =
          response.findManyServices?.services?.filter((service) =>
            service?.doctorIds?.includes(filters?.doctorId as string)
          );
        if (filters.doctorId) setServices(selectedDoctorServices);
        else {
          setServices(response.findManyServices?.services);
        }
      },
    });
  }, [clinicId, filters?.doctorId]);

  // Patients
  useEffect(() => {
    if (isNoClinicId) return;
    getPatiensByClient({
      variables: {
        schema: { clinicId },
      },
      onCompleted: (response) => {
        setPatients(
          response?.findAndCountManyPatientsInWaitlistsByClient?.patients
        );
      },
      fetchPolicy: "no-cache",
    });
  }, [clinicId]);

  const loading = doctorsLoading || waitListsLoading || patientsLoading;

  return (
    <Wrapper>
      <Header>
        <HeadingStyled as="h3">{t("waitlist.title")}</HeadingStyled>
        <WaitlistFilterComponent
          logoFiles={logoFilesData?.findManyFilesByIds?.files}
          doctors={doctorsData?.findAndCountManyDoctorsByClinicId?.doctors}
          filters={filters}
          setFilters={setFilters}
          services={services}
          servicesLoading={servicesLoading}
          patients={patients}
        />
      </Header>

      <TableListComponent
        waitLists={
          data?.findAndCountManyScheduleWaitlistsByClient
            ?.waitlists as AggregatedScheduleWaitlistEntityByClientResponse[]
        }
        loading={loading}
      />

      <PaginationComponent
        pages={pages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </Wrapper>
  );
}

export default WaitListPage;
