import React, { FunctionComponent } from "react";
import { Svg, SvgProps } from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
  <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M2 4H14"
      stroke="#A5A5A5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6663 4V13.3333C12.6663 14 11.9997 14.6667 11.333 14.6667H4.66634C3.99967 14.6667 3.33301 14 3.33301 13.3333V4"
      stroke="#A5A5A5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33301 3.99999V2.66666C5.33301 1.99999 5.99967 1.33333 6.66634 1.33333H9.33301C9.99967 1.33333 10.6663 1.99999 10.6663 2.66666V3.99999"
      stroke="#A5A5A5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66699 7.33333V11.3333"
      stroke="#A5A5A5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33301 7.33333V11.3333"
      stroke="#A5A5A5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Icon;
