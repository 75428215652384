import { forwardRef } from "react";
import { DateInputStyled } from "./styled";

export const FormItemComponentCustomInput = forwardRef(
  ({ value, onClick, placeholder }: any, ref: any) => (
    <DateInputStyled onClick={onClick} ref={ref}>
      {value || placeholder || ""}
    </DateInputStyled>
  )
);
