import { Route, Routes } from "react-router-dom";

import {
  EditServiceModal,
  NewEventModal,
  NewWaitlistModal,
  QrcodeModal,
  TimetableModal,
  ConfirmModal,
  ForgotPinModal,
  ForgotPassModal,
  CreatePinModal,
  AddServiceModal,
  ForgotEmailModal,
  PatientVisitSettingsModal,
  PatientSettingsModal,
  AddCabinetsModal,
  CreateDoctorInvitationModal,
  ChangeEmailModal,
  EditServiceCategoryModal,
  AddPatientModal,
  SheduleAppointmentModal,
  ScheduleWaitingAppointmentModal,
  CreateEditForm,
  PreviewFormModal,
  PatientFilledFormPreviewModal,
} from "src/components/Modals";
import { MenuComponent } from "src/components/DashboardComponents/MenuComponents";

import { NavbarView } from "src/views/DashboardViews";
import CustomizeFormModal from "src/components/Modals/CustomizeFormModal";
import { useNewAppointment } from "src/hooks/useNewAppointment";
import { AddAppointment } from "src/context/AddAppointment";
import TipComponent from "src/components/DashboardComponents/MenuComponents/TipComponent";

import { dashboardRoutes } from "src/router/dashboardRoutes";
import { ProtectedRoute, ProtectedRouteTypes } from "src/router/protectedRoute";
import { Dashboard, PageStyled } from "./styles";
import NotFoundPage from "./NotFoundPage";

function DashboardPage() {
  const appointmentProvide = useNewAppointment();

  return (
    <AddAppointment.Provider value={appointmentProvide}>
      <ForgotPinModal />
      <ForgotPassModal />
      <ForgotEmailModal />
      <TimetableModal />
      <AddServiceModal />
      <EditServiceModal />
      <NewWaitlistModal />
      <QrcodeModal />
      <CustomizeFormModal />
      <NewEventModal />
      <PatientVisitSettingsModal />
      <PatientSettingsModal />
      <ConfirmModal />
      <CreatePinModal />
      <AddCabinetsModal />
      <CreateDoctorInvitationModal />
      <ChangeEmailModal />
      <EditServiceCategoryModal />
      <AddPatientModal />
      <SheduleAppointmentModal />
      <ScheduleWaitingAppointmentModal />
      <CreateEditForm />
      <PreviewFormModal />
      <PatientFilledFormPreviewModal />
      <Dashboard>
        <MenuComponent />
        <TipComponent />
        <PageStyled>
          <NavbarView />
          <Routes>
            {dashboardRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={<ProtectedRoute {...(route as ProtectedRouteTypes)} />}
              />
            ))}
            <Route key="NotFound" path="*" element={<NotFoundPage />} />
          </Routes>
        </PageStyled>
      </Dashboard>
    </AddAppointment.Provider>
  );
}

export default DashboardPage;
