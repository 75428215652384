import styled from "styled-components";

export const UserBlock = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 15px;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.lightBiege};
  }
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 16px;
    gap: 12px;
  }
`;

export const Account = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.lightBiege};

  svg path {
    stroke: ${({ theme }) => theme.colors.green};
  }
  ${({ theme }) => theme.mediaQueries.ll} {
    width: 46px;
    height: 46px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const InfoBlock = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 8px;
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 6px;
  }
`;

export const Name = styled.h4`
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 16px;
  }
`;

export const List = styled.ul`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
`;

export const Item = styled.li`
  color: ${({ theme }) => theme.colors.gray};
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  font-weight: 500;

  span {
    background: ${({ theme }) => theme.colors.gray};
    width: 4px;
    height: 4px;
    border-radius: 50%;
    display: flex;
    margin: 0 8px;
  }

  &:first-child {
    color: ${({ theme }) => theme.colors.green};

    span {
      display: none;
    }
  }
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 12px;
  }
`;

export const Image = styled.img`
  height: 100%;
  width: auto;
`;
