import { AppointmentStatuses } from "src/graphql/generated";

export enum EDuration {
  Day,
  Week,
  Month,
  Year,
}

export type TAppointmentsFilters = {
  dateFrom: string; // date ISO
  dateTo: string; // date ISO
  roomIds: string[];
  doctorIds: string[];
  patientId: string;
  status: AppointmentStatuses | string;
};

export type TDateTimeRange = {
  min: Date;
  max: Date;
} | null;

export type TCabinetsGroup = { key: string; value: string }[];
export type TDoctorsGroup = { key: string; value: string }[];
