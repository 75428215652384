import { useTranslation } from "react-i18next";
import { EButtonVariants } from "src/UI/Button";

import {
  // CheckBoxRHF,
  DateInputRHF,
  InputAutosizeRHF,
  InputRHF,
  PhoneInputSmallRHF,
} from "src/UI/Input";

import { FieldError, useFormContext } from "react-hook-form";
import DropdownInputRHF from "src/UI/DropdownInput/DropdownInputRHF";

import { Loading } from "src/UI/Loading";
import { PatientGenders } from "src/graphql/generated";
import {
  ButtonStyled,
  Buttons,
  Content,
  Footer,
  InputsRow,
  // ToggleBlock,
} from "./styles";

export interface IProps {
  onCloseModal: () => void;
  loading: boolean;
}

const AddPatientModalForm = ({ onCloseModal, loading }: IProps) => {
  const { t } = useTranslation();

  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  const fields = watch();

  return (
    <>
      <Content>
        <InputsRow>
          <InputRHF
            val={fields.first_name}
            label={t("patients.add.labels.first_name") as string}
            inputType="text"
            errorMessage={errors.first_name as FieldError}
            register={register("first_name")}
          />
          <InputRHF
            val={watch("last_name")}
            label={t("patients.add.labels.last_name") as string}
            inputType="text"
            errorMessage={errors.last_name as FieldError}
            register={register("last_name")}
          />
        </InputsRow>
        <InputsRow>
          <DateInputRHF
            value={fields.birth}
            name="birth"
            placeholder=""
            label={t("patients.add.labels.birth") as string}
            errorMessage={errors.birth as FieldError}
            isMonthAndYear
          />
          <DropdownInputRHF
            name="gender"
            value={fields.gender}
            placeholder=""
            options={[
              {
                item: t("common.gender_male"),
                value: PatientGenders.Male,
              },
              {
                item: t("common.gender_female"),
                value: PatientGenders.Female,
              },
            ]}
            label={t("patients.add.labels.gender") as string}
            adaptedForMobile={false}
            errorMessage={errors.gender as FieldError}
          />
        </InputsRow>
        <InputsRow>
          <InputRHF
            val={fields.email}
            label={t("patients.add.labels.email") as string}
            inputType="email"
            errorMessage={errors.email as FieldError}
            register={register("email")}
          />
          <PhoneInputSmallRHF
            label={t("auth.reg.phone") || ""}
            value={fields.phone}
            name="phone"
          />
        </InputsRow>
        <InputsRow>
          <InputRHF
            val={fields.passportNumber}
            label={t("add_doctor.label.passport") as string}
            inputType="text"
            errorMessage={errors.passportNumber as FieldError}
            register={register("passportNumber")}
          />
        </InputsRow>
        <InputsRow>
          <InputAutosizeRHF
            val={fields.description}
            label={t("patients.add.labels.description") as string}
            inputType="text"
            errorMessage={errors.description as FieldError}
            register={register("description")}
          />
        </InputsRow>
      </Content>
      <Footer>
        {/* TODO: temporary comment */}
        {/* <ToggleBlock>
          <CheckBoxRHF
            id="isEmail"
            name="isEmail"
            label={t("patients.unsubscribe.labels.email")}
            disabled={!!errors?.email || !fields.email}
          />
          <CheckBoxRHF
            id="isSms"
            name="isSms"
            label={t("patients.unsubscribe.labels.sms")}
          />
        </ToggleBlock> */}
        <Buttons>
          <ButtonStyled
            variant={EButtonVariants.Text}
            type="button"
            onClick={onCloseModal}
          >
            {t("common.cancel")}
          </ButtonStyled>
          <ButtonStyled variant={EButtonVariants.Default} disable={loading}>
            {loading && <Loading type="secondary" padding="0" />}
            {t("patients.add.buttons.add")}
          </ButtonStyled>
        </Buttons>
      </Footer>
    </>
  );
};

export default AddPatientModalForm;
