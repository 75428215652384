import { IUser } from "../types";
import { user } from "./search";

export const users: IUser[] = [
  {
    ...user,
    id: 0,
  },
  {
    id: 1,
    name: "Rafał",
    soname: "Nachyna",
    email: "rafal@goclients.pl",
    phone: "+48 571-062-214",
  },
  {
    id: 2,
    name: "Gacek",
    soname: "Przemysław",
    email: "gacek@goclients.pl",
    phone: "+48 571-062-212",
  },
  {
    id: 3,
    name: "Gracjan",
    soname: "Fiedorowicz",
    email: "gracjan@goclients.pl",
    phone: "+48 571-062-213",
  },
];
