import { gql } from "@apollo/client";

export const GET_FIND_MANY_CLINIC_ROOMS_BY_CLINIC_ID = gql`
  query FindManyClinicRoomsByClinicId($schema: FindManyRoomsByClinicIdSchema!) {
    findManyClinicRoomsByClinicId(schema: $schema) {
      rooms {
        clinicId
        id
        name
      }
    }
  }
`;
