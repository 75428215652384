/* eslint-disable react/self-closing-comp */
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { atcb_action } from "add-to-calendar-button";
import { AggregatedScheduleAppointmentEntityWithRelationsByPatientResponse } from "src/graphql/generated";
import { format } from "date-fns";
import { CalendarButton } from "./styled";

interface AddToCalendarComponentProps {
  appointment: AggregatedScheduleAppointmentEntityWithRelationsByPatientResponse;
}

function AddToCalendarComponent({ appointment }: AddToCalendarComponentProps) {
  const { t } = useTranslation();

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { address, name } = appointment.clinic;

  const { dateFrom, minuteFrom, minuteTo } = appointment.schedule;

  const { firstName, lastName } = appointment.doctor;

  const startDate = dateFrom.split("T")[0];

  const getTime = (minutesData: number) => {
    const hours = Math.floor(minutesData / 60);
    const minutes = minutesData % 60;
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    const formattedTime = format(date, "HH:mm");
    return formattedTime;
  };

  const config = {
    name: `${t(
      "app.booking.patient.add_to_calendar.description"
    )} ${name}, ${firstName} ${lastName}`,
    description: `${t(
      "app.booking.patient.add_to_calendar.description"
    )} ${name}, ${firstName} ${lastName}`,
    startDate,
    startTime: getTime(minuteFrom),
    endTime: getTime(minuteTo),
    location: address,
    options: ["Apple", "Google"] as (
      | "Apple"
      | "Google"
      | "iCal"
      | "Microsoft365"
      | "MicrosoftTeams"
      | "Outlook.com"
      | "Yahoo"
    )[],
    timeZone: userTimeZone,
  };

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  return (
    <CalendarButton
      ref={buttonRef}
      onClick={() => atcb_action(config, buttonRef.current as HTMLElement)}
    >
      {t("app.appointment.add_to_calendar")}
    </CalendarButton>
  );
}

export default AddToCalendarComponent;
