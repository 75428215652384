import { gql } from "@apollo/client";

export const UPDATE_DOCTOR_BY_USER_ID_BY_DOCTOR = gql`
  mutation UpdateDoctorByUserIdByDoctor(
    $schema: UpdateDoctorByUserIdByDoctorSchema!
  ) {
    updateDoctorByUserIdByDoctor(schema: $schema) {
      updated
    }
  }
`;
