import { gql } from "@apollo/client";

export const CREATE_FORM_BY_CLIENT = gql`
  mutation CreateFormByClient($schema: CreateFormSchema!) {
    createFormByClient(schema: $schema) {
      id
      name
      serviceId
      clinicId
    }
  }
`;

export const UPDATE_FORM_BY_CLIENT = gql`
  mutation UpdateFormByClient($schema: UpdateFormSchema!) {
    updateFormByClient(schema: $schema) {
      updated
    }
  }
`;
