import styled from "styled-components";

export const ComponentWrapper = styled.div<{ isActive?: boolean }>`
  position: relative;
  width: 300px;
`;

export const InputWrapper = styled.div`
  position: relative;
  z-index: 20;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.03);
  border-radius: 56px;
  height: 48px;
  padding: 6px;
  border: 1px solid #ededed;
  cursor: pointer;

  svg path {
    stroke: ${({ theme }) => theme.colors.green};
  }
`;

export const InputStyled = styled.input`
  display: block;
  border: none;
  background: transparent;
  font-weight: 700;
  font-size: 16px;
  color: #3d3d3d;
  padding: 5px 5px 5px 15px;
  outline: none;
`;

export const IconCover = styled.div`
  width: 38px;
  min-width: 38px;
  height: 38px;
  background: #f8f8f8;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 24px;
  }
`;

export const SuggestionsCover = styled.div`
  position: absolute;
  z-index: 15;
  top: 24px;
  padding-top: 24px;
  left: 0;
  width: 100%;
  background: ${({ theme }) => theme.colors.background};
  border-radius: 0 0 28px 28px;
  box-shadow: 0 5px 25px rgba(33, 44, 65, 0.03);
  max-height: 300px;
  overflow: auto;
`;

export const SuggestionItem = styled.div`
  padding: 10px 20px 10px 20px;
  cursor: pointer;

  &:hover {
    background: #e7f6f5;
  }

  div:nth-child(1) {
    font-weight: 700;
    font-size: 16px;
    color: #3d3d3d;
    margin-bottom: 5px;
  }
  div:nth-child(2) {
    font-weight: 500;
    font-size: 12px;
    color: #828282;
  }
`;

export const SelectedLocation = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 10px 0 15px;

  div:nth-child(1) {
    font-weight: 700;
    font-size: 16px;
    color: #3d3d3d;
    margin-bottom: 5px;
  }
  div:nth-child(2) {
    font-weight: 500;
    font-size: 12px;
    color: #828282;
  }
`;

export const SelectedLocationText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SuggestionItemEmpty = styled.div`
  padding: 10px 20px 10px 20px;
  cursor: default;
`;

export const EmptyTextStyled = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.gray};
`;
