import { Route, Routes } from "react-router-dom";

import { TabsView } from "src/views/BookingsViews";
import { UserRoles } from "src/graphql/generated";
import { ProtectedRoute, ProtectedRouteTypes } from "src/router/protectedRoute";
import { getFilteredLinksByRole } from "src/utils";
import { ILinkTypes } from "src/types";
import { PATHS } from "src/constants/constants";
import TableScheduleAppointmentsPage from "./TableScheduleAppointmentsPage/TableScheduleAppointmentsPage";
import SettingsPage from "./SettingsPage";
// import AddDoctorPage from "./AddDoctorPage";
// import EditDoctorPage from "./EditDoctorPage";
import AddVisitPage from "./AddVisitPage/AddVisitPage";
import WidgetsPage from "./WidgetsPage/WidgetsPage";

const links: ILinkTypes[] = [
  {
    title: "booking.tabs.patients",
    link: `${PATHS.cp}${PATHS.booking}`,
    roles: [UserRoles.Doctor, UserRoles.Client],
  },
  {
    title: "booking.tabs.settings",
    link: `${PATHS.cp}${PATHS.booking}${PATHS.settings}`,
    roles: [UserRoles.Client],
  },
  {
    title: "booking.tabs.widgets",
    link: `${PATHS.cp}${PATHS.booking}${PATHS.widgets}`,
    roles: [UserRoles.Client],
  },
];

const routes = [
  {
    path: "/",
    element: () => (
      <>
        <TabsView links={getFilteredLinksByRole(links)} />
        <TableScheduleAppointmentsPage />
      </>
    ),
    roles: [UserRoles.Doctor, UserRoles.Client],
  },
  {
    path: `${PATHS.settings}/*`,
    element: () => (
      <>
        <TabsView links={getFilteredLinksByRole(links)} />
        <SettingsPage />
      </>
    ),
    roles: [UserRoles.Client],
  },
  // {
  //   path: "/settings/add",
  //   element: () => <AddDoctorPage />,
  //   roles: [UserRoles.Doctor, UserRoles.Client],
  // },
  // {
  //   path: "/settings/edit",
  //   element: () => <EditDoctorPage />,
  //   roles: [UserRoles.Doctor, UserRoles.Client],
  // },
  {
    path: "/settings/addVisit",
    element: () => <AddVisitPage />,
    roles: [UserRoles.Doctor, UserRoles.Client],
  },
  {
    path: "/widgets",
    element: () => (
      <>
        <TabsView links={links} />
        <WidgetsPage />
      </>
    ),
    roles: [UserRoles.Client],
  },
];

function Index() {
  return (
    <Routes>
      {routes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={<ProtectedRoute {...(route as ProtectedRouteTypes)} />}
        />
      ))}
    </Routes>
  );
}

export default Index;
