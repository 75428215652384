import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { TimetableRowComponent } from "src/components/BookingComponents";
import { PlusIcon } from "src/UI/Svg";
import { EButtonVariants } from "src/UI/Button";

import { CardComponent } from "src/components/UserComponents";
import { ButtonStyled, Content, TimetableWrapper, Wrapper } from "./styles";

function AddTimetableView() {
  const { t } = useTranslation();

  const [timeTableItems, setTimeTableItems] = useState([
    { id: 1 },
    { id: 2 },
    { id: 3 },
  ]);

  const removeTableItem = (id: number) => {
    setTimeTableItems((items) => items.filter((i) => i.id !== id));
  };

  const handleRemoveTableItem = (id: number) => () => {
    removeTableItem(id);
  };

  return (
    <Wrapper>
      <CardComponent
        title={t("booking.timetable.title")}
        headerChildren={
          <ButtonStyled
            onClick={() => {
              setTimeTableItems((prev) => [...prev, { id: prev.length + 2 }]);
            }}
            variant={EButtonVariants.Default}
          >
            <PlusIcon /> {t("booking.timetable.add")}
          </ButtonStyled>
        }
      >
        <Content>
          <TimetableWrapper>
            {timeTableItems.map((item) => (
              <TimetableRowComponent
                key={item.id}
                removeItem={handleRemoveTableItem(item.id)}
              />
            ))}
          </TimetableWrapper>
        </Content>
      </CardComponent>
    </Wrapper>
  );
}

export default AddTimetableView;
