import { ChartOptions } from "chart.js";
import { theme } from "src/theme";

const stepSize = 1000;
const fontSize = 16;

const tension = 0.4;
const pointRadius = 2;
const borderWidth = 2;

export const options: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      ticks: {
        stepSize,
      },
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      display: false,
      labels: {
        font: {
          size: fontSize,
        },
      },
    },
    title: {
      display: false,
    },
    tooltip: {
      backgroundColor: theme.colors.green,
      displayColors: false,
    },
  },
};

export const datasetsOptions = {
  borderColor: theme.colors.green,
  backgroundColor: theme.colors.green,
  tension,
  pointRadius,
  borderWidth,
};
