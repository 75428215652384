import { Button } from "src/UI/Button";
import { Heading } from "src/UI/Heading";
import styled from "styled-components";

export const ServicesPageWrapper = styled.div`
  max-width: 62%;
  min-width: 804px;
  z-index: 1;

  ${({ theme }) => theme.mediaQueries.lg} {
    max-width: 100%;
    min-width: 100%;
  }
`;

export const Wrapper = styled.div`
  width: 804px;
  background: ${({ theme }) => theme.colors.white};
  height: 100vh;
  padding: 20px 32px 32px 32px;
  z-index: 1;
`;

export const SideBar = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 25px;
  justify-content: space-between;
`;

export const Label = styled.h2`
  width: 100%;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
`;

export const ServicesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const ServiceButton = styled(Button)`
  display: flex;
  width: 100%;
  max-width: 740px;
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
`;

export const LoadingWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageStyled = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  border-radius: 50%;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  align-items: center;
  gap: 10px;

  width: 38px;
  height: 38px;
  background: ${({ theme }) => theme.colors.lightBiege};
  border-radius: 50%;
`;

export const ClinicWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const HeadingStyled = styled(Heading)`
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
