import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import CopyToClipboard from "react-copy-to-clipboard";
import { useAppAction } from "src/store/app/hooks";
import { EButtonVariants } from "src/UI/Button";
import { CopyIcon } from "src/UI/Svg";
import { EAlert } from "src/store/app/types";
import { useBookingAction } from "src/store/booking/hooks";
import {
  ButtonBlock,
  ButtonStyled,
  Content,
  CopyButton,
  WidgetContent,
  Wrapper,
} from "./styles";

interface ComponentProps {
  link: string;
  name: string | null;
  lang: string;
}

const WidgetComponent: FC<ComponentProps> = ({ link, name, lang }) => {
  const { t } = useTranslation();
  const { onSetClinic } = useBookingAction();
  const { onShowAlert } = useAppAction();

  const handleButtonClick = () => {
    onSetClinic(null);
    window.location.replace(link);
  };

  const linkCode = `
  <a href="${link}" 
    style="
    font-style: normal; 
    text-align: center; 
    border: none; 
    display: -webkit-box;
    display: -webkit-flex; 
    display: -ms-flexbox; 
    display: flex; 
    -webkit-align-items: center; 
    -webkit-box-align: center; 
    -ms-flex-align: center; 
    align-items: center; 
    -webkit-transition: all 0.25s ease; 
    transition: all 0.25s ease; 
    -webkit-align-content: center; 
    -ms-flex-line-pack: center; 
    align-content: center; 
    -webkit-box-pack: center; 
    -webkit-justify-content: center; 
    -ms-flex-pack: center; 
    justify-content: center; cursor: 
    pointer; width: fit-content; 
    max-width: 320px; 
    padding: 16px; 
    gap: 10px; 
    border-radius: 10px; 
    font-weight: 500; 
    font-size: 24px; 
    line-height: 120%; 
    outline: none; 
    background: #0BA495; 
    color: #fff; 
    font-size: 20px; 
    line-height: 100%; 
    max-width: 100%; 
    padding: 16px; 
    font-family: 'SFProDisplay', sans-serif; 
    text-decoration: none;"
    onmouseover="this.style.background='#E7F6F5'; this.style.color='#0BA495'; this.style.outline='none';" 
    onmouseout="this.style.background='#0BA495'; this.style.color='#fff';"
  >
    ${name ? name : t("booking.widgets.button", { lng: lang })}
  </a>
  `;

  return (
    <Wrapper>
      <ButtonBlock onClick={handleButtonClick}>
        <ButtonStyled variant={EButtonVariants.Default}>
          {name ? name : t("booking.widgets.button", { lng: lang })}
        </ButtonStyled>
      </ButtonBlock>
      <Content>
        <WidgetContent>{linkCode}</WidgetContent>
        <CopyToClipboard
          text={linkCode}
          onCopy={() =>
            onShowAlert({
              alertType: EAlert.Success,
              content: (
                <>
                  <CopyIcon />
                  {t("booking.widgets.copied")}
                </>
              ),
            })
          }
        >
          <CopyButton>
            <CopyIcon />
          </CopyButton>
        </CopyToClipboard>
      </Content>
    </Wrapper>
  );
};

export default WidgetComponent;
