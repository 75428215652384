import { PlusIcon } from "src/UI/Svg";
import { EButtonVariants } from "src/UI/Button";
import { SearchComponentRHF } from "src/UI/Input";
import { FieldError, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ReactNode, useCallback, useEffect } from "react";
import { EModals } from "src/store/app/types";
import { useAppAction } from "src/store/app/hooks";
import { useAuthContext } from "src/context/AuthContext";
import { usePatientsAction, usePatientsState } from "src/store/patients/hooks";
import {
  CountedPatientsResponse,
  ServiceEntityWithRelationsResponse,
} from "src/graphql/generated";
import { getCurrencySimbolByCode } from "src/utils";
import { useBookingState } from "src/store/booking/hooks";
import {
  LeftWrapper,
  Wrapper,
  Header,
  HeadingStyled,
  Content,
  Row,
  RowItem,
  StyledDropdownInputRHF,
  EmptyTextStyled,
  ButtonHeaderStyled,
  NewPatientComponentWrap,
  ServiceInfoWrap,
  ServiceDescrWrap,
  ServicePriceWrap,
  ServiceInfoTitle,
  ServicePrice,
  ServiceDescr,
  ServiceCurrencie,
} from "./styles";
import NewPatientComponent from "../NewPatientComponent/NewPatientComponent";
import { EViewsTypes } from "../types";

interface ComponentProps {
  clinicsOpts: Array<{ item: string | ReactNode; value: string | number }>;
  doctorsOpts: Array<{ item: string | ReactNode; value: string | number }>;
  servicesOpts: Array<{ item: string | ReactNode; value: string | number }>;
  patientsOpts: Array<{ item: string | ReactNode; value: string | number }>;
  cabinetsOpts: Array<{ item: string | ReactNode; value: string | number }>;
  services: ServiceEntityWithRelationsResponse[];
  searchPatient: string;
  filterView: EViewsTypes;
  setSearchPatient: (val: string) => void;
  fetchMore: () => Promise<unknown>;
  data: CountedPatientsResponse | null;
}

const LeftSideComponent = ({
  clinicsOpts,
  doctorsOpts,
  servicesOpts,
  patientsOpts,
  cabinetsOpts,
  services,
  searchPatient,
  filterView,
  setSearchPatient,
  fetchMore,
  data,
}: ComponentProps) => {
  const { t } = useTranslation();
  const {
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();
  const { newPatient } = usePatientsState();
  const { onSetPatient } = usePatientsAction();
  const { rescheduleAppointment, rescheduleWaitlist } = useBookingState();

  useEffect(() => {
    if (newPatient) {
      setValue("patientId", newPatient?.id);
      trigger("patientId");
      setSearchPatient("");
    }
  }, [newPatient]);

  const { onShowModal } = useAppAction();
  const { isClient } = useAuthContext();

  const dataForm = watch();

  const currentService = services?.find(
    (service) => service.id === dataForm.serviceId
  );

  const handleShowAddPatientModal = useCallback(() => {
    onShowModal(EModals.AddPatient);
  }, [onShowModal]);

  const handleSearchAddEntity = () => {
    handleShowAddPatientModal();
  };

  const handleSelectClinic = () => {
    if (isClient) setValue("doctorId", null);
    setValue("serviceId", null);
    setValue("roomId", null);
    setSearchPatient("");
  };

  const handleSelectDoctor = () => {
    setValue("date", null);
    setValue("minuteFrom", null);
    setValue("minuteTo", null);
    setValue("serviceId", null);
    setValue("roomId", null);
  };

  const handleSelectService = () => {
    setValue("date", null);
    setValue("minuteFrom", null);
    setValue("minuteTo", null);
  };

  const handleSelectPatient = () => {
    onSetPatient(null);
  };

  return (
    <LeftWrapper>
      <Wrapper>
        <Header>
          <HeadingStyled as="h3">{t("calendar.event.title")}</HeadingStyled>
        </Header>
        <Content>
          <Row>
            <RowItem>
              <StyledDropdownInputRHF
                name="clinicId"
                value={dataForm.clinicId}
                placeholder=""
                options={clinicsOpts || []}
                label={t("map.title" as string)}
                adaptedForMobile={false}
                errorMessage={errors.clinicId as FieldError}
                onSelect={handleSelectClinic}
                isDisabled={!!rescheduleAppointment}
              />
            </RowItem>
            {isClient && (
              <RowItem>
                {doctorsOpts.length ? (
                  <StyledDropdownInputRHF
                    name="doctorId"
                    value={dataForm.doctorId}
                    placeholder=""
                    options={doctorsOpts || []}
                    label={t("booking.doctors.title" as string)}
                    adaptedForMobile={false}
                    errorMessage={errors.doctorId as FieldError}
                    onSelect={handleSelectDoctor}
                    isDisabled={!!rescheduleAppointment}
                  />
                ) : (
                  <EmptyTextStyled>
                    {t("app.doctors_not_added")}
                  </EmptyTextStyled>
                )}
              </RowItem>
            )}
          </Row>
        </Content>
      </Wrapper>
      <Wrapper>
        <Header>
          <HeadingStyled as="h3">{t("calendar.filters.service")}</HeadingStyled>
        </Header>
        <Content>
          <Row>
            <RowItem>
              {servicesOpts.length ? (
                <StyledDropdownInputRHF
                  name="serviceId"
                  value={dataForm.serviceId}
                  placeholder=""
                  options={servicesOpts || []}
                  label={t("booking.services.title" as string)}
                  adaptedForMobile={false}
                  errorMessage={errors.serviceId as FieldError}
                  onSelect={handleSelectService}
                  isDisabled={!!rescheduleAppointment}
                />
              ) : (
                <EmptyTextStyled>{t("app.services_not_added")}</EmptyTextStyled>
              )}
            </RowItem>
            <RowItem>
              {!!cabinetsOpts.length && filterView !== EViewsTypes.WaitList && (
                <StyledDropdownInputRHF
                  name="roomId"
                  value={dataForm.roomId}
                  placeholder=""
                  options={cabinetsOpts || []}
                  label={t("map.cabinets.title" as string)}
                  adaptedForMobile={false}
                  errorMessage={errors.roomId as FieldError}
                  onSelect={handleSelectService}
                  isDisabled={!!rescheduleAppointment}
                />
              )}
            </RowItem>
          </Row>
          {currentService && (
            <ServiceInfoWrap>
              <ServiceDescrWrap>
                {currentService.description && (
                  <>
                    <ServiceInfoTitle>
                      {t("booking.edit_service.label.description")}
                    </ServiceInfoTitle>
                    <ServiceDescr>{currentService.description}</ServiceDescr>
                  </>
                )}
              </ServiceDescrWrap>
              <ServicePriceWrap>
                <ServiceInfoTitle>
                  {t("modals.patient_settings.price")}
                </ServiceInfoTitle>
                <ServicePrice>
                  {currentService.price}
                  <ServiceCurrencie>
                    {getCurrencySimbolByCode(
                      currentService.currencyCode as string
                    )}
                  </ServiceCurrencie>
                </ServicePrice>
              </ServicePriceWrap>
            </ServiceInfoWrap>
          )}
        </Content>
      </Wrapper>
      <Wrapper>
        <Header>
          <HeadingStyled as="h3">{t("history.patient")}</HeadingStyled>
          {!rescheduleAppointment ? (
            <ButtonHeaderStyled
              type="button"
              onClick={handleShowAddPatientModal}
              variant={EButtonVariants.Default}
            >
              <PlusIcon />
              {t("patients.button")}
            </ButtonHeaderStyled>
          ) : null}
        </Header>
        <Content>
          <Row>
            <RowItem>
              {!rescheduleAppointment && !rescheduleWaitlist && (
                <SearchComponentRHF
                  name="patientId"
                  placeholder={t("calendar.search.buttons.find") as string}
                  value={dataForm.patientId}
                  defaultSearchValue={searchPatient}
                  options={patientsOpts || []}
                  handleInputChange={(val: string) => {
                    setSearchPatient(val);
                  }}
                  handleSearchAddEntity={handleSearchAddEntity}
                  handleSelect={handleSelectPatient}
                  errorMessage={errors.patientId as FieldError}
                  isClearable={!rescheduleAppointment}
                  fetchMore={fetchMore}
                  data={data}
                />
              )}
              {newPatient && (
                <NewPatientComponentWrap>
                  <NewPatientComponent patient={newPatient} />
                </NewPatientComponentWrap>
              )}
            </RowItem>
          </Row>
        </Content>
      </Wrapper>
    </LeftWrapper>
  );
};

export default LeftSideComponent;
