import { Button } from "src/UI/Button";
import { Dropdown } from "src/UI/Dropdown";
import { SliderHorizontalIcon } from "src/UI/Svg";
import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  gap: 8px;
  // ${({ theme }) => theme.mediaQueries.ll} {
  //   gap: 6px;
  // }
`;

export const FilterIconStyled = styled(SliderHorizontalIcon)`
  cursor: pointer;

  path {
    stroke: #a5a5a5 !important;
  }
`;

export const ButtonStyled = styled(Button)<{ isMenuButton?: boolean }>`
  display: flex;
  padding: 8px 10px;
  align-items: center;
  align-self: ${({ isMenuButton }) => (isMenuButton ? "flex-end" : "auto")};
  margin-right: ${({ isMenuButton }) => (isMenuButton ? "0px" : "8px")};
  gap: 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  width: fit-content;
  border-radius: 5px;

  // ${({ theme }) => theme.mediaQueries.ll} {
  //   padding: 6px 8px;
  //   font-size: 12px;
  // }
`;
export const DropdownFilter = styled(Dropdown)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 16px;
  font-weight: 700;
  line-height: 100%;
  background: ${({ theme }) => theme.colors.lightBiege};
  border-radius: 5px;

  & div {
    border: none;
  }

  & .selection div {
    color: ${({ theme }) => theme.colors.gray};
  }

  // ${({ theme }) => theme.mediaQueries.ll} {
  //   padding: 6px 8px;
  //   font-size: 12px;
  // }
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  align-items: center;
  gap: 10px;

  width: 16px;
  height: 16px;
  background: ${({ theme }) => theme.colors.lightBiege};
  border-radius: 56px;
`;

export const ImageStyled = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  border-radius: 50%;
`;

export const UserOptionStyled = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  gap: 4px;
  svg:first-child {
    margin-right: 6px;
    path {
      fill: ${({ theme }) => theme.colors.gray};
      stroke: ${({ theme }) => theme.colors.gray};
    }
  }
`;

export const Text = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.black};
  span {
    color: ${({ theme }) => theme.colors.gray};
  }
`;
// TODO commeted because it media query maybe be needs in the future
// ${({ theme }) => theme.mediaQueries.ll} {
//   font-size: 12px;
// }

export const DropdownStyled = styled(Dropdown)`
  border-radius: 5px;
  min-width: 255px;
  & > div:first-child {
    padding: 8px;
  }
  & .placeholder {
    color: ${({ theme }) => theme.colors.gray};
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  position: absolute;
  top: 0;
  background: ${({ theme }) => theme.colors.white};
  z-index: 10;
`;

export const FieldWrapStyled = styled.div`
  display: flex;
  position: relative;
`;

export const Label = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  margin-right: 6px;
  color: ${({ theme }) => theme.colors.gray};
  // ${({ theme }) => theme.mediaQueries.ll} {
  //   font-size: 12px;
  //   margin-right: 6px;
  // }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  height: 100%;
`;

export const DateWarpper = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 15px;
`;

export const DateVisitTitle = styled.div`
  color: ${({ theme }) => theme.colors.gray};
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
`;
export const DateBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DateInputStyled = styled.div`
  padding: 8px 10px;
  gap: 4px;
  min-width: 140px;
  min-height: 32px;
  background: ${({ theme }) => theme.colors.lightBiege};
  border-radius: 5px;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.black};
  // ${({ theme }) => theme.mediaQueries.ll} {
  //   font-size: 12px;
  // }
`;
