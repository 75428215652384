import { AppointmentStatuses } from "src/graphql/generated";
import { useTranslation } from "react-i18next";
import { theme } from "src/theme";
import { PointIcon } from "src/UI/Svg";
import { getAppointmetStatusWithTranslation } from "src/utils/translate";
import { StatusText, StatusWrapper } from "./styled";

interface IAppointmentStatusTextProps {
  status: AppointmentStatuses;
}

const AppointmentStatusText = ({ status }: IAppointmentStatusTextProps) => {
  const { t } = useTranslation();

  return (
    <StatusText>{getAppointmetStatusWithTranslation(t, status)}</StatusText>
  );
};

interface IPatientAppointmentsStatusProps {
  status?: AppointmentStatuses;
}

export function PatientAppointmentsStatus({
  status,
}: IPatientAppointmentsStatusProps) {
  switch (status) {
    case AppointmentStatuses.Successful:
      return (
        <StatusWrapper color={theme.colors.darkGrey}>
          <PointIcon fill={theme.colors.darkGrey} />
          <AppointmentStatusText status={status} />
        </StatusWrapper>
      );

    case AppointmentStatuses.ConfirmedByApplication:
      return (
        <StatusWrapper color={theme.colors.darkGrey}>
          <PointIcon fill={theme.colors.darkGrey} />
          <AppointmentStatusText status={status} />
        </StatusWrapper>
      );

    case AppointmentStatuses.ConfirmedByPatient:
      return (
        <StatusWrapper color={theme.colors.green}>
          <PointIcon fill={theme.colors.green} />
          <AppointmentStatusText status={status} />
        </StatusWrapper>
      );

    case AppointmentStatuses.New:
      return (
        <StatusWrapper color={theme.colors.darkGrey}>
          <PointIcon fill={theme.colors.darkGrey} />
          <AppointmentStatusText status={status} />
        </StatusWrapper>
      );

    case AppointmentStatuses.Canceled:
      return (
        <StatusWrapper color={theme.colors.darkGrey}>
          <PointIcon fill={theme.colors.darkGrey} />
          <AppointmentStatusText status={status} />
        </StatusWrapper>
      );

    case AppointmentStatuses.Missed:
      return (
        <StatusWrapper color={theme.colors.red}>
          <PointIcon fill={theme.colors.red} />
          <AppointmentStatusText status={status} />
        </StatusWrapper>
      );

    default:
      return null;
  }
}
