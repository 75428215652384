import { Heading } from "src/UI/Heading";
import { StatisticItemComponent } from "src/components/DashboardComponents/StatisticComponents";
import { ReportComponent } from "src/components/ReviewComponents";
import styled from "styled-components";

export const Wrapper = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  padding: 20px;
  gap: 15px;
  background: ${({ theme, isSelected }) =>
    theme.colors[isSelected ? "lightBiege" : "background"]};
  border: 2px solid ${({ theme }) => theme.colors.borderInputDefault};
  border-left: 2px solid
    ${({ theme, isSelected }) =>
      theme.colors[isSelected ? "green" : "borderInputDefault"]};
  border-radius: 5px;
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 12px;
    padding: 16px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  align-items: center;
  padding: 16px;
  gap: 10px;

  width: 56px;
  height: 56px;
  background: ${({ theme }) => theme.colors.lightBiege};
  border-radius: 56px;

  svg {
    width: 32px;
    height: 32px;
  }
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 12px;
    gap: 8px;
    width: 46px;
    height: 46px;
    svg {
      width: 26px;
      height: 26px;
    }
  }
`;

export const DescBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: flex-start;
  align-items: flex-start;
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 12px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 6px;
  }
`;

export const HeadingStyled = styled(Heading)`
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 16px;
  }
`;

export const Address = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.darkGrey};
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 12px;
  }
`;
export const Actions = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  gap: 10px;
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 12px;
  }
`;

export const ButtonIcon = styled.button<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 50%;

  svg {
    width: 16px;
    height: 16px;

    path {
      stroke: ${({ theme }) => theme.colors.gray};
    }
  }

  &:hover {
    background: ${({ theme, isSelected }) =>
      theme.colors[isSelected ? "background" : "lightBiege"]};
    opacity: 0.8;
  }

  &:first-child {
    svg path {
      stroke: ${({ theme }) => theme.colors.green};
    }
  }

  ${({ theme }) => theme.mediaQueries.ll} {
    svg {
      width: 12px;
      height: 12px;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const RatingBlock = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 8px;
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 6px;
  }
`;

export const Rating = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.black};
  padding: 8px 10px;
  border: 1px solid ${({ theme }) => theme.colors.grayC4};
  border-radius: 56px;

  svg {
    width: 16px;
    height: 16px;
  }

  span {
    margin-left: -6px;
    color: ${({ theme }) => theme.colors.gray};
  }
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 6px;
    padding: 6px 8px;
    font-size: 12px;
    svg {
      width: 12px;
      height: 12px;
    }
  }
`;

export const IdBlock = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 100%;
  text-align: right;
  color: ${({ theme }) => theme.colors.grayC4};
`;

export const StatisticItem = styled(StatisticItemComponent)`
  box-shadow: none;
  width: 100%;
  background: ${({ theme }) => theme.colors.background};
  max-width: calc(50% - 5px);
  & > div {
    &:nth-child(2) {
      margin-top: 0;
    }
  }
`;

export const RepostStyled = styled(ReportComponent)`
  box-shadow: none;
  width: 100%;
  background: ${({ theme }) => theme.colors.background};
  max-width: calc(50% - 5px);
  & > div {
    &:nth-child(2) {
      height: 52px;
    }
  }
`;

export const ImageStyled = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  border-radius: 50%;
`;
