import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from "react";

import { useTranslation } from "react-i18next";
import { EButtonVariants } from "src/UI/Button";
import { PlusIcon } from "src/UI/Svg";
import {
  AggregatedDoctorEntityResponse,
  FileEntityResponse,
  PatientEntityResponse,
  ServiceEntityWithRelationsResponse,
  WaitlistStatuses,
} from "src/graphql/generated";
import DoctorOptionComponent from "src/components/BookingComponents/ScheduleAppointmentsFilterComponent/DoctorOptionComponent";
import { useOutsideClick } from "src/hooks/useOutsideClick";
import { IWaitListsFilters } from "src/pages/DashboardPages/WaitListPage/WaitListPage";
import { Loading } from "src/UI/Loading";
import { useNavigate } from "react-router-dom";
import { PATHS } from "src/constants/constants";
import { EViewsTypes } from "src/pages/DashboardPages/BookingPages/AddVisitPage/types";
import {
  ButtonStyled,
  DropdownStyled,
  FilterIconStyled,
  FilterWrapper,
  Wrapper,
  DropdownAutocompleteStyled,
} from "./styled";

interface IWaitlistFilterComponent {
  logoFiles: Array<FileEntityResponse>;
  doctors: AggregatedDoctorEntityResponse[];
  filters: IWaitListsFilters;
  setFilters: Dispatch<SetStateAction<IWaitListsFilters>>;
  services: ServiceEntityWithRelationsResponse[];
  servicesLoading: boolean;
  patients: PatientEntityResponse[];
}

function WaitlistFilterComponent({
  logoFiles,
  doctors,
  filters,
  setFilters,
  services,
  servicesLoading,
  patients,
}: IWaitlistFilterComponent) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isShowMenu, setIsShowMenu] = useState(false);
  const dropdown = useRef<HTMLDivElement>(null);

  const optsDoctors: Array<{ item: ReactNode; value: string }> = useMemo(
    () =>
      doctors?.map((doctor: AggregatedDoctorEntityResponse) => ({
        item: <DoctorOptionComponent profile={doctor} logoFiles={logoFiles} />,
        value: doctor?.doctorId || "",
      })),
    [t, doctors, logoFiles]
  );

  const servicesOpts = services.map((service) => ({
    item: service.name,
    value: service.id,
  }));

  const patientsOpts = patients.map((patient) => ({
    key: `${patient.firstName} ${patient.lastName}`,
    value: patient.id,
  }));

  const statusOpts = Object.keys(WaitlistStatuses).map((key) => ({
    item: key,
    value: key,
  }));

  const onChange = (key: string, val: string | boolean) => {
    setFilters((prevState: IWaitListsFilters) => ({
      ...prevState,
      [key]: val,
    }));
  };

  const onShowMenu = () => setIsShowMenu(true);

  useOutsideClick({
    ref: dropdown,
    handler: () => setIsShowMenu(false),
  });

  const resetFiltersHandler = () => {
    setFilters((prev) => {
      return {
        ...prev,
        ...{
          doctorId: "",
          patientId: "",
          serviceId: "",
          status: "" as WaitlistStatuses,
        },
      };
    });
  };

  return (
    <Wrapper>
      <FilterIconStyled onClick={onShowMenu} />
      {isShowMenu ? (
        <FilterWrapper
          isEngLang={i18n.resolvedLanguage === "en"}
          ref={dropdown}
        >
          <DropdownStyled
            isShowOnlyPlaceholder={false}
            value={filters.doctorId}
            placeholder={t("booking.patients.table.provider")}
            onSelect={(v: string) => onChange("doctorId", v)}
            options={optsDoctors?.length ? optsDoctors : []}
            label=""
          />
          {servicesLoading ? (
            <Loading padding="0" />
          ) : (
            <DropdownStyled
              isShowOnlyPlaceholder={false}
              value={filters.serviceId}
              placeholder={t("waitlist.table.header.service")}
              onSelect={(v: string) => onChange("serviceId", v)}
              options={servicesOpts?.length ? servicesOpts : []}
              label=""
            />
          )}

          <DropdownAutocompleteStyled
            value={filters.patientId}
            placeholder={t("waitlist.table.header.patient_name") as string}
            onChange={(v: string) => onChange("patientId", v)}
            label={t("waitlist.table.header.patient")}
            options={patientsOpts}
          />

          <DropdownStyled
            isShowOnlyPlaceholder={false}
            value={filters.status}
            placeholder={t("waitlist.table.header.status")}
            onSelect={(v: string) => onChange("status", v)}
            options={statusOpts?.length ? statusOpts : []}
            label=""
          />

          <ButtonStyled
            variant={EButtonVariants.Gray}
            onClick={resetFiltersHandler}
            isMenuButton
          >
            {t("booking.patients.filters.reset_filters")}
          </ButtonStyled>
        </FilterWrapper>
      ) : null}
      <ButtonStyled
        onClick={() => {
          navigate(
            `${PATHS.cp}${PATHS.booking}${PATHS.settings}${PATHS.addVisit}?viewType=${EViewsTypes.WaitList}`
          );
        }}
        variant={EButtonVariants.Default}
      >
        <PlusIcon />
        {t("waitlist.filters.button")}
      </ButtonStyled>
    </Wrapper>
  );
}

export default WaitlistFilterComponent;
