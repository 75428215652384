import { gql } from "@apollo/client";

export const UPDATE_DOCOTOR_AVATAR_BY_CLIENT = gql`
  mutation UpdateDoctorAvatarFileIdByClient(
    $schema: UpdateDoctorAvatarFileIdByClientSchema!
  ) {
    updateDoctorAvatarFileIdByClient(schema: $schema) {
      updated
    }
  }
`;
