import React from "react";
import styled from "styled-components";
import { useAppState } from "../../../store/app/hooks";

const Tip = styled.div<{ top: number }>`
  position: fixed;
  z-index: 1010;
  padding: 6px 8px;
  gap: 10px;
  display: flex;
  opacity: ${({ top }) => (top ? 1 : 0)};
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.green};
  top: ${({ top }) => `${top}px`};
  left: 110px;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.white};
  white-space: nowrap;
  transition: none;

  span {
    position: absolute;
    top: 50%;
    transform: translate(-100%, -50%);
    left: 1px;
    border: 8px solid transparent;
    border-right: 6px solid ${({ theme }) => theme.colors.green};
    border-radius: 4px;
    display: flex;
  }
`;

function TipComponent() {
  const {
    nav: { tip },
  } = useAppState();
  return (
    <Tip top={tip.top}>
      <span />
      {tip.title}
    </Tip>
  );
}

export default TipComponent;
