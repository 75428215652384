import { TAppointmentByClient, TScheduleAppointmentByRole } from "src/types";
import { getDateFromMinutesStartDay } from "src/utils";
import { format } from "date-fns";
import { useAuthContext } from "src/context/AuthContext";
import { getAppointmetStatusWithTranslation } from "src/utils/translate";
import { useTranslation } from "react-i18next";
import { LinkStyled, Row } from "./styled";

interface IRowComponentProps {
  appointment: TScheduleAppointmentByRole;
  handleShowModal: (id: string) => () => void;
}

function RowComponent({ appointment, handleShowModal }: IRowComponentProps) {
  const { isClient } = useAuthContext();
  const { t } = useTranslation();

  const doctor = (appointment as TAppointmentByClient)?.doctor;

  return (
    <Row>
      <td>
        <LinkStyled onClick={handleShowModal(appointment?.id as string)}>
          {appointment?.patient.firstName} {appointment?.patient.lastName}
        </LinkStyled>
      </td>
      <td>{appointment?.patient.phoneNumber}</td>
      <td>{getAppointmetStatusWithTranslation(t, appointment?.status)}</td>
      <td>
        {format(
          getDateFromMinutesStartDay(appointment?.schedule.minuteFrom) as Date,
          "HH:mm"
        )}
      </td>
      {isClient && doctor ? (
        <td>
          {doctor.firstName} {doctor.lastName}
        </td>
      ) : null}
      <td>{appointment?.service?.name}</td>
    </Row>
  );
}

export default RowComponent;
