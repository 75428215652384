import { gql } from "@apollo/client";

export const CREATE_CLINIC = gql`
  mutation CreateClinic($schema: CreateClinicSchema!) {
    createClinic(schema: $schema) {
      address
      city
      code
      companyId
      createdAt
      description
      geographicalCoordinates {
        latitude
        longitude
      }
      houseNumber
      id
      index
      licenseNumber
      logoFileId
      name
      phoneNumber
      rooms {
        clinicId
        id
        name
      }
      workingDays {
        clinicId
        day
        fromMinute
        id
        toMinute
      }
    }
  }
`;
