import { gql } from "@apollo/client";

export const CREATE_CLINIC_WORKING_DAY_BY_CLINIC_ID = gql`
  mutation CreateClinicWorkingDay(
    $schema: CreateClinicWorkingDayWithClinicIdSchema!
  ) {
    createClinicWorkingDay(schema: $schema) {
      clinicId
      day
      fromMinute
      id
      toMinute
    }
  }
`;
