import { useTranslation } from "react-i18next";
import { useState } from "react";
import {
  SearchByClientUnion,
  SearchByDoctorUnion,
  SearchTypes,
} from "src/graphql/generated";
import { useLocation } from "react-router-dom";
import { useSearchByRoleFetchMore } from "src/hooks/search/useSearchByRoleFetchMore";
import { Loading } from "src/UI/Loading";
import { TScheduleAppointmentByRole } from "src/types";
import { useAppAction } from "src/store/app/hooks";
import { EntitiesByTypeComponent } from "src/components/DashboardComponents/SearchComponents/EntitiesByTypeComponent/EntitiesByTypeComponent";
import { useSearchCountByRole } from "src/hooks/search/useSearchCountByRole";
import { Header, HeadingStyled, LoadingWrap, Tab, Tabs } from "./styled";

function SearchResultView() {
  const { t } = useTranslation();
  const { onShowScheduleAppointmentModal } = useAppAction();
  const [filter, setFilter] = useState<SearchTypes>(SearchTypes.Patient);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const search = queryParams.get("search");

  const { data, loading, node, isLoading } = useSearchByRoleFetchMore({
    filter,
    query: search,
  });

  const { patientsCount, appointmentsCount } = useSearchCountByRole({
    query: search,
  });

  const handleSetFilter = (status: SearchTypes) => () => {
    setFilter(status);
  };

  const count = data?.count || 0;

  const entitiesLength = data?.entities?.length || 0;

  const handleShowModal = (appointment: TScheduleAppointmentByRole) => () => {
    onShowScheduleAppointmentModal({
      data: {
        appointment,
      },
    });
  };

  return (
    <>
      <Header>
        <HeadingStyled as="h3">{t("search.modal_title")}</HeadingStyled>
        <Tabs>
          <Tab
            isActive={filter === SearchTypes.Patient}
            onClick={handleSetFilter(SearchTypes.Patient)}
          >
            {t("search.tabs.patients")} <span>{patientsCount}</span>
          </Tab>
          <Tab
            isActive={filter === SearchTypes.Appointment}
            onClick={handleSetFilter(SearchTypes.Appointment)}
          >
            {t("search.tabs.visits")} <span>{appointmentsCount}</span>
          </Tab>
        </Tabs>
      </Header>
      <EntitiesByTypeComponent
        filter={filter}
        entities={
          data?.entities as SearchByClientUnion[] | SearchByDoctorUnion[]
        }
        loading={loading}
        handleShowModal={handleShowModal}
      />
      {isLoading ? (
        <LoadingWrap>
          <Loading padding="0" />
        </LoadingWrap>
      ) : null}
      {count > entitiesLength ? <div ref={node} /> : null}
    </>
  );
}

export default SearchResultView;
