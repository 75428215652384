import { Button } from "src/UI/Button";
import { CardComponent } from "src/components/UserComponents";
import styled from "styled-components";

export const CardStyled = styled(CardComponent)`
  max-width: 412px;
  min-height: 340px;
  width: 100%;
`;
export const ButtonStyled = styled(Button)`
  padding: 8px 10px;
  font-size: 16px;
  line-height: 100%;
  width: fit-content;
  border-radius: 5px;

  svg {
    path {
      stroke: ${({ theme }) => theme.colors.white};
    }
  }
`;
export const Content = styled.div`
  width: 100%;
  display: flex;
  gap: 0;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 20px;
  padding-bottom: 16px;
`;
export const CabinetStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
`;
export const EmptyTextStyled = styled.div`
  width: 100%;
  padding-top: 24px;
  color: ${({ theme }) => theme.colors.gray};
`;
export const ErrorPhotoStyled = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.red};
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 12px;
  }
`;
export const BtnStyled = styled.button`
  width: 32px;
  height: 32px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 10px;
  gap: 8px;
  margin-left: 10px;
  background: ${({ theme }) => theme.colors.background};
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
  line-height: 100%;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.black};
  svg {
    width: 12px;
    height: 12px;
    path {
      stroke: ${({ theme }) => theme.colors.gray};
      stroke-width: 2;
    }
  }
  &:hover {
    background: ${({ theme }) => theme.colors.greenHover};
    color: ${({ theme }) => theme.colors.green};
    svg path {
      stroke: ${({ theme }) => theme.colors.green};
    }
  }
`;
