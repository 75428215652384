import { gql } from "@apollo/client";

export const GET_FIND_SERVICE_CATEGORY_BY_ID = gql`
  query FindServiceCategoryById($schema: FindServiceCategoryByIdSchema!) {
    findServiceCategoryById(schema: $schema) {
      id
      name
    }
  }
`;
