import React, { useEffect } from "react";
import { useFindManyFilesByIdsLazy } from "src/graphql/hooks/useQueries";
import {
  ServiceEntityWithRelationsResponse,
  ServiceSortFields,
  SortingOrders,
  useFindClinicByIdLazyQuery,
  useFindManyServicesByPatientQuery,
} from "src/graphql/generated";
import { EButtonVariants } from "src/UI/Button";
import { Loading } from "src/UI/Loading";
import { useTranslation } from "react-i18next";
import { useBookingAction, useBookingState } from "src/store/booking/hooks";
import { Link, useLocation } from "react-router-dom";
import { PATHS } from "src/constants/constants";
import Layout from "src/components/PatientBookingComponents/Layout/Layout";
import {
  Label,
  LoadingWrap,
  ServiceButton,
  ServicesPageWrapper,
  ServicesWrapper,
} from "./styled";

function ServicesPage() {
  const { t, i18n } = useTranslation();
  const { currentClinic } = useBookingState();
  const { onSetService, onSetLocation, onSetClinic } = useBookingAction();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const langCode = queryParams.get("langCode");
  const clinicId = queryParams.get("clinicId") as string;
  const clientId = queryParams.get("clientId");

  const [getClinicById] = useFindClinicByIdLazyQuery();
  const [getFile] = useFindManyFilesByIdsLazy();

  const { data, loading } = useFindManyServicesByPatientQuery({
    variables: {
      schema: {
        clinicId,
        sortField: ServiceSortFields.CreatedAt,
        sortOrder: SortingOrders.Descending,
      },
    },
  });

  useEffect(() => {
    if (langCode) i18n.changeLanguage(langCode as string);
  }, []);

  useEffect(() => {
    if (!currentClinic && clinicId) {
      getClinicById({
        variables: {
          schema: {
            id: clinicId,
          },
        },
        onCompleted(response) {
          if (response) {
            onSetLocation({
              center: [
                response?.findClinicById?.geographicalCoordinates?.longitude,
                response?.findClinicById?.geographicalCoordinates?.latitude,
              ],
            });
            if (!response?.findClinicById?.logoFileId) {
              onSetClinic({ clinic: response?.findClinicById, logoUrl: null });
            } else {
              getFile({
                variables: {
                  schema: {
                    ids: [response?.findClinicById?.logoFileId],
                  },
                },
                onCompleted(responseFile) {
                  onSetClinic({
                    clinic: response?.findClinicById,
                    logoUrl: responseFile?.findManyFilesByIds?.files?.[0]?.url,
                  });
                },
              });
            }
          }
        },
      });
    }
  }, [currentClinic, clinicId]);

  const onSelectHandler =
    (service: ServiceEntityWithRelationsResponse) => () => {
      onSetService({ service });
    };

  return (
    <ServicesPageWrapper>
      <Layout currentClinic={currentClinic}>
        <Label>{t("app.booking.patient.select_service")}</Label>
        <ServicesWrapper>
          {!loading ? (
            data?.findManyServicesByPatient?.services?.map(
              (service: ServiceEntityWithRelationsResponse) => (
                <ServiceButton
                  key={service.id}
                  variant={EButtonVariants.Green}
                  onClick={onSelectHandler(service)}
                  as={Link}
                  to={`${PATHS.patientBooking}${PATHS.steps}?clientId=${clientId}&clinicId=${clinicId}&serviceId=${service?.id}&langCode=${i18n.resolvedLanguage}`}
                >
                  {service.name}
                </ServiceButton>
              )
            )
          ) : (
            <LoadingWrap>
              <Loading size="32px" padding="20px" />
            </LoadingWrap>
          )}
        </ServicesWrapper>
      </Layout>
    </ServicesPageWrapper>
  );
}

export default ServicesPage;
