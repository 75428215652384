import React, { ReactNode, useEffect, useRef, useState } from "react";
import { NumericFormat } from "react-number-format";
import { Controller, FieldError, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DefaultTFuncReturn } from "i18next";
import {
  ErrorMessage,
  IconBlock,
  InputBlock,
  InputWrapper,
  Label,
} from "./styled";

interface InputProps {
  children?: ReactNode;
  label?: DefaultTFuncReturn;
  name: string;
  val: string;
  errorMessage?: FieldError;
  iconLeft?: ReactNode;
  disabled?: boolean;
}

const InputNumberFormatRHF = React.forwardRef(
  (
    {
      children,
      name,
      val,
      label,
      errorMessage,
      iconLeft,
      disabled = false,
    }: InputProps,
    ref
  ) => {
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const { control } = useFormContext();

    const { t } = useTranslation();

    const wrapper = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (!isFocused && val) {
        setIsFocused(!!val);
      }
    }, [val, isFocused]);

    return (
      <InputWrapper>
        <InputBlock
          isError={!!errorMessage}
          isFocused={isFocused}
          isIconLeft={!!iconLeft}
          ref={wrapper}
        >
          <Label isFocused={isFocused} htmlFor={name}>
            {label}
          </Label>
          {isFocused && iconLeft && <IconBlock>{iconLeft}</IconBlock>}
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
              <NumericFormat
                getInputRef={ref}
                value={value}
                onChange={onChange}
                allowedDecimalSeparators={[",", "."]}
                decimalSeparator="."
                thousandSeparator=" "
                valueIsNumericString
                disabled={disabled}
                onBlur={() => setIsFocused(false)}
              />
            )}
          />

          {errorMessage && (
            <ErrorMessage>
              {errorMessage?.message && t(errorMessage.message)}
            </ErrorMessage>
          )}
        </InputBlock>
        {children}
      </InputWrapper>
    );
  }
);

export default InputNumberFormatRHF;
