import styled from "styled-components";
import { Heading } from "../../../UI/Heading";

export const List = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 0;
  flex-direction: column;
  width: 100%;
`;

export const HeadingStyled = styled(Heading)`
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault};

  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 20px;
    padding: 16px;
  }
`;
