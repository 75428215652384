import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { EButtonVariants } from "src/UI/Button";
import { LockIcon } from "src/UI/Svg";

import { useAppAction } from "src/store/app/hooks";
import { useAuthContext } from "src/context/AuthContext";
import { useRequestEmailChange } from "src/graphql/hooks/useMutations";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { PATHS } from "src/constants/constants";

import { Loading } from "src/UI/Loading";
import { Wrapper, ButtonHeader } from "./styled";
import {
  CardComponent,
  // DashboardSettingsComponent,
  // EmailSettingsComponent,
  MainFormComponent,
  // ReferralComponent,
} from "../../../components/UserComponents";

function SettingView() {
  const { t } = useTranslation();
  const { onShowChangeEmailModal } = useAppAction();
  const { currentUserData, logout, loading, refetchUser } = useAuthContext();
  const navigate = useNavigate();

  const [initialEmail, setInitialEmail] = useState("");

  const [reqChangeMail] = useRequestEmailChange();

  useEffect(() => {
    if (!currentUserData) return;

    setInitialEmail(currentUserData?.email);
  }, [currentUserData]);

  const handleChangeEmail = () => {
    onShowChangeEmailModal({
      email: initialEmail,
      accept: (data) => {
        if (initialEmail !== data.email) {
          reqChangeMail({
            variables: {
              schema: {
                email: data.email,
              },
            },
            onCompleted() {
              logout();
              navigate(`${PATHS.auth}${PATHS.checkEmail}`);
            },
            onError(error) {
              toast.error(error.message);
              refetchUser();
            },
          });
        }
      },
    });
  };

  return (
    <Wrapper>
      {/* <ReferralComponent /> */}
      <CardComponent
        title={t("user.person_data.data.title")}
        // headerChildren={
        //   <ButtonHeader variant={EButtonVariants.Gray}>
        //     <LockIcon />
        //     {t("user.person_data.data.buttons.change_password")}
        //   </ButtonHeader>
        // }
        headerChildren={
          <ButtonHeader
            variant={EButtonVariants.Gray}
            type="button"
            onClick={handleChangeEmail}
          >
            <LockIcon />
            {t("user.person_data.data.buttons.change_email")}
          </ButtonHeader>
        }
      >
        {!loading ? (
          <MainFormComponent />
        ) : (
          <Loading type="secondary" padding="0" />
        )}
      </CardComponent>
      {/* <CardComponent title={t("user.person_data.email_setting.title")}>
        <EmailSettingsComponent />
      </CardComponent>
      <CardComponent title={t("user.person_data.dashboard_setting.title")}>
        <DashboardSettingsComponent />
      </CardComponent> */}
    </Wrapper>
  );
}

export default SettingView;
