import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Loading } from "src/UI/Loading";
import { ITemplateCreateEdit } from "src/types/templates";
import { useClinicsState } from "src/store/clinics/hooks";
import { EButtonVariants } from "src/UI/Button";
import {
  DistributionSystemEvents,
  useUpdateCurrentClinicTemplateMutation,
  useUpdateTemplateActionsByClientMutation,
} from "src/graphql/generated";
import {
  useTemplatesAction,
  useTemplatesState,
} from "src/store/templates/hooks";
import { useTemplateContext } from "src/context/TemplatesContext";
import { PlusIcon } from "src/UI/Svg";
import { DEFAULT_TEMPLATE_ACTIONS } from "src/constants/constants";
import { SaveActionsButton, StyledTemplatesComponentWrapper } from "./styled";

function TemplatesComponentSubmitActions() {
  const { t } = useTranslation();
  const { watch } = useFormContext<ITemplateCreateEdit>();
  const { onShowAddNewAction } = useTemplateContext();
  const [updateTemplateActions, { loading: loadingAction }] =
    useUpdateTemplateActionsByClientMutation();
  const { currentClinicId: clinicId } = useClinicsState();
  const { selectedTemplateData } = useTemplatesState();
  const { onSetClinicTemplate } = useTemplatesAction();

  const [updateActiveTemplateActions, { loading: loadingActiveTemplate }] =
    useUpdateCurrentClinicTemplateMutation();

  const [templateActions, selectedEvent, isActiveTemplate] = watch([
    "templateActions",
    "selectedEvent",
    "isActiveTemplate",
  ]);

  const handleAddNewAction = () => {
    onShowAddNewAction(true);
  };

  const handleSubmit = async () => {
    if (selectedEvent && templateActions && selectedTemplateData)
      await updateTemplateActions({
        variables: {
          schema: {
            id: selectedTemplateData.id,
            actions: templateActions.map(({ name }) => name),
          },
        },
        onCompleted: () => {
          toast.success(t("app.successful"));
        },
      });

    if (selectedTemplateData) {
      await updateActiveTemplateActions({
        variables: {
          schema: {
            clinicId,
            event: selectedEvent as DistributionSystemEvents,
            ...(isActiveTemplate && { templateId: selectedTemplateData.id }),
          },
        },
      });

      onSetClinicTemplate({
        clinicId,
        event: selectedEvent as DistributionSystemEvents,
        templateId: selectedTemplateData?.id,
      });
    }
  };

  const loading = loadingActiveTemplate || loadingAction;

  const isAllActionInForm = DEFAULT_TEMPLATE_ACTIONS.every((defaultTemplate) =>
    templateActions?.some(({ name }) => name === defaultTemplate.name)
  );

  return (
    <StyledTemplatesComponentWrapper>
      <SaveActionsButton
        variant={EButtonVariants.Border}
        type="button"
        onClick={handleAddNewAction}
        disabled={isAllActionInForm}
      >
        <PlusIcon />
        {t("templates.action.buttons.add")}
      </SaveActionsButton>

      {!!selectedTemplateData && (
        <SaveActionsButton
          variant={EButtonVariants.Default}
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading && <Loading padding="2px" />}
          {t("common.save")}
        </SaveActionsButton>
      )}
    </StyledTemplatesComponentWrapper>
  );
}

export default TemplatesComponentSubmitActions;
