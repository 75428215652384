import { useAuthContext } from "src/context/AuthContext";

import { IAppointmentsFilters } from "src/types";

import { useScheduleAppointmentsByClientFetchMore } from "./useScheduleAppointmentsByClientFetchMore";
import { useScheduleAppointmentsByDoctorFetchMore } from "./useScheduleAppointmentsByDoctorFetchMore";

interface IGetAppointmentsByRoleFetchMore {
  filters: IAppointmentsFilters;
  isReminders?: false;
}

export function useScheduleAppointmentsByRoleFetchMore({
  filters,
  isReminders,
}: IGetAppointmentsByRoleFetchMore) {
  const { isDoctor } = useAuthContext();

  const {
    data: clientData,
    loading: clientLoading,
    node: clientNode,
    isLoading: clientIsLoading,
  } = useScheduleAppointmentsByClientFetchMore({ filters, isReminders });

  const {
    data: doctorData,
    loading: doctorLoading,
    node: doctorNode,
    isLoading: doctorIsLoading,
  } = useScheduleAppointmentsByDoctorFetchMore({ filters, isReminders });

  const data = isDoctor
    ? doctorData?.findAndCountManyScheduleAppointmentsWithRelationsByDoctor
    : clientData?.findAndCountManyScheduleAppointmentsWithRelationsByClient;
  const loading = isDoctor ? doctorLoading : clientLoading;
  const node = isDoctor ? doctorNode : clientNode;
  const isLoading = isDoctor ? doctorIsLoading : clientIsLoading;

  return {
    data,
    loading,
    node,
    isLoading,
  };
}
