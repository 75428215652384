import { enUS, pl } from "date-fns/locale";
import { ReactNode } from "react";
import { DistributionActions, LanguageIsoCodes } from "src/graphql/generated";
import { EPriorityEvent } from "src/types";
import { Lang } from "src/types/internationalization";
import {
  FormMailTemplate,
  FormSmsTemplate,
  ITemplateAction,
} from "src/types/templates";
import { EnglishFlag, PolandFlag } from "src/UI/Svg";
import {
  DayView,
  MonthView,
  WeekView,
  YearView,
} from "src/views/CalendarViews";

export const PATHS = {
  // === layers ===
  auth: "/auth",
  cp: "/cp",
  vr: "/vr",
  // === paths ===
  home: "/",
  login: "/login",
  registration: "/registration",
  company: "/company",
  clinic: "/clinic",
  clinics: "/clinics",
  auth2fa: "/2factor",
  create2fa: "/bind-verified",
  dashboard: "/dashboard",
  checkEmail: "/check-email",
  addAccount: "/add-account",
  restore: "/restore",
  password: "/password",
  create: "/create",
  update: "/update",
  map: "/map",
  patients: "/patients",
  patient: "/patient",
  addVisit: "/addVisit",
  booking: "/booking",
  patientBooking: "/appointment",
  settings: "/settings",
  widgets: "/widgets",
  personal: "/personal",
  steps: "/steps",
  services: "/services",
  confirmAppointment: "/confirm",
  detailsAppointment: "/details",
  confirmedAppointment: "/confirmed",
  pinModal: "/pin-modal",
  passModal: "/pass-modal",
  searchResult: "/search-result",
  templates: "/templates",
  rate: "/rate",
};

export const DASHBOARD_PATHS = {
  defaultPage: "/clinic",
  dashboard: "/dashboard",
  messages: "/messages",
  news: "/news",
  payment: "/payment",
  analytic: "/analytic",
  calendar: "/calendar",
  waitlist: "/waitlist",
  mailing: "/mailing/*",
  map: "/map/*",
  patients: "/patients/*",
  templates: "/templates/*",
  constructor: "/constructor",
  booking: "/booking/*",
  clinic: "/clinic/*",
  forms: "/forms/*",
  reviews: "/reviews",
  advertisement: "/advertisement/*",
  searchResult: "/search-result",
  pinModal: "/pin-modal/:userId",
  passModal: "/pass-modal/:userId",
};

export const JWT = "json_web_token";
export const PERMISSION_2FA = "permission_2FA";
export const IS_COMPANY_EXIST = "isCompanyExists";
export const IS_PINCODE_EXIST = "isPinCodeExists";

export const optsSpecialisation: Array<{
  item: string | ReactNode;
  value: string;
}> = [
  {
    item: "Stomatolog",
    value: "0",
  },
  {
    item: "Chirurg",
    value: "1",
  },
  {
    item: "Ortodonta",
    value: "2",
  },
];

export const optsLocation: Array<{
  item: string;
  shortItem: string;
  value: string;
}> = [
  {
    item: "Żwirki i Wigury 63A, 02-091 Warszawa",
    shortItem: "Żwirki i Wigury 63A, 02-091 Warszawa",
    value: "1",
  },
  {
    item: "Grójecka 194, 02-390 Warszawa",
    shortItem: "Grójecka 194, 02-390 Warszawa",
    value: "2",
  },
  {
    item: "Drawska 22, 00-001 Warszawa",
    shortItem: "Drawska 22, 00-001 Warszawa",
    value: "3",
  },
  {
    item: "1 Sierpnia 46, 02-134 Warszawa",
    shortItem: "1 Sierpnia 46, 02-134 Warszawa",
    value: "4",
  },
];

export const options: Array<{
  item: string;
  shortItem: string;
  value: string;
}> = [
  {
    item: "Poniedziałek",
    shortItem: "Pon.",
    value: "Poniedziałek",
  },
  {
    item: "Wtorek",
    shortItem: "Wt.",
    value: "Wtorek",
  },
  {
    item: "Środa",
    shortItem: "Śr.",
    value: "Środa",
  },
  {
    item: "Czwartek",
    shortItem: "Czw.",
    value: "Czwartek ",
  },
  {
    item: "Piątek",
    shortItem: "Pt.",
    value: "Piątek",
  },
  {
    item: "Sobota",
    shortItem: "Sob.",
    value: "Sobota",
  },
  {
    item: "Niedziela",
    shortItem: "Niedz.",
    value: "Niedziela ",
  },
];
export const optOperatory: Array<{
  item: string | ReactNode;
  value: string | number;
}> = [
  {
    item: "Anna Li4",
    value: "0",
  },
  {
    item: "Anna Li4",
    value: "1",
  },
  {
    item: "Anna Li4",
    value: "2",
  },
  {
    item: "Anna Li4",
    value: "3",
  },
];

// constants for DayView
export enum EDay {
  Today,
  Holiday,
  Weekday,
}

export const variants: { [day in EDay]: string } = {
  [EDay.Holiday]: "#E26161",
  [EDay.Today]: "#0BA495",
  [EDay.Weekday]: "#3D3D3D",
};

export const hours: string[] = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];
// end const for DayView

// consts for EventDayComponent
export const variantsEventDayComponent: {
  [priority in EPriorityEvent]: { background: string; date: string };
} = {
  [EPriorityEvent.Low]: {
    background: "#F8F8F8",
    date: "#A5A5A5",
  },
  [EPriorityEvent.Normal]: {
    background: "#E7F6F5",
    date: "#0BA495",
  },
  [EPriorityEvent.Warning]: {
    background: "rgba(255, 184, 0, 0.1);",
    date: "#FFB800;",
  },
  [EPriorityEvent.High]: {
    background: "#dababa",
    date: "#E26161",
  },
};

export const optProvider: Array<{
  item: string | ReactNode;
  value: string | number;
}> = [
  {
    item: "Provider 1",
    value: "0",
  },
  {
    item: "Provider 2",
    value: "1",
  },
];

export const LangFlags: Lang[] = [
  {
    code: "en",
    flag: EnglishFlag,
  },
  {
    code: "pl",
    flag: PolandFlag,
  },
];

export const durationInMinutes = [
  { value: 5, item: "5" },
  { value: 10, item: "10" },
  { value: 15, item: "15" },
  { value: 20, item: "20" },
  { value: 25, item: "25" },
  { value: 30, item: "30" },
];

export const defaultPageLimit = 10;
export const defaultPatientsLimit = 10;
export const defaultClinicsLimit = 10;
export const defaultWaitListLimit = 10;
export const defaultBlankFormsLimit = 10;
export const defaultVisitsLimit = 10;
export const defaultDoctorLimit = 10;
export const defaultLastSmsLimit = 10;
export const defaultSmsLimit = 10;
export const defaultSearchLimit = 10;

export enum AppointmentSortFieldsForApi {
  CreatedAt = "createdAt",
  ScheduleTimeFrom = "scheduleTimeFrom",
  UpdatedAt = "updatedAt",
}

export enum AppointmentStatusesForApi {
  Canceled = "canceled",
  ConfirmedByApplication = "confirmedByApplication",
  ConfirmedByPatient = "confirmedByPatient",
  Missed = "missed",
  New = "new",
  Successful = "successful",
}

export enum SortingOrdersForApi {
  Ascending = "ASC",
  Descending = "DESC",
}

export const defaultQueryParamOffset = 0;

export const localStoragepatientsPageNumberName = "patientsPageNumber";
export const localStorageVisitsPageNumberName = "visitsPageNumber";
export const localStorageDoctorsPageNumberName = "doctorsPageNumber";
export const localStorageWaitListsPageNumberName = "waitListsPageNumber";
export const localFormPatientTableViewPageNumber =
  "localFormPatientTableViewPageNumber";

export const allClinicsItemId = "all-clinics-id";

export const calendarLocales = {
  pl,
  enUS,
};

export const views = {
  day: DayView,
  week: WeekView,
  month: MonthView,
  year: YearView,
};

export const RESET_PIN_ACTION = "RESET_PIN";
export const RELOGIN_BY_PASS_SELECTION =
  "RELOGIN_BY_PASS_FROM_PIN_MODAL_SELECTION";

export const availablesPhoneCodes = ["1", "48"];

export const ALL_APPOINTMENT_STATUS = "All";

export const FILE_NOT_FOUND_ERROR_MESSAGE = "File not found";

export const TEMPORARY_REMINDERS_LIMIT = 100;
export const DEFAULT_TEMPLATE_ACTIONS: ITemplateAction[] = [
  {
    name: DistributionActions.SendMail,
    titleId: "templates.action.actions.email",
    subtitleId: "templates.action.actions.email.description",
  },
  {
    name: DistributionActions.SendSms,
    titleId: "templates.action.actions.sms",
    subtitleId: "templates.action.actions.sms.description",
  },
];

export const DEFAULT_FORM_MAIL_TEMPLATE: FormMailTemplate[] = [
  {
    delayInHours: undefined,
    languageISOCode: LanguageIsoCodes.En,
    subject: "",
    text: undefined,
  },
  {
    delayInHours: undefined,
    languageISOCode: LanguageIsoCodes.Pl,
    subject: "",
    text: undefined,
  },
];

export const DEFAULT_FORM_SMS_TEMPLATE: FormSmsTemplate[] = [
  {
    delayInHours: 1,
    languageISOCode: LanguageIsoCodes.En,
    text: "",
  },
  {
    delayInHours: 1,
    languageISOCode: LanguageIsoCodes.Pl,
    text: "",
  },
];

export const POLICY_LINK = "https://goclients.pl/policy";

export const EMAIL_ALREADY_TAKEN_ERROR_MESSAGE = "Email already taken";
export const CHECK_PHONE_NUMBER_ERROR_MESSAGE =
  "Please сheck the phone number is correct";

export const PATIENT_TYPE_NAME = "PatientEntityResponse";
export const APPOINTMENT_TYPE_NAME_BY_CLIENT =
  "AggregatedScheduleAppointmentEntityWithRelationsByClientResponse";
export const APPOINTMENT_TYPE_NAME_BY_DOCTOR =
  "AggregatedScheduleAppointmentEntityWithRelationsByDoctorResponse";

export const BOOKING_PATIENT_SLIDES_COUNT = 5;
