import { Button } from "src/UI/Button";
import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 355px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding-top: 25px;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  min-width: 64px;
  width: 64px;
  height: 64px;
  margin-bottom: 10px;
  background: ${({ theme }) => theme.colors.greenHover};
  border-radius: 48px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.black};
  font-size: 24px;
  line-height: 120%;
  margin-bottom: 20px;
`;

export const DoctorInfoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`;

export const InfoListWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const Address = styled.div`
  max-width: 154px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const Label = styled.p`
  color: ${({ theme }) => theme.colors.gray};
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  margin-bottom: 6px;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  align-content: center;
  gap: 8px;
  margin-bottom: 16px;
`;

export const ButtonStyled = styled(Button)`
  min-width: 162px;
  padding: 8px 10px;
  font-size: 16px;
  line-height: 100%;
  text-wrap: nowrap;
  width: fit-content;
  border-radius: 5px;
`;

export const CalendarButton = styled.button`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.gray};
  background-color: transparent;
  border: none;
  font-size: 16px;
  line-height: 100%;
  text-decoration-line: underline;
  margin-bottom: 8px;
`;

export const CalendarListWrapper = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
`;

export const CalendarItem = styled.div`
  cursor: pointer;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  }
  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.greenHover};
  }
`;

export const Text = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
`;

export const TextWrapper = styled.p`
  display: flex;
  align-items: baseline;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  & span {
    color: ${({ theme }) => theme.colors.gray};
  }
`;
