import { PassForPinModal, PinModal } from "src/components/Modals";
import { DASHBOARD_PATHS } from "src/constants/constants";
import { UserRoles } from "src/graphql/generated";
import AdvertisementPages from "src/pages/DashboardPages/AdvertisementPages";
import AnalyticPage from "src/pages/DashboardPages/AnalyticPage";
import BookingPages from "src/pages/DashboardPages/BookingPages";
import CalendarPage from "src/pages/DashboardPages/CalendarPage/CalendarPage";
import ConstructorPage from "src/pages/DashboardPages/ConstructorPage";
import FormsPages from "src/pages/DashboardPages/FormsPages";
import MailingPages from "src/pages/DashboardPages/MailingPages";
import MainPage from "src/pages/DashboardPages/MainPage/MainPage";
import MapPages from "src/pages/DashboardPages/MapPages";
import MessengerPage from "src/pages/DashboardPages/MessengerPage/MessengerPage";
import NewsPage from "src/pages/DashboardPages/NewsPage";
import PatientsPages from "src/pages/DashboardPages/PatientsPages";
import PaymentPage from "src/pages/DashboardPages/PaymentPage";
import ReviewPage from "src/pages/DashboardPages/ReviewPage";
import SearchResultPage from "src/pages/DashboardPages/SearchResultPage/SearchResultPage";
import TemplatesPages from "src/pages/DashboardPages/TemplatesPages";
import UserPages from "src/pages/DashboardPages/UserPages";
import WaitListPage from "src/pages/DashboardPages/WaitListPage/WaitListPage";

export const dashboardRoutes = [
  {
    path: DASHBOARD_PATHS.dashboard,
    element: MainPage,
    roles: [UserRoles.Doctor, UserRoles.Client],
  },
  {
    path: DASHBOARD_PATHS.messages,
    element: MessengerPage,
    roles: [UserRoles.Client],
  },
  {
    path: DASHBOARD_PATHS.news,
    element: NewsPage,
    roles: [],
  },
  {
    path: DASHBOARD_PATHS.payment,
    element: PaymentPage,
    roles: [],
  },
  {
    path: DASHBOARD_PATHS.analytic,
    element: AnalyticPage,
    roles: [],
  },
  {
    path: DASHBOARD_PATHS.calendar,
    element: CalendarPage,
    roles: [UserRoles.Doctor, UserRoles.Client],
  },
  {
    path: DASHBOARD_PATHS.waitlist,
    element: WaitListPage,
    roles: [UserRoles.Client],
  },
  {
    path: DASHBOARD_PATHS.mailing,
    element: MailingPages,
    roles: [],
  },
  {
    path: DASHBOARD_PATHS.map,
    element: MapPages,
    roles: [UserRoles.Client],
  },
  {
    path: DASHBOARD_PATHS.patients,
    element: PatientsPages,
    roles: [UserRoles.Doctor, UserRoles.Client],
  },
  {
    path: DASHBOARD_PATHS.templates,
    element: TemplatesPages,
    roles: [UserRoles.Client],
  },

  {
    path: DASHBOARD_PATHS.constructor,
    element: ConstructorPage,
    roles: [],
  },
  {
    path: DASHBOARD_PATHS.booking,
    element: BookingPages,
    roles: [UserRoles.Doctor, UserRoles.Client],
  },
  {
    path: DASHBOARD_PATHS.clinic,
    element: UserPages,
    roles: [UserRoles.Doctor, UserRoles.Client],
  },
  {
    path: DASHBOARD_PATHS.forms,
    element: FormsPages,
    roles: [UserRoles.Doctor, UserRoles.Client],
  },
  {
    path: DASHBOARD_PATHS.reviews,
    element: ReviewPage,
    roles: [],
  },
  {
    path: DASHBOARD_PATHS.advertisement,
    element: AdvertisementPages,
    roles: [],
  },
  {
    path: DASHBOARD_PATHS.searchResult,
    element: SearchResultPage,
    roles: [UserRoles.Doctor, UserRoles.Client],
  },
  {
    path: DASHBOARD_PATHS.pinModal,
    element: PinModal,
    roles: [UserRoles.Doctor, UserRoles.Client],
  },
  {
    path: DASHBOARD_PATHS.passModal,
    element: PassForPinModal,
    roles: [UserRoles.Doctor, UserRoles.Client],
  },
];
