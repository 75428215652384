export const paginationMerge = (
  key: string,
  keyArgs: boolean | Array<string | string[]> = false,
  list = "rows"
) => {
  return {
    [key]: {
      keyArgs,
      merge(
        // eslint-disable-next-line @typescript-eslint/default-param-last
        existing = { [list]: [] },
        incoming: { [x: string]: never[] },
        params: any
      ) {
        const getKey = (item: never) => (item as any).__ref;
        const isOffsetZero = params?.args?.schema?.offset === 0;

        const prevEdges = isOffsetZero ? [] : existing?.[list] || [];
        const incomingEdges = incoming?.[list] || [];

        const combinedArray = [...prevEdges, ...incomingEdges];
        const uniqueSet = new Set(combinedArray.map(getKey));

        const uniqueArray = Array.from(uniqueSet).map((ieteKey) =>
          combinedArray.find((item) => getKey(item) === ieteKey)
        );

        const arraysIdentical = (
          arr1: never[] | undefined,
          arr2: never[] | undefined
        ) => JSON.stringify(arr1) === JSON.stringify(arr2);

        if (arraysIdentical(prevEdges, incomingEdges)) {
          return {
            ...incoming,
            [list]: incomingEdges,
          };
        }

        return {
          ...incoming,
          [list]: uniqueArray,
        };
      },
    },
  };
};
