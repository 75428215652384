import { AggregatedScheduleWaitlistEntityByClientResponse } from "src/graphql/generated";

export const transformBeforeConvertWaitingAppointment = (
  rescheduleAppointment: AggregatedScheduleWaitlistEntityByClientResponse
) => {
  if (rescheduleAppointment) {
    return {
      appointmentId: rescheduleAppointment.id,
      clinicId: rescheduleAppointment.clinicId,
      doctorId: rescheduleAppointment?.doctor?.doctorId || "",
      serviceId: rescheduleAppointment.service?.id,
      patientId: rescheduleAppointment.patientId,
    };
  }
};
