import { useDispatch, useSelector } from "react-redux";
import { useCallback, useMemo } from "react";
import { AppDispatch, RootState } from "../index";
import {
  EModals,
  IAlert,
  INavTip,
  IPatientVisitSettingsFieldsList,
  IConfirmProps,
  IConfirmPropsClose,
  IPatientSettingsFieldsList,
  IAddCabinetProps,
  IEdirServiceModal,
  IChangeEmailProps,
  ICreateDoctorInvitationModal,
  EditServiceCategoryProps,
  IScheduleAppointmentModal,
  ICancelAppointmentProps,
  ICancelAppointmentPropsClose,
  IScheduleWaitingAppointmentModal,
  IShowEditFormModalProps,
  IShowPreviewModalProps,
  IAddWaitListProps,
  IShowPatientFilledFormPreviewModalProps,
} from "./types";
import {
  closeAlert,
  closeModal,
  showAlert,
  showModal,
  toggleNavTip,
  togglePatientSettings,
  confirmOpen,
  confirmClose,
  togglePatientVisitSettings,
  showAddCabinetModal,
  showEditServiceModal,
  showChangeEmailModal,
  showCreateDoctorInvitationModal,
  showEditServiceCategoryModal,
  showScheduleAppointmentModal,
  showCancelAppointmentModal,
  closeCancelAppointmentModal,
  showScheduleWaitingAppointmentModal,
  showCreateEditFormModal,
  showPreviewFormModal,
  showAddToWaitListModal,
  showPatientFilledFormPreviewModal,
} from "./appSlice";

export const getApp = (state: RootState) => state.app;

export function useAppAction() {
  const dispatch = useDispatch<AppDispatch>();

  const onShowModal = useCallback(
    (modal: EModals) => {
      dispatch(showModal(modal));
    },
    [dispatch]
  );

  const onCloseModal = useCallback(
    (modal: EModals) => {
      dispatch(closeModal(modal));
    },
    [dispatch]
  );

  const onShowAlert = useCallback(
    (alert: IAlert) => {
      dispatch(showAlert(alert));
    },
    [dispatch]
  );

  const onCloseAlert = useCallback(() => {
    dispatch(closeAlert());
  }, [dispatch]);

  const onToggleNavTip = useCallback(
    (data?: INavTip) => {
      dispatch(toggleNavTip(data));
    },
    [dispatch]
  );

  const onTogglePatientVisitSettings = useCallback(
    (data: IPatientVisitSettingsFieldsList) => {
      dispatch(togglePatientVisitSettings(data));
    },
    [dispatch]
  );

  const onTogglePatientSettings = useCallback(
    (data: IPatientSettingsFieldsList) => {
      dispatch(togglePatientSettings(data));
    },
    [dispatch]
  );

  const onConfirmOpen = useCallback(
    (data: IConfirmProps) => {
      dispatch(confirmOpen(data));
    },
    [dispatch]
  );

  const onConfirmClose = useCallback(
    (data: IConfirmPropsClose) => {
      dispatch(confirmClose(data));
    },
    [dispatch]
  );

  const onShowAddCabinetModal = useCallback(
    (data: IAddCabinetProps) => {
      dispatch(showAddCabinetModal(data));
    },
    [dispatch]
  );

  const onShowEditServiceCategoryModal = useCallback(
    (data: EditServiceCategoryProps) => {
      dispatch(showEditServiceCategoryModal(data));
    },
    [dispatch]
  );

  const onShowChangeEmailModal = useCallback(
    (data: IChangeEmailProps) => {
      dispatch(showChangeEmailModal(data));
    },
    [dispatch]
  );

  const onShowEditServiceModal = useCallback(
    (data: IEdirServiceModal) => {
      dispatch(showEditServiceModal(data));
    },
    [dispatch]
  );

  const onShowCreateDoctorInvitationModal = useCallback(
    (data: ICreateDoctorInvitationModal) => {
      dispatch(showCreateDoctorInvitationModal(data));
    },
    [dispatch]
  );

  const onShowScheduleAppointmentModal = useCallback(
    (data: IScheduleAppointmentModal) => {
      dispatch(showScheduleAppointmentModal(data));
    },
    [dispatch]
  );
  const onShowScheduleWaitingAppointmentModal = useCallback(
    (data: IScheduleWaitingAppointmentModal) => {
      dispatch(showScheduleWaitingAppointmentModal(data));
    },
    [dispatch]
  );

  const onShowCancelAppointmentModal = useCallback(
    (data: ICancelAppointmentProps) => {
      dispatch(showCancelAppointmentModal(data));
    },
    [dispatch]
  );

  const onCloseCancelAppointmentModal = useCallback(
    (data: ICancelAppointmentPropsClose) => {
      dispatch(closeCancelAppointmentModal(data));
    },
    [dispatch]
  );

  const onShowCreateEditForm = useCallback(
    (data?: IShowEditFormModalProps) => {
      dispatch(showCreateEditFormModal(data));
    },
    [dispatch]
  );

  const onShowPreviewForm = useCallback(
    (data: IShowPreviewModalProps) => {
      dispatch(showPreviewFormModal(data));
    },
    [dispatch]
  );

  const onShowAddToWaitListModal = useCallback(
    (data: IAddWaitListProps) => {
      dispatch(showAddToWaitListModal(data));
    },
    [dispatch]
  );

  const onShowPatientFilledFormPreviewModal = useCallback(
    (data: IShowPatientFilledFormPreviewModalProps) => {
      dispatch(showPatientFilledFormPreviewModal(data));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      onShowModal,
      onCloseModal,
      onShowAlert,
      onCloseAlert,
      onToggleNavTip,
      onTogglePatientVisitSettings,
      onTogglePatientSettings,
      onConfirmOpen,
      onConfirmClose,
      onShowAddCabinetModal,
      onShowEditServiceModal,
      onShowChangeEmailModal,
      onShowCreateDoctorInvitationModal,
      onShowEditServiceCategoryModal,
      onShowScheduleAppointmentModal,
      onShowScheduleWaitingAppointmentModal,
      onShowCancelAppointmentModal,
      onCloseCancelAppointmentModal,
      onShowCreateEditForm,
      onShowAddToWaitListModal,
      onShowPreviewForm,
      onShowPatientFilledFormPreviewModal,
    }),
    [
      onShowModal,
      onCloseModal,
      onShowAlert,
      onCloseAlert,
      onToggleNavTip,
      onTogglePatientVisitSettings,
      onTogglePatientSettings,
      onConfirmOpen,
      onConfirmClose,
      onShowEditServiceModal,
      onShowChangeEmailModal,
      onShowCreateDoctorInvitationModal,
      onShowEditServiceCategoryModal,
      onShowScheduleAppointmentModal,
      onShowScheduleWaitingAppointmentModal,
      onShowCancelAppointmentModal,
      onCloseCancelAppointmentModal,
      onShowCreateEditForm,
      onShowAddToWaitListModal,
      onShowPreviewForm,
      onShowPatientFilledFormPreviewModal,
    ]
  );
}

export function useAppState() {
  return useSelector(getApp);
}
