import { FieldError, useFormContext } from "react-hook-form";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { FormItemComponentCustomInput } from "./FormItemComponentCustomInput";
import { DateWrapper, ErrorMessage } from "./styled";

interface IFormItemComponentDateProps {
  disabled?: boolean;
  selected: any;
  placeholder: string;
  fieldOrder: number;
}

export function FormItemComponentTime({
  disabled,
  selected,
  placeholder,
  fieldOrder,
}: IFormItemComponentDateProps) {
  const { t } = useTranslation();
  const {
    setValue,
    formState: { errors },
  } = useFormContext();

  const handleChangeDate = (date: Date | null) => {
    setValue(`fields.${fieldOrder}.fillValue`, format(date as Date, "HH:mm"));
  };

  return (
    <DateWrapper>
      <DatePicker
        disabled={disabled}
        selected={selected}
        onChange={handleChangeDate}
        showTimeSelect
        showTimeSelectOnly
        placeholderText={placeholder}
        timeIntervals={30}
        timeCaption="Time"
        customInput={<FormItemComponentCustomInput />}
        timeFormat="HH:mm aa"
        dateFormat="HH:mm aa"
      />
      {((errors.fields as any)?.[fieldOrder] as FieldError) && (
        <ErrorMessage>
          {(errors.fields as any)?.[fieldOrder]?.message &&
            t((errors.fields as any)?.[fieldOrder]?.message)}
        </ErrorMessage>
      )}
    </DateWrapper>
  );
}
