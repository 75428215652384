import { createContext, MutableRefObject } from "react";

export type IAppointmentTimes = {
  from: Date | null;
  to: Date | null;
};

export type AddAppointmentMethod = (from?: Date, to?: Date) => void;
type AddAppointmentType = {
  addAppointment: AddAppointmentMethod;
  clearTimes: () => void;
  appointmentTimes: MutableRefObject<IAppointmentTimes> | undefined;
};
export const AddAppointment = createContext<AddAppointmentType>({
  addAppointment: () => {},
  clearTimes: () => {},
  appointmentTimes: undefined,
});
