import { Button } from "src/UI/Button";
import styled from "styled-components";

export const Content = styled.div`
  padding: 20px;
  width: 988px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: ${({ theme }) => theme.colors.lightBiege};
`;

export const ButtonStyled = styled(Button)`
  padding: 8px 10px;
  font-size: 16px;
  line-height: 100%;
  border-radius: 5px;
  gap: 7px;
  width: fit-content;
  svg {
    width: 16px;
    height: 16px;
  }
`;

export const InputsRow = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  align-content: center;
  justify-content: flex-start;
  gap: 20px;

  & > div {
    width: 100%;
  }
`;

export const Wrapper = styled.div`
  padding: 20px;
  gap: 20px;
  display: flex;
  align-items: center;
  align-content: center;
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
`;

export const Footer = styled(Wrapper)`
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
`;

export const Buttons = styled.div`
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

export const FooterButton = styled(Button)`
  padding: 15px 20px;
  font-size: 20px;
  line-height: 100%;
  gap: 10px;
  white-space: nowrap;
  width: fit-content;
`;

export const Form = styled.form`
  display: contents;
`;

export const StyledInput = styled.div`
  & input {
    background: ${({ theme }) => theme.colors.lightBiege};
  }
`;

export const ErrorMessage = styled.div`
  position: absolute;
  bottom: -20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: right;
  color: ${({ theme }) => theme.colors.red};
`;
