import {
  AggregatedDoctorEntityResponse,
  DoctorTypeEntityResponse,
  FileEntityResponse,
  FindManyDoctorTypesByLanguageIsoCodeQuery,
  Query,
} from "src/graphql/generated";

import { DoctorIcon } from "src/UI/Svg";
import {
  DoctorOptInfoWrap,
  DoctorOptWrap,
  IconWrapper,
  ImageStyled,
  OptItemSubTitle,
  OptItemTitle,
} from "./styles";

interface DoctorOptComponentProps {
  doctor: AggregatedDoctorEntityResponse;
  logoFiles?: Query;
  doctorTypesData?: FindManyDoctorTypesByLanguageIsoCodeQuery | undefined;
}

const DoctorOptComponent = ({
  doctor,
  logoFiles,
  doctorTypesData,
}: DoctorOptComponentProps) => {
  const specialisation: DoctorTypeEntityResponse | undefined =
    doctorTypesData?.findManyDoctorTypesByLanguageISOCode?.doctorTypes.find(
      (type) => type.id === doctor.doctorTypeId
    );

  const avatarFile = logoFiles?.findManyFilesByIds?.files?.find(
    (file: FileEntityResponse) => file.id === doctor?.avatarFileId
  );
  return (
    <DoctorOptWrap>
      <IconWrapper>
        {avatarFile ? (
          <ImageStyled src={avatarFile.url} alt="#" />
        ) : (
          <DoctorIcon />
        )}
      </IconWrapper>
      <DoctorOptInfoWrap>
        <OptItemTitle>{`${doctor.firstName} ${doctor.lastName}`}</OptItemTitle>
        {specialisation && (
          <OptItemSubTitle>
            {specialisation?.defaultLanguageName}
          </OptItemSubTitle>
        )}
      </DoctorOptInfoWrap>
    </DoctorOptWrap>
  );
};

export default DoctorOptComponent;
