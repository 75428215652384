import { useTranslation } from "react-i18next";
import { TCabinetsGroup } from "src/store/calendar/types";
import { HeadingStyled } from "./styles";

const HeadingRoomsComponent = ({
  cabinet,
}: {
  cabinet: TCabinetsGroup[number];
}) => {
  const { t } = useTranslation();

  return (
    <HeadingStyled>{`${t("booking.widgets.tabs.location")} № ${
      cabinet.key
    }`}</HeadingStyled>
  );
};

export default HeadingRoomsComponent;
