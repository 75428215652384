import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 20px;
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  max-width: calc(100% / 5);
  width: 100%;
`;

export const Title = styled.h4`
  text-wrap: nowrap;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.gray};
  margin: 0;
`;

export const Value = styled.div`
  min-height: 28px;
  font-weight: 500;
  font-size: 28px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.black};
  margin-top: 16px;

  span {
    color: ${({ theme }) => theme.colors.gray};

    &.success {
      color: ${({ theme }) => theme.colors.green};
    }
  }
`;
