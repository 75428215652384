import { gql } from "@apollo/client";

export const CLOSE_SCHEDULE_WAITLIST_BY_CLIENT = gql`
  mutation CloseScheduleWaitlistByClient(
    $schema: CloseScheduleWaitlistByClientSchema!
  ) {
    closeScheduleWaitlistByClient(schema: $schema) {
      closed
    }
  }
`;
