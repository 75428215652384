import { FormValues } from "src/pages/DashboardPages/MapPages/types";

export const transformCreateAndEditClinic = (formData: FormValues) => {
  if (formData) {
    return {
      address: formData?.location?.place_name,
      city: formData?.location?.city,
      index: formData?.location?.index,
      name: formData.title,
      phoneNumber: `+${formData.phone}`,
      hiddenFromPatients: formData.hiddenFromPatients,
      ...(formData?.clinicCode && {
        code: formData.clinicCode,
      }) /* not required */,
      ...(formData?.description && {
        description: formData.description,
      }) /* not required */,
      ...(formData?.location?.address && {
        houseNumber: formData?.location?.address,
      }) /* not required */,
      ...(formData?.licenseNumber && {
        licenseNumber: formData.licenseNumber,
      }) /* not required */,
      geographicalCoordinates: {
        latitude: formData?.location?.geometry?.coordinates?.[1],
        longitude: formData?.location?.geometry?.coordinates?.[0],
      },
    };
  }
  return {};
};
