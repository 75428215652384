import React from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "src/UI/Modal";
import { useAppAction, useAppState } from "src/store/app/hooks";
import { EModals, IUserStorageItem } from "src/store/app/types";
import { useTheme } from "styled-components";
import PinInput from "react-pin-input";
import { EButtonVariants } from "src/UI/Button";
import { useAddPINCode } from "src/graphql/hooks/useMutations";
import { toast } from "react-toastify";
import { useAuthContext } from "src/context/AuthContext";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createPinValidationSchema } from "src/validation/schemas/createPinSchema";
import { Loading } from "src/UI/Loading";
import {
  ActionBlock,
  ButtonStyled,
  Content,
  ErrorMessage,
  Form,
} from "./styles";

export interface FormValues {
  pin: string;
}

function PinModal() {
  const { colors } = useTheme();
  const { onCloseModal } = useAppAction();
  const { t } = useTranslation();
  const { modals } = useAppState();
  const {
    currentUserData,
    localUsers,
    isPinCodeExists,
    onSetPinCodeExists,
    onSetLocalusers,
  } = useAuthContext();

  const methods = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(createPinValidationSchema),
    defaultValues: {
      pin: "",
    },
  });

  const {
    formState: { errors },
    handleSubmit,
    setValue,
  } = methods;

  const [addPinCode, { loading }] = useAddPINCode({
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },

    onCompleted: (response) => {
      if (response) {
        const updatedUsersStorageArray = localUsers?.map(
          (user: IUserStorageItem) => {
            if (user?.id === currentUserData?.id) {
              return { ...user, isPinCodeExists: true };
            }
            return user;
          }
        );
        onSetPinCodeExists(true);
        onSetLocalusers(updatedUsersStorageArray);
        toast.success(
          t(
            isPinCodeExists
              ? "user.person_data.enter.changed_pin"
              : "user.person_data.enter.created_pin"
          )
        );
        onCloseModal(EModals.CreatePin);
      }
    },
  });

  const handleCreatePin = (formData: FormValues) => {
    addPinCode({ variables: { schema: { pinCode: formData.pin } } });
  };

  if (!modals[EModals.CreatePin]) return null;
  return (
    <Modal
      modal={EModals.CreatePin}
      title={t(
        isPinCodeExists
          ? "user.person_data.enter.change_pin"
          : "user.person_data.enter.create_pin"
      )}
      headerChildren={t("user.person_data.enter.create_pin_title")}
    >
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(handleCreatePin)}>
          <Content>
            <PinInput
              length={4}
              initialValue=""
              onChange={(value) => setValue("pin", value)}
              type="numeric"
              inputMode="number"
              style={{
                display: "flex",
                gap: 20,
              }}
              inputStyle={{
                padding: "15px 0",
                width: 60,
                height: 52,
                border: "none",
                borderBottom: `1px solid ${colors.borderInputDefault}`,
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "140%",
                textAlign: "center",
                color: colors.black,
              }}
              focus
              onComplete={() => onCloseModal(EModals.Pin)}
              autoSelect
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
            {errors.pin && (
              <ErrorMessage>
                {errors.pin?.message && t(errors.pin.message)}
              </ErrorMessage>
            )}
            <ActionBlock>
              <ButtonStyled variant={EButtonVariants.Default}>
                {loading && <Loading type="secondary" padding="0" />}
                {t("common.save")}
              </ButtonStyled>
            </ActionBlock>
          </Content>
        </Form>
      </FormProvider>
    </Modal>
  );
}

export default PinModal;
