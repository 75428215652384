/* eslint-disable @typescript-eslint/no-use-before-define */
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useAuthContext } from "src/context/AuthContext";
import NotFoundPage from "src/pages/DashboardPages/NotFoundPage";
import { PATHS } from "src/constants/constants";
import { ScreenLoader } from "src/UI/ScreenLoader";

import { routes } from "./routes";

interface ProtectedRouteTypes {
  element: () => JSX.Element;
  path: string;
  isAuth: boolean;
  isAuth2Fa: boolean;
  isCompanyExists: boolean;
  loading: boolean;
  location: string;
}

export function Router() {
  const { isAuth, isAuth2Fa, isCompanyExists, loading } = useAuthContext();
  const location = useLocation();

  return (
    <Routes>
      {routes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <ProtectedRoute
              {...route}
              isAuth={isAuth}
              loading={loading}
              isAuth2Fa={isAuth2Fa}
              isCompanyExists={isCompanyExists}
              location={location.pathname}
            />
          }
        />
      ))}
      <Route key="NotFound" path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

function ProtectedRoute({
  element: Element,
  path,
  isAuth,
  isAuth2Fa,
  isCompanyExists,
  loading,
  location,
}: ProtectedRouteTypes) {
  if (checkIsPathToAuthorizedRout(path)) {
    if (isAuth) return <Navigate to={`${PATHS.cp}${PATHS.dashboard}`} />;
  }

  if (checkIsPathToNonAuthorizedRout(path)) {
    if (
      isAuth &&
      !isAuth2Fa &&
      !checkIfMatchesCurrentLocationWithPath(
        location,
        `${PATHS.vr}${PATHS.auth2fa}`
      )
    ) {
      return <Navigate to={`${PATHS.vr}${PATHS.auth2fa}`} />;
    }
    if (
      isAuth &&
      isAuth2Fa &&
      checkIfMatchesCurrentLocationWithPath(
        location,
        `${PATHS.vr}${PATHS.auth2fa}`
      )
    ) {
      return <Navigate to={`${PATHS.cp}${PATHS.map}`} />;
    }
    if (
      isAuth &&
      isAuth2Fa &&
      !isCompanyExists &&
      !checkIfMatchesCurrentLocationWithPath(
        location,
        `${PATHS.vr}${PATHS.registration}${PATHS.company}`
      ) &&
      !checkIfMatchesCurrentLocationWithPath(
        location,
        `${PATHS.vr}${PATHS.create2fa}`
      )
    ) {
      return (
        <Navigate to={`${PATHS.vr}${PATHS.registration}${PATHS.company}`} />
      );
    }
    if (
      isAuth &&
      isAuth2Fa &&
      isCompanyExists &&
      checkIfMatchesCurrentLocationWithPath(
        location,
        `${PATHS.vr}${PATHS.registration}${PATHS.company}`
      ) &&
      !checkIfMatchesCurrentLocationWithPath(
        location,
        `${PATHS.vr}${PATHS.create2fa}`
      )
    ) {
      return <Navigate to={`${PATHS.cp}${PATHS.map}`} />;
    }

    if (!isAuth) return <Navigate to={`${PATHS.auth}${PATHS.login}`} />;
  }

  return (
    <>
      {loading && <ScreenLoader />}
      <Element />
    </>
  );
}

function checkIsPathToAuthorizedRout(path: string) {
  const authRout = [PATHS.home, `${PATHS.auth}/*`];
  return authRout.includes(path);
}
function checkIsPathToNonAuthorizedRout(path: string) {
  const authRout = [`${PATHS.cp}/*`, `${PATHS.vr}/*`];
  return authRout.includes(path);
}
function checkIfMatchesCurrentLocationWithPath(location: string, path: string) {
  return location === path;
}
