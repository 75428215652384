import {
  CreateFormSchema,
  FindBlankFormByServiceIdQuery,
  FormFieldTypes,
  LanguageIsoCodes,
  UpdateFormFieldSchema,
  UpdateFormSchema,
} from "src/graphql/generated";
import { IFilledFormCheckboxFilledValue } from "src/types";
import {
  CreateEditFormValues,
  IFormFieldTranslationEntityResponse,
} from "src/types/CreateEditForm";

const languagesByEn = ["EN", "PL"];
const languagesByPL = ["PL", "EN"];

export const transformBlankFormToCreateEditForm = (
  findBlankFormResponse: FindBlankFormByServiceIdQuery,
  currentLanguage: string,
  formId?: string
): CreateEditFormValues => {
  return {
    formId,
    clinicId: findBlankFormResponse?.findBlankFormByServiceId?.clinicId || "",
    name: findBlankFormResponse?.findBlankFormByServiceId?.name || "",
    serviceId: findBlankFormResponse?.findBlankFormByServiceId?.serviceId || "",
    language:
      findBlankFormResponse?.findBlankFormByServiceId?.fields[0]
        ?.translations[0]?.languageISOCode,
    defaultCountFields:
      findBlankFormResponse?.findBlankFormByServiceId?.fields.length - 1,
    fields: findBlankFormResponse?.findBlankFormByServiceId?.fields
      .map((field) => {
        const value = field.value ? JSON.parse(field.value) : undefined;
        let defaultCountValues;

        const result = {
          id: field?.id,
          translations: (
            Object.keys(LanguageIsoCodes) as Array<
              keyof typeof LanguageIsoCodes
            >
          )
            .map<IFormFieldTranslationEntityResponse>((keyLangIso) => {
              const translation = field.translations.find(
                (fieldTranslation) =>
                  fieldTranslation.languageISOCode ===
                  LanguageIsoCodes[keyLangIso]
              );

              const translationsResult: Partial<IFormFieldTranslationEntityResponse> =
                {
                  languageISOCode: LanguageIsoCodes[keyLangIso],
                  placeholder: "",
                  name: "",
                };

              if (translation) {
                translationsResult.id = translation?.id;
                translationsResult.fieldId = translation.fieldId;
                translationsResult.name = translation?.name;
                translationsResult.placeholder = translation?.placeholder;

                if (translation.value) {
                  const values = JSON.parse(translation.value);

                  translationsResult.value = values;
                  defaultCountValues = values.length - 1;
                }
              }

              if (
                !translation &&
                field.type === FormFieldTypes.Checkbox &&
                field.translations[0]?.value
              ) {
                const parsedValue = JSON.parse(
                  field.translations[0]?.value
                ) as IFilledFormCheckboxFilledValue[];

                translationsResult.placeholder =
                  field.translations[0].placeholder;
                translationsResult.value = parsedValue.map((item) => ({
                  ...item,
                  text: "",
                }));
              }

              return translationsResult as IFormFieldTranslationEntityResponse;
            })
            .sort((a, b) => {
              const sortingArr =
                currentLanguage.toUpperCase() === LanguageIsoCodes.En
                  ? languagesByEn
                  : languagesByPL;

              return (
                sortingArr.indexOf(a.languageISOCode) -
                sortingArr.indexOf(b.languageISOCode)
              );
            }),

          hiddenFromExistingPatients: field.hiddenFromExistingPatients,
          order: field.order,
          required: field.required,
          type: field.type,
          value,
          defaultCountValues,
        };

        return result;
      })
      .sort((a, b) => a.order - b.order),
  };
};

export const transformCreateEditFormToCreateBlankForm = (
  formValues: CreateEditFormValues,
  clinicId: string
): CreateFormSchema => {
  const fields = formValues.fields.map(
    ({ fillValue, id, defaultCountValues, ...field }) => {
      return {
        ...field,

        value: JSON.stringify(field.value),

        translations: field.translations.map((translation) => {
          return {
            ...translation,
            value: JSON.stringify(translation.value),
          };
        }),
      };
    }
  );

  return {
    clinicId,
    serviceId: formValues.serviceId,
    name: formValues.name,
    fields,
  };
};

export const transformCreateEditFormToUpdateBlankForm = (
  formValues: CreateEditFormValues
): UpdateFormSchema => {
  const fields = formValues.fields.map(
    ({ fillValue, id, defaultCountValues, ...field }) => {
      const result: Partial<UpdateFormFieldSchema> = {
        ...field,
        value: JSON.stringify(field.value),

        translations: field.translations.map(({ fieldId, ...translation }) => {
          const translationResult: UpdateFormFieldSchema = {
            ...translation,
            value: (JSON.stringify(translation.value) as string) || "",
          };
          return translationResult;
        }),
      };

      if (typeof id === "string") {
        result.id = id;
      }

      return result as UpdateFormFieldSchema;
    }
  );

  return {
    id: formValues.formId || "",
    serviceId: formValues.serviceId,
    name: formValues.name,
    fields,
  };
};
