import { ClinicIcon } from "src/UI/Svg";
import {
  AggregatedScheduleAppointmentEntityWithRelationsByClientResponse,
  AggregatedScheduleAppointmentEntityWithRelationsByDoctorResponse,
} from "src/graphql/generated";
import { useTranslation } from "react-i18next";
import { getDateFromScheduleAppointment } from "src/utils";
import { TScheduleAppointmentByRole } from "src/types";
import { Account, InfoBlock, Item, List, Name, UserBlock } from "./styled";

interface IAppointmentSearchComponent {
  item:
    | AggregatedScheduleAppointmentEntityWithRelationsByClientResponse
    | AggregatedScheduleAppointmentEntityWithRelationsByDoctorResponse;
  handleShowModal: (appointment: TScheduleAppointmentByRole) => () => void;
}

function AppointmentSearchComponent({
  item,
  handleShowModal,
}: IAppointmentSearchComponent) {
  const { i18n } = useTranslation();

  return (
    <UserBlock onClick={handleShowModal(item as TScheduleAppointmentByRole)}>
      <Account>
        <ClinicIcon />
      </Account>
      <InfoBlock>
        <Name>{`${item?.patient?.firstName} ${item?.patient?.lastName}`}</Name>
        <List>
          <Item>{item?.service?.name}</Item>

          <Item>
            <span />
            {item?.schedule?.dateFrom && item?.schedule?.minuteFrom
              ? getDateFromScheduleAppointment(
                  item.schedule.dateFrom,
                  item.schedule.minuteFrom,
                  i18n
                )
              : null}
          </Item>
        </List>
      </InfoBlock>
    </UserBlock>
  );
}

export default AppointmentSearchComponent;
