import { FormFieldTypes } from "src/graphql/generated";
import { TPreviewAnswerFilledForm } from "src/types";
import { EActivityComponentSize } from "src/types/enum";
import { PatientFormPreview } from "./styled";
import ActivityComponentPatientFormCheckbox from "./ActivityComponentPatientFormCheckbox";
import ActivityComponentPatientFormDefault from "./ActivityComponentPatientFormDefault";
import ActivityComponentPatientFormTextarea from "./ActivityComponentPatientFormTextarea";
import ActivityComponentPatientFormText from "./ActivityComponentPatientFormText";

interface IActivityComponentPatientFormProps {
  size?: EActivityComponentSize;
  patientForm?: TPreviewAnswerFilledForm;
}

function ActivityComponentPatientForm({
  size = EActivityComponentSize.small,
  patientForm,
}: IActivityComponentPatientFormProps) {
  return (
    <PatientFormPreview>
      {patientForm?.answer?.answers?.map((answer, index, array) => {
        switch (answer.fieldType) {
          case FormFieldTypes.Checkbox:
            const isShowBottomBorder = !(
              index + 1 < array.length &&
              array[index + 1].fieldType === FormFieldTypes.Checkbox
            );

            return (
              <ActivityComponentPatientFormCheckbox
                key={index}
                answer={answer}
                fields={patientForm.fields}
                isShowBottomBorder={isShowBottomBorder}
                size={size}
              />
            );

          case FormFieldTypes.TextArea:
            return (
              <ActivityComponentPatientFormTextarea
                key={index}
                answer={answer}
                fields={patientForm.fields}
                size={size}
              />
            );

          case FormFieldTypes.Text:
            return (
              <ActivityComponentPatientFormText
                key={index}
                answer={answer}
                fields={patientForm.fields}
                size={size}
              />
            );

          default:
            return (
              <ActivityComponentPatientFormDefault
                key={index}
                answer={answer}
                fields={patientForm.fields}
                size={size}
              />
            );
        }
      })}
    </PatientFormPreview>
  );
}

export default ActivityComponentPatientForm;
