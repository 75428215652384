import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
`;

export const Wrapper = styled.div`
  &:first-child {
    width: calc(40% - 10px);
  }

  &:last-child {
    width: calc(60% - 10px);
  }
`;
