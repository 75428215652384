import { gql } from "@apollo/client";

export const FIND_AND_COUNT_MANY_APPOIINTMENTS_WITH_RELATIONS_BY_DOCTOR = gql`
  query FindAndCountManyScheduleAppointmentsWithRelationsByDoctor(
    $schema: FindAndCountManyScheduleAppointmentsWithRelationsByDoctorSchema!
  ) {
    findAndCountManyScheduleAppointmentsWithRelationsByDoctor(schema: $schema) {
      appointments {
        id
        scheduleId
        patientId
        status
        createdAt
        updatedAt
        schedule {
          id
          doctorId
          clinicId
          roomId
          serviceId
          eventType
          layoutType
          daysOfWeek
          dateFrom
          dateTo
          minuteFrom
          minuteTo
          createdAt
        }
        patient {
          id
          phoneNumber
          firstName
          lastName
          email
          dateOfBirth
          gender
          companyId
          comment
          emailDistribution
          smsDistribution
          activated
          passportNumber
          status
          address
          allergic
          allergens
          postCode
          createdAt
          languageISOCode
        }
        service {
          id
          name
          category {
            id
            name
          }
          description
          icd10Code
          icd9Code
          durationInMinutes
          price
          currencyCode
          hiddenFromPatients
          createdAt
        }
        room {
          id
          clinicId
          name
        }
        clinic {
          id
          companyId
          name
          address
          houseNumber
          index
          city
          logoFileId
          licenseNumber
          geographicalCoordinates {
            latitude
            longitude
          }
          code
          phoneNumber
          description
          hiddenFromPatients
          createdAt
          rooms {
            id
            clinicId
            name
          }
          workingDays {
            id
            clinicId
            day
            fromMinute
            toMinute
          }
        }
        answer {
          id
          formId
          patientId
          entityType
          entityId
          answers {
            fieldName
            fieldValue
            fieldType
            order
            value
          }
          createdAt
          form {
            id
            name
            serviceId
            clinicId
            updatedAt
            fields {
              id
              order
              value
              required
              formId
              type
              hiddenFromExistingPatients
              translations {
                id
                name
                value
                placeholder
                fieldId
                languageISOCode
              }
            }
          }
        }
      }
      count
    }
  }
`;
