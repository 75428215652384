import { gql } from "@apollo/client";

export const UPDATE_SERVICE_CATEGORY_BY_ID = gql`
  mutation UpdateServiceCategoryById(
    $schema: UpdateServiceCategoryByIdSchema!
  ) {
    updateServiceCategoryById(schema: $schema) {
      updated
    }
  }
`;
