import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { EButtonVariants } from "src/UI/Button";
import { PlusIcon } from "src/UI/Svg";
import { TemplatesTableView } from "src/views/TemplatesViews";
import { useState } from "react";
import { useTemplatesCount } from "src/hooks/templates";
import {
  ButtonStyled,
  Header,
  HeadingStyled,
  Row,
  Tab,
  Tabs,
  Wrapper,
} from "./styled";

export enum EViewsTypesTemplates {
  All = "All",
  Active = "Active",
}

function TemplatePage() {
  const { t } = useTranslation();
  const [viewType, setViewType] = useState<EViewsTypesTemplates>(
    EViewsTypesTemplates.All
  );

  const handleToggleViewType = (type: EViewsTypesTemplates) => () => {
    setViewType(type);
  };

  const { all, active } = useTemplatesCount();

  const isShowCurrent = viewType === EViewsTypesTemplates.Active ? true : false;

  return (
    <Wrapper>
      <Header>
        <Row>
          <HeadingStyled as="h3">{t("templates.title")}</HeadingStyled>
          <ButtonStyled
            as={Link}
            to="/cp/templates/actions"
            variant={EButtonVariants.Default}
          >
            <PlusIcon />
            {t("templates.button_add")}
          </ButtonStyled>
        </Row>
        <Tabs>
          <Tab
            isActive={viewType === EViewsTypesTemplates.All}
            onClick={handleToggleViewType(EViewsTypesTemplates.All)}
          >
            {t("templates.tabs.all")} <span>{all}</span>
          </Tab>
          <Tab
            isActive={viewType === EViewsTypesTemplates.Active}
            onClick={handleToggleViewType(EViewsTypesTemplates.Active)}
          >
            {t("templates.tabs.active")} <span>{active}</span>
          </Tab>
        </Tabs>
      </Header>

      <TemplatesTableView isShowCurrent={isShowCurrent} />
    </Wrapper>
  );
}

export default TemplatePage;
