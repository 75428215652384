import React from "react";
import { LogoFullIcon } from "src/UI/Svg";
import { Heading } from "src/UI/Heading";
import { useTranslation } from "react-i18next";
import { PATHS } from "src/constants/constants";
import { useNavigate } from "react-router-dom";
import {
  CheckEmailWrapper,
  EmailIcon,
  EmailSubtitle,
  WrapperNav,
} from "./styled";

function CheckEmail() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goHome = () => {
    navigate(`${PATHS.home}`);
  };
  return (
    <>
      <WrapperNav onClick={goHome}>
        <LogoFullIcon />
      </WrapperNav>
      <CheckEmailWrapper>
        <EmailIcon src="/images/email_icon.png" alt="email" />
        <Heading as="h2">{t("auth.check_email.title")}</Heading>
        <EmailSubtitle>{t("auth.check_email.subtitle")}</EmailSubtitle>
      </CheckEmailWrapper>
    </>
  );
}

export default CheckEmail;
