import { useTranslation } from "react-i18next";
import { getTranslationsBySelectedLang } from "src/utils/form";
import { FormFieldTranslationEntityResponseParsed } from "src/types";
import { FieldError, useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { InputRHF } from "src/UI/Input";
import { Toggle } from "src/UI/Toggle";
import {
  CheckboxesWrapper,
  FormCheckboxWrapper,
  Label,
  LabelChecbox,
  ToggleBlock,
} from "./styled";

interface IFormItemComponentCheckboxProps {
  isPreview?: boolean;
  fieldOrder: number;
  fieldName: string;
}

export function FormItemComponentCheckbox({
  isPreview,
  fieldOrder,
  fieldName,
}: IFormItemComponentCheckboxProps) {
  const { i18n } = useTranslation();
  const {
    watch,
    register,
    setValue,
    formState: { errors },
  } = useFormContext();
  const fields = watch("fields");

  const [parsedCheckboxValue, setParsedCheckboxValue] = useState<
    { type: string; order: number; text: string }[] | null
  >(null);

  useEffect(() => {
    if (fields[fieldOrder]?.translations[0]?.value) {
      const isFillValue = !!fields[fieldOrder].fillValue;

      const checkboxValue =
        typeof fields[fieldOrder].translations[0].value === "string"
          ? JSON.parse(fields[fieldOrder].translations[0].value)
          : fields[fieldOrder].translations[0].value;

      const checkboxValues = checkboxValue.map(
        (val: { type: string; order: number; text: string }) => {
          if (val.type === "checkbox") {
            return { order: val.order, checked: false };
          }
          return { order: val.order, text: "" };
        }
      );

      const translation = getTranslationsBySelectedLang(
        i18n.language,
        fields[fieldOrder].translations
      );

      const translationValue =
        typeof translation.value === "string"
          ? JSON.parse(translation.value)
          : translation.value;

      const fillValue =
        typeof fields?.[fieldOrder]?.fillValue === "string"
          ? JSON.parse(fields?.[fieldOrder]?.fillValue)
          : fields?.[fieldOrder]?.fillValue;

      setParsedCheckboxValue(translationValue);
      if (!isFillValue) {
        setValue(`fields.${fieldOrder}.fillValue`, checkboxValues);
      } else {
        setValue(`fields.${fieldOrder}.fillValue`, fillValue);
      }
    }
  }, [fields[fieldOrder].translations[0], i18n.resolvedLanguage]);

  const translationBySelectedLang = getTranslationsBySelectedLang(
    i18n.language,
    fields[fieldOrder]
      .translations as FormFieldTranslationEntityResponseParsed[]
  );

  const checkboxArray = isPreview
    ? translationBySelectedLang.value?.filter(
        (checkbox: { type: string; order: number; text: string }) =>
          checkbox.type === "checkbox"
      )
    : parsedCheckboxValue?.filter((checkbox) => checkbox.type === "checkbox");

  const anotherArray = isPreview
    ? translationBySelectedLang.value?.filter(
        (checkbox: { type: string; order: number; text: string }) =>
          checkbox.type !== "checkbox"
      )
    : parsedCheckboxValue?.filter((checkbox) => checkbox.type !== "checkbox");

  const handleToggle = (checkboxOrder: number) => () => {
    if (isPreview) return;

    const newValue = !fields[fieldOrder].fillValue[checkboxOrder].checked;

    setValue(
      `fields.${fieldOrder}.fillValue.${checkboxOrder}.checked`,
      newValue
    );
  };

  return (
    <>
      <FormCheckboxWrapper>
        <Label>{fieldName} </Label>

        <CheckboxesWrapper>
          {checkboxArray?.map(
            (checkbox: { type: string; order: number; text: string }) => (
              <ToggleBlock key={checkbox.order}>
                <LabelChecbox>{checkbox.text}</LabelChecbox>
                <Toggle
                  isActive={
                    fields?.[fieldOrder]?.fillValue?.[checkbox.order]
                      ?.checked || false
                  }
                  onToggle={handleToggle(checkbox.order)}
                />
              </ToggleBlock>
            )
          )}
        </CheckboxesWrapper>
      </FormCheckboxWrapper>

      {anotherArray?.map(
        (checkbox: { type: string; order: number; text: string }) => (
          <InputRHF
            disabled={isPreview}
            key={checkbox.order}
            val={fields?.[fieldOrder]?.fillValue?.[checkbox.order]?.text || ""}
            label={checkbox.text as string}
            inputType="text"
            errorMessage={(errors.fields as any)?.[fieldOrder] as FieldError}
            register={register(
              `fields.${fieldOrder}.fillValue.${checkbox.order}.text`
            )}
          />
        )
      )}
    </>
  );
}
