import { gql } from "@apollo/client";

export const CREATE_CLINIC_ROOM = gql`
  mutation CreateClinicRoom($schema: CreateClinicRoomWithClinicIdSchema!) {
    createClinicRoom(schema: $schema) {
      clinicId
      id
      name
    }
  }
`;
