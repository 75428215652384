import { useTranslation } from "react-i18next";
import { transformCheckboxFieldResult } from "src/connectors/Forms/transformFilledForm";
import {
  FormFieldAnswerResponse,
  FormFieldEntityWithRelationsResponse,
  FormFieldTranslationEntityResponse,
} from "src/graphql/generated";
import { EActivityComponentSize } from "src/types/enum";
import {
  FieldElementView,
  FieldElementViewContainer,
  FieldName,
  FieldValue,
} from "./styled";
import { useGetFieldTranslation } from "./useGetFieldTranslation";

interface IActivityComponentPatientFormCheckbox {
  answer: FormFieldAnswerResponse;
  fields: Array<FormFieldEntityWithRelationsResponse>;
  isShowBottomBorder: boolean;
  size?: EActivityComponentSize;
}

function ActivityComponentPatientFormCheckbox({
  answer,
  fields,
  isShowBottomBorder,
  size,
}: IActivityComponentPatientFormCheckbox) {
  const { t } = useTranslation();
  const fieldTranslation = useGetFieldTranslation({ answer, fields });

  const checkboxes = transformCheckboxFieldResult(
    answer,
    fieldTranslation as FormFieldTranslationEntityResponse
  );

  return (
    <FieldElementView $isShowBotBorder={isShowBottomBorder} $isShowTopBorder>
      <FieldElementViewContainer>
        <FieldValue $size={size}>{answer.fieldName}</FieldValue>
      </FieldElementViewContainer>

      {checkboxes && (
        <FieldElementView>
          {checkboxes.map(({ variantTitle, checked, text }, index) => (
            <FieldElementViewContainer key={index}>
              <FieldName $size={size}>
                {variantTitle}:{" "}
                {typeof checked === "boolean" && (
                  <>
                    {checked && (
                      <FieldValue $size={size}>
                        {t(
                          "forms.tabs.patients_submissions.view_filled_form.yes"
                        )}
                      </FieldValue>
                    )}

                    {!checked && (
                      <FieldValue $size={size}>
                        {t(
                          "forms.tabs.patients_submissions.view_filled_form.no"
                        )}
                      </FieldValue>
                    )}
                  </>
                )}
                {text && <FieldValue>{text}</FieldValue>}
              </FieldName>
            </FieldElementViewContainer>
          ))}
        </FieldElementView>
      )}
    </FieldElementView>
  );
}

export default ActivityComponentPatientFormCheckbox;
