import React from "react";
import styled from "styled-components";

interface IToggleStyledProps {
  isActive: boolean;
  isDisabled?: boolean;
}

const ToggleStyled = styled.div<IToggleStyledProps>`
  box-sizing: border-box;
  position: relative;
  min-width: 49px;
  display: flex;
  align-content: center;
  align-items: center;
  background: ${({ theme, isActive }) =>
    theme.colors[isActive ? "green" : "grayC4"]};
  cursor: ${({ isDisabled }) => (isDisabled ? "not-allowed" : "pointer")};
  border-radius: 48px;
  padding: 4px;
  gap: 10px;
  width: 48px;
  height: 24px;

  div {
    position: absolute;
    transition: all 0.25s ease;
    top: 50%;
    left: ${({ isActive }) => (isActive ? "calc(100% - 20px)" : "4px")};
    transform: translateY(-50%);
    background: ${({ theme }) => theme.colors.background};
    border-radius: 50%;
    width: 16px;
    height: 16px;
  }
`;

function Toggle({
  isActive,
  onToggle,
  disabled,
}: {
  isActive: boolean;
  onToggle: () => void;
  disabled?: boolean;
}) {
  const handleToggle = () => {
    if (!disabled) {
      onToggle();
    }
  };

  return (
    <ToggleStyled
      isActive={isActive}
      onClick={handleToggle}
      isDisabled={disabled}
    >
      <div />
    </ToggleStyled>
  );
}

export default Toggle;
