import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import _ from "lodash";
import RowItem from "./RowItem";
import { ChevronDownIcon } from "../Svg";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import {
  IconStyled,
  Menu,
  MultiselectStyled,
  Placeholder,
  RowStyled,
} from "./styled";

interface MultiselectProps {
  value: string[];
  placeholder: string | ReactNode;
  onSelect: (val: any) => void;
  options: Array<{ item: string; shortItem: string; value: string }>;
  label: string;
  isShowOnlyPlaceholder?: boolean;
  maxItemsRender?: number;
  customIcon?: ReactNode;
}

function Multiselect({
  value,
  placeholder,
  onSelect,
  options,
  customIcon,
  isShowOnlyPlaceholder,
  maxItemsRender = 3,
  ...props
}: MultiselectProps) {
  const dropdown = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const menu = useRef<HTMLDivElement | null>(null);

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  useEffect(() => {
    if (value) setSelectedItems(value);
  }, [value]);

  useOutsideClick({ ref: dropdown, handler: () => setShowMenu(false) });

  const onSelectHandler = useCallback(
    (val: string) => {
      const isItemAlreadyInArray = selectedItems.includes(val);
      const addItemToSelectedArray = [...selectedItems, val];
      const filterItemFromSelectedArray = selectedItems?.filter(
        (item) => val !== item
      );

      setSelectedItems(
        isItemAlreadyInArray
          ? filterItemFromSelectedArray
          : addItemToSelectedArray
      );
      onSelect(
        isItemAlreadyInArray
          ? filterItemFromSelectedArray
          : addItemToSelectedArray
      );
    },
    [setSelectedItems, selectedItems]
  );

  const onToggleShow = useCallback(() => {
    setShowMenu(!showMenu);
  }, [showMenu]);

  const renderValues = useMemo(() => {
    if (selectedItems.length > 0) {
      const optsFiltered = options.filter((opt) =>
        _.includes(selectedItems, opt.value)
      );
      const optsRender = optsFiltered.map((opt) =>
        optsFiltered.length > 2 ? opt.shortItem : opt.item
      );
      return optsRender.length > maxItemsRender
        ? `${optsRender.slice(0, maxItemsRender).join(", ")} +${
            optsRender.length - maxItemsRender
          }`
        : optsRender.join(", ");
    }
    return "";
  }, [selectedItems, options]);

  return (
    <MultiselectStyled ref={dropdown} {...props}>
      <RowStyled onClick={onToggleShow} showMenu={showMenu} className="select">
        {selectedItems.length > 0 && !isShowOnlyPlaceholder ? (
          <Placeholder>{renderValues}</Placeholder>
        ) : (
          <Placeholder>{placeholder}</Placeholder>
        )}
        <IconStyled showMenu={showMenu && !customIcon}>
          {customIcon || <ChevronDownIcon />}
        </IconStyled>
      </RowStyled>
      <Menu ref={menu} showMenu={showMenu} className="dropdown">
        {options.map((opt) => {
          const isSelected = _.includes(selectedItems, opt.value);

          return (
            <RowItem
              isActive={false}
              isSelected={isSelected}
              key={JSON.stringify(opt)}
              onSelect={onSelectHandler}
              item={opt.item}
              value={opt.value}
            />
          );
        })}
      </Menu>
    </MultiselectStyled>
  );
}

export default Multiselect;
