import { useTranslation } from "react-i18next";
import { useUpdateDoctorByUserIdByDoctor } from "src/graphql/hooks/useMutations";
import { FieldError, FormProvider, useForm } from "react-hook-form";
import { EButtonVariants } from "src/UI/Button";
import {
  InputAutosizeRHF,
  InputRHF,
  PhoneInput,
  PhoneInputSmallRHF,
} from "src/UI/Input";
import { useAuthContext } from "src/context/AuthContext";
import { useEffect } from "react";
import { toast } from "react-toastify";

import { Loading } from "src/UI/Loading";
import { GET_CURRENT_DOCTOR } from "src/graphql/queries";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateDoctorByDoctorSchema } from "src/validation/schemas/updateDoctorByDoctorSchema";
import { Buttons, FooterButton, FormWrapper, InputsRow } from "./styled";

interface FormValues {
  full_name: string;
  email: string;
  phoneNumber: string;
  description: string;
  location: string;
  medicalCertificateNumber: string;
  passportNumber: string;
  promoCode: string;
}

function MainFormComponent() {
  const { t } = useTranslation();

  const { currentUserData, isDoctor } = useAuthContext();

  const [
    updateDoctorByDoctorByUserIdMutation,
    { loading: updateDoctorByDoctorByUserIdLoading },
  ] = useUpdateDoctorByUserIdByDoctor();

  const methods = useForm<FormValues>({
    resolver: yupResolver(updateDoctorByDoctorSchema),
    mode: "onChange",
    defaultValues: {
      full_name: "",
      email: "",
      phoneNumber: "",
      description: "",
      location: "",
      medicalCertificateNumber: "",
      passportNumber: "",
      promoCode: "",
    },
  });

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = methods;

  const resetToInitData = () =>
    reset({
      full_name:
        `${currentUserData?.firstName} ${currentUserData?.lastName}` || "",
      email: currentUserData?.email || "",
      phoneNumber: currentUserData?.phoneNumber || "",
      description: currentUserData?.description || "",
      medicalCertificateNumber: currentUserData?.medicalCertificateNumber || "",
      passportNumber: currentUserData?.passportNumber || "",
      promoCode: currentUserData?.promoCode || "",
      location: "",
    });

  useEffect(() => {
    resetToInitData();
  }, [currentUserData]);

  const data = watch();

  const onSave = (formData: FormValues) => {
    if (isDoctor)
      updateDoctorByDoctorByUserIdMutation({
        variables: {
          schema: {
            medicalCertificateNumber: formData.medicalCertificateNumber,
            phoneNumber: formData.phoneNumber,
            ...(formData.description
              ? { description: formData.description }
              : { description: null }),
            ...(formData.passportNumber
              ? {
                  passportNumber: formData.passportNumber,
                }
              : {
                  passportNumber: null,
                }),
            ...(formData.promoCode
              ? { promoCode: formData.promoCode }
              : { promoCode: null }),
          },
        },
        refetchQueries: [GET_CURRENT_DOCTOR],
        awaitRefetchQueries: true,
        onCompleted() {
          toast.success(t("app.successful"));
        },
      });
  };

  const onChangePhoneNumberHandler = (val: string) =>
    setValue("phoneNumber", val);

  return (
    <FormProvider {...methods}>
      <FormWrapper onSubmit={handleSubmit(onSave)}>
        <InputsRow>
          <InputRHF
            val={data.full_name}
            label={t("user.person_data.data.labels.full_name") as string}
            inputType="text"
            errorMessage={errors.full_name}
            register={register("full_name", {
              required: true,
            })}
            disabled={true}
          />
          <InputRHF
            val={data.email}
            label={t("user.person_data.data.labels.email") as string}
            inputType="email"
            errorMessage={errors.email}
            register={register("email", {
              required: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: t("auth.login.email_error"),
              },
            })}
            disabled={true}
          />
        </InputsRow>

        {isDoctor ? (
          <>
            <InputsRow>
              <PhoneInputSmallRHF
                label={t("add_doctor.label.phone") || ""}
                value={data.phoneNumber}
                name="phoneNumber"
              />
              <InputRHF
                val={data.passportNumber as string}
                label={t("add_doctor.label.passport") as string}
                register={register("passportNumber")}
                inputType="text"
                errorMessage={errors.passportNumber}
              />
            </InputsRow>
            <InputsRow>
              <InputRHF
                val={data.promoCode as string}
                label={t("add_doctor.label.promo") as string}
                register={register("promoCode")}
                inputType="text"
                errorMessage={errors.promoCode}
              />
              <InputRHF
                val={data.medicalCertificateNumber}
                label={t("add_doctor.label.certificate") as string}
                register={register("medicalCertificateNumber")}
                inputType="text"
                errorMessage={errors.medicalCertificateNumber}
              />
            </InputsRow>
            <InputAutosizeRHF
              val={data.description as string}
              label={t("booking.add_service.label.description") as string}
              inputType="text"
              errorMessage={errors.description as FieldError}
              register={register("description")}
            />
          </>
        ) : (
          <InputsRow>
            <PhoneInput
              value={data.phoneNumber}
              onChange={onChangePhoneNumberHandler}
              id="phoneNumber"
              disabled={true}
            />
          </InputsRow>
        )}

        {isDoctor ? (
          <Buttons>
            <FooterButton
              variant={EButtonVariants.Text}
              onClick={resetToInitData}
              type="button"
            >
              {t("user.person_data.data.buttons.cancel")}
            </FooterButton>
            <FooterButton
              variant={EButtonVariants.Default}
              disabled={updateDoctorByDoctorByUserIdLoading}
            >
              {updateDoctorByDoctorByUserIdLoading ? (
                <Loading type="secondary" padding="0" />
              ) : null}
              {t("user.person_data.data.buttons.save")}
            </FooterButton>
          </Buttons>
        ) : null}
      </FormWrapper>
    </FormProvider>
  );
}

export default MainFormComponent;
