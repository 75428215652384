import { gql } from "@apollo/client";

export const GET_FIND_MANY_SERVICES = gql`
  query FindManyServices($schema: FindManyServicesSchema!) {
    findManyServices(schema: $schema) {
      services {
        id
        name
        category {
          id
          name
        }
        description
        icd10Code
        icd9Code
        durationInMinutes
        price
        currencyCode
        hiddenFromPatients
        createdAt
        clinicRooms {
          id
          clinicId
          name
        }
        doctorIds
      }
    }
  }
`;
