import { Heading } from "src/UI/Heading";
import { UserSearchComponent } from "src/components/DashboardComponents/SearchComponents";
import styled from "styled-components";

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.colors.borderInputDefault}`};
  padding: 20px 20px 0 20px;
`;

export const HeadingStyled = styled(Heading)`
  font-size: 24px;
  line-height: 100%;
`;

export const TitleIcon = styled.div`
  display: flex;

  svg path {
    stroke: ${({ theme }) => theme.colors.gray};
  }
`;

export const Tabs = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;

export const Tab = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  padding-bottom: 16px;
  color: ${({ theme, isActive }) => theme.colors[isActive ? "green" : "black"]};
  border-bottom: 1px solid
    ${({ theme, isActive }) => (isActive ? theme.colors.green : "transparent")};
  cursor: pointer;
  span {
    min-width: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2px 4.5px;
    background: ${({ theme, isActive }) =>
      theme.colors[isActive ? "green" : "grayC4"]};
    border-radius: 800px;
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const UserSearchStyled = styled(UserSearchComponent)`
  min-width: 600px;
`;

export const LoadingWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;
