import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  gap: 20px;
`;

export const Heading = styled.h3`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  margin-bottom: 10px;
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 16px;
  }
`;

export const List = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
`;
