import { gql } from "@apollo/client";

export const CONFIRM_SCHEDULE_APPOINTMENT_BY_CLIENT = gql`
  mutation ConfirmScheduleAppointmentByClient(
    $schema: ConfirmScheduleAppointmentByClientSchema!
  ) {
    confirmScheduleAppointmentByClient(schema: $schema) {
      confirmed
    }
  }
`;
