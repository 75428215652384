import styled from "styled-components";
import { Dropdown } from "src/UI/Dropdown";
import { EventTypes } from "src/graphql/generated";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px;
  gap: 20px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  border-radius: 5px;
  width: 100%;
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 16px;
    gap: 16px;
  }
`;
export const RowWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 30px;
  width: 100%;
`;
export const FooterRowWrapper = styled.div`
  display: flex;
  align-items: stretch;
  gap: 30px;
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
`;
export const HeadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DropdownWrapper = styled.div`
  max-width: 136px;
`;

export const FormColumnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 20px;
  gap: 30px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  border-radius: 5px;
  width: 100%;
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 16px;
    gap: 24px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 7px;
  height: 100%;
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 6px;
  }
`;

export const DropdownStyled = styled(Dropdown)<{ workingType?: string }>`
  border-radius: 5px;
  width: 100%;
  font-size: 16px;

  & .selection *,
  .dropdown * {
    font-size: 16px;
  }

  & .selection {
    background-color: ${({ workingType }) => {
      if (!!workingType && workingType === EventTypes.Working) {
        return "#E7F6F5";
      }
      if (!!workingType && workingType === EventTypes.Vacation) {
        return "#FEF9EB";
      }
      return "initial";
    }};
  }

  & .selection * {
    color: ${({ theme, workingType }) => {
      if (!!workingType && workingType === EventTypes.Working) {
        return theme.colors.green;
      }
      if (!!workingType && workingType === EventTypes.Vacation) {
        return theme.colors.yellow;
      }
      return "initial";
    }};

    & svg path {
      stroke: ${({ theme, workingType }) => {
        if (!!workingType && workingType === EventTypes.Working) {
          return theme.colors.green;
        }
        if (!!workingType && workingType === EventTypes.Vacation) {
          return theme.colors.yellow;
        }
        return theme.colors.black;
      }};
    }
  }
  & > div:first-child {
    padding: 7px;
  }
`;

export const FormColumn = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  align-content: flex-start;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  flex: 1;
  max-width: calc(33.33% - 20px);
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 10px;
  }
`;

export const Label = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.gray};
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 12px;
  }
`;

export const DateInputStyled = styled.div`
  padding: 8px 10px;
  gap: 4px;
  min-height: 32px;
  background: ${({ theme }) => theme.colors.lightBiege};
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const ButtonIcon = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;

  &:hover {
    background: ${({ theme }) => theme.colors.greenHover};
  }
`;

export const ErrorStyled = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: right;
  color: ${({ theme }) => theme.colors.red};
  position: absolute;
  bottom: -15px;
  right: 0;
  transform: translateX(-50%);
`;
