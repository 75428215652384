import React, { useCallback, useEffect } from "react";
import { nanoid } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";
import { useAppAction, useAppState } from "src/store/app/hooks";
import { EModals } from "src/store/app/types";
import { Modal } from "src/UI/Modal";
import { EButtonVariants } from "src/UI/Button";
import { EyeIcon } from "src/UI/Svg";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FindAndCountManyBlankFormsByClientDocument,
  ServiceEntityWithRelationsResponse,
  ServiceSortFields,
  SortingOrders,
  useCreateFormByClientMutation,
  useFindBlankFormByServiceIdLazyQuery,
  useUpdateFormByClientMutation,
} from "src/graphql/generated";
import { useClinicsState } from "src/store/clinics/hooks";
import { useFindManyServices } from "src/graphql/hooks/useQueries";
import { toast } from "react-toastify";
import DropdownInputRHF from "src/UI/DropdownInput/DropdownInputRHF";
import { InputRHF } from "src/UI/Input";
import { createEditFormSchema } from "src/validation/schemas/createEditFormSchema";
import {
  transformBlankFormToCreateEditForm,
  transformCreateEditFormToCreateBlankForm,
  transformCreateEditFormToUpdateBlankForm,
} from "src/connectors/Forms/transformCreateEditForm";
import { CreateEditFormValues } from "src/types/CreateEditForm";
import { ButtonStyled, Content, Form, InputsRow, StyledInput } from "./styled";
import { CreateEditFormFields } from "./CreateEditFormFields";

const formDefaultValues: CreateEditFormValues = {
  fields: [],
  name: "",
  serviceId: "",
  clinicId: "",
  defaultCountFields: 0,
};

const formId = nanoid();

function CreateEditFormModal() {
  const { t, i18n } = useTranslation();
  const { currentClinicId: clinicId } = useClinicsState();
  const { modals } = useAppState();
  const { onCloseModal, onShowPreviewForm } = useAppAction();

  const { data: servicesData, loading } = useFindManyServices({
    variables: {
      schema: {
        clinicId,
        sortField: ServiceSortFields.CreatedAt,
        sortOrder: SortingOrders.Descending,
      },
    },
    skip: !modals[EModals.CreateEditForm].active,
  });
  const [createForm, { loading: createFormLoading }] =
    useCreateFormByClientMutation();
  const [updateForm, { loading: updateFormLoading }] =
    useUpdateFormByClientMutation();
  const [getFormData, { data: existFormData }] =
    useFindBlankFormByServiceIdLazyQuery({
      variables: {
        schema: {
          serviceId: modals[EModals.CreateEditForm].data?.serviceId || "",
        },
      },
      fetchPolicy: "network-only",
    });

  const methods = useForm<CreateEditFormValues>({
    mode: "onChange",
    resolver: yupResolver(
      createEditFormSchema(
        existFormData &&
          (transformBlankFormToCreateEditForm(
            existFormData,
            i18n.language
          ) as CreateEditFormValues)
      )
    ),
    defaultValues: formDefaultValues,
  });

  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const isEditForm = !!modals[EModals.CreateEditForm].data?.isEditForm;
  const [serviceId, name, fields, formIdValue] = watch([
    "serviceId",
    "name",
    "fields",
    "formId",
  ]);

  useEffect(() => {
    if (
      !modals[EModals.CreateEditForm].data.formValue &&
      modals[EModals.CreateEditForm].data?.serviceId &&
      modals[EModals.CreateEditForm].active
    ) {
      getFormData({
        variables: {
          schema: {
            serviceId: modals[EModals.CreateEditForm].data?.serviceId,
          },
        },
        onCompleted: (data) => {
          reset(
            transformBlankFormToCreateEditForm(
              data,
              i18n.language,
              modals[EModals.CreateEditForm].data.formId
            ) as CreateEditFormValues
          );
        },
      });
    }
  }, [
    modals[EModals.CreateEditForm].data?.serviceId,
    modals[EModals.CreateEditForm].active,
  ]);

  useEffect(() => {
    if (
      modals[EModals.CreateEditForm].data.formValue &&
      modals[EModals.CreateEditForm].data?.defaultServiceId &&
      modals[EModals.CreateEditForm].active
    ) {
      reset(modals[EModals.CreateEditForm].data.formValue);
      getFormData({
        variables: {
          schema: {
            serviceId: modals[EModals.CreateEditForm].data?.defaultServiceId,
          },
        },
        onCompleted: (data) => {
          setValue(
            "defaultCountFields",
            data.findBlankFormByServiceId.fields.length - 1
          );

          data.findBlankFormByServiceId.fields.forEach((item, index) => {
            if (item.value?.length && item.translations[0].value) {
              setValue(
                `fields.${index}.defaultCountValues`,
                JSON.parse(item.translations[0].value).length - 1
              );
            }
          });
        },
      });
    }
  }, [
    modals[EModals.CreateEditForm].data.formValue,
    modals[EModals.CreateEditForm].data?.defaultServiceId,
    modals[EModals.CreateEditForm].active,
  ]);

  useEffect(() => {
    if (
      modals[EModals.CreateEditForm].data.formValue &&
      modals[EModals.CreateEditForm].active
    ) {
      reset(modals[EModals.CreateEditForm].data.formValue);
    }
  }, [
    modals[EModals.CreateEditForm].data.formValue,
    modals[EModals.CreateEditForm].active,
  ]);

  useEffect(() => {
    if (!modals[EModals.CreateEditForm].active) {
      reset(formDefaultValues);
    }
  }, [modals[EModals.CreateEditForm].active]);

  const closeModal = useCallback(() => {
    onCloseModal(EModals.CreateEditForm);
  }, [onCloseModal]);

  const formHandler = (formValues: CreateEditFormValues) => {
    if (isEditForm) {
      const transformValues =
        transformCreateEditFormToUpdateBlankForm(formValues);

      updateForm({
        variables: {
          schema: transformValues,
        },
        refetchQueries: [FindAndCountManyBlankFormsByClientDocument],
        onError(error) {
          if (error) toast.error(error.message);
        },
        onCompleted: () => {
          toast.success(t("app.successful"));
          closeModal();
        },
      });

      return;
    }

    const transformValues = transformCreateEditFormToCreateBlankForm(
      formValues,
      clinicId
    );

    createForm({
      variables: {
        schema: transformValues,
      },
      refetchQueries: [FindAndCountManyBlankFormsByClientDocument],
      onError(error) {
        if (error) toast.error(error.message);
      },
      onCompleted: () => {
        toast.success(t("app.successful"));
        closeModal();
      },
    });
  };

  const handlePreviewClick = () => {
    onCloseModal(EModals.CreateEditForm);
    onShowPreviewForm({
      isEditForm,
      isShowCreateEditModalAfterClose: true,
      clinicId,
      fields,
      name,
      serviceId,
      formId: formIdValue,
      defaultServiceId: modals[EModals.CreateEditForm].data?.defaultServiceId,
    });
  };

  const servicesOpts = servicesData?.findManyServices?.services?.map(
    (service: ServiceEntityWithRelationsResponse) => ({
      value: service.id,
      item: service.name,
    })
  );

  if (!modals[EModals.CreateEditForm].active) return null;

  return (
    <FormProvider {...methods}>
      <Modal
        onCloseAction={closeModal}
        modal={EModals.CreateEditForm}
        title={
          isEditForm
            ? t("forms.modals.custom.edit_title")
            : t("forms.modals.custom.title")
        }
        titleChildren={
          <ButtonStyled
            variant={EButtonVariants.Gray}
            type="button"
            disabled={!fields?.length || !name}
            onClick={handlePreviewClick}
          >
            <EyeIcon /> {t("forms.modals.custom.button")}
          </ButtonStyled>
        }
        footerContent={
          <CreateEditFormFields
            isEditForm={isEditForm}
            disabledSubmit={loading || createFormLoading || updateFormLoading}
            loading={loading || createFormLoading || updateFormLoading}
            submitFormId={formId}
          />
        }
      >
        <Form onSubmit={handleSubmit(formHandler)} id={formId}>
          <Content>
            <InputsRow>
              <StyledInput>
                <InputRHF
                  val={name}
                  label={t("forms.modals.fields.title") as string}
                  register={register("name")}
                  inputType="text"
                  errorMessage={errors.name}
                />
              </StyledInput>

              <DropdownInputRHF
                name="serviceId"
                value={serviceId}
                placeholder=""
                options={servicesOpts}
                label={t("calendar.filters.service" as string)}
                adaptedForMobile={false}
                errorMessage={errors.serviceId}
                isFocusOnError
              />
            </InputsRow>
          </Content>
        </Form>
      </Modal>
    </FormProvider>
  );
}

export default CreateEditFormModal;
