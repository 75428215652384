import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { EButtonVariants } from "src/UI/Button";
import { Modal } from "src/UI/Modal";
import { useAppAction, useAppState } from "src/store/app/hooks";
import { EModals } from "src/store/app/types";
import { useCancelScheduleAppointmentByPatient } from "src/graphql/hooks/useMutations";
import { toast } from "react-toastify";
import { Loading } from "src/UI/Loading";
import { useSearchParams } from "react-router-dom";
import { FIND_SCHEDULE_APPOINTMENT_WITH_RELATIONS_BY_HASH_BY_PATIENT } from "src/graphql/queries";
import { useCloseScheduleWaitlistByPatientMutation } from "src/graphql/generated";
import { ButtonStyled, Buttons, Content } from "./styled";

function CancelAppointmentModal() {
  const { t } = useTranslation();
  const { modals } = useAppState();
  const { onCloseCancelAppointmentModal } = useAppAction();
  const [query] = useSearchParams();
  const hash = query.get("hash") as string;
  const waitingApptHash = query.get("waitingApptHash") as string;

  const isWaitingAppointment = !!waitingApptHash;

  const [cancelAppointmentMutation, { loading: closeApptLoading }] =
    useCancelScheduleAppointmentByPatient();

  const [
    cancelWaitingAppointmentMutation,
    { loading: closeWaitingApptLoading },
  ] = useCloseScheduleWaitlistByPatientMutation();

  const closeModal = useCallback(
    (accept: boolean) => {
      onCloseCancelAppointmentModal(accept);
    },
    [onCloseCancelAppointmentModal]
  );

  const handleAccept = () => {
    if (isWaitingAppointment) {
      cancelWaitingAppointmentMutation({
        variables: {
          schema: {
            hash: waitingApptHash,
          },
        },
        onCompleted: (response) => {
          modals[EModals.CancelAppointment].props.accept();
          if (response) {
            toast.success(t("app.successful"));
            onCloseCancelAppointmentModal(false);
          }
        },
      });
    } else {
      cancelAppointmentMutation({
        variables: {
          schema: {
            hash,
          },
        },
        onCompleted: (response) => {
          modals[EModals.CancelAppointment].props.accept();
          if (response) {
            toast.success(t("app.successful"));
            onCloseCancelAppointmentModal(false);
          }
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          FIND_SCHEDULE_APPOINTMENT_WITH_RELATIONS_BY_HASH_BY_PATIENT,
        ],
      });
    }
  };

  const loading = closeApptLoading || closeWaitingApptLoading;

  if (!modals[EModals.CancelAppointment].active) return null;
  return (
    <Modal
      modal={EModals.CancelAppointment}
      title={t("app.booking.patient.cancel.modal_title")}
      onCloseAction={() => closeModal(false)}
    >
      <Content>{t("app.booking.patient.cancel.modal_content")}</Content>
      <Buttons>
        <ButtonStyled
          variant={EButtonVariants.Text}
          onClick={() => closeModal(false)}
        >
          {t("common.cancel")}
        </ButtonStyled>
        <ButtonStyled
          variant={EButtonVariants.Red}
          onClick={handleAccept}
          disabled={loading}
        >
          {loading && <Loading type="secondary" padding="0" />}
          {t("auth.create2fa.submit")}
        </ButtonStyled>
      </Buttons>
    </Modal>
  );
}

export default CancelAppointmentModal;
