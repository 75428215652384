import { Dropdown } from "src/UI/Dropdown";
import styled from "styled-components";

export const ServicesInfoWrapStyled = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

export const ClinicInfoWrapStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: calc(100% - 200px);
`;

export const DropdownStyled = styled(Dropdown)`
  max-width: 195px;

  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: 100%;
  }
`;

export const ClinicInfoAddressStyled = styled.div`
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ClinicInfoPhoneStyled = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkGrey};
`;
