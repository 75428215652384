import { DateInput } from "src/UI/DateInput";
import { FiltersTabs } from "src/UI/FiltersTabs";
import { Heading } from "src/UI/Heading";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 20px 0 20px;
  gap: 20px;
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  flex: 1;
  width: 100%;
`;

export const HeadingStyled = styled(Heading)`
  font-size: 24px;
  line-height: 120%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  width: 100%;
`;

export const DateInputStyled = styled(DateInput)`
  width: auto !important;
  min-width: 120px;
  cursor: pointer;

  & > div:first-child > div:first-child > div:first-child {
    padding: 5px 0 !important;
    min-height: auto !important;
  }
`;

export const TabsStyled = styled(FiltersTabs)`
  gap: 5px;
`;
