import React, { ReactNode } from "react";
import styled from "styled-components";
import { Heading } from "../../UI/Heading";

const Wrapper = styled.div<{ disableBlock: boolean | undefined }>`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  background: ${({ theme }) => theme.colors.background};
  border-radius: 10px;
  flex-direction: column;

  ${({ disableBlock }) => {
    if (disableBlock !== undefined && disableBlock) {
      return `
          position: relative;
          overflow: hidden;
          
          * {
            filter: ${disableBlock ? "blur(1px)" : "none"};
            pointer-events: ${disableBlock ? "none" : "auto"};   
          }
    
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            backdrop-filter: ${disableBlock ? "none" : "blur(1px)"}; 
            background-color: rgba(255, 255, 255, 0.5);
          }`;
    }
  }}
`;

const Header = styled.div<{ isBottomBorderNone: boolean | undefined }>`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: ${({ isBottomBorderNone, theme }) =>
    isBottomBorderNone
      ? "none"
      : `1px solid ${theme.colors.borderInputDefault}`};
`;

const HeadingStyled = styled(Heading)`
  font-size: 24px;
  line-height: 100%;
`;

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
`;
const CountWrapper = styled.span`
  color: ${({ theme }) => theme.colors.grayC4};
`;

function CardComponent({
  title,
  children,
  headerChildren,
  disableBlock,
  count,
  isBottomBorderNone,
  ...props
}: {
  title: string;
  children?: ReactNode;
  headerChildren?: ReactNode;
  disableBlock?: boolean;
  count?: number;
  isBottomBorderNone?: boolean;
}) {
  return (
    <Wrapper {...props} disableBlock={disableBlock}>
      <Header isBottomBorderNone={isBottomBorderNone}>
        <HeadingStyled as="h3">
          {title} {count ? <CountWrapper>({count})</CountWrapper> : null}
        </HeadingStyled>
        {headerChildren}
      </Header>
      <Content>{children}</Content>
    </Wrapper>
  );
}

export default CardComponent;
