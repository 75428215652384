interface ITransformBeforeSendSmsProps {
  clinicId: string;
  patientId: string;
}

export const transformBeforeSendSms = ({
  clinicId,
  patientId,
}: ITransformBeforeSendSmsProps) => {
  return { clinicId, patientId, content: "" };
};
