import React, { FunctionComponent } from "react";
import { Svg, SvgProps } from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
  <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#)">
      <path
        d="M3.91744 9.52989L3.40691 11.4358L1.54095 11.4752C0.9833 10.4409 0.666992 9.25753 0.666992 7.99997C0.666992 6.78393 0.962732 5.63718 1.48695 4.62744H1.48735L3.14858 4.932L3.8763 6.58326C3.72399 7.0273 3.64097 7.50397 3.64097 7.99997C3.64103 8.53829 3.73854 9.05405 3.91744 9.52989Z"
        fill="#FBBB00"
      />
      <path
        d="M15.2051 6.63C15.2893 7.07361 15.3332 7.53175 15.3332 7.99996C15.3332 8.52498 15.278 9.03711 15.1729 9.53111C14.8159 11.2121 13.8831 12.68 12.5909 13.7188L12.5905 13.7184L10.4981 13.6116L10.202 11.7629C11.0594 11.2601 11.7295 10.4732 12.0825 9.53111H8.16113V6.63H12.1397H15.2051Z"
        fill="#518EF8"
      />
      <path
        d="M12.591 13.7184L12.5914 13.7188C11.3347 14.7289 9.73822 15.3333 8.00039 15.3333C5.20768 15.3333 2.77963 13.7724 1.54102 11.4752L3.9175 9.52991C4.5368 11.1827 6.1312 12.3593 8.00039 12.3593C8.80382 12.3593 9.55652 12.1421 10.2024 11.7629L12.591 13.7184Z"
        fill="#28B446"
      />
      <path
        d="M12.6815 2.3549L10.3058 4.29983C9.63739 3.88201 8.84722 3.64064 8.00068 3.64064C6.08917 3.64064 4.46495 4.87118 3.87668 6.58325L1.48771 4.62743H1.4873C2.70779 2.27432 5.16646 0.666626 8.00068 0.666626C9.78002 0.666626 11.4115 1.30044 12.6815 2.3549Z"
        fill="#F14336"
      />
    </g>
    <defs>
      <clipPath id="">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </Svg>
);

export default Icon;
