import { useEffect, useState } from "react";
import Layout from "src/components/PatientBookingComponents/Layout/Layout";
import { EButtonVariants } from "src/UI/Button";
import { CalendarIcon, CheckIcon, CloseIcon } from "src/UI/Svg";
import { useTranslation } from "react-i18next";
import {
  AggregatedDoctorEntityResponse,
  AggregatedScheduleAppointmentEntityWithRelationsByPatientResponse,
  AggregatedScheduleWaitlistEntityByPatientResponse,
  ClinicEntityResponse,
  useFindManyFilesByIdsLazyQuery,
  useFindScheduleAppointmentWithRelationsByHashByPatientLazyQuery,
  useFindScheduleWaitlistByHashByPatientLazyQuery,
} from "src/graphql/generated";
import DoctorInfoComponent from "src/components/DoctorInfoComponent/DoctorInfoComponent";
import { Divider } from "src/UI/Divider";
import { theme } from "src/theme";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PATHS } from "src/constants/constants";
import { useAppAction } from "src/store/app/hooks";
import { ScreenLoader } from "src/UI/ScreenLoader";
import {
  getDateByLocale,
  getDateFromMinutesStartDay,
  getFormatDateByLocale,
} from "src/utils";
import { format } from "date-fns";
import {
  Address,
  ButtonStyled,
  Buttons,
  DateWrapper,
  DoctorInfoWrapper,
  Icon,
  Info,
  InfoListWrapper,
  InfoWrapper,
  Label,
  Text,
  TextWrapper,
  Title,
  Wrapper,
} from "./styled";
import AddToCalendarComponent from "./AddToCalendarComponent";

type TScheduleAppt =
  AggregatedScheduleAppointmentEntityWithRelationsByPatientResponse;

type TWaitingAppt = AggregatedScheduleWaitlistEntityByPatientResponse;

type TAppointment = TScheduleAppt | TWaitingAppt;

function ConfirmedAppointmentPage() {
  const { t, i18n } = useTranslation();
  const [query] = useSearchParams();
  const navigate = useNavigate();

  const hash = query.get("hash") as string;
  const waitingApptHash = query.get("waitingApptHash") as string;
  const isWaitingAppt = !!waitingApptHash;
  const isScheduleAppt = !!hash;

  const { onShowCancelAppointmentModal } = useAppAction();
  const [appointment, setAppointment] = useState<TAppointment>();
  const [logoUrl, setLogoUrl] = useState<string | null>(null);

  const [getLogo] = useFindManyFilesByIdsLazyQuery();

  const [getScheduleAppointment, { loading: loadingScheduleAppointment }] =
    useFindScheduleAppointmentWithRelationsByHashByPatientLazyQuery({
      onCompleted: (response) => {
        if (response?.findScheduleAppointmentWithRelationsByHashByPatient) {
          const appointmentResponse =
            response?.findScheduleAppointmentWithRelationsByHashByPatient as TScheduleAppt;
          setAppointment(appointmentResponse);
        }
      },
      fetchPolicy: "no-cache",
    });

  const [getWaitingAppointment, { loading: loadingWaitingAppointment }] =
    useFindScheduleWaitlistByHashByPatientLazyQuery({
      onCompleted: (response) => {
        setAppointment(
          response.findScheduleWaitlistByHashByPatient as TAppointment
        );
      },
      fetchPolicy: "no-cache",
    });

  useEffect(() => {
    if (isWaitingAppt) {
      getWaitingAppointment({
        variables: { schema: { hash: waitingApptHash } },
      });
    }
    if (isScheduleAppt) {
      getScheduleAppointment({ variables: { schema: { hash } } });
    }
  }, [hash, waitingApptHash]);

  const clinicLayout = {
    clinic: appointment?.clinic as ClinicEntityResponse,
    logoUrl,
  };

  useEffect(() => {
    if (!appointment?.clinic?.logoFileId) return;
    getLogo({
      variables: {
        schema: {
          ids: [appointment?.clinic?.logoFileId as string],
        },
      },
      onCompleted(response) {
        setLogoUrl(response?.findManyFilesByIds?.files?.[0]?.url);
      },
    });
  }, [appointment?.clinic]);

  const handleNavigeteModifyAppt = () => {
    if (appointment) {
      const searchParams = new URLSearchParams({
        clientId: appointment.client.id,
        clinicId: appointment.clinic?.id,
        serviceId: appointment.service?.id,
        langCode: i18n.resolvedLanguage,
        ...(hash && { hash }),
        ...(waitingApptHash && { waitingApptHash }),
      });

      navigate({
        pathname: `${PATHS.patientBooking}${PATHS.steps}`,
        search: searchParams.toString(),
      });
    }
  };

  const handleCancelAppointment = () => {
    onShowCancelAppointmentModal({
      accept: () => {
        if (isWaitingAppt) {
          const searchParams = new URLSearchParams({
            waitingApptHash,
          });
          navigate({
            pathname: `${PATHS.patientBooking}${PATHS.detailsAppointment}`,
            search: searchParams.toString(),
          });
        }
        if (isScheduleAppt) {
          const searchParams = new URLSearchParams({
            hash,
          });
          navigate({
            pathname: `${PATHS.patientBooking}${PATHS.detailsAppointment}`,
            search: searchParams.toString(),
          });
        }
      },
    });
  };

  const getTime = () => {
    if (isScheduleAppt && appointment) {
      const hours = Math.floor(
        (appointment as TScheduleAppt)?.schedule.minuteFrom / 60
      );
      const minutes = (appointment as TScheduleAppt)?.schedule.minuteFrom % 60;
      const date = new Date();
      date.setHours(hours);
      date.setMinutes(minutes);
      const formattedTime = format(
        date,
        i18n?.resolvedLanguage !== "en" ? "HH:mm" : "h:mm a"
      );
      return formattedTime;
    }
    if (isWaitingAppt && appointment) {
      return `${format(
        getDateFromMinutesStartDay((appointment as TWaitingAppt)?.minuteFrom) ||
          new Date(),
        "hh:mm a"
      )} - ${format(
        getDateFromMinutesStartDay((appointment as TWaitingAppt)?.minuteTo) ||
          new Date(),
        "hh:mm a"
      )}`;
    }
    return null;
  };

  const getDate = () => {
    if (isWaitingAppt && appointment) {
      return `${getDateByLocale(
        (appointment as TWaitingAppt)?.dateFrom as string,
        {
          year: "numeric",
          month: "long",
          day: "numeric",
        },
        i18n?.resolvedLanguage
      )} - ${getDateByLocale(
        (appointment as TWaitingAppt)?.dateTo as string,
        {
          year: "numeric",
          month: "long",
          day: "numeric",
        },
        i18n?.resolvedLanguage
      )}`;
    }

    if (isScheduleAppt && appointment) {
      return (
        (appointment as TScheduleAppt)?.schedule &&
        getFormatDateByLocale(
          (appointment as TScheduleAppt)?.schedule.dateFrom as string,
          i18n?.resolvedLanguage
        )
      );
    }

    return null;
  };

  if (loadingScheduleAppointment || loadingWaitingAppointment)
    return <ScreenLoader />;

  return (
    <Layout currentClinic={clinicLayout} isLangComponent={false}>
      <Wrapper>
        <Icon>
          <CheckIcon width="32" height="32" />
        </Icon>
        <Title>{t("app.booking.patient.confirmed_title")}</Title>
        <DoctorInfoWrapper>
          <DoctorInfoComponent
            doctor={appointment?.doctor as AggregatedDoctorEntityResponse}
          />
          <DateWrapper>
            <TextWrapper>
              {t("app.booking.patient.confirmed_date")}&nbsp;
              <span>{getDate()}</span>
            </TextWrapper>
            <TextWrapper>
              {t("app.booking.patient.confirmed_time")}&nbsp;
              <span>{getTime()}</span>
            </TextWrapper>
          </DateWrapper>
        </DoctorInfoWrapper>
        <Divider
          margin="16px 0 20px 0"
          color={theme.colors.borderInputDefault}
        />
        <InfoListWrapper>
          <InfoWrapper>
            <Info>
              <Label>{t("app.booking.patient.confirmed_clinic_name")}</Label>
              <Text>{appointment?.clinic?.name}</Text>
            </Info>
            <Info>
              <Label>{t("app.booking.patient.confirmed_clinic_number")}</Label>
              <Text>{appointment?.clinic?.phoneNumber}</Text>
            </Info>
          </InfoWrapper>

          <Address>
            <Label>{t("app.booking.patient.confirmed_clinic_address")}</Label>
            <Text>{appointment?.clinic?.address}</Text>
          </Address>
        </InfoListWrapper>
        <Divider
          margin="16px 0 25px 0"
          color={theme.colors.borderInputDefault}
        />

        <Buttons>
          <ButtonStyled
            variant={EButtonVariants.Gray}
            onClick={handleNavigeteModifyAppt}
          >
            <CalendarIcon width="16" height="16" />
            {t("app.booking.patient.confirmed_modify")}
          </ButtonStyled>
          <ButtonStyled
            variant={EButtonVariants.Red}
            onClick={handleCancelAppointment}
          >
            <CloseIcon />
            {t("app.booking.patient.confirmed_cancel")}
          </ButtonStyled>
        </Buttons>
        {isScheduleAppt && appointment && (
          <AddToCalendarComponent appointment={appointment as TScheduleAppt} />
        )}
      </Wrapper>
    </Layout>
  );
}

export default ConfirmedAppointmentPage;
