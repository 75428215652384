import React from "react";

import { SendIcon } from "src/UI/Svg";
import { EButtonVariants } from "src/UI/Button";
import { FieldError, useFormContext } from "react-hook-form";
import { InputAutosizeRHF } from "src/UI/Input";
import { useTranslation } from "react-i18next";
import { Loading } from "src/UI/Loading";
import { ButtonIcon, Wrapper } from "./styled";

interface INewMessageComponentProps {
  loading: boolean;
}

function NewMessageComponent({ loading }: INewMessageComponentProps) {
  const { t } = useTranslation();

  const {
    register,
    watch,
    formState: { errors, isValid },
  } = useFormContext();

  const data = watch();

  return (
    <Wrapper>
      <InputAutosizeRHF
        val={data?.content || ""}
        label={t("messenger.enter") as string}
        inputType="text"
        errorMessage={errors?.content as FieldError}
        register={register("content")}
      />

      <ButtonIcon
        type="submit"
        variant={EButtonVariants.White}
        disabled={!isValid}
      >
        {loading ? <Loading padding="0" /> : <SendIcon />}
      </ButtonIcon>
    </Wrapper>
  );
}

export default NewMessageComponent;
