import {
  FormFieldEntityWithRelationsResponse,
  FormFieldTranslationEntityResponse,
} from "src/graphql/generated";
import { IFieldStep3 } from "src/pages/PatientBookingPages/StepsPage/types";

export const transformBeforeEditPatientBookingStep3 = (
  fields: IFieldStep3[] | FormFieldEntityWithRelationsResponse[],
  languageISOCode?: string,
  fillValueArr?: (string | null | undefined)[]
) => {
  const getTranslationsByLanguage = (
    translations: Array<FormFieldTranslationEntityResponse>
  ) => {
    const translation = translations.find(
      (tr) => tr.languageISOCode === languageISOCode?.toUpperCase() && tr.name
    );
    if (!translation) {
      return translations[0];
    }
    return translation;
  };

  if (fields.length) {
    return {
      fields: [...fields]
        .sort((a, b) => a.order - b.order)
        .map((field, index) => ({
          ...field,
          ...(fillValueArr?.length && { fillValue: fillValueArr[index] }),
          translations: [getTranslationsByLanguage(field.translations)],
        })),
    };
  }
};
