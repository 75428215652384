import { useEffect, useState } from "react";
import Layout from "src/components/PatientBookingComponents/Layout/Layout";
import { EButtonVariants } from "src/UI/Button";
import { CalendarIcon, CloseIcon } from "src/UI/Svg";
import { useTranslation } from "react-i18next";
import {
  AggregatedScheduleAppointmentEntityWithRelationsByPatientResponse,
  AggregatedScheduleWaitlistEntityByPatientResponse,
  AppointmentStatuses,
  WaitlistStatuses,
  useFindManyFilesByIdsLazyQuery,
  useFindScheduleAppointmentWithRelationsByHashByPatientLazyQuery,
  useFindScheduleWaitlistByHashByPatientLazyQuery,
  useScheduleAppointmentConfirmedByPatientLazyQuery,
  useScheduleWaitlistConfirmedByPatientLazyQuery,
} from "src/graphql/generated";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { PATHS } from "src/constants/constants";
import { toast } from "react-toastify";
import { Loading } from "src/UI/Loading";
import CanceledComponent from "src/components/PatientBookingComponents/ConfirmPageComponents/CanceledComponent";
import LateComponent from "src/components/PatientBookingComponents/ConfirmPageComponents/LateComponent";
import ConfirmComponent from "src/components/PatientBookingComponents/ConfirmPageComponents/ConfirmComponent";
import { useAppAction } from "src/store/app/hooks";
import { ScreenLoader } from "src/UI/ScreenLoader";
import { useConfirmScheduleAppointmentByPatient } from "src/graphql/hooks/useMutations";
import { SelectedBookingClinic } from "src/store/booking/types";
import { ButtonStyled, Buttons, LoadingWrapper } from "./styled";

type TAppointment =
  | AggregatedScheduleAppointmentEntityWithRelationsByPatientResponse
  | AggregatedScheduleWaitlistEntityByPatientResponse;

function ConfirmAppointmentPage() {
  const { t, i18n } = useTranslation();
  const { onShowCancelAppointmentModal } = useAppAction();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [query] = useSearchParams();
  const hash = query.get("hash") as string;
  const waitingApptHash = query.get("waitingApptHash") as string;
  const isWaitingAppt = !!waitingApptHash;
  const isScheduleAppt = !!hash;

  const [isCanceled, setIsCanceled] = useState(false);
  const [isLate, setIsLate] = useState(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);
  const [appointment, setAppointment] = useState<TAppointment>();
  const [logoUrl, setLogoUrl] = useState<string | null>(null);

  const isDetailsComponent = pathname.includes(PATHS.detailsAppointment);

  const [getLogo] = useFindManyFilesByIdsLazyQuery();

  const [getScheduleAppointment, { loading: loadingScheduleAppointment }] =
    useFindScheduleAppointmentWithRelationsByHashByPatientLazyQuery({
      onCompleted: (response) => {
        setAppointment(
          response?.findScheduleAppointmentWithRelationsByHashByPatient as TAppointment
        );
        if (
          response.findScheduleAppointmentWithRelationsByHashByPatient
            .status === AppointmentStatuses.Canceled
        ) {
          setIsCanceled(true);
          clearInterval(intervalId as NodeJS.Timer);
        }
      },
      fetchPolicy: "no-cache",
    });

  const [getWaitingAppointment, { loading: loadingWaitingAppointment }] =
    useFindScheduleWaitlistByHashByPatientLazyQuery({
      onCompleted: (response) => {
        setAppointment(
          response.findScheduleWaitlistByHashByPatient as TAppointment
        );
        if (
          response.findScheduleWaitlistByHashByPatient.status ===
          WaitlistStatuses.Closed
        ) {
          setIsCanceled(true);
          clearInterval(intervalId as NodeJS.Timer);
        }
      },
      fetchPolicy: "no-cache",
    });

  const clinicLayout = {
    clinic: appointment?.clinic,
    logoUrl,
  };

  useEffect(() => {
    if (isWaitingAppt) {
      getWaitingAppointment({
        variables: { schema: { hash: waitingApptHash } },
      });
    }
    if (isScheduleAppt) {
      getScheduleAppointment({ variables: { schema: { hash } } });
    }
  }, [hash, waitingApptHash]);

  useEffect(() => {
    if (!appointment?.clinic?.logoFileId) return;
    getLogo({
      variables: {
        schema: {
          ids: [appointment?.clinic?.logoFileId as string],
        },
      },
      onCompleted(response) {
        setLogoUrl(response?.findManyFilesByIds?.files?.[0]?.url);
      },
    });
  }, [appointment?.clinic]);

  const [getScheduleConfirmedLazy] =
    useScheduleAppointmentConfirmedByPatientLazyQuery({
      variables: { schema: { hash } },
      fetchPolicy: "network-only",
      onCompleted: (response) => {
        if (response.scheduleAppointmentConfirmedByPatient.confirmed) {
          const searchParams = new URLSearchParams({
            ...(hash && { hash }),
          });

          navigate({
            pathname: `${PATHS.patientBooking}${PATHS.confirmedAppointment}`,
            search: searchParams.toString(),
          });
        }
      },
    });

  const [getWaitingConfirmedLazy] =
    useScheduleWaitlistConfirmedByPatientLazyQuery({
      variables: { schema: { hash: waitingApptHash } },
      fetchPolicy: "network-only",
      onCompleted: (response) => {
        if (response.scheduleWaitlistConfirmedByPatient.confirmed) {
          toast.success(t("app.successful"));

          const searchParams = new URLSearchParams({
            waitingApptHash,
          });
          navigate({
            pathname: `${PATHS.patientBooking}${PATHS.confirmedAppointment}`,
            search: searchParams.toString(),
          });
        }
      },
    });

  const [
    confirmScheduleApptMutation,
    { loading: confirmScheduleApptMutationLoading },
  ] = useConfirmScheduleAppointmentByPatient();

  useEffect(() => {
    if (isWaitingAppt) getWaitingConfirmedLazy();
    if (isScheduleAppt) getScheduleConfirmedLazy();
    const id = setInterval(() => {
      if (isWaitingAppt) getWaitingConfirmedLazy();
      if (isScheduleAppt) getScheduleConfirmedLazy();
    }, 3000);
    setIntervalId(id);
    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    if (isLate) clearInterval(intervalId as NodeJS.Timer);
  }, [isLate]);

  useEffect(() => {
    if (!hash || isDetailsComponent) return;
    confirmScheduleApptMutation({
      variables: {
        schema: { hash },
      },
      onError: (error) => {
        if (error) {
          setIsLate(true);
        }
      },
    });
  }, [hash]);

  const handleCancelAppointment = () => {
    onShowCancelAppointmentModal({
      accept: () => {
        setIsCanceled(true);
      },
    });
  };

  const handleNavigeteChangeAppt = () => {
    if (appointment) {
      const searchParams = new URLSearchParams({
        clientId: appointment.client.id,
        clinicId: appointment.clinic?.id,
        serviceId: appointment.service?.id,
        langCode: i18n.resolvedLanguage,
        ...(!isCanceled && hash && { hash }),
        ...(!isCanceled && waitingApptHash && { waitingApptHash }),
      });

      navigate({
        pathname: `${PATHS.patientBooking}${PATHS.steps}`,
        search: searchParams.toString(),
      });
    }
  };

  if (
    loadingScheduleAppointment ||
    confirmScheduleApptMutationLoading ||
    loadingWaitingAppointment
  )
    return <ScreenLoader />;

  return (
    <Layout
      currentClinic={clinicLayout as SelectedBookingClinic}
      isLangComponent={false}
    >
      <LoadingWrapper>
        {!isCanceled && !isLate ? <Loading size="36px" padding="0" /> : null}
      </LoadingWrapper>
      {isCanceled ? (
        <CanceledComponent appointment={appointment} />
      ) : isLate ? (
        <LateComponent />
      ) : (
        <ConfirmComponent appointment={appointment} />
      )}
      <Buttons>
        <ButtonStyled
          variant={EButtonVariants.Gray}
          onClick={handleNavigeteChangeAppt}
        >
          <CalendarIcon width="16" height="16" />
          {isCanceled
            ? `${t("app.booking.patient.canceled_choose_date")}`
            : `${t("app.booking.patient.confirm_change")}`}
        </ButtonStyled>
        {!isCanceled && !isLate ? (
          <ButtonStyled
            variant={EButtonVariants.Red}
            onClick={handleCancelAppointment}
          >
            <CloseIcon />
            {`${t("app.booking.patient.confirm_cancel")}`}
          </ButtonStyled>
        ) : null}
      </Buttons>
    </Layout>
  );
}

export default ConfirmAppointmentPage;
