import SearchResultView from "src/views/BookingsViews/SearchResultView/SearchResultView";
import { Wrapper } from "./styled";

function SearchResultPage() {
  return (
    <Wrapper>
      <SearchResultView />
    </Wrapper>
  );
}

export default SearchResultPage;
