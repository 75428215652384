import { Button } from "src/UI/Button";
import { Dropdown } from "src/UI/Dropdown";
import DropdownAutocomplete from "src/UI/Dropdown/DropdownAutocomplete";
import { SliderHorizontalIcon } from "src/UI/Svg";
import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  gap: 8px;
  // ${({ theme }) => theme.mediaQueries.ll} {
  //   gap: 6px;
  // }
`;

export const FilterIconStyled = styled(SliderHorizontalIcon)`
  cursor: pointer;

  path {
    stroke: #a5a5a5 !important;
  }
`;

export const ButtonStyled = styled(Button)<{ isMenuButton?: boolean }>`
  display: flex;
  padding: 8px 10px;
  align-items: center;
  align-self: ${({ isMenuButton }) => (isMenuButton ? "flex-end" : "auto")};
  margin-right: ${({ isMenuButton }) => (isMenuButton ? "0px" : "8px")};
  gap: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 100%;
  width: fit-content;
  border-radius: 5px;

  // ${({ theme }) => theme.mediaQueries.ll} {
  //   padding: 6px 8px;
  //   font-size: 12px;
  // }
`;

export const FilterWrapper = styled.div<{ isEngLang: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  position: absolute;
  top: 0;
  right: ${({ isEngLang }) => (isEngLang ? "134px" : "160px")};
  background: ${({ theme }) => theme.colors.white};
  z-index: 10;
`;

export const DropdownStyled = styled(Dropdown)`
  border-radius: 5px;
  min-width: 255px;

  & > div:first-child {
    padding: 8px;
    border: none;
  }
  & .placeholder {
    color: ${({ theme }) => theme.colors.gray};
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;
  }
`;

export const DropdownAutocompleteStyled = styled(DropdownAutocomplete)`
  border-radius: 5px;
  min-width: 255px;
  & > div {
    padding: 8px;
    border: none;
  }

  & input {
    height: 16px;
    padding: 0;

    &::placeholder {
      color: ${({ theme }) => theme.colors.gray};
      font-size: 16px;
      font-weight: 500;
      line-height: 100%;
    }
  }
`;
