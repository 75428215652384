import styled from "styled-components";
import { Button } from "src/UI/Button";

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  flex-direction: column;
`;

export const Content = styled.div`
  display: grid;
  width: 100%;
  padding: 20px;
  gap: 10px;
  grid-template-columns: 100%;
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 16px;
  }
`;

export const TimetableWrapper = styled.div`
  display: grid;
  grid-template-columns: 100%;
  gap: 10px;
`;

export const BtnWrapper = styled.div`
  position: relative;
`;

export const ButtonStyled = styled(Button)`
  width: auto;
  padding: 8px 10px;
  font-size: 16px;
  line-height: 100%;
  border-radius: 5px;

  svg path {
    stroke: ${({ theme }) => theme.colors.white};
  }
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 12px;
    padding: 7px 8px;
  }
`;

export const Menu = styled.div<{ showMenu: boolean }>`
  visibility: ${({ showMenu }) => (showMenu ? "visible" : "hidden")};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  gap: 2px;
  padding: 4px 0;
  top: 0;
  right: 0;
  box-sizing: border-box;
  right: 0;
  transition: opacity 0.25s ease-out;
  opacity: ${({ showMenu }) => (showMenu ? 1 : 0)};
  z-index: 100;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  border-radius: 5px;
  height: ${({ showMenu }) => (showMenu ? "fit-content" : 0)};
  max-height: 300px;
  width: max-content;
  overflow-y: auto;
`;

export const MenuItem = styled.div`
  width: 100%;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background: ${({ theme }) => theme.colors.greenHover};
  }
`;
