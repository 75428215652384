import { useTranslation } from "react-i18next";
import {
  ServiceEntityWithRelationsResponse,
  ServiceSortFields,
  SortingOrders,
} from "src/graphql/generated";
import { useAppAction } from "src/store/app/hooks";
import { MoreAction } from "src/UI/MoreAction";
import { DeleteIcon, EditIcon, EyeIcon, EyeOffIcon } from "src/UI/Svg";
import {
  useDeleteServiceById,
  useUpdateServiceById,
} from "src/graphql/hooks/useMutations";
import { toast } from "react-toastify";
import { GET_FIND_MANY_SERVICES } from "src/graphql/queries";
import { getCurrencySimbolByCode } from "src/utils";
import {
  Content,
  Header,
  HiddenTag,
  Price,
  RightBlock,
  Tag,
  Tags,
  Title,
  Wrapper,
} from "./styles";

function ServiceComponent({
  service,
  clinicId,
  isShowMore = true,
}: {
  service: ServiceEntityWithRelationsResponse;
  clinicId: string;
  isShowMore?: boolean;
}) {
  const { t } = useTranslation();
  const { onShowEditServiceModal, onConfirmOpen } = useAppAction();

  const {
    id,
    hiddenFromPatients,
    durationInMinutes,
    name,
    price,
    description,
    currencyCode,
  } = service;

  const [updateServiceByIdMutation] = useUpdateServiceById({
    onCompleted: (response) => {
      if (response) {
        toast.success(t("app.successful"));
      }
    },
    refetchQueries: [
      {
        query: GET_FIND_MANY_SERVICES,
        variables: {
          schema: {
            clinicId,
            sortField: ServiceSortFields.CreatedAt,
            sortOrder: SortingOrders.Descending,
          },
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const [deleteServiceById] = useDeleteServiceById({
    onCompleted: (response) => {
      if (response) {
        toast.success(t("app.successful"));
      }
    },
    refetchQueries: [
      {
        query: GET_FIND_MANY_SERVICES,
        variables: {
          schema: {
            clinicId,
            sortField: ServiceSortFields.CreatedAt,
            sortOrder: SortingOrders.Descending,
          },
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleRemove = () => () => {
    onConfirmOpen({
      accept: () => {
        if (typeof id === "string" && clinicId) {
          deleteServiceById({
            variables: {
              schema: {
                id,
              },
            },
          });
        }
      },
    });
  };

  const handleShowModal = () => {
    onShowEditServiceModal({
      data: {
        service,
      },
    });
  };

  const handleToggleVisibleService = () => () => {
    updateServiceByIdMutation({
      variables: {
        schema: { id, hiddenFromPatients: !hiddenFromPatients },
      },
    });
  };

  return (
    <Wrapper>
      <Tags>
        {hiddenFromPatients && (
          <HiddenTag>{t("booking.services.hidden")}</HiddenTag>
        )}
        <Tag>{`${durationInMinutes} ${t("booking.services.actions.min")}`}</Tag>
      </Tags>
      <Header>
        <Title>{name}</Title>
        <RightBlock>
          <Price>
            {price?.toLocaleString().replace(",", " ")}{" "}
            {getCurrencySimbolByCode(currencyCode as string)}
          </Price>
          {isShowMore && (
            <MoreAction
              actions={[
                {
                  title: (
                    <>
                      <EditIcon /> {t("booking.services.actions.edit")}
                    </>
                  ),
                  callback: () => handleShowModal(),
                },
                {
                  title: (
                    <>
                      {hiddenFromPatients ? <EyeIcon /> : <EyeOffIcon />}
                      {hiddenFromPatients
                        ? t("booking.services.actions.visible")
                        : t("booking.services.actions.invisible")}
                    </>
                  ),
                  callback: handleToggleVisibleService(),
                },
                {
                  title: (
                    <>
                      <DeleteIcon /> {t("booking.services.actions.delete")}
                    </>
                  ),
                  callback: handleRemove(),
                },
              ]}
            />
          )}
        </RightBlock>
      </Header>
      <Content isHidden={hiddenFromPatients}>{description}</Content>
    </Wrapper>
  );
}

export default ServiceComponent;
