import { gql } from "@apollo/client";

export const FIND_AND_COUNT_MANY_SMS_BY_CLIENT = gql`
  query FindAndCountManySmsByClient(
    $schema: FindAndCountManySmsByClientSchema!
  ) {
    findAndCountManySmsByClient(schema: $schema) {
      count
      sms {
        clinicId
        clinicToPatient
        content
        createdAt
        from
        id
        patientId
        to
      }
    }
  }
`;
