import { ProfileMainComponent } from "src/components/DashboardComponents/ProfileComponent";
import { SearchComponent } from "src/components/DashboardComponents/SearchComponents";
import {
  LangComponent,
  LocationComponent,
} from "src/components/DashboardComponents/SettingComponents";
import { Navbar, RightBlock, Settings } from "./styled";

function NavbarView() {
  return (
    <Navbar>
      <SearchComponent />
      <RightBlock>
        <Settings>
          <LocationComponent />
          <LangComponent />
          {/* <WalletComponent />
          <NotificationComponent />
          <MessageComponent /> */}
        </Settings>
        <ProfileMainComponent />
      </RightBlock>
    </Navbar>
  );
}

export default NavbarView;
