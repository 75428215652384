import { FC } from "react";
import { CrossIcon } from "src/UI/Svg";
import { SelectedBookingClinic } from "src/store/booking/types";
import {
  ImageStyled,
  ImageWrapStyled,
  MarkerAddresStyled,
  MarkerInfoStyled,
  MarkerPhoneStyled,
  MarkerStyled,
} from "./styled";

interface MarkerProps {
  currentClinic: SelectedBookingClinic;
}

const Marker: FC<MarkerProps> = ({ currentClinic }) => {
  return (
    <MarkerStyled>
      <ImageWrapStyled>
        {currentClinic?.logoUrl ? (
          <ImageStyled src={currentClinic?.logoUrl as string} alt="#" />
        ) : (
          <CrossIcon />
        )}
      </ImageWrapStyled>
      <MarkerInfoStyled>
        <MarkerAddresStyled
          title={`${currentClinic?.clinic?.address}`}
        >{`${currentClinic?.clinic?.address}`}</MarkerAddresStyled>
        <MarkerPhoneStyled>{`${currentClinic?.clinic?.phoneNumber}`}</MarkerPhoneStyled>
      </MarkerInfoStyled>
    </MarkerStyled>
  );
};

export default Marker;
