import { variantsEventDayComponent } from "src/constants/constants";
import { EPriorityEvent } from "src/types";
import styled from "styled-components";

export const Wrapper = styled.div<{
  priority: EPriorityEvent;
  posTop: number;
  posBottom: number;
  isWeek?: boolean;
}>`
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: ${({ posTop }) => posTop}%;
  bottom: ${({ posBottom }) => 100 - posBottom}%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7px;
  padding: ${({ isWeek }) => (isWeek ? "9px 10px" : `20px 10px`)};
  z-index: 2;
  overflow: auto;
  background: ${({ priority }) =>
    variantsEventDayComponent[priority].background};
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 6px;
    padding: ${({ isWeek }) => (isWeek ? "7px 8px" : `16px 8px`)};
  }
`;

export const Icon = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  svg {
    width: 24px;
    height: 24px;
  }
  ${({ theme }) => theme.mediaQueries.ll} {
    width: 30px;
    height: 30px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
`;

export const Desc = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 12px;
  }
`;

export const Date = styled.div<{ priority: EPriorityEvent }>`
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: ${({ priority }) => variantsEventDayComponent[priority].background};
[priority].date};
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 10px;
  }
`;

export const Content = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.darkGrey};
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 12px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  gap: 16px;
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 12px;
  }
`;

export const ButtonIcon = styled.button`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;

    path {
      stroke: ${({ theme }) => theme.colors.grayC4};
    }
  }
  ${({ theme }) => theme.mediaQueries.ll} {
    svg {
      width: 12px;
      height: 12px;
    }
  }
`;
