import { DoctorIcon } from "src/UI/Svg";
import { PatientEntityResponse } from "src/graphql/generated";
import { getAge } from "src/utils";
import {
  Age,
  DotSeparator,
  IconWrapper,
  OptItemSubTitle,
  OptItemTitle,
  PatientOptInfoWrap,
  PatientOptWrap,
} from "./styles";

interface PatientOptComponentProps {
  patient: PatientEntityResponse;
}

const PatientOptComponent = ({ patient }: PatientOptComponentProps) => {
  return (
    <PatientOptWrap>
      <IconWrapper>
        <DoctorIcon />
      </IconWrapper>
      <PatientOptInfoWrap>
        <OptItemTitle>
          {`${patient.firstName} ${patient.lastName}`}
          {!!patient.dateOfBirth && <Age>{getAge(patient.dateOfBirth)}</Age>}
        </OptItemTitle>
        {(!!patient.phoneNumber || !!patient.email) && (
          <OptItemSubTitle>
            {patient.phoneNumber}
            {!!patient.phoneNumber && !!patient.email && <DotSeparator />}
            {patient.email}
          </OptItemSubTitle>
        )}
      </PatientOptInfoWrap>
    </PatientOptWrap>
  );
};

export default PatientOptComponent;
