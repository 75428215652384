import { ReactNode } from "react";
import { StyledTable } from "./CommonTable.styled";

interface ITableProps {
  className?: string;
  children: ReactNode | ReactNode[];
}

function CommonTable({ children, className }: ITableProps) {
  return <StyledTable className={className}>{children}</StyledTable>;
}

export default CommonTable;
