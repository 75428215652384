import { CreateEditFormValues } from "src/types/CreateEditForm";
import * as yup from "yup";

export const createEditFormSchema = (
  values: CreateEditFormValues | undefined
) =>
  yup.object().shape({
    name: yup
      .string()
      .required("auth.reg.name_error")
      .min(1, "auth.reg.name_error")
      .max(100, "validation.field_max_100"),
    serviceId: yup.string().required("validation.field_required"),
    fields: yup
      .array()
      .of(
        yup.object().shape({
          type: yup.string().required("validation.field_required"),
          hiddenFromExistingPatients: yup
            .boolean()
            .required("validation.field_required"),
          order: yup.number().required("validation.field_required"),
          required: yup.boolean().required("validation.field_required"),
          translations: yup
            .array()

            .of(
              yup.object().shape({
                languageISOCode: yup
                  .string()
                  .required("validation.field_required"),
                name: yup
                  .string()
                  .test(
                    "check-name-require",
                    "validation.field_required",
                    (val, context) => {
                      let primaryIndex = 0;
                      let secondaryIndex = 1;
                      if (!values?.fields[0].translations[0].name) {
                        primaryIndex = 1;
                        secondaryIndex = 0;
                      }
                      const isFirstElement =
                        // @ts-ignore
                        context.options.index === primaryIndex;

                      const isSomeFillFieldsName =
                        context?.from?.[2]?.value.fields.some(
                          (field: any) =>
                            field.translations[secondaryIndex].name
                        );

                      const placeholderOptionalLanguageValue =
                        context?.from?.[1]?.value.translations[secondaryIndex]
                          .placeholder;

                      const checkboxOptionalTranslationValuesArray =
                        context?.from?.[1]?.value.translations[secondaryIndex]
                          .value;

                      const isFillSomeCheckboxValue =
                        checkboxOptionalTranslationValuesArray &&
                        checkboxOptionalTranslationValuesArray?.find(
                          (checkboxVal: any) => checkboxVal.text
                        );

                      const isNoValidOptionalTranslationChecbox =
                        !isFirstElement &&
                        !!isFillSomeCheckboxValue &&
                        checkboxOptionalTranslationValuesArray &&
                        Array.isArray(checkboxOptionalTranslationValuesArray);

                      if (isNoValidOptionalTranslationChecbox && !val?.length) {
                        return false;
                      }

                      if (
                        !isFirstElement &&
                        placeholderOptionalLanguageValue &&
                        !val?.length
                      ) {
                        return false;
                      }

                      if (isFirstElement && !val?.length) {
                        return false;
                      }

                      if (isSomeFillFieldsName && !val?.length) {
                        return false;
                      }

                      return true;
                    }
                  ),
                placeholder: yup
                  .string()
                  .test(
                    "check-placeholder-require",
                    "validation.field_required",
                    (val, context) => {
                      let primaryIndex = 0;
                      let secondaryIndex = 1;
                      if (!values?.fields[0].translations[0].name) {
                        primaryIndex = 1;
                        secondaryIndex = 0;
                      }

                      const isFirstElement =
                        // @ts-ignore
                        context.options.index === primaryIndex;

                      const placeholderLanguageValue =
                        context?.from?.[1]?.value.translations[primaryIndex]
                          .placeholder;

                      const placeholderOptionalLanguageValue =
                        context?.from?.[1]?.value.translations[secondaryIndex]
                          .placeholder;

                      const nameOptionalLanguageValue =
                        context?.from?.[1]?.value.translations[secondaryIndex]
                          .name;

                      if (placeholderOptionalLanguageValue && !val?.length) {
                        return false;
                      }

                      if (
                        !isFirstElement &&
                        placeholderLanguageValue &&
                        nameOptionalLanguageValue &&
                        !placeholderOptionalLanguageValue
                      ) {
                        return false;
                      }

                      return true;
                    }
                  ),
                value: yup
                  .mixed()
                  .test(
                    "check-checkbox-require",
                    "validation.field_required",
                    (value, context) => {
                      let primaryIndex = 0;
                      let secondaryIndex = 1;
                      if (!values?.fields[0].translations[0].name) {
                        primaryIndex = 1;
                        secondaryIndex = 0;
                      }

                      const isFirstElement =
                        // @ts-ignore
                        context.options.index === primaryIndex;

                      const nameOptionalLanguageValue =
                        context?.from?.[1]?.value.translations[secondaryIndex]
                          .name;

                      const checkboxOptionalTranslationValuesArray =
                        context?.from?.[1]?.value.translations[secondaryIndex]
                          .value;

                      const isFillSomeCheckboxValue =
                        checkboxOptionalTranslationValuesArray &&
                        checkboxOptionalTranslationValuesArray.find(
                          (checkboxVal: any) => checkboxVal.text
                        );

                      if (
                        !isFirstElement &&
                        value &&
                        Array.isArray(value) &&
                        isFillSomeCheckboxValue
                      ) {
                        value.forEach((val) => {
                          if (!val.text) {
                            throw new yup.ValidationError(
                              "validation.field_required",
                              null,
                              `checkBoxTextOptionalTranslation`
                            );
                          }
                        });
                      }

                      if (
                        !isFirstElement &&
                        value &&
                        Array.isArray(value) &&
                        nameOptionalLanguageValue
                      ) {
                        value.forEach((val) => {
                          if (!val.text) {
                            throw new yup.ValidationError(
                              "validation.field_required",
                              null,
                              `checkBoxTextOptionalTranslationErrorMessage`
                            );
                          }
                        });
                      }

                      if (isFirstElement && value && Array.isArray(value)) {
                        value.forEach((val) => {
                          if (!val.text) {
                            throw new yup.ValidationError(
                              "validation.field_required",
                              null,
                              `checkBoxTextErrorMessage`
                            );
                          }
                        });
                      }

                      return true;
                    }
                  ),
              })
            ),
        })
      )
      .min(1, "forms.modals.fields.validation.form_required"),
  });
