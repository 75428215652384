import styled from "styled-components";

export const SliderItemStyled = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;
`;

export const SliderItemDayStyled = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.gray};
`;

export const SliderItemDateStyled = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
`;

export const ButtonsWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  gap: 5px;
`;

export const EmptyTextStyled = styled.div`
  width: 100%;
  padding: 15px;
  color: ${({ theme }) => theme.colors.gray};
`;
