import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-gap: 0;
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
`;

export const DayWrapperStyled = styled.div`
  width: 100%;
  min-height: 180px;
  box-sizing: border-box;
  border-right: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault};

  &:nth-child(7n) {
    border-right: none;
  }

  &:nth-last-child(-n + 7) {
    border-bottom: none;
  }
`;

export const MonthDateHeaderWrapper = styled.div`
  padding-left: 16px;
  padding-top: 16px;
  padding-bottom: 5px;
  font-size: 18px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.darkGrey};
  display: flex;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;
  gap: 8px;
`;

export const Item = styled.div`
  width: 100%;
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.darkGrey};

  span {
    width: 8px;
    min-width: 8px;
    height: 8px;
    display: flex;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.darkGrey};
  }
`;

export const User = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.black};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MoreBlock = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.gray};
  margin-top: 5px;
  cursor: pointer;
`;
