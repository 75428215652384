import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  HeaderChatComponent,
  NewMessageComponent,
} from "src/components/MessengerComponents";
import {
  FindAndCountManySmsByClientQuery,
  SmsSortFields,
  SortingOrders,
  useFindAndCountManySmsByClientQuery,
  useSendRawSmsByClientMutation,
} from "src/graphql/generated";
import { useClinicsState } from "src/store/clinics/hooks";
import { useMessengerState } from "src/store/messenger/hooks";
import { toast } from "react-toastify";
import { Loading } from "src/UI/Loading";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { sendSmsValidationSchema } from "src/validation/schemas/sendSmsSchema";
import { transformBeforeSendSms } from "src/connectors/Messenger/transformBeforeSendSms";
import { FIND_AND_COUNT_MANY_SMS_BY_CLIENT } from "src/graphql/queries/findAndCountManySmsByClient.gql";
import { FIND_AND_COUNT_MANY_LAST_SMS_BY_CLIENT } from "src/graphql/queries/findAndCountManyLastSmsByClient.gql";
import { defaultSmsLimit } from "src/constants/constants";
import { Form, Wrapper } from "./styled";
import { MessageListView } from "../index";

interface IFormValues {
  clinicId: string;
  content: string;
  patientId: string;
}

function MessengerAreaView() {
  const { currentClinicId: clinicId } = useClinicsState();
  const { selectedChat } = useMessengerState();
  const { t } = useTranslation();

  const methods = useForm<IFormValues>({
    mode: "onChange",
    resolver: yupResolver(sendSmsValidationSchema),
    defaultValues: {
      clinicId: "",
      content: "",
      patientId: "",
    },
  });

  const { handleSubmit, reset } = methods;

  const { data, loading, fetchMore } = useFindAndCountManySmsByClientQuery({
    variables: {
      schema: {
        patientId: selectedChat?.id as string,
        clinicId,
        limit: defaultSmsLimit,
        offset: 0,
        sortField: SmsSortFields.CreatedAt,
        sortOrder: SortingOrders.Descending,
      },
    },
    skip: !selectedChat?.id,
    onError(error) {
      if (error) toast.error(error.message);
    },
  });

  const [sendRawSmsByClient, { loading: sendSmsByClientLoading }] =
    useSendRawSmsByClientMutation();

  useEffect(() => {
    if (selectedChat && clinicId) {
      reset(transformBeforeSendSms({ clinicId, patientId: selectedChat.id }));
    }
  }, [selectedChat, clinicId]);

  const onSubmit = (formData: IFormValues) => {
    sendRawSmsByClient({
      variables: { schema: { ...formData } },
      onCompleted() {
        toast.success(t("app.successful"));
        reset();
      },
      onError(error) {
        if (error) toast.error(error.message);
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        FIND_AND_COUNT_MANY_SMS_BY_CLIENT,
        FIND_AND_COUNT_MANY_LAST_SMS_BY_CLIENT,
      ],
    });
  };

  if (!selectedChat) return null;

  return (
    <Wrapper isSelectedChat={selectedChat !== null}>
      {!loading ? (
        <>
          <HeaderChatComponent patient={selectedChat} />
          <MessageListView
            messages={data as FindAndCountManySmsByClientQuery}
            fetchMore={fetchMore}
          />
          <FormProvider {...methods}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <NewMessageComponent loading={sendSmsByClientLoading} />
            </Form>
          </FormProvider>
        </>
      ) : (
        <Loading padding="0" />
      )}
    </Wrapper>
  );
}

export default MessengerAreaView;
