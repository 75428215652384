import { toast } from "react-toastify";
import {
  UpdateMailTemplateByClientSchema,
  UpdateSmsTemplateByClientSchema,
  UpdateTemplateByClientSchema,
  useUpdateMailTemplateByClientMutation,
  useUpdateSmsTemplateByClientMutation,
  useUpdateTemplateByClientMutation,
} from "src/graphql/generated";

export const useTemplateUpdate = () => {
  const [updateMailTemplate] = useUpdateMailTemplateByClientMutation({
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
  const [updateSmsTemplate] = useUpdateSmsTemplateByClientMutation({
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });

  const [updateTemplate] = useUpdateTemplateByClientMutation({
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });

  const updateMailTemplates = async (
    templates: UpdateMailTemplateByClientSchema[]
  ) => {
    let isUpdated = false;

    if (templates[0] && templates[0].id) {
      const result = await updateMailTemplate({
        variables: {
          schema: templates[0],
        },
      });

      if (result.data?.updateMailTemplateByClient.updated) {
        isUpdated = true;
      }
    }

    if (templates[1] && templates[1].id && isUpdated) {
      const result = await updateMailTemplate({
        variables: {
          schema: templates[1],
        },
      });

      if (result.data?.updateMailTemplateByClient.updated) {
        isUpdated = true;
      }
    }

    return isUpdated;
  };

  const updateSmsTemplates = async (
    templates: UpdateSmsTemplateByClientSchema[]
  ) => {
    let isUpdated = false;

    if (templates[0] && templates[0].id) {
      const result = await updateSmsTemplate({
        variables: {
          schema: templates[0],
        },
      });

      if (result.data?.updateSmsTemplateByClient.updated) {
        isUpdated = true;
      }
    }

    if (templates[1] && templates[1].id && isUpdated) {
      const result = await updateSmsTemplate({
        variables: {
          schema: templates[1],
        },
      });

      if (result.data?.updateSmsTemplateByClient.updated) {
        isUpdated = true;
      }
    }

    return isUpdated;
  };

  const updateDataTemplate = async (
    updateSchema: UpdateTemplateByClientSchema
  ) => {
    let isUpdated = false;

    const result = await updateTemplate({
      variables: {
        schema: updateSchema,
      },
    });

    if (result.data?.updateTemplateByClient.updated) {
      isUpdated = true;
    }

    return isUpdated;
  };

  return { updateMailTemplates, updateSmsTemplates, updateDataTemplate };
};
