import { FC } from "react";
import { useBookingAction, useBookingState } from "src/store/booking/hooks";
import { ServiceEntityWithRelationsResponse } from "src/graphql/generated";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "src/hooks/useMediaQuery";
import { breakpointMap } from "src/theme/base";
import { addDays, formatISO } from "date-fns";
import { ComponentProps } from "./types";
import {
  ClinicInfoAddressStyled,
  ClinicInfoPhoneStyled,
  ClinicInfoWrapStyled,
  DropdownStyled,
  ServicesInfoWrapStyled,
} from "./styles";

const ServicesInfoComponent: FC<ComponentProps> = ({
  services,
  setDateFilter,
}) => {
  const { t } = useTranslation();
  const { onSetService, onSetFormFields } = useBookingAction();
  const { currentClinic, currentService } = useBookingState();
  const isTablet = useMediaQuery(breakpointMap.md);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hash = queryParams.get("hash");

  const onChange = (service: ServiceEntityWithRelationsResponse) => {
    setDateFilter({
      dateFrom: formatISO(new Date()),
      dateTo: formatISO(addDays(new Date(), 30)),
    });
    onSetFormFields(null);
    onSetService({ service });
  };
  return (
    <ServicesInfoWrapStyled>
      {!isTablet && (
        <ClinicInfoWrapStyled>
          <ClinicInfoAddressStyled>
            {currentClinic?.clinic?.address}
          </ClinicInfoAddressStyled>
          <ClinicInfoPhoneStyled>
            {currentClinic?.clinic?.phoneNumber}
          </ClinicInfoPhoneStyled>
        </ClinicInfoWrapStyled>
      )}

      <DropdownStyled
        isShowOnlyPlaceholder={false}
        value={currentService?.service as object}
        placeholder={t("analytic.filters.service")}
        onSelect={onChange}
        options={services}
        label=""
        disabled={!!hash}
      />
    </ServicesInfoWrapStyled>
  );
};

export default ServicesInfoComponent;
