import styled from "styled-components";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
  width: 100%;
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 20px;

  thead {
  }

  th {
    padding: 20px 5px 5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.grayC4};
    text-align: left;

    &:first-child {
      padding-left: 20px;
    }

    &:last-child {
      padding-right: 20px;
    }
  }

  tbody tr {
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault};

    &:last-child {
      border-bottom: none;
    }
  }

  td {
    padding: 30px 5px;
    vertical-align: middle;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black};
    text-align: left;

    &:first-child {
      width: 96px;
      padding-left: 20px;
      color: ${({ theme }) => theme.colors.gray};
    }

    &:last-child {
      padding-right: 20px;
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  gap: 24px;
`;

export const ButtonIcon = styled.button`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;

    path {
      stroke: ${({ theme }) => theme.colors.gray};
    }
  }
`;

export const LinkStyled = styled(Link)`
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.green};
`;

export const LocationList = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
`;

export const Location = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 7px;
  svg {
    width: 16px;
    height: 16px;
    path {
      stroke: ${({ theme }) => theme.colors.gray};
    }
  }
`;

export const EmptyTextStyled = styled.div`
  width: 100%;
  padding: 20px;
  color: ${({ theme }) => theme.colors.gray};
`;

export const ImageStyled = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  border-radius: 50%;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  align-items: center;
  padding: 16px;
  gap: 10px;

  width: 56px;
  height: 56px;
  background: ${({ theme }) => theme.colors.lightBiege};
  border-radius: 56px;

  svg {
    width: 32px;
    height: 32px;
  }
`;
export const StatusWrapper = styled.div<{ isActivated: boolean }>`
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  padding: 6px;
  background-color: ${({ theme, isActivated }) =>
    isActivated ? theme.colors.green : theme.colors.red};
  border-radius: 5px;
`;
