import { Heading } from "src/UI/Heading";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 5px;
  padding: 20px 20px 0;
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 16px 16px 0;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const HeadingStyled = styled(Heading)`
  font-size: 24px;
  line-height: 100%;
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 20px;
  }
`;
