import {
  AggregatedScheduleAppointmentEntityWithRelationsByPatientResponse,
  AggregatedScheduleWaitlistEntityByPatientResponse,
} from "src/graphql/generated";
import { CheckIcon } from "src/UI/Svg";
import { useTranslation } from "react-i18next";
import {
  getDateByLocale,
  getDateFromMinutesStartDay,
  getDateFromScheduleAppointment,
} from "src/utils";
import { useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import { Icon, Info, InfoWrapper, Title, Wrapper } from "./styled";

type TScheduleAppt =
  AggregatedScheduleAppointmentEntityWithRelationsByPatientResponse;

type TWaitingAppt = AggregatedScheduleWaitlistEntityByPatientResponse;

interface ICanceledComponentProps {
  appointment?: TScheduleAppt | TWaitingAppt;
}

function CanceledComponent({ appointment }: ICanceledComponentProps) {
  const { t, i18n } = useTranslation();

  const [query] = useSearchParams();

  const isWaitingAppt = query.get("waitingApptHash") as string;
  const isScheduleAppt = query.get("hash") as string;

  const getDateAndTime = () => {
    if (isWaitingAppt) {
      return `${getDateByLocale(
        (appointment as TWaitingAppt)?.dateFrom as string,
        {
          year: "numeric",
          month: "long",
          day: "numeric",
        },
        i18n?.resolvedLanguage
      )} - ${getDateByLocale(
        (appointment as TWaitingAppt)?.dateTo as string,
        {
          year: "numeric",
          month: "long",
          day: "numeric",
        },
        i18n?.resolvedLanguage
      )}, ${format(
        getDateFromMinutesStartDay((appointment as TWaitingAppt)?.minuteFrom) ||
          new Date(),
        "hh:mm a"
      )} - ${format(
        getDateFromMinutesStartDay((appointment as TWaitingAppt)?.minuteTo) ||
          new Date(),
        "hh:mm a"
      )}`;
    }
    if (isScheduleAppt) {
      return (
        (appointment as TScheduleAppt)?.schedule &&
        getDateFromScheduleAppointment(
          (appointment as TScheduleAppt)?.schedule?.dateFrom,
          (appointment as TScheduleAppt)?.schedule?.minuteFrom as number,
          i18n
        )
      );
    }

    return null;
  };
  return (
    <Wrapper>
      <Icon>
        <CheckIcon width="32" height="32" />
      </Icon>
      <Title>{t("app.booking.patient.canceled_title")}</Title>
      <InfoWrapper>
        <Info>{`${t("app.booking.patient.canceled_name")}
          ${appointment?.doctor.firstName} ${appointment?.doctor.lastName} ${t(
          "app.booking.patient.confirm_at"
        )}
          ${appointment?.clinic.name} ${t("app.booking.patient.confirm_for")}
          ${getDateAndTime()}`}</Info>
      </InfoWrapper>
    </Wrapper>
  );
}
export default CanceledComponent;
