import React from "react";
import AvatarComponent from "src/components/AvatarComponent/AvatarComponent";
import { Block, InfoBlock, Name, Phone, Wrapper } from "./styled";
import { IChatPatientComponentProps } from "../types";

function ChatUserComponent({ patient }: IChatPatientComponentProps) {
  return (
    <Wrapper>
      <Block>
        <AvatarComponent
          firstName={patient.firstName}
          lastName={patient.lastName}
          size={48}
        />
        <InfoBlock>
          <Name>
            {patient.firstName} {patient.lastName}
          </Name>
          <Phone>{patient.phoneNumber}</Phone>
        </InfoBlock>
      </Block>
    </Wrapper>
  );
}

export default ChatUserComponent;
