import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import {
  AggregatedDoctorEntityResponse,
  ScheduleAppointmentsCountByStatusesResponse,
} from "src/graphql/generated";
import { useAuthContext } from "src/context/AuthContext";
import { useAppAction } from "src/store/app/hooks";
import { IAppointmentsFilters, TScheduleAppointmentByRole } from "src/types";
import { ScheduleAppointmentsFilterComponent } from "src/components/BookingComponents";
import ScheduleAppointmentsTableComponent from "src/components/BookingComponents/ScheduleAppointmentsTableComponent/ScheduleAppointmentsTableComponent";
import { PaginationComponent } from "src/components/ReviewComponents";
import { useLogoFiles } from "src/hooks/appointments/useLogoFiles";
import { FilterWrapper, Header, HeadingStyled, TabsStyled } from "./styled";

interface ScheduleAppointmentsViewProps {
  appointments: TScheduleAppointmentByRole[];
  appointmentsCount: number;
  doctors: AggregatedDoctorEntityResponse[];
  countStatusesByRole: ScheduleAppointmentsCountByStatusesResponse & {
    [key: string]: number;
  };
  filters: IAppointmentsFilters;
  currentPage: number;
  pages: number[];
  setFilters: Dispatch<SetStateAction<IAppointmentsFilters>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  handleNavigateToVisit: () => void;
  loading: boolean;
}

type IScheduleAppointmentStatuseswithT = {}[];

function ScheduleAppointmentsView({
  appointments,
  appointmentsCount,
  doctors,
  countStatusesByRole,
  filters,
  currentPage,
  pages,
  setFilters,
  setCurrentPage,
  handleNavigateToVisit,
  loading,
}: ScheduleAppointmentsViewProps) {
  const { t } = useTranslation();
  const { isDoctor } = useAuthContext();
  const { onShowScheduleAppointmentModal } = useAppAction();

  const { logoFiles } = useLogoFiles({ appointments });

  const ScheduleAppointmentStatuseswithT: IScheduleAppointmentStatuseswithT = [
    { All: t("appointments.tabs.all") },
    { New: t("appointments.tabs.new") },
    { Missed: t("appointments.tabs.missed") },
    { Canceled: t("appointments.tabs.canceled") },
    { Successful: t("appointments.tabs.successful") },
    { ConfirmedByPatient: t("appointments.tabs.confirmed_by_patient") },
    { ConfirmedByApplication: t("appointments.tabs.confirmed_by_application") },
  ];

  const appointmentsStatuses = ScheduleAppointmentStatuseswithT.map(
    (status) => {
      const arrStatus = Object.entries(status)[0];
      if (arrStatus[0] === "All") {
        return {
          key: "All",
          value: arrStatus[1] as string,
        };
      }
      return {
        key: arrStatus[0] as string,
        value: arrStatus[1] as string,
      };
    }
  );

  const handleShowModal = (id: string) => () => {
    onShowScheduleAppointmentModal({
      data: {
        appointment: appointments?.find(
          (item) => item?.id === id
        ) as TScheduleAppointmentByRole,
      },
    });
  };

  return (
    <>
      <Header>
        <HeadingStyled as="h3">{t("booking.patients.title")}</HeadingStyled>
        <FilterWrapper>
          <ScheduleAppointmentsFilterComponent
            logoFiles={logoFiles?.findManyFilesByIds?.files}
            doctors={doctors}
            filters={filters}
            setFilters={setFilters}
            isDoctor={isDoctor}
            count={appointmentsCount as number}
          />
        </FilterWrapper>
      </Header>
      <TabsStyled
        headers={appointmentsStatuses}
        contents={
          <ScheduleAppointmentsTableComponent
            scheduleAppointmentsList={
              appointments as TScheduleAppointmentByRole[]
            }
            handleShowModal={handleShowModal}
            loading={loading}
          />
        }
        filters={filters}
        onShowModal={handleNavigateToVisit}
        setFilters={setFilters}
        titleButton="calendar.event.title"
        countStatusesByRole={
          countStatusesByRole as ScheduleAppointmentsCountByStatusesResponse & {
            [key: string]: number;
          }
        }
      />

      <PaginationComponent
        pages={pages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}

export default ScheduleAppointmentsView;
