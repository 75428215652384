import styled from "styled-components";
import { Dropdown } from "src/UI/Dropdown";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 20px;
  gap: 30px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  border-radius: 5px;
  width: 100%;
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 16px;
    gap: 24px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 7px;
  height: 100%;
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 6px;
  }
`;

export const DropdownStyled = styled(Dropdown)`
  border-radius: 5px;
  width: 100%;

  & > div:first-child {
    padding: 8px;
  }
`;

export const FormColumn = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  align-content: flex-start;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  flex: 1;
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 10px;
  }
`;

export const Label = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.gray};
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 12px;
  }
`;

export const DateInputStyled = styled.div`
  padding: 8px 10px;
  gap: 4px;
  min-height: 32px;
  background: ${({ theme }) => theme.colors.lightBiege};
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 12px;
    padding: 6px 8px;
  }
`;

export const ButtonIcon = styled.button`
  display: flex;
  align-items: flex-start;
  align-content: center;
  justify-content: center;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
`;
