import { useMemo } from "react";
import {
  addYears,
  endOfDay,
  endOfMonth,
  endOfYear,
  format,
  startOfMonth,
  subYears,
} from "date-fns";
import { CalendarMonthComponent } from "src/components/CalendarComponents";
import { ViewProps } from "react-big-calendar";
import { Wrapper } from "./styles";

const YearView = ({ date, events, onView, onNavigate }: ViewProps) => {
  const months = useMemo(() => {
    const now = new Date(date);
    const currentMonthIndex = now.getMonth();
    const currentYear = now.getFullYear();

    const monthIndices = Array.from(
      { length: 12 },
      (_, i) => (currentMonthIndex + i) % 12
    );

    return monthIndices.map((monthIndex) => {
      const year =
        monthIndex >= currentMonthIndex ? currentYear : currentYear + 1;
      const monthDate = new Date(year, monthIndex, 1);

      const filteredEvents = events?.filter(
        (event) =>
          (event?.start as Date) >= startOfMonth(monthDate) &&
          (event?.end as Date) <= endOfMonth(monthDate)
      );

      return { monthDate, events: filteredEvents || [] };
    });
  }, [date, events]);

  const handleShowMore = (dayDate: Date) => {
    onNavigate("DATE", dayDate);
    if (onView) onView("day");
  };

  return (
    <Wrapper>
      {months.map((month) => {
        return (
          <CalendarMonthComponent
            monthDate={month.monthDate}
            events={month.events}
            onShowMore={handleShowMore}
            key={month.monthDate.getTime()}
          />
        );
      })}
    </Wrapper>
  );
};

YearView.title = (date: Date) => {
  const startYear = format(date, "yyyy");

  return <div>{startYear}</div>;
};

YearView.navigate = (date: Date, action: string) => {
  switch (action) {
    case "PREV":
      return subYears(date, 1);
    case "NEXT":
      return addYears(date, 1);
    default:
      return date;
  }
};

YearView.range = (targetStartDate: Date) => {
  const now = new Date();
  const start =
    targetStartDate.getFullYear() === now.getFullYear()
      ? now
      : startOfMonth(new Date(targetStartDate.getFullYear(), 0));
  const end = endOfDay(endOfYear(new Date(targetStartDate.getFullYear(), 0)));

  return [start, end];
};

export default YearView;
