import React, { ReactNode } from "react";
import { useFormContext } from "react-hook-form";
import { CheckBoxRHFProps } from "../types";
import { Label, StyledInput, Switch } from "./styled";

interface ToggleRHFProps extends CheckBoxRHFProps {
  labelTextBefore?: ReactNode;
  className?: string;
}

function ToggleRHF({
  name,
  disabled,
  className,
  labelTextBefore,
}: ToggleRHFProps) {
  const { register, watch } = useFormContext();
  const isChecked = watch(name);

  return (
    <Label className={className}>
      {labelTextBefore}

      <StyledInput
        checked={isChecked}
        type="checkbox"
        {...register(name)}
        disabled={disabled}
      />
      <Switch />
    </Label>
  );
}

export default ToggleRHF;
