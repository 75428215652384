import styled from "styled-components";

export const Icon = styled.div<{ size: number }>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
`;

export const LettersBlock = styled.div<{ fontSize: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ fontSize }) => `${fontSize}px`};
  font-weight: 500;
  gap: 10px;
  color: ${({ theme }) => theme.colors.green};
`;

export const AvatarBlock = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 10px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.lightBiege};
`;
