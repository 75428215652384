import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CombinedUserType } from "src/context/reducers/authReducer";
import { PATHS } from "src/constants/constants";

import { IUserStorageItem } from "src/store/app/types";
import { PlusIcon } from "src/UI/Svg";
import { useAuthContext } from "src/context/AuthContext";
import { ListStyled, Footer, ActionItem, Line, ListWrapStyled } from "./styled";
import ProfileItemComponent from "../ProfileItemComponent";

interface ProfilesListComponentProps {
  currentUserData: CombinedUserType | null;
  isShow: boolean;
  onClose: () => void;
}

function ProfilesListComponent({
  currentUserData,
  isShow,
  onClose,
}: ProfilesListComponentProps) {
  const { localUsers } = useAuthContext();
  const [profiles, setProfiles] = useState<IUserStorageItem[]>([]);

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setProfiles(localUsers);
  }, [currentUserData, localUsers]);

  const addNewAccount = () => {
    navigate(`${PATHS.vr}${PATHS.addAccount}`);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <ListStyled isShow={isShow} onClick={handleClose}>
      <ListWrapStyled>
        {profiles.reduce((acc: JSX.Element[], profile: IUserStorageItem) => {
          if (profile.id !== currentUserData?.id) {
            acc.push(
              <ProfileItemComponent key={`user-${profile.id}`} user={profile} />
            );
          }
          return acc;
        }, [])}
      </ListWrapStyled>
      <Footer>
        <ActionItem onClick={addNewAccount}>
          <PlusIcon />
          {t("profile.add")}
        </ActionItem>
        <Line />
        <ActionItem onClick={handleClose}>{t("profile.cancel")}</ActionItem>
      </Footer>
    </ListStyled>
  );
}

export default ProfilesListComponent;
