import styled from "styled-components";

export const DropdownStyled = styled.div`
  position: relative;
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  min-width: 180px;
`;

export const LabelStyled = styled.label<{ isFocused: boolean }>`
  color: ${({ isFocused, theme }) =>
    theme.colors[isFocused ? "grayC4" : "gray"]};
  position: absolute;
  top: ${({ isFocused }) => (isFocused ? "0" : "50%")};
  transform: translateY(-50%);
  left: 0;
  font-weight: 500;
  font-size: ${({ isFocused }) => (isFocused ? "12px" : "16px")};
  line-height: ${({ isFocused }) => (isFocused ? "100%" : "140%")};
`;

export const RowStyled = styled.div<{
  showMenu: boolean;
}>`
  position: relative;
  font-style: normal;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  gap: 4px;
  background: none;
  border-radius: 0;
  color: ${({ theme }) => theme.colors.black};
  white-space: nowrap;

  width: 100%;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.black};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  padding: 15px 0;
  padding-right: 25px;

  &:focus {
    border-color: ${({ theme }) => theme.colors.green};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray};
  }
`;

export const Menu = styled.div<{ showMenu: boolean }>`
  visibility: ${({ showMenu }) => (showMenu ? "visible" : "hidden")};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  gap: 2px;
  padding: 4px 0;
  top: 100%;
  left: 0;
  box-sizing: border-box;
  right: 0;
  transition: all 0.25s ease-out;
  opacity: ${({ showMenu }) => (showMenu ? 1 : 0)};
  z-index: 100;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  border-top: none;
  border-radius: 0 0 5px 5px;
  height: ${({ showMenu }) => (showMenu ? "fit-content" : 0)};
  max-height: 300px;
  width: 100%;
  overflow-y: auto;
`;

export const SelectedElement = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  border-radius: 5px;
  font-weight: 700;
  line-height: 100%;
  padding: 2px 5px;
  background: ${({ theme }) => theme.colors.lightBiege};

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.darkGrey};
  }
`;

export const SelectedElements = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 13px;
  line-height: 100%;
  gap: 5px;
`;

export const InputSearch = styled.input`
  flex-grow: 1;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.black};
  border: none;
  background: transparent;
  outline: none;
`;

export const EmptyTextStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  color: ${({ theme }) => theme.colors.gray};
  padding: 10px;
`;

export const ClearButton = styled.div`
  margin-left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconButton = styled.div`
  line-height: 100%;
`;

export const IconStyled = styled.div<{ showMenu: boolean }>`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.lightBiege};
  border-radius: 50%;
  ${({ showMenu }) =>
    showMenu
      ? `svg {
    transform: rotateZ(-180deg);
  };`
      : ""}
`;
