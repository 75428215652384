import {
  SortingOrders,
  TemplateSortFields,
  useFindAndCountManyTemplatesByClientGetCountQuery,
} from "src/graphql/generated";

import { useClinicsState } from "src/store/clinics/hooks";

export function useTemplatesCount() {
  const { currentClinicId: clinicId } = useClinicsState();

  const { data: dataAllCount } =
    useFindAndCountManyTemplatesByClientGetCountQuery({
      variables: {
        schema: {
          limit: 1,
          clinicId,
          offset: 0,
          onlyCurrent: false,
          sortField: TemplateSortFields.CreatedAt,
          sortOrder: SortingOrders.Descending,
        },
      },
      fetchPolicy: "no-cache",
      skip: !clinicId,
    });

  const { data: dataActiveCount } =
    useFindAndCountManyTemplatesByClientGetCountQuery({
      variables: {
        schema: {
          limit: 1,
          clinicId,
          offset: 0,
          onlyCurrent: true,
          sortField: TemplateSortFields.CreatedAt,
          sortOrder: SortingOrders.Descending,
        },
      },
      fetchPolicy: "no-cache",
      skip: !clinicId,
    });

  return {
    all: dataAllCount?.findAndCountManyTemplatesByClient?.count || 0,
    active: dataActiveCount?.findAndCountManyTemplatesByClient?.count || 0,
  };
}
