/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo } from "react";

import { useTranslation } from "react-i18next";
import {
  CalendarIcon,
  CheckIcon,
  ClockIcon,
  CloseIcon,
  MessageIcon,
  NumberIcon,
  SmartphoneIcon,
  TextIcon,
  TextInputIcon,
} from "src/UI/Svg";

import { FormFieldTypes } from "src/graphql/generated";
import { useFormContext } from "react-hook-form";
import { ESupportedLangs } from "src/types/enum";
import { CloseButton, FieldItem, List, Title, Wrapper } from "./styled";

interface FieldsListComponentProps {
  onClose: () => void;
  languageISOCode: string;
}

function FieldsListComponent({
  onClose,
  languageISOCode,
}: FieldsListComponentProps) {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext();
  const fields = watch("fields");

  const filedsList = useMemo(
    () => [
      {
        title: t("forms.modals.fields.text"),
        icon: <TextIcon />,
        componentType: FormFieldTypes.Text,
      },

      {
        title: t("forms.modals.fields.text_area"),
        icon: <TextInputIcon />,
        componentType: FormFieldTypes.TextArea,
      },
      {
        title: t("forms.modals.fields.email"),
        icon: <MessageIcon />,
        componentType: FormFieldTypes.Email,
      },
      {
        title: t("forms.modals.fields.phone"),
        icon: <SmartphoneIcon />,
        componentType: FormFieldTypes.Phone,
      },
      {
        title: t("forms.modals.fields.number"),
        icon: <NumberIcon />,
        componentType: FormFieldTypes.Number,
      },
      {
        title: t("forms.modals.fields.date"),
        icon: <CalendarIcon />,
        componentType: FormFieldTypes.Date,
      },
      {
        title: t("forms.modals.fields.time"),
        icon: <ClockIcon />,
        componentType: FormFieldTypes.Time,
      },
      {
        title: t("forms.modals.fields.checkbox"),
        icon: <CheckIcon />,
        componentType: FormFieldTypes.Checkbox,
      },
    ],
    [t]
  );

  const getTranslationsFieldInit = (type: FormFieldTypes) => {
    if (type === FormFieldTypes.Checkbox) {
      return [
        {
          languageISOCode,
          name: "",
          placeholder: "",
          value: [{ type: "checkbox", order: 0, text: "" }],
        },
        {
          languageISOCode:
            languageISOCode === ESupportedLangs.en.toUpperCase()
              ? ESupportedLangs.pl.toUpperCase()
              : ESupportedLangs.en.toUpperCase(),
          name: "",
          placeholder: "",
          value: [{ type: "checkbox", order: 0, text: "" }],
        },
      ];
    }
    if (type === FormFieldTypes.Date) {
      return [
        {
          languageISOCode,
          name: "",
          placeholder: "",
        },
        {
          languageISOCode:
            languageISOCode === ESupportedLangs.en.toUpperCase()
              ? ESupportedLangs.pl.toUpperCase()
              : ESupportedLangs.en.toUpperCase(),
          name: "",
          placeholder: "",
        },
      ];
    }
    return [
      {
        languageISOCode,
        name: "",
        placeholder: "",
      },
      {
        languageISOCode:
          languageISOCode === ESupportedLangs.en.toUpperCase()
            ? ESupportedLangs.pl.toUpperCase()
            : ESupportedLangs.en.toUpperCase(),
        name: "",
        placeholder: "",
      },
    ];
  };

  return (
    <Wrapper>
      <Title>
        {t("forms.modals.select")}{" "}
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      </Title>
      <List>
        {filedsList.map((field) => (
          <FieldItem
            key={field.title}
            onClick={() => {
              setValue(`fields`, [
                ...fields,
                {
                  id: Date.now(),
                  type: field.componentType,
                  hiddenFromExistingPatients: false,
                  required: false,
                  translations: getTranslationsFieldInit(field.componentType),
                  ...(field.componentType === FormFieldTypes.Date && {
                    value: { time: false },
                  }),
                },
              ]);
              onClose();
            }}
          >
            {field.icon}
            {field.title}
          </FieldItem>
        ))}
      </List>
    </Wrapper>
  );
}

export default FieldsListComponent;
