import ReactDatePicker from "react-datepicker";
import { format, isSameDay } from "date-fns";
import { getFNSLocaleByLang } from "src/utils";
import { useTranslation } from "react-i18next";
import { Event } from "react-big-calendar";
import { useState } from "react";
import {
  DatePickerDay,
  DatePickerDayDropdown,
  EmptyTextStyled,
  Title,
  Wrapper,
} from "./styles";
import DayEventsList from "../DayEventsList/DayEventsList";

const CalendarMonthComponent = ({
  monthDate,
  events,
  onShowMore,
}: {
  monthDate: Date;
  events: Event[];
  onShowMore: (val: Date) => void;
}) => {
  const { t, i18n } = useTranslation();

  const [showDropdown, setShowDropdown] = useState<{
    open: boolean;
    date: Date | null;
  }>({
    open: false,
    date: null,
  });

  const lastDayOfMonth = (date: Date) =>
    new Date(date.getFullYear(), date.getMonth() + 1, 0);

  return (
    <Wrapper>
      <ReactDatePicker
        locale={getFNSLocaleByLang(i18n.resolvedLanguage)}
        onChange={() => {}}
        selected={monthDate}
        renderCustomHeader={({ date: dayDate }) => (
          <Title>
            {format(dayDate, "LLLL", {
              locale: getFNSLocaleByLang(i18n.resolvedLanguage),
            })}
          </Title>
        )}
        minDate={new Date()}
        maxDate={lastDayOfMonth(monthDate)}
        inline
        renderDayContents={(dayNumber, date) => {
          const eventsForCurrentDay = events.filter((event) =>
            isSameDay(date as Date, event.start as Date)
          );

          const isCurrentDay =
            showDropdown.open &&
            showDropdown.date &&
            isSameDay(date || dayNumber, showDropdown.date);

          return (
            <DatePickerDay
              onClick={() => {
                setShowDropdown((prew) => ({
                  open: !prew.open,
                  date: date as Date | null,
                }));
              }}
              onMouseLeave={() => setShowDropdown({ open: false, date: null })}
            >
              <div className="datepicker-day-number">{dayNumber}</div>
              {isCurrentDay && (
                <DatePickerDayDropdown className="datepicker-dropdown">
                  {eventsForCurrentDay.length ? (
                    <DayEventsList
                      date={date as Date}
                      events={eventsForCurrentDay}
                      maxLength={5}
                      onShowMore={onShowMore}
                    />
                  ) : (
                    <EmptyTextStyled>{t("app.not_found")}</EmptyTextStyled>
                  )}
                </DatePickerDayDropdown>
              )}
            </DatePickerDay>
          );
        }}
        dayClassName={(date) => {
          const availableDay = events.some((availableDate) =>
            isSameDay(date, availableDate?.start as Date)
          );
          return availableDay ? `available-day` : null;
        }}
      />
    </Wrapper>
  );
};

export default CalendarMonthComponent;
