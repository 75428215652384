import { PatientEntityResponse } from "src/graphql/generated";

import { useTranslation } from "react-i18next";

import { getAge } from "src/utils";
import {
  AvatarComponentStyled,
  DoctorOptInfoWrap,
  DotSeparator,
  OptItemSubTitle,
  OptItemTitle,
  Wrap,
  WrapBottom,
  WrapBottomBox,
  WrapBottomBoxContent,
  WrapBottomBoxTitle,
  WrapTop,
} from "./styles";

interface ComponentProps {
  patient: PatientEntityResponse;
}

const NewPatientComponent = ({ patient }: ComponentProps) => {
  const { t } = useTranslation();

  return (
    <Wrap>
      <WrapTop>
        <AvatarComponentStyled
          firstName={patient.firstName}
          lastName={patient.lastName}
          size={56}
        />
        <DoctorOptInfoWrap>
          <OptItemTitle>{`${patient.firstName} ${patient.lastName}`}</OptItemTitle>
          {(!!patient.phoneNumber || !!patient.email) && (
            <OptItemSubTitle>
              {patient.phoneNumber}
              {!!patient.phoneNumber && !!patient.email && <DotSeparator />}
              {patient.email}
            </OptItemSubTitle>
          )}
        </DoctorOptInfoWrap>
      </WrapTop>
      <WrapBottom>
        {!!patient.gender && (
          <WrapBottomBox>
            <WrapBottomBoxTitle>
              {t("personal_info.labels.gender")}
            </WrapBottomBoxTitle>
            <WrapBottomBoxContent>{patient.gender}</WrapBottomBoxContent>
          </WrapBottomBox>
        )}
        {!!patient.dateOfBirth && (
          <WrapBottomBox>
            <WrapBottomBoxTitle>
              {t("mailing.select_mailing.labels.age")}
            </WrapBottomBoxTitle>
            <WrapBottomBoxContent>
              {getAge(patient.dateOfBirth)}
            </WrapBottomBoxContent>
          </WrapBottomBox>
        )}
        {!!patient.passportNumber && (
          <WrapBottomBox>
            <WrapBottomBoxTitle>{t("app.passport")}</WrapBottomBoxTitle>
            <WrapBottomBoxContent>
              {patient.passportNumber}
            </WrapBottomBoxContent>
          </WrapBottomBox>
        )}
      </WrapBottom>
    </Wrap>
  );
};

export default NewPatientComponent;
