import React, { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { options, optOperatory } from "src/constants/constants";
import { Multiselect } from "src/UI/Multiselect";
import { CalendarIcon, DeleteIcon } from "src/UI/Svg";
import "react-datepicker/dist/react-datepicker.css";
import { Toggle } from "src/UI/Toggle";
import {
  ButtonIcon,
  DateInputStyled,
  DropdownStyled,
  FormColumn,
  InputWrapper,
  Label,
  Wrapper,
} from "./styles";

const CustomInput = forwardRef(
  (
    { value, onClick, placeholder }: React.HTMLProps<HTMLInputElement>,
    ref: React.Ref<HTMLInputElement>
  ) => (
    <DateInputStyled onClick={onClick} ref={ref}>
      {value || placeholder || ""}
    </DateInputStyled>
  )
);

function TimetableRowComponent({
  removeItem = () => {},
}: {
  removeItem?: () => void;
}) {
  const { t } = useTranslation();
  const [days, setDays] = useState([]);
  const [data, setData] = useState<{
    operator: string;
    dateFrom: Date | null;
    dateTo: Date | null;
    isOperatory: boolean;
    isCustomRepeat: boolean;
  }>({
    operator: optOperatory[0].value as string,
    dateFrom: null,
    dateTo: null,
    isOperatory: false,
    isCustomRepeat: false,
  });

  const onChange = (key: string, val: any) => {
    setData((prevState) => ({
      ...prevState,
      [key]: val,
    }));
  };

  const handleToggle = (key: string, val: boolean) => () => {
    onChange(key, !val);
  };

  const handleRemoveItem = () => () => {
    removeItem();
  };

  return (
    <Wrapper>
      <FormColumn>
        <Multiselect
          value={days}
          placeholder={t("booking.timetable.days")}
          options={options}
          label={t("booking.timetable.days")}
          onSelect={(val) => setDays(val)}
          customIcon={<CalendarIcon width={16} height={16} />}
        />
        <InputWrapper>
          <Label>{t("booking.timetable.labels.operatory")}</Label>
          <DropdownStyled
            isShowOnlyPlaceholder={false}
            value={data.operator}
            placeholder={t("booking.timetable.labels.operatory")}
            onSelect={(val) => onChange("operator", val)}
            options={optOperatory}
            label=""
          />
        </InputWrapper>
      </FormColumn>
      <FormColumn>
        <InputWrapper>
          <Label>{t("booking.timetable.labels.from")}</Label>
          <DatePicker
            selected={data.dateFrom}
            onChange={(date) => onChange("dateFrom", date)}
            placeholderText={t("booking.timetable.labels.from") as string}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption={t("app.schedule_time") as string}
            customInput={<CustomInput />}
            dateFormat="hh:mm"
            timeFormat="p"
          />
        </InputWrapper>
        <InputWrapper>
          <Label>{t("booking.timetable.labels.operatory")}</Label>
          <Toggle
            isActive={data.isOperatory}
            onToggle={handleToggle("isOperatory", data.isOperatory)}
          />
        </InputWrapper>
      </FormColumn>
      <FormColumn>
        <InputWrapper>
          <Label>{t("booking.timetable.labels.to")}</Label>
          <DatePicker
            selected={data.dateTo}
            onChange={(date) => onChange("dateTo", date)}
            placeholderText={t("booking.timetable.labels.to") as string}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption={t("app.schedule_time") as string}
            customInput={<CustomInput />}
            dateFormat="hh:mm"
            timeFormat="p"
          />
        </InputWrapper>
        <InputWrapper>
          <Label>{t("booking.timetable.labels.custom_repeat")}</Label>
          <Toggle
            isActive={data.isCustomRepeat}
            onToggle={handleToggle("isCustomRepeat", data.isCustomRepeat)}
          />
        </InputWrapper>
      </FormColumn>
      <ButtonIcon onClick={handleRemoveItem}>
        <DeleteIcon />
      </ButtonIcon>
    </Wrapper>
  );
}

export default TimetableRowComponent;
