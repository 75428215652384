import { CardComponent } from "src/components/UserComponents";
import styled from "styled-components";

export const CardStyled = styled(CardComponent)`
  min-height: 340px;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  gap: 0;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 20px;
  padding-bottom: 16px;
`;

export const Wrap = styled.div`
  width: 662px;
  margin-left: auto;
`;

export const ErrorStyled = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.red};
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 12px;
  }
`;
