import { useTranslation } from "react-i18next";
import { ActivityComponent } from "src/components/DashboardComponents/ActivityComponents";
import {
  AppointmentSortFields,
  AppointmentStatuses,
  SortingOrders,
} from "src/graphql/generated";
import { Loading } from "src/UI/Loading";
import { useAppAction } from "src/store/app/hooks";
import { IAppointmentsFilters, TScheduleAppointmentByRole } from "src/types";
import { useScheduleAppointmentsByRoleFetchMore } from "src/hooks/appointments/fetchMore/useScheduleAppointmentsByRoleFetchMore";
import { useState } from "react";
import { DropdownFilter } from "src/components/BookingComponents/ScheduleAppointmentsFilterComponent/styled";
import {
  Block,
  EmptyTextStyled,
  HeadingStyled,
  List,
  LoadingWrap,
  Title,
} from "./styled";

function ActivityView() {
  const { t } = useTranslation();
  const { onShowScheduleAppointmentModal } = useAppAction();

  const [filters, setFilters] = useState<IAppointmentsFilters>({
    sortField: AppointmentSortFields.CreatedAt,
    status: AppointmentStatuses.New,
    sortOrder: SortingOrders.Ascending,
  });

  const optsDate: Array<{ item: string; value: string }> = [
    {
      item: t("booking.patients.filters.date_created"),
      value: AppointmentSortFields.CreatedAt,
    },
    {
      item: t("booking.patients.filters.date_ustart_visit"),
      value: AppointmentSortFields.ScheduleTimeFrom,
    },
  ];

  const { data, loading, node, isLoading } =
    useScheduleAppointmentsByRoleFetchMore({ filters });

  const handleShowModal = (id: string) => () => {
    onShowScheduleAppointmentModal({
      data: {
        appointment:
          (data?.appointments as TScheduleAppointmentByRole[])?.find(
            (item: any) => item?.id === id
          ) || null,
      },
    });
  };

  const handleOnChangeFilter = (key: string) => (val: string | boolean) => {
    const sortOrderValue =
      val === AppointmentSortFields.UpdatedAt
        ? SortingOrders.Descending
        : SortingOrders.Ascending;

    setFilters((prevState) => ({
      ...prevState,
      sortOrder: sortOrderValue,
      [key]: val,
    }));
  };

  const count = data?.count || 0;

  const apptLength = data?.appointments?.length || 0;

  return (
    <Block>
      <Title>
        <HeadingStyled as="h3">{t("activity_view.title")}</HeadingStyled>
        <DropdownFilter
          isShowOnlyPlaceholder={false}
          value={filters.sortField}
          placeholder={t("booking.patients.filters.date")}
          onSelect={handleOnChangeFilter("sortField")}
          options={optsDate}
          label=""
        />
      </Title>
      {loading ? (
        <LoadingWrap>
          <Loading padding="0" />
        </LoadingWrap>
      ) : (
        <List>
          {data?.appointments.length ? (
            data?.appointments?.map((appointment) => (
              <ActivityComponent
                key={appointment.id}
                appointment={appointment as TScheduleAppointmentByRole}
                handleShowModal={handleShowModal}
              />
            ))
          ) : (
            <EmptyTextStyled>{t("app.appointments_not_added")}</EmptyTextStyled>
          )}
        </List>
      )}
      {isLoading ? (
        <LoadingWrap>
          <Loading padding="0" />
        </LoadingWrap>
      ) : null}
      {count > apptLength ? <div ref={node} /> : null}
    </Block>
  );
}

export default ActivityView;
