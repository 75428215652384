import * as yup from "yup";

export const sendSmsValidationSchema = yup.object().shape({
  clinicId: yup.string().required("validation.field_required"),
  content: yup
    .string()
    .min(1, "validation.field_min_1")
    .required("validation.field_required"),
  patientId: yup.string().required("validation.field_required"),
});
