import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  padding: 0px 0px 9px 0px;
`;

export const Desc = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  flex: 1;
`;

export const Label = styled.div`
  width: 100%;
  font-size: 10px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme }) => theme.colors.darkGrey};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Title = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme }) => theme.colors.darkGrey};
`;

export const Cabinet = styled.div`
  padding: 8px 10px;
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.white};
  font-size: 16px;
  font-weight: 700;
  line-height: 1.1;
  letter-spacing: 0em;
  width: fit-content;
  color: ${({ theme }) => theme.colors.darkGrey};
`;

export const SubTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme }) => theme.colors.gray};
`;
