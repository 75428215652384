import { gql } from "@apollo/client";

export const LOCAL_SIGN_IN = gql`
  mutation LocalSignIn($schema: LocalSignInSchema!) {
    localSignIn(schema: $schema) {
      signedIn
      twoFactorAuthenticationEnabled
    }
  }
`;
