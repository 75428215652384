import { gql } from "@apollo/client";

export const GET_ALL_COUNTRIES = gql`
  query FindAllCountries {
    findAllCountries {
      countries {
        id
        isoAlpha2Code
        isoAlpha3Code
        name
      }
    }
  }
`;
