import { PatientEntityResponse } from "src/graphql/generated";

export const transformBeforeEditPatient = (
  patientResponse: PatientEntityResponse
) => {
  if (patientResponse) {
    return {
      id: patientResponse.id,
      first_name: patientResponse.firstName,
      last_name: patientResponse.lastName,
      dateOfBirth: patientResponse.dateOfBirth
        ? new Date(patientResponse.dateOfBirth as string)
        : null,
      gender: patientResponse.gender,
      email: patientResponse.email,
      comment: patientResponse.comment,
      phone: patientResponse.phoneNumber.slice(1),
      passportNumber: patientResponse.passportNumber,
      emailDistribution: patientResponse.emailDistribution,
      smsDistribution: patientResponse.smsDistribution,
      isAllergy: patientResponse.allergic ? "yes" : "no",
      allergyComment: patientResponse.allergens || "",
    };
  }
};
