import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { DeleteIcon } from "src/UI/Svg";
import "react-datepicker/dist/react-datepicker.css";
import {
  EventTypes,
  LayoutTypes,
  ScheduleEntityResponse,
} from "src/graphql/generated";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { GET_FIND_SCHEDULES_BY_CLIENT } from "src/graphql/queries";
import {
  useCreateSchedule,
  useUpdateScheduleByIdByClient,
} from "src/graphql/hooks/useMutations";
import { getMinutesFromStartDay, getDateFromMinutesStartDay } from "src/utils";

import { useClinicsState } from "src/store/clinics/hooks";
import {
  ButtonIcon,
  DateInputStyled,
  DropdownStyled,
  DropdownWrapper,
  ErrorStyled,
  FooterRowWrapper,
  FormColumn,
  HeadWrapper,
  InputWrapper,
  Label,
  RowWrapper,
  Wrapper,
} from "./styles";

const CustomInput = forwardRef(
  (
    { value, onClick, placeholder }: React.HTMLProps<HTMLInputElement>,
    ref: React.Ref<HTMLInputElement>
  ) => (
    <DateInputStyled onClick={onClick} ref={ref}>
      {value || placeholder || ""}
    </DateInputStyled>
  )
);

function CustomTimeRowComponent({
  newItem,
  scheduleItem,
  cabinets,
  doctorId,
  removeItem = () => {},
}: {
  newItem?: { id: number; type: LayoutTypes };
  scheduleItem?: ScheduleEntityResponse;
  cabinets: { item: string; value: string }[];
  doctorId: string;
  removeItem?: (id: string | number) => void;
}) {
  const { t } = useTranslation();
  const { currentClinicId: clinicId } = useClinicsState();

  const [createSchedule] = useCreateSchedule();
  const [updateSchedule] = useUpdateScheduleByIdByClient();

  const workingTypeOpts: Array<{
    item: string;
    value: string;
  }> = [
    {
      item: t("app.working"),
      value: EventTypes.Working,
    },
    {
      item: t("app.vacation"),
      value: EventTypes.Vacation,
    },
  ];

  const [data, setData] = useState<{
    eventType: string;
    roomId: string;
    minuteFrom: number | null;
    minuteTo: number | null;
    dateFrom: Date | null;
    dateTo: Date | null;
  }>({
    eventType: workingTypeOpts?.[0].value || "",
    roomId: "",
    minuteFrom: null,
    minuteTo: null,
    dateFrom: null,
    dateTo: null,
  });

  const allKeys = Object.keys(data);
  const actulArrayKeys = useMemo(() => {
    return !cabinets?.length
      ? allKeys.filter((key) => key !== "roomId")
      : allKeys;
  }, [cabinets, allKeys]);

  const handleRemoveItem = (id: string | number) => () => {
    removeItem(id);
  };

  const onChange = (key: string, val: any) => {
    setData((prevState) => ({
      ...prevState,
      [key]: val,
    }));

    const filteredKeys = actulArrayKeys.filter((keyItem) => keyItem !== key);

    const allKeysContainValue = filteredKeys.every((keyItem) => {
      const dataKey = keyItem as keyof typeof data;
      return !!data?.[dataKey] || typeof data?.[dataKey] === "number";
    });

    const params = {
      eventType: data?.eventType,
      dateFrom: data?.dateFrom,
      dateTo: data?.dateTo,
      minuteFrom: data.minuteFrom,
      minuteTo: data.minuteTo,
      ...(data?.roomId && { roomId: data?.roomId }),
    };

    const updatedParams = {
      ...params,
      [key]: val,
    };

    if (allKeysContainValue) {
      if (scheduleItem) {
        updateSchedule({
          variables: {
            schema: {
              fields: {
                ...updatedParams,
                dateFrom: `${format(
                  updatedParams?.dateFrom as Date,
                  "yyyy-MM-dd"
                )}T00:00:00`,
                dateTo: `${format(
                  updatedParams?.dateTo as Date,
                  "yyyy-MM-dd"
                )}T00:00:00`,
              },
              id: scheduleItem?.id,
            },
          },
          refetchQueries: [GET_FIND_SCHEDULES_BY_CLIENT],
          awaitRefetchQueries: true,
          onCompleted: () => {
            toast.success(t("app.successful"));
          },
        });
      } else {
        createSchedule({
          variables: {
            schema: {
              ...updatedParams,
              doctorId,
              clinicId,
              layoutType: LayoutTypes.Custom,
              dateFrom: `${format(
                updatedParams?.dateFrom as Date,
                "yyyy-MM-dd"
              )}T00:00:00`,
              dateTo: `${format(
                updatedParams?.dateTo as Date,
                "yyyy-MM-dd"
              )}T00:00:00`,
            },
          },
          refetchQueries: [GET_FIND_SCHEDULES_BY_CLIENT],
          onCompleted: () => {
            toast.success(t("app.successful"));
            if (newItem) handleRemoveItem(newItem?.id)();
          },
        });
      }
    }
  };

  useEffect(() => {
    if (scheduleItem) {
      setData({
        eventType: scheduleItem?.eventType,
        roomId: scheduleItem?.roomId || "",
        minuteFrom: scheduleItem?.minuteFrom,
        minuteTo: scheduleItem?.minuteTo,
        dateFrom: new Date(scheduleItem?.dateFrom),
        dateTo: new Date(scheduleItem?.dateTo),
      });
    }
  }, [scheduleItem]);

  const handleDateRangeChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    setData((prev) => ({
      ...prev,
      dateFrom: start,
      dateTo: end,
    }));
    if (start && end) onChange("dateTo", end);
  };

  return (
    <Wrapper>
      <HeadWrapper>
        <DropdownWrapper>
          <DropdownStyled
            isShowOnlyPlaceholder={false}
            value={data.eventType}
            placeholder={t("templates.history.headers.type")}
            onSelect={(val) => onChange("eventType", val)}
            workingType={data.eventType}
            options={workingTypeOpts}
            label=""
          />
        </DropdownWrapper>
        <ButtonIcon
          onClick={handleRemoveItem(
            (scheduleItem?.id as string) || (newItem?.id as number)
          )}
          type="button"
        >
          <DeleteIcon />
        </ButtonIcon>
      </HeadWrapper>
      <RowWrapper>
        <FormColumn>
          <DatePicker
            placeholderText={t("app.schedule_date_range") as string}
            customInput={<CustomInput />}
            selectsRange={true}
            startDate={data.dateFrom}
            endDate={data.dateTo}
            onChange={handleDateRangeChange}
            isClearable={true}
          />
          {(!data?.dateFrom || !data?.dateTo) && (
            <ErrorStyled>{t("validation.field_required")}</ErrorStyled>
          )}
        </FormColumn>
        <FormColumn>
          <InputWrapper>
            <Label>{t("booking.timetable.labels.from")}</Label>
            <DatePicker
              selected={getDateFromMinutesStartDay(data.minuteFrom)}
              onChange={(date) =>
                onChange("minuteFrom", getMinutesFromStartDay(date))
              }
              placeholderText={t("booking.timetable.labels.from") as string}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={10}
              timeCaption={t("app.schedule_time") as string}
              customInput={<CustomInput />}
              timeFormat="HH:mm"
              dateFormat="HH:mm"
              filterTime={(currentTime) => {
                const minuteTo = data?.minuteTo;
                if (minuteTo) {
                  return (
                    currentTime < (getDateFromMinutesStartDay(minuteTo) as Date)
                  );
                }
                return true;
              }}
            />
          </InputWrapper>
          {!data?.minuteFrom && (
            <ErrorStyled>{t("validation.field_required")}</ErrorStyled>
          )}
        </FormColumn>
        <FormColumn>
          <InputWrapper>
            <Label>{t("booking.timetable.labels.to")}</Label>
            <DatePicker
              selected={getDateFromMinutesStartDay(data.minuteTo)}
              onChange={(date) =>
                onChange("minuteTo", getMinutesFromStartDay(date))
              }
              placeholderText={t("booking.timetable.labels.to") as string}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={10}
              timeCaption={t("app.schedule_time") as string}
              customInput={<CustomInput />}
              timeFormat="HH:mm"
              dateFormat="HH:mm"
              filterTime={(currentTime) => {
                const minuteFrom = data?.minuteFrom;
                if (minuteFrom) {
                  return (
                    currentTime >
                    (getDateFromMinutesStartDay(minuteFrom) as Date)
                  );
                }
                return true;
              }}
            />
          </InputWrapper>
          {!data?.minuteTo && (
            <ErrorStyled>{t("validation.field_required")}</ErrorStyled>
          )}
        </FormColumn>
      </RowWrapper>
      <FooterRowWrapper>
        <FormColumn>
          {!!cabinets.length && (
            <>
              <InputWrapper>
                <Label>{t("modals.patient_settings.cabinet")}</Label>
                <DropdownStyled
                  isShowOnlyPlaceholder={false}
                  value={data.roomId}
                  placeholder={t("map.cabinets.title")}
                  onSelect={(val) => onChange("roomId", val)}
                  options={cabinets}
                  label=""
                />
              </InputWrapper>
              {!data?.roomId?.length && (
                <ErrorStyled>{t("validation.field_required")}</ErrorStyled>
              )}
            </>
          )}
        </FormColumn>
      </FooterRowWrapper>
    </Wrapper>
  );
}

export default CustomTimeRowComponent;
