import { Navigate } from "react-router-dom";
import { DASHBOARD_PATHS, PATHS } from "src/constants/constants";
import { useAuthContext } from "src/context/AuthContext";
import { UserRoles } from "src/graphql/generated";

export interface ProtectedRouteTypes {
  element: () => JSX.Element;
  roles: string[];
}

export function ProtectedRoute({
  element: Element,
  roles,
}: ProtectedRouteTypes) {
  const { isDoctor, isClient } = useAuthContext();

  if (isDoctor && !roles.includes(UserRoles.Doctor)) {
    return <Navigate to={`${PATHS.cp}${DASHBOARD_PATHS.defaultPage}`} />;
  }

  if (isClient && !roles.includes(UserRoles.Client))
    return <Navigate to={`${PATHS.cp}${DASHBOARD_PATHS.defaultPage}`} />;

  return <Element />;
}
