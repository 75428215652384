import { useAuthContext } from "src/context/AuthContext";
import { AggregatedScheduleAppointmentEntityWithRelationsByClientResponse } from "src/graphql/generated";
import { useFindManyFilesByIds } from "src/graphql/hooks/useQueries";
import { TScheduleAppointmentByRole } from "src/types";

interface IUseLogoFiles {
  appointments: TScheduleAppointmentByRole[] | undefined | null;
}

export function useLogoFiles({ appointments }: IUseLogoFiles) {
  const { isDoctor, currentUserData } = useAuthContext();
  const doctorsPhotoIds = appointments?.reduce((photoIds: string[], appt) => {
    const doctor = (
      appt as AggregatedScheduleAppointmentEntityWithRelationsByClientResponse
    )?.doctor;
    const avatarFileId = doctor?.avatarFileId;

    if (avatarFileId) {
      photoIds.push(avatarFileId);
    }

    return photoIds;
  }, []);

  const uniqueDoctorsPhotoIds = [...new Set(doctorsPhotoIds)];

  const doctorPhotoDoctorId = currentUserData?.avatarFileId;

  const { data: logoFiles } = useFindManyFilesByIds({
    variables: {
      schema: {
        ids: isDoctor ? [doctorPhotoDoctorId] : uniqueDoctorsPhotoIds,
      },
    },
    skip: isDoctor ? !doctorPhotoDoctorId : !doctorsPhotoIds?.length,
  });

  return {
    logoFiles,
  };
}
