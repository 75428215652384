import styled from "styled-components";

export const PatientOptInfoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const PatientOptWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  align-items: center;
  min-width: 32px;
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.colors.lightBiege};
  border-radius: 56px;

  svg {
    width: 32px;
    height: 32px;
  }
`;

export const OptItemTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Age = styled.div`
  color: ${({ theme }) => theme.colors.gray};
  margin-left: 8px;
`;

export const OptItemSubTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  color: ${({ theme }) => theme.colors.gray};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DotSeparator = styled.div`
  width: fit-content;
  margin: 0 10px;
  display: flex;
  align-items: center;

  &:before {
    content: "";
    width: 5px;
    height: 5px;
    background-color: ${({ theme }) => theme.colors.gray};
    border-radius: 50%;
  }
`;
