import React, { FunctionComponent } from "react";
import { Svg, SvgProps } from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
  <Svg width="62" height="49" viewBox="0 0 62 49" fill="none" {...props}>
    <path
      d="M34.7295 48.9697C39.5264 48.9697 42.542 46.4541 42.542 42.7041V26.6104H38.6514V29.4229H38.5576C37.6201 27.5166 35.8076 26.2666 33.4639 26.2666C29.2764 26.2666 26.5732 29.4854 26.5732 34.7197V34.7354C26.5732 39.8916 29.2451 43.0791 33.4014 43.0791C35.7764 43.0791 37.6514 41.9541 38.5732 40.1885H38.6514V42.5791C38.6514 44.6885 37.2607 46.0322 34.7295 46.0322C32.6982 46.0322 31.3701 45.3135 30.9482 44.3604L30.9014 44.2354H27.0732L27.1045 44.376C27.5732 46.9697 30.3389 48.9697 34.7295 48.9697ZM34.6201 40.0791C32.1201 40.0791 30.542 38.0635 30.542 34.7979V34.7822C30.542 31.5635 32.1201 29.5479 34.6201 29.5479C37.0264 29.5479 38.667 31.5947 38.667 34.7979V34.8135C38.667 38.0322 37.042 40.0791 34.6201 40.0791Z"
      fill="#0BA495"
    />
    <path
      d="M52.4176 43.501C57.277 43.501 60.4332 40.2666 60.4332 34.8916V34.8604C60.4332 29.5166 57.2301 26.2666 52.402 26.2666C47.5895 26.2666 44.402 29.5479 44.402 34.8604V34.8916C44.402 40.251 47.5426 43.501 52.4176 43.501ZM52.4332 40.3604C49.9489 40.3604 48.3707 38.376 48.3707 34.8916V34.8604C48.3707 31.4229 49.9801 29.4229 52.402 29.4229C54.8707 29.4229 56.4645 31.4072 56.4645 34.8604V34.8916C56.4645 38.3604 54.8864 40.3604 52.4332 40.3604Z"
      fill="#0BA495"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.9179 23.2203C42.9723 22.5986 43.0002 21.9692 43.0002 21.3333C43.0002 9.55126 33.4489 0 21.6668 0C9.88476 0 0.333496 9.55126 0.333496 21.3333C0.333496 32.7596 9.31663 42.0878 20.6058 42.6407C20.3236 42.2326 20.1583 41.7375 20.1583 41.2038V31.7249H12.1963C10.7993 31.7249 9.66683 30.5924 9.66683 29.1954V23.8627C9.66683 22.4657 10.7993 21.3333 12.1963 21.3333H20.1583V11.8619C20.1583 10.4649 21.2908 9.33246 22.6877 9.33246H27.7467C29.1437 9.33246 30.2761 10.4649 30.2761 11.8619V21.3333H40.4707C41.6457 21.3333 42.6336 22.1344 42.9179 23.2203Z"
      fill="#0BA495"
    />
  </Svg>
);

export default Icon;
