import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { ITemplateCreateEdit } from "src/types/templates";
import { createEditTemplate } from "src/validation/schemas/createEditTemplate";
import { TemplateProvider } from "src/context/TemplatesContext";
import { DEFAULT_TEMPLATE_ACTIONS } from "src/constants/constants";
import { useTemplateUpdate } from "src/hooks/templates";
import {
  useTemplatesAction,
  useTemplatesState,
} from "src/store/templates/hooks";
import {
  useCreateTemplateMutation,
  useFindManyTemplateVariablesByClientLazyQuery,
} from "src/graphql/generated";
import { useClinicsState } from "src/store/clinics/hooks";
import {
  transformTemplateCreateEditToCreate,
  transformTemplateCreateEditToUpdate,
  transformTemplateToFormCreateEdit,
} from "src/connectors/Templates/transformTemplates";
import {
  TemplatesEventActionsView,
  TemplatesEditView,
} from "../../../../views/TemplatesViews";
import { FormContainer, StyledEmptyText, Title, Wrapper } from "./styled";

function ActionPage() {
  const { t } = useTranslation();
  const { currentClinicId: clinicId } = useClinicsState();
  const [templateVariable, setTemplateVariable] = useState<string[]>();
  const { selectedTemplateData } = useTemplatesState();
  const methods = useForm<ITemplateCreateEdit>({
    mode: "onChange",
    defaultValues: {
      templateActions: DEFAULT_TEMPLATE_ACTIONS,
    },
    resolver: yupResolver(createEditTemplate(templateVariable)),
  });

  const selectedEvent = methods.watch("selectedEvent");

  const [getTemplateVariables] = useFindManyTemplateVariablesByClientLazyQuery({
    fetchPolicy: "network-only",
  });

  const [createTemplate] = useCreateTemplateMutation();
  const { updateMailTemplates, updateSmsTemplates, updateDataTemplate } =
    useTemplateUpdate();

  const { onSetSelectedTemplate } = useTemplatesAction();

  useEffect(() => {
    if (selectedEvent) {
      getTemplateVariables({
        variables: {
          schema: {
            event: selectedEvent,
          },
        },
        onCompleted: (result) => {
          setTemplateVariable(
            result.findManyTemplateVariablesByClient.variables
          );
        },
      });
    }
  }, [selectedEvent]);

  const formHandlerSubmit = async (formValues: ITemplateCreateEdit) => {
    const createTemplateSchema = transformTemplateCreateEditToCreate(
      formValues,
      clinicId
    );

    if (createTemplateSchema && !selectedTemplateData) {
      await createTemplate({
        variables: {
          schema: createTemplateSchema,
        },
        onCompleted: (data) => {
          const resetData = transformTemplateToFormCreateEdit(
            data.createTemplate
          );

          onSetSelectedTemplate(data.createTemplate);

          methods.reset(resetData);
          toast.success(t("app.successful"));
        },
        onError: (error) => {
          if (error) toast.error(error.message);
        },
      });
    }

    if (selectedTemplateData) {
      const updateTemplateSchema = transformTemplateCreateEditToUpdate(
        formValues,
        selectedTemplateData
      );

      let isUpdated = false;
      let isSmsUpdated = false;
      let isMailUpdated = false;

      if (updateTemplateSchema.mailTemplatesValue) {
        isMailUpdated = await updateMailTemplates(
          updateTemplateSchema.mailTemplatesValue
        );
      }

      if (updateTemplateSchema.smsTemplatesValue) {
        isSmsUpdated = await updateSmsTemplates(
          updateTemplateSchema.smsTemplatesValue
        );
      }

      isUpdated = isSmsUpdated || isMailUpdated;

      if (updateTemplateSchema.updateTemplateValue && isUpdated) {
        isUpdated = await updateDataTemplate(
          updateTemplateSchema.updateTemplateValue
        );
      }

      if (isUpdated) {
        toast.success(t("app.successful"));
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <TemplateProvider>
        <FormContainer onSubmit={methods.handleSubmit(formHandlerSubmit)}>
          <Title>{t("templates.action.actions.page_title")}</Title>

          <Wrapper>
            <TemplatesEventActionsView />

            <TemplatesEditView />
          </Wrapper>

          {!selectedEvent && (
            <StyledEmptyText
              text={t("templates.action.edit.no_event_selected")}
            />
          )}
        </FormContainer>
      </TemplateProvider>
    </FormProvider>
  );
}

export default ActionPage;
