import { Button } from "src/UI/Button";
import styled from "styled-components";

export const TimeBlockWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const TimeBlockLabel = styled.div`
  font-family: "SFProDisplay", system-ui;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: ${({ theme }) => theme.colors.gray};
  margin-right: 30px;
`;

export const TimeScheduleWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 10px;
  align-items: center;
`;

export const TimeScheduleItem = styled.div`
  flex-grow: 1;
  padding: 8px 10px 8px 10px;
  border-radius: 5px;
  gap: 4px;
  background-color: #f8f8f8;
  font-weight: 700;
`;

export const ActionsWrapStyled = styled.div`
  width: 100%;
`;

export const TimeScheduleItemWaitList = styled.div`
  flex-grow: 1;

  & .react-datepicker-wrapper {
    display: block;
    max-width: 100%;
  }

  & .react-datepicker__input-container input {
    padding: 8px 10px 8px 10px;
    border-radius: 5px;
    border: none;
    background-color: #f8f8f8;
    display: block;
    max-width: 100%;
    width: 100%;
  }
`;

export const DatePickerWrapper = styled.div`
  width: 100%;
  position: relative;

  & .react-datepicker {
    width: 100%;
    border: none;
    background-color: #f8f8f8;
    border-radius: 10px;
  }

  & .react-datepicker__navigation {
    width: 24px;
    height: 24px;
    &--previous {
      left: auto;
      right: 20px;
      top: 20px;
    }
    &--next {
      left: auto;
      right: 60px;
      top: 20px;
    }
  }

  & .react-datepicker__navigation-icon {
    &::before {
      border-width: 1.5px 1.5px 0 0;
      border-color: ${({ theme }) => theme.colors.green};
    }
    &--previous {
      &::before {
        transform: rotate(135deg);
        right: -3px;
      }
    }
    &--next {
      &::before {
        transform: rotate(-45deg);
        left: -3px;
        top: 10px;
      }
    }
  }

  & .react-datepicker__header {
    background-color: unset;
    border-bottom: none;
  }

  & .react-datepicker__month-container {
    float: none;
  }

  & .react-datepicker__current-month {
    padding: 12px 23px;
    font-family: "SFProDisplay", system-ui;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
  }

  & .react-datepicker__day-names,
  .react-datepicker__week {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }
  & .react-datepicker__week {
    & .react-datepicker__day--in-range:first-of-type {
      &:after {
        width: 70%;
        right: -10px;
      }
      &:before {
        content: "";
        position: absolute;
        border-left: 1px solid;
        border-top: 1px solid;
        border-bottom: 1px solid;
        border-color: ${({ theme }) => theme.colors.green};
        border-bottom-left-radius: 50%;
        border-top-left-radius: 50%;
        width: 100%;
        height: 100%;
        left: 0;
      }
    }
    & .react-datepicker__day--in-range:last-of-type {
      &:after {
        width: 70%;
        left: -10px;
      }
      &:before {
        content: "";
        position: absolute;
        border-right: 1px solid;
        border-top: 1px solid;
        border-bottom: 1px solid;
        border-color: ${({ theme }) => theme.colors.green};
        border-bottom-right-radius: 50%;
        border-top-right-radius: 50%;
        width: 100%;
        height: 100%;
        left: 0;
      }
    }
  }

  & .react-datepicker__day,
  .react-datepicker__day-name {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 14px;
    font-family: "SFProDisplay", system-ui;
  }

  & .react-datepicker__day {
    &--outside-month {
      color: ${({ theme }) => theme.colors.grayC4};
      & * {
        color: ${({ theme }) => theme.colors.grayC4};
      }
    }
    &--weekend {
      color: ${({ theme }) => theme.colors.red};
      & * {
        color: ${({ theme }) => theme.colors.red};
      }
    }
    &--disabled {
      color: ${({ theme }) => theme.colors.grayC4};
      & * {
        color: ${({ theme }) => theme.colors.grayC4};
      }
    }

    &--selected {
      background-color: transparent;
      border: 1px solid;
      background-color: ${({ theme }) => theme.colors.white} !important;
      border-color: ${({ theme }) => theme.colors.green} !important;
      color: ${({ theme }) => theme.colors.green} !important;
      font-weight: 600;

      & .datepicker-day-number {
        color: ${({ theme }) => theme.colors.green};
      }
    }
    &--keyboard-selected {
      background-color: transparent;
      border: 1px solid;
      background-color: ${({ theme }) => theme.colors.white} !important;
      border-color: ${({ theme }) => theme.colors.green} !important;
      color: ${({ theme }) => theme.colors.green} !important;
      font-weight: 600;

      & .datepicker-day-number {
        color: ${({ theme }) => theme.colors.green};
      }
    }

    &.available-day {
      background-color: ${({ theme }) => theme.colors.white} !important;
      border: 1px solid;
      border-color: ${({ theme }) => theme.colors.borderInputDefault};
      color: ${({ theme }) => theme.colors.black};
      font-weight: 600;
      & * {
        color: ${({ theme }) => theme.colors.black};
      }
    }
    &--selected-reschadule.available-day {
      background-color: transparent;
      border: 1px solid;
      background-color: ${({ theme }) => theme.colors.green} !important;
      border-color: ${({ theme }) => theme.colors.green} !important;
      color: ${({ theme }) => theme.colors.white} !important;
      font-weight: 600;

      & .datepicker-day-number {
        color: ${({ theme }) => theme.colors.white};
      }
    }
    &--today {
      color: ${({ theme }) => theme.colors.green};
      & * {
        color: ${({ theme }) => theme.colors.green};
      }
    }
    &--in-range {
      position: relative;
      color: ${({ theme }) => theme.colors.black};
      background-color: transparent;
      & * {
        color: ${({ theme }) => theme.colors.black};
      }
      &:after {
        content: "";
        position: absolute;
        width: calc(100% + 10px);
        height: 100%;
        top: 0;
        border-top: 1px solid;
        border-bottom: 1px solid;
        border-color: ${({ theme }) => theme.colors.green};
      }
      &.react-datepicker__day--keyboard-selected:not(
          .react-datepicker__day--range-end
        ):not(.react-datepicker__day--range-start) {
        border: none !important;
        background-color: transparent !important;
        font-weight: initial;
        color: ${({ theme }) => theme.colors.black} !important;
        & * {
          color: ${({ theme }) => theme.colors.black} !important;
        }
      }
    }
    &--in-selecting-range:not(.react-datepicker__day--selecting-range-end):not(
        .react-datepicker__day--selecting-range-start
      ) {
      background-color: ${({ theme }) => theme.colors.green};
      color: ${({ theme }) => theme.colors.white} !important;
      opacity: 0.5;
      & * {
        color: ${({ theme }) => theme.colors.white} !important;
      }
      &.react-datepicker__day--keyboard-selected {
        color: ${({ theme }) => theme.colors.black} !important;
        & * {
          color: ${({ theme }) => theme.colors.black} !important;
        }
      }
    }
    &--range-start {
      position: relative;
      border: none;
      color: ${({ theme }) => theme.colors.white} !important;
      background-color: ${({ theme }) => theme.colors.green} !important;
      & * {
        color: ${({ theme }) => theme.colors.white};
      }
      &.react-datepicker__day--range-end:after {
        display: none;
      }
      &:not(.react-datepicker__day--range-end):after {
        width: 70%;
        right: -10px;
      }
    }
    &--range-end {
      position: relative;
      border: none;
      color: ${({ theme }) => theme.colors.white} !important;
      background-color: ${({ theme }) => theme.colors.green} !important;
      & * {
        color: ${({ theme }) => theme.colors.white};
      }
      &.react-datepicker__day--range-start:after {
        display: none;
      }
      &:not(.react-datepicker__day--range-start):after {
        width: 70%;
        left: -10px;
      }
    }
  }

  & .react-datepicker__day-name {
    color: ${({ theme }) => theme.colors.grayC4};
  }

  & .react-datepicker__month {
    margin: 0;
    padding-bottom: 20px;
  }
`;

export const DatePickerDay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const DatePickerDayDropdown = styled.div`
  width: 150px;
  max-height: 150px;
  position: absolute;
  top: 100%;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.borderInputDefault};
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 1;
  border-radius: 5px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const DatePickerDayDropdownItem = styled.div<{ isSelected: boolean }>`
  display: flex;
  width: 100%;
  padding: 4px 10px;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme, isSelected }) =>
    theme.colors[isSelected ? "greenHover" : "white"]};
  & * {
    color: ${({ theme, isSelected }) =>
      theme.colors[isSelected ? "green" : "black"]} !important;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.greenHover};
  }
`;

export const DatePickerDayDropdownItemTime = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: left;
`;

export const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  width: 100%;
  gap: 15px;
  margin-top: 36px;
`;

export const ButtonStyled = styled(Button)`
  padding: 15px 35px;
  font-size: 20px;
  line-height: 100%;
  max-width: unset;
  width: fit-content;
`;

export const EmptyTextStyled = styled.div`
  width: 100%;
  padding: 15px;
  color: ${({ theme }) => theme.colors.gray};
`;
