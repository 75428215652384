import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  SortingOrders,
  TemplateEntityResponse,
  TemplateSortFields,
  useFindAndCountManyTemplatesByClientLazyQuery,
} from "src/graphql/generated";
import { usePagination } from "src/hooks/usePagination";
import { PaginationComponent } from "src/components/ReviewComponents";
import EmptyText from "src/components/EmptyText/EmptyText";
import { EditIcon } from "src/UI/Svg";
import { ScreenLoader } from "src/UI/ScreenLoader";
import { useClinicsState } from "src/store/clinics/hooks";
import { defaultPageLimit } from "src/constants/constants";
import { useTemplatesAction } from "src/store/templates/hooks";
import { getFormatDateByLocale } from "src/utils";
import { Actions, ButtonIcon, Table, Wrapper } from "./styled";

interface ITemplatesTableViewProps {
  isShowCurrent: boolean;
}

function TemplatesTableView({ isShowCurrent }: ITemplatesTableViewProps) {
  const { t, i18n } = useTranslation();
  const { currentClinicId: clinicId } = useClinicsState();
  const { onSetSelectedTemplate, onResetSelectedTemplate } =
    useTemplatesAction();
  const navigate = useNavigate();

  const [getTemplates, { data, loading }] =
    useFindAndCountManyTemplatesByClientLazyQuery({
      fetchPolicy: "no-cache",
    });

  const { offset, currentPage, pages, setCurrentPage } = usePagination({
    limit: defaultPageLimit,
    count: data?.findAndCountManyTemplatesByClient.count || 0,
  });

  useEffect(() => {
    if (clinicId) {
      getTemplates({
        variables: {
          schema: {
            limit: defaultPageLimit,
            clinicId,
            offset,
            onlyCurrent: isShowCurrent,
            sortField: TemplateSortFields.CreatedAt,
            sortOrder: SortingOrders.Descending,
          },
        },
      });
    }
  }, [clinicId, offset, isShowCurrent]);

  useEffect(() => {
    onResetSelectedTemplate();
  }, []);

  useEffect(() => {
    setCurrentPage(0);
  }, [isShowCurrent]);

  const handleEditClick = (template: TemplateEntityResponse) => () => {
    onSetSelectedTemplate(template);
    navigate("/cp/templates/actions");
  };

  const isFormsExist =
    !!data?.findAndCountManyTemplatesByClient?.templates?.length;

  return (
    <Wrapper>
      {loading && !isFormsExist && <ScreenLoader />}

      {!loading && !isFormsExist && (
        <EmptyText text={t("templates.table.no_templates")} />
      )}

      {isFormsExist && (
        <>
          <Table>
            <thead>
              <tr>
                <th>{t("templates.headers.title")}</th>
                <th>{t("templates.headers.total_sms_sent")}</th>
                <th>{t("templates.headers.total_email_sent")}</th>
                <th>{t("templates.headers.edit_date")}</th>
                <th>{t("templates.headers.create_date")}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {data?.findAndCountManyTemplatesByClient?.templates.map(
                (item) => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.smsSent}</td>
                    <td>{item.mailSent}</td>
                    <td>
                      {getFormatDateByLocale(
                        item.updatedAt,
                        i18n?.resolvedLanguage,
                        i18n?.resolvedLanguage !== "pl"
                          ? "d MMM yyyy 'at' h:mm a"
                          : "d MMM yyyy HH:mm"
                      )}
                    </td>
                    <td>
                      {getFormatDateByLocale(
                        item.createdAt,
                        i18n?.resolvedLanguage,
                        i18n?.resolvedLanguage !== "pl"
                          ? "d MMM yyyy 'at' h:mm a"
                          : "d MMM yyyy HH:mm"
                      )}
                    </td>
                    <td>
                      <Actions>
                        <ButtonIcon onClick={handleEditClick(item)}>
                          <EditIcon />
                        </ButtonIcon>
                      </Actions>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>
          <PaginationComponent
            pages={pages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </>
      )}
    </Wrapper>
  );
}

export default TemplatesTableView;
