import { useState } from "react";
import { MessageComponent } from "src/components/MessengerComponents";

import {
  FindAndCountManySmsByClientQuery,
  SmsSortFields,
  SortingOrders,
  useFindAndCountManySmsByClientQuery,
  useFindManyFilesByIdsQuery,
} from "src/graphql/generated";
import { useAuthContext } from "src/context/AuthContext";
import { useMessengerState } from "src/store/messenger/hooks";
import { useTranslation } from "react-i18next";
import { useFetchMore } from "src/hooks/useFetchMore";
import { useClinicsState } from "src/store/clinics/hooks";
import { Loading } from "src/UI/Loading";
import { defaultSmsLimit } from "src/constants/constants";
import { LoadingWrap, Wrapper } from "./styled";

interface IMessageListViewProps {
  messages: FindAndCountManySmsByClientQuery;
  fetchMore: (
    options: Parameters<
      ReturnType<typeof useFindAndCountManySmsByClientQuery>["fetchMore"]
    >[0]
  ) => Promise<unknown>;
}

function MessageListView({ messages, fetchMore }: IMessageListViewProps) {
  const { i18n } = useTranslation();
  const { currentUserData } = useAuthContext();
  const { selectedChat } = useMessengerState();
  const { currentClinicId: clinicId } = useClinicsState();
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);

  useFindManyFilesByIdsQuery({
    variables: {
      schema: {
        ids: [currentUserData?.avatarFileId as string],
      },
    },
    onCompleted(response) {
      setAvatarUrl(response?.findManyFilesByIds?.files?.[0]?.url);
    },
    skip: !currentUserData?.avatarFileId,
  });

  const { node, isLoading } = useFetchMore(() =>
    fetchMore({
      variables: {
        schema: {
          patientId: selectedChat?.id as string,
          clinicId,
          limit: defaultSmsLimit,
          offset: messages?.findAndCountManySmsByClient?.sms.length,
          sortField: SmsSortFields.CreatedAt,
          sortOrder: SortingOrders.Descending,
        },
      },
    })
  );

  return (
    <Wrapper>
      {messages?.findAndCountManySmsByClient?.sms.map((message) => (
        <MessageComponent
          key={message.id}
          message={message}
          avatarUrl={avatarUrl}
          selectedChat={selectedChat}
          resolvedLanguage={i18n.resolvedLanguage}
        />
      ))}
      {isLoading ? (
        <LoadingWrap>
          <Loading padding="0" />
        </LoadingWrap>
      ) : null}
      {messages?.findAndCountManySmsByClient?.count >
      messages?.findAndCountManySmsByClient?.sms.length ? (
        <div ref={node} />
      ) : null}
    </Wrapper>
  );
}

export default MessageListView;
