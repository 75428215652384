import React from "react";
import styled from "styled-components";

const InputBlock = styled.input`
  display: block;
  width: 100%;
  outline: none;
  border: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.black};

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray};
  }
`;

function PlainInput({ ...props }) {
  return <InputBlock type="text" {...props} />;
}

export default PlainInput;
