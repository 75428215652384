import { useTranslation } from "react-i18next";
import { EButtonVariants } from "src/UI/Button";
import {
  AggregatedPatientEntityResponse,
  FormAnswerEntityWithFormResponse,
} from "src/graphql/generated";
import { getFormatDateByLocale } from "src/utils";
import { PatientRow, StyledButton } from "./styled";

interface IPatientTableViewRowProps {
  answer: FormAnswerEntityWithFormResponse;
  patient: AggregatedPatientEntityResponse;
  formName: string;
  isFirst?: boolean;
  isSelected?: boolean;
  onSelectPatient: (patient: AggregatedPatientEntityResponse) => void;
}

const FormPatientTableViewRow = ({
  answer,
  patient,
  formName,
  isFirst,
  isSelected,
  onSelectPatient,
}: IPatientTableViewRowProps) => {
  const { i18n } = useTranslation();
  const handleSelectPatient = () => {
    onSelectPatient(patient);
  };

  return (
    <PatientRow $isSelected={isSelected}>
      <td>
        {isFirst ? (
          <StyledButton
            variant={EButtonVariants.Text}
            onClick={handleSelectPatient}
          >
            {patient.firstName} {patient.lastName}
          </StyledButton>
        ) : (
          ""
        )}
      </td>

      <td>
        {getFormatDateByLocale(
          answer.createdAt,
          i18n?.resolvedLanguage,
          i18n?.resolvedLanguage !== "pl"
            ? "d MMM yyyy 'at' h:mm a"
            : "d MMM yyyy HH:mm"
        )}
      </td>

      <td>{formName}</td>
    </PatientRow>
  );
};

export default FormPatientTableViewRow;
