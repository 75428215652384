import { gql } from "@apollo/client";

export const CALCULATE_CLINIC_CONSTRAINTS_BY_DOCTOR = gql`
  query CalculateClinicConstraintsByDoctor(
    $schema: CalculateClinicConstraintsByClientSchema!
  ) {
    calculateClinicConstraintsByDoctor(schema: $schema) {
      mailSent
      mailLimit
      smsSent
      smsLimit
    }
  }
`;
