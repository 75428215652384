import React from "react";
import { Route, Routes } from "react-router-dom";
import RegistrationCompanyPage from "./RegistrationCompanyPage";
import Create2FAPage from "./Create2FAPage";
import FAPage from "./FAPage";
import NotFoundPage from "../DashboardPages/NotFoundPage";
import LoginPage from "../AuthPages/LoginPage";

function VerifyPage() {
  return (
    <Routes>
      <Route
        path="/registration/company"
        element={<RegistrationCompanyPage />}
      />
      <Route path="/bind-verified" element={<Create2FAPage />} />
      <Route path="/2factor" element={<FAPage />} />
      <Route path="/add-account" element={<LoginPage />} />

      <Route key="NotFound" path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default VerifyPage;
