import { gql } from "@apollo/client";

export const DELETE_CLINIC_WORKING_DAY_BY_ID = gql`
  mutation DeleteClinicWorkingDayById(
    $schema: DeleteClinicWorkingDayByIdSchema!
  ) {
    deleteClinicWorkingDayById(schema: $schema) {
      deleted
    }
  }
`;
