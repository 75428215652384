import styled from "styled-components";

export const Form = styled.form`
  display: contents;
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
`;
