import React, { useEffect, useRef, useState } from "react";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTheme } from "styled-components";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorMessage, InputBlock, Label } from "./styled";

function Input({ name, value, label, errorMessage = "" }: any) {
  const {
    formState: { errors },
    trigger,
    setValue,
  } = useFormContext();
  const { colors } = useTheme();
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const { t } = useTranslation();

  const wrapper = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!isFocused && value) {
      setIsFocused(!!value);
    }
  }, [value, isFocused]);

  const handleChange = (val: string) => {
    setValue(name, val);
    trigger(name);
  };

  const onMount = (val: string) => {
    setValue(name, val);
  };

  return (
    <InputBlock ref={wrapper} isError={!!errors?.[name]?.message}>
      {(errors?.[name]?.message || errorMessage.message) && (
        <ErrorMessage>
          {t(errors?.[name]?.message || errorMessage.message) as string}
        </ErrorMessage>
      )}
      <Label htmlFor={name}>{label}</Label>
      <PhoneInput
        country="pl"
        onlyCountries={["us", "pl"]}
        countryCodeEditable={false}
        value={value}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onMount={onMount}
        onChange={handleChange}
        placeholder={label}
        inputProps={{
          name,
          errors: errors?.[name],
          required: true,
        }}
        inputStyle={{
          width: "100%",
          border: "none",
          outline: "none",
          fontWeight: 500,
          fontSize: 16,
          lineHeight: "140%",
          color: colors.black,
          borderBottom: `1px solid ${colors.borderInputDefault}`,
          padding: `16px 0 16px 26px`,
          borderRadius: 0,
          height: `100%`,
          background: "transparent",
        }}
        buttonClass="phone-button"
      />
    </InputBlock>
  );
}

export default Input;
