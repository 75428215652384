import { gql } from "@apollo/client";

export const GET_FIND_AND_COUNT_MANY_PATIENTS_BY_DOCTOR = gql`
  query FindAndCountManyPatientsByDoctor(
    $schema: FindAndCountManyPatientsByDoctorSchema!
  ) {
    findAndCountManyPatientsByDoctor(schema: $schema) {
      patients {
        id
        phoneNumber
        firstName
        lastName
        email
        dateOfBirth
        gender
        companyId
        comment
        emailDistribution
        smsDistribution
        activated
        passportNumber
        createdAt
        status
        languageISOCode
      }
      count
    }
  }
`;
