import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CheckBox, NewVariantButton, RowInput } from "../styled";

interface IFormComponentCheckboxNewProps {
  translation: string;
  order: number;
}

function FormComponentCheckboxNew({
  translation,
  order,
}: IFormComponentCheckboxNewProps) {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext();

  const valueCurrentLang = watch(`fields.${order}.translations.0.value`);
  const valueOptionalLang = watch(`fields.${order}.translations.1.value`);

  const newCheckboxHandler =
    (checkboxType: string) => (e: React.MouseEvent) => {
      e.stopPropagation();
      if (Array.isArray(valueCurrentLang)) {
        setValue(`fields.${order}.translations.0.value`, [
          ...valueCurrentLang,
          ...[
            { type: checkboxType, order: valueCurrentLang?.length, text: "" },
          ],
        ]);
      }

      if (Array.isArray(valueOptionalLang)) {
        setValue(`fields.${order}.translations.1.value`, [
          ...valueOptionalLang,
          { type: checkboxType, order: valueOptionalLang?.length, text: "" },
        ]);
      }
    };

  return (
    <RowInput>
      <CheckBox />
      <NewVariantButton onClick={newCheckboxHandler("checkbox")}>
        {t("forms.modals.checkbox.add", {
          lng: translation,
        })}

        <span onClick={newCheckboxHandler("another")}>
          {t("forms.modals.checkbox.another", {
            lng: translation,
          })}
        </span>
      </NewVariantButton>
    </RowInput>
  );
}

export default FormComponentCheckboxNew;
