import { useTranslation } from "react-i18next";
import { format, isSameDay } from "date-fns";
import { Loading } from "src/UI/Loading";
import ReactDatePicker from "react-datepicker";
import { SlotsGroupedByDateResponse } from "src/graphql/generated";
import {
  getDateWithoutTimeZoneOffset,
  getMinutesFromStartOfDayWithoutTimeZoneOffset,
} from "src/utils";
import { FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import { EButtonVariants } from "src/UI/Button";
import { useBookingState } from "src/store/booking/hooks";
import {
  ActionsWrapStyled,
  ButtonBlock,
  ButtonStyled,
  DatePickerDay,
  DatePickerDayDropdown,
  DatePickerDayDropdownItem,
  DatePickerDayDropdownItemTime,
  DatePickerWrapper,
  EmptyTextStyled,
  TimeBlockLabel,
  TimeBlockWrapper,
  TimeScheduleItem,
  TimeScheduleWrapper,
} from "./styled";

interface Props {
  slots: SlotsGroupedByDateResponse[];
  timeTo: Date;
  timeFrom: Date;
  loading: boolean;
  dateFilter: Date;
  buttonLoading: boolean;
  isScheduleProvided: boolean;
  setDateFilter: (date: Date) => void;
}

const AddVisitDatePicker: FC<Props> = ({
  slots,
  timeTo,
  timeFrom,
  loading,
  dateFilter,
  buttonLoading,
  isScheduleProvided,
  setDateFilter,
}) => {
  const { t } = useTranslation();

  const { watch, setValue } = useFormContext();
  const dataForm = watch();

  const { rescheduleAppointment } = useBookingState();

  const isReschedule = !!rescheduleAppointment;

  const [showDropdown, setShowDropdown] = useState<{
    open: boolean;
    date: Date | null;
  }>({
    open: isReschedule ? false : true,
    date: new Date(dataForm.date as string),
  });

  const availableDays = slots?.map((day) => new Date(day.date)) || [];

  const isDisabled =
    typeof dataForm.minuteFrom !== "number" ||
    typeof dataForm.minuteTo !== "number" ||
    loading ||
    buttonLoading;

  return (
    <>
      {typeof dataForm.minuteFrom === "number" &&
        typeof dataForm.minuteTo === "number" &&
        dataForm.date && (
          <TimeBlockWrapper>
            <TimeBlockLabel>{t("modals.patient_settings.time")}</TimeBlockLabel>
            <TimeScheduleWrapper>
              <TimeScheduleItem>{format(timeFrom, "HH:mm")}</TimeScheduleItem>
              <span>-</span>
              <TimeScheduleItem>{format(timeTo, "HH:mm")}</TimeScheduleItem>
            </TimeScheduleWrapper>
          </TimeBlockWrapper>
        )}
      {loading ? (
        <Loading />
      ) : (
        <ActionsWrapStyled>
          {isScheduleProvided ? (
            <>
              <DatePickerWrapper>
                <ReactDatePicker
                  selected={new Date(dateFilter) || null}
                  onMonthChange={(date) => {
                    setDateFilter(date);
                  }}
                  onChange={(date) => {
                    setDateFilter(date[0] as unknown as Date);
                  }}
                  renderDayContents={(dayNumber, date) => {
                    const currentDaySlots = slots?.find((day) =>
                      isSameDay(date || dayNumber, new Date(day.date))
                    );

                    const currentTime = new Date();

                    const availableSlots = currentDaySlots?.slots?.filter(
                      (slot) => {
                        const slotFrom = getDateWithoutTimeZoneOffset(
                          slot.from
                        );
                        return new Date(slotFrom) > new Date(currentTime);
                      }
                    );

                    const isSelectedDaySlots =
                      dataForm.date &&
                      isSameDay(date || dayNumber, new Date(dataForm.date));
                    const isCurrentDay =
                      showDropdown.open &&
                      showDropdown.date &&
                      isSameDay(date || dayNumber, showDropdown.date);

                    return (
                      <DatePickerDay
                        onClick={() => {
                          setShowDropdown((prew) => ({
                            open: !prew.open,
                            date: date as Date | null,
                          }));
                        }}
                        onMouseLeave={() =>
                          setShowDropdown({ open: false, date: null })
                        }
                      >
                        <div className="datepicker-day-number">{dayNumber}</div>
                        {isCurrentDay && (
                          <DatePickerDayDropdown className="datepicker-dropdown">
                            {availableSlots?.length ? (
                              availableSlots?.map((slot) => {
                                const isSelected =
                                  !!isSelectedDaySlots &&
                                  getMinutesFromStartOfDayWithoutTimeZoneOffset(
                                    slot?.from
                                  ) === dataForm.minuteFrom &&
                                  getMinutesFromStartOfDayWithoutTimeZoneOffset(
                                    slot?.to
                                  ) === dataForm.minuteTo;

                                return (
                                  <DatePickerDayDropdownItem
                                    key={slot?.from}
                                    isSelected={!!isSelected}
                                    onClick={() => {
                                      setValue("date", currentDaySlots?.date);
                                      setValue(
                                        "minuteFrom",
                                        getMinutesFromStartOfDayWithoutTimeZoneOffset(
                                          slot?.from
                                        )
                                      );
                                      setValue(
                                        "minuteTo",
                                        getMinutesFromStartOfDayWithoutTimeZoneOffset(
                                          slot?.to
                                        )
                                      );
                                    }}
                                  >
                                    <DatePickerDayDropdownItemTime>
                                      {format(
                                        getDateWithoutTimeZoneOffset(
                                          slot?.from
                                        ),
                                        "HH:mm"
                                      )}
                                    </DatePickerDayDropdownItemTime>

                                    <span>-</span>

                                    <DatePickerDayDropdownItemTime>
                                      {format(
                                        getDateWithoutTimeZoneOffset(slot?.to),
                                        "HH:mm"
                                      )}
                                    </DatePickerDayDropdownItemTime>
                                  </DatePickerDayDropdownItem>
                                );
                              })
                            ) : (
                              <EmptyTextStyled>
                                {t("app.not_found")}
                              </EmptyTextStyled>
                            )}
                          </DatePickerDayDropdown>
                        )}
                      </DatePickerDay>
                    );
                  }}
                  dayClassName={(date) => {
                    const availableDay = availableDays.some((availableDate) =>
                      isSameDay(date, new Date(availableDate))
                    );

                    const isSelectedReschaduleDay = isSameDay(
                      date,
                      new Date(rescheduleAppointment?.schedule.dateFrom)
                    );

                    return availableDay
                      ? `available-day ${
                          isSelectedReschaduleDay &&
                          "react-datepicker__day--selected-reschadule"
                        }`
                      : null;
                  }}
                  selectsRange
                  minDate={new Date()}
                  inline
                />
              </DatePickerWrapper>

              <ButtonBlock>
                <ButtonStyled
                  variant={EButtonVariants.Default}
                  disabled={isDisabled}
                >
                  {buttonLoading && <Loading type="secondary" padding="0" />}
                  {isReschedule
                    ? t("calendar.event.reshedule")
                    : t("calendar.event.title")}
                </ButtonStyled>
              </ButtonBlock>
            </>
          ) : (
            <EmptyTextStyled>{t("app.schedule_empty_text")}</EmptyTextStyled>
          )}
        </ActionsWrapStyled>
      )}
    </>
  );
};

export default AddVisitDatePicker;
