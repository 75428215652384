import styled from "styled-components";
import { Button } from "src/UI/Button";
import { TemplatesComponentWrapper } from "../styled";

export const StyledTemplatesComponentWrapper = styled(
  TemplatesComponentWrapper
)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
`;

export const SaveActionsButton = styled(Button)`
  padding: 15px 20px;
  font-size: 20px;
  line-height: 100%;
  width: fit-content;
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 16px;
    padding: 12px 16px;
  }
`;
