import { gql } from "@apollo/client";

export const FIND_SCHEDULE_APPOINTMENT_WITH_RELATIONS_BY_HASH_BY_PATIENT = gql`
  query FindScheduleAppointmentWithRelationsByHashByPatient(
    $schema: FindScheduleAppointmentWithRelationsByHashByPatientSchema!
  ) {
    findScheduleAppointmentWithRelationsByHashByPatient(schema: $schema) {
      clinic {
        name
        id
        logoFileId
        address
        phoneNumber
      }
      schedule {
        dateFrom
        dateTo
        minuteFrom
        minuteTo
        doctorId
      }
      status
      patient {
        phoneNumber
        languageISOCode
      }
      doctor {
        firstName
        lastName
      }
      service {
        id
      }
      client {
        id
      }
      languageISOCode
    }
  }
`;
