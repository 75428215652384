/* eslint-disable */
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Upload: any;
};

export type AddPinCodeResponse = {
  __typename?: "AddPINCodeResponse";
  added: Scalars["Boolean"];
};

export type AddPinCodeSchema = {
  pinCode: Scalars["String"];
};

export type AdminLocalSignInResponse = {
  __typename?: "AdminLocalSignInResponse";
  signedIn: Scalars["Boolean"];
  twoFactorAuthenticationEnabled: Scalars["Boolean"];
};

export type AdminLocalSignInSchema = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type AggregatedDoctorEntitiesResponse = {
  __typename?: "AggregatedDoctorEntitiesResponse";
  doctors: Array<AggregatedDoctorEntityResponse>;
};

export type AggregatedDoctorEntityResponse = {
  __typename?: "AggregatedDoctorEntityResponse";
  activated: Scalars["Boolean"];
  address?: Maybe<Scalars["String"]>;
  avatarFileId?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  dateOfBirth?: Maybe<Scalars["DateTime"]>;
  description?: Maybe<Scalars["String"]>;
  doctorCreatedAt: Scalars["DateTime"];
  doctorId: Scalars["String"];
  doctorTypeId: Scalars["String"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  id: Scalars["String"];
  lastName: Scalars["String"];
  medicalCertificateNumber: Scalars["String"];
  passportNumber?: Maybe<Scalars["String"]>;
  phoneNumber: Scalars["String"];
  promoCode?: Maybe<Scalars["String"]>;
  roles: Array<UserRoles>;
};

export type AggregatedFilledFormEntityResponse = {
  __typename?: "AggregatedFilledFormEntityResponse";
  answers: Array<AggregatedFormAnswerEntityResponse>;
  clinicId: Scalars["String"];
  fields: Array<FormFieldEntityWithRelationsResponse>;
  id: Scalars["String"];
  name: Scalars["String"];
  serviceId?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

export type AggregatedFormAnswerEntityResponse = {
  __typename?: "AggregatedFormAnswerEntityResponse";
  answers?: Maybe<Array<FormFieldAnswerResponse>>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  entityId?: Maybe<Scalars["String"]>;
  entityType?: Maybe<FormAnswerEntityTypes>;
  formId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  patient: PatientEntityResponse;
  patientId?: Maybe<Scalars["String"]>;
};

export type AggregatedPatientEntityResponse = {
  __typename?: "AggregatedPatientEntityResponse";
  activated: Scalars["Boolean"];
  address?: Maybe<Scalars["String"]>;
  allergens?: Maybe<Scalars["String"]>;
  allergic?: Maybe<Scalars["Boolean"]>;
  answers: Array<FormAnswerEntityWithFormResponse>;
  comment?: Maybe<Scalars["String"]>;
  companyId: Scalars["String"];
  createdAt: Scalars["DateTime"];
  dateOfBirth?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  emailDistribution: Scalars["Boolean"];
  firstName: Scalars["String"];
  gender?: Maybe<PatientGenders>;
  id: Scalars["String"];
  languageISOCode: Scalars["String"];
  lastName: Scalars["String"];
  passportNumber?: Maybe<Scalars["String"]>;
  phoneNumber: Scalars["String"];
  postCode?: Maybe<Scalars["String"]>;
  smsDistribution: Scalars["Boolean"];
  status: PatientStatuses;
};

export type AggregatedScheduleAppointmentEntityWithRelationsByClientResponse = {
  __typename?: "AggregatedScheduleAppointmentEntityWithRelationsByClientResponse";
  answer?: Maybe<FormAnswerEntityWithRelationsResponse>;
  clinic: ClinicEntityResponse;
  createdAt: Scalars["DateTime"];
  doctor: AggregatedDoctorEntityResponse;
  id: Scalars["String"];
  patient: PatientEntityResponse;
  patientId: Scalars["String"];
  room?: Maybe<ClinicRoomEntityResponse>;
  schedule: ScheduleEntityResponse;
  scheduleId: Scalars["String"];
  service?: Maybe<ServiceEntityResponse>;
  status: AppointmentStatuses;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type AggregatedScheduleAppointmentEntityWithRelationsByDoctorResponse = {
  __typename?: "AggregatedScheduleAppointmentEntityWithRelationsByDoctorResponse";
  answer?: Maybe<FormAnswerEntityWithRelationsResponse>;
  clinic: ClinicEntityResponse;
  createdAt: Scalars["DateTime"];
  id: Scalars["String"];
  patient: PatientEntityResponse;
  patientId: Scalars["String"];
  room?: Maybe<ClinicRoomEntityResponse>;
  schedule: ScheduleEntityResponse;
  scheduleId: Scalars["String"];
  service?: Maybe<ServiceEntityResponse>;
  status: AppointmentStatuses;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type AggregatedScheduleAppointmentEntityWithRelationsByPatientResponse =
  {
    __typename?: "AggregatedScheduleAppointmentEntityWithRelationsByPatientResponse";
    answer?: Maybe<FormAnswerEntityResponse>;
    client: ClientEntityResponse;
    clinic: ClinicEntityResponse;
    createdAt: Scalars["DateTime"];
    doctor: AggregatedDoctorEntityResponse;
    id: Scalars["String"];
    languageISOCode: LanguageIsoCodes;
    patient: PatientEntityResponse;
    patientId: Scalars["String"];
    schedule: ScheduleEntityResponse;
    scheduleId: Scalars["String"];
    service: ServiceEntityResponse;
    status: AppointmentStatuses;
    updatedAt?: Maybe<Scalars["DateTime"]>;
  };

export type AggregatedScheduleWaitlistEntityByClientResponse = {
  __typename?: "AggregatedScheduleWaitlistEntityByClientResponse";
  clinicId: Scalars["String"];
  createdAt: Scalars["DateTime"];
  dateFrom: Scalars["String"];
  dateTo: Scalars["String"];
  doctor?: Maybe<AggregatedDoctorEntityResponse>;
  doctorId?: Maybe<Scalars["String"]>;
  hash: Scalars["String"];
  id: Scalars["String"];
  minuteFrom: Scalars["Int"];
  minuteTo: Scalars["Int"];
  patient: PatientEntityResponse;
  patientId: Scalars["String"];
  service: ServiceEntityResponse;
  serviceId: Scalars["String"];
  status: WaitlistStatuses;
  uncommittedPatientId?: Maybe<Scalars["String"]>;
};

export type AggregatedScheduleWaitlistEntityByPatientResponse = {
  __typename?: "AggregatedScheduleWaitlistEntityByPatientResponse";
  answer?: Maybe<FormAnswerEntityResponse>;
  client: ClientEntityResponse;
  clinic: ClinicEntityResponse;
  clinicId: Scalars["String"];
  createdAt: Scalars["DateTime"];
  dateFrom: Scalars["String"];
  dateTo: Scalars["String"];
  doctor: AggregatedDoctorEntityResponse;
  doctorId?: Maybe<Scalars["String"]>;
  hash: Scalars["String"];
  id: Scalars["String"];
  languageISOCode: LanguageIsoCodes;
  minuteFrom: Scalars["Int"];
  minuteTo: Scalars["Int"];
  patient: PatientEntityResponse;
  patientId: Scalars["String"];
  service: ServiceEntityResponse;
  serviceId: Scalars["String"];
  status: WaitlistStatuses;
  uncommittedPatientId?: Maybe<Scalars["String"]>;
};

export type AggregatedSmsEntityResponse = {
  __typename?: "AggregatedSmsEntityResponse";
  clinic: ClinicEntityResponse;
  clinicId: Scalars["String"];
  clinicToPatient: Scalars["Boolean"];
  content: Scalars["String"];
  createdAt: Scalars["DateTime"];
  from: Scalars["String"];
  id: Scalars["String"];
  patient: PatientEntityResponse;
  patientId: Scalars["String"];
  to: Scalars["String"];
};

/** Enum of appointment sort fields */
export enum AppointmentSortFields {
  CreatedAt = "CreatedAt",
  ScheduleTimeFrom = "ScheduleTimeFrom",
  UpdatedAt = "UpdatedAt",
}

/** Enum of appointment statuses */
export enum AppointmentStatuses {
  Canceled = "Canceled",
  ConfirmedByApplication = "ConfirmedByApplication",
  ConfirmedByPatient = "ConfirmedByPatient",
  Missed = "Missed",
  New = "New",
  Successful = "Successful",
}

export type AttachDoctorToServiceResponse = {
  __typename?: "AttachDoctorToServiceResponse";
  attached: Scalars["Boolean"];
};

export type AttachDoctorToServiceSchema = {
  doctorId: Scalars["String"];
  serviceId: Scalars["String"];
};

export type AuthorizeWith2FaResponse = {
  __typename?: "AuthorizeWith2FAResponse";
  authorized: Scalars["Boolean"];
};

export type AuthorizeWith2FaSchema = {
  code: Scalars["String"];
};

export type AuthorizeWithPinCodeResponse = {
  __typename?: "AuthorizeWithPINCodeResponse";
  authorized: Scalars["Boolean"];
};

export type AuthorizeWithPinCodeSchema = {
  pinCode: Scalars["String"];
};

export type AuthorizeWithPasswordResponse = {
  __typename?: "AuthorizeWithPasswordResponse";
  authorized: Scalars["Boolean"];
};

export type AuthorizeWithPasswordSchema = {
  password: Scalars["String"];
};

export type AutocompleteByClientSchema = {
  query: Scalars["String"];
};

export type AutocompleteByDoctorSchema = {
  query: Scalars["String"];
};

export type AutocompleteResultByClientResponse = {
  __typename?: "AutocompleteResultByClientResponse";
  result?: Maybe<Array<SearchByClientUnion>>;
};

export type AutocompleteResultByDoctorResponse = {
  __typename?: "AutocompleteResultByDoctorResponse";
  result?: Maybe<Array<SearchByDoctorUnion>>;
};

export type BlankFormEntityResponse = {
  __typename?: "BlankFormEntityResponse";
  clinicId: Scalars["String"];
  fields: Array<FormFieldEntityWithRelationsResponse>;
  id: Scalars["String"];
  name: Scalars["String"];
  serviceId?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

export type BlankFormWithCalculatedFieldsResponse = {
  __typename?: "BlankFormWithCalculatedFieldsResponse";
  clinicId: Scalars["String"];
  countAnswers: Scalars["Int"];
  id: Scalars["String"];
  name: Scalars["String"];
  serviceId?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

export type CalculateAverageScheduleAppointmentRateByClientSchema = {
  clinicId?: InputMaybe<Scalars["String"]>;
};

export type CalculateAverageScheduleAppointmentRateResponse = {
  __typename?: "CalculateAverageScheduleAppointmentRateResponse";
  rate: Scalars["Float"];
};

export type CalculateAverageScheduleAppointmentRateSchema = {
  clinicId?: InputMaybe<Scalars["String"]>;
};

export type CalculateClinicConstraintsByClientSchema = {
  clinicId?: InputMaybe<Scalars["String"]>;
};

export type CancelScheduleAppointmentByClientResponse = {
  __typename?: "CancelScheduleAppointmentByClientResponse";
  canceled: Scalars["Boolean"];
};

export type CancelScheduleAppointmentByClientSchema = {
  id: Scalars["String"];
};

export type CancelScheduleAppointmentByPatientResponse = {
  __typename?: "CancelScheduleAppointmentByPatientResponse";
  canceled: Scalars["Boolean"];
};

export type CancelScheduleAppointmentByPatientSchema = {
  hash: Scalars["String"];
};

export type ChangeEmailResponse = {
  __typename?: "ChangeEmailResponse";
  changed: Scalars["Boolean"];
};

export type ChangeEmailSchema = {
  changeHash: Scalars["String"];
};

export type CheckIfCompanyExistsByUserIdResponse = {
  __typename?: "CheckIfCompanyExistsByUserIdResponse";
  exists: Scalars["Boolean"];
};

export type ClientEntityResponse = {
  __typename?: "ClientEntityResponse";
  countryId: Scalars["String"];
  createdAt: Scalars["DateTime"];
  id: Scalars["String"];
  passportNumber?: Maybe<Scalars["String"]>;
  phoneNumber: Scalars["String"];
  promoCode?: Maybe<Scalars["String"]>;
  userId: Scalars["String"];
};

export type ClinicConstraintEntityResponse = {
  __typename?: "ClinicConstraintEntityResponse";
  clinicId: Scalars["String"];
  id: Scalars["String"];
  mailLimit: Scalars["Float"];
  mailSent: Scalars["Float"];
  smsLimit: Scalars["Float"];
  smsSent: Scalars["Float"];
};

export type ClinicConstraintResponse = {
  __typename?: "ClinicConstraintResponse";
  mailLimit: Scalars["Float"];
  mailSent: Scalars["Float"];
  smsLimit: Scalars["Float"];
  smsSent: Scalars["Float"];
};

export type ClinicEntityByAdminResponse = {
  __typename?: "ClinicEntityByAdminResponse";
  address: Scalars["String"];
  city: Scalars["String"];
  code?: Maybe<Scalars["String"]>;
  companyId: Scalars["String"];
  constraints?: Maybe<ClinicConstraintEntityResponse>;
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  geographicalCoordinates: ClinicGeographicalCoordinatesResponse;
  hiddenFromPatients: Scalars["Boolean"];
  houseNumber?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  index: Scalars["String"];
  licenseNumber?: Maybe<Scalars["String"]>;
  logoFileId?: Maybe<Scalars["String"]>;
  messagingServiceSid?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  phoneNumber: Scalars["String"];
  rooms?: Maybe<Array<ClinicRoomEntityResponse>>;
  workingDays?: Maybe<Array<ClinicWorkingDayEntityResponse>>;
};

export type ClinicEntityResponse = {
  __typename?: "ClinicEntityResponse";
  address: Scalars["String"];
  city: Scalars["String"];
  code?: Maybe<Scalars["String"]>;
  companyId: Scalars["String"];
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  geographicalCoordinates: ClinicGeographicalCoordinatesResponse;
  hiddenFromPatients: Scalars["Boolean"];
  houseNumber?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  index: Scalars["String"];
  licenseNumber?: Maybe<Scalars["String"]>;
  logoFileId?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  phoneNumber: Scalars["String"];
  rooms?: Maybe<Array<ClinicRoomEntityResponse>>;
  workingDays?: Maybe<Array<ClinicWorkingDayEntityResponse>>;
};

export type ClinicGeographicalCoordinatesResponse = {
  __typename?: "ClinicGeographicalCoordinatesResponse";
  latitude: Scalars["Float"];
  longitude: Scalars["Float"];
};

export type ClinicRoomEntityResponse = {
  __typename?: "ClinicRoomEntityResponse";
  clinicId: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

/** Enum of clinic sort fields */
export enum ClinicRoomSortFields {
  CreatedAt = "CreatedAt",
}

/** Enum of clinic sort fields */
export enum ClinicSortFields {
  CreatedAt = "CreatedAt",
  Name = "Name",
}

export type ClinicWorkingDayEntityResponse = {
  __typename?: "ClinicWorkingDayEntityResponse";
  clinicId: Scalars["String"];
  day: ClinicWorkingDays;
  fromMinute: Scalars["Float"];
  id: Scalars["String"];
  toMinute: Scalars["Float"];
};

/** Enum of clinic working days */
export enum ClinicWorkingDays {
  Friday = "Friday",
  Monday = "Monday",
  Saturday = "Saturday",
  Sunday = "Sunday",
  Thursday = "Thursday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
}

/** Enum of clinic sort fields */
export enum ClinicWorkingDaysSortFields {
  CreatedAt = "CreatedAt",
}

export type ClinicsTemplatesResponse = {
  __typename?: "ClinicsTemplatesResponse";
  clinicId: Scalars["String"];
  event: DistributionSystemEvents;
  templateId?: Maybe<Scalars["String"]>;
};

export type CloseScheduleWaitlistByClientResponse = {
  __typename?: "CloseScheduleWaitlistByClientResponse";
  closed: Scalars["Boolean"];
};

export type CloseScheduleWaitlistByClientSchema = {
  id: Scalars["String"];
};

export type CloseScheduleWaitlistByPatientResponse = {
  __typename?: "CloseScheduleWaitlistByPatientResponse";
  closed: Scalars["Boolean"];
};

export type CloseScheduleWaitlistByPatientSchema = {
  hash: Scalars["String"];
};

export type CompanyEntityResponse = {
  __typename?: "CompanyEntityResponse";
  address?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  countryId?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  dateOfRegistrationInKrs?: Maybe<Scalars["DateTime"]>;
  email?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  name: Scalars["String"];
  nip: Scalars["String"];
  ownerId: Scalars["String"];
  postCode?: Maybe<Scalars["String"]>;
  regon?: Maybe<Scalars["String"]>;
  street?: Maybe<Scalars["String"]>;
};

export type ConfirmMailResponse = {
  __typename?: "ConfirmMailResponse";
  confirmed: Scalars["Boolean"];
};

export type ConfirmMailSchema = {
  confirmationHash: Scalars["String"];
};

export type ConfirmScheduleAppointmentByClientResponse = {
  __typename?: "ConfirmScheduleAppointmentByClientResponse";
  confirmed: Scalars["Boolean"];
};

export type ConfirmScheduleAppointmentByClientSchema = {
  id: Scalars["String"];
};

export type ConfirmScheduleAppointmentByPatientResponse = {
  __typename?: "ConfirmScheduleAppointmentByPatientResponse";
  confirmed: Scalars["Boolean"];
};

export type ConfirmScheduleAppointmentByPatientSchema = {
  hash: Scalars["String"];
};

export type ConfirmScheduleWaitlistByPatientResponse = {
  __typename?: "ConfirmScheduleWaitlistByPatientResponse";
  confirmed: Scalars["Boolean"];
};

export type ConfirmScheduleWaitlistByPatientSchema = {
  hash: Scalars["String"];
};

export type ConvertScheduleWaitlistToScheduleAppointmentByPatientSchema = {
  date: Scalars["String"];
  hash: Scalars["String"];
  minuteFrom: Scalars["Int"];
  minuteTo: Scalars["Int"];
};

export type CountAndGroupScheduleAppointmentsByDateByClientSchema = {
  clinicId?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<AppointmentStatuses>;
};

export type CountAndGroupScheduleAppointmentsByDateByDoctorSchema = {
  clinicId?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<AppointmentStatuses>;
};

export type CountNewPatientsByClientSchema = {
  clinicId?: InputMaybe<Scalars["String"]>;
};

export type CountNewPatientsByDoctorSchema = {
  clinicId?: InputMaybe<Scalars["String"]>;
};

export type CountScheduleAppointmentsByStatusesByClientSchema = {
  clinicId?: InputMaybe<Scalars["String"]>;
  dateFrom?: InputMaybe<Scalars["String"]>;
  dateTo?: InputMaybe<Scalars["String"]>;
  doctorId?: InputMaybe<Scalars["String"]>;
  minuteFrom?: InputMaybe<Scalars["Int"]>;
  minuteTo?: InputMaybe<Scalars["Int"]>;
};

export type CountScheduleAppointmentsByStatusesByDoctorSchema = {
  clinicId?: InputMaybe<Scalars["String"]>;
  dateFrom?: InputMaybe<Scalars["String"]>;
  dateTo?: InputMaybe<Scalars["String"]>;
  minuteFrom?: InputMaybe<Scalars["Int"]>;
  minuteTo?: InputMaybe<Scalars["Int"]>;
};

export type CountScheduleAppointmentsCountByClientSchema = {
  status?: InputMaybe<AppointmentStatuses>;
};

export type CountedAggregatedDoctorEntitiesResponse = {
  __typename?: "CountedAggregatedDoctorEntitiesResponse";
  count: Scalars["Int"];
  doctors: Array<AggregatedDoctorEntityResponse>;
};

export type CountedAggregatedFilledFormsResponse = {
  __typename?: "CountedAggregatedFilledFormsResponse";
  count: Scalars["Int"];
  forms: Array<AggregatedFilledFormEntityResponse>;
};

export type CountedAggregatedPatientEntitiesResponse = {
  __typename?: "CountedAggregatedPatientEntitiesResponse";
  count: Scalars["Int"];
  patients: Array<AggregatedPatientEntityResponse>;
};

export type CountedAggregatedScheduleAppointmentEntitiesWithRelationsByClientResponse =
  {
    __typename?: "CountedAggregatedScheduleAppointmentEntitiesWithRelationsByClientResponse";
    appointments: Array<AggregatedScheduleAppointmentEntityWithRelationsByClientResponse>;
    count: Scalars["Int"];
  };

export type CountedAggregatedScheduleAppointmentEntitiesWithRelationsByDoctorResponse =
  {
    __typename?: "CountedAggregatedScheduleAppointmentEntitiesWithRelationsByDoctorResponse";
    appointments: Array<AggregatedScheduleAppointmentEntityWithRelationsByDoctorResponse>;
    count: Scalars["Int"];
  };

export type CountedAggregatedScheduleWaitlistEntitiesByClientResponse = {
  __typename?: "CountedAggregatedScheduleWaitlistEntitiesByClientResponse";
  count: Scalars["Int"];
  waitlists: Array<AggregatedScheduleWaitlistEntityByClientResponse>;
};

export type CountedAggregatedSmsEntitiesResponse = {
  __typename?: "CountedAggregatedSmsEntitiesResponse";
  count: Scalars["Int"];
  sms: Array<AggregatedSmsEntityResponse>;
};

export type CountedBlankFormEntitiesResponse = {
  __typename?: "CountedBlankFormEntitiesResponse";
  count: Scalars["Int"];
  forms: Array<BlankFormWithCalculatedFieldsResponse>;
};

export type CountedClinicsByAdminResponse = {
  __typename?: "CountedClinicsByAdminResponse";
  clinics: Array<ClinicEntityByAdminResponse>;
  count: Scalars["Float"];
};

export type CountedClinicsResponse = {
  __typename?: "CountedClinicsResponse";
  clinics: Array<ClinicEntityResponse>;
  count: Scalars["Float"];
};

export type CountedPatientsResponse = {
  __typename?: "CountedPatientsResponse";
  count: Scalars["Float"];
  patients: Array<PatientEntityResponse>;
};

export type CountedSmsEntitiesResponse = {
  __typename?: "CountedSmsEntitiesResponse";
  count: Scalars["Int"];
  sms: Array<SmsEntityResponse>;
};

export type CountedTemplatesResponse = {
  __typename?: "CountedTemplatesResponse";
  count: Scalars["Int"];
  templates: Array<TemplateEntityResponse>;
};

export type CountryEntityResponse = {
  __typename?: "CountryEntityResponse";
  id: Scalars["String"];
  isoAlpha2Code: Scalars["String"];
  isoAlpha3Code: Scalars["String"];
  name: Scalars["String"];
};

export type CreateClinicGeographicalCoordinatesSchema = {
  latitude: Scalars["Float"];
  longitude: Scalars["Float"];
};

export type CreateClinicRoomWithClinicIdSchema = {
  clinicId: Scalars["String"];
  name: Scalars["String"];
};

export type CreateClinicSchema = {
  address: Scalars["String"];
  city: Scalars["String"];
  code?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  geographicalCoordinates: CreateClinicGeographicalCoordinatesSchema;
  hiddenFromPatients: Scalars["Boolean"];
  houseNumber?: InputMaybe<Scalars["String"]>;
  index: Scalars["String"];
  licenseNumber?: InputMaybe<Scalars["String"]>;
  logoFileId?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  phoneNumber: Scalars["String"];
};

export type CreateClinicWorkingDayWithClinicIdSchema = {
  clinicId: Scalars["String"];
  day: ClinicWorkingDays;
  fromMinute: Scalars["Int"];
  toMinute: Scalars["Int"];
};

export type CreateCompanySchema = {
  address: Scalars["String"];
  name: Scalars["String"];
  nip: Scalars["String"];
};

export type CreateDoctorInvitationSchema = {
  address?: InputMaybe<Scalars["String"]>;
  clinicId: Scalars["String"];
  description?: InputMaybe<Scalars["String"]>;
  doctorTypeId: Scalars["String"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  languageISOCode: LanguageIsoCodes;
  lastName: Scalars["String"];
  medicalCertificateNumber: Scalars["String"];
  passportNumber?: InputMaybe<Scalars["String"]>;
  phoneNumber: Scalars["String"];
  promoCode?: InputMaybe<Scalars["String"]>;
};

export type CreateFormFieldAnswerSchema = {
  fieldId: Scalars["String"];
  order: Scalars["Int"];
  value?: InputMaybe<Scalars["String"]>;
};

export type CreateFormFieldSchema = {
  hiddenFromExistingPatients: Scalars["Boolean"];
  order: Scalars["Float"];
  required: Scalars["Boolean"];
  translations: Array<CreateFormFieldTranslationSchema>;
  type: FormFieldTypes;
  value?: InputMaybe<Scalars["String"]>;
};

export type CreateFormFieldTranslationSchema = {
  languageISOCode: LanguageIsoCodes;
  name: Scalars["String"];
  placeholder: Scalars["String"];
  value?: InputMaybe<Scalars["String"]>;
};

export type CreateFormSchema = {
  clinicId: Scalars["String"];
  fields: Array<CreateFormFieldSchema>;
  name: Scalars["String"];
  serviceId?: InputMaybe<Scalars["String"]>;
};

export type CreateMailTemplateSchema = {
  delayInHours: Scalars["Int"];
  languageISOCode: LanguageIsoCodes;
  subject: Scalars["String"];
  text: Scalars["String"];
};

export type CreatePatientSchema = {
  address?: InputMaybe<Scalars["String"]>;
  comment?: InputMaybe<Scalars["String"]>;
  dateOfBirth?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  emailDistribution: Scalars["Boolean"];
  firstName: Scalars["String"];
  gender?: InputMaybe<PatientGenders>;
  languageISOCode: LanguageIsoCodes;
  lastName: Scalars["String"];
  passportNumber?: InputMaybe<Scalars["String"]>;
  phoneNumber: Scalars["String"];
  postCode?: InputMaybe<Scalars["String"]>;
  smsDistribution: Scalars["Boolean"];
};

export type CreateScheduleAppointmentByClientSchema = {
  clinicId: Scalars["String"];
  date: Scalars["String"];
  doctorId: Scalars["String"];
  minuteFrom: Scalars["Int"];
  minuteTo: Scalars["Int"];
  patientId: Scalars["String"];
  roomId?: InputMaybe<Scalars["String"]>;
  serviceId: Scalars["String"];
};

export type CreateScheduleAppointmentByDoctorSchema = {
  clinicId: Scalars["String"];
  date: Scalars["String"];
  minuteFrom: Scalars["Int"];
  minuteTo: Scalars["Int"];
  patientId: Scalars["String"];
  roomId?: InputMaybe<Scalars["String"]>;
  serviceId: Scalars["String"];
};

export type CreateScheduleAppointmentByPatientResponse = {
  __typename?: "CreateScheduleAppointmentByPatientResponse";
  hash: Scalars["String"];
};

export type CreateScheduleAppointmentByPatientSchema = {
  clinicId: Scalars["String"];
  date: Scalars["String"];
  doctorId: Scalars["String"];
  formAnswer?: InputMaybe<CreateScheduleAppointmentFormAnswerByPatientSchema>;
  languageISOCode: LanguageIsoCodes;
  minuteFrom: Scalars["Int"];
  minuteTo: Scalars["Int"];
  patient: CreateScheduleAppointmentPatientByPatientSchema;
  roomId?: InputMaybe<Scalars["String"]>;
  serviceId: Scalars["String"];
  waitlistHash?: InputMaybe<Scalars["String"]>;
};

export type CreateScheduleAppointmentFormAnswerByPatientSchema = {
  answers: Array<CreateFormFieldAnswerSchema>;
  formId: Scalars["String"];
};

export type CreateScheduleAppointmentPatientByPatientSchema = {
  address: Scalars["String"];
  comment?: InputMaybe<Scalars["String"]>;
  dateOfBirth: Scalars["String"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  gender: PatientGenders;
  lastName: Scalars["String"];
  passportNumber: Scalars["String"];
  phoneNumber: Scalars["String"];
  postCode: Scalars["String"];
};

export type CreateScheduleSchema = {
  clinicId: Scalars["String"];
  dateFrom?: InputMaybe<Scalars["DateTime"]>;
  dateTo?: InputMaybe<Scalars["DateTime"]>;
  daysOfWeek?: InputMaybe<Array<DaysOfWeek>>;
  doctorId: Scalars["String"];
  eventType: EventTypes;
  layoutType: LayoutTypes;
  minuteFrom: Scalars["Int"];
  minuteTo: Scalars["Int"];
  roomId?: InputMaybe<Scalars["String"]>;
};

export type CreateScheduleWaitlistByClientSchema = {
  clinicId: Scalars["String"];
  dateFrom: Scalars["String"];
  dateTo: Scalars["String"];
  doctorId: Scalars["String"];
  minuteFrom: Scalars["Int"];
  minuteTo: Scalars["Int"];
  patientId: Scalars["String"];
  serviceId: Scalars["String"];
};

export type CreateScheduleWaitlistByPatientResponse = {
  __typename?: "CreateScheduleWaitlistByPatientResponse";
  hash: Scalars["String"];
};

export type CreateScheduleWaitlistByPatientSchema = {
  clinicId: Scalars["String"];
  dateFrom: Scalars["String"];
  dateTo: Scalars["String"];
  doctorId: Scalars["String"];
  formAnswer?: InputMaybe<CreateScheduleWaitlistFormAnswerByPatientSchema>;
  languageISOCode: LanguageIsoCodes;
  minuteFrom: Scalars["Int"];
  minuteTo: Scalars["Int"];
  patient: CreateScheduleWaitlistPatientByPatientSchema;
  roomId?: InputMaybe<Scalars["String"]>;
  serviceId: Scalars["String"];
};

export type CreateScheduleWaitlistFormAnswerByPatientSchema = {
  answers: Array<CreateFormFieldAnswerSchema>;
  formId: Scalars["String"];
};

export type CreateScheduleWaitlistPatientByPatientSchema = {
  address: Scalars["String"];
  comment?: InputMaybe<Scalars["String"]>;
  dateOfBirth: Scalars["String"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  gender: PatientGenders;
  lastName: Scalars["String"];
  passportNumber: Scalars["String"];
  phoneNumber: Scalars["String"];
  postCode: Scalars["String"];
};

export type CreateServiceCategorySchema = {
  clinicId: Scalars["String"];
  name: Scalars["String"];
};

export type CreateServiceSchema = {
  categoryId: Scalars["String"];
  currencyCode?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  durationInMinutes: Scalars["Int"];
  hiddenFromPatients: Scalars["Boolean"];
  icd9Code?: InputMaybe<Scalars["String"]>;
  icd10Code?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  price?: InputMaybe<Scalars["Int"]>;
};

export type CreateSmsTemplateSchema = {
  delayInHours: Scalars["Int"];
  languageISOCode: LanguageIsoCodes;
  text: Scalars["String"];
};

export type CreateSurveySchema = {
  clinicId: Scalars["String"];
  finalMessage: Scalars["String"];
  followUpResponse: Scalars["String"];
  name: Scalars["String"];
};

export type CreateTemplateSchema = {
  actions: Array<DistributionActions>;
  clinicId: Scalars["String"];
  event: DistributionSystemEvents;
  mailTemplates: Array<CreateMailTemplateSchema>;
  name: Scalars["String"];
  smsTemplates: Array<CreateSmsTemplateSchema>;
};

export type CurrencyEntitiesResponse = {
  __typename?: "CurrencyEntitiesResponse";
  currencies: Array<CurrencyEntityResponse>;
};

export type CurrencyEntityResponse = {
  __typename?: "CurrencyEntityResponse";
  code: Scalars["String"];
};

/** Enum of days of week */
export enum DaysOfWeek {
  Friday = "Friday",
  Monday = "Monday",
  Saturday = "Saturday",
  Sunday = "Sunday",
  Thursday = "Thursday",
  Tuesday = "Tuesday",
  Wednesday = "Wednesday",
}

export type DeleteClinicByIdSchema = {
  id: Scalars["String"];
};

export type DeleteClinicLogoFileIdResponse = {
  __typename?: "DeleteClinicLogoFileIdResponse";
  deleted: Scalars["Boolean"];
};

export type DeleteClinicLogoFileIdSchema = {
  id: Scalars["String"];
};

export type DeleteClinicResponse = {
  __typename?: "DeleteClinicResponse";
  deleted: Scalars["Boolean"];
};

export type DeleteClinicRoomByIdSchema = {
  id: Scalars["String"];
};

export type DeleteClinicRoomResponse = {
  __typename?: "DeleteClinicRoomResponse";
  deleted: Scalars["Boolean"];
};

export type DeleteClinicWorkingDayByIdSchema = {
  id: Scalars["String"];
};

export type DeleteClinicWorkingDayResponse = {
  __typename?: "DeleteClinicWorkingDayResponse";
  deleted: Scalars["Boolean"];
};

export type DeleteDoctorByIdSchema = {
  doctorId: Scalars["String"];
};

export type DeleteDoctorResponse = {
  __typename?: "DeleteDoctorResponse";
  deleted: Scalars["Boolean"];
};

export type DeletePatientByIdSchema = {
  id: Scalars["String"];
};

export type DeletePatientResponse = {
  __typename?: "DeletePatientResponse";
  deleted: Scalars["Boolean"];
};

export type DeleteScheduleByIdByClientResponse = {
  __typename?: "DeleteScheduleByIdByClientResponse";
  deleted: Scalars["Boolean"];
};

export type DeleteScheduleByIdByClientSchema = {
  id: Scalars["String"];
};

export type DeleteServiceByIdSchema = {
  id: Scalars["String"];
};

export type DeleteServiceCategoryByIdResponse = {
  __typename?: "DeleteServiceCategoryByIdResponse";
  deleted: Scalars["Boolean"];
};

export type DeleteServiceCategoryByIdSchema = {
  id: Scalars["String"];
};

export type DeleteServiceResponse = {
  __typename?: "DeleteServiceResponse";
  deleted: Scalars["Boolean"];
};

export type DeleteUserAvatarFileIdResponse = {
  __typename?: "DeleteUserAvatarFileIdResponse";
  deleted: Scalars["Boolean"];
};

/** Distribution actions */
export enum DistributionActions {
  SendMail = "SendMail",
  SendSms = "SendSms",
  Timer = "Timer",
}

/** Distribution system events */
export enum DistributionSystemEvents {
  AppointmentCanceledByClient = "AppointmentCanceledByClient",
  AppointmentCanceledByPatient = "AppointmentCanceledByPatient",
  AppointmentConfirmedByClient = "AppointmentConfirmedByClient",
  AppointmentConfirmedByPatient = "AppointmentConfirmedByPatient",
  AppointmentCreatedByClient = "AppointmentCreatedByClient",
  AppointmentCreatedByPatient = "AppointmentCreatedByPatient",
  AppointmentMissed = "AppointmentMissed",
  AppointmentRemindedBefore48Hours = "AppointmentRemindedBefore48Hours",
  AppointmentRescheduledByClient = "AppointmentRescheduledByClient",
  AppointmentRescheduledByPatient = "AppointmentRescheduledByPatient",
  AppointmentSuccessful1Hour = "AppointmentSuccessful1Hour",
  AppointmentSuccessful6Months = "AppointmentSuccessful6Months",
  WaitlistConfirmedByPatient = "WaitlistConfirmedByPatient",
  WaitlistCreatedByClient = "WaitlistCreatedByClient",
  WaitlistCreatedByPatient = "WaitlistCreatedByPatient",
  WaitlistTimeslotAvailable = "WaitlistTimeslotAvailable",
}

export type DoctorEntityResponse = {
  __typename?: "DoctorEntityResponse";
  address?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  description?: Maybe<Scalars["String"]>;
  doctorTypeId: Scalars["String"];
  id: Scalars["String"];
  medicalCertificateNumber: Scalars["String"];
  passportNumber?: Maybe<Scalars["String"]>;
  phoneNumber: Scalars["String"];
  promoCode?: Maybe<Scalars["String"]>;
  userId: Scalars["String"];
};

/** Enum of clinic sort fields */
export enum DoctorSortFields {
  CreatedAt = "CreatedAt",
}

export type DoctorTypeEntitiesResponse = {
  __typename?: "DoctorTypeEntitiesResponse";
  doctorTypes: Array<DoctorTypeEntityResponse>;
};

export type DoctorTypeEntityResponse = {
  __typename?: "DoctorTypeEntityResponse";
  defaultLanguageName: Scalars["String"];
  doctorTypeTranslations: Array<DoctorTypeTranslationEntityResponse>;
  id: Scalars["String"];
};

export type DoctorTypeTranslationEntityResponse = {
  __typename?: "DoctorTypeTranslationEntityResponse";
  doctorTypeId: Scalars["String"];
  id: Scalars["String"];
  languageISOCode: Scalars["String"];
  name: Scalars["String"];
};

export type EnableTwoFactorAuthenticationResponse = {
  __typename?: "EnableTwoFactorAuthenticationResponse";
  enabled: Scalars["Boolean"];
};

export type EnableTwoFactorAuthenticationSchema = {
  code: Scalars["String"];
};

/** Enum of event types */
export enum EventTypes {
  Appointment = "Appointment",
  Vacation = "Vacation",
  Working = "Working",
}

export type FileEntityResponse = {
  __typename?: "FileEntityResponse";
  createdAt: Scalars["DateTime"];
  extension: Scalars["String"];
  id: Scalars["String"];
  mimetype: Scalars["String"];
  originalName: Scalars["String"];
  resourceKey: Scalars["String"];
  /** File size in bytes */
  size: Scalars["Int"];
  url: Scalars["String"];
  usageStatus: Scalars["String"];
  userId: Scalars["String"];
};

/** The usage status of the file */
export enum FileUsageStatuses {
  Failed = "Failed",
  Fulfilled = "Fulfilled",
  Pending = "Pending",
}

export type FindAllCountriesResponse = {
  __typename?: "FindAllCountriesResponse";
  countries: Array<CountryEntityResponse>;
};

export type FindAndCountManyAggregatedFormsPatientsSchema = {
  clinicId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type FindAndCountManyClinics = {
  limit?: InputMaybe<Scalars["Float"]>;
  name?: InputMaybe<Scalars["String"]>;
  offset?: InputMaybe<Scalars["Float"]>;
  sortField?: InputMaybe<ClinicSortFields>;
  sortOrder?: InputMaybe<SortingOrders>;
};

export type FindAndCountManyClinicsByClientIdSchema = {
  clientId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type FindAndCountManyDoctorsByClinicIdSchema = {
  clinicId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sortField?: InputMaybe<DoctorSortFields>;
  sortOrder?: InputMaybe<SortingOrders>;
};

export type FindAndCountManyDoctorsByUserIdSchema = {
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  sortField?: InputMaybe<DoctorSortFields>;
  sortOrder?: InputMaybe<SortingOrders>;
};

export type FindAndCountManyFormsByClientSchema = {
  clinicId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  patientId?: InputMaybe<Scalars["String"]>;
};

export type FindAndCountManyFormsByDoctorSchema = {
  clinicId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  patientId?: InputMaybe<Scalars["String"]>;
};

export type FindAndCountManyLastSmsByClientSchema = {
  clinicId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

export type FindAndCountManyPatientsByClientSchema = {
  clinicId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  search?: InputMaybe<Scalars["String"]>;
  sortField?: InputMaybe<PatientSortFields>;
  sortOrder?: InputMaybe<SortingOrders>;
};

export type FindAndCountManyPatientsByDoctorSchema = {
  clinicId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Float"]>;
  offset?: InputMaybe<Scalars["Float"]>;
  search?: InputMaybe<Scalars["String"]>;
  sortField?: InputMaybe<PatientSortFields>;
  sortOrder?: InputMaybe<SortingOrders>;
};

export type FindAndCountManyScheduleAppointmentsWithRelationsByClientSchema = {
  clinicId?: InputMaybe<Scalars["String"]>;
  dateFrom?: InputMaybe<Scalars["String"]>;
  dateTo?: InputMaybe<Scalars["String"]>;
  doctorIds?: InputMaybe<Array<Scalars["String"]>>;
  limit?: InputMaybe<Scalars["Int"]>;
  minuteFrom?: InputMaybe<Scalars["Int"]>;
  minuteTo?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  patientId?: InputMaybe<Scalars["String"]>;
  roomIds?: InputMaybe<Array<Scalars["String"]>>;
  sortField?: InputMaybe<AppointmentSortFields>;
  sortOrder?: InputMaybe<SortingOrders>;
  status?: InputMaybe<AppointmentStatuses>;
};

export type FindAndCountManyScheduleAppointmentsWithRelationsByDoctorSchema = {
  clinicId?: InputMaybe<Scalars["String"]>;
  dateFrom?: InputMaybe<Scalars["String"]>;
  dateTo?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  minuteFrom?: InputMaybe<Scalars["Int"]>;
  minuteTo?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  patientId?: InputMaybe<Scalars["String"]>;
  roomIds?: InputMaybe<Array<Scalars["String"]>>;
  sortField?: InputMaybe<AppointmentSortFields>;
  sortOrder?: InputMaybe<SortingOrders>;
  status?: InputMaybe<AppointmentStatuses>;
};

export type FindAndCountManyScheduleWaitlistsByClientSchema = {
  clinicId?: InputMaybe<Scalars["String"]>;
  doctorId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  patientId?: InputMaybe<Scalars["String"]>;
  serviceId?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<WaitlistStatuses>;
};

export type FindAndCountManySmsByClientSchema = {
  clinicId: Scalars["String"];
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  patientId: Scalars["String"];
  sortField?: InputMaybe<SmsSortFields>;
  sortOrder?: InputMaybe<SortingOrders>;
};

export type FindAndCountManyTemplatesByClientSchema = {
  clinicId?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  onlyCurrent: Scalars["Boolean"];
  sortField: TemplateSortFields;
  sortOrder: SortingOrders;
};

export type FindBlankFormByServiceIdSchema = {
  serviceId: Scalars["String"];
};

export type FindClinicByIdSchema = {
  id: Scalars["String"];
};

export type FindClinicRoomByIdSchema = {
  id: Scalars["String"];
};

export type FindDefaultMailTemplateSchema = {
  event: DistributionSystemEvents;
  languageISOCode: LanguageIsoCodes;
};

export type FindDefaultSmsTemplateSchema = {
  event: DistributionSystemEvents;
  languageISOCode: LanguageIsoCodes;
};

export type FindDoctorByIdSchema = {
  doctorId: Scalars["String"];
};

export type FindManyAggregatedDoctorsByServiceIdSchema = {
  serviceId: Scalars["String"];
};

export type FindManyAvailableSlotsByDateRangeSchema = {
  dateFrom: Scalars["String"];
  dateTo: Scalars["String"];
  doctorId: Scalars["String"];
  serviceId: Scalars["String"];
};

export type FindManyAvailableSlotsResponse = {
  __typename?: "FindManyAvailableSlotsResponse";
  availableSlots: Array<SlotsGroupedByDateResponse>;
};

export type FindManyAvailableSlotsSchema = {
  doctorId: Scalars["String"];
  month: Scalars["Int"];
  serviceId: Scalars["String"];
  year: Scalars["Int"];
};

export type FindManyClinicWorkingDaysByClinicIdResponse = {
  __typename?: "FindManyClinicWorkingDaysByClinicIdResponse";
  workingDays: Array<ClinicWorkingDayEntityResponse>;
};

export type FindManyClinicWorkingDaysByClinicIdSchema = {
  clinicId: Scalars["String"];
  sortField?: InputMaybe<ClinicWorkingDaysSortFields>;
  sortOrder?: InputMaybe<SortingOrders>;
};

export type FindManyDoctorClinicsByDoctorIdResponse = {
  __typename?: "FindManyDoctorClinicsByDoctorIdResponse";
  clinics: Array<ClinicEntityResponse>;
};

export type FindManyDoctorClinicsByDoctorIdSchema = {
  doctorId: Scalars["String"];
};

export type FindManyDoctorTypesByLanguageIsoCodeSchema = {
  languageISOCode: LanguageIsoCodes;
};

export type FindManyFilesByIdsResponse = {
  __typename?: "FindManyFilesByIdsResponse";
  files: Array<FileEntityResponse>;
};

export type FindManyFilesByIdsSchema = {
  ids: Array<Scalars["String"]>;
};

export type FindManyRoomsByClinicIdResponse = {
  __typename?: "FindManyRoomsByClinicIdResponse";
  rooms: Array<ClinicRoomEntityResponse>;
};

export type FindManyRoomsByClinicIdSchema = {
  clinicId: Scalars["String"];
  sortField?: InputMaybe<ClinicRoomSortFields>;
  sortOrder?: InputMaybe<SortingOrders>;
};

export type FindManyScheduleAppointmentUpdatesByIdByClientSchema = {
  appointmentId: Scalars["String"];
};

export type FindManySchedulesByClientSchema = {
  clinicId: Scalars["String"];
  doctorId: Scalars["String"];
};

export type FindManySchedulesByDoctorSchema = {
  clinicId: Scalars["String"];
};

export type FindManyServiceCategoriesByClinicIdResponse = {
  __typename?: "FindManyServiceCategoriesByClinicIdResponse";
  categories: Array<ServiceCategoryEntityResponse>;
};

export type FindManyServiceCategoriesByClinicIdSchema = {
  clinicId: Scalars["String"];
  sortField?: InputMaybe<ServiceSortFields>;
  sortOrder?: InputMaybe<SortingOrders>;
};

export type FindManyServicesSchema = {
  clinicId: Scalars["String"];
  sortField?: InputMaybe<ServiceSortFields>;
  sortOrder?: InputMaybe<SortingOrders>;
};

export type FindManyTemplateVariablesByClientResponse = {
  __typename?: "FindManyTemplateVariablesByClientResponse";
  variables: Array<Scalars["String"]>;
};

export type FindManyTemplateVariablesByClientSchema = {
  event: DistributionSystemEvents;
};

export type FindPatientByIdSchema = {
  id: Scalars["String"];
};

export type FindScheduleAppointmentWithRelationsByHashByPatientSchema = {
  hash: Scalars["String"];
};

export type FindSentTemplateCountByEventResponse = {
  __typename?: "FindSentTemplateCountByEventResponse";
  sent: Scalars["Int"];
};

export type FindSentTemplateCountByEventSchema = {
  clinicId?: InputMaybe<Scalars["String"]>;
  event: DistributionSystemEvents;
};

export type FindServiceByIdSchema = {
  id: Scalars["String"];
};

export type FindServiceCategoryByIdSchema = {
  id: Scalars["String"];
};

export type FindSystemEventsResponse = {
  __typename?: "FindSystemEventsResponse";
  events: Array<Scalars["String"]>;
};

export type FindTemplateByClientSchema = {
  clinicId: Scalars["String"];
  event: DistributionSystemEvents;
};

export type FormAnswerEntityResponse = {
  __typename?: "FormAnswerEntityResponse";
  answers?: Maybe<Array<FormFieldAnswerResponse>>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  entityId?: Maybe<Scalars["String"]>;
  entityType?: Maybe<FormAnswerEntityTypes>;
  formId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  patientId?: Maybe<Scalars["String"]>;
};

/** Enum of entity types in form answer */
export enum FormAnswerEntityTypes {
  Appointment = "Appointment",
  Waitlist = "Waitlist",
}

export type FormAnswerEntityWithFormResponse = {
  __typename?: "FormAnswerEntityWithFormResponse";
  answers?: Maybe<Array<FormFieldAnswerResponse>>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  entityId?: Maybe<Scalars["String"]>;
  entityType?: Maybe<FormAnswerEntityTypes>;
  form: FormEntityResponse;
  formId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  patientId?: Maybe<Scalars["String"]>;
};

export type FormAnswerEntityWithRelationsResponse = {
  __typename?: "FormAnswerEntityWithRelationsResponse";
  answers?: Maybe<Array<FormFieldAnswerResponse>>;
  createdAt?: Maybe<Scalars["DateTime"]>;
  entityId?: Maybe<Scalars["String"]>;
  entityType?: Maybe<FormAnswerEntityTypes>;
  form: FormEntityWithRelationsResponse;
  formId?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  patientId?: Maybe<Scalars["String"]>;
};

export type FormEntityResponse = {
  __typename?: "FormEntityResponse";
  clinicId: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
  serviceId?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

export type FormEntityWithRelationsResponse = {
  __typename?: "FormEntityWithRelationsResponse";
  clinicId: Scalars["String"];
  fields: Array<FormFieldEntityWithRelationsResponse>;
  id: Scalars["String"];
  name: Scalars["String"];
  serviceId?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

export type FormFieldAnswerResponse = {
  __typename?: "FormFieldAnswerResponse";
  fieldName?: Maybe<Scalars["String"]>;
  fieldType?: Maybe<FormFieldTypes>;
  fieldValue?: Maybe<Scalars["String"]>;
  order?: Maybe<Scalars["Int"]>;
  value?: Maybe<Scalars["String"]>;
};

export type FormFieldEntityWithRelationsResponse = {
  __typename?: "FormFieldEntityWithRelationsResponse";
  formId: Scalars["String"];
  hiddenFromExistingPatients: Scalars["Boolean"];
  id: Scalars["String"];
  order: Scalars["Float"];
  required: Scalars["Boolean"];
  translations: Array<FormFieldTranslationEntityResponse>;
  type: FormFieldTypes;
  value?: Maybe<Scalars["String"]>;
};

export type FormFieldTranslationEntityResponse = {
  __typename?: "FormFieldTranslationEntityResponse";
  fieldId: Scalars["String"];
  id: Scalars["String"];
  languageISOCode: LanguageIsoCodes;
  name: Scalars["String"];
  placeholder: Scalars["String"];
  value?: Maybe<Scalars["String"]>;
};

/** Enum of form field types */
export enum FormFieldTypes {
  Checkbox = "Checkbox",
  Date = "Date",
  Email = "Email",
  Number = "Number",
  Phone = "Phone",
  Text = "Text",
  TextArea = "TextArea",
  Time = "Time",
}

export type GeneratedTwoFactorAuthenticationResponse = {
  __typename?: "GeneratedTwoFactorAuthenticationResponse";
  qrCodeSVG: Scalars["String"];
  secret: Scalars["String"];
};

/** Enum of available language ISO codes */
export enum LanguageIsoCodes {
  En = "EN",
  Pl = "PL",
}

/** Enum of layout types */
export enum LayoutTypes {
  Custom = "Custom",
  DayOfWeek = "DayOfWeek",
}

export type LocalSignInByAdminResponse = {
  __typename?: "LocalSignInByAdminResponse";
  signedIn: Scalars["Boolean"];
};

export type LocalSignInByAdminSchema = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type LocalSignInResponse = {
  __typename?: "LocalSignInResponse";
  signedIn: Scalars["Boolean"];
  twoFactorAuthenticationEnabled: Scalars["Boolean"];
};

export type LocalSignInSchema = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type LocalSignUpResponse = {
  __typename?: "LocalSignUpResponse";
  signedUp: Scalars["Boolean"];
};

export type LocalSignUpSchema = {
  countryId: Scalars["String"];
  email: Scalars["String"];
  firstName: Scalars["String"];
  languageISOCode: LanguageIsoCodes;
  lastName: Scalars["String"];
  password: Scalars["String"];
  phoneNumber: Scalars["String"];
  promoCode?: InputMaybe<Scalars["String"]>;
};

export type MailTemplateEntityResponse = {
  __typename?: "MailTemplateEntityResponse";
  createdAt: Scalars["DateTime"];
  delayInHours: Scalars["Float"];
  id: Scalars["String"];
  languageISOCode: Scalars["String"];
  subject: Scalars["String"];
  templateId: Scalars["String"];
  text: Scalars["String"];
};

export type MailTemplateResponse = {
  __typename?: "MailTemplateResponse";
  subject: Scalars["String"];
  text: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  addPINCode: AddPinCodeResponse;
  adminLocalSignIn: AdminLocalSignInResponse;
  attachDoctorToService: AttachDoctorToServiceResponse;
  authorizeWith2FA: AuthorizeWith2FaResponse;
  authorizeWithPINCode: AuthorizeWithPinCodeResponse;
  authorizeWithPassword: AuthorizeWithPasswordResponse;
  cancelScheduleAppointmentByClient: CancelScheduleAppointmentByClientResponse;
  cancelScheduleAppointmentByPatient: CancelScheduleAppointmentByPatientResponse;
  changeEmail: ChangeEmailResponse;
  closeScheduleWaitlistByClient: CloseScheduleWaitlistByClientResponse;
  closeScheduleWaitlistByPatient: CloseScheduleWaitlistByPatientResponse;
  confirmMail: ConfirmMailResponse;
  confirmScheduleAppointmentByClient: ConfirmScheduleAppointmentByClientResponse;
  confirmScheduleAppointmentByPatient: ConfirmScheduleAppointmentByPatientResponse;
  confirmScheduleWaitlistByPatient: ConfirmScheduleWaitlistByPatientResponse;
  convertScheduleWaitlistToScheduleAppointmentByPatient: ScheduleAppointmentEntityResponse;
  createClinic: ClinicEntityResponse;
  createClinicRoom: ClinicRoomEntityResponse;
  createClinicWorkingDay: ClinicWorkingDayEntityResponse;
  createCompany: CompanyEntityResponse;
  createDoctorInvitation: DoctorEntityResponse;
  createFormByClient: FormEntityResponse;
  createPatient: PatientEntityResponse;
  createSchedule: ScheduleEntityResponse;
  createScheduleAppointmentByClient: ScheduleAppointmentEntityResponse;
  createScheduleAppointmentByDoctor: ScheduleAppointmentEntityResponse;
  createScheduleAppointmentByPatient: CreateScheduleAppointmentByPatientResponse;
  createScheduleWaitlistByClient: ScheduleWaitlistEntityResponse;
  createScheduleWaitlistByPatient: CreateScheduleWaitlistByPatientResponse;
  createService: ServiceEntityResponse;
  createServiceCategory: ServiceCategoryEntityResponse;
  createSurvey: SurveyEntityResponse;
  createTemplate: TemplateEntityResponse;
  deleteClinicById: DeleteClinicResponse;
  deleteClinicLogoFileId: DeleteClinicLogoFileIdResponse;
  deleteClinicRoomById: DeleteClinicRoomResponse;
  deleteClinicWorkingDayById: DeleteClinicWorkingDayResponse;
  deleteDoctorById: DeleteDoctorResponse;
  deletePatientById: DeletePatientResponse;
  deleteScheduleByIdByClient: DeleteScheduleByIdByClientResponse;
  deleteServiceById: DeleteServiceResponse;
  deleteServiceCategoryById: DeleteServiceCategoryByIdResponse;
  deleteUserAvatarFileId: DeleteUserAvatarFileIdResponse;
  enable2FA: EnableTwoFactorAuthenticationResponse;
  generate2FA: GeneratedTwoFactorAuthenticationResponse;
  localSignIn: LocalSignInResponse;
  localSignInByAdmin: LocalSignInByAdminResponse;
  localSignUp: LocalSignUpResponse;
  rateScheduleAppointmentByPatient: RateScheduleAppointmentByPatientResponse;
  requestEmailChange: RequestEmailChangeResponse;
  requestPasswordRestorage: RequestPasswordRestorageResponse;
  rescheduleAppointmentByClient: RescheduleScheduleAppointmentByClientResponse;
  rescheduleScheduleAppointmentByPatient: RescheduleScheduleAppointmentByPatientResponse;
  rescheduleWaitlistByClient: RescheduleScheduleWaitlistByClientResponse;
  rescheduleWaitlistByPatient: RescheduleScheduleWaitlistByPatientResponse;
  restorePassword: RestorePasswordResponse;
  sendRawSmsByClient: SendRawSmsResponse;
  unattachDoctorService: UnattachServiceDoctorResponse;
  updateClinicById: ClinicEntityResponse;
  updateClinicConstraints: UpdateTemplateByClientResponse;
  updateClinicLogoFileId: UpdateClinicLogoFileIdResponse;
  updateClinicMessagingServiceSidByAdmin: UpdateClinicLogoFileIdResponse;
  updateClinicRoomById: UpdateClinicRoomByIdResponse;
  updateClinicWorkingDaysByClinicId: UpdateClinicWorkingDaysByClinicIdResponse;
  updateCurrentClinicTemplate: UpdateTemplateByClientResponse;
  updateDoctorAvatarFileIdByClient: UpdateDoctorResponse;
  updateDoctorByIdByClient: UpdateDoctorResponse;
  updateDoctorByUserIdByDoctor: UpdateDoctorResponse;
  updateDoctorClinicsByClient: UpdateDoctorClinicsResponse;
  updateFormByClient: UpdateFormResponse;
  updateMailTemplateByClient: UpdateTemplateByClientResponse;
  updatePatientById: UpdatePatientByIdResponse;
  updateScheduleAppointmentMissedStatusByClient: UpdateScheduleAppointmentMissedStatusResponse;
  updateScheduleAppointmentMissedStatusByDoctor: UpdateScheduleAppointmentMissedStatusResponse;
  updateScheduleAppointmentStatusByIdByClient: UpdateScheduleAppointmentStatusByIdByClientResponse;
  updateScheduleByIdByClient: UpdateScheduleByIdByClientResponse;
  updateServiceById: UpdateServiceByIdResponse;
  updateServiceCategoryById: UpdateServiceCategoryByIdResponse;
  updateSmsTemplateByClient: UpdateTemplateByClientResponse;
  updateTemplateActionsByClient: UpdateTemplateActionsByClientResponse;
  updateTemplateByClient: UpdateTemplateByClientResponse;
  updateUserAvatarFileId: UpdateUserAvatarFileIdResponse;
  uploadFile: FileEntityResponse;
};

export type MutationAddPinCodeArgs = {
  schema: AddPinCodeSchema;
};

export type MutationAdminLocalSignInArgs = {
  schema: AdminLocalSignInSchema;
};

export type MutationAttachDoctorToServiceArgs = {
  schema: AttachDoctorToServiceSchema;
};

export type MutationAuthorizeWith2FaArgs = {
  schema: AuthorizeWith2FaSchema;
};

export type MutationAuthorizeWithPinCodeArgs = {
  schema: AuthorizeWithPinCodeSchema;
};

export type MutationAuthorizeWithPasswordArgs = {
  schema: AuthorizeWithPasswordSchema;
};

export type MutationCancelScheduleAppointmentByClientArgs = {
  schema: CancelScheduleAppointmentByClientSchema;
};

export type MutationCancelScheduleAppointmentByPatientArgs = {
  schema: CancelScheduleAppointmentByPatientSchema;
};

export type MutationChangeEmailArgs = {
  schema: ChangeEmailSchema;
};

export type MutationCloseScheduleWaitlistByClientArgs = {
  schema: CloseScheduleWaitlistByClientSchema;
};

export type MutationCloseScheduleWaitlistByPatientArgs = {
  schema: CloseScheduleWaitlistByPatientSchema;
};

export type MutationConfirmMailArgs = {
  schema: ConfirmMailSchema;
};

export type MutationConfirmScheduleAppointmentByClientArgs = {
  schema: ConfirmScheduleAppointmentByClientSchema;
};

export type MutationConfirmScheduleAppointmentByPatientArgs = {
  schema: ConfirmScheduleAppointmentByPatientSchema;
};

export type MutationConfirmScheduleWaitlistByPatientArgs = {
  schema: ConfirmScheduleWaitlistByPatientSchema;
};

export type MutationConvertScheduleWaitlistToScheduleAppointmentByPatientArgs =
  {
    schema: ConvertScheduleWaitlistToScheduleAppointmentByPatientSchema;
  };

export type MutationCreateClinicArgs = {
  schema: CreateClinicSchema;
};

export type MutationCreateClinicRoomArgs = {
  schema: CreateClinicRoomWithClinicIdSchema;
};

export type MutationCreateClinicWorkingDayArgs = {
  schema: CreateClinicWorkingDayWithClinicIdSchema;
};

export type MutationCreateCompanyArgs = {
  schema: CreateCompanySchema;
};

export type MutationCreateDoctorInvitationArgs = {
  schema: CreateDoctorInvitationSchema;
};

export type MutationCreateFormByClientArgs = {
  schema: CreateFormSchema;
};

export type MutationCreatePatientArgs = {
  schema: CreatePatientSchema;
};

export type MutationCreateScheduleArgs = {
  schema: CreateScheduleSchema;
};

export type MutationCreateScheduleAppointmentByClientArgs = {
  schema: CreateScheduleAppointmentByClientSchema;
};

export type MutationCreateScheduleAppointmentByDoctorArgs = {
  schema: CreateScheduleAppointmentByDoctorSchema;
};

export type MutationCreateScheduleAppointmentByPatientArgs = {
  schema: CreateScheduleAppointmentByPatientSchema;
};

export type MutationCreateScheduleWaitlistByClientArgs = {
  schema: CreateScheduleWaitlistByClientSchema;
};

export type MutationCreateScheduleWaitlistByPatientArgs = {
  schema: CreateScheduleWaitlistByPatientSchema;
};

export type MutationCreateServiceArgs = {
  schema: CreateServiceSchema;
};

export type MutationCreateServiceCategoryArgs = {
  schema: CreateServiceCategorySchema;
};

export type MutationCreateSurveyArgs = {
  schema: CreateSurveySchema;
};

export type MutationCreateTemplateArgs = {
  schema: CreateTemplateSchema;
};

export type MutationDeleteClinicByIdArgs = {
  schema: DeleteClinicByIdSchema;
};

export type MutationDeleteClinicLogoFileIdArgs = {
  schema: DeleteClinicLogoFileIdSchema;
};

export type MutationDeleteClinicRoomByIdArgs = {
  schema: DeleteClinicRoomByIdSchema;
};

export type MutationDeleteClinicWorkingDayByIdArgs = {
  schema: DeleteClinicWorkingDayByIdSchema;
};

export type MutationDeleteDoctorByIdArgs = {
  schema: DeleteDoctorByIdSchema;
};

export type MutationDeletePatientByIdArgs = {
  schema: DeletePatientByIdSchema;
};

export type MutationDeleteScheduleByIdByClientArgs = {
  schema: DeleteScheduleByIdByClientSchema;
};

export type MutationDeleteServiceByIdArgs = {
  schema: DeleteServiceByIdSchema;
};

export type MutationDeleteServiceCategoryByIdArgs = {
  schema: DeleteServiceCategoryByIdSchema;
};

export type MutationEnable2FaArgs = {
  schema: EnableTwoFactorAuthenticationSchema;
};

export type MutationLocalSignInArgs = {
  schema: LocalSignInSchema;
};

export type MutationLocalSignInByAdminArgs = {
  schema: LocalSignInByAdminSchema;
};

export type MutationLocalSignUpArgs = {
  schema: LocalSignUpSchema;
};

export type MutationRateScheduleAppointmentByPatientArgs = {
  schema: RateScheduleAppointmentByPatientSchema;
};

export type MutationRequestEmailChangeArgs = {
  schema: RequestEmailChangeSchema;
};

export type MutationRequestPasswordRestorageArgs = {
  schema: RequestPasswordRestorageSchema;
};

export type MutationRescheduleAppointmentByClientArgs = {
  schema: RescheduleScheduleAppointmentByClientSchema;
};

export type MutationRescheduleScheduleAppointmentByPatientArgs = {
  schema: RescheduleScheduleAppointmentByPatientSchema;
};

export type MutationRescheduleWaitlistByClientArgs = {
  schema: RescheduleScheduleWaitlistByClientSchema;
};

export type MutationRescheduleWaitlistByPatientArgs = {
  schema: RescheduleScheduleWaitlistByPatientSchema;
};

export type MutationRestorePasswordArgs = {
  schema: RestorePasswordSchema;
};

export type MutationSendRawSmsByClientArgs = {
  schema: SendRawSmsByClientSchema;
};

export type MutationUnattachDoctorServiceArgs = {
  schema: UnattachServiceDoctorSchema;
};

export type MutationUpdateClinicByIdArgs = {
  schema: UpdateClinicByIdSchema;
};

export type MutationUpdateClinicConstraintsArgs = {
  schema: UpdateClinicConstraintsSchema;
};

export type MutationUpdateClinicLogoFileIdArgs = {
  schema: UpdateClinicLogoFileIdSchema;
};

export type MutationUpdateClinicMessagingServiceSidByAdminArgs = {
  schema: UpdateClinicMessagingServiceSidSchema;
};

export type MutationUpdateClinicRoomByIdArgs = {
  schema: UpdateClinicRoomByIdSchema;
};

export type MutationUpdateClinicWorkingDaysByClinicIdArgs = {
  schema: UpdateClinicWorkingDaysByClinicIdSchema;
};

export type MutationUpdateCurrentClinicTemplateArgs = {
  schema: UpdateCurrentClinicTemplateSchema;
};

export type MutationUpdateDoctorAvatarFileIdByClientArgs = {
  schema: UpdateDoctorAvatarFileIdByClientSchema;
};

export type MutationUpdateDoctorByIdByClientArgs = {
  schema: UpdateDoctorByIdByClientSchema;
};

export type MutationUpdateDoctorByUserIdByDoctorArgs = {
  schema: UpdateDoctorByUserIdByDoctorSchema;
};

export type MutationUpdateDoctorClinicsByClientArgs = {
  schema: UpdateDoctorClinicsByClientSchema;
};

export type MutationUpdateFormByClientArgs = {
  schema: UpdateFormSchema;
};

export type MutationUpdateMailTemplateByClientArgs = {
  schema: UpdateMailTemplateByClientSchema;
};

export type MutationUpdatePatientByIdArgs = {
  schema: UpdatePatientByIdSchema;
};

export type MutationUpdateScheduleAppointmentMissedStatusByClientArgs = {
  schema: UpdateScheduleAppointmentMissedSchema;
};

export type MutationUpdateScheduleAppointmentMissedStatusByDoctorArgs = {
  schema: UpdateScheduleAppointmentMissedSchema;
};

export type MutationUpdateScheduleAppointmentStatusByIdByClientArgs = {
  schema: UpdateScheduleAppointmentStatusByIdByClientSchema;
};

export type MutationUpdateScheduleByIdByClientArgs = {
  schema: UpdateScheduleByIdByClientSchema;
};

export type MutationUpdateServiceByIdArgs = {
  schema: UpdateServiceByIdSchema;
};

export type MutationUpdateServiceCategoryByIdArgs = {
  schema: UpdateServiceCategoryByIdSchema;
};

export type MutationUpdateSmsTemplateByClientArgs = {
  schema: UpdateSmsTemplateByClientSchema;
};

export type MutationUpdateTemplateActionsByClientArgs = {
  schema: UpdateTemplateActionsByClientSchema;
};

export type MutationUpdateTemplateByClientArgs = {
  schema: UpdateTemplateByClientSchema;
};

export type MutationUpdateUserAvatarFileIdArgs = {
  schema: UpdateUserAvatarFileIdSchema;
};

export type MutationUploadFileArgs = {
  schema: UploadFileSchema;
};

export type PatientEntityResponse = {
  __typename?: "PatientEntityResponse";
  activated: Scalars["Boolean"];
  address?: Maybe<Scalars["String"]>;
  allergens?: Maybe<Scalars["String"]>;
  allergic?: Maybe<Scalars["Boolean"]>;
  comment?: Maybe<Scalars["String"]>;
  companyId: Scalars["String"];
  createdAt: Scalars["DateTime"];
  dateOfBirth?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  emailDistribution: Scalars["Boolean"];
  firstName: Scalars["String"];
  gender?: Maybe<PatientGenders>;
  id: Scalars["String"];
  languageISOCode: Scalars["String"];
  lastName: Scalars["String"];
  passportNumber?: Maybe<Scalars["String"]>;
  phoneNumber: Scalars["String"];
  postCode?: Maybe<Scalars["String"]>;
  smsDistribution: Scalars["Boolean"];
  status: PatientStatuses;
};

/** Enum of patient genders */
export enum PatientGenders {
  Female = "Female",
  Male = "Male",
}

/** Enum of patient sort fields */
export enum PatientSortFields {
  CreatedAt = "CreatedAt",
}

/** Enum of patient statuses */
export enum PatientStatuses {
  Existing = "Existing",
  New = "New",
}

export type PatientsCountResponse = {
  __typename?: "PatientsCountResponse";
  count: Scalars["Int"];
};

export type PinCodeExistsByUserIdResponse = {
  __typename?: "PinCodeExistsByUserIdResponse";
  exists: Scalars["Boolean"];
};

export type Query = {
  __typename?: "Query";
  autocompleteByClient: AutocompleteResultByClientResponse;
  autocompleteByDoctor: AutocompleteResultByDoctorResponse;
  calculateAverageScheduleAppointmentRateByClient: CalculateAverageScheduleAppointmentRateResponse;
  calculateAverageScheduleAppointmentRateByDoctor: CalculateAverageScheduleAppointmentRateResponse;
  calculateClinicConstraintsByClient: ClinicConstraintResponse;
  calculateClinicConstraintsByDoctor: ClinicConstraintResponse;
  checkIfCompanyExistsByUserId: CheckIfCompanyExistsByUserIdResponse;
  countAndGroupScheduleAppointmentsByDateByClient: ScheduleAppointmentCountsGroupedByDateResponse;
  countAndGroupScheduleAppointmentsByDateByDoctor: ScheduleAppointmentCountsGroupedByDateResponse;
  countNewPatientsByClient: PatientsCountResponse;
  countNewPatientsByDoctor: PatientsCountResponse;
  countScheduleAppointmentsByClient: ScheduleAppointmentsCountResponse;
  countScheduleAppointmentsByStatusesByClient: ScheduleAppointmentsCountByStatusesResponse;
  countScheduleAppointmentsByStatusesByDoctor: ScheduleAppointmentsCountByStatusesResponse;
  findAllCountries: FindAllCountriesResponse;
  findAndCountManyAggregatedFormsPatientsByClient: CountedAggregatedPatientEntitiesResponse;
  findAndCountManyAggregatedFormsPatientsByDoctor: CountedAggregatedPatientEntitiesResponse;
  findAndCountManyBlankFormsByClient: CountedBlankFormEntitiesResponse;
  findAndCountManyClinics: CountedClinicsResponse;
  findAndCountManyClinicsByAdmin: CountedClinicsByAdminResponse;
  findAndCountManyClinicsByClientId: CountedClinicsResponse;
  findAndCountManyDoctors: CountedAggregatedDoctorEntitiesResponse;
  findAndCountManyDoctorsByClinicId: CountedAggregatedDoctorEntitiesResponse;
  findAndCountManyFilledFormsByClient: CountedAggregatedFilledFormsResponse;
  findAndCountManyFilledFormsByDoctor: CountedAggregatedFilledFormsResponse;
  findAndCountManyLastSmsByClient: CountedAggregatedSmsEntitiesResponse;
  findAndCountManyPatientsByClient: CountedPatientsResponse;
  findAndCountManyPatientsByDoctor: CountedPatientsResponse;
  findAndCountManyPatientsInWaitlistsByClient: CountedPatientsResponse;
  findAndCountManyScheduleAppointmentsWithRelationsByClient: CountedAggregatedScheduleAppointmentEntitiesWithRelationsByClientResponse;
  findAndCountManyScheduleAppointmentsWithRelationsByDoctor: CountedAggregatedScheduleAppointmentEntitiesWithRelationsByDoctorResponse;
  findAndCountManyScheduleWaitlistsByClient: CountedAggregatedScheduleWaitlistEntitiesByClientResponse;
  findAndCountManySmsByClient: CountedSmsEntitiesResponse;
  findAndCountManyTemplatesByClient: CountedTemplatesResponse;
  findBlankFormByServiceId: BlankFormEntityResponse;
  findClinicById: ClinicEntityResponse;
  findClinicRoomById: ClinicRoomEntityResponse;
  findCompanyByUserId: CompanyEntityResponse;
  findDefaultMailTemplate: MailTemplateResponse;
  findDefaultSmsTemplate: SmsTemplateResponse;
  findDoctorById: AggregatedDoctorEntityResponse;
  findManyAggregatedDoctorsByServiceId: AggregatedDoctorEntitiesResponse;
  findManyAvailableSlots: FindManyAvailableSlotsResponse;
  findManyAvailableSlotsByDateRange: FindManyAvailableSlotsResponse;
  findManyClinicRoomsByClinicId: FindManyRoomsByClinicIdResponse;
  findManyClinicWorkingDaysByClinicId: FindManyClinicWorkingDaysByClinicIdResponse;
  findManyCurrencies: CurrencyEntitiesResponse;
  findManyDoctorClinicsByDoctorId: FindManyDoctorClinicsByDoctorIdResponse;
  findManyDoctorTypesByLanguageISOCode: DoctorTypeEntitiesResponse;
  findManyFilesByIds: FindManyFilesByIdsResponse;
  findManyScheduleAppointmentUpdatesByIdByClient: ScheduleAppointmentUpdateEntitiesResponse;
  findManySchedulesByClient: ScheduleEntitiesResponse;
  findManySchedulesByDoctor: ScheduleEntitiesResponse;
  findManyServiceCategoriesByClinicId: FindManyServiceCategoriesByClinicIdResponse;
  findManyServices: ServiceEntitiesWithRelationsResponse;
  findManyServicesByPatient: ServiceEntitiesWithRelationsResponse;
  findManyTemplateVariablesByClient: FindManyTemplateVariablesByClientResponse;
  findPatientById: PatientEntityResponse;
  findScheduleAppointmentWithRelationsByHashByPatient: AggregatedScheduleAppointmentEntityWithRelationsByPatientResponse;
  findScheduleWaitlistByHashByPatient: AggregatedScheduleWaitlistEntityByPatientResponse;
  findSentTemplateCountByEventByClient: FindSentTemplateCountByEventResponse;
  findSentTemplateCountByEventByDoctor: FindSentTemplateCountByEventResponse;
  findServiceById: ServiceEntityWithRelationsResponse;
  findServiceCategoryById: ServiceCategoryEntityResponse;
  findSystemEvents: FindSystemEventsResponse;
  findTemplateByClient: TemplateEntityResponse;
  getCurrentClient: ClientEntityResponse;
  getCurrentDoctor: DoctorEntityResponse;
  getCurrentUser: UserEntityResponse;
  helloWorld: Scalars["String"];
  pinCodeExistsByUserId: PinCodeExistsByUserIdResponse;
  scheduleAppointmentConfirmedByPatient: ScheduleAppointmentConfirmedByPatientResponse;
  scheduleWaitlistConfirmedByPatient: ScheduleAppointmentConfirmedByPatientResponse;
  searchByClient: SearchResultByClientResponse;
  searchByDoctor: SearchResultByDoctorResponse;
};

export type QueryAutocompleteByClientArgs = {
  schema: AutocompleteByClientSchema;
};

export type QueryAutocompleteByDoctorArgs = {
  schema: AutocompleteByDoctorSchema;
};

export type QueryCalculateAverageScheduleAppointmentRateByClientArgs = {
  schema: CalculateAverageScheduleAppointmentRateByClientSchema;
};

export type QueryCalculateAverageScheduleAppointmentRateByDoctorArgs = {
  schema: CalculateAverageScheduleAppointmentRateSchema;
};

export type QueryCalculateClinicConstraintsByClientArgs = {
  schema: CalculateClinicConstraintsByClientSchema;
};

export type QueryCalculateClinicConstraintsByDoctorArgs = {
  schema: CalculateClinicConstraintsByClientSchema;
};

export type QueryCountAndGroupScheduleAppointmentsByDateByClientArgs = {
  schema?: InputMaybe<CountAndGroupScheduleAppointmentsByDateByClientSchema>;
};

export type QueryCountAndGroupScheduleAppointmentsByDateByDoctorArgs = {
  schema?: InputMaybe<CountAndGroupScheduleAppointmentsByDateByDoctorSchema>;
};

export type QueryCountNewPatientsByClientArgs = {
  schema: CountNewPatientsByClientSchema;
};

export type QueryCountNewPatientsByDoctorArgs = {
  schema: CountNewPatientsByDoctorSchema;
};

export type QueryCountScheduleAppointmentsByClientArgs = {
  schema?: InputMaybe<CountScheduleAppointmentsCountByClientSchema>;
};

export type QueryCountScheduleAppointmentsByStatusesByClientArgs = {
  schema?: InputMaybe<CountScheduleAppointmentsByStatusesByClientSchema>;
};

export type QueryCountScheduleAppointmentsByStatusesByDoctorArgs = {
  schema?: InputMaybe<CountScheduleAppointmentsByStatusesByDoctorSchema>;
};

export type QueryFindAndCountManyAggregatedFormsPatientsByClientArgs = {
  schema: FindAndCountManyAggregatedFormsPatientsSchema;
};

export type QueryFindAndCountManyAggregatedFormsPatientsByDoctorArgs = {
  schema: FindAndCountManyAggregatedFormsPatientsSchema;
};

export type QueryFindAndCountManyBlankFormsByClientArgs = {
  schema: FindAndCountManyFormsByClientSchema;
};

export type QueryFindAndCountManyClinicsArgs = {
  schema: FindAndCountManyClinics;
};

export type QueryFindAndCountManyClinicsByAdminArgs = {
  schema: FindAndCountManyClinics;
};

export type QueryFindAndCountManyClinicsByClientIdArgs = {
  schema: FindAndCountManyClinicsByClientIdSchema;
};

export type QueryFindAndCountManyDoctorsArgs = {
  schema: FindAndCountManyDoctorsByUserIdSchema;
};

export type QueryFindAndCountManyDoctorsByClinicIdArgs = {
  schema: FindAndCountManyDoctorsByClinicIdSchema;
};

export type QueryFindAndCountManyFilledFormsByClientArgs = {
  schema: FindAndCountManyFormsByClientSchema;
};

export type QueryFindAndCountManyFilledFormsByDoctorArgs = {
  schema: FindAndCountManyFormsByDoctorSchema;
};

export type QueryFindAndCountManyLastSmsByClientArgs = {
  schema: FindAndCountManyLastSmsByClientSchema;
};

export type QueryFindAndCountManyPatientsByClientArgs = {
  schema: FindAndCountManyPatientsByClientSchema;
};

export type QueryFindAndCountManyPatientsByDoctorArgs = {
  schema: FindAndCountManyPatientsByDoctorSchema;
};

export type QueryFindAndCountManyPatientsInWaitlistsByClientArgs = {
  schema: FindAndCountManyPatientsByClientSchema;
};

export type QueryFindAndCountManyScheduleAppointmentsWithRelationsByClientArgs =
  {
    schema: FindAndCountManyScheduleAppointmentsWithRelationsByClientSchema;
  };

export type QueryFindAndCountManyScheduleAppointmentsWithRelationsByDoctorArgs =
  {
    schema: FindAndCountManyScheduleAppointmentsWithRelationsByDoctorSchema;
  };

export type QueryFindAndCountManyScheduleWaitlistsByClientArgs = {
  schema: FindAndCountManyScheduleWaitlistsByClientSchema;
};

export type QueryFindAndCountManySmsByClientArgs = {
  schema: FindAndCountManySmsByClientSchema;
};

export type QueryFindAndCountManyTemplatesByClientArgs = {
  schema: FindAndCountManyTemplatesByClientSchema;
};

export type QueryFindBlankFormByServiceIdArgs = {
  schema: FindBlankFormByServiceIdSchema;
};

export type QueryFindClinicByIdArgs = {
  schema: FindClinicByIdSchema;
};

export type QueryFindClinicRoomByIdArgs = {
  schema: FindClinicRoomByIdSchema;
};

export type QueryFindDefaultMailTemplateArgs = {
  schema: FindDefaultMailTemplateSchema;
};

export type QueryFindDefaultSmsTemplateArgs = {
  schema: FindDefaultSmsTemplateSchema;
};

export type QueryFindDoctorByIdArgs = {
  schema: FindDoctorByIdSchema;
};

export type QueryFindManyAggregatedDoctorsByServiceIdArgs = {
  schema: FindManyAggregatedDoctorsByServiceIdSchema;
};

export type QueryFindManyAvailableSlotsArgs = {
  schema: FindManyAvailableSlotsSchema;
};

export type QueryFindManyAvailableSlotsByDateRangeArgs = {
  schema: FindManyAvailableSlotsByDateRangeSchema;
};

export type QueryFindManyClinicRoomsByClinicIdArgs = {
  schema: FindManyRoomsByClinicIdSchema;
};

export type QueryFindManyClinicWorkingDaysByClinicIdArgs = {
  schema: FindManyClinicWorkingDaysByClinicIdSchema;
};

export type QueryFindManyDoctorClinicsByDoctorIdArgs = {
  schema: FindManyDoctorClinicsByDoctorIdSchema;
};

export type QueryFindManyDoctorTypesByLanguageIsoCodeArgs = {
  schema: FindManyDoctorTypesByLanguageIsoCodeSchema;
};

export type QueryFindManyFilesByIdsArgs = {
  schema: FindManyFilesByIdsSchema;
};

export type QueryFindManyScheduleAppointmentUpdatesByIdByClientArgs = {
  schema: FindManyScheduleAppointmentUpdatesByIdByClientSchema;
};

export type QueryFindManySchedulesByClientArgs = {
  schema: FindManySchedulesByClientSchema;
};

export type QueryFindManySchedulesByDoctorArgs = {
  schema: FindManySchedulesByDoctorSchema;
};

export type QueryFindManyServiceCategoriesByClinicIdArgs = {
  schema: FindManyServiceCategoriesByClinicIdSchema;
};

export type QueryFindManyServicesArgs = {
  schema: FindManyServicesSchema;
};

export type QueryFindManyServicesByPatientArgs = {
  schema: FindManyServicesSchema;
};

export type QueryFindManyTemplateVariablesByClientArgs = {
  schema: FindManyTemplateVariablesByClientSchema;
};

export type QueryFindPatientByIdArgs = {
  schema: FindPatientByIdSchema;
};

export type QueryFindScheduleAppointmentWithRelationsByHashByPatientArgs = {
  schema: FindScheduleAppointmentWithRelationsByHashByPatientSchema;
};

export type QueryFindScheduleWaitlistByHashByPatientArgs = {
  schema: FindScheduleAppointmentWithRelationsByHashByPatientSchema;
};

export type QueryFindSentTemplateCountByEventByClientArgs = {
  schema: FindSentTemplateCountByEventSchema;
};

export type QueryFindSentTemplateCountByEventByDoctorArgs = {
  schema: FindSentTemplateCountByEventSchema;
};

export type QueryFindServiceByIdArgs = {
  schema: FindServiceByIdSchema;
};

export type QueryFindServiceCategoryByIdArgs = {
  schema: FindServiceCategoryByIdSchema;
};

export type QueryFindTemplateByClientArgs = {
  schema: FindTemplateByClientSchema;
};

export type QueryScheduleAppointmentConfirmedByPatientArgs = {
  schema: ScheduleAppointmentConfirmedByPatientSchema;
};

export type QueryScheduleWaitlistConfirmedByPatientArgs = {
  schema: ScheduleAppointmentConfirmedByPatientSchema;
};

export type QuerySearchByClientArgs = {
  schema: SearchByClientSchema;
};

export type QuerySearchByDoctorArgs = {
  schema: SearchByDoctorSchema;
};

export type RateScheduleAppointmentByPatientResponse = {
  __typename?: "RateScheduleAppointmentByPatientResponse";
  rated: Scalars["Boolean"];
};

export type RateScheduleAppointmentByPatientSchema = {
  hash: Scalars["String"];
  rate: Scalars["Int"];
};

export type RequestEmailChangeResponse = {
  __typename?: "RequestEmailChangeResponse";
  requested: Scalars["Boolean"];
};

export type RequestEmailChangeSchema = {
  email: Scalars["String"];
};

export type RequestPasswordRestorageResponse = {
  __typename?: "RequestPasswordRestorageResponse";
  requested: Scalars["Boolean"];
};

export type RequestPasswordRestorageSchema = {
  email: Scalars["String"];
  languageISOCode: LanguageIsoCodes;
};

export type RescheduleScheduleAppointmentByClientResponse = {
  __typename?: "RescheduleScheduleAppointmentByClientResponse";
  rescheduled: Scalars["Boolean"];
};

export type RescheduleScheduleAppointmentByClientSchema = {
  appointmentId: Scalars["String"];
  date: Scalars["String"];
  minuteFrom: Scalars["Int"];
  minuteTo: Scalars["Int"];
};

export type RescheduleScheduleAppointmentByPatientResponse = {
  __typename?: "RescheduleScheduleAppointmentByPatientResponse";
  rescheduled: Scalars["Boolean"];
};

export type RescheduleScheduleAppointmentByPatientSchema = {
  date: Scalars["String"];
  doctorId?: InputMaybe<Scalars["String"]>;
  hash: Scalars["String"];
  minuteFrom: Scalars["Int"];
  minuteTo: Scalars["Int"];
};

export type RescheduleScheduleWaitlistByClientResponse = {
  __typename?: "RescheduleScheduleWaitlistByClientResponse";
  rescheduled: Scalars["Boolean"];
};

export type RescheduleScheduleWaitlistByClientSchema = {
  dateFrom?: InputMaybe<Scalars["String"]>;
  dateTo?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
  minuteFrom?: InputMaybe<Scalars["Int"]>;
  minuteTo?: InputMaybe<Scalars["Int"]>;
};

export type RescheduleScheduleWaitlistByPatientResponse = {
  __typename?: "RescheduleScheduleWaitlistByPatientResponse";
  rescheduled: Scalars["Boolean"];
};

export type RescheduleScheduleWaitlistByPatientSchema = {
  dateFrom?: InputMaybe<Scalars["String"]>;
  dateTo?: InputMaybe<Scalars["String"]>;
  hash: Scalars["String"];
  minuteFrom?: InputMaybe<Scalars["Int"]>;
  minuteTo?: InputMaybe<Scalars["Int"]>;
};

export type RestorePasswordResponse = {
  __typename?: "RestorePasswordResponse";
  restored: Scalars["Boolean"];
};

export type RestorePasswordSchema = {
  confirmationHash: Scalars["String"];
  password: Scalars["String"];
};

export type ScheduleAppointmentConfirmedByPatientResponse = {
  __typename?: "ScheduleAppointmentConfirmedByPatientResponse";
  confirmed: Scalars["Boolean"];
};

export type ScheduleAppointmentConfirmedByPatientSchema = {
  hash: Scalars["String"];
};

export type ScheduleAppointmentCountGroupedByDateResponse = {
  __typename?: "ScheduleAppointmentCountGroupedByDateResponse";
  count: Scalars["Float"];
  date: Scalars["String"];
};

export type ScheduleAppointmentCountsGroupedByDateResponse = {
  __typename?: "ScheduleAppointmentCountsGroupedByDateResponse";
  counts: Array<ScheduleAppointmentCountGroupedByDateResponse>;
};

export type ScheduleAppointmentEntityResponse = {
  __typename?: "ScheduleAppointmentEntityResponse";
  createdAt: Scalars["DateTime"];
  id: Scalars["String"];
  patientId: Scalars["String"];
  scheduleId: Scalars["String"];
  status: AppointmentStatuses;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ScheduleAppointmentEntityWithRelationsResponse = {
  __typename?: "ScheduleAppointmentEntityWithRelationsResponse";
  createdAt: Scalars["DateTime"];
  id: Scalars["String"];
  patientId: Scalars["String"];
  schedule: ScheduleEntityResponse;
  scheduleId: Scalars["String"];
  status: AppointmentStatuses;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

export type ScheduleAppointmentUpdateEntitiesResponse = {
  __typename?: "ScheduleAppointmentUpdateEntitiesResponse";
  appointmentUpdates: Array<ScheduleAppointmentUpdateEntityResponse>;
};

export type ScheduleAppointmentUpdateEntityResponse = {
  __typename?: "ScheduleAppointmentUpdateEntityResponse";
  appointmentId: Scalars["String"];
  createdAt: Scalars["DateTime"];
  id: Scalars["String"];
  original: ScheduleAppointmentEntityWithRelationsResponse;
  updated: ScheduleAppointmentEntityWithRelationsResponse;
  userId: Scalars["String"];
};

export type ScheduleAppointmentsCountByStatusesResponse = {
  __typename?: "ScheduleAppointmentsCountByStatusesResponse";
  all: Scalars["Int"];
  canceled: Scalars["Int"];
  confirmedByApplication: Scalars["Int"];
  confirmedByPatient: Scalars["Int"];
  missed: Scalars["Int"];
  new: Scalars["Int"];
  successful: Scalars["Int"];
};

export type ScheduleAppointmentsCountResponse = {
  __typename?: "ScheduleAppointmentsCountResponse";
  count: Scalars["Int"];
};

export type ScheduleEntitiesResponse = {
  __typename?: "ScheduleEntitiesResponse";
  schedules: Array<ScheduleEntityResponse>;
};

export type ScheduleEntityResponse = {
  __typename?: "ScheduleEntityResponse";
  clinicId: Scalars["String"];
  createdAt: Scalars["DateTime"];
  dateFrom?: Maybe<Scalars["DateTime"]>;
  dateTo?: Maybe<Scalars["DateTime"]>;
  daysOfWeek?: Maybe<Array<DaysOfWeek>>;
  doctorId: Scalars["String"];
  eventType: EventTypes;
  id: Scalars["String"];
  layoutType: LayoutTypes;
  minuteFrom: Scalars["Float"];
  minuteTo: Scalars["Float"];
  roomId?: Maybe<Scalars["String"]>;
  serviceId?: Maybe<Scalars["String"]>;
};

export type ScheduleWaitlistEntityResponse = {
  __typename?: "ScheduleWaitlistEntityResponse";
  clinicId: Scalars["String"];
  createdAt: Scalars["DateTime"];
  dateFrom: Scalars["String"];
  dateTo: Scalars["String"];
  doctorId?: Maybe<Scalars["String"]>;
  hash: Scalars["String"];
  id: Scalars["String"];
  minuteFrom: Scalars["Int"];
  minuteTo: Scalars["Int"];
  patientId: Scalars["String"];
  serviceId: Scalars["String"];
  status: WaitlistStatuses;
  uncommittedPatientId?: Maybe<Scalars["String"]>;
};

export type SearchByClientSchema = {
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  query: Scalars["String"];
  type: SearchTypes;
};

export type SearchByClientUnion =
  | AggregatedScheduleAppointmentEntityWithRelationsByClientResponse
  | PatientEntityResponse;

export type SearchByDoctorSchema = {
  limit: Scalars["Int"];
  offset: Scalars["Int"];
  query: Scalars["String"];
  type: SearchTypes;
};

export type SearchByDoctorUnion =
  | AggregatedScheduleAppointmentEntityWithRelationsByDoctorResponse
  | PatientEntityResponse;

export type SearchResultByClientResponse = {
  __typename?: "SearchResultByClientResponse";
  count: Scalars["Float"];
  entities?: Maybe<Array<SearchByClientUnion>>;
};

export type SearchResultByDoctorResponse = {
  __typename?: "SearchResultByDoctorResponse";
  count: Scalars["Float"];
  entities?: Maybe<Array<SearchByDoctorUnion>>;
};

/** Enum of search types */
export enum SearchTypes {
  Appointment = "Appointment",
  Patient = "Patient",
}

export type SendRawSmsByClientSchema = {
  clinicId: Scalars["String"];
  content: Scalars["String"];
  patientId: Scalars["String"];
};

export type SendRawSmsResponse = {
  __typename?: "SendRawSmsResponse";
  sent: Scalars["Boolean"];
};

export type ServiceCategoryEntityResponse = {
  __typename?: "ServiceCategoryEntityResponse";
  id: Scalars["String"];
  name: Scalars["String"];
};

export type ServiceEntitiesWithRelationsResponse = {
  __typename?: "ServiceEntitiesWithRelationsResponse";
  services: Array<ServiceEntityWithRelationsResponse>;
};

export type ServiceEntityResponse = {
  __typename?: "ServiceEntityResponse";
  category?: Maybe<ServiceCategoryEntityResponse>;
  createdAt: Scalars["DateTime"];
  currencyCode?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  durationInMinutes: Scalars["Float"];
  hiddenFromPatients: Scalars["Boolean"];
  icd9Code?: Maybe<Scalars["String"]>;
  icd10Code?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  name: Scalars["String"];
  price?: Maybe<Scalars["Float"]>;
};

export type ServiceEntityWithRelationsResponse = {
  __typename?: "ServiceEntityWithRelationsResponse";
  category?: Maybe<ServiceCategoryEntityResponse>;
  clinicRooms?: Maybe<Array<ClinicRoomEntityResponse>>;
  createdAt: Scalars["DateTime"];
  currencyCode?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  doctorIds?: Maybe<Array<Scalars["String"]>>;
  durationInMinutes: Scalars["Float"];
  hiddenFromPatients: Scalars["Boolean"];
  icd9Code?: Maybe<Scalars["String"]>;
  icd10Code?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  name: Scalars["String"];
  price?: Maybe<Scalars["Float"]>;
};

/** Enum of service sort fields */
export enum ServiceSortFields {
  CreatedAt = "CreatedAt",
}

export type SlotResponse = {
  __typename?: "SlotResponse";
  from: Scalars["String"];
  to: Scalars["String"];
};

export type SlotsGroupedByDateResponse = {
  __typename?: "SlotsGroupedByDateResponse";
  date: Scalars["String"];
  slots: Array<SlotResponse>;
};

export type SmsEntityResponse = {
  __typename?: "SmsEntityResponse";
  clinicId: Scalars["String"];
  clinicToPatient: Scalars["Boolean"];
  content: Scalars["String"];
  createdAt: Scalars["DateTime"];
  from: Scalars["String"];
  id: Scalars["String"];
  patientId: Scalars["String"];
  to: Scalars["String"];
};

/** Enum of sorting fields */
export enum SmsSortFields {
  CreatedAt = "CreatedAt",
}

export type SmsTemplateEntityResponse = {
  __typename?: "SmsTemplateEntityResponse";
  createdAt: Scalars["DateTime"];
  delayInHours: Scalars["Float"];
  id: Scalars["String"];
  languageISOCode: Scalars["String"];
  templateId: Scalars["String"];
  text: Scalars["String"];
};

export type SmsTemplateResponse = {
  __typename?: "SmsTemplateResponse";
  text: Scalars["String"];
};

/** Enum of sorting orders */
export enum SortingOrders {
  Ascending = "Ascending",
  Descending = "Descending",
}

export type SurveyEntityResponse = {
  __typename?: "SurveyEntityResponse";
  clinicId: Scalars["String"];
  createdAt: Scalars["DateTime"];
  finalMessage: Scalars["String"];
  followUpResponse: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
};

export type TemplateEntityResponse = {
  __typename?: "TemplateEntityResponse";
  actions: Array<DistributionActions>;
  clinicId: Scalars["String"];
  clinicsTemplates?: Maybe<ClinicsTemplatesResponse>;
  createdAt: Scalars["DateTime"];
  event: DistributionSystemEvents;
  id: Scalars["String"];
  mailSent: Scalars["Float"];
  mailTemplates?: Maybe<Array<MailTemplateEntityResponse>>;
  name: Scalars["String"];
  semanticLock: Scalars["String"];
  smsSent: Scalars["Float"];
  smsTemplates?: Maybe<Array<SmsTemplateEntityResponse>>;
  updatedAt?: Maybe<Scalars["DateTime"]>;
};

/** Enum of template sort fields */
export enum TemplateSortFields {
  CreatedAt = "CreatedAt",
}

export type UnattachServiceDoctorResponse = {
  __typename?: "UnattachServiceDoctorResponse";
  unattached: Scalars["Boolean"];
};

export type UnattachServiceDoctorSchema = {
  doctorId: Scalars["String"];
  serviceId: Scalars["String"];
};

export type UpdateClinicByIdSchema = {
  fields: UpdateClinicFieldsSchema;
  id: Scalars["String"];
};

export type UpdateClinicConstraintsFieldsSchema = {
  mailLimit?: InputMaybe<Scalars["Int"]>;
  mailSent?: InputMaybe<Scalars["Int"]>;
  smsLimit?: InputMaybe<Scalars["Int"]>;
  smsSent?: InputMaybe<Scalars["Int"]>;
};

export type UpdateClinicConstraintsSchema = {
  fields: UpdateClinicConstraintsFieldsSchema;
  id: Scalars["String"];
};

export type UpdateClinicFieldsSchema = {
  address?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  code?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  geographicalCoordinates?: InputMaybe<UpdateClinicGeographicalCoordinatesSchema>;
  hiddenFromPatients?: InputMaybe<Scalars["Boolean"]>;
  houseNumber?: InputMaybe<Scalars["String"]>;
  index?: InputMaybe<Scalars["String"]>;
  licenseNumber?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  rooms?: InputMaybe<Array<UpdateClinicRoomSchema>>;
  workingDays?: InputMaybe<Array<UpdateClinicWorkingDaySchema>>;
};

export type UpdateClinicGeographicalCoordinatesSchema = {
  latitude: Scalars["Float"];
  longitude: Scalars["Float"];
};

export type UpdateClinicLogoFileIdResponse = {
  __typename?: "UpdateClinicLogoFileIdResponse";
  updated: Scalars["Boolean"];
};

export type UpdateClinicLogoFileIdSchema = {
  id: Scalars["String"];
  logoFileId: Scalars["String"];
};

export type UpdateClinicMessagingServiceSidSchema = {
  id: Scalars["String"];
  messagingServiceSid?: InputMaybe<Scalars["String"]>;
};

export type UpdateClinicRoomByIdFieldsSchema = {
  name: Scalars["String"];
};

export type UpdateClinicRoomByIdResponse = {
  __typename?: "UpdateClinicRoomByIdResponse";
  updated: Scalars["Boolean"];
};

export type UpdateClinicRoomByIdSchema = {
  fields: UpdateClinicRoomByIdFieldsSchema;
  id: Scalars["String"];
};

export type UpdateClinicRoomSchema = {
  id?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
};

export type UpdateClinicWorkingDayByIdSchema = {
  day?: InputMaybe<ClinicWorkingDays>;
  fromMinute?: InputMaybe<Scalars["Int"]>;
  id: Scalars["String"];
  toMinute?: InputMaybe<Scalars["Int"]>;
};

export type UpdateClinicWorkingDaySchema = {
  day: ClinicWorkingDays;
  fromMinute: Scalars["Int"];
  id?: InputMaybe<Scalars["String"]>;
  toMinute: Scalars["Int"];
};

export type UpdateClinicWorkingDaysByClinicIdResponse = {
  __typename?: "UpdateClinicWorkingDaysByClinicIdResponse";
  updated: Scalars["Boolean"];
};

export type UpdateClinicWorkingDaysByClinicIdSchema = {
  clinicId: Scalars["String"];
  workingDays: Array<UpdateClinicWorkingDayByIdSchema>;
};

export type UpdateClinicsSchema = {
  clinicId: Scalars["String"];
  hiddenFromPatients: Scalars["Boolean"];
};

export type UpdateCurrentClinicTemplateSchema = {
  clinicId: Scalars["String"];
  event: DistributionSystemEvents;
  templateId?: InputMaybe<Scalars["String"]>;
};

export type UpdateDoctorAvatarFileIdByClientSchema = {
  avatarFileId: Scalars["String"];
  doctorId: Scalars["String"];
};

export type UpdateDoctorByIdByClientSchema = {
  description?: InputMaybe<Scalars["String"]>;
  doctorId: Scalars["String"];
  doctorTypeId?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  medicalCertificateNumber?: InputMaybe<Scalars["String"]>;
  passportNumber?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  promoCode?: InputMaybe<Scalars["String"]>;
};

export type UpdateDoctorByUserIdByDoctorSchema = {
  description?: InputMaybe<Scalars["String"]>;
  doctorTypeId?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  medicalCertificateNumber?: InputMaybe<Scalars["String"]>;
  passportNumber?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  promoCode?: InputMaybe<Scalars["String"]>;
};

export type UpdateDoctorClinicsByClientSchema = {
  clinics: Array<UpdateClinicsSchema>;
  doctorId: Scalars["String"];
};

export type UpdateDoctorClinicsResponse = {
  __typename?: "UpdateDoctorClinicsResponse";
  updated: Scalars["Boolean"];
};

export type UpdateDoctorResponse = {
  __typename?: "UpdateDoctorResponse";
  updated: Scalars["Boolean"];
};

export type UpdateFormFieldSchema = {
  hiddenFromExistingPatients?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["String"]>;
  order?: InputMaybe<Scalars["Float"]>;
  required?: InputMaybe<Scalars["Boolean"]>;
  translations?: InputMaybe<Array<UpdateFormFieldTranslationSchema>>;
  type?: InputMaybe<FormFieldTypes>;
  value?: InputMaybe<Scalars["String"]>;
};

export type UpdateFormFieldTranslationSchema = {
  id?: InputMaybe<Scalars["String"]>;
  languageISOCode?: InputMaybe<LanguageIsoCodes>;
  name?: InputMaybe<Scalars["String"]>;
  placeholder?: InputMaybe<Scalars["String"]>;
  value?: InputMaybe<Scalars["String"]>;
};

export type UpdateFormResponse = {
  __typename?: "UpdateFormResponse";
  updated: Scalars["Boolean"];
};

export type UpdateFormSchema = {
  fields?: InputMaybe<Array<UpdateFormFieldSchema>>;
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  serviceId?: InputMaybe<Scalars["String"]>;
};

export type UpdateMailTemplateByClientSchema = {
  fields: UpdateMailTemplateFieldsSchema;
  id: Scalars["String"];
};

export type UpdateMailTemplateFieldsSchema = {
  delayInHours?: InputMaybe<Scalars["Int"]>;
  subject?: InputMaybe<Scalars["String"]>;
  text?: InputMaybe<Scalars["String"]>;
};

export type UpdatePatientByIdResponse = {
  __typename?: "UpdatePatientByIdResponse";
  updated: Scalars["Boolean"];
};

export type UpdatePatientByIdSchema = {
  allergens?: InputMaybe<Scalars["String"]>;
  allergic?: InputMaybe<Scalars["Boolean"]>;
  comment?: InputMaybe<Scalars["String"]>;
  dateOfBirth?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  emailDistribution?: InputMaybe<Scalars["Boolean"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<PatientGenders>;
  id: Scalars["String"];
  languageISOCode: LanguageIsoCodes;
  lastName?: InputMaybe<Scalars["String"]>;
  passportNumber?: InputMaybe<Scalars["String"]>;
  phoneNumber?: InputMaybe<Scalars["String"]>;
  smsDistribution?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateScheduleAppointmentMissedSchema = {
  id: Scalars["String"];
};

export type UpdateScheduleAppointmentMissedStatusResponse = {
  __typename?: "UpdateScheduleAppointmentMissedStatusResponse";
  updated: Scalars["Boolean"];
};

export type UpdateScheduleAppointmentStatusByIdByClientResponse = {
  __typename?: "UpdateScheduleAppointmentStatusByIdByClientResponse";
  updated: Scalars["Boolean"];
};

export type UpdateScheduleAppointmentStatusByIdByClientSchema = {
  id: Scalars["String"];
  status: AppointmentStatuses;
};

export type UpdateScheduleByIdByClientResponse = {
  __typename?: "UpdateScheduleByIdByClientResponse";
  updated: Scalars["Boolean"];
};

export type UpdateScheduleByIdByClientSchema = {
  fields: UpdateScheduleFieldsSchema;
  id: Scalars["String"];
};

export type UpdateScheduleFieldsSchema = {
  dateFrom?: InputMaybe<Scalars["String"]>;
  dateTo?: InputMaybe<Scalars["String"]>;
  daysOfWeek?: InputMaybe<Array<DaysOfWeek>>;
  eventType?: InputMaybe<EventTypes>;
  minuteFrom?: InputMaybe<Scalars["Int"]>;
  minuteTo?: InputMaybe<Scalars["Int"]>;
  roomId?: InputMaybe<Scalars["String"]>;
};

export type UpdateServiceByIdResponse = {
  __typename?: "UpdateServiceByIdResponse";
  updated: Scalars["Boolean"];
};

export type UpdateServiceByIdSchema = {
  categoryId?: InputMaybe<Scalars["String"]>;
  currencyCode?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  durationInMinutes?: InputMaybe<Scalars["Float"]>;
  hiddenFromPatients?: InputMaybe<Scalars["Boolean"]>;
  icd9Code?: InputMaybe<Scalars["String"]>;
  icd10Code?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
  name?: InputMaybe<Scalars["String"]>;
  price?: InputMaybe<Scalars["Float"]>;
  roomIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type UpdateServiceCategoryByIdResponse = {
  __typename?: "UpdateServiceCategoryByIdResponse";
  updated: Scalars["Boolean"];
};

export type UpdateServiceCategoryByIdSchema = {
  fields: UpdateServiceCategoryFieldsSchema;
  id: Scalars["String"];
};

export type UpdateServiceCategoryFieldsSchema = {
  name: Scalars["String"];
};

export type UpdateSmsTemplateByClientSchema = {
  fields: UpdateSmsTemplateFieldsSchema;
  id: Scalars["String"];
};

export type UpdateSmsTemplateFieldsSchema = {
  delayInHours?: InputMaybe<Scalars["Int"]>;
  text?: InputMaybe<Scalars["String"]>;
};

export type UpdateTemplateActionsByClientResponse = {
  __typename?: "UpdateTemplateActionsByClientResponse";
  updated: Scalars["Boolean"];
};

export type UpdateTemplateActionsByClientSchema = {
  actions: Array<DistributionActions>;
  id: Scalars["String"];
};

export type UpdateTemplateByClientResponse = {
  __typename?: "UpdateTemplateByClientResponse";
  updated: Scalars["Boolean"];
};

export type UpdateTemplateByClientSchema = {
  fields: UpdateTemplateFieldsSchema;
  id: Scalars["String"];
};

export type UpdateTemplateFieldsSchema = {
  actions?: InputMaybe<Array<DistributionActions>>;
  name?: InputMaybe<Scalars["String"]>;
};

export type UpdateUserAvatarFileIdResponse = {
  __typename?: "UpdateUserAvatarFileIdResponse";
  updated: Scalars["Boolean"];
};

export type UpdateUserAvatarFileIdSchema = {
  avatarFileId: Scalars["String"];
};

export type UploadFileSchema = {
  file: Scalars["Upload"];
  usageStatus?: InputMaybe<FileUsageStatuses>;
};

export type UserEntityResponse = {
  __typename?: "UserEntityResponse";
  activated: Scalars["Boolean"];
  avatarFileId?: Maybe<Scalars["String"]>;
  createdAt: Scalars["DateTime"];
  dateOfBirth?: Maybe<Scalars["DateTime"]>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  id: Scalars["String"];
  lastName: Scalars["String"];
  roles: Array<UserRoles>;
};

/** Enum of user roles */
export enum UserRoles {
  Client = "Client",
  Doctor = "Doctor",
  Superuser = "Superuser",
  SupportManager = "SupportManager",
}

/** Enum of waitlist statuses */
export enum WaitlistStatuses {
  Closed = "Closed",
  Completed = "Completed",
  Pending = "Pending",
  PendingUnconfirmed = "PendingUnconfirmed",
}

export type FragmentServiceFragment = {
  __typename?: "ServiceEntityResponse";
  createdAt: any;
  currencyCode?: string | null;
  description?: string | null;
  durationInMinutes: number;
  hiddenFromPatients: boolean;
  icd10Code?: string | null;
  icd9Code?: string | null;
  id: string;
  name: string;
  price?: number | null;
};

export type AddPinCodeMutationVariables = Exact<{
  schema: AddPinCodeSchema;
}>;

export type AddPinCodeMutation = {
  __typename?: "Mutation";
  addPINCode: { __typename?: "AddPINCodeResponse"; added: boolean };
};

export type AttachDoctorToServiceMutationVariables = Exact<{
  schema: AttachDoctorToServiceSchema;
}>;

export type AttachDoctorToServiceMutation = {
  __typename?: "Mutation";
  attachDoctorToService: {
    __typename?: "AttachDoctorToServiceResponse";
    attached: boolean;
  };
};

export type AuthorizeWith2FaMutationVariables = Exact<{
  schema: AuthorizeWith2FaSchema;
}>;

export type AuthorizeWith2FaMutation = {
  __typename?: "Mutation";
  authorizeWith2FA: {
    __typename?: "AuthorizeWith2FAResponse";
    authorized: boolean;
  };
};

export type AuthorizeWithPinCodeMutationVariables = Exact<{
  schema: AuthorizeWithPinCodeSchema;
}>;

export type AuthorizeWithPinCodeMutation = {
  __typename?: "Mutation";
  authorizeWithPINCode: {
    __typename?: "AuthorizeWithPINCodeResponse";
    authorized: boolean;
  };
};

export type AuthorizeWithPasswordMutationVariables = Exact<{
  schema: AuthorizeWithPasswordSchema;
}>;

export type AuthorizeWithPasswordMutation = {
  __typename?: "Mutation";
  authorizeWithPassword: {
    __typename?: "AuthorizeWithPasswordResponse";
    authorized: boolean;
  };
};

export type CancelScheduleAppointmentByClientMutationVariables = Exact<{
  schema: CancelScheduleAppointmentByClientSchema;
}>;

export type CancelScheduleAppointmentByClientMutation = {
  __typename?: "Mutation";
  cancelScheduleAppointmentByClient: {
    __typename?: "CancelScheduleAppointmentByClientResponse";
    canceled: boolean;
  };
};

export type CancelScheduleAppointmentByPatientMutationVariables = Exact<{
  schema: CancelScheduleAppointmentByPatientSchema;
}>;

export type CancelScheduleAppointmentByPatientMutation = {
  __typename?: "Mutation";
  cancelScheduleAppointmentByPatient: {
    __typename?: "CancelScheduleAppointmentByPatientResponse";
    canceled: boolean;
  };
};

export type ChangeEmailMutationVariables = Exact<{
  schema: ChangeEmailSchema;
}>;

export type ChangeEmailMutation = {
  __typename?: "Mutation";
  changeEmail: { __typename?: "ChangeEmailResponse"; changed: boolean };
};

export type CloseScheduleWaitlistByClientMutationVariables = Exact<{
  schema: CloseScheduleWaitlistByClientSchema;
}>;

export type CloseScheduleWaitlistByClientMutation = {
  __typename?: "Mutation";
  closeScheduleWaitlistByClient: {
    __typename?: "CloseScheduleWaitlistByClientResponse";
    closed: boolean;
  };
};

export type CloseScheduleWaitlistByPatientMutationVariables = Exact<{
  schema: CloseScheduleWaitlistByPatientSchema;
}>;

export type CloseScheduleWaitlistByPatientMutation = {
  __typename?: "Mutation";
  closeScheduleWaitlistByPatient: {
    __typename?: "CloseScheduleWaitlistByPatientResponse";
    closed: boolean;
  };
};

export type ConfirmMailMutationVariables = Exact<{
  schema: ConfirmMailSchema;
}>;

export type ConfirmMailMutation = {
  __typename?: "Mutation";
  confirmMail: { __typename?: "ConfirmMailResponse"; confirmed: boolean };
};

export type ConfirmScheduleAppointmentByClientMutationVariables = Exact<{
  schema: ConfirmScheduleAppointmentByClientSchema;
}>;

export type ConfirmScheduleAppointmentByClientMutation = {
  __typename?: "Mutation";
  confirmScheduleAppointmentByClient: {
    __typename?: "ConfirmScheduleAppointmentByClientResponse";
    confirmed: boolean;
  };
};

export type ConfirmScheduleAppointmentByPatientMutationVariables = Exact<{
  schema: ConfirmScheduleAppointmentByPatientSchema;
}>;

export type ConfirmScheduleAppointmentByPatientMutation = {
  __typename?: "Mutation";
  confirmScheduleAppointmentByPatient: {
    __typename?: "ConfirmScheduleAppointmentByPatientResponse";
    confirmed: boolean;
  };
};

export type ConvertScheduleWaitlistToScheduleAppointmentByPatientMutationVariables =
  Exact<{
    schema: ConvertScheduleWaitlistToScheduleAppointmentByPatientSchema;
  }>;

export type ConvertScheduleWaitlistToScheduleAppointmentByPatientMutation = {
  __typename?: "Mutation";
  convertScheduleWaitlistToScheduleAppointmentByPatient: {
    __typename?: "ScheduleAppointmentEntityResponse";
    id: string;
    scheduleId: string;
    patientId: string;
    status: AppointmentStatuses;
    createdAt: any;
    updatedAt?: any | null;
  };
};

export type CreateClinicMutationVariables = Exact<{
  schema: CreateClinicSchema;
}>;

export type CreateClinicMutation = {
  __typename?: "Mutation";
  createClinic: {
    __typename?: "ClinicEntityResponse";
    address: string;
    city: string;
    code?: string | null;
    companyId: string;
    createdAt: any;
    description?: string | null;
    houseNumber?: string | null;
    id: string;
    index: string;
    licenseNumber?: string | null;
    logoFileId?: string | null;
    name: string;
    phoneNumber: string;
    geographicalCoordinates: {
      __typename?: "ClinicGeographicalCoordinatesResponse";
      latitude: number;
      longitude: number;
    };
    rooms?: Array<{
      __typename?: "ClinicRoomEntityResponse";
      clinicId: string;
      id: string;
      name: string;
    }> | null;
    workingDays?: Array<{
      __typename?: "ClinicWorkingDayEntityResponse";
      clinicId: string;
      day: ClinicWorkingDays;
      fromMinute: number;
      id: string;
      toMinute: number;
    }> | null;
  };
};

export type CreateClinicRoomMutationVariables = Exact<{
  schema: CreateClinicRoomWithClinicIdSchema;
}>;

export type CreateClinicRoomMutation = {
  __typename?: "Mutation";
  createClinicRoom: {
    __typename?: "ClinicRoomEntityResponse";
    clinicId: string;
    id: string;
    name: string;
  };
};

export type CreateClinicWorkingDayMutationVariables = Exact<{
  schema: CreateClinicWorkingDayWithClinicIdSchema;
}>;

export type CreateClinicWorkingDayMutation = {
  __typename?: "Mutation";
  createClinicWorkingDay: {
    __typename?: "ClinicWorkingDayEntityResponse";
    clinicId: string;
    day: ClinicWorkingDays;
    fromMinute: number;
    id: string;
    toMinute: number;
  };
};

export type CreateCompanyMutationVariables = Exact<{
  schema: CreateCompanySchema;
}>;

export type CreateCompanyMutation = {
  __typename?: "Mutation";
  createCompany: {
    __typename?: "CompanyEntityResponse";
    address?: string | null;
    city?: string | null;
    countryId?: string | null;
    createdAt: any;
    dateOfRegistrationInKrs?: any | null;
    email?: string | null;
    id: string;
    name: string;
    nip: string;
    ownerId: string;
    postCode?: string | null;
    regon?: string | null;
    street?: string | null;
  };
};

export type CreateDoctorInvitationMutationVariables = Exact<{
  schema: CreateDoctorInvitationSchema;
}>;

export type CreateDoctorInvitationMutation = {
  __typename?: "Mutation";
  createDoctorInvitation: {
    __typename?: "DoctorEntityResponse";
    createdAt: any;
    description?: string | null;
    doctorTypeId: string;
    id: string;
    medicalCertificateNumber: string;
    passportNumber?: string | null;
    phoneNumber: string;
    promoCode?: string | null;
    userId: string;
  };
};

export type CreateFormByClientMutationVariables = Exact<{
  schema: CreateFormSchema;
}>;

export type CreateFormByClientMutation = {
  __typename?: "Mutation";
  createFormByClient: {
    __typename?: "FormEntityResponse";
    id: string;
    name: string;
    serviceId?: string | null;
    clinicId: string;
  };
};

export type UpdateFormByClientMutationVariables = Exact<{
  schema: UpdateFormSchema;
}>;

export type UpdateFormByClientMutation = {
  __typename?: "Mutation";
  updateFormByClient: { __typename?: "UpdateFormResponse"; updated: boolean };
};

export type CreatePatientMutationVariables = Exact<{
  schema: CreatePatientSchema;
}>;

export type CreatePatientMutation = {
  __typename?: "Mutation";
  createPatient: {
    __typename?: "PatientEntityResponse";
    activated: boolean;
    comment?: string | null;
    companyId: string;
    createdAt: any;
    dateOfBirth?: string | null;
    email: string;
    emailDistribution: boolean;
    firstName: string;
    gender?: PatientGenders | null;
    id: string;
    lastName: string;
    phoneNumber: string;
    smsDistribution: boolean;
    passportNumber?: string | null;
    status: PatientStatuses;
  };
};

export type CreateScheduleMutationVariables = Exact<{
  schema: CreateScheduleSchema;
}>;

export type CreateScheduleMutation = {
  __typename?: "Mutation";
  createSchedule: {
    __typename?: "ScheduleEntityResponse";
    id: string;
    doctorId: string;
    clinicId: string;
    roomId?: string | null;
    eventType: EventTypes;
    layoutType: LayoutTypes;
    daysOfWeek?: Array<DaysOfWeek> | null;
    dateFrom?: any | null;
    dateTo?: any | null;
    minuteFrom: number;
    minuteTo: number;
    createdAt: any;
  };
};

export type CreateScheduleAppointmentByPatientMutationVariables = Exact<{
  schema: CreateScheduleAppointmentByPatientSchema;
}>;

export type CreateScheduleAppointmentByPatientMutation = {
  __typename?: "Mutation";
  createScheduleAppointmentByPatient: {
    __typename?: "CreateScheduleAppointmentByPatientResponse";
    hash: string;
  };
};

export type CreateScheduleWaitlistByClientMutationVariables = Exact<{
  schema: CreateScheduleWaitlistByClientSchema;
}>;

export type CreateScheduleWaitlistByClientMutation = {
  __typename?: "Mutation";
  createScheduleWaitlistByClient: {
    __typename?: "ScheduleWaitlistEntityResponse";
    id: string;
    hash: string;
    dateTo: string;
    createdAt: any;
    clinicId: string;
    dateFrom: string;
    minuteTo: number;
    patientId: string;
    serviceId: string;
    doctorId?: string | null;
    minuteFrom: number;
    status: WaitlistStatuses;
    uncommittedPatientId?: string | null;
  };
};

export type CreateScheduleWaitlistByPatientMutationVariables = Exact<{
  schema: CreateScheduleWaitlistByPatientSchema;
}>;

export type CreateScheduleWaitlistByPatientMutation = {
  __typename?: "Mutation";
  createScheduleWaitlistByPatient: {
    __typename?: "CreateScheduleWaitlistByPatientResponse";
    hash: string;
  };
};

export type CreateServiceMutationVariables = Exact<{
  schema: CreateServiceSchema;
}>;

export type CreateServiceMutation = {
  __typename?: "Mutation";
  createService: {
    __typename?: "ServiceEntityResponse";
    createdAt: any;
    currencyCode?: string | null;
    description?: string | null;
    durationInMinutes: number;
    hiddenFromPatients: boolean;
    icd10Code?: string | null;
    icd9Code?: string | null;
    id: string;
    name: string;
    price?: number | null;
    category?: {
      __typename?: "ServiceCategoryEntityResponse";
      id: string;
      name: string;
    } | null;
  };
};

export type CreateServiceCategoryMutationVariables = Exact<{
  schema: CreateServiceCategorySchema;
}>;

export type CreateServiceCategoryMutation = {
  __typename?: "Mutation";
  createServiceCategory: {
    __typename?: "ServiceCategoryEntityResponse";
    id: string;
    name: string;
  };
};

export type CreateScheduleAppointmentByClientMutationVariables = Exact<{
  schema: CreateScheduleAppointmentByClientSchema;
}>;

export type CreateScheduleAppointmentByClientMutation = {
  __typename?: "Mutation";
  createScheduleAppointmentByClient: {
    __typename?: "ScheduleAppointmentEntityResponse";
    createdAt: any;
    id: string;
    patientId: string;
    scheduleId: string;
    status: AppointmentStatuses;
  };
};

export type CreateScheduleAppointmentByDoctorMutationVariables = Exact<{
  schema: CreateScheduleAppointmentByDoctorSchema;
}>;

export type CreateScheduleAppointmentByDoctorMutation = {
  __typename?: "Mutation";
  createScheduleAppointmentByDoctor: {
    __typename?: "ScheduleAppointmentEntityResponse";
    createdAt: any;
    id: string;
    patientId: string;
    scheduleId: string;
    status: AppointmentStatuses;
  };
};

export type DeleteClinicByIdMutationVariables = Exact<{
  schema: DeleteClinicByIdSchema;
}>;

export type DeleteClinicByIdMutation = {
  __typename?: "Mutation";
  deleteClinicById: { __typename?: "DeleteClinicResponse"; deleted: boolean };
};

export type DeleteClinicLogoFileIdMutationVariables = Exact<{
  schema: DeleteClinicLogoFileIdSchema;
}>;

export type DeleteClinicLogoFileIdMutation = {
  __typename?: "Mutation";
  deleteClinicLogoFileId: {
    __typename?: "DeleteClinicLogoFileIdResponse";
    deleted: boolean;
  };
};

export type DeleteClinicRoomByIdMutationVariables = Exact<{
  schema: DeleteClinicRoomByIdSchema;
}>;

export type DeleteClinicRoomByIdMutation = {
  __typename?: "Mutation";
  deleteClinicRoomById: {
    __typename?: "DeleteClinicRoomResponse";
    deleted: boolean;
  };
};

export type DeleteClinicWorkingDayByIdMutationVariables = Exact<{
  schema: DeleteClinicWorkingDayByIdSchema;
}>;

export type DeleteClinicWorkingDayByIdMutation = {
  __typename?: "Mutation";
  deleteClinicWorkingDayById: {
    __typename?: "DeleteClinicWorkingDayResponse";
    deleted: boolean;
  };
};

export type MutationMutationVariables = Exact<{
  schema: DeleteDoctorByIdSchema;
}>;

export type MutationMutation = {
  __typename?: "Mutation";
  deleteDoctorById: { __typename?: "DeleteDoctorResponse"; deleted: boolean };
};

export type DeletePatientByIdMutationVariables = Exact<{
  schema: DeletePatientByIdSchema;
}>;

export type DeletePatientByIdMutation = {
  __typename?: "Mutation";
  deletePatientById: { __typename?: "DeletePatientResponse"; deleted: boolean };
};

export type DeleteScheduleByIdByClientMutationVariables = Exact<{
  schema: DeleteScheduleByIdByClientSchema;
}>;

export type DeleteScheduleByIdByClientMutation = {
  __typename?: "Mutation";
  deleteScheduleByIdByClient: {
    __typename?: "DeleteScheduleByIdByClientResponse";
    deleted: boolean;
  };
};

export type DeleteServiceByIdMutationVariables = Exact<{
  schema: DeleteServiceByIdSchema;
}>;

export type DeleteServiceByIdMutation = {
  __typename?: "Mutation";
  deleteServiceById: { __typename?: "DeleteServiceResponse"; deleted: boolean };
};

export type DeleteServiceCategoryByIdMutationVariables = Exact<{
  schema: DeleteServiceCategoryByIdSchema;
}>;

export type DeleteServiceCategoryByIdMutation = {
  __typename?: "Mutation";
  deleteServiceCategoryById: {
    __typename?: "DeleteServiceCategoryByIdResponse";
    deleted: boolean;
  };
};

export type Enable2FaMutationVariables = Exact<{
  schema: EnableTwoFactorAuthenticationSchema;
}>;

export type Enable2FaMutation = {
  __typename?: "Mutation";
  enable2FA: {
    __typename?: "EnableTwoFactorAuthenticationResponse";
    enabled: boolean;
  };
};

export type Generate2FaMutationVariables = Exact<{ [key: string]: never }>;

export type Generate2FaMutation = {
  __typename?: "Mutation";
  generate2FA: {
    __typename?: "GeneratedTwoFactorAuthenticationResponse";
    qrCodeSVG: string;
    secret: string;
  };
};

export type LocalSignInMutationVariables = Exact<{
  schema: LocalSignInSchema;
}>;

export type LocalSignInMutation = {
  __typename?: "Mutation";
  localSignIn: {
    __typename?: "LocalSignInResponse";
    signedIn: boolean;
    twoFactorAuthenticationEnabled: boolean;
  };
};

export type LocalSignUpMutationVariables = Exact<{
  schema: LocalSignUpSchema;
}>;

export type LocalSignUpMutation = {
  __typename?: "Mutation";
  localSignUp: { __typename?: "LocalSignUpResponse"; signedUp: boolean };
};

export type RateScheduleAppointmentByPatientMutationVariables = Exact<{
  schema: RateScheduleAppointmentByPatientSchema;
}>;

export type RateScheduleAppointmentByPatientMutation = {
  __typename?: "Mutation";
  rateScheduleAppointmentByPatient: {
    __typename?: "RateScheduleAppointmentByPatientResponse";
    rated: boolean;
  };
};

export type RequestEmailChangeMutationVariables = Exact<{
  schema: RequestEmailChangeSchema;
}>;

export type RequestEmailChangeMutation = {
  __typename?: "Mutation";
  requestEmailChange: {
    __typename?: "RequestEmailChangeResponse";
    requested: boolean;
  };
};

export type RequestPasswordRestorageMutationVariables = Exact<{
  schema: RequestPasswordRestorageSchema;
}>;

export type RequestPasswordRestorageMutation = {
  __typename?: "Mutation";
  requestPasswordRestorage: {
    __typename?: "RequestPasswordRestorageResponse";
    requested: boolean;
  };
};

export type RescheduleAppointmentByClientMutationVariables = Exact<{
  schema: RescheduleScheduleAppointmentByClientSchema;
}>;

export type RescheduleAppointmentByClientMutation = {
  __typename?: "Mutation";
  rescheduleAppointmentByClient: {
    __typename?: "RescheduleScheduleAppointmentByClientResponse";
    rescheduled: boolean;
  };
};

export type RescheduleScheduleAppointmentByPatientMutationVariables = Exact<{
  schema: RescheduleScheduleAppointmentByPatientSchema;
}>;

export type RescheduleScheduleAppointmentByPatientMutation = {
  __typename?: "Mutation";
  rescheduleScheduleAppointmentByPatient: {
    __typename?: "RescheduleScheduleAppointmentByPatientResponse";
    rescheduled: boolean;
  };
};

export type RescheduleWaitlistByClientMutationVariables = Exact<{
  schema: RescheduleScheduleWaitlistByClientSchema;
}>;

export type RescheduleWaitlistByClientMutation = {
  __typename?: "Mutation";
  rescheduleWaitlistByClient: {
    __typename?: "RescheduleScheduleWaitlistByClientResponse";
    rescheduled: boolean;
  };
};

export type RescheduleWaitlistByPatientMutationVariables = Exact<{
  schema: RescheduleScheduleWaitlistByPatientSchema;
}>;

export type RescheduleWaitlistByPatientMutation = {
  __typename?: "Mutation";
  rescheduleWaitlistByPatient: {
    __typename?: "RescheduleScheduleWaitlistByPatientResponse";
    rescheduled: boolean;
  };
};

export type RestorePasswordMutationVariables = Exact<{
  schema: RestorePasswordSchema;
}>;

export type RestorePasswordMutation = {
  __typename?: "Mutation";
  restorePassword: {
    __typename?: "RestorePasswordResponse";
    restored: boolean;
  };
};

export type SendRawSmsByClientMutationVariables = Exact<{
  schema: SendRawSmsByClientSchema;
}>;

export type SendRawSmsByClientMutation = {
  __typename?: "Mutation";
  sendRawSmsByClient: { __typename?: "SendRawSmsResponse"; sent: boolean };
};

export type UnattachDoctorServiceMutationVariables = Exact<{
  schema: UnattachServiceDoctorSchema;
}>;

export type UnattachDoctorServiceMutation = {
  __typename?: "Mutation";
  unattachDoctorService: {
    __typename?: "UnattachServiceDoctorResponse";
    unattached: boolean;
  };
};

export type UpdateClinicByIdMutationVariables = Exact<{
  schema: UpdateClinicByIdSchema;
}>;

export type UpdateClinicByIdMutation = {
  __typename?: "Mutation";
  updateClinicById: {
    __typename?: "ClinicEntityResponse";
    id: string;
    companyId: string;
    name: string;
    address: string;
    houseNumber?: string | null;
    index: string;
    city: string;
    logoFileId?: string | null;
    licenseNumber?: string | null;
    code?: string | null;
    phoneNumber: string;
    description?: string | null;
    createdAt: any;
    geographicalCoordinates: {
      __typename?: "ClinicGeographicalCoordinatesResponse";
      latitude: number;
      longitude: number;
    };
    rooms?: Array<{
      __typename?: "ClinicRoomEntityResponse";
      id: string;
      clinicId: string;
      name: string;
    }> | null;
    workingDays?: Array<{
      __typename?: "ClinicWorkingDayEntityResponse";
      id: string;
      clinicId: string;
      day: ClinicWorkingDays;
      fromMinute: number;
      toMinute: number;
    }> | null;
  };
};

export type UpdateClinicLogoFileIdMutationVariables = Exact<{
  schema: UpdateClinicLogoFileIdSchema;
}>;

export type UpdateClinicLogoFileIdMutation = {
  __typename?: "Mutation";
  updateClinicLogoFileId: {
    __typename?: "UpdateClinicLogoFileIdResponse";
    updated: boolean;
  };
};

export type UpdateClinicRoomByIdMutationVariables = Exact<{
  schema: UpdateClinicRoomByIdSchema;
}>;

export type UpdateClinicRoomByIdMutation = {
  __typename?: "Mutation";
  updateClinicRoomById: {
    __typename?: "UpdateClinicRoomByIdResponse";
    updated: boolean;
  };
};

export type UpdateClinicWorkingDaysByClinicIdMutationVariables = Exact<{
  schema: UpdateClinicWorkingDaysByClinicIdSchema;
}>;

export type UpdateClinicWorkingDaysByClinicIdMutation = {
  __typename?: "Mutation";
  updateClinicWorkingDaysByClinicId: {
    __typename?: "UpdateClinicWorkingDaysByClinicIdResponse";
    updated: boolean;
  };
};

export type UpdateDoctorAvatarFileIdByClientMutationVariables = Exact<{
  schema: UpdateDoctorAvatarFileIdByClientSchema;
}>;

export type UpdateDoctorAvatarFileIdByClientMutation = {
  __typename?: "Mutation";
  updateDoctorAvatarFileIdByClient: {
    __typename?: "UpdateDoctorResponse";
    updated: boolean;
  };
};

export type UpdateDoctorByIdByClientMutationVariables = Exact<{
  schema: UpdateDoctorByIdByClientSchema;
}>;

export type UpdateDoctorByIdByClientMutation = {
  __typename?: "Mutation";
  updateDoctorByIdByClient: {
    __typename?: "UpdateDoctorResponse";
    updated: boolean;
  };
};

export type UpdateDoctorByUserIdByDoctorMutationVariables = Exact<{
  schema: UpdateDoctorByUserIdByDoctorSchema;
}>;

export type UpdateDoctorByUserIdByDoctorMutation = {
  __typename?: "Mutation";
  updateDoctorByUserIdByDoctor: {
    __typename?: "UpdateDoctorResponse";
    updated: boolean;
  };
};

export type UpdateDoctorClinicsByClientMutationVariables = Exact<{
  schema: UpdateDoctorClinicsByClientSchema;
}>;

export type UpdateDoctorClinicsByClientMutation = {
  __typename?: "Mutation";
  updateDoctorClinicsByClient: {
    __typename?: "UpdateDoctorClinicsResponse";
    updated: boolean;
  };
};

export type UpdatePatientByIdMutationVariables = Exact<{
  schema: UpdatePatientByIdSchema;
}>;

export type UpdatePatientByIdMutation = {
  __typename?: "Mutation";
  updatePatientById: {
    __typename?: "UpdatePatientByIdResponse";
    updated: boolean;
  };
};

export type UpdateScheduleAppointmentMissedStatusByClientMutationVariables =
  Exact<{
    schema: UpdateScheduleAppointmentMissedSchema;
  }>;

export type UpdateScheduleAppointmentMissedStatusByClientMutation = {
  __typename?: "Mutation";
  updateScheduleAppointmentMissedStatusByClient: {
    __typename?: "UpdateScheduleAppointmentMissedStatusResponse";
    updated: boolean;
  };
};

export type UpdateScheduleAppointmentMissedStatusByDoctorMutationVariables =
  Exact<{
    schema: UpdateScheduleAppointmentMissedSchema;
  }>;

export type UpdateScheduleAppointmentMissedStatusByDoctorMutation = {
  __typename?: "Mutation";
  updateScheduleAppointmentMissedStatusByDoctor: {
    __typename?: "UpdateScheduleAppointmentMissedStatusResponse";
    updated: boolean;
  };
};

export type UpdateScheduleAppointmentStatusByIdByClientMutationVariables =
  Exact<{
    schema: UpdateScheduleAppointmentStatusByIdByClientSchema;
  }>;

export type UpdateScheduleAppointmentStatusByIdByClientMutation = {
  __typename?: "Mutation";
  updateScheduleAppointmentStatusByIdByClient: {
    __typename?: "UpdateScheduleAppointmentStatusByIdByClientResponse";
    updated: boolean;
  };
};

export type UpdateScheduleByIdByClientMutationVariables = Exact<{
  schema: UpdateScheduleByIdByClientSchema;
}>;

export type UpdateScheduleByIdByClientMutation = {
  __typename?: "Mutation";
  updateScheduleByIdByClient: {
    __typename?: "UpdateScheduleByIdByClientResponse";
    updated: boolean;
  };
};

export type UpdateServiceByIdMutationVariables = Exact<{
  schema: UpdateServiceByIdSchema;
}>;

export type UpdateServiceByIdMutation = {
  __typename?: "Mutation";
  updateServiceById: {
    __typename?: "UpdateServiceByIdResponse";
    updated: boolean;
  };
};

export type UpdateServiceCategoryByIdMutationVariables = Exact<{
  schema: UpdateServiceCategoryByIdSchema;
}>;

export type UpdateServiceCategoryByIdMutation = {
  __typename?: "Mutation";
  updateServiceCategoryById: {
    __typename?: "UpdateServiceCategoryByIdResponse";
    updated: boolean;
  };
};

export type UpdateUserAvatarFileIdMutationVariables = Exact<{
  schema: UpdateUserAvatarFileIdSchema;
}>;

export type UpdateUserAvatarFileIdMutation = {
  __typename?: "Mutation";
  updateUserAvatarFileId: {
    __typename?: "UpdateUserAvatarFileIdResponse";
    updated: boolean;
  };
};

export type UploadFileMutationVariables = Exact<{
  schema: UploadFileSchema;
}>;

export type UploadFileMutation = {
  __typename?: "Mutation";
  uploadFile: {
    __typename?: "FileEntityResponse";
    id: string;
    resourceKey: string;
    url: string;
    userId: string;
    mimetype: string;
    size: number;
    extension: string;
    usageStatus: string;
    originalName: string;
    createdAt: any;
  };
};

export type AutocompleteByClientQueryVariables = Exact<{
  schema: AutocompleteByClientSchema;
}>;

export type AutocompleteByClientQuery = {
  __typename?: "Query";
  autocompleteByClient: {
    __typename?: "AutocompleteResultByClientResponse";
    result?: Array<
      | {
          __typename?: "AggregatedScheduleAppointmentEntityWithRelationsByClientResponse";
          id: string;
          scheduleId: string;
          patientId: string;
          status: AppointmentStatuses;
          createdAt: any;
          updatedAt?: any | null;
          schedule: {
            __typename?: "ScheduleEntityResponse";
            id: string;
            doctorId: string;
            clinicId: string;
            roomId?: string | null;
            serviceId?: string | null;
            eventType: EventTypes;
            layoutType: LayoutTypes;
            daysOfWeek?: Array<DaysOfWeek> | null;
            dateFrom?: any | null;
            dateTo?: any | null;
            minuteFrom: number;
            minuteTo: number;
            createdAt: any;
          };
          patient: {
            __typename?: "PatientEntityResponse";
            id: string;
            phoneNumber: string;
            firstName: string;
            lastName: string;
            email: string;
            dateOfBirth?: string | null;
            gender?: PatientGenders | null;
            companyId: string;
            comment?: string | null;
            emailDistribution: boolean;
            smsDistribution: boolean;
            activated: boolean;
            passportNumber?: string | null;
            status: PatientStatuses;
            createdAt: any;
            languageISOCode: string;
          };
          doctor: {
            __typename?: "AggregatedDoctorEntityResponse";
            id: string;
            email: string;
            firstName: string;
            lastName: string;
            activated: boolean;
            dateOfBirth?: any | null;
            avatarFileId?: string | null;
            roles: Array<UserRoles>;
            createdAt: any;
            doctorId: string;
            doctorTypeId: string;
            phoneNumber: string;
            passportNumber?: string | null;
            medicalCertificateNumber: string;
            description?: string | null;
            promoCode?: string | null;
            address?: string | null;
            doctorCreatedAt: any;
          };
          service?: {
            __typename?: "ServiceEntityResponse";
            id: string;
            name: string;
            description?: string | null;
            icd10Code?: string | null;
            icd9Code?: string | null;
            durationInMinutes: number;
            price?: number | null;
            currencyCode?: string | null;
            hiddenFromPatients: boolean;
            createdAt: any;
            category?: {
              __typename?: "ServiceCategoryEntityResponse";
              id: string;
              name: string;
            } | null;
          } | null;
          clinic: {
            __typename?: "ClinicEntityResponse";
            id: string;
            name: string;
          };
          room?: {
            __typename?: "ClinicRoomEntityResponse";
            id: string;
            clinicId: string;
            name: string;
          } | null;
        }
      | {
          __typename?: "PatientEntityResponse";
          id: string;
          phoneNumber: string;
          firstName: string;
          lastName: string;
          email: string;
        }
    > | null;
  };
};

export type AutocompleteByDoctorQueryVariables = Exact<{
  schema: AutocompleteByDoctorSchema;
}>;

export type AutocompleteByDoctorQuery = {
  __typename?: "Query";
  autocompleteByDoctor: {
    __typename?: "AutocompleteResultByDoctorResponse";
    result?: Array<
      | {
          __typename?: "AggregatedScheduleAppointmentEntityWithRelationsByDoctorResponse";
          id: string;
          scheduleId: string;
          patientId: string;
          status: AppointmentStatuses;
          createdAt: any;
          updatedAt?: any | null;
          schedule: {
            __typename?: "ScheduleEntityResponse";
            id: string;
            doctorId: string;
            clinicId: string;
            roomId?: string | null;
            serviceId?: string | null;
            eventType: EventTypes;
            layoutType: LayoutTypes;
            daysOfWeek?: Array<DaysOfWeek> | null;
            dateFrom?: any | null;
            dateTo?: any | null;
            minuteFrom: number;
            minuteTo: number;
            createdAt: any;
          };
          patient: {
            __typename?: "PatientEntityResponse";
            id: string;
            phoneNumber: string;
            firstName: string;
            lastName: string;
            email: string;
            dateOfBirth?: string | null;
            gender?: PatientGenders | null;
            companyId: string;
            comment?: string | null;
            emailDistribution: boolean;
            smsDistribution: boolean;
            activated: boolean;
            passportNumber?: string | null;
            status: PatientStatuses;
            createdAt: any;
            languageISOCode: string;
          };
          service?: {
            __typename?: "ServiceEntityResponse";
            id: string;
            name: string;
            description?: string | null;
            icd10Code?: string | null;
            icd9Code?: string | null;
            durationInMinutes: number;
            price?: number | null;
            currencyCode?: string | null;
            hiddenFromPatients: boolean;
            createdAt: any;
            category?: {
              __typename?: "ServiceCategoryEntityResponse";
              id: string;
              name: string;
            } | null;
          } | null;
          room?: {
            __typename?: "ClinicRoomEntityResponse";
            id: string;
            clinicId: string;
            name: string;
          } | null;
        }
      | {
          __typename?: "PatientEntityResponse";
          id: string;
          phoneNumber: string;
          firstName: string;
          lastName: string;
          email: string;
        }
    > | null;
  };
};

export type CalculateAverageScheduleAppointmentRateByClientQueryVariables =
  Exact<{
    schema: CalculateAverageScheduleAppointmentRateByClientSchema;
  }>;

export type CalculateAverageScheduleAppointmentRateByClientQuery = {
  __typename?: "Query";
  calculateAverageScheduleAppointmentRateByClient: {
    __typename?: "CalculateAverageScheduleAppointmentRateResponse";
    rate: number;
  };
};

export type CalculateAverageScheduleAppointmentRateByDoctorQueryVariables =
  Exact<{
    schema: CalculateAverageScheduleAppointmentRateSchema;
  }>;

export type CalculateAverageScheduleAppointmentRateByDoctorQuery = {
  __typename?: "Query";
  calculateAverageScheduleAppointmentRateByDoctor: {
    __typename?: "CalculateAverageScheduleAppointmentRateResponse";
    rate: number;
  };
};

export type CalculateClinicConstraintsByClientQueryVariables = Exact<{
  schema: CalculateClinicConstraintsByClientSchema;
}>;

export type CalculateClinicConstraintsByClientQuery = {
  __typename?: "Query";
  calculateClinicConstraintsByClient: {
    __typename?: "ClinicConstraintResponse";
    mailLimit: number;
    mailSent: number;
    smsSent: number;
    smsLimit: number;
  };
};

export type CalculateClinicConstraintsByDoctorQueryVariables = Exact<{
  schema: CalculateClinicConstraintsByClientSchema;
}>;

export type CalculateClinicConstraintsByDoctorQuery = {
  __typename?: "Query";
  calculateClinicConstraintsByDoctor: {
    __typename?: "ClinicConstraintResponse";
    mailSent: number;
    mailLimit: number;
    smsSent: number;
    smsLimit: number;
  };
};

export type CheckIfCompanyExistsByUserIdQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CheckIfCompanyExistsByUserIdQuery = {
  __typename?: "Query";
  checkIfCompanyExistsByUserId: {
    __typename?: "CheckIfCompanyExistsByUserIdResponse";
    exists: boolean;
  };
};

export type PinCodeExistsByUserIdQueryVariables = Exact<{
  [key: string]: never;
}>;

export type PinCodeExistsByUserIdQuery = {
  __typename?: "Query";
  pinCodeExistsByUserId: {
    __typename?: "PinCodeExistsByUserIdResponse";
    exists: boolean;
  };
};

export type CountAndGroupScheduleAppointmentsByDateByClientQueryVariables =
  Exact<{
    schema?: InputMaybe<CountAndGroupScheduleAppointmentsByDateByClientSchema>;
  }>;

export type CountAndGroupScheduleAppointmentsByDateByClientQuery = {
  __typename?: "Query";
  countAndGroupScheduleAppointmentsByDateByClient: {
    __typename?: "ScheduleAppointmentCountsGroupedByDateResponse";
    counts: Array<{
      __typename?: "ScheduleAppointmentCountGroupedByDateResponse";
      count: number;
      date: string;
    }>;
  };
};

export type CountAndGroupScheduleAppointmentsByDateByDoctorQueryVariables =
  Exact<{
    schema?: InputMaybe<CountAndGroupScheduleAppointmentsByDateByDoctorSchema>;
  }>;

export type CountAndGroupScheduleAppointmentsByDateByDoctorQuery = {
  __typename?: "Query";
  countAndGroupScheduleAppointmentsByDateByDoctor: {
    __typename?: "ScheduleAppointmentCountsGroupedByDateResponse";
    counts: Array<{
      __typename?: "ScheduleAppointmentCountGroupedByDateResponse";
      count: number;
      date: string;
    }>;
  };
};

export type CountNewPatientsByClientQueryVariables = Exact<{
  schema: CountNewPatientsByClientSchema;
}>;

export type CountNewPatientsByClientQuery = {
  __typename?: "Query";
  countNewPatientsByClient: {
    __typename?: "PatientsCountResponse";
    count: number;
  };
};

export type CountNewPatientsByDoctorQueryVariables = Exact<{
  schema: CountNewPatientsByDoctorSchema;
}>;

export type CountNewPatientsByDoctorQuery = {
  __typename?: "Query";
  countNewPatientsByDoctor: {
    __typename?: "PatientsCountResponse";
    count: number;
  };
};

export type CountScheduleAppointmentsByStatusesByClientQueryVariables = Exact<{
  schema?: InputMaybe<CountScheduleAppointmentsByStatusesByClientSchema>;
}>;

export type CountScheduleAppointmentsByStatusesByClientQuery = {
  __typename?: "Query";
  countScheduleAppointmentsByStatusesByClient: {
    __typename?: "ScheduleAppointmentsCountByStatusesResponse";
    all: number;
    canceled: number;
    confirmedByApplication: number;
    confirmedByPatient: number;
    missed: number;
    new: number;
    successful: number;
  };
};

export type CountScheduleAppointmentsByStatusesByDoctorQueryVariables = Exact<{
  schema?: InputMaybe<CountScheduleAppointmentsByStatusesByDoctorSchema>;
}>;

export type CountScheduleAppointmentsByStatusesByDoctorQuery = {
  __typename?: "Query";
  countScheduleAppointmentsByStatusesByDoctor: {
    __typename?: "ScheduleAppointmentsCountByStatusesResponse";
    all: number;
    canceled: number;
    confirmedByApplication: number;
    confirmedByPatient: number;
    missed: number;
    new: number;
    successful: number;
  };
};

export type FindAndCountManyBlankFormsByClientQueryVariables = Exact<{
  schema: FindAndCountManyFormsByClientSchema;
}>;

export type FindAndCountManyBlankFormsByClientQuery = {
  __typename?: "Query";
  findAndCountManyBlankFormsByClient: {
    __typename?: "CountedBlankFormEntitiesResponse";
    count: number;
    forms: Array<{
      __typename?: "BlankFormWithCalculatedFieldsResponse";
      id: string;
      name: string;
      serviceId?: string | null;
      clinicId: string;
      countAnswers: number;
      updatedAt: any;
    }>;
  };
};

export type FindAndCountManyDoctorsQueryVariables = Exact<{
  schema: FindAndCountManyDoctorsByUserIdSchema;
}>;

export type FindAndCountManyDoctorsQuery = {
  __typename?: "Query";
  findAndCountManyDoctors: {
    __typename?: "CountedAggregatedDoctorEntitiesResponse";
    count: number;
    doctors: Array<{
      __typename?: "AggregatedDoctorEntityResponse";
      createdAt: any;
      description?: string | null;
      doctorTypeId: string;
      id: string;
      medicalCertificateNumber: string;
      passportNumber?: string | null;
      phoneNumber: string;
      promoCode?: string | null;
      activated: boolean;
      avatarFileId?: string | null;
      dateOfBirth?: any | null;
      doctorCreatedAt: any;
      doctorId: string;
      email: string;
      firstName: string;
      lastName: string;
      roles: Array<UserRoles>;
    }>;
  };
};

export type FindAndCountManyLastSmsByClientQueryVariables = Exact<{
  schema: FindAndCountManyLastSmsByClientSchema;
}>;

export type FindAndCountManyLastSmsByClientQuery = {
  __typename?: "Query";
  findAndCountManyLastSmsByClient: {
    __typename?: "CountedAggregatedSmsEntitiesResponse";
    count: number;
    sms: Array<{
      __typename?: "AggregatedSmsEntityResponse";
      clinicId: string;
      clinicToPatient: boolean;
      content: string;
      createdAt: any;
      from: string;
      id: string;
      patientId: string;
      to: string;
      patient: {
        __typename?: "PatientEntityResponse";
        id: string;
        firstName: string;
        lastName: string;
        phoneNumber: string;
        languageISOCode: string;
      };
    }>;
  };
};

export type FindAndCountManyPatientsInWaitlistsByClientQueryVariables = Exact<{
  schema: FindAndCountManyPatientsByClientSchema;
}>;

export type FindAndCountManyPatientsInWaitlistsByClientQuery = {
  __typename?: "Query";
  findAndCountManyPatientsInWaitlistsByClient: {
    __typename?: "CountedPatientsResponse";
    count: number;
    patients: Array<{
      __typename?: "PatientEntityResponse";
      activated: boolean;
      comment?: string | null;
      companyId: string;
      createdAt: any;
      dateOfBirth?: string | null;
      email: string;
      emailDistribution: boolean;
      firstName: string;
      gender?: PatientGenders | null;
      id: string;
      lastName: string;
      passportNumber?: string | null;
      smsDistribution: boolean;
      phoneNumber: string;
      status: PatientStatuses;
      languageISOCode: string;
    }>;
  };
};

export type FindAndCountManyScheduleAppointmentsWithRelationsByClientQueryVariables =
  Exact<{
    schema: FindAndCountManyScheduleAppointmentsWithRelationsByClientSchema;
  }>;

export type FindAndCountManyScheduleAppointmentsWithRelationsByClientQuery = {
  __typename?: "Query";
  findAndCountManyScheduleAppointmentsWithRelationsByClient: {
    __typename?: "CountedAggregatedScheduleAppointmentEntitiesWithRelationsByClientResponse";
    count: number;
    appointments: Array<{
      __typename?: "AggregatedScheduleAppointmentEntityWithRelationsByClientResponse";
      id: string;
      scheduleId: string;
      patientId: string;
      status: AppointmentStatuses;
      createdAt: any;
      updatedAt?: any | null;
      schedule: {
        __typename?: "ScheduleEntityResponse";
        id: string;
        doctorId: string;
        clinicId: string;
        roomId?: string | null;
        serviceId?: string | null;
        eventType: EventTypes;
        layoutType: LayoutTypes;
        daysOfWeek?: Array<DaysOfWeek> | null;
        dateFrom?: any | null;
        dateTo?: any | null;
        minuteFrom: number;
        minuteTo: number;
        createdAt: any;
      };
      patient: {
        __typename?: "PatientEntityResponse";
        id: string;
        phoneNumber: string;
        firstName: string;
        lastName: string;
        email: string;
        dateOfBirth?: string | null;
        gender?: PatientGenders | null;
        companyId: string;
        comment?: string | null;
        emailDistribution: boolean;
        smsDistribution: boolean;
        activated: boolean;
        passportNumber?: string | null;
        status: PatientStatuses;
        address?: string | null;
        allergic?: boolean | null;
        allergens?: string | null;
        postCode?: string | null;
        createdAt: any;
        languageISOCode: string;
      };
      doctor: {
        __typename?: "AggregatedDoctorEntityResponse";
        id: string;
        email: string;
        firstName: string;
        lastName: string;
        activated: boolean;
        dateOfBirth?: any | null;
        avatarFileId?: string | null;
        roles: Array<UserRoles>;
        createdAt: any;
        doctorId: string;
        doctorTypeId: string;
        phoneNumber: string;
        passportNumber?: string | null;
        medicalCertificateNumber: string;
        description?: string | null;
        promoCode?: string | null;
        address?: string | null;
        doctorCreatedAt: any;
      };
      service?: {
        __typename?: "ServiceEntityResponse";
        id: string;
        name: string;
        description?: string | null;
        icd10Code?: string | null;
        icd9Code?: string | null;
        durationInMinutes: number;
        price?: number | null;
        currencyCode?: string | null;
        hiddenFromPatients: boolean;
        createdAt: any;
        category?: {
          __typename?: "ServiceCategoryEntityResponse";
          id: string;
          name: string;
        } | null;
      } | null;
      room?: {
        __typename?: "ClinicRoomEntityResponse";
        id: string;
        clinicId: string;
        name: string;
      } | null;
      answer?: {
        __typename?: "FormAnswerEntityWithRelationsResponse";
        id?: string | null;
        formId?: string | null;
        patientId?: string | null;
        entityType?: FormAnswerEntityTypes | null;
        entityId?: string | null;
        createdAt?: any | null;
        answers?: Array<{
          __typename?: "FormFieldAnswerResponse";
          fieldName?: string | null;
          fieldValue?: string | null;
          fieldType?: FormFieldTypes | null;
          order?: number | null;
          value?: string | null;
        }> | null;
        form: {
          __typename?: "FormEntityWithRelationsResponse";
          id: string;
          name: string;
          serviceId?: string | null;
          clinicId: string;
          updatedAt: any;
          fields: Array<{
            __typename?: "FormFieldEntityWithRelationsResponse";
            id: string;
            order: number;
            value?: string | null;
            required: boolean;
            formId: string;
            type: FormFieldTypes;
            hiddenFromExistingPatients: boolean;
            translations: Array<{
              __typename?: "FormFieldTranslationEntityResponse";
              id: string;
              name: string;
              value?: string | null;
              placeholder: string;
              fieldId: string;
              languageISOCode: LanguageIsoCodes;
            }>;
          }>;
        };
      } | null;
      clinic: {
        __typename?: "ClinicEntityResponse";
        id: string;
        companyId: string;
        name: string;
        address: string;
        houseNumber?: string | null;
        index: string;
        city: string;
        logoFileId?: string | null;
        licenseNumber?: string | null;
        code?: string | null;
        phoneNumber: string;
        description?: string | null;
        hiddenFromPatients: boolean;
        createdAt: any;
        geographicalCoordinates: {
          __typename?: "ClinicGeographicalCoordinatesResponse";
          latitude: number;
          longitude: number;
        };
        rooms?: Array<{
          __typename?: "ClinicRoomEntityResponse";
          id: string;
          clinicId: string;
          name: string;
        }> | null;
        workingDays?: Array<{
          __typename?: "ClinicWorkingDayEntityResponse";
          id: string;
          clinicId: string;
          day: ClinicWorkingDays;
          fromMinute: number;
          toMinute: number;
        }> | null;
      };
    }>;
  };
};

export type FindAndCountManyScheduleAppointmentsWithRelationsByClientPatientViewQueryVariables =
  Exact<{
    schema: FindAndCountManyScheduleAppointmentsWithRelationsByClientSchema;
  }>;

export type FindAndCountManyScheduleAppointmentsWithRelationsByClientPatientViewQuery =
  {
    __typename?: "Query";
    findAndCountManyScheduleAppointmentsWithRelationsByClient: {
      __typename?: "CountedAggregatedScheduleAppointmentEntitiesWithRelationsByClientResponse";
      count: number;
      appointments: Array<{
        __typename?: "AggregatedScheduleAppointmentEntityWithRelationsByClientResponse";
        id: string;
        scheduleId: string;
        patientId: string;
        status: AppointmentStatuses;
        createdAt: any;
        updatedAt?: any | null;
        schedule: {
          __typename?: "ScheduleEntityResponse";
          id: string;
          doctorId: string;
          clinicId: string;
          roomId?: string | null;
          serviceId?: string | null;
          eventType: EventTypes;
          layoutType: LayoutTypes;
          daysOfWeek?: Array<DaysOfWeek> | null;
          dateFrom?: any | null;
          dateTo?: any | null;
          minuteFrom: number;
          minuteTo: number;
          createdAt: any;
        };
        patient: {
          __typename?: "PatientEntityResponse";
          id: string;
          phoneNumber: string;
          firstName: string;
          lastName: string;
          email: string;
          dateOfBirth?: string | null;
          gender?: PatientGenders | null;
          companyId: string;
          comment?: string | null;
          emailDistribution: boolean;
          smsDistribution: boolean;
          activated: boolean;
          passportNumber?: string | null;
          status: PatientStatuses;
          createdAt: any;
          languageISOCode: string;
        };
        doctor: {
          __typename?: "AggregatedDoctorEntityResponse";
          id: string;
          email: string;
          firstName: string;
          lastName: string;
          activated: boolean;
          dateOfBirth?: any | null;
          avatarFileId?: string | null;
          roles: Array<UserRoles>;
          createdAt: any;
          doctorId: string;
          doctorTypeId: string;
          phoneNumber: string;
          passportNumber?: string | null;
          medicalCertificateNumber: string;
          description?: string | null;
          promoCode?: string | null;
          address?: string | null;
          doctorCreatedAt: any;
        };
        service?: {
          __typename?: "ServiceEntityResponse";
          id: string;
          name: string;
          description?: string | null;
          icd10Code?: string | null;
          icd9Code?: string | null;
          durationInMinutes: number;
          price?: number | null;
          currencyCode?: string | null;
          hiddenFromPatients: boolean;
          createdAt: any;
          category?: {
            __typename?: "ServiceCategoryEntityResponse";
            id: string;
            name: string;
          } | null;
        } | null;
        clinic: {
          __typename?: "ClinicEntityResponse";
          id: string;
          name: string;
        };
        room?: {
          __typename?: "ClinicRoomEntityResponse";
          id: string;
          clinicId: string;
          name: string;
        } | null;
        answer?: {
          __typename?: "FormAnswerEntityWithRelationsResponse";
          id?: string | null;
          formId?: string | null;
          patientId?: string | null;
          entityType?: FormAnswerEntityTypes | null;
          entityId?: string | null;
          createdAt?: any | null;
          answers?: Array<{
            __typename?: "FormFieldAnswerResponse";
            fieldName?: string | null;
            fieldValue?: string | null;
            fieldType?: FormFieldTypes | null;
            order?: number | null;
            value?: string | null;
          }> | null;
          form: {
            __typename?: "FormEntityWithRelationsResponse";
            id: string;
            name: string;
            serviceId?: string | null;
            clinicId: string;
            updatedAt: any;
            fields: Array<{
              __typename?: "FormFieldEntityWithRelationsResponse";
              id: string;
              order: number;
              value?: string | null;
              required: boolean;
              formId: string;
              type: FormFieldTypes;
              hiddenFromExistingPatients: boolean;
              translations: Array<{
                __typename?: "FormFieldTranslationEntityResponse";
                id: string;
                name: string;
                value?: string | null;
                placeholder: string;
                fieldId: string;
                languageISOCode: LanguageIsoCodes;
              }>;
            }>;
          };
        } | null;
      }>;
    };
  };

export type FindAndCountManyScheduleAppointmentsWithRelationsByDoctorQueryVariables =
  Exact<{
    schema: FindAndCountManyScheduleAppointmentsWithRelationsByDoctorSchema;
  }>;

export type FindAndCountManyScheduleAppointmentsWithRelationsByDoctorQuery = {
  __typename?: "Query";
  findAndCountManyScheduleAppointmentsWithRelationsByDoctor: {
    __typename?: "CountedAggregatedScheduleAppointmentEntitiesWithRelationsByDoctorResponse";
    count: number;
    appointments: Array<{
      __typename?: "AggregatedScheduleAppointmentEntityWithRelationsByDoctorResponse";
      id: string;
      scheduleId: string;
      patientId: string;
      status: AppointmentStatuses;
      createdAt: any;
      updatedAt?: any | null;
      schedule: {
        __typename?: "ScheduleEntityResponse";
        id: string;
        doctorId: string;
        clinicId: string;
        roomId?: string | null;
        serviceId?: string | null;
        eventType: EventTypes;
        layoutType: LayoutTypes;
        daysOfWeek?: Array<DaysOfWeek> | null;
        dateFrom?: any | null;
        dateTo?: any | null;
        minuteFrom: number;
        minuteTo: number;
        createdAt: any;
      };
      patient: {
        __typename?: "PatientEntityResponse";
        id: string;
        phoneNumber: string;
        firstName: string;
        lastName: string;
        email: string;
        dateOfBirth?: string | null;
        gender?: PatientGenders | null;
        companyId: string;
        comment?: string | null;
        emailDistribution: boolean;
        smsDistribution: boolean;
        activated: boolean;
        passportNumber?: string | null;
        status: PatientStatuses;
        address?: string | null;
        allergic?: boolean | null;
        allergens?: string | null;
        postCode?: string | null;
        createdAt: any;
        languageISOCode: string;
      };
      service?: {
        __typename?: "ServiceEntityResponse";
        id: string;
        name: string;
        description?: string | null;
        icd10Code?: string | null;
        icd9Code?: string | null;
        durationInMinutes: number;
        price?: number | null;
        currencyCode?: string | null;
        hiddenFromPatients: boolean;
        createdAt: any;
        category?: {
          __typename?: "ServiceCategoryEntityResponse";
          id: string;
          name: string;
        } | null;
      } | null;
      room?: {
        __typename?: "ClinicRoomEntityResponse";
        id: string;
        clinicId: string;
        name: string;
      } | null;
      clinic: {
        __typename?: "ClinicEntityResponse";
        id: string;
        companyId: string;
        name: string;
        address: string;
        houseNumber?: string | null;
        index: string;
        city: string;
        logoFileId?: string | null;
        licenseNumber?: string | null;
        code?: string | null;
        phoneNumber: string;
        description?: string | null;
        hiddenFromPatients: boolean;
        createdAt: any;
        geographicalCoordinates: {
          __typename?: "ClinicGeographicalCoordinatesResponse";
          latitude: number;
          longitude: number;
        };
        rooms?: Array<{
          __typename?: "ClinicRoomEntityResponse";
          id: string;
          clinicId: string;
          name: string;
        }> | null;
        workingDays?: Array<{
          __typename?: "ClinicWorkingDayEntityResponse";
          id: string;
          clinicId: string;
          day: ClinicWorkingDays;
          fromMinute: number;
          toMinute: number;
        }> | null;
      };
      answer?: {
        __typename?: "FormAnswerEntityWithRelationsResponse";
        id?: string | null;
        formId?: string | null;
        patientId?: string | null;
        entityType?: FormAnswerEntityTypes | null;
        entityId?: string | null;
        createdAt?: any | null;
        answers?: Array<{
          __typename?: "FormFieldAnswerResponse";
          fieldName?: string | null;
          fieldValue?: string | null;
          fieldType?: FormFieldTypes | null;
          order?: number | null;
          value?: string | null;
        }> | null;
        form: {
          __typename?: "FormEntityWithRelationsResponse";
          id: string;
          name: string;
          serviceId?: string | null;
          clinicId: string;
          updatedAt: any;
          fields: Array<{
            __typename?: "FormFieldEntityWithRelationsResponse";
            id: string;
            order: number;
            value?: string | null;
            required: boolean;
            formId: string;
            type: FormFieldTypes;
            hiddenFromExistingPatients: boolean;
            translations: Array<{
              __typename?: "FormFieldTranslationEntityResponse";
              id: string;
              name: string;
              value?: string | null;
              placeholder: string;
              fieldId: string;
              languageISOCode: LanguageIsoCodes;
            }>;
          }>;
        };
      } | null;
    }>;
  };
};

export type FindAndCountManyScheduleWaitlistsByClientQueryVariables = Exact<{
  schema: FindAndCountManyScheduleWaitlistsByClientSchema;
}>;

export type FindAndCountManyScheduleWaitlistsByClientQuery = {
  __typename?: "Query";
  findAndCountManyScheduleWaitlistsByClient: {
    __typename?: "CountedAggregatedScheduleWaitlistEntitiesByClientResponse";
    count: number;
    waitlists: Array<{
      __typename?: "AggregatedScheduleWaitlistEntityByClientResponse";
      clinicId: string;
      createdAt: any;
      dateFrom: string;
      dateTo: string;
      doctorId?: string | null;
      hash: string;
      id: string;
      minuteFrom: number;
      minuteTo: number;
      patientId: string;
      serviceId: string;
      status: WaitlistStatuses;
      uncommittedPatientId?: string | null;
      doctor?: {
        __typename?: "AggregatedDoctorEntityResponse";
        id: string;
        doctorId: string;
        avatarFileId?: string | null;
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
      } | null;
      patient: {
        __typename?: "PatientEntityResponse";
        firstName: string;
        lastName: string;
        phoneNumber: string;
        languageISOCode: string;
      };
      service: {
        __typename?: "ServiceEntityResponse";
        currencyCode?: string | null;
        description?: string | null;
        durationInMinutes: number;
        id: string;
        name: string;
        price?: number | null;
        category?: {
          __typename?: "ServiceCategoryEntityResponse";
          id: string;
          name: string;
        } | null;
      };
    }>;
  };
};

export type FindAndCountManySmsByClientQueryVariables = Exact<{
  schema: FindAndCountManySmsByClientSchema;
}>;

export type FindAndCountManySmsByClientQuery = {
  __typename?: "Query";
  findAndCountManySmsByClient: {
    __typename?: "CountedSmsEntitiesResponse";
    count: number;
    sms: Array<{
      __typename?: "SmsEntityResponse";
      clinicId: string;
      clinicToPatient: boolean;
      content: string;
      createdAt: any;
      from: string;
      id: string;
      patientId: string;
      to: string;
    }>;
  };
};

export type FindBlankFormByServiceIdQueryVariables = Exact<{
  schema: FindBlankFormByServiceIdSchema;
}>;

export type FindBlankFormByServiceIdQuery = {
  __typename?: "Query";
  findBlankFormByServiceId: {
    __typename?: "BlankFormEntityResponse";
    clinicId: string;
    id: string;
    name: string;
    serviceId?: string | null;
    fields: Array<{
      __typename?: "FormFieldEntityWithRelationsResponse";
      formId: string;
      hiddenFromExistingPatients: boolean;
      id: string;
      order: number;
      required: boolean;
      type: FormFieldTypes;
      value?: string | null;
      translations: Array<{
        __typename?: "FormFieldTranslationEntityResponse";
        fieldId: string;
        id: string;
        name: string;
        languageISOCode: LanguageIsoCodes;
        placeholder: string;
        value?: string | null;
      }>;
    }>;
  };
};

export type FindAndCountManyAggregatedFormsPatientsByClientQueryVariables =
  Exact<{
    schema: FindAndCountManyAggregatedFormsPatientsSchema;
  }>;

export type FindAndCountManyAggregatedFormsPatientsByClientQuery = {
  __typename?: "Query";
  findAndCountManyAggregatedFormsPatientsByClient: {
    __typename?: "CountedAggregatedPatientEntitiesResponse";
    count: number;
    patients: Array<{
      __typename?: "AggregatedPatientEntityResponse";
      id: string;
      phoneNumber: string;
      firstName: string;
      lastName: string;
      email: string;
      dateOfBirth?: string | null;
      gender?: PatientGenders | null;
      companyId: string;
      comment?: string | null;
      emailDistribution: boolean;
      smsDistribution: boolean;
      activated: boolean;
      passportNumber?: string | null;
      status: PatientStatuses;
      address?: string | null;
      allergic?: boolean | null;
      allergens?: string | null;
      postCode?: string | null;
      createdAt: any;
      languageISOCode: string;
      answers: Array<{
        __typename?: "FormAnswerEntityWithFormResponse";
        id?: string | null;
        formId?: string | null;
        patientId?: string | null;
        entityType?: FormAnswerEntityTypes | null;
        entityId?: string | null;
        createdAt?: any | null;
        answers?: Array<{
          __typename?: "FormFieldAnswerResponse";
          fieldName?: string | null;
          fieldValue?: string | null;
          fieldType?: FormFieldTypes | null;
          order?: number | null;
          value?: string | null;
        }> | null;
        form: {
          __typename?: "FormEntityResponse";
          id: string;
          name: string;
          serviceId?: string | null;
          clinicId: string;
          updatedAt: any;
        };
      }>;
    }>;
  };
};

export type FindAndCountManyAggregatedFormsPatientsByDoctorQueryVariables =
  Exact<{
    schema: FindAndCountManyAggregatedFormsPatientsSchema;
  }>;

export type FindAndCountManyAggregatedFormsPatientsByDoctorQuery = {
  __typename?: "Query";
  findAndCountManyAggregatedFormsPatientsByDoctor: {
    __typename?: "CountedAggregatedPatientEntitiesResponse";
    count: number;
    patients: Array<{
      __typename?: "AggregatedPatientEntityResponse";
      id: string;
      phoneNumber: string;
      firstName: string;
      lastName: string;
      email: string;
      dateOfBirth?: string | null;
      gender?: PatientGenders | null;
      companyId: string;
      comment?: string | null;
      emailDistribution: boolean;
      smsDistribution: boolean;
      activated: boolean;
      passportNumber?: string | null;
      status: PatientStatuses;
      address?: string | null;
      allergic?: boolean | null;
      allergens?: string | null;
      postCode?: string | null;
      createdAt: any;
      languageISOCode: string;
      answers: Array<{
        __typename?: "FormAnswerEntityWithFormResponse";
        id?: string | null;
        formId?: string | null;
        patientId?: string | null;
        entityType?: FormAnswerEntityTypes | null;
        entityId?: string | null;
        createdAt?: any | null;
        answers?: Array<{
          __typename?: "FormFieldAnswerResponse";
          fieldName?: string | null;
          fieldValue?: string | null;
          fieldType?: FormFieldTypes | null;
          order?: number | null;
          value?: string | null;
        }> | null;
        form: {
          __typename?: "FormEntityResponse";
          id: string;
          name: string;
          serviceId?: string | null;
          clinicId: string;
          updatedAt: any;
        };
      }>;
    }>;
  };
};

export type FindManyScheduleAppointmentUpdatesByIdByClientQueryVariables =
  Exact<{
    schema: FindManyScheduleAppointmentUpdatesByIdByClientSchema;
  }>;

export type FindManyScheduleAppointmentUpdatesByIdByClientQuery = {
  __typename?: "Query";
  findManyScheduleAppointmentUpdatesByIdByClient: {
    __typename?: "ScheduleAppointmentUpdateEntitiesResponse";
    appointmentUpdates: Array<{
      __typename?: "ScheduleAppointmentUpdateEntityResponse";
      appointmentId: string;
      createdAt: any;
      id: string;
      userId: string;
      original: {
        __typename?: "ScheduleAppointmentEntityWithRelationsResponse";
        createdAt: any;
        patientId: string;
        scheduleId: string;
        status: AppointmentStatuses;
        updatedAt?: any | null;
      };
      updated: {
        __typename?: "ScheduleAppointmentEntityWithRelationsResponse";
        createdAt: any;
        patientId: string;
        status: AppointmentStatuses;
        updatedAt?: any | null;
        scheduleId: string;
      };
    }>;
  };
};

export type FindManyServicesByPatientQueryVariables = Exact<{
  schema: FindManyServicesSchema;
}>;

export type FindManyServicesByPatientQuery = {
  __typename?: "Query";
  findManyServicesByPatient: {
    __typename?: "ServiceEntitiesWithRelationsResponse";
    services: Array<{
      __typename?: "ServiceEntityWithRelationsResponse";
      id: string;
      name: string;
      description?: string | null;
      icd10Code?: string | null;
      icd9Code?: string | null;
      durationInMinutes: number;
      price?: number | null;
      currencyCode?: string | null;
      hiddenFromPatients: boolean;
      createdAt: any;
      doctorIds?: Array<string> | null;
      category?: {
        __typename?: "ServiceCategoryEntityResponse";
        id: string;
        name: string;
      } | null;
      clinicRooms?: Array<{
        __typename?: "ClinicRoomEntityResponse";
        id: string;
        clinicId: string;
        name: string;
      }> | null;
    }>;
  };
};

export type FindPatientByIdQueryVariables = Exact<{
  schema: FindPatientByIdSchema;
}>;

export type FindPatientByIdQuery = {
  __typename?: "Query";
  findPatientById: {
    __typename?: "PatientEntityResponse";
    id: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    email: string;
    dateOfBirth?: string | null;
    gender?: PatientGenders | null;
    companyId: string;
    comment?: string | null;
    emailDistribution: boolean;
    smsDistribution: boolean;
    activated: boolean;
    passportNumber?: string | null;
    createdAt: any;
    status: PatientStatuses;
    allergic?: boolean | null;
    allergens?: string | null;
    languageISOCode: string;
  };
};

export type FindScheduleAppointmentWithRelationsByHashByPatientQueryVariables =
  Exact<{
    schema: FindScheduleAppointmentWithRelationsByHashByPatientSchema;
  }>;

export type FindScheduleAppointmentWithRelationsByHashByPatientQuery = {
  __typename?: "Query";
  findScheduleAppointmentWithRelationsByHashByPatient: {
    __typename?: "AggregatedScheduleAppointmentEntityWithRelationsByPatientResponse";
    status: AppointmentStatuses;
    languageISOCode: LanguageIsoCodes;
    clinic: {
      __typename?: "ClinicEntityResponse";
      name: string;
      id: string;
      logoFileId?: string | null;
      address: string;
      phoneNumber: string;
    };
    schedule: {
      __typename?: "ScheduleEntityResponse";
      dateFrom?: any | null;
      dateTo?: any | null;
      minuteFrom: number;
      minuteTo: number;
      doctorId: string;
    };
    patient: {
      __typename?: "PatientEntityResponse";
      phoneNumber: string;
      languageISOCode: string;
    };
    doctor: {
      __typename?: "AggregatedDoctorEntityResponse";
      firstName: string;
      lastName: string;
    };
    service: { __typename?: "ServiceEntityResponse"; id: string };
    client: { __typename?: "ClientEntityResponse"; id: string };
  };
};

export type FindScheduleWaitlistByHashByPatientQueryVariables = Exact<{
  schema: FindScheduleAppointmentWithRelationsByHashByPatientSchema;
}>;

export type FindScheduleWaitlistByHashByPatientQuery = {
  __typename?: "Query";
  findScheduleWaitlistByHashByPatient: {
    __typename?: "AggregatedScheduleWaitlistEntityByPatientResponse";
    id: string;
    clinicId: string;
    serviceId: string;
    patientId: string;
    doctorId?: string | null;
    dateFrom: string;
    dateTo: string;
    minuteFrom: number;
    minuteTo: number;
    status: WaitlistStatuses;
    hash: string;
    uncommittedPatientId?: string | null;
    createdAt: any;
    languageISOCode: LanguageIsoCodes;
    doctor: {
      __typename?: "AggregatedDoctorEntityResponse";
      id: string;
      email: string;
      firstName: string;
      lastName: string;
      activated: boolean;
      dateOfBirth?: any | null;
      avatarFileId?: string | null;
      roles: Array<UserRoles>;
      createdAt: any;
      doctorId: string;
      doctorTypeId: string;
      phoneNumber: string;
      passportNumber?: string | null;
      medicalCertificateNumber: string;
      description?: string | null;
      promoCode?: string | null;
      address?: string | null;
      doctorCreatedAt: any;
    };
    clinic: {
      __typename?: "ClinicEntityResponse";
      id: string;
      companyId: string;
      name: string;
      address: string;
      houseNumber?: string | null;
      index: string;
      city: string;
      logoFileId?: string | null;
      licenseNumber?: string | null;
      code?: string | null;
      phoneNumber: string;
      description?: string | null;
      hiddenFromPatients: boolean;
      createdAt: any;
      geographicalCoordinates: {
        __typename?: "ClinicGeographicalCoordinatesResponse";
        latitude: number;
        longitude: number;
      };
      rooms?: Array<{
        __typename?: "ClinicRoomEntityResponse";
        id: string;
        clinicId: string;
        name: string;
      }> | null;
      workingDays?: Array<{
        __typename?: "ClinicWorkingDayEntityResponse";
        id: string;
        clinicId: string;
        day: ClinicWorkingDays;
        fromMinute: number;
        toMinute: number;
      }> | null;
    };
    patient: {
      __typename?: "PatientEntityResponse";
      id: string;
      phoneNumber: string;
      firstName: string;
      lastName: string;
      email: string;
      dateOfBirth?: string | null;
      gender?: PatientGenders | null;
      companyId: string;
      comment?: string | null;
      emailDistribution: boolean;
      smsDistribution: boolean;
      activated: boolean;
      passportNumber?: string | null;
      status: PatientStatuses;
      address?: string | null;
      postCode?: string | null;
      createdAt: any;
      languageISOCode: string;
    };
    service: {
      __typename?: "ServiceEntityResponse";
      id: string;
      name: string;
      description?: string | null;
      icd10Code?: string | null;
      icd9Code?: string | null;
      durationInMinutes: number;
      price?: number | null;
      currencyCode?: string | null;
      hiddenFromPatients: boolean;
      createdAt: any;
      category?: {
        __typename?: "ServiceCategoryEntityResponse";
        id: string;
        name: string;
      } | null;
    };
    client: {
      __typename?: "ClientEntityResponse";
      id: string;
      userId: string;
      countryId: string;
      phoneNumber: string;
      promoCode?: string | null;
      passportNumber?: string | null;
      createdAt: any;
    };
    answer?: {
      __typename?: "FormAnswerEntityResponse";
      id?: string | null;
      formId?: string | null;
      patientId?: string | null;
      entityType?: FormAnswerEntityTypes | null;
      entityId?: string | null;
      createdAt?: any | null;
      answers?: Array<{
        __typename?: "FormFieldAnswerResponse";
        fieldName?: string | null;
        fieldValue?: string | null;
        fieldType?: FormFieldTypes | null;
        order?: number | null;
        value?: string | null;
      }> | null;
    } | null;
  };
};

export type FindSentTemplateCountByEventByClientQueryVariables = Exact<{
  schema: FindSentTemplateCountByEventSchema;
}>;

export type FindSentTemplateCountByEventByClientQuery = {
  __typename?: "Query";
  findSentTemplateCountByEventByClient: {
    __typename?: "FindSentTemplateCountByEventResponse";
    sent: number;
  };
};

export type FindSentTemplateCountByEventByDoctorQueryVariables = Exact<{
  schema: FindSentTemplateCountByEventSchema;
}>;

export type FindSentTemplateCountByEventByDoctorQuery = {
  __typename?: "Query";
  findSentTemplateCountByEventByDoctor: {
    __typename?: "FindSentTemplateCountByEventResponse";
    sent: number;
  };
};

export type GetCurrentClientQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentClientQuery = {
  __typename?: "Query";
  getCurrentClient: {
    __typename?: "ClientEntityResponse";
    countryId: string;
    createdAt: any;
    id: string;
    passportNumber?: string | null;
    phoneNumber: string;
    promoCode?: string | null;
    userId: string;
  };
};

export type GetCurrentDoctorQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentDoctorQuery = {
  __typename?: "Query";
  getCurrentDoctor: {
    __typename?: "DoctorEntityResponse";
    createdAt: any;
    description?: string | null;
    id: string;
    medicalCertificateNumber: string;
    passportNumber?: string | null;
    phoneNumber: string;
    promoCode?: string | null;
    userId: string;
  };
};

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQuery = {
  __typename?: "Query";
  getCurrentUser: {
    __typename?: "UserEntityResponse";
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    activated: boolean;
    roles: Array<UserRoles>;
    createdAt: any;
    avatarFileId?: string | null;
  };
};

export type FindAllCountriesQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllCountriesQuery = {
  __typename?: "Query";
  findAllCountries: {
    __typename?: "FindAllCountriesResponse";
    countries: Array<{
      __typename?: "CountryEntityResponse";
      id: string;
      isoAlpha2Code: string;
      isoAlpha3Code: string;
      name: string;
    }>;
  };
};

export type FindManyServiceCategoriesByClinicIdQueryVariables = Exact<{
  schema: FindManyServiceCategoriesByClinicIdSchema;
}>;

export type FindManyServiceCategoriesByClinicIdQuery = {
  __typename?: "Query";
  findManyServiceCategoriesByClinicId: {
    __typename?: "FindManyServiceCategoriesByClinicIdResponse";
    categories: Array<{
      __typename?: "ServiceCategoryEntityResponse";
      id: string;
      name: string;
    }>;
  };
};

export type FindAndCountManyClinicsQueryVariables = Exact<{
  schema: FindAndCountManyClinics;
}>;

export type FindAndCountManyClinicsQuery = {
  __typename?: "Query";
  findAndCountManyClinics: {
    __typename?: "CountedClinicsResponse";
    count: number;
    clinics: Array<{
      __typename?: "ClinicEntityResponse";
      description?: string | null;
      address: string;
      city: string;
      code?: string | null;
      companyId: string;
      createdAt: any;
      houseNumber?: string | null;
      id: string;
      index: string;
      licenseNumber?: string | null;
      logoFileId?: string | null;
      name: string;
      phoneNumber: string;
      hiddenFromPatients: boolean;
      geographicalCoordinates: {
        __typename?: "ClinicGeographicalCoordinatesResponse";
        latitude: number;
        longitude: number;
      };
      rooms?: Array<{
        __typename?: "ClinicRoomEntityResponse";
        clinicId: string;
        id: string;
        name: string;
      }> | null;
      workingDays?: Array<{
        __typename?: "ClinicWorkingDayEntityResponse";
        clinicId: string;
        fromMinute: number;
        id: string;
        toMinute: number;
        day: ClinicWorkingDays;
      }> | null;
    }>;
  };
};

export type FindAndCountManyClinicsByClientIdQueryVariables = Exact<{
  schema: FindAndCountManyClinicsByClientIdSchema;
}>;

export type FindAndCountManyClinicsByClientIdQuery = {
  __typename?: "Query";
  findAndCountManyClinicsByClientId: {
    __typename?: "CountedClinicsResponse";
    count: number;
    clinics: Array<{
      __typename?: "ClinicEntityResponse";
      id: string;
      companyId: string;
      name: string;
      address: string;
      houseNumber?: string | null;
      index: string;
      city: string;
      logoFileId?: string | null;
      licenseNumber?: string | null;
      code?: string | null;
      phoneNumber: string;
      description?: string | null;
      hiddenFromPatients: boolean;
      createdAt: any;
      geographicalCoordinates: {
        __typename?: "ClinicGeographicalCoordinatesResponse";
        latitude: number;
        longitude: number;
      };
      rooms?: Array<{
        __typename?: "ClinicRoomEntityResponse";
        id: string;
        clinicId: string;
        name: string;
      }> | null;
      workingDays?: Array<{
        __typename?: "ClinicWorkingDayEntityResponse";
        id: string;
        clinicId: string;
        day: ClinicWorkingDays;
        fromMinute: number;
        toMinute: number;
      }> | null;
    }>;
  };
};

export type FindAndCountManyDoctorsByClinicIdQueryVariables = Exact<{
  schema: FindAndCountManyDoctorsByClinicIdSchema;
}>;

export type FindAndCountManyDoctorsByClinicIdQuery = {
  __typename?: "Query";
  findAndCountManyDoctorsByClinicId: {
    __typename?: "CountedAggregatedDoctorEntitiesResponse";
    count: number;
    doctors: Array<{
      __typename?: "AggregatedDoctorEntityResponse";
      createdAt: any;
      description?: string | null;
      doctorTypeId: string;
      id: string;
      medicalCertificateNumber: string;
      passportNumber?: string | null;
      phoneNumber: string;
      promoCode?: string | null;
      activated: boolean;
      avatarFileId?: string | null;
      dateOfBirth?: any | null;
      doctorCreatedAt: any;
      doctorId: string;
      email: string;
      firstName: string;
      lastName: string;
      roles: Array<UserRoles>;
    }>;
  };
};

export type FindAndCountManyFilledFormsByClientTableViewQueryVariables = Exact<{
  schema: FindAndCountManyFormsByClientSchema;
}>;

export type FindAndCountManyFilledFormsByClientTableViewQuery = {
  __typename?: "Query";
  findAndCountManyFilledFormsByClient: {
    __typename?: "CountedAggregatedFilledFormsResponse";
    count: number;
    forms: Array<{
      __typename?: "AggregatedFilledFormEntityResponse";
      id: string;
      name: string;
      serviceId?: string | null;
      clinicId: string;
      answers: Array<{
        __typename?: "AggregatedFormAnswerEntityResponse";
        id?: string | null;
        formId?: string | null;
        patientId?: string | null;
        createdAt?: any | null;
        patient: {
          __typename?: "PatientEntityResponse";
          firstName: string;
          lastName: string;
          languageISOCode: string;
        };
      }>;
    }>;
  };
};

export type FindAndCountManyFilledFormsByClientInfoViewQueryVariables = Exact<{
  schema: FindAndCountManyFormsByClientSchema;
}>;

export type FindAndCountManyFilledFormsByClientInfoViewQuery = {
  __typename?: "Query";
  findAndCountManyFilledFormsByClient: {
    __typename?: "CountedAggregatedFilledFormsResponse";
    forms: Array<{
      __typename?: "AggregatedFilledFormEntityResponse";
      id: string;
      name: string;
      serviceId?: string | null;
      clinicId: string;
      updatedAt: any;
      answers: Array<{
        __typename?: "AggregatedFormAnswerEntityResponse";
        id?: string | null;
        formId?: string | null;
        patientId?: string | null;
        createdAt?: any | null;
        answers?: Array<{
          __typename?: "FormFieldAnswerResponse";
          fieldName?: string | null;
          fieldValue?: string | null;
          fieldType?: FormFieldTypes | null;
          order?: number | null;
          value?: string | null;
        }> | null;
        patient: {
          __typename?: "PatientEntityResponse";
          firstName: string;
          lastName: string;
          languageISOCode: string;
          id: string;
          phoneNumber: string;
          email: string;
          dateOfBirth?: string | null;
          gender?: PatientGenders | null;
          companyId: string;
          comment?: string | null;
          emailDistribution: boolean;
          smsDistribution: boolean;
          activated: boolean;
          passportNumber?: string | null;
          status: PatientStatuses;
          address?: string | null;
          allergic?: boolean | null;
          allergens?: string | null;
          postCode?: string | null;
          createdAt: any;
        };
      }>;
      fields: Array<{
        __typename?: "FormFieldEntityWithRelationsResponse";
        id: string;
        order: number;
        value?: string | null;
        required: boolean;
        formId: string;
        type: FormFieldTypes;
        hiddenFromExistingPatients: boolean;
        translations: Array<{
          __typename?: "FormFieldTranslationEntityResponse";
          id: string;
          name: string;
          value?: string | null;
          placeholder: string;
          fieldId: string;
          languageISOCode: LanguageIsoCodes;
        }>;
      }>;
    }>;
  };
};

export type FindAndCountManyFilledFormsByDoctorTableViewQueryVariables = Exact<{
  schema: FindAndCountManyFormsByDoctorSchema;
}>;

export type FindAndCountManyFilledFormsByDoctorTableViewQuery = {
  __typename?: "Query";
  findAndCountManyFilledFormsByDoctor: {
    __typename?: "CountedAggregatedFilledFormsResponse";
    count: number;
    forms: Array<{
      __typename?: "AggregatedFilledFormEntityResponse";
      id: string;
      name: string;
      serviceId?: string | null;
      clinicId: string;
      answers: Array<{
        __typename?: "AggregatedFormAnswerEntityResponse";
        id?: string | null;
        formId?: string | null;
        patientId?: string | null;
        createdAt?: any | null;
        patient: {
          __typename?: "PatientEntityResponse";
          firstName: string;
          lastName: string;
          languageISOCode: string;
        };
      }>;
    }>;
  };
};

export type FindAndCountManyFilledFormsByDoctorInfoViewQueryVariables = Exact<{
  schema: FindAndCountManyFormsByDoctorSchema;
}>;

export type FindAndCountManyFilledFormsByDoctorInfoViewQuery = {
  __typename?: "Query";
  findAndCountManyFilledFormsByDoctor: {
    __typename?: "CountedAggregatedFilledFormsResponse";
    forms: Array<{
      __typename?: "AggregatedFilledFormEntityResponse";
      id: string;
      name: string;
      serviceId?: string | null;
      clinicId: string;
      updatedAt: any;
      answers: Array<{
        __typename?: "AggregatedFormAnswerEntityResponse";
        id?: string | null;
        formId?: string | null;
        patientId?: string | null;
        createdAt?: any | null;
        answers?: Array<{
          __typename?: "FormFieldAnswerResponse";
          fieldName?: string | null;
          fieldValue?: string | null;
          fieldType?: FormFieldTypes | null;
          order?: number | null;
          value?: string | null;
        }> | null;
        patient: {
          __typename?: "PatientEntityResponse";
          id: string;
          phoneNumber: string;
          firstName: string;
          lastName: string;
          email: string;
          dateOfBirth?: string | null;
          gender?: PatientGenders | null;
          companyId: string;
          comment?: string | null;
          emailDistribution: boolean;
          smsDistribution: boolean;
          activated: boolean;
          passportNumber?: string | null;
          status: PatientStatuses;
          address?: string | null;
          allergic?: boolean | null;
          allergens?: string | null;
          postCode?: string | null;
          createdAt: any;
          languageISOCode: string;
        };
      }>;
      fields: Array<{
        __typename?: "FormFieldEntityWithRelationsResponse";
        id: string;
        order: number;
        value?: string | null;
        required: boolean;
        formId: string;
        type: FormFieldTypes;
        hiddenFromExistingPatients: boolean;
        translations: Array<{
          __typename?: "FormFieldTranslationEntityResponse";
          id: string;
          name: string;
          value?: string | null;
          placeholder: string;
          fieldId: string;
          languageISOCode: LanguageIsoCodes;
        }>;
      }>;
    }>;
  };
};

export type FindAndCountManyPatientsByClientQueryVariables = Exact<{
  schema: FindAndCountManyPatientsByClientSchema;
}>;

export type FindAndCountManyPatientsByClientQuery = {
  __typename?: "Query";
  findAndCountManyPatientsByClient: {
    __typename?: "CountedPatientsResponse";
    count: number;
    patients: Array<{
      __typename?: "PatientEntityResponse";
      id: string;
      phoneNumber: string;
      firstName: string;
      lastName: string;
      email: string;
      dateOfBirth?: string | null;
      gender?: PatientGenders | null;
      companyId: string;
      comment?: string | null;
      emailDistribution: boolean;
      smsDistribution: boolean;
      activated: boolean;
      passportNumber?: string | null;
      createdAt: any;
      status: PatientStatuses;
      languageISOCode: string;
    }>;
  };
};

export type FindAndCountManyPatientsByDoctorQueryVariables = Exact<{
  schema: FindAndCountManyPatientsByDoctorSchema;
}>;

export type FindAndCountManyPatientsByDoctorQuery = {
  __typename?: "Query";
  findAndCountManyPatientsByDoctor: {
    __typename?: "CountedPatientsResponse";
    count: number;
    patients: Array<{
      __typename?: "PatientEntityResponse";
      id: string;
      phoneNumber: string;
      firstName: string;
      lastName: string;
      email: string;
      dateOfBirth?: string | null;
      gender?: PatientGenders | null;
      companyId: string;
      comment?: string | null;
      emailDistribution: boolean;
      smsDistribution: boolean;
      activated: boolean;
      passportNumber?: string | null;
      createdAt: any;
      status: PatientStatuses;
      languageISOCode: string;
    }>;
  };
};

export type FindClinicByIdQueryVariables = Exact<{
  schema: FindClinicByIdSchema;
}>;

export type FindClinicByIdQuery = {
  __typename?: "Query";
  findClinicById: {
    __typename?: "ClinicEntityResponse";
    address: string;
    city: string;
    code?: string | null;
    companyId: string;
    id: string;
    name: string;
    houseNumber?: string | null;
    index: string;
    logoFileId?: string | null;
    licenseNumber?: string | null;
    hiddenFromPatients: boolean;
    phoneNumber: string;
    description?: string | null;
    createdAt: any;
    geographicalCoordinates: {
      __typename?: "ClinicGeographicalCoordinatesResponse";
      latitude: number;
      longitude: number;
    };
    rooms?: Array<{
      __typename?: "ClinicRoomEntityResponse";
      id: string;
      clinicId: string;
      name: string;
    }> | null;
    workingDays?: Array<{
      __typename?: "ClinicWorkingDayEntityResponse";
      id: string;
      clinicId: string;
      day: ClinicWorkingDays;
      fromMinute: number;
      toMinute: number;
    }> | null;
  };
};

export type FindCompanyByUserIdQueryVariables = Exact<{ [key: string]: never }>;

export type FindCompanyByUserIdQuery = {
  __typename?: "Query";
  findCompanyByUserId: {
    __typename?: "CompanyEntityResponse";
    address?: string | null;
    city?: string | null;
    countryId?: string | null;
    createdAt: any;
    dateOfRegistrationInKrs?: any | null;
    email?: string | null;
    id: string;
    name: string;
    nip: string;
    ownerId: string;
    postCode?: string | null;
    regon?: string | null;
    street?: string | null;
  };
};

export type FindDoctorByIdQueryVariables = Exact<{
  schema: FindDoctorByIdSchema;
}>;

export type FindDoctorByIdQuery = {
  __typename?: "Query";
  findDoctorById: {
    __typename?: "AggregatedDoctorEntityResponse";
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    activated: boolean;
    dateOfBirth?: any | null;
    avatarFileId?: string | null;
    roles: Array<UserRoles>;
    createdAt: any;
    doctorId: string;
    doctorTypeId: string;
    phoneNumber: string;
    passportNumber?: string | null;
    medicalCertificateNumber: string;
    description?: string | null;
    promoCode?: string | null;
    address?: string | null;
    doctorCreatedAt: any;
  };
};

export type FindManyAggregatedDoctorsByServiceIdQueryVariables = Exact<{
  schema: FindManyAggregatedDoctorsByServiceIdSchema;
}>;

export type FindManyAggregatedDoctorsByServiceIdQuery = {
  __typename?: "Query";
  findManyAggregatedDoctorsByServiceId: {
    __typename?: "AggregatedDoctorEntitiesResponse";
    doctors: Array<{
      __typename?: "AggregatedDoctorEntityResponse";
      id: string;
      email: string;
      firstName: string;
      lastName: string;
      activated: boolean;
      dateOfBirth?: any | null;
      avatarFileId?: string | null;
      roles: Array<UserRoles>;
      createdAt: any;
      doctorId: string;
      doctorTypeId: string;
      phoneNumber: string;
      passportNumber?: string | null;
      medicalCertificateNumber: string;
      description?: string | null;
      promoCode?: string | null;
      address?: string | null;
      doctorCreatedAt: any;
    }>;
  };
};

export type FindManyAvailableSlotsByDateRangeQueryVariables = Exact<{
  schema: FindManyAvailableSlotsByDateRangeSchema;
}>;

export type FindManyAvailableSlotsByDateRangeQuery = {
  __typename?: "Query";
  findManyAvailableSlotsByDateRange: {
    __typename?: "FindManyAvailableSlotsResponse";
    availableSlots: Array<{
      __typename?: "SlotsGroupedByDateResponse";
      date: string;
      slots: Array<{ __typename?: "SlotResponse"; from: string; to: string }>;
    }>;
  };
};

export type AvailableSlotsQueryVariables = Exact<{
  schema: FindManyAvailableSlotsSchema;
}>;

export type AvailableSlotsQuery = {
  __typename?: "Query";
  findManyAvailableSlots: {
    __typename?: "FindManyAvailableSlotsResponse";
    availableSlots: Array<{
      __typename?: "SlotsGroupedByDateResponse";
      date: string;
      slots: Array<{ __typename?: "SlotResponse"; from: string; to: string }>;
    }>;
  };
};

export type FindManyClinicRoomsByClinicIdQueryVariables = Exact<{
  schema: FindManyRoomsByClinicIdSchema;
}>;

export type FindManyClinicRoomsByClinicIdQuery = {
  __typename?: "Query";
  findManyClinicRoomsByClinicId: {
    __typename?: "FindManyRoomsByClinicIdResponse";
    rooms: Array<{
      __typename?: "ClinicRoomEntityResponse";
      clinicId: string;
      id: string;
      name: string;
    }>;
  };
};

export type FindManyCurrenciesQueryVariables = Exact<{ [key: string]: never }>;

export type FindManyCurrenciesQuery = {
  __typename?: "Query";
  findManyCurrencies: {
    __typename?: "CurrencyEntitiesResponse";
    currencies: Array<{ __typename?: "CurrencyEntityResponse"; code: string }>;
  };
};

export type FindManyDoctorClinicsByDoctorIdQueryVariables = Exact<{
  schema: FindManyDoctorClinicsByDoctorIdSchema;
}>;

export type FindManyDoctorClinicsByDoctorIdQuery = {
  __typename?: "Query";
  findManyDoctorClinicsByDoctorId: {
    __typename?: "FindManyDoctorClinicsByDoctorIdResponse";
    clinics: Array<{
      __typename?: "ClinicEntityResponse";
      id: string;
      companyId: string;
      name: string;
      address: string;
      houseNumber?: string | null;
      index: string;
      city: string;
      logoFileId?: string | null;
      licenseNumber?: string | null;
      code?: string | null;
      phoneNumber: string;
      description?: string | null;
      hiddenFromPatients: boolean;
      createdAt: any;
      geographicalCoordinates: {
        __typename?: "ClinicGeographicalCoordinatesResponse";
        latitude: number;
        longitude: number;
      };
      rooms?: Array<{
        __typename?: "ClinicRoomEntityResponse";
        id: string;
        clinicId: string;
        name: string;
      }> | null;
      workingDays?: Array<{
        __typename?: "ClinicWorkingDayEntityResponse";
        id: string;
        clinicId: string;
        day: ClinicWorkingDays;
        fromMinute: number;
        toMinute: number;
      }> | null;
    }>;
  };
};

export type FindManyDoctorTypesByLanguageIsoCodeQueryVariables = Exact<{
  schema: FindManyDoctorTypesByLanguageIsoCodeSchema;
}>;

export type FindManyDoctorTypesByLanguageIsoCodeQuery = {
  __typename?: "Query";
  findManyDoctorTypesByLanguageISOCode: {
    __typename?: "DoctorTypeEntitiesResponse";
    doctorTypes: Array<{
      __typename?: "DoctorTypeEntityResponse";
      defaultLanguageName: string;
      id: string;
      doctorTypeTranslations: Array<{
        __typename?: "DoctorTypeTranslationEntityResponse";
        doctorTypeId: string;
        id: string;
        languageISOCode: string;
        name: string;
      }>;
    }>;
  };
};

export type FindManyFilesByIdsQueryVariables = Exact<{
  schema: FindManyFilesByIdsSchema;
}>;

export type FindManyFilesByIdsQuery = {
  __typename?: "Query";
  findManyFilesByIds: {
    __typename?: "FindManyFilesByIdsResponse";
    files: Array<{
      __typename?: "FileEntityResponse";
      createdAt: any;
      extension: string;
      id: string;
      size: number;
      mimetype: string;
      originalName: string;
      resourceKey: string;
      url: string;
      usageStatus: string;
      userId: string;
    }>;
  };
};

export type FindManySchedulesByClientQueryVariables = Exact<{
  schema: FindManySchedulesByClientSchema;
}>;

export type FindManySchedulesByClientQuery = {
  __typename?: "Query";
  findManySchedulesByClient: {
    __typename?: "ScheduleEntitiesResponse";
    schedules: Array<{
      __typename?: "ScheduleEntityResponse";
      id: string;
      doctorId: string;
      clinicId: string;
      roomId?: string | null;
      eventType: EventTypes;
      layoutType: LayoutTypes;
      daysOfWeek?: Array<DaysOfWeek> | null;
      dateFrom?: any | null;
      dateTo?: any | null;
      minuteFrom: number;
      minuteTo: number;
      createdAt: any;
    }>;
  };
};

export type FindManySchedulesByDoctorQueryVariables = Exact<{
  schema: FindManySchedulesByDoctorSchema;
}>;

export type FindManySchedulesByDoctorQuery = {
  __typename?: "Query";
  findManySchedulesByDoctor: {
    __typename?: "ScheduleEntitiesResponse";
    schedules: Array<{
      __typename?: "ScheduleEntityResponse";
      id: string;
      doctorId: string;
      clinicId: string;
      roomId?: string | null;
      eventType: EventTypes;
      layoutType: LayoutTypes;
      daysOfWeek?: Array<DaysOfWeek> | null;
      dateFrom?: any | null;
      dateTo?: any | null;
      minuteFrom: number;
      minuteTo: number;
      createdAt: any;
    }>;
  };
};

export type FindManyServicesQueryVariables = Exact<{
  schema: FindManyServicesSchema;
}>;

export type FindManyServicesQuery = {
  __typename?: "Query";
  findManyServices: {
    __typename?: "ServiceEntitiesWithRelationsResponse";
    services: Array<{
      __typename?: "ServiceEntityWithRelationsResponse";
      id: string;
      name: string;
      description?: string | null;
      icd10Code?: string | null;
      icd9Code?: string | null;
      durationInMinutes: number;
      price?: number | null;
      currencyCode?: string | null;
      hiddenFromPatients: boolean;
      createdAt: any;
      doctorIds?: Array<string> | null;
      category?: {
        __typename?: "ServiceCategoryEntityResponse";
        id: string;
        name: string;
      } | null;
      clinicRooms?: Array<{
        __typename?: "ClinicRoomEntityResponse";
        id: string;
        clinicId: string;
        name: string;
      }> | null;
    }>;
  };
};

export type FindServiceByIdQueryVariables = Exact<{
  schema: FindServiceByIdSchema;
}>;

export type FindServiceByIdQuery = {
  __typename?: "Query";
  findServiceById: {
    __typename?: "ServiceEntityWithRelationsResponse";
    id: string;
    name: string;
    description?: string | null;
    icd10Code?: string | null;
    icd9Code?: string | null;
    durationInMinutes: number;
    price?: number | null;
    currencyCode?: string | null;
    hiddenFromPatients: boolean;
    createdAt: any;
    doctorIds?: Array<string> | null;
    category?: {
      __typename?: "ServiceCategoryEntityResponse";
      id: string;
      name: string;
    } | null;
    clinicRooms?: Array<{
      __typename?: "ClinicRoomEntityResponse";
      id: string;
      clinicId: string;
      name: string;
    }> | null;
  };
};

export type FindServiceCategoryByIdQueryVariables = Exact<{
  schema: FindServiceCategoryByIdSchema;
}>;

export type FindServiceCategoryByIdQuery = {
  __typename?: "Query";
  findServiceCategoryById: {
    __typename?: "ServiceCategoryEntityResponse";
    id: string;
    name: string;
  };
};

export type ScheduleAppointmentConfirmedByPatientQueryVariables = Exact<{
  schema: ScheduleAppointmentConfirmedByPatientSchema;
}>;

export type ScheduleAppointmentConfirmedByPatientQuery = {
  __typename?: "Query";
  scheduleAppointmentConfirmedByPatient: {
    __typename?: "ScheduleAppointmentConfirmedByPatientResponse";
    confirmed: boolean;
  };
};

export type ScheduleWaitlistConfirmedByPatientQueryVariables = Exact<{
  schema: ScheduleAppointmentConfirmedByPatientSchema;
}>;

export type ScheduleWaitlistConfirmedByPatientQuery = {
  __typename?: "Query";
  scheduleWaitlistConfirmedByPatient: {
    __typename?: "ScheduleAppointmentConfirmedByPatientResponse";
    confirmed: boolean;
  };
};

export type SearchByClientQueryVariables = Exact<{
  schema: SearchByClientSchema;
}>;

export type SearchByClientQuery = {
  __typename?: "Query";
  searchByClient: {
    __typename?: "SearchResultByClientResponse";
    count: number;
    entities?: Array<
      | {
          __typename?: "AggregatedScheduleAppointmentEntityWithRelationsByClientResponse";
          id: string;
          scheduleId: string;
          patientId: string;
          status: AppointmentStatuses;
          createdAt: any;
          updatedAt?: any | null;
          schedule: {
            __typename?: "ScheduleEntityResponse";
            id: string;
            doctorId: string;
            clinicId: string;
            roomId?: string | null;
            serviceId?: string | null;
            eventType: EventTypes;
            layoutType: LayoutTypes;
            daysOfWeek?: Array<DaysOfWeek> | null;
            dateFrom?: any | null;
            dateTo?: any | null;
            minuteFrom: number;
            minuteTo: number;
            createdAt: any;
          };
          patient: {
            __typename?: "PatientEntityResponse";
            id: string;
            phoneNumber: string;
            firstName: string;
            lastName: string;
            email: string;
            dateOfBirth?: string | null;
            gender?: PatientGenders | null;
            companyId: string;
            comment?: string | null;
            emailDistribution: boolean;
            smsDistribution: boolean;
            activated: boolean;
            passportNumber?: string | null;
            status: PatientStatuses;
            createdAt: any;
            languageISOCode: string;
          };
          doctor: {
            __typename?: "AggregatedDoctorEntityResponse";
            id: string;
            email: string;
            firstName: string;
            lastName: string;
            activated: boolean;
            dateOfBirth?: any | null;
            avatarFileId?: string | null;
            roles: Array<UserRoles>;
            createdAt: any;
            doctorId: string;
            doctorTypeId: string;
            phoneNumber: string;
            passportNumber?: string | null;
            medicalCertificateNumber: string;
            description?: string | null;
            promoCode?: string | null;
            address?: string | null;
            doctorCreatedAt: any;
          };
          service?: {
            __typename?: "ServiceEntityResponse";
            id: string;
            name: string;
            description?: string | null;
            icd10Code?: string | null;
            icd9Code?: string | null;
            durationInMinutes: number;
            price?: number | null;
            currencyCode?: string | null;
            hiddenFromPatients: boolean;
            createdAt: any;
            category?: {
              __typename?: "ServiceCategoryEntityResponse";
              id: string;
              name: string;
            } | null;
          } | null;
          clinic: {
            __typename?: "ClinicEntityResponse";
            id: string;
            name: string;
          };
          room?: {
            __typename?: "ClinicRoomEntityResponse";
            id: string;
            clinicId: string;
            name: string;
          } | null;
        }
      | {
          __typename?: "PatientEntityResponse";
          id: string;
          phoneNumber: string;
          firstName: string;
          lastName: string;
          email: string;
        }
    > | null;
  };
};

export type SearchByDoctorQueryVariables = Exact<{
  schema: SearchByDoctorSchema;
}>;

export type SearchByDoctorQuery = {
  __typename?: "Query";
  searchByDoctor: {
    __typename?: "SearchResultByDoctorResponse";
    count: number;
    entities?: Array<
      | {
          __typename?: "AggregatedScheduleAppointmentEntityWithRelationsByDoctorResponse";
          id: string;
          scheduleId: string;
          patientId: string;
          status: AppointmentStatuses;
          createdAt: any;
          updatedAt?: any | null;
          schedule: {
            __typename?: "ScheduleEntityResponse";
            id: string;
            doctorId: string;
            clinicId: string;
            roomId?: string | null;
            serviceId?: string | null;
            eventType: EventTypes;
            layoutType: LayoutTypes;
            daysOfWeek?: Array<DaysOfWeek> | null;
            dateFrom?: any | null;
            dateTo?: any | null;
            minuteFrom: number;
            minuteTo: number;
            createdAt: any;
          };
          patient: {
            __typename?: "PatientEntityResponse";
            id: string;
            phoneNumber: string;
            firstName: string;
            lastName: string;
            email: string;
            dateOfBirth?: string | null;
            gender?: PatientGenders | null;
            companyId: string;
            comment?: string | null;
            emailDistribution: boolean;
            smsDistribution: boolean;
            activated: boolean;
            passportNumber?: string | null;
            status: PatientStatuses;
            createdAt: any;
            languageISOCode: string;
          };
          service?: {
            __typename?: "ServiceEntityResponse";
            id: string;
            name: string;
            description?: string | null;
            icd10Code?: string | null;
            icd9Code?: string | null;
            durationInMinutes: number;
            price?: number | null;
            currencyCode?: string | null;
            hiddenFromPatients: boolean;
            createdAt: any;
            category?: {
              __typename?: "ServiceCategoryEntityResponse";
              id: string;
              name: string;
            } | null;
          } | null;
          room?: {
            __typename?: "ClinicRoomEntityResponse";
            id: string;
            clinicId: string;
            name: string;
          } | null;
        }
      | {
          __typename?: "PatientEntityResponse";
          id: string;
          phoneNumber: string;
          firstName: string;
          lastName: string;
          email: string;
        }
    > | null;
  };
};

export type FindAndCountManyTemplatesByClientQueryVariables = Exact<{
  schema: FindAndCountManyTemplatesByClientSchema;
}>;

export type FindAndCountManyTemplatesByClientQuery = {
  __typename?: "Query";
  findAndCountManyTemplatesByClient: {
    __typename?: "CountedTemplatesResponse";
    count: number;
    templates: Array<{
      __typename?: "TemplateEntityResponse";
      id: string;
      name: string;
      smsSent: number;
      mailSent: number;
      clinicId: string;
      actions: Array<DistributionActions>;
      event: DistributionSystemEvents;
      semanticLock: string;
      updatedAt?: any | null;
      createdAt: any;
      mailTemplates?: Array<{
        __typename?: "MailTemplateEntityResponse";
        id: string;
        subject: string;
        text: string;
        templateId: string;
        delayInHours: number;
        languageISOCode: string;
        createdAt: any;
      }> | null;
      smsTemplates?: Array<{
        __typename?: "SmsTemplateEntityResponse";
        id: string;
        text: string;
        templateId: string;
        languageISOCode: string;
        delayInHours: number;
        createdAt: any;
      }> | null;
      clinicsTemplates?: {
        __typename?: "ClinicsTemplatesResponse";
        clinicId: string;
        templateId?: string | null;
        event: DistributionSystemEvents;
      } | null;
    }>;
  };
};

export type FindAndCountManyTemplatesByClientGetCountQueryVariables = Exact<{
  schema: FindAndCountManyTemplatesByClientSchema;
}>;

export type FindAndCountManyTemplatesByClientGetCountQuery = {
  __typename?: "Query";
  findAndCountManyTemplatesByClient: {
    __typename?: "CountedTemplatesResponse";
    count: number;
  };
};

export type FindTemplateByClientQueryVariables = Exact<{
  schema: FindTemplateByClientSchema;
}>;

export type FindTemplateByClientQuery = {
  __typename?: "Query";
  findTemplateByClient: {
    __typename?: "TemplateEntityResponse";
    id: string;
    name: string;
    smsSent: number;
    mailSent: number;
    clinicId: string;
    actions: Array<DistributionActions>;
    event: DistributionSystemEvents;
    semanticLock: string;
    updatedAt?: any | null;
    createdAt: any;
    mailTemplates?: Array<{
      __typename?: "MailTemplateEntityResponse";
      id: string;
      subject: string;
      text: string;
      templateId: string;
      delayInHours: number;
      languageISOCode: string;
      createdAt: any;
    }> | null;
    smsTemplates?: Array<{
      __typename?: "SmsTemplateEntityResponse";
      id: string;
      text: string;
      templateId: string;
      languageISOCode: string;
      delayInHours: number;
      createdAt: any;
    }> | null;
    clinicsTemplates?: {
      __typename?: "ClinicsTemplatesResponse";
      clinicId: string;
      templateId?: string | null;
      event: DistributionSystemEvents;
    } | null;
  };
};

export type FindManyTemplateVariablesByClientQueryVariables = Exact<{
  schema: FindManyTemplateVariablesByClientSchema;
}>;

export type FindManyTemplateVariablesByClientQuery = {
  __typename?: "Query";
  findManyTemplateVariablesByClient: {
    __typename?: "FindManyTemplateVariablesByClientResponse";
    variables: Array<string>;
  };
};

export type FindDefaultMailTemplateQueryVariables = Exact<{
  schema: FindDefaultMailTemplateSchema;
}>;

export type FindDefaultMailTemplateQuery = {
  __typename?: "Query";
  findDefaultMailTemplate: {
    __typename?: "MailTemplateResponse";
    subject: string;
    text: string;
  };
};

export type FindDefaultSmsTemplateQueryVariables = Exact<{
  schema: FindDefaultSmsTemplateSchema;
}>;

export type FindDefaultSmsTemplateQuery = {
  __typename?: "Query";
  findDefaultSmsTemplate: { __typename?: "SmsTemplateResponse"; text: string };
};

export type UpdateTemplateActionsByClientMutationVariables = Exact<{
  schema: UpdateTemplateActionsByClientSchema;
}>;

export type UpdateTemplateActionsByClientMutation = {
  __typename?: "Mutation";
  updateTemplateActionsByClient: {
    __typename?: "UpdateTemplateActionsByClientResponse";
    updated: boolean;
  };
};

export type CreateTemplateMutationVariables = Exact<{
  schema: CreateTemplateSchema;
}>;

export type CreateTemplateMutation = {
  __typename?: "Mutation";
  createTemplate: {
    __typename?: "TemplateEntityResponse";
    id: string;
    name: string;
    smsSent: number;
    mailSent: number;
    clinicId: string;
    actions: Array<DistributionActions>;
    event: DistributionSystemEvents;
    semanticLock: string;
    updatedAt?: any | null;
    createdAt: any;
    mailTemplates?: Array<{
      __typename?: "MailTemplateEntityResponse";
      id: string;
      subject: string;
      text: string;
      templateId: string;
      delayInHours: number;
      languageISOCode: string;
      createdAt: any;
    }> | null;
    smsTemplates?: Array<{
      __typename?: "SmsTemplateEntityResponse";
      id: string;
      text: string;
      templateId: string;
      languageISOCode: string;
      delayInHours: number;
      createdAt: any;
    }> | null;
    clinicsTemplates?: {
      __typename?: "ClinicsTemplatesResponse";
      clinicId: string;
      templateId?: string | null;
      event: DistributionSystemEvents;
    } | null;
  };
};

export type UpdateTemplateByClientMutationVariables = Exact<{
  schema: UpdateTemplateByClientSchema;
}>;

export type UpdateTemplateByClientMutation = {
  __typename?: "Mutation";
  updateTemplateByClient: {
    __typename?: "UpdateTemplateByClientResponse";
    updated: boolean;
  };
};

export type UpdateSmsTemplateByClientMutationVariables = Exact<{
  schema: UpdateSmsTemplateByClientSchema;
}>;

export type UpdateSmsTemplateByClientMutation = {
  __typename?: "Mutation";
  updateSmsTemplateByClient: {
    __typename?: "UpdateTemplateByClientResponse";
    updated: boolean;
  };
};

export type UpdateMailTemplateByClientMutationVariables = Exact<{
  schema: UpdateMailTemplateByClientSchema;
}>;

export type UpdateMailTemplateByClientMutation = {
  __typename?: "Mutation";
  updateMailTemplateByClient: {
    __typename?: "UpdateTemplateByClientResponse";
    updated: boolean;
  };
};

export type UpdateCurrentClinicTemplateMutationVariables = Exact<{
  schema: UpdateCurrentClinicTemplateSchema;
}>;

export type UpdateCurrentClinicTemplateMutation = {
  __typename?: "Mutation";
  updateCurrentClinicTemplate: {
    __typename?: "UpdateTemplateByClientResponse";
    updated: boolean;
  };
};

export const FragmentServiceFragmentDoc = gql`
  fragment FragmentService on ServiceEntityResponse {
    createdAt
    currencyCode
    description
    durationInMinutes
    hiddenFromPatients
    icd10Code
    icd9Code
    id
    name
    price
  }
`;
export const AddPinCodeDocument = gql`
  mutation AddPINCode($schema: AddPINCodeSchema!) {
    addPINCode(schema: $schema) {
      added
    }
  }
`;
export type AddPinCodeMutationFn = Apollo.MutationFunction<
  AddPinCodeMutation,
  AddPinCodeMutationVariables
>;

/**
 * __useAddPinCodeMutation__
 *
 * To run a mutation, you first call `useAddPinCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPinCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPinCodeMutation, { data, loading, error }] = useAddPinCodeMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useAddPinCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPinCodeMutation,
    AddPinCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddPinCodeMutation, AddPinCodeMutationVariables>(
    AddPinCodeDocument,
    options
  );
}
export type AddPinCodeMutationHookResult = ReturnType<
  typeof useAddPinCodeMutation
>;
export type AddPinCodeMutationResult =
  Apollo.MutationResult<AddPinCodeMutation>;
export type AddPinCodeMutationOptions = Apollo.BaseMutationOptions<
  AddPinCodeMutation,
  AddPinCodeMutationVariables
>;
export const AttachDoctorToServiceDocument = gql`
  mutation AttachDoctorToService($schema: AttachDoctorToServiceSchema!) {
    attachDoctorToService(schema: $schema) {
      attached
    }
  }
`;
export type AttachDoctorToServiceMutationFn = Apollo.MutationFunction<
  AttachDoctorToServiceMutation,
  AttachDoctorToServiceMutationVariables
>;

/**
 * __useAttachDoctorToServiceMutation__
 *
 * To run a mutation, you first call `useAttachDoctorToServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachDoctorToServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachDoctorToServiceMutation, { data, loading, error }] = useAttachDoctorToServiceMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useAttachDoctorToServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AttachDoctorToServiceMutation,
    AttachDoctorToServiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AttachDoctorToServiceMutation,
    AttachDoctorToServiceMutationVariables
  >(AttachDoctorToServiceDocument, options);
}
export type AttachDoctorToServiceMutationHookResult = ReturnType<
  typeof useAttachDoctorToServiceMutation
>;
export type AttachDoctorToServiceMutationResult =
  Apollo.MutationResult<AttachDoctorToServiceMutation>;
export type AttachDoctorToServiceMutationOptions = Apollo.BaseMutationOptions<
  AttachDoctorToServiceMutation,
  AttachDoctorToServiceMutationVariables
>;
export const AuthorizeWith2FaDocument = gql`
  mutation AuthorizeWith2FA($schema: AuthorizeWith2FASchema!) {
    authorizeWith2FA(schema: $schema) {
      authorized
    }
  }
`;
export type AuthorizeWith2FaMutationFn = Apollo.MutationFunction<
  AuthorizeWith2FaMutation,
  AuthorizeWith2FaMutationVariables
>;

/**
 * __useAuthorizeWith2FaMutation__
 *
 * To run a mutation, you first call `useAuthorizeWith2FaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorizeWith2FaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorizeWith2FaMutation, { data, loading, error }] = useAuthorizeWith2FaMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useAuthorizeWith2FaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthorizeWith2FaMutation,
    AuthorizeWith2FaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AuthorizeWith2FaMutation,
    AuthorizeWith2FaMutationVariables
  >(AuthorizeWith2FaDocument, options);
}
export type AuthorizeWith2FaMutationHookResult = ReturnType<
  typeof useAuthorizeWith2FaMutation
>;
export type AuthorizeWith2FaMutationResult =
  Apollo.MutationResult<AuthorizeWith2FaMutation>;
export type AuthorizeWith2FaMutationOptions = Apollo.BaseMutationOptions<
  AuthorizeWith2FaMutation,
  AuthorizeWith2FaMutationVariables
>;
export const AuthorizeWithPinCodeDocument = gql`
  mutation AuthorizeWithPINCode($schema: AuthorizeWithPINCodeSchema!) {
    authorizeWithPINCode(schema: $schema) {
      authorized
    }
  }
`;
export type AuthorizeWithPinCodeMutationFn = Apollo.MutationFunction<
  AuthorizeWithPinCodeMutation,
  AuthorizeWithPinCodeMutationVariables
>;

/**
 * __useAuthorizeWithPinCodeMutation__
 *
 * To run a mutation, you first call `useAuthorizeWithPinCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorizeWithPinCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorizeWithPinCodeMutation, { data, loading, error }] = useAuthorizeWithPinCodeMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useAuthorizeWithPinCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthorizeWithPinCodeMutation,
    AuthorizeWithPinCodeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AuthorizeWithPinCodeMutation,
    AuthorizeWithPinCodeMutationVariables
  >(AuthorizeWithPinCodeDocument, options);
}
export type AuthorizeWithPinCodeMutationHookResult = ReturnType<
  typeof useAuthorizeWithPinCodeMutation
>;
export type AuthorizeWithPinCodeMutationResult =
  Apollo.MutationResult<AuthorizeWithPinCodeMutation>;
export type AuthorizeWithPinCodeMutationOptions = Apollo.BaseMutationOptions<
  AuthorizeWithPinCodeMutation,
  AuthorizeWithPinCodeMutationVariables
>;
export const AuthorizeWithPasswordDocument = gql`
  mutation AuthorizeWithPassword($schema: AuthorizeWithPasswordSchema!) {
    authorizeWithPassword(schema: $schema) {
      authorized
    }
  }
`;
export type AuthorizeWithPasswordMutationFn = Apollo.MutationFunction<
  AuthorizeWithPasswordMutation,
  AuthorizeWithPasswordMutationVariables
>;

/**
 * __useAuthorizeWithPasswordMutation__
 *
 * To run a mutation, you first call `useAuthorizeWithPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorizeWithPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorizeWithPasswordMutation, { data, loading, error }] = useAuthorizeWithPasswordMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useAuthorizeWithPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthorizeWithPasswordMutation,
    AuthorizeWithPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AuthorizeWithPasswordMutation,
    AuthorizeWithPasswordMutationVariables
  >(AuthorizeWithPasswordDocument, options);
}
export type AuthorizeWithPasswordMutationHookResult = ReturnType<
  typeof useAuthorizeWithPasswordMutation
>;
export type AuthorizeWithPasswordMutationResult =
  Apollo.MutationResult<AuthorizeWithPasswordMutation>;
export type AuthorizeWithPasswordMutationOptions = Apollo.BaseMutationOptions<
  AuthorizeWithPasswordMutation,
  AuthorizeWithPasswordMutationVariables
>;
export const CancelScheduleAppointmentByClientDocument = gql`
  mutation CancelScheduleAppointmentByClient(
    $schema: CancelScheduleAppointmentByClientSchema!
  ) {
    cancelScheduleAppointmentByClient(schema: $schema) {
      canceled
    }
  }
`;
export type CancelScheduleAppointmentByClientMutationFn =
  Apollo.MutationFunction<
    CancelScheduleAppointmentByClientMutation,
    CancelScheduleAppointmentByClientMutationVariables
  >;

/**
 * __useCancelScheduleAppointmentByClientMutation__
 *
 * To run a mutation, you first call `useCancelScheduleAppointmentByClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelScheduleAppointmentByClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelScheduleAppointmentByClientMutation, { data, loading, error }] = useCancelScheduleAppointmentByClientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCancelScheduleAppointmentByClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelScheduleAppointmentByClientMutation,
    CancelScheduleAppointmentByClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelScheduleAppointmentByClientMutation,
    CancelScheduleAppointmentByClientMutationVariables
  >(CancelScheduleAppointmentByClientDocument, options);
}
export type CancelScheduleAppointmentByClientMutationHookResult = ReturnType<
  typeof useCancelScheduleAppointmentByClientMutation
>;
export type CancelScheduleAppointmentByClientMutationResult =
  Apollo.MutationResult<CancelScheduleAppointmentByClientMutation>;
export type CancelScheduleAppointmentByClientMutationOptions =
  Apollo.BaseMutationOptions<
    CancelScheduleAppointmentByClientMutation,
    CancelScheduleAppointmentByClientMutationVariables
  >;
export const CancelScheduleAppointmentByPatientDocument = gql`
  mutation CancelScheduleAppointmentByPatient(
    $schema: CancelScheduleAppointmentByPatientSchema!
  ) {
    cancelScheduleAppointmentByPatient(schema: $schema) {
      canceled
    }
  }
`;
export type CancelScheduleAppointmentByPatientMutationFn =
  Apollo.MutationFunction<
    CancelScheduleAppointmentByPatientMutation,
    CancelScheduleAppointmentByPatientMutationVariables
  >;

/**
 * __useCancelScheduleAppointmentByPatientMutation__
 *
 * To run a mutation, you first call `useCancelScheduleAppointmentByPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelScheduleAppointmentByPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelScheduleAppointmentByPatientMutation, { data, loading, error }] = useCancelScheduleAppointmentByPatientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCancelScheduleAppointmentByPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelScheduleAppointmentByPatientMutation,
    CancelScheduleAppointmentByPatientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelScheduleAppointmentByPatientMutation,
    CancelScheduleAppointmentByPatientMutationVariables
  >(CancelScheduleAppointmentByPatientDocument, options);
}
export type CancelScheduleAppointmentByPatientMutationHookResult = ReturnType<
  typeof useCancelScheduleAppointmentByPatientMutation
>;
export type CancelScheduleAppointmentByPatientMutationResult =
  Apollo.MutationResult<CancelScheduleAppointmentByPatientMutation>;
export type CancelScheduleAppointmentByPatientMutationOptions =
  Apollo.BaseMutationOptions<
    CancelScheduleAppointmentByPatientMutation,
    CancelScheduleAppointmentByPatientMutationVariables
  >;
export const ChangeEmailDocument = gql`
  mutation ChangeEmail($schema: ChangeEmailSchema!) {
    changeEmail(schema: $schema) {
      changed
    }
  }
`;
export type ChangeEmailMutationFn = Apollo.MutationFunction<
  ChangeEmailMutation,
  ChangeEmailMutationVariables
>;

/**
 * __useChangeEmailMutation__
 *
 * To run a mutation, you first call `useChangeEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeEmailMutation, { data, loading, error }] = useChangeEmailMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useChangeEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeEmailMutation,
    ChangeEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeEmailMutation, ChangeEmailMutationVariables>(
    ChangeEmailDocument,
    options
  );
}
export type ChangeEmailMutationHookResult = ReturnType<
  typeof useChangeEmailMutation
>;
export type ChangeEmailMutationResult =
  Apollo.MutationResult<ChangeEmailMutation>;
export type ChangeEmailMutationOptions = Apollo.BaseMutationOptions<
  ChangeEmailMutation,
  ChangeEmailMutationVariables
>;
export const CloseScheduleWaitlistByClientDocument = gql`
  mutation CloseScheduleWaitlistByClient(
    $schema: CloseScheduleWaitlistByClientSchema!
  ) {
    closeScheduleWaitlistByClient(schema: $schema) {
      closed
    }
  }
`;
export type CloseScheduleWaitlistByClientMutationFn = Apollo.MutationFunction<
  CloseScheduleWaitlistByClientMutation,
  CloseScheduleWaitlistByClientMutationVariables
>;

/**
 * __useCloseScheduleWaitlistByClientMutation__
 *
 * To run a mutation, you first call `useCloseScheduleWaitlistByClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseScheduleWaitlistByClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeScheduleWaitlistByClientMutation, { data, loading, error }] = useCloseScheduleWaitlistByClientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCloseScheduleWaitlistByClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloseScheduleWaitlistByClientMutation,
    CloseScheduleWaitlistByClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CloseScheduleWaitlistByClientMutation,
    CloseScheduleWaitlistByClientMutationVariables
  >(CloseScheduleWaitlistByClientDocument, options);
}
export type CloseScheduleWaitlistByClientMutationHookResult = ReturnType<
  typeof useCloseScheduleWaitlistByClientMutation
>;
export type CloseScheduleWaitlistByClientMutationResult =
  Apollo.MutationResult<CloseScheduleWaitlistByClientMutation>;
export type CloseScheduleWaitlistByClientMutationOptions =
  Apollo.BaseMutationOptions<
    CloseScheduleWaitlistByClientMutation,
    CloseScheduleWaitlistByClientMutationVariables
  >;
export const CloseScheduleWaitlistByPatientDocument = gql`
  mutation CloseScheduleWaitlistByPatient(
    $schema: CloseScheduleWaitlistByPatientSchema!
  ) {
    closeScheduleWaitlistByPatient(schema: $schema) {
      closed
    }
  }
`;
export type CloseScheduleWaitlistByPatientMutationFn = Apollo.MutationFunction<
  CloseScheduleWaitlistByPatientMutation,
  CloseScheduleWaitlistByPatientMutationVariables
>;

/**
 * __useCloseScheduleWaitlistByPatientMutation__
 *
 * To run a mutation, you first call `useCloseScheduleWaitlistByPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseScheduleWaitlistByPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeScheduleWaitlistByPatientMutation, { data, loading, error }] = useCloseScheduleWaitlistByPatientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCloseScheduleWaitlistByPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloseScheduleWaitlistByPatientMutation,
    CloseScheduleWaitlistByPatientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CloseScheduleWaitlistByPatientMutation,
    CloseScheduleWaitlistByPatientMutationVariables
  >(CloseScheduleWaitlistByPatientDocument, options);
}
export type CloseScheduleWaitlistByPatientMutationHookResult = ReturnType<
  typeof useCloseScheduleWaitlistByPatientMutation
>;
export type CloseScheduleWaitlistByPatientMutationResult =
  Apollo.MutationResult<CloseScheduleWaitlistByPatientMutation>;
export type CloseScheduleWaitlistByPatientMutationOptions =
  Apollo.BaseMutationOptions<
    CloseScheduleWaitlistByPatientMutation,
    CloseScheduleWaitlistByPatientMutationVariables
  >;
export const ConfirmMailDocument = gql`
  mutation ConfirmMail($schema: ConfirmMailSchema!) {
    confirmMail(schema: $schema) {
      confirmed
    }
  }
`;
export type ConfirmMailMutationFn = Apollo.MutationFunction<
  ConfirmMailMutation,
  ConfirmMailMutationVariables
>;

/**
 * __useConfirmMailMutation__
 *
 * To run a mutation, you first call `useConfirmMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmMailMutation, { data, loading, error }] = useConfirmMailMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useConfirmMailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmMailMutation,
    ConfirmMailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmMailMutation, ConfirmMailMutationVariables>(
    ConfirmMailDocument,
    options
  );
}
export type ConfirmMailMutationHookResult = ReturnType<
  typeof useConfirmMailMutation
>;
export type ConfirmMailMutationResult =
  Apollo.MutationResult<ConfirmMailMutation>;
export type ConfirmMailMutationOptions = Apollo.BaseMutationOptions<
  ConfirmMailMutation,
  ConfirmMailMutationVariables
>;
export const ConfirmScheduleAppointmentByClientDocument = gql`
  mutation ConfirmScheduleAppointmentByClient(
    $schema: ConfirmScheduleAppointmentByClientSchema!
  ) {
    confirmScheduleAppointmentByClient(schema: $schema) {
      confirmed
    }
  }
`;
export type ConfirmScheduleAppointmentByClientMutationFn =
  Apollo.MutationFunction<
    ConfirmScheduleAppointmentByClientMutation,
    ConfirmScheduleAppointmentByClientMutationVariables
  >;

/**
 * __useConfirmScheduleAppointmentByClientMutation__
 *
 * To run a mutation, you first call `useConfirmScheduleAppointmentByClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmScheduleAppointmentByClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmScheduleAppointmentByClientMutation, { data, loading, error }] = useConfirmScheduleAppointmentByClientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useConfirmScheduleAppointmentByClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmScheduleAppointmentByClientMutation,
    ConfirmScheduleAppointmentByClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmScheduleAppointmentByClientMutation,
    ConfirmScheduleAppointmentByClientMutationVariables
  >(ConfirmScheduleAppointmentByClientDocument, options);
}
export type ConfirmScheduleAppointmentByClientMutationHookResult = ReturnType<
  typeof useConfirmScheduleAppointmentByClientMutation
>;
export type ConfirmScheduleAppointmentByClientMutationResult =
  Apollo.MutationResult<ConfirmScheduleAppointmentByClientMutation>;
export type ConfirmScheduleAppointmentByClientMutationOptions =
  Apollo.BaseMutationOptions<
    ConfirmScheduleAppointmentByClientMutation,
    ConfirmScheduleAppointmentByClientMutationVariables
  >;
export const ConfirmScheduleAppointmentByPatientDocument = gql`
  mutation ConfirmScheduleAppointmentByPatient(
    $schema: ConfirmScheduleAppointmentByPatientSchema!
  ) {
    confirmScheduleAppointmentByPatient(schema: $schema) {
      confirmed
    }
  }
`;
export type ConfirmScheduleAppointmentByPatientMutationFn =
  Apollo.MutationFunction<
    ConfirmScheduleAppointmentByPatientMutation,
    ConfirmScheduleAppointmentByPatientMutationVariables
  >;

/**
 * __useConfirmScheduleAppointmentByPatientMutation__
 *
 * To run a mutation, you first call `useConfirmScheduleAppointmentByPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmScheduleAppointmentByPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmScheduleAppointmentByPatientMutation, { data, loading, error }] = useConfirmScheduleAppointmentByPatientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useConfirmScheduleAppointmentByPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmScheduleAppointmentByPatientMutation,
    ConfirmScheduleAppointmentByPatientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmScheduleAppointmentByPatientMutation,
    ConfirmScheduleAppointmentByPatientMutationVariables
  >(ConfirmScheduleAppointmentByPatientDocument, options);
}
export type ConfirmScheduleAppointmentByPatientMutationHookResult = ReturnType<
  typeof useConfirmScheduleAppointmentByPatientMutation
>;
export type ConfirmScheduleAppointmentByPatientMutationResult =
  Apollo.MutationResult<ConfirmScheduleAppointmentByPatientMutation>;
export type ConfirmScheduleAppointmentByPatientMutationOptions =
  Apollo.BaseMutationOptions<
    ConfirmScheduleAppointmentByPatientMutation,
    ConfirmScheduleAppointmentByPatientMutationVariables
  >;
export const ConvertScheduleWaitlistToScheduleAppointmentByPatientDocument = gql`
  mutation ConvertScheduleWaitlistToScheduleAppointmentByPatient(
    $schema: ConvertScheduleWaitlistToScheduleAppointmentByPatientSchema!
  ) {
    convertScheduleWaitlistToScheduleAppointmentByPatient(schema: $schema) {
      id
      scheduleId
      patientId
      status
      createdAt
      updatedAt
    }
  }
`;
export type ConvertScheduleWaitlistToScheduleAppointmentByPatientMutationFn =
  Apollo.MutationFunction<
    ConvertScheduleWaitlistToScheduleAppointmentByPatientMutation,
    ConvertScheduleWaitlistToScheduleAppointmentByPatientMutationVariables
  >;

/**
 * __useConvertScheduleWaitlistToScheduleAppointmentByPatientMutation__
 *
 * To run a mutation, you first call `useConvertScheduleWaitlistToScheduleAppointmentByPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertScheduleWaitlistToScheduleAppointmentByPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertScheduleWaitlistToScheduleAppointmentByPatientMutation, { data, loading, error }] = useConvertScheduleWaitlistToScheduleAppointmentByPatientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useConvertScheduleWaitlistToScheduleAppointmentByPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConvertScheduleWaitlistToScheduleAppointmentByPatientMutation,
    ConvertScheduleWaitlistToScheduleAppointmentByPatientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConvertScheduleWaitlistToScheduleAppointmentByPatientMutation,
    ConvertScheduleWaitlistToScheduleAppointmentByPatientMutationVariables
  >(ConvertScheduleWaitlistToScheduleAppointmentByPatientDocument, options);
}
export type ConvertScheduleWaitlistToScheduleAppointmentByPatientMutationHookResult =
  ReturnType<
    typeof useConvertScheduleWaitlistToScheduleAppointmentByPatientMutation
  >;
export type ConvertScheduleWaitlistToScheduleAppointmentByPatientMutationResult =
  Apollo.MutationResult<ConvertScheduleWaitlistToScheduleAppointmentByPatientMutation>;
export type ConvertScheduleWaitlistToScheduleAppointmentByPatientMutationOptions =
  Apollo.BaseMutationOptions<
    ConvertScheduleWaitlistToScheduleAppointmentByPatientMutation,
    ConvertScheduleWaitlistToScheduleAppointmentByPatientMutationVariables
  >;
export const CreateClinicDocument = gql`
  mutation CreateClinic($schema: CreateClinicSchema!) {
    createClinic(schema: $schema) {
      address
      city
      code
      companyId
      createdAt
      description
      geographicalCoordinates {
        latitude
        longitude
      }
      houseNumber
      id
      index
      licenseNumber
      logoFileId
      name
      phoneNumber
      rooms {
        clinicId
        id
        name
      }
      workingDays {
        clinicId
        day
        fromMinute
        id
        toMinute
      }
    }
  }
`;
export type CreateClinicMutationFn = Apollo.MutationFunction<
  CreateClinicMutation,
  CreateClinicMutationVariables
>;

/**
 * __useCreateClinicMutation__
 *
 * To run a mutation, you first call `useCreateClinicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClinicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClinicMutation, { data, loading, error }] = useCreateClinicMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCreateClinicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClinicMutation,
    CreateClinicMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClinicMutation,
    CreateClinicMutationVariables
  >(CreateClinicDocument, options);
}
export type CreateClinicMutationHookResult = ReturnType<
  typeof useCreateClinicMutation
>;
export type CreateClinicMutationResult =
  Apollo.MutationResult<CreateClinicMutation>;
export type CreateClinicMutationOptions = Apollo.BaseMutationOptions<
  CreateClinicMutation,
  CreateClinicMutationVariables
>;
export const CreateClinicRoomDocument = gql`
  mutation CreateClinicRoom($schema: CreateClinicRoomWithClinicIdSchema!) {
    createClinicRoom(schema: $schema) {
      clinicId
      id
      name
    }
  }
`;
export type CreateClinicRoomMutationFn = Apollo.MutationFunction<
  CreateClinicRoomMutation,
  CreateClinicRoomMutationVariables
>;

/**
 * __useCreateClinicRoomMutation__
 *
 * To run a mutation, you first call `useCreateClinicRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClinicRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClinicRoomMutation, { data, loading, error }] = useCreateClinicRoomMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCreateClinicRoomMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClinicRoomMutation,
    CreateClinicRoomMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClinicRoomMutation,
    CreateClinicRoomMutationVariables
  >(CreateClinicRoomDocument, options);
}
export type CreateClinicRoomMutationHookResult = ReturnType<
  typeof useCreateClinicRoomMutation
>;
export type CreateClinicRoomMutationResult =
  Apollo.MutationResult<CreateClinicRoomMutation>;
export type CreateClinicRoomMutationOptions = Apollo.BaseMutationOptions<
  CreateClinicRoomMutation,
  CreateClinicRoomMutationVariables
>;
export const CreateClinicWorkingDayDocument = gql`
  mutation CreateClinicWorkingDay(
    $schema: CreateClinicWorkingDayWithClinicIdSchema!
  ) {
    createClinicWorkingDay(schema: $schema) {
      clinicId
      day
      fromMinute
      id
      toMinute
    }
  }
`;
export type CreateClinicWorkingDayMutationFn = Apollo.MutationFunction<
  CreateClinicWorkingDayMutation,
  CreateClinicWorkingDayMutationVariables
>;

/**
 * __useCreateClinicWorkingDayMutation__
 *
 * To run a mutation, you first call `useCreateClinicWorkingDayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClinicWorkingDayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClinicWorkingDayMutation, { data, loading, error }] = useCreateClinicWorkingDayMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCreateClinicWorkingDayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateClinicWorkingDayMutation,
    CreateClinicWorkingDayMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateClinicWorkingDayMutation,
    CreateClinicWorkingDayMutationVariables
  >(CreateClinicWorkingDayDocument, options);
}
export type CreateClinicWorkingDayMutationHookResult = ReturnType<
  typeof useCreateClinicWorkingDayMutation
>;
export type CreateClinicWorkingDayMutationResult =
  Apollo.MutationResult<CreateClinicWorkingDayMutation>;
export type CreateClinicWorkingDayMutationOptions = Apollo.BaseMutationOptions<
  CreateClinicWorkingDayMutation,
  CreateClinicWorkingDayMutationVariables
>;
export const CreateCompanyDocument = gql`
  mutation CreateCompany($schema: CreateCompanySchema!) {
    createCompany(schema: $schema) {
      address
      city
      countryId
      createdAt
      dateOfRegistrationInKrs
      email
      id
      name
      nip
      ownerId
      postCode
      regon
      street
    }
  }
`;
export type CreateCompanyMutationFn = Apollo.MutationFunction<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCreateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCompanyMutation,
    CreateCompanyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCompanyMutation,
    CreateCompanyMutationVariables
  >(CreateCompanyDocument, options);
}
export type CreateCompanyMutationHookResult = ReturnType<
  typeof useCreateCompanyMutation
>;
export type CreateCompanyMutationResult =
  Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>;
export const CreateDoctorInvitationDocument = gql`
  mutation CreateDoctorInvitation($schema: CreateDoctorInvitationSchema!) {
    createDoctorInvitation(schema: $schema) {
      createdAt
      description
      doctorTypeId
      id
      medicalCertificateNumber
      passportNumber
      phoneNumber
      promoCode
      userId
    }
  }
`;
export type CreateDoctorInvitationMutationFn = Apollo.MutationFunction<
  CreateDoctorInvitationMutation,
  CreateDoctorInvitationMutationVariables
>;

/**
 * __useCreateDoctorInvitationMutation__
 *
 * To run a mutation, you first call `useCreateDoctorInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDoctorInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDoctorInvitationMutation, { data, loading, error }] = useCreateDoctorInvitationMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCreateDoctorInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDoctorInvitationMutation,
    CreateDoctorInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateDoctorInvitationMutation,
    CreateDoctorInvitationMutationVariables
  >(CreateDoctorInvitationDocument, options);
}
export type CreateDoctorInvitationMutationHookResult = ReturnType<
  typeof useCreateDoctorInvitationMutation
>;
export type CreateDoctorInvitationMutationResult =
  Apollo.MutationResult<CreateDoctorInvitationMutation>;
export type CreateDoctorInvitationMutationOptions = Apollo.BaseMutationOptions<
  CreateDoctorInvitationMutation,
  CreateDoctorInvitationMutationVariables
>;
export const CreateFormByClientDocument = gql`
  mutation CreateFormByClient($schema: CreateFormSchema!) {
    createFormByClient(schema: $schema) {
      id
      name
      serviceId
      clinicId
    }
  }
`;
export type CreateFormByClientMutationFn = Apollo.MutationFunction<
  CreateFormByClientMutation,
  CreateFormByClientMutationVariables
>;

/**
 * __useCreateFormByClientMutation__
 *
 * To run a mutation, you first call `useCreateFormByClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFormByClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFormByClientMutation, { data, loading, error }] = useCreateFormByClientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCreateFormByClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFormByClientMutation,
    CreateFormByClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateFormByClientMutation,
    CreateFormByClientMutationVariables
  >(CreateFormByClientDocument, options);
}
export type CreateFormByClientMutationHookResult = ReturnType<
  typeof useCreateFormByClientMutation
>;
export type CreateFormByClientMutationResult =
  Apollo.MutationResult<CreateFormByClientMutation>;
export type CreateFormByClientMutationOptions = Apollo.BaseMutationOptions<
  CreateFormByClientMutation,
  CreateFormByClientMutationVariables
>;
export const UpdateFormByClientDocument = gql`
  mutation UpdateFormByClient($schema: UpdateFormSchema!) {
    updateFormByClient(schema: $schema) {
      updated
    }
  }
`;
export type UpdateFormByClientMutationFn = Apollo.MutationFunction<
  UpdateFormByClientMutation,
  UpdateFormByClientMutationVariables
>;

/**
 * __useUpdateFormByClientMutation__
 *
 * To run a mutation, you first call `useUpdateFormByClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFormByClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFormByClientMutation, { data, loading, error }] = useUpdateFormByClientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useUpdateFormByClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFormByClientMutation,
    UpdateFormByClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateFormByClientMutation,
    UpdateFormByClientMutationVariables
  >(UpdateFormByClientDocument, options);
}
export type UpdateFormByClientMutationHookResult = ReturnType<
  typeof useUpdateFormByClientMutation
>;
export type UpdateFormByClientMutationResult =
  Apollo.MutationResult<UpdateFormByClientMutation>;
export type UpdateFormByClientMutationOptions = Apollo.BaseMutationOptions<
  UpdateFormByClientMutation,
  UpdateFormByClientMutationVariables
>;
export const CreatePatientDocument = gql`
  mutation CreatePatient($schema: CreatePatientSchema!) {
    createPatient(schema: $schema) {
      activated
      comment
      companyId
      createdAt
      dateOfBirth
      email
      emailDistribution
      firstName
      gender
      id
      lastName
      phoneNumber
      smsDistribution
      passportNumber
      status
    }
  }
`;
export type CreatePatientMutationFn = Apollo.MutationFunction<
  CreatePatientMutation,
  CreatePatientMutationVariables
>;

/**
 * __useCreatePatientMutation__
 *
 * To run a mutation, you first call `useCreatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientMutation, { data, loading, error }] = useCreatePatientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCreatePatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePatientMutation,
    CreatePatientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePatientMutation,
    CreatePatientMutationVariables
  >(CreatePatientDocument, options);
}
export type CreatePatientMutationHookResult = ReturnType<
  typeof useCreatePatientMutation
>;
export type CreatePatientMutationResult =
  Apollo.MutationResult<CreatePatientMutation>;
export type CreatePatientMutationOptions = Apollo.BaseMutationOptions<
  CreatePatientMutation,
  CreatePatientMutationVariables
>;
export const CreateScheduleDocument = gql`
  mutation CreateSchedule($schema: CreateScheduleSchema!) {
    createSchedule(schema: $schema) {
      id
      doctorId
      clinicId
      roomId
      eventType
      layoutType
      daysOfWeek
      dateFrom
      dateTo
      minuteFrom
      minuteTo
      createdAt
    }
  }
`;
export type CreateScheduleMutationFn = Apollo.MutationFunction<
  CreateScheduleMutation,
  CreateScheduleMutationVariables
>;

/**
 * __useCreateScheduleMutation__
 *
 * To run a mutation, you first call `useCreateScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduleMutation, { data, loading, error }] = useCreateScheduleMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCreateScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateScheduleMutation,
    CreateScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateScheduleMutation,
    CreateScheduleMutationVariables
  >(CreateScheduleDocument, options);
}
export type CreateScheduleMutationHookResult = ReturnType<
  typeof useCreateScheduleMutation
>;
export type CreateScheduleMutationResult =
  Apollo.MutationResult<CreateScheduleMutation>;
export type CreateScheduleMutationOptions = Apollo.BaseMutationOptions<
  CreateScheduleMutation,
  CreateScheduleMutationVariables
>;
export const CreateScheduleAppointmentByPatientDocument = gql`
  mutation CreateScheduleAppointmentByPatient(
    $schema: CreateScheduleAppointmentByPatientSchema!
  ) {
    createScheduleAppointmentByPatient(schema: $schema) {
      hash
    }
  }
`;
export type CreateScheduleAppointmentByPatientMutationFn =
  Apollo.MutationFunction<
    CreateScheduleAppointmentByPatientMutation,
    CreateScheduleAppointmentByPatientMutationVariables
  >;

/**
 * __useCreateScheduleAppointmentByPatientMutation__
 *
 * To run a mutation, you first call `useCreateScheduleAppointmentByPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduleAppointmentByPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduleAppointmentByPatientMutation, { data, loading, error }] = useCreateScheduleAppointmentByPatientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCreateScheduleAppointmentByPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateScheduleAppointmentByPatientMutation,
    CreateScheduleAppointmentByPatientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateScheduleAppointmentByPatientMutation,
    CreateScheduleAppointmentByPatientMutationVariables
  >(CreateScheduleAppointmentByPatientDocument, options);
}
export type CreateScheduleAppointmentByPatientMutationHookResult = ReturnType<
  typeof useCreateScheduleAppointmentByPatientMutation
>;
export type CreateScheduleAppointmentByPatientMutationResult =
  Apollo.MutationResult<CreateScheduleAppointmentByPatientMutation>;
export type CreateScheduleAppointmentByPatientMutationOptions =
  Apollo.BaseMutationOptions<
    CreateScheduleAppointmentByPatientMutation,
    CreateScheduleAppointmentByPatientMutationVariables
  >;
export const CreateScheduleWaitlistByClientDocument = gql`
  mutation CreateScheduleWaitlistByClient(
    $schema: CreateScheduleWaitlistByClientSchema!
  ) {
    createScheduleWaitlistByClient(schema: $schema) {
      id
      hash
      dateTo
      createdAt
      clinicId
      dateFrom
      minuteTo
      patientId
      serviceId
      doctorId
      minuteFrom
      status
      uncommittedPatientId
    }
  }
`;
export type CreateScheduleWaitlistByClientMutationFn = Apollo.MutationFunction<
  CreateScheduleWaitlistByClientMutation,
  CreateScheduleWaitlistByClientMutationVariables
>;

/**
 * __useCreateScheduleWaitlistByClientMutation__
 *
 * To run a mutation, you first call `useCreateScheduleWaitlistByClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduleWaitlistByClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduleWaitlistByClientMutation, { data, loading, error }] = useCreateScheduleWaitlistByClientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCreateScheduleWaitlistByClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateScheduleWaitlistByClientMutation,
    CreateScheduleWaitlistByClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateScheduleWaitlistByClientMutation,
    CreateScheduleWaitlistByClientMutationVariables
  >(CreateScheduleWaitlistByClientDocument, options);
}
export type CreateScheduleWaitlistByClientMutationHookResult = ReturnType<
  typeof useCreateScheduleWaitlistByClientMutation
>;
export type CreateScheduleWaitlistByClientMutationResult =
  Apollo.MutationResult<CreateScheduleWaitlistByClientMutation>;
export type CreateScheduleWaitlistByClientMutationOptions =
  Apollo.BaseMutationOptions<
    CreateScheduleWaitlistByClientMutation,
    CreateScheduleWaitlistByClientMutationVariables
  >;
export const CreateScheduleWaitlistByPatientDocument = gql`
  mutation CreateScheduleWaitlistByPatient(
    $schema: CreateScheduleWaitlistByPatientSchema!
  ) {
    createScheduleWaitlistByPatient(schema: $schema) {
      hash
    }
  }
`;
export type CreateScheduleWaitlistByPatientMutationFn = Apollo.MutationFunction<
  CreateScheduleWaitlistByPatientMutation,
  CreateScheduleWaitlistByPatientMutationVariables
>;

/**
 * __useCreateScheduleWaitlistByPatientMutation__
 *
 * To run a mutation, you first call `useCreateScheduleWaitlistByPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduleWaitlistByPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduleWaitlistByPatientMutation, { data, loading, error }] = useCreateScheduleWaitlistByPatientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCreateScheduleWaitlistByPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateScheduleWaitlistByPatientMutation,
    CreateScheduleWaitlistByPatientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateScheduleWaitlistByPatientMutation,
    CreateScheduleWaitlistByPatientMutationVariables
  >(CreateScheduleWaitlistByPatientDocument, options);
}
export type CreateScheduleWaitlistByPatientMutationHookResult = ReturnType<
  typeof useCreateScheduleWaitlistByPatientMutation
>;
export type CreateScheduleWaitlistByPatientMutationResult =
  Apollo.MutationResult<CreateScheduleWaitlistByPatientMutation>;
export type CreateScheduleWaitlistByPatientMutationOptions =
  Apollo.BaseMutationOptions<
    CreateScheduleWaitlistByPatientMutation,
    CreateScheduleWaitlistByPatientMutationVariables
  >;
export const CreateServiceDocument = gql`
  mutation CreateService($schema: CreateServiceSchema!) {
    createService(schema: $schema) {
      category {
        id
        name
      }
      createdAt
      currencyCode
      description
      durationInMinutes
      hiddenFromPatients
      icd10Code
      icd9Code
      id
      name
      price
    }
  }
`;
export type CreateServiceMutationFn = Apollo.MutationFunction<
  CreateServiceMutation,
  CreateServiceMutationVariables
>;

/**
 * __useCreateServiceMutation__
 *
 * To run a mutation, you first call `useCreateServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceMutation, { data, loading, error }] = useCreateServiceMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCreateServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateServiceMutation,
    CreateServiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateServiceMutation,
    CreateServiceMutationVariables
  >(CreateServiceDocument, options);
}
export type CreateServiceMutationHookResult = ReturnType<
  typeof useCreateServiceMutation
>;
export type CreateServiceMutationResult =
  Apollo.MutationResult<CreateServiceMutation>;
export type CreateServiceMutationOptions = Apollo.BaseMutationOptions<
  CreateServiceMutation,
  CreateServiceMutationVariables
>;
export const CreateServiceCategoryDocument = gql`
  mutation CreateServiceCategory($schema: CreateServiceCategorySchema!) {
    createServiceCategory(schema: $schema) {
      id
      name
    }
  }
`;
export type CreateServiceCategoryMutationFn = Apollo.MutationFunction<
  CreateServiceCategoryMutation,
  CreateServiceCategoryMutationVariables
>;

/**
 * __useCreateServiceCategoryMutation__
 *
 * To run a mutation, you first call `useCreateServiceCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceCategoryMutation, { data, loading, error }] = useCreateServiceCategoryMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCreateServiceCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateServiceCategoryMutation,
    CreateServiceCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateServiceCategoryMutation,
    CreateServiceCategoryMutationVariables
  >(CreateServiceCategoryDocument, options);
}
export type CreateServiceCategoryMutationHookResult = ReturnType<
  typeof useCreateServiceCategoryMutation
>;
export type CreateServiceCategoryMutationResult =
  Apollo.MutationResult<CreateServiceCategoryMutation>;
export type CreateServiceCategoryMutationOptions = Apollo.BaseMutationOptions<
  CreateServiceCategoryMutation,
  CreateServiceCategoryMutationVariables
>;
export const CreateScheduleAppointmentByClientDocument = gql`
  mutation CreateScheduleAppointmentByClient(
    $schema: CreateScheduleAppointmentByClientSchema!
  ) {
    createScheduleAppointmentByClient(schema: $schema) {
      createdAt
      id
      patientId
      scheduleId
      status
    }
  }
`;
export type CreateScheduleAppointmentByClientMutationFn =
  Apollo.MutationFunction<
    CreateScheduleAppointmentByClientMutation,
    CreateScheduleAppointmentByClientMutationVariables
  >;

/**
 * __useCreateScheduleAppointmentByClientMutation__
 *
 * To run a mutation, you first call `useCreateScheduleAppointmentByClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduleAppointmentByClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduleAppointmentByClientMutation, { data, loading, error }] = useCreateScheduleAppointmentByClientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCreateScheduleAppointmentByClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateScheduleAppointmentByClientMutation,
    CreateScheduleAppointmentByClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateScheduleAppointmentByClientMutation,
    CreateScheduleAppointmentByClientMutationVariables
  >(CreateScheduleAppointmentByClientDocument, options);
}
export type CreateScheduleAppointmentByClientMutationHookResult = ReturnType<
  typeof useCreateScheduleAppointmentByClientMutation
>;
export type CreateScheduleAppointmentByClientMutationResult =
  Apollo.MutationResult<CreateScheduleAppointmentByClientMutation>;
export type CreateScheduleAppointmentByClientMutationOptions =
  Apollo.BaseMutationOptions<
    CreateScheduleAppointmentByClientMutation,
    CreateScheduleAppointmentByClientMutationVariables
  >;
export const CreateScheduleAppointmentByDoctorDocument = gql`
  mutation CreateScheduleAppointmentByDoctor(
    $schema: CreateScheduleAppointmentByDoctorSchema!
  ) {
    createScheduleAppointmentByDoctor(schema: $schema) {
      createdAt
      id
      patientId
      scheduleId
      status
    }
  }
`;
export type CreateScheduleAppointmentByDoctorMutationFn =
  Apollo.MutationFunction<
    CreateScheduleAppointmentByDoctorMutation,
    CreateScheduleAppointmentByDoctorMutationVariables
  >;

/**
 * __useCreateScheduleAppointmentByDoctorMutation__
 *
 * To run a mutation, you first call `useCreateScheduleAppointmentByDoctorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduleAppointmentByDoctorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduleAppointmentByDoctorMutation, { data, loading, error }] = useCreateScheduleAppointmentByDoctorMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCreateScheduleAppointmentByDoctorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateScheduleAppointmentByDoctorMutation,
    CreateScheduleAppointmentByDoctorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateScheduleAppointmentByDoctorMutation,
    CreateScheduleAppointmentByDoctorMutationVariables
  >(CreateScheduleAppointmentByDoctorDocument, options);
}
export type CreateScheduleAppointmentByDoctorMutationHookResult = ReturnType<
  typeof useCreateScheduleAppointmentByDoctorMutation
>;
export type CreateScheduleAppointmentByDoctorMutationResult =
  Apollo.MutationResult<CreateScheduleAppointmentByDoctorMutation>;
export type CreateScheduleAppointmentByDoctorMutationOptions =
  Apollo.BaseMutationOptions<
    CreateScheduleAppointmentByDoctorMutation,
    CreateScheduleAppointmentByDoctorMutationVariables
  >;
export const DeleteClinicByIdDocument = gql`
  mutation DeleteClinicById($schema: DeleteClinicByIdSchema!) {
    deleteClinicById(schema: $schema) {
      deleted
    }
  }
`;
export type DeleteClinicByIdMutationFn = Apollo.MutationFunction<
  DeleteClinicByIdMutation,
  DeleteClinicByIdMutationVariables
>;

/**
 * __useDeleteClinicByIdMutation__
 *
 * To run a mutation, you first call `useDeleteClinicByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClinicByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClinicByIdMutation, { data, loading, error }] = useDeleteClinicByIdMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useDeleteClinicByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClinicByIdMutation,
    DeleteClinicByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteClinicByIdMutation,
    DeleteClinicByIdMutationVariables
  >(DeleteClinicByIdDocument, options);
}
export type DeleteClinicByIdMutationHookResult = ReturnType<
  typeof useDeleteClinicByIdMutation
>;
export type DeleteClinicByIdMutationResult =
  Apollo.MutationResult<DeleteClinicByIdMutation>;
export type DeleteClinicByIdMutationOptions = Apollo.BaseMutationOptions<
  DeleteClinicByIdMutation,
  DeleteClinicByIdMutationVariables
>;
export const DeleteClinicLogoFileIdDocument = gql`
  mutation DeleteClinicLogoFileId($schema: DeleteClinicLogoFileIdSchema!) {
    deleteClinicLogoFileId(schema: $schema) {
      deleted
    }
  }
`;
export type DeleteClinicLogoFileIdMutationFn = Apollo.MutationFunction<
  DeleteClinicLogoFileIdMutation,
  DeleteClinicLogoFileIdMutationVariables
>;

/**
 * __useDeleteClinicLogoFileIdMutation__
 *
 * To run a mutation, you first call `useDeleteClinicLogoFileIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClinicLogoFileIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClinicLogoFileIdMutation, { data, loading, error }] = useDeleteClinicLogoFileIdMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useDeleteClinicLogoFileIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClinicLogoFileIdMutation,
    DeleteClinicLogoFileIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteClinicLogoFileIdMutation,
    DeleteClinicLogoFileIdMutationVariables
  >(DeleteClinicLogoFileIdDocument, options);
}
export type DeleteClinicLogoFileIdMutationHookResult = ReturnType<
  typeof useDeleteClinicLogoFileIdMutation
>;
export type DeleteClinicLogoFileIdMutationResult =
  Apollo.MutationResult<DeleteClinicLogoFileIdMutation>;
export type DeleteClinicLogoFileIdMutationOptions = Apollo.BaseMutationOptions<
  DeleteClinicLogoFileIdMutation,
  DeleteClinicLogoFileIdMutationVariables
>;
export const DeleteClinicRoomByIdDocument = gql`
  mutation DeleteClinicRoomById($schema: DeleteClinicRoomByIdSchema!) {
    deleteClinicRoomById(schema: $schema) {
      deleted
    }
  }
`;
export type DeleteClinicRoomByIdMutationFn = Apollo.MutationFunction<
  DeleteClinicRoomByIdMutation,
  DeleteClinicRoomByIdMutationVariables
>;

/**
 * __useDeleteClinicRoomByIdMutation__
 *
 * To run a mutation, you first call `useDeleteClinicRoomByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClinicRoomByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClinicRoomByIdMutation, { data, loading, error }] = useDeleteClinicRoomByIdMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useDeleteClinicRoomByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClinicRoomByIdMutation,
    DeleteClinicRoomByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteClinicRoomByIdMutation,
    DeleteClinicRoomByIdMutationVariables
  >(DeleteClinicRoomByIdDocument, options);
}
export type DeleteClinicRoomByIdMutationHookResult = ReturnType<
  typeof useDeleteClinicRoomByIdMutation
>;
export type DeleteClinicRoomByIdMutationResult =
  Apollo.MutationResult<DeleteClinicRoomByIdMutation>;
export type DeleteClinicRoomByIdMutationOptions = Apollo.BaseMutationOptions<
  DeleteClinicRoomByIdMutation,
  DeleteClinicRoomByIdMutationVariables
>;
export const DeleteClinicWorkingDayByIdDocument = gql`
  mutation DeleteClinicWorkingDayById(
    $schema: DeleteClinicWorkingDayByIdSchema!
  ) {
    deleteClinicWorkingDayById(schema: $schema) {
      deleted
    }
  }
`;
export type DeleteClinicWorkingDayByIdMutationFn = Apollo.MutationFunction<
  DeleteClinicWorkingDayByIdMutation,
  DeleteClinicWorkingDayByIdMutationVariables
>;

/**
 * __useDeleteClinicWorkingDayByIdMutation__
 *
 * To run a mutation, you first call `useDeleteClinicWorkingDayByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClinicWorkingDayByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClinicWorkingDayByIdMutation, { data, loading, error }] = useDeleteClinicWorkingDayByIdMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useDeleteClinicWorkingDayByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteClinicWorkingDayByIdMutation,
    DeleteClinicWorkingDayByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteClinicWorkingDayByIdMutation,
    DeleteClinicWorkingDayByIdMutationVariables
  >(DeleteClinicWorkingDayByIdDocument, options);
}
export type DeleteClinicWorkingDayByIdMutationHookResult = ReturnType<
  typeof useDeleteClinicWorkingDayByIdMutation
>;
export type DeleteClinicWorkingDayByIdMutationResult =
  Apollo.MutationResult<DeleteClinicWorkingDayByIdMutation>;
export type DeleteClinicWorkingDayByIdMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteClinicWorkingDayByIdMutation,
    DeleteClinicWorkingDayByIdMutationVariables
  >;
export const MutationDocument = gql`
  mutation Mutation($schema: DeleteDoctorByIdSchema!) {
    deleteDoctorById(schema: $schema) {
      deleted
    }
  }
`;
export type MutationMutationFn = Apollo.MutationFunction<
  MutationMutation,
  MutationMutationVariables
>;

/**
 * __useMutationMutation__
 *
 * To run a mutation, you first call `useMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutationMutation, { data, loading, error }] = useMutationMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MutationMutation,
    MutationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MutationMutation, MutationMutationVariables>(
    MutationDocument,
    options
  );
}
export type MutationMutationHookResult = ReturnType<typeof useMutationMutation>;
export type MutationMutationResult = Apollo.MutationResult<MutationMutation>;
export type MutationMutationOptions = Apollo.BaseMutationOptions<
  MutationMutation,
  MutationMutationVariables
>;
export const DeletePatientByIdDocument = gql`
  mutation DeletePatientById($schema: DeletePatientByIdSchema!) {
    deletePatientById(schema: $schema) {
      deleted
    }
  }
`;
export type DeletePatientByIdMutationFn = Apollo.MutationFunction<
  DeletePatientByIdMutation,
  DeletePatientByIdMutationVariables
>;

/**
 * __useDeletePatientByIdMutation__
 *
 * To run a mutation, you first call `useDeletePatientByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientByIdMutation, { data, loading, error }] = useDeletePatientByIdMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useDeletePatientByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePatientByIdMutation,
    DeletePatientByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePatientByIdMutation,
    DeletePatientByIdMutationVariables
  >(DeletePatientByIdDocument, options);
}
export type DeletePatientByIdMutationHookResult = ReturnType<
  typeof useDeletePatientByIdMutation
>;
export type DeletePatientByIdMutationResult =
  Apollo.MutationResult<DeletePatientByIdMutation>;
export type DeletePatientByIdMutationOptions = Apollo.BaseMutationOptions<
  DeletePatientByIdMutation,
  DeletePatientByIdMutationVariables
>;
export const DeleteScheduleByIdByClientDocument = gql`
  mutation DeleteScheduleByIdByClient(
    $schema: DeleteScheduleByIdByClientSchema!
  ) {
    deleteScheduleByIdByClient(schema: $schema) {
      deleted
    }
  }
`;
export type DeleteScheduleByIdByClientMutationFn = Apollo.MutationFunction<
  DeleteScheduleByIdByClientMutation,
  DeleteScheduleByIdByClientMutationVariables
>;

/**
 * __useDeleteScheduleByIdByClientMutation__
 *
 * To run a mutation, you first call `useDeleteScheduleByIdByClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScheduleByIdByClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScheduleByIdByClientMutation, { data, loading, error }] = useDeleteScheduleByIdByClientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useDeleteScheduleByIdByClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteScheduleByIdByClientMutation,
    DeleteScheduleByIdByClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteScheduleByIdByClientMutation,
    DeleteScheduleByIdByClientMutationVariables
  >(DeleteScheduleByIdByClientDocument, options);
}
export type DeleteScheduleByIdByClientMutationHookResult = ReturnType<
  typeof useDeleteScheduleByIdByClientMutation
>;
export type DeleteScheduleByIdByClientMutationResult =
  Apollo.MutationResult<DeleteScheduleByIdByClientMutation>;
export type DeleteScheduleByIdByClientMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteScheduleByIdByClientMutation,
    DeleteScheduleByIdByClientMutationVariables
  >;
export const DeleteServiceByIdDocument = gql`
  mutation DeleteServiceById($schema: DeleteServiceByIdSchema!) {
    deleteServiceById(schema: $schema) {
      deleted
    }
  }
`;
export type DeleteServiceByIdMutationFn = Apollo.MutationFunction<
  DeleteServiceByIdMutation,
  DeleteServiceByIdMutationVariables
>;

/**
 * __useDeleteServiceByIdMutation__
 *
 * To run a mutation, you first call `useDeleteServiceByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServiceByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServiceByIdMutation, { data, loading, error }] = useDeleteServiceByIdMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useDeleteServiceByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteServiceByIdMutation,
    DeleteServiceByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteServiceByIdMutation,
    DeleteServiceByIdMutationVariables
  >(DeleteServiceByIdDocument, options);
}
export type DeleteServiceByIdMutationHookResult = ReturnType<
  typeof useDeleteServiceByIdMutation
>;
export type DeleteServiceByIdMutationResult =
  Apollo.MutationResult<DeleteServiceByIdMutation>;
export type DeleteServiceByIdMutationOptions = Apollo.BaseMutationOptions<
  DeleteServiceByIdMutation,
  DeleteServiceByIdMutationVariables
>;
export const DeleteServiceCategoryByIdDocument = gql`
  mutation DeleteServiceCategoryById(
    $schema: DeleteServiceCategoryByIdSchema!
  ) {
    deleteServiceCategoryById(schema: $schema) {
      deleted
    }
  }
`;
export type DeleteServiceCategoryByIdMutationFn = Apollo.MutationFunction<
  DeleteServiceCategoryByIdMutation,
  DeleteServiceCategoryByIdMutationVariables
>;

/**
 * __useDeleteServiceCategoryByIdMutation__
 *
 * To run a mutation, you first call `useDeleteServiceCategoryByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServiceCategoryByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServiceCategoryByIdMutation, { data, loading, error }] = useDeleteServiceCategoryByIdMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useDeleteServiceCategoryByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteServiceCategoryByIdMutation,
    DeleteServiceCategoryByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteServiceCategoryByIdMutation,
    DeleteServiceCategoryByIdMutationVariables
  >(DeleteServiceCategoryByIdDocument, options);
}
export type DeleteServiceCategoryByIdMutationHookResult = ReturnType<
  typeof useDeleteServiceCategoryByIdMutation
>;
export type DeleteServiceCategoryByIdMutationResult =
  Apollo.MutationResult<DeleteServiceCategoryByIdMutation>;
export type DeleteServiceCategoryByIdMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteServiceCategoryByIdMutation,
    DeleteServiceCategoryByIdMutationVariables
  >;
export const Enable2FaDocument = gql`
  mutation Enable2FA($schema: EnableTwoFactorAuthenticationSchema!) {
    enable2FA(schema: $schema) {
      enabled
    }
  }
`;
export type Enable2FaMutationFn = Apollo.MutationFunction<
  Enable2FaMutation,
  Enable2FaMutationVariables
>;

/**
 * __useEnable2FaMutation__
 *
 * To run a mutation, you first call `useEnable2FaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnable2FaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enable2FaMutation, { data, loading, error }] = useEnable2FaMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useEnable2FaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Enable2FaMutation,
    Enable2FaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Enable2FaMutation, Enable2FaMutationVariables>(
    Enable2FaDocument,
    options
  );
}
export type Enable2FaMutationHookResult = ReturnType<
  typeof useEnable2FaMutation
>;
export type Enable2FaMutationResult = Apollo.MutationResult<Enable2FaMutation>;
export type Enable2FaMutationOptions = Apollo.BaseMutationOptions<
  Enable2FaMutation,
  Enable2FaMutationVariables
>;
export const Generate2FaDocument = gql`
  mutation Generate2FA {
    generate2FA {
      qrCodeSVG
      secret
    }
  }
`;
export type Generate2FaMutationFn = Apollo.MutationFunction<
  Generate2FaMutation,
  Generate2FaMutationVariables
>;

/**
 * __useGenerate2FaMutation__
 *
 * To run a mutation, you first call `useGenerate2FaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerate2FaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generate2FaMutation, { data, loading, error }] = useGenerate2FaMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerate2FaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Generate2FaMutation,
    Generate2FaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Generate2FaMutation, Generate2FaMutationVariables>(
    Generate2FaDocument,
    options
  );
}
export type Generate2FaMutationHookResult = ReturnType<
  typeof useGenerate2FaMutation
>;
export type Generate2FaMutationResult =
  Apollo.MutationResult<Generate2FaMutation>;
export type Generate2FaMutationOptions = Apollo.BaseMutationOptions<
  Generate2FaMutation,
  Generate2FaMutationVariables
>;
export const LocalSignInDocument = gql`
  mutation LocalSignIn($schema: LocalSignInSchema!) {
    localSignIn(schema: $schema) {
      signedIn
      twoFactorAuthenticationEnabled
    }
  }
`;
export type LocalSignInMutationFn = Apollo.MutationFunction<
  LocalSignInMutation,
  LocalSignInMutationVariables
>;

/**
 * __useLocalSignInMutation__
 *
 * To run a mutation, you first call `useLocalSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocalSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [localSignInMutation, { data, loading, error }] = useLocalSignInMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useLocalSignInMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LocalSignInMutation,
    LocalSignInMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LocalSignInMutation, LocalSignInMutationVariables>(
    LocalSignInDocument,
    options
  );
}
export type LocalSignInMutationHookResult = ReturnType<
  typeof useLocalSignInMutation
>;
export type LocalSignInMutationResult =
  Apollo.MutationResult<LocalSignInMutation>;
export type LocalSignInMutationOptions = Apollo.BaseMutationOptions<
  LocalSignInMutation,
  LocalSignInMutationVariables
>;
export const LocalSignUpDocument = gql`
  mutation LocalSignUp($schema: LocalSignUpSchema!) {
    localSignUp(schema: $schema) {
      signedUp
    }
  }
`;
export type LocalSignUpMutationFn = Apollo.MutationFunction<
  LocalSignUpMutation,
  LocalSignUpMutationVariables
>;

/**
 * __useLocalSignUpMutation__
 *
 * To run a mutation, you first call `useLocalSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLocalSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [localSignUpMutation, { data, loading, error }] = useLocalSignUpMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useLocalSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LocalSignUpMutation,
    LocalSignUpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LocalSignUpMutation, LocalSignUpMutationVariables>(
    LocalSignUpDocument,
    options
  );
}
export type LocalSignUpMutationHookResult = ReturnType<
  typeof useLocalSignUpMutation
>;
export type LocalSignUpMutationResult =
  Apollo.MutationResult<LocalSignUpMutation>;
export type LocalSignUpMutationOptions = Apollo.BaseMutationOptions<
  LocalSignUpMutation,
  LocalSignUpMutationVariables
>;
export const RateScheduleAppointmentByPatientDocument = gql`
  mutation RateScheduleAppointmentByPatient(
    $schema: RateScheduleAppointmentByPatientSchema!
  ) {
    rateScheduleAppointmentByPatient(schema: $schema) {
      rated
    }
  }
`;
export type RateScheduleAppointmentByPatientMutationFn =
  Apollo.MutationFunction<
    RateScheduleAppointmentByPatientMutation,
    RateScheduleAppointmentByPatientMutationVariables
  >;

/**
 * __useRateScheduleAppointmentByPatientMutation__
 *
 * To run a mutation, you first call `useRateScheduleAppointmentByPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRateScheduleAppointmentByPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rateScheduleAppointmentByPatientMutation, { data, loading, error }] = useRateScheduleAppointmentByPatientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useRateScheduleAppointmentByPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RateScheduleAppointmentByPatientMutation,
    RateScheduleAppointmentByPatientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RateScheduleAppointmentByPatientMutation,
    RateScheduleAppointmentByPatientMutationVariables
  >(RateScheduleAppointmentByPatientDocument, options);
}
export type RateScheduleAppointmentByPatientMutationHookResult = ReturnType<
  typeof useRateScheduleAppointmentByPatientMutation
>;
export type RateScheduleAppointmentByPatientMutationResult =
  Apollo.MutationResult<RateScheduleAppointmentByPatientMutation>;
export type RateScheduleAppointmentByPatientMutationOptions =
  Apollo.BaseMutationOptions<
    RateScheduleAppointmentByPatientMutation,
    RateScheduleAppointmentByPatientMutationVariables
  >;
export const RequestEmailChangeDocument = gql`
  mutation RequestEmailChange($schema: RequestEmailChangeSchema!) {
    requestEmailChange(schema: $schema) {
      requested
    }
  }
`;
export type RequestEmailChangeMutationFn = Apollo.MutationFunction<
  RequestEmailChangeMutation,
  RequestEmailChangeMutationVariables
>;

/**
 * __useRequestEmailChangeMutation__
 *
 * To run a mutation, you first call `useRequestEmailChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestEmailChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestEmailChangeMutation, { data, loading, error }] = useRequestEmailChangeMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useRequestEmailChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestEmailChangeMutation,
    RequestEmailChangeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestEmailChangeMutation,
    RequestEmailChangeMutationVariables
  >(RequestEmailChangeDocument, options);
}
export type RequestEmailChangeMutationHookResult = ReturnType<
  typeof useRequestEmailChangeMutation
>;
export type RequestEmailChangeMutationResult =
  Apollo.MutationResult<RequestEmailChangeMutation>;
export type RequestEmailChangeMutationOptions = Apollo.BaseMutationOptions<
  RequestEmailChangeMutation,
  RequestEmailChangeMutationVariables
>;
export const RequestPasswordRestorageDocument = gql`
  mutation RequestPasswordRestorage($schema: RequestPasswordRestorageSchema!) {
    requestPasswordRestorage(schema: $schema) {
      requested
    }
  }
`;
export type RequestPasswordRestorageMutationFn = Apollo.MutationFunction<
  RequestPasswordRestorageMutation,
  RequestPasswordRestorageMutationVariables
>;

/**
 * __useRequestPasswordRestorageMutation__
 *
 * To run a mutation, you first call `useRequestPasswordRestorageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordRestorageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordRestorageMutation, { data, loading, error }] = useRequestPasswordRestorageMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useRequestPasswordRestorageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestPasswordRestorageMutation,
    RequestPasswordRestorageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestPasswordRestorageMutation,
    RequestPasswordRestorageMutationVariables
  >(RequestPasswordRestorageDocument, options);
}
export type RequestPasswordRestorageMutationHookResult = ReturnType<
  typeof useRequestPasswordRestorageMutation
>;
export type RequestPasswordRestorageMutationResult =
  Apollo.MutationResult<RequestPasswordRestorageMutation>;
export type RequestPasswordRestorageMutationOptions =
  Apollo.BaseMutationOptions<
    RequestPasswordRestorageMutation,
    RequestPasswordRestorageMutationVariables
  >;
export const RescheduleAppointmentByClientDocument = gql`
  mutation RescheduleAppointmentByClient(
    $schema: RescheduleScheduleAppointmentByClientSchema!
  ) {
    rescheduleAppointmentByClient(schema: $schema) {
      rescheduled
    }
  }
`;
export type RescheduleAppointmentByClientMutationFn = Apollo.MutationFunction<
  RescheduleAppointmentByClientMutation,
  RescheduleAppointmentByClientMutationVariables
>;

/**
 * __useRescheduleAppointmentByClientMutation__
 *
 * To run a mutation, you first call `useRescheduleAppointmentByClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleAppointmentByClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleAppointmentByClientMutation, { data, loading, error }] = useRescheduleAppointmentByClientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useRescheduleAppointmentByClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RescheduleAppointmentByClientMutation,
    RescheduleAppointmentByClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RescheduleAppointmentByClientMutation,
    RescheduleAppointmentByClientMutationVariables
  >(RescheduleAppointmentByClientDocument, options);
}
export type RescheduleAppointmentByClientMutationHookResult = ReturnType<
  typeof useRescheduleAppointmentByClientMutation
>;
export type RescheduleAppointmentByClientMutationResult =
  Apollo.MutationResult<RescheduleAppointmentByClientMutation>;
export type RescheduleAppointmentByClientMutationOptions =
  Apollo.BaseMutationOptions<
    RescheduleAppointmentByClientMutation,
    RescheduleAppointmentByClientMutationVariables
  >;
export const RescheduleScheduleAppointmentByPatientDocument = gql`
  mutation RescheduleScheduleAppointmentByPatient(
    $schema: RescheduleScheduleAppointmentByPatientSchema!
  ) {
    rescheduleScheduleAppointmentByPatient(schema: $schema) {
      rescheduled
    }
  }
`;
export type RescheduleScheduleAppointmentByPatientMutationFn =
  Apollo.MutationFunction<
    RescheduleScheduleAppointmentByPatientMutation,
    RescheduleScheduleAppointmentByPatientMutationVariables
  >;

/**
 * __useRescheduleScheduleAppointmentByPatientMutation__
 *
 * To run a mutation, you first call `useRescheduleScheduleAppointmentByPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleScheduleAppointmentByPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleScheduleAppointmentByPatientMutation, { data, loading, error }] = useRescheduleScheduleAppointmentByPatientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useRescheduleScheduleAppointmentByPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RescheduleScheduleAppointmentByPatientMutation,
    RescheduleScheduleAppointmentByPatientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RescheduleScheduleAppointmentByPatientMutation,
    RescheduleScheduleAppointmentByPatientMutationVariables
  >(RescheduleScheduleAppointmentByPatientDocument, options);
}
export type RescheduleScheduleAppointmentByPatientMutationHookResult =
  ReturnType<typeof useRescheduleScheduleAppointmentByPatientMutation>;
export type RescheduleScheduleAppointmentByPatientMutationResult =
  Apollo.MutationResult<RescheduleScheduleAppointmentByPatientMutation>;
export type RescheduleScheduleAppointmentByPatientMutationOptions =
  Apollo.BaseMutationOptions<
    RescheduleScheduleAppointmentByPatientMutation,
    RescheduleScheduleAppointmentByPatientMutationVariables
  >;
export const RescheduleWaitlistByClientDocument = gql`
  mutation RescheduleWaitlistByClient(
    $schema: RescheduleScheduleWaitlistByClientSchema!
  ) {
    rescheduleWaitlistByClient(schema: $schema) {
      rescheduled
    }
  }
`;
export type RescheduleWaitlistByClientMutationFn = Apollo.MutationFunction<
  RescheduleWaitlistByClientMutation,
  RescheduleWaitlistByClientMutationVariables
>;

/**
 * __useRescheduleWaitlistByClientMutation__
 *
 * To run a mutation, you first call `useRescheduleWaitlistByClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleWaitlistByClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleWaitlistByClientMutation, { data, loading, error }] = useRescheduleWaitlistByClientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useRescheduleWaitlistByClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RescheduleWaitlistByClientMutation,
    RescheduleWaitlistByClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RescheduleWaitlistByClientMutation,
    RescheduleWaitlistByClientMutationVariables
  >(RescheduleWaitlistByClientDocument, options);
}
export type RescheduleWaitlistByClientMutationHookResult = ReturnType<
  typeof useRescheduleWaitlistByClientMutation
>;
export type RescheduleWaitlistByClientMutationResult =
  Apollo.MutationResult<RescheduleWaitlistByClientMutation>;
export type RescheduleWaitlistByClientMutationOptions =
  Apollo.BaseMutationOptions<
    RescheduleWaitlistByClientMutation,
    RescheduleWaitlistByClientMutationVariables
  >;
export const RescheduleWaitlistByPatientDocument = gql`
  mutation RescheduleWaitlistByPatient(
    $schema: RescheduleScheduleWaitlistByPatientSchema!
  ) {
    rescheduleWaitlistByPatient(schema: $schema) {
      rescheduled
    }
  }
`;
export type RescheduleWaitlistByPatientMutationFn = Apollo.MutationFunction<
  RescheduleWaitlistByPatientMutation,
  RescheduleWaitlistByPatientMutationVariables
>;

/**
 * __useRescheduleWaitlistByPatientMutation__
 *
 * To run a mutation, you first call `useRescheduleWaitlistByPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleWaitlistByPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleWaitlistByPatientMutation, { data, loading, error }] = useRescheduleWaitlistByPatientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useRescheduleWaitlistByPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RescheduleWaitlistByPatientMutation,
    RescheduleWaitlistByPatientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RescheduleWaitlistByPatientMutation,
    RescheduleWaitlistByPatientMutationVariables
  >(RescheduleWaitlistByPatientDocument, options);
}
export type RescheduleWaitlistByPatientMutationHookResult = ReturnType<
  typeof useRescheduleWaitlistByPatientMutation
>;
export type RescheduleWaitlistByPatientMutationResult =
  Apollo.MutationResult<RescheduleWaitlistByPatientMutation>;
export type RescheduleWaitlistByPatientMutationOptions =
  Apollo.BaseMutationOptions<
    RescheduleWaitlistByPatientMutation,
    RescheduleWaitlistByPatientMutationVariables
  >;
export const RestorePasswordDocument = gql`
  mutation RestorePassword($schema: RestorePasswordSchema!) {
    restorePassword(schema: $schema) {
      restored
    }
  }
`;
export type RestorePasswordMutationFn = Apollo.MutationFunction<
  RestorePasswordMutation,
  RestorePasswordMutationVariables
>;

/**
 * __useRestorePasswordMutation__
 *
 * To run a mutation, you first call `useRestorePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestorePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restorePasswordMutation, { data, loading, error }] = useRestorePasswordMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useRestorePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RestorePasswordMutation,
    RestorePasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RestorePasswordMutation,
    RestorePasswordMutationVariables
  >(RestorePasswordDocument, options);
}
export type RestorePasswordMutationHookResult = ReturnType<
  typeof useRestorePasswordMutation
>;
export type RestorePasswordMutationResult =
  Apollo.MutationResult<RestorePasswordMutation>;
export type RestorePasswordMutationOptions = Apollo.BaseMutationOptions<
  RestorePasswordMutation,
  RestorePasswordMutationVariables
>;
export const SendRawSmsByClientDocument = gql`
  mutation SendRawSmsByClient($schema: SendRawSmsByClientSchema!) {
    sendRawSmsByClient(schema: $schema) {
      sent
    }
  }
`;
export type SendRawSmsByClientMutationFn = Apollo.MutationFunction<
  SendRawSmsByClientMutation,
  SendRawSmsByClientMutationVariables
>;

/**
 * __useSendRawSmsByClientMutation__
 *
 * To run a mutation, you first call `useSendRawSmsByClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendRawSmsByClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendRawSmsByClientMutation, { data, loading, error }] = useSendRawSmsByClientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useSendRawSmsByClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendRawSmsByClientMutation,
    SendRawSmsByClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendRawSmsByClientMutation,
    SendRawSmsByClientMutationVariables
  >(SendRawSmsByClientDocument, options);
}
export type SendRawSmsByClientMutationHookResult = ReturnType<
  typeof useSendRawSmsByClientMutation
>;
export type SendRawSmsByClientMutationResult =
  Apollo.MutationResult<SendRawSmsByClientMutation>;
export type SendRawSmsByClientMutationOptions = Apollo.BaseMutationOptions<
  SendRawSmsByClientMutation,
  SendRawSmsByClientMutationVariables
>;
export const UnattachDoctorServiceDocument = gql`
  mutation UnattachDoctorService($schema: UnattachServiceDoctorSchema!) {
    unattachDoctorService(schema: $schema) {
      unattached
    }
  }
`;
export type UnattachDoctorServiceMutationFn = Apollo.MutationFunction<
  UnattachDoctorServiceMutation,
  UnattachDoctorServiceMutationVariables
>;

/**
 * __useUnattachDoctorServiceMutation__
 *
 * To run a mutation, you first call `useUnattachDoctorServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnattachDoctorServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unattachDoctorServiceMutation, { data, loading, error }] = useUnattachDoctorServiceMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useUnattachDoctorServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnattachDoctorServiceMutation,
    UnattachDoctorServiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnattachDoctorServiceMutation,
    UnattachDoctorServiceMutationVariables
  >(UnattachDoctorServiceDocument, options);
}
export type UnattachDoctorServiceMutationHookResult = ReturnType<
  typeof useUnattachDoctorServiceMutation
>;
export type UnattachDoctorServiceMutationResult =
  Apollo.MutationResult<UnattachDoctorServiceMutation>;
export type UnattachDoctorServiceMutationOptions = Apollo.BaseMutationOptions<
  UnattachDoctorServiceMutation,
  UnattachDoctorServiceMutationVariables
>;
export const UpdateClinicByIdDocument = gql`
  mutation UpdateClinicById($schema: UpdateClinicByIdSchema!) {
    updateClinicById(schema: $schema) {
      id
      companyId
      name
      address
      houseNumber
      index
      city
      logoFileId
      licenseNumber
      geographicalCoordinates {
        latitude
        longitude
      }
      code
      phoneNumber
      description
      createdAt
      rooms {
        id
        clinicId
        name
      }
      workingDays {
        id
        clinicId
        day
        fromMinute
        toMinute
      }
    }
  }
`;
export type UpdateClinicByIdMutationFn = Apollo.MutationFunction<
  UpdateClinicByIdMutation,
  UpdateClinicByIdMutationVariables
>;

/**
 * __useUpdateClinicByIdMutation__
 *
 * To run a mutation, you first call `useUpdateClinicByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClinicByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClinicByIdMutation, { data, loading, error }] = useUpdateClinicByIdMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useUpdateClinicByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClinicByIdMutation,
    UpdateClinicByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClinicByIdMutation,
    UpdateClinicByIdMutationVariables
  >(UpdateClinicByIdDocument, options);
}
export type UpdateClinicByIdMutationHookResult = ReturnType<
  typeof useUpdateClinicByIdMutation
>;
export type UpdateClinicByIdMutationResult =
  Apollo.MutationResult<UpdateClinicByIdMutation>;
export type UpdateClinicByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateClinicByIdMutation,
  UpdateClinicByIdMutationVariables
>;
export const UpdateClinicLogoFileIdDocument = gql`
  mutation UpdateClinicLogoFileId($schema: UpdateClinicLogoFileIdSchema!) {
    updateClinicLogoFileId(schema: $schema) {
      updated
    }
  }
`;
export type UpdateClinicLogoFileIdMutationFn = Apollo.MutationFunction<
  UpdateClinicLogoFileIdMutation,
  UpdateClinicLogoFileIdMutationVariables
>;

/**
 * __useUpdateClinicLogoFileIdMutation__
 *
 * To run a mutation, you first call `useUpdateClinicLogoFileIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClinicLogoFileIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClinicLogoFileIdMutation, { data, loading, error }] = useUpdateClinicLogoFileIdMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useUpdateClinicLogoFileIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClinicLogoFileIdMutation,
    UpdateClinicLogoFileIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClinicLogoFileIdMutation,
    UpdateClinicLogoFileIdMutationVariables
  >(UpdateClinicLogoFileIdDocument, options);
}
export type UpdateClinicLogoFileIdMutationHookResult = ReturnType<
  typeof useUpdateClinicLogoFileIdMutation
>;
export type UpdateClinicLogoFileIdMutationResult =
  Apollo.MutationResult<UpdateClinicLogoFileIdMutation>;
export type UpdateClinicLogoFileIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateClinicLogoFileIdMutation,
  UpdateClinicLogoFileIdMutationVariables
>;
export const UpdateClinicRoomByIdDocument = gql`
  mutation UpdateClinicRoomById($schema: UpdateClinicRoomByIdSchema!) {
    updateClinicRoomById(schema: $schema) {
      updated
    }
  }
`;
export type UpdateClinicRoomByIdMutationFn = Apollo.MutationFunction<
  UpdateClinicRoomByIdMutation,
  UpdateClinicRoomByIdMutationVariables
>;

/**
 * __useUpdateClinicRoomByIdMutation__
 *
 * To run a mutation, you first call `useUpdateClinicRoomByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClinicRoomByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClinicRoomByIdMutation, { data, loading, error }] = useUpdateClinicRoomByIdMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useUpdateClinicRoomByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClinicRoomByIdMutation,
    UpdateClinicRoomByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClinicRoomByIdMutation,
    UpdateClinicRoomByIdMutationVariables
  >(UpdateClinicRoomByIdDocument, options);
}
export type UpdateClinicRoomByIdMutationHookResult = ReturnType<
  typeof useUpdateClinicRoomByIdMutation
>;
export type UpdateClinicRoomByIdMutationResult =
  Apollo.MutationResult<UpdateClinicRoomByIdMutation>;
export type UpdateClinicRoomByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateClinicRoomByIdMutation,
  UpdateClinicRoomByIdMutationVariables
>;
export const UpdateClinicWorkingDaysByClinicIdDocument = gql`
  mutation UpdateClinicWorkingDaysByClinicId(
    $schema: UpdateClinicWorkingDaysByClinicIdSchema!
  ) {
    updateClinicWorkingDaysByClinicId(schema: $schema) {
      updated
    }
  }
`;
export type UpdateClinicWorkingDaysByClinicIdMutationFn =
  Apollo.MutationFunction<
    UpdateClinicWorkingDaysByClinicIdMutation,
    UpdateClinicWorkingDaysByClinicIdMutationVariables
  >;

/**
 * __useUpdateClinicWorkingDaysByClinicIdMutation__
 *
 * To run a mutation, you first call `useUpdateClinicWorkingDaysByClinicIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClinicWorkingDaysByClinicIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClinicWorkingDaysByClinicIdMutation, { data, loading, error }] = useUpdateClinicWorkingDaysByClinicIdMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useUpdateClinicWorkingDaysByClinicIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateClinicWorkingDaysByClinicIdMutation,
    UpdateClinicWorkingDaysByClinicIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateClinicWorkingDaysByClinicIdMutation,
    UpdateClinicWorkingDaysByClinicIdMutationVariables
  >(UpdateClinicWorkingDaysByClinicIdDocument, options);
}
export type UpdateClinicWorkingDaysByClinicIdMutationHookResult = ReturnType<
  typeof useUpdateClinicWorkingDaysByClinicIdMutation
>;
export type UpdateClinicWorkingDaysByClinicIdMutationResult =
  Apollo.MutationResult<UpdateClinicWorkingDaysByClinicIdMutation>;
export type UpdateClinicWorkingDaysByClinicIdMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateClinicWorkingDaysByClinicIdMutation,
    UpdateClinicWorkingDaysByClinicIdMutationVariables
  >;
export const UpdateDoctorAvatarFileIdByClientDocument = gql`
  mutation UpdateDoctorAvatarFileIdByClient(
    $schema: UpdateDoctorAvatarFileIdByClientSchema!
  ) {
    updateDoctorAvatarFileIdByClient(schema: $schema) {
      updated
    }
  }
`;
export type UpdateDoctorAvatarFileIdByClientMutationFn =
  Apollo.MutationFunction<
    UpdateDoctorAvatarFileIdByClientMutation,
    UpdateDoctorAvatarFileIdByClientMutationVariables
  >;

/**
 * __useUpdateDoctorAvatarFileIdByClientMutation__
 *
 * To run a mutation, you first call `useUpdateDoctorAvatarFileIdByClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDoctorAvatarFileIdByClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDoctorAvatarFileIdByClientMutation, { data, loading, error }] = useUpdateDoctorAvatarFileIdByClientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useUpdateDoctorAvatarFileIdByClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDoctorAvatarFileIdByClientMutation,
    UpdateDoctorAvatarFileIdByClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDoctorAvatarFileIdByClientMutation,
    UpdateDoctorAvatarFileIdByClientMutationVariables
  >(UpdateDoctorAvatarFileIdByClientDocument, options);
}
export type UpdateDoctorAvatarFileIdByClientMutationHookResult = ReturnType<
  typeof useUpdateDoctorAvatarFileIdByClientMutation
>;
export type UpdateDoctorAvatarFileIdByClientMutationResult =
  Apollo.MutationResult<UpdateDoctorAvatarFileIdByClientMutation>;
export type UpdateDoctorAvatarFileIdByClientMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateDoctorAvatarFileIdByClientMutation,
    UpdateDoctorAvatarFileIdByClientMutationVariables
  >;
export const UpdateDoctorByIdByClientDocument = gql`
  mutation UpdateDoctorByIdByClient($schema: UpdateDoctorByIdByClientSchema!) {
    updateDoctorByIdByClient(schema: $schema) {
      updated
    }
  }
`;
export type UpdateDoctorByIdByClientMutationFn = Apollo.MutationFunction<
  UpdateDoctorByIdByClientMutation,
  UpdateDoctorByIdByClientMutationVariables
>;

/**
 * __useUpdateDoctorByIdByClientMutation__
 *
 * To run a mutation, you first call `useUpdateDoctorByIdByClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDoctorByIdByClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDoctorByIdByClientMutation, { data, loading, error }] = useUpdateDoctorByIdByClientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useUpdateDoctorByIdByClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDoctorByIdByClientMutation,
    UpdateDoctorByIdByClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDoctorByIdByClientMutation,
    UpdateDoctorByIdByClientMutationVariables
  >(UpdateDoctorByIdByClientDocument, options);
}
export type UpdateDoctorByIdByClientMutationHookResult = ReturnType<
  typeof useUpdateDoctorByIdByClientMutation
>;
export type UpdateDoctorByIdByClientMutationResult =
  Apollo.MutationResult<UpdateDoctorByIdByClientMutation>;
export type UpdateDoctorByIdByClientMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateDoctorByIdByClientMutation,
    UpdateDoctorByIdByClientMutationVariables
  >;
export const UpdateDoctorByUserIdByDoctorDocument = gql`
  mutation UpdateDoctorByUserIdByDoctor(
    $schema: UpdateDoctorByUserIdByDoctorSchema!
  ) {
    updateDoctorByUserIdByDoctor(schema: $schema) {
      updated
    }
  }
`;
export type UpdateDoctorByUserIdByDoctorMutationFn = Apollo.MutationFunction<
  UpdateDoctorByUserIdByDoctorMutation,
  UpdateDoctorByUserIdByDoctorMutationVariables
>;

/**
 * __useUpdateDoctorByUserIdByDoctorMutation__
 *
 * To run a mutation, you first call `useUpdateDoctorByUserIdByDoctorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDoctorByUserIdByDoctorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDoctorByUserIdByDoctorMutation, { data, loading, error }] = useUpdateDoctorByUserIdByDoctorMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useUpdateDoctorByUserIdByDoctorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDoctorByUserIdByDoctorMutation,
    UpdateDoctorByUserIdByDoctorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDoctorByUserIdByDoctorMutation,
    UpdateDoctorByUserIdByDoctorMutationVariables
  >(UpdateDoctorByUserIdByDoctorDocument, options);
}
export type UpdateDoctorByUserIdByDoctorMutationHookResult = ReturnType<
  typeof useUpdateDoctorByUserIdByDoctorMutation
>;
export type UpdateDoctorByUserIdByDoctorMutationResult =
  Apollo.MutationResult<UpdateDoctorByUserIdByDoctorMutation>;
export type UpdateDoctorByUserIdByDoctorMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateDoctorByUserIdByDoctorMutation,
    UpdateDoctorByUserIdByDoctorMutationVariables
  >;
export const UpdateDoctorClinicsByClientDocument = gql`
  mutation UpdateDoctorClinicsByClient(
    $schema: UpdateDoctorClinicsByClientSchema!
  ) {
    updateDoctorClinicsByClient(schema: $schema) {
      updated
    }
  }
`;
export type UpdateDoctorClinicsByClientMutationFn = Apollo.MutationFunction<
  UpdateDoctorClinicsByClientMutation,
  UpdateDoctorClinicsByClientMutationVariables
>;

/**
 * __useUpdateDoctorClinicsByClientMutation__
 *
 * To run a mutation, you first call `useUpdateDoctorClinicsByClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDoctorClinicsByClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDoctorClinicsByClientMutation, { data, loading, error }] = useUpdateDoctorClinicsByClientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useUpdateDoctorClinicsByClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDoctorClinicsByClientMutation,
    UpdateDoctorClinicsByClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDoctorClinicsByClientMutation,
    UpdateDoctorClinicsByClientMutationVariables
  >(UpdateDoctorClinicsByClientDocument, options);
}
export type UpdateDoctorClinicsByClientMutationHookResult = ReturnType<
  typeof useUpdateDoctorClinicsByClientMutation
>;
export type UpdateDoctorClinicsByClientMutationResult =
  Apollo.MutationResult<UpdateDoctorClinicsByClientMutation>;
export type UpdateDoctorClinicsByClientMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateDoctorClinicsByClientMutation,
    UpdateDoctorClinicsByClientMutationVariables
  >;
export const UpdatePatientByIdDocument = gql`
  mutation UpdatePatientById($schema: UpdatePatientByIdSchema!) {
    updatePatientById(schema: $schema) {
      updated
    }
  }
`;
export type UpdatePatientByIdMutationFn = Apollo.MutationFunction<
  UpdatePatientByIdMutation,
  UpdatePatientByIdMutationVariables
>;

/**
 * __useUpdatePatientByIdMutation__
 *
 * To run a mutation, you first call `useUpdatePatientByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientByIdMutation, { data, loading, error }] = useUpdatePatientByIdMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useUpdatePatientByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePatientByIdMutation,
    UpdatePatientByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePatientByIdMutation,
    UpdatePatientByIdMutationVariables
  >(UpdatePatientByIdDocument, options);
}
export type UpdatePatientByIdMutationHookResult = ReturnType<
  typeof useUpdatePatientByIdMutation
>;
export type UpdatePatientByIdMutationResult =
  Apollo.MutationResult<UpdatePatientByIdMutation>;
export type UpdatePatientByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdatePatientByIdMutation,
  UpdatePatientByIdMutationVariables
>;
export const UpdateScheduleAppointmentMissedStatusByClientDocument = gql`
  mutation UpdateScheduleAppointmentMissedStatusByClient(
    $schema: UpdateScheduleAppointmentMissedSchema!
  ) {
    updateScheduleAppointmentMissedStatusByClient(schema: $schema) {
      updated
    }
  }
`;
export type UpdateScheduleAppointmentMissedStatusByClientMutationFn =
  Apollo.MutationFunction<
    UpdateScheduleAppointmentMissedStatusByClientMutation,
    UpdateScheduleAppointmentMissedStatusByClientMutationVariables
  >;

/**
 * __useUpdateScheduleAppointmentMissedStatusByClientMutation__
 *
 * To run a mutation, you first call `useUpdateScheduleAppointmentMissedStatusByClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduleAppointmentMissedStatusByClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduleAppointmentMissedStatusByClientMutation, { data, loading, error }] = useUpdateScheduleAppointmentMissedStatusByClientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useUpdateScheduleAppointmentMissedStatusByClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScheduleAppointmentMissedStatusByClientMutation,
    UpdateScheduleAppointmentMissedStatusByClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateScheduleAppointmentMissedStatusByClientMutation,
    UpdateScheduleAppointmentMissedStatusByClientMutationVariables
  >(UpdateScheduleAppointmentMissedStatusByClientDocument, options);
}
export type UpdateScheduleAppointmentMissedStatusByClientMutationHookResult =
  ReturnType<typeof useUpdateScheduleAppointmentMissedStatusByClientMutation>;
export type UpdateScheduleAppointmentMissedStatusByClientMutationResult =
  Apollo.MutationResult<UpdateScheduleAppointmentMissedStatusByClientMutation>;
export type UpdateScheduleAppointmentMissedStatusByClientMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateScheduleAppointmentMissedStatusByClientMutation,
    UpdateScheduleAppointmentMissedStatusByClientMutationVariables
  >;
export const UpdateScheduleAppointmentMissedStatusByDoctorDocument = gql`
  mutation UpdateScheduleAppointmentMissedStatusByDoctor(
    $schema: UpdateScheduleAppointmentMissedSchema!
  ) {
    updateScheduleAppointmentMissedStatusByDoctor(schema: $schema) {
      updated
    }
  }
`;
export type UpdateScheduleAppointmentMissedStatusByDoctorMutationFn =
  Apollo.MutationFunction<
    UpdateScheduleAppointmentMissedStatusByDoctorMutation,
    UpdateScheduleAppointmentMissedStatusByDoctorMutationVariables
  >;

/**
 * __useUpdateScheduleAppointmentMissedStatusByDoctorMutation__
 *
 * To run a mutation, you first call `useUpdateScheduleAppointmentMissedStatusByDoctorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduleAppointmentMissedStatusByDoctorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduleAppointmentMissedStatusByDoctorMutation, { data, loading, error }] = useUpdateScheduleAppointmentMissedStatusByDoctorMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useUpdateScheduleAppointmentMissedStatusByDoctorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScheduleAppointmentMissedStatusByDoctorMutation,
    UpdateScheduleAppointmentMissedStatusByDoctorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateScheduleAppointmentMissedStatusByDoctorMutation,
    UpdateScheduleAppointmentMissedStatusByDoctorMutationVariables
  >(UpdateScheduleAppointmentMissedStatusByDoctorDocument, options);
}
export type UpdateScheduleAppointmentMissedStatusByDoctorMutationHookResult =
  ReturnType<typeof useUpdateScheduleAppointmentMissedStatusByDoctorMutation>;
export type UpdateScheduleAppointmentMissedStatusByDoctorMutationResult =
  Apollo.MutationResult<UpdateScheduleAppointmentMissedStatusByDoctorMutation>;
export type UpdateScheduleAppointmentMissedStatusByDoctorMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateScheduleAppointmentMissedStatusByDoctorMutation,
    UpdateScheduleAppointmentMissedStatusByDoctorMutationVariables
  >;
export const UpdateScheduleAppointmentStatusByIdByClientDocument = gql`
  mutation UpdateScheduleAppointmentStatusByIdByClient(
    $schema: UpdateScheduleAppointmentStatusByIdByClientSchema!
  ) {
    updateScheduleAppointmentStatusByIdByClient(schema: $schema) {
      updated
    }
  }
`;
export type UpdateScheduleAppointmentStatusByIdByClientMutationFn =
  Apollo.MutationFunction<
    UpdateScheduleAppointmentStatusByIdByClientMutation,
    UpdateScheduleAppointmentStatusByIdByClientMutationVariables
  >;

/**
 * __useUpdateScheduleAppointmentStatusByIdByClientMutation__
 *
 * To run a mutation, you first call `useUpdateScheduleAppointmentStatusByIdByClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduleAppointmentStatusByIdByClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduleAppointmentStatusByIdByClientMutation, { data, loading, error }] = useUpdateScheduleAppointmentStatusByIdByClientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useUpdateScheduleAppointmentStatusByIdByClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScheduleAppointmentStatusByIdByClientMutation,
    UpdateScheduleAppointmentStatusByIdByClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateScheduleAppointmentStatusByIdByClientMutation,
    UpdateScheduleAppointmentStatusByIdByClientMutationVariables
  >(UpdateScheduleAppointmentStatusByIdByClientDocument, options);
}
export type UpdateScheduleAppointmentStatusByIdByClientMutationHookResult =
  ReturnType<typeof useUpdateScheduleAppointmentStatusByIdByClientMutation>;
export type UpdateScheduleAppointmentStatusByIdByClientMutationResult =
  Apollo.MutationResult<UpdateScheduleAppointmentStatusByIdByClientMutation>;
export type UpdateScheduleAppointmentStatusByIdByClientMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateScheduleAppointmentStatusByIdByClientMutation,
    UpdateScheduleAppointmentStatusByIdByClientMutationVariables
  >;
export const UpdateScheduleByIdByClientDocument = gql`
  mutation UpdateScheduleByIdByClient(
    $schema: UpdateScheduleByIdByClientSchema!
  ) {
    updateScheduleByIdByClient(schema: $schema) {
      updated
    }
  }
`;
export type UpdateScheduleByIdByClientMutationFn = Apollo.MutationFunction<
  UpdateScheduleByIdByClientMutation,
  UpdateScheduleByIdByClientMutationVariables
>;

/**
 * __useUpdateScheduleByIdByClientMutation__
 *
 * To run a mutation, you first call `useUpdateScheduleByIdByClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduleByIdByClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduleByIdByClientMutation, { data, loading, error }] = useUpdateScheduleByIdByClientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useUpdateScheduleByIdByClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateScheduleByIdByClientMutation,
    UpdateScheduleByIdByClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateScheduleByIdByClientMutation,
    UpdateScheduleByIdByClientMutationVariables
  >(UpdateScheduleByIdByClientDocument, options);
}
export type UpdateScheduleByIdByClientMutationHookResult = ReturnType<
  typeof useUpdateScheduleByIdByClientMutation
>;
export type UpdateScheduleByIdByClientMutationResult =
  Apollo.MutationResult<UpdateScheduleByIdByClientMutation>;
export type UpdateScheduleByIdByClientMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateScheduleByIdByClientMutation,
    UpdateScheduleByIdByClientMutationVariables
  >;
export const UpdateServiceByIdDocument = gql`
  mutation UpdateServiceById($schema: UpdateServiceByIdSchema!) {
    updateServiceById(schema: $schema) {
      updated
    }
  }
`;
export type UpdateServiceByIdMutationFn = Apollo.MutationFunction<
  UpdateServiceByIdMutation,
  UpdateServiceByIdMutationVariables
>;

/**
 * __useUpdateServiceByIdMutation__
 *
 * To run a mutation, you first call `useUpdateServiceByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceByIdMutation, { data, loading, error }] = useUpdateServiceByIdMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useUpdateServiceByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateServiceByIdMutation,
    UpdateServiceByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateServiceByIdMutation,
    UpdateServiceByIdMutationVariables
  >(UpdateServiceByIdDocument, options);
}
export type UpdateServiceByIdMutationHookResult = ReturnType<
  typeof useUpdateServiceByIdMutation
>;
export type UpdateServiceByIdMutationResult =
  Apollo.MutationResult<UpdateServiceByIdMutation>;
export type UpdateServiceByIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateServiceByIdMutation,
  UpdateServiceByIdMutationVariables
>;
export const UpdateServiceCategoryByIdDocument = gql`
  mutation UpdateServiceCategoryById(
    $schema: UpdateServiceCategoryByIdSchema!
  ) {
    updateServiceCategoryById(schema: $schema) {
      updated
    }
  }
`;
export type UpdateServiceCategoryByIdMutationFn = Apollo.MutationFunction<
  UpdateServiceCategoryByIdMutation,
  UpdateServiceCategoryByIdMutationVariables
>;

/**
 * __useUpdateServiceCategoryByIdMutation__
 *
 * To run a mutation, you first call `useUpdateServiceCategoryByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServiceCategoryByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServiceCategoryByIdMutation, { data, loading, error }] = useUpdateServiceCategoryByIdMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useUpdateServiceCategoryByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateServiceCategoryByIdMutation,
    UpdateServiceCategoryByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateServiceCategoryByIdMutation,
    UpdateServiceCategoryByIdMutationVariables
  >(UpdateServiceCategoryByIdDocument, options);
}
export type UpdateServiceCategoryByIdMutationHookResult = ReturnType<
  typeof useUpdateServiceCategoryByIdMutation
>;
export type UpdateServiceCategoryByIdMutationResult =
  Apollo.MutationResult<UpdateServiceCategoryByIdMutation>;
export type UpdateServiceCategoryByIdMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateServiceCategoryByIdMutation,
    UpdateServiceCategoryByIdMutationVariables
  >;
export const UpdateUserAvatarFileIdDocument = gql`
  mutation UpdateUserAvatarFileId($schema: UpdateUserAvatarFileIdSchema!) {
    updateUserAvatarFileId(schema: $schema) {
      updated
    }
  }
`;
export type UpdateUserAvatarFileIdMutationFn = Apollo.MutationFunction<
  UpdateUserAvatarFileIdMutation,
  UpdateUserAvatarFileIdMutationVariables
>;

/**
 * __useUpdateUserAvatarFileIdMutation__
 *
 * To run a mutation, you first call `useUpdateUserAvatarFileIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAvatarFileIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAvatarFileIdMutation, { data, loading, error }] = useUpdateUserAvatarFileIdMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useUpdateUserAvatarFileIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserAvatarFileIdMutation,
    UpdateUserAvatarFileIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserAvatarFileIdMutation,
    UpdateUserAvatarFileIdMutationVariables
  >(UpdateUserAvatarFileIdDocument, options);
}
export type UpdateUserAvatarFileIdMutationHookResult = ReturnType<
  typeof useUpdateUserAvatarFileIdMutation
>;
export type UpdateUserAvatarFileIdMutationResult =
  Apollo.MutationResult<UpdateUserAvatarFileIdMutation>;
export type UpdateUserAvatarFileIdMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserAvatarFileIdMutation,
  UpdateUserAvatarFileIdMutationVariables
>;
export const UploadFileDocument = gql`
  mutation UploadFile($schema: UploadFileSchema!) {
    uploadFile(schema: $schema) {
      id
      resourceKey
      url
      userId
      mimetype
      size
      extension
      usageStatus
      originalName
      createdAt
    }
  }
`;
export type UploadFileMutationFn = Apollo.MutationFunction<
  UploadFileMutation,
  UploadFileMutationVariables
>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useUploadFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadFileMutation,
    UploadFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(
    UploadFileDocument,
    options
  );
}
export type UploadFileMutationHookResult = ReturnType<
  typeof useUploadFileMutation
>;
export type UploadFileMutationResult =
  Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<
  UploadFileMutation,
  UploadFileMutationVariables
>;
export const AutocompleteByClientDocument = gql`
  query AutocompleteByClient($schema: AutocompleteByClientSchema!) {
    autocompleteByClient(schema: $schema) {
      result {
        ... on PatientEntityResponse {
          id
          phoneNumber
          firstName
          lastName
          email
        }
        ... on AggregatedScheduleAppointmentEntityWithRelationsByClientResponse {
          id
          scheduleId
          patientId
          status
          createdAt
          updatedAt
          schedule {
            id
            doctorId
            clinicId
            roomId
            serviceId
            eventType
            layoutType
            daysOfWeek
            dateFrom
            dateTo
            minuteFrom
            minuteTo
            createdAt
          }
          patient {
            id
            phoneNumber
            firstName
            lastName
            email
            dateOfBirth
            gender
            companyId
            comment
            emailDistribution
            smsDistribution
            activated
            passportNumber
            status
            createdAt
            languageISOCode
          }
          doctor {
            id
            email
            firstName
            lastName
            activated
            dateOfBirth
            avatarFileId
            roles
            createdAt
            doctorId
            doctorTypeId
            phoneNumber
            passportNumber
            medicalCertificateNumber
            description
            promoCode
            address
            doctorCreatedAt
          }
          service {
            id
            name
            category {
              id
              name
            }
            description
            icd10Code
            icd9Code
            durationInMinutes
            price
            currencyCode
            hiddenFromPatients
            createdAt
          }
          clinic {
            id
            name
          }
          room {
            id
            clinicId
            name
          }
        }
      }
    }
  }
`;

/**
 * __useAutocompleteByClientQuery__
 *
 * To run a query within a React component, call `useAutocompleteByClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutocompleteByClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutocompleteByClientQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useAutocompleteByClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    AutocompleteByClientQuery,
    AutocompleteByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AutocompleteByClientQuery,
    AutocompleteByClientQueryVariables
  >(AutocompleteByClientDocument, options);
}
export function useAutocompleteByClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AutocompleteByClientQuery,
    AutocompleteByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AutocompleteByClientQuery,
    AutocompleteByClientQueryVariables
  >(AutocompleteByClientDocument, options);
}
export type AutocompleteByClientQueryHookResult = ReturnType<
  typeof useAutocompleteByClientQuery
>;
export type AutocompleteByClientLazyQueryHookResult = ReturnType<
  typeof useAutocompleteByClientLazyQuery
>;
export type AutocompleteByClientQueryResult = Apollo.QueryResult<
  AutocompleteByClientQuery,
  AutocompleteByClientQueryVariables
>;
export const AutocompleteByDoctorDocument = gql`
  query AutocompleteByDoctor($schema: AutocompleteByDoctorSchema!) {
    autocompleteByDoctor(schema: $schema) {
      result {
        ... on PatientEntityResponse {
          id
          phoneNumber
          firstName
          lastName
          email
        }
        ... on AggregatedScheduleAppointmentEntityWithRelationsByDoctorResponse {
          id
          scheduleId
          patientId
          status
          createdAt
          updatedAt
          schedule {
            id
            doctorId
            clinicId
            roomId
            serviceId
            eventType
            layoutType
            daysOfWeek
            dateFrom
            dateTo
            minuteFrom
            minuteTo
            createdAt
          }
          patient {
            id
            phoneNumber
            firstName
            lastName
            email
            dateOfBirth
            gender
            companyId
            comment
            emailDistribution
            smsDistribution
            activated
            passportNumber
            status
            createdAt
            languageISOCode
          }
          service {
            id
            name
            category {
              id
              name
            }
            description
            icd10Code
            icd9Code
            durationInMinutes
            price
            currencyCode
            hiddenFromPatients
            createdAt
          }
          room {
            id
            clinicId
            name
          }
        }
      }
    }
  }
`;

/**
 * __useAutocompleteByDoctorQuery__
 *
 * To run a query within a React component, call `useAutocompleteByDoctorQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutocompleteByDoctorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutocompleteByDoctorQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useAutocompleteByDoctorQuery(
  baseOptions: Apollo.QueryHookOptions<
    AutocompleteByDoctorQuery,
    AutocompleteByDoctorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AutocompleteByDoctorQuery,
    AutocompleteByDoctorQueryVariables
  >(AutocompleteByDoctorDocument, options);
}
export function useAutocompleteByDoctorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AutocompleteByDoctorQuery,
    AutocompleteByDoctorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AutocompleteByDoctorQuery,
    AutocompleteByDoctorQueryVariables
  >(AutocompleteByDoctorDocument, options);
}
export type AutocompleteByDoctorQueryHookResult = ReturnType<
  typeof useAutocompleteByDoctorQuery
>;
export type AutocompleteByDoctorLazyQueryHookResult = ReturnType<
  typeof useAutocompleteByDoctorLazyQuery
>;
export type AutocompleteByDoctorQueryResult = Apollo.QueryResult<
  AutocompleteByDoctorQuery,
  AutocompleteByDoctorQueryVariables
>;
export const CalculateAverageScheduleAppointmentRateByClientDocument = gql`
  query CalculateAverageScheduleAppointmentRateByClient(
    $schema: CalculateAverageScheduleAppointmentRateByClientSchema!
  ) {
    calculateAverageScheduleAppointmentRateByClient(schema: $schema) {
      rate
    }
  }
`;

/**
 * __useCalculateAverageScheduleAppointmentRateByClientQuery__
 *
 * To run a query within a React component, call `useCalculateAverageScheduleAppointmentRateByClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateAverageScheduleAppointmentRateByClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateAverageScheduleAppointmentRateByClientQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCalculateAverageScheduleAppointmentRateByClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    CalculateAverageScheduleAppointmentRateByClientQuery,
    CalculateAverageScheduleAppointmentRateByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CalculateAverageScheduleAppointmentRateByClientQuery,
    CalculateAverageScheduleAppointmentRateByClientQueryVariables
  >(CalculateAverageScheduleAppointmentRateByClientDocument, options);
}
export function useCalculateAverageScheduleAppointmentRateByClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalculateAverageScheduleAppointmentRateByClientQuery,
    CalculateAverageScheduleAppointmentRateByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CalculateAverageScheduleAppointmentRateByClientQuery,
    CalculateAverageScheduleAppointmentRateByClientQueryVariables
  >(CalculateAverageScheduleAppointmentRateByClientDocument, options);
}
export type CalculateAverageScheduleAppointmentRateByClientQueryHookResult =
  ReturnType<typeof useCalculateAverageScheduleAppointmentRateByClientQuery>;
export type CalculateAverageScheduleAppointmentRateByClientLazyQueryHookResult =
  ReturnType<
    typeof useCalculateAverageScheduleAppointmentRateByClientLazyQuery
  >;
export type CalculateAverageScheduleAppointmentRateByClientQueryResult =
  Apollo.QueryResult<
    CalculateAverageScheduleAppointmentRateByClientQuery,
    CalculateAverageScheduleAppointmentRateByClientQueryVariables
  >;
export const CalculateAverageScheduleAppointmentRateByDoctorDocument = gql`
  query CalculateAverageScheduleAppointmentRateByDoctor(
    $schema: CalculateAverageScheduleAppointmentRateSchema!
  ) {
    calculateAverageScheduleAppointmentRateByDoctor(schema: $schema) {
      rate
    }
  }
`;

/**
 * __useCalculateAverageScheduleAppointmentRateByDoctorQuery__
 *
 * To run a query within a React component, call `useCalculateAverageScheduleAppointmentRateByDoctorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateAverageScheduleAppointmentRateByDoctorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateAverageScheduleAppointmentRateByDoctorQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCalculateAverageScheduleAppointmentRateByDoctorQuery(
  baseOptions: Apollo.QueryHookOptions<
    CalculateAverageScheduleAppointmentRateByDoctorQuery,
    CalculateAverageScheduleAppointmentRateByDoctorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CalculateAverageScheduleAppointmentRateByDoctorQuery,
    CalculateAverageScheduleAppointmentRateByDoctorQueryVariables
  >(CalculateAverageScheduleAppointmentRateByDoctorDocument, options);
}
export function useCalculateAverageScheduleAppointmentRateByDoctorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalculateAverageScheduleAppointmentRateByDoctorQuery,
    CalculateAverageScheduleAppointmentRateByDoctorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CalculateAverageScheduleAppointmentRateByDoctorQuery,
    CalculateAverageScheduleAppointmentRateByDoctorQueryVariables
  >(CalculateAverageScheduleAppointmentRateByDoctorDocument, options);
}
export type CalculateAverageScheduleAppointmentRateByDoctorQueryHookResult =
  ReturnType<typeof useCalculateAverageScheduleAppointmentRateByDoctorQuery>;
export type CalculateAverageScheduleAppointmentRateByDoctorLazyQueryHookResult =
  ReturnType<
    typeof useCalculateAverageScheduleAppointmentRateByDoctorLazyQuery
  >;
export type CalculateAverageScheduleAppointmentRateByDoctorQueryResult =
  Apollo.QueryResult<
    CalculateAverageScheduleAppointmentRateByDoctorQuery,
    CalculateAverageScheduleAppointmentRateByDoctorQueryVariables
  >;
export const CalculateClinicConstraintsByClientDocument = gql`
  query CalculateClinicConstraintsByClient(
    $schema: CalculateClinicConstraintsByClientSchema!
  ) {
    calculateClinicConstraintsByClient(schema: $schema) {
      mailLimit
      mailSent
      smsSent
      smsLimit
    }
  }
`;

/**
 * __useCalculateClinicConstraintsByClientQuery__
 *
 * To run a query within a React component, call `useCalculateClinicConstraintsByClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateClinicConstraintsByClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateClinicConstraintsByClientQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCalculateClinicConstraintsByClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    CalculateClinicConstraintsByClientQuery,
    CalculateClinicConstraintsByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CalculateClinicConstraintsByClientQuery,
    CalculateClinicConstraintsByClientQueryVariables
  >(CalculateClinicConstraintsByClientDocument, options);
}
export function useCalculateClinicConstraintsByClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalculateClinicConstraintsByClientQuery,
    CalculateClinicConstraintsByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CalculateClinicConstraintsByClientQuery,
    CalculateClinicConstraintsByClientQueryVariables
  >(CalculateClinicConstraintsByClientDocument, options);
}
export type CalculateClinicConstraintsByClientQueryHookResult = ReturnType<
  typeof useCalculateClinicConstraintsByClientQuery
>;
export type CalculateClinicConstraintsByClientLazyQueryHookResult = ReturnType<
  typeof useCalculateClinicConstraintsByClientLazyQuery
>;
export type CalculateClinicConstraintsByClientQueryResult = Apollo.QueryResult<
  CalculateClinicConstraintsByClientQuery,
  CalculateClinicConstraintsByClientQueryVariables
>;
export const CalculateClinicConstraintsByDoctorDocument = gql`
  query CalculateClinicConstraintsByDoctor(
    $schema: CalculateClinicConstraintsByClientSchema!
  ) {
    calculateClinicConstraintsByDoctor(schema: $schema) {
      mailSent
      mailLimit
      smsSent
      smsLimit
    }
  }
`;

/**
 * __useCalculateClinicConstraintsByDoctorQuery__
 *
 * To run a query within a React component, call `useCalculateClinicConstraintsByDoctorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalculateClinicConstraintsByDoctorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalculateClinicConstraintsByDoctorQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCalculateClinicConstraintsByDoctorQuery(
  baseOptions: Apollo.QueryHookOptions<
    CalculateClinicConstraintsByDoctorQuery,
    CalculateClinicConstraintsByDoctorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CalculateClinicConstraintsByDoctorQuery,
    CalculateClinicConstraintsByDoctorQueryVariables
  >(CalculateClinicConstraintsByDoctorDocument, options);
}
export function useCalculateClinicConstraintsByDoctorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CalculateClinicConstraintsByDoctorQuery,
    CalculateClinicConstraintsByDoctorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CalculateClinicConstraintsByDoctorQuery,
    CalculateClinicConstraintsByDoctorQueryVariables
  >(CalculateClinicConstraintsByDoctorDocument, options);
}
export type CalculateClinicConstraintsByDoctorQueryHookResult = ReturnType<
  typeof useCalculateClinicConstraintsByDoctorQuery
>;
export type CalculateClinicConstraintsByDoctorLazyQueryHookResult = ReturnType<
  typeof useCalculateClinicConstraintsByDoctorLazyQuery
>;
export type CalculateClinicConstraintsByDoctorQueryResult = Apollo.QueryResult<
  CalculateClinicConstraintsByDoctorQuery,
  CalculateClinicConstraintsByDoctorQueryVariables
>;
export const CheckIfCompanyExistsByUserIdDocument = gql`
  query CheckIfCompanyExistsByUserId {
    checkIfCompanyExistsByUserId {
      exists
    }
  }
`;

/**
 * __useCheckIfCompanyExistsByUserIdQuery__
 *
 * To run a query within a React component, call `useCheckIfCompanyExistsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckIfCompanyExistsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckIfCompanyExistsByUserIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckIfCompanyExistsByUserIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CheckIfCompanyExistsByUserIdQuery,
    CheckIfCompanyExistsByUserIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CheckIfCompanyExistsByUserIdQuery,
    CheckIfCompanyExistsByUserIdQueryVariables
  >(CheckIfCompanyExistsByUserIdDocument, options);
}
export function useCheckIfCompanyExistsByUserIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckIfCompanyExistsByUserIdQuery,
    CheckIfCompanyExistsByUserIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CheckIfCompanyExistsByUserIdQuery,
    CheckIfCompanyExistsByUserIdQueryVariables
  >(CheckIfCompanyExistsByUserIdDocument, options);
}
export type CheckIfCompanyExistsByUserIdQueryHookResult = ReturnType<
  typeof useCheckIfCompanyExistsByUserIdQuery
>;
export type CheckIfCompanyExistsByUserIdLazyQueryHookResult = ReturnType<
  typeof useCheckIfCompanyExistsByUserIdLazyQuery
>;
export type CheckIfCompanyExistsByUserIdQueryResult = Apollo.QueryResult<
  CheckIfCompanyExistsByUserIdQuery,
  CheckIfCompanyExistsByUserIdQueryVariables
>;
export const PinCodeExistsByUserIdDocument = gql`
  query PinCodeExistsByUserId {
    pinCodeExistsByUserId {
      exists
    }
  }
`;

/**
 * __usePinCodeExistsByUserIdQuery__
 *
 * To run a query within a React component, call `usePinCodeExistsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePinCodeExistsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePinCodeExistsByUserIdQuery({
 *   variables: {
 *   },
 * });
 */
export function usePinCodeExistsByUserIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PinCodeExistsByUserIdQuery,
    PinCodeExistsByUserIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PinCodeExistsByUserIdQuery,
    PinCodeExistsByUserIdQueryVariables
  >(PinCodeExistsByUserIdDocument, options);
}
export function usePinCodeExistsByUserIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PinCodeExistsByUserIdQuery,
    PinCodeExistsByUserIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PinCodeExistsByUserIdQuery,
    PinCodeExistsByUserIdQueryVariables
  >(PinCodeExistsByUserIdDocument, options);
}
export type PinCodeExistsByUserIdQueryHookResult = ReturnType<
  typeof usePinCodeExistsByUserIdQuery
>;
export type PinCodeExistsByUserIdLazyQueryHookResult = ReturnType<
  typeof usePinCodeExistsByUserIdLazyQuery
>;
export type PinCodeExistsByUserIdQueryResult = Apollo.QueryResult<
  PinCodeExistsByUserIdQuery,
  PinCodeExistsByUserIdQueryVariables
>;
export const CountAndGroupScheduleAppointmentsByDateByClientDocument = gql`
  query CountAndGroupScheduleAppointmentsByDateByClient(
    $schema: CountAndGroupScheduleAppointmentsByDateByClientSchema
  ) {
    countAndGroupScheduleAppointmentsByDateByClient(schema: $schema) {
      counts {
        count
        date
      }
    }
  }
`;

/**
 * __useCountAndGroupScheduleAppointmentsByDateByClientQuery__
 *
 * To run a query within a React component, call `useCountAndGroupScheduleAppointmentsByDateByClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountAndGroupScheduleAppointmentsByDateByClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountAndGroupScheduleAppointmentsByDateByClientQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCountAndGroupScheduleAppointmentsByDateByClientQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CountAndGroupScheduleAppointmentsByDateByClientQuery,
    CountAndGroupScheduleAppointmentsByDateByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CountAndGroupScheduleAppointmentsByDateByClientQuery,
    CountAndGroupScheduleAppointmentsByDateByClientQueryVariables
  >(CountAndGroupScheduleAppointmentsByDateByClientDocument, options);
}
export function useCountAndGroupScheduleAppointmentsByDateByClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CountAndGroupScheduleAppointmentsByDateByClientQuery,
    CountAndGroupScheduleAppointmentsByDateByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CountAndGroupScheduleAppointmentsByDateByClientQuery,
    CountAndGroupScheduleAppointmentsByDateByClientQueryVariables
  >(CountAndGroupScheduleAppointmentsByDateByClientDocument, options);
}
export type CountAndGroupScheduleAppointmentsByDateByClientQueryHookResult =
  ReturnType<typeof useCountAndGroupScheduleAppointmentsByDateByClientQuery>;
export type CountAndGroupScheduleAppointmentsByDateByClientLazyQueryHookResult =
  ReturnType<
    typeof useCountAndGroupScheduleAppointmentsByDateByClientLazyQuery
  >;
export type CountAndGroupScheduleAppointmentsByDateByClientQueryResult =
  Apollo.QueryResult<
    CountAndGroupScheduleAppointmentsByDateByClientQuery,
    CountAndGroupScheduleAppointmentsByDateByClientQueryVariables
  >;
export const CountAndGroupScheduleAppointmentsByDateByDoctorDocument = gql`
  query CountAndGroupScheduleAppointmentsByDateByDoctor(
    $schema: CountAndGroupScheduleAppointmentsByDateByDoctorSchema
  ) {
    countAndGroupScheduleAppointmentsByDateByDoctor(schema: $schema) {
      counts {
        count
        date
      }
    }
  }
`;

/**
 * __useCountAndGroupScheduleAppointmentsByDateByDoctorQuery__
 *
 * To run a query within a React component, call `useCountAndGroupScheduleAppointmentsByDateByDoctorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountAndGroupScheduleAppointmentsByDateByDoctorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountAndGroupScheduleAppointmentsByDateByDoctorQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCountAndGroupScheduleAppointmentsByDateByDoctorQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CountAndGroupScheduleAppointmentsByDateByDoctorQuery,
    CountAndGroupScheduleAppointmentsByDateByDoctorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CountAndGroupScheduleAppointmentsByDateByDoctorQuery,
    CountAndGroupScheduleAppointmentsByDateByDoctorQueryVariables
  >(CountAndGroupScheduleAppointmentsByDateByDoctorDocument, options);
}
export function useCountAndGroupScheduleAppointmentsByDateByDoctorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CountAndGroupScheduleAppointmentsByDateByDoctorQuery,
    CountAndGroupScheduleAppointmentsByDateByDoctorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CountAndGroupScheduleAppointmentsByDateByDoctorQuery,
    CountAndGroupScheduleAppointmentsByDateByDoctorQueryVariables
  >(CountAndGroupScheduleAppointmentsByDateByDoctorDocument, options);
}
export type CountAndGroupScheduleAppointmentsByDateByDoctorQueryHookResult =
  ReturnType<typeof useCountAndGroupScheduleAppointmentsByDateByDoctorQuery>;
export type CountAndGroupScheduleAppointmentsByDateByDoctorLazyQueryHookResult =
  ReturnType<
    typeof useCountAndGroupScheduleAppointmentsByDateByDoctorLazyQuery
  >;
export type CountAndGroupScheduleAppointmentsByDateByDoctorQueryResult =
  Apollo.QueryResult<
    CountAndGroupScheduleAppointmentsByDateByDoctorQuery,
    CountAndGroupScheduleAppointmentsByDateByDoctorQueryVariables
  >;
export const CountNewPatientsByClientDocument = gql`
  query CountNewPatientsByClient($schema: CountNewPatientsByClientSchema!) {
    countNewPatientsByClient(schema: $schema) {
      count
    }
  }
`;

/**
 * __useCountNewPatientsByClientQuery__
 *
 * To run a query within a React component, call `useCountNewPatientsByClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountNewPatientsByClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountNewPatientsByClientQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCountNewPatientsByClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    CountNewPatientsByClientQuery,
    CountNewPatientsByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CountNewPatientsByClientQuery,
    CountNewPatientsByClientQueryVariables
  >(CountNewPatientsByClientDocument, options);
}
export function useCountNewPatientsByClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CountNewPatientsByClientQuery,
    CountNewPatientsByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CountNewPatientsByClientQuery,
    CountNewPatientsByClientQueryVariables
  >(CountNewPatientsByClientDocument, options);
}
export type CountNewPatientsByClientQueryHookResult = ReturnType<
  typeof useCountNewPatientsByClientQuery
>;
export type CountNewPatientsByClientLazyQueryHookResult = ReturnType<
  typeof useCountNewPatientsByClientLazyQuery
>;
export type CountNewPatientsByClientQueryResult = Apollo.QueryResult<
  CountNewPatientsByClientQuery,
  CountNewPatientsByClientQueryVariables
>;
export const CountNewPatientsByDoctorDocument = gql`
  query CountNewPatientsByDoctor($schema: CountNewPatientsByDoctorSchema!) {
    countNewPatientsByDoctor(schema: $schema) {
      count
    }
  }
`;

/**
 * __useCountNewPatientsByDoctorQuery__
 *
 * To run a query within a React component, call `useCountNewPatientsByDoctorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountNewPatientsByDoctorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountNewPatientsByDoctorQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCountNewPatientsByDoctorQuery(
  baseOptions: Apollo.QueryHookOptions<
    CountNewPatientsByDoctorQuery,
    CountNewPatientsByDoctorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CountNewPatientsByDoctorQuery,
    CountNewPatientsByDoctorQueryVariables
  >(CountNewPatientsByDoctorDocument, options);
}
export function useCountNewPatientsByDoctorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CountNewPatientsByDoctorQuery,
    CountNewPatientsByDoctorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CountNewPatientsByDoctorQuery,
    CountNewPatientsByDoctorQueryVariables
  >(CountNewPatientsByDoctorDocument, options);
}
export type CountNewPatientsByDoctorQueryHookResult = ReturnType<
  typeof useCountNewPatientsByDoctorQuery
>;
export type CountNewPatientsByDoctorLazyQueryHookResult = ReturnType<
  typeof useCountNewPatientsByDoctorLazyQuery
>;
export type CountNewPatientsByDoctorQueryResult = Apollo.QueryResult<
  CountNewPatientsByDoctorQuery,
  CountNewPatientsByDoctorQueryVariables
>;
export const CountScheduleAppointmentsByStatusesByClientDocument = gql`
  query CountScheduleAppointmentsByStatusesByClient(
    $schema: CountScheduleAppointmentsByStatusesByClientSchema
  ) {
    countScheduleAppointmentsByStatusesByClient(schema: $schema) {
      all
      canceled
      confirmedByApplication
      confirmedByPatient
      missed
      new
      successful
    }
  }
`;

/**
 * __useCountScheduleAppointmentsByStatusesByClientQuery__
 *
 * To run a query within a React component, call `useCountScheduleAppointmentsByStatusesByClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountScheduleAppointmentsByStatusesByClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountScheduleAppointmentsByStatusesByClientQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCountScheduleAppointmentsByStatusesByClientQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CountScheduleAppointmentsByStatusesByClientQuery,
    CountScheduleAppointmentsByStatusesByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CountScheduleAppointmentsByStatusesByClientQuery,
    CountScheduleAppointmentsByStatusesByClientQueryVariables
  >(CountScheduleAppointmentsByStatusesByClientDocument, options);
}
export function useCountScheduleAppointmentsByStatusesByClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CountScheduleAppointmentsByStatusesByClientQuery,
    CountScheduleAppointmentsByStatusesByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CountScheduleAppointmentsByStatusesByClientQuery,
    CountScheduleAppointmentsByStatusesByClientQueryVariables
  >(CountScheduleAppointmentsByStatusesByClientDocument, options);
}
export type CountScheduleAppointmentsByStatusesByClientQueryHookResult =
  ReturnType<typeof useCountScheduleAppointmentsByStatusesByClientQuery>;
export type CountScheduleAppointmentsByStatusesByClientLazyQueryHookResult =
  ReturnType<typeof useCountScheduleAppointmentsByStatusesByClientLazyQuery>;
export type CountScheduleAppointmentsByStatusesByClientQueryResult =
  Apollo.QueryResult<
    CountScheduleAppointmentsByStatusesByClientQuery,
    CountScheduleAppointmentsByStatusesByClientQueryVariables
  >;
export const CountScheduleAppointmentsByStatusesByDoctorDocument = gql`
  query CountScheduleAppointmentsByStatusesByDoctor(
    $schema: CountScheduleAppointmentsByStatusesByDoctorSchema
  ) {
    countScheduleAppointmentsByStatusesByDoctor(schema: $schema) {
      all
      canceled
      confirmedByApplication
      confirmedByPatient
      missed
      new
      successful
    }
  }
`;

/**
 * __useCountScheduleAppointmentsByStatusesByDoctorQuery__
 *
 * To run a query within a React component, call `useCountScheduleAppointmentsByStatusesByDoctorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountScheduleAppointmentsByStatusesByDoctorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountScheduleAppointmentsByStatusesByDoctorQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCountScheduleAppointmentsByStatusesByDoctorQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CountScheduleAppointmentsByStatusesByDoctorQuery,
    CountScheduleAppointmentsByStatusesByDoctorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CountScheduleAppointmentsByStatusesByDoctorQuery,
    CountScheduleAppointmentsByStatusesByDoctorQueryVariables
  >(CountScheduleAppointmentsByStatusesByDoctorDocument, options);
}
export function useCountScheduleAppointmentsByStatusesByDoctorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CountScheduleAppointmentsByStatusesByDoctorQuery,
    CountScheduleAppointmentsByStatusesByDoctorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CountScheduleAppointmentsByStatusesByDoctorQuery,
    CountScheduleAppointmentsByStatusesByDoctorQueryVariables
  >(CountScheduleAppointmentsByStatusesByDoctorDocument, options);
}
export type CountScheduleAppointmentsByStatusesByDoctorQueryHookResult =
  ReturnType<typeof useCountScheduleAppointmentsByStatusesByDoctorQuery>;
export type CountScheduleAppointmentsByStatusesByDoctorLazyQueryHookResult =
  ReturnType<typeof useCountScheduleAppointmentsByStatusesByDoctorLazyQuery>;
export type CountScheduleAppointmentsByStatusesByDoctorQueryResult =
  Apollo.QueryResult<
    CountScheduleAppointmentsByStatusesByDoctorQuery,
    CountScheduleAppointmentsByStatusesByDoctorQueryVariables
  >;
export const FindAndCountManyBlankFormsByClientDocument = gql`
  query FindAndCountManyBlankFormsByClient(
    $schema: FindAndCountManyFormsByClientSchema!
  ) {
    findAndCountManyBlankFormsByClient(schema: $schema) {
      forms {
        id
        name
        serviceId
        clinicId
        countAnswers
        updatedAt
      }
      count
    }
  }
`;

/**
 * __useFindAndCountManyBlankFormsByClientQuery__
 *
 * To run a query within a React component, call `useFindAndCountManyBlankFormsByClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAndCountManyBlankFormsByClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAndCountManyBlankFormsByClientQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindAndCountManyBlankFormsByClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAndCountManyBlankFormsByClientQuery,
    FindAndCountManyBlankFormsByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindAndCountManyBlankFormsByClientQuery,
    FindAndCountManyBlankFormsByClientQueryVariables
  >(FindAndCountManyBlankFormsByClientDocument, options);
}
export function useFindAndCountManyBlankFormsByClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAndCountManyBlankFormsByClientQuery,
    FindAndCountManyBlankFormsByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindAndCountManyBlankFormsByClientQuery,
    FindAndCountManyBlankFormsByClientQueryVariables
  >(FindAndCountManyBlankFormsByClientDocument, options);
}
export type FindAndCountManyBlankFormsByClientQueryHookResult = ReturnType<
  typeof useFindAndCountManyBlankFormsByClientQuery
>;
export type FindAndCountManyBlankFormsByClientLazyQueryHookResult = ReturnType<
  typeof useFindAndCountManyBlankFormsByClientLazyQuery
>;
export type FindAndCountManyBlankFormsByClientQueryResult = Apollo.QueryResult<
  FindAndCountManyBlankFormsByClientQuery,
  FindAndCountManyBlankFormsByClientQueryVariables
>;
export const FindAndCountManyDoctorsDocument = gql`
  query FindAndCountManyDoctors(
    $schema: FindAndCountManyDoctorsByUserIdSchema!
  ) {
    findAndCountManyDoctors(schema: $schema) {
      count
      doctors {
        createdAt
        description
        doctorTypeId
        id
        medicalCertificateNumber
        passportNumber
        phoneNumber
        promoCode
        activated
        avatarFileId
        dateOfBirth
        doctorCreatedAt
        doctorId
        email
        firstName
        lastName
        roles
      }
    }
  }
`;

/**
 * __useFindAndCountManyDoctorsQuery__
 *
 * To run a query within a React component, call `useFindAndCountManyDoctorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAndCountManyDoctorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAndCountManyDoctorsQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindAndCountManyDoctorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAndCountManyDoctorsQuery,
    FindAndCountManyDoctorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindAndCountManyDoctorsQuery,
    FindAndCountManyDoctorsQueryVariables
  >(FindAndCountManyDoctorsDocument, options);
}
export function useFindAndCountManyDoctorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAndCountManyDoctorsQuery,
    FindAndCountManyDoctorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindAndCountManyDoctorsQuery,
    FindAndCountManyDoctorsQueryVariables
  >(FindAndCountManyDoctorsDocument, options);
}
export type FindAndCountManyDoctorsQueryHookResult = ReturnType<
  typeof useFindAndCountManyDoctorsQuery
>;
export type FindAndCountManyDoctorsLazyQueryHookResult = ReturnType<
  typeof useFindAndCountManyDoctorsLazyQuery
>;
export type FindAndCountManyDoctorsQueryResult = Apollo.QueryResult<
  FindAndCountManyDoctorsQuery,
  FindAndCountManyDoctorsQueryVariables
>;
export const FindAndCountManyLastSmsByClientDocument = gql`
  query FindAndCountManyLastSmsByClient(
    $schema: FindAndCountManyLastSmsByClientSchema!
  ) {
    findAndCountManyLastSmsByClient(schema: $schema) {
      count
      sms {
        clinicId
        clinicToPatient
        content
        createdAt
        from
        id
        patient {
          id
          firstName
          lastName
          phoneNumber
          languageISOCode
        }
        patientId
        to
      }
    }
  }
`;

/**
 * __useFindAndCountManyLastSmsByClientQuery__
 *
 * To run a query within a React component, call `useFindAndCountManyLastSmsByClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAndCountManyLastSmsByClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAndCountManyLastSmsByClientQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindAndCountManyLastSmsByClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAndCountManyLastSmsByClientQuery,
    FindAndCountManyLastSmsByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindAndCountManyLastSmsByClientQuery,
    FindAndCountManyLastSmsByClientQueryVariables
  >(FindAndCountManyLastSmsByClientDocument, options);
}
export function useFindAndCountManyLastSmsByClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAndCountManyLastSmsByClientQuery,
    FindAndCountManyLastSmsByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindAndCountManyLastSmsByClientQuery,
    FindAndCountManyLastSmsByClientQueryVariables
  >(FindAndCountManyLastSmsByClientDocument, options);
}
export type FindAndCountManyLastSmsByClientQueryHookResult = ReturnType<
  typeof useFindAndCountManyLastSmsByClientQuery
>;
export type FindAndCountManyLastSmsByClientLazyQueryHookResult = ReturnType<
  typeof useFindAndCountManyLastSmsByClientLazyQuery
>;
export type FindAndCountManyLastSmsByClientQueryResult = Apollo.QueryResult<
  FindAndCountManyLastSmsByClientQuery,
  FindAndCountManyLastSmsByClientQueryVariables
>;
export const FindAndCountManyPatientsInWaitlistsByClientDocument = gql`
  query FindAndCountManyPatientsInWaitlistsByClient(
    $schema: FindAndCountManyPatientsByClientSchema!
  ) {
    findAndCountManyPatientsInWaitlistsByClient(schema: $schema) {
      count
      patients {
        activated
        comment
        companyId
        createdAt
        dateOfBirth
        email
        emailDistribution
        firstName
        gender
        id
        lastName
        passportNumber
        smsDistribution
        phoneNumber
        status
        languageISOCode
      }
    }
  }
`;

/**
 * __useFindAndCountManyPatientsInWaitlistsByClientQuery__
 *
 * To run a query within a React component, call `useFindAndCountManyPatientsInWaitlistsByClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAndCountManyPatientsInWaitlistsByClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAndCountManyPatientsInWaitlistsByClientQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindAndCountManyPatientsInWaitlistsByClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAndCountManyPatientsInWaitlistsByClientQuery,
    FindAndCountManyPatientsInWaitlistsByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindAndCountManyPatientsInWaitlistsByClientQuery,
    FindAndCountManyPatientsInWaitlistsByClientQueryVariables
  >(FindAndCountManyPatientsInWaitlistsByClientDocument, options);
}
export function useFindAndCountManyPatientsInWaitlistsByClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAndCountManyPatientsInWaitlistsByClientQuery,
    FindAndCountManyPatientsInWaitlistsByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindAndCountManyPatientsInWaitlistsByClientQuery,
    FindAndCountManyPatientsInWaitlistsByClientQueryVariables
  >(FindAndCountManyPatientsInWaitlistsByClientDocument, options);
}
export type FindAndCountManyPatientsInWaitlistsByClientQueryHookResult =
  ReturnType<typeof useFindAndCountManyPatientsInWaitlistsByClientQuery>;
export type FindAndCountManyPatientsInWaitlistsByClientLazyQueryHookResult =
  ReturnType<typeof useFindAndCountManyPatientsInWaitlistsByClientLazyQuery>;
export type FindAndCountManyPatientsInWaitlistsByClientQueryResult =
  Apollo.QueryResult<
    FindAndCountManyPatientsInWaitlistsByClientQuery,
    FindAndCountManyPatientsInWaitlistsByClientQueryVariables
  >;
export const FindAndCountManyScheduleAppointmentsWithRelationsByClientDocument = gql`
  query FindAndCountManyScheduleAppointmentsWithRelationsByClient(
    $schema: FindAndCountManyScheduleAppointmentsWithRelationsByClientSchema!
  ) {
    findAndCountManyScheduleAppointmentsWithRelationsByClient(schema: $schema) {
      appointments {
        id
        scheduleId
        patientId
        status
        createdAt
        updatedAt
        schedule {
          id
          doctorId
          clinicId
          roomId
          serviceId
          eventType
          layoutType
          daysOfWeek
          dateFrom
          dateTo
          minuteFrom
          minuteTo
          createdAt
        }
        patient {
          id
          phoneNumber
          firstName
          lastName
          email
          dateOfBirth
          gender
          companyId
          comment
          emailDistribution
          smsDistribution
          activated
          passportNumber
          status
          address
          allergic
          allergens
          postCode
          createdAt
          languageISOCode
        }
        doctor {
          id
          email
          firstName
          lastName
          activated
          dateOfBirth
          avatarFileId
          roles
          createdAt
          doctorId
          doctorTypeId
          phoneNumber
          passportNumber
          medicalCertificateNumber
          description
          promoCode
          address
          doctorCreatedAt
        }
        service {
          id
          name
          category {
            id
            name
          }
          description
          icd10Code
          icd9Code
          durationInMinutes
          price
          currencyCode
          hiddenFromPatients
          createdAt
        }
        room {
          id
          clinicId
          name
        }
        answer {
          id
          formId
          patientId
          entityType
          entityId
          answers {
            fieldName
            fieldValue
            fieldType
            order
            value
          }
          createdAt
          form {
            id
            name
            serviceId
            clinicId
            updatedAt
            fields {
              id
              order
              value
              required
              formId
              type
              hiddenFromExistingPatients
              translations {
                id
                name
                value
                placeholder
                fieldId
                languageISOCode
              }
            }
          }
        }
        clinic {
          id
          companyId
          name
          address
          houseNumber
          index
          city
          logoFileId
          licenseNumber
          geographicalCoordinates {
            latitude
            longitude
          }
          code
          phoneNumber
          description
          hiddenFromPatients
          createdAt
          rooms {
            id
            clinicId
            name
          }
          workingDays {
            id
            clinicId
            day
            fromMinute
            toMinute
          }
        }
      }
      count
    }
  }
`;

/**
 * __useFindAndCountManyScheduleAppointmentsWithRelationsByClientQuery__
 *
 * To run a query within a React component, call `useFindAndCountManyScheduleAppointmentsWithRelationsByClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAndCountManyScheduleAppointmentsWithRelationsByClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAndCountManyScheduleAppointmentsWithRelationsByClientQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindAndCountManyScheduleAppointmentsWithRelationsByClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAndCountManyScheduleAppointmentsWithRelationsByClientQuery,
    FindAndCountManyScheduleAppointmentsWithRelationsByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindAndCountManyScheduleAppointmentsWithRelationsByClientQuery,
    FindAndCountManyScheduleAppointmentsWithRelationsByClientQueryVariables
  >(FindAndCountManyScheduleAppointmentsWithRelationsByClientDocument, options);
}
export function useFindAndCountManyScheduleAppointmentsWithRelationsByClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAndCountManyScheduleAppointmentsWithRelationsByClientQuery,
    FindAndCountManyScheduleAppointmentsWithRelationsByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindAndCountManyScheduleAppointmentsWithRelationsByClientQuery,
    FindAndCountManyScheduleAppointmentsWithRelationsByClientQueryVariables
  >(FindAndCountManyScheduleAppointmentsWithRelationsByClientDocument, options);
}
export type FindAndCountManyScheduleAppointmentsWithRelationsByClientQueryHookResult =
  ReturnType<
    typeof useFindAndCountManyScheduleAppointmentsWithRelationsByClientQuery
  >;
export type FindAndCountManyScheduleAppointmentsWithRelationsByClientLazyQueryHookResult =
  ReturnType<
    typeof useFindAndCountManyScheduleAppointmentsWithRelationsByClientLazyQuery
  >;
export type FindAndCountManyScheduleAppointmentsWithRelationsByClientQueryResult =
  Apollo.QueryResult<
    FindAndCountManyScheduleAppointmentsWithRelationsByClientQuery,
    FindAndCountManyScheduleAppointmentsWithRelationsByClientQueryVariables
  >;
export const FindAndCountManyScheduleAppointmentsWithRelationsByClientPatientViewDocument = gql`
  query FindAndCountManyScheduleAppointmentsWithRelationsByClientPatientView(
    $schema: FindAndCountManyScheduleAppointmentsWithRelationsByClientSchema!
  ) {
    findAndCountManyScheduleAppointmentsWithRelationsByClient(schema: $schema) {
      appointments {
        id
        scheduleId
        patientId
        status
        createdAt
        updatedAt
        schedule {
          id
          doctorId
          clinicId
          roomId
          serviceId
          eventType
          layoutType
          daysOfWeek
          dateFrom
          dateTo
          minuteFrom
          minuteTo
          createdAt
        }
        patient {
          id
          phoneNumber
          firstName
          lastName
          email
          dateOfBirth
          gender
          companyId
          comment
          emailDistribution
          smsDistribution
          activated
          passportNumber
          status
          createdAt
          languageISOCode
        }
        doctor {
          id
          email
          firstName
          lastName
          activated
          dateOfBirth
          avatarFileId
          roles
          createdAt
          doctorId
          doctorTypeId
          phoneNumber
          passportNumber
          medicalCertificateNumber
          description
          promoCode
          address
          doctorCreatedAt
        }
        service {
          id
          name
          category {
            id
            name
          }
          description
          icd10Code
          icd9Code
          durationInMinutes
          price
          currencyCode
          hiddenFromPatients
          createdAt
        }
        clinic {
          id
          name
        }
        room {
          id
          clinicId
          name
        }
        answer {
          id
          formId
          patientId
          entityType
          entityId
          answers {
            fieldName
            fieldValue
            fieldType
            order
            value
          }
          form {
            id
            name
            serviceId
            clinicId
            updatedAt
            fields {
              id
              order
              value
              required
              formId
              type
              hiddenFromExistingPatients
              translations {
                id
                name
                value
                placeholder
                fieldId
                languageISOCode
              }
            }
          }
          createdAt
        }
      }
      count
    }
  }
`;

/**
 * __useFindAndCountManyScheduleAppointmentsWithRelationsByClientPatientViewQuery__
 *
 * To run a query within a React component, call `useFindAndCountManyScheduleAppointmentsWithRelationsByClientPatientViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAndCountManyScheduleAppointmentsWithRelationsByClientPatientViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAndCountManyScheduleAppointmentsWithRelationsByClientPatientViewQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindAndCountManyScheduleAppointmentsWithRelationsByClientPatientViewQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAndCountManyScheduleAppointmentsWithRelationsByClientPatientViewQuery,
    FindAndCountManyScheduleAppointmentsWithRelationsByClientPatientViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindAndCountManyScheduleAppointmentsWithRelationsByClientPatientViewQuery,
    FindAndCountManyScheduleAppointmentsWithRelationsByClientPatientViewQueryVariables
  >(
    FindAndCountManyScheduleAppointmentsWithRelationsByClientPatientViewDocument,
    options
  );
}
export function useFindAndCountManyScheduleAppointmentsWithRelationsByClientPatientViewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAndCountManyScheduleAppointmentsWithRelationsByClientPatientViewQuery,
    FindAndCountManyScheduleAppointmentsWithRelationsByClientPatientViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindAndCountManyScheduleAppointmentsWithRelationsByClientPatientViewQuery,
    FindAndCountManyScheduleAppointmentsWithRelationsByClientPatientViewQueryVariables
  >(
    FindAndCountManyScheduleAppointmentsWithRelationsByClientPatientViewDocument,
    options
  );
}
export type FindAndCountManyScheduleAppointmentsWithRelationsByClientPatientViewQueryHookResult =
  ReturnType<
    typeof useFindAndCountManyScheduleAppointmentsWithRelationsByClientPatientViewQuery
  >;
export type FindAndCountManyScheduleAppointmentsWithRelationsByClientPatientViewLazyQueryHookResult =
  ReturnType<
    typeof useFindAndCountManyScheduleAppointmentsWithRelationsByClientPatientViewLazyQuery
  >;
export type FindAndCountManyScheduleAppointmentsWithRelationsByClientPatientViewQueryResult =
  Apollo.QueryResult<
    FindAndCountManyScheduleAppointmentsWithRelationsByClientPatientViewQuery,
    FindAndCountManyScheduleAppointmentsWithRelationsByClientPatientViewQueryVariables
  >;
export const FindAndCountManyScheduleAppointmentsWithRelationsByDoctorDocument = gql`
  query FindAndCountManyScheduleAppointmentsWithRelationsByDoctor(
    $schema: FindAndCountManyScheduleAppointmentsWithRelationsByDoctorSchema!
  ) {
    findAndCountManyScheduleAppointmentsWithRelationsByDoctor(schema: $schema) {
      appointments {
        id
        scheduleId
        patientId
        status
        createdAt
        updatedAt
        schedule {
          id
          doctorId
          clinicId
          roomId
          serviceId
          eventType
          layoutType
          daysOfWeek
          dateFrom
          dateTo
          minuteFrom
          minuteTo
          createdAt
        }
        patient {
          id
          phoneNumber
          firstName
          lastName
          email
          dateOfBirth
          gender
          companyId
          comment
          emailDistribution
          smsDistribution
          activated
          passportNumber
          status
          address
          allergic
          allergens
          postCode
          createdAt
          languageISOCode
        }
        service {
          id
          name
          category {
            id
            name
          }
          description
          icd10Code
          icd9Code
          durationInMinutes
          price
          currencyCode
          hiddenFromPatients
          createdAt
        }
        room {
          id
          clinicId
          name
        }
        clinic {
          id
          companyId
          name
          address
          houseNumber
          index
          city
          logoFileId
          licenseNumber
          geographicalCoordinates {
            latitude
            longitude
          }
          code
          phoneNumber
          description
          hiddenFromPatients
          createdAt
          rooms {
            id
            clinicId
            name
          }
          workingDays {
            id
            clinicId
            day
            fromMinute
            toMinute
          }
        }
        answer {
          id
          formId
          patientId
          entityType
          entityId
          answers {
            fieldName
            fieldValue
            fieldType
            order
            value
          }
          createdAt
          form {
            id
            name
            serviceId
            clinicId
            updatedAt
            fields {
              id
              order
              value
              required
              formId
              type
              hiddenFromExistingPatients
              translations {
                id
                name
                value
                placeholder
                fieldId
                languageISOCode
              }
            }
          }
        }
      }
      count
    }
  }
`;

/**
 * __useFindAndCountManyScheduleAppointmentsWithRelationsByDoctorQuery__
 *
 * To run a query within a React component, call `useFindAndCountManyScheduleAppointmentsWithRelationsByDoctorQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAndCountManyScheduleAppointmentsWithRelationsByDoctorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAndCountManyScheduleAppointmentsWithRelationsByDoctorQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindAndCountManyScheduleAppointmentsWithRelationsByDoctorQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAndCountManyScheduleAppointmentsWithRelationsByDoctorQuery,
    FindAndCountManyScheduleAppointmentsWithRelationsByDoctorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindAndCountManyScheduleAppointmentsWithRelationsByDoctorQuery,
    FindAndCountManyScheduleAppointmentsWithRelationsByDoctorQueryVariables
  >(FindAndCountManyScheduleAppointmentsWithRelationsByDoctorDocument, options);
}
export function useFindAndCountManyScheduleAppointmentsWithRelationsByDoctorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAndCountManyScheduleAppointmentsWithRelationsByDoctorQuery,
    FindAndCountManyScheduleAppointmentsWithRelationsByDoctorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindAndCountManyScheduleAppointmentsWithRelationsByDoctorQuery,
    FindAndCountManyScheduleAppointmentsWithRelationsByDoctorQueryVariables
  >(FindAndCountManyScheduleAppointmentsWithRelationsByDoctorDocument, options);
}
export type FindAndCountManyScheduleAppointmentsWithRelationsByDoctorQueryHookResult =
  ReturnType<
    typeof useFindAndCountManyScheduleAppointmentsWithRelationsByDoctorQuery
  >;
export type FindAndCountManyScheduleAppointmentsWithRelationsByDoctorLazyQueryHookResult =
  ReturnType<
    typeof useFindAndCountManyScheduleAppointmentsWithRelationsByDoctorLazyQuery
  >;
export type FindAndCountManyScheduleAppointmentsWithRelationsByDoctorQueryResult =
  Apollo.QueryResult<
    FindAndCountManyScheduleAppointmentsWithRelationsByDoctorQuery,
    FindAndCountManyScheduleAppointmentsWithRelationsByDoctorQueryVariables
  >;
export const FindAndCountManyScheduleWaitlistsByClientDocument = gql`
  query FindAndCountManyScheduleWaitlistsByClient(
    $schema: FindAndCountManyScheduleWaitlistsByClientSchema!
  ) {
    findAndCountManyScheduleWaitlistsByClient(schema: $schema) {
      count
      waitlists {
        clinicId
        createdAt
        dateFrom
        dateTo
        doctor {
          id
          doctorId
          avatarFileId
          firstName
          lastName
          email
          phoneNumber
        }
        doctorId
        hash
        id
        minuteFrom
        minuteTo
        patient {
          firstName
          lastName
          phoneNumber
          languageISOCode
        }
        patientId
        service {
          category {
            id
            name
          }
          currencyCode
          description
          durationInMinutes
          id
          name
          price
        }
        serviceId
        status
        uncommittedPatientId
      }
    }
  }
`;

/**
 * __useFindAndCountManyScheduleWaitlistsByClientQuery__
 *
 * To run a query within a React component, call `useFindAndCountManyScheduleWaitlistsByClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAndCountManyScheduleWaitlistsByClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAndCountManyScheduleWaitlistsByClientQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindAndCountManyScheduleWaitlistsByClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAndCountManyScheduleWaitlistsByClientQuery,
    FindAndCountManyScheduleWaitlistsByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindAndCountManyScheduleWaitlistsByClientQuery,
    FindAndCountManyScheduleWaitlistsByClientQueryVariables
  >(FindAndCountManyScheduleWaitlistsByClientDocument, options);
}
export function useFindAndCountManyScheduleWaitlistsByClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAndCountManyScheduleWaitlistsByClientQuery,
    FindAndCountManyScheduleWaitlistsByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindAndCountManyScheduleWaitlistsByClientQuery,
    FindAndCountManyScheduleWaitlistsByClientQueryVariables
  >(FindAndCountManyScheduleWaitlistsByClientDocument, options);
}
export type FindAndCountManyScheduleWaitlistsByClientQueryHookResult =
  ReturnType<typeof useFindAndCountManyScheduleWaitlistsByClientQuery>;
export type FindAndCountManyScheduleWaitlistsByClientLazyQueryHookResult =
  ReturnType<typeof useFindAndCountManyScheduleWaitlistsByClientLazyQuery>;
export type FindAndCountManyScheduleWaitlistsByClientQueryResult =
  Apollo.QueryResult<
    FindAndCountManyScheduleWaitlistsByClientQuery,
    FindAndCountManyScheduleWaitlistsByClientQueryVariables
  >;
export const FindAndCountManySmsByClientDocument = gql`
  query FindAndCountManySmsByClient(
    $schema: FindAndCountManySmsByClientSchema!
  ) {
    findAndCountManySmsByClient(schema: $schema) {
      count
      sms {
        clinicId
        clinicToPatient
        content
        createdAt
        from
        id
        patientId
        to
      }
    }
  }
`;

/**
 * __useFindAndCountManySmsByClientQuery__
 *
 * To run a query within a React component, call `useFindAndCountManySmsByClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAndCountManySmsByClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAndCountManySmsByClientQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindAndCountManySmsByClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAndCountManySmsByClientQuery,
    FindAndCountManySmsByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindAndCountManySmsByClientQuery,
    FindAndCountManySmsByClientQueryVariables
  >(FindAndCountManySmsByClientDocument, options);
}
export function useFindAndCountManySmsByClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAndCountManySmsByClientQuery,
    FindAndCountManySmsByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindAndCountManySmsByClientQuery,
    FindAndCountManySmsByClientQueryVariables
  >(FindAndCountManySmsByClientDocument, options);
}
export type FindAndCountManySmsByClientQueryHookResult = ReturnType<
  typeof useFindAndCountManySmsByClientQuery
>;
export type FindAndCountManySmsByClientLazyQueryHookResult = ReturnType<
  typeof useFindAndCountManySmsByClientLazyQuery
>;
export type FindAndCountManySmsByClientQueryResult = Apollo.QueryResult<
  FindAndCountManySmsByClientQuery,
  FindAndCountManySmsByClientQueryVariables
>;
export const FindBlankFormByServiceIdDocument = gql`
  query FindBlankFormByServiceId($schema: FindBlankFormByServiceIdSchema!) {
    findBlankFormByServiceId(schema: $schema) {
      clinicId
      id
      name
      serviceId
      fields {
        formId
        hiddenFromExistingPatients
        id
        order
        required
        type
        value
        translations {
          fieldId
          id
          name
          languageISOCode
          placeholder
          value
        }
      }
    }
  }
`;

/**
 * __useFindBlankFormByServiceIdQuery__
 *
 * To run a query within a React component, call `useFindBlankFormByServiceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindBlankFormByServiceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindBlankFormByServiceIdQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindBlankFormByServiceIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindBlankFormByServiceIdQuery,
    FindBlankFormByServiceIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindBlankFormByServiceIdQuery,
    FindBlankFormByServiceIdQueryVariables
  >(FindBlankFormByServiceIdDocument, options);
}
export function useFindBlankFormByServiceIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindBlankFormByServiceIdQuery,
    FindBlankFormByServiceIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindBlankFormByServiceIdQuery,
    FindBlankFormByServiceIdQueryVariables
  >(FindBlankFormByServiceIdDocument, options);
}
export type FindBlankFormByServiceIdQueryHookResult = ReturnType<
  typeof useFindBlankFormByServiceIdQuery
>;
export type FindBlankFormByServiceIdLazyQueryHookResult = ReturnType<
  typeof useFindBlankFormByServiceIdLazyQuery
>;
export type FindBlankFormByServiceIdQueryResult = Apollo.QueryResult<
  FindBlankFormByServiceIdQuery,
  FindBlankFormByServiceIdQueryVariables
>;
export const FindAndCountManyAggregatedFormsPatientsByClientDocument = gql`
  query FindAndCountManyAggregatedFormsPatientsByClient(
    $schema: FindAndCountManyAggregatedFormsPatientsSchema!
  ) {
    findAndCountManyAggregatedFormsPatientsByClient(schema: $schema) {
      patients {
        id
        phoneNumber
        firstName
        lastName
        email
        dateOfBirth
        gender
        companyId
        comment
        emailDistribution
        smsDistribution
        activated
        passportNumber
        status
        address
        allergic
        allergens
        postCode
        createdAt
        languageISOCode
        answers {
          id
          formId
          patientId
          entityType
          entityId
          answers {
            fieldName
            fieldValue
            fieldType
            order
            value
          }
          createdAt
          form {
            id
            name
            serviceId
            clinicId
            updatedAt
          }
        }
      }
      count
    }
  }
`;

/**
 * __useFindAndCountManyAggregatedFormsPatientsByClientQuery__
 *
 * To run a query within a React component, call `useFindAndCountManyAggregatedFormsPatientsByClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAndCountManyAggregatedFormsPatientsByClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAndCountManyAggregatedFormsPatientsByClientQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindAndCountManyAggregatedFormsPatientsByClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAndCountManyAggregatedFormsPatientsByClientQuery,
    FindAndCountManyAggregatedFormsPatientsByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindAndCountManyAggregatedFormsPatientsByClientQuery,
    FindAndCountManyAggregatedFormsPatientsByClientQueryVariables
  >(FindAndCountManyAggregatedFormsPatientsByClientDocument, options);
}
export function useFindAndCountManyAggregatedFormsPatientsByClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAndCountManyAggregatedFormsPatientsByClientQuery,
    FindAndCountManyAggregatedFormsPatientsByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindAndCountManyAggregatedFormsPatientsByClientQuery,
    FindAndCountManyAggregatedFormsPatientsByClientQueryVariables
  >(FindAndCountManyAggregatedFormsPatientsByClientDocument, options);
}
export type FindAndCountManyAggregatedFormsPatientsByClientQueryHookResult =
  ReturnType<typeof useFindAndCountManyAggregatedFormsPatientsByClientQuery>;
export type FindAndCountManyAggregatedFormsPatientsByClientLazyQueryHookResult =
  ReturnType<
    typeof useFindAndCountManyAggregatedFormsPatientsByClientLazyQuery
  >;
export type FindAndCountManyAggregatedFormsPatientsByClientQueryResult =
  Apollo.QueryResult<
    FindAndCountManyAggregatedFormsPatientsByClientQuery,
    FindAndCountManyAggregatedFormsPatientsByClientQueryVariables
  >;
export const FindAndCountManyAggregatedFormsPatientsByDoctorDocument = gql`
  query FindAndCountManyAggregatedFormsPatientsByDoctor(
    $schema: FindAndCountManyAggregatedFormsPatientsSchema!
  ) {
    findAndCountManyAggregatedFormsPatientsByDoctor(schema: $schema) {
      patients {
        id
        phoneNumber
        firstName
        lastName
        email
        dateOfBirth
        gender
        companyId
        comment
        emailDistribution
        smsDistribution
        activated
        passportNumber
        status
        address
        allergic
        allergens
        postCode
        createdAt
        languageISOCode
        answers {
          id
          formId
          patientId
          entityType
          entityId
          answers {
            fieldName
            fieldValue
            fieldType
            order
            value
          }
          createdAt
          form {
            id
            name
            serviceId
            clinicId
            updatedAt
          }
        }
      }
      count
    }
  }
`;

/**
 * __useFindAndCountManyAggregatedFormsPatientsByDoctorQuery__
 *
 * To run a query within a React component, call `useFindAndCountManyAggregatedFormsPatientsByDoctorQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAndCountManyAggregatedFormsPatientsByDoctorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAndCountManyAggregatedFormsPatientsByDoctorQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindAndCountManyAggregatedFormsPatientsByDoctorQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAndCountManyAggregatedFormsPatientsByDoctorQuery,
    FindAndCountManyAggregatedFormsPatientsByDoctorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindAndCountManyAggregatedFormsPatientsByDoctorQuery,
    FindAndCountManyAggregatedFormsPatientsByDoctorQueryVariables
  >(FindAndCountManyAggregatedFormsPatientsByDoctorDocument, options);
}
export function useFindAndCountManyAggregatedFormsPatientsByDoctorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAndCountManyAggregatedFormsPatientsByDoctorQuery,
    FindAndCountManyAggregatedFormsPatientsByDoctorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindAndCountManyAggregatedFormsPatientsByDoctorQuery,
    FindAndCountManyAggregatedFormsPatientsByDoctorQueryVariables
  >(FindAndCountManyAggregatedFormsPatientsByDoctorDocument, options);
}
export type FindAndCountManyAggregatedFormsPatientsByDoctorQueryHookResult =
  ReturnType<typeof useFindAndCountManyAggregatedFormsPatientsByDoctorQuery>;
export type FindAndCountManyAggregatedFormsPatientsByDoctorLazyQueryHookResult =
  ReturnType<
    typeof useFindAndCountManyAggregatedFormsPatientsByDoctorLazyQuery
  >;
export type FindAndCountManyAggregatedFormsPatientsByDoctorQueryResult =
  Apollo.QueryResult<
    FindAndCountManyAggregatedFormsPatientsByDoctorQuery,
    FindAndCountManyAggregatedFormsPatientsByDoctorQueryVariables
  >;
export const FindManyScheduleAppointmentUpdatesByIdByClientDocument = gql`
  query FindManyScheduleAppointmentUpdatesByIdByClient(
    $schema: FindManyScheduleAppointmentUpdatesByIdByClientSchema!
  ) {
    findManyScheduleAppointmentUpdatesByIdByClient(schema: $schema) {
      appointmentUpdates {
        appointmentId
        createdAt
        id
        original {
          createdAt
          patientId
          scheduleId
          status
          updatedAt
        }
        updated {
          createdAt
          patientId
          status
          updatedAt
          scheduleId
        }
        userId
      }
    }
  }
`;

/**
 * __useFindManyScheduleAppointmentUpdatesByIdByClientQuery__
 *
 * To run a query within a React component, call `useFindManyScheduleAppointmentUpdatesByIdByClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyScheduleAppointmentUpdatesByIdByClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyScheduleAppointmentUpdatesByIdByClientQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindManyScheduleAppointmentUpdatesByIdByClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindManyScheduleAppointmentUpdatesByIdByClientQuery,
    FindManyScheduleAppointmentUpdatesByIdByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindManyScheduleAppointmentUpdatesByIdByClientQuery,
    FindManyScheduleAppointmentUpdatesByIdByClientQueryVariables
  >(FindManyScheduleAppointmentUpdatesByIdByClientDocument, options);
}
export function useFindManyScheduleAppointmentUpdatesByIdByClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindManyScheduleAppointmentUpdatesByIdByClientQuery,
    FindManyScheduleAppointmentUpdatesByIdByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindManyScheduleAppointmentUpdatesByIdByClientQuery,
    FindManyScheduleAppointmentUpdatesByIdByClientQueryVariables
  >(FindManyScheduleAppointmentUpdatesByIdByClientDocument, options);
}
export type FindManyScheduleAppointmentUpdatesByIdByClientQueryHookResult =
  ReturnType<typeof useFindManyScheduleAppointmentUpdatesByIdByClientQuery>;
export type FindManyScheduleAppointmentUpdatesByIdByClientLazyQueryHookResult =
  ReturnType<typeof useFindManyScheduleAppointmentUpdatesByIdByClientLazyQuery>;
export type FindManyScheduleAppointmentUpdatesByIdByClientQueryResult =
  Apollo.QueryResult<
    FindManyScheduleAppointmentUpdatesByIdByClientQuery,
    FindManyScheduleAppointmentUpdatesByIdByClientQueryVariables
  >;
export const FindManyServicesByPatientDocument = gql`
  query FindManyServicesByPatient($schema: FindManyServicesSchema!) {
    findManyServicesByPatient(schema: $schema) {
      services {
        id
        name
        category {
          id
          name
        }
        description
        icd10Code
        icd9Code
        durationInMinutes
        price
        currencyCode
        hiddenFromPatients
        createdAt
        clinicRooms {
          id
          clinicId
          name
        }
        doctorIds
      }
    }
  }
`;

/**
 * __useFindManyServicesByPatientQuery__
 *
 * To run a query within a React component, call `useFindManyServicesByPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyServicesByPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyServicesByPatientQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindManyServicesByPatientQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindManyServicesByPatientQuery,
    FindManyServicesByPatientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindManyServicesByPatientQuery,
    FindManyServicesByPatientQueryVariables
  >(FindManyServicesByPatientDocument, options);
}
export function useFindManyServicesByPatientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindManyServicesByPatientQuery,
    FindManyServicesByPatientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindManyServicesByPatientQuery,
    FindManyServicesByPatientQueryVariables
  >(FindManyServicesByPatientDocument, options);
}
export type FindManyServicesByPatientQueryHookResult = ReturnType<
  typeof useFindManyServicesByPatientQuery
>;
export type FindManyServicesByPatientLazyQueryHookResult = ReturnType<
  typeof useFindManyServicesByPatientLazyQuery
>;
export type FindManyServicesByPatientQueryResult = Apollo.QueryResult<
  FindManyServicesByPatientQuery,
  FindManyServicesByPatientQueryVariables
>;
export const FindPatientByIdDocument = gql`
  query FindPatientById($schema: FindPatientByIdSchema!) {
    findPatientById(schema: $schema) {
      id
      phoneNumber
      firstName
      lastName
      email
      dateOfBirth
      gender
      companyId
      comment
      emailDistribution
      smsDistribution
      activated
      passportNumber
      createdAt
      status
      allergic
      allergens
      languageISOCode
    }
  }
`;

/**
 * __useFindPatientByIdQuery__
 *
 * To run a query within a React component, call `useFindPatientByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPatientByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPatientByIdQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindPatientByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindPatientByIdQuery,
    FindPatientByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindPatientByIdQuery, FindPatientByIdQueryVariables>(
    FindPatientByIdDocument,
    options
  );
}
export function useFindPatientByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindPatientByIdQuery,
    FindPatientByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindPatientByIdQuery,
    FindPatientByIdQueryVariables
  >(FindPatientByIdDocument, options);
}
export type FindPatientByIdQueryHookResult = ReturnType<
  typeof useFindPatientByIdQuery
>;
export type FindPatientByIdLazyQueryHookResult = ReturnType<
  typeof useFindPatientByIdLazyQuery
>;
export type FindPatientByIdQueryResult = Apollo.QueryResult<
  FindPatientByIdQuery,
  FindPatientByIdQueryVariables
>;
export const FindScheduleAppointmentWithRelationsByHashByPatientDocument = gql`
  query FindScheduleAppointmentWithRelationsByHashByPatient(
    $schema: FindScheduleAppointmentWithRelationsByHashByPatientSchema!
  ) {
    findScheduleAppointmentWithRelationsByHashByPatient(schema: $schema) {
      clinic {
        name
        id
        logoFileId
        address
        phoneNumber
      }
      schedule {
        dateFrom
        dateTo
        minuteFrom
        minuteTo
        doctorId
      }
      status
      patient {
        phoneNumber
        languageISOCode
      }
      doctor {
        firstName
        lastName
      }
      service {
        id
      }
      client {
        id
      }
      languageISOCode
    }
  }
`;

/**
 * __useFindScheduleAppointmentWithRelationsByHashByPatientQuery__
 *
 * To run a query within a React component, call `useFindScheduleAppointmentWithRelationsByHashByPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindScheduleAppointmentWithRelationsByHashByPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindScheduleAppointmentWithRelationsByHashByPatientQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindScheduleAppointmentWithRelationsByHashByPatientQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindScheduleAppointmentWithRelationsByHashByPatientQuery,
    FindScheduleAppointmentWithRelationsByHashByPatientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindScheduleAppointmentWithRelationsByHashByPatientQuery,
    FindScheduleAppointmentWithRelationsByHashByPatientQueryVariables
  >(FindScheduleAppointmentWithRelationsByHashByPatientDocument, options);
}
export function useFindScheduleAppointmentWithRelationsByHashByPatientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindScheduleAppointmentWithRelationsByHashByPatientQuery,
    FindScheduleAppointmentWithRelationsByHashByPatientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindScheduleAppointmentWithRelationsByHashByPatientQuery,
    FindScheduleAppointmentWithRelationsByHashByPatientQueryVariables
  >(FindScheduleAppointmentWithRelationsByHashByPatientDocument, options);
}
export type FindScheduleAppointmentWithRelationsByHashByPatientQueryHookResult =
  ReturnType<
    typeof useFindScheduleAppointmentWithRelationsByHashByPatientQuery
  >;
export type FindScheduleAppointmentWithRelationsByHashByPatientLazyQueryHookResult =
  ReturnType<
    typeof useFindScheduleAppointmentWithRelationsByHashByPatientLazyQuery
  >;
export type FindScheduleAppointmentWithRelationsByHashByPatientQueryResult =
  Apollo.QueryResult<
    FindScheduleAppointmentWithRelationsByHashByPatientQuery,
    FindScheduleAppointmentWithRelationsByHashByPatientQueryVariables
  >;
export const FindScheduleWaitlistByHashByPatientDocument = gql`
  query FindScheduleWaitlistByHashByPatient(
    $schema: FindScheduleAppointmentWithRelationsByHashByPatientSchema!
  ) {
    findScheduleWaitlistByHashByPatient(schema: $schema) {
      id
      clinicId
      serviceId
      patientId
      doctorId
      dateFrom
      dateTo
      minuteFrom
      minuteTo
      status
      hash
      uncommittedPatientId
      createdAt
      doctor {
        id
        email
        firstName
        lastName
        activated
        dateOfBirth
        avatarFileId
        roles
        createdAt
        doctorId
        doctorTypeId
        phoneNumber
        passportNumber
        medicalCertificateNumber
        description
        promoCode
        address
        doctorCreatedAt
      }
      clinic {
        id
        companyId
        name
        address
        houseNumber
        index
        city
        logoFileId
        licenseNumber
        geographicalCoordinates {
          latitude
          longitude
        }
        code
        phoneNumber
        description
        hiddenFromPatients
        createdAt
        rooms {
          id
          clinicId
          name
        }
        workingDays {
          id
          clinicId
          day
          fromMinute
          toMinute
        }
      }
      patient {
        id
        phoneNumber
        firstName
        lastName
        email
        dateOfBirth
        gender
        companyId
        comment
        emailDistribution
        smsDistribution
        activated
        passportNumber
        status
        address
        postCode
        createdAt
        languageISOCode
      }
      service {
        id
        name
        category {
          id
          name
        }
        description
        icd10Code
        icd9Code
        durationInMinutes
        price
        currencyCode
        hiddenFromPatients
        createdAt
      }
      client {
        id
        userId
        countryId
        phoneNumber
        promoCode
        passportNumber
        createdAt
      }
      answer {
        id
        formId
        patientId
        entityType
        entityId
        answers {
          fieldName
          fieldValue
          fieldType
          order
          value
        }
        createdAt
      }
      languageISOCode
    }
  }
`;

/**
 * __useFindScheduleWaitlistByHashByPatientQuery__
 *
 * To run a query within a React component, call `useFindScheduleWaitlistByHashByPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindScheduleWaitlistByHashByPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindScheduleWaitlistByHashByPatientQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindScheduleWaitlistByHashByPatientQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindScheduleWaitlistByHashByPatientQuery,
    FindScheduleWaitlistByHashByPatientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindScheduleWaitlistByHashByPatientQuery,
    FindScheduleWaitlistByHashByPatientQueryVariables
  >(FindScheduleWaitlistByHashByPatientDocument, options);
}
export function useFindScheduleWaitlistByHashByPatientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindScheduleWaitlistByHashByPatientQuery,
    FindScheduleWaitlistByHashByPatientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindScheduleWaitlistByHashByPatientQuery,
    FindScheduleWaitlistByHashByPatientQueryVariables
  >(FindScheduleWaitlistByHashByPatientDocument, options);
}
export type FindScheduleWaitlistByHashByPatientQueryHookResult = ReturnType<
  typeof useFindScheduleWaitlistByHashByPatientQuery
>;
export type FindScheduleWaitlistByHashByPatientLazyQueryHookResult = ReturnType<
  typeof useFindScheduleWaitlistByHashByPatientLazyQuery
>;
export type FindScheduleWaitlistByHashByPatientQueryResult = Apollo.QueryResult<
  FindScheduleWaitlistByHashByPatientQuery,
  FindScheduleWaitlistByHashByPatientQueryVariables
>;
export const FindSentTemplateCountByEventByClientDocument = gql`
  query FindSentTemplateCountByEventByClient(
    $schema: FindSentTemplateCountByEventSchema!
  ) {
    findSentTemplateCountByEventByClient(schema: $schema) {
      sent
    }
  }
`;

/**
 * __useFindSentTemplateCountByEventByClientQuery__
 *
 * To run a query within a React component, call `useFindSentTemplateCountByEventByClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSentTemplateCountByEventByClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSentTemplateCountByEventByClientQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindSentTemplateCountByEventByClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindSentTemplateCountByEventByClientQuery,
    FindSentTemplateCountByEventByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindSentTemplateCountByEventByClientQuery,
    FindSentTemplateCountByEventByClientQueryVariables
  >(FindSentTemplateCountByEventByClientDocument, options);
}
export function useFindSentTemplateCountByEventByClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindSentTemplateCountByEventByClientQuery,
    FindSentTemplateCountByEventByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindSentTemplateCountByEventByClientQuery,
    FindSentTemplateCountByEventByClientQueryVariables
  >(FindSentTemplateCountByEventByClientDocument, options);
}
export type FindSentTemplateCountByEventByClientQueryHookResult = ReturnType<
  typeof useFindSentTemplateCountByEventByClientQuery
>;
export type FindSentTemplateCountByEventByClientLazyQueryHookResult =
  ReturnType<typeof useFindSentTemplateCountByEventByClientLazyQuery>;
export type FindSentTemplateCountByEventByClientQueryResult =
  Apollo.QueryResult<
    FindSentTemplateCountByEventByClientQuery,
    FindSentTemplateCountByEventByClientQueryVariables
  >;
export const FindSentTemplateCountByEventByDoctorDocument = gql`
  query FindSentTemplateCountByEventByDoctor(
    $schema: FindSentTemplateCountByEventSchema!
  ) {
    findSentTemplateCountByEventByDoctor(schema: $schema) {
      sent
    }
  }
`;

/**
 * __useFindSentTemplateCountByEventByDoctorQuery__
 *
 * To run a query within a React component, call `useFindSentTemplateCountByEventByDoctorQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSentTemplateCountByEventByDoctorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSentTemplateCountByEventByDoctorQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindSentTemplateCountByEventByDoctorQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindSentTemplateCountByEventByDoctorQuery,
    FindSentTemplateCountByEventByDoctorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindSentTemplateCountByEventByDoctorQuery,
    FindSentTemplateCountByEventByDoctorQueryVariables
  >(FindSentTemplateCountByEventByDoctorDocument, options);
}
export function useFindSentTemplateCountByEventByDoctorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindSentTemplateCountByEventByDoctorQuery,
    FindSentTemplateCountByEventByDoctorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindSentTemplateCountByEventByDoctorQuery,
    FindSentTemplateCountByEventByDoctorQueryVariables
  >(FindSentTemplateCountByEventByDoctorDocument, options);
}
export type FindSentTemplateCountByEventByDoctorQueryHookResult = ReturnType<
  typeof useFindSentTemplateCountByEventByDoctorQuery
>;
export type FindSentTemplateCountByEventByDoctorLazyQueryHookResult =
  ReturnType<typeof useFindSentTemplateCountByEventByDoctorLazyQuery>;
export type FindSentTemplateCountByEventByDoctorQueryResult =
  Apollo.QueryResult<
    FindSentTemplateCountByEventByDoctorQuery,
    FindSentTemplateCountByEventByDoctorQueryVariables
  >;
export const GetCurrentClientDocument = gql`
  query GetCurrentClient {
    getCurrentClient {
      countryId
      createdAt
      id
      passportNumber
      phoneNumber
      promoCode
      userId
    }
  }
`;

/**
 * __useGetCurrentClientQuery__
 *
 * To run a query within a React component, call `useGetCurrentClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentClientQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentClientQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentClientQuery,
    GetCurrentClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentClientQuery, GetCurrentClientQueryVariables>(
    GetCurrentClientDocument,
    options
  );
}
export function useGetCurrentClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentClientQuery,
    GetCurrentClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentClientQuery,
    GetCurrentClientQueryVariables
  >(GetCurrentClientDocument, options);
}
export type GetCurrentClientQueryHookResult = ReturnType<
  typeof useGetCurrentClientQuery
>;
export type GetCurrentClientLazyQueryHookResult = ReturnType<
  typeof useGetCurrentClientLazyQuery
>;
export type GetCurrentClientQueryResult = Apollo.QueryResult<
  GetCurrentClientQuery,
  GetCurrentClientQueryVariables
>;
export const GetCurrentDoctorDocument = gql`
  query GetCurrentDoctor {
    getCurrentDoctor {
      createdAt
      description
      id
      medicalCertificateNumber
      passportNumber
      phoneNumber
      promoCode
      userId
    }
  }
`;

/**
 * __useGetCurrentDoctorQuery__
 *
 * To run a query within a React component, call `useGetCurrentDoctorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentDoctorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentDoctorQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentDoctorQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentDoctorQuery,
    GetCurrentDoctorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentDoctorQuery, GetCurrentDoctorQueryVariables>(
    GetCurrentDoctorDocument,
    options
  );
}
export function useGetCurrentDoctorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentDoctorQuery,
    GetCurrentDoctorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentDoctorQuery,
    GetCurrentDoctorQueryVariables
  >(GetCurrentDoctorDocument, options);
}
export type GetCurrentDoctorQueryHookResult = ReturnType<
  typeof useGetCurrentDoctorQuery
>;
export type GetCurrentDoctorLazyQueryHookResult = ReturnType<
  typeof useGetCurrentDoctorLazyQuery
>;
export type GetCurrentDoctorQueryResult = Apollo.QueryResult<
  GetCurrentDoctorQuery,
  GetCurrentDoctorQueryVariables
>;
export const GetCurrentUserDocument = gql`
  query GetCurrentUser {
    getCurrentUser {
      id
      email
      firstName
      lastName
      activated
      roles
      createdAt
      avatarFileId
    }
  }
`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options
  );
}
export function useGetCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(
    GetCurrentUserDocument,
    options
  );
}
export type GetCurrentUserQueryHookResult = ReturnType<
  typeof useGetCurrentUserQuery
>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserLazyQuery
>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables
>;
export const FindAllCountriesDocument = gql`
  query FindAllCountries {
    findAllCountries {
      countries {
        id
        isoAlpha2Code
        isoAlpha3Code
        name
      }
    }
  }
`;

/**
 * __useFindAllCountriesQuery__
 *
 * To run a query within a React component, call `useFindAllCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindAllCountriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindAllCountriesQuery,
    FindAllCountriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindAllCountriesQuery, FindAllCountriesQueryVariables>(
    FindAllCountriesDocument,
    options
  );
}
export function useFindAllCountriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAllCountriesQuery,
    FindAllCountriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindAllCountriesQuery,
    FindAllCountriesQueryVariables
  >(FindAllCountriesDocument, options);
}
export type FindAllCountriesQueryHookResult = ReturnType<
  typeof useFindAllCountriesQuery
>;
export type FindAllCountriesLazyQueryHookResult = ReturnType<
  typeof useFindAllCountriesLazyQuery
>;
export type FindAllCountriesQueryResult = Apollo.QueryResult<
  FindAllCountriesQuery,
  FindAllCountriesQueryVariables
>;
export const FindManyServiceCategoriesByClinicIdDocument = gql`
  query FindManyServiceCategoriesByClinicId(
    $schema: FindManyServiceCategoriesByClinicIdSchema!
  ) {
    findManyServiceCategoriesByClinicId(schema: $schema) {
      categories {
        id
        name
      }
    }
  }
`;

/**
 * __useFindManyServiceCategoriesByClinicIdQuery__
 *
 * To run a query within a React component, call `useFindManyServiceCategoriesByClinicIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyServiceCategoriesByClinicIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyServiceCategoriesByClinicIdQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindManyServiceCategoriesByClinicIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindManyServiceCategoriesByClinicIdQuery,
    FindManyServiceCategoriesByClinicIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindManyServiceCategoriesByClinicIdQuery,
    FindManyServiceCategoriesByClinicIdQueryVariables
  >(FindManyServiceCategoriesByClinicIdDocument, options);
}
export function useFindManyServiceCategoriesByClinicIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindManyServiceCategoriesByClinicIdQuery,
    FindManyServiceCategoriesByClinicIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindManyServiceCategoriesByClinicIdQuery,
    FindManyServiceCategoriesByClinicIdQueryVariables
  >(FindManyServiceCategoriesByClinicIdDocument, options);
}
export type FindManyServiceCategoriesByClinicIdQueryHookResult = ReturnType<
  typeof useFindManyServiceCategoriesByClinicIdQuery
>;
export type FindManyServiceCategoriesByClinicIdLazyQueryHookResult = ReturnType<
  typeof useFindManyServiceCategoriesByClinicIdLazyQuery
>;
export type FindManyServiceCategoriesByClinicIdQueryResult = Apollo.QueryResult<
  FindManyServiceCategoriesByClinicIdQuery,
  FindManyServiceCategoriesByClinicIdQueryVariables
>;
export const FindAndCountManyClinicsDocument = gql`
  query FindAndCountManyClinics($schema: FindAndCountManyClinics!) {
    findAndCountManyClinics(schema: $schema) {
      clinics {
        description
        address
        city
        code
        companyId
        createdAt
        geographicalCoordinates {
          latitude
          longitude
        }
        houseNumber
        id
        index
        licenseNumber
        logoFileId
        name
        phoneNumber
        hiddenFromPatients
        rooms {
          clinicId
          id
          name
        }
        workingDays {
          clinicId
          fromMinute
          id
          toMinute
          day
        }
      }
      count
    }
  }
`;

/**
 * __useFindAndCountManyClinicsQuery__
 *
 * To run a query within a React component, call `useFindAndCountManyClinicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAndCountManyClinicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAndCountManyClinicsQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindAndCountManyClinicsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAndCountManyClinicsQuery,
    FindAndCountManyClinicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindAndCountManyClinicsQuery,
    FindAndCountManyClinicsQueryVariables
  >(FindAndCountManyClinicsDocument, options);
}
export function useFindAndCountManyClinicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAndCountManyClinicsQuery,
    FindAndCountManyClinicsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindAndCountManyClinicsQuery,
    FindAndCountManyClinicsQueryVariables
  >(FindAndCountManyClinicsDocument, options);
}
export type FindAndCountManyClinicsQueryHookResult = ReturnType<
  typeof useFindAndCountManyClinicsQuery
>;
export type FindAndCountManyClinicsLazyQueryHookResult = ReturnType<
  typeof useFindAndCountManyClinicsLazyQuery
>;
export type FindAndCountManyClinicsQueryResult = Apollo.QueryResult<
  FindAndCountManyClinicsQuery,
  FindAndCountManyClinicsQueryVariables
>;
export const FindAndCountManyClinicsByClientIdDocument = gql`
  query FindAndCountManyClinicsByClientId(
    $schema: FindAndCountManyClinicsByClientIdSchema!
  ) {
    findAndCountManyClinicsByClientId(schema: $schema) {
      clinics {
        id
        companyId
        name
        address
        houseNumber
        index
        city
        logoFileId
        licenseNumber
        geographicalCoordinates {
          latitude
          longitude
        }
        code
        phoneNumber
        description
        hiddenFromPatients
        createdAt
        rooms {
          id
          clinicId
          name
        }
        workingDays {
          id
          clinicId
          day
          fromMinute
          toMinute
        }
      }
      count
    }
  }
`;

/**
 * __useFindAndCountManyClinicsByClientIdQuery__
 *
 * To run a query within a React component, call `useFindAndCountManyClinicsByClientIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAndCountManyClinicsByClientIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAndCountManyClinicsByClientIdQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindAndCountManyClinicsByClientIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAndCountManyClinicsByClientIdQuery,
    FindAndCountManyClinicsByClientIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindAndCountManyClinicsByClientIdQuery,
    FindAndCountManyClinicsByClientIdQueryVariables
  >(FindAndCountManyClinicsByClientIdDocument, options);
}
export function useFindAndCountManyClinicsByClientIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAndCountManyClinicsByClientIdQuery,
    FindAndCountManyClinicsByClientIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindAndCountManyClinicsByClientIdQuery,
    FindAndCountManyClinicsByClientIdQueryVariables
  >(FindAndCountManyClinicsByClientIdDocument, options);
}
export type FindAndCountManyClinicsByClientIdQueryHookResult = ReturnType<
  typeof useFindAndCountManyClinicsByClientIdQuery
>;
export type FindAndCountManyClinicsByClientIdLazyQueryHookResult = ReturnType<
  typeof useFindAndCountManyClinicsByClientIdLazyQuery
>;
export type FindAndCountManyClinicsByClientIdQueryResult = Apollo.QueryResult<
  FindAndCountManyClinicsByClientIdQuery,
  FindAndCountManyClinicsByClientIdQueryVariables
>;
export const FindAndCountManyDoctorsByClinicIdDocument = gql`
  query FindAndCountManyDoctorsByClinicId(
    $schema: FindAndCountManyDoctorsByClinicIdSchema!
  ) {
    findAndCountManyDoctorsByClinicId(schema: $schema) {
      count
      doctors {
        createdAt
        description
        doctorTypeId
        id
        medicalCertificateNumber
        passportNumber
        phoneNumber
        promoCode
        activated
        avatarFileId
        dateOfBirth
        doctorCreatedAt
        doctorId
        email
        firstName
        lastName
        roles
      }
    }
  }
`;

/**
 * __useFindAndCountManyDoctorsByClinicIdQuery__
 *
 * To run a query within a React component, call `useFindAndCountManyDoctorsByClinicIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAndCountManyDoctorsByClinicIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAndCountManyDoctorsByClinicIdQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindAndCountManyDoctorsByClinicIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAndCountManyDoctorsByClinicIdQuery,
    FindAndCountManyDoctorsByClinicIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindAndCountManyDoctorsByClinicIdQuery,
    FindAndCountManyDoctorsByClinicIdQueryVariables
  >(FindAndCountManyDoctorsByClinicIdDocument, options);
}
export function useFindAndCountManyDoctorsByClinicIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAndCountManyDoctorsByClinicIdQuery,
    FindAndCountManyDoctorsByClinicIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindAndCountManyDoctorsByClinicIdQuery,
    FindAndCountManyDoctorsByClinicIdQueryVariables
  >(FindAndCountManyDoctorsByClinicIdDocument, options);
}
export type FindAndCountManyDoctorsByClinicIdQueryHookResult = ReturnType<
  typeof useFindAndCountManyDoctorsByClinicIdQuery
>;
export type FindAndCountManyDoctorsByClinicIdLazyQueryHookResult = ReturnType<
  typeof useFindAndCountManyDoctorsByClinicIdLazyQuery
>;
export type FindAndCountManyDoctorsByClinicIdQueryResult = Apollo.QueryResult<
  FindAndCountManyDoctorsByClinicIdQuery,
  FindAndCountManyDoctorsByClinicIdQueryVariables
>;
export const FindAndCountManyFilledFormsByClientTableViewDocument = gql`
  query findAndCountManyFilledFormsByClientTableView(
    $schema: FindAndCountManyFormsByClientSchema!
  ) {
    findAndCountManyFilledFormsByClient(schema: $schema) {
      count
      forms {
        answers {
          id
          formId
          patientId
          createdAt
          patient {
            firstName
            lastName
            languageISOCode
          }
        }
        id
        name
        serviceId
        clinicId
      }
    }
  }
`;

/**
 * __useFindAndCountManyFilledFormsByClientTableViewQuery__
 *
 * To run a query within a React component, call `useFindAndCountManyFilledFormsByClientTableViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAndCountManyFilledFormsByClientTableViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAndCountManyFilledFormsByClientTableViewQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindAndCountManyFilledFormsByClientTableViewQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAndCountManyFilledFormsByClientTableViewQuery,
    FindAndCountManyFilledFormsByClientTableViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindAndCountManyFilledFormsByClientTableViewQuery,
    FindAndCountManyFilledFormsByClientTableViewQueryVariables
  >(FindAndCountManyFilledFormsByClientTableViewDocument, options);
}
export function useFindAndCountManyFilledFormsByClientTableViewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAndCountManyFilledFormsByClientTableViewQuery,
    FindAndCountManyFilledFormsByClientTableViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindAndCountManyFilledFormsByClientTableViewQuery,
    FindAndCountManyFilledFormsByClientTableViewQueryVariables
  >(FindAndCountManyFilledFormsByClientTableViewDocument, options);
}
export type FindAndCountManyFilledFormsByClientTableViewQueryHookResult =
  ReturnType<typeof useFindAndCountManyFilledFormsByClientTableViewQuery>;
export type FindAndCountManyFilledFormsByClientTableViewLazyQueryHookResult =
  ReturnType<typeof useFindAndCountManyFilledFormsByClientTableViewLazyQuery>;
export type FindAndCountManyFilledFormsByClientTableViewQueryResult =
  Apollo.QueryResult<
    FindAndCountManyFilledFormsByClientTableViewQuery,
    FindAndCountManyFilledFormsByClientTableViewQueryVariables
  >;
export const FindAndCountManyFilledFormsByClientInfoViewDocument = gql`
  query FindAndCountManyFilledFormsByClientInfoView(
    $schema: FindAndCountManyFormsByClientSchema!
  ) {
    findAndCountManyFilledFormsByClient(schema: $schema) {
      forms {
        id
        name
        serviceId
        clinicId
        updatedAt
        answers {
          id
          formId
          patientId
          createdAt
          answers {
            fieldName
            fieldValue
            fieldType
            order
            value
          }
          createdAt
          patient {
            firstName
            lastName
            languageISOCode
            id
            phoneNumber
            firstName
            lastName
            email
            dateOfBirth
            gender
            companyId
            comment
            emailDistribution
            smsDistribution
            activated
            passportNumber
            status
            address
            allergic
            allergens
            postCode
            createdAt
            languageISOCode
          }
        }
        fields {
          id
          order
          value
          required
          formId
          type
          hiddenFromExistingPatients
          translations {
            id
            name
            value
            placeholder
            fieldId
            languageISOCode
          }
        }
      }
    }
  }
`;

/**
 * __useFindAndCountManyFilledFormsByClientInfoViewQuery__
 *
 * To run a query within a React component, call `useFindAndCountManyFilledFormsByClientInfoViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAndCountManyFilledFormsByClientInfoViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAndCountManyFilledFormsByClientInfoViewQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindAndCountManyFilledFormsByClientInfoViewQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAndCountManyFilledFormsByClientInfoViewQuery,
    FindAndCountManyFilledFormsByClientInfoViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindAndCountManyFilledFormsByClientInfoViewQuery,
    FindAndCountManyFilledFormsByClientInfoViewQueryVariables
  >(FindAndCountManyFilledFormsByClientInfoViewDocument, options);
}
export function useFindAndCountManyFilledFormsByClientInfoViewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAndCountManyFilledFormsByClientInfoViewQuery,
    FindAndCountManyFilledFormsByClientInfoViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindAndCountManyFilledFormsByClientInfoViewQuery,
    FindAndCountManyFilledFormsByClientInfoViewQueryVariables
  >(FindAndCountManyFilledFormsByClientInfoViewDocument, options);
}
export type FindAndCountManyFilledFormsByClientInfoViewQueryHookResult =
  ReturnType<typeof useFindAndCountManyFilledFormsByClientInfoViewQuery>;
export type FindAndCountManyFilledFormsByClientInfoViewLazyQueryHookResult =
  ReturnType<typeof useFindAndCountManyFilledFormsByClientInfoViewLazyQuery>;
export type FindAndCountManyFilledFormsByClientInfoViewQueryResult =
  Apollo.QueryResult<
    FindAndCountManyFilledFormsByClientInfoViewQuery,
    FindAndCountManyFilledFormsByClientInfoViewQueryVariables
  >;
export const FindAndCountManyFilledFormsByDoctorTableViewDocument = gql`
  query findAndCountManyFilledFormsByDoctorTableView(
    $schema: FindAndCountManyFormsByDoctorSchema!
  ) {
    findAndCountManyFilledFormsByDoctor(schema: $schema) {
      count
      forms {
        answers {
          id
          formId
          patientId
          createdAt
          patient {
            firstName
            lastName
            languageISOCode
          }
        }
        id
        name
        serviceId
        clinicId
      }
    }
  }
`;

/**
 * __useFindAndCountManyFilledFormsByDoctorTableViewQuery__
 *
 * To run a query within a React component, call `useFindAndCountManyFilledFormsByDoctorTableViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAndCountManyFilledFormsByDoctorTableViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAndCountManyFilledFormsByDoctorTableViewQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindAndCountManyFilledFormsByDoctorTableViewQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAndCountManyFilledFormsByDoctorTableViewQuery,
    FindAndCountManyFilledFormsByDoctorTableViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindAndCountManyFilledFormsByDoctorTableViewQuery,
    FindAndCountManyFilledFormsByDoctorTableViewQueryVariables
  >(FindAndCountManyFilledFormsByDoctorTableViewDocument, options);
}
export function useFindAndCountManyFilledFormsByDoctorTableViewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAndCountManyFilledFormsByDoctorTableViewQuery,
    FindAndCountManyFilledFormsByDoctorTableViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindAndCountManyFilledFormsByDoctorTableViewQuery,
    FindAndCountManyFilledFormsByDoctorTableViewQueryVariables
  >(FindAndCountManyFilledFormsByDoctorTableViewDocument, options);
}
export type FindAndCountManyFilledFormsByDoctorTableViewQueryHookResult =
  ReturnType<typeof useFindAndCountManyFilledFormsByDoctorTableViewQuery>;
export type FindAndCountManyFilledFormsByDoctorTableViewLazyQueryHookResult =
  ReturnType<typeof useFindAndCountManyFilledFormsByDoctorTableViewLazyQuery>;
export type FindAndCountManyFilledFormsByDoctorTableViewQueryResult =
  Apollo.QueryResult<
    FindAndCountManyFilledFormsByDoctorTableViewQuery,
    FindAndCountManyFilledFormsByDoctorTableViewQueryVariables
  >;
export const FindAndCountManyFilledFormsByDoctorInfoViewDocument = gql`
  query FindAndCountManyFilledFormsByDoctorInfoView(
    $schema: FindAndCountManyFormsByDoctorSchema!
  ) {
    findAndCountManyFilledFormsByDoctor(schema: $schema) {
      forms {
        id
        name
        serviceId
        clinicId
        updatedAt
        answers {
          id
          formId
          patientId
          createdAt
          answers {
            fieldName
            fieldValue
            fieldType
            order
            value
          }
          createdAt
          patient {
            id
            phoneNumber
            firstName
            lastName
            email
            dateOfBirth
            gender
            companyId
            comment
            emailDistribution
            smsDistribution
            activated
            passportNumber
            status
            address
            allergic
            allergens
            postCode
            createdAt
            languageISOCode
          }
        }
        fields {
          id
          order
          value
          required
          formId
          type
          hiddenFromExistingPatients
          translations {
            id
            name
            value
            placeholder
            fieldId
            languageISOCode
          }
        }
      }
    }
  }
`;

/**
 * __useFindAndCountManyFilledFormsByDoctorInfoViewQuery__
 *
 * To run a query within a React component, call `useFindAndCountManyFilledFormsByDoctorInfoViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAndCountManyFilledFormsByDoctorInfoViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAndCountManyFilledFormsByDoctorInfoViewQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindAndCountManyFilledFormsByDoctorInfoViewQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAndCountManyFilledFormsByDoctorInfoViewQuery,
    FindAndCountManyFilledFormsByDoctorInfoViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindAndCountManyFilledFormsByDoctorInfoViewQuery,
    FindAndCountManyFilledFormsByDoctorInfoViewQueryVariables
  >(FindAndCountManyFilledFormsByDoctorInfoViewDocument, options);
}
export function useFindAndCountManyFilledFormsByDoctorInfoViewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAndCountManyFilledFormsByDoctorInfoViewQuery,
    FindAndCountManyFilledFormsByDoctorInfoViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindAndCountManyFilledFormsByDoctorInfoViewQuery,
    FindAndCountManyFilledFormsByDoctorInfoViewQueryVariables
  >(FindAndCountManyFilledFormsByDoctorInfoViewDocument, options);
}
export type FindAndCountManyFilledFormsByDoctorInfoViewQueryHookResult =
  ReturnType<typeof useFindAndCountManyFilledFormsByDoctorInfoViewQuery>;
export type FindAndCountManyFilledFormsByDoctorInfoViewLazyQueryHookResult =
  ReturnType<typeof useFindAndCountManyFilledFormsByDoctorInfoViewLazyQuery>;
export type FindAndCountManyFilledFormsByDoctorInfoViewQueryResult =
  Apollo.QueryResult<
    FindAndCountManyFilledFormsByDoctorInfoViewQuery,
    FindAndCountManyFilledFormsByDoctorInfoViewQueryVariables
  >;
export const FindAndCountManyPatientsByClientDocument = gql`
  query FindAndCountManyPatientsByClient(
    $schema: FindAndCountManyPatientsByClientSchema!
  ) {
    findAndCountManyPatientsByClient(schema: $schema) {
      patients {
        id
        phoneNumber
        firstName
        lastName
        email
        dateOfBirth
        gender
        companyId
        comment
        emailDistribution
        smsDistribution
        activated
        passportNumber
        createdAt
        status
        languageISOCode
      }
      count
    }
  }
`;

/**
 * __useFindAndCountManyPatientsByClientQuery__
 *
 * To run a query within a React component, call `useFindAndCountManyPatientsByClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAndCountManyPatientsByClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAndCountManyPatientsByClientQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindAndCountManyPatientsByClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAndCountManyPatientsByClientQuery,
    FindAndCountManyPatientsByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindAndCountManyPatientsByClientQuery,
    FindAndCountManyPatientsByClientQueryVariables
  >(FindAndCountManyPatientsByClientDocument, options);
}
export function useFindAndCountManyPatientsByClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAndCountManyPatientsByClientQuery,
    FindAndCountManyPatientsByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindAndCountManyPatientsByClientQuery,
    FindAndCountManyPatientsByClientQueryVariables
  >(FindAndCountManyPatientsByClientDocument, options);
}
export type FindAndCountManyPatientsByClientQueryHookResult = ReturnType<
  typeof useFindAndCountManyPatientsByClientQuery
>;
export type FindAndCountManyPatientsByClientLazyQueryHookResult = ReturnType<
  typeof useFindAndCountManyPatientsByClientLazyQuery
>;
export type FindAndCountManyPatientsByClientQueryResult = Apollo.QueryResult<
  FindAndCountManyPatientsByClientQuery,
  FindAndCountManyPatientsByClientQueryVariables
>;
export const FindAndCountManyPatientsByDoctorDocument = gql`
  query FindAndCountManyPatientsByDoctor(
    $schema: FindAndCountManyPatientsByDoctorSchema!
  ) {
    findAndCountManyPatientsByDoctor(schema: $schema) {
      patients {
        id
        phoneNumber
        firstName
        lastName
        email
        dateOfBirth
        gender
        companyId
        comment
        emailDistribution
        smsDistribution
        activated
        passportNumber
        createdAt
        status
        languageISOCode
      }
      count
    }
  }
`;

/**
 * __useFindAndCountManyPatientsByDoctorQuery__
 *
 * To run a query within a React component, call `useFindAndCountManyPatientsByDoctorQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAndCountManyPatientsByDoctorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAndCountManyPatientsByDoctorQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindAndCountManyPatientsByDoctorQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAndCountManyPatientsByDoctorQuery,
    FindAndCountManyPatientsByDoctorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindAndCountManyPatientsByDoctorQuery,
    FindAndCountManyPatientsByDoctorQueryVariables
  >(FindAndCountManyPatientsByDoctorDocument, options);
}
export function useFindAndCountManyPatientsByDoctorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAndCountManyPatientsByDoctorQuery,
    FindAndCountManyPatientsByDoctorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindAndCountManyPatientsByDoctorQuery,
    FindAndCountManyPatientsByDoctorQueryVariables
  >(FindAndCountManyPatientsByDoctorDocument, options);
}
export type FindAndCountManyPatientsByDoctorQueryHookResult = ReturnType<
  typeof useFindAndCountManyPatientsByDoctorQuery
>;
export type FindAndCountManyPatientsByDoctorLazyQueryHookResult = ReturnType<
  typeof useFindAndCountManyPatientsByDoctorLazyQuery
>;
export type FindAndCountManyPatientsByDoctorQueryResult = Apollo.QueryResult<
  FindAndCountManyPatientsByDoctorQuery,
  FindAndCountManyPatientsByDoctorQueryVariables
>;
export const FindClinicByIdDocument = gql`
  query FindClinicById($schema: FindClinicByIdSchema!) {
    findClinicById(schema: $schema) {
      address
      city
      code
      companyId
      id
      name
      houseNumber
      index
      logoFileId
      licenseNumber
      geographicalCoordinates {
        latitude
        longitude
      }
      hiddenFromPatients
      phoneNumber
      description
      createdAt
      rooms {
        id
        clinicId
        name
      }
      workingDays {
        id
        clinicId
        day
        fromMinute
        toMinute
      }
    }
  }
`;

/**
 * __useFindClinicByIdQuery__
 *
 * To run a query within a React component, call `useFindClinicByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindClinicByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindClinicByIdQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindClinicByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindClinicByIdQuery,
    FindClinicByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindClinicByIdQuery, FindClinicByIdQueryVariables>(
    FindClinicByIdDocument,
    options
  );
}
export function useFindClinicByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindClinicByIdQuery,
    FindClinicByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindClinicByIdQuery, FindClinicByIdQueryVariables>(
    FindClinicByIdDocument,
    options
  );
}
export type FindClinicByIdQueryHookResult = ReturnType<
  typeof useFindClinicByIdQuery
>;
export type FindClinicByIdLazyQueryHookResult = ReturnType<
  typeof useFindClinicByIdLazyQuery
>;
export type FindClinicByIdQueryResult = Apollo.QueryResult<
  FindClinicByIdQuery,
  FindClinicByIdQueryVariables
>;
export const FindCompanyByUserIdDocument = gql`
  query FindCompanyByUserId {
    findCompanyByUserId {
      address
      city
      countryId
      createdAt
      dateOfRegistrationInKrs
      email
      id
      name
      nip
      ownerId
      postCode
      regon
      street
    }
  }
`;

/**
 * __useFindCompanyByUserIdQuery__
 *
 * To run a query within a React component, call `useFindCompanyByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCompanyByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCompanyByUserIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindCompanyByUserIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindCompanyByUserIdQuery,
    FindCompanyByUserIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindCompanyByUserIdQuery,
    FindCompanyByUserIdQueryVariables
  >(FindCompanyByUserIdDocument, options);
}
export function useFindCompanyByUserIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindCompanyByUserIdQuery,
    FindCompanyByUserIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindCompanyByUserIdQuery,
    FindCompanyByUserIdQueryVariables
  >(FindCompanyByUserIdDocument, options);
}
export type FindCompanyByUserIdQueryHookResult = ReturnType<
  typeof useFindCompanyByUserIdQuery
>;
export type FindCompanyByUserIdLazyQueryHookResult = ReturnType<
  typeof useFindCompanyByUserIdLazyQuery
>;
export type FindCompanyByUserIdQueryResult = Apollo.QueryResult<
  FindCompanyByUserIdQuery,
  FindCompanyByUserIdQueryVariables
>;
export const FindDoctorByIdDocument = gql`
  query FindDoctorById($schema: FindDoctorByIdSchema!) {
    findDoctorById(schema: $schema) {
      id
      email
      firstName
      lastName
      activated
      dateOfBirth
      avatarFileId
      roles
      createdAt
      doctorId
      doctorTypeId
      phoneNumber
      passportNumber
      medicalCertificateNumber
      description
      promoCode
      address
      doctorCreatedAt
    }
  }
`;

/**
 * __useFindDoctorByIdQuery__
 *
 * To run a query within a React component, call `useFindDoctorByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindDoctorByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindDoctorByIdQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindDoctorByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindDoctorByIdQuery,
    FindDoctorByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindDoctorByIdQuery, FindDoctorByIdQueryVariables>(
    FindDoctorByIdDocument,
    options
  );
}
export function useFindDoctorByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindDoctorByIdQuery,
    FindDoctorByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindDoctorByIdQuery, FindDoctorByIdQueryVariables>(
    FindDoctorByIdDocument,
    options
  );
}
export type FindDoctorByIdQueryHookResult = ReturnType<
  typeof useFindDoctorByIdQuery
>;
export type FindDoctorByIdLazyQueryHookResult = ReturnType<
  typeof useFindDoctorByIdLazyQuery
>;
export type FindDoctorByIdQueryResult = Apollo.QueryResult<
  FindDoctorByIdQuery,
  FindDoctorByIdQueryVariables
>;
export const FindManyAggregatedDoctorsByServiceIdDocument = gql`
  query FindManyAggregatedDoctorsByServiceId(
    $schema: FindManyAggregatedDoctorsByServiceIdSchema!
  ) {
    findManyAggregatedDoctorsByServiceId(schema: $schema) {
      doctors {
        id
        email
        firstName
        lastName
        activated
        dateOfBirth
        avatarFileId
        roles
        createdAt
        doctorId
        doctorTypeId
        phoneNumber
        passportNumber
        medicalCertificateNumber
        description
        promoCode
        address
        doctorCreatedAt
      }
    }
  }
`;

/**
 * __useFindManyAggregatedDoctorsByServiceIdQuery__
 *
 * To run a query within a React component, call `useFindManyAggregatedDoctorsByServiceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyAggregatedDoctorsByServiceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyAggregatedDoctorsByServiceIdQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindManyAggregatedDoctorsByServiceIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindManyAggregatedDoctorsByServiceIdQuery,
    FindManyAggregatedDoctorsByServiceIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindManyAggregatedDoctorsByServiceIdQuery,
    FindManyAggregatedDoctorsByServiceIdQueryVariables
  >(FindManyAggregatedDoctorsByServiceIdDocument, options);
}
export function useFindManyAggregatedDoctorsByServiceIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindManyAggregatedDoctorsByServiceIdQuery,
    FindManyAggregatedDoctorsByServiceIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindManyAggregatedDoctorsByServiceIdQuery,
    FindManyAggregatedDoctorsByServiceIdQueryVariables
  >(FindManyAggregatedDoctorsByServiceIdDocument, options);
}
export type FindManyAggregatedDoctorsByServiceIdQueryHookResult = ReturnType<
  typeof useFindManyAggregatedDoctorsByServiceIdQuery
>;
export type FindManyAggregatedDoctorsByServiceIdLazyQueryHookResult =
  ReturnType<typeof useFindManyAggregatedDoctorsByServiceIdLazyQuery>;
export type FindManyAggregatedDoctorsByServiceIdQueryResult =
  Apollo.QueryResult<
    FindManyAggregatedDoctorsByServiceIdQuery,
    FindManyAggregatedDoctorsByServiceIdQueryVariables
  >;
export const FindManyAvailableSlotsByDateRangeDocument = gql`
  query FindManyAvailableSlotsByDateRange(
    $schema: FindManyAvailableSlotsByDateRangeSchema!
  ) {
    findManyAvailableSlotsByDateRange(schema: $schema) {
      availableSlots {
        date
        slots {
          from
          to
        }
      }
    }
  }
`;

/**
 * __useFindManyAvailableSlotsByDateRangeQuery__
 *
 * To run a query within a React component, call `useFindManyAvailableSlotsByDateRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyAvailableSlotsByDateRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyAvailableSlotsByDateRangeQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindManyAvailableSlotsByDateRangeQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindManyAvailableSlotsByDateRangeQuery,
    FindManyAvailableSlotsByDateRangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindManyAvailableSlotsByDateRangeQuery,
    FindManyAvailableSlotsByDateRangeQueryVariables
  >(FindManyAvailableSlotsByDateRangeDocument, options);
}
export function useFindManyAvailableSlotsByDateRangeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindManyAvailableSlotsByDateRangeQuery,
    FindManyAvailableSlotsByDateRangeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindManyAvailableSlotsByDateRangeQuery,
    FindManyAvailableSlotsByDateRangeQueryVariables
  >(FindManyAvailableSlotsByDateRangeDocument, options);
}
export type FindManyAvailableSlotsByDateRangeQueryHookResult = ReturnType<
  typeof useFindManyAvailableSlotsByDateRangeQuery
>;
export type FindManyAvailableSlotsByDateRangeLazyQueryHookResult = ReturnType<
  typeof useFindManyAvailableSlotsByDateRangeLazyQuery
>;
export type FindManyAvailableSlotsByDateRangeQueryResult = Apollo.QueryResult<
  FindManyAvailableSlotsByDateRangeQuery,
  FindManyAvailableSlotsByDateRangeQueryVariables
>;
export const AvailableSlotsDocument = gql`
  query AvailableSlots($schema: FindManyAvailableSlotsSchema!) {
    findManyAvailableSlots(schema: $schema) {
      availableSlots {
        date
        slots {
          from
          to
        }
      }
    }
  }
`;

/**
 * __useAvailableSlotsQuery__
 *
 * To run a query within a React component, call `useAvailableSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableSlotsQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useAvailableSlotsQuery(
  baseOptions: Apollo.QueryHookOptions<
    AvailableSlotsQuery,
    AvailableSlotsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AvailableSlotsQuery, AvailableSlotsQueryVariables>(
    AvailableSlotsDocument,
    options
  );
}
export function useAvailableSlotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AvailableSlotsQuery,
    AvailableSlotsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AvailableSlotsQuery, AvailableSlotsQueryVariables>(
    AvailableSlotsDocument,
    options
  );
}
export type AvailableSlotsQueryHookResult = ReturnType<
  typeof useAvailableSlotsQuery
>;
export type AvailableSlotsLazyQueryHookResult = ReturnType<
  typeof useAvailableSlotsLazyQuery
>;
export type AvailableSlotsQueryResult = Apollo.QueryResult<
  AvailableSlotsQuery,
  AvailableSlotsQueryVariables
>;
export const FindManyClinicRoomsByClinicIdDocument = gql`
  query FindManyClinicRoomsByClinicId($schema: FindManyRoomsByClinicIdSchema!) {
    findManyClinicRoomsByClinicId(schema: $schema) {
      rooms {
        clinicId
        id
        name
      }
    }
  }
`;

/**
 * __useFindManyClinicRoomsByClinicIdQuery__
 *
 * To run a query within a React component, call `useFindManyClinicRoomsByClinicIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyClinicRoomsByClinicIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyClinicRoomsByClinicIdQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindManyClinicRoomsByClinicIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindManyClinicRoomsByClinicIdQuery,
    FindManyClinicRoomsByClinicIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindManyClinicRoomsByClinicIdQuery,
    FindManyClinicRoomsByClinicIdQueryVariables
  >(FindManyClinicRoomsByClinicIdDocument, options);
}
export function useFindManyClinicRoomsByClinicIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindManyClinicRoomsByClinicIdQuery,
    FindManyClinicRoomsByClinicIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindManyClinicRoomsByClinicIdQuery,
    FindManyClinicRoomsByClinicIdQueryVariables
  >(FindManyClinicRoomsByClinicIdDocument, options);
}
export type FindManyClinicRoomsByClinicIdQueryHookResult = ReturnType<
  typeof useFindManyClinicRoomsByClinicIdQuery
>;
export type FindManyClinicRoomsByClinicIdLazyQueryHookResult = ReturnType<
  typeof useFindManyClinicRoomsByClinicIdLazyQuery
>;
export type FindManyClinicRoomsByClinicIdQueryResult = Apollo.QueryResult<
  FindManyClinicRoomsByClinicIdQuery,
  FindManyClinicRoomsByClinicIdQueryVariables
>;
export const FindManyCurrenciesDocument = gql`
  query FindManyCurrencies {
    findManyCurrencies {
      currencies {
        code
      }
    }
  }
`;

/**
 * __useFindManyCurrenciesQuery__
 *
 * To run a query within a React component, call `useFindManyCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyCurrenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindManyCurrenciesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FindManyCurrenciesQuery,
    FindManyCurrenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindManyCurrenciesQuery,
    FindManyCurrenciesQueryVariables
  >(FindManyCurrenciesDocument, options);
}
export function useFindManyCurrenciesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindManyCurrenciesQuery,
    FindManyCurrenciesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindManyCurrenciesQuery,
    FindManyCurrenciesQueryVariables
  >(FindManyCurrenciesDocument, options);
}
export type FindManyCurrenciesQueryHookResult = ReturnType<
  typeof useFindManyCurrenciesQuery
>;
export type FindManyCurrenciesLazyQueryHookResult = ReturnType<
  typeof useFindManyCurrenciesLazyQuery
>;
export type FindManyCurrenciesQueryResult = Apollo.QueryResult<
  FindManyCurrenciesQuery,
  FindManyCurrenciesQueryVariables
>;
export const FindManyDoctorClinicsByDoctorIdDocument = gql`
  query FindManyDoctorClinicsByDoctorId(
    $schema: FindManyDoctorClinicsByDoctorIdSchema!
  ) {
    findManyDoctorClinicsByDoctorId(schema: $schema) {
      clinics {
        id
        companyId
        name
        address
        houseNumber
        index
        city
        logoFileId
        licenseNumber
        geographicalCoordinates {
          latitude
          longitude
        }
        code
        phoneNumber
        description
        hiddenFromPatients
        createdAt
        rooms {
          id
          clinicId
          name
        }
        workingDays {
          id
          clinicId
          day
          fromMinute
          toMinute
        }
      }
    }
  }
`;

/**
 * __useFindManyDoctorClinicsByDoctorIdQuery__
 *
 * To run a query within a React component, call `useFindManyDoctorClinicsByDoctorIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyDoctorClinicsByDoctorIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyDoctorClinicsByDoctorIdQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindManyDoctorClinicsByDoctorIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindManyDoctorClinicsByDoctorIdQuery,
    FindManyDoctorClinicsByDoctorIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindManyDoctorClinicsByDoctorIdQuery,
    FindManyDoctorClinicsByDoctorIdQueryVariables
  >(FindManyDoctorClinicsByDoctorIdDocument, options);
}
export function useFindManyDoctorClinicsByDoctorIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindManyDoctorClinicsByDoctorIdQuery,
    FindManyDoctorClinicsByDoctorIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindManyDoctorClinicsByDoctorIdQuery,
    FindManyDoctorClinicsByDoctorIdQueryVariables
  >(FindManyDoctorClinicsByDoctorIdDocument, options);
}
export type FindManyDoctorClinicsByDoctorIdQueryHookResult = ReturnType<
  typeof useFindManyDoctorClinicsByDoctorIdQuery
>;
export type FindManyDoctorClinicsByDoctorIdLazyQueryHookResult = ReturnType<
  typeof useFindManyDoctorClinicsByDoctorIdLazyQuery
>;
export type FindManyDoctorClinicsByDoctorIdQueryResult = Apollo.QueryResult<
  FindManyDoctorClinicsByDoctorIdQuery,
  FindManyDoctorClinicsByDoctorIdQueryVariables
>;
export const FindManyDoctorTypesByLanguageIsoCodeDocument = gql`
  query FindManyDoctorTypesByLanguageISOCode(
    $schema: FindManyDoctorTypesByLanguageISOCodeSchema!
  ) {
    findManyDoctorTypesByLanguageISOCode(schema: $schema) {
      doctorTypes {
        defaultLanguageName
        doctorTypeTranslations {
          doctorTypeId
          id
          languageISOCode
          name
        }
        id
      }
    }
  }
`;

/**
 * __useFindManyDoctorTypesByLanguageIsoCodeQuery__
 *
 * To run a query within a React component, call `useFindManyDoctorTypesByLanguageIsoCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyDoctorTypesByLanguageIsoCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyDoctorTypesByLanguageIsoCodeQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindManyDoctorTypesByLanguageIsoCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindManyDoctorTypesByLanguageIsoCodeQuery,
    FindManyDoctorTypesByLanguageIsoCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindManyDoctorTypesByLanguageIsoCodeQuery,
    FindManyDoctorTypesByLanguageIsoCodeQueryVariables
  >(FindManyDoctorTypesByLanguageIsoCodeDocument, options);
}
export function useFindManyDoctorTypesByLanguageIsoCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindManyDoctorTypesByLanguageIsoCodeQuery,
    FindManyDoctorTypesByLanguageIsoCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindManyDoctorTypesByLanguageIsoCodeQuery,
    FindManyDoctorTypesByLanguageIsoCodeQueryVariables
  >(FindManyDoctorTypesByLanguageIsoCodeDocument, options);
}
export type FindManyDoctorTypesByLanguageIsoCodeQueryHookResult = ReturnType<
  typeof useFindManyDoctorTypesByLanguageIsoCodeQuery
>;
export type FindManyDoctorTypesByLanguageIsoCodeLazyQueryHookResult =
  ReturnType<typeof useFindManyDoctorTypesByLanguageIsoCodeLazyQuery>;
export type FindManyDoctorTypesByLanguageIsoCodeQueryResult =
  Apollo.QueryResult<
    FindManyDoctorTypesByLanguageIsoCodeQuery,
    FindManyDoctorTypesByLanguageIsoCodeQueryVariables
  >;
export const FindManyFilesByIdsDocument = gql`
  query FindManyFilesByIds($schema: FindManyFilesByIdsSchema!) {
    findManyFilesByIds(schema: $schema) {
      files {
        createdAt
        extension
        id
        size
        mimetype
        originalName
        resourceKey
        url
        usageStatus
        userId
      }
    }
  }
`;

/**
 * __useFindManyFilesByIdsQuery__
 *
 * To run a query within a React component, call `useFindManyFilesByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyFilesByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyFilesByIdsQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindManyFilesByIdsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindManyFilesByIdsQuery,
    FindManyFilesByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindManyFilesByIdsQuery,
    FindManyFilesByIdsQueryVariables
  >(FindManyFilesByIdsDocument, options);
}
export function useFindManyFilesByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindManyFilesByIdsQuery,
    FindManyFilesByIdsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindManyFilesByIdsQuery,
    FindManyFilesByIdsQueryVariables
  >(FindManyFilesByIdsDocument, options);
}
export type FindManyFilesByIdsQueryHookResult = ReturnType<
  typeof useFindManyFilesByIdsQuery
>;
export type FindManyFilesByIdsLazyQueryHookResult = ReturnType<
  typeof useFindManyFilesByIdsLazyQuery
>;
export type FindManyFilesByIdsQueryResult = Apollo.QueryResult<
  FindManyFilesByIdsQuery,
  FindManyFilesByIdsQueryVariables
>;
export const FindManySchedulesByClientDocument = gql`
  query FindManySchedulesByClient($schema: FindManySchedulesByClientSchema!) {
    findManySchedulesByClient(schema: $schema) {
      schedules {
        id
        doctorId
        clinicId
        roomId
        eventType
        layoutType
        daysOfWeek
        dateFrom
        dateTo
        minuteFrom
        minuteTo
        createdAt
      }
    }
  }
`;

/**
 * __useFindManySchedulesByClientQuery__
 *
 * To run a query within a React component, call `useFindManySchedulesByClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManySchedulesByClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManySchedulesByClientQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindManySchedulesByClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindManySchedulesByClientQuery,
    FindManySchedulesByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindManySchedulesByClientQuery,
    FindManySchedulesByClientQueryVariables
  >(FindManySchedulesByClientDocument, options);
}
export function useFindManySchedulesByClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindManySchedulesByClientQuery,
    FindManySchedulesByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindManySchedulesByClientQuery,
    FindManySchedulesByClientQueryVariables
  >(FindManySchedulesByClientDocument, options);
}
export type FindManySchedulesByClientQueryHookResult = ReturnType<
  typeof useFindManySchedulesByClientQuery
>;
export type FindManySchedulesByClientLazyQueryHookResult = ReturnType<
  typeof useFindManySchedulesByClientLazyQuery
>;
export type FindManySchedulesByClientQueryResult = Apollo.QueryResult<
  FindManySchedulesByClientQuery,
  FindManySchedulesByClientQueryVariables
>;
export const FindManySchedulesByDoctorDocument = gql`
  query FindManySchedulesByDoctor($schema: FindManySchedulesByDoctorSchema!) {
    findManySchedulesByDoctor(schema: $schema) {
      schedules {
        id
        doctorId
        clinicId
        roomId
        eventType
        layoutType
        daysOfWeek
        dateFrom
        dateTo
        minuteFrom
        minuteTo
        createdAt
      }
    }
  }
`;

/**
 * __useFindManySchedulesByDoctorQuery__
 *
 * To run a query within a React component, call `useFindManySchedulesByDoctorQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManySchedulesByDoctorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManySchedulesByDoctorQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindManySchedulesByDoctorQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindManySchedulesByDoctorQuery,
    FindManySchedulesByDoctorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindManySchedulesByDoctorQuery,
    FindManySchedulesByDoctorQueryVariables
  >(FindManySchedulesByDoctorDocument, options);
}
export function useFindManySchedulesByDoctorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindManySchedulesByDoctorQuery,
    FindManySchedulesByDoctorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindManySchedulesByDoctorQuery,
    FindManySchedulesByDoctorQueryVariables
  >(FindManySchedulesByDoctorDocument, options);
}
export type FindManySchedulesByDoctorQueryHookResult = ReturnType<
  typeof useFindManySchedulesByDoctorQuery
>;
export type FindManySchedulesByDoctorLazyQueryHookResult = ReturnType<
  typeof useFindManySchedulesByDoctorLazyQuery
>;
export type FindManySchedulesByDoctorQueryResult = Apollo.QueryResult<
  FindManySchedulesByDoctorQuery,
  FindManySchedulesByDoctorQueryVariables
>;
export const FindManyServicesDocument = gql`
  query FindManyServices($schema: FindManyServicesSchema!) {
    findManyServices(schema: $schema) {
      services {
        id
        name
        category {
          id
          name
        }
        description
        icd10Code
        icd9Code
        durationInMinutes
        price
        currencyCode
        hiddenFromPatients
        createdAt
        clinicRooms {
          id
          clinicId
          name
        }
        doctorIds
      }
    }
  }
`;

/**
 * __useFindManyServicesQuery__
 *
 * To run a query within a React component, call `useFindManyServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyServicesQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindManyServicesQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindManyServicesQuery,
    FindManyServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindManyServicesQuery, FindManyServicesQueryVariables>(
    FindManyServicesDocument,
    options
  );
}
export function useFindManyServicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindManyServicesQuery,
    FindManyServicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindManyServicesQuery,
    FindManyServicesQueryVariables
  >(FindManyServicesDocument, options);
}
export type FindManyServicesQueryHookResult = ReturnType<
  typeof useFindManyServicesQuery
>;
export type FindManyServicesLazyQueryHookResult = ReturnType<
  typeof useFindManyServicesLazyQuery
>;
export type FindManyServicesQueryResult = Apollo.QueryResult<
  FindManyServicesQuery,
  FindManyServicesQueryVariables
>;
export const FindServiceByIdDocument = gql`
  query FindServiceById($schema: FindServiceByIdSchema!) {
    findServiceById(schema: $schema) {
      id
      name
      category {
        id
        name
      }
      description
      icd10Code
      icd9Code
      durationInMinutes
      price
      currencyCode
      hiddenFromPatients
      createdAt
      clinicRooms {
        id
        clinicId
        name
      }
      doctorIds
    }
  }
`;

/**
 * __useFindServiceByIdQuery__
 *
 * To run a query within a React component, call `useFindServiceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindServiceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindServiceByIdQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindServiceByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindServiceByIdQuery,
    FindServiceByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindServiceByIdQuery, FindServiceByIdQueryVariables>(
    FindServiceByIdDocument,
    options
  );
}
export function useFindServiceByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindServiceByIdQuery,
    FindServiceByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindServiceByIdQuery,
    FindServiceByIdQueryVariables
  >(FindServiceByIdDocument, options);
}
export type FindServiceByIdQueryHookResult = ReturnType<
  typeof useFindServiceByIdQuery
>;
export type FindServiceByIdLazyQueryHookResult = ReturnType<
  typeof useFindServiceByIdLazyQuery
>;
export type FindServiceByIdQueryResult = Apollo.QueryResult<
  FindServiceByIdQuery,
  FindServiceByIdQueryVariables
>;
export const FindServiceCategoryByIdDocument = gql`
  query FindServiceCategoryById($schema: FindServiceCategoryByIdSchema!) {
    findServiceCategoryById(schema: $schema) {
      id
      name
    }
  }
`;

/**
 * __useFindServiceCategoryByIdQuery__
 *
 * To run a query within a React component, call `useFindServiceCategoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindServiceCategoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindServiceCategoryByIdQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindServiceCategoryByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindServiceCategoryByIdQuery,
    FindServiceCategoryByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindServiceCategoryByIdQuery,
    FindServiceCategoryByIdQueryVariables
  >(FindServiceCategoryByIdDocument, options);
}
export function useFindServiceCategoryByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindServiceCategoryByIdQuery,
    FindServiceCategoryByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindServiceCategoryByIdQuery,
    FindServiceCategoryByIdQueryVariables
  >(FindServiceCategoryByIdDocument, options);
}
export type FindServiceCategoryByIdQueryHookResult = ReturnType<
  typeof useFindServiceCategoryByIdQuery
>;
export type FindServiceCategoryByIdLazyQueryHookResult = ReturnType<
  typeof useFindServiceCategoryByIdLazyQuery
>;
export type FindServiceCategoryByIdQueryResult = Apollo.QueryResult<
  FindServiceCategoryByIdQuery,
  FindServiceCategoryByIdQueryVariables
>;
export const ScheduleAppointmentConfirmedByPatientDocument = gql`
  query ScheduleAppointmentConfirmedByPatient(
    $schema: ScheduleAppointmentConfirmedByPatientSchema!
  ) {
    scheduleAppointmentConfirmedByPatient(schema: $schema) {
      confirmed
    }
  }
`;

/**
 * __useScheduleAppointmentConfirmedByPatientQuery__
 *
 * To run a query within a React component, call `useScheduleAppointmentConfirmedByPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleAppointmentConfirmedByPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleAppointmentConfirmedByPatientQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useScheduleAppointmentConfirmedByPatientQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScheduleAppointmentConfirmedByPatientQuery,
    ScheduleAppointmentConfirmedByPatientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ScheduleAppointmentConfirmedByPatientQuery,
    ScheduleAppointmentConfirmedByPatientQueryVariables
  >(ScheduleAppointmentConfirmedByPatientDocument, options);
}
export function useScheduleAppointmentConfirmedByPatientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScheduleAppointmentConfirmedByPatientQuery,
    ScheduleAppointmentConfirmedByPatientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ScheduleAppointmentConfirmedByPatientQuery,
    ScheduleAppointmentConfirmedByPatientQueryVariables
  >(ScheduleAppointmentConfirmedByPatientDocument, options);
}
export type ScheduleAppointmentConfirmedByPatientQueryHookResult = ReturnType<
  typeof useScheduleAppointmentConfirmedByPatientQuery
>;
export type ScheduleAppointmentConfirmedByPatientLazyQueryHookResult =
  ReturnType<typeof useScheduleAppointmentConfirmedByPatientLazyQuery>;
export type ScheduleAppointmentConfirmedByPatientQueryResult =
  Apollo.QueryResult<
    ScheduleAppointmentConfirmedByPatientQuery,
    ScheduleAppointmentConfirmedByPatientQueryVariables
  >;
export const ScheduleWaitlistConfirmedByPatientDocument = gql`
  query ScheduleWaitlistConfirmedByPatient(
    $schema: ScheduleAppointmentConfirmedByPatientSchema!
  ) {
    scheduleWaitlistConfirmedByPatient(schema: $schema) {
      confirmed
    }
  }
`;

/**
 * __useScheduleWaitlistConfirmedByPatientQuery__
 *
 * To run a query within a React component, call `useScheduleWaitlistConfirmedByPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleWaitlistConfirmedByPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleWaitlistConfirmedByPatientQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useScheduleWaitlistConfirmedByPatientQuery(
  baseOptions: Apollo.QueryHookOptions<
    ScheduleWaitlistConfirmedByPatientQuery,
    ScheduleWaitlistConfirmedByPatientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ScheduleWaitlistConfirmedByPatientQuery,
    ScheduleWaitlistConfirmedByPatientQueryVariables
  >(ScheduleWaitlistConfirmedByPatientDocument, options);
}
export function useScheduleWaitlistConfirmedByPatientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ScheduleWaitlistConfirmedByPatientQuery,
    ScheduleWaitlistConfirmedByPatientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ScheduleWaitlistConfirmedByPatientQuery,
    ScheduleWaitlistConfirmedByPatientQueryVariables
  >(ScheduleWaitlistConfirmedByPatientDocument, options);
}
export type ScheduleWaitlistConfirmedByPatientQueryHookResult = ReturnType<
  typeof useScheduleWaitlistConfirmedByPatientQuery
>;
export type ScheduleWaitlistConfirmedByPatientLazyQueryHookResult = ReturnType<
  typeof useScheduleWaitlistConfirmedByPatientLazyQuery
>;
export type ScheduleWaitlistConfirmedByPatientQueryResult = Apollo.QueryResult<
  ScheduleWaitlistConfirmedByPatientQuery,
  ScheduleWaitlistConfirmedByPatientQueryVariables
>;
export const SearchByClientDocument = gql`
  query SearchByClient($schema: SearchByClientSchema!) {
    searchByClient(schema: $schema) {
      count
      entities {
        ... on PatientEntityResponse {
          id
          phoneNumber
          firstName
          lastName
          email
        }
        ... on AggregatedScheduleAppointmentEntityWithRelationsByClientResponse {
          id
          scheduleId
          patientId
          status
          createdAt
          updatedAt
          schedule {
            id
            doctorId
            clinicId
            roomId
            serviceId
            eventType
            layoutType
            daysOfWeek
            dateFrom
            dateTo
            minuteFrom
            minuteTo
            createdAt
          }
          patient {
            id
            phoneNumber
            firstName
            lastName
            email
            dateOfBirth
            gender
            companyId
            comment
            emailDistribution
            smsDistribution
            activated
            passportNumber
            status
            createdAt
            languageISOCode
          }
          doctor {
            id
            email
            firstName
            lastName
            activated
            dateOfBirth
            avatarFileId
            roles
            createdAt
            doctorId
            doctorTypeId
            phoneNumber
            passportNumber
            medicalCertificateNumber
            description
            promoCode
            address
            doctorCreatedAt
          }
          service {
            id
            name
            category {
              id
              name
            }
            description
            icd10Code
            icd9Code
            durationInMinutes
            price
            currencyCode
            hiddenFromPatients
            createdAt
          }
          clinic {
            id
            name
          }
          room {
            id
            clinicId
            name
          }
        }
      }
    }
  }
`;

/**
 * __useSearchByClientQuery__
 *
 * To run a query within a React component, call `useSearchByClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchByClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchByClientQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useSearchByClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchByClientQuery,
    SearchByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchByClientQuery, SearchByClientQueryVariables>(
    SearchByClientDocument,
    options
  );
}
export function useSearchByClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchByClientQuery,
    SearchByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchByClientQuery, SearchByClientQueryVariables>(
    SearchByClientDocument,
    options
  );
}
export type SearchByClientQueryHookResult = ReturnType<
  typeof useSearchByClientQuery
>;
export type SearchByClientLazyQueryHookResult = ReturnType<
  typeof useSearchByClientLazyQuery
>;
export type SearchByClientQueryResult = Apollo.QueryResult<
  SearchByClientQuery,
  SearchByClientQueryVariables
>;
export const SearchByDoctorDocument = gql`
  query SearchByDoctor($schema: SearchByDoctorSchema!) {
    searchByDoctor(schema: $schema) {
      count
      entities {
        ... on PatientEntityResponse {
          id
          phoneNumber
          firstName
          lastName
          email
        }
        ... on AggregatedScheduleAppointmentEntityWithRelationsByDoctorResponse {
          id
          scheduleId
          patientId
          status
          createdAt
          updatedAt
          schedule {
            id
            doctorId
            clinicId
            roomId
            serviceId
            eventType
            layoutType
            daysOfWeek
            dateFrom
            dateTo
            minuteFrom
            minuteTo
            createdAt
          }
          patient {
            id
            phoneNumber
            firstName
            lastName
            email
            dateOfBirth
            gender
            companyId
            comment
            emailDistribution
            smsDistribution
            activated
            passportNumber
            status
            createdAt
            languageISOCode
          }
          service {
            id
            name
            category {
              id
              name
            }
            description
            icd10Code
            icd9Code
            durationInMinutes
            price
            currencyCode
            hiddenFromPatients
            createdAt
          }
          room {
            id
            clinicId
            name
          }
        }
      }
    }
  }
`;

/**
 * __useSearchByDoctorQuery__
 *
 * To run a query within a React component, call `useSearchByDoctorQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchByDoctorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchByDoctorQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useSearchByDoctorQuery(
  baseOptions: Apollo.QueryHookOptions<
    SearchByDoctorQuery,
    SearchByDoctorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchByDoctorQuery, SearchByDoctorQueryVariables>(
    SearchByDoctorDocument,
    options
  );
}
export function useSearchByDoctorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchByDoctorQuery,
    SearchByDoctorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchByDoctorQuery, SearchByDoctorQueryVariables>(
    SearchByDoctorDocument,
    options
  );
}
export type SearchByDoctorQueryHookResult = ReturnType<
  typeof useSearchByDoctorQuery
>;
export type SearchByDoctorLazyQueryHookResult = ReturnType<
  typeof useSearchByDoctorLazyQuery
>;
export type SearchByDoctorQueryResult = Apollo.QueryResult<
  SearchByDoctorQuery,
  SearchByDoctorQueryVariables
>;
export const FindAndCountManyTemplatesByClientDocument = gql`
  query FindAndCountManyTemplatesByClient(
    $schema: FindAndCountManyTemplatesByClientSchema!
  ) {
    findAndCountManyTemplatesByClient(schema: $schema) {
      templates {
        id
        name
        smsSent
        mailSent
        clinicId
        actions
        mailTemplates {
          id
          subject
          text
          templateId
          delayInHours
          languageISOCode
          createdAt
        }
        smsTemplates {
          id
          text
          templateId
          languageISOCode
          delayInHours
          createdAt
        }
        clinicsTemplates {
          clinicId
          templateId
          event
        }
        event
        semanticLock
        updatedAt
        createdAt
      }
      count
    }
  }
`;

/**
 * __useFindAndCountManyTemplatesByClientQuery__
 *
 * To run a query within a React component, call `useFindAndCountManyTemplatesByClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAndCountManyTemplatesByClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAndCountManyTemplatesByClientQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindAndCountManyTemplatesByClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAndCountManyTemplatesByClientQuery,
    FindAndCountManyTemplatesByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindAndCountManyTemplatesByClientQuery,
    FindAndCountManyTemplatesByClientQueryVariables
  >(FindAndCountManyTemplatesByClientDocument, options);
}
export function useFindAndCountManyTemplatesByClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAndCountManyTemplatesByClientQuery,
    FindAndCountManyTemplatesByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindAndCountManyTemplatesByClientQuery,
    FindAndCountManyTemplatesByClientQueryVariables
  >(FindAndCountManyTemplatesByClientDocument, options);
}
export type FindAndCountManyTemplatesByClientQueryHookResult = ReturnType<
  typeof useFindAndCountManyTemplatesByClientQuery
>;
export type FindAndCountManyTemplatesByClientLazyQueryHookResult = ReturnType<
  typeof useFindAndCountManyTemplatesByClientLazyQuery
>;
export type FindAndCountManyTemplatesByClientQueryResult = Apollo.QueryResult<
  FindAndCountManyTemplatesByClientQuery,
  FindAndCountManyTemplatesByClientQueryVariables
>;
export const FindAndCountManyTemplatesByClientGetCountDocument = gql`
  query FindAndCountManyTemplatesByClientGetCount(
    $schema: FindAndCountManyTemplatesByClientSchema!
  ) {
    findAndCountManyTemplatesByClient(schema: $schema) {
      count
    }
  }
`;

/**
 * __useFindAndCountManyTemplatesByClientGetCountQuery__
 *
 * To run a query within a React component, call `useFindAndCountManyTemplatesByClientGetCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAndCountManyTemplatesByClientGetCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAndCountManyTemplatesByClientGetCountQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindAndCountManyTemplatesByClientGetCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAndCountManyTemplatesByClientGetCountQuery,
    FindAndCountManyTemplatesByClientGetCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindAndCountManyTemplatesByClientGetCountQuery,
    FindAndCountManyTemplatesByClientGetCountQueryVariables
  >(FindAndCountManyTemplatesByClientGetCountDocument, options);
}
export function useFindAndCountManyTemplatesByClientGetCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAndCountManyTemplatesByClientGetCountQuery,
    FindAndCountManyTemplatesByClientGetCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindAndCountManyTemplatesByClientGetCountQuery,
    FindAndCountManyTemplatesByClientGetCountQueryVariables
  >(FindAndCountManyTemplatesByClientGetCountDocument, options);
}
export type FindAndCountManyTemplatesByClientGetCountQueryHookResult =
  ReturnType<typeof useFindAndCountManyTemplatesByClientGetCountQuery>;
export type FindAndCountManyTemplatesByClientGetCountLazyQueryHookResult =
  ReturnType<typeof useFindAndCountManyTemplatesByClientGetCountLazyQuery>;
export type FindAndCountManyTemplatesByClientGetCountQueryResult =
  Apollo.QueryResult<
    FindAndCountManyTemplatesByClientGetCountQuery,
    FindAndCountManyTemplatesByClientGetCountQueryVariables
  >;
export const FindTemplateByClientDocument = gql`
  query FindTemplateByClient($schema: FindTemplateByClientSchema!) {
    findTemplateByClient(schema: $schema) {
      id
      name
      smsSent
      mailSent
      clinicId
      actions
      mailTemplates {
        id
        subject
        text
        templateId
        delayInHours
        languageISOCode
        createdAt
      }
      smsTemplates {
        id
        text
        templateId
        languageISOCode
        delayInHours
        createdAt
      }
      clinicsTemplates {
        clinicId
        templateId
        event
      }
      event
      semanticLock
      updatedAt
      createdAt
    }
  }
`;

/**
 * __useFindTemplateByClientQuery__
 *
 * To run a query within a React component, call `useFindTemplateByClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindTemplateByClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindTemplateByClientQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindTemplateByClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindTemplateByClientQuery,
    FindTemplateByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindTemplateByClientQuery,
    FindTemplateByClientQueryVariables
  >(FindTemplateByClientDocument, options);
}
export function useFindTemplateByClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindTemplateByClientQuery,
    FindTemplateByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindTemplateByClientQuery,
    FindTemplateByClientQueryVariables
  >(FindTemplateByClientDocument, options);
}
export type FindTemplateByClientQueryHookResult = ReturnType<
  typeof useFindTemplateByClientQuery
>;
export type FindTemplateByClientLazyQueryHookResult = ReturnType<
  typeof useFindTemplateByClientLazyQuery
>;
export type FindTemplateByClientQueryResult = Apollo.QueryResult<
  FindTemplateByClientQuery,
  FindTemplateByClientQueryVariables
>;
export const FindManyTemplateVariablesByClientDocument = gql`
  query FindManyTemplateVariablesByClient(
    $schema: FindManyTemplateVariablesByClientSchema!
  ) {
    findManyTemplateVariablesByClient(schema: $schema) {
      variables
    }
  }
`;

/**
 * __useFindManyTemplateVariablesByClientQuery__
 *
 * To run a query within a React component, call `useFindManyTemplateVariablesByClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManyTemplateVariablesByClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManyTemplateVariablesByClientQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindManyTemplateVariablesByClientQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindManyTemplateVariablesByClientQuery,
    FindManyTemplateVariablesByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindManyTemplateVariablesByClientQuery,
    FindManyTemplateVariablesByClientQueryVariables
  >(FindManyTemplateVariablesByClientDocument, options);
}
export function useFindManyTemplateVariablesByClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindManyTemplateVariablesByClientQuery,
    FindManyTemplateVariablesByClientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindManyTemplateVariablesByClientQuery,
    FindManyTemplateVariablesByClientQueryVariables
  >(FindManyTemplateVariablesByClientDocument, options);
}
export type FindManyTemplateVariablesByClientQueryHookResult = ReturnType<
  typeof useFindManyTemplateVariablesByClientQuery
>;
export type FindManyTemplateVariablesByClientLazyQueryHookResult = ReturnType<
  typeof useFindManyTemplateVariablesByClientLazyQuery
>;
export type FindManyTemplateVariablesByClientQueryResult = Apollo.QueryResult<
  FindManyTemplateVariablesByClientQuery,
  FindManyTemplateVariablesByClientQueryVariables
>;
export const FindDefaultMailTemplateDocument = gql`
  query FindDefaultMailTemplate($schema: FindDefaultMailTemplateSchema!) {
    findDefaultMailTemplate(schema: $schema) {
      subject
      text
    }
  }
`;

/**
 * __useFindDefaultMailTemplateQuery__
 *
 * To run a query within a React component, call `useFindDefaultMailTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindDefaultMailTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindDefaultMailTemplateQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindDefaultMailTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindDefaultMailTemplateQuery,
    FindDefaultMailTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindDefaultMailTemplateQuery,
    FindDefaultMailTemplateQueryVariables
  >(FindDefaultMailTemplateDocument, options);
}
export function useFindDefaultMailTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindDefaultMailTemplateQuery,
    FindDefaultMailTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindDefaultMailTemplateQuery,
    FindDefaultMailTemplateQueryVariables
  >(FindDefaultMailTemplateDocument, options);
}
export type FindDefaultMailTemplateQueryHookResult = ReturnType<
  typeof useFindDefaultMailTemplateQuery
>;
export type FindDefaultMailTemplateLazyQueryHookResult = ReturnType<
  typeof useFindDefaultMailTemplateLazyQuery
>;
export type FindDefaultMailTemplateQueryResult = Apollo.QueryResult<
  FindDefaultMailTemplateQuery,
  FindDefaultMailTemplateQueryVariables
>;
export const FindDefaultSmsTemplateDocument = gql`
  query FindDefaultSmsTemplate($schema: FindDefaultSmsTemplateSchema!) {
    findDefaultSmsTemplate(schema: $schema) {
      text
    }
  }
`;

/**
 * __useFindDefaultSmsTemplateQuery__
 *
 * To run a query within a React component, call `useFindDefaultSmsTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindDefaultSmsTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindDefaultSmsTemplateQuery({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useFindDefaultSmsTemplateQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindDefaultSmsTemplateQuery,
    FindDefaultSmsTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FindDefaultSmsTemplateQuery,
    FindDefaultSmsTemplateQueryVariables
  >(FindDefaultSmsTemplateDocument, options);
}
export function useFindDefaultSmsTemplateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindDefaultSmsTemplateQuery,
    FindDefaultSmsTemplateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FindDefaultSmsTemplateQuery,
    FindDefaultSmsTemplateQueryVariables
  >(FindDefaultSmsTemplateDocument, options);
}
export type FindDefaultSmsTemplateQueryHookResult = ReturnType<
  typeof useFindDefaultSmsTemplateQuery
>;
export type FindDefaultSmsTemplateLazyQueryHookResult = ReturnType<
  typeof useFindDefaultSmsTemplateLazyQuery
>;
export type FindDefaultSmsTemplateQueryResult = Apollo.QueryResult<
  FindDefaultSmsTemplateQuery,
  FindDefaultSmsTemplateQueryVariables
>;
export const UpdateTemplateActionsByClientDocument = gql`
  mutation UpdateTemplateActionsByClient(
    $schema: UpdateTemplateActionsByClientSchema!
  ) {
    updateTemplateActionsByClient(schema: $schema) {
      updated
    }
  }
`;
export type UpdateTemplateActionsByClientMutationFn = Apollo.MutationFunction<
  UpdateTemplateActionsByClientMutation,
  UpdateTemplateActionsByClientMutationVariables
>;

/**
 * __useUpdateTemplateActionsByClientMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateActionsByClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateActionsByClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateActionsByClientMutation, { data, loading, error }] = useUpdateTemplateActionsByClientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useUpdateTemplateActionsByClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTemplateActionsByClientMutation,
    UpdateTemplateActionsByClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTemplateActionsByClientMutation,
    UpdateTemplateActionsByClientMutationVariables
  >(UpdateTemplateActionsByClientDocument, options);
}
export type UpdateTemplateActionsByClientMutationHookResult = ReturnType<
  typeof useUpdateTemplateActionsByClientMutation
>;
export type UpdateTemplateActionsByClientMutationResult =
  Apollo.MutationResult<UpdateTemplateActionsByClientMutation>;
export type UpdateTemplateActionsByClientMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateTemplateActionsByClientMutation,
    UpdateTemplateActionsByClientMutationVariables
  >;
export const CreateTemplateDocument = gql`
  mutation CreateTemplate($schema: CreateTemplateSchema!) {
    createTemplate(schema: $schema) {
      id
      name
      smsSent
      mailSent
      clinicId
      actions
      mailTemplates {
        id
        subject
        text
        templateId
        delayInHours
        languageISOCode
        createdAt
      }
      smsTemplates {
        id
        text
        templateId
        languageISOCode
        delayInHours
        createdAt
      }
      clinicsTemplates {
        clinicId
        templateId
        event
      }
      event
      semanticLock
      updatedAt
      createdAt
    }
  }
`;
export type CreateTemplateMutationFn = Apollo.MutationFunction<
  CreateTemplateMutation,
  CreateTemplateMutationVariables
>;

/**
 * __useCreateTemplateMutation__
 *
 * To run a mutation, you first call `useCreateTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplateMutation, { data, loading, error }] = useCreateTemplateMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useCreateTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTemplateMutation,
    CreateTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTemplateMutation,
    CreateTemplateMutationVariables
  >(CreateTemplateDocument, options);
}
export type CreateTemplateMutationHookResult = ReturnType<
  typeof useCreateTemplateMutation
>;
export type CreateTemplateMutationResult =
  Apollo.MutationResult<CreateTemplateMutation>;
export type CreateTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateTemplateMutation,
  CreateTemplateMutationVariables
>;
export const UpdateTemplateByClientDocument = gql`
  mutation UpdateTemplateByClient($schema: UpdateTemplateByClientSchema!) {
    updateTemplateByClient(schema: $schema) {
      updated
    }
  }
`;
export type UpdateTemplateByClientMutationFn = Apollo.MutationFunction<
  UpdateTemplateByClientMutation,
  UpdateTemplateByClientMutationVariables
>;

/**
 * __useUpdateTemplateByClientMutation__
 *
 * To run a mutation, you first call `useUpdateTemplateByClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTemplateByClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTemplateByClientMutation, { data, loading, error }] = useUpdateTemplateByClientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useUpdateTemplateByClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTemplateByClientMutation,
    UpdateTemplateByClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTemplateByClientMutation,
    UpdateTemplateByClientMutationVariables
  >(UpdateTemplateByClientDocument, options);
}
export type UpdateTemplateByClientMutationHookResult = ReturnType<
  typeof useUpdateTemplateByClientMutation
>;
export type UpdateTemplateByClientMutationResult =
  Apollo.MutationResult<UpdateTemplateByClientMutation>;
export type UpdateTemplateByClientMutationOptions = Apollo.BaseMutationOptions<
  UpdateTemplateByClientMutation,
  UpdateTemplateByClientMutationVariables
>;
export const UpdateSmsTemplateByClientDocument = gql`
  mutation UpdateSmsTemplateByClient(
    $schema: UpdateSmsTemplateByClientSchema!
  ) {
    updateSmsTemplateByClient(schema: $schema) {
      updated
    }
  }
`;
export type UpdateSmsTemplateByClientMutationFn = Apollo.MutationFunction<
  UpdateSmsTemplateByClientMutation,
  UpdateSmsTemplateByClientMutationVariables
>;

/**
 * __useUpdateSmsTemplateByClientMutation__
 *
 * To run a mutation, you first call `useUpdateSmsTemplateByClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSmsTemplateByClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSmsTemplateByClientMutation, { data, loading, error }] = useUpdateSmsTemplateByClientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useUpdateSmsTemplateByClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSmsTemplateByClientMutation,
    UpdateSmsTemplateByClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSmsTemplateByClientMutation,
    UpdateSmsTemplateByClientMutationVariables
  >(UpdateSmsTemplateByClientDocument, options);
}
export type UpdateSmsTemplateByClientMutationHookResult = ReturnType<
  typeof useUpdateSmsTemplateByClientMutation
>;
export type UpdateSmsTemplateByClientMutationResult =
  Apollo.MutationResult<UpdateSmsTemplateByClientMutation>;
export type UpdateSmsTemplateByClientMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateSmsTemplateByClientMutation,
    UpdateSmsTemplateByClientMutationVariables
  >;
export const UpdateMailTemplateByClientDocument = gql`
  mutation UpdateMailTemplateByClient(
    $schema: UpdateMailTemplateByClientSchema!
  ) {
    updateMailTemplateByClient(schema: $schema) {
      updated
    }
  }
`;
export type UpdateMailTemplateByClientMutationFn = Apollo.MutationFunction<
  UpdateMailTemplateByClientMutation,
  UpdateMailTemplateByClientMutationVariables
>;

/**
 * __useUpdateMailTemplateByClientMutation__
 *
 * To run a mutation, you first call `useUpdateMailTemplateByClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMailTemplateByClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMailTemplateByClientMutation, { data, loading, error }] = useUpdateMailTemplateByClientMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useUpdateMailTemplateByClientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMailTemplateByClientMutation,
    UpdateMailTemplateByClientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateMailTemplateByClientMutation,
    UpdateMailTemplateByClientMutationVariables
  >(UpdateMailTemplateByClientDocument, options);
}
export type UpdateMailTemplateByClientMutationHookResult = ReturnType<
  typeof useUpdateMailTemplateByClientMutation
>;
export type UpdateMailTemplateByClientMutationResult =
  Apollo.MutationResult<UpdateMailTemplateByClientMutation>;
export type UpdateMailTemplateByClientMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateMailTemplateByClientMutation,
    UpdateMailTemplateByClientMutationVariables
  >;
export const UpdateCurrentClinicTemplateDocument = gql`
  mutation UpdateCurrentClinicTemplate(
    $schema: UpdateCurrentClinicTemplateSchema!
  ) {
    updateCurrentClinicTemplate(schema: $schema) {
      updated
    }
  }
`;
export type UpdateCurrentClinicTemplateMutationFn = Apollo.MutationFunction<
  UpdateCurrentClinicTemplateMutation,
  UpdateCurrentClinicTemplateMutationVariables
>;

/**
 * __useUpdateCurrentClinicTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentClinicTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentClinicTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentClinicTemplateMutation, { data, loading, error }] = useUpdateCurrentClinicTemplateMutation({
 *   variables: {
 *      schema: // value for 'schema'
 *   },
 * });
 */
export function useUpdateCurrentClinicTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCurrentClinicTemplateMutation,
    UpdateCurrentClinicTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCurrentClinicTemplateMutation,
    UpdateCurrentClinicTemplateMutationVariables
  >(UpdateCurrentClinicTemplateDocument, options);
}
export type UpdateCurrentClinicTemplateMutationHookResult = ReturnType<
  typeof useUpdateCurrentClinicTemplateMutation
>;
export type UpdateCurrentClinicTemplateMutationResult =
  Apollo.MutationResult<UpdateCurrentClinicTemplateMutation>;
export type UpdateCurrentClinicTemplateMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCurrentClinicTemplateMutation,
    UpdateCurrentClinicTemplateMutationVariables
  >;
