import styled from "styled-components";
import { Button } from "src/UI/Button";

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  flex-direction: column;
`;

export const LoadingWrap = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
`;

export const ButtonStyled = styled(Button)`
  width: auto;
  padding: 8px 10px;
  font-size: 16px;
  line-height: 100%;
  border-radius: 5px;

  svg path {
    stroke: ${({ theme }) => theme.colors.white};
  }
`;

export const List = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  padding: 30px 20px 20px;
  flex-direction: column;
  gap: 30px;
`;

export const EmptyTextStyled = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.gray};
`;

export const Tabs = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 16px;
  }
`;

export const Tab = styled.div<{ isActive: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 4px;
  font-size: 14px;
  line-height: 100%;
  color: ${({ theme, isActive }) => theme.colors[isActive ? "green" : "black"]};
  font-weight: ${({ isActive }) => (isActive ? "700" : "500")};
  cursor: pointer;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    top: calc(100% + 20px);
    border-bottom: 2px solid
      ${({ theme, isActive }) =>
        isActive ? theme.colors.green : "transparent"};
  }
  span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2px 4.5px;
    background: ${({ theme, isActive }) =>
      theme.colors[isActive ? "green" : "grayC4"]};
    border-radius: 800px;
    color: ${({ theme }) => theme.colors.white};
  }
`;
