import styled from "styled-components";

export const MapStyled = styled.div<{ isClinicsPage: boolean }>`
  position: fixed;
  width: calc(100% - 734px)}
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0px;
  z-index: 0;
`;
