import { useTranslation } from "react-i18next";

import { useFindManyFilesByIds } from "src/graphql/hooks/useQueries";
import {
  AggregatedDoctorEntityResponse,
  DoctorTypeEntityResponse,
  FileEntityResponse,
  FindManyDoctorTypesByLanguageIsoCodeQuery,
} from "src/graphql/generated";
import { DeleteIcon, DoctorIcon, EditIcon } from "src/UI/Svg";
import { useAppAction } from "src/store/app/hooks";
import { useDeleteDoctorById } from "src/graphql/hooks/useMutations";
import { toast } from "react-toastify";
import { FIND_AND_COUNT_MANY_DOCTORS_BY_CLINIC_ID } from "src/graphql/queries";
import { useAuthContext } from "src/context/AuthContext";
import { IUserStorageItem } from "src/store/app/types";
import { ScreenLoader } from "src/UI/ScreenLoader";
import { useNavigate } from "react-router-dom";
import { PATHS } from "src/constants/constants";

import {
  Actions,
  ButtonIcon,
  EmptyTextStyled,
  IconWrapper,
  ImageStyled,
  StatusWrapper,
  Table,
  Wrapper,
} from "./styled";

function TableListComponent({
  doctorTypesData,
  doctors,
  loading,
  doctorTypesLoading,
}: {
  doctorTypesData: FindManyDoctorTypesByLanguageIsoCodeQuery | undefined;
  doctors: AggregatedDoctorEntityResponse[];
  loading: boolean;
  doctorTypesLoading: boolean;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { localUsers, onSetLocalusers } = useAuthContext();

  const { onConfirmOpen } = useAppAction();

  const doctotsLogoIds = doctors
    ?.map((doctor) => doctor?.avatarFileId)
    ?.filter((id) => id);

  const { data: logoFiels } = useFindManyFilesByIds({
    variables: {
      schema: {
        ids: doctotsLogoIds,
      },
    },
    skip: !doctotsLogoIds?.length,
  });

  const [deleteDoctorById] = useDeleteDoctorById();

  const getSpecialisation = (specialisationTypeId: string) => {
    const specialisation: DoctorTypeEntityResponse | undefined =
      doctorTypesData?.findManyDoctorTypesByLanguageISOCode?.doctorTypes.find(
        (type) => type.id === specialisationTypeId
      );
    return specialisation?.doctorTypeTranslations[0]?.name;
  };

  const getDoctorLogoFile = (id: string) => {
    const doctorLogoFile = logoFiels?.findManyFilesByIds?.files?.find(
      (file: FileEntityResponse) => file.id === id
    );
    return doctorLogoFile?.url;
  };

  const handleRemoveDoctor = (doctor: AggregatedDoctorEntityResponse) => {
    onConfirmOpen({
      accept: () =>
        deleteDoctorById({
          variables: {
            schema: {
              doctorId: doctor?.doctorId,
            },
          },
          refetchQueries: [FIND_AND_COUNT_MANY_DOCTORS_BY_CLINIC_ID],
          awaitRefetchQueries: true,
          onCompleted: () => {
            toast.success(t("app.successful"));

            const filtredUsersStorage = localUsers.filter(
              (userStorage: IUserStorageItem) => {
                return userStorage?.id !== doctor.id;
              }
            );

            onSetLocalusers([...filtredUsersStorage]);
          },
        }),
    });
  };

  const getStatus = (isActivated: boolean) => {
    if (isActivated) {
      return (
        <StatusWrapper isActivated={isActivated}>
          {t("user.personal.headers.active")}
        </StatusWrapper>
      );
    }
    return (
      <StatusWrapper isActivated={isActivated}>
        {t("user.personal.headers.disactive")}
      </StatusWrapper>
    );
  };

  const handleEdit = (doctorId: string) => () => {
    navigate(`${PATHS.cp}${PATHS.clinic}${PATHS.personal}/${doctorId}`);
  };

  return (
    <Wrapper>
      {loading || doctorTypesLoading ? (
        <ScreenLoader />
      ) : doctors?.length ? (
        <Table>
          <thead>
            <tr>
              <th> </th>
              <th>{t("user.personal.headers.name")}</th>
              <th>{t("user.personal.headers.cellphone")}</th>
              <th>{t("user.personal.headers.email")}</th>
              <th>{t("add_doctor.label.specialisation")}</th>
              <th>{t("user.personal.headers.status")}</th>
              {/* TODO tenporary comment 
               <th>{t("user.personal.headers.location")}</th> */}
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {doctors?.map((item) => (
              <tr key={item.id}>
                <td>
                  <IconWrapper>
                    {getDoctorLogoFile(item?.avatarFileId as string) ? (
                      <ImageStyled
                        src={getDoctorLogoFile(item?.avatarFileId as string)}
                        alt="#"
                      />
                    ) : (
                      <DoctorIcon />
                    )}
                  </IconWrapper>
                </td>
                <td>
                  <div>
                    {item.firstName} {item.lastName}
                  </div>
                </td>
                <td>{item.phoneNumber}</td>
                <td>{item.email}</td>
                <td>{getSpecialisation(item.doctorTypeId)}</td>
                <td>{getStatus(item.activated)}</td>
                <td>
                  {/* TODO tenporary comment 
                <LocationList>
                  {item.location.map((location, lid) => (
                    <Location key={`${id}-${lid}`}>
                      <MapIcon />
                      {location}
                    </Location>
                  ))}
                </LocationList> */}
                </td>

                <td>
                  <Actions>
                    <ButtonIcon
                      disabled={!item.activated}
                      onClick={handleEdit(item.doctorId)}
                    >
                      <EditIcon />
                    </ButtonIcon>
                    <ButtonIcon
                      onClick={() => {
                        handleRemoveDoctor(item);
                      }}
                    >
                      <DeleteIcon />
                    </ButtonIcon>
                  </Actions>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTextStyled>{t("app.doctors_not_added")}</EmptyTextStyled>
      )}
    </Wrapper>
  );
}

export default TableListComponent;
