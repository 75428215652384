import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { CheckBoxRHF } from "src/UI/Input";
import { POLICY_LINK } from "src/constants/constants";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
`;

const Text = styled.p`
  display: inline;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #3d3d3d;
`;

const Link = styled.a`
  display: inline;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #3d3d3d;
  text-decoration: underline;
  cursor: pointer;
`;

function PolicyAgreementComponent() {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <CheckBoxRHF id="check-geh" label="" name="policy" />
      <div>
        <Text>{t("auth.reg.agree_with")}</Text>
        <Link href={POLICY_LINK}>{t("auth.reg.agree_link")}</Link>
      </div>
    </Wrapper>
  );
}

export default PolicyAgreementComponent;
