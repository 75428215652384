import { Dispatch, SetStateAction, useEffect } from "react";
import { toast } from "react-toastify";
import { transformClientResponseToEventsArray } from "src/connectors/Visits/transformClientResponseToEventsArray";
import { allClinicsItemId } from "src/constants/constants";

import { useAuthContext } from "src/context/AuthContext";
import {
  AppointmentSortFields,
  AppointmentStatuses,
  CountedAggregatedScheduleAppointmentEntitiesWithRelationsByClientResponse,
  Query,
  SortingOrders,
  useFindAndCountManyScheduleAppointmentsWithRelationsByClientLazyQuery,
  useFindManyFilesByIdsLazyQuery,
} from "src/graphql/generated";
import { IEvent } from "src/pages/DashboardPages/CalendarPage/types";
import { useCalendarState } from "src/store/calendar/hooks";
import { useClinicsState } from "src/store/clinics/hooks";

export function useCalendarScheduleAppointmentsByClientEffect(
  setEvents: Dispatch<SetStateAction<IEvent[]>>
) {
  const { isClient } = useAuthContext();
  const { filters } = useCalendarState();
  const { currentClinicId: clinicId } = useClinicsState();

  const [getFiles] = useFindManyFilesByIdsLazyQuery();
  const [getScheduleAppointmentsByClientLazy] =
    useFindAndCountManyScheduleAppointmentsWithRelationsByClientLazyQuery();

  useEffect(() => {
    if (isClient && clinicId) {
      getScheduleAppointmentsByClientLazy({
        variables: {
          schema: {
            ...(clinicId !== allClinicsItemId && { clinicId }),
            sortField: AppointmentSortFields.ScheduleTimeFrom,
            sortOrder: SortingOrders.Ascending,
            dateFrom: filters.dateFrom.split("T")[0],
            dateTo: filters.dateTo.split("T")[0],
            ...(filters?.roomIds?.length && { roomIds: filters.roomIds }),
            ...(filters?.doctorIds?.length && {
              doctorIds: filters.doctorIds,
            }),
            ...(filters?.patientId?.length && {
              patientId: filters.patientId,
            }),
            ...(filters?.status?.length && {
              status: filters.status as AppointmentStatuses,
            }),
          },
        },
        onCompleted(response) {
          if (response) {
            const eventsData =
              response?.findAndCountManyScheduleAppointmentsWithRelationsByClient;
            const eventsArray = transformClientResponseToEventsArray(
              eventsData as CountedAggregatedScheduleAppointmentEntitiesWithRelationsByClientResponse
            );

            setEvents(eventsArray);

            if (eventsData?.appointments?.length) {
              const doctorsPhotoIds = eventsData?.appointments?.reduce(
                (accumulator, event) => {
                  const avatarFileId = event?.doctor?.avatarFileId;
                  if (avatarFileId && !accumulator.includes(avatarFileId)) {
                    accumulator.push(avatarFileId);
                  }
                  return accumulator;
                },
                [] as string[]
              );
              if (doctorsPhotoIds.length) {
                getFiles({
                  variables: {
                    schema: {
                      ids: doctorsPhotoIds,
                    },
                  },
                  onCompleted(responseFiles) {
                    if (responseFiles?.findManyFilesByIds) {
                      setEvents((prev) =>
                        prev.map((event) => ({
                          ...event,
                          logoFiles: responseFiles as Query,
                        }))
                      );
                    }
                  },
                  fetchPolicy: "network-only",
                });
              }
            }
          }
        },
        fetchPolicy: "no-cache",
        onError: (error) => {
          if (error) toast.error(error.message);
        },
      });
    }
  }, [clinicId, filters, isClient]);
}
