import React, { useRef } from "react";
import { useTranslation } from "react-i18next";

import { EModals } from "src/store/app/types";
import { Modal } from "src/UI/Modal";
import { useAppAction, useAppState } from "src/store/app/hooks";
import { EButtonVariants } from "src/UI/Button";

import { FormProvider, useForm } from "react-hook-form";

import { PhoneInputBig } from "src/UI/Input";
import {
  ActionBlock,
  ButtonStyled,
  Content,
  ContentWrapStyled,
  Form,
  SubTitleStyled,
} from "./styles";

interface FormValues {
  field: string;
  method: string;
}

type PhoneInputBigRef = React.ElementRef<typeof PhoneInputBig>;

function ForgotEmailModal() {
  const { onCloseModal } = useAppAction();

  const { t } = useTranslation();
  const { modals } = useAppState();

  const methods = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      method: "phone",
      field: "",
    },
  });

  const {
    watch,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = methods;

  const phoneRef = useRef<PhoneInputBigRef>(null);

  const data = watch();

  const onGoBack = () => {
    onCloseModal(EModals.ForgotEmail);
  };

  const onSubmit = (formData: FormValues) => {
    console.log(formData);
  };

  if (!modals[EModals.ForgotEmail]) return null;
  return (
    <Modal
      modal={EModals.ForgotEmail}
      title={t("modals.forgot.password")}
      headerChildren={
        <SubTitleStyled>{t("common.choose_way_to_restore")}</SubTitleStyled>
      }
    >
      <Content>
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ContentWrapStyled>
              <PhoneInputBig
                value={data.field}
                ref={phoneRef}
                onChange={(val) => setValue("field", val)}
                label={t("auth.reg.phone") || ""}
                errorMessage={t("auth.reg.phone_error") || ""}
                id="phone"
              />
            </ContentWrapStyled>
            <ActionBlock>
              <ButtonStyled onClick={onGoBack} variant={EButtonVariants.Text}>
                {t("common.back")}
              </ButtonStyled>
              <ButtonStyled
                type="submit"
                disabled={!isValid}
                variant={EButtonVariants.Default}
              >
                {t("common.continue")}
              </ButtonStyled>
            </ActionBlock>
          </Form>
        </FormProvider>
      </Content>
    </Modal>
  );
}

export default ForgotEmailModal;
