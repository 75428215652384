import { ReactNode, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import PinInput from "react-pin-input";
import { useAuthorizeWithPinCode } from "src/graphql/hooks/useMutations";
import {
  FileEntityResponse,
  UserEntityResponse,
  useFindManyFilesByIdsLazyQuery,
} from "src/graphql/generated";
import { toast } from "react-toastify";
import { useAuthContext } from "src/context/AuthContext";
import Cookies from "js-cookie";
import { Loading } from "src/UI/Loading";
import { useClinicsAction } from "src/store/clinics/hooks";
import {
  JWT,
  PATHS,
  RELOGIN_BY_PASS_SELECTION,
  RESET_PIN_ACTION,
} from "src/constants/constants";
import { useNavigate, useParams } from "react-router-dom";
import { DoctorIcon } from "src/UI/Svg";
import { useAppAction } from "src/store/app/hooks";
import { EModals } from "src/store/app/types";
import { Modal } from "src/UI/Modal";
import { EButtonVariants } from "src/UI/Button";
import {
  ActionBlock,
  ButtonForgotStyled,
  ButtonStyled,
  Content,
  DropdownStyled,
  IconWrapper,
  ImageStyled,
  Text,
  UserOptionStyled,
} from "./styles";

interface ProfileComponentProps {
  profile: UserEntityResponse;
  logoFiles: Array<FileEntityResponse>;
}

function UserOption({ profile, logoFiles }: ProfileComponentProps) {
  const avatarLogo = logoFiles?.filter(
    (file) => file.id === profile?.avatarFileId
  )[0]?.url;

  return (
    <UserOptionStyled>
      <IconWrapper>
        {!avatarLogo ? (
          <DoctorIcon height={16} width={16} />
        ) : (
          <ImageStyled src={avatarLogo} alt="avatar" />
        )}
      </IconWrapper>

      <Text>
        {profile.firstName} {profile.lastName} <span>– ID {profile.id}</span>
      </Text>
    </UserOptionStyled>
  );
}

function PinModal() {
  const { colors } = useTheme();
  const navigate = useNavigate();
  const { userId } = useParams();
  const profiles = JSON.parse(localStorage.getItem("users") as string);
  const { currentUserData, logout, refetchUser } = useAuthContext();
  const { onConfirmOpen } = useAppAction();
  const [pin, setPin] = useState<string>("");
  const [logoFiles, setLogoFiles] = useState<FileEntityResponse[]>([]);
  const { t } = useTranslation();
  const [selectedProfile, setSelectedProfile] = useState<string>("");
  const [currentUserJWT, setCurrentUserJWT] = useState<string>("");

  const avatarFileIds = profiles.reduce(
    (accumulator: string[], obj: UserEntityResponse) => {
      if (obj.avatarFileId !== null && obj.avatarFileId !== undefined) {
        accumulator.push(obj.avatarFileId);
      }
      return accumulator;
    },
    []
  );

  const [getFiles] = useFindManyFilesByIdsLazyQuery();

  useEffect(() => {
    if (!avatarFileIds && !avatarFileIds.length) {
      return;
    }
    if (!logoFiles.length) {
      getFiles({
        variables: {
          schema: {
            ids: avatarFileIds,
          },
        },
        onCompleted(response) {
          setLogoFiles(response?.findManyFilesByIds?.files);
        },
      });
    }
  }, [logoFiles]);

  const { onSetClinicId, onSetLocation } = useClinicsAction();

  const cookieJWT = Cookies.get(JWT);

  useEffect(() => {
    setCurrentUserJWT(cookieJWT || "");
  }, []);

  const jwtStorage = profiles?.find(
    (profile: UserEntityResponse) => profile?.id === selectedProfile
  )?.jwtToken;

  const [useAuthorizationWithPin, { loading }] = useAuthorizeWithPinCode({
    onCompleted: (response) => {
      if (response) {
        localStorage.removeItem("currentClinicId");
        onSetClinicId("");
        onSetLocation(null);
        refetchUser();
        toast.success(t("app.successful"));
        navigate(-1);
      }
    },
    onError: (error) => {
      if (error) {
        Cookies.set(JWT, currentUserJWT as string);
        toast.error(error.message);
        if (error.message === "Invalid JSON Web Token") {
          onConfirmOpen({
            title: `Error ${error.message}`,
            text: t("app.expired_token_message") as string,
            accept: () => {
              logout();
            },
          });
        }
      }
    },
  });

  useEffect(() => {
    if (userId) setSelectedProfile(userId);
  }, [userId]);

  const activities: Array<{ item: ReactNode; value: number }> = useMemo(
    () =>
      profiles
        ?.filter(
          (profile: UserEntityResponse) => profile?.id !== currentUserData?.id
        )
        ?.map((profile: UserEntityResponse) => ({
          item: <UserOption profile={profile} logoFiles={logoFiles} />,
          value: profile?.id || 0,
        })),
    [t, selectedProfile, profiles]
  );

  const authorizationWithPinCodeHandler = async () => {
    Cookies.set(JWT, jwtStorage as string);
    useAuthorizationWithPin({
      variables: { schema: { pinCode: pin } },
    });
  };

  const setSelectedProfileHandler = (val: string) => {
    const selectedUser = profiles?.find(
      (profile: UserEntityResponse) => profile.id === val
    );
    if (selectedUser && !selectedUser?.isPinCodeExists) {
      const searchParams = new URLSearchParams({
        actionType: RELOGIN_BY_PASS_SELECTION,
      });
      navigate({
        pathname: `${PATHS.cp}${PATHS.passModal}/${val}`,
        search: searchParams.toString(),
      });
    } else {
      setSelectedProfile(val);
    }
  };
  const setPinHandler = (val: string) => setPin(val);

  const onShowForgotPinModal = () => {
    const searchParams = new URLSearchParams({
      actionType: RESET_PIN_ACTION,
    });

    navigate({
      pathname: `${PATHS.cp}${PATHS.passModal}/${userId}`,
      search: searchParams.toString(),
    });
  };

  const handleCloseModal = () => {
    navigate(-1);
  };

  return (
    <Modal
      onCloseAction={handleCloseModal}
      modal={EModals.Pin}
      title={t("profile.pin_modal.title")}
      titleChildren={
        <DropdownStyled
          isShowOnlyPlaceholder={false}
          value={selectedProfile}
          placeholder=""
          onSelect={setSelectedProfileHandler}
          options={activities}
          label=""
        />
      }
    >
      <Content>
        <PinInput
          length={4}
          initialValue=""
          onChange={setPinHandler}
          type="numeric"
          inputMode="number"
          secret={true}
          style={{
            display: "flex",
            gap: 20,
          }}
          inputStyle={{
            padding: "15px 0",
            width: 60,
            height: 52,
            border: "none",
            borderBottom: `1px solid ${colors.borderInputDefault}`,
            fontWeight: 400,
            fontSize: 16,
            lineHeight: "140%",
            textAlign: "center",
            color: colors.black,
          }}
          focus
          autoSelect
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
        />
        <ActionBlock>
          <ButtonForgotStyled
            onClick={onShowForgotPinModal}
            variant={EButtonVariants.Text}
          >
            {t("profile.pin_modal.forgot")}
          </ButtonForgotStyled>
          <ButtonStyled
            onClick={authorizationWithPinCodeHandler}
            disabled={pin.length !== 4 || loading}
            variant={EButtonVariants.Default}
          >
            {loading ? <Loading type="secondary" padding="0" /> : null}
            {t("profile.pin_modal.enter")}
          </ButtonStyled>
        </ActionBlock>
      </Content>
    </Modal>
  );
}

export default PinModal;
