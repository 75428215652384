import styled from "styled-components";
import { Button } from "src/UI/Button";

export const Content = styled.div`
  padding: 20px;
  width: 635px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 20px;
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 16px;
    gap: 16px;
  }
`;

export const ActionBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  gap: 10px;
`;

export const SubTitleStyled = styled.p`
  margin-top: -10px;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.darkGrey};
`;

export const ButtonStyled = styled(Button)`
  width: fit-content;
  font-size: 20px;
  line-height: 100%;
  padding: 15px 20px;
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 12px 16px;
    font-size: 16px;
  }
`;

export const ContentWrapStyled = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  margin-bottom: 40px;
`;

export const Form = styled.form`
  width: 100%;

  & input {
    padding: 15px 10px;
  }
`;
