import { Dispatch, SetStateAction, useEffect } from "react";
import {
  FindManyDoctorTypesByLanguageIsoCodeQuery,
  LanguageIsoCodes,
  useFindManyDoctorTypesByLanguageIsoCodeLazyQuery,
} from "src/graphql/generated";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const useDoctorTypesEffect = (
  setDoctorTypesData: Dispatch<
    SetStateAction<FindManyDoctorTypesByLanguageIsoCodeQuery | undefined>
  >
) => {
  const { i18n } = useTranslation();
  const [findDoctorTypesByLocaleLazyQuery] =
    useFindManyDoctorTypesByLanguageIsoCodeLazyQuery();
  useEffect(() => {
    if (!i18n) return;
    findDoctorTypesByLocaleLazyQuery({
      variables: {
        schema: {
          languageISOCode: i18n.language.toUpperCase() as LanguageIsoCodes,
        },
      },
      onCompleted(response) {
        setDoctorTypesData(response);
      },
      onError: (error) => {
        if (error) toast.error(error.message);
      },
      fetchPolicy: "network-only",
    });
  }, [i18n.language]);
};
