import { Button } from "src/UI/Button";
import styled from "styled-components";

export const SlotsListStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: fit-content;
  gap: 5px;
`;

export const ButtonStyled = styled(Button)`
  padding: 8px 10px;
  font-size: 16px;
  line-height: 100%;
  width: fit-content;
  border-radius: 5px;
  outline: none !important;
  min-width: 95px;
`;
