import { gql } from "@apollo/client";

export const UPDATE_CLINIC_BY_ID = gql`
  mutation UpdateClinicById($schema: UpdateClinicByIdSchema!) {
    updateClinicById(schema: $schema) {
      id
      companyId
      name
      address
      houseNumber
      index
      city
      logoFileId
      licenseNumber
      geographicalCoordinates {
        latitude
        longitude
      }
      code
      phoneNumber
      description
      createdAt
      rooms {
        id
        clinicId
        name
      }
      workingDays {
        id
        clinicId
        day
        fromMinute
        toMinute
      }
    }
  }
`;
