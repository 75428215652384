import styled from "styled-components";

export const Wrapper = styled.div<{ isConfirmModal: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => theme.colors.modalWrapper};
  z-index: ${({ isConfirmModal }) => (isConfirmModal ? "10001" : "10000")};
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  justify-content: center;
`;

export const Overflow = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 90%;
  overflow: auto;
  max-width: 90%;

  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: 100%;
    width: 100%;
    height: 100%;
  }
`;

export const ModalStyled = styled.div`
  background: ${({ theme }) => theme.colors.background};
  border-radius: 10px;
  position: relative;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
`;

export const Title = styled.h3<{ isHeaderChildren: boolean }>`
  font-weight: 500;
  font-size: 24px;
  line-height: 100%;
  margin-bottom: ${({ isHeaderChildren }) =>
    isHeaderChildren ? "20px" : "0px"};
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
`;

export const Content = styled.div``;

export const CloseButton = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.white};
  backdrop-filter: blur(50px);
  border-radius: 100px;
  min-width: 32px;
  width: 32px;
  height: 32px;

  ${({ theme }) => theme.mediaQueries.sm} {
    position: absolute;
    width: 40px;
    height: 40px;
    right: 0;
    top: 12px;
  }
`;

export const Header = styled.div`
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault};

  ${({ theme }) => theme.mediaQueries.sm} {
    padding-right: 45px;
  }
`;

export const ModalsWrappers = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: 100%;
    height: 100%;
  }
`;

export const Row = styled.div`
  position: relative;
  max-height: 100%;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 20px;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: 100%;
    height: 100%;
  }
`;
