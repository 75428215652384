import React, { ReactNode, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { EModals } from "src/store/app/types";
import { Modal } from "src/UI/Modal";
import { useAppAction, useAppState } from "src/store/app/hooks";
import { useProfileAction, useProfileState } from "src/store/profile/hooks";
import { DoctorIcon } from "src/UI/Svg";
import { EButtonVariants } from "src/UI/Button";

import { IUser } from "src/types";
import { CheckBox } from "src/UI/Input";
import {
  ActionBlock,
  ButtonStyled,
  Content,
  DropdownStyled,
  Menu,
  MenuItemStyled,
  SubTitleStyled,
  Text,
  UserOptionStyled,
} from "./styles";

function UserOption({ name, soname, id }: IUser) {
  return (
    <UserOptionStyled>
      <DoctorIcon height={16} width={16} />
      <Text>
        {name} {soname} <span>– ID {id}</span>
      </Text>
    </UserOptionStyled>
  );
}

function ForgotPinModal() {
  const { onCloseModal, onShowModal } = useAppAction();

  const { t } = useTranslation();
  const { modals } = useAppState();
  const { profiles, selectedProfile, selectedProfileId } = useProfileState();
  const { onSelectProfile, onSelectProfileId } = useProfileAction();

  const [selectedItem, setSelectedItem] = useState<{
    item: string;
    value: string;
  }>({ item: t("user.person_data.enter.auth"), value: "Qrcode" });

  const activities: Array<{ item: ReactNode; value: number }> = useMemo(
    () =>
      profiles
        .filter((profile) => profile.id !== selectedProfile.id)
        .map((profile) => ({
          item: <UserOption {...profile} />,
          value: profile.id || 0,
        })),
    [t, selectedProfile, profiles]
  );

  const variants: Array<{ item: string; value: string }> = useMemo(
    () => [
      { item: t("user.person_data.enter.auth"), value: "Qrcode" },
      { item: t("user.person_data.profile.facebook"), value: "facebook" },
      { item: t("history.email"), value: "email" },
      { item: t("auth.reg.password"), value: "PassForPin" },
      { item: t("reminder.table.cellphone"), value: "ForgotEmail" },
    ],
    [t]
  );

  const handleSlelect = (menuItem: { item: string; value: string }) => () => {
    setSelectedItem(menuItem);
  };

  const handleOpenModal = () => {
    onSelectProfile();
    onCloseModal(EModals.ForgotPin);

    switch (selectedItem.value) {
      case "Qrcode":
        onShowModal(EModals.Qrcode);
        break;
      case "ForgotPass":
        onShowModal(EModals.ForgotPass);
        break;
      case "ForgotEmail":
        onShowModal(EModals.ForgotEmail);
        break;
      case "PassForPin":
        onShowModal(EModals.PassForPin);
        break;

      default:
        break;
    }
  };

  if (!modals[EModals.ForgotPin]) return null;
  return (
    <Modal
      modal={EModals.ForgotPin}
      title={t("profile.pin_modal.forgot")}
      titleChildren={
        <DropdownStyled
          isShowOnlyPlaceholder={false}
          value={selectedProfileId || 0}
          placeholder=""
          onSelect={(val) => onSelectProfileId(val)}
          options={activities}
          label=""
        />
      }
      headerChildren={
        <SubTitleStyled>{t("common.choose_way_to_restore")}</SubTitleStyled>
      }
    >
      <Content>
        <Menu>
          {variants.map((opt) => {
            const isSelected = opt?.value === selectedItem?.value;
            return (
              <MenuItemStyled
                isSelected={isSelected}
                key={opt.value}
                onClick={handleSlelect(opt)}
              >
                {opt.item}
                <CheckBox
                  value={isSelected}
                  onChange={() => {}}
                  id={String(opt.value)}
                />
              </MenuItemStyled>
            );
          })}
        </Menu>
        <ActionBlock>
          <ButtonStyled
            onClick={() => {
              onSelectProfile();
              onCloseModal(EModals.ForgotPin);
              onShowModal(EModals.Pin);
            }}
            variant={EButtonVariants.Text}
          >
            {t("common.back")}
          </ButtonStyled>
          <ButtonStyled
            onClick={handleOpenModal}
            disabled={!selectedItem}
            variant={EButtonVariants.Default}
          >
            {t("common.continue")}
          </ButtonStyled>
        </ActionBlock>
      </Content>
    </Modal>
  );
}

export default ForgotPinModal;
