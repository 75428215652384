import styled from "styled-components";

export const CheckBoxStyled = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 10px;
  position: relative;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  opacity: ${({ disabled }) => (disabled ? ".3" : "1")};
`;

export const Label = styled.label<{ isChecked?: boolean }>`
  display: flex;
  width: 20px;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 20px;
  background: ${({ theme, isChecked }) =>
    theme.colors[isChecked ? "green" : "white"]};
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.03);
  border-radius: 3px;
  cursor: pointer;
  svg path {
    stroke: ${({ theme }) => theme.colors.white};
  }
`;

export const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  display; none;
`;

export const Text = styled.label`
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.black};
  margin: 0;
  cursor: pointer;
`;
