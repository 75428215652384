import React, { ReactNode, MouseEvent } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppAction } from "src/store/app/hooks";
import styled from "styled-components";

interface MenuItemProps {
  link: string;
  tip: string;
  icon: ReactNode;
}

const MenuItem = styled(Link)<{ active: string; isDisabled?: boolean }>`
  position: relative;
  display: flex;
  padding: 16px 0;
  width: 100%;
  justify-content: center;
  gap: 10px;
  background: ${({ theme, active }) =>
    active === "true"
      ? "linear-gradient(91.55deg, rgba(11, 164, 149, 0.05) 0%, rgba(11, 164, 149, 0) 100%)"
      : theme.colors.white};
  border-left: 2px solid ${({ theme }) => theme.colors.green};
  border-color: ${({ theme, active }) =>
    theme.colors[active === "true" ? "green" : "white"]};

  svg path {
    stroke: ${({ theme, active }) =>
      theme.colors[active === "true" ? "green" : "gray"]};
  }

  opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};

  ${({ isDisabled }) => (isDisabled ? "pointer-events: none;" : "")}
  &:hover {
    svg path {
      stroke: ${({ theme }) => theme.colors.green};
    }
  }
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 5px 0;
    svg {
      height: 20px;
      width: 20px;
    }
  }
`;

function MenuItemComponent({ link, tip, icon }: MenuItemProps) {
  const { pathname } = useLocation();

  const { onToggleNavTip } = useAppAction();
  return (
    <MenuItem
      to={link}
      active={`${pathname === link}`}
      onMouseEnter={(e: MouseEvent) => {
        onToggleNavTip({
          top: e.currentTarget.getBoundingClientRect().y,
          title: tip,
        });
      }}
      onMouseLeave={() => onToggleNavTip()}
    >
      {icon}
    </MenuItem>
  );
}

export default MenuItemComponent;
