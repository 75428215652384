import { gql } from "@apollo/client";

export const CANCEL_SCHEDULE_APPOINTMENT_BY_CLIENT = gql`
  mutation CancelScheduleAppointmentByClient(
    $schema: CancelScheduleAppointmentByClientSchema!
  ) {
    cancelScheduleAppointmentByClient(schema: $schema) {
      canceled
    }
  }
`;
