import styled from "styled-components";

export const DropdownStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  min-width: 180px;
  // ${({ theme }) => theme.mediaQueries.ll} {
  //   min-width: 160px;
  // }
`;

export const LabelStyled = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.gray};
  margin-right: 8px;
  // ${({ theme }) => theme.mediaQueries.ll} {
  //   font-size: 11px;
  //   line-height: 15px;
  //   margin-right: 6px;
  // }
`;
export const RowStyled = styled.div<{ showMenu: boolean; disabled?: boolean }>`
  font-style: normal;
  width: 100%;
  font-weight: 500;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  padding: 8px 10px;
  font-size: 16px;
  line-height: 100%;
  gap: 4px;
  background: ${({ theme, showMenu }) =>
    theme.colors[showMenu ? "white" : "lightBiege"]};
  border: 1px solid
    ${({ theme, showMenu }) =>
      showMenu ? theme.colors.borderInputDefault : "transparent"};
  border-radius: ${({ showMenu }) => (showMenu ? "5px 5px 0 0" : "5px")};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.gray : theme.colors.black};
  white-space: nowrap;
  // ${({ theme }) => theme.mediaQueries.ll} {
  //   padding: 6px 8px;
  //   font-size: 12px;
  // }
`;

export const IconStyled = styled.div<{ showMenu: boolean }>`
  margin-left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ showMenu }) =>
    showMenu
      ? `svg {
    transform: rotateZ(-180deg);
  };`
      : ""}
`;
export const Menu = styled.div<{
  showMenu: boolean;
  isHideBorderOnShowMenu?: boolean;
}>`
  visibility: ${({ showMenu }) => (showMenu ? "visible" : "hidden")};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  gap: 2px;
  padding: 4px 0;
  top: 100%;
  left: 0;
  box-sizing: border-box;
  right: 0;
  transition: all 0.25s ease-out;
  opacity: ${({ showMenu }) => (showMenu ? 1 : 0)};
  z-index: 100;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  border-top: ${({ isHideBorderOnShowMenu, theme }) =>
    isHideBorderOnShowMenu
      ? `1px solid ${theme.colors.borderInputDefault}`
      : "none"};
  border-radius: ${({ isHideBorderOnShowMenu }) =>
    isHideBorderOnShowMenu ? "5px" : "0 0 5px 5px"};
  height: ${({ showMenu }) => (showMenu ? "fit-content" : 0)};
  max-height: 300px;
  width: 100%;
  overflow-y: auto;
`;

export const Placeholder = styled.div<{ disabled: boolean }>`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.gray : theme.colors.black};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RowItemStyled = styled.div<{
  isActive: boolean;
  isSelected: boolean;
}>`
  font-style: normal;
  padding: 7px 14px;
  font-weight: 500;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme, isActive, isSelected }) =>
    theme.colors[isActive || isSelected ? "green" : "black"]};
  cursor: pointer;
  &:hover {
    background-color: #e7f6f5;
    color: ${({ theme }) => theme.colors.green};
  }
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 12px;
    padding: 5px 12px;
  }
`;
