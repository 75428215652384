import styled from "styled-components";

export const InputBlock = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;
`;
export const GeocoderStyled = styled.div<{
  isError: boolean;
  isShowBtn: boolean;
}>`
  width: 100%;

  & .mapboxgl-ctrl-geocoder {
    width: 100%;
    max-width: 100%;
    box-shadow: none;
  }

  & input {
    height: 53px;
    border: none;
    outline: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    padding: 15px 36px 15px 0;
    color: ${({ theme }) => theme.colors.black};
    border-bottom: 1px solid
      ${({ theme, isError }) =>
        isError ? theme.colors.red : theme.colors.borderInputDefault};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  & input:focus {
    outline: none;
    border-color: ${({ theme }) => theme.colors.green};
  }

  & .mapboxgl-ctrl-geocoder--button {
    display: ${({ isShowBtn }) => (isShowBtn ? `block !important` : `none`)};
  }

  & .mapboxgl-ctrl-geocoder--icon-loading {
    top: 30% !important;
    right: 14px;
  }

  & .mapboxgl-ctrl-geocoder--icon-search {
    display: none;
  }

  & .mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right > * {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #fff;
    top: 50%;
    transform: translateY(-50%);

    & svg {
      margin: 0;
    }
  }

  & .mapboxgl-ctrl-geocoder input::placeholder {
    color: transparent;
  }
`;

export const Label = styled.label<{ isFocused: boolean }>`
  color: ${({ isFocused, theme }) =>
    theme.colors[isFocused ? "grayC4" : "gray"]};
  position: absolute;
  top: ${({ isFocused }) => (isFocused ? "0" : "50%")};
  transform: translateY(-50%);
  left: 0;
  z-index: 2;
  font-weight: 500;
  font-size: ${({ isFocused }) => (isFocused ? "12px" : "16px")};
  line-height: ${({ isFocused }) => (isFocused ? "100%" : "140%")};
`;

// ${({ theme }) => theme.mediaQueries.ll} {
//   font-size: ${({ isFocused }) => (isFocused ? "10px" : "12px")};
// }

export const ErrorMessage = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: right;
  color: ${({ theme }) => theme.colors.red};
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
`;

// ${({ theme }) => theme.mediaQueries.ll} {
//   font-size: 12px;
// }
