import { IPost } from "../../types";

export const posts: IPost[] = [
  {
    isGoogle: true,
    date: "17 wrz 2022 o 9:30",
    text: `More than 16% of the population live on Earth with hearing loss. More than 500M people. More than 16% of the population live on Earth with hearing loss. More than 500M people. More than 16% of the population live on Earth with hearing loss. More than 500M people.`,
    isClock: false,
    image: "post_1.png",
  },
  {
    isGoogle: false,
    date: "17 wrz 2022 o 9:30",
    text: `More than 16% of the population live on Earth with hearing loss. More than 500M people. More than 16% of the population live on Earth with hearing loss. More than 500M people. More than 16% of the population live on Earth with hearing loss. More than 500M people.`,
    isClock: true,
    image: "post_2.png",
  },
];
