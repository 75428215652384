import { ReactNode } from "react";
import {
  ClinicEntityResponse,
  ServiceEntityWithRelationsResponse,
  FileEntityResponse,
} from "src/graphql/generated";
import {
  TData,
  TScheduleAppointmentByRole,
  TScheduleWaitingAppointment,
} from "src/types";
import { CreateEditFormValues } from "src/types/CreateEditForm";

export enum EModals {
  // Search,
  Pin,
  ReviewAnswer,
  Timetable,
  AddService,
  EditService,
  NewWaitlist,
  Qrcode,
  CustomizeForm,
  Preview,
  Send,
  AddEmployee,
  AddPatient,
  CalendarDetail,
  DeleteEvent,
  NewEvent,
  PatientVisitSettings,
  PatientSettings,
  Confirm,
  ForgotPin,
  ForgotPass,
  ForgotEmail,
  PassForPin,
  CreatePin,
  AddCabinet,
  CreateDoctorInvition,
  ChangeEmail,
  EditServiceCategory,
  ScheduleAppointment,
  ScheduleWaitingAppointment,
  CreateEditForm,
  PreviewForm,
  CancelAppointment,
  AddToWaitListModal,
  PatientFilledFormPreviewModal,
}
export enum EAlert {
  Success,
  Error,
  Info,
}

export type IPatientVisitSettingsFieldsList =
  | "name"
  | "birth"
  | "doctor"
  | "clinic"
  | "cabinet"
  | "send"
  | "time"
  | "price";

export type IPatientVisitSettingsFields = {
  [key in IPatientVisitSettingsFieldsList]: boolean;
};

export type IPatientSettingsFieldsList =
  | "name"
  | "birth"
  | "phone"
  | "email"
  | "appelation"
  | "status"
  | "pesel";

export type IPatientSettingsFields = {
  [key in IPatientSettingsFieldsList]: boolean;
};

export type IConfirmPropsClose = boolean;

export type IConfirmProps = {
  title?: string;
  text?: string;
  accept: () => void;
  cancel?: () => void;
};

export type ICabinet = { name: string; id?: string };
export type IAddCabinetProps = {
  cabinet?: ICabinet | null;
  accept: (data: ICabinet) => void;
};

export type IAddWaitListProps = {
  accept: () => void;
};

export type IServiceCategory = { name: string; id?: string };
export type EditServiceCategoryProps = {
  category?: IServiceCategory | null;
  accept: (data: IServiceCategory) => void;
};

export type IEmail = { email: string };
export type IChangeEmailProps = {
  email: string;
  accept: (data: IEmail) => void;
};

export type IUserPinCode = {
  id: string;
  firstName: string;
  lastName: string;
  logoFiles: Array<FileEntityResponse>;
};

export type IUserPass = {
  id: string;
  firstName: string;
  lastName: string;
};

export interface IAlert {
  alertType: EAlert;
  content: string | ReactNode;
}

export interface INavTip {
  title: string;
  top: number;
}

export type IEdirServiceModal = {
  data: {
    service: ServiceEntityWithRelationsResponse;
  };
};

export type ICreateDoctorInvitationModal = {
  data: {
    clinics: ClinicEntityResponse[];
  };
};

export type IScheduleAppointmentModal = {
  data: {
    appointment: TScheduleAppointmentByRole;
  };
};

export type IScheduleWaitingAppointmentModal = {
  data: {
    appointment: TScheduleWaitingAppointment;
  };
};

export interface IUserStorageItem {
  avatarFileId?: string | null;
  firstName: string;
  id: string;
  lastName: string;
  isPinCodeExists?: boolean;
}

export type ICancelAppointmentProps = {
  accept: () => void;
  cancel?: () => void;
};

export interface IShowEditFormModalProps {
  isEditForm?: boolean;
  formId?: string;
  serviceId?: string;
  defaultServiceId?: string;
  formValue?: CreateEditFormValues;
}

export interface IShowPreviewModalProps
  extends Omit<CreateEditFormValues, "defaultCountFields"> {
  defaultCountFields?: number;
  isShowCreateEditModalAfterClose: boolean;
  defaultServiceId?: string;
  isEditForm?: boolean;
}

export interface IShowPatientFilledFormPreviewModalProps {
  tDataPreview: TData[];
}

export type ICancelAppointmentPropsClose = boolean;
