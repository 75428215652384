import * as yup from "yup";

export const createWaitListSchema = yup.object().shape({
  clinicId: yup.string().nullable().required("validation.field_required"),
  doctorId: yup.string().nullable().required("validation.field_required"),
  patientId: yup.string().nullable().required("validation.field_required"),
  serviceId: yup.string().nullable().required("validation.field_required"),
  minuteFrom: yup
    .number()
    .nullable()
    .test("check-time", "app.booking.no_visit_time", (value) => {
      return typeof value === "number";
    }),
  minuteTo: yup
    .number()
    .nullable()
    .test("check-time", "app.booking.no_visit_time", (value) => {
      return typeof value === "number";
    }),
});
