import styled, { keyframes } from "styled-components";

const pulsingMarkerAnimation = keyframes`
  0% {
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }
  50% {
    opacity: 0.2;
    width: 80px;
    height: 80px;
  }
  100% {
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }
`;

export const MapCover = styled.div`
  width: 100%;
  height: 100%;

  & .mapboxgl-canvas {
    width: 100% !important;
  }

  & .mapboxgl-marker {
    &.pulsing-marker {
      &:before {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: "";
        width: 80px;
        height: 80px;
        background-color: ${({ theme }) => theme.colors.green};
        border-radius: 50%;
        opacity: 0.2 !important;
        transform-origin: center;
        animation: ${pulsingMarkerAnimation} 2s infinite !important;
      }
      img {
        position: absolute;
        width: 80px;
        height: 80px;
        min-width: 80px;
        min-height: 80px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
`;
