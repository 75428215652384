import { FunctionComponent, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { LangFlags, PATHS } from "src/constants/constants";
import { useOutsideClick } from "src/hooks/useOutsideClick";
import { SvgProps } from "src/UI/Svg";
import { useLocation } from "react-router-dom";
import { DefaultState, LangItem, LangList, SelectWrapper } from "./styled";

function LangSelectComponent() {
  const { i18n } = useTranslation();
  const location = useLocation();
  const isAuthLayout = location.pathname.includes(`${PATHS.auth || PATHS.vr}`);

  const [data, setData] = useState<{
    open: boolean;
    code: string;
  }>({
    open: false,
    code: "en",
  });

  const currentFlag = useMemo(() => {
    const flag = LangFlags.find((i) => i.code === i18n.resolvedLanguage);
    return flag ? flag.flag : null;
  }, [i18n.resolvedLanguage]);

  const onChange = (key: string, val: any) => {
    setData((prevState) => ({
      ...prevState,
      [key]: val,
    }));
  };

  const onSelectLang =
    (lang: { code: any; flag?: FunctionComponent<SvgProps> }) => () => {
      onChange("open", false);
      i18n.changeLanguage(lang.code);
    };

  const LangItems = LangFlags.map((lang) => {
    const Icon = lang.flag;

    return (
      <LangItem
        key={lang.code}
        id={lang.code}
        onClick={onSelectLang(lang)}
        isSelected={lang.code === i18n.resolvedLanguage}
        isAuthLayout={!!isAuthLayout}
      >
        <Icon />
      </LangItem>
    );
  });

  const SelectedIcon = currentFlag;

  const wrap = useRef<HTMLDivElement>(null);

  useOutsideClick({
    ref: wrap,
    handler: () => {
      onChange("open", false);
    },
  });

  const onOpenLangs = () => {
    onChange("open", !data.open);
  };

  return (
    <SelectWrapper ref={wrap}>
      <DefaultState onClick={onOpenLangs}>
        {SelectedIcon && <SelectedIcon />}
      </DefaultState>
      {data.open ? (
        <LangList isAuthLayout={!!isAuthLayout}>{LangItems}</LangList>
      ) : null}
    </SelectWrapper>
  );
}

export default LangSelectComponent;
