import React, { useRef, useState } from "react";
import { useAuthContext } from "src/context/AuthContext";
import { useOutsideClick } from "src/hooks/useOutsideClick";
import { ProfileBlock, StyledWrapperMain } from "./styled";
import ProfileComponent from "./ProfileComponent";

import ProfilesListComponent from "./ProfileListComponent/ProfilesListComponent";
import ProfileMenuComponent from "./profileMenuComponent/ProfileMenuComponent";

function ProfileMainComponent() {
  const { currentUserData } = useAuthContext();
  const [isShowList, setIsShowList] = useState<boolean>(false);
  const [isShowMenu, setIsShowMenu] = useState<boolean>(false);
  const form = useRef(null);

  useOutsideClick({
    ref: form,
    handler: () => {
      setIsShowList(() => false);
      setIsShowMenu(() => false);
    },
  });

  return (
    <ProfileBlock ref={form}>
      <StyledWrapperMain
        onClick={() => setIsShowMenu(!isShowMenu)}
        isOpen={isShowList || isShowMenu}
      >
        <ProfileComponent user={currentUserData} />
      </StyledWrapperMain>
      <ProfileMenuComponent isShow={isShowMenu} onToggleList={setIsShowList} />
      <ProfilesListComponent
        currentUserData={currentUserData}
        isShow={isShowList}
        onClose={() => setIsShowList(false)}
      />
    </ProfileBlock>
  );
}

export default ProfileMainComponent;
