import { gql } from "@apollo/client";

export const CREATE_SCHEDULE_APPOINTMENT_BY_PATIENT = gql`
  mutation CreateScheduleAppointmentByPatient(
    $schema: CreateScheduleAppointmentByPatientSchema!
  ) {
    createScheduleAppointmentByPatient(schema: $schema) {
      hash
    }
  }
`;
