import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { FieldError, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import RowItem from "./RowItem";
import { ChevronDownIcon, CloseIcon } from "../Svg";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import {
  BtnsRowStyled,
  DropdownInputStyled,
  ErrorMessage,
  IconButton,
  IconStyled,
  Label,
  Menu,
  Placeholder,
  RowStyled,
  SelectedValue,
  StyledInput,
} from "./styles";

interface DropdownInputProps {
  name: string;
  value: string | number | object | null | undefined;
  placeholder: string | ReactNode;
  options?: Array<{ item: string | ReactNode; value: string | number }>;
  label: string;
  isShowOnlyPlaceholder?: boolean;
  adaptedForMobile?: boolean;
  isRegister?: boolean;
  errorMessage?: FieldError;
  customWidth?: string;
  customIcon?: ReactNode;
  isDisabled?: boolean;
  isFocusOnError?: boolean;
  isClearable?: boolean;
  onSelect?: () => void;
}

function DropdownInputRHF({
  name,
  value,
  placeholder,
  label,
  options,
  isShowOnlyPlaceholder,
  adaptedForMobile = true,
  isRegister = false,
  errorMessage,
  customWidth,
  customIcon,
  isDisabled = false,
  isClearable = false,
  isFocusOnError,
  onSelect,
  ...props
}: DropdownInputProps) {
  const { t } = useTranslation();
  const dropdown = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const menu = useRef<HTMLDivElement | null>(null);
  const input = useRef<HTMLInputElement | null>(null);
  const {
    setValue,
    trigger,
    formState: { errors, isSubmitting },
  } = useFormContext();

  useOutsideClick({ ref: dropdown, handler: () => setShowMenu(false) });

  useEffect(() => {
    if (isFocusOnError && !isSubmitting && errors[name]?.message) {
      input.current?.focus();
    }
  }, [isSubmitting]);

  const selectItem = useMemo(
    () =>
      options?.filter(
        (item) => JSON.stringify(item.value) === JSON.stringify(value)
      )[0] || null,
    [options, value]
  );

  const onSelectHandler = useCallback(
    (val: string | number) => {
      setValue(name, val);
      setShowMenu(false);
      trigger(name);
      if (onSelect) onSelect();
    },
    [setValue]
  );

  const [selectItemItem] = useMemo(() => {
    if (selectItem) return [selectItem.item, selectItem.value];
    return [placeholder, "-"];
  }, [selectItem, placeholder]);

  const onToggleShow = useCallback(() => {
    setShowMenu(!showMenu);
  }, [showMenu]);

  const handleClear = () => {
    setValue(name, "");
  };

  return (
    <DropdownInputStyled
      ref={dropdown}
      isRegister={isRegister}
      customWidth={customWidth}
      isDisabled={isDisabled}
      {...props}
    >
      {label !== "" && (
        <Label onClick={onToggleShow} isFocused={showMenu || !!value}>
          {label}
        </Label>
      )}
      <StyledInput ref={input} style={{ position: "absolute" }} />
      <RowStyled
        onClick={onToggleShow}
        isFocused={showMenu || !!value}
        showMenu={showMenu}
        isRegister={isRegister}
      >
        {selectItem && !isShowOnlyPlaceholder ? (
          <SelectedValue
            isRegister={isRegister}
            adaptedForMobile={adaptedForMobile}
          >
            {selectItemItem}
          </SelectedValue>
        ) : (
          <Placeholder
            isRegister={isRegister}
            adaptedForMobile={adaptedForMobile}
          >
            {placeholder}
          </Placeholder>
        )}
        <BtnsRowStyled>
          {isClearable && selectItemItem && (
            <IconButton onClick={handleClear}>
              <CloseIcon />
            </IconButton>
          )}
          <IconStyled showMenu={showMenu}>
            {customIcon || <ChevronDownIcon size="16px" />}
          </IconStyled>
        </BtnsRowStyled>
      </RowStyled>
      <Menu ref={menu} showMenu={showMenu}>
        {options?.map((opt) => {
          const isSelected = selectItem?.value === opt.value;

          return (
            <RowItem
              isActive={false}
              isSelected={isSelected}
              key={JSON.stringify(opt.value)}
              onSelect={onSelectHandler}
              item={opt.item}
              value={opt.value}
            />
          );
        })}
      </Menu>
      {errorMessage && (
        <ErrorMessage isRegister={isRegister}>
          {errorMessage?.message && t(errorMessage.message)}
        </ErrorMessage>
      )}
    </DropdownInputStyled>
  );
}

export default DropdownInputRHF;
