import { gql } from "@apollo/client";

export const FIND_BLANK_FORM_BY_SERVICE_ID = gql`
  query FindBlankFormByServiceId($schema: FindBlankFormByServiceIdSchema!) {
    findBlankFormByServiceId(schema: $schema) {
      clinicId
      id
      name
      serviceId
      fields {
        formId
        hiddenFromExistingPatients
        id
        order
        required
        type
        value
        translations {
          fieldId
          id
          name
          languageISOCode
          placeholder
          value
        }
      }
    }
  }
`;
