import { useMemo } from "react";
import {
  DateLocalizer,
  Navigate,
  NavigateAction,
  TitleOptions,
  ViewProps,
} from "react-big-calendar";
// @ts-ignore
import TimeGrid from "react-big-calendar/lib/TimeGrid";
import SliderCarousel from "src/components/SliderCarousel/SliderCarousel";
import { ChevronRightIcon } from "src/UI/Svg";
import { useCalendarState } from "src/store/calendar/hooks";
import { IEvent } from "src/pages/DashboardPages/CalendarPage/types";
import { TCabinetsGroup, TDoctorsGroup } from "src/store/calendar/types";
import { useGetDoctorsAgregated } from "src/hooks/doctors/useGetDoctorsAgregated";
import { useTranslation } from "react-i18next";
import EmptyText from "src/components/EmptyText/EmptyText";
import {
  ArrowLeftStyled,
  ArrowRightStyled,
  RowStyled,
  SliderWrapStyled,
  TimeGutterEndWrapper,
  TimeGutterWrapper,
} from "./styles";
import HeadingRoomsComponent from "./HeadingRoomsComponent/HeadingRoomsComponent";
import HeadingDoctorsComponent from "./HeadingDoctorsComponent/HeadingDoctorsComponent";

function DayView({
  date,
  localizer,
  scrollToTime = localizer.startOf(new Date(), "day"),
  events = [],
  ...props
}: ViewProps) {
  const { t, i18n } = useTranslation();
  const currRange = useMemo(() => DayView.range(date), [date]);
  const { cabinetsGroup, doctorsGroup, dayTimeRange, filters } =
    useCalendarState();
  const { doctorsList } = useGetDoctorsAgregated();
  const min = dayTimeRange?.min;
  const max = dayTimeRange?.max;

  const settings = useMemo(() => {
    const slidesCount =
      cabinetsGroup.length || doctorsGroup.length < 6
        ? cabinetsGroup.length || doctorsGroup.length
        : 6;

    return {
      arrows: true,
      dots: false,
      speed: 500,
      infinite: false,
      slidesToShow: slidesCount,
      slidesToScroll: slidesCount,
      prevArrow: (
        <ArrowLeftStyled>
          <ChevronRightIcon />
        </ArrowLeftStyled>
      ),
      nextArrow: (
        <ArrowRightStyled>
          <ChevronRightIcon />
        </ArrowRightStyled>
      ),
    };
  }, [cabinetsGroup.length, doctorsGroup.length]);

  const slides = useMemo(() => {
    const isDoctorsGroup = !!doctorsGroup?.length && !cabinetsGroup?.length;
    const isCabinetsGroup = !!cabinetsGroup?.length;

    const generateSlides = (groups: TCabinetsGroup | TDoctorsGroup) => {
      return groups.map((group) => {
        const eventsByGroup = [...(events || [])].filter((event) => {
          const eventData = (event as IEvent)?.eventData;
          if (isDoctorsGroup && eventData?.doctor?.doctorId === group.value) {
            return true;
          }
          if (isCabinetsGroup && eventData?.room?.id === group.value) {
            return true;
          }
          return false;
        });

        return (
          <div key={group.value}>
            {isCabinetsGroup && (
              <HeadingRoomsComponent
                cabinet={group as TCabinetsGroup[number]}
              />
            )}
            {isDoctorsGroup && (
              <HeadingDoctorsComponent
                doctor={group as TDoctorsGroup[number]}
                doctorsList={doctorsList}
                color={(eventsByGroup?.[0] as IEvent)?.color?.softColor}
              />
            )}

            <TimeGrid
              events={eventsByGroup}
              date={date}
              localizer={localizer}
              max={max}
              min={min}
              range={currRange}
              scrollToTime={scrollToTime}
              {...props}
            />
          </div>
        );
      });
    };

    const cabinetsSlides = generateSlides(cabinetsGroup);
    const doctorsSlides = generateSlides(doctorsGroup);

    if (isDoctorsGroup) {
      return doctorsSlides;
    }
    if (isCabinetsGroup) {
      return cabinetsSlides;
    }
    return [];
  }, [
    cabinetsGroup,
    doctorsGroup,
    events,
    i18n.language,
    doctorsList,
    filters,
  ]);

  if (!dayTimeRange) {
    return <EmptyText text={t("app.schedule_empty_text")} />;
  }
  if (slides?.length) {
    return (
      <div>
        <RowStyled>
          <TimeGutterWrapper>
            <TimeGrid
              events={[]}
              date={date}
              localizer={localizer}
              max={max}
              min={min}
              range={currRange}
              scrollToTime={scrollToTime}
              {...props}
            />
          </TimeGutterWrapper>
          <SliderWrapStyled key={slides?.length}>
            <SliderCarousel settings={settings} slides={slides} />
          </SliderWrapStyled>
          <TimeGutterEndWrapper>
            <TimeGrid
              events={[]}
              date={date}
              localizer={localizer}
              max={max}
              min={min}
              range={currRange}
              scrollToTime={scrollToTime}
              {...props}
            />
          </TimeGutterEndWrapper>
        </RowStyled>
      </div>
    );
  }
  return (
    <TimeGrid
      events={events}
      date={date}
      localizer={localizer}
      max={max}
      min={min}
      range={currRange}
      scrollToTime={scrollToTime}
      {...props}
    />
  );
}

DayView.navigate = (
  date: Date,
  action: NavigateAction,
  options: TitleOptions & { localizer: DateLocalizer }
) => {
  const { localizer } = options;
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -1, "day");

    case Navigate.NEXT:
      return localizer.add(date, 1, "day");

    default:
      return date;
  }
};

DayView.title = (
  date: Date,
  options: TitleOptions & { localizer: DateLocalizer }
) => {
  const { localizer } = options;
  const [start] = DayView.range(date);

  return localizer.format(start, "EEEE, MMMM d");
};

DayView.range = (date: string | Date) => {
  const formattedDate = typeof date === "string" ? new Date(date) : date;
  return [formattedDate];
};

export default DayView;
