import { gql } from "@apollo/client";

export const CONVERT_SCHEDULE_WAITLIST_TO_APPOINTMENT_BY_PATIENT = gql`
  mutation ConvertScheduleWaitlistToScheduleAppointmentByPatient(
    $schema: ConvertScheduleWaitlistToScheduleAppointmentByPatientSchema!
  ) {
    convertScheduleWaitlistToScheduleAppointmentByPatient(schema: $schema) {
      id
      scheduleId
      patientId
      status
      createdAt
      updatedAt
    }
  }
`;
