import {
  formatDateToDateISO,
  getDateFromMinutesStartDay,
  getFNSLocaleByLang,
  getMinutesFromStartOfDayWithoutTimeZoneOffset,
} from "src/utils";
import { useTranslation } from "react-i18next";
import ReactDatePicker from "react-datepicker";
import { FC, forwardRef, useEffect, useState } from "react";

import { addMonths } from "date-fns";
import {
  DateInputStyled,
  DatePickerWrapper,
  TimeBlockLabel,
  TimeBlockWrapper,
  TimeScheduleItemWaitList,
  TimeScheduleWrapper,
} from "./styled";

interface Props {
  minuteFromCount: number | null;
  minuteToCount: number | null;
  dateFrom: Date | null;
  dateTo: Date | null;
  onChangeMinuteFrom: (minuteCount: number | null) => void;
  onChangeMinuteTo: (date: number | null) => void;
  onDateRangeChange: (dates: [Date | null, Date | null]) => void;
}

const CustomInput = forwardRef(
  (
    { value, onClick, placeholder }: React.HTMLProps<HTMLInputElement>,
    ref: React.Ref<HTMLInputElement>
  ) => (
    <DateInputStyled onClick={onClick} ref={ref}>
      {value || placeholder || ""}
    </DateInputStyled>
  )
);

const AddWaitListDatePicker: FC<Props> = ({
  minuteFromCount,
  minuteToCount,
  dateFrom,
  dateTo,
  onChangeMinuteFrom,
  onChangeMinuteTo,
  onDateRangeChange,
}) => {
  const { t, i18n } = useTranslation();
  const [minuteFrom, setMinuteFrom] = useState<number | null>(null);
  const [minuteTo, setMinuteTo] = useState<number | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  useEffect(() => {
    setMinuteFrom(minuteFromCount);
    setMinuteTo(minuteToCount);
    setStartDate(dateFrom ? new Date(dateFrom) : null);
    setEndDate(dateTo ? new Date(dateTo) : null);
  }, [minuteFromCount, minuteToCount, dateFrom, dateTo]);

  const handleChangeMinuteFrom = (date: Date) => {
    const minuteCount = getMinutesFromStartOfDayWithoutTimeZoneOffset(
      formatDateToDateISO(date as Date)
    );
    setMinuteFrom(minuteCount);
    onChangeMinuteFrom(minuteCount);
  };

  const handleChangeMinuteTo = (date: Date) => {
    const minuteCount = getMinutesFromStartOfDayWithoutTimeZoneOffset(
      formatDateToDateISO(date as Date)
    );
    setMinuteTo(minuteCount);
    onChangeMinuteTo(minuteCount);
  };

  const handleDateRangeChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    onDateRangeChange(dates);
  };

  return (
    <>
      <TimeBlockWrapper>
        <TimeBlockLabel>{t("modals.patient_settings.time")}</TimeBlockLabel>
        <TimeScheduleWrapper>
          <TimeScheduleItemWaitList>
            <ReactDatePicker
              selected={getDateFromMinutesStartDay(minuteFrom)}
              onChange={handleChangeMinuteFrom}
              customInput={<CustomInput />}
              placeholderText={t("booking.timetable.labels.from") as string}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={10}
              timeCaption={t("app.schedule_time") as string}
              timeFormat="HH:mm"
              dateFormat="HH:mm"
              filterTime={(currentTime) => {
                return minuteTo
                  ? currentTime < (getDateFromMinutesStartDay(minuteTo) as Date)
                  : true;
              }}
            />
          </TimeScheduleItemWaitList>
          <span>-</span>
          <TimeScheduleItemWaitList>
            <ReactDatePicker
              selected={getDateFromMinutesStartDay(minuteTo)}
              onChange={handleChangeMinuteTo}
              placeholderText={t("booking.timetable.labels.to") as string}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={10}
              customInput={<CustomInput />}
              timeCaption={t("app.schedule_time") as string}
              timeFormat="HH:mm"
              dateFormat="HH:mm"
              filterTime={(currentTime) => {
                return minuteFrom
                  ? currentTime >
                      (getDateFromMinutesStartDay(minuteFrom) as Date)
                  : true;
              }}
            />
          </TimeScheduleItemWaitList>
        </TimeScheduleWrapper>
      </TimeBlockWrapper>
      <DatePickerWrapper>
        <ReactDatePicker
          locale={getFNSLocaleByLang(i18n.resolvedLanguage)}
          startDate={startDate}
          endDate={endDate}
          selected={startDate}
          onChange={handleDateRangeChange}
          minDate={new Date()}
          maxDate={addMonths(new Date(), 1)}
          selectsRange
          inline
        />
      </DatePickerWrapper>
    </>
  );
};

export default AddWaitListDatePicker;
