import styled from "styled-components";

const HeaderCover = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export default HeaderCover;
