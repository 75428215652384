import { useDispatch, useSelector } from "react-redux";
import { useCallback, useMemo } from "react";
import { AppDispatch, RootState } from "../index";
import {
  selectDuration,
  setCabinetsGroup,
  setDayRange,
  setDoctorsGroup,
  setFilters,
  setWeekRange,
} from "./calendarSlice";
import {
  EDuration,
  TAppointmentsFilters,
  TCabinetsGroup,
  TDateTimeRange,
  TDoctorsGroup,
} from "./types";

export const getCalendar = (state: RootState) => state.calendar;

export function useCalendarAction() {
  const dispatch = useDispatch<AppDispatch>();

  const onSetFilters = useCallback(
    (filter: Partial<TAppointmentsFilters>) => {
      dispatch(setFilters(filter));
    },
    [dispatch]
  );

  const onSetCabinetsGroup = useCallback(
    (cabinets: TCabinetsGroup) => {
      dispatch(setCabinetsGroup(cabinets));
    },
    [dispatch]
  );

  const onSetDoctorsGroup = useCallback(
    (cabinets: TDoctorsGroup) => {
      dispatch(setDoctorsGroup(cabinets));
    },
    [dispatch]
  );

  const onSetDayTimeRange = useCallback(
    (range: TDateTimeRange) => {
      dispatch(setDayRange(range));
    },
    [dispatch]
  );

  const onSetWeekTimeRange = useCallback(
    (range: TDateTimeRange) => {
      dispatch(setWeekRange(range));
    },
    [dispatch]
  );

  const onSelectDuration = useCallback(
    (durationId: EDuration) => {
      dispatch(selectDuration(durationId));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      onSetFilters,
      onSelectDuration,
      onSetCabinetsGroup,
      onSetDoctorsGroup,
      onSetDayTimeRange,
      onSetWeekTimeRange,
    }),
    [
      onSetFilters,
      onSelectDuration,
      onSetCabinetsGroup,
      onSetDoctorsGroup,
      onSetDayTimeRange,
      onSetWeekTimeRange,
    ]
  );
}

export function useCalendarState() {
  return useSelector(getCalendar);
}
