import styled from "styled-components";
import { Button } from "../Button";

export const Block = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
`;

export const PaddingWrapper = styled.div`
  padding: 0 20px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;

  position: relative;

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    border-bottom: 2px solid ${({ theme }) => theme.colors.borderInputDefault};
  }
`;

export const WrapperTabs = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  gap: 20px;
  //   ${({ theme }) => theme.mediaQueries.ll} {
  //     gap: 16px;
  //   }
  position: relative;
`;

export const Tab = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  padding-bottom: 16px;
  color: ${({ theme, isActive }) => theme.colors[isActive ? "green" : "black"]};
  cursor: pointer;
  position: relative;
  z-index: 2;
  border-bottom: 2px solid
    ${({ theme, isActive }) => (isActive ? theme.colors.green : "transparent")};

  span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2px 4.5px;
    background: ${({ theme, isActive }) =>
      theme.colors[isActive ? "green" : "grayC4"]};
    border-radius: 800px;
    color: ${({ theme }) => theme.colors.white};
  }
  //   ${({ theme }) => theme.mediaQueries.ll} {
  //     font-size: 12px;
  //   }
`;

export const ButtonStyled = styled(Button)`
  padding: 8px 10px;
  font-size: 16px;
  line-height: 100%;
  width: fit-content;

  // ${({ theme }) => theme.mediaQueries.ll} {
  //   padding: 6px 8px;
  //   font-size: 12px;
  // }
`;
