import { gql } from "@apollo/client";

export const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    getCurrentUser {
      id
      email
      firstName
      lastName
      activated
      roles
      createdAt
      avatarFileId
    }
  }
`;
