import { Heading } from "src/UI/Heading";
import { CloseIconThin } from "src/UI/Svg";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  //display: flex;
  //flex-direction: column;
  //align-items: flex-start;
  //gap: 20px;
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  flex: 1;
  width: 100%;
  height: 100%;
  max-width: 552px;
`;

export const CloseIconStyled = styled(CloseIconThin)`
  position: absolute;
  right: 0;
  top: 0;
`;

export const HeadingStyled = styled(Heading)`
  font-size: 24px;
  line-height: 120%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  width: 100%;
  height: 68px;
  padding: 0 20px;

  svg {
    cursor: pointer;
  }
  svg path {
    stroke: ${({ theme }) => theme.colors.gray};
  }
`;

export const LoadingWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;
