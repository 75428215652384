import { FC, useEffect, useMemo, useState } from "react";
import { Divider } from "src/UI/Divider";
import {
  SlotsGroupedByDateResponse,
  useFindManyAvailableSlotsByDateRangeQuery,
} from "src/graphql/generated";
import { useBookingState } from "src/store/booking/hooks";
import { getDateWithoutTimeZoneOffset } from "src/utils";
import {
  DoctorLeftWrapStiled,
  DoctorRightWrapStiled,
  DoctorWrapStiled,
} from "./styles";
import { ComponentProps } from "./types";
import SlotsListComponent from "../SlotsListComponent/SlotsListComponent";
import AddToWaitListCmponent from "../AddToWaitListCmponent/AddToWaitListCmponent";
import DoctorInfoComponent from "../DoctorInfoComponent/DoctorInfoComponent";

const DoctorItemComponent: FC<ComponentProps> = ({
  doctor,
  slotsWaitlist,
  dateFilter,
  selectedDate,
  doctorTypesData,
  logoFilesArray,
  logoFielsLoading,
  onSetAvailableSlotsLoading,
  onSetAvailableDates,
  onSetCurrentStep,
}) => {
  const { currentService } = useBookingState();
  const [slots, setSlots] = useState<SlotsGroupedByDateResponse[]>([]);

  const { loading: availableSlotsLoading } =
    useFindManyAvailableSlotsByDateRangeQuery({
      variables: {
        schema: {
          doctorId: doctor?.doctorId,
          serviceId: currentService?.service?.id as string,
          dateFrom: dateFilter.dateFrom,
          dateTo: dateFilter.dateTo,
        },
      },
      skip: !currentService?.service?.id,
      onCompleted: (response) => {
        const availableDatesArray =
          response?.findManyAvailableSlotsByDateRange?.availableSlots?.map(
            (item: SlotsGroupedByDateResponse) => item.date
          );
        onSetAvailableDates((prev) => [...prev, ...availableDatesArray]);
        setSlots((prev) => [
          ...prev,
          ...response?.findManyAvailableSlotsByDateRange?.availableSlots,
        ]);
      },
      fetchPolicy: "network-only",
    });

  const availableSlots = useMemo(() => {
    const currentSlots = slotsWaitlist.length ? slotsWaitlist : slots;

    const foundSlotsByDate = currentSlots.find(
      (slot) => slot.date === selectedDate
    );

    const availableSlotsByCurrentTime = foundSlotsByDate?.slots?.filter(
      (slot) => {
        const slotFrom = getDateWithoutTimeZoneOffset(slot.from);
        return new Date(slotFrom) > new Date();
      }
    );
    return availableSlotsByCurrentTime ? availableSlotsByCurrentTime : [];
  }, [selectedDate, slots]);

  useEffect(() => {
    onSetAvailableSlotsLoading(!!availableSlotsLoading);
  }, [availableSlotsLoading]);

  const availableSlotsFiltredByActualTime = useMemo(() => {
    const currentSlots = slotsWaitlist.length ? slotsWaitlist : slots;

    const filteredSlots = currentSlots.reduce(
      (
        accumulator: SlotsGroupedByDateResponse[],
        slotObj: SlotsGroupedByDateResponse
      ) => {
        if (slotObj.date === selectedDate) {
          const filteredSlotArray = slotObj?.slots?.filter((slot) => {
            const slotFrom = getDateWithoutTimeZoneOffset(slot.from);
            return new Date(slotFrom) > new Date();
          });

          if (filteredSlotArray?.length > 0) {
            accumulator.push({
              ...slotObj,
              slots: filteredSlotArray,
            });
          }
        } else {
          accumulator.push(slotObj);
        }

        return accumulator;
      },
      []
    );

    return filteredSlots;
  }, [selectedDate, availableSlots, slotsWaitlist]);

  return (
    <div>
      <DoctorWrapStiled>
        <DoctorLeftWrapStiled>
          <DoctorInfoComponent
            doctor={doctor}
            doctorTypesData={doctorTypesData}
            logoFilesArray={logoFilesArray}
            logoFielsLoading={logoFielsLoading}
          />
        </DoctorLeftWrapStiled>

        <DoctorRightWrapStiled>
          {availableSlots.length ? (
            <SlotsListComponent
              slots={availableSlots}
              selectedDate={selectedDate}
              doctor={doctor}
              onSetCurrentStep={onSetCurrentStep}
            />
          ) : (
            <AddToWaitListCmponent
              doctor={doctor}
              slots={availableSlotsFiltredByActualTime}
              onSetCurrentStep={onSetCurrentStep}
            />
          )}
        </DoctorRightWrapStiled>
      </DoctorWrapStiled>
      <Divider margin="20px 0" />
    </div>
  );
};

export default DoctorItemComponent;
