import { Modal } from "src/UI/Modal";
import { useAppAction, useAppState } from "src/store/app/hooks";
import { EModals } from "src/store/app/types";
import AddWaitListDatePicker from "src/components/BookingComponents/BookingDatePickerComponents/AddWaitListDatePicker/AddWaitListDatePicker";
import { useTranslation } from "react-i18next";
import { EButtonVariants } from "src/UI/Button";
import { useCallback, useEffect, useState } from "react";
import { useBookingAction } from "src/store/booking/hooks";
import { formatISO } from "date-fns";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  useFindScheduleWaitlistByHashByPatientLazyQuery,
  useRescheduleWaitlistByPatientMutation,
} from "src/graphql/generated";
import { toast } from "react-toastify";
import { PATHS } from "src/constants/constants";
import {
  ButtonStyled,
  Buttons,
  Content,
  Header,
  HeadingStyled,
} from "./styles";

function AddToWaitListModal() {
  const { t } = useTranslation();
  const { modals } = useAppState();
  const { onCloseModal } = useAppAction();
  const { onSetWaitListSlot } = useBookingAction();
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const waitingApptHash = query.get("waitingApptHash") as string;

  const [getWaitingAppointment, { loading: loadingWaitingAppointment }] =
    useFindScheduleWaitlistByHashByPatientLazyQuery();

  const [
    reschaduleWaitingAppointment,
    { loading: loadingReschaduleWaitingAppointment },
  ] = useRescheduleWaitlistByPatientMutation();

  const [minuteFrom, setMinuteFrom] = useState<number | null>(null);
  const [minuteTo, setMinuteTo] = useState<number | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  useEffect(() => {
    if (waitingApptHash) {
      getWaitingAppointment({
        variables: { schema: { hash: waitingApptHash } },
        onCompleted: (response) => {
          setMinuteFrom(
            response.findScheduleWaitlistByHashByPatient.minuteFrom
          );
          setMinuteTo(response.findScheduleWaitlistByHashByPatient.minuteTo);
          setStartDate(
            new Date(response.findScheduleWaitlistByHashByPatient.dateFrom)
          );
          setEndDate(
            new Date(response.findScheduleWaitlistByHashByPatient.dateTo)
          );
        },
        fetchPolicy: "no-cache",
      });
    }
  }, [waitingApptHash]);

  const handleChangeMinuteFrom = (minuteCount: number | null) => {
    setMinuteFrom(minuteCount);
  };
  const handleChangeMinuteTo = (minuteCount: number | null) => {
    setMinuteTo(minuteCount);
  };

  const handleDateRangeChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    if (start && end) {
      setStartDate(start);
      setEndDate(end);
    }
  };

  const closeModal = useCallback(() => {
    onCloseModal(EModals.AddToWaitListModal);
  }, [onCloseModal]);

  const handleAdd = () => {
    if (waitingApptHash) {
      reschaduleWaitingAppointment({
        variables: {
          schema: {
            minuteFrom: minuteFrom as number,
            minuteTo: minuteTo as number,
            dateFrom: formatISO(startDate as Date),
            dateTo: formatISO(endDate as Date),
            hash: waitingApptHash,
          },
        },
        onCompleted: () => {
          toast.success(t("app.successful"));
          const searchParams = new URLSearchParams({
            waitingApptHash,
          });
          navigate({
            pathname: `${PATHS.patientBooking}${PATHS.detailsAppointment}`,
            search: searchParams.toString(),
          });
        },
      });
    }
    onSetWaitListSlot({
      slot: {
        minuteFrom: minuteFrom as number,
        minuteTo: minuteTo as number,
        dateFrom: formatISO(startDate as Date),
        dateTo: formatISO(endDate as Date),
      },
    });
    setMinuteFrom(null);
    setMinuteTo(null);
    setStartDate(null);
    setEndDate(null);
    modals[EModals.AddToWaitListModal].props.accept();
    closeModal();
  };

  const idDisabled =
    typeof minuteFrom !== "number" ||
    typeof minuteTo !== "number" ||
    !startDate ||
    !endDate ||
    loadingWaitingAppointment ||
    loadingReschaduleWaitingAppointment;

  if (!modals[EModals.AddToWaitListModal].active) return null;
  return (
    <Modal modal={EModals.AddToWaitListModal} title="">
      <Header>
        <HeadingStyled as="h3">{t("forms.modals.fields.date")}</HeadingStyled>
      </Header>
      <Content>
        <AddWaitListDatePicker
          minuteFromCount={minuteFrom}
          minuteToCount={minuteTo}
          dateFrom={startDate}
          dateTo={endDate}
          onChangeMinuteFrom={handleChangeMinuteFrom}
          onChangeMinuteTo={handleChangeMinuteTo}
          onDateRangeChange={handleDateRangeChange}
        />
      </Content>
      <Buttons>
        <ButtonStyled
          variant={EButtonVariants.Text}
          type="button"
          onClick={closeModal}
        >
          {t("common.cancel")}
        </ButtonStyled>
        <ButtonStyled
          type="button"
          variant={EButtonVariants.Default}
          onClick={handleAdd}
          disabled={idDisabled}
        >
          {t("website.booking.on_the_wait_list")}
        </ButtonStyled>
      </Buttons>
    </Modal>
  );
}

export default AddToWaitListModal;
