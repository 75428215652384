import React, { FunctionComponent } from "react";
import { Svg, SvgProps } from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
  <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      d="M7.99967 8.66671C8.36786 8.66671 8.66634 8.36823 8.66634 8.00004C8.66634 7.63185 8.36786 7.33337 7.99967 7.33337C7.63148 7.33337 7.33301 7.63185 7.33301 8.00004C7.33301 8.36823 7.63148 8.66671 7.99967 8.66671Z"
      fill="#A5A5A5"
      stroke="#A5A5A5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99967 3.99996C8.36786 3.99996 8.66634 3.70148 8.66634 3.33329C8.66634 2.9651 8.36786 2.66663 7.99967 2.66663C7.63148 2.66663 7.33301 2.9651 7.33301 3.33329C7.33301 3.70148 7.63148 3.99996 7.99967 3.99996Z"
      fill="#A5A5A5"
      stroke="#A5A5A5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99967 13.3333C8.36786 13.3333 8.66634 13.0349 8.66634 12.6667C8.66634 12.2985 8.36786 12 7.99967 12C7.63148 12 7.33301 12.2985 7.33301 12.6667C7.33301 13.0349 7.63148 13.3333 7.99967 13.3333Z"
      fill="#A5A5A5"
      stroke="#A5A5A5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Icon;
