import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;
  gap: 4px;
  background: ${({ theme }) => theme.colors.lightBiege};
  border-radius: 40px;
`;

export const Block = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;
  gap: 10px;
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.03);
  border-radius: 56px;
  &:first-child {
    padding-right: 20px;
  }
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
`;

export const Name = styled.h4`
  font-weight: 500;
  font-size: 16px;
  line-height: 22.4px;
`;

export const Phone = styled.h4`
  font-weight: 500;
  font-size: 12px;
  line-height: 16.8px;
  color: ${({ theme }) => theme.colors.darkGrey};
`;
