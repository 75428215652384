import { Loading } from "src/UI/Loading";
import { Title, Value, Wrapper } from "./styled";

interface StatisticItemProps {
  title: string;
  value: number | undefined;
  loading: boolean;
}

function StatisticItemComponent({ title, value, loading }: StatisticItemProps) {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <Value>{loading ? <Loading padding="0" /> : value ?? 0}</Value>
    </Wrapper>
  );
}

export default StatisticItemComponent;
