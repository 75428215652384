import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDrag, useDrop } from "react-dnd";
import type { Identifier, XYCoord } from "dnd-core";
import { ITemplateAction } from "src/types/templates";
import { DistributionActions } from "src/graphql/generated";
import { EButtonVariants } from "src/UI/Button";
import {
  DeleteIcon,
  DragHorizontalIcon,
  MessageIcon,
  SmartphoneIcon,
} from "../../../../UI/Svg";
import { Actions, ButtonIcon, Desc, Info, Item } from "./styled";

interface DragItem {
  id: string;
  index: number;
  type: string;
}

function TemplateComponentsDragItem({
  data,
  isSelected,
  index,
  isDisabledDelete,
  select,
  remove,
  moveCard,
}: {
  data: ITemplateAction;
  isSelected: boolean;
  isDisabledDelete: boolean;
  select: () => void;
  remove: () => void;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: "card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: () => {
      return { name: data.name, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const handleRemoveClick = (e: any) => {
    e.stopPropagation();
    remove();
  };

  return (
    <Item
      isSelected={isSelected}
      onClick={select}
      ref={ref}
      opacity={opacity}
      data-handler-id={handlerId}
    >
      <Info isGreen>
        {data.name === DistributionActions.SendSms && <SmartphoneIcon />}
        {data.name === DistributionActions.SendMail && <MessageIcon />}

        <Desc>
          {t(data.titleId)}

          <div>{t(data.subtitleId)}</div>
        </Desc>
      </Info>

      <Actions>
        <ButtonIcon variant={EButtonVariants.Default} type="button">
          <DragHorizontalIcon />
        </ButtonIcon>

        <ButtonIcon
          onClick={handleRemoveClick}
          disabled={isDisabledDelete}
          variant={EButtonVariants.Default}
        >
          <DeleteIcon />
        </ButtonIcon>
      </Actions>
    </Item>
  );
}

export default TemplateComponentsDragItem;
