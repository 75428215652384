import { Loading } from "src/UI/Loading";
import EmptyText from "src/components/EmptyText/EmptyText";
import { useTranslation } from "react-i18next";
import {
  AggregatedScheduleAppointmentEntityWithRelationsByClientResponse,
  AggregatedScheduleAppointmentEntityWithRelationsByDoctorResponse,
  PatientEntityResponse,
  SearchByClientUnion,
  SearchByDoctorUnion,
  SearchTypes,
} from "src/graphql/generated";
import {
  AppointmentSearchComponent,
  PatientSearchComponent,
} from "src/components/DashboardComponents/SearchComponents";
import { TScheduleAppointmentByRole } from "src/types";
import { LoadingWrap } from "./styled";

interface IEntitiesByTypeComponent {
  filter: SearchTypes;
  entities: SearchByClientUnion[] | SearchByDoctorUnion[] | null | undefined;
  loading: boolean;
  handleShowModal: (appointment: TScheduleAppointmentByRole) => () => void;
}

export const EntitiesByTypeComponent = ({
  filter,
  entities,
  loading,
  handleShowModal,
}: IEntitiesByTypeComponent) => {
  const { t } = useTranslation();
  if (loading) {
    return (
      <LoadingWrap>
        <Loading padding="0" />
      </LoadingWrap>
    );
  }

  if (!entities?.length) {
    return <EmptyText text={t("app.not_found")} />;
  }

  if (filter === SearchTypes.Patient) {
    return (
      <div>
        {entities?.map((item) => (
          <PatientSearchComponent
            key={(item as PatientEntityResponse).id}
            item={item as PatientEntityResponse}
          />
        ))}
      </div>
    );
  }
  return (
    <div>
      {entities?.map((item) => (
        <AppointmentSearchComponent
          key={
            (
              item as
                | AggregatedScheduleAppointmentEntityWithRelationsByClientResponse
                | AggregatedScheduleAppointmentEntityWithRelationsByDoctorResponse
            ).id
          }
          item={
            item as
              | AggregatedScheduleAppointmentEntityWithRelationsByClientResponse
              | AggregatedScheduleAppointmentEntityWithRelationsByDoctorResponse
          }
          handleShowModal={handleShowModal}
        />
      ))}
    </div>
  );
};
