import { Route, Routes } from "react-router-dom";

import { useNewAppointment } from "src/hooks/useNewAppointment";
import { AddAppointment } from "src/context/AddAppointment";
import CancelAppointmentModal from "src/components/Modals/CancelAppointmentModal/CancelAppointmentModal";
import AddToWaitListModal from "src/components/Modals/AddToWaitListModal/AddToWaitListModal";
import { PATHS } from "src/constants/constants";
import NotFoundPage from "../DashboardPages/NotFoundPage";

import MapPage from "./MapPage";
import RatePage from "./RatePage/RatePage";

function Booking() {
  const appointmentProvide = useNewAppointment();
  return (
    <AddAppointment.Provider value={appointmentProvide}>
      <CancelAppointmentModal />
      <AddToWaitListModal />
      <Routes>
        <Route path="/*" element={<MapPage />} />
        <Route path={`${PATHS.rate}`} element={<RatePage />} />
        <Route key="NotFound" path="*" element={<NotFoundPage />} />
      </Routes>
    </AddAppointment.Provider>
  );
}

export default Booking;
