import * as yup from "yup";
import { parsePhoneNumber } from "awesome-phonenumber";

export const addPatientSchema = yup.object().shape({
  first_name: yup
    .string()
    .required("auth.reg.name_error")
    .min(1, "auth.reg.name_error")
    .max(100, "validation.field_max_100"),
  last_name: yup
    .string()
    .required("auth.reg.surname_error")
    .min(1, "auth.reg.surname_error")
    .max(100, "validation.field_max_100"),
  phone: yup
    .string()
    .required("validation.field_required")
    .test("is-valid", "validation.invalid_phone_number_required", (val) => {
      return parsePhoneNumber(`+${val}` as string).possible;
    }),
  email: yup
    .string()
    .required("validation.field_required")
    .test("check-length", "auth.login.email_error", (value) => {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (value?.length) return emailRegex.test(value);
      return true;
    })
    .required("validation.field_required"),
  description: yup
    .string()
    .max(300, "validation.field_max_300")
    .test("check-min-length-5", "validation.field_min_5", (value) => {
      if (value && value.length < 5) return false;
      return true;
    })
    .nullable(),
  passportNumber: yup
    .string()
    .max(11, "validation.field_max_11")
    .test("check-min-length-9", "validation.field_min_9", (value) => {
      if (value && value.length < 9) return false;
      return true;
    })
    .nullable(),
});
