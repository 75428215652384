import { Heading } from "src/UI/Heading";
import styled from "styled-components";

export const Block = styled.div`
  padding: 20px;
  gap: 20px;
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 698px;
  min-width: calc(100% / 3);
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 16px;
    max-width: unset;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  width: 100%;
`;

export const HeadingStyled = styled(Heading)`
  font-size: 24px;
  line-height: 120%;
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 20px;
  }
`;

export const List = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 16px;
`;

export const EmptyTextStyled = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.gray};
`;

export const LoadingWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;
