import { Button } from "src/UI/Button";
import { Heading } from "src/UI/Heading";
import styled from "styled-components";

export const Stepper = styled.div`
  display: flex;
`;
export const StepperWrapper = styled.div<{ isFullWidth: boolean }>`
  width: ${({ isFullWidth }) => (isFullWidth ? "100%" : "62%")};
  min-width: ${({ isFullWidth }) => (isFullWidth ? "100%" : "734px")};
  display: flex;
  z-index: 1;

  ${({ theme }) => theme.mediaQueries.lg} {
    width: 100%;
    min-width: 100%;
  }
`;

export const Step = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.black : theme.colors.gray};
  cursor: ${({ isActive }) => (isActive ? "pointer" : "default")};
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  span {
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    background: ${({ theme, isActive }) =>
      theme.colors[isActive ? "green" : "grayC4"]};
    border-radius: 50%;
    color: ${({ theme }) => theme.colors.white};
  }

  &:not(:first-child):before {
    content: "";
    width: 30px;
    height: 1px;
    margin: 0px 10px;
    background: ${({ theme, isActive }) =>
      theme.colors[isActive ? "green" : "grayC4"]};
  }
`;

export const Content = styled.div`
  padding: 25px 0px 30px 0px;
`;

export const Label = styled.label`
  width: 100%;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
`;

export const FormsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ButtonBlock = styled.div`
  align-self: flex-end;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  padding: 30px 0px 20px;
`;

export const StyledButton = styled(Button)`
  padding: 15px 20px;
  font-size: 20px;
  line-height: 100%;
  max-width: unset;
  width: fit-content;
`;

export const HeadingStyled = styled(Heading)`
  font-size: 24px;
  line-height: 100%;
  margin-bottom: 20px;
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;

  ${({ theme }) => theme.mediaQueries.md} {
    gap: 20px;
  }
`;

export const InputsRow = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  align-content: center;
  justify-content: flex-start;
  gap: 20px;

  & > div {
    width: 100%;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: column;
  }
`;

export const Form = styled.form`
  display: contents;
`;
