import { useTranslation } from "react-i18next";
import {
  CommunicationPreferences,
  MainDataComponent,
} from "src/components/PatientsComponents";
import PatientFormButtons from "src/components/PatientsComponents/PatientFormButtons/PatientFormButtons";
import { Block, HeadingStyled, Title } from "./styled";

interface ActivityViewProps {
  updatePatientLoading: boolean;
}

function ActivityView({ updatePatientLoading }: ActivityViewProps) {
  const { t } = useTranslation();

  return (
    <Block>
      <Title>
        <HeadingStyled as="h3">{t("patients.activity.title")}</HeadingStyled>
      </Title>
      <MainDataComponent />
      <CommunicationPreferences />
      <PatientFormButtons updatePatientLoading={updatePatientLoading} />
    </Block>
  );
}

export default ActivityView;
