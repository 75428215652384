import { useTranslation } from "react-i18next";
import {
  FormFieldAnswerResponse,
  FormFieldEntityWithRelationsResponse,
} from "src/graphql/generated";
import { EActivityComponentSize } from "src/types/enum";
import {
  FieldElementView,
  FieldElementViewContainer,
  FieldName,
  FieldValue,
} from "./styled";
import { useGetFieldTranslation } from "./useGetFieldTranslation";

interface IActivityComponentPatientFormDefault {
  answer: FormFieldAnswerResponse;
  fields: Array<FormFieldEntityWithRelationsResponse>;

  size?: EActivityComponentSize;
}

function ActivityComponentPatientFormText({
  answer,
  fields,
  size,
}: IActivityComponentPatientFormDefault) {
  const { t } = useTranslation();
  const fieldTranslation = useGetFieldTranslation({ answer, fields });

  return (
    <FieldElementView>
      <FieldElementViewContainer>
        <FieldName $size={size}>{fieldTranslation?.name}: </FieldName>

        <FieldValue $size={size}>
          {answer.value ||
            t(
              "forms.tabs.patients_submissions.view_filled_form.field_filled_value"
            )}
        </FieldValue>
      </FieldElementViewContainer>
    </FieldElementView>
  );
}

export default ActivityComponentPatientFormText;
