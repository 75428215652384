import { TFunction, i18n as Ti18n } from "i18next";
import { toast } from "react-toastify";
import { useAuthContext } from "src/context/AuthContext";
import { IAppointmentsFilters, ILinkTypes, IMenuItem } from "src/types";
import { enUS, pl } from "date-fns/locale";

import {
  format,
  differenceInYears,
  parse,
  intlFormat,
  setMinutes,
  setHours,
  startOfDay,
  getHours,
  getMinutes,
  startOfWeek,
  eachDayOfInterval,
} from "date-fns";
import {
  AppointmentStatuses,
  ClinicWorkingDayEntityResponse,
  SortingOrders,
} from "src/graphql/generated";

import {
  AppointmentSortFieldsForApi,
  AppointmentStatusesForApi,
  SortingOrdersForApi,
  allClinicsItemId,
} from "src/constants/constants";
import { IEvent } from "src/pages/DashboardPages/CalendarPage/types";
import {
  print,
  parse as parseGQL,
  DocumentNode,
  OperationDefinitionNode,
} from "graphql";
import { client } from "src/graphql/client";
import { ESupportedLangs } from "src/types/enum";

export const addZeroForward = (string: string, needLength: number = 2) =>
  `${"0".repeat(needLength - string.length)}${string}`;

export function renderDate(
  date: Date,
  withTime?: boolean,
  withSeconds?: boolean
): string {
  if (withTime) {
    return `${addZeroForward(date.getDate().toString())}.${addZeroForward(
      (date.getMonth() + 1).toString()
    )}.${date.getFullYear()}, ${addZeroForward(
      date.getHours().toString()
    )}:${addZeroForward(date.getMinutes().toString())}${
      withSeconds ? `:${addZeroForward(date.getSeconds().toString())}` : ""
    }`;
  }
  return `${addZeroForward(date.getDate().toString())}.${addZeroForward(
    (date.getMonth() + 1).toString()
  )}.${date.getFullYear()}`;
}

export const getNowTime = () => {
  const now = new Date();
  const countHours = now.getHours();
  const countMinutes = now.getMinutes();
  const countSeconds = now.getSeconds();
  const allTime = 24 * 3600;
  return (
    ((countHours * 3600 + countMinutes * 60 + countSeconds) * 100) / allTime
  );
};

export const renderTime = (date: Date) =>
  `${date.getHours()}:${String(date.getMinutes()).padStart(2, "0")}`;

export const getTime = (date: Date) => {
  const countHours = date.getHours();
  const countMinutes = date.getMinutes();
  const countSeconds = date.getSeconds();
  const allTime = 24 * 3600;
  return (
    ((countHours * 3600 + countMinutes * 60 + countSeconds) * 100) / allTime
  );
};

export const getCurrencySimbolByCode = (code?: string) => {
  switch (code) {
    case "PLN": {
      return "zł";
    }
    case "EUR": {
      return "€";
    }
    case "USD": {
      return "$";
    }

    default: {
      return "-";
    }
  }
};

export const getFNSLocaleByLang = (langCode?: string) => {
  switch (langCode) {
    case "pl": {
      return pl;
    }
    case "en": {
      return enUS;
    }

    default: {
      return enUS;
    }
  }
};

export const getFilteredLinksByRole = (links: ILinkTypes[]) => {
  const { role } = useAuthContext();
  const filteredLinks = links.filter((link) => link.roles.includes(role));

  return filteredLinks;
};

export const getMenuItemsByRole = (menuItems: IMenuItem[]) => {
  const { role } = useAuthContext();
  const filteredLinks = menuItems.filter((item) => item.roles.includes(role));

  return filteredLinks;
};

export const getMinutesFromStartDay = (date?: Date | null) => {
  if (date) {
    return date.getHours() * 60 + date.getMinutes();
  }

  return null;
};

export const getDateFromMinutesStartDay = (
  minutesCount?: number | null | undefined,
  date?: Date
): Date | null => {
  if (typeof minutesCount === "number") {
    return setMinutes(
      setHours(startOfDay(date || new Date()), 0),
      Number(minutesCount)
    );
  }

  return null;
};

export const formatDateToDateISO = (date: Date) => {
  return `${format(date as Date, "yyyy-MM-dd")}T${
    getHours(date) < 10 ? "0" : ""
  }${getHours(date)}:${getMinutes(date) < 10 ? "0" : ""}${getMinutes(
    date
  )}:00.000Z`;
};

export const isValidaImageFile = (
  file: File,
  translate: TFunction<"translation", undefined>
) => {
  const isImg = file.type.includes("image");
  const isBigSize = file.size / 1024 > 10240;
  if (!isImg) {
    toast.error(translate("app.file_img_error_format_message"));
    return false;
  }
  if (isBigSize) {
    toast.error(translate("app.file_img_error_size_message"));
    return false;
  }

  return true;
};

export const getDateWithoutTimeZoneOffset = (dateIso: string) => {
  const dateObject = new Date(dateIso);

  const localDate = dateObject.setMinutes(
    dateObject.getMinutes() + dateObject.getTimezoneOffset()
  );
  return localDate;
};

export const getMinutesFromStartOfDayWithoutTimeZoneOffset = (
  dateIso: string
) => {
  // DATE EXAMPLE 2023-11-02T00:25:00.000Z
  const dateObjectString = new Date(dateIso);

  const hoursString = dateObjectString.getUTCHours();
  const minutesString = dateObjectString.getUTCMinutes();

  const minutesFromStartOfDay = hoursString * 60 + minutesString;
  return minutesFromStartOfDay;
};

export const getAge = (date: string) => {
  // DATE EXAMPLE 2023-09-05
  const dateOfBirth = parse(date, "yyyy-MM-dd", new Date());
  const currentDate = new Date();
  const age = differenceInYears(currentDate, dateOfBirth);
  return age;
};

export const getDateByLocale = (
  dateIso: string,
  opt: {
    year?: "numeric" | "2-digit";
    month?: "numeric" | "2-digit" | "narrow" | "short" | "long";
    day?: "numeric" | "2-digit";
    hour?: "numeric" | "2-digit";
    minute?: "numeric" | "2-digit";
  },
  locale: string
) => {
  return intlFormat(new Date(dateIso as string), opt, {
    locale: `${locale}-${locale.toUpperCase()}`,
  });
};

export const getFormatDateByLocale = (
  date: string | Date,
  locale: string,
  formatDate = "dd MMM yyyy"
) => {
  // DATE EXAMPLE 2023-10-31T07:21:42.656Z
  return format(new Date(date as string), formatDate, {
    locale: getFNSLocaleByLang(locale),
  });
};

export const getUrlExportAppointmentsToExcel = (
  filters: IAppointmentsFilters,
  isDoctor: boolean,
  clinicId: string
) => {
  let dateTo;
  if (filters?.dateTo) {
    dateTo = `&dateTo=${format(
      new Date(filters?.dateTo),
      "yyyy-MM-dd"
    )}T00:00:00`;
  } else {
    dateTo = "";
  }
  let dateFrom;
  if (filters?.dateFrom) {
    dateFrom = `&dateFrom=${format(
      new Date(filters?.dateFrom),
      "yyyy-MM-dd"
    )}T00:00:00`;
  } else {
    dateFrom = "";
  }
  let status;
  if (filters.status && filters.status !== ("All" as AppointmentStatuses)) {
    status = `&status=${AppointmentStatusesForApi[filters.status]}`;
  } else {
    status = "";
  }
  let doctorId;
  if (filters.doctorId) {
    doctorId = `&doctorId=${filters.doctorId}`;
  } else {
    doctorId = "";
  }
  let clinicIdFilter;
  if (clinicId && clinicId !== allClinicsItemId) {
    clinicIdFilter = `&clinicId=${clinicId}`;
  } else {
    clinicIdFilter = "";
  }

  const urlByClient = `${
    process.env.REACT_APP_API_HOST
  }/api/schedule/excel/client?sortOrder=${
    SortingOrdersForApi[SortingOrders.Descending]
  }&sortField=${
    AppointmentSortFieldsForApi[filters.sortField]
  }${clinicIdFilter}${status}${dateFrom}${dateTo}${doctorId}`;

  const urlByDoctor = `${
    process.env.REACT_APP_API_HOST
  }/api/schedule/excel/doctor?sortOrder=${
    SortingOrdersForApi[SortingOrders.Descending]
  }${clinicIdFilter}${dateFrom}${dateTo}${status}&sortField=${
    AppointmentSortFieldsForApi[filters.sortField]
  }`;

  return isDoctor ? urlByDoctor : urlByClient;
};

export const getDaysOfWeek = (lang: string) => {
  const today = new Date();
  let startDate;

  if (lang === "en") {
    startDate = startOfWeek(today, {
      locale: getFNSLocaleByLang(lang),
      weekStartsOn: 0,
    });
  } else {
    startDate = startOfWeek(today, {
      locale: getFNSLocaleByLang(lang),
      weekStartsOn: 1,
    });
  }

  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6);

  const daysOfWeek = eachDayOfInterval({ start: startDate, end: endDate });

  return daysOfWeek;
};

export const getDateFromScheduleAppointmentDate = (
  date: any,
  minutes: number
) => {
  const originalDate = new Date(date);
  const newDate = new Date(
    originalDate.getTime() + (minutes as number) * 60000
  );
  const formattedDate = getDateWithoutTimeZoneOffset(newDate.toISOString());
  const scheduleAppointmentDate = new Date(formattedDate);

  return scheduleAppointmentDate;
};

export const getDateFromScheduleAppointment = (
  date: any,
  minutes: number,
  i18n: Ti18n
) => {
  const scheduleAppointmentDate = getDateFromScheduleAppointmentDate(
    date,
    minutes
  );

  return getFormatDateByLocale(
    scheduleAppointmentDate,
    i18n?.resolvedLanguage,
    i18n?.resolvedLanguage === ESupportedLangs.en
      ? "d MMM yyyy 'at' h:mm a"
      : "d MMM yyyy HH:mm"
  );
};

export const startAccessor = (event: IEvent) => {
  if (event && event.start) {
    return event.start;
  }
  return new Date();
};

export const endAccessor = (event: IEvent) => {
  if (event && event.end) {
    return event.end;
  }
  return new Date();
};

export const getFilteredNamesOfAllActiveQueries = (
  queryNamesToFilter: string[]
) => {
  const activeQueries = client.getObservableQueries();

  const queryKeysToRefetch = Array.from(activeQueries.keys());

  const filteredQueryNames = queryKeysToRefetch.reduce((acc, queryKey) => {
    const query = activeQueries.get(queryKey)?.query;

    if (query) {
      const queryAsString = print(query) as string;
      const parsedQuery = parseGQL(queryAsString) as DocumentNode;

      const operationDefinition = parsedQuery.definitions.find(
        (definition) =>
          definition.kind === "OperationDefinition" &&
          (definition as OperationDefinitionNode).name
      ) as OperationDefinitionNode | undefined;

      if (operationDefinition && operationDefinition.name) {
        const queryName = operationDefinition.name.value;

        // Add the name to the resulting array if it is not included in the excluded list
        if (!queryNamesToFilter.includes(queryName)) {
          acc.push(queryName);
        }
      }
    }

    return acc;
  }, [] as string[]);

  return filteredQueryNames;
};

export const generateColor = (() => {
  const usedColors: {
    [doctorId: string]: { softColor: string; brightColor: string };
  } = {};

  let minHue = 0; // Initial minimum hue value
  let maxHue = 60; // Initial maximum hue value

  // Recursive function to generate a unique color
  const generateUniqueColor = (
    doctorId: string
  ): { softColor: string; brightColor: string } => {
    // Increase minHue and maxHue by 60 and reset minHue to 0 if maxHue exceeds 360
    maxHue += 60;
    if (maxHue === 360) {
      minHue = 0;
      maxHue = 60;
    } else {
      minHue += 60;
    }

    // Generate a random hue within the current range
    const randomHue = minHue + Math.random() * (maxHue - minHue);

    // Generate soft and bright colors using the random hue
    const nextSoftColor = `hsl(${randomHue}, 100%, 92%)`;
    const nextBrightColor = `hsl(${randomHue}, 100%, 45%)`;

    // Save the generated colors for the current doctorId
    usedColors[doctorId] = {
      softColor: nextSoftColor,
      brightColor: nextBrightColor,
    };

    // Return the generated colors
    return usedColors[doctorId];
  };

  return (doctorId: string) => {
    if (!usedColors[doctorId]) {
      // If colors are not generated for this doctorId, generate them
      return generateUniqueColor(doctorId);
    }

    // Return the previously generated colors for this doctorId
    return usedColors[doctorId];
  };
})();

export const isToday = (currentDate: Date) => {
  const today = new Date();
  return (
    today.getFullYear() === currentDate.getFullYear() &&
    today.getMonth() === currentDate.getMonth() &&
    today.getDate() === currentDate.getDate()
  );
};

export const getTimeRange = (
  range: { fromMinute: number; toMinute: number } | null
) => {
  const minMinuteCount = range?.fromMinute;
  const maxToMinute = range?.toMinute;

  const minTime = getDateFromMinutesStartDay(minMinuteCount) as Date;
  const maxTime = getDateFromMinutesStartDay(maxToMinute) as Date;

  const roundDownToNearHour = minTime?.setMinutes(0);
  const roundUpToNearHour = new Date(
    maxTime?.setHours(maxTime?.getHours() + 1, 0, 0, 0)
  ).setMilliseconds(maxTime?.getMilliseconds() - 1);

  const timeGutterStart = new Date(roundDownToNearHour);
  const timeGutterEnd = new Date(roundUpToNearHour);

  if (!range) {
    return null;
  }

  return { start: timeGutterStart, end: timeGutterEnd };
};

export const isValidRange = (min: Date | null, max: Date | null) => {
  return (
    min && !Number.isNaN(min?.getTime()) && max && !Number.isNaN(max?.getTime())
  );
};

export function getWeekTimeRange(
  workingDays: ClinicWorkingDayEntityResponse[] | null | undefined
) {
  const weekTimeRange =
    workingDays?.reduce<{
      fromMinute: number;
      toMinute: number;
    } | null>((acc, day, index) => {
      if (index === 0) {
        return {
          fromMinute: day.fromMinute,
          toMinute: day.toMinute,
        };
      }
      return acc
        ? {
            fromMinute: Math.min(acc.fromMinute, day.fromMinute),
            toMinute: Math.max(acc.toMinute, day.toMinute),
          }
        : acc;
    }, null) || null;

  return weekTimeRange;
}
export function getDayTimeRange(
  workingDays: ClinicWorkingDayEntityResponse[] | null | undefined,
  dateFrom: string // dateIso
) {
  const dayTimeRange =
    workingDays?.find(
      (schedule) => schedule.day === format(new Date(dateFrom), "EEEE")
    ) || null;

  return dayTimeRange;
}
