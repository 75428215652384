import { gql } from "@apollo/client";

export const GET_COMPANY_BY_USER_ID = gql`
  query FindCompanyByUserId {
    findCompanyByUserId {
      address
      city
      countryId
      createdAt
      dateOfRegistrationInKrs
      email
      id
      name
      nip
      ownerId
      postCode
      regon
      street
    }
  }
`;
