import React from "react";
import { Route, Routes } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { TabsView } from "src/views/BookingsViews";

import { EButtonVariants } from "src/UI/Button";
import { PlusIcon } from "src/UI/Svg";
import { useAppAction } from "src/store/app/hooks";
import { UserRoles } from "src/graphql/generated";
import { PATHS } from "src/constants/constants";
import { getFilteredLinksByRole } from "src/utils";
import { ProtectedRoute, ProtectedRouteTypes } from "src/router/protectedRoute";
import { useAuthContext } from "src/context/AuthContext";
import PatientsPage from "./PatientsPage";
import FormPage from "./FormPage/FormPage";
import { ButtonStyled, TabsWrapper } from "./styled";

const getlinks = (isDoctor: boolean) =>
  isDoctor
    ? [
        {
          title: "forms.tabs.patients_submissions",
          link: "/cp/forms",
          roles: [UserRoles.Doctor, UserRoles.Client],
        },
      ]
    : [
        {
          title: "forms.tabs.forms",
          link: "/cp/forms",
          roles: [UserRoles.Client],
        },
        {
          title: "forms.tabs.patients_submissions",
          link: "/cp/forms/patients",
          roles: [UserRoles.Doctor, UserRoles.Client],
        },
      ];

const getRoutes = (isDoctor: boolean) =>
  isDoctor
    ? [
        {
          path: PATHS.home,
          element: () => <PatientsPage />,
          roles: [UserRoles.Doctor, UserRoles.Client],
        },
      ]
    : [
        {
          path: PATHS.home,
          element: () => <FormPage />,
          roles: [UserRoles.Client],
        },
        {
          path: PATHS.patients,
          element: () => <PatientsPage />,
          roles: [UserRoles.Doctor, UserRoles.Client],
        },
      ];

function Index() {
  const { t } = useTranslation();
  const { onShowCreateEditForm } = useAppAction();
  const { isDoctor } = useAuthContext();

  const handlePreviewClick = () => {
    onShowCreateEditForm({});
  };

  return (
    <>
      <TabsWrapper>
        <TabsView links={getFilteredLinksByRole(getlinks(isDoctor))} />
        {!isDoctor ? (
          <ButtonStyled
            onClick={handlePreviewClick}
            variant={EButtonVariants.Default}
          >
            <PlusIcon />
            {t("forms.button")}
          </ButtonStyled>
        ) : null}
      </TabsWrapper>
      <Routes>
        {getRoutes(isDoctor).map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<ProtectedRoute {...(route as ProtectedRouteTypes)} />}
          />
        ))}
      </Routes>
    </>
  );
}

export default Index;
