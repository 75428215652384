import { useEffect } from "react";
import { toast } from "react-toastify";
import { allClinicsItemId } from "src/constants/constants";
import { useFindClinicByIdLazyQuery } from "src/graphql/generated";
import { useCalendarAction, useCalendarState } from "src/store/calendar/hooks";
import { useClinicsState } from "src/store/clinics/hooks";
import {
  getDayTimeRange,
  getTimeRange,
  getWeekTimeRange,
  isToday,
  isValidRange,
} from "src/utils";

export function useSetTimeRange() {
  const { currentClinicId: clinicId } = useClinicsState();
  const { filters } = useCalendarState();
  const { onSetDayTimeRange, onSetWeekTimeRange } = useCalendarAction();

  const [getClinicById, { loading }] = useFindClinicByIdLazyQuery();

  useEffect(() => {
    if (clinicId && clinicId !== allClinicsItemId) {
      getClinicById({
        variables: { schema: { id: clinicId } },
        onCompleted: (response) => {
          if (response?.findClinicById) {
            const { workingDays } = response.findClinicById;

            const weekTimeRange = getWeekTimeRange(workingDays);
            const dayTimeRange = getDayTimeRange(workingDays, filters.dateFrom);

            const dayRange = getTimeRange(dayTimeRange);
            const weekRange = getTimeRange(weekTimeRange);

            const now = new Date();

            const today = isToday(new Date(filters.dateFrom));

            const minDayRange = () => {
              if (!dayRange) {
                return null;
              }
              if (today && now > dayRange.start) {
                return new Date(now.setMinutes(0, 0, 0));
              }
              return dayRange.start;
            };

            const maxDayRange = () => {
              if (!dayRange) {
                return null;
              }
              if (today && now > dayRange.end) {
                return null;
              }
              return dayRange.end;
            };

            const minWeekRange = weekRange?.start || null;
            const maxWeekRange = weekRange?.end || null;

            const isValidDayRange = isValidRange(minDayRange(), maxDayRange());
            const isValidWeekRange = isValidRange(minWeekRange, maxWeekRange);

            if (isValidDayRange) {
              onSetDayTimeRange({
                min: minDayRange() as Date,
                max: maxDayRange() as Date,
              });
            } else {
              onSetDayTimeRange(null);
            }
            if (isValidWeekRange) {
              onSetWeekTimeRange({
                min: minWeekRange as Date,
                max: maxWeekRange as Date,
              });
            } else {
              onSetWeekTimeRange(null);
            }
          }
        },
        onError: (error) => {
          if (error) toast.error(error.message);
        },
      });
    }
  }, [clinicId, filters?.dateFrom, filters?.dateTo]);

  return {
    loading,
  };
}
