import styled from "styled-components";
import { CalendarView } from "src/views/CalendarViews";
import { CalendarDetailModal, DeleteEventModal } from "src/components/Modals";
import { useCalendarScheduleAppointmentsByRole } from "src/hooks/calendar/useCalendarScheduleAppointmentsByRole";

const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.background};
`;

function CalendarPage() {
  const { events } = useCalendarScheduleAppointmentsByRole();

  return (
    <>
      <CalendarDetailModal />
      <DeleteEventModal />
      <Wrapper>
        <CalendarView eventsArray={events} />
      </Wrapper>
      {/* Temporary */}
      {/* <HeaderView /> */}
      {/* <WrapperOverflow ref={wrapper}>
          {selectedDuration === EDuration.Day && (
           <DayView date={new Date()} data={mockDay} wrapper={wrapper} />
          )}
          {selectedDuration === EDuration.Week && <WeekView />}
          {selectedDuration === EDuration.Month && <MonthView />}
          {selectedDuration === EDuration.Year && <YearView />}
        </WrapperOverflow> */}
    </>
  );
}

export default CalendarPage;
