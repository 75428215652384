import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { allClinicsItemId } from "src/constants/constants";
import { useAuthContext } from "src/context/AuthContext";
import {
  LanguageIsoCodes,
  Maybe,
  useFindAndCountManyDoctorsByClinicIdQuery,
  useFindManyDoctorTypesByLanguageIsoCodeLazyQuery,
  useFindManyFilesByIdsLazyQuery,
} from "src/graphql/generated";
import { useClinicsState } from "src/store/clinics/hooks";
import { IDoctorsAgregated } from "src/types";

export function useGetDoctorsAgregated() {
  const { i18n } = useTranslation();
  const { currentClinicId: clinicId } = useClinicsState();
  const { isDoctor, currentUserData } = useAuthContext();
  const [doctorsList, setDoctorsList] = useState<IDoctorsAgregated[]>([]);

  const [getFiles, { loading: getFilesLoading }] =
    useFindManyFilesByIdsLazyQuery();
  const [findDoctorTypesByLocaleLazyQuery, { loading: getDoctorTypesLoading }] =
    useFindManyDoctorTypesByLanguageIsoCodeLazyQuery();

  const { loading: getDoctorsLoading } =
    useFindAndCountManyDoctorsByClinicIdQuery({
      variables: {
        schema: {
          clinicId,
        },
      },
      onCompleted: (response) => {
        if (response?.findAndCountManyDoctorsByClinicId) {
          const { doctors } = response?.findAndCountManyDoctorsByClinicId;
          const doctorsArray = doctors.map((doctor) => ({
            doctorEntity: doctor,
          }));
          setDoctorsList(doctorsArray);
        }
      },
      onError: (error) => {
        if (error) {
          toast.error(error.message);
        }
      },
      fetchPolicy: "network-only",
      skip:
        !clinicId ||
        !!isDoctor ||
        clinicId === allClinicsItemId ||
        !currentUserData,
    });

  useEffect(() => {
    const doctorsPhotoIds = doctorsList?.reduce(
      (accumulator: string[], doctor) => {
        const avatarFileId: Maybe<string> | undefined =
          doctor?.doctorEntity?.avatarFileId;

        if (avatarFileId && !accumulator.includes(avatarFileId)) {
          accumulator.push(avatarFileId);
        }

        return accumulator;
      },
      []
    );
    if (!!doctorsList.length && !!doctorsPhotoIds.length) {
      getFiles({
        variables: {
          schema: {
            ids: doctorsPhotoIds,
          },
        },
        onCompleted: (filesResponse) => {
          if (filesResponse?.findManyFilesByIds) {
            setDoctorsList((prev) =>
              prev.map((doctor) => {
                const { files } = filesResponse.findManyFilesByIds;
                const doctorFile = files.find(
                  (file) => file.id === doctor.doctorEntity.avatarFileId
                );

                return {
                  ...doctor,
                  avatarUrl: doctorFile?.url || null,
                };
              })
            );
          }
        },
        fetchPolicy: "network-only",
      });
    }
  }, [doctorsList.length, clinicId]);

  useEffect(() => {
    if (i18n && !!doctorsList.length) {
      findDoctorTypesByLocaleLazyQuery({
        variables: {
          schema: {
            languageISOCode: i18n.language.toUpperCase() as LanguageIsoCodes,
          },
        },
        onCompleted: (typesResponse) => {
          if (typesResponse?.findManyDoctorTypesByLanguageISOCode) {
            const { doctorTypes } =
              typesResponse.findManyDoctorTypesByLanguageISOCode;

            setDoctorsList((prev) =>
              prev?.map((doctor) => {
                const doctorType = doctorTypes.find(
                  (file) => file.id === doctor.doctorEntity.doctorTypeId
                );

                return {
                  ...doctor,
                  doctorType: doctorType?.doctorTypeTranslations?.[0]?.name,
                };
              })
            );
          }
        },
        fetchPolicy: "network-only",
      });
    }
  }, [i18n.language, doctorsList.length, clinicId]);

  return {
    doctorsList,
    loading: getDoctorsLoading || getFilesLoading || getDoctorTypesLoading,
  };
}
