import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EModals } from "src/store/app/types";
import { Modal } from "src/UI/Modal";
import { useAppAction } from "src/store/app/hooks";
import { EButtonVariants } from "src/UI/Button";
import { FormProvider, useForm } from "react-hook-form";
import { InputComponent } from "src/components/AuthComponents";
import { yupResolver } from "@hookform/resolvers/yup";
import { passwordRecoveryValidationSchema } from "src/validation/schemas/passwordRecoveryValidationSchema";
import Cookies from "js-cookie";
import { UserEntityResponse } from "src/graphql/generated";
import {
  JWT,
  PATHS,
  RELOGIN_BY_PASS_SELECTION,
  RESET_PIN_ACTION,
} from "src/constants/constants";
import { useAuthorizeWithPassword } from "src/graphql/hooks/useMutations";
import { toast } from "react-toastify";
import { useClinicsAction } from "src/store/clinics/hooks";
import { Loading } from "src/UI/Loading";
import { useAuthContext } from "src/context/AuthContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ActionBlock,
  ButtonStyled,
  Content,
  ContentWrapStyled,
  Form,
} from "./styles";

interface FormValues {
  pass: string;
}

function PassForPinModal() {
  const { onConfirmOpen } = useAppAction();
  const { onSetClinicId, onSetLocation } = useClinicsAction();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const actionType = queryParams.get("actionType");

  const isResetPinAction = actionType === RESET_PIN_ACTION;
  const isReloginFromPinModalSelection =
    actionType === RELOGIN_BY_PASS_SELECTION;

  const { logout, refetchUser } = useAuthContext();
  const navigate = useNavigate();
  const { userId } = useParams();

  const { t } = useTranslation();

  const methods = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(passwordRecoveryValidationSchema),
    defaultValues: {
      pass: "",
    },
  });

  const {
    watch,
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = methods;

  const [selectedProfile, setSelectedProfile] = useState<string>("");
  const [currentUserJWT, setCurrentUserJWT] = useState<string>("");

  const cookieJWT = Cookies.get(JWT);
  const profiles = JSON.parse(localStorage.getItem("users") as string);

  useEffect(() => {
    setCurrentUserJWT(cookieJWT || "");
  }, []);

  const jwtStorage = profiles?.find(
    (profile: UserEntityResponse) => profile?.id === selectedProfile
  )?.jwtToken;

  const [useAuthorizationWithPass, { loading }] = useAuthorizeWithPassword({
    onCompleted: (response) => {
      if (response) {
        localStorage.removeItem("currentClinicId");
        onSetClinicId("");
        onSetLocation(null);
        refetchUser();
        toast.success(t("app.successful"));
        if (isResetPinAction) {
          navigate(`${PATHS.cp}${PATHS.clinic}`);
        } else if (isReloginFromPinModalSelection) {
          navigate(-2);
        } else {
          navigate(-1);
        }
      }

      reset();
    },
    onError: (error) => {
      if (error) {
        Cookies.set(JWT, currentUserJWT as string);
        toast.error(error.message);

        if (error.message === "Invalid JSON Web Token") {
          onConfirmOpen({
            title: `Error ${error.message}`,
            text: t("app.expired_token_message") as string,
            accept: () => {
              logout();
            },
          });
        }
      }
    },
  });

  useEffect(() => {
    if (userId) setSelectedProfile(userId);
  }, [userId]);

  const data = watch();

  const onCancel = () => {
    navigate(-1);
  };

  const onSubmit = (formData: FormValues) => {
    Cookies.set(JWT, jwtStorage as string);
    useAuthorizationWithPass({
      variables: { schema: { password: formData.pass } },
    });
  };

  return (
    <Modal
      modal={EModals.PassForPin}
      title={t("profile.pass_modal.title")}
      onCloseAction={onCancel}
    >
      <Content>
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ContentWrapStyled>
              <InputComponent
                placeholder={t("auth.login.password")}
                inputType="password"
                val={data.pass}
                errorMessage={errors.pass}
                register={register("pass")}
              />
            </ContentWrapStyled>
            <ActionBlock>
              <ButtonStyled
                onClick={onCancel}
                variant={EButtonVariants.Text}
                type="button"
              >
                {t("common.cancel")}
              </ButtonStyled>
              <ButtonStyled
                type="submit"
                disabled={!isValid || loading}
                variant={EButtonVariants.Default}
              >
                {loading ? <Loading type="secondary" padding="0" /> : null}
                {t("common.continue")}
              </ButtonStyled>
            </ActionBlock>
          </Form>
        </FormProvider>
      </Content>
    </Modal>
  );
}

export default PassForPinModal;
