import { ChangeEvent, FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CameraIcon, DoctorIcon } from "src/UI/Svg";

import { useFindManyFilesByIds } from "src/graphql/hooks/useQueries";
import { isValidaImageFile } from "src/utils";
import { Loading } from "src/UI/Loading";
import { ImageStyled, UserPhoto, UserPhotoHover } from "./styles";

interface ComponentProps {
  value: string | null;
  onChange: (val: File | null) => void;
  loading: boolean;
}

const DoctorPhotoComponent: FC<ComponentProps> = ({
  value,
  onChange,
  loading,
}) => {
  const { t } = useTranslation();
  const [preview, setPreview] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { loading: logoLoading } = useFindManyFilesByIds({
    variables: {
      schema: {
        ids: [value],
      },
    },
    onCompleted: (response) => {
      const logoUrl = response?.findManyFilesByIds?.files?.[0]?.url;

      if (logoUrl) setPreview(logoUrl as string);
    },
    skip: !value,
  });

  const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      onChange(null);
      return;
    }
    const objectUrl: string = URL.createObjectURL(e.target.files[0]);

    if (isValidaImageFile(e.target.files[0], t)) {
      setPreview(objectUrl);
      onChange(e.target.files[0]);
    }
  };

  return (
    <UserPhoto>
      {loading || logoLoading ? (
        <Loading padding="0" />
      ) : preview ? (
        <ImageStyled src={preview} alt="#" />
      ) : (
        <DoctorIcon />
      )}

      <UserPhotoHover>
        <CameraIcon />
        {t("add_doctor.label.photo") as string}
        <input type="file" ref={inputRef} onChange={onSelectFile} />
      </UserPhotoHover>
    </UserPhoto>
  );
};

export default DoctorPhotoComponent;
