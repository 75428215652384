import { FieldError, FieldPath, useFormContext } from "react-hook-form";
import { DistributionActions, LanguageIsoCodes } from "src/graphql/generated";
import {
  FormSmsTemplate,
  FormMailTemplate,
  ITemplateCreateEdit,
} from "src/types/templates";

type TFieldName = keyof FormMailTemplate | keyof FormSmsTemplate;

export const useTemplateFormSelected = (selectedLang: LanguageIsoCodes) => {
  const {
    watch,
    formState: { errors },
  } = useFormContext<ITemplateCreateEdit>();

  const [selectedAction, selectedEvent] = watch([
    "selectedAction",
    "selectedEvent",
  ]);

  const templateName =
    selectedAction === DistributionActions.SendMail
      ? "mailTemplates"
      : "smsTemplates";

  const selectedTemplateByAction = watch(templateName) as (
    | FormMailTemplate
    | FormSmsTemplate
  )[];

  const selectedTemplateIndex = selectedTemplateByAction?.findIndex(
    (template: FormMailTemplate | FormSmsTemplate) =>
      template.languageISOCode === selectedLang
  );

  const selectedTemplate =
    selectedTemplateByAction && selectedTemplateIndex !== -1
      ? selectedTemplateByAction[selectedTemplateIndex]
      : null;

  const getSelectedTemplateFieldName = (
    name: TFieldName
  ): FieldPath<ITemplateCreateEdit> | null => {
    if (!templateName || selectedTemplateIndex === -1) return null;

    if (name && templateName === "mailTemplates") {
      return `${templateName}.${selectedTemplateIndex}.${name}`;
    }

    if (name && name !== "subject")
      return `${templateName}.${selectedTemplateIndex}.${name}`;

    return `${templateName}.${selectedTemplateIndex}`;
  };

  const getSelectedTemplateError = (name: TFieldName) => {
    if (!templateName || selectedTemplateIndex === -1) return;

    if (name && templateName === "mailTemplates") {
      return errors[templateName]?.[selectedTemplateIndex]?.[
        name
      ] as FieldError;
    }

    if (name && name !== "subject") {
      return errors[templateName]?.[selectedTemplateIndex]?.[
        name
      ] as FieldError;
    }
  };

  return {
    selectedLang,
    selectedEvent,
    selectedAction,
    selectedTemplate,
    getSelectedTemplateError,
    getSelectedTemplateFieldName,
  };
};
