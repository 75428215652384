import { useState } from "react";
import {
  Calendar,
  EventPropGetter,
  View,
  Views,
  dateFnsLocalizer,
} from "react-big-calendar";
import { useTranslation } from "react-i18next";
import { endOfDay, format, formatISO, startOfDay, startOfWeek } from "date-fns";
import getDay from "date-fns/getDay";
import { EventDayComponent } from "src/components/CalendarComponents";
import { IEvent } from "src/pages/DashboardPages/CalendarPage/types";
import { endAccessor, startAccessor } from "src/utils";
import { calendarLocales, views } from "src/constants/constants";
import { useCalendarAction, useCalendarState } from "src/store/calendar/hooks";
import { useSetTimeRange } from "src/hooks/calendar/useSetTimeRange";
import { TScheduleAppointmentByRole } from "src/types";
import { useAppAction } from "src/store/app/hooks";
import ToolbarComponent from "../ToolbarComponent/ToolbarComponent";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Wrapper } from "./styles";

interface ICalendarView {
  eventsArray: IEvent[];
}

type TRangeArray = Date[];
type TRangeObject = { start: Date; end: Date };

function CalendarView({ eventsArray = [] }: ICalendarView) {
  const { i18n } = useTranslation();
  const { filters } = useCalendarState();
  const { onSetFilters } = useCalendarAction();
  const { onShowScheduleAppointmentModal } = useAppAction();
  const [currentView, setCurrentView] = useState<View>(Views.DAY);

  useSetTimeRange();

  const dateFrom = new Date(filters?.dateFrom);

  const weekStartsOn = getDay(dateFrom);
  const localizerFNS = dateFnsLocalizer({
    format,
    startOfWeek: (date: Date) => startOfWeek(date, { weekStartsOn }),
    startOf: dateFrom,
    getDay,
    locales: calendarLocales,
  });

  const isEngLang = i18n.resolvedLanguage === "en";

  const handleViewChange = (view: View) => {
    setCurrentView(view);
  };

  const getViewClassName = () => `${currentView}-view`;

  const dayPropGetter = (date: Date) => {
    const dayOfWeek = date.getDay();
    const isWeekendDay = dayOfWeek === 0 || dayOfWeek === 6;

    if (isWeekendDay) {
      return {
        className: "custom-weekend-class",
      };
    }

    return {};
  };

  const eventStyleGetter: EventPropGetter<IEvent> = (event) => {
    return {
      style: {
        backgroundColor: event.color?.softColor,
      },
    };
  };

  const handleChangeRange = (range: TRangeArray | TRangeObject) => {
    onSetFilters({
      dateFrom: formatISO(startOfDay((range as TRangeArray)?.[0])),
    });
    onSetFilters({
      dateTo: formatISO(
        endOfDay((range as TRangeArray)?.[(range as TRangeArray).length - 1])
      ),
    });
  };

  const handleSelectEvent = (event: IEvent) => {
    onShowScheduleAppointmentModal({
      data: {
        appointment: event.eventData as TScheduleAppointmentByRole,
      },
    });
  };

  const handleShowMore = () => {
    setCurrentView(Views.DAY);
  };

  const components = {
    toolbar: ToolbarComponent,
    day: {
      event: EventDayComponent,
    },
    week: {
      event: EventDayComponent,
    },
  };

  return (
    <Wrapper isEngLang={isEngLang}>
      <Calendar
        components={components}
        localizer={localizerFNS}
        events={eventsArray}
        startAccessor={startAccessor}
        endAccessor={endAccessor}
        culture={i18n.resolvedLanguage}
        views={views}
        onView={handleViewChange}
        view={currentView}
        className={getViewClassName()}
        dayPropGetter={dayPropGetter}
        eventPropGetter={eventStyleGetter}
        onSelectEvent={handleSelectEvent}
        onRangeChange={handleChangeRange}
        defaultDate={dateFrom}
        onShowMore={handleShowMore}
      />
    </Wrapper>
  );
}

export default CalendarView;
