import { gql } from "@apollo/client";

export const FIND_AND_COUNT_MANY_BLANK_FORMS_BY_CLIENT = gql`
  query FindAndCountManyBlankFormsByClient(
    $schema: FindAndCountManyFormsByClientSchema!
  ) {
    findAndCountManyBlankFormsByClient(schema: $schema) {
      forms {
        id
        name
        serviceId
        clinicId
        countAnswers
        updatedAt
      }
      count
    }
  }
`;
