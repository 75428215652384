import { useTranslation } from "react-i18next";
import { getFormatDateByLocale } from "src/utils";
import { DateItem, DateWrapper } from "./styled";

interface IPatientAppointmentsDateProps {
  date: Date | string;
}

export function PatientAppointmentsDate({
  date,
}: IPatientAppointmentsDateProps) {
  const { i18n } = useTranslation();
  const dateValue = new Date(date);

  const datePart = getFormatDateByLocale(dateValue, "d MMM yyyy");
  const timePart = getFormatDateByLocale(
    dateValue,
    i18n?.resolvedLanguage,
    i18n?.resolvedLanguage !== "pl" ? "'at' h:mm a" : " HH:mm"
  );

  return (
    <DateWrapper>
      <DateItem>{datePart}</DateItem>
      <DateItem>{timePart}</DateItem>
    </DateWrapper>
  );
}
