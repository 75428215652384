import React, { FunctionComponent } from "react";
import { Svg, SvgProps } from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
  <Svg width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
    <path
      d="M14.666 2.66669C13.9588 2.66669 13.2805 2.94764 12.7804 3.44774C12.2803 3.94783 11.9993 4.62611 11.9993 5.33335V12H5.33268C4.62544 12 3.94716 12.281 3.44706 12.7811C2.94697 13.2812 2.66602 13.9594 2.66602 14.6667V17.3334C2.66602 18.8 3.86602 20 5.33268 20H11.9993V26.6667C11.9993 28.1334 13.1993 29.3334 14.666 29.3334H17.3327C18.0399 29.3334 18.7182 29.0524 19.2183 28.5523C19.7184 28.0522 19.9993 27.3739 19.9993 26.6667V20H26.666C27.3733 20 28.0515 19.7191 28.5516 19.219C29.0517 18.7189 29.3327 18.0406 29.3327 17.3334V14.6667C29.3327 13.9594 29.0517 13.2812 28.5516 12.7811C28.0515 12.281 27.3733 12 26.666 12H19.9993V5.33335C19.9993 4.62611 19.7184 3.94783 19.2183 3.44774C18.7182 2.94764 18.0399 2.66669 17.3327 2.66669H14.666Z"
      stroke="#C4C4C4"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Icon;
