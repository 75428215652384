import { FeatureCollection } from "src/components/Map/types";
import {
  ClinicEntityResponse,
  CountedClinicsResponse,
  useFindManyFilesByIdsLazyQuery,
} from "src/graphql/generated";
import { useEffect, useState } from "react";
import { useFindAndCountManyClinicsByClientId } from "src/graphql/hooks/useQueries";
import { Route, Routes, useLocation } from "react-router-dom";
import { useBookingAction, useBookingState } from "src/store/booking/hooks";
import Map from "src/components/Map/Map";
import { PATHS, defaultClinicsLimit } from "src/constants/constants";
import { breakpointMap } from "src/theme/base";
import { useMediaQuery } from "src/hooks/useMediaQuery";
import ClinicsPage from "./ClinicsPage/ClinicsPage";
import { MapStyled } from "./styled";
import NotFoundPage from "../DashboardPages/NotFoundPage";
import StepsPage from "./StepsPage/StepsPage";
import ServicesPage from "./SevicesPage/ServicesPage";
import ConfirmAppointmentPage from "./ConfirmAppointmentPage/ConfirmAppointmentPage";
import ConfirmedAppointmentPage from "./ConfirmedAppointmentPage/ConfirmedAppointmentPage";

function MapPage() {
  const isTablet = useMediaQuery(breakpointMap.md);
  const { pathname } = useLocation();
  const [data, setData] = useState<CountedClinicsResponse | null>(null);
  const { currentLocation, currentClinic } = useBookingState();
  const { onSetLocation, onSetClinic } = useBookingAction();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const clientId = queryParams.get("clientId");

  const [getLogo] = useFindManyFilesByIdsLazyQuery();

  const { loading, fetchMore } = useFindAndCountManyClinicsByClientId({
    variables: {
      schema: {
        clientId,
        limit: defaultClinicsLimit,
        offset: 0,
      },
    },
    onCompleted: (response) => {
      setData(response?.findAndCountManyClinicsByClientId);
    },
    skip: !clientId,
  });

  const clinics = data?.clinics;

  const locations = clinics?.map((marker: ClinicEntityResponse) => {
    return {
      type: "Feature",
      properties: {
        icon: "default",
        title: marker.name,
      },
      geometry: {
        coordinates: [
          marker.geographicalCoordinates.longitude,
          marker.geographicalCoordinates.latitude,
        ] as [number, number],
        type: "Point",
      },
      id: marker.id,
    };
  });

  const markers: FeatureCollection = {
    features: locations || [],
    type: "FeatureCollection",
  };

  useEffect(() => {
    if (currentClinic?.clinic?.logoFileId)
      getLogo({
        variables: {
          schema: {
            ids: [currentClinic.clinic.logoFileId as string],
          },
        },
        onCompleted(response) {
          onSetClinic({
            clinic: currentClinic?.clinic,
            logoUrl: response?.findManyFilesByIds?.files?.[0]?.url,
          });
        },
      });
  }, [currentLocation]);

  useEffect(() => {
    if (clinics?.length && !currentLocation) {
      onSetClinic({
        clinic: clinics?.[0],
        logoUrl: null,
      });
      onSetLocation({
        center: [
          clinics?.[0]?.geographicalCoordinates?.longitude,
          clinics?.[0]?.geographicalCoordinates?.latitude,
        ],
      });
    }
  }, [clinics]);

  const handleSelectMarker = (id: string) => {
    const selectedClinic = clinics?.find(
      (clinic: ClinicEntityResponse) => clinic?.id === id
    );

    onSetClinic({
      clinic: selectedClinic as ClinicEntityResponse,
      logoUrl: null,
    });
    onSetLocation({
      center: [
        selectedClinic?.geographicalCoordinates?.longitude as number,
        selectedClinic?.geographicalCoordinates?.latitude as number,
      ],
    });
  };

  const isClinicsPage = pathname?.includes(PATHS.clinics);

  return (
    <>
      {!isTablet && (
        <MapStyled isClinicsPage={!!isClinicsPage}>
          <Map
            markers={markers}
            currentLocation={currentLocation}
            onSelectMarker={handleSelectMarker}
          />
        </MapStyled>
      )}
      <Routes>
        <Route
          path={`${PATHS.clinics}`}
          element={
            <ClinicsPage loading={loading} fetchMore={fetchMore} data={data} />
          }
        />
        <Route path={`${PATHS.steps}`} element={<StepsPage />} />
        <Route path={`${PATHS.services}`} element={<ServicesPage />} />
        <Route
          path={`${PATHS.detailsAppointment}`}
          element={<ConfirmAppointmentPage />}
        />

        <Route
          path={`${PATHS.confirmAppointment}`}
          element={<ConfirmAppointmentPage />}
        />
        <Route
          path={`${PATHS.confirmedAppointment}`}
          element={<ConfirmedAppointmentPage />}
        />
        <Route key="NotFound" path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
}
export default MapPage;
