import { PATHS } from "src/constants/constants";
import AuthPage from "src/pages/AuthPages";
import LoginPage from "src/pages/AuthPages/LoginPage";
import DashboardPages from "src/pages/DashboardPages";
import Booking from "src/pages/PatientBookingPages";
import VerifyPage from "src/pages/VerifyPages";

export const routes = [
  {
    path: `${PATHS.cp}/*`,
    element: DashboardPages,
  },
  {
    path: `${PATHS.vr}/*`,
    element: VerifyPage,
  },
  {
    path: `${PATHS.auth}/*`,
    element: AuthPage,
  },
  {
    path: `${PATHS.patientBooking}/*`,
    element: Booking,
  },
  {
    path: PATHS.home,
    element: LoginPage,
  },
];
