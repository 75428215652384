import { FieldError, useFormContext } from "react-hook-form";
import { parse } from "date-fns";
import { InputAutosizeRHF, InputRHF, PhoneInputSmallRHF } from "src/UI/Input";
import {
  FormFieldEntityWithRelationsResponse,
  FormFieldTypes,
} from "src/graphql/generated";
import { FormItemComponentTime } from "./FormItemComponentTime";
import { FormItemComponentCheckbox } from "./FormItemComponentCheckbox";
import { FormItemComponentDate } from "./FormItemComponentDate";

interface IFormItemComponentFieldByTypeProps {
  field: FormFieldEntityWithRelationsResponse;
  placeholder: string;
  fieldName: string;
  isPreview?: boolean;
}

export function FormItemComponentFieldByType({
  field: { type, ...field },
  isPreview,
  placeholder,
  fieldName,
}: IFormItemComponentFieldByTypeProps) {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  const fields = watch("fields");

  switch (type) {
    case FormFieldTypes.Text:
      return (
        <InputRHF
          val={fields[field.order].fillValue || ""}
          label={placeholder}
          disabled={isPreview}
          inputType="text"
          errorMessage={(errors.fields as any)?.[field.order] as FieldError}
          register={register(`fields.${field.order}.fillValue`)}
        />
      );

    case FormFieldTypes.TextArea:
      return (
        <InputAutosizeRHF
          val={fields[field.order].fillValue || ""}
          label={placeholder}
          disabled={isPreview}
          inputType="text"
          errorMessage={(errors.fields as any)?.[field.order] as FieldError}
          register={register(`fields.${field.order}.fillValue`)}
        />
      );

    case FormFieldTypes.Email:
      return (
        <InputRHF
          val={fields[field.order].fillValue || ""}
          label={placeholder}
          disabled={isPreview}
          inputType="text"
          errorMessage={(errors.fields as any)?.[field.order] as FieldError}
          register={register(`fields.${field.order}.fillValue`)}
        />
      );

    case FormFieldTypes.Phone:
      return (
        <PhoneInputSmallRHF
          value={fields[field.order].fillValue || ""}
          label={placeholder}
          errorMessage={(errors.fields as any)?.[field.order] as FieldError}
          name={`fields.${field.order}.fillValue`}
          disabled={isPreview}
        />
      );

    case FormFieldTypes.Number:
      return (
        <InputRHF
          val={fields[field.order].fillValue || ""}
          label={placeholder}
          disabled={isPreview}
          inputType="number"
          errorMessage={(errors.fields as any)?.[field.order] as FieldError}
          register={register(`fields.${field.order}.fillValue`)}
        />
      );

    case FormFieldTypes.Date:
      return (
        <FormItemComponentDate
          fieldOrder={field.order}
          placeholder={placeholder}
          disabled={isPreview}
          selected={
            fields[field.order].fillValue
              ? (new Date(fields[field.order].fillValue) as Date)
              : fields[field.order].fillValue || ""
          }
        />
      );

    case FormFieldTypes.Time:
      return (
        <FormItemComponentTime
          fieldOrder={field.order}
          placeholder={placeholder}
          disabled={isPreview}
          selected={
            fields[field.order].fillValue
              ? parse(fields[field.order].fillValue, "HH:mm", new Date())
              : null
          }
        />
      );

    case FormFieldTypes.Checkbox:
      return (
        <FormItemComponentCheckbox
          fieldName={fieldName}
          fieldOrder={field.order}
          isPreview={isPreview}
        />
      );

    default:
      return null;
  }
}
