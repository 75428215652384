import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { toast } from "react-toastify";
import {
  AggregatedFilledFormEntityResponse,
  useFindAndCountManyFilledFormsByClientInfoViewQuery,
  useFindAndCountManyFilledFormsByDoctorInfoViewQuery,
  useFindPatientByIdQuery,
} from "src/graphql/generated";
import { IPatient, ISelectedPreviewFilledForm, TData } from "src/types";
import EmptyText from "src/components/EmptyText/EmptyText";
import { Loading } from "src/UI/Loading";
import { EActivityComponentSize, EUserComponentSize } from "src/types/enum";
import { useAuthContext } from "src/context/AuthContext";
import UserComponent from "../../../components/DashboardComponents/ActivityComponents/UserComponent";
import ActivityContentComponent from "../../../components/DashboardComponents/ActivityComponents/ActivityContentComponent";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  width: 100%;
  max-width: 700px;
`;

interface IFormPatientInfoViewProps {
  selectedPreview?: ISelectedPreviewFilledForm;
}

enum FormPatientInfoViewContent {
  isLoading,
  isNotSelected,
  isNotResult,
  isShowInfo,
}

function FormPatientInfoView({ selectedPreview }: IFormPatientInfoViewProps) {
  const { t } = useTranslation();
  const { isDoctor, currentUserData } = useAuthContext();
  const { data: selectedPatient, loading: patientLoading } =
    useFindPatientByIdQuery({
      variables: {
        schema: {
          id: selectedPreview?.patient.id || "",
        },
      },
      skip: !selectedPreview?.patient.id,
    });
  const {
    data: filledFormsByPatient,
    loading: loadingFilledFormsByPatient,
    error: errorFilledFormsByPatient,
  } = useFindAndCountManyFilledFormsByClientInfoViewQuery({
    variables: {
      schema: {
        patientId: selectedPreview?.patient.id || "",
      },
    },
    fetchPolicy: "no-cache",
    skip: !selectedPreview?.patient.id || isDoctor || !currentUserData,
    onError(e) {
      if (e) toast.error(e?.message);
    },
  });
  const {
    data: filledFormsByDoctor,
    loading: loadingFilledFormsByDoctor,
    error: errorFilledFormsByDoctor,
  } = useFindAndCountManyFilledFormsByDoctorInfoViewQuery({
    variables: {
      schema: {
        patientId: selectedPreview?.patient.id || "",
      },
    },
    fetchPolicy: "no-cache",
    skip: !selectedPreview?.patient.id || !isDoctor || !currentUserData,
    onError(e) {
      if (e) toast.error(e?.message);
    },
  });

  const data = isDoctor
    ? filledFormsByDoctor?.findAndCountManyFilledFormsByDoctor
    : filledFormsByPatient?.findAndCountManyFilledFormsByClient;

  const loading = loadingFilledFormsByPatient || loadingFilledFormsByDoctor;

  const error = errorFilledFormsByPatient || errorFilledFormsByDoctor;

  const getActivityAnswers = (form: AggregatedFilledFormEntityResponse) => {
    const activityAnswers = form?.answers.map(
      (answer) =>
        ({
          patientForm: { answer, fields: form.fields },
          customTitle: form?.name,
          date: new Date(answer?.createdAt || Date.now()),
        } as TData)
    );
    return activityAnswers;
  };

  let showContent: FormPatientInfoViewContent;
  switch (true) {
    case loading || patientLoading:
      showContent = FormPatientInfoViewContent.isLoading;
      break;
    case !selectedPreview:
      showContent = FormPatientInfoViewContent.isNotSelected;
      break;
    case !data?.forms.length && !selectedPatient:
    case !!error:
      showContent = FormPatientInfoViewContent.isNotResult;
      break;

    default:
      showContent = FormPatientInfoViewContent.isShowInfo;
      break;
  }

  const patientData: IPatient = {
    email: selectedPatient?.findPatientById?.email || "",
    patient_id: Number(selectedPatient?.findPatientById?.id) || 0,
    phone: selectedPatient?.findPatientById?.phoneNumber || "",
    name: selectedPatient?.findPatientById?.firstName || "",
    soname: selectedPatient?.findPatientById?.lastName || "",
  };

  return (
    <Wrapper>
      {showContent === FormPatientInfoViewContent.isLoading && <Loading />}

      {showContent === FormPatientInfoViewContent.isNotResult && (
        <EmptyText
          text={t(
            "forms.tabs.patients_submissions.view_filled_form.filled_form_not_found"
          )}
        />
      )}

      {showContent === FormPatientInfoViewContent.isNotSelected && (
        <EmptyText
          text={t(
            "forms.tabs.patients_submissions.view_filled_form.select_patient"
          )}
        />
      )}

      {showContent === FormPatientInfoViewContent.isShowInfo && (
        <>
          <UserComponent
            patient={patientData}
            size={EUserComponentSize.large}
            isHideAvatar
          />

          {data?.forms.map((form, formIndex) =>
            getActivityAnswers(form).map((answer, answerIndex) => (
              <ActivityContentComponent
                key={answerIndex}
                date={answer.date}
                item={answer}
                isShowMoreDefault={formIndex === 0 && answerIndex === 0}
                size={EActivityComponentSize.mid}
              />
            ))
          )}
        </>
      )}
    </Wrapper>
  );
}

export default FormPatientInfoView;
