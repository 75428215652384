import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";
import { Button } from "src/UI/Button";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  padding: 30px;
  gap: 16px;
  width: 100%;
  border-top: 1px solid #ededed;
`;

export const Input = styled(TextareaAutosize)`
  width: 100%;
  flex: 1;
  resize: none;
  border: none;
  outline: none;
  border-bottom: 1px solid transparent;
  &:focus {
    border-color: ${({ theme }) => theme.colors.borderInputDefault};
  }
`;

export const ButtonIcon = styled(Button)`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  padding: 0;
  width: fit-content;
  svg {
    width: 24px;
    height: 24px;
    path {
      stroke: ${({ theme }) => theme.colors.green};
    }
  }
`;
