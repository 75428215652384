import styled from "styled-components";

export const HistoryBlock = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 16px;
`;

export const ColumnTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 16px;
`;
export const ColumnValue = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 16px;
`;

export const Title = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.gray};
`;

export const Value = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.black};
`;

export const History = styled.div`
  width: 100%;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 16px;
  display: flex;
`;
