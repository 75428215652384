import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PaginationComponent } from "src/components/ReviewComponents";
import { CommonTable } from "src/UI/CommonTable";
import { ISelectedPreviewFilledForm } from "src/types";
import {
  AggregatedPatientEntityResponse,
  FormAnswerEntityWithFormResponse,
  useFindAndCountManyAggregatedFormsPatientsByClientLazyQuery,
  useFindAndCountManyAggregatedFormsPatientsByDoctorLazyQuery,
} from "src/graphql/generated";
import { usePagination } from "src/hooks/usePagination";
import {
  defaultPageLimit,
  localFormPatientTableViewPageNumber,
} from "src/constants/constants";
import { useClinicsState } from "src/store/clinics/hooks";
import EmptyText from "src/components/EmptyText/EmptyText";
import { useAuthContext } from "src/context/AuthContext";
import { FormTbody, Wrapper } from "./styled";
import FormPatientTableViewRow from "./FormPatientTableViewRow";

interface IPatientsTableViewProps {
  selectedPreview?: ISelectedPreviewFilledForm;
  onSelectPreview?: (patient: AggregatedPatientEntityResponse) => void;
}

function FormPatientsTableView({
  onSelectPreview,
  selectedPreview,
}: IPatientsTableViewProps) {
  const { t } = useTranslation();
  const clinic = useClinicsState();
  const { isDoctor, currentUserData } = useAuthContext();
  const [
    getPatientsFormsByClient,
    { data: patientsFormsByClientData, loading: filledFormsByClientLoading },
  ] = useFindAndCountManyAggregatedFormsPatientsByClientLazyQuery();

  const [
    getPatientsFormsByDoctor,
    { data: patientsFormsByDoctorData, loading: filledFormsByDoctorLoading },
  ] = useFindAndCountManyAggregatedFormsPatientsByDoctorLazyQuery();

  const data = isDoctor
    ? patientsFormsByDoctorData?.findAndCountManyAggregatedFormsPatientsByDoctor
    : patientsFormsByClientData?.findAndCountManyAggregatedFormsPatientsByClient;

  const loading = filledFormsByClientLoading || filledFormsByDoctorLoading;

  const { offset, currentPage, pages, setCurrentPage } = usePagination({
    limit: defaultPageLimit,
    count: data?.count || 0,
    localStorageKey: localFormPatientTableViewPageNumber,
  });

  useEffect(() => {
    if (clinic?.currentClinicId && !isDoctor && currentUserData) {
      getPatientsFormsByClient({
        variables: {
          schema: {
            clinicId: clinic.currentClinicId,
            limit: defaultPageLimit,
            offset,
          },
        },
      });
    }
    if (clinic?.currentClinicId && isDoctor && currentUserData) {
      getPatientsFormsByDoctor({
        variables: {
          schema: {
            clinicId: clinic.currentClinicId,
            limit: defaultPageLimit,
            offset,
          },
        },
      });
    }
  }, [currentPage, clinic?.currentClinicId, isDoctor, currentUserData]);

  const handleSelectRowClick =
    (patient: AggregatedPatientEntityResponse) => () => {
      if (onSelectPreview) {
        onSelectPreview(patient);
      }
    };

  const isShowTable = data?.patients && !!data?.patients.length;

  return (
    <Wrapper>
      {!loading && !isShowTable && (
        <EmptyText
          text={t("forms.patients.no_applications_from_patients_yet")}
        />
      )}

      {isShowTable && (
        <>
          <CommonTable>
            <thead>
              <tr>
                <th>{t("waitlist.table.header.patient_name")}</th>
                <th>{t("forms.patients.headers.submission")}</th>
                <th>{t("forms.patients.headers.submission_by_patients")}</th>
              </tr>
            </thead>

            {data?.patients.map((patient) => (
              <FormTbody key={patient.id}>
                {patient?.answers?.map((answer, index) => {
                  return (
                    <FormPatientTableViewRow
                      key={answer.id}
                      patient={patient as AggregatedPatientEntityResponse}
                      formName={
                        (answer as FormAnswerEntityWithFormResponse).form.name
                      }
                      answer={answer as FormAnswerEntityWithFormResponse}
                      isSelected={selectedPreview?.patient.id === patient.id}
                      onSelectPatient={handleSelectRowClick(
                        patient as AggregatedPatientEntityResponse
                      )}
                      isFirst={index === 0}
                    />
                  );
                })}
              </FormTbody>
            ))}
          </CommonTable>

          <PaginationComponent
            pages={pages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </>
      )}
    </Wrapper>
  );
}

export default FormPatientsTableView;
