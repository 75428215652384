import { gql } from "@apollo/client";

export const GET_FIND_MANY_AGGREGATED_DOCTORS_BY_SERVICE_ID = gql`
  query FindManyAggregatedDoctorsByServiceId(
    $schema: FindManyAggregatedDoctorsByServiceIdSchema!
  ) {
    findManyAggregatedDoctorsByServiceId(schema: $schema) {
      doctors {
        id
        email
        firstName
        lastName
        activated
        dateOfBirth
        avatarFileId
        roles
        createdAt
        doctorId
        doctorTypeId
        phoneNumber
        passportNumber
        medicalCertificateNumber
        description
        promoCode
        address
        doctorCreatedAt
      }
    }
  }
`;
