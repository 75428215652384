import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import {
  AppointmentStatuses,
  ClinicEntityResponse,
  ServiceEntityResponse,
} from "src/graphql/generated";
import { EButtonVariants } from "src/UI/Button";
import AvatarComponent from "src/components/AvatarComponent/AvatarComponent";
import {
  getDateFromScheduleAppointment,
  getDateWithoutTimeZoneOffset,
} from "src/utils";
import { LinkIcon } from "src/UI/Svg";
import { useAuthContext } from "src/context/AuthContext";
import { Loading } from "src/UI/Loading";
import { TAppointmentByClient, TScheduleAppointmentByRole } from "src/types";
import { useUpdateStatus } from "src/hooks/appointments/useUpdateStatus";
import HistoryComponent from "../HistoryComponent/HistoryComponent";
import {
  ButtonStyled,
  Buttons,
  Footer,
  Header,
  InfoBlock,
  Name,
  Phone,
  StyledButtonIcon,
  Wrapper,
} from "./styled";

interface IActivityComponentProps {
  appointment: TScheduleAppointmentByRole;
  handleShowModal: (id: string) => () => void;
}

function ActivityComponent({
  appointment,
  handleShowModal,
}: IActivityComponentProps) {
  const { t, i18n } = useTranslation();
  const { isDoctor } = useAuthContext();

  const [isAppointmentInPast, setIsAppointmentInPast] = useState(false);

  const {
    updateStatusHandler,
    confirmAppointmentLoading,
    cancelAppointmentLoading,
  } = useUpdateStatus({ id: appointment?.id as string });

  useEffect(() => {
    if (appointment?.schedule.dateFrom && appointment?.schedule.minuteFrom) {
      const originalDate =
        appointment?.schedule.dateFrom &&
        new Date(appointment?.schedule.dateFrom);

      const newDate = new Date(
        originalDate.getTime() +
          (appointment?.schedule.minuteFrom as number) * 60000
      );

      const formattedDate = getDateWithoutTimeZoneOffset(newDate.toISOString());

      const dateNow = Date.now();
      if (dateNow > formattedDate) {
        setIsAppointmentInPast(true);
      } else {
        setIsAppointmentInPast(false);
      }
    }
  }, [appointment?.schedule.dateFrom, appointment?.schedule.minuteFrom]);

  return (
    <Wrapper>
      <Header>
        <AvatarComponent
          firstName={appointment?.patient.firstName as string}
          lastName={appointment?.patient.lastName as string}
          size={56}
        />
        <InfoBlock>
          <Name>
            {appointment?.patient.firstName} {appointment?.patient.lastName}
          </Name>
          <Phone>
            {appointment?.schedule.dateFrom && appointment?.schedule.minuteFrom
              ? getDateFromScheduleAppointment(
                  appointment?.schedule.dateFrom,
                  appointment?.schedule.minuteFrom,
                  i18n
                )
              : null}
          </Phone>
        </InfoBlock>
        <StyledButtonIcon
          variant={EButtonVariants.Text}
          onClick={handleShowModal(appointment?.id as string)}
        >
          <LinkIcon />
        </StyledButtonIcon>
      </Header>
      <HistoryComponent
        doctor={
          !isDoctor ? (appointment as TAppointmentByClient)?.doctor : null
        }
        service={appointment?.service as ServiceEntityResponse}
        clinic={appointment?.clinic as ClinicEntityResponse}
      />
      {!isDoctor ? (
        <Footer>
          <Buttons>
            {!isAppointmentInPast ? (
              <ButtonStyled
                variant={EButtonVariants.Default}
                onClick={updateStatusHandler(
                  AppointmentStatuses.ConfirmedByApplication
                )}
                type="button"
              >
                {confirmAppointmentLoading ? <Loading padding="0" /> : null}
                {t("activity.accept")}
              </ButtonStyled>
            ) : (
              <ButtonStyled
                variant={EButtonVariants.Red}
                type="button"
                onClick={updateStatusHandler(AppointmentStatuses.Canceled)}
              >
                {cancelAppointmentLoading ? <Loading padding="0" /> : null}
                {t("booking.schedule_appointment.cancel_visit")}
              </ButtonStyled>
            )}
          </Buttons>
        </Footer>
      ) : null}
    </Wrapper>
  );
}

export default ActivityComponent;
