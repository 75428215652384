import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuthorizeWith2FA } from "src/graphql/hooks/useMutations";
import { toast } from "react-toastify";
import { PATHS } from "src/constants/constants";
import { useAuthContext } from "src/context/AuthContext";
import { Loading } from "src/UI/Loading";
import { ChevronIcon, LogoFullIcon } from "src/UI/Svg";
import { Text } from "src/UI/Text";
import { Heading } from "src/UI/Heading";
import { InputComponent } from "src/components/AuthComponents";
import { EButtonVariants } from "src/UI/Button";

import {
  Breadcrumb,
  ButtonStyled,
  FormFA,
  FormWrapper,
  TitleBlock,
  WrapperNav,
} from "../AuthPages/styled";

interface FormValues {
  code: string;
}

function FAPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [authorizeWith2FA, { loading }] = useAuthorizeWith2FA();
  const { onSuccessAuth, refetchUser, logout } = useAuthContext();

  const {
    register,
    formState: { errors, isValid },
    watch,
    handleSubmit,
  } = useForm<FormValues>({
    mode: "onChange",
  });
  const data = watch();

  const formHandler = () => {
    authorizeWith2FA({
      variables: { schema: { code: data.code } },
      onError: (error) => {
        if (error) {
          toast.error(error.message);
        }
      },

      onCompleted: () => {
        onSuccessAuth(true, true);
        refetchUser();

        navigate(`${PATHS.cp}${PATHS.map}`);
      },
    });
  };

  return (
    <>
      <WrapperNav>
        <LogoFullIcon />
      </WrapperNav>
      <FormWrapper>
        <Breadcrumb to={`${PATHS.auth}${PATHS.login}`} onClick={logout}>
          <ChevronIcon />
          <Text>{t("auth.fa.breadcrumb")}</Text>
        </Breadcrumb>
        <FormFA onSubmit={handleSubmit(formHandler)}>
          <TitleBlock>
            <Heading as="h2">{t("auth.fa.title")}</Heading>
            <Text>{t("auth.fa.header_text")}</Text>
          </TitleBlock>
          <InputComponent
            placeholder={t("auth.fa.title")}
            inputType="password"
            val={data.code}
            errorMessage={errors.code}
            register={register("code", {
              required: true,
              minLength: {
                value: 4,
                message: t("auth.create2fa.minLengthError"),
              },
            })}
          />
          <ButtonStyled
            variant={EButtonVariants.Default}
            disabled={!isValid || loading}
          >
            {loading ? <Loading type="secondary" padding="0" /> : null}
            {t("auth.fa.button")}
          </ButtonStyled>
        </FormFA>
      </FormWrapper>
    </>
  );
}

export default FAPage;
