import { Button } from "src/UI/Button";
import { Heading } from "src/UI/Heading";
import { Tabs } from "src/UI/Tabs";
import styled from "styled-components";

interface IWrapperProps {
  $isNoBg?: boolean;
}

export const Wrapper = styled.div<IWrapperProps>`
  width: 100%;
  min-height: 82px;
  background: ${({ theme, $isNoBg }) =>
    $isNoBg ? "transparent" : theme.colors.background};
  box-shadow: 0 5px 25px
    ${({ $isNoBg }) => ($isNoBg ? "transparent" : "rgba(0, 0, 0, 0.03)")};
  border-radius: 10px;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 5px;
  padding: 0;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  padding: 20px;
`;

export const HeadingStyled = styled(Heading)`
  font-size: 24px;
  line-height: 100%;
`;

export const ButtonStyled = styled(Button)`
  padding: 8px 10px;
  font-size: 16px;
  line-height: 100%;
  width: fit-content;
  border-radius: 5px;
  svg {
    width: 16px;
    height: 16px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  gap: 8px;
`;

export const FooterButtons = styled(Buttons)`
  width: 100%;
  padding: 20px;
`;
export const ButtonFooter = styled(Button)`
  padding: 15px 20px;
  font-size: 20px;
  line-height: 100%;
  width: fit-content;
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 16px;
    padding: 12px 16px;
  }
`;

export const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 20px;
  padding: 0 20px;
`;

export const TabsStyled = styled(Tabs)`
  gap: 20px;
`;

export const TabsHeaderWrapper = styled.div``;

export const TabsHeader = styled.div`
  color: inherit;
  text-transform: capitalize;
  padding-inline: 8px;
`;
