import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PatientEntityResponse } from "src/graphql/generated";

export interface MessengerState {
  selectedChat: PatientEntityResponse | null;
}

const initialState: MessengerState = {
  selectedChat: null,
};

export const messengerSlice = createSlice({
  name: "messenger",
  initialState,
  reducers: {
    selectChat: (
      state,
      action: PayloadAction<PatientEntityResponse | null>
    ) => {
      state.selectedChat = action.payload;
    },
  },
});

export const { selectChat } = messengerSlice.actions;
export default messengerSlice.reducer;
