import React, { useCallback, useId } from "react";
import { useTranslation } from "react-i18next";
import { useAppAction, useAppState } from "src/store/app/hooks";
import { EModals, IPatientSettingsFieldsList } from "src/store/app/types";
import { EButtonVariants } from "src/UI/Button";
import { CheckBox } from "src/UI/Input";
import { Modal } from "src/UI/Modal";
import { Buttons, ButtonStyled, Content } from "./styles";

function PatientSettingsModal() {
  const { t } = useTranslation();
  const { modals } = useAppState();
  const { onCloseModal, onTogglePatientSettings } = useAppAction();

  const closeModal = useCallback(() => {
    onCloseModal(EModals.PatientSettings);
  }, [onCloseModal]);

  const changeProp = useCallback(
    (field: import("src/store/app/types").IPatientSettingsFieldsList) => {
      onTogglePatientSettings(field);
    },
    []
  );
  const propsId = useId();
  const modalSettings = modals[EModals.PatientSettings];

  if (!modalSettings.active) return null;
  return (
    <Modal
      modal={EModals.PatientSettings}
      title={t("modals.patient_settings.title")}
    >
      <Content>
        {Object.keys(modalSettings.fields).map((field) => (
          <CheckBox
            key={field}
            value={
              modalSettings.fields[
                field as IPatientSettingsFieldsList
              ] as boolean
            }
            onChange={() => changeProp(field as IPatientSettingsFieldsList)}
            id={propsId + field}
            label={t(`modals.patient_settings.${field}`) || ""}
          />
        ))}
      </Content>
      <Buttons>
        <ButtonStyled variant={EButtonVariants.Text} onClick={closeModal}>
          {t("common.cancel")}
        </ButtonStyled>
        <ButtonStyled variant={EButtonVariants.Default} onClick={closeModal}>
          {t("common.save")}
        </ButtonStyled>
      </Buttons>
    </Modal>
  );
}

export default PatientSettingsModal;
