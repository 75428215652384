import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 466px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  min-width: 64px;
  width: 64px;
  height: 64px;
  margin-bottom: 10px;
  background: ${({ theme }) => theme.colors.greenHover};
  border-radius: 48px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.black};
  font-size: 24px;
  line-height: 120%;
  margin-bottom: 10px;
`;

export const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-bottom: 35px;
`;

export const Info = styled.p`
  color: ${({ theme }) => theme.colors.darkGrey};
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`;
