import React, { ReactNode } from "react";
import styled from "styled-components";

interface RowItemProps {
  isSelected: boolean;
  name: number | string | ReactNode;
  onChange: () => void;
}

const RowItemStyled = styled.div<{ isSelected: boolean }>`
  font-style: normal;
  padding: 7px 14px;
  font-weight: 500;
  min-width: max-content;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme, isSelected }) =>
    theme.colors[isSelected ? "green" : "black"]};
  cursor: pointer;
  &:hover {
    background-color: #e7f6f5;
    color: ${({ theme }) => theme.colors.green};
  }
`;

function RowItem({ isSelected, name, onChange }: RowItemProps) {
  return (
    <RowItemStyled isSelected={isSelected} onClick={() => onChange()}>
      {name}
    </RowItemStyled>
  );
}

export default RowItem;
