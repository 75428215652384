import { gql } from "@apollo/client";

export const GET_FIND_MANY_DOCTOR_TYPES_BY_LANGUAGE_ISO_CODE = gql`
  query FindManyDoctorTypesByLanguageISOCode(
    $schema: FindManyDoctorTypesByLanguageISOCodeSchema!
  ) {
    findManyDoctorTypesByLanguageISOCode(schema: $schema) {
      doctorTypes {
        defaultLanguageName
        doctorTypeTranslations {
          doctorTypeId
          id
          languageISOCode
          name
        }
        id
      }
    }
  }
`;
