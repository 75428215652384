import React, {
  ComponentType,
  ReactNode,
  createElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { DropdownCustomSelectedProps } from "src/types";
import RowItem from "./RowItem";
import { ChevronDownIcon } from "../Svg";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import {
  DropdownStyled,
  LabelStyled,
  RowStyled,
  IconStyled,
  Menu,
  Placeholder,
} from "./styles";

interface DropdownProps {
  value: string | number | object;
  placeholder: string | null;
  onSelect: (val: any) => void;
  options: Array<{ item: string | ReactNode; value: string | number | object }>;
  label: string;
  isShowOnlyPlaceholder?: boolean;
  isHideBorderOnShowMenu?: boolean;
  customSelectedItem?: ComponentType<DropdownCustomSelectedProps>;
  disabled?: boolean;
}

function Dropdown({
  value,
  placeholder,
  onSelect,
  label,
  options,
  isShowOnlyPlaceholder,
  customSelectedItem,
  isHideBorderOnShowMenu,
  disabled = false,
  ...props
}: DropdownProps) {
  const dropdown = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const menu = useRef<HTMLDivElement | null>(null);

  const selectItem = useMemo(
    () =>
      (typeof value !== "undefined" &&
        options.filter((item) => {
          return JSON.stringify(item.value) === JSON.stringify(value);
        })[0]) ||
      null,
    [options, value]
  );

  const onSelectHandler = useCallback(
    (val: string | number | object) => {
      onSelect(val);
      setShowMenu(false);
    },
    [onSelect]
  );

  useOutsideClick({ ref: dropdown, handler: () => setShowMenu(false) });

  const [selectItemItem] = useMemo(() => {
    if (customSelectedItem && selectItem)
      return [
        createElement(customSelectedItem, { value: selectItem.value }),
        selectItem.value,
      ];

    if (selectItem) return [selectItem.item, selectItem.value];
    return [placeholder, "-"];
  }, [selectItem, placeholder, customSelectedItem]);

  const onToggleShow = useCallback(() => {
    if (disabled) return;
    setShowMenu(!showMenu);
  }, [showMenu]);
  return (
    <DropdownStyled ref={dropdown} {...props}>
      {label !== "" && <LabelStyled>{label}</LabelStyled>}
      <RowStyled
        onClick={onToggleShow}
        showMenu={showMenu && !isHideBorderOnShowMenu}
        disabled={disabled}
        className="selection"
      >
        {selectItem && !isShowOnlyPlaceholder ? (
          <Placeholder disabled={disabled}>{selectItemItem}</Placeholder>
        ) : (
          <Placeholder disabled={disabled} className="placeholder">
            {placeholder}
          </Placeholder>
        )}
        <IconStyled showMenu={showMenu} className="icon">
          <ChevronDownIcon />
        </IconStyled>
      </RowStyled>
      <Menu
        ref={menu}
        showMenu={showMenu}
        isHideBorderOnShowMenu={isHideBorderOnShowMenu}
        className="dropdown"
      >
        {options.map((opt) => {
          const isSelected =
            JSON.stringify(selectItem?.value) === JSON.stringify(opt.value);

          return (
            <RowItem
              isActive={false}
              isSelected={isSelected}
              key={JSON.stringify(opt.value)}
              onSelect={onSelectHandler}
              item={opt.item}
              value={opt.value}
            />
          );
        })}
      </Menu>
    </DropdownStyled>
  );
}

export default Dropdown;
