import { gql } from "@apollo/client";

export const GET_FIND_DOCTOR_BY_ID = gql`
  query FindDoctorById($schema: FindDoctorByIdSchema!) {
    findDoctorById(schema: $schema) {
      id
      email
      firstName
      lastName
      activated
      dateOfBirth
      avatarFileId
      roles
      createdAt
      doctorId
      doctorTypeId
      phoneNumber
      passportNumber
      medicalCertificateNumber
      description
      promoCode
      address
      doctorCreatedAt
    }
  }
`;
