import React, { useEffect, useRef } from "react";
import { FormFieldTypes } from "src/graphql/generated";
import { INewForm } from "src/types/CreateEditForm";
import {
  CheckIcon,
  ClockIcon,
  DeleteIcon,
  DragHorizontalIcon,
  EyeOffIcon,
} from "src/UI/Svg";
import { Toggle } from "src/UI/Toggle";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDrag, useDrop } from "react-dnd";
import { Identifier, XYCoord } from "dnd-core";
import {
  Actions,
  ButtonIcon,
  DragButton,
  Footer,
  Label,
  ToggleBlock,
  Toggles,
  Wrapper,
} from "../styled";
import FormComponent from "../FormComponent/FormComponent";

const formComponentByTypes = Object.values(FormFieldTypes).map(
  (item: FormFieldTypes) => {
    return {
      [item]: (order: number, isHideDeleteButton?: boolean) => (
        <FormComponent
          type={item}
          order={order}
          isHideDeleteButton={isHideDeleteButton}
        />
      ),
    };
  }
);

interface DragItem {
  order: number;
  id: string;
  type: string;
}

const NewFormComponent = ({
  type,
  order,
  id,
  isHideDeleteButton,
  onRemove,
  moveForm,
}: INewForm) => {
  const { t } = useTranslation();

  const { watch, setValue } = useFormContext();
  const fields = watch("fields");

  useEffect(() => {
    setValue(`fields.${order}.order`, order);
  }, [order, fields.length]);

  const formComponent = formComponentByTypes?.find(
    (item) => Object.keys(item)[0] === type
  )![type];

  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: "card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.order;
      const hoverIndex = order;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveForm(dragIndex, hoverIndex);
      item.order = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: () => {
      return { id, order };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const isHideDeleteInField = isHideDeleteButton && typeof id === "string";

  return (
    <Wrapper ref={ref} opacity={opacity} data-handler-id={handlerId}>
      <DragButton ref={ref} data-handler-id={handlerId}>
        <DragHorizontalIcon />
      </DragButton>

      {formComponent && formComponent(order, isHideDeleteInField)}
      <Footer>
        <Toggles>
          {type === FormFieldTypes.Date ? (
            <ToggleBlock>
              <Label>
                <ClockIcon />
                {t("forms.modals.fields.time") as string}
              </Label>
              <Toggle
                isActive={fields[order]?.value?.time || false}
                onToggle={() =>
                  setValue(
                    `fields.${order}.value.time`,
                    !fields[order]?.value?.time
                  )
                }
              />
            </ToggleBlock>
          ) : null}

          <ToggleBlock>
            <Label>
              <EyeOffIcon />
              {t("forms.modals.checkbox.hide") as string}
            </Label>
            <Toggle
              isActive={fields[order]?.hiddenFromExistingPatients || false}
              onToggle={() =>
                setValue(
                  `fields.${order}.hiddenFromExistingPatients`,
                  !fields[order]?.hiddenFromExistingPatients
                )
              }
            />
          </ToggleBlock>
          <ToggleBlock>
            <Label>
              <CheckIcon />
              {t("forms.modals.checkbox.required") as string}
            </Label>
            <Toggle
              isActive={fields[order]?.required || false}
              onToggle={() =>
                setValue(`fields.${order}.required`, !fields[order]?.required)
              }
            />
          </ToggleBlock>
        </Toggles>
        <Actions>
          {!isHideDeleteInField && (
            <ButtonIcon onClick={() => onRemove(id as number)}>
              <DeleteIcon />
            </ButtonIcon>
          )}
        </Actions>
      </Footer>
    </Wrapper>
  );
};

export default NewFormComponent;
