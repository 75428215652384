import * as yup from "yup";
import { parsePhoneNumber } from "awesome-phonenumber";
import { AutocompletePrediction } from "react-places-autocomplete";
import { IUserValue } from "src/pages/VerifyPages/RegistrationCompanyPage";

export const addPatientBookingStep2Schema = yup.object().shape({
  firstName: yup
    .string()
    .required("auth.reg.name_error")
    .min(1, "auth.reg.name_error")
    .max(100, "validation.field_max_100"),
  lastName: yup
    .string()
    .required("auth.reg.surname_error")
    .min(1, "auth.reg.surname_error")
    .max(100, "validation.field_max_100"),
  email: yup
    .string()
    .test("check-length", "auth.login.email_error", (value) => {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (value?.length) return emailRegex.test(value);
      return true;
    })
    .required("validation.field_required"),
  phoneNumber: yup
    .string()
    .required("validation.field_required")
    .test("is-valid", "validation.invalid_phone_number_required", (val) => {
      return parsePhoneNumber(`+${val}` as string).possible;
    }),
  postCode: yup.string().required("validation.field_required"),
  comment: yup
    .string()
    .max(300, "validation.field_max_300")
    .test("check-min-length-5", "validation.field_min_5", (value) => {
      if (value && value.length < 5) return false;
      return true;
    })
    .nullable(),
  passportNumber: yup
    .string()
    .max(11, "validation.field_max_11")
    .test("check-min-length-9", "validation.field_min_9", (value) => {
      if (value && value.length < 9) return false;
      return true;
    })
    .required("validation.field_required"),
  address: yup
    .mixed()
    .nullable()
    .test(
      "check-address-type",
      "app.geocoder.not_correct_address_error",
      (value) => {
        const typedValue = value as AutocompletePrediction | IUserValue;
        const isNotCorrecValue =
          !!value &&
          typedValue.description.length &&
          (value as IUserValue)?.type === "custom_user_value";
        return !isNotCorrecValue;
      }
    )
    .test("check-required", "validation.field_required", (value) => {
      const typedValue = value as AutocompletePrediction | IUserValue;
      return !!typedValue?.description;
    }),
  dateOfBirth: yup.date().required("validation.field_required"),
  gender: yup.string().required("validation.field_required"),
});
