import styled from "styled-components";

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.background};
  width: 100%;
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 20px;

  thead {
  }

  tbody tr {
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
    &:last-child {
      border-bottom: none;
    }
  }

  th {
    padding: 20px 0 5px 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.grayC4};
    text-align: left;

    &:first-child {
    }

    &:last-child {
    }
  }

  td {
    padding: 30px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black};
    text-align: left;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  gap: 24px;
`;

export const ButtonIcon = styled.button`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: none;
  padding: 0;
  border: none;
  cursor: pointer;
  a {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }

  svg {
    width: 16px;
    height: 16px;

    path {
      stroke: ${({ theme }) => theme.colors.gray};
    }
  }
`;

export const DateBlock = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.black};
  span {
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.gray};
  }
`;
