import { useDispatch, useSelector } from "react-redux";
import { useCallback, useMemo } from "react";
import { AppDispatch, RootState } from "../index";
import { setClinicId, setLocation } from "./clinicsSlice";
import { Location } from "./types";

export const getClinics = (state: RootState) => state.clinics;

export function useClinicsAction() {
  const dispatch = useDispatch<AppDispatch>();

  const onSetLocation = useCallback(
    (location: Location) => {
      dispatch(setLocation(location));
    },
    [dispatch]
  );
  const onSetClinicId = useCallback(
    (id: string) => {
      dispatch(setClinicId(id));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      onSetLocation,
      onSetClinicId,
    }),
    [onSetLocation, onSetClinicId]
  );
}

export function useClinicsState() {
  return useSelector(getClinics);
}
