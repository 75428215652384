import {
  useMessengerAction,
  useMessengerState,
} from "src/store/messenger/hooks";
import { PatientEntityResponse } from "src/graphql/generated";
import AvatarComponent from "src/components/AvatarComponent/AvatarComponent";
import { getFormatDateByLocale, isToday } from "src/utils";
import { useTranslation } from "react-i18next";
import {
  Chat,
  DateBlock,
  Header,
  InfoBlock,
  MessageBlock,
  MessageContent,
  Name,
} from "./styled";

interface IChatComponentProps {
  patient: PatientEntityResponse;
  createdAt: string;
  content: string;
  resolvedLanguage: string;
}

function ChatComponent({
  patient,
  createdAt,
  content,
  resolvedLanguage,
}: IChatComponentProps) {
  const { t } = useTranslation();
  const { selectedChat } = useMessengerState();
  const { onSelectChat } = useMessengerAction();

  const handleSelectChat = () => {
    onSelectChat(patient);
  };

  return (
    <Chat
      isSelected={patient.id === selectedChat?.id}
      onClick={handleSelectChat}
    >
      <AvatarComponent
        firstName={patient.firstName}
        lastName={patient.lastName}
        size={56}
      />
      <InfoBlock>
        <Header>
          <Name>
            {patient.firstName} {patient.lastName}
          </Name>
          <DateBlock>
            {isToday(new Date(createdAt))
              ? t("messenger.today")
              : getFormatDateByLocale(
                  new Date(createdAt),
                  resolvedLanguage,
                  resolvedLanguage !== "pl"
                    ? "d MMM yyyy 'at' h:mm a"
                    : "d MMM yyyy HH:mm"
                )}
          </DateBlock>
        </Header>
        <MessageBlock>
          <MessageContent>{content}</MessageContent>
        </MessageBlock>
      </InfoBlock>
    </Chat>
  );
}

export default ChatComponent;
