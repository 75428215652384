import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider } from "src/UI/Divider";
import { useFindManyFilesByIds } from "src/graphql/hooks/useQueries";
import {
  AggregatedDoctorEntityResponse,
  SlotsGroupedByDateResponse,
} from "src/graphql/generated";
import { addDays, format, formatISO } from "date-fns";
import { useLocation } from "react-router-dom";
import { useLanguageEffect } from "src/hooks/patientBooking/useLanguageEffect";
import { useClinicEffect } from "src/hooks/patientBooking/useClinicEffect";
import { useServiceEffect } from "src/hooks/patientBooking/useServiceEffect";
import { useDoctorsEffect } from "src/hooks/patientBooking/useDoctorsEffect";
import { useDoctorTypesEffect } from "src/hooks/patientBooking/useDoctorTypesEffect";
import { useServicesListEffect } from "src/hooks/patientBooking/useServicesListEffect";
import { useBookingState } from "src/store/booking/hooks";
import { EmptyTextStyled } from "./styles";
import DoctorItemComponent from "./DoctorItemComponent/DoctorItemComponent";
import ServicesInfoComponent from "./ServicesInfoComponent/ServicesInfoComponent";
import DatesSelectionComponent from "./DatesSelectionComponent/DatesSelectionComponent";
import { PageProps, TDoctors, TDoctorsTypes, TFiles, TServices } from "./types";

const Step1: FC<PageProps> = ({ onSetCurrentStep }) => {
  const { currentService } = useBookingState();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const { t } = useTranslation();

  const [selectedDate, setSelectedDate] = useState<string>(
    format(new Date(), "yyyy-MM-dd")
  );
  const [dateFilter, setDateFilter] = useState({
    dateFrom: formatISO(new Date()),
    dateTo: formatISO(addDays(new Date(), 30)),
  });
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const [availableSlotsLoading, setAvailableSlotsLoading] = useState(false);
  const [logoFilesArray, setLogoFilesArray] = useState<TFiles>([]);
  const [doctors, setDoctors] = useState<TDoctors>([]);
  const [doctorTypesData, setDoctorTypesData] = useState<TDoctorsTypes>();
  const [services, setServices] = useState<TServices>([]);
  const [slotsWaitlist, setSlotsWaitlist] = useState<
    SlotsGroupedByDateResponse[]
  >([]);

  const langCode = queryParams.get("langCode");
  const clinicId = queryParams.get("clinicId");
  const serviceId = queryParams.get("serviceId");

  const availableDatesArray = Array.from(new Set(availableDates));

  useLanguageEffect(langCode);
  useClinicEffect(clinicId);
  useServiceEffect(serviceId);
  useDoctorTypesEffect(setDoctorTypesData);
  useServicesListEffect(setServices);
  useDoctorsEffect(
    setDoctors,
    setSlotsWaitlist,
    setAvailableDates,
    setSelectedDate
  );

  const doctotsLogoIds =
    doctors?.reduce<string[]>((accumulator, doctor) => {
      const avatarFileId = doctor?.avatarFileId;
      if (avatarFileId) {
        accumulator.push(avatarFileId);
      }
      return accumulator;
    }, []) || [];

  const { loading: logoFielsLoading } = useFindManyFilesByIds({
    variables: {
      schema: {
        ids: doctotsLogoIds,
      },
    },
    onCompleted(response) {
      setLogoFilesArray(response?.findManyFilesByIds?.files);
    },
    skip: !doctotsLogoIds?.length,
  });

  useEffect(() => {
    if (serviceId !== currentService?.service?.id) {
      setAvailableDates([]);
    }
  }, [currentService]);

  return (
    <div>
      <ServicesInfoComponent
        services={services}
        setDateFilter={setDateFilter}
      />
      <Divider margin="20px 0" />
      {doctors && (
        <DatesSelectionComponent
          availableDatesArray={availableDatesArray}
          availableSlotsLoading={availableSlotsLoading}
          dateFilter={dateFilter}
          selectedDate={selectedDate}
          setDateFilter={setDateFilter}
          setSelectedDate={setSelectedDate}
        />
      )}
      <div>
        {doctors?.length ? (
          doctors?.map((doctor: AggregatedDoctorEntityResponse) => {
            return (
              <DoctorItemComponent
                key={doctor.doctorId}
                doctor={doctor}
                doctorTypesData={doctorTypesData}
                slotsWaitlist={slotsWaitlist}
                dateFilter={dateFilter}
                selectedDate={selectedDate}
                logoFilesArray={logoFilesArray}
                logoFielsLoading={!!logoFielsLoading}
                onSetAvailableSlotsLoading={setAvailableSlotsLoading}
                onSetAvailableDates={setAvailableDates}
                onSetCurrentStep={onSetCurrentStep}
              />
            );
          })
        ) : (
          <EmptyTextStyled>{t("app.doctors_not_added")}</EmptyTextStyled>
        )}
      </div>
    </div>
  );
};

export default Step1;
