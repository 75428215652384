import styled from "styled-components";

export const SearchWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 554px;
  z-index: 1;
`;

export const SearchBlock = styled.div<{ isShow: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 50px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.borderInputDefault};
  box-shadow: ${({ isShow }) =>
    isShow ? `none` : "0 5px 25px rgba(0, 0, 0, 0.03)"};
  font-weight: 500;
  border-radius: 30px;
  padding: 0 12px;
  background: ${({ theme }) => theme.colors.white};
  z-index: 1;
`;

export const SelectedElem = styled.div`
  position: relative;
  width: 100%;
  padding-right: 30px;
`;

export const ClearButton = styled.div`
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.lightBiege};
  border-radius: 50%;
  cursor: pointer;
`;

export const Input = styled.input`
  width: 100%;
  outline: none;
  font-size: 16px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.black};
  border: none;
  padding-left: 50px;
  background: transparent;
  &::placeholder {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray};
  }
`;

export const Icon = styled.div`
  border: none;
  outline: none;
  display: flex;
  padding: 8px;
  background: ${({ theme }) => theme.colors.lightBiege};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 4px;
  border-radius: 50%;
`;

export const ErrorMessage = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: right;
  color: ${({ theme }) => theme.colors.red};
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 1;
`;
