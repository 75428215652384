import { DoctorIcon } from "src/UI/Svg";
import {
  AggregatedDoctorEntityResponse,
  FileEntityResponse,
} from "src/graphql/generated";
import { IconWrapper, ImageStyled, Text, UserOptionStyled } from "./styled";

interface ProfileComponentProps {
  profile: AggregatedDoctorEntityResponse;
  logoFiles?: Array<FileEntityResponse>;
}

function DoctorOptionComponent({ profile, logoFiles }: ProfileComponentProps) {
  const avatarLogo = logoFiles?.filter(
    (file) => file.id === profile?.avatarFileId
  )[0]?.url;

  return (
    <UserOptionStyled>
      <IconWrapper>
        {!avatarLogo ? (
          <DoctorIcon height={16} width={16} />
        ) : (
          <ImageStyled src={avatarLogo} alt="avatar" />
        )}
      </IconWrapper>

      <Text>
        {profile.firstName} {profile.lastName}
      </Text>
    </UserOptionStyled>
  );
}

export default DoctorOptionComponent;
