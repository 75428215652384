import styled from "styled-components";
import { Button } from "src/UI/Button";

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  flex-direction: column;
`;

export const LoadingWrap = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
`;

export const ButtonStyled = styled(Button)`
  width: auto;
  padding: 8px 10px;
  font-size: 16px;
  line-height: 100%;
  border-radius: 5px;

  svg path {
    stroke: ${({ theme }) => theme.colors.white};
  }
`;

export const List = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  padding: 30px 20px 20px;
  flex-direction: column;
  gap: 30px;
`;

export const EmptyTextStyled = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.gray};
`;
