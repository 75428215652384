import { ClinicEntityResponse } from "src/graphql/generated";
import { ClinicOptWrap, OptItemSubTitle, OptItemTitle } from "./styles";

interface ClinicOptComponentProps {
  clinic: ClinicEntityResponse;
}

const ClinicOptComponent = ({ clinic }: ClinicOptComponentProps) => {
  return (
    <ClinicOptWrap>
      <OptItemTitle>{clinic.name}</OptItemTitle>
      <OptItemSubTitle>{clinic.address}</OptItemSubTitle>
    </ClinicOptWrap>
  );
};

export default ClinicOptComponent;
