import { gql } from "@apollo/client";

export const GENERATE_2FA = gql`
  mutation Generate2FA {
    generate2FA {
      qrCodeSVG
      secret
    }
  }
`;
