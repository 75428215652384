import React, { useEffect, useMemo } from "react";
import {
  IS_PINCODE_EXIST,
  PERMISSION_2FA,
  JWT,
  IS_COMPANY_EXIST,
} from "src/constants/constants";
import {
  UserEntityResponse,
  UserRoles,
  useCheckIfCompanyExistsByUserIdLazyQuery,
  useGetCurrentUserLazyQuery,
  usePinCodeExistsByUserIdLazyQuery,
} from "src/graphql/generated";
import {
  useGetCurrentClient,
  useGetCurrentDoctor,
} from "src/graphql/hooks/useQueries";
import Cookies from "js-cookie";
import { client } from "src/graphql/client";
import { useClinicsAction } from "src/store/clinics/hooks";
import { IUserStorageItem } from "src/store/app/types";
import { toast } from "react-toastify";
import {
  CombinedUserTypeWithAdditionalKeys,
  actionTypes,
  authReducer,
} from "./reducers/authReducer";

type Props = {
  children: React.ReactNode;
};

type ContextProps = {
  currentUserData: CombinedUserTypeWithAdditionalKeys | null;
  isAuth: boolean;
  isAuth2Fa: boolean;
  isCompanyExists: boolean;
  isPinCodeExists: boolean;
  loading: boolean;
  onSuccessAuth: (stateAuth: boolean, state2FA: boolean) => void;
  onSetLocalusers: (users: IUserStorageItem[]) => void;
  onSetPinCodeExists: (val: boolean) => void;
  refetchUser: () => void;
  logout: () => void;
  isDoctor: boolean;
  isClient: boolean;
  role: string;
  localUsers: IUserStorageItem[];
};

export const AuthContext = React.createContext<ContextProps>({
  currentUserData: null,
  isAuth: false,
  isAuth2Fa: false,
  isCompanyExists: false,
  isPinCodeExists: false,
  loading: false,
  onSuccessAuth: () => {},
  onSetLocalusers: () => {},
  onSetPinCodeExists: () => {},
  refetchUser: () => {},
  logout: () => {},
  isDoctor: false,
  isClient: false,
  role: "",
  localUsers: [],
});

export function useAuthContext() {
  const authContext = React.useContext(AuthContext);

  if (!authContext) {
    throw new Error("useAuthContext must be used within a AuthProvider");
  }
  return authContext;
}

export function AuthProvider({ children }: Props) {
  const isDevelopment = process.env.REACT_APP_ENV === "develop"; // temporary condition  for development
  const isAuthDev = Cookies.get("stateAuth") === "true"; // temporary condition for development
  const { onSetClinicId, onSetLocation } = useClinicsAction();

  const [getDoctor, { loading: doctorLoading }] = useGetCurrentDoctor();
  const [getClient, { loading: clientLoading }] = useGetCurrentClient();
  const [checkIfCompanyExists] = useCheckIfCompanyExistsByUserIdLazyQuery();
  const [checkIfPinCodeExists] = usePinCodeExistsByUserIdLazyQuery();

  const [state, dispatch] = React.useReducer(authReducer, {
    isAuth: false,
    isInit: false,
    isAuth2Fa: false,
    loading: false,
    isCompanyExists: false,
    isPinCodeExists: false,
    currentUserData: null,
    localUsers: [],
  });

  const cookieJWT = Cookies.get(JWT);
  const permission2FA = Cookies.get(PERMISSION_2FA) === "true";
  const permissionCompanyExist = Cookies.get(IS_COMPANY_EXIST) === "true";
  const permissionPinCodeExist = Cookies.get(IS_PINCODE_EXIST) === "true";

  const isDoctor =
    state?.currentUserData?.roles?.includes(UserRoles.Doctor) &&
    !state?.currentUserData?.roles?.includes(UserRoles.Client);
  const isClient = state?.currentUserData?.roles?.includes(UserRoles.Client);

  const usersStorage = JSON.parse(localStorage.getItem("users") as string);

  const handleSetLocalUsers = (users: IUserStorageItem[]) => {
    localStorage.setItem("users", JSON.stringify([...users]));
    dispatch({
      type: actionTypes.SET_LOCAL_USERS,
      payload: [...users],
    });
  };

  const handleSuccessAuth = (stateAuth: boolean, state2FA: boolean) => {
    dispatch({
      type: actionTypes.SUCCESS_AUTH,
      payload: { stateAuth, state2FA },
    });
    Cookies.set(PERMISSION_2FA, `${state2FA}`, { expires: 31 });
    // temporary condition for development VVV
    if (isDevelopment) Cookies.set("stateAuth", `${stateAuth}`);
  };

  const handleSetCompanyExists = (stateCompanyExists: boolean) => {
    dispatch({
      type: actionTypes.SET_IS_EXISTS_COMPANY,
      payload: stateCompanyExists,
    });
    Cookies.set(IS_COMPANY_EXIST, `${stateCompanyExists}`);
  };

  const handleSetPinCodeExists = (statePinCodeExists: boolean) => {
    dispatch({
      type: actionTypes.SET_IS_EXISTS_PIN,
      payload: statePinCodeExists,
    });
    Cookies.set(IS_PINCODE_EXIST, `${statePinCodeExists}`);
  };

  const handleLogout = () => {
    handleSuccessAuth(false, false);
    Cookies.remove(JWT);
    Cookies.remove(PERMISSION_2FA);
    Cookies.remove(IS_COMPANY_EXIST);
    Cookies.remove(IS_PINCODE_EXIST);
    onSetClinicId("");
    onSetLocation(null);
    localStorage.removeItem("currentClinicId");
    dispatch({
      type: actionTypes.SET_USER,
      payload: null,
    });
    // temporary condition for development VVV

    if (isDevelopment) Cookies.remove("stateAuth");
    client.clearStore();
  };

  const [getUser, { loading: userLoading }] = useGetCurrentUserLazyQuery({
    onCompleted: (data) => {
      const currentUser = data?.getCurrentUser;

      if (currentUser) {
        handleSuccessAuth(true, true);

        const isUserDoctor =
          currentUser?.roles?.includes(UserRoles.Doctor) &&
          !currentUser?.roles?.includes(UserRoles.Client);
        const isUserClient = currentUser?.roles?.includes(UserRoles.Client);

        dispatch({
          type: actionTypes.SET_USER,
          payload: currentUser,
        });

        if (isUserClient) {
          getClient({
            onCompleted(response) {
              dispatch({
                type: actionTypes.UPDATE_USER,
                payload: {
                  ...response?.getCurrentClient,
                  id: currentUser?.id,
                  userId: response?.getCurrentClient?.id,
                  role: UserRoles.Client,
                },
              });
            },
            fetchPolicy: "network-only",
          });
          checkIfCompanyExists({
            onCompleted: (dataIsCompanyExists) => {
              handleSetCompanyExists(
                dataIsCompanyExists?.checkIfCompanyExistsByUserId?.exists
              );
            },
            onError: (error) => {
              if (error)
                toast.error(
                  `AUTH CONTEXT checkIfCompanyExists ${error.message}`
                );
              handleLogout();
            },
            fetchPolicy: "network-only",
          });
        }
        if (isUserDoctor) {
          getDoctor({
            onCompleted(response) {
              dispatch({
                type: actionTypes.UPDATE_USER,
                payload: {
                  ...response?.getCurrentDoctor,
                  id: currentUser?.id,
                  doctorId: response?.getCurrentDoctor.id,
                  role: UserRoles.Doctor,
                },
              });
            },
            fetchPolicy: "network-only",
          });
          handleSetCompanyExists(true);
        }
        checkIfPinCodeExists({
          onCompleted: (dataIsPinCodeExists) => {
            if (dataIsPinCodeExists) {
              handleSetPinCodeExists(
                dataIsPinCodeExists?.pinCodeExistsByUserId?.exists
              );
            }
            const isUserAlreadyInStorage = usersStorage?.some(
              (userStorage: UserEntityResponse) =>
                userStorage.id === currentUser?.id
            );

            if (usersStorage && isUserAlreadyInStorage) {
              const usersStorageWithNewToken = usersStorage?.map(
                (userStorage: UserEntityResponse) => {
                  if (userStorage?.id === currentUser?.id) {
                    return {
                      ...userStorage,
                      firstName: currentUser?.firstName,
                      lastName: currentUser?.lastName,
                      avatarFileId: currentUser?.avatarFileId,
                      jwtToken: cookieJWT,
                      isPinCodeExists:
                        dataIsPinCodeExists?.pinCodeExistsByUserId?.exists,
                    };
                  }
                  return userStorage;
                }
              );
              handleSetLocalUsers([...usersStorageWithNewToken]);
            } else {
              const newUser = {
                id: currentUser?.id,
                firstName: currentUser?.firstName,
                lastName: currentUser?.lastName,
                avatarFileId: currentUser?.avatarFileId,
                jwtToken: cookieJWT,
                isPinCodeExists:
                  dataIsPinCodeExists?.pinCodeExistsByUserId?.exists,
              };
              handleSetLocalUsers([...(usersStorage || []), newUser]);
            }
          },
          onError: (error) => {
            if (error)
              toast.error(`AUTH CONTEXT checkIfPinCodeExists ${error.message}`);
            handleLogout();
          },
          fetchPolicy: "network-only",
        });
      }
    },
    onError: (error) => {
      if (error) toast.error(`AUTH CONTEXT GET USER ${error.message}`);
      handleLogout();
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (state.isAuth && state.isAuth2Fa && !state.currentUserData) {
      getUser();
    }
  }, [state.isAuth, state.isAuth2Fa, state.currentUserData]);

  useEffect(() => {
    if (!usersStorage) {
      handleSetLocalUsers([]);
    } else {
      handleSetLocalUsers(usersStorage);
    }
    if (cookieJWT || isAuthDev) {
      handleSuccessAuth(!!cookieJWT || isAuthDev, permission2FA);
      handleSetCompanyExists(permissionCompanyExist);
      handleSetPinCodeExists(permissionPinCodeExist);
    } else {
      dispatch({ type: actionTypes.INIT });
    }
  }, []);

  const refetchUser = () => {
    dispatch({
      type: actionTypes.SET_USER,
      payload: null,
    });
    getUser();
  };

  const value = useMemo(
    () => ({
      currentUserData: state.currentUserData,
      // temporary condition isAuthDev for development VVV
      isAuth: cookieJWT || isAuthDev || state.isAuth,
      isAuth2Fa: permission2FA || state.isAuth2Fa,
      isCompanyExists: permissionCompanyExist || state.isCompanyExists,
      isPinCodeExists: state.isPinCodeExists,
      loading: doctorLoading || clientLoading || userLoading || state.loading,
      localUsers: state.localUsers,
      isDoctor,
      isClient,
      role: (state?.currentUserData as CombinedUserTypeWithAdditionalKeys)
        ?.role,
      onSuccessAuth: handleSuccessAuth,
      refetchUser,
      logout: handleLogout,
      onSetLocalusers: handleSetLocalUsers,
      onSetPinCodeExists: handleSetPinCodeExists,
    }),
    [
      state.currentUserData,
      doctorLoading || clientLoading || userLoading,
      state.isAuth,
      state.isAuth2Fa,
      state.isCompanyExists,
      state.isPinCodeExists,
      state.localUsers,
      isDoctor,
      isClient,
      refetchUser,
      handleSuccessAuth,
      handleLogout,
      handleSetLocalUsers,
      handleSetPinCodeExists,
    ]
  );

  return (
    <AuthContext.Provider value={value as ContextProps}>
      {children}
    </AuthContext.Provider>
  );
}
