import { CheckIcon } from "src/UI/Svg";

import { ChangeEvent } from "react";
import { CheckBoxStyled, Input, Label, Text } from "./styled";
import { RadioBoxRHFProps } from "../types";

function RadioBoxRHF({ id, label, disabled, value, field }: RadioBoxRHFProps) {
  const isChecked = value === field.value;
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    field.onChange(e.target.value);
  };
  return (
    <CheckBoxStyled disabled={disabled}>
      <Input
        name={field.name}
        type="radio"
        value={value}
        id={id}
        checked={isChecked}
        onChange={handleChange}
      />
      <Label htmlFor={id} isChecked={isChecked}>
        <CheckIcon width={14} height={14} />
      </Label>
      {label && <Text htmlFor={id}>{label}</Text>}
    </CheckBoxStyled>
  );
}

export default RadioBoxRHF;
