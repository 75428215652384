import { useNavigate } from "react-router-dom";
import { PATHS, allClinicsItemId } from "src/constants/constants";
import { useClinicsState } from "src/store/clinics/hooks";
import {
  AggregatedDoctorEntityResponse,
  AppointmentSortFields,
  AppointmentStatuses,
  ScheduleAppointmentsCountByStatusesResponse,
  useFindAndCountManyDoctorsByClinicIdQuery,
} from "src/graphql/generated";
import { useState } from "react";
import { useAuthContext } from "src/context/AuthContext";
import { TableScheduleAppointmentsView } from "src/views/BookingsViews";
import { IAppointmentsFilters, TScheduleAppointmentByRole } from "src/types";
import { useScheduleAppointmentsByRole } from "src/hooks/appointments/pagination/useScheduleAppointmentsByRole";
import { useCountStatusesByRole } from "src/hooks/appointments/useCountStatusesByRole";
import { toast } from "react-toastify";
import { Wrapper } from "./styled";

function PatientsPage() {
  const navigate = useNavigate();
  const { isDoctor, currentUserData } = useAuthContext();
  const { currentClinicId: clinicId } = useClinicsState();

  const [filters, setFilters] = useState<IAppointmentsFilters>({
    sortField: AppointmentSortFields.CreatedAt,
    dateFrom: "",
    dateTo: "",
    doctorId: "",
    status: "All" as AppointmentStatuses,
  });

  const { data: doctorsData, loading: doctorsLoading } =
    useFindAndCountManyDoctorsByClinicIdQuery({
      variables: {
        schema: {
          clinicId,
        },
      },
      onError: (error) => {
        if (error) {
          toast.error(error.message);
        }
      },
      fetchPolicy: "network-only",
      skip:
        isDoctor ||
        !clinicId ||
        !currentUserData ||
        clinicId === allClinicsItemId,
    });

  const {
    data,
    loading: scheduleAppointmentsByRoleLoading,
    currentPage,
    pages,
    setCurrentPage,
  } = useScheduleAppointmentsByRole({ filters });

  const { countStatusesByRole, countStatusesByRoleLoading } =
    useCountStatusesByRole({ filters });

  const handleNavigateToVisit = () => {
    navigate(`${PATHS.cp}${PATHS.booking}${PATHS.settings}${PATHS.addVisit}`);
  };

  const loading =
    scheduleAppointmentsByRoleLoading ||
    doctorsLoading ||
    countStatusesByRoleLoading;

  return (
    <Wrapper>
      <TableScheduleAppointmentsView
        appointments={data?.appointments as TScheduleAppointmentByRole[]}
        appointmentsCount={data?.count as number}
        doctors={
          doctorsData?.findAndCountManyDoctorsByClinicId
            ?.doctors as AggregatedDoctorEntityResponse[]
        }
        countStatusesByRole={
          countStatusesByRole as ScheduleAppointmentsCountByStatusesResponse & {
            [key: string]: number;
          }
        }
        filters={filters}
        pages={pages}
        setFilters={setFilters}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        handleNavigateToVisit={handleNavigateToVisit}
        loading={loading}
      />
    </Wrapper>
  );
}

export default PatientsPage;
