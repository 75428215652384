import { FieldError, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InputRHF } from "src/UI/Input";
import { DeleteIcon } from "src/UI/Svg";
import { ICheckboxValue } from "src/types";
import { Actions, ButtonIcon, CheckBox, RowInput } from "../styled";

interface IFormComponentCheckboxItemProps {
  translation: string;
  translationsIndex: number;
  isHideDeleteButton?: boolean;
  order: number;
  value: {
    text: string;
    type: string;
    order: number;
  };
}

function FormComponentCheckboxItem({
  translation,
  order,
  translationsIndex,
  value,
  isHideDeleteButton,
}: IFormComponentCheckboxItemProps) {
  const { t } = useTranslation();
  const {
    watch,
    setValue,
    register,
    formState: { errors },
  } = useFormContext();

  const [fields, valueCurrentLang, valueOptionalLang] = watch([
    "fields",
    `fields.${order}.translations.0.value`,
    `fields.${order}.translations.1.value`,
  ]);

  const removeCheckBoxHandler = (id: number) => () => {
    const newChecboxesValueCurrentLang = valueCurrentLang?.reduce(
      (acc: ICheckboxValue[], current: ICheckboxValue) => {
        if (current.order !== id) {
          acc.push({ ...current, order: acc.length });
        }

        return acc;
      },
      []
    );

    const newChecboxesValueOptionalLang = valueOptionalLang.reduce(
      (acc: ICheckboxValue[], current: ICheckboxValue) => {
        if (current.order !== id) {
          acc.push({ ...current, order: acc.length });
        }

        return acc;
      },
      []
    );

    setValue(
      `fields.${order}.translations.0.value`,
      newChecboxesValueCurrentLang
    );
    setValue(
      `fields.${order}.translations.1.value`,
      newChecboxesValueOptionalLang
    );
  };

  const isShowDeleteButton = (index: number) => {
    return (
      valueOptionalLang?.length! > 1 &&
      !(isHideDeleteButton && index <= (fields[order].defaultCountValues || 0))
    );
  };

  const filedValue =
    fields[order]?.translations[translationsIndex]?.value?.[value.order];
  const fieldError =
    translationsIndex === 0
      ? errors?.checkBoxTextErrorMessage
      : errors?.checkBoxTextOptionalTranslationErrorMessage;

  return (
    <RowInput key={`${value.order}-translation`}>
      <CheckBox />
      <InputRHF
        val={filedValue?.text || ""}
        inputType="text"
        label={
          filedValue.type === "another"
            ? t("forms.modals.checkbox.another_title", {
                lng: translation as string,
              })
            : value.order === 0
            ? t("forms.modals.checkbox.first", {
                lng: translation as string,
              })
            : (t("forms.modals.checkbox.next", {
                lng: translation as string,
              }) as string)
        }
        errorMessage={
          fieldError && !filedValue.text
            ? (fieldError as FieldError)
            : undefined
        }
        register={register(
          `fields.${order}.translations.${translationsIndex}.value.${value.order}.text`
        )}
      />

      {valueCurrentLang.length > 1 ? (
        <Actions>
          {isShowDeleteButton(value.order) && (
            <ButtonIcon
              type="button"
              onClick={removeCheckBoxHandler(value.order)}
            >
              <DeleteIcon />
            </ButtonIcon>
          )}
        </Actions>
      ) : null}
    </RowInput>
  );
}

export default FormComponentCheckboxItem;
