import * as yup from "yup";

export const loginValidationSchema = yup.object().shape({
  email: yup
    .string()
    .matches(
      /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      "auth.login.email_error"
    )
    .required("validation.field_required"),
  password: yup
    .string()
    .min(8, "auth.reg.password_error_min")
    .required("validation.field_required"),
});
