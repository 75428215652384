import React from "react";
import { useTranslation } from "react-i18next";

import { useAuthContext } from "src/context/AuthContext";
import { TScheduleAppointmentByRole } from "src/types";

import { Loading } from "src/UI/Loading";
import { EmptyTextStyled, Table } from "./styled";
import RowComponent from "./RowComponent";

interface IScheduleAppointmentsTableComponentProps {
  scheduleAppointmentsList: TScheduleAppointmentByRole[];
  handleShowModal: (id: string) => () => void;
  loading: boolean;
}

function ScheduleAppointmentsTableComponent({
  scheduleAppointmentsList,
  handleShowModal,
  loading,
}: IScheduleAppointmentsTableComponentProps) {
  const { t } = useTranslation();
  const { isDoctor } = useAuthContext();

  return loading ? (
    <Loading padding="0" />
  ) : scheduleAppointmentsList?.length ? (
    <Table>
      <thead>
        <tr>
          <th>{t("booking.patients.table.patient_name")}</th>
          <th>{t("booking.patients.table.birth")}</th>
          <th>{t("booking.patients.table.cellphone")}</th>
          <th>{t("booking.patients.table.email")}</th>
          <th>{t("booking.patients.table.appt_status")}</th>
          <th>{t("booking.patients.table.start")}</th>
          {!isDoctor ? <th>{t("booking.patients.table.provider")}</th> : null}
          <th>{t("booking.patients.table.service_name")}</th>
        </tr>
      </thead>
      <tbody>
        {scheduleAppointmentsList?.map((appointment) => (
          <RowComponent
            key={`Patient-${appointment?.id}`}
            appointment={appointment}
            handleShowModal={handleShowModal}
          />
        ))}
      </tbody>
    </Table>
  ) : (
    <EmptyTextStyled>{t("app.appointments_not_added")}</EmptyTextStyled>
  );
}

export default ScheduleAppointmentsTableComponent;
