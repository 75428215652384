import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { DistributionActions } from "src/graphql/generated";
import { DEFAULT_TEMPLATE_ACTIONS } from "src/constants/constants";
import { useTemplateContext } from "src/context/TemplatesContext";
import { ITemplateAction, ITemplateCreateEdit } from "src/types/templates";
import { CloseIcon, MessageIcon, SmartphoneIcon } from "src/UI/Svg";
import {
  CloseButton,
  FieldItem,
  List,
  StyledTemplatesComponentWrapper,
  Title,
} from "./styled";

const actionIcons: { [n: string]: ReactNode } = {
  [DistributionActions.SendMail]: <MessageIcon />,
  [DistributionActions.SendSms]: <SmartphoneIcon />,
};

function TemplatesComponentSubmitActions() {
  const { t } = useTranslation();
  const { onShowAddNewAction } = useTemplateContext();
  const { watch, setValue } = useFormContext<ITemplateCreateEdit>();

  const templateActions = watch("templateActions");
  const handleClose = () => onShowAddNewAction(false);

  const handleAddAction =
    (template: ITemplateAction, isSelected: boolean) => () => {
      if (!isSelected && templateActions) {
        setValue("templateActions", [...templateActions, template]);
      }
    };

  return (
    <StyledTemplatesComponentWrapper>
      <Title>
        {t("templates.action.select")}

        <CloseButton onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
      </Title>

      <List>
        {DEFAULT_TEMPLATE_ACTIONS.map((action) => {
          const isSelected = !!templateActions?.find(
            (selected) => selected.name === action.name
          );
          const icon = actionIcons[action.name];

          return (
            <FieldItem
              isSelected={isSelected}
              onClick={handleAddAction(action, isSelected)}
              key={action.name}
            >
              {icon}
              {t(action.titleId)}
            </FieldItem>
          );
        })}
      </List>
    </StyledTemplatesComponentWrapper>
  );
}

export default TemplatesComponentSubmitActions;
