import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HeaderCover } from "src/UI/Header";
import { LangSelectComponent } from "src/components/LangSelectComponets";
import { ChevronIcon, LogoFullIcon } from "src/UI/Svg";
import { Text } from "src/UI/Text";
import { Heading } from "src/UI/Heading";
import { InputComponent } from "src/components/AuthComponents";
import { EButtonVariants } from "src/UI/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import { passwordRepeatValidationSchema } from "src/validation/schemas/passwordRepeatValidationSchema";
import { useRestorePassword } from "src/graphql/hooks/useMutations";
import { toast } from "react-toastify";
import { Loading } from "src/UI/Loading";
import { PATHS } from "src/constants/constants";
import {
  Breadcrumb,
  ButtonStyled,
  FormFA,
  FormWrapper,
  TitleBlock,
  WrapperNav,
} from "../styled";

interface FormValues {
  password: string;
  passwordRepeat: string;
}

function NewPassword() {
  const { t } = useTranslation();
  const [restorePass, { loading }] = useRestorePassword();

  const [query] = useSearchParams();
  const confirmationHash = query.get("confirmationHash");

  const {
    register,
    trigger,
    formState: { errors, isValid },
    watch,
    handleSubmit,
  } = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(passwordRepeatValidationSchema),
    defaultValues: {
      password: "",
      passwordRepeat: "",
    },
  });
  const data = watch();

  const navigate = useNavigate();
  const formHandler = (formData: FormValues) => {
    restorePass({
      variables: {
        schema: {
          confirmationHash,
          password: formData?.password,
        },
      },
      onCompleted() {
        toast.success(t("auth.reg.password_updated"));
        navigate("/auth/login");
      },
      onError(error) {
        toast.error(error.message);
      },
    });
  };

  return (
    <>
      <WrapperNav>
        <LogoFullIcon />
      </WrapperNav>
      <FormWrapper>
        <HeaderCover>
          <Breadcrumb to={`${PATHS.auth}${PATHS.restore}${PATHS.password}`}>
            <ChevronIcon />
            <Text>{t("common.back")}</Text>
          </Breadcrumb>
          <LangSelectComponent />
        </HeaderCover>
        <FormFA onSubmit={handleSubmit(formHandler)}>
          <TitleBlock>
            <Heading as="h2">{t("restore.new_password.title")}</Heading>
          </TitleBlock>
          <InputComponent
            placeholder={t("restore.new_password.first")}
            inputType="password"
            val={data.password}
            errorMessage={errors.password}
            register={register("password")}
            handleChange={() => trigger("passwordRepeat")}
          />
          <InputComponent
            placeholder={t("restore.new_password.second")}
            inputType="password"
            val={data.passwordRepeat}
            errorMessage={errors.passwordRepeat}
            register={register("passwordRepeat")}
            handleChange={() => trigger("passwordRepeat")}
          />
          <ButtonStyled
            variant={EButtonVariants.Default}
            disabled={!isValid || loading}
          >
            {loading && <Loading type="secondary" padding="0" />}
            {t("restore.new_password.submit")}
          </ButtonStyled>
        </FormFA>
      </FormWrapper>
    </>
  );
}

export default NewPassword;
