import { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { transformBeforeEditPatientBookingStep3 } from "src/connectors/PatientBooking/transformBeforeEditpatientBookingStep2";
import { transformPatientInfo } from "src/connectors/PatientBooking/transformPatientInfo";
import {
  AggregatedDoctorEntityResponse,
  SlotResponse,
  SlotsGroupedByDateResponse,
  useFindBlankFormByServiceIdLazyQuery,
  useFindManyAggregatedDoctorsByServiceIdLazyQuery,
  useFindManyAvailableSlotsByDateRangeLazyQuery,
  useFindScheduleWaitlistByHashByPatientLazyQuery,
} from "src/graphql/generated";
import { useBookingAction, useBookingState } from "src/store/booking/hooks";
import { SelectedBookingFormFields } from "src/store/booking/types";

export function useDoctorsEffect(
  setDoctors: Dispatch<SetStateAction<AggregatedDoctorEntityResponse[]>>,
  setSlots: Dispatch<SetStateAction<SlotsGroupedByDateResponse[]>>,
  setAvailableDates: Dispatch<SetStateAction<string[]>>,
  setSelectedDate: Dispatch<SetStateAction<string>>
) {
  const { i18n } = useTranslation();
  const { onSetDoctor, onSetPatientInfo, onSetFormFields } = useBookingAction();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const convertWaitlistHash = queryParams.get("convertWaitlistHash");
  const { currentClinic, currentService } = useBookingState();

  const [getDoctorsByServiceId] =
    useFindManyAggregatedDoctorsByServiceIdLazyQuery();

  const [getWaitingAppointment] =
    useFindScheduleWaitlistByHashByPatientLazyQuery();

  const [getAvailableSlotsByDateRange] =
    useFindManyAvailableSlotsByDateRangeLazyQuery();

  const [getBlankFormById] = useFindBlankFormByServiceIdLazyQuery();

  useEffect(() => {
    if (currentClinic?.clinic?.id && currentService?.service?.id) {
      getDoctorsByServiceId({
        variables: {
          schema: {
            serviceId: currentService?.service?.id,
          },
        },
        onCompleted(response) {
          if (convertWaitlistHash) {
            getWaitingAppointment({
              variables: { schema: { hash: convertWaitlistHash } },
              onCompleted: async (responseApptByHash) => {
                const formBlank = await getBlankFormById({
                  variables: {
                    schema: { serviceId: currentService?.service.id },
                  },
                  fetchPolicy: "network-only",
                });
                const appointment =
                  responseApptByHash?.findScheduleWaitlistByHashByPatient;

                onSetFormFields(
                  formBlank?.data?.findBlankFormByServiceId?.fields?.length
                    ? (transformBeforeEditPatientBookingStep3(
                        formBlank?.data?.findBlankFormByServiceId?.fields,
                        i18n.resolvedLanguage,
                        appointment?.answer?.answers?.map(
                          (field) => field.value
                        )
                      ) as SelectedBookingFormFields)
                    : null
                );

                setDoctors([appointment?.doctor]);
                onSetDoctor({
                  doctor: appointment?.doctor,
                });
                onSetPatientInfo({
                  patientInfo: transformPatientInfo(appointment?.patient),
                });
                getAvailableSlotsByDateRange({
                  variables: {
                    schema: {
                      serviceId: appointment?.serviceId,
                      doctorId: appointment?.doctor?.doctorId,
                      dateFrom: appointment?.dateFrom,
                      dateTo: appointment?.dateTo,
                    },
                  },
                  onCompleted: (responseAvailableSlots) => {
                    if (
                      responseAvailableSlots?.findManyAvailableSlotsByDateRange
                    ) {
                      const slots =
                        responseAvailableSlots.findManyAvailableSlotsByDateRange
                          .availableSlots;

                      const filterSlotsByTime = (slots: SlotResponse[]) => {
                        return slots.filter((slot) => {
                          const slotStartDate = new Date(slot.from);
                          return slotStartDate >= new Date(); // Оставляем только те слоты, которые начинаются не ранее текущего времени
                        });
                      };

                      const filteredSlotsData = slots.map((slotsGroup) => ({
                        ...slotsGroup,
                        slots: filterSlotsByTime(slotsGroup.slots), // Применяем фильтрацию для каждого объекта "SlotsGroupedByDateResponse"
                      }));

                      const filteredDatesData = filteredSlotsData.filter(
                        (date) => date?.slots?.length
                      );

                      const availableDates = filteredDatesData?.map(
                        (item: SlotsGroupedByDateResponse) => {
                          return item.date;
                        }
                      );
                      setAvailableDates(availableDates);
                      setSlots(filteredDatesData);
                      setSelectedDate(availableDates?.[0]);
                    }
                  },
                });
              },
              fetchPolicy: "no-cache",
              onError: (error) => {
                if (error) toast.error(error.message);
              },
            });
          } else {
            setDoctors(response?.findManyAggregatedDoctorsByServiceId?.doctors);
          }
        },
        onError: (error) => {
          if (error) toast.error(error.message);
        },
        fetchPolicy: "network-only",
      });
    }
  }, [currentClinic?.clinic?.id, currentService?.service?.id]);
}
