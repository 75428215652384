import { Dispatch, SetStateAction, useEffect } from "react";
import { toast } from "react-toastify";
import { transformDoctorResponseToEventsArray } from "src/connectors/Visits/transformDoctorResponseToEventsArray";
import { allClinicsItemId } from "src/constants/constants";
import { useAuthContext } from "src/context/AuthContext";
import {
  AppointmentSortFields,
  AppointmentStatuses,
  CountedAggregatedScheduleAppointmentEntitiesWithRelationsByDoctorResponse,
  Query,
  SortingOrders,
  useFindAndCountManyScheduleAppointmentsWithRelationsByDoctorLazyQuery,
  useFindManyFilesByIdsLazyQuery,
} from "src/graphql/generated";
import { IEvent } from "src/pages/DashboardPages/CalendarPage/types";
import { useCalendarState } from "src/store/calendar/hooks";
import { useClinicsState } from "src/store/clinics/hooks";

export function useCalendarScheduleAppointmentsByDoctorEffect(
  setEvents: Dispatch<SetStateAction<IEvent[]>>
) {
  const { isDoctor, currentUserData } = useAuthContext();
  const { filters } = useCalendarState();
  const { currentClinicId: clinicId } = useClinicsState();

  const [getFiles] = useFindManyFilesByIdsLazyQuery();

  const [getScheduleAppointmentsByDoctorLazy] =
    useFindAndCountManyScheduleAppointmentsWithRelationsByDoctorLazyQuery();

  useEffect(() => {
    if (isDoctor && clinicId) {
      getScheduleAppointmentsByDoctorLazy({
        variables: {
          schema: {
            ...(clinicId !== allClinicsItemId && { clinicId }),
            sortField: AppointmentSortFields.ScheduleTimeFrom,
            sortOrder: SortingOrders.Ascending,
            dateFrom: filters.dateFrom,
            dateTo: filters.dateTo,
            ...(filters?.roomIds?.length && { roomIds: filters.roomIds }),
            ...(filters?.patientId?.length && {
              patientId: filters.patientId,
            }),
            ...(filters?.status?.length && {
              status: filters.status as AppointmentStatuses,
            }),
          },
        },
        onCompleted(response) {
          if (response) {
            const eventsData =
              response?.findAndCountManyScheduleAppointmentsWithRelationsByDoctor;
            const eventsArray = transformDoctorResponseToEventsArray(
              eventsData as CountedAggregatedScheduleAppointmentEntitiesWithRelationsByDoctorResponse,
              currentUserData
            );

            setEvents(eventsArray);

            if (eventsData?.appointments?.length) {
              if (currentUserData?.avatarFileId) {
                getFiles({
                  variables: {
                    schema: {
                      ids: [currentUserData?.avatarFileId],
                    },
                  },
                  onCompleted(responseFiles) {
                    if (responseFiles?.findManyFilesByIds) {
                      setEvents((prev) =>
                        prev.map((event) => ({
                          ...event,
                          logoFiles: responseFiles as Query,
                        }))
                      );
                    }
                  },
                  fetchPolicy: "network-only",
                });
              }
            }
          }
        },
        fetchPolicy: "no-cache",
        onError: (error) => {
          if (error) toast.error(error.message);
        },
      });
    }
  }, [clinicId, filters, isDoctor]);
}
