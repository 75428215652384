import { AggregatedDoctorEntityResponse } from "src/graphql/generated";

export const transformBeforeEditDoctor = (
  doctorResponse: AggregatedDoctorEntityResponse,
  clinicsIds: string[]
) => {
  if (doctorResponse) {
    return {
      description: doctorResponse?.description || "",
      doctorId: doctorResponse?.doctorId || "",
      doctorTypeId: doctorResponse?.doctorTypeId || "",
      email: doctorResponse?.email || "",
      firstName: doctorResponse?.firstName || "",
      id: doctorResponse?.id || "",
      lastName: doctorResponse?.lastName || "",
      medicalCertificateNumber: doctorResponse?.medicalCertificateNumber || "",
      passportNumber: doctorResponse?.passportNumber || "",
      phoneNumber: doctorResponse?.phoneNumber.slice(1) || "",
      promoCode: doctorResponse?.promoCode || "",
      activated: doctorResponse?.activated || "",
      createdAt: doctorResponse?.createdAt || "",
      doctorCreatedAt: doctorResponse?.doctorCreatedAt || "",
      roles: doctorResponse.roles || "",
      clinicsIds,
    };
  }
};
