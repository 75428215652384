import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;

  * {
    color: unset;
    text-align: unset;
  }

  & .go-draft-wrapper {
    background-color: ${({ theme }) => theme.colors.white};
  }

  & .go-draft-toolbar {
    border: none;
    padding-top: 0;
    border-radius: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  }

  & .rdw-right-aligned-block {
    text-align: right;
  }

  & .rdw-image-wrapper,
  .rdw-link-wrapper {
    margin-bottom: 0;
  }

  & .rdw-inline-wrapper,
  .rdw-text-align-wrapper,
  .rdw-option-wrapper,
  .rdw-list-wrapper {
    border-right: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
    margin-right: 15px;
    margin-bottom: 0;
  }

  & .rdw-option-wrapper:not(.rdw-colorpicker-option) {
    padding: 3px;
    border: 1px solid transparent;
    min-width: fit-content;
    height: fit-content;
    margin: 0;
    margin-right: 15px;
    transition: 0.3s;

    &:hover * {
      transition: 0.3s;
    }

    & * {
      transition: 0.3s;
    }

    &.rdw-option-active {
      transition: 0.3s;
      border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
    }
  }
  & .rdw-editor-main {
    padding: 18px;
  }
`;
