import { EActivityComponentSize } from "src/types/enum";
import styled from "styled-components";

export const PatientFormPreview = styled.div`
  width: 100%;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 16px;
  display: flex;
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 12px;
  }
`;

interface IFieldElementViewProps {
  $isShowTopBorder?: boolean;
  $isShowBotBorder?: boolean;
}

export const FieldElementView = styled.div<IFieldElementViewProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;

  padding-top: ${({ $isShowTopBorder }) => ($isShowTopBorder ? "12px" : "0")};
  padding-bottom: ${({ $isShowBotBorder }) =>
    $isShowBotBorder ? "12px" : "0"};
  border-top: ${({ $isShowTopBorder, theme }) =>
    $isShowTopBorder ? `1px solid${theme.colors.grayC4}` : "none"};
  border-bottom: ${({ $isShowBotBorder, theme }) =>
    $isShowBotBorder ? `1px solid${theme.colors.grayC4}` : "none"};
`;

export const FieldElementViewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 6px;
`;

interface IFieldNameProps {
  $isWithMinWith?: boolean;
  $size?: EActivityComponentSize;
}

export const FieldName = styled.span<IFieldNameProps>`
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray};
  min-width: ${({ $isWithMinWith }) => ($isWithMinWith ? "100px" : "auto")};
  width: ${({ $isWithMinWith }) => ($isWithMinWith ? "100px" : "auto")};
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: ${({ $size }) => {
      switch ($size) {
        case EActivityComponentSize.mid:
          return "14px";
        default:
          return "12px";
      }
    }};
  }
`;

interface IFieldValueProps {
  $size?: EActivityComponentSize;
}

export const FieldTextareaValue = styled.pre<IFieldValueProps>`
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;

  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: ${({ $size }) => {
      switch ($size) {
        case EActivityComponentSize.mid:
          return "14px";
        default:
          return "12px";
      }
    }};
  }
`;

export const FieldValue = styled.span<IFieldValueProps>`
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: ${({ $size }) => {
      switch ($size) {
        case EActivityComponentSize.mid:
          return "14px";
        default:
          return "12px";
      }
    }};
  }
`;
