import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import styled, { useTheme } from "styled-components";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { InputProps, PhoneValidProp } from "./types";

interface InputPropsWithError extends InputProps {
  errorMessage?: string | undefined;
  isValidCallback?: () => () => boolean;
}

const InputBlock = styled.div<{ isError: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;

  .form-control {
    border-bottom: 1px solid
      ${({ theme, isError }) =>
        theme.colors[isError ? "red" : "borderInputDefault"]} !important;
  }

  .form-control:focus {
    border-color: ${({ theme, isError }) =>
      theme.colors[isError ? "red" : "green"]} !important;
  }
`;
// const Label = styled.label`
//   color: ${({ theme }) => theme.colors.grayC4};
//   position: absolute;
//   top: 0;
//   transform: translateY(-50%);
//   left: 0;
//   font-weight: 500;
//   font-size: 12px;
//   line-height: 100%;
//   z-index: 2;
// `;
const ErrorMessage = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: block;
  text-align: left;
  color: ${({ theme }) => theme.colors.red};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 12px;
  },
`;

const Input: React.ForwardRefRenderFunction<
  PhoneValidProp,
  InputPropsWithError
> = (
  { onChange, value, label, errorMessage, isValidCallback },
  forwardedRef
) => {
  console.log(isValidCallback);
  const { colors } = useTheme();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const isErrorShow = useMemo(
    () => !isValid && isChanged,
    [isValid, isChanged]
  );

  const wrapper = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!isFocused && value) {
      setIsFocused(!!value);
    }
  }, [value, isFocused]);

  useImperativeHandle(forwardedRef, () => ({
    isPhoneValid: () => isValid,
  }));

  const handleChange = (
    value: string,
    country: any,
    e: React.ChangeEvent,
    formattedValue: string
  ) => {
    setIsChanged(true);
    onChange(value);
    const { format, dialCode } = country;
    if (
      format?.length === formattedValue?.length &&
      (value.startsWith(dialCode) || dialCode.startsWith(value))
    ) {
      setIsValid(true);
    } else setIsValid(false);
  };

  return (
    <InputBlock ref={wrapper} isError={isErrorShow}>
      {isErrorShow && errorMessage && (
        <ErrorMessage>{errorMessage}</ErrorMessage>
      )}
      <PhoneInput
        country="pl"
        value={value}
        onChange={handleChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder={label}
        inputProps={{
          required: true,
        }}
        inputStyle={{
          width: "100%",
          border: "none",
          outline: "none",
          fontWeight: 500,
          fontSize: 16,
          lineHeight: "140%",
          color: colors.black,
          borderBottom: `1px solid ${colors.borderInputDefault}`,
          padding: `38px 0px 37px 26px`,
          borderRadius: 0,
        }}
        buttonClass="phone-button"
      />
    </InputBlock>
  );
};

export default React.forwardRef(Input);
