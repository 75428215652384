import { LanguageIsoCodes } from "src/graphql/generated";
import {
  IFieldStep3,
  PatientBookingInfo,
} from "src/pages/PatientBookingPages/StepsPage/types";
import {
  SelectedBookingClinic,
  SelectedBookingDoctor,
  SelectedBookingService,
  SelectedBookingSlot,
  SelectedDate,
} from "src/store/booking/types";
import { getMinutesFromStartOfDayWithoutTimeZoneOffset } from "src/utils";

export const transformReqSchemaCreateScheduleAppoint = (
  currentDate: SelectedDate,
  currentSlot: SelectedBookingSlot,
  currentClinic: SelectedBookingClinic,
  currentDoctor: SelectedBookingDoctor,
  currentService: SelectedBookingService,
  formattedFields: IFieldStep3[] | undefined,
  patientInfoSelected: PatientBookingInfo | undefined,
  formAnswers:
    | {
        fieldId: string;
        order: number;
        value: string;
      }[]
    | undefined,
  languageISOCode: LanguageIsoCodes,
  convertWaitlistHash?: string | null
) => {
  return {
    clinicId: currentClinic?.clinic.id,
    date: currentDate?.currentDate,
    doctorId: currentDoctor?.doctor.doctorId,
    ...(formattedFields?.length && {
      formAnswer: {
        answers: formAnswers,
        formId: formattedFields[0]?.formId,
      },
    }),

    minuteFrom: getMinutesFromStartOfDayWithoutTimeZoneOffset(
      currentSlot?.slot.from as string
    ),
    minuteTo: getMinutesFromStartOfDayWithoutTimeZoneOffset(
      currentSlot?.slot.to as string
    ),
    patient: {
      ...patientInfoSelected,
      address: patientInfoSelected?.address?.description,
      phoneNumber: `+${patientInfoSelected?.phoneNumber}`,
    },
    serviceId: currentService?.service.id,
    languageISOCode,
    ...(convertWaitlistHash && { waitlistHash: convertWaitlistHash }),
  };
};
