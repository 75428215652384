import { FC } from "react";
import { format } from "date-fns";
import {
  getDateWithoutTimeZoneOffset,
  getMinutesFromStartOfDayWithoutTimeZoneOffset,
} from "src/utils";
import { useTranslation } from "react-i18next";
import { EButtonVariants } from "src/UI/Button";
import { SlotResponse } from "src/graphql/generated";
import { useBookingAction } from "src/store/booking/hooks";
import { useRescheduleScheduleAppointmentByPatient } from "src/graphql/hooks/useMutations";
import { toast } from "react-toastify";
import { PATHS } from "src/constants/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { ButtonStyled, SlotsListStyled } from "./styles";
import { ComponentProps } from "./types";

const SlotsListComponent: FC<ComponentProps> = ({
  slots,
  doctor,
  selectedDate,
  onSetCurrentStep,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const hash = queryParams.get("hash");

  const { onSetDoctor, onSetSlot, onSetDate, onSetWaitListSlot } =
    useBookingAction();

  const [resheduleApptByPatientMutation, { loading }] =
    useRescheduleScheduleAppointmentByPatient();

  const handleSelectSlot = (slot: SlotResponse) => () => {
    onSetDate({ currentDate: selectedDate as string });
    onSetDoctor({ doctor });
    onSetSlot({ slot });
    onSetWaitListSlot(null);

    if (hash) {
      resheduleApptByPatientMutation({
        variables: {
          schema: {
            date: selectedDate,
            hash,
            minuteFrom: getMinutesFromStartOfDayWithoutTimeZoneOffset(
              slot.from as string
            ),
            minuteTo: getMinutesFromStartOfDayWithoutTimeZoneOffset(
              slot.to as string
            ),
            doctorId: doctor.doctorId,
          },
        },
        onCompleted: () => {
          toast.success(t("app.successful"));
          const searchParams = new URLSearchParams({
            hash,
          });
          navigate({
            pathname: `${PATHS.patientBooking}${PATHS.detailsAppointment}`,
            search: searchParams.toString(),
          });
        },
      });
    } else {
      onSetCurrentStep((prev) => prev + 1);
    }
  };

  return (
    <SlotsListStyled>
      {slots?.map((slot: SlotResponse) => {
        return (
          <ButtonStyled
            key={slot?.from}
            variant={EButtonVariants.Green}
            type="button"
            onClick={handleSelectSlot(slot)}
            disabled={loading}
          >
            {format(getDateWithoutTimeZoneOffset(slot?.from), "HH:mm a")}
          </ButtonStyled>
        );
      })}
    </SlotsListStyled>
  );
};

export default SlotsListComponent;
