import { useCallback, useMemo, useRef } from "react";
import {
  AddAppointmentMethod,
  IAppointmentTimes,
} from "../context/AddAppointment";
import { EModals } from "../store/app/types";
import { useAppAction } from "../store/app/hooks";

export function useNewAppointment() {
  const { onShowModal } = useAppAction();
  const appointmentTimes = useRef<IAppointmentTimes>({
    from: null,
    to: null,
  });
  const addAppointment: AddAppointmentMethod = useCallback((from, to) => {
    if (from) appointmentTimes.current.from = from;
    if (to) appointmentTimes.current.to = to;
    onShowModal(EModals.NewEvent);
  }, []);
  const clearTimes = useCallback(() => {
    appointmentTimes.current.from = null;
    appointmentTimes.current.to = null;
  }, []);

  return useMemo(
    () => ({
      addAppointment,
      clearTimes,
      appointmentTimes,
    }),
    []
  );
}
