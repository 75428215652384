import { LangFlags, PATHS } from "src/constants/constants";
import { useAuthContext } from "src/context/AuthContext";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import {
  ClinicEntityResponse,
  ServiceEntityWithRelationsResponse,
  ServiceSortFields,
  SortingOrders,
  useFindAndCountManyClinicsByClientIdLazyQuery,
  useFindManyServicesByPatientLazyQuery,
} from "src/graphql/generated";
import { toast } from "react-toastify";
import { ApolloError } from "@apollo/client";
import { WidgetsListView } from "src/views/BookingsViews";
import { TabsStyled, Wrapper } from "./styles";

const WidgetsPage = () => {
  const { t } = useTranslation();
  const { currentUserData } = useAuthContext();

  const [clinics, setClinics] = useState<ClinicEntityResponse[]>([]);
  const [services, setServices] = useState<
    {
      clinicId: string;
      services: ServiceEntityWithRelationsResponse[];
    }[]
  >([]);

  const [getClinics] = useFindAndCountManyClinicsByClientIdLazyQuery();

  const [getServices] = useFindManyServicesByPatientLazyQuery();

  useEffect(() => {
    if (currentUserData) {
      const fetchClinicsAndServices = async () => {
        try {
          const clinicsResponse = await getClinics({
            variables: {
              schema: {
                clientId: currentUserData?.userId as string,
              },
            },
          });

          const clinicsList =
            clinicsResponse?.data?.findAndCountManyClinicsByClientId?.clinics;

          const servicePromises = clinicsList?.map(async (clinic) => {
            const servicesResponse = await getServices({
              variables: {
                schema: {
                  sortField: ServiceSortFields.CreatedAt,
                  sortOrder: SortingOrders.Descending,
                  clinicId: clinic.id,
                },
              },
            });
            return {
              clinicId: clinic.id,
              services:
                servicesResponse?.data?.findManyServicesByPatient?.services,
            };
          });

          const servicesList = await Promise.all(servicePromises || []);

          setClinics(clinicsList || []);
          setServices((servicesList as any) || []);
        } catch (error) {
          if (error instanceof ApolloError) {
            if (error.message) {
              toast.error(error.message);
            } else {
              toast.error("An error occurred");
            }
          }
        }
      };

      fetchClinicsAndServices();
    }
  }, [currentUserData]);

  const clinicsPageLinks = [
    {
      path: `${process.env.REACT_APP_API_HOST}${PATHS.patientBooking}${PATHS?.clinics}?clientId=${currentUserData?.userId}`,
      name: null,
    },
  ];
  const servicesPageLinks = clinics?.map((clinic) => {
    return {
      path: `${process.env.REACT_APP_API_HOST}${PATHS.patientBooking}${PATHS?.services}?clientId=${currentUserData?.userId}&clinicId=${clinic?.id}`,
      name: clinic.name,
    };
  });

  const doctorsPageLinks: { path: string; name: string }[] = services.reduce(
    (links: { path: string; name: string }[], clinic) => {
      clinic.services.forEach((service) => {
        links.push({
          path: `${process.env.REACT_APP_API_HOST}${PATHS.patientBooking}${PATHS.steps}?clientId=${currentUserData?.userId}&clinicId=${clinic.clinicId}&serviceId=${service.id}`,
          name: service.name,
        });
      });
      return links;
    },
    []
  );

  return (
    <Wrapper>
      <TabsStyled
        headers={[
          <>
            {t("map.title")}{" "}
            <span>{clinicsPageLinks.length * LangFlags.length}</span>
          </>,
          <>
            {t("booking.services.title")}{" "}
            <span>{servicesPageLinks.length * LangFlags.length}</span>
          </>,
          <>
            {t("menu.clinic")}{" "}
            <span>{doctorsPageLinks.length * LangFlags.length}</span>
          </>,
        ]}
        contents={[
          <WidgetsListView links={clinicsPageLinks} />,
          <WidgetsListView links={servicesPageLinks} />,
          <WidgetsListView links={doctorsPageLinks} />,
        ]}
      />
    </Wrapper>
  );
};

export default WidgetsPage;
