import { Button } from "src/UI/Button";
import styled from "styled-components";

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.background};
  padding: 0 20px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 20px;

  thead {
  }

  tbody tr {
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
    &:last-child {
      border-bottom: none;
    }
  }

  th {
    padding: 20px 0 5px 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.grayC4};
    text-align: left;

    &:last-child {
    }
  }

  th:first-child,
  td:first-child {
    width: 80px;
  }

  td {
    padding: 30px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black};
    text-align: left;

    &:first-child {
      color: ${({ theme }) => theme.colors.gray};
    }

    &:last-child {
    }
  }
`;

export const StyledButtonIcon = styled(Button)`
  background: none;
  padding: 0;
  border: none;
  justify-content: flex-start;

  svg {
    width: 16px;
    height: 16px;

    path {
      stroke: ${({ theme }) => theme.colors.gray};
    }
  }

  &:not(:disabled):hover {
    background-color: transparent;
  }
`;

export const StyledButton = styled(Button)`
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  width: auto;
  height: auto;
  text-align: start;
  color: ${({ theme }) => theme.colors.black};

  &:not(:disabled):hover {
    background-color: transparent;
  }
`;
