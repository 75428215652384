import styled from "styled-components";

export const PhoneBlock = styled.div<{ isShowFlag: boolean }>`
  & .react-tel-input {
    display: flex;
    flex-direction: ${({ isShowFlag }) => (isShowFlag ? "row-reverse" : "row")};

    & :disabled {
      cursor: text;
    }
    & .form-control {
      background: none;
      padding: 0;
      border: none;
      line-height: unset;
      height: auto;
      font-size: 16px;
      width: fit-content;
    }
    & .flag-dropdown {
      display: ${({ isShowFlag }) => (isShowFlag ? "block" : "none")};
      border: none;
      position: static;
      background: none;
      margin-right: 5px;

      & .arrow {
        display: none;
      }

      & .selected-flag {
        padding: 0;
        width: 25px;
        height: 25px;
        cursor: default;
        border-radius: 50%;
        background: #f8f8f8;

        & .flag {
          position: absolute;
          top: 50%;
          left: 50%;
          margin: 0;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
`;
