import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ClinicEntityResponse } from "src/graphql/generated";
import { EButtonVariants } from "src/UI/Button";
import { PlusIcon } from "src/UI/Svg";
import { BranchComponent } from "src/components/MapComponents";
import { useDeleteClinicById } from "src/graphql/hooks/useMutations";
import { GET_COUNT_CLINICS } from "src/graphql/queries";
import { PATHS } from "src/constants/constants";
import { useClinicsAction, useClinicsState } from "src/store/clinics/hooks";
import { toast } from "react-toastify";
import { useAppAction } from "src/store/app/hooks";
import { useFindManyFilesByIds } from "src/graphql/hooks/useQueries";
import { Loading } from "src/UI/Loading";
import {
  ButtonStyled,
  CardStyled,
  EmptyTextStyled,
  List,
  LoadingWrap,
} from "./styles";
import { sortParams } from "../staticData";

interface MapPageProps {
  clinics: ClinicEntityResponse[];
  loading: boolean;
}

const Clinics: React.FC<MapPageProps> = ({ clinics, loading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { onSetLocation, onSetClinicId } = useClinicsAction();
  const { onConfirmOpen } = useAppAction();
  const { currentClinicId } = useClinicsState();

  const [delClinic] = useDeleteClinicById();

  const handleSelect = (clinic: ClinicEntityResponse) => () => {
    onSetLocation({
      center: [
        clinic?.geographicalCoordinates?.longitude,
        clinic?.geographicalCoordinates?.latitude,
      ],
    });
    onSetClinicId(clinic?.id);
  };
  const handleEdit = (id: string) => () => {
    navigate(`${PATHS.cp}${PATHS.map}${PATHS.update}/${id}`);
  };
  const handleRemove = (id: string, index: number) => () => {
    const prevLocationCoords =
      index > 0
        ? {
            center: [
              clinics?.[index - 1]?.geographicalCoordinates?.longitude,
              clinics?.[index - 1]?.geographicalCoordinates?.latitude,
            ] as [number, number],
            id: clinics?.[index - 1]?.id,
          }
        : null;
    const nextLocationCoords =
      index < clinics.length - 1
        ? {
            center: [
              clinics?.[index + 1]?.geographicalCoordinates?.longitude,
              clinics?.[index + 1]?.geographicalCoordinates?.latitude,
            ] as [number, number],
            id: clinics?.[index + 1]?.id,
          }
        : null;

    onConfirmOpen({
      accept: () =>
        delClinic({
          variables: {
            schema: {
              id,
            },
          },
          refetchQueries: [
            {
              query: GET_COUNT_CLINICS,
              variables: {
                schema: {
                  ...sortParams,
                },
              },
            },
          ],
          onCompleted: () => {
            toast.success(t("app.successful"));
            onSetLocation(nextLocationCoords || prevLocationCoords);
            onSetClinicId(
              nextLocationCoords?.id || prevLocationCoords?.id || ""
            );
          },
        }),
    });
  };

  const clinicsLogoIds = clinics
    ?.map((clinic) => clinic?.logoFileId)
    .filter((id) => id);

  const { data: logoFiels } = useFindManyFilesByIds({
    variables: {
      schema: {
        ids: clinicsLogoIds,
      },
    },
    skip: !clinicsLogoIds,
  });

  return (
    <CardStyled
      title={t("map.title")}
      headerChildren={
        <ButtonStyled
          as={Link}
          to={`${PATHS.cp}${PATHS.map}${PATHS.create}`}
          variant={EButtonVariants.Default}
        >
          <PlusIcon />
          {t("map.button")}
        </ButtonStyled>
      }
    >
      {loading ? (
        <LoadingWrap>
          <Loading />
        </LoadingWrap>
      ) : (
        <List>
          {clinics.length ? (
            clinics.map((clinic: ClinicEntityResponse, idx: number) => (
              <BranchComponent
                key={clinic.id}
                onSelect={handleSelect(clinic)}
                onRemove={handleRemove(clinic.id, idx)}
                onEdit={handleEdit(clinic.id)}
                clinic={clinic}
                isSelected={currentClinicId === clinic.id}
                logoFiles={logoFiels?.findManyFilesByIds?.files}
              />
            ))
          ) : (
            <EmptyTextStyled>{t("app.clinics_not_added")}</EmptyTextStyled>
          )}
        </List>
      )}
    </CardStyled>
  );
};

export default Clinics;
