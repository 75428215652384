import { Tabs } from "src/UI/Tabs";
import styled from "styled-components";

export const TabsStyled = styled(Tabs)`
  gap: 20px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 20px;
  background: ${({ theme }) => theme.colors.background};
  border-radius: 10px;
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 16px;
  }
`;
