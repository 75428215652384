import { UserRoles } from "src/graphql/generated";
import { IMenuItem } from "../types";

export const menuItems: IMenuItem[] = [
  {
    link: "/cp/dashboard",
    icon: "HomeIcon",
    tip: "home",
    roles: [UserRoles.Client, UserRoles.Doctor],
  },
  {
    link: "/cp/calendar",
    icon: "CalendarIcon",
    tip: "calendar",
    roles: [UserRoles.Client, UserRoles.Doctor],
  },
  {
    link: "/cp/booking",
    icon: "BookingIcon",
    tip: "booking",
    roles: [UserRoles.Client, UserRoles.Doctor],
  },
  {
    link: "/cp/waitlist",
    icon: "WaitlistIcon",
    tip: "waitlist",
    roles: [UserRoles.Client],
  },
  {
    link: "/cp/patients",
    icon: "PatientIcon",
    tip: "patient",
    roles: [UserRoles.Client, UserRoles.Doctor],
  },
  {
    link: "/cp/map",
    icon: "MapIcon",
    tip: "map",
    roles: [UserRoles.Client],
  },
  {
    link: "/cp/clinic",
    icon: "ClinicIcon",
    tip: "clinic",
    roles: [UserRoles.Client, UserRoles.Doctor],
  },
  // {
  //   link: "/cp/reviews",
  //   icon: "StarIcon",
  //   tip: "star",
  // },
  {
    link: "/cp/templates",
    icon: "TemplatesIcon",
    tip: "templates",
    roles: [UserRoles.Client],
  },
  // {
  //   link: "/cp/analytic",
  //   icon: "AnalyticIcon",
  //   tip: "analytic",
  // },
  {
    link: "/cp/messages",
    icon: "AdvertismentIcon",
    tip: "advertisement",
    roles: [UserRoles.Client],
  },
  // {
  //   link: "/cp/advertisement",
  //   icon: "AdvertismentIcon",
  //   tip: "advertisement",
  //   roles: [UserRoles.Client],
  // },
  {
    link: "/cp/forms",
    icon: "LaptopIcon",
    tip: "forms",
    roles: [UserRoles.Client, UserRoles.Doctor],
  },
  // {
  //   link: "/cp/news",
  //   icon: "NewsIcon",
  //   tip: "news",
  // },
  // {
  //   link: "/cp/constructor",
  //   icon: "NewsIcon",
  //   tip: "constructor",
  // },
];
