import React from "react";
import { DoctorsView, ServicesView } from "src/views/BookingsViews";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 20px;
`;

function SettingsPage() {
  return (
    <Wrapper>
      <ServicesView />
      <DoctorsView />
    </Wrapper>
  );
}

export default SettingsPage;
