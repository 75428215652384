import {
  AggregatedDoctorEntityResponse,
  DoctorTypeEntityResponse,
  LanguageIsoCodes,
  useFindManyDoctorTypesByLanguageIsoCodeLazyQuery,
  useFindManyFilesByIdsLazyQuery,
} from "src/graphql/generated";

import { DoctorIcon } from "src/UI/Svg";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Loading } from "src/UI/Loading";
import {
  DoctorOptInfoWrap,
  DoctorOptWrap,
  IconWrapper,
  ImageStyled,
  OptItemSubTitle,
  OptItemTitle,
} from "./styled";

interface DoctorOptComponentProps {
  doctor: AggregatedDoctorEntityResponse;
}

const DoctorInfoComponent = ({ doctor }: DoctorOptComponentProps) => {
  const { i18n } = useTranslation();

  const [
    findDoctorTypesByLocaleLazyQuery,
    { data: doctorTypesData, loading: doctorTypesLoading },
  ] = useFindManyDoctorTypesByLanguageIsoCodeLazyQuery();

  const [avaUrl, setAvaUrl] = useState<string | null>(null);

  const [getLogo, { loading: logoFielsLoading }] =
    useFindManyFilesByIdsLazyQuery();

  useEffect(() => {
    if (doctor?.avatarFileId) {
      getLogo({
        variables: {
          schema: {
            ids: [doctor?.avatarFileId as string],
          },
        },
        onCompleted(response) {
          setAvaUrl(response?.findManyFilesByIds?.files?.[0]?.url);
        },
      });
    }
  }, [doctor?.avatarFileId]);

  const isLoading = logoFielsLoading || doctorTypesLoading;

  const specialisation: DoctorTypeEntityResponse | undefined =
    doctorTypesData?.findManyDoctorTypesByLanguageISOCode?.doctorTypes.find(
      (type) => type.id === doctor.doctorTypeId
    );

  useEffect(() => {
    if (!i18n) return;
    findDoctorTypesByLocaleLazyQuery({
      variables: {
        schema: {
          languageISOCode: i18n.language.toUpperCase() as LanguageIsoCodes,
        },
      },
      fetchPolicy: "network-only",
    });
  }, [i18n.language]);

  return isLoading ? (
    <Loading padding="0" />
  ) : (
    <DoctorOptWrap>
      <IconWrapper>
        {avaUrl ? <ImageStyled src={avaUrl} alt="#" /> : <DoctorIcon />}
      </IconWrapper>
      <DoctorOptInfoWrap>
        <OptItemTitle>{`${doctor?.firstName} ${doctor?.lastName}`}</OptItemTitle>
        {specialisation && (
          <OptItemSubTitle>
            {specialisation?.defaultLanguageName}
          </OptItemSubTitle>
        )}
      </DoctorOptInfoWrap>
    </DoctorOptWrap>
  );
};

export default DoctorInfoComponent;
