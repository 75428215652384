import { AggregatedScheduleAppointmentEntityWithRelationsByClientResponse } from "src/graphql/generated";

export const transformBeforeReschedule = (
  rescheduleAppointment: AggregatedScheduleAppointmentEntityWithRelationsByClientResponse & {
    appointmentId?: string;
  }
) => {
  if (rescheduleAppointment) {
    return {
      appointmentId: rescheduleAppointment.id,
      clinicId: rescheduleAppointment.schedule.clinicId,
      doctorId: rescheduleAppointment.doctor.doctorId,
      serviceId: rescheduleAppointment.service?.id,
      patientId: rescheduleAppointment.patientId,
      minuteFrom: rescheduleAppointment.schedule.minuteFrom,
      minuteTo: rescheduleAppointment.schedule.minuteTo,
      date: rescheduleAppointment.schedule.dateFrom,
      roomId: rescheduleAppointment.room?.id || null,
    };
  }
};
