import React from "react";
import { Icon, AvatarBlock, LettersBlock } from "./styled";

interface AvatarComponentProps {
  firstName: string;
  lastName: string;
  size?: number;
}

const AvatarComponent = ({
  firstName,
  lastName,
  size = 32,
  ...props
}: AvatarComponentProps) => {
  const fontSize = size / 2;
  const initials = `${firstName?.charAt(0)}${lastName?.charAt(
    0
  )}`.toUpperCase();
  return (
    <AvatarBlock {...props}>
      <Icon size={size}>
        <LettersBlock fontSize={fontSize}>{initials}</LettersBlock>
      </Icon>
    </AvatarBlock>
  );
};

export default AvatarComponent;
