import React from "react";

import { useTranslation } from "react-i18next";
import { IHistory } from "src/types";
import { useAuthContext } from "src/context/AuthContext";
import {
  ColumnTitle,
  ColumnValue,
  History,
  HistoryBlock,
  Title,
  Value,
} from "./styled";

function HistoryComponent({ service, doctor, clinic }: IHistory) {
  const { t } = useTranslation();
  const { isClient } = useAuthContext();
  return (
    <History>
      <HistoryBlock>
        <ColumnTitle>
          <Title>{t("reminder.table.service")}</Title>
          {isClient ? <Title>{t("reminder.table.provider")}</Title> : null}
          <Title>{t("app.booking.patient.confirmed_clinic_name")}</Title>
        </ColumnTitle>
        <ColumnValue>
          <Value>{service?.name}</Value>
          {isClient && doctor ? (
            <Value>
              {doctor.firstName} {doctor.lastName}
            </Value>
          ) : null}
          <Value>{clinic?.name}</Value>
        </ColumnValue>
      </HistoryBlock>
    </History>
  );
}

export default HistoryComponent;
