import React, {
  ChangeEvent,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useTranslation } from "react-i18next";
import { useOutsideClick } from "src/hooks/useOutsideClick";
import { FieldError, useFormContext } from "react-hook-form";
import { AutocompletePrediction } from "react-places-autocomplete";
import { IUserValue } from "src/pages/VerifyPages/RegistrationCompanyPage";
import {
  ErrorMessage,
  InputBlock,
  InputStyled,
  InputWrapper,
  SuggestionItem,
  Suggestions,
} from "./styles";

interface InputProps {
  placeholder: string;
  errorMessage?: FieldError;
  value: AutocompletePrediction | IUserValue | null;
  name: string;
}

export interface ExposeMethods {
  getValue: () => string;
}

const AddressComponent = React.forwardRef<ExposeMethods, InputProps>(
  ({ placeholder, errorMessage, value, name }, ref) => {
    const { t, i18n } = useTranslation();
    const { setValue, trigger } = useFormContext();

    const { placePredictions, getPlacePredictions } = usePlacesService({
      apiKey: process.env.REACT_APP_GOOGLE,
      language: i18n?.resolvedLanguage,
    });

    const dropdown = useRef<HTMLDivElement>(null);

    const [closeSuggestions, setCloseSuggestions] = useState(false);

    useOutsideClick({
      ref: dropdown,
      handler: () => setCloseSuggestions(true),
    });

    const triggerChange = (v: AutocompletePrediction | IUserValue) => {
      setValue(name, v);
      trigger(name);
    };

    const selectSuggestion = (v: AutocompletePrediction) => {
      triggerChange(v);
      setCloseSuggestions(true);
    };

    const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
      triggerChange({
        description: e.target.value,
        type: "custom_user_value",
      });
      getPlacePredictions({ input: e.target.value });
      setCloseSuggestions(false);
    };

    useImperativeHandle(ref, () => ({
      getValue: () => {
        return value?.description || "";
      },
    }));

    return (
      <InputWrapper>
        <InputBlock>
          {errorMessage && (
            <ErrorMessage>
              {errorMessage?.message && t(errorMessage.message)}
            </ErrorMessage>
          )}
          <InputStyled
            value={value?.description}
            onChange={handleInput}
            isError={!!errorMessage}
            type="text"
            placeholder={placeholder}
          />
        </InputBlock>
        {!closeSuggestions && placePredictions.length ? (
          <Suggestions ref={dropdown}>
            {placePredictions.map((item) => (
              <SuggestionItem
                key={item.place_id}
                onClick={() => selectSuggestion(item)}
              >
                {item.description}
              </SuggestionItem>
            ))}
          </Suggestions>
        ) : null}
      </InputWrapper>
    );
  }
);

export default AddressComponent;
