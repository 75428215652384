import React, { ReactNode } from "react";
import { LangComponent } from "src/components/DashboardComponents/SettingComponents";
import { CrossIcon } from "src/UI/Svg";
import { SelectedBookingClinic } from "src/store/booking/types";
import {
  ClinicWrapper,
  HeadingStyled,
  IconWrapper,
  ImageStyled,
  SideBar,
  Wrapper,
} from "./styled";

interface LayoutProps {
  currentClinic: SelectedBookingClinic;
  children: ReactNode;
  isLangComponent?: boolean;
}

function Layout({
  currentClinic,
  children,
  isLangComponent = true,
}: LayoutProps) {
  return (
    <Wrapper>
      <SideBar>
        <ClinicWrapper>
          <IconWrapper>
            {currentClinic?.logoUrl ? (
              <ImageStyled src={currentClinic?.logoUrl as string} alt="#" />
            ) : (
              <CrossIcon />
            )}
          </IconWrapper>
          <HeadingStyled as="h3">{currentClinic?.clinic?.name}</HeadingStyled>
        </ClinicWrapper>

        {isLangComponent ? <LangComponent /> : null}
      </SideBar>
      {children}
    </Wrapper>
  );
}

export default Layout;
