import styled from "styled-components";

export const Content = styled.div`
  padding: 24px;
  width: 650px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  grid-template-columns: 50% 50%;
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 16px;
    gap: 16px;
  }
`;
