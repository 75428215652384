import { Button } from "src/UI/Button";
import { Heading } from "src/UI/Heading";
import { CardComponent } from "src/components/UserComponents";
import styled from "styled-components";

export const CardStyled = styled(CardComponent)`
  flex: 1;

  & > div:first-child {
    padding-bottom: 0;
    border-bottom: none;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
`;

export const FieldsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 40px;
`;
export const FormBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
`;

export const FormTitle = styled(Heading)`
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
`;

export const RowInput = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;

  & > div {
    min-width: 120px;

    &:nth-child(2) {
      flex: 1;
    }
  }
`;
export const Buttons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  gap: 8px;
`;

export const ButtonStyled = styled(Button)`
  padding: 15px 20px;
  font-size: 20px;
  line-height: 100%;
  width: fit-content;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 10px;
`;

export const Text = styled.label`
  display: inline;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #3d3d3d;
`;

export const FormFooter = styled.div`
  display: flex;
  width: 100%;
`;

export const PhotoWrapStiled = styled.div`
  width: 120px;
`;

export const ErrorPhotoStyled = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.red};
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 12px;
  }
`;
