import { gql } from "@apollo/client";

export const GET_SCHEDULE_APPOINTMENT_CONFIRMED_PY_PATIENT = gql`
  query ScheduleAppointmentConfirmedByPatient(
    $schema: ScheduleAppointmentConfirmedByPatientSchema!
  ) {
    scheduleAppointmentConfirmedByPatient(schema: $schema) {
      confirmed
    }
  }
`;
