import styled from "styled-components";

export const DoctorOptInfoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const DoctorOptWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  align-items: center;
  min-width: 44px;
  width: 44px;
  height: 44px;
  background: ${({ theme }) => theme.colors.lightBiege};
  border-radius: 56px;

  svg {
    width: 44px;
    height: 44px;
  }
`;

export const OptItemTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const OptItemSubTitle = styled.div`
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  text-align: left;
  color: ${({ theme }) => theme.colors.gray};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ImageStyled = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  border-radius: 50%;
`;
