import styled from "styled-components";
import { TemplatesComponentWrapper } from "../styled";

export const StyledTemplateComponentWrapper = styled(TemplatesComponentWrapper)`
  padding: 20px;
`;

export const ToggleBlock = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 12px;
  }
`;

export const LabelBlock = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.black};

  span {
    color: ${({ theme }) => theme.colors.gray};
  }

  svg {
    width: 16px;
    height: 16px;

    path {
      stroke: ${({ theme }) => theme.colors.gray};
    }
  }
`;
