import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { EModals } from "src/store/app/types";
import { Modal } from "src/UI/Modal";
import { useAppAction, useAppState } from "src/store/app/hooks";
import { EButtonVariants } from "src/UI/Button";

import { FormProvider, useForm } from "react-hook-form";

import { InputComponent } from "src/components/AuthComponents";
import { yupResolver } from "@hookform/resolvers/yup";
import { passwordRecoveryValidationSchema } from "src/validation/schemas/passwordRecoveryValidationSchema";
import {
  ActionBlock,
  ButtonStyled,
  Content,
  ContentWrapStyled,
  DropdownStyled,
  Form,
  SubTitleStyled,
} from "./styles";

interface FormValues {
  field: string;
  method: string;
}

function ForgotPassModal() {
  const { onCloseModal } = useAppAction();

  const { t } = useTranslation();
  const { modals } = useAppState();

  const methods = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(passwordRecoveryValidationSchema),
    defaultValues: {
      method: "email",
      field: "",
    },
  });

  const {
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = methods;

  const [selectedOption, setSelectedOption] = useState<string>("");

  const variants: Array<{ item: string; value: string }> = useMemo(
    () => [
      { item: t("history.email"), value: "email" },
      { item: t("user.person_data.profile.facebook"), value: "facebook" },
      { item: t("reminder.table.cellphone"), value: "phone" },
    ],
    [t]
  );

  const selectedObject: { item: string; value: string } | undefined = useMemo(
    () => variants.find((item) => item.value === selectedOption),
    [selectedOption]
  );

  const data = watch();

  const onSelect = (val: string) => {
    setSelectedOption(val);
    setValue("method", val);
  };

  const onGoBack = () => {
    onCloseModal(EModals.ForgotPass);
  };

  const onSubmit = (formData: FormValues) => {
    console.log(formData);
  };

  if (!modals[EModals.ForgotPass]) return null;
  return (
    <Modal
      modal={EModals.ForgotPass}
      title={t("modals.forgot.password")}
      headerChildren={
        <SubTitleStyled>{t("common.choose_way_to_restore")}</SubTitleStyled>
      }
    >
      <Content>
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ContentWrapStyled>
              <InputComponent
                placeholder={selectedObject?.item || variants[0]?.item}
                inputType={selectedOption === "email" ? "email" : "text"}
                val={data.field}
                errorMessage={errors.field}
                register={register("field")}
              />
              <DropdownStyled
                isShowOnlyPlaceholder={true}
                value={selectedOption}
                placeholder=""
                onSelect={(val) => onSelect(val)}
                options={variants}
                label=""
              />
            </ContentWrapStyled>
            <ActionBlock>
              <ButtonStyled onClick={onGoBack} variant={EButtonVariants.Text}>
                {t("common.back")}
              </ButtonStyled>
              <ButtonStyled
                type="submit"
                disabled={!isValid}
                variant={EButtonVariants.Default}
              >
                {t("common.continue")}
              </ButtonStyled>
            </ActionBlock>
          </Form>
        </FormProvider>
      </Content>
    </Modal>
  );
}

export default ForgotPassModal;
