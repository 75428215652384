import { gql } from "@apollo/client";

export const CANCEL_SCHEDULE_APPOINTMENT_BY_PATIENT = gql`
  mutation CancelScheduleAppointmentByPatient(
    $schema: CancelScheduleAppointmentByPatientSchema!
  ) {
    cancelScheduleAppointmentByPatient(schema: $schema) {
      canceled
    }
  }
`;
