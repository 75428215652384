import React, { FunctionComponent } from "react";
import { Svg, SvgProps } from "..";

const Icon: FunctionComponent<SvgProps> = ({
  size = "8px",
  color = "#3D3D3D",
  strokeWidth = "1.5",
}) => (
  <Svg width={size} height={size} viewBox="0 0 8 8" fill="none">
    <path
      d="M6 3L4 5L2 3"
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Icon;
