import { Button } from "src/UI/Button";
import { Dropdown } from "src/UI/Dropdown";
import { Heading } from "src/UI/Heading";
import { Tabs } from "src/UI/Tabs";
import { SettingsIcon } from "src/UI/Svg";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 5px;
  padding: 20px 20px 20px;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const HeadingStyled = styled(Heading)`
  font-size: 24px;
  line-height: 100%;
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 20px;
  }
`;

export const TabsStyled = styled(Tabs)`
  gap: 5px;
`;

export const ButtonStyled = styled(Button)`
  padding: 8px 10px;
  font-size: 16px;
  line-height: 100%;
  font-weight: 700;
  width: fit-content;
  border-radius: 5px;
  white-space: nowrap;
`;

export const DownloadButtonStyled = styled(Button)`
  padding: 8px 10px;
  font-size: 16px;
  line-height: 100%;
  font-weight: 500;
  width: fit-content;
  border-radius: 5px;
  white-space: nowrap;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  gap: 8px;
`;
export const DropdownStyled = styled(Dropdown)`
  border-radius: 5px;
  width: 100%;

  & > div:first-child {
    padding: 8px;
  }
`;

export const SettingsIconStyled = styled(SettingsIcon)`
  cursor: pointer;
  width: -webkit-fill-available;

  path {
    stroke: #a5a5a5 !important;
  }
`;

export const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  gap: 8px;
`;
