import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px 20px 20px;
  gap: 10px;
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  border-radius: 5px;
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`;

export const Title = styled.h4`
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.black};
`;
export const Price = styled.p`
  white-space: nowrap;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.black};
`;

export const RightBlock = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  gap: 16px;
`;

export const Content = styled.p<{ isHidden: boolean }>`
  font-weight: ${({ isHidden }) => (isHidden ? "400" : "600")};
  font-size: 16px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.darkGrey};
`;

export const Tags = styled.div`
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  left: 15px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 5px;
`;

export const Tag = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  gap: 10px;
  background: ${({ theme }) => theme.colors.greenHover};
  color: ${({ theme }) => theme.colors.green};
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  border-radius: 3px;
`;

export const HiddenTag = styled(Tag)`
  background: ${({ theme }) => theme.colors.borderInputDefault};
  color: ${({ theme }) => theme.colors.darkGrey};
`;
