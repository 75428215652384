import {
  ClinicEntityResponse,
  FileEntityResponse,
} from "src/graphql/generated";
import { CrossIcon, DeleteIcon, EditIcon } from "src/UI/Svg";
import {
  Actions,
  Address,
  ButtonIcon,
  DescBlock,
  Footer,
  Header,
  HeadingStyled,
  IconWrapper,
  IdBlock,
  ImageStyled,
  Wrapper,
} from "./styled";

// const mockStatistic: Array<{
//   title: string;
//   value: number | ReactNode;
//   period: string;
//   percent: number;
//   isNegative?: boolean;
// }> = [
//   {
//     title: "total_send",
//     value: 1200,
//     period: "7",
//     percent: 2,
//   },
//   {
//     title: "report",
//     value: 980,
//     period: "7",
//     percent: 2,
//     isNegative: true,
//   },
//   {
//     title: "opened",
//     value: 876,
//     period: "7",
//     percent: 15,
//   },
// ];
function BranchComponent({
  isSelected,
  clinic,
  logoFiles,
  onSelect,
  onRemove,
  onEdit,
}: {
  isSelected: boolean;
  clinic: ClinicEntityResponse;
  logoFiles: Array<FileEntityResponse>;
  onSelect: () => void;
  onRemove: () => void;
  onEdit: () => void;
}) {
  const clinicLogoFile = logoFiles?.find(
    (file) => file.id === clinic?.logoFileId
  );

  return (
    <Wrapper isSelected={isSelected} onClick={onSelect}>
      <IconWrapper>
        {clinicLogoFile ? (
          <ImageStyled src={clinicLogoFile?.url as string} alt="#" />
        ) : (
          <CrossIcon />
        )}
      </IconWrapper>

      <DescBlock>
        <Header>
          <HeadingStyled as="h3">
            {clinic.name}
            <Actions>
              <ButtonIcon isSelected={isSelected} onClick={onEdit}>
                <EditIcon />
              </ButtonIcon>
              <ButtonIcon isSelected={isSelected} onClick={onRemove}>
                <DeleteIcon />
              </ButtonIcon>
            </Actions>
          </HeadingStyled>
          <Address>{clinic.address}</Address>
        </Header>
        <Footer>
          {/* <RatingBlock>
            <Rating>
              <GoogleIcon /> 5.0
              <span>(10)</span>
            </Rating>
            <Rating>
              <FacebookIcon /> 5.0
              <span>(50)</span>
            </Rating>
          </RatingBlock> */}
          <IdBlock>ID {clinic.id}</IdBlock>
        </Footer>

        {/* {isSelected && (
          <Statistic>
            {mockStatistic.map((statistic, id) => (
              <StatisticItem
                key={`statistic-${id}`}
                {...statistic}
                title={t(`map.statistic.${statistic.title}`)}
              />
            ))}
            <RepostStyled />
          </Statistic>
        )} */}
      </DescBlock>
    </Wrapper>
  );
}

export default BranchComponent;
