import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TemplateEntityResponse } from "src/graphql/generated";

export interface TemplatesSliceState {
  selectedTemplateData: TemplateEntityResponse | null;
}

const initialState: TemplatesSliceState = {
  selectedTemplateData: null,
};

export const templatesSlice = createSlice({
  name: "templates-slice",
  initialState,
  reducers: {
    setSelectedTemplate: (
      state,
      action: PayloadAction<TemplateEntityResponse>
    ) => {
      state.selectedTemplateData = action.payload;
    },
    setClinicTemplate: (
      state,
      action: PayloadAction<TemplateEntityResponse["clinicsTemplates"]>
    ) => {
      if (state.selectedTemplateData) {
        state.selectedTemplateData = {
          ...state.selectedTemplateData,
          clinicsTemplates: action.payload,
        };
      }
    },
    resetSelectedTemplate: (state) => {
      state.selectedTemplateData = null;
    },
  },
});

export const { resetSelectedTemplate, setSelectedTemplate, setClinicTemplate } =
  templatesSlice.actions;
export default templatesSlice.reducer;
