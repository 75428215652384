import { Button } from "src/UI/Button";
import styled from "styled-components";

export const DateSelectionWrapStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SliderWrapStyled = styled.div`
  width: 496px;
  height: fit-content;
  padding: 0 20px;
  overflow: hidden;

  & .slick-slider {
    height: 18px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    width: 100%;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    & .slick-slider {
      height: 30px;
    }
  }
`;

export const ButtonStyled = styled(Button)`
  padding: 8px 10px;
  font-size: 16px;
  line-height: 100%;
  width: fit-content;
  border-radius: 5px;
  outline: none !important;
`;

export const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
`;

export const ArrowLeftStyled = styled.div<{ isDisable: boolean }>`
  display: flex !important;
  align-items: center;
  justify-content: center;
  opacity: ${({ isDisable }) => (isDisable ? ".3" : "1")};
  pointer-events: ${({ isDisable }) => (isDisable ? "none" : "auto")};

  & svg {
    transform: rotate(180deg);
  }
  &:before {
    display: none;
  }
`;

export const ArrowRightStyled = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;

  & svg {
    transform: rotate(0deg);
  }
  &:before {
    display: none;
  }
`;

export const FieldWrapStyled = styled.div`
  display: flex;
  position: relative;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  height: 100%;
`;
