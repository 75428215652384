import React from "react";
import styled from "styled-components";
import { EUserComponentSize } from "src/types/enum";
import { UserIcon } from "../../../UI/Svg";
import { renderDate } from "../../../utils";
import { IPatient } from "../../../types";
import { Account } from "./ActivityComponent/styled";

interface ISizeProps {
  $size: EUserComponentSize;
}

const User = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 10px;
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 8px;
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7px;
  flex-direction: column;
`;

const Name = styled.h4<ISizeProps>`
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.black};

  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: ${({ $size }) => {
      switch ($size) {
        case EUserComponentSize.large:
          return "20px";
        default:
          return "16px";
      }
    }};
  }
`;

const DateBlock = styled.p<ISizeProps>`
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.gray};

  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: ${({ $size }) => {
      switch ($size) {
        case EUserComponentSize.large:
          return "16px";
        default:
          return "12px";
      }
    }};
  }
`;

interface IUserComponentProps {
  patient: IPatient;
  date?: Date;
  isHideAvatar?: boolean;
  size?: EUserComponentSize;
}

function UserComponent({
  patient,
  date,
  isHideAvatar,
  size = EUserComponentSize.mid,
}: IUserComponentProps) {
  return (
    <User>
      {!isHideAvatar && (
        <Account>
          <UserIcon />
        </Account>
      )}
      <UserInfo>
        <Name $size={size}>
          {patient.name} {patient.soname}
        </Name>
        {Boolean(date) && (
          <DateBlock $size={size}>{renderDate(date as Date, true)}</DateBlock>
        )}
      </UserInfo>
    </User>
  );
}

export default UserComponent;
