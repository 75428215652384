import React, { useEffect, useRef, useState } from "react";

export function useFetchMore<T>(fetchMore: () => Promise<T>) {
  const [node, setNode] = useState<HTMLDivElement | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const loader = useRef(fetchMore);

  const intersectionObserver = () => {
    if (typeof window === "undefined") {
      return null;
    }
    return new IntersectionObserver(
      (entries) => {
        if (loader.current && entries[0].isIntersecting) {
          setIsLoading(true);
          loader
            .current()
            .then(() => setIsLoading(false))
            .catch(() => setIsLoading(false));
        }
      },
      { threshold: 1 }
    );
  };

  const observer = React.useRef(intersectionObserver());

  useEffect(() => {
    loader.current = fetchMore;
  }, [fetchMore]);

  useEffect(() => {
    const currentObserver = observer.current;

    if (node) {
      currentObserver?.observe(node);
    }

    return () => {
      if (node) {
        currentObserver?.unobserve(node);
      }
    };
  }, [node]);

  return { node: setNode, isLoading };
}
