import { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import { SliderHorizontalIcon } from "src/UI/Svg";
import { useOutsideClick } from "src/hooks/useOutsideClick";
import { EButtonVariants } from "src/UI/Button";
import { FieldError, useFormContext } from "react-hook-form";
import { SearchComponentRHF } from "src/UI/Input";
import { CountedPatientsResponse } from "src/graphql/generated";
import {
  ButtonStyled,
  Buttons,
  DateInputRHFStyled,
  Dates,
  DropdownAutocompleteRHFStyled,
  DropdownInputRHFStyled,
  FilterBlock,
  IconButton,
  Label,
  Menu,
  RowInput,
} from "./styles";
import { ComponentProps } from "./types";

const SettingFormComponent: FC<ComponentProps> = ({
  showMenu,
  doctorsOpts,
  cabinetsOpts,
  patientsOpts,
  patientsList,
  statusOpts,
  searchPatient,
  setShowMenu,
  onResetFilters,
  setSearchPatient,
  fetchMore,
}) => {
  const { t } = useTranslation();
  const dropdown = useRef<HTMLDivElement>(null);

  const {
    formState: { errors },
    watch,
  } = useFormContext();

  const data = watch();

  useOutsideClick({
    ref: dropdown,
    handler: () => setShowMenu(() => false),
  });

  const handleResetFilters = () => {
    onResetFilters();
  };

  const handleShowMenu = () => setShowMenu(!showMenu);

  const handleSearchPatient = (val: string) => {
    setSearchPatient(val);
  };

  return (
    <>
      <IconButton onClick={handleShowMenu} type="button">
        <SliderHorizontalIcon />
      </IconButton>
      <Menu isShowMenu={showMenu}>
        <FilterBlock ref={dropdown}>
          <RowInput>
            <Label>{t("calendar.search.labels.patient")}</Label>
            <SearchComponentRHF
              name="patientId"
              defaultSearchValue={searchPatient}
              placeholder={t("calendar.search.buttons.find") as string}
              value={data.patientId}
              options={patientsOpts}
              handleInputChange={handleSearchPatient}
              errorMessage={errors.patientId as FieldError}
              isClearable={true}
              fetchMore={fetchMore}
              data={patientsList as CountedPatientsResponse}
            />
          </RowInput>
          <RowInput>
            <Label>{t("app.status")}</Label>
            <DropdownInputRHFStyled
              name="status"
              value={data.status}
              placeholder=""
              options={statusOpts}
              label=""
              adaptedForMobile={false}
              isClearable
            />
          </RowInput>
          {!!doctorsOpts?.length && !data?.roomIds?.length && (
            <RowInput>
              <Label>{t("calendar.filters.doctor")}</Label>
              <DropdownAutocompleteRHFStyled
                name="doctorIds"
                options={doctorsOpts}
                label=""
              />
            </RowInput>
          )}
          {!!cabinetsOpts?.length && !data?.doctorIds?.length && (
            <RowInput>
              <Label>{t("calendar.search.labels.room")}</Label>
              <DropdownAutocompleteRHFStyled
                name="roomIds"
                options={cabinetsOpts}
                label=""
              />
            </RowInput>
          )}
          <RowInput>
            <Label>{t("calendar.search.labels.date")}</Label>
            <Dates>
              <DateInputRHFStyled
                value={data.from}
                name="from"
                placeholder={t("calendar.search.placeholders.from") as string}
                label=""
                errorMessage={errors.to as FieldError}
              />
              -
              <DateInputRHFStyled
                value={data.to}
                name="to"
                placeholder={t("calendar.search.placeholders.to") as string}
                label=""
                errorMessage={errors.to as FieldError}
              />
            </Dates>
          </RowInput>
          <Buttons>
            <ButtonStyled
              type="button"
              variant={EButtonVariants.Text}
              onClick={handleResetFilters}
            >
              {t("calendar.search.buttons.clear")}
            </ButtonStyled>
            <ButtonStyled variant={EButtonVariants.Default}>
              {t("calendar.search.buttons.find")}
            </ButtonStyled>
          </Buttons>
        </FilterBlock>
      </Menu>
    </>
  );
};

export default SettingFormComponent;
