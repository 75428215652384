import { Colors } from "./types";

export const baseColors = {
  background: "#fff",
  backgroundDashboard: "#F5F7FB",
  white: "#fff",
  black: "#3D3D3D",
  grayC4: "#c4c4c4",
  gray: "#A5A5A5",
  red: "#E26161",
  borderInputDefault: "#EDEDED",
  green: "#0BA495",
  yellow: "#F8C237",
  greenHover: "#E7F6F5",
  lightBiege: "#F8F8F8",
  darkGrey: "#828282",
  modalWrapper: "#3D3D3D",
  redHover: "#FBECEC",
};

export const lightColors: Colors = {
  ...baseColors,
};
