import { IActivity, IDoctor, IPatient } from "src/types";
import { ClinicIcon, DoctorIcon, PatientIcon } from "src/UI/Svg";
import { renderDate } from "src/utils";
import {
  Account,
  Image,
  InfoBlock,
  Item,
  List,
  Name,
  UserBlock,
} from "./styled";

interface UserSearchComponentProps {
  item: IActivity | IDoctor | IPatient;
}

function UserSearchComponent({ item, ...props }: UserSearchComponentProps) {
  return (
    <UserBlock {...props}>
      <Account>
        {"patient" in item ? (
          item.patient.image ? (
            <Image src={`/images/${item.patient.image}`} />
          ) : (
            <ClinicIcon />
          )
        ) : item.image ? (
          <Image src={`/images/${item.image}`} />
        ) : "specialization" in item ? (
          <DoctorIcon />
        ) : (
          <PatientIcon />
        )}
      </Account>
      <InfoBlock>
        <Name>
          {"patient" in item
            ? `${item.patient.name} ${item.patient.soname}`
            : `${item.name} ${item.soname}`}
        </Name>
        <List>
          {"patient" in item ? (
            <>
              <Item>{item.procedure}</Item>
              <Item>
                <span />
                {renderDate(item.date, true)}
              </Item>
            </>
          ) : (
            <>
              <Item>
                {"specialization" in item ? item.specialization : "Pacjent"}
              </Item>
              <Item>
                <span />
                {item.phone}
              </Item>
              <Item>
                <span />
                {item.email}
              </Item>
            </>
          )}
        </List>
      </InfoBlock>
    </UserBlock>
  );
}

export default UserSearchComponent;
