import { gql } from "@apollo/client";

export const GET_CURRENT_DOCTOR = gql`
  query GetCurrentDoctor {
    getCurrentDoctor {
      createdAt
      description
      id
      medicalCertificateNumber
      passportNumber
      phoneNumber
      promoCode
      userId
    }
  }
`;
