import React, { memo, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";

import mapboxgl from "mapbox-gl";
import styled from "styled-components";

import {
  ClinicEntityResponse,
  ClinicSortFields,
  SortingOrders,
  useFindAndCountManyClinicsLazyQuery,
} from "src/graphql/generated";

import { PATHS } from "src/constants/constants";
import { useClinicsAction, useClinicsState } from "src/store/clinics/hooks";

import { FeatureCollection } from "src/components/Map/types";
import Map from "src/components/Map/Map";
import CreateBranch from "./CreateBranch/CreateBranch";
import Clinics from "./Clinics/Clinics";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX as string;

const MapStyled = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 0;
`;

function Index() {
  const [clinics, setClinics] = useState<ClinicEntityResponse[]>([]);

  const { onSetLocation, onSetClinicId } = useClinicsAction();
  const { currentLocation } = useClinicsState();

  const [getFindAndCountManyClinicsLazyQuery, { loading }] =
    useFindAndCountManyClinicsLazyQuery();

  useEffect(() => {
    getFindAndCountManyClinicsLazyQuery({
      variables: {
        schema: {
          sortOrder: SortingOrders.Descending,
          sortField: ClinicSortFields.CreatedAt,
        },
      },
      onCompleted: (response) => {
        setClinics(response?.findAndCountManyClinics?.clinics);
      },
    });
  }, []);

  const locations = clinics.map((marker: ClinicEntityResponse) => {
    return {
      type: "Feature",
      properties: {
        icon: "default",
        title: marker.name,
      },
      geometry: {
        coordinates: [
          marker.geographicalCoordinates.longitude,
          marker.geographicalCoordinates.latitude,
        ] as [number, number],
        type: "Point",
      },
      id: marker.id,
    };
  });

  const markers: FeatureCollection = {
    features: locations,
    type: "FeatureCollection",
  };

  useEffect(() => {
    if (clinics.length) {
      const currentClinicId = localStorage.getItem("currentClinicId");
      const currentClinic = clinics?.find(
        (clinic: ClinicEntityResponse) => clinic.id === currentClinicId
      );

      if (currentClinic) {
        const currentClinicObject = {
          center: [
            (currentClinic as ClinicEntityResponse)?.geographicalCoordinates
              ?.longitude,
            (currentClinic as ClinicEntityResponse)?.geographicalCoordinates
              ?.latitude,
          ] as [number, number],
        };
        onSetLocation(currentClinicObject || currentLocation);
        onSetClinicId((currentClinic as ClinicEntityResponse)?.id);
      } else {
        onSetLocation({
          center: markers?.features?.[0]?.geometry?.coordinates,
        });
        onSetClinicId(markers?.features?.[0]?.id);
      }
    }
  }, [clinics]);

  return (
    <>
      <MapStyled>
        <Map
          markers={markers}
          currentLocation={currentLocation}
          horizontalOffset={-0.009}
        />
      </MapStyled>
      <Routes>
        <Route
          path={PATHS.home}
          element={<Clinics clinics={clinics} loading={loading} />}
        />
        <Route path={PATHS.create} element={<CreateBranch />} />
        <Route path={`${PATHS.update}/:clinicId`} element={<CreateBranch />} />
      </Routes>
    </>
  );
}

export default memo(Index);
