import { useEffect } from "react";
import { FormFieldEntityWithRelationsResponse } from "src/graphql/generated";
import { useFindBlankFormByServiceId } from "src/graphql/hooks/useQueries";
import { useBookingState } from "src/store/booking/hooks";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { transformBeforeEditPatientBookingStep3 } from "src/connectors/PatientBooking/transformBeforeEditpatientBookingStep2";
import { createPatientBookingStep3ValidationSchema } from "src/validation/schemas/createPatientBookingStep3";

import FormItemComponent from "src/components/FormsComponents/FormItemComponent/FormItemComponent";
import { EButtonVariants } from "src/UI/Button";
import { useTranslation } from "react-i18next";
import { Loading } from "src/UI/Loading";
import { ButtonBlock, Form, FormsList, StyledButton } from "../../styled";
import { IFieldStep3, PatientBookingInfo } from "../../types";

export interface IFormValuesStep3 {
  fields: IFieldStep3[];
}
interface IStep3 {
  prevStepHandler: () => void;
  createAppointmentHandler: (
    patientInfoSelected?: PatientBookingInfo,
    languageISOCode?: string,
    fields?: IFieldStep3[]
  ) => void;
  loading: boolean;
}

function Step3({ prevStepHandler, createAppointmentHandler, loading }: IStep3) {
  const { t, i18n } = useTranslation();
  const { currentService, currentFormFields, patientInfo } = useBookingState();

  const methods = useForm<IFormValuesStep3>({
    mode: "onChange",
    resolver: yupResolver(createPatientBookingStep3ValidationSchema),
    defaultValues: {
      fields: [],
    },
  });

  const { handleSubmit, watch, reset } = methods;

  const { data } = useFindBlankFormByServiceId({
    variables: { schema: { serviceId: currentService?.service.id } },
    skip: !currentService?.service.id || !!currentFormFields?.fields.length,
    fetchPolicy: "network-only",
  });

  const fields = watch("fields");

  useEffect(() => {
    if (data || currentFormFields?.fields.length)
      reset(
        transformBeforeEditPatientBookingStep3(
          currentFormFields?.fields || data.findBlankFormByServiceId?.fields,
          i18n.resolvedLanguage,
          currentFormFields?.fields?.map((field) => field.fillValue)
        )
      );
  }, [data, i18n.resolvedLanguage, currentFormFields?.fields]);

  const formHandler = (formData: IFormValuesStep3) => {
    const languageISOCode =
      formData?.fields?.[0]?.translations[0]?.languageISOCode;

    createAppointmentHandler(
      patientInfo?.patientInfo,
      languageISOCode,
      formData?.fields as IFieldStep3[]
    );
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(formHandler)}>
        <FormsList>
          {fields?.map((field: FormFieldEntityWithRelationsResponse) => (
            <FormItemComponent key={field.id} field={field} />
          ))}
        </FormsList>
        <ButtonBlock>
          <StyledButton
            variant={EButtonVariants.Text}
            onClick={prevStepHandler}
          >
            {t("app.booking.patient.step_back")}
          </StyledButton>
          <StyledButton variant={EButtonVariants.Default}>
            {loading && <Loading type="secondary" padding="0" />}
            {t("app.booking.patient.step_next")}
          </StyledButton>
        </ButtonBlock>
      </Form>
    </FormProvider>
  );
}

export default Step3;
