import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  allClinicsItemId,
  defaultPatientsLimit,
} from "src/constants/constants";
import { useAuthContext } from "src/context/AuthContext";
import {
  CountedPatientsResponse,
  useFindAndCountManyPatientsByClientLazyQuery,
  useFindAndCountManyPatientsByDoctorLazyQuery,
} from "src/graphql/generated";
import { useClinicsState } from "src/store/clinics/hooks";
import { IPatientsFilters } from "src/types";

interface IPatientsFiltersProps {
  filters?: IPatientsFilters;
}

export function useGetPatientsLazyLoading({ filters }: IPatientsFiltersProps) {
  const { currentClinicId: clinicId } = useClinicsState();
  const [patientsData, setPatientsData] =
    useState<CountedPatientsResponse | null>(null);
  const [searchPatient, setSearchPatient] = useState("");
  const { isDoctor, isClient } = useAuthContext();

  const [
    getPatientsByClient,
    { fetchMore: fetchMorePatientsByClient, loading: loadingPatientsByClient },
  ] = useFindAndCountManyPatientsByClientLazyQuery({
    variables: {
      schema: {
        ...(filters?.clinicId &&
          filters.clinicId !== allClinicsItemId && {
            clinicId: filters.clinicId,
          }),
        ...(!!searchPatient && { search: searchPatient }),
        limit: defaultPatientsLimit,
        offset: 0,
      },
    },
    onCompleted: (response) => {
      setPatientsData(response?.findAndCountManyPatientsByClient);
    },
    onError: (error) => {
      if (error) toast.error(error.message);
    },

    fetchPolicy: "network-only",
  });

  const [
    getPatientsByDoctor,
    { fetchMore: fetchMorePatientsByDoctor, loading: loadingPatientsByDoctor },
  ] = useFindAndCountManyPatientsByDoctorLazyQuery({
    variables: {
      schema: {
        ...(filters?.clinicId &&
          filters.clinicId !== allClinicsItemId && {
            clinicId: filters.clinicId,
          }),
        ...(!!searchPatient && { search: searchPatient }),
        limit: defaultPatientsLimit,
        offset: 0,
      },
    },
    onCompleted: (response) => {
      setPatientsData(response?.findAndCountManyPatientsByDoctor);
    },
    onError: (error) => {
      if (error) toast.error(error.message);
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (isClient) {
      getPatientsByClient();
    }
    if (isDoctor) {
      getPatientsByDoctor();
    }
  }, [isDoctor, isClient, clinicId]);

  const handleFetchMore = () => {
    if (isClient) {
      return fetchMorePatientsByClient({
        variables: {
          schema: {
            ...(filters?.clinicId &&
              filters.clinicId !== allClinicsItemId && {
                clinicId: filters.clinicId,
              }),
            ...(!!searchPatient && { search: searchPatient }),
            limit: defaultPatientsLimit,
            offset: patientsData?.patients.length,
          },
        },
      });
    }
    if (isDoctor) {
      return fetchMorePatientsByDoctor({
        variables: {
          schema: {
            ...(filters?.clinicId &&
              filters.clinicId !== allClinicsItemId && {
                clinicId: filters.clinicId,
              }),
            ...(!!searchPatient && { search: searchPatient }),
            limit: defaultPatientsLimit,
            offset: patientsData?.patients.length,
          },
        },
      });
    }
  };

  return {
    patientsList: patientsData,
    loading: loadingPatientsByClient || loadingPatientsByDoctor,
    fetchMore: handleFetchMore as () => Promise<unknown>,
    setSearchPatient,
    searchPatient,
  };
}
