import { useTranslation } from "react-i18next";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useFormContext } from "react-hook-form";
import update from "immutability-helper";
import { ITemplateCreateEdit } from "src/types/templates";
import { DistributionActions } from "src/graphql/generated";
import { useTemplateDefault } from "src/hooks/templates";
import EmptyText from "src/components/EmptyText/EmptyText";
import { HeadingStyled, List } from "./styled";
import TemplateComponentsDragItem from "./TemplatesComponentsDragItem/TemplateComponentsDragItem";
import { TemplatesComponentWrapper } from "../styled";

function TemplatesComponentActions() {
  const { watch, setValue } = useFormContext<ITemplateCreateEdit>();
  const [selectedAction, templateActions, selectedEvent] = watch([
    "selectedAction",
    "templateActions",
    "selectedEvent",
  ]);
  const { t } = useTranslation();
  const { getDefaultMail, getDefaultSms } = useTemplateDefault();

  const handleSelectAction = (actionName: DistributionActions) => () => {
    setValue("selectedAction", actionName);
  };

  const handleRemoveAction = (actionName: DistributionActions) => async () => {
    setValue(
      "templateActions",
      templateActions?.filter((action) => action.name !== actionName)
    );

    if (selectedAction === actionName) {
      setValue("selectedAction", undefined);
    }

    if (actionName === DistributionActions.SendMail && selectedEvent) {
      const defaultValue = await getDefaultMail(selectedEvent);

      if (defaultValue) {
        setValue("mailTemplates", defaultValue);
      }
    }

    if (actionName === DistributionActions.SendSms && selectedEvent) {
      const defaultValue = await getDefaultSms(selectedEvent);

      if (defaultValue) {
        setValue("smsTemplates", defaultValue);
      }
    }
  };

  const handleMoveCard = (dragIndex: number, hoverIndex: number) => {
    if (templateActions) {
      setValue(
        "templateActions",
        update(templateActions, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, templateActions[dragIndex]],
          ],
        })
      );
    }
  };

  return (
    <TemplatesComponentWrapper>
      <HeadingStyled as="h3">
        {t("templates.action.actions.title")}
      </HeadingStyled>

      {!templateActions?.length && (
        <EmptyText text={t("templates.action.actions.no_added_actions")} />
      )}

      <List>
        <DndProvider backend={HTML5Backend}>
          {templateActions?.map((item, index) => (
            <TemplateComponentsDragItem
              key={item.name}
              isSelected={item.name === selectedAction}
              select={handleSelectAction(item.name)}
              remove={handleRemoveAction(item.name)}
              data={item}
              index={index}
              moveCard={handleMoveCard}
              isDisabledDelete={false}
            />
          ))}
        </DndProvider>
      </List>
    </TemplatesComponentWrapper>
  );
}

export default TemplatesComponentActions;
