import { gql } from "@apollo/client";

export const CREATE_SERVICE = gql`
  mutation CreateService($schema: CreateServiceSchema!) {
    createService(schema: $schema) {
      category {
        id
        name
      }
      createdAt
      currencyCode
      description
      durationInMinutes
      hiddenFromPatients
      icd10Code
      icd9Code
      id
      name
      price
    }
  }
`;
