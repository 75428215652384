import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { EButtonVariants } from "src/UI/Button";
import { CalendarIcon } from "src/UI/Svg";
import {
  useCloseScheduleWaitlistByClient,
  useRescheduleWaitlistByClient,
} from "src/graphql/hooks/useMutations";
import { WaitlistStatuses } from "src/graphql/generated";
import { Loading } from "src/UI/Loading";
import { EModals } from "src/store/app/types";
import { useAppAction } from "src/store/app/hooks";
import { FIND_AND_COUNT_MANY_SCHEDULE_WAITLISTS_BY_CLIENT } from "src/graphql/queries";
import { useBookingAction, useBookingState } from "src/store/booking/hooks";
import { toast } from "react-toastify";
import { ButtonStyled, ButtonWrapper, Buttons } from "../styled";

interface IScheduleAppointmentUpdateStatusComponent {
  id: string;
  status: WaitlistStatuses;
  setAppointmentToStore: () => void;
}

function ScheduleAppointmentUpdateStatusComponent({
  id,
  status,
  setAppointmentToStore,
}: IScheduleAppointmentUpdateStatusComponent) {
  const { t } = useTranslation();
  const { onCloseModal } = useAppAction();

  const { rescheduleWaitlist: rescheduleWaitlistData } = useBookingState();
  const { onSetRescheduleWaitlist } = useBookingAction();

  const [rescheduleWaitlist, { loading: rescheduleWaitlistLoading }] =
    useRescheduleWaitlistByClient();

  const [closeScheduleWaitlist, { loading: closeScheduleWaitlistLoading }] =
    useCloseScheduleWaitlistByClient();

  const closeModal = useCallback(() => {
    onCloseModal(EModals.ScheduleWaitingAppointment);
  }, [onCloseModal]);

  const handleReschaduleWaitingAppointment = () => {
    if (rescheduleWaitlistData)
      rescheduleWaitlist({
        variables: {
          schema: {
            id,
            dateFrom: rescheduleWaitlistData?.dateFrom,
            dateTo: rescheduleWaitlistData?.dateTo,
            minuteFrom: rescheduleWaitlistData?.minuteFrom,
            minuteTo: rescheduleWaitlistData?.minuteTo,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [FIND_AND_COUNT_MANY_SCHEDULE_WAITLISTS_BY_CLIENT],
        onCompleted(response) {
          if (response) {
            toast.success(t("app.successful"));
            onSetRescheduleWaitlist(null);
            closeModal();
          }
        },
      });
  };

  const handleCloseWaitslist = () => {
    closeScheduleWaitlist({
      variables: { schema: { id } },
      awaitRefetchQueries: true,
      refetchQueries: [FIND_AND_COUNT_MANY_SCHEDULE_WAITLISTS_BY_CLIENT],
      onCompleted(response) {
        if (response) {
          toast.success(t("app.successful"));
          onSetRescheduleWaitlist(null);
          closeModal();
        }
      },
    });
  };

  return (
    <Buttons>
      <ButtonWrapper>
        <ButtonStyled
          variant={EButtonVariants.Red}
          type="button"
          disabled={WaitlistStatuses.Closed === status}
          onClick={handleCloseWaitslist}
        >
          {closeScheduleWaitlistLoading ? <Loading padding="0" /> : null}
          {t("booking.schedule_appointment.cancel_visit")}
        </ButtonStyled>
        <ButtonStyled
          variant={EButtonVariants.Text}
          type="button"
          onClick={handleReschaduleWaitingAppointment}
          disabled={!rescheduleWaitlistData}
        >
          {rescheduleWaitlistLoading ? <Loading padding="0" /> : null}
          <CalendarIcon height="16" width="16" />{" "}
          {t("booking.schedule_appointment.reshedule_visit")}
        </ButtonStyled>
      </ButtonWrapper>
      <ButtonWrapper>
        <ButtonStyled
          variant={EButtonVariants.Default}
          type="button"
          onClick={setAppointmentToStore}
        >
          {t("booking.schedule_appointment.confirm_visit")}
        </ButtonStyled>
      </ButtonWrapper>
    </Buttons>
  );
}
export default ScheduleAppointmentUpdateStatusComponent;
