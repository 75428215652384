import { Button } from "src/UI/Button";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 24px;
  gap: 16px;
  border: 2px solid ${({ theme }) => theme.colors.borderInputDefault};
  filter: drop-shadow(0px 5px 25px rgba(0, 0, 0, 0.03));
  border-radius: 5px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  align-content: center;
  justify-content: flex-start;
`;

export const Account = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.lightBiege};
  ${({ theme }) => theme.mediaQueries.ll} {
    width: 48px;
    height: 48px;
  }
`;

// const Actions = styled.div`
//   display: flex;
//   align-items: center;
//   align-content: center;
//   justify-content: flex-end;
//   gap: 16px;
//   ${({ theme }) => theme.mediaQueries.ll} {
//     gap: 12px;
//   }
// `;

export const Footer = styled.div`
  display: flex;
  width: 100%;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 8px;
`;

export const ButtonStyled = styled(Button)`
  padding: 8px 10px;
  width: fit-content;
  font-size: 16px;
  line-height: 100%;
  border-radius: 5px;
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 7px;
`;

export const Name = styled.h4`
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
`;

export const Phone = styled.h4`
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.darkGrey};
`;

export const StyledButtonIcon = styled(Button)`
  display: flex;
  justify-content: flex-end;
  width: auto;
  background: none;
  padding: 0;
  border: none;

  svg {
    width: 16px;
    height: 16px;

    path {
      stroke: ${({ theme }) => theme.colors.gray};
    }
  }

  &:not(:disabled):hover {
    background-color: transparent;
  }
`;
