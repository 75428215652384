import { useTranslation } from "react-i18next";

import { useAppAction, useAppState } from "src/store/app/hooks";
import { EModals, ICabinet } from "src/store/app/types";
import { Modal } from "src/UI/Modal";
import { EButtonVariants } from "src/UI/Button";
import { useCallback, useEffect } from "react";
import { InputRHF } from "src/UI/Input";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addCabinetValidationSchema } from "src/validation/schemas/addCabinetSchema";
import { ActionBlock, ButtonStyled, Content, Form } from "./styles";

interface FormValues {
  cabinet: ICabinet | null;
}

function AddCabinetsModal() {
  const { t } = useTranslation();
  const { modals } = useAppState();
  const { onCloseModal } = useAppAction();

  const methods = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(addCabinetValidationSchema),
    defaultValues: {
      cabinet: null,
    },
  });

  const {
    watch,
    register,
    setValue,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = methods;

  useEffect(() => {
    reset({ cabinet: modals[EModals.AddCabinet]?.data?.cabinet });
  }, [modals[EModals.AddCabinet]?.data?.cabinet]);

  const data = watch();

  const isEdit = !!modals[EModals.AddCabinet]?.data?.cabinet;

  const closeModal = useCallback(() => {
    onCloseModal(EModals.AddCabinet);
  }, [onCloseModal]);

  const onSubmit = (formData: FormValues) => {
    modals[EModals.AddCabinet].data.accept({
      ...(formData?.cabinet as ICabinet),
    });
    setValue("cabinet", null);
    closeModal();
  };

  if (!modals[EModals.AddCabinet].active) return null;
  return (
    <Modal
      modal={EModals.AddCabinet}
      title={isEdit ? t("app.edit_cabinet") : t("app.add_cabinet")}
    >
      <Content>
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <InputRHF
              val={data?.cabinet?.name || ""}
              label={t("forms.forms.headers.title") as string}
              inputType="text"
              errorMessage={errors?.cabinet?.name}
              register={register("cabinet.name")}
            />

            <ActionBlock>
              <ButtonStyled
                variant={EButtonVariants.Text}
                onClick={closeModal}
                type="button"
              >
                {t("common.cancel")}
              </ButtonStyled>
              <ButtonStyled
                variant={EButtonVariants.Default}
                type="submit"
                disabled={!isValid}
              >
                {isEdit ? t("common.save") : t("booking.add_service.public")}
              </ButtonStyled>
            </ActionBlock>
          </Form>
        </FormProvider>
      </Content>
    </Modal>
  );
}

export default AddCabinetsModal;
