import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  align-content: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const ImageBlock = styled.div`
  min-width: 233px;
  width: 233px;
  height: 233px;
  overflow: hidden;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: #f8f8f8;
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};

  img {
    width: 100%;
    height: auto;
  }

  svg {
    width: 80px;
    height: 80px;

    path {
      stroke: ${({ theme }) => theme.colors.gray};
    }
  }
`;

export const InputsRow = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  align-content: center;
  justify-content: flex-start;
  gap: 20px;

  & > div {
    width: 100%;
  }
`;

export const Form = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 20px 25px;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 16px 16px 16px 20px;
    gap: 16px;
  }
`;
