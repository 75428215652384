import styled from "styled-components";

export const AddPhoto = styled.div`
  background: #f8f8f8;
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  border-radius: 50%;
  min-width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
  justify-content: center;
  // overflow: hidden;
`;

export const InputLogo = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
`;

export const PhotoButton = styled.label`
  cursor: pointer;
  gap: 4px;
  color: ${({ theme }) => theme.colors.gray};
  width: fit-content;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  align-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  background: transparent;

  svg {
    width: 24px;
    height: 24px;

    path {
      stroke: ${({ theme }) => theme.colors.gray};
    }
  }

  z-index: 2;
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 10px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  object-fit: cover;
  border-radius: 50%;
`;

export const PhotoText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 71px;
  color: ${({ theme }) => theme.colors.gray};
  z-index: 0;
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 10px;
  }
`;

export const DelIconWrap = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  background: #fff;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  cursor: pointer;
`;
