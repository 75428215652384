import styled from "styled-components";

export const DateInputStyled = styled.div`
  width: fit-content;
  padding: 8px 10px;
  gap: 4px;
  min-width: 140px;
  min-height: 32px;
  background: ${({ theme }) => theme.colors.lightBiege};
  border-radius: 5px;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.black};
`;

export const Label = styled.label`
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
`;

export const CheckboxesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
`;

export const FormWrapper = styled.div<{ isCheckbox: boolean }>`
  display: flex;
  flex-direction: column};
  gap: 8px;
  justify-content: flex-end;
`;

export const Toggles = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 30px;
`;

export const ToggleBlock = styled.div`
  padding: 10px;
  gap: 16px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
`;

export const LabelChecbox = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.black};
  svg {
    width: 16px;
    height: 16px;
    path {
      stroke: ${({ theme }) => theme.colors.gray};
    }
  }
`;

export const FormCheckboxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ErrorMessage = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: right;
  color: ${({ theme }) => theme.colors.red};
  position: absolute;
  top: 0;
  right: 0;

  z-index: 2;
`;

export const DateWrapper = styled.div`
  position: relative;
`;
