import { IMailing } from "../types";

export const mockMailing: IMailing[] = [
  {
    status: true,
    name: "Edward Brzeziński",
    total_sent: "1",
    recipient: "12",
    open_rate: "100%",
    clicked: "1",
    last_updated: {
      date: "14 Sep 2020",
      userName: "Artur Wilk",
    },
    created: {
      date: "4 step",
      userName: "Artur Wilk",
    },
  },
  {
    status: false,
    name: "Gabriel Ziółkowska",
    total_sent: "1",
    recipient: "12",
    open_rate: "100%",
    clicked: "1",
    last_updated: {
      date: "14 Sep 2020",
      userName: "Artur Wilk",
    },
    created: {
      date: "4 step",
      userName: "Artur Wilk",
    },
  },
  {
    status: true,
    name: "Alek Wójcik",
    total_sent: "1",
    recipient: "12",
    open_rate: "100%",
    clicked: "1",
    last_updated: {
      date: "14 Sep 2020",
      userName: "Artur Wilk",
    },
    created: {
      date: "4 step",
      userName: "Artur Wilk",
    },
  },
  {
    status: false,
    name: "Eryk Krawczyk",
    total_sent: "1",
    recipient: "12",
    open_rate: "100%",
    clicked: "1",
    last_updated: {
      date: "14 Sep 2020",
      userName: "Artur Wilk",
    },
    created: {
      date: "4 step",
      userName: "Artur Wilk",
    },
  },
  {
    status: true,
    name: "Marek Sawicki",
    total_sent: "1",
    recipient: "12",
    open_rate: "100%",
    clicked: "1",
    last_updated: {
      date: "14 Sep 2020",
      userName: "Artur Wilk",
    },
    created: {
      date: "4 step",
      userName: "Artur Wilk",
    },
  },
  {
    status: false,
    name: "Julian Kamiński",
    total_sent: "1",
    recipient: "12",
    open_rate: "100%",
    clicked: "1",
    last_updated: {
      date: "14 Sep 2020",
      userName: "Artur Wilk",
    },
    created: {
      date: "4 step",
      userName: "Artur Wilk",
    },
  },
  {
    status: true,
    name: "Norbert Sikorska",
    total_sent: "1",
    recipient: "12",
    open_rate: "100%",
    clicked: "1",
    last_updated: {
      date: "14 Sep 2020",
      userName: "Artur Wilk",
    },
    created: {
      date: "4 step",
      userName: "Artur Wilk",
    },
  },
  {
    status: false,
    name: "Albert Kwiatkowski",
    total_sent: "1",
    recipient: "12",
    open_rate: "100%",
    clicked: "1",
    last_updated: {
      date: "14 Sep 2020",
      userName: "Artur Wilk",
    },
    created: {
      date: "4 step",
      userName: "Artur Wilk",
    },
  },
  {
    status: true,
    name: "Eryk Włodarczyk",
    total_sent: "1",
    recipient: "12",
    open_rate: "100%",
    clicked: "1",
    last_updated: {
      date: "14 Sep 2020",
      userName: "Artur Wilk",
    },
    created: {
      date: "4 step",
      userName: "Artur Wilk",
    },
  },
  {
    status: false,
    name: "Kajetan Szczepański",
    total_sent: "1",
    recipient: "12",
    open_rate: "100%",
    clicked: "1",
    last_updated: {
      date: "14 Sep 2020",
      userName: "Artur Wilk",
    },
    created: {
      date: "4 step",
      userName: "Artur Wilk",
    },
  },
];
