import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { CameraIcon, DeleteIcon } from "src/UI/Svg";
import { useFindManyFilesByIds } from "src/graphql/hooks/useQueries";
import { isValidaImageFile } from "src/utils";
import { useTranslation } from "react-i18next";

import {
  AddPhoto,
  DelIconWrap,
  InputLogo,
  PhotoButton,
  PhotoText,
  PreviewImage,
} from "./styled";

function AddPhotoComponent({
  value,
  buttonText,
  text,
  onChange,
  onRemove,
  id,
  name,
  ...props
}: {
  onChange: (val: File | null) => void;
  onRemove: () => void;
  id: string;
  value: string | null;
  buttonText: string;
  text: string;
  name: string;
}) {
  const { t } = useTranslation();
  const [preview, setPreview] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { refetch } = useFindManyFilesByIds({
    variables: {
      schema: {
        ids: [value],
      },
    },
    skip: !value,
    onCompleted: (response) => {
      const logoUrl = response?.findManyFilesByIds?.files?.[0]?.url;

      if (logoUrl) setPreview(logoUrl as string);
    },
  });

  useEffect(() => {
    if (!value) {
      setPreview(null);
      return;
    }
    refetch({
      variables: {
        schema: {
          ids: [value],
        },
      },
    });
  }, [value]);

  const onRemoveFile = () => {
    if (preview) URL.revokeObjectURL(preview);
    if (inputRef && inputRef.current) inputRef.current.value = "";
    onChange(null);
    onRemove();
  };

  const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      onChange(null);
      return;
    }
    const objectUrl: string = URL.createObjectURL(e.target.files[0]);

    if (isValidaImageFile(e.target.files[0], t)) {
      setPreview(objectUrl);
      onChange(e.target.files[0]);
    }
  };
  return (
    <AddPhoto {...props}>
      {preview && <PreviewImage src={preview} />}
      <InputLogo
        id={id}
        type="file"
        accept="image"
        onChange={onSelectFile}
        ref={inputRef}
      />
      {!preview && (
        <PhotoButton htmlFor={id}>
          <CameraIcon />
          {buttonText}
        </PhotoButton>
      )}
      {text && <PhotoText>{text}</PhotoText>}
      {preview && (
        <DelIconWrap onClick={onRemoveFile}>
          <DeleteIcon />
        </DelIconWrap>
      )}
    </AddPhoto>
  );
}

export default AddPhotoComponent;
