import { Button } from "src/UI/Button";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  background: ${({ theme }) => theme.colors.lightBiege};
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  border-radius: 5px;
  max-width: 317px;
`;

export const ButtonBlock = styled.div`
  padding: 20px;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0;
  padding: 20px;
  max-width: 100%;
`;

export const ButtonStyled = styled(Button)`
  font-size: 20px;
  line-height: 100%;
  max-width: 100%;
  padding: 16px;
  overflow-wrap: anywhere;
`;

export const WidgetContent = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.darkGrey};
  word-wrap: break-word;
  max-width: 100%;
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 12px;
  }
`;

export const CopyButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
`;
