import { gql } from "@apollo/client";

export const GET_FIND_MANY_AVAILABLE_SLOTS = gql`
  query AvailableSlots($schema: FindManyAvailableSlotsSchema!) {
    findManyAvailableSlots(schema: $schema) {
      availableSlots {
        date
        slots {
          from
          to
        }
      }
    }
  }
`;
