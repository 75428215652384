import {
  FormMailTemplate,
  FormSmsTemplate,
  ITemplateCreateEdit,
} from "src/types/templates";
import * as yup from "yup";

type SchemaObject = {
  [key in keyof ITemplateCreateEdit]: yup.Schema<any>;
};

type SmsSchemaObject = {
  [key in keyof FormSmsTemplate]: yup.Schema<any>;
};

type MailSchemaObject = {
  [key in keyof FormMailTemplate]: yup.Schema<any>;
};

const regExVariableMask = /\$\{\S+?\}/g;

const isUsedNotTemplateVariables = (
  text?: string,
  templateVariables?: string[]
) => {
  const listVariableInBody = text
    ?.match(regExVariableMask)
    ?.map((item) => item.replace("${", "").replace("}", ""));

  if (
    templateVariables &&
    listVariableInBody?.some(
      (item) => !templateVariables.some((template) => template === item)
    )
  ) {
    return false;
  }

  return true;
};

export const createEditTemplate = (templateVariables?: string[]) => {
  return yup.object().shape<SchemaObject>({
    isActiveTemplate: yup.boolean(),
    title: yup.string().required("validation.field_required"),
    smsTemplates: yup.array().of(
      yup.object().shape<SmsSchemaObject>({
        languageISOCode: yup.string().required("validation.field_required"),
        delayInHours: yup.number().required("validation.field_required"),
        text: yup
          .string()
          .required("validation.field_required")
          .test(
            "is-invalid-variables",
            "validation.templates.invalid_variable",
            (value) => isUsedNotTemplateVariables(value, templateVariables)
          ),
      })
    ),
    mailTemplates: yup.array().of(
      yup.object().shape<MailSchemaObject>({
        languageISOCode: yup.string().required("validation.field_required"),
        subject: yup
          .string()
          .required("validation.field_required")
          .max(988, "validation.templates.subject.max_988")
          .test(
            "is-invalid-variables",
            "validation.templates.invalid_variable",
            (value) => isUsedNotTemplateVariables(value, templateVariables)
          ),
        text: yup
          .string()
          .test(
            "is-invalid-variables",
            "validation.templates.invalid_variable",
            (value) => isUsedNotTemplateVariables(value, templateVariables)
          ),
        delayInHours: yup.number().required("validation.field_required"),
      })
    ),
  });
};
