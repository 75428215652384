import { useAuthContext } from "src/context/AuthContext";

import { IAppointmentsFilters } from "src/types";
import { defaultVisitsLimit } from "src/constants/constants";
import { useScheduleAppointmentsByClient } from "./useScheduleAppointmentsByClient";
import { useScheduleAppointmentsByDoctor } from "./useScheduleAppointmentsByDoctor";

interface IGetAppointmentsByRole {
  filters: IAppointmentsFilters;
  limit?: number;
}

export function useScheduleAppointmentsByRole({
  filters,
  limit = defaultVisitsLimit,
}: IGetAppointmentsByRole) {
  const { isDoctor } = useAuthContext();

  const {
    data: scheduleAppointmentsByClientData,
    loading: scheduleAppointmentsByClientLoading,
    currentPage: scheduleAppointmentsByClientCurrentPage,
    pages: scheduleAppointmentsByClientPages,
    setCurrentPage: scheduleAppointmentsByClientSetCurrentPage,
  } = useScheduleAppointmentsByClient({ filters, limit });

  const {
    data: scheduleAppointmentsByDoctorData,
    loading: scheduleAppointmentsByDoctorLoading,
    currentPage: scheduleAppointmentsByDoctorCurrentPage,
    pages: scheduleAppointmentsByDoctorPages,
    setCurrentPage: scheduleAppointmentsByDoctorSetCurrentPage,
  } = useScheduleAppointmentsByDoctor({ filters, limit });

  const data = isDoctor
    ? scheduleAppointmentsByDoctorData?.findAndCountManyScheduleAppointmentsWithRelationsByDoctor
    : scheduleAppointmentsByClientData?.findAndCountManyScheduleAppointmentsWithRelationsByClient;

  const loading = isDoctor
    ? scheduleAppointmentsByDoctorLoading
    : scheduleAppointmentsByClientLoading;

  const currentPage = isDoctor
    ? scheduleAppointmentsByDoctorCurrentPage
    : scheduleAppointmentsByClientCurrentPage;

  const pages = isDoctor
    ? scheduleAppointmentsByDoctorPages
    : scheduleAppointmentsByClientPages;

  const setCurrentPage = isDoctor
    ? scheduleAppointmentsByDoctorSetCurrentPage
    : scheduleAppointmentsByClientSetCurrentPage;

  return {
    data,
    loading,
    currentPage,
    pages,
    setCurrentPage,
  };
}
