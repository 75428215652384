import { parsePhoneNumber } from "awesome-phonenumber";
import { availablesPhoneCodes } from "src/constants/constants";
import { FormFieldTypes } from "src/graphql/generated";
import * as yup from "yup";

export const createPatientBookingStep3ValidationSchema = yup.object().shape({
  fields: yup.array().of(
    yup
      .object()
      .test("check-is-required", "validation.field_required", (_, obj) => {
        if (
          obj.originalValue.type === FormFieldTypes.Phone &&
          availablesPhoneCodes.includes(obj.originalValue.fillValue) &&
          obj.originalValue.required
        ) {
          return false;
        }
        if (
          obj.originalValue.type === FormFieldTypes.Checkbox &&
          obj.originalValue.required
        ) {
          const lastCheckboxObject =
            obj.originalValue.fillValue[obj.originalValue.fillValue.length - 1];

          const hasTextProperty = "text" in lastCheckboxObject;

          if (hasTextProperty && lastCheckboxObject.text) return true;

          if (!hasTextProperty) return true;

          return false;
        }
        if (
          (obj.originalValue.type === FormFieldTypes.Date ||
            obj.originalValue.type === FormFieldTypes.Time) &&
          obj.originalValue.required
        ) {
          return !!obj.originalValue.fillValue;
        }
        if (obj.originalValue.required) {
          return !!obj.originalValue.fillValue?.length;
        }
        return true;
      })
      .test("check-max-length-text", "validation.field_max_256", (_, obj) => {
        if (obj.originalValue.type === FormFieldTypes.Text) {
          if (
            obj.originalValue.fillValue &&
            obj.originalValue.fillValue.length > 256
          )
            return false;
        }
        return true;
      })
      .test(
        "check-max-length-text-area",
        "validation.field_max_1024",
        (_, obj) => {
          if (obj.originalValue.type === FormFieldTypes.TextArea) {
            if (
              obj.originalValue.fillValue &&
              obj.originalValue.fillValue.length > 1024
            )
              return false;
          }
          return true;
        }
      )
      .test("check-max-length-number", "validation.field_max_10", (_, obj) => {
        if (obj.originalValue.type === FormFieldTypes.Number) {
          if (
            obj.originalValue.fillValue &&
            obj.originalValue.fillValue.length > 10
          )
            return false;
        }
        return true;
      })
      .test(
        "check-is-vali-phone",
        "validation.invalid_phone_number_required",
        (_, obj) => {
          if (
            obj.originalValue.type === FormFieldTypes.Phone &&
            !availablesPhoneCodes.includes(obj.originalValue.fillValue)
          ) {
            return parsePhoneNumber(`+${obj.originalValue.fillValue}` as string)
              .possible;
          }

          return true;
        }
      )
      .test("check-correct-email", "auth.login.email_error", (_, obj) => {
        if (obj.originalValue.type === FormFieldTypes.Email) {
          const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          if (obj.originalValue.fillValue.length)
            return emailRegex.test(obj.originalValue.fillValue);
        }
        return true;
      })
  ),
});
