import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  ClinicEntityResponse,
  CountedClinicsResponse,
} from "src/graphql/generated";
import { BranchPatientComponent } from "src/components/MapComponents";
import {
  useFindAndCountManyClinicsByClientId,
  useFindManyFilesByIds,
} from "src/graphql/hooks/useQueries";
import { Loading } from "src/UI/Loading";
import { useBookingAction, useBookingState } from "src/store/booking/hooks";
import { SelectedBookingClinic } from "src/store/booking/types";
import { useFetchMore } from "src/hooks/useFetchMore";
import { useLocation } from "react-router-dom";
import { defaultClinicsLimit } from "src/constants/constants";
import {
  CardStyled,
  EmptyTextStyled,
  List,
  LoadingWrap,
  NodeStyled,
} from "./styles";

interface MapPageProps {
  loading: boolean;
  fetchMore: (
    options: Parameters<
      ReturnType<typeof useFindAndCountManyClinicsByClientId>["fetchMore"]
    >[0]
  ) => Promise<unknown>;
  data: CountedClinicsResponse | null;
}

const ClinicsList: React.FC<MapPageProps> = ({ loading, fetchMore, data }) => {
  const { t } = useTranslation();

  const { onSetLocation, onSetClinic } = useBookingAction();
  const { currentClinic } = useBookingState();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedClinicRef = useRef<HTMLDivElement | null>(null);

  const clientId = queryParams.get("clientId");

  const handleSelect = (clinicData: SelectedBookingClinic) => {
    if (clinicData) {
      onSetLocation({
        center: [
          clinicData?.clinic?.geographicalCoordinates?.longitude,
          clinicData?.clinic?.geographicalCoordinates?.latitude,
        ],
      });

      onSetClinic(clinicData);
    }
  };

  const clinicsLogoIds = data?.clinics
    ?.map((clinic: any) => clinic?.logoFileId)
    .filter((id: any) => id);

  const { data: logoFiels } = useFindManyFilesByIds({
    variables: {
      schema: {
        ids: clinicsLogoIds,
      },
    },
    skip: !clinicsLogoIds,
  });

  const { node, isLoading } = useFetchMore(() => {
    return fetchMore({
      variables: {
        schema: {
          clientId,
          limit: defaultClinicsLimit,
          offset: data?.clinics.length,
        },
      },
    });
  });

  useEffect(() => {
    if (currentClinic && selectedClinicRef.current) {
      selectedClinicRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [currentClinic]);

  const count = data?.count || 0;

  const apptLength = data?.clinics?.length || 0;

  return (
    <CardStyled title={t("map.title")}>
      {loading ? (
        <LoadingWrap>
          <Loading />
        </LoadingWrap>
      ) : (
        <List>
          {data?.clinics.length ? (
            data?.clinics.map((clinic: ClinicEntityResponse) => (
              <BranchPatientComponent
                ref={
                  clinic.id === currentClinic?.clinic?.id
                    ? selectedClinicRef
                    : null
                }
                key={clinic.id}
                onSelect={handleSelect}
                clinic={clinic}
                isSelected={currentClinic?.clinic?.id === clinic.id}
                logoFiles={logoFiels?.findManyFilesByIds?.files}
              />
            ))
          ) : (
            <EmptyTextStyled>{t("app.clinics_not_added")}</EmptyTextStyled>
          )}
        </List>
      )}
      {isLoading ? (
        <LoadingWrap>
          <Loading />
        </LoadingWrap>
      ) : null}
      {count > apptLength ? <NodeStyled ref={node} /> : null}
    </CardStyled>
  );
};

export default ClinicsList;
