import React, { useState } from "react";
import styled from "styled-components";
import { ISelectedPreviewFilledForm } from "src/types";
import {
  FormPatientInfoView,
  FormPatientsTableView,
} from "src/views/FormViews";
import { AggregatedPatientEntityResponse } from "src/graphql/generated";

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 20px;
`;

function PatientsPage() {
  const [selectedPatient, setSelectedPatient] =
    useState<ISelectedPreviewFilledForm>();

  const handlePatientSelect = (patient: AggregatedPatientEntityResponse) => {
    setSelectedPatient({
      patient,
    });
  };

  return (
    <Wrapper>
      <FormPatientsTableView
        onSelectPreview={handlePatientSelect}
        selectedPreview={selectedPatient}
      />
      <FormPatientInfoView selectedPreview={selectedPatient} />
    </Wrapper>
  );
}

export default PatientsPage;
