import styled from "styled-components";

const DividerStyled = styled.hr<{
  color?: string;
  height?: string;
  margin?: string;
}>`
  border: none;
  border-top: ${({ theme, color }) =>
    color ? `1px solid ${theme.colors.borderInputDefault}` : "none"};
  background: ${({ theme, color }) =>
    color ? color : theme.colors.borderInputDefault};
  height: ${({ height }) => (height ? height : "1px")};
  margin: ${({ margin }) => (margin ? margin : "0")};
  width: ${({ color }) => (color ? "100%" : "auto")}; ;
`;

function Divider({
  color,
  height,
  margin,
}: {
  color?: string;
  height?: string;
  margin?: string;
}) {
  return <DividerStyled color={color} height={height} margin={margin} />;
}

export default Divider;
