import { useTranslation } from "react-i18next";

import { useAppAction, useAppState } from "src/store/app/hooks";
import { EModals } from "src/store/app/types";
import { Modal } from "src/UI/Modal";
import { EButtonVariants } from "src/UI/Button";
import { useCallback, useEffect, useState } from "react";
import { InputRHF } from "src/UI/Input";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { changeEmailSchema } from "src/validation/schemas/changeEmailSchema";
import { useAuthContext } from "src/context/AuthContext";
import { ActionBlock, ButtonStyled, Content, Form } from "./styles";

interface FormValues {
  email: string;
}

function ChangeEmailModal() {
  const { t } = useTranslation();
  const { modals } = useAppState();
  const { onCloseModal } = useAppAction();

  const { currentUserData } = useAuthContext();

  const [initialEmail, setInitialEmail] = useState("");

  const methods = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(changeEmailSchema),
    defaultValues: {
      email: "",
    },
  });

  const {
    watch,
    register,
    setValue,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = methods;

  useEffect(() => {
    reset({ email: modals[EModals.ChangeEmail]?.data.email });
  }, [modals[EModals.ChangeEmail]?.data.email, initialEmail]);

  const data = watch();

  const closeModal = useCallback(() => {
    onCloseModal(EModals.ChangeEmail);
  }, [onCloseModal]);

  useEffect(() => {
    if (!currentUserData) return;

    setInitialEmail(currentUserData?.email);
  }, [currentUserData]);

  const onSubmit = (formData: FormValues) => {
    modals[EModals.ChangeEmail].data.accept({
      ...formData,
    });
    setValue("email", "");
    closeModal();
  };

  if (!modals[EModals.ChangeEmail].active) return null;
  return (
    <Modal
      modal={EModals.ChangeEmail}
      title={t("user.person_data.data.buttons.change_email")}
    >
      <Content>
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <InputRHF
              val={data.email}
              label={t("user.person_data.data.labels.email") as string}
              inputType="email"
              errorMessage={errors.email}
              register={register("email", {
                required: true,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: t("auth.login.email_error"),
                },
              })}
            />

            <ActionBlock>
              <ButtonStyled
                variant={EButtonVariants.Text}
                onClick={closeModal}
                type="button"
              >
                {t("common.cancel")}
              </ButtonStyled>
              <ButtonStyled
                variant={EButtonVariants.Default}
                type="submit"
                disabled={!isValid || initialEmail === data.email}
              >
                {t("user.person_data.data.buttons.change_email")}
              </ButtonStyled>
            </ActionBlock>
          </Form>
        </FormProvider>
      </Content>
    </Modal>
  );
}

export default ChangeEmailModal;
