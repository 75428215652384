import React from "react";
import styled from "styled-components";
import { LangSelectComponent } from "src/components/LangSelectComponets";
import { Wrapper } from "./styled";

const WrapperStyled = styled(Wrapper)`
  svg {
    path {
      stroke: none;
    }
  }
  ${({ theme }) => theme.mediaQueries.ll} {
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

function LangComponent() {
  return (
    <WrapperStyled>
      <LangSelectComponent />
    </WrapperStyled>
  );
}

export default LangComponent;
