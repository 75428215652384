import { FieldError, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { EButtonVariants } from "src/UI/Button";

import { HeaderCover } from "src/UI/Header";
import { Heading } from "src/UI/Heading";
import { LangSelectComponent } from "src/components/LangSelectComponets";
import { LogoFullIcon } from "src/UI/Svg";
import { registrationCompanyValidationSchema } from "src/validation/schemas/registrationCompanySchema";
import { useCreateCompany } from "src/graphql/hooks/useMutations";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { PATHS } from "src/constants/constants";
import { Loading } from "src/UI/Loading";

import {
  AddressComponent,
  InputComponent,
} from "src/components/AuthComponents";
import { CHECK_IS_EXISTS_COMPANY } from "src/graphql/queries";
import InputNumberFormatComponent from "src/components/AuthComponents/InputNumberFormatComponent/InputNumberFormatComponent";
import { AutocompletePrediction } from "react-places-autocomplete";
import {
  ButtonStyled,
  DividedBlock,
  Form,
  FormWrapper,
  WrapperNav,
} from "../AuthPages/styled";

export interface IUserValue {
  description: string;
  type: string;
}

interface FormValues {
  companyName: string;
  companyAddress: null | AutocompletePrediction | IUserValue;
  companyNIP: string;
}

function RegistrationCompanyPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [createCompany, { loading }] = useCreateCompany();

  const methods = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(registrationCompanyValidationSchema),
    defaultValues: {
      companyName: "",
      companyAddress: null,
      companyNIP: "",
    },
  });

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = methods;

  const data = watch();

  const registrationHandler = (formData: FormValues) => {
    createCompany({
      variables: {
        schema: {
          address: formData.companyAddress?.description,
          name: formData.companyName,
          nip: formData.companyNIP,
        },
      },
      onError(error) {
        toast.error(error.message);
      },
      onCompleted: (response) => {
        if (response) {
          navigate(`${PATHS.cp}${PATHS.map}`);
        }
      },
      refetchQueries: [
        {
          query: CHECK_IS_EXISTS_COMPANY,
        },
      ],
      awaitRefetchQueries: true,
    });
  };

  return (
    <>
      <WrapperNav>
        <LogoFullIcon />
      </WrapperNav>
      <FormWrapper>
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(registrationHandler)}>
            <HeaderCover>
              <Heading as="h2">{t("auth.reg.company_title")}</Heading>
              <LangSelectComponent />
            </HeaderCover>
            <DividedBlock>
              <InputComponent
                placeholder={t("auth.reg.company_name")}
                inputType="text"
                val={data.companyName}
                errorMessage={errors.companyName}
                register={register("companyName")}
              />
              <InputNumberFormatComponent
                name="companyNIP"
                placeholder={t("auth.reg.company_nip") as string}
                errorMessage={errors.companyNIP}
              />
            </DividedBlock>
            <AddressComponent
              value={data.companyAddress}
              name="companyAddress"
              placeholder={t("auth.reg.company_address")}
              errorMessage={errors.companyAddress as FieldError}
            />
            <ButtonStyled
              variant={EButtonVariants.Default}
              disabled={!isValid || loading}
            >
              {loading ? <Loading type="secondary" padding="0" /> : null}
              {t("auth.reg.next")}
            </ButtonStyled>
          </Form>
        </FormProvider>
      </FormWrapper>
    </>
  );
}

export default RegistrationCompanyPage;
