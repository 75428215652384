import { gql } from "@apollo/client";

export const RESCHEDULE_SCHEDULE_APPOINTMENT_BY_PATIENT = gql`
  mutation RescheduleScheduleAppointmentByPatient(
    $schema: RescheduleScheduleAppointmentByPatientSchema!
  ) {
    rescheduleScheduleAppointmentByPatient(schema: $schema) {
      rescheduled
    }
  }
`;
