import styled from "styled-components";

export const ListStyled = styled.div<{ isShow: boolean }>`
  position: absolute;
  width: 100%;
  background: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.03);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
  min-height: 56px;
  padding-top: 56px;
  visibility: ${({ isShow }) => (isShow ? "visible" : "hidden")};
  opacity: ${({ isShow }) => (isShow ? "1" : "0")};
`;

export const ShowAllBlock = styled.div<{ isAutocompleteLength: boolean }>`
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 7px;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  cursor: ${({ isAutocompleteLength }) =>
    isAutocompleteLength ? "pointer" : "default"};
  color: ${({ theme, isAutocompleteLength }) =>
    isAutocompleteLength ? theme.colors.green : theme.colors.gray};
`;
