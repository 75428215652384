import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { endOfDay, formatISO, startOfDay } from "date-fns";
import {
  EDuration,
  TAppointmentsFilters,
  TCabinetsGroup,
  TDateTimeRange,
  TDoctorsGroup,
} from "./types";

export interface CalendarState {
  selectedDuration: EDuration;
  filters: TAppointmentsFilters;
  cabinetsGroup: TCabinetsGroup;
  doctorsGroup: TDoctorsGroup;
  dayTimeRange: TDateTimeRange;
  weekTimeRange: TDateTimeRange;
}

const initialState: CalendarState = {
  filters: {
    dateFrom: formatISO(startOfDay(new Date())),
    dateTo: formatISO(endOfDay(new Date())),
    roomIds: [],
    doctorIds: [],
    patientId: "",
    status: "",
  },
  cabinetsGroup: [],
  doctorsGroup: [],
  dayTimeRange: null,
  weekTimeRange: null,

  // TODO: may not be needed
  selectedDuration: EDuration.Day,
};

export const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    selectDuration: (state, action: PayloadAction<EDuration>) => {
      state.selectedDuration = action.payload;
    },
    setFilters: (
      state,
      action: PayloadAction<Partial<TAppointmentsFilters>>
    ) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    setCabinetsGroup: (state, action: PayloadAction<TCabinetsGroup>) => {
      state.cabinetsGroup = action.payload;
    },
    setDoctorsGroup: (state, action: PayloadAction<TDoctorsGroup>) => {
      state.doctorsGroup = action.payload;
    },
    setDayRange: (state, action: PayloadAction<TDateTimeRange>) => {
      state.dayTimeRange = action.payload;
    },
    setWeekRange: (state, action: PayloadAction<TDateTimeRange>) => {
      state.weekTimeRange = action.payload;
    },
  },
});

export const {
  selectDuration,
  setFilters,
  setCabinetsGroup,
  setDoctorsGroup,
  setDayRange,
  setWeekRange,
} = calendarSlice.actions;
export default calendarSlice.reducer;
