import React, {
  ReactNode,
  // useCallback,
  // useEffect,
  useRef,
  useState,
} from "react";

import { useOutsideClick } from "src/hooks/useOutsideClick";
import { MoreVerticalIcon } from "../Svg";
import { ActionItem, ButtonIcon, MenuAction, Wrapper } from "./styled";

function MoreAction({
  actions,
}: {
  actions: { title: string | ReactNode; callback: () => void }[];
}) {
  const [isShowMenu, setIsShowMenu] = useState<boolean>(false);
  const dropdown = useRef<HTMLDivElement>(null);

  useOutsideClick({ ref: dropdown, handler: () => setIsShowMenu(false) });

  return (
    <Wrapper ref={dropdown}>
      <ButtonIcon onClick={() => setIsShowMenu(true)} type="button">
        <MoreVerticalIcon />
      </ButtonIcon>
      <MenuAction isShowMenu={isShowMenu}>
        {actions.map((action, id) => (
          <ActionItem key={id} onClick={action.callback} type="button">
            {action.title}
          </ActionItem>
        ))}
      </MenuAction>
    </Wrapper>
  );
}

export default MoreAction;
