import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IDoctorBooking, IPatientItem, IService } from "../../types";
import { doctors, patients, services } from "./mock";
import {
  Location,
  PatientInfo,
  SelectedBookingClinic,
  SelectedBookingFormFields,
  SelectedBookingDoctor,
  SelectedBookingService,
  SelectedBookingSlot,
  SelectedRescheduleAppointment,
  SelectedDate,
  SelectedWaitListSlot,
  SelectedRescheduleWaitlist,
} from "./types";

export interface BookingState {
  patients: IPatientItem[];
  services: IService[];
  doctors: IDoctorBooking[];

  currentLocation: Location;
  currentClinic: SelectedBookingClinic;
  currentService: SelectedBookingService;
  currentFormFields: SelectedBookingFormFields;
  patientInfo: PatientInfo;
  currentDoctor: SelectedBookingDoctor;
  currentSlot: SelectedBookingSlot;
  currentDateWaitListSlot: SelectedWaitListSlot;
  rescheduleAppointment: SelectedRescheduleAppointment;
  rescheduleWaitlist: SelectedRescheduleWaitlist;
  currentDate: SelectedDate;
}

const initialState: BookingState = {
  patients,
  services,
  doctors,

  currentLocation: null,
  currentClinic: null,
  currentService: null,
  currentFormFields: null,
  patientInfo: null,
  currentDoctor: null,
  currentSlot: null,
  rescheduleAppointment: null,
  rescheduleWaitlist: null,
  currentDate: null,
  currentDateWaitListSlot: null,
};

export const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    // selectChat: (state, action: PayloadAction<number>) => {
    selectChat: () => {},
    setLocation: (state, action: PayloadAction<Location>) => {
      state.currentLocation = action.payload;
    },
    setClinic: (state, action: PayloadAction<SelectedBookingClinic>) => {
      state.currentClinic = action.payload;
    },
    setService: (state, action: PayloadAction<SelectedBookingService>) => {
      state.currentService = action.payload;
    },
    setFormFields: (
      state,
      action: PayloadAction<SelectedBookingFormFields>
    ) => {
      state.currentFormFields = action.payload;
    },
    setPatientInfo: (state, action: PayloadAction<PatientInfo>) => {
      state.patientInfo = action.payload;
    },
    setDoctor: (state, action: PayloadAction<SelectedBookingDoctor>) => {
      state.currentDoctor = action.payload;
    },
    setSlot: (state, action: PayloadAction<SelectedBookingSlot>) => {
      state.currentSlot = action.payload;
    },
    setWaitListSlot: (state, action: PayloadAction<SelectedWaitListSlot>) => {
      state.currentDateWaitListSlot = action.payload;
    },
    setRescheduleAppointment: (
      state,
      action: PayloadAction<SelectedRescheduleAppointment>
    ) => {
      state.rescheduleAppointment = action.payload;
    },
    setRescheduleWaitlist: (
      state,
      action: PayloadAction<SelectedRescheduleWaitlist>
    ) => {
      state.rescheduleWaitlist = action.payload;
    },
    setDate: (state, action: PayloadAction<SelectedDate>) => {
      state.currentDate = action.payload;
    },
  },
});

export const {
  selectChat,
  setLocation,
  setClinic,
  setService,
  setFormFields,
  setDoctor,
  setSlot,
  setWaitListSlot,
  setPatientInfo,
  setRescheduleAppointment,
  setRescheduleWaitlist,
  setDate,
} = bookingSlice.actions;
export default bookingSlice.reducer;
