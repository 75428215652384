import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useAuthContext } from "src/context/AuthContext";
import {
  AppointmentStatuses,
  useUpdateScheduleAppointmentMissedStatusByDoctorMutation,
  useUpdateScheduleAppointmentStatusByIdByClientMutation,
} from "src/graphql/generated";
import {
  useCancelScheduleAppointmentByClient,
  useConfirmScheduleAppointmentByClient,
  useUpdateScheduleAppointmentMissedStatusByClient,
} from "src/graphql/hooks/useMutations";
import {
  COUNT_SCHEDULE_APPOINTMENTS_BY_STATUSES_BY_CLIENT,
  COUNT_SCHEDULE_APPOINTMENTS_BY_STATUSES_BY_DOCTOR,
  FIND_AND_COUNT_MANY_APPOIINTMENTS_WITH_RELATIONS_BY_CLIENT,
  FIND_AND_COUNT_MANY_APPOIINTMENTS_WITH_RELATIONS_BY_DOCTOR,
  FIND_MANY_SCHEDULE_APPOINTMENT_UPDATES_BY_ID_BY_CLIENT,
} from "src/graphql/queries";

interface IUseUpdateStatusProps {
  id: string;
  closeModal?: () => void;
}

export function useUpdateStatus({ id, closeModal }: IUseUpdateStatusProps) {
  const { t } = useTranslation();
  const { isDoctor } = useAuthContext();

  const queryToRefetchByRole = isDoctor
    ? [
        FIND_AND_COUNT_MANY_APPOIINTMENTS_WITH_RELATIONS_BY_DOCTOR,
        COUNT_SCHEDULE_APPOINTMENTS_BY_STATUSES_BY_DOCTOR,
      ]
    : [
        FIND_MANY_SCHEDULE_APPOINTMENT_UPDATES_BY_ID_BY_CLIENT,
        FIND_AND_COUNT_MANY_APPOIINTMENTS_WITH_RELATIONS_BY_CLIENT,
        COUNT_SCHEDULE_APPOINTMENTS_BY_STATUSES_BY_CLIENT,
      ];

  const [confirmAppointment, { loading: confirmAppointmentLoading }] =
    useConfirmScheduleAppointmentByClient();
  const [cancelAppointment, { loading: cancelAppointmentLoading }] =
    useCancelScheduleAppointmentByClient();
  const [
    missedAppointmentByClient,
    { loading: missedAppointmentLoadingByClient },
  ] = useUpdateScheduleAppointmentMissedStatusByClient();
  const [
    missedAppointmentByDoctor,
    { loading: missedAppointmentLoadingByDoctor },
  ] = useUpdateScheduleAppointmentMissedStatusByDoctorMutation();
  const [updateAppointmentStatusById, { loading: updateStatusLoading }] =
    useUpdateScheduleAppointmentStatusByIdByClientMutation();

  const missedAppointment = isDoctor
    ? missedAppointmentByDoctor
    : missedAppointmentByClient;

  const missedAppointmentLoading =
    missedAppointmentLoadingByDoctor || missedAppointmentLoadingByClient;

  const updateStatusHandler = (buttonStatus: string) => () => {
    switch (buttonStatus) {
      case AppointmentStatuses.Missed:
        missedAppointment({
          variables: {
            schema: {
              id,
            },
          },
          onCompleted: () => {
            toast.success(t("app.successful"));
            if (closeModal) {
              closeModal();
            }
          },
          refetchQueries: queryToRefetchByRole,
        });
        break;

      case AppointmentStatuses.ConfirmedByApplication:
        confirmAppointment({
          variables: {
            schema: {
              id,
            },
          },
          onCompleted: () => {
            toast.success(t("app.successful"));
            if (closeModal) {
              closeModal();
            }
          },
          refetchQueries: [
            FIND_MANY_SCHEDULE_APPOINTMENT_UPDATES_BY_ID_BY_CLIENT,
            FIND_AND_COUNT_MANY_APPOIINTMENTS_WITH_RELATIONS_BY_CLIENT,
            COUNT_SCHEDULE_APPOINTMENTS_BY_STATUSES_BY_CLIENT,
          ],
        });
        break;

      case AppointmentStatuses.Canceled:
        cancelAppointment({
          variables: {
            schema: {
              id,
            },
          },
          onCompleted: () => {
            toast.success(t("app.successful"));
            if (closeModal) {
              closeModal();
            }
          },
          refetchQueries: [
            FIND_MANY_SCHEDULE_APPOINTMENT_UPDATES_BY_ID_BY_CLIENT,
            FIND_AND_COUNT_MANY_APPOIINTMENTS_WITH_RELATIONS_BY_CLIENT,
            COUNT_SCHEDULE_APPOINTMENTS_BY_STATUSES_BY_CLIENT,
          ],
        });
        break;
      case AppointmentStatuses.Successful:
        updateAppointmentStatusById({
          variables: {
            schema: {
              id,
              status: AppointmentStatuses.Successful,
            },
          },
          onCompleted: () => {
            toast.success(t("app.successful"));
            if (closeModal) {
              closeModal();
            }
          },
          onError: (error) => {
            if (error) toast.error(error.message);
          },
          refetchQueries: [
            FIND_MANY_SCHEDULE_APPOINTMENT_UPDATES_BY_ID_BY_CLIENT,
            FIND_AND_COUNT_MANY_APPOIINTMENTS_WITH_RELATIONS_BY_CLIENT,
            COUNT_SCHEDULE_APPOINTMENTS_BY_STATUSES_BY_CLIENT,
          ],
        });
        break;

      default:
        break;
    }
  };
  return {
    updateStatusHandler,
    confirmAppointmentLoading,
    cancelAppointmentLoading,
    missedAppointmentLoading,
    updateStatusLoading,
  };
}
