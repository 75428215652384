import React, { FunctionComponent } from "react";
import { Svg, SvgProps } from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <g clipPath="url(#)">
      <path
        d="M11.9999 0C9.41389 0 7.31055 2.10392 7.31055 4.68995V7.11969C7.31055 9.70591 9.41389 11.8098 11.9999 11.8098C14.5861 11.8098 16.6893 9.70591 16.6893 7.11969V4.68995C16.6893 2.10392 14.5861 0 11.9999 0Z"
        fill="#A5A5A5"
      />
      <path
        d="M22.3412 17.9214C20.8396 15.3624 18.4394 13.4653 15.5829 12.5794C15.5166 12.5588 15.445 12.5763 15.3957 12.6251C14.0669 13.9353 12.4157 14.9575 12.0029 15.2044C11.5622 14.9227 9.70619 13.7129 8.60277 12.6251C8.55362 12.5763 8.48143 12.5588 8.41557 12.5794C5.55864 13.4655 3.15866 15.3626 1.65761 17.9216C1.62324 17.9801 1.62324 18.0529 1.65761 18.1114C3.78918 21.7437 7.75183 24 11.9992 24C16.2468 24 20.2097 21.7437 22.3412 18.1114C22.3758 18.0527 22.3758 17.9799 22.3412 17.9214Z"
        fill="#A5A5A5"
      />
    </g>
    <defs>
      <clipPath id="">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </Svg>
);

export default Icon;
