import { DEFAULT_TEMPLATE_ACTIONS } from "src/constants/constants";
import {
  CreateMailTemplateSchema,
  CreateSmsTemplateSchema,
  CreateTemplateSchema,
  DistributionActions,
  LanguageIsoCodes,
  MailTemplateResponse,
  SmsTemplateResponse,
  TemplateEntityResponse,
  UpdateMailTemplateByClientSchema,
  UpdateSmsTemplateByClientSchema,
  UpdateTemplateByClientSchema,
} from "src/graphql/generated";
import {
  FormMailTemplate,
  FormSmsTemplate,
  ITemplateAction,
  ITemplateCreateEdit,
} from "src/types/templates";
import { getLanguageIsoCode } from "src/utils/translate";

const defaultDelayInHours = 0;

export const transformTemplateActionToTemplateAction = (
  actions: DistributionActions[]
) => {
  const result = actions.reduce<ITemplateAction[]>((acc, current) => {
    if (
      current === DistributionActions.SendMail ||
      current === DistributionActions.SendSms
    ) {
      const templateAction = DEFAULT_TEMPLATE_ACTIONS.find(
        (template) => template.name === current
      );

      acc.push({
        name: current,
        titleId: templateAction?.titleId || "",
        subtitleId: templateAction?.subtitleId || "",
      });
    }

    return acc;
  }, []);

  return result;
};

export const transformTemplateToFormCreateEdit = (
  template: TemplateEntityResponse
): ITemplateCreateEdit => {
  const result: Partial<ITemplateCreateEdit> = {
    isActiveTemplate: !!template?.clinicsTemplates,
    selectedAction: undefined,
    title: template.name || "",
    selectedEvent: template?.event,
    templateActions: transformTemplateActionToTemplateAction(template.actions),
  };

  if (template.mailTemplates) {
    result.mailTemplates = template.mailTemplates.map(
      ({
        createdAt,
        __typename,
        templateId,
        languageISOCode,
        ...mailTemplates
      }) => ({
        ...mailTemplates,
        languageISOCode: getLanguageIsoCode(languageISOCode),
        subject: mailTemplates?.subject || "",
      })
    );
  }

  if (template.smsTemplates) {
    result.smsTemplates = template.smsTemplates.map(
      ({
        createdAt,
        __typename,
        templateId,
        languageISOCode,
        ...smsTemplates
      }) => ({
        languageISOCode: getLanguageIsoCode(languageISOCode),
        ...smsTemplates,
      })
    );
  }

  return result as ITemplateCreateEdit;
};

const transformFormMailTemplateToCreateSmsTemplateSchema = (
  mailTemplates?: FormMailTemplate[]
): CreateMailTemplateSchema[] | undefined => {
  if (!mailTemplates) return;

  return mailTemplates.map(
    ({ delayInHours, languageISOCode, text, subject }) => ({
      delayInHours: delayInHours || defaultDelayInHours,
      languageISOCode: languageISOCode as LanguageIsoCodes,
      text: text || "",
      subject: subject || "",
    })
  );
};

const transformFormSmsTemplateToCreateSmsTemplateSchema = (
  smsTemplates?: FormSmsTemplate[]
): CreateSmsTemplateSchema[] | undefined => {
  if (!smsTemplates) return;

  return smsTemplates.map(({ languageISOCode, delayInHours, text }) => ({
    delayInHours: delayInHours || defaultDelayInHours,
    languageISOCode: languageISOCode as LanguageIsoCodes,
    text: text || "",
  }));
};

export const transformTemplateCreateEditToCreate = (
  {
    title,
    mailTemplates,
    templateActions,
    smsTemplates,
    selectedEvent,
  }: ITemplateCreateEdit,
  clinicId: string
): CreateTemplateSchema | undefined => {
  if (!selectedEvent || !templateActions) {
    return;
  }

  const mailTemplatesValue =
    transformFormMailTemplateToCreateSmsTemplateSchema(mailTemplates);
  const smsTemplatesValue =
    transformFormSmsTemplateToCreateSmsTemplateSchema(smsTemplates);

  const result: Partial<CreateTemplateSchema> = {
    clinicId,
    name: title,
    event: selectedEvent,
    actions: templateActions?.map(({ name }) => name),
  };

  if (mailTemplatesValue) {
    result.mailTemplates = mailTemplatesValue;
  }

  if (smsTemplatesValue) {
    result.smsTemplates = smsTemplatesValue;
  }

  return result as CreateTemplateSchema;
};

export const transformTemplateCreateEditToUpdate = (
  { mailTemplates, smsTemplates, title, templateActions }: ITemplateCreateEdit,
  selectedTemplateData: TemplateEntityResponse
) => {
  const mailIds = selectedTemplateData?.mailTemplates?.map((template) => ({
    id: template.id,
  }));
  const mailTemplatesValue =
    mailTemplates?.map<UpdateMailTemplateByClientSchema>((mailTemplate, i) => ({
      id: mailIds![i]?.id,
      fields: {
        delayInHours: mailTemplate.delayInHours,
        subject: mailTemplate.subject,
        text: mailTemplate.text,
      },
    }));

  const smsIds = selectedTemplateData?.smsTemplates?.map((template) => ({
    id: template.id,
  }));
  const smsTemplatesValue = smsTemplates?.map<UpdateSmsTemplateByClientSchema>(
    (mailTemplate, i) => ({
      id: smsIds![i]?.id,
      fields: {
        delayInHours: mailTemplate.delayInHours,
        text: mailTemplate.text,
      },
    })
  );

  const updateTemplateValue: UpdateTemplateByClientSchema = {
    id: selectedTemplateData.id,
    fields: {
      actions: templateActions?.map((item) => item.name) || [],
      name: title,
    },
  };

  const result = {
    mailTemplatesValue,
    smsTemplatesValue,
    updateTemplateValue,
  };

  return result;
};

export const transformDefaultMailTemplateToFormMailTemplate = (
  mailTemplates: MailTemplateResponse[],
  langs: LanguageIsoCodes[]
): FormMailTemplate[] => {
  return mailTemplates.map(({ text, subject }, index) => ({
    delayInHours: defaultDelayInHours,
    languageISOCode: langs[index],
    text: text || "",
    subject: subject || "",
  }));
};

export const transformDefaultSmsTemplateToFormMailTemplate = (
  mailTemplates: SmsTemplateResponse[],
  langs: LanguageIsoCodes[]
): FormSmsTemplate[] => {
  return mailTemplates.map(({ text }, index) => ({
    delayInHours: defaultDelayInHours,
    languageISOCode: langs[index],
    text: text || "",
  }));
};
