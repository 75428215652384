import styled from "styled-components";

export const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  gap: 20px;
  padding-bottom: 120px;
  max-width: 1240px;
`;

export const Tabs = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 16px;
  }
`;

export const Tab = styled.div<{ isActive: boolean }>`
  position: relative;
  left: 5px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 4px;
  font-size: 14px;
  line-height: 100%;
  color: ${({ theme, isActive }) => theme.colors[isActive ? "green" : "black"]};
  font-weight: 600;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    top: calc(100% + 26px);
    border-bottom: 2px solid
      ${({ theme, isActive }) =>
        isActive ? theme.colors.green : "transparent"};
  }
  span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2px 4.5px;
    background: ${({ theme, isActive }) =>
      theme.colors[isActive ? "green" : "grayC4"]};
    border-radius: 800px;
    color: ${({ theme }) => theme.colors.white};
  }
`;
