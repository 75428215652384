import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { EButtonVariants } from "src/UI/Button";
import { PlusIcon } from "src/UI/Svg";
import { ServiceComponent } from "src/components/BookingComponents";
import { useAppAction } from "src/store/app/hooks";
import { CardComponent } from "src/components/UserComponents";
import {
  ServiceEntityWithRelationsResponse,
  ServiceSortFields,
  SortingOrders,
  useFindManyServicesLazyQuery,
} from "src/graphql/generated";
import { Loading } from "src/UI/Loading";
import { useClinicsState } from "src/store/clinics/hooks";
import { EModals } from "src/store/app/types";
import {
  ButtonStyled,
  EmptyTextStyled,
  List,
  LoadingWrap,
  Wrapper,
} from "./styles";

function ServicesView() {
  const { t } = useTranslation();

  const { currentClinicId: clinicId } = useClinicsState();

  const [services, setServices] = useState<
    ServiceEntityWithRelationsResponse[]
  >([]);

  const { currentLocation, currentClinicId } = useClinicsState();

  const { onShowModal } = useAppAction();

  const [getServices, { loading }] = useFindManyServicesLazyQuery();

  useEffect(() => {
    getServices({
      variables: {
        schema: {
          clinicId,
          sortField: ServiceSortFields.CreatedAt,
          sortOrder: SortingOrders.Descending,
        },
      },
      onCompleted: (response) => {
        setServices(response.findManyServices?.services);
      },
    });
  }, [currentLocation]);

  const handleShowModal = () => {
    onShowModal(EModals.AddService);
  };

  return (
    <Wrapper>
      <CardComponent
        title={t("booking.services.title")}
        count={services.length}
        headerChildren={
          <ButtonStyled
            onClick={handleShowModal}
            variant={EButtonVariants.Default}
            disabled={!currentClinicId}
          >
            <PlusIcon /> {t("booking.services.add")}
          </ButtonStyled>
        }
      >
        {loading ? (
          <LoadingWrap>
            <Loading type="primary" />
          </LoadingWrap>
        ) : (
          <List>
            {services.length ? (
              services.map((service: ServiceEntityWithRelationsResponse) => (
                <ServiceComponent
                  key={service.id as never}
                  service={service}
                  clinicId={clinicId}
                />
              ))
            ) : (
              <EmptyTextStyled>{t("app.services_not_added")}</EmptyTextStyled>
            )}
          </List>
        )}
      </CardComponent>
    </Wrapper>
  );
}

export default ServicesView;
