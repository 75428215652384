import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { RadioBoxRHF } from "src/UI/Input";
import {
  // CommunicationPreferenceItem,
  // CommunicationPreferenceItemName,
  // CommunicationPreferenceTitle,
  // StyledCheckIcon,
  // ToggleContainer,
  // ToggleLabel,
  // StyledToggleRHF,
  Wrapper,
  ToggleContainerAdditionalInfo,
  AdditionalInfoHead,
  StyledClinicIcon,
  AdditionalInfoTitle,
  AdditionalInfoWrap,
  AdditionalInfoItem,
  AdditionalInfoItemName,
  InputAutosizeRHFStyled,
} from "./styled";

function CommunicationPreferences() {
  const { t } = useTranslation();

  const { control, watch, register } = useFormContext();
  const [isAllergy, allergyComment] = watch(["isAllergy", "allergyComment"]);

  return (
    <Wrapper>
      {/* // TODO temporary comment */}
      {/* <CommunicationPreferenceItem>
        <CommunicationPreferenceTitle>
          {t("patients.communication_preferences.title")}
        </CommunicationPreferenceTitle>
      </CommunicationPreferenceItem>

      <CommunicationPreferenceItem>
        <CommunicationPreferenceItemName>
          <StyledCheckIcon />

          {t("patients.communication_preferences.field_name.distribution")}
        </CommunicationPreferenceItemName>

        <ToggleContainer>
          <StyledToggleRHF
            name="emailDistribution"
            labelTextBefore={
              <ToggleLabel>{t("forms.modals.fields.email")}</ToggleLabel>
            }
          />

          <StyledToggleRHF
            name="smsDistribution"
            labelTextBefore={
              <ToggleLabel>{t("patients.unsubscribe.labels.sms")}</ToggleLabel>
            }
          />
        </ToggleContainer>
      </CommunicationPreferenceItem> */}
      <AdditionalInfoHead>
        <StyledClinicIcon />
        <AdditionalInfoTitle>{t("app.additional_info")}</AdditionalInfoTitle>
      </AdditionalInfoHead>
      <AdditionalInfoWrap>
        <AdditionalInfoItem>
          <AdditionalInfoItemName>
            {t("patients.additional_info.allergy")}
          </AdditionalInfoItemName>

          <ToggleContainerAdditionalInfo>
            <Controller
              control={control}
              name="isAllergy"
              defaultValue={isAllergy}
              render={({ field }) => {
                return (
                  <>
                    <RadioBoxRHF
                      id="isYes"
                      label={t(
                        "forms.tabs.patients_submissions.view_filled_form.yes"
                      )}
                      value="yes"
                      field={field}
                    />
                    <RadioBoxRHF
                      id="isNo"
                      label={t(
                        "forms.tabs.patients_submissions.view_filled_form.no"
                      )}
                      value="no"
                      field={field}
                    />
                  </>
                );
              }}
            />
          </ToggleContainerAdditionalInfo>
        </AdditionalInfoItem>
        <AdditionalInfoItem>
          <AdditionalInfoItemName>
            {t("patients.additional_info.allergy_comment")}
          </AdditionalInfoItemName>
          <InputAutosizeRHFStyled
            val={allergyComment}
            label={t("patients.add.labels.description") as string}
            inputType="text"
            register={register("allergyComment")}
          />
        </AdditionalInfoItem>
      </AdditionalInfoWrap>
    </Wrapper>
  );
}

export default CommunicationPreferences;
