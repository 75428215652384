import { Button } from "src/UI/Button";
import { Heading } from "src/UI/Heading";
import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
`;

export const HeadingStyled = styled(Heading)`
  font-size: 24px;
  line-height: 100%;
`;

export const Content = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 16px;
    gap: 16px;
  }
`;

export const RightWrapper = styled.div`
  width: 414px;
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  height: fit-content;
`;

export const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  width: 100%;
  gap: 15px;
  margin-top: 36px;
`;

export const ButtonStyled = styled(Button)`
  padding: 15px 35px;
  font-size: 20px;
  line-height: 100%;
  max-width: unset;
  width: fit-content;
`;
