import { ChangeAccountIcon, LogoutIcon } from "src/UI/Svg";
import { useTranslation } from "react-i18next";
import { useAuthContext } from "src/context/AuthContext";
import { ActionItemStiled, ListStyled } from "./styled";

interface ProfileMenuComponentProps {
  isShow: boolean;
  onToggleList: (state: boolean) => void;
}

function ProfileMenuComponent({
  isShow,
  onToggleList,
}: ProfileMenuComponentProps) {
  const { t } = useTranslation();
  const { logout } = useAuthContext();

  const handleShowListClick = () => {
    onToggleList(true);
  };

  return (
    <ListStyled isShow={isShow}>
      <ActionItemStiled onClick={handleShowListClick}>
        <ChangeAccountIcon />
        {t("app.сhange_account")}
      </ActionItemStiled>
      <ActionItemStiled onClick={logout}>
        <LogoutIcon />
        {t("app.logout")}
      </ActionItemStiled>
    </ListStyled>
  );
}

export default ProfileMenuComponent;
