import {
  ClinicRoomEntityResponse,
  ServiceEntityWithRelationsResponse,
} from "src/graphql/generated";

export const transformBeforeEditService = (
  serviceResponse: ServiceEntityWithRelationsResponse | undefined
) => {
  if (serviceResponse) {
    const cabinets = serviceResponse?.clinicRooms?.map(
      (cabinet: ClinicRoomEntityResponse) => cabinet.id
    );
    return {
      categoryId: serviceResponse?.category?.id,
      description: serviceResponse?.description,
      durationInMinutes: serviceResponse?.durationInMinutes,
      hiddenFromPatients: serviceResponse?.hiddenFromPatients,
      name: serviceResponse?.name,
      price: serviceResponse?.price,
      currencyCode: serviceResponse?.currencyCode || "",
      roomIds: cabinets || [],
    };
  }
};
