import React, { useCallback, useId } from "react";
import { useTranslation } from "react-i18next";
import { EButtonVariants } from "src/UI/Button";
import { CheckBox } from "src/UI/Input";
import { useAppAction, useAppState } from "src/store/app/hooks";
import { EModals, IPatientVisitSettingsFieldsList } from "src/store/app/types";
import { Modal } from "src/UI/Modal";
import { Buttons, ButtonStyled, Content } from "./styles";

function PatientVisitSettingsModal() {
  const { t } = useTranslation();
  const { modals } = useAppState();
  const { onCloseModal, onTogglePatientVisitSettings } = useAppAction();

  const closeModal = useCallback(() => {
    onCloseModal(EModals.PatientVisitSettings);
  }, [onCloseModal]);

  const changeProp = useCallback((field: IPatientVisitSettingsFieldsList) => {
    onTogglePatientVisitSettings(field);
  }, []);

  const propsId = useId();
  const modalSettings = modals[EModals.PatientVisitSettings];

  const handleChangeCheckBox =
    (field: IPatientVisitSettingsFieldsList) => () => {
      changeProp(field);
    };

  if (!modalSettings.active) return null;
  return (
    <Modal
      modal={EModals.PatientVisitSettings}
      title={t("modals.patient_settings.title")}
    >
      <Content>
        {Object.keys(modalSettings.fields).map((field) => (
          <CheckBox
            key={field}
            value={
              modalSettings.fields[
                field as IPatientVisitSettingsFieldsList
              ] as boolean
            }
            onChange={handleChangeCheckBox(
              field as IPatientVisitSettingsFieldsList
            )}
            id={propsId + field}
            label={t(`modals.patient_settings.${field}`) || ""}
          />
        ))}
      </Content>
      <Buttons>
        <ButtonStyled variant={EButtonVariants.Text} onClick={closeModal}>
          {t("common.cancel")}
        </ButtonStyled>
        <ButtonStyled variant={EButtonVariants.Default} onClick={closeModal}>
          {t("common.save")}
        </ButtonStyled>
      </Buttons>
    </Modal>
  );
}

export default PatientVisitSettingsModal;
