import { gql } from "@apollo/client";

export const RESCHEDULE_WAITLIST_BY_CLIENT = gql`
  mutation RescheduleWaitlistByClient(
    $schema: RescheduleScheduleWaitlistByClientSchema!
  ) {
    rescheduleWaitlistByClient(schema: $schema) {
      rescheduled
    }
  }
`;
