import { ReactNode, createContext, useContext, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  DistributionSystemEvents,
  FindManyTemplateVariablesByClientResponse,
  LanguageIsoCodes,
  useFindManyTemplateVariablesByClientQuery,
} from "src/graphql/generated";
import {
  useTemplateDefault,
  useTemplateFormSelected,
} from "src/hooks/templates";
import { useTemplateFocusOnFormErrors } from "src/hooks/templates/useTemplateFormErrors";
import { ITemplateCreateEdit } from "src/types/templates";

interface ITemplateContextProps {
  variablesTemplate?: FindManyTemplateVariablesByClientResponse["variables"];
  defaultTemplate?: ReturnType<typeof useTemplateDefault>;
  selectedTemplate?: ReturnType<typeof useTemplateFormSelected>;
  selectedLang?: LanguageIsoCodes;
  isShowAddNewAction?: boolean;
  onSelectLang: (lang: LanguageIsoCodes) => void;
  onShowAddNewAction: (isShow: boolean) => void;
}

interface ITemplateProviderProps {
  children: ReactNode;
}
export const TemplateContext = createContext<ITemplateContextProps>({
  onSelectLang: () => {},
  onShowAddNewAction: () => {},
});

export function useTemplateContext() {
  const templateContext = useContext(TemplateContext);

  if (!templateContext) {
    throw new Error(
      "useTemplateContext must be used within a TemplateProvider"
    );
  }

  return templateContext;
}

export function TemplateProvider({ children }: ITemplateProviderProps) {
  const { watch } = useFormContext<ITemplateCreateEdit>();
  const { i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState(
    i18n.resolvedLanguage === LanguageIsoCodes.En.toLocaleLowerCase()
      ? LanguageIsoCodes.En
      : LanguageIsoCodes.Pl
  );
  const [isShowAddNewAction, setIsShowAddNewAction] = useState(false);

  const event = watch("selectedEvent");

  const { data } = useFindManyTemplateVariablesByClientQuery({
    variables: {
      schema: {
        event: event as DistributionSystemEvents,
      },
    },
    skip: !event,
  });

  const selectedTemplate = useTemplateFormSelected(selectedLang);

  useTemplateFocusOnFormErrors(selectedLang, setSelectedLang);

  const handleSelectedLangChange = (lang: LanguageIsoCodes) => {
    setSelectedLang(lang);
  };

  const value = useMemo<ITemplateContextProps>(() => {
    return {
      selectedLang,
      selectedTemplate,
      variablesTemplate: data?.findManyTemplateVariablesByClient?.variables,
      isShowAddNewAction,
      onSelectLang: handleSelectedLangChange,
      onShowAddNewAction: setIsShowAddNewAction,
    };
  }, [
    selectedLang,
    selectedTemplate,
    selectedTemplate?.selectedTemplate,
    isShowAddNewAction,
  ]);

  return (
    <TemplateContext.Provider value={value}>
      {children}
    </TemplateContext.Provider>
  );
}
