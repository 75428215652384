import styled from "styled-components";

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  flex-direction: column;
`;
// TODO commeted because it media query maybe be needs in the future
// ${({ theme }) => theme.mediaQueries.ll} {
//   gap: 12px;
// }

export const InputBlock = styled.div<{ isError: boolean }>`
  position: relative;
  width: 100%;

  & input {
    width: 100%;
    border: none;
    outline: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    color: ${({ theme }) => theme.colors.black};
    padding: 32px 0;
    border-bottom: 1px solid
      ${({ theme, isError }) =>
        theme.colors[isError ? "red" : "borderInputDefault"]};

    &:focus {
      border-color: ${({ theme, isError }) =>
        theme.colors[isError ? "red" : "green"]};
    }

    &::placeholder {
      color: ${({ theme }) => theme.colors.gray};
    }

    &[type="password"] {
      font: small-caption;
      font-size: 16px;
      line-height: 140%;

      &::placeholder {
        font-family: "SFProDisplay", system-ui;
        font-weight: 500;
      }
    }
  }
`;

// TODO commeted because it media query maybe be needs in the future
// ${({ theme }) => theme.mediaQueries.ll} {
//   padding: 26px 0;
// }

export const ErrorMessage = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: right;
  color: ${({ theme }) => theme.colors.red};
  position: absolute;
  top: 0;
  left: 0;
`;

// TODO commeted because it media query maybe be needs in the future
// ${({ theme }) => theme.mediaQueries.ll} {
//   font-size: 12px;
// }

export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

export const IconPassword = styled(IconWrapper)<{ isShow: boolean }>`
  cursor: pointer;
  svg path {
    stroke: ${({ theme, isShow }) => theme.colors[isShow ? "green" : "gray"]};
  }
`;
