import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { MapIcon, SearchIcon } from "src/UI/Svg";
import { useOutsideClick } from "src/hooks/useOutsideClick";
import {
  ClinicEntityResponse,
  ClinicSortFields,
  SortingOrders,
  useFindAndCountManyClinicsLazyQuery,
} from "src/graphql/generated";
import { useClinicsAction, useClinicsState } from "src/store/clinics/hooks";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { PATHS, allClinicsItemId } from "src/constants/constants";
import { useAuthContext } from "src/context/AuthContext";
import {
  ComponentWrapper,
  EmptyTextStyled,
  IconCover,
  InputStyled,
  InputWrapper,
  SelectedLocation,
  SelectedLocationText,
  SuggestionItem,
  SuggestionItemEmpty,
  SuggestionsCover,
} from "./styled";

function LocationComponent() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { currentUserData } = useAuthContext();
  const { onSetLocation, onSetClinicId } = useClinicsAction();
  const { currentClinicId: clinicId } = useClinicsState();
  const [selectedId, setSelectedId] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemsSearch, setItemsSearch] = useState<ClinicEntityResponse[]>([]);

  const allClinicsItem = {
    id: allClinicsItemId,
    name: t("app.location.all_clinics"),
  } as ClinicEntityResponse;

  const storageClinicId = localStorage.getItem("currentClinicId");

  const isAllClinicsOptionPage =
    pathname === `${PATHS.cp}${PATHS.patients}` ||
    pathname === `${PATHS.cp}${PATHS.booking}` ||
    pathname === `${PATHS.cp}${PATHS.dashboard}`;

  const [getFindManyClinicsLazy, { data }] =
    useFindAndCountManyClinicsLazyQuery({
      variables: {
        schema: {
          sortOrder: SortingOrders.Descending,
          sortField: ClinicSortFields.CreatedAt,
        },
      },
      fetchPolicy: "network-only",
      onCompleted: (response) => {
        const clinics = response?.findAndCountManyClinics?.clinics;

        if (!clinics?.length) {
          setSearchQuery("");
        }

        const clinicsWithAllClinics = isAllClinicsOptionPage
          ? [allClinicsItem, ...clinics]
          : clinics;

        setItemsSearch(clinicsWithAllClinics);

        const currentClinic = clinicsWithAllClinics?.find(
          (clinic: ClinicEntityResponse) => clinic.id === storageClinicId
        );

        if (!response?.findAndCountManyClinics?.clinics?.length) {
          localStorage.removeItem("currentClinicId");
        }

        if (currentClinic) {
          setSelectedId(currentClinic?.id);
          onSetLocation({
            center: [
              currentClinic?.geographicalCoordinates?.longitude,
              currentClinic?.geographicalCoordinates?.latitude,
            ],
          });
          onSetClinicId(currentClinic?.id);
          localStorage.setItem("currentClinicId", currentClinic?.id);
        } else if (clinics.length) {
          setSelectedId(clinics?.[0]?.id);
          onSetLocation({
            center: [
              clinics?.[0]?.geographicalCoordinates?.longitude,
              clinics?.[0]?.geographicalCoordinates?.latitude,
            ],
          });
          onSetClinicId(clinics?.[0]?.id);
          localStorage.setItem("currentClinicId", clinics?.[0]?.id);
        }
      },
    });

  useEffect(() => {
    getFindManyClinicsLazy();
  }, [isAllClinicsOptionPage, currentUserData]);

  const selectedData = useMemo<
    undefined | null | { name: string; address: string }
  >(() => {
    if (selectedId) {
      const currentClinic = itemsSearch?.find(
        (i: ClinicEntityResponse) => i.id === selectedId
      );
      if (currentClinic) setSearchQuery(currentClinic?.name);
      return currentClinic;
    }
    setSearchQuery("");
    return null;
  }, [selectedId, data, clinicId, itemsSearch, isAllClinicsOptionPage]);

  const suggestionsFiltered = useMemo(() => {
    if (searchQuery.trim().length > 1) {
      return itemsSearch.filter(
        (item) =>
          item?.name
            ?.toLowerCase()
            ?.includes(searchQuery.trim().toLowerCase()) ||
          item?.address
            ?.toLowerCase()
            ?.includes(searchQuery.trim().toLowerCase())
      );
    }

    return itemsSearch;
  }, [searchQuery, isFocused]);

  const coverEl = useRef<HTMLDivElement>(null);
  const inputEl = useCallback(
    (node: any) => {
      if (node && isFocused) node.focus();
    },
    [isFocused]
  );

  useOutsideClick({ ref: coverEl, handler: () => setIsFocused(false) });

  const handleSelectClinic = (item: ClinicEntityResponse) => () => {
    setSearchQuery(item.name);
    setSelectedId(item.id);
    setIsFocused(false);

    onSetLocation({
      center: [
        item?.geographicalCoordinates?.longitude,
        item?.geographicalCoordinates?.latitude,
      ],
    });
    onSetClinicId(item?.id);
    localStorage.setItem("currentClinicId", item?.id);
  };

  useEffect(() => {
    if (!clinicId) {
      setSearchQuery("");
      return;
    }
    setSelectedId(clinicId as string);
    localStorage.setItem("currentClinicId", clinicId as string);
  }, [clinicId]);

  const handleFocus = () => {
    setSearchQuery("");
  };

  return (
    <ComponentWrapper ref={coverEl}>
      <InputWrapper
        onClick={() => {
          setIsFocused(true);
        }}
      >
        {!isFocused && selectedData ? (
          <SelectedLocation>
            <SelectedLocationText>
              {selectedId === allClinicsItemId
                ? t("app.location.all_clinics")
                : selectedData.name}
            </SelectedLocationText>
            <SelectedLocationText>{selectedData.address}</SelectedLocationText>
          </SelectedLocation>
        ) : (
          <InputStyled
            type="text"
            ref={inputEl}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onFocus={handleFocus}
          />
        )}
        <IconCover>{isFocused ? <SearchIcon /> : <MapIcon />}</IconCover>
      </InputWrapper>
      {isFocused && (
        <SuggestionsCover>
          {suggestionsFiltered.length > 0 ? (
            suggestionsFiltered.map((item) => (
              <SuggestionItem key={item.id} onClick={handleSelectClinic(item)}>
                <SelectedLocationText>
                  {item.id === allClinicsItemId
                    ? t("app.location.all_clinics")
                    : item.name}
                </SelectedLocationText>
                <SelectedLocationText>{item.address}</SelectedLocationText>
              </SuggestionItem>
            ))
          ) : (
            <SuggestionItemEmpty>
              <EmptyTextStyled>{t("app.clinics_not_added")}</EmptyTextStyled>
            </SuggestionItemEmpty>
          )}
        </SuggestionsCover>
      )}
    </ComponentWrapper>
  );
}

export default LocationComponent;
