import React, { FunctionComponent } from "react";
import { Svg, SvgProps } from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
  <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
    <g clipPath="url(#clip0_59_12270)">
      <path
        d="M13.5 8.00036C13.5003 8.27651 13.7243 8.50016 14.0005 8.49991C14.2766 8.49966 14.5003 8.27559 14.5 7.99945L13.5 8.00036ZM4 3.53324L3.66666 3.16056L3.66552 3.16159L4 3.53324ZM2 5.33324L7.64761 4.13412L3.78534 -0.157288L2 5.33324ZM14.5 7.99945C14.4989 6.74518 14.1348 5.51803 13.4519 4.46601L12.6131 5.01052C13.191 5.90069 13.499 6.93906 13.5 8.00036L14.5 7.99945ZM13.4519 4.46601C12.7689 3.41399 11.7961 2.58204 10.6509 2.0705L10.2431 2.98356C11.2121 3.4164 12.0352 4.12035 12.6131 5.01052L13.4519 4.46601ZM10.6509 2.0705C9.50569 1.55897 8.23693 1.38971 6.99766 1.58312L7.15186 2.57116C8.20048 2.4075 9.27404 2.55073 10.2431 2.98356L10.6509 2.0705ZM6.99766 1.58312C5.75839 1.77654 4.60155 2.32438 3.66667 3.16056L4.33333 3.90592C5.12439 3.19838 6.10325 2.73482 7.15186 2.57116L6.99766 1.58312Z"
        fill="#A5A5A5"
      />
      <path
        d="M14 7.99991C13.9989 6.84212 13.6629 5.70936 13.0325 4.73827C12.4021 3.76717 11.5041 2.99922 10.447 2.52703C9.38987 2.05485 8.2187 1.8986 7.07476 2.07714C5.93082 2.25568 4.86297 2.76138 4 3.53324"
        stroke="#A5A5A5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 8C2.00105 9.15779 2.33707 10.2905 2.9675 11.2616C3.59794 12.2327 4.49588 13.0007 5.55301 13.4729C6.61013 13.9451 7.7813 14.1013 8.92524 13.9228C10.0692 13.7442 11.137 13.2385 12 12.4667"
        stroke="#A5A5A5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 7.99954C2.49975 7.7234 2.27569 7.49975 1.99954 7.5C1.7234 7.50025 1.49975 7.72431 1.5 8.00046L2.5 7.99954ZM12 12.4667L12.3333 12.8393L12.3345 12.8383L12 12.4667ZM14 10.6667L8.3524 11.8658L12.2147 16.1572L14 10.6667ZM1.5 8.00046C1.50114 9.25473 1.86515 10.4819 2.54813 11.5339L3.38688 10.9894C2.80898 10.0992 2.50097 9.06085 2.5 7.99954L1.5 8.00046ZM2.54813 11.5339C3.2311 12.5859 4.20387 13.4179 5.34909 13.9294L5.75692 13.0163C4.78789 12.5835 3.96478 11.8796 3.38688 10.9894L2.54813 11.5339ZM5.34909 13.9294C6.49431 14.4409 7.76307 14.6102 9.00234 14.4168L8.84814 13.4287C7.79952 13.5924 6.72596 13.4492 5.75692 13.0163L5.34909 13.9294ZM9.00234 14.4168C10.2416 14.2234 11.3985 13.6755 12.3333 12.8393L11.6667 12.094C10.8756 12.8015 9.89675 13.2651 8.84814 13.4287L9.00234 14.4168Z"
        fill="#A5A5A5"
      />
    </g>
    <defs>
      <clipPath id="clip0_59_12270">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </Svg>
);

export default Icon;
