import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 20px 0;
`;

export const Table = styled.table`
  width: 100%;

  thead {
  }

  th {
    padding-left: 5px;
    padding-right: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.grayC4};
    text-align: left;
  }
`;

export const Row = styled.tr`
  td {
    padding: 24px 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const LinkStyled = styled.div`
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.green};
`;
