import {
  ClinicEntityResponse,
  ClinicRoomEntityResponse,
  ClinicWorkingDayEntityResponse,
} from "src/graphql/generated";

export const transformBeforeEditClinic = (
  clinicResponse: ClinicEntityResponse
) => {
  if (clinicResponse) {
    const rooms = clinicResponse?.rooms?.map(
      ({ name, id }: ClinicRoomEntityResponse) => ({ name, id })
    );

    const workingDays = clinicResponse?.workingDays?.map(
      ({ day, id, fromMinute, toMinute }: ClinicWorkingDayEntityResponse) => ({
        day,
        id,
        fromMinute,
        toMinute,
      })
    );

    return {
      title: clinicResponse?.name,
      description: clinicResponse?.description || "",
      licenseNumber: clinicResponse?.licenseNumber || "",
      phone: clinicResponse?.phoneNumber.slice(1),
      clinicCode: clinicResponse?.code || "",
      location: {
        geometry: {
          coordinates: [
            clinicResponse?.geographicalCoordinates?.longitude as number,
            clinicResponse?.geographicalCoordinates?.latitude as number,
          ] as [number, number],
        },
        place_name: clinicResponse?.address,
        city: clinicResponse?.city,
        index: clinicResponse?.index,
        place_type: ["editPlace"],
      },
      photo: clinicResponse?.logoFileId || "",
      hiddenFromPatients: clinicResponse?.hiddenFromPatients,
      schedule: workingDays,
      cabinets: rooms,
    };
  }
  return {};
};
