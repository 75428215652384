import React, { forwardRef } from "react";
import {
  ClinicEntityResponse,
  FileEntityResponse,
} from "src/graphql/generated";
import { CrossIcon } from "src/UI/Svg";
import PhoneInput from "react-phone-input-2";
import { EButtonVariants } from "src/UI/Button";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { PATHS } from "src/constants/constants";
import { SelectedBookingClinic } from "src/store/booking/types";
import {
  Address,
  ButtonStyled,
  DescBlock,
  Footer,
  Header,
  HeadingStyled,
  IconWrapper,
  ImageStyled,
  PhoneBlock,
  Wrapper,
} from "./styled";

const BranchPatientComponent = forwardRef(
  (
    {
      isSelected,
      clinic,
      logoFiles,
      onSelect,
    }: {
      isSelected: boolean;
      clinic: ClinicEntityResponse;
      logoFiles: Array<FileEntityResponse>;
      onSelect: (val: SelectedBookingClinic) => void;
    },
    ref: React.Ref<HTMLDivElement>
  ) => {
    const { t } = useTranslation();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const clientId = queryParams.get("clientId");
    const langCode = queryParams.get("langCode");

    const clinicLogoFile = logoFiles?.find(
      (file) => file.id === clinic?.logoFileId
    );

    const handleSelect = () => {
      onSelect({ clinic, logoUrl: clinicLogoFile?.url || null });
    };

    return (
      <Wrapper ref={ref} isSelected={isSelected} onClick={handleSelect}>
        <IconWrapper>
          {clinicLogoFile ? (
            <ImageStyled src={clinicLogoFile?.url as string} alt="#" />
          ) : (
            <CrossIcon />
          )}
        </IconWrapper>

        <DescBlock>
          <Header>
            <HeadingStyled as="h3">
              {clinic.name}
              <ButtonStyled
                variant={EButtonVariants.Default}
                as={Link}
                to={`${PATHS.patientBooking}${PATHS?.services}?clientId=${clientId}&clinicId=${clinic?.id}&langCode=${langCode}`}
              >
                {t("app.pick")}
              </ButtonStyled>
            </HeadingStyled>
            <Address>{clinic.address}</Address>
          </Header>
          <Footer>
            <PhoneBlock>
              <PhoneInput value={clinic.phoneNumber} disabled />
            </PhoneBlock>
          </Footer>
        </DescBlock>
      </Wrapper>
    );
  }
);

export default BranchPatientComponent;
