import React, {
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { CloseIcon } from "../Svg";

import { DropdownStyled, LabelStyled, RowStyled, Menu } from "./styles";
import RowForMultipleSelect from "./RowForMultipleSelect";

const Search = styled(RowStyled)`
  flex-wrap: wrap;
  gap: 7px;
`;

const SelectedElement = styled.span`
  display: flex;
  align-items: center;
  gap: 3px;

  & > span > svg {
    width: 15px;
  }
`;

const InputSearch = styled.input`
  flex-grow: 1;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.black};
  border: none;
  background: transparent;
  outline: none;
`;

interface DropdownProps {
  value: Array<string | number> | string | number;
  onChange: (val: any) => void;
  options: Array<{ key: string; value: string | number }>;
  label: string;
  placeholder?: string;
  multiple?: boolean;
}

function DropdownAutocomplete({
  value,
  // multiple = false,
  placeholder = "",
  onChange,
  label,
  options,
  ...props
}: DropdownProps) {
  const dropdown = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (Array.isArray(value)) {
      if (!value.length) setSearchText("");
    } else if (typeof value === "string" && !value.length) setSearchText("");
  }, [value]);

  useEffect(() => {
    if (value) {
      setSearchText(options.find((o) => o.value === value)?.key || "-");
    }
  }, [value]);

  const deferredSearch = useDeferredValue(searchText);
  const viewOptions = useMemo(() => {
    if (deferredSearch.trim().length)
      return options.filter((o) =>
        o.key.toLowerCase().includes(deferredSearch.trim().toLowerCase())
      );
    return options;
  }, [deferredSearch, options]);

  const onSelectHandler = useCallback(
    (val: string | number) => {
      if (Array.isArray(value)) {
        if (value.includes(val)) onChange(value.filter((v) => v !== val));
        else {
          setSearchText("");
          onChange([...value, val]);
        }
      } else {
        setSearchText(options.find((o) => o.value === val)?.key || "-");
        onChange(val);
      }
    },
    [onChange]
  );

  return (
    <DropdownStyled ref={dropdown} {...props}>
      {label !== "" && <LabelStyled>{label}</LabelStyled>}
      <Search showMenu={showMenu}>
        {Array.isArray(value) &&
          value.map((v) => (
            <SelectedElement key={v} onClick={() => onSelectHandler(v)}>
              <span>{options.find((o) => o.value === v)?.key || "-"}</span>
              <span>
                <CloseIcon />
              </span>
            </SelectedElement>
          ))}
        <InputSearch
          value={searchText}
          placeholder={placeholder}
          onChange={(e) => setSearchText(e.target.value)}
          onFocus={() => setShowMenu(true)}
          onBlur={() => setTimeout(() => setShowMenu(false), 200)}
        />
      </Search>
      <Menu showMenu={showMenu}>
        {viewOptions.map((opt) => (
          <RowForMultipleSelect
            key={opt.value}
            isSelected={
              Array.isArray(value)
                ? value.includes(opt.value)
                : value === opt.value
            }
            onChange={() => onSelectHandler(opt.value)}
            name={opt.key}
          />
        ))}
      </Menu>
    </DropdownStyled>
  );
}

export default DropdownAutocomplete;
