import React, { FunctionComponent } from "react";
import { Svg, SvgProps } from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
  <Svg width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
    <path
      d="M25.4987 3.33325H15.4987C14.5782 3.33325 13.832 4.07944 13.832 4.99992V8.33325C13.832 9.25373 14.5782 9.99992 15.4987 9.99992H25.4987C26.4192 9.99992 27.1654 9.25373 27.1654 8.33325V4.99992C27.1654 4.07944 26.4192 3.33325 25.4987 3.33325Z"
      stroke="#C4C4C4"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.868 21.0166C18.193 20.6916 18.5788 20.4338 19.0035 20.2579C19.4282 20.082 19.8833 19.9915 20.343 19.9915C20.8026 19.9915 21.2578 20.082 21.6824 20.2579C22.1071 20.4338 22.4929 20.6916 22.818 21.0166C23.143 21.3417 23.4008 21.7275 23.5767 22.1522C23.7526 22.5768 23.8431 23.032 23.8431 23.4916C23.8431 23.9513 23.7526 24.4064 23.5767 24.8311C23.4008 25.2558 23.143 25.6416 22.818 25.9666L13.7513 35L7.16797 36.6666L8.81797 30.0833L17.868 21.0166Z"
      stroke="#C4C4C4"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.1654 6.66675H30.4987C31.3828 6.66675 32.2306 7.01794 32.8557 7.64306C33.4808 8.26818 33.832 9.11603 33.832 10.0001V33.3334C33.832 34.2175 33.4808 35.0653 32.8557 35.6904C32.2306 36.3156 31.3828 36.6667 30.4987 36.6667H21.332"
      stroke="#C4C4C4"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.16797 22.5001V10.0001C7.16797 9.11603 7.51916 8.26818 8.14428 7.64306C8.7694 7.01794 9.61725 6.66675 10.5013 6.66675H13.8346"
      stroke="#C4C4C4"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Icon;
