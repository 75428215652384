import { format } from "date-fns";
import { FC } from "react";
import { IEvent } from "src/pages/DashboardPages/CalendarPage/types";
import { useTranslation } from "react-i18next";
import { Event } from "react-big-calendar";
import { TScheduleAppointmentByRole } from "src/types";
import { useAppAction } from "src/store/app/hooks";
import { Item, List, MoreBlock, User } from "./styles";

interface ComponentProps {
  date: Date;
  maxLength: number;
  events: Event[];
  onShowMore: (val: Date) => void;
}

const DayEventsList: FC<ComponentProps> = ({
  date,
  maxLength,
  events,
  onShowMore,
}) => {
  const { t } = useTranslation();
  const { onShowScheduleAppointmentModal } = useAppAction();
  const isOverflow = events?.length > maxLength;
  const slicedEvents = events?.slice(0, maxLength);
  const showMoreCount = events?.length - slicedEvents?.length;

  const handleShowMore = () => {
    onShowMore(date);
  };

  const handleSelectEvent = (event: IEvent) => () => {
    onShowScheduleAppointmentModal({
      data: {
        appointment: event.eventData as TScheduleAppointmentByRole,
      },
    });
  };
  return (
    <List>
      {slicedEvents?.map((event) => {
        const eventObj = event as IEvent;
        return (
          <Item
            key={eventObj.id}
            onClick={handleSelectEvent(eventObj)}
            bgColor={eventObj?.color?.brightColor}
          >
            <span />
            {`${format(eventObj?.start as Date, "HH:mm")}-${format(
              eventObj?.end as Date,
              "HH:mm"
            )}`}
            <User>
              {eventObj?.eventData?.patient?.firstName}{" "}
              {eventObj?.eventData?.patient?.lastName}
            </User>
          </Item>
        );
      })}
      {isOverflow && (
        <MoreBlock onClick={handleShowMore}>{`${t(
          "reviews.more"
        )} ${showMoreCount}`}</MoreBlock>
      )}
    </List>
  );
};

export default DayEventsList;
