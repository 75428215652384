import styled from "styled-components";

export const AppointmentCard = styled.div`
  width: 38%;
  background: ${({ theme }) => theme.colors.white};

  padding: 96px 32px 32px 32px;
  z-index: 1;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 25px;
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.lightBiege};
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-left: 52px;
`;

export const InfoTitle = styled.p`
  color: ${({ theme }) => theme.colors.gray};
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
`;
export const InfoSubTitle = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  font-weight: 500;
  line-height: 22.4px;
`;
export const ClinicInfoTitle = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
`;
