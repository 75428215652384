import styled from "styled-components";

export const Wrapper = styled.div<{ isNotMyMessage: boolean }>`
  display: flex;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: ${({ isNotMyMessage }) =>
    isNotMyMessage ? "flex-start" : "flex-end"};
  width: 100%;
`;

export const Message = styled.div<{ isNotMyMessage: boolean }>`
  display: flex;
  max-width: 66%;
  align-items: flex-end;
  align-content: flex-end;
  flex-direction: ${({ isNotMyMessage }) =>
    isNotMyMessage ? "row-reverse" : "row"};
  gap: 20px;
  justify-content: flex-end;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  align-items: center;
  min-width: 40px;
  width: 40px;
  height: 40px;
  background: ${({ theme }) => theme.colors.lightBiege};
  border-radius: 56px;

  svg {
    width: 40px;
    height: 40px;
  }
`;

export const Content = styled.div<{ isNotMyMessage: boolean }>`
  display: flex;
  align-items: ${({ isNotMyMessage }) =>
    isNotMyMessage ? "flex-start" : "flex-end"};
  align-content: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
`;

export const DateBlock = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  text-align: right;
  color: ${({ theme }) => theme.colors.gray};
`;

export const Name = styled.h4`
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 22.4px;
`;
export const InfoBlock = styled.div<{ isNotMyMessage: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  gap: 10px;
  background: ${({ theme }) => theme.colors.lightBiege};
  border-radius: 15px 15px 0 15px;
  width: fit-content;
  ${Text} {
    color: ${({ theme }) => theme.colors.black};
  }
  ${Name} {
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const ImageStyled = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  border-radius: 50%;
`;
