import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FC, ReactNode } from "react";

interface PageProps {
  settings: Settings;
  slides: ReactNode[];
}

const SliderCarousel: FC<PageProps> = ({ settings, slides }) => {
  return <Slider {...settings}>{slides.map((slide) => slide)}</Slider>;
};

export default SliderCarousel;
