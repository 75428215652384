import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  forwardRef,
  useMemo,
  useRef,
  useState,
} from "react";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import {
  AggregatedDoctorEntityResponse,
  AppointmentSortFields,
  FileEntityResponse,
} from "src/graphql/generated";

import { EButtonVariants } from "src/UI/Button";
import { DownloadIcon } from "src/UI/Svg";
import { formatISO } from "date-fns";
import { useOutsideClick } from "src/hooks/useOutsideClick";
import { useClinicsState } from "src/store/clinics/hooks";
import { getUrlExportAppointmentsToExcel } from "src/utils";
import { IAppointmentsFilters } from "src/types";
import {
  ButtonStyled,
  DateBlockWrapper,
  DateInputStyled,
  DateVisitTitle,
  DateWarpper,
  DropdownFilter,
  DropdownStyled,
  FieldWrapStyled,
  FilterIconStyled,
  FilterWrapper,
  InputWrapper,
  Label,
  Wrapper,
} from "./styled";
import DoctorOptionComponent from "./DoctorOptionComponent";

interface ScheduleAppointmentsFilterComponentProps {
  logoFiles: Array<FileEntityResponse>;
  doctors: AggregatedDoctorEntityResponse[];
  filters: IAppointmentsFilters;
  setFilters: Dispatch<SetStateAction<IAppointmentsFilters>>;
  isDoctor: boolean;
  count: number;
}

const CustomInput = forwardRef(
  ({ value, onClick, placeholder }: any, ref: any) => (
    <DateInputStyled onClick={onClick} ref={ref}>
      {value || placeholder || ""}
    </DateInputStyled>
  )
);

function ScheduleAppointmentsFilterComponent({
  logoFiles,
  doctors,
  filters,
  setFilters,
  isDoctor,
  count,
}: ScheduleAppointmentsFilterComponentProps) {
  const [isShowMenu, setIsShowMenu] = useState(false);
  const dropdown = useRef<HTMLDivElement>(null);
  const { currentClinicId: clinicId } = useClinicsState();
  const { t } = useTranslation();
  const optsDate: Array<{ item: string; value: string }> = [
    {
      item: t("booking.patients.filters.date_created"),
      value: AppointmentSortFields.CreatedAt,
    },
    {
      item: t("booking.patients.filters.date_updated"),
      value: AppointmentSortFields.UpdatedAt,
    },
    {
      item: t("booking.patients.filters.date_ustart_visit"),
      value: AppointmentSortFields.ScheduleTimeFrom,
    },
  ];

  const optsDoctors: Array<{ item: ReactNode; value: string }> = useMemo(
    () =>
      doctors?.map((doctor: AggregatedDoctorEntityResponse) => ({
        item: <DoctorOptionComponent profile={doctor} logoFiles={logoFiles} />,
        value: doctor?.doctorId || "",
      })),
    [t, doctors, logoFiles]
  );

  const onChange = (key: string, val: string | boolean) => {
    setFilters((prevState: IAppointmentsFilters) => ({
      ...prevState,
      [key]: val,
    }));
  };

  const onShowMenu = () => setIsShowMenu(true);

  useOutsideClick({
    ref: dropdown,
    handler: () => setIsShowMenu(false),
  });

  const exportAppointmentsToExcel = () => {
    fetch(getUrlExportAppointmentsToExcel(filters, isDoctor, clinicId), {
      method: "GET",
      credentials: "include",
    })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const anchorElement = document.createElement("a");
        anchorElement.href = href;
        anchorElement.download = "GoClientsAppointments.xlsx";
        document.body.appendChild(anchorElement);
        anchorElement.click();
        document.body.removeChild(anchorElement);
        window.URL.revokeObjectURL(href);
      });
  };

  const resetFiltersHandler = () => {
    setFilters((prev) => {
      return { ...prev, ...{ dateFrom: "", dateTo: "", doctorId: "" } };
    });
  };

  return (
    <Wrapper>
      <DropdownFilter
        isShowOnlyPlaceholder={false}
        value={filters.sortField}
        placeholder={t("booking.patients.filters.date")}
        onSelect={(v) => onChange("sortField", v)}
        options={optsDate}
        label=""
      />
      <ButtonStyled
        variant={EButtonVariants.Gray}
        onClick={exportAppointmentsToExcel}
        disabled={!count}
      >
        <DownloadIcon />
        {t("booking.patients.filters.export")}
      </ButtonStyled>
      <FilterIconStyled onClick={onShowMenu} />
      {isShowMenu ? (
        <FilterWrapper ref={dropdown}>
          {!isDoctor ? (
            <DropdownStyled
              isShowOnlyPlaceholder={false}
              value={filters.doctorId as string}
              placeholder={t("booking.patients.table.provider")}
              onSelect={(v) => onChange("doctorId", v)}
              options={optsDoctors?.length ? optsDoctors : []}
              label=""
            />
          ) : null}

          <DateBlockWrapper>
            <DateVisitTitle>
              {t("booking.patients.filters.date_of_visit")}
            </DateVisitTitle>
            <DateWarpper>
              <FieldWrapStyled>
                <InputWrapper>
                  <Label>{t("constructor.work_time.labels.from")}</Label>
                  <DatePicker
                    selected={
                      filters.dateFrom ? new Date(filters.dateFrom) : null
                    }
                    onChange={(date) =>
                      onChange("dateFrom", formatISO(date as Date))
                    }
                    placeholderText={
                      t("constructor.work_time.labels.from") as string
                    }
                    maxDate={new Date(filters.dateTo ?? Date.now())}
                    customInput={<CustomInput />}
                    dateFormat="d  MMMM"
                  />
                </InputWrapper>
              </FieldWrapStyled>

              <FieldWrapStyled>
                <InputWrapper>
                  <Label>{t("constructor.work_time.labels.to")}</Label>
                  <DatePicker
                    selected={filters.dateTo ? new Date(filters.dateTo) : null}
                    onChange={(date) =>
                      onChange("dateTo", formatISO(date as Date))
                    }
                    placeholderText={
                      t("constructor.work_time.labels.to") as string
                    }
                    minDate={new Date(filters.dateFrom ?? Date.now())}
                    customInput={<CustomInput />}
                    dateFormat="d  MMMM"
                  />
                </InputWrapper>
              </FieldWrapStyled>
            </DateWarpper>
          </DateBlockWrapper>
          <ButtonStyled
            variant={EButtonVariants.Gray}
            onClick={resetFiltersHandler}
            isMenuButton
          >
            {t("booking.patients.filters.reset_filters")}
          </ButtonStyled>
        </FilterWrapper>
      ) : null}
    </Wrapper>
  );
}

export default ScheduleAppointmentsFilterComponent;
