import { Button } from "src/UI/Button";
import { Tabs } from "src/UI/Tabs";
import { CardComponent } from "src/components/UserComponents";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  background: ${({ theme }) => theme.colors.black};
`;

export const CardStyled = styled(CardComponent)<{
  isBorderBootomNone?: boolean;
}>`
  z-index: 1;
  border-bottom: ${(isBorderBootomNone) =>
    !isBorderBootomNone ? "inherit" : "none"};
`;

export const CardPatientStyled = styled(CardComponent)<{
  isBorderBootomNone?: boolean;
}>`
  z-index: 0;
  border-bottom: ${(isBorderBootomNone) =>
    !isBorderBootomNone ? "inherit" : "none"};
`;

export const Content = styled.div`
  display: flex;
  padding: 24px 20px 20px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  width: 988px;
`;
export const PatientContent = styled.div`
  display: flex;
  padding: 24px 20px 20px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 36px;
  align-self: stretch;
  width: 988px;
`;

export const DoctorWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 15px;
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
`;

export const DoctorWrapperRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const InfoDescriptionWrapper = styled.div`
  display: flex;
  gap: 36px;
`;

export const DoctorOptInfoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const OptItemTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  text-overflow: ellipsis;
`;

export const OptItemSubTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: ${({ theme }) => theme.colors.gray};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DotSeparator = styled.div`
  width: fit-content;
  margin: 0 10px;
  display: flex;
  align-items: center;

  &:before {
    content: "";
    width: 5px;
    height: 5px;
    background-color: ${({ theme }) => theme.colors.gray};
    border-radius: 50%;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  align-items: center;
  min-width: 56px;
  width: 56px;
  height: 56px;
  background: ${({ theme }) => theme.colors.lightBiege};
  border-radius: 56px;

  svg {
    width: 56px;
    height: 56px;
  }
`;

export const ImageStyled = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  border-radius: 50%;
`;

export const DoctorOptWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Price = styled.p`
  white-space: nowrap;
  font-weight: 500;
  font-size: 16px;
  line-height: 22.4px;
  color: ${({ theme }) => theme.colors.black};
`;
export const ServiceName = styled.p`
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.black};
`;
export const ServiceDescription = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  color: ${({ theme }) => theme.colors.black};
`;

export const PriceLabel = styled.p`
  white-space: nowrap;
  font-weight: 500;
  font-size: 16px;
  line-height: 22.4px;
  color: ${({ theme }) => theme.colors.gray};
`;
export const CardHeader = styled.p`
  white-space: nowrap;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray};
`;

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export const ButtonStyled = styled(Button)`
  padding: 15px 20px;
  font-size: 20px;
  line-height: 100%;
  width: fit-content;
`;

export const TabsStyled = styled(Tabs)`
  gap: 5px;
`;

export const UpdatesListWrapper = styled.div``;
export const UpdatesItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 20px;
`;
export const UpdatesItemTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
`;
export const UpdatesItemText = styled.div`
  color: ${({ theme }) => theme.colors.gray};
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
`;

export const EmptyTextStyled = styled.div`
  width: 100%;
  padding: 20px;
  color: ${({ theme }) => theme.colors.gray};
`;

export const FormColumn = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  align-content: flex-start;
  flex-direction: column;
  width: 100%;
  flex: 1;
  min-width: 230px;
  max-width: 230px;
`;

export const Label = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.gray};
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 12px;
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  gap: 30px;
  width: fit-content;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 7px;
  height: 100%;
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 6px;
  }
`;

export const DateInputStyled = styled.div`
  padding: 8px 10px;
  gap: 4px;
  min-height: 32px;
  background: ${({ theme }) => theme.colors.lightBiege};
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
