import { toast } from "react-toastify";
import {
  transformDefaultMailTemplateToFormMailTemplate,
  transformDefaultSmsTemplateToFormMailTemplate,
} from "src/connectors/Templates/transformTemplates";
import { DEFAULT_TEMPLATE_ACTIONS } from "src/constants/constants";
import {
  DistributionSystemEvents,
  LanguageIsoCodes,
  useFindDefaultMailTemplateLazyQuery,
  useFindDefaultSmsTemplateLazyQuery,
} from "src/graphql/generated";
import { ITemplateCreateEdit } from "src/types/templates";

export const useTemplateDefault = () => {
  const [fetchDefaultEnMail] = useFindDefaultMailTemplateLazyQuery({
    fetchPolicy: "network-only",
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });

  const [fetchDefaultPlMail] = useFindDefaultMailTemplateLazyQuery({
    fetchPolicy: "network-only",
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });

  const [fetchDefaultEnSms] = useFindDefaultSmsTemplateLazyQuery({
    fetchPolicy: "network-only",
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });

  const [fetchDefaultPlSms] = useFindDefaultSmsTemplateLazyQuery({
    fetchPolicy: "network-only",
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });

  const getDefaultMail = async (selectedEvent: DistributionSystemEvents) => {
    const defaultEnMail = await fetchDefaultEnMail({
      variables: {
        schema: {
          event: selectedEvent,
          languageISOCode: LanguageIsoCodes.En,
        },
      },
    });
    const defaultPlMail = await fetchDefaultPlMail({
      variables: {
        schema: {
          event: selectedEvent,
          languageISOCode: LanguageIsoCodes.Pl,
        },
      },
    });

    if (
      !defaultEnMail.data ||
      !defaultPlMail.data ||
      defaultEnMail.error ||
      defaultPlMail?.error
    )
      return;

    return transformDefaultMailTemplateToFormMailTemplate(
      [
        defaultEnMail.data?.findDefaultMailTemplate,
        defaultPlMail.data?.findDefaultMailTemplate,
      ],
      [LanguageIsoCodes.En, LanguageIsoCodes.Pl]
    );
  };

  const getDefaultSms = async (selectedEvent: DistributionSystemEvents) => {
    const defaultEnSms = await fetchDefaultEnSms({
      variables: {
        schema: {
          event: selectedEvent,
          languageISOCode: LanguageIsoCodes.En,
        },
      },
    });
    const defaultPlSms = await fetchDefaultPlSms({
      variables: {
        schema: {
          event: selectedEvent,
          languageISOCode: LanguageIsoCodes.Pl,
        },
      },
    });

    if (
      !defaultEnSms.data ||
      !defaultPlSms.data ||
      defaultEnSms.error ||
      defaultPlSms?.error
    )
      return;

    return transformDefaultSmsTemplateToFormMailTemplate(
      [
        defaultEnSms.data.findDefaultSmsTemplate,
        defaultPlSms.data.findDefaultSmsTemplate,
      ],
      [LanguageIsoCodes.En, LanguageIsoCodes.Pl]
    );
  };

  const getDefaultValues = async (
    event: DistributionSystemEvents
  ): Promise<ITemplateCreateEdit> => {
    const defaultMail = await getDefaultMail(event);
    const defaultSms = await getDefaultSms(event);

    return {
      title: "",
      mailTemplates: defaultMail,
      smsTemplates: defaultSms,
      templateActions: DEFAULT_TEMPLATE_ACTIONS,
    };
  };

  return {
    getDefaultMail,
    getDefaultSms,
    getDefaultValues,
  };
};
