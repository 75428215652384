import { IWaitlistItem } from "../../types";

export const waitlistAll: IWaitlistItem[] = [
  {
    num: "1",
    provider: "Kewin Nowak",
    app_time: "11 wrz 2022 o 3:30",
    recipient: "11 wrz 2022",
    openrate: "Joachim Sikora",
  },
  {
    num: "2",
    provider: "Grzegorz Stępień",
    app_time: "11 wrz 2022 o 3:30",
    recipient: "",
    openrate: "Ryszard Wysocki",
  },
  {
    num: "3",
    provider: "Anatol Sikora",
    app_time: "11 wrz 2022 o 3:30",
    recipient: "11 wrz 2022",
    openrate: "Jędrzej Mazurek",
  },
  {
    num: "4",
    provider: "Ariel Tomaszewski",
    app_time: "",
    recipient: "11 wrz 2022",
    openrate: "Albert Brzeziński",
  },
  {
    num: "5",
    provider: "Korneliusz Krajewska",
    app_time: "11 wrz 2022 o 3:30",
    recipient: "11 wrz 2022",
    openrate: "Patryk Czerwiński",
  },
];
export const waitlistAsap: IWaitlistItem[] = [
  {
    num: "2",
    provider: "Czesław Szymczak",
    app_time: "11 wrz 2022 o 3:30",
    recipient: "",
    openrate: "Korneliusz Przybylski",
  },
  {
    num: "3",
    provider: "Kuba Sawicki",
    app_time: "11 wrz 2022 o 3:30",
    recipient: "11 wrz 2022",
    openrate: "Marcin Dąbrowski",
  },
  {
    num: "4",
    provider: "Błażej Wójcik",
    app_time: "",
    recipient: "11 wrz 2022",
    openrate: "Aleks Wojciechowski",
  },
  {
    num: "5",
    provider: "Ignacy Mróz",
    app_time: "11 wrz 2022 o 3:30M",
    recipient: "11 wrz 2022",
    openrate: "Ireneusz Kaźmierczak",
  },
];
export const waitlistContinuing: IWaitlistItem[] = [
  {
    num: "1",
    provider: "Alexander Brzeziński",
    app_time: "11 wrz 2022 o 3:30",
    recipient: "11 wrz 2022",
    openrate: "Czesław Sikorska",
  },
  {
    num: "3",
    provider: "Maurycy Lis",
    app_time: "11 wrz 2022 o 3:30",
    recipient: "11 wrz 2022",
    openrate: "Miłosz Maciejewski",
  },
  {
    num: "4",
    provider: "Jacek Stępień",
    app_time: "",
    recipient: "11 wrz 2022",
    openrate: "Maciej Pawlak",
  },
  {
    num: "5",
    provider: "Alfred Wojciechowski",
    app_time: "11 wrz 2022 o 3:30",
    recipient: "11 wrz 2022",
    openrate: "Dawid Witkowski",
  },
];
export const waitlistDrafts: IWaitlistItem[] = [
  {
    num: "1",
    provider: "Denis Sikorska",
    app_time: "11 wrz 2022 o 3:30",
    recipient: "11 wrz 2022",
    openrate: "Lucjan Kucharski",
  },
  {
    num: "2",
    provider: "Kordian Wójcik",
    app_time: "11 wrz 2022 o 3:30",
    recipient: "",
    openrate: "Łukasz Kaźmierczak",
  },
  {
    num: "5",
    provider: "Czesław Kalinowski",
    app_time: "11 wrz 2022 o 3:30",
    recipient: "11 wrz 2022",
    openrate: "Korneliusz Zawadzki",
  },
];
