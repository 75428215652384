import styled from "styled-components";
import { Button } from "src/UI/Button";

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  flex-direction: column;
`;

export const Content = styled.div`
  display: grid;
  width: 100%;
  padding: 20px;
  gap: 10px;
  grid-template-columns: 100%;
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 16px;
  }
`;

export const ToggleBlock = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 16px;
`;

export const UserInfo = styled.div`
  display: grid;
  grid-template-columns: 180px auto;
  align-items: center;
  width: 100%;
  gap: 30px;
`;

export const Inputs = styled.div`
  width: 100%;
  gap: 20px;
  display: grid;
  grid-template-columns: 100%;
`;

export const LabelBlock = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.black};

  svg {
    width: 16px;
    height: 16px;

    path {
      stroke: ${({ theme }) => theme.colors.gray};
    }
  }

  span {
    color: ${({ theme }) => theme.colors.gray};
  }
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 12px;
  }
`;

export const InputsRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

export const UserPhotoHover = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 8px 16px;
  gap: 8px;
  background: rgba(61, 61, 61, 0.6);
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.03);
  visibility: hidden;
  opacity: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.white};
  flex-direction: column;
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 12px;
    padding: 7px 12px;
  }

  input[type="file"] {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
`;

export const UserPhoto = styled.div`
  min-width: 180px;
  height: 180px;
  cursor: pointer;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  background: #f8f8f8;
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  position: relative;

  & > svg {
    width: 80px;
    height: 80px;

    path {
      stroke: ${({ theme }) => theme.colors.green};
    }
  }

  &:hover {
    ${UserPhotoHover} {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const ButtonStyled = styled(Button)`
  width: auto;
  padding: 8px 10px;
  font-size: 16px;
  line-height: 100%;
  border-radius: 5px;

  svg path {
    stroke: ${({ theme }) => theme.colors.white};
  }
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 12px;
    padding: 7px 8px;
  }
`;

export const Services = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0;
  width: 100%;
  margin-top: -10px;
`;

export const Service = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
`;

export const ServiceHidden = styled(Service)`
  border-bottom: none;
  padding: 20px 0px 10px;
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 16px 0 8px;
  }
`;

export const TimetableWrapper = styled.div`
  display: grid;
  grid-template-columns: 100%;
  gap: 10px;
`;

export const ServiceLabel = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.black};
  svg {
    width: 16px;
    height: 16px;
    path {
      stroke: ${({ theme }) => theme.colors.gray};
    }
  }
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 12px;
  }
`;
