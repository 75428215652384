import { gql } from "@apollo/client";

export const UPDATE_SCHEDULE_BY_CLIENT = gql`
  mutation UpdateScheduleByIdByClient(
    $schema: UpdateScheduleByIdByClientSchema!
  ) {
    updateScheduleByIdByClient(schema: $schema) {
      updated
    }
  }
`;
