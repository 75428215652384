import Layout from "src/components/PatientBookingComponents/Layout/Layout";
import { CheckIcon, LinkSecondIcon } from "src/UI/Svg";
import { useTranslation } from "react-i18next";
import { EButtonVariants } from "src/UI/Button";
import {
  ClinicEntityResponse,
  LanguageIsoCodes,
  useFindManyFilesByIdsLazyQuery,
  useFindScheduleAppointmentWithRelationsByHashByPatientQuery,
  useRateScheduleAppointmentByPatientMutation,
} from "src/graphql/generated";
import { PATHS } from "src/constants/constants";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ScreenLoader } from "src/UI/ScreenLoader";
import { Loading } from "src/UI/Loading";
import { toast } from "react-toastify";
import {
  ButtonStyled,
  Buttons,
  Icon,
  Info,
  InfoWrapper,
  Title,
  Wrapper,
} from "./styled";

function RatePage() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const hash = query.get("hash") as string;
  const rate = Number(query.get("rate"));
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const queryParams = new URLSearchParams(location.search);

  const langCode = queryParams.get("langCode");

  useEffect(() => {
    i18n.changeLanguage(langCode as string);
  }, []);

  const [getLogo] = useFindManyFilesByIdsLazyQuery();

  const { data, loading } =
    useFindScheduleAppointmentWithRelationsByHashByPatientQuery({
      variables: { schema: { hash } },
    });

  const [rateMutation, { loading: rateLoading }] =
    useRateScheduleAppointmentByPatientMutation();

  const clinicLayout = {
    clinic: data?.findScheduleAppointmentWithRelationsByHashByPatient
      .clinic as ClinicEntityResponse,
    logoUrl,
  };

  useEffect(() => {
    if (data) {
      rateMutation({
        variables: { schema: { rate, hash } },
        onCompleted: () => {
          toast.success(t("app.successful"));
        },
        onError: (error) => {
          if (error) toast.error(error.message);
        },
      });
      getLogo({
        variables: {
          schema: {
            ids: [
              data?.findScheduleAppointmentWithRelationsByHashByPatient.clinic
                .logoFileId as string,
            ],
          },
        },
        onCompleted(response) {
          setLogoUrl(response?.findManyFilesByIds?.files?.[0]?.url);
        },
      });
    }
  }, [data]);

  const handleNavigeteClinics = () => {
    const searchParams = new URLSearchParams({
      clientId: data?.findScheduleAppointmentWithRelationsByHashByPatient.client
        .id as string,
      clinicId: data?.findScheduleAppointmentWithRelationsByHashByPatient.clinic
        ?.id as string,
      langCode: data?.findScheduleAppointmentWithRelationsByHashByPatient
        .languageISOCode as LanguageIsoCodes,
    });

    navigate({
      pathname: `${PATHS.patientBooking}${PATHS.clinics}`,
      search: searchParams.toString(),
    });
  };

  if (loading) {
    return <ScreenLoader />;
  }

  return (
    <Layout currentClinic={clinicLayout} isLangComponent={false}>
      <Wrapper>
        <Icon>
          {rateLoading ? (
            <Loading padding="0" />
          ) : (
            <CheckIcon width="32" height="32" />
          )}
        </Icon>
        <Title>{t("app.booking.patient.rate.thank_message")}</Title>

        <InfoWrapper>
          <Info>{t("app.booking.patient.rate.description_message")}</Info>
          <Info>{t("app.booking.patient.rate.end_message")}</Info>
        </InfoWrapper>

        <Buttons>
          <ButtonStyled
            variant={EButtonVariants.Green}
            onClick={handleNavigeteClinics}
          >
            <LinkSecondIcon />
            {t("final.buttons.link")}
          </ButtonStyled>
        </Buttons>
      </Wrapper>
    </Layout>
  );
}

export default RatePage;
