import { ErrorMessage } from "src/UI/DropdownInput/styles";
import { InputAutosize } from "src/UI/Input";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  align-items: stretch;
  align-content: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export const SubjectWrapper = styled.div`
  position: relative;
`;

interface IEditorWrapperProps {
  $isError: boolean;
}

export const EditorWrapper = styled.div<IEditorWrapperProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  border: 1px solid
    ${({ theme, $isError }) =>
      $isError ? theme.colors.red : theme.colors.borderInputDefault};
`;

export const Commands = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  width: 254px;
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 16px;
    padding: 16px;
  }
`;

export const StyledInputAutosize = styled(InputAutosize)`
  height: 100%;
  width: 100;
  padding: 18px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
  align-items: start;
  border: none;

  & textarea {
    padding: 0;
    border-bottom: none;
  }
`;

export const StyledErrorMessage = styled(ErrorMessage)`
  justify-content: end;
  right: 18px;
  line-height: 100%;
`;
