import {
  FormFieldAnswerResponse,
  FormFieldTranslationEntityResponse,
} from "src/graphql/generated";
import {
  IFilledFormCheckboxFilledValue,
  IFilledFormCheckboxUI,
  IFilledFormCheckboxValue,
} from "src/types";

export const transformCheckboxFieldResult = (
  answer: FormFieldAnswerResponse,
  fieldTranslation?: FormFieldTranslationEntityResponse
) => {
  const checkBoxFields =
    fieldTranslation?.value &&
    (JSON.parse(fieldTranslation?.value) as IFilledFormCheckboxFilledValue[]);
  const checkBoxValues =
    answer.value && (JSON.parse(answer.value) as IFilledFormCheckboxValue[]);

  let renderFields;

  if (checkBoxFields && checkBoxValues) {
    renderFields = checkBoxFields.map((field) => {
      const fieldValue = checkBoxValues.find(
        (item) => item.order === field.order
      );

      const result: IFilledFormCheckboxUI = {
        variantTitle: field.text,
      };

      if (fieldValue && typeof fieldValue.checked === "boolean") {
        result.checked = fieldValue.checked;
      }

      if (fieldValue && fieldValue.text) {
        result.text = fieldValue.text;
      }

      return result;
    });
  }

  return renderFields;
};
