import React, { Dispatch, ReactNode, SetStateAction } from "react";

import { useTranslation } from "react-i18next";
import {
  AppointmentStatuses,
  ScheduleAppointmentsCountByStatusesResponse,
} from "src/graphql/generated";
import { IAppointmentsFilters } from "src/types";
import { EButtonVariants } from "../Button";
import { PlusIcon } from "../Svg";
import { Block, ButtonStyled, Tab, Wrapper, WrapperTabs } from "./styled";

function FiltersTabs({
  headers,
  contents,
  onShowModal,
  titleButton,
  setFilters,
  filters,
  countStatusesByRole,
  ...props
}: {
  headers: {
    key: string;
    value: string;
  }[];
  contents: ReactNode;
  setFilters: Dispatch<SetStateAction<IAppointmentsFilters>>;
  onShowModal?: () => void;
  titleButton?: string;
  filters: IAppointmentsFilters;
  countStatusesByRole: ScheduleAppointmentsCountByStatusesResponse & {
    [key: string]: number;
  };
}) {
  const { t } = useTranslation();

  const getCount = (key: string) => {
    return countStatusesByRole?.[key?.charAt(0)?.toLowerCase() + key?.slice(1)];
  };

  return (
    <Block {...props}>
      <Wrapper>
        <WrapperTabs>
          {headers.map((item) => {
            return (
              <Tab
                key={`Tab-${item.key}`}
                isActive={filters.status === item.key}
                onClick={() =>
                  setFilters((prevState: IAppointmentsFilters) => ({
                    ...prevState,
                    status: item?.key as AppointmentStatuses,
                  }))
                }
              >
                {item?.value} <span>{getCount(item.key)}</span>
              </Tab>
            );
          })}
        </WrapperTabs>

        {titleButton ? (
          <ButtonStyled onClick={onShowModal} variant={EButtonVariants.Text}>
            <PlusIcon /> {t(titleButton)}
          </ButtonStyled>
        ) : null}
      </Wrapper>

      {contents}
    </Block>
  );
}

export default FiltersTabs;
