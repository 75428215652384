import { useTranslation } from "react-i18next";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ServiceCategoryEntityResponse } from "src/graphql/generated";

import { FieldError, useFormContext } from "react-hook-form";

import { useOutsideClick } from "src/hooks/useOutsideClick";
import { EditIcon, PlusIcon } from "src/UI/Svg";
import {
  useCreateServiceCategory,
  // useDeleteServiceCategoryById,
  useUpdateServiceCategoryById,
} from "src/graphql/hooks/useMutations";
import { toast } from "react-toastify";
import { FIND_MANY_SERVICE_CATEGORIES_BY_CLINIC_ID } from "src/graphql/queries";
import { Loading } from "src/UI/Loading";

import { EButtonVariants } from "src/UI/Button";
import { IServiceCategory } from "src/store/app/types";
import { useAppAction } from "src/store/app/hooks";
import {
  Actions,
  ButtonIcon,
  CategoryCover,
  CategoryItem,
  CategoryItemText,
  ComponentWrapper,
  EmptyTextStyled,
  ErrorMessage,
  FooterButton,
  InputStyled,
  InputWrapper,
  Label,
  SearchQueryWrapper,
  SelectedCategory,
  SelectedCategoryText,
} from "./styled";

interface AddCategoryViewProps {
  name: string;
  category: string;
  errorMessage: FieldError | undefined;
  serviceCategories: ServiceCategoryEntityResponse[];
  setServiceCategories?: any;
}

function AddCategoryView({
  name,
  category,
  errorMessage,
  serviceCategories,
  setServiceCategories,
}: AddCategoryViewProps) {
  const { t } = useTranslation();
  const { onShowEditServiceCategoryModal } = useAppAction();

  const { setValue, trigger, clearErrors } = useFormContext();

  const clinicId = localStorage.getItem("currentClinicId") as string;

  const [selectedId, setSelectedId] = useState(category || "");
  const [isFocused, setIsFocused] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedData, setSelectedData] =
    useState<ServiceCategoryEntityResponse | null>(null);

  useEffect(() => {
    if (category) {
      setSelectedId(category);
      const currentCategory = serviceCategories?.find(
        (i: ServiceCategoryEntityResponse) => i.id === category
      );

      if (currentCategory) {
        setSearchQuery(currentCategory?.name);
        setSelectedData(currentCategory as ServiceCategoryEntityResponse);
      }
    }
  }, [category, serviceCategories]);

  const categoriesFiltered = useMemo(() => {
    if (searchQuery.trim().length) {
      return serviceCategories.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.trim().toLowerCase())
      );
    }

    return serviceCategories;
  }, [searchQuery, isFocused]);

  const coverEl = useRef<HTMLDivElement>(null);
  const inputEl = useCallback(
    (node: any) => {
      if (node && isFocused) node.focus();
    },
    [isFocused]
  );

  useOutsideClick({
    ref: coverEl,
    handler: () => {
      setIsFocused(false);
      clearErrors(["categoryName"]);
    },
  });

  const handleSelectCategory = (item: ServiceCategoryEntityResponse) => () => {
    setSearchQuery(item.name);
    setSelectedId(item.id);
    setValue(name, item.id);
    setIsFocused(false);
    trigger(name);
  };

  const [
    createServiceCategoryMutation,
    { loading: createServiceCategoryLoading },
  ] = useCreateServiceCategory({
    onCompleted: (response) => {
      if (response) {
        setServiceCategories((prev: ServiceCategoryEntityResponse[]) => [
          ...prev,
          response.createServiceCategory,
        ]);
        setSearchQuery(response.createServiceCategory.name);
        setSelectedId(response.createServiceCategory.id);
        setSelectedData(response.createServiceCategory);
        setValue(name, response.createServiceCategory.id);
        setIsFocused(false);
        toast.success(t("app.successful"));
      }
    },
    refetchQueries: [
      {
        query: FIND_MANY_SERVICE_CATEGORIES_BY_CLINIC_ID,
        variables: {
          schema: {
            clinicId,
          },
        },
      },
    ],
  });

  const [updateServiceCategory] = useUpdateServiceCategoryById();

  // TODO temporary comment
  // const [deleteServiceCategory] = useDeleteServiceCategoryById();

  const createServiceCategoryHandler = () => {
    createServiceCategoryMutation({
      variables: { schema: { clinicId, name: searchQuery } },
    });
  };

  const handleEditServiceCategory = (categoryRes?: IServiceCategory) => () => {
    onShowEditServiceCategoryModal({
      category: categoryRes || null,
      accept: (data: IServiceCategory) => {
        updateServiceCategory({
          variables: {
            schema: {
              fields: {
                name: data.name,
              },
              id: data?.id,
            },
          },
          refetchQueries: [
            {
              query: FIND_MANY_SERVICE_CATEGORIES_BY_CLINIC_ID,
              variables: {
                schema: {
                  id: clinicId,
                },
              },
            },
          ],
          onCompleted: () => {
            setServiceCategories((prev: ServiceCategoryEntityResponse[]) => {
              const updatedServiceCategories = prev?.map((item) => {
                if (item?.id === data?.id) {
                  return { ...item, name: data.name };
                }
                return item;
              });

              return updatedServiceCategories;
            });
            toast.success(t("app.successful"));
          },
        });
      },
    });
  };

  // TODO temporary comment
  // const handleRemove = (id: string) => () => {
  //   onConfirmOpen({
  //     accept: () => {
  //       deleteServiceCategory({
  //         variables: {
  //           schema: {
  //             id,
  //           },
  //         },

  //         refetchQueries: [
  //           {
  //             query: FIND_MANY_SERVICE_CATEGORIES_BY_CLINIC_ID,
  //             variables: {
  //               schema: {
  //                 clinicId,
  //               },
  //             },
  //           },
  //         ],
  //         awaitRefetchQueries: true,
  //         onCompleted: () => {
  //           setServiceCategories(
  //             serviceCategories.filter((item) => item.id !== id)
  //           );
  //           setValue(name, selectedId);
  //           toast.success(t("app.successful"));
  //         },
  //       });
  //     },
  //   });
  // };

  const isIncludeServiceCategory = serviceCategories.find(
    (item) => item.name === searchQuery
  );

  return (
    <ComponentWrapper ref={coverEl}>
      <InputWrapper
        onClick={() => {
          setIsFocused(true);
        }}
      >
        <Label
          isFocused={isFocused}
          isSearchQuery={!!searchQuery as boolean}
          isSelected={!!selectedData?.name as boolean}
          htmlFor={name}
        >
          {t("booking.add_service.label.category")}
        </Label>
        {!isFocused && selectedData ? (
          <SelectedCategory>
            <SelectedCategoryText>{selectedData.name}</SelectedCategoryText>
          </SelectedCategory>
        ) : (
          <InputStyled
            autoComplete="off"
            isFocused={isFocused}
            id={name}
            name={category}
            type="text"
            ref={inputEl}
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setValue("categoryName", e.target.value);
              trigger("categoryName");
            }}
          />
        )}
      </InputWrapper>
      {isFocused && (
        <CategoryCover isFocused={isFocused}>
          {!isIncludeServiceCategory ? (
            !createServiceCategoryLoading ? (
              <EmptyTextStyled>
                <FooterButton
                  type="button"
                  disabled={!searchQuery}
                  variant={EButtonVariants.Default}
                  onClick={createServiceCategoryHandler}
                >
                  <PlusIcon />
                </FooterButton>
                <SearchQueryWrapper>{searchQuery}</SearchQueryWrapper>
                {t("booking.add_service.label.category_not_found")}
              </EmptyTextStyled>
            ) : (
              <Loading type="secondary" padding="0" />
            )
          ) : null}
          {categoriesFiltered.length
            ? categoriesFiltered.map((item) => {
                const isSelected = selectedId === item.id;
                return (
                  <CategoryItem
                    key={item.id}
                    isActive={false}
                    isSelected={isSelected}
                    onClick={handleSelectCategory(item)}
                  >
                    <CategoryItemText>
                      <SelectedCategoryText>{item.name}</SelectedCategoryText>
                      <Actions>
                        <ButtonIcon
                          onClick={handleEditServiceCategory(item)}
                          isSelected={isSelected}
                        >
                          <EditIcon />
                        </ButtonIcon>
                        {/* TODO temporary comment
                        {!isSelected ? (
                          <ButtonIcon
                            onClick={handleRemove(item.id)}
                            isSelected={isSelected}
                          >
                            <DeleteIcon />
                          </ButtonIcon>
                        ) : null} */}
                      </Actions>
                    </CategoryItemText>
                  </CategoryItem>
                );
              })
            : null}
        </CategoryCover>
      )}
      {errorMessage && (
        <ErrorMessage>
          {errorMessage.message && t(errorMessage.message)}
        </ErrorMessage>
      )}
    </ComponentWrapper>
  );
}

export default AddCategoryView;
