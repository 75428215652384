import { useCallback, useEffect, useState } from "react";
import { Modal } from "src/UI/Modal";
import { useAppAction, useAppState } from "src/store/app/hooks";
import { EModals } from "src/store/app/types";
import { useBookingAction } from "src/store/booking/hooks";
import { TScheduleWaitingAppointment } from "src/types";
import { useFindManyFilesByIdsLazy } from "src/graphql/hooks/useQueries";
import { parse } from "date-fns";
import {
  ClinicEntityResponse,
  FileEntityResponse,
  WaitlistStatuses,
  useFindClinicByIdLazyQuery,
} from "src/graphql/generated";
import { Wrapper } from "./styled";
import { IData } from "./types";
import DoctorInfoCardComponent from "./DoctorInfoCardComponent/DoctorInfoCardComponent";
import PatientInfoCardComponent from "./PatientInfoCardComponent/PatientInfoCardComponent";

function ScheduleWaitingAppointmentModal() {
  const { onCloseModal } = useAppAction();
  const { onSetRescheduleWaitlist } = useBookingAction();

  const { modals } = useAppState();
  const [appointment, setAppointment] =
    useState<TScheduleWaitingAppointment | null>(null);
  const [clinic, setClinic] = useState<ClinicEntityResponse | null>(null);
  const [files, setFiles] = useState<FileEntityResponse[]>([]);
  const [data, setData] = useState<IData>({
    minuteFrom: null,
    minuteTo: null,
    dateFrom: null,
    dateTo: null,
  });

  const isClosedStatus = appointment?.status === WaitlistStatuses.Closed;

  const [getFile] = useFindManyFilesByIdsLazy();
  const [getClinicById] = useFindClinicByIdLazyQuery();

  useEffect(() => {
    if (modals[EModals.ScheduleWaitingAppointment]?.data?.appointment) {
      setAppointment(
        modals[EModals.ScheduleWaitingAppointment]?.data?.appointment
      );
    }
  }, [modals[EModals.ScheduleWaitingAppointment]?.data?.appointment]);

  useEffect(() => {
    if (appointment) {
      if (appointment?.doctor?.avatarFileId) {
        getFile({
          variables: {
            schema: {
              ids: [appointment?.doctor?.avatarFileId],
            },
          },
          onCompleted(response) {
            setFiles((prevState) => [
              ...prevState,
              response?.findManyFilesByIds?.files?.[0],
            ]);
          },
        });
      }
      getClinicById({
        variables: {
          schema: {
            id: appointment.clinicId,
          },
        },
        onCompleted(response) {
          setClinic(response.findClinicById);
          if (response?.findClinicById?.logoFileId) {
            getFile({
              variables: {
                schema: {
                  ids: [response?.findClinicById?.logoFileId],
                },
              },
              onCompleted(responseFile) {
                setFiles((prevState) => [
                  ...prevState,
                  responseFile?.findManyFilesByIds?.files?.[0],
                ]);
              },
            });
          }
        },
      });
    }
  }, [appointment]);

  const closeModal = useCallback(() => {
    onCloseModal(EModals.ScheduleWaitingAppointment);

    setFiles([]);
  }, [onCloseModal]);

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      minuteFrom: appointment?.minuteFrom || null,
      minuteTo: appointment?.minuteTo || null,
    }));
    if (appointment?.dateFrom) {
      setData((prevState) => ({
        ...prevState,
        dateFrom: parse(
          appointment?.dateFrom as string,
          "yyyy-MM-dd",
          new Date()
        ),
      }));
    }
    if (appointment?.dateTo) {
      setData((prevState) => ({
        ...prevState,
        dateTo: parse(appointment?.dateTo as string, "yyyy-MM-dd", new Date()),
      }));
    }
  }, [appointment]);

  if (!modals[EModals.ScheduleWaitingAppointment].active) return null;

  return (
    <Modal
      modal={EModals.ScheduleWaitingAppointment}
      onCloseAction={closeModal}
    >
      <Wrapper>
        <DoctorInfoCardComponent
          appointment={appointment}
          files={files}
          clinic={clinic}
          isClosedStatus={isClosedStatus}
          data={data}
          setData={setData}
          onSetRescheduleWaitlist={onSetRescheduleWaitlist}
        />
        <PatientInfoCardComponent
          appointment={appointment}
          isClosedStatus={isClosedStatus}
          onSetRescheduleWaitlist={onSetRescheduleWaitlist}
          closeModal={closeModal}
        />
      </Wrapper>
    </Modal>
  );
}

export default ScheduleWaitingAppointmentModal;
