import { useTranslation } from "react-i18next";
import { EButtonVariants } from "src/UI/Button";
import { DeleteIcon, EditIcon, PlusIcon } from "src/UI/Svg";
import { useAppAction } from "src/store/app/hooks";
import { useFormContext } from "react-hook-form";
import {
  useCreateClinicRoom,
  useDeleteClinicRoomById,
  useUpdateClinicRoomById,
} from "src/graphql/hooks/useMutations";
import { toast } from "react-toastify";
import {
  GET_FIND_CLINIC_BY_ID,
  GET_FIND_MANY_CLINIC_ROOMS_BY_CLINIC_ID,
  GET_FIND_MANY_SERVICES,
} from "src/graphql/queries";
import { useParams } from "react-router-dom";
import { MoreAction } from "src/UI/MoreAction";
import { ICabinet } from "src/store/app/types";
import {
  ButtonStyled,
  CabinetStyled,
  CardStyled,
  Content,
  EmptyTextStyled,
  ErrorPhotoStyled,
} from "./styled";

function AddCabinetsView() {
  const { t } = useTranslation();
  const { onShowAddCabinetModal, onConfirmOpen } = useAppAction();
  const { clinicId } = useParams();

  const [delCabinet] = useDeleteClinicRoomById();
  const [addCabinet] = useCreateClinicRoom();
  const [updateCabinet] = useUpdateClinicRoomById();

  const {
    watch,
    trigger,
    formState: { errors },
  } = useFormContext();

  const cabinets = watch("cabinets");

  const handleAddCabinet = (cabinet?: ICabinet) => () => {
    onShowAddCabinetModal({
      cabinet: cabinet || null,
      accept: (data: ICabinet) => {
        if (data?.id) {
          updateCabinet({
            variables: {
              schema: {
                fields: {
                  name: data.name,
                },
                id: data?.id,
              },
            },
            refetchQueries: [
              {
                query: GET_FIND_CLINIC_BY_ID,
                variables: {
                  schema: {
                    id: clinicId,
                  },
                },
              },
              {
                query: GET_FIND_MANY_SERVICES,
                variables: {
                  schema: {
                    clinicId,
                  },
                },
              },
              {
                query: GET_FIND_MANY_CLINIC_ROOMS_BY_CLINIC_ID,
                variables: {
                  schema: {
                    clinicId,
                  },
                },
              },
            ],
            onCompleted: () => {
              toast.success(t("app.successful"));
            },
          });
        } else {
          addCabinet({
            variables: {
              schema: {
                clinicId,
                name: data?.name,
              },
            },
            refetchQueries: [
              {
                query: GET_FIND_CLINIC_BY_ID,
                variables: {
                  schema: {
                    id: clinicId,
                  },
                },
              },
              {
                query: GET_FIND_MANY_SERVICES,
                variables: {
                  schema: {
                    clinicId,
                  },
                },
              },
              {
                query: GET_FIND_MANY_CLINIC_ROOMS_BY_CLINIC_ID,
                variables: {
                  schema: {
                    clinicId,
                  },
                },
              },
            ],
            onCompleted: () => {
              toast.success(t("app.successful"));
            },
          });
        }
        trigger("cabinets");
      },
    });
  };

  const handleRemove = (id: string | number) => () => {
    onConfirmOpen({
      accept: () => {
        delCabinet({
          variables: {
            schema: {
              id,
            },
          },
          refetchQueries: [
            {
              query: GET_FIND_CLINIC_BY_ID,
              variables: {
                schema: {
                  id: clinicId,
                },
              },
            },
            {
              query: GET_FIND_MANY_SERVICES,
              variables: {
                schema: {
                  clinicId,
                },
              },
            },
            {
              query: GET_FIND_MANY_CLINIC_ROOMS_BY_CLINIC_ID,
              variables: {
                schema: {
                  clinicId,
                },
              },
            },
          ],
          awaitRefetchQueries: true,
          onCompleted: () => {
            toast.success(t("app.successful"));
            trigger("cabinets");
          },
        });
      },
    });
  };

  return (
    <CardStyled
      disableBlock={!clinicId}
      title={t("map.cabinets.title")}
      headerChildren={
        <ButtonStyled
          variant={EButtonVariants.Default}
          onClick={handleAddCabinet()}
          type="button"
        >
          <PlusIcon />
          {t("booking.add_service.public")}
        </ButtonStyled>
      }
    >
      <Content>
        {cabinets?.length ? (
          cabinets.map((cabinet: ICabinet) => (
            <CabinetStyled key={cabinet.id}>
              {cabinet.name}
              <MoreAction
                actions={[
                  {
                    title: (
                      <>
                        <EditIcon /> {t("booking.doctors.actions.edit")}
                      </>
                    ),
                    callback: handleAddCabinet(cabinet),
                  },
                  {
                    title: (
                      <>
                        <DeleteIcon /> {t("booking.doctors.actions.delete")}
                      </>
                    ),
                    callback: handleRemove(cabinet?.id as string),
                  },
                ]}
              />
            </CabinetStyled>
          ))
        ) : (
          <EmptyTextStyled>{t("app.cabinets_not_added")}</EmptyTextStyled>
        )}
        {errors?.cabinets && (
          <ErrorPhotoStyled>
            {t(errors?.cabinets?.message as string)}
          </ErrorPhotoStyled>
        )}
      </Content>
    </CardStyled>
  );
}

export default AddCabinetsView;
