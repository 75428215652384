import { Button } from "src/UI/Button";
import styled from "styled-components";

export const Form = styled.form``;

export const Content = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  width: 988px;
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 16px;
    gap: 16px;
  }
`;

export const InputsRow = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  align-content: center;
  justify-content: flex-start;
  gap: 20px;

  & > div {
    width: 100%;
  }
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 16px;
  }
`;

export const FooterButton = styled(Button)`
  padding: 15px 20px;
  font-size: 20px;
  line-height: 100%;
  max-width: unset;
  width: fit-content;
  align-self: end;
`;
