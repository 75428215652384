import styled from "styled-components";
import { Button } from "src/UI/Button";
import { Heading } from "src/UI/Heading";

export const Header = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
`;

export const HeadingStyled = styled(Heading)`
  font-size: 24px;
  line-height: 100%;
`;

export const Content = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 20px;
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 16px;
    gap: 16px;
  }
`;

export const ButtonStyled = styled(Button)`
  padding: 15px 20px;
  font-size: 20px;
  line-height: 100%;
  gap: 7px;
  width: fit-content;
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 12px 16px;
    font-size: 16px;
    gap: 6px;
  }
`;

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  gap: 8px;
  padding: 20px;
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 7px;
    padding: 16px;
  }
`;
