import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppState } from "src/store/app/hooks";
import { EModals } from "src/store/app/types";
import { Modal } from "src/UI/Modal";
import { CheckBox } from "src/UI/Input";
import { EButtonVariants } from "src/UI/Button";
import AddTimetableView from "src/views/BookingsViews/AddTimetableView/AddTimetableView";
import {
  AddEmployeeComponent,
  LocationsComponents,
  PermissionComponent,
} from "src/components/UserComponents";
import { Buttons, ButtonStyled, CardStyled } from "./styles";

function AddEmployeeModal() {
  const { t } = useTranslation();
  const { modals } = useAppState();
  const [isSelectAllLocations, setIsSelectAllLocations] =
    useState<boolean>(false);

  const handleSelectAllLocations = () => () => {
    setIsSelectAllLocations(!isSelectAllLocations);
  };

  if (!modals[EModals.AddEmployee]) return null;
  return (
    <Modal
      modal={EModals.AddEmployee}
      footerContent={
        <>
          <AddTimetableView />
          <CardStyled
            title={t("user.add_employee.location.title")}
            headerChildren={
              <CheckBox
                value={isSelectAllLocations}
                label={t("user.add_employee.location.select_all")}
                onChange={handleSelectAllLocations}
                id="all-locations"
              />
            }
          >
            <LocationsComponents />
          </CardStyled>
          <CardStyled title={t("user.add_employee.permission.title")}>
            <PermissionComponent />
            <Buttons>
              <ButtonStyled variant={EButtonVariants.Text}>
                {t("user.add_employee.buttons.cancel")}
              </ButtonStyled>
              <ButtonStyled variant={EButtonVariants.Default}>
                {t("user.add_employee.buttons.save")}
              </ButtonStyled>
            </Buttons>
          </CardStyled>
        </>
      }
    >
      <CardStyled title={t("user.add_employee.data.title")}>
        <AddEmployeeComponent />
      </CardStyled>
    </Modal>
  );
}

export default AddEmployeeModal;
