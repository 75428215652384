import { useEffect, useState } from "react";
import {
  UserEntityResponse,
  useFindManyFilesByIdsLazyQuery,
} from "src/graphql/generated";
import { IUserStorageItem } from "src/store/app/types";
import { DoctorIcon } from "src/UI/Svg";
import { toast } from "react-toastify";
import { FILE_NOT_FOUND_ERROR_MESSAGE } from "src/constants/constants";
import { useAuthContext } from "src/context/AuthContext";
import { IconWrapper, ImageStyled, InfoBlock, Name } from "./styled";

interface ProfileComponentProps {
  user?: IUserStorageItem | UserEntityResponse | null;
}

function ProfileComponent({ user }: ProfileComponentProps) {
  const { localUsers } = useAuthContext();
  const [preview, setPreview] = useState<string | undefined>("");
  const [getFile] = useFindManyFilesByIdsLazyQuery();

  useEffect(() => {
    if (!user) return;
    if (!user?.avatarFileId) {
      setPreview("");
      return;
    }
    getFile({
      variables: {
        schema: {
          ids: [user?.avatarFileId],
        },
      },
      onCompleted: (response) => {
        const logoUrl = response?.findManyFilesByIds?.files?.[0]?.url;
        if (logoUrl) setPreview(logoUrl as string);
        else setPreview("");
      },
      onError: (error) => {
        if (error) {
          if (error.message === FILE_NOT_FOUND_ERROR_MESSAGE) {
            const users = localUsers.map((localUser) => {
              if (localUser.id === user.id) {
                return { ...localUser, avatarFileId: null };
              }
              return localUser;
            });
            localStorage.setItem("users", JSON.stringify([...users]));
          } else {
            toast.error(error.message);
          }
        }
      },
    });
  }, [user]);

  return (
    <>
      <InfoBlock>
        <Name>
          {user?.firstName} {user?.lastName}
        </Name>
      </InfoBlock>
      <IconWrapper>
        {preview ? <ImageStyled src={preview} alt="#" /> : <DoctorIcon />}
      </IconWrapper>
    </>
  );
}

export default ProfileComponent;
