import styled from "styled-components";

export const Wrapper = styled.div<{ isEngLang: boolean }>`
  width: 100%;

  // ==== Common styles for day & week view
  .day-view,
  .week-view {
    .rbc-event-label {
      display: none;
      font-size: 12px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      color: ${({ theme }) => theme.colors.green};
    }

    .rbc-time-content {
      overflow-y: visible;
    }

    .rbc-row-bg .rbc-day-bg.rbc-today {
      background-color: ${({ theme }) => theme.colors.white};
    }

    .rbc-time-header-content {
      border-left: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
    }

    .rbc-day-slot.rbc-time-column {
      background-color: ${({ theme }) => theme.colors.white};
      border-top: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
    }

    .rbc-time-content {
      border: none;
    }

    .rbc-time-gutter.rbc-time-column > .rbc-timeslot-group {
      border: none;
      min-width: 82px;
    }

    .rbc-time-gutter.rbc-time-column .rbc-time-slot {
      margin-top: -8px;
    }

    .rbc-timeslot-group {
      min-height: 76px;
      border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
    }

    .rbc-timeslot-group .rbc-time-slot:last-of-type {
      display: none;
    }

    .rbc-row-content .rbc-row {
      min-height: 46px;
    }

    .rbc-row-content .rbc-row:last-of-type {
      display: none;
    }

    .rbc-time-content > * + * > * {
      border-left: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
    }

    .rbc-label {
      display: block;
      text-align: center;
      color: ${({ theme }) => theme.colors.gray};
    }

    .rbc-day-slot .rbc-time-slot {
      border-top: none;
    }

    .rbc-current-time-indicator {
      height: 2px;
      background-color: ${({ theme }) => theme.colors.red};
    }

    .rbc-current-time-indicator::before {
      content: "";
      position: absolute;
      top: -4.5px;
      left: -6px;
      width: 11px;
      height: 11px;
      background-color: ${({ theme }) => theme.colors.red};
      border-radius: 50%;
    }
  }

  // ==== Month view styles

  .month-view {
    border: none;
    max-width: 100%;

    .rbc-month-header {
      display: none;
    }

    .rbc-row .rbc-month-header {
      display: flex;
    }

    .rbc-month-row {
      min-height: 180px;

      .rbc-row-bg {
        .rbc-day-bg,
        .rbc-day-bg.rbc-off-range-bg {
          background-color: ${({ theme }) => theme.colors.white};
        }

        .rbc-day-bg {
          border-right: 1px solid
            ${({ theme }) => theme.colors.borderInputDefault};
          border-bottom: 1px solid
            ${({ theme }) => theme.colors.borderInputDefault};

          &:last-child {
            border-right: none;
          }
        }
      }

      &:last-child {
        .rbc-row-bg {
          .rbc-day-bg {
            border-bottom: none;
          }
        }
      }
    }

    .rbc-event {
      width: 100%;
      background-color: transparent;
      height: fit-content;
      border: none;
      border-radius: unset;

      &.selected-event {
        z-index: 2;
      }

      &:focus {
        outline: none;
      }
    }

    .rbc-button-link.rbc-show-more {
      font-weight: 500;
      font-size: 14px;
      line-height: 1.2;
      color: ${({ theme }) => theme.colors.gray};
      padding: 2px 5px;
    }
  }

  // ==== Day view styles

  .day-view {
    .rbc-events-container {
      margin: 0;
    }

    .rbc-event {
      width: 100% !important;
      left: 0 !important;
      background-color: ${({ theme }) => theme.colors.greenHover};
      flex-direction: column;
      flex-flow: column;
      height: fit-content !important;
      border: none;
      border-radius: unset;
      border-left: 3px solid transparent;

      &.selected-event {
        z-index: 2;
        background-color: ${({ theme }) => theme.colors.lightBiege};
        border-left: 3px solid #99d6d0;
      }
    }

    .rbc-timeslot-group {
      min-height: 160px;
    }
  }

  // ==== Week view styles

  .week-view {
    .rbc-time-header.rbc-overflowing {
      border-right: none;
      margin: 0 !important;
    }

    .rbc-row-content .rbc-row {
      display: none;
    }

    .rbc-timeslot-group {
      min-height: 140px;
    }

    .rbc-header {
      padding: 12px 0;
    }

    .custom-weekend-class span {
      color: ${({ theme }) => theme.colors.red};
    }

    .rbc-header {
      background-color: ${({ theme }) => theme.colors.white};
      border-bottom: none;
    }

    .rbc-header span {
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      line-height: 100%;
    }
    .rbc-header.rbc-today span {
      color: ${({ theme }) => theme.colors.green};
    }

    .rbc-allday-cell {
      display: none;
    }

    .rbc-events-container {
      margin: 0;
    }

    .rbc-event {
      width: 100% !important;
      left: 0 !important;
      background-color: ${({ theme }) => theme.colors.greenHover};
      flex-direction: column;
      flex-flow: column;
      height: fit-content !important;
      border: none;
      border-radius: unset;
      border-left: 3px solid transparent;

      &.selected-event {
        z-index: 2;
        background-color: ${({ theme }) => theme.colors.lightBiege};
        border-left: 3px solid #99d6d0;
      }
    }

    .rbc-event-label {
      font-size: 12px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: left;
      color: ${({ theme }) => theme.colors.green};
    }
  }
`;
