import {
  ApolloCache,
  DefaultContext,
  MutationHookOptions,
  OperationVariables,
  useMutation,
} from "@apollo/client";

import { toast } from "react-toastify";
import {
  CHECK_PHONE_NUMBER_ERROR_MESSAGE,
  EMAIL_ALREADY_TAKEN_ERROR_MESSAGE,
} from "src/constants/constants";
import * as MUTATIONS from "../../mutations";

type TOptions = MutationHookOptions<
  any,
  OperationVariables,
  DefaultContext,
  ApolloCache<any>
>;

export const useSignIn = (options?: MutationHookOptions) => {
  return useMutation(MUTATIONS.LOCAL_SIGN_IN, options);
};

export const useLocalSignUp = (options?: TOptions) => {
  return useMutation(MUTATIONS.LOCAL_SIGN_UP, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useAuthorizeWithPinCode = (options?: MutationHookOptions) => {
  return useMutation(MUTATIONS.AUTHORIZE_WITH_PIN_CODE, options);
};

export const useAuthorizeWithPassword = (options?: MutationHookOptions) => {
  return useMutation(MUTATIONS.AUTHORIZE_WITH_PASS, options);
};

export const useConfirmMail = (options?: TOptions) => {
  return useMutation(MUTATIONS.CONFIRM_MAIL, options);
};

export const useEnable2FA = (options?: MutationHookOptions) => {
  return useMutation(MUTATIONS.ENABLE_2FA, options);
};

export const useCreateCompany = (options?: TOptions) => {
  return useMutation(MUTATIONS.CREATE_COMPANY, options);
};

export const useGenerate2FA = (options?: MutationHookOptions) => {
  return useMutation(MUTATIONS.GENERATE_2FA, options);
};

export const useAddPINCode = (options?: MutationHookOptions) => {
  return useMutation(MUTATIONS.ADD_PIN_CODE, options);
};

export const useRequestPasswordRestorage = (options?: TOptions) => {
  return useMutation(MUTATIONS.REQ_PASS_RESTORAGE, options);
};

export const useRestorePassword = (options?: TOptions) => {
  return useMutation(MUTATIONS.RESTORE_PASS, options);
};

export const useChangeMail = (options?: TOptions) => {
  return useMutation(MUTATIONS.CHANGE_MAIL, options);
};

export const useRequestEmailChange = (options?: TOptions) => {
  return useMutation(MUTATIONS.REQ_EMAIL_CHANGE, options);
};

export const useAuthorizeWith2FA = (options?: TOptions) => {
  return useMutation(MUTATIONS.AUTH_2FA, options);
};

export const useCreateClinic = (options?: TOptions) => {
  return useMutation(MUTATIONS.CREATE_CLINIC, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useUploadFile = (options?: TOptions) => {
  return useMutation(MUTATIONS.UPLOAD_FILE, {
    ...options,
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
  });
};

export const useCreateService = (options?: MutationHookOptions) => {
  return useMutation(MUTATIONS.CREATE_SERVICE, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useDeleteServiceById = (options?: MutationHookOptions) => {
  return useMutation(MUTATIONS.DELETE_SERVICE_BY_ID, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};
export const useDeleteClinicById = (options?: TOptions) => {
  return useMutation(MUTATIONS.DEL_CLINIC_BY_ID, options);
};

export const useUpdateClinicById = (options?: TOptions) => {
  return useMutation(MUTATIONS.UPDATE_CLINIC_BY_ID, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useCreateClinicRoom = (options?: TOptions) => {
  return useMutation(MUTATIONS.CREATE_CLINIC_ROOM, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useUpdateClinicRoomById = (options?: TOptions) => {
  return useMutation(MUTATIONS.UPDATE_CLINIC_ROOM_BY_ID, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useDeleteClinicRoomById = (options?: TOptions) => {
  return useMutation(MUTATIONS.DELETE_CLINIC_ROOM_BY_ID, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useDeleteServiceCategoryById = (options?: MutationHookOptions) => {
  return useMutation(MUTATIONS.DELETE_SERVICE_CATEGORY_BY_ID, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useUpdateServiceCategoryById = (options?: MutationHookOptions) => {
  return useMutation(MUTATIONS.UPDATE_SERVICE_CATEGORY_BY_ID, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useCreateServiceCategory = (options?: MutationHookOptions) => {
  return useMutation(MUTATIONS.CREATE_SERVICE_CATEGORY, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useUpdateServiceById = (options?: MutationHookOptions) => {
  return useMutation(MUTATIONS.UPDATE_SERVICE_BY_ID, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useDeleteClinicWorkingDayById = (options?: TOptions) => {
  return useMutation(MUTATIONS.DELETE_CLINIC_WORKING_DAY_BY_ID, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useCreateClinicWorkingDay = (options?: TOptions) => {
  return useMutation(MUTATIONS.CREATE_CLINIC_WORKING_DAY_BY_CLINIC_ID, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useUpdateClinicWorkingDaysByClinicId = (options?: TOptions) => {
  return useMutation(MUTATIONS.UPDATE_CLINIC_WORKING_DAY_BY_CLINIC_ID, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useUpdateClinicLogoFileId = (options?: TOptions) => {
  return useMutation(MUTATIONS.UPDATE_CLINIC_LOGO_FIELD, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useDeleteClinicLogoFileId = (options?: TOptions) => {
  return useMutation(MUTATIONS.DELETE_CLINIC_LOGO_FIELD, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useCreatePatient = (options?: TOptions) => {
  return useMutation(MUTATIONS.CREATE_PATIENT, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useCreateDoctorInvitation = (options?: TOptions) => {
  return useMutation(MUTATIONS.CREATE_DOCTOR_INVITATION, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useUpdateDoctorByUserIdByDoctor = (options?: TOptions) => {
  return useMutation(MUTATIONS.UPDATE_DOCTOR_BY_USER_ID_BY_DOCTOR, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useUpdateUserAvatarFileId = (options?: TOptions) => {
  return useMutation(MUTATIONS.UPDATE_USER_AVATAR_FILE_ID, {
    ...options,
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
  });
};

export const useUpdateDoctorByIdByClient = (options?: TOptions) => {
  return useMutation(MUTATIONS.UPDATE_DOCTOR_BY_ID_BY_CLIENT, {
    ...options,
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
  });
};

export const useCreateSchedule = (options?: TOptions) => {
  return useMutation(MUTATIONS.CREATE_SCHEDULE, {
    ...options,
    onError: (error) => {
      if (error) {
        toast.error(error.message);
      }
    },
  });
};

export const useDeleteDoctorById = (options?: TOptions) => {
  return useMutation(MUTATIONS.DELETE_DOCTOR_BY_ID, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useCreateVisitByClient = (options?: TOptions) => {
  return useMutation(MUTATIONS.CREATE_VISIT_BY_CLIENT, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useCreateVisitByDoctor = (options?: TOptions) => {
  return useMutation(MUTATIONS.CREATE_VISIT_BY_DOCTOR, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useDeletePatientById = (options?: TOptions) => {
  return useMutation(MUTATIONS.DELETE_PATIENT_BY_ID, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useUpdatePatientById = (options?: TOptions) => {
  return useMutation(MUTATIONS.UPDATE_PATIENT_BY_ID, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useUpdateDoctorClinicsByClient = (options?: TOptions) => {
  return useMutation(MUTATIONS.UPDATE_DOCOTOR_CLINICS_BY_CLIENT, {
    ...options,
  });
};

export const useDeleteScheduleByIdByClient = (options?: TOptions) => {
  return useMutation(MUTATIONS.DELETE_SHEDULE_BY_ID_BY_CLIENT, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useUpdateScheduleByIdByClient = (options?: TOptions) => {
  return useMutation(MUTATIONS.UPDATE_SCHEDULE_BY_CLIENT, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useAttachDoctorToService = (options?: TOptions) => {
  return useMutation(MUTATIONS.ATTACH_DOCTOR_TO_SERVICE, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useUnattachDoctorService = (options?: TOptions) => {
  return useMutation(MUTATIONS.UNATTACH_DOCTOR_TO_SERVICE, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useCancelScheduleAppointmentByClient = (options?: TOptions) => {
  return useMutation(MUTATIONS.CANCEL_SCHEDULE_APPOINTMENT_BY_CLIENT, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useConfirmScheduleAppointmentByClient = (options?: TOptions) => {
  return useMutation(MUTATIONS.CONFIRM_SCHEDULE_APPOINTMENT_BY_CLIENT, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useUpdateScheduleAppointmentMissedStatusByClient = (
  options?: TOptions
) => {
  return useMutation(
    MUTATIONS.UPDATE_SCHEDULE_APPOINTMENT_MISSED_STATUS_BY_CLIENT,
    {
      ...options,
      onError: (error) => {
        if (error) toast.error(error.message);
      },
    }
  );
};

export const useRescheduleAppointmentByClient = (options?: TOptions) => {
  return useMutation(MUTATIONS.RESCHEDULE_APPOINTMENT_BY_CLIENT, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useCancelScheduleAppointmentByPatient = (options?: TOptions) => {
  return useMutation(MUTATIONS.CANCEL_SCHEDULE_APPOINTMENT_BY_PATIENT, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useCreateScheduleAppointmentByPatient = (options?: TOptions) => {
  return useMutation(MUTATIONS.CREATE_SCHEDULE_APPOINTMENT_BY_PATIENT, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
      if (error.message === EMAIL_ALREADY_TAKEN_ERROR_MESSAGE) {
        toast.warning(CHECK_PHONE_NUMBER_ERROR_MESSAGE);
      }
    },
  });
};

export const useRescheduleScheduleAppointmentByPatient = (
  options?: TOptions
) => {
  return useMutation(MUTATIONS.RESCHEDULE_SCHEDULE_APPOINTMENT_BY_PATIENT, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useConfirmScheduleAppointmentByPatient = (options?: TOptions) => {
  return useMutation(MUTATIONS.CONFIRM_SCHEDULE_APPOINTMENT_BY_PATIENT, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useCreateScheduleWaitlistByClient = (options?: TOptions) => {
  return useMutation(MUTATIONS.CREATE_SCHEDULE_WAITLIST_BY_CLIENT, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useRescheduleWaitlistByClient = (options?: TOptions) => {
  return useMutation(MUTATIONS.RESCHEDULE_WAITLIST_BY_CLIENT, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useCloseScheduleWaitlistByClient = (options?: TOptions) => {
  return useMutation(MUTATIONS.CLOSE_SCHEDULE_WAITLIST_BY_CLIENT, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useUpdateDoctorAvatarFileIdByClient = (options?: TOptions) => {
  return useMutation(MUTATIONS.UPDATE_DOCOTOR_AVATAR_BY_CLIENT, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};
