import { FC, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { CardComponent } from "src/components/UserComponents";
import {
  ServiceEntityWithRelationsResponse,
  ServiceSortFields,
  SortingOrders,
  useFindManyServicesLazyQuery,
} from "src/graphql/generated";
import { Loading } from "src/UI/Loading";
import { useClinicsState } from "src/store/clinics/hooks";
import {
  EmptyTextStyled,
  List,
  LoadingWrap,
  Tab,
  Tabs,
  Wrapper,
} from "./styles";
import ServiceComponent from "../ServiceComponent/ServiceComponent";

enum EFilterTypes {
  All = "ALL",
  Added = "ADDED",
}

const ServicesView: FC<{ doctorId: string }> = ({ doctorId }) => {
  const { t } = useTranslation();

  const clinicId = localStorage.getItem("currentClinicId") as string;
  const [services, setServices] = useState<
    ServiceEntityWithRelationsResponse[]
  >([]);
  const [doctorServices, setDoctorServices] = useState<
    ServiceEntityWithRelationsResponse[]
  >([]);
  const [actualServices, setActualServices] = useState<
    ServiceEntityWithRelationsResponse[]
  >([]);
  const [filter, setFilter] = useState<EFilterTypes>(EFilterTypes.All);

  useEffect(() => {
    if (filter === EFilterTypes.Added) {
      setActualServices(doctorServices);
    }
    if (filter === EFilterTypes.All) {
      setActualServices(services);
    }
  }, [filter, services]);

  const { currentLocation } = useClinicsState();

  const [getServices, { loading }] = useFindManyServicesLazyQuery();

  useEffect(() => {
    getServices({
      variables: {
        schema: {
          clinicId,
          sortField: ServiceSortFields.CreatedAt,
          sortOrder: SortingOrders.Descending,
        },
      },
      onCompleted: (response) => {
        const doctorServicesList = response.findManyServices?.services?.filter(
          (service: ServiceEntityWithRelationsResponse) =>
            service?.doctorIds?.includes(doctorId)
        );
        setServices(response.findManyServices?.services);
        setDoctorServices(doctorServicesList);
      },
    });
  }, [currentLocation]);

  const handleToggleFilterType = (type: EFilterTypes) => () => {
    setFilter(type);
  };

  return (
    <Wrapper>
      <CardComponent
        title={t("booking.services.title")}
        headerChildren={
          <Tabs>
            <Tab
              isActive={filter === EFilterTypes.All}
              onClick={handleToggleFilterType(EFilterTypes.All)}
            >
              {t("search.tabs.all")} <span>{services.length}</span>
            </Tab>
            <Tab
              isActive={filter === EFilterTypes.Added}
              onClick={handleToggleFilterType(EFilterTypes.Added)}
            >
              {t("app.added")} <span>{doctorServices.length}</span>
            </Tab>
          </Tabs>
        }
      >
        {loading ? (
          <LoadingWrap>
            <Loading type="primary" />
          </LoadingWrap>
        ) : (
          <List>
            {actualServices.length ? (
              actualServices.map(
                (service: ServiceEntityWithRelationsResponse) => (
                  <ServiceComponent
                    key={service.id as never}
                    service={service}
                    doctorId={doctorId}
                  />
                )
              )
            ) : (
              <EmptyTextStyled>{t("app.services_not_added")}</EmptyTextStyled>
            )}
          </List>
        )}
      </CardComponent>
    </Wrapper>
  );
};

export default ServicesView;
