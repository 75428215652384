import styled from "styled-components";

export const Row = styled.div`
  width: 100%;
  display: flex;
  padding: 20px 0;
  align-items: center;
  align-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  justify-content: space-between;
  &:last-child {
    border-bottom: none;
  }
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 16px 0;
  }
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  margin-right: 15px;
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 12px;
    gap: 8px;
  }
`;

export const Right = styled.div<{ isChecked: boolean }>`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 15px;
  opacity: ${({ isChecked }) => (isChecked ? "1" : ".5")};
  pointer-events: ${({ isChecked }) => (isChecked ? "auto" : "none")};
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 12px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  height: 100%;
`;

export const ErrorStyled = styled.div`
  font-weight: 500;
  font-size: 10px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: right;
  color: ${({ theme }) => theme.colors.red};
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
`;

export const FieldWrapStyled = styled.div`
  display: flex;
  position: relative;
`;

export const Label = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  margin-right: 6px;
  color: ${({ theme }) => theme.colors.gray};
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 12px;
    margin-right: 6px;
  }
`;
export const DateInputStyled = styled.div`
  padding: 8px 10px;
  gap: 4px;
  min-width: 140px;
  min-height: 32px;
  background: ${({ theme }) => theme.colors.lightBiege};
  border-radius: 5px;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.black};
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 12px;
  }
`;
