import { ElementType, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EButtonVariants } from "../Button";
import { PlusIcon } from "../Svg";
import {
  Block,
  ButtonStyled,
  PaddingWrapper,
  Tab,
  Wrapper,
  WrapperTabs,
} from "./styled";

function Tabs({
  headers,
  contents,
  onShowModal,
  onTabChange,
  titleButton,
  PaddingWrapperEl = PaddingWrapper,
  activeTabValue,
  ...props
}: {
  headers: Array<string | ReactNode>;
  PaddingWrapperEl?: ElementType;
  contents: ReactNode[];
  onShowModal?: () => void;
  onTabChange?: (id: number) => void;
  titleButton?: string;
  activeTabValue?: number;
}) {
  const [activeTab, setActiveTab] = useState<number>(0);
  const { t } = useTranslation();

  const handleChangeTab = (id: number) => () => {
    if (onTabChange) onTabChange(id);
    setActiveTab(id);
  };

  useEffect(() => {
    if (onTabChange) onTabChange(activeTab);
  }, []);

  useEffect(() => {
    if (activeTabValue !== undefined && activeTabValue !== activeTab) {
      setActiveTab(activeTabValue);
    }
  }, [activeTabValue]);

  return (
    <Block {...props}>
      <PaddingWrapperEl>
        <Wrapper>
          <WrapperTabs>
            {headers.map((item, id) => (
              <Tab
                key={`Tab-${id}`}
                isActive={activeTab === id}
                onClick={handleChangeTab(id)}
              >
                {item}
              </Tab>
            ))}
          </WrapperTabs>

          {titleButton ? (
            <ButtonStyled onClick={onShowModal} variant={EButtonVariants.Text}>
              <PlusIcon /> {t(titleButton)}
            </ButtonStyled>
          ) : null}
        </Wrapper>
      </PaddingWrapperEl>

      {contents[activeTab]}
    </Block>
  );
}

export default Tabs;
