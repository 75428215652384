import { useFormContext } from "react-hook-form";
import { setHours, setMinutes, startOfDay } from "date-fns";
import { useTranslation } from "react-i18next";
import { SlotsGroupedByDateResponse } from "src/graphql/generated";
import { useState } from "react";
import {
  useFindManySchedulesByClient,
  useFindManySchedulesByDoctor,
} from "src/graphql/hooks/useQueries";
import { useAuthContext } from "src/context/AuthContext";
import AddVisitDatePicker from "src/components/BookingComponents/BookingDatePickerComponents/AddVisitDatePicker/AddVisitDatePicker";
import AddWaitListDatePicker from "src/components/BookingComponents/BookingDatePickerComponents/AddWaitListDatePicker/AddWaitListDatePicker";
import { formatDateToDateISO } from "src/utils";
import { Loading } from "src/UI/Loading";
import { EButtonVariants } from "src/UI/Button";
import {
  Header,
  HeadingStyled,
  Content,
  RightWrapper,
  ButtonBlock,
  ButtonStyled,
} from "./styles";
import { EViewsTypes } from "../types";

interface ComponentProps {
  slots: SlotsGroupedByDateResponse[];
  loading: boolean;
  buttonLoading: boolean;
  dateFilter: Date;
  filterView: EViewsTypes;
  setDateFilter: (date: Date) => void;
}

const RightSideComponent = ({
  slots,
  loading,
  buttonLoading,
  dateFilter,
  filterView,
  setDateFilter,
}: ComponentProps) => {
  const [isScheduleProvided, setIsScheduleProvided] = useState(false);
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const dataForm = watch();
  const { isDoctor, isClient } = useAuthContext();

  const { setValue } = useFormContext();

  const timeFrom = setMinutes(
    setHours(startOfDay(new Date()), 0),
    dataForm.minuteFrom as number
  );
  const timeTo = setMinutes(
    setHours(startOfDay(new Date()), 0),
    dataForm.minuteTo as number
  );

  useFindManySchedulesByClient({
    variables: {
      schema: {
        clinicId: dataForm.clinicId,
        doctorId: dataForm.doctorId,
      },
    },
    skip: !dataForm.doctorId || isDoctor,
    onCompleted: (response) => {
      setIsScheduleProvided(
        !!response?.findManySchedulesByClient?.schedules?.length
      );
    },
  });

  useFindManySchedulesByDoctor({
    variables: {
      schema: {
        clinicId: dataForm.clinicId,
      },
    },
    skip: !dataForm.doctorId || isClient,
    onCompleted: (response) => {
      setIsScheduleProvided(
        !!response?.findManySchedulesByDoctor?.schedules?.length
      );
    },
  });

  const handleChangeMinuteFrom = (minuteCount: number | null) => {
    setValue("minuteFrom", minuteCount);
  };
  const handleChangeMinuteTo = (minuteCount: number | null) => {
    setValue("minuteTo", minuteCount);
  };

  const handleDateRangeChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;

    if (start && end) {
      setValue("dateFrom", formatDateToDateISO(start || new Date()));
      setValue("dateTo", formatDateToDateISO(end || new Date()));
    }
  };

  return (
    <RightWrapper>
      <Header>
        <HeadingStyled as="h3">{t("forms.modals.fields.date")}</HeadingStyled>
      </Header>

      {filterView === EViewsTypes.Booking ? (
        <Content>
          <AddVisitDatePicker
            slots={slots}
            timeTo={timeTo}
            timeFrom={timeFrom}
            loading={loading}
            dateFilter={dateFilter}
            buttonLoading={buttonLoading}
            isScheduleProvided={isScheduleProvided}
            setDateFilter={setDateFilter}
          />
        </Content>
      ) : (
        <Content>
          <AddWaitListDatePicker
            minuteFromCount={dataForm.minuteFrom}
            minuteToCount={dataForm.minuteTo}
            dateFrom={dataForm.dateFrom}
            dateTo={dataForm.dateTo}
            onChangeMinuteFrom={handleChangeMinuteFrom}
            onChangeMinuteTo={handleChangeMinuteTo}
            onDateRangeChange={handleDateRangeChange}
          />
          <ButtonBlock>
            <ButtonStyled
              variant={EButtonVariants.Gray}
              disabled={
                typeof dataForm.minuteFrom !== "number" ||
                typeof dataForm.minuteTo !== "number" ||
                !dataForm.dateFrom ||
                !dataForm.dateTo
              }
            >
              {buttonLoading && <Loading type="secondary" padding="0" />}
              {t("website.booking.on_the_wait_list")}
            </ButtonStyled>
          </ButtonBlock>
        </Content>
      )}
    </RightWrapper>
  );
};

export default RightSideComponent;
