import { Button } from "src/UI/Button";
import styled from "styled-components";

export const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`;

export const ButtonStyled = styled(Button)`
  padding: 8px 10px;
  gap: 7px;
  font-size: 16px;
  line-height: 100%;
  font-weight: 700;
  border-radius: 5px;
  width: fit-content;

  svg path {
    stroke: ${({ theme }) => theme.colors.white};
  }
`;
