import React, {
  ChangeEvent,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CheckIcon, EyeIcon } from "src/UI/Svg";
import { DefaultTFuncReturn } from "i18next";
import {
  ErrorMessage,
  IconBlock,
  IconPassword,
  IconWrapper,
  InputBlock,
  InputStyled,
  InputWrapper,
  Label,
} from "./styled";

interface InputProps {
  children?: ReactNode;
  label?: DefaultTFuncReturn;
  val: string;
  placeholder?: DefaultTFuncReturn;
  inputType: string;
  errorMessage?: FieldError;
  register: any;
  handleChange?: (value: any) => void;
  iconLeft?: ReactNode;
  isFocused?: boolean;
  customWidth?: string;
  disabled?: boolean;
}

function InputRHF({
  children,
  register: { onChange, name, ...registerRest },
  label,
  val,
  errorMessage,
  handleChange,
  iconLeft,
  placeholder,
  inputType,
  disabled = false,
}: InputProps) {
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleChangeOwn = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e);
    if (handleChange) handleChange(e.target.value);
  };

  const wrapper = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!isFocused && val) {
      setIsFocused(!!val);
    }
  }, [val, isFocused]);

  return (
    <InputWrapper>
      <InputBlock ref={wrapper}>
        <Label isFocused={isFocused} htmlFor={name}>
          {label}
        </Label>
        {isFocused && iconLeft && <IconBlock>{iconLeft}</IconBlock>}
        <InputStyled
          {...registerRest}
          id={name}
          name={name}
          onChange={handleChangeOwn}
          isError={!!errorMessage}
          type={isShowPassword ? "text" : inputType}
          placeholder={placeholder}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          disabled={disabled}
        />
        {inputType === "email"
          ? val &&
            val !== "" &&
            !errorMessage && (
              <IconWrapper>
                <CheckIcon />
              </IconWrapper>
            )
          : inputType === "password" && (
              <IconPassword
                isShow={isShowPassword}
                onClick={() => setIsShowPassword(!isShowPassword)}
              >
                <EyeIcon />
              </IconPassword>
            )}
        {errorMessage && (
          <ErrorMessage>
            {errorMessage?.message && t(errorMessage.message)}
          </ErrorMessage>
        )}
      </InputBlock>
      {children}
    </InputWrapper>
  );
}

export default InputRHF;
