import { AutocompletePrediction } from "react-places-autocomplete";
import { IUserValue } from "src/pages/VerifyPages/RegistrationCompanyPage";
import * as yup from "yup";

export const registrationCompanyValidationSchema = yup.object().shape({
  companyName: yup
    .string()
    .required("auth.reg.company_name_error")
    .min(3, "auth.reg.company_name_error")
    .max(100, "validation.field_max_100"),
  companyNIP: yup
    .string()
    .required("validation.field_required")
    .min(7, "auth.reg.company_nip_error_min")
    .max(12, "auth.reg.company_nip_error_max"),
  companyAddress: yup
    .mixed()
    .nullable()
    .test(
      "check-address-type",
      "app.geocoder.not_correct_address_error",
      (value) => {
        const typedValue = value as AutocompletePrediction | IUserValue;
        const isNotCorrecValue =
          !!value &&
          typedValue.description.length &&
          (value as IUserValue)?.type === "custom_user_value";
        return !isNotCorrecValue;
      }
    )
    .test("check-required", "validation.field_required", (value) => {
      const typedValue = value as AutocompletePrediction | IUserValue;
      return !!typedValue?.description;
    }),
});
