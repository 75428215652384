import React from "react";
import styled from "styled-components";

const Wrapper = styled.div<{
  padding?: string;
}>`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  padding: ${({ padding }) => (padding ? padding : "8px 10px")};
  gap: 7px;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.green};
`;

const LoadBlock = styled.div<{
  type: string;
  size: string;
}>`
  display: inline-block;
  position: relative;
  width: ${({ size }) => size};
  height: ${({ size }) => size};

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${({ size }) => size};
    height: ${({ size }) => size};
    margin: 2px;
    border: 1px solid
      ${({ theme, type }) =>
        type === "primary" ? theme.colors.green : theme.colors.white};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ theme, type }) =>
        type === "primary" ? theme.colors.green : theme.colors.white}
      transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

function Loading({
  title,
  type = "primary",
  padding,
  size = "12px",
}: {
  title?: string;
  type?: "primary" | "secondary";
  padding?: string;
  size?: string;
}) {
  return (
    <Wrapper padding={padding}>
      <LoadBlock type={type} size={size}>
        <div />
        <div />
        <div />
      </LoadBlock>
      {title && title}
    </Wrapper>
  );
}

export default Loading;
