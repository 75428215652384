import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  max-height: calc(100vh - 140px);
  //overflow: hidden;
`;
