import { Dropdown } from "src/UI/Dropdown";
import { ErrorMessage } from "src/UI/DropdownInput/styles";
import styled from "styled-components";

export const InputWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  & > div {
    width: 100%;
    display: flex;
  }
`;

export const Label = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.gray};
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 12px;
  }
`;

export const DropdownStyled = styled(Dropdown)`
  border-radius: 5px;
  background-color: transparent;

  & > div:first-child {
    width: 100%;
    background-color: transparent;
    padding: 0;
  }
`;

export const SelectItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  line-height: 22px;
`;

interface ISelectItemDot {
  $isFill: boolean;
}
export const SelectItemDot = styled.div<ISelectItemDot>`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: ${({ theme, $isFill }) =>
    $isFill ? theme.colors.green : theme.colors.white};
  box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.white},
    0 0 0 3px
      ${({ theme, $isFill }) =>
        $isFill ? theme.colors.green : theme.colors.gray};
`;

export const StyledErrorMessage = styled(ErrorMessage)`
  justify-content: end;
  line-height: 100%;
`;
