import React, { FunctionComponent } from "react";
import { Svg, SvgProps } from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M3.03326 2.82957L3.03365 2.82976L21.0937 11.4672L21.0939 11.4673C21.1913 11.5138 21.2735 11.5869 21.331 11.6782C21.3886 11.7694 21.4191 11.8751 21.4191 11.983C21.4191 12.0909 21.3886 12.1965 21.331 12.2878C21.2735 12.3791 21.1913 12.4521 21.0939 12.4986L21.0937 12.4988L3.03365 21.1362L3.03339 21.1363C2.93465 21.1836 2.8244 21.2016 2.71576 21.188C2.60711 21.1744 2.50466 21.1299 2.42057 21.0598C2.33649 20.9897 2.27432 20.8969 2.24146 20.7925C2.20861 20.6881 2.20643 20.5764 2.23518 20.4708C2.23519 20.4708 2.23521 20.4708 2.23522 20.4707L4.02963 13.8923L12.7341 12.7099L12.7372 12.7094C12.8591 12.6921 12.9745 12.6435 13.0721 12.5684L13.0722 12.5684C13.1698 12.4933 13.2463 12.3943 13.2943 12.2809L13.3059 12.2536L13.3141 12.2252L13.3296 12.1721L13.3395 12.1379L13.3445 12.1026C13.367 11.9431 13.335 11.7807 13.2535 11.6417C13.1721 11.5027 13.0461 11.3953 12.8959 11.3369L12.8716 11.3275L12.8464 11.3206L12.7992 11.3077L12.7676 11.2991L12.735 11.2947L4.03995 10.1123L2.23518 3.49597L2.23509 3.49563C2.20619 3.38997 2.20827 3.27823 2.24108 3.17371C2.27389 3.0692 2.33605 2.97632 2.42016 2.90613C2.50426 2.83595 2.60677 2.79141 2.71547 2.77784C2.82417 2.76427 2.93448 2.78223 3.03326 2.82957ZM4.05241 10.158L4.05233 10.1577L4.05241 10.158Z"
      fill="#0BA495"
      stroke="#0BA495"
    />
  </Svg>
);

export default Icon;
