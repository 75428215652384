import React from "react";

import { useTranslation } from "react-i18next";
import { AggregatedScheduleAppointmentEntityWithRelationsByClientResponse } from "src/graphql/generated";
import { useAppAction } from "src/store/app/hooks";
import { TScheduleAppointmentByRole } from "src/types";
import { Loading } from "src/UI/Loading";
import { useAuthContext } from "src/context/AuthContext";
import RowComponent from "./RowComponent";
import { Table, Wrapper } from "./styled";
import { EmptyTextStyled } from "../SettingComponents/LocationComponent/styled";

interface IReminderTableComponentProps {
  remindersAppoitments: TScheduleAppointmentByRole[];
  loading: boolean;
}

function ReminderTableComponent({
  remindersAppoitments,
  loading = false,
}: IReminderTableComponentProps) {
  const { t } = useTranslation();
  const { isClient } = useAuthContext();
  const { onShowScheduleAppointmentModal } = useAppAction();

  const handleShowModal = (id: string) => () => {
    onShowScheduleAppointmentModal({
      data: {
        appointment: remindersAppoitments?.find(
          (item) => item?.id === id
        ) as AggregatedScheduleAppointmentEntityWithRelationsByClientResponse,
      },
    });
  };

  return (
    <Wrapper>
      {loading ? (
        <Loading padding="0" />
      ) : remindersAppoitments?.length ? (
        <Table>
          <thead>
            <tr>
              <th>{t("reminder.table.patient_name")}</th>
              <th>{t("reminder.table.cellphone")}</th>
              <th>{t("reminder.table.status")}</th>
              <th>{t("reminder.table.app_time")}</th>
              {isClient ? <th>{t("reminder.table.provider")}</th> : null}
              <th>{t("reminder.table.service")}</th>
            </tr>
          </thead>
          <tbody>
            {remindersAppoitments?.map((appointment) => (
              <RowComponent
                key={appointment?.id}
                appointment={appointment}
                handleShowModal={handleShowModal}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTextStyled>{t("app.appointments_not_added")}</EmptyTextStyled>
      )}
    </Wrapper>
  );
}

export default ReminderTableComponent;
