import { Button } from "src/UI/Button";
import styled from "styled-components";

export const Content = styled.div`
  padding: 20px;
  width: 635px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 20px;
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 16px;
    gap: 16px;
  }
`;

export const Form = styled.form`
  width: 100%;
`;

export const ActionBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  gap: 10px;
  margin-top: 16px;
`;

export const ButtonStyled = styled(Button)`
  width: fit-content;
  font-size: 20px;
  line-height: 100%;
  padding: 15px 20px;
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 12px 16px;
    font-size: 16px;
  }
`;

export const Text = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.black};
  span {
    color: ${({ theme }) => theme.colors.gray};
  }
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 12px;
  }
`;
