import styled from "styled-components";

export const SliderItemStyled = styled.div<{ isSelected: boolean }>`
  width: fit-content;
  margin: 0 auto;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid;
  border-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.green : "transparent"};

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: column;
  }
`;

export const SliderItemDayStyled = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.gray};

  ${({ theme }) => theme.mediaQueries.xs} {
    font-size: 12px;
    line-height: 14px;
  }
`;

export const SliderItemDateStyled = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;

  ${({ theme }) => theme.mediaQueries.xs} {
    font-size: 12px;
    line-height: 14px;
  }
`;
