import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;
  min-height: 330px;
  width: calc(100% / 4);
  & .react-datepicker {
    border: none;

    &__month {
      margin: 0;
    }

    &__header {
      background-color: transparent;
      border: none;
    }

    &__day {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;

      &--outside-month {
        opacity: 0;
      }

      &--selected {
        background-color: transparent;
        color: ${({ theme }) => theme.colors.black};
      }

      &--keyboard-selected {
        background-color: ${({ theme }) => theme.colors.green} !important;
      }

      &--weekend {
        color: ${({ theme }) => theme.colors.red};
      }

      &--disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      &.available-day {
        background-color: ${({ theme }) => theme.colors.greenHover};
      }
    }

    &__day-name {
      width: 32px;
      font-size: 14px;
      font-weight: 300;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: center;
      color: ${({ theme }) => theme.colors.grayC4};
    }
  }
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.black};
  text-transform: capitalize;
  text-align: left;
  margin-bottom: 10px;
`;

export const DatePickerDay = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const DatePickerDayDropdown = styled.div`
  width: 160px;
  max-height: 150px;
  position: absolute;
  top: 100%;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.borderInputDefault};
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 1;
  border-radius: 5px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const EmptyTextStyled = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.gray};
`;
