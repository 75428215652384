import { useTranslation } from "react-i18next";
import { PatientAppointmentsTable } from "src/components/PatientsComponents";
import { Block } from "../ActivityView/styled";
import { PatientAppointmentsTitle } from "./styled";

function PatientAppointmentsView() {
  const { t } = useTranslation();

  return (
    <Block>
      <PatientAppointmentsTitle>
        {t("patients.visit.title")}
      </PatientAppointmentsTitle>
      <PatientAppointmentsTable />
    </Block>
  );
}

export default PatientAppointmentsView;
