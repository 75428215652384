import React, { forwardRef, useEffect, useRef, useState } from "react";

import DatePicker, { registerLocale } from "react-datepicker";
import pl from "date-fns/locale/pl";
import { ChevronDownIcon } from "src/UI/Svg";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { eachMonthOfInterval, format, getMonth, getYear } from "date-fns";
import { range } from "lodash";
import { getFNSLocaleByLang } from "src/utils";
import { DateInputPropsRHF } from "../types";
import {
  DropdownStyled,
  ErrorMessage,
  HeadingWrapStyled,
  IconStyled,
  InputBlock,
  InputStyled,
  Label,
} from "./styled";

interface CustomHeaderProps {
  date: Date;
  changeYear: (newYear: number) => void;
  changeMonth: (newMonth: number) => void;
}

registerLocale("pl", pl);

const CustomInput = forwardRef(
  ({ value, onClick, placeholder }: any, ref: any) => (
    <InputStyled isFocused={!!value} onClick={onClick} ref={ref}>
      {value || placeholder || ""}
    </InputStyled>
  )
);

function DateInput({
  name,
  label,
  value,
  placeholder,
  className = "",
  errorMessage,
  showTimeInput = false,
  isMonthAndYear = false,
  startYear = 1920,
}: DateInputPropsRHF) {
  const { t, i18n } = useTranslation();
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const years = range(startYear, getYear(new Date()) + 1, 1);

  const allMonths = eachMonthOfInterval({
    start: new Date(2023, 0, 1),
    end: new Date(2023, 11, 31),
  });
  const formattedMonths = allMonths.map((month) =>
    format(month, "MMMM", { locale: getFNSLocaleByLang(i18n.resolvedLanguage) })
  );

  const wrapper = useRef<HTMLDivElement | null>(null);

  const { setValue, trigger } = useFormContext();

  useEffect(() => {
    if (!value) return;
    trigger(name);
  }, [value]);

  useEffect(() => {
    if (!isFocused && value) {
      setIsFocused(!!value);
    }
  }, [value, isFocused]);

  const handleChange = (val: Date) => {
    setValue(name, val);
  };

  const handleChangeYear = (
    yearValue: number,
    changeYear: (newYear: number) => void,
    date: Date
  ) => {
    const newYear = Number(yearValue);
    changeYear(newYear);
    handleChange(new Date(newYear, getMonth(date), date.getDate()));
  };

  const handleChangeMonth = (
    monthValue: string,
    changeMonth: (newMonth: number) => void,
    date: Date
  ) => {
    const newMonth = formattedMonths.indexOf(monthValue);
    changeMonth(newMonth);
    handleChange(new Date(getYear(date), newMonth, date.getDate()));
  };

  return (
    <InputBlock
      ref={wrapper}
      onClick={() => setIsFocused(false)}
      className={className}
    >
      {!(!isFocused && placeholder) && !!label && (
        <Label isFocused={isFocused}>{label}</Label>
      )}
      <DatePicker
        selected={value}
        {...(isMonthAndYear && {
          renderCustomHeader: ({
            date,
            changeYear,
            changeMonth,
          }: CustomHeaderProps) => (
            <HeadingWrapStyled>
              <DropdownStyled
                value={getYear(date)}
                options={years.map((year) => ({ item: year, value: year }))}
                onSelect={(yearValue) =>
                  handleChangeYear(yearValue, changeYear, date)
                }
                label=""
                placeholder=""
              />
              <DropdownStyled
                value={formattedMonths[getMonth(date)]}
                options={formattedMonths.map((year) => ({
                  item: year,
                  value: year,
                }))}
                onSelect={(monthValue) =>
                  handleChangeMonth(monthValue, changeMonth, date)
                }
                label=""
                placeholder=""
              />
            </HeadingWrapStyled>
          ),
        })}
        onChange={(date) => handleChange(date as Date)}
        placeholderText={placeholder}
        customInput={<CustomInput />}
        dateFormat="dd MMM yyyy"
        locale={i18n.resolvedLanguage}
        showTimeInput={showTimeInput}
      />
      <IconStyled>
        <ChevronDownIcon size="16px" />
      </IconStyled>
      {errorMessage && (
        <ErrorMessage>
          {errorMessage?.message && t(errorMessage.message)}
        </ErrorMessage>
      )}
    </InputBlock>
  );
}

export default DateInput;
