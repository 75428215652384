import styled from "styled-components";
import { Button } from "src/UI/Button";
import { Dropdown } from "src/UI/Dropdown";

export const UserOptionStyled = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  gap: 4px;
  svg:first-child {
    margin-right: 6px;
    path {
      fill: ${({ theme }) => theme.colors.gray};
      stroke: ${({ theme }) => theme.colors.gray};
    }
  }
`;

export const Content = styled.div`
  padding: 20px;
  width: 635px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 20px;
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 16px;
    gap: 16px;
  }
`;

export const ActionBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
`;

export const ButtonStyled = styled(Button)`
  width: fit-content;
  font-size: 20px;
  line-height: 100%;
  padding: 15px 20px;
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 12px 16px;
    font-size: 16px;
  }
`;

export const Text = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.black};
  span {
    color: ${({ theme }) => theme.colors.gray};
  }
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 12px;
  }
`;

export const DropdownStyled = styled(Dropdown)`
  border-radius: 5px;
  min-width: 255px;
  & > div:first-child {
    padding: 8px;
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
  top: 100%;
  box-sizing: border-box;
  transition: all 0.25s ease-out;
  z-index: 100;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  border-radius: 5px;
  max-height: 300px;
  width: 100%;
  overflow-y: auto;
`;

export const MenuItemStyled = styled.div<{ isSelected: boolean }>`
  font-style: normal;
  padding: 12px 14px;
  font-weight: 500;
  min-width: max-content;
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 100%;
  position: relative;
  color: ${({ theme, isSelected }) =>
    theme.colors[isSelected ? "green" : "black"]};
  cursor: pointer;
  & label {
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.white};
    &:before {
      content: "";
      width: 14px;
      height: 14px;
      border-radius: 50%;
      position: absolute;
      background: ${({ theme }) => theme.colors.green};
      visibility: hidden;
    }
    & svg {
      display: none;
    }
  }
  & input[type="checkbox"]:checked ~ label:before {
    visibility: visible;
    transition: 0.3s;
  }
  & input[type="checkbox"]:checked ~ label {
    border-color: ${({ theme }) => theme.colors.green};
  }
  &:hover {
    background-color: #e7f6f5;
    color: ${({ theme }) => theme.colors.green};
    & label {
      border-color: ${({ theme }) => theme.colors.green};
    }
  }
`;

export const SubTitleStyled = styled.p`
  margin-top: -10px;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.darkGrey};
`;
