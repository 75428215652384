import { IEvent } from "src/pages/DashboardPages/CalendarPage/types";

import { format } from "date-fns";
import { Cabinet, Desc, Label, Title, Wrapper } from "./styles";
import EventDayDoctorComponent from "./EventDayDoctorComponent/EventDayDoctorComponent";

function EventDayView({ eventData, start, end }: IEvent) {
  return (
    <Wrapper>
      <Label>{`${format(start, "h:mm a")} - ${format(end, "h:mm a")} ${
        eventData?.patient?.firstName
      } ${eventData?.patient?.lastName}`}</Label>
      {!!eventData?.doctor && (
        <EventDayDoctorComponent doctor={eventData.doctor} />
      )}
      <Desc>
        {/* <SubTitle>{t("history.patient")}</SubTitle>
        <Title>
          {`${eventData?.patient?.firstName} ${eventData?.patient?.lastName}`}
        </Title> */}
        <Title>{eventData?.service?.name}</Title>
        {eventData?.room && <Cabinet>{eventData.room.name}</Cabinet>}
      </Desc>
    </Wrapper>
  );
}

export default EventDayView;
