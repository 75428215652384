import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.background};
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  gap: 0;
  flex-wrap: wrap;
  border-radius: 0 0 10px 10px;
`;
