import { gql } from "@apollo/client";

export const GET_CURRENT_CLIENT = gql`
  query GetCurrentClient {
    getCurrentClient {
      countryId
      createdAt
      id
      passportNumber
      phoneNumber
      promoCode
      userId
    }
  }
`;
