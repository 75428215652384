import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Modal } from "src/UI/Modal";
import {
  DoctorTypeEntityResponse,
  LanguageIsoCodes,
  useFindManyDoctorTypesByLanguageIsoCodeLazyQuery,
} from "src/graphql/generated";
import { useAppAction, useAppState } from "src/store/app/hooks";
import { EModals } from "src/store/app/types";
import { createDoctorInvitionSchema } from "src/validation/schemas/createDoctorInvitionSchema";
import { InputRHF, PhoneInputSmallRHF } from "src/UI/Input";
import DropdownInputRHF from "src/UI/DropdownInput/DropdownInputRHF";
import { EButtonVariants } from "src/UI/Button";
import { LangSelectComponent } from "src/components/LangSelectComponets";
import { Loading } from "src/UI/Loading";
import { toast } from "react-toastify";
import { FIND_AND_COUNT_MANY_DOCTORS_BY_CLINIC_ID } from "src/graphql/queries";
import { useCreateDoctorInvitation } from "src/graphql/hooks/useMutations";
import { Content, FooterButton, Form, InputsRow } from "./styles";

export interface FormValues {
  description?: string | null;
  doctorTypeId: string;
  email: string;
  firstName: string;
  clinicId: string;
  languageISOCode: string;
  lastName: string;
  passportNumber?: string | null;
  phoneNumber: string;
  medicalCertificateNumber: string;
  promoCode?: string | null;
}

export interface OptionsType {
  value: string;
  item: string;
}

function CreateDoctorInvitationModal() {
  const { t, i18n } = useTranslation();
  const { onCloseModal } = useAppAction();
  const { modals } = useAppState();

  const [clinicsList, setClinicsList] = useState<OptionsType[]>([]);
  const [specialisationsList, setSpecialisationsList] = useState<OptionsType[]>(
    []
  );

  const [
    findDoctorTypesByLocaleLazyQuery,
    { data: doctorTypesData, loading: doctorTypesLoading },
  ] = useFindManyDoctorTypesByLanguageIsoCodeLazyQuery();

  const [createDoctorInvitation] = useCreateDoctorInvitation();

  const methods = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(createDoctorInvitionSchema),
    defaultValues: {
      doctorTypeId: "",
      email: "",
      firstName: "",
      clinicId: "",
      languageISOCode: "",
      lastName: "",
      phoneNumber: "",
      medicalCertificateNumber: "",
      passportNumber: null,
      description: null,
      promoCode: null,
    },
  });

  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
    reset,
    setValue,
  } = methods;

  const data = watch();

  useEffect(() => {
    if (!i18n || !modals[EModals.CreateDoctorInvition].active) return;
    findDoctorTypesByLocaleLazyQuery({
      variables: {
        schema: {
          languageISOCode: i18n.language.toUpperCase() as LanguageIsoCodes,
        },
      },
      fetchPolicy: "network-only",
    });
  }, [i18n.language, modals[EModals.CreateDoctorInvition].active]);

  useEffect(() => {
    if (!modals[EModals.CreateDoctorInvition]?.data?.clinics?.length) return;
    const clinicsData = modals[
      EModals.CreateDoctorInvition
    ]?.data?.clinics?.map((clinic) => ({
      value: clinic.id,
      item: clinic.name,
    }));
    setClinicsList(clinicsData);
  }, [modals[EModals.CreateDoctorInvition]?.data?.clinics]);

  useEffect(() => {
    if (!doctorTypesData) return;
    const specialisationsData =
      doctorTypesData?.findManyDoctorTypesByLanguageISOCode?.doctorTypes?.map(
        (type: DoctorTypeEntityResponse) => ({
          value: type.doctorTypeTranslations[0].doctorTypeId,
          item: type.doctorTypeTranslations[0].name,
        })
      );

    setSpecialisationsList(specialisationsData as OptionsType[]);
  }, [doctorTypesData]);

  useEffect(() => {
    if (!i18n || !modals[EModals.CreateDoctorInvition].active) return;
    setValue("languageISOCode", i18n?.resolvedLanguage?.toUpperCase());
  }, [i18n.language, modals[EModals.CreateDoctorInvition].active]);

  const closeModal = useCallback(() => {
    onCloseModal(EModals.CreateDoctorInvition);
  }, [onCloseModal]);

  const createDoctorInvationHandler = (formData: FormValues) => {
    createDoctorInvitation({
      variables: {
        schema: { ...formData, phoneNumber: `+${formData.phoneNumber}` },
      },
      onCompleted() {
        toast.success(t("app.successful"));
        reset();
        closeModal();
      },
      refetchQueries: [FIND_AND_COUNT_MANY_DOCTORS_BY_CLINIC_ID],
      awaitRefetchQueries: true,
    });
  };

  if (!modals[EModals.CreateDoctorInvition].active) return null;
  return (
    <Modal
      modal={EModals.CreateDoctorInvition}
      title={t("invite_doctor.title")}
      isCreateDoctorInvitation={true}
      titleChildren={<LangSelectComponent />}
      onCloseAction={reset}
    >
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(createDoctorInvationHandler)}>
          <Content>
            <InputsRow>
              <DropdownInputRHF
                name="doctorTypeId"
                value={data.doctorTypeId}
                placeholder=""
                options={specialisationsList}
                label={t("add_doctor.label.specialisation" as string)}
                adaptedForMobile={false}
                errorMessage={errors.doctorTypeId}
              />
              <DropdownInputRHF
                name="clinicId"
                value={data.clinicId}
                placeholder=""
                options={clinicsList}
                label={t("add_doctor.label.clinic_id" as string)}
                adaptedForMobile={false}
                errorMessage={errors.clinicId}
              />
            </InputsRow>
            <InputsRow>
              <InputRHF
                val={data.email}
                label={t("add_doctor.label.email") as string}
                register={register("email")}
                inputType="text"
                errorMessage={errors.email}
              />
              <InputRHF
                val={data.firstName}
                label={t("add_doctor.label.name") as string}
                register={register("firstName")}
                inputType="text"
                errorMessage={errors.firstName}
              />
            </InputsRow>
            <InputsRow>
              <InputRHF
                val={data.lastName}
                label={t("add_doctor.label.surname") as string}
                register={register("lastName")}
                inputType="text"
                errorMessage={errors.lastName}
              />
              <PhoneInputSmallRHF
                label={t("add_doctor.label.phone") || ""}
                value={data.phoneNumber}
                name="phoneNumber"
              />
            </InputsRow>
            <InputsRow>
              <InputRHF
                val={data.passportNumber as string}
                label={t("add_doctor.label.passport") as string}
                register={register("passportNumber")}
                inputType="text"
                errorMessage={errors.passportNumber}
              />
              <InputRHF
                val={data.medicalCertificateNumber}
                label={t("add_doctor.label.certificate") as string}
                register={register("medicalCertificateNumber")}
                inputType="text"
                errorMessage={errors.medicalCertificateNumber}
              />
            </InputsRow>

            <FooterButton
              variant={EButtonVariants.Default}
              disabled={doctorTypesLoading}
            >
              {doctorTypesLoading ? (
                <Loading type="secondary" padding="0" />
              ) : null}
              {t("invite_doctor.send") as string}
            </FooterButton>
          </Content>
        </Form>
      </FormProvider>
    </Modal>
  );
}

export default CreateDoctorInvitationModal;
