import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LangSelectComponent } from "src/components/LangSelectComponets";
import { HeaderCover } from "src/UI/Header";
import { ChevronIcon, LogoFullIcon } from "src/UI/Svg";
import { Text } from "src/UI/Text";
import { Heading } from "src/UI/Heading";
import { InputComponent } from "src/components/AuthComponents";
import { EButtonVariants } from "src/UI/Button";
import { useRequestPasswordRestorage } from "src/graphql/hooks/useMutations";
import { toast } from "react-toastify";
import { Loading } from "src/UI/Loading";
import {
  Breadcrumb,
  ButtonStyled,
  FormFA,
  FormWrapper,
  TitleBlock,
  WrapperNav,
} from "../styled";

interface FormValues {
  email: string;
}

function RestorePassword() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [reqPassRestorage, { loading }] = useRequestPasswordRestorage();

  const {
    register,
    formState: { errors, isValid },
    watch,
    handleSubmit,
  } = useForm<FormValues>({
    mode: "onChange",
  });
  const data = watch();

  const formHandler = (formData: FormValues) => {
    reqPassRestorage({
      variables: {
        schema: {
          email: formData?.email,
          languageISOCode: i18n?.resolvedLanguage?.toUpperCase(),
        },
      },
      onCompleted() {
        navigate("/auth/check-email");
      },
      onError(error) {
        toast.error(error.message);
      },
    });
  };

  return (
    <>
      <WrapperNav>
        <LogoFullIcon />
      </WrapperNav>
      <FormWrapper>
        <HeaderCover>
          <Breadcrumb to="/auth/login">
            <ChevronIcon />
            <Text>{t("common.back")}</Text>
          </Breadcrumb>
          <LangSelectComponent />
        </HeaderCover>
        <FormFA onSubmit={handleSubmit(formHandler)}>
          <TitleBlock>
            <Heading as="h2">{t("restore.password.title")}</Heading>
          </TitleBlock>
          <InputComponent
            placeholder={t("auth.login.email")}
            inputType="email"
            val={data.email}
            errorMessage={errors.email}
            register={register("email", {
              required: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: t("auth.login.email_error"),
              },
            })}
          />
          {/* TODO temporary comment
          <BlockUnderInput>
              <ForgotLink to="/auth/restore/email">
                {t("restore.password.forgot")}
              </ForgotLink>
            </BlockUnderInput>
          </InputComponent> */}
          <ButtonStyled
            variant={EButtonVariants.Default}
            disabled={!isValid || loading}
          >
            {loading ? <Loading type="secondary" padding="0" /> : null}
            {t("restore.password.submit")}
          </ButtonStyled>
        </FormFA>
      </FormWrapper>
    </>
  );
}

export default RestorePassword;
