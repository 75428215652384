import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputRHF } from "src/UI/Input";
import { FieldError, useFormContext } from "react-hook-form";
import { FormFieldTypes } from "src/graphql/generated";
import { CreateEditFormValues, IFormComponent } from "src/types/CreateEditForm";
import { ESupportedLangs } from "src/types/enum";
import FormComponentCheckboxNew from "./FormComponentCheckboxNew";
import FormComponentCheckboxItem from "./FormComponentCheckboxItem";

function FormComponent({ type, order, isHideDeleteButton }: IFormComponent) {
  const { t, i18n } = useTranslation();

  const [optionalTranslation, setOptionalTranslation] = useState<string | null>(
    null
  );

  const {
    watch,
    register,
    trigger,
    formState: { errors },
  } = useFormContext<CreateEditFormValues>();

  const fields = watch("fields");

  useEffect(() => {
    if (i18n.resolvedLanguage === ESupportedLangs.en) {
      setOptionalTranslation(ESupportedLangs.pl);
    } else {
      setOptionalTranslation(ESupportedLangs.en);
    }
  }, []);

  useEffect(() => {
    if (!Object.keys(errors).length) return;
    trigger();
  }, [
    fields[order].translations[1]?.name,
    fields[order].translations[0]?.placeholder,
    fields[order].translations[1]?.placeholder,
  ]);

  const valueCurrentLang = watch(`fields.${order}.translations.0.value`);
  const valueOptionalLang = watch(`fields.${order}.translations.1.value`);

  const titleTranslations = useMemo(() => {
    return {
      [FormFieldTypes.Text]: t("forms.modals.fields.text"),
      [FormFieldTypes.TextArea]: t("forms.modals.fields.text_area"),
      [FormFieldTypes.Email]: t("forms.modals.fields.email"),
      [FormFieldTypes.Phone]: t("forms.modals.fields.phone"),
      [FormFieldTypes.Number]: t("forms.modals.fields.number"),
      [FormFieldTypes.Date]: t("forms.modals.fields.date"),
      [FormFieldTypes.Time]: t("forms.modals.fields.time"),
      [FormFieldTypes.Checkbox]: t("forms.modals.checkbox.title"),
    };
  }, [t]);

  const optionalTitleTranslation = useMemo(() => {
    return {
      [FormFieldTypes.Text]: t("forms.modals.fields.text", {
        lng: optionalTranslation as string,
      }),
      [FormFieldTypes.TextArea]: t("forms.modals.fields.text_area", {
        lng: optionalTranslation as string,
      }),
      [FormFieldTypes.Email]: t("forms.modals.fields.email", {
        lng: optionalTranslation as string,
      }),
      [FormFieldTypes.Phone]: t("forms.modals.fields.phone", {
        lng: optionalTranslation as string,
      }),
      [FormFieldTypes.Number]: t("forms.modals.fields.number", {
        lng: optionalTranslation as string,
      }),
      [FormFieldTypes.Date]: t("forms.modals.fields.date", {
        lng: optionalTranslation as string,
      }),
      [FormFieldTypes.Time]: t("forms.modals.fields.time", {
        lng: optionalTranslation as string,
      }),
      [FormFieldTypes.Checkbox]: t("forms.modals.checkbox.title", {
        lng: optionalTranslation as string,
      }),
    };
  }, [optionalTranslation]);

  return (
    <>
      <InputRHF
        val={fields[order]?.translations[0].name || ""}
        label={titleTranslations[type] as string}
        inputType="text"
        errorMessage={
          errors?.fields?.[order]?.translations?.[0]?.name as FieldError
        }
        register={register(`fields.${order}.translations.0.name`)}
      />

      {!(type === FormFieldTypes.Checkbox) ? (
        <InputRHF
          val={fields[order]?.translations[0].placeholder || ""}
          label={t("forms.modals.fields.placeholder") as string}
          inputType="text"
          errorMessage={
            errors?.fields?.[order]?.translations?.[0]
              ?.placeholder as FieldError
          }
          register={register(`fields.${order}.translations.0.placeholder`)}
        />
      ) : null}

      {type === FormFieldTypes.Checkbox && (
        <>
          {valueCurrentLang?.map((val: any) => (
            <FormComponentCheckboxItem
              key={val.order}
              value={val}
              order={order}
              translationsIndex={0}
              translation={i18n.resolvedLanguage}
              isHideDeleteButton={isHideDeleteButton}
            />
          ))}

          <FormComponentCheckboxNew
            order={order}
            translation={i18n.resolvedLanguage}
          />
        </>
      )}

      <InputRHF
        val={fields[order]?.translations[1]?.name || ""}
        label={optionalTitleTranslation[type] as string}
        inputType="text"
        errorMessage={
          errors?.fields?.[order]?.translations?.[1]?.name as FieldError
        }
        register={register(`fields.${order}.translations.1.name`)}
      />
      {!(type === FormFieldTypes.Checkbox) ? (
        <InputRHF
          val={fields[order]?.translations[1]?.placeholder || ""}
          label={
            t("forms.modals.fields.placeholder", {
              lng: optionalTranslation as string,
            }) as string
          }
          inputType="text"
          errorMessage={
            errors?.fields?.[order]?.translations?.[1]
              ?.placeholder as FieldError
          }
          register={register(`fields.${order}.translations.1.placeholder`)}
        />
      ) : null}

      {type === FormFieldTypes.Checkbox && (
        <>
          {valueOptionalLang?.map((val: any) => (
            <FormComponentCheckboxItem
              key={val.order}
              value={val}
              order={order}
              translationsIndex={1}
              translation={optionalTranslation || ""}
              isHideDeleteButton={isHideDeleteButton}
            />
          ))}

          <FormComponentCheckboxNew
            order={order}
            translation={optionalTranslation || ""}
          />
        </>
      )}
    </>
  );
}

export default FormComponent;
