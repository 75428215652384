import {
  OperationVariables,
  QueryHookOptions,
  useLazyQuery,
  useQuery,
} from "@apollo/client";

import { toast } from "react-toastify";
import * as QUERIES from "../../queries";

type TOptions = QueryHookOptions<any, OperationVariables>;

export const useFindAllCountries = () => {
  return useQuery(QUERIES.GET_ALL_COUNTRIES);
};

export const useGetCurrentUser = (options?: TOptions) => {
  return useQuery(QUERIES.GET_CURRENT_USER, options);
};

export const useGetCurrentClient = () => {
  return useLazyQuery(QUERIES.GET_CURRENT_CLIENT);
};

export const useGetCurrentDoctor = () => {
  return useLazyQuery(QUERIES.GET_CURRENT_DOCTOR);
};

export const useCheckIfPinCodeExistsByUserId = (options?: TOptions) => {
  return useQuery(QUERIES.CHECK_IS_EXISTS_PIN, options);
};

export const useCheckIfCompanyExistsByOwnerId = (options?: TOptions) => {
  return useQuery(QUERIES.CHECK_IS_EXISTS_COMPANY, options);
};

export const useFindAllServiceCategories = (
  options?: QueryHookOptions<any, OperationVariables>
) => {
  return useQuery(QUERIES.FIND_MANY_SERVICE_CATEGORIES_BY_CLINIC_ID, options);
};

export const useFindAndCountManyClinics = (options?: TOptions) => {
  return useQuery(QUERIES.GET_COUNT_CLINICS, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useFindCompanyByUserId = (options?: TOptions) => {
  return useQuery(QUERIES.GET_COMPANY_BY_USER_ID, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useFindManyFilesByIds = (options?: TOptions) => {
  return useQuery(QUERIES.FIND_FILES_BY_IDS, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useFindManyFilesByIdsLazy = (options?: TOptions) => {
  return useLazyQuery(QUERIES.FIND_FILES_BY_IDS, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useFindClinicById = (options?: TOptions) => {
  return useQuery(QUERIES.GET_FIND_CLINIC_BY_ID, options);
};

export const useFindManyServices = (options?: TOptions) => {
  return useQuery(QUERIES.GET_FIND_MANY_SERVICES, options);
};

export const useFindServiceCategoryById = (options?: TOptions) => {
  return useQuery(QUERIES.GET_FIND_SERVICE_CATEGORY_BY_ID, options);
};

export const useFindManyCurrencies = (options?: TOptions) => {
  return useQuery(QUERIES.GET_FIND_MANY_CURRENCIES, options);
};

export const useFindManyDoctorTypesByLanguageISOCode = (options?: TOptions) => {
  return useQuery(QUERIES.GET_FIND_MANY_DOCTOR_TYPES_BY_LANGUAGE_ISO_CODE, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useFindManyClinicRoomsByClinicId = (options?: TOptions) => {
  return useQuery(QUERIES.GET_FIND_MANY_CLINIC_ROOMS_BY_CLINIC_ID, options);
};

export const useFindAndCountManyDoctors = (options?: TOptions) => {
  return useQuery(QUERIES.FIND_AND_COUNT_MANY_DOCTORS, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useFindAndCountManyDoctorsByClinicId = (options?: TOptions) => {
  return useQuery(QUERIES.FIND_AND_COUNT_MANY_DOCTORS_BY_CLINIC_ID, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useFindAndCountManyPatientsByClient = (options?: TOptions) => {
  return useQuery(QUERIES.GET_FIND_AND_COUNT_MANY_PATIENTS_BY_CLIENT, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useFindAndCountManyPatientsByDoctor = (options?: TOptions) => {
  return useQuery(QUERIES.GET_FIND_AND_COUNT_MANY_PATIENTS_BY_DOCTOR, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useFindPatientById = (options?: TOptions) => {
  return useQuery(QUERIES.FIND_PATIENT_BY_ID, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useFindManyAvailableSlots = (options?: TOptions) => {
  return useQuery(QUERIES.GET_FIND_MANY_AVAILABLE_SLOTS, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useFindManySchedulesByClient = (options?: TOptions) => {
  return useQuery(QUERIES.GET_FIND_SCHEDULES_BY_CLIENT, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useFindManySchedulesByDoctor = (options?: TOptions) => {
  return useQuery(QUERIES.GET_FIND_SCHEDULES_BY_DOCTOR, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useFindAndCountManyScheduleAppointmentsWithRelationsByClient = (
  options?: TOptions
) => {
  return useQuery(
    QUERIES.FIND_AND_COUNT_MANY_APPOIINTMENTS_WITH_RELATIONS_BY_CLIENT,
    {
      ...options,
      onError: (error) => {
        if (error) toast.error(error.message);
      },
    }
  );
};

export const useFindAndCountManyScheduleAppointmentsWithRelationsByDoctor = (
  options?: TOptions
) => {
  return useQuery(
    QUERIES.FIND_AND_COUNT_MANY_APPOIINTMENTS_WITH_RELATIONS_BY_DOCTOR,
    {
      ...options,
      onError: (error) => {
        if (error) toast.error(error.message);
      },
    }
  );
};

export const useFindManyDoctorClinicsByDoctorId = (options?: TOptions) => {
  return useQuery(QUERIES.GET_FIND_DOCTOR_CLINICS_BY_DOCTOR_ID, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useFindAndCountManyClinicsByClientId = (options?: TOptions) => {
  return useQuery(QUERIES.GET_FIND_MANY_CLINICS_BY_CLIENT_ID, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useFindBlankFormByServiceId = (options?: TOptions) => {
  return useQuery(QUERIES.FIND_BLANK_FORM_BY_SERVICE_ID, {
    ...options,
  });
};

export const useFindManyAggregatedDoctorsByServiceId = (options?: TOptions) => {
  return useQuery(QUERIES.GET_FIND_MANY_AGGREGATED_DOCTORS_BY_SERVICE_ID, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useCountScheduleAppointmentsByStatusesByClient = (
  options?: TOptions
) => {
  return useQuery(QUERIES.COUNT_SCHEDULE_APPOINTMENTS_BY_STATUSES_BY_CLIENT, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useCountScheduleAppointmentsByStatusesByDoctor = (
  options?: TOptions
) => {
  return useQuery(QUERIES.COUNT_SCHEDULE_APPOINTMENTS_BY_STATUSES_BY_DOCTOR, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useFindManyScheduleAppointmentUpdatesByIdByClient = (
  options?: TOptions
) => {
  return useQuery(
    QUERIES.FIND_MANY_SCHEDULE_APPOINTMENT_UPDATES_BY_ID_BY_CLIENT,
    {
      ...options,
      onError: (error) => {
        if (error) toast.error(error.message);
      },
    }
  );
};

export const useFindDoctorById = (options?: TOptions) => {
  return useQuery(QUERIES.GET_FIND_DOCTOR_BY_ID, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useFindScheduleAppointmentWithRelationsByHashByPatient = (
  options?: TOptions
) => {
  return useQuery(
    QUERIES.FIND_SCHEDULE_APPOINTMENT_WITH_RELATIONS_BY_HASH_BY_PATIENT,
    {
      ...options,
      onError: (error) => {
        if (error) toast.error(error.message);
      },
    }
  );
};

export const useScheduleAppointmentConfirmedByPatient = (
  options?: TOptions
) => {
  return useQuery(QUERIES.GET_SCHEDULE_APPOINTMENT_CONFIRMED_PY_PATIENT, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};

export const useFindAndCountManyScheduleWaitlistsByClient = (
  options?: TOptions
) => {
  return useQuery(QUERIES.FIND_AND_COUNT_MANY_SCHEDULE_WAITLISTS_BY_CLIENT, {
    ...options,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });
};
