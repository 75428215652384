import styled from "styled-components";

export const StyledTable = styled.table`
  width: 100%;
  border-spacing: 20px;

  th {
    padding: 20px 0 5px 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.grayC4};
    text-align: left;
  }

  td {
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black};
    text-align: left;
  }
`;
