import AvatarComponent from "src/components/AvatarComponent/AvatarComponent";
import styled from "styled-components";

export const Wrap = styled.div`
  width: fit-content;
  min-width: 449px;
  display: flex;
  flex-direction: column;
  background: #f8f8f8;
  padding: 20px;
  border-radius: 10px;
  gap: 20px;
`;
export const WrapTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const WrapBottom = styled.div`
  width: 100%;
  display: flex;
`;

export const WrapBottomBox = styled.div`
  &:not(:last-of-type) {
    margin-right: 20px;
  }
  &:last-of-type {
    margin-left: auto;
  }
`;

export const WrapBottomBoxTitle = styled.div`
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  text-align: left;
  color: ${({ theme }) => theme.colors.gray};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
`;

export const WrapBottomBoxContent = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
`;

export const DoctorOptInfoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: 15px;
`;

export const AvatarComponentStyled = styled(AvatarComponent)`
  background: ${({ theme }) => theme.colors.white} !important;
`;

export const OptItemTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  text-overflow: ellipsis;
`;

export const OptItemSubTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: ${({ theme }) => theme.colors.gray};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const DotSeparator = styled.div`
  width: fit-content;
  margin: 0 10px;
  display: flex;
  align-items: center;

  &:before {
    content: "";
    width: 5px;
    height: 5px;
    background-color: ${({ theme }) => theme.colors.gray};
    border-radius: 50%;
  }
`;
