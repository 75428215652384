import React from "react";
import { useFormContext } from "react-hook-form";
import { CheckIcon } from "src/UI/Svg";
import { CheckBoxRHFProps } from "../types";

import { CheckBoxStyled, Input, Label, Text } from "./styled";

function CheckBoxRHF({ id, label, name, disabled }: CheckBoxRHFProps) {
  const { register, watch } = useFormContext();
  const isChecked = watch(name);
  return (
    <CheckBoxStyled disabled={disabled}>
      <Input {...register(name)} name={name} type="checkbox" id={id} />
      <Label htmlFor={id} isChecked={isChecked}>
        <CheckIcon width={14} height={14} />
      </Label>
      {label && <Text htmlFor={id}>{label}</Text>}
    </CheckBoxStyled>
  );
}

export default CheckBoxRHF;
