import styled from "styled-components";

export const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;
  gap: 8px;
`;

export const Item = styled.div<{ bgColor?: string }>`
  width: 100%;
  display: flex;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
  color: ${({ theme, bgColor }) => (bgColor ? bgColor : theme.colors.darkGrey)};
  cursor: pointer;

  span {
    width: 8px;
    min-width: 8px;
    height: 8px;
    display: flex;
    border-radius: 50%;
    background: ${({ theme, bgColor }) =>
      bgColor ? bgColor : theme.colors.darkGrey};
  }
`;

export const User = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.black};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MoreBlock = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.gray};
  margin-top: 5px;
  cursor: pointer;
`;
