import React from "react";
import { useTranslation } from "react-i18next";
import {
  ScheduleAppointmentEntityWithRelationsResponse,
  ScheduleAppointmentUpdateEntityResponse,
} from "src/graphql/generated";
import { UpdatesItem, UpdatesItemText, UpdatesItemTitle } from "./styled";

interface IUpdatesItem {
  update: ScheduleAppointmentUpdateEntityResponse;
}

type ScheduleAppointmentEntityWithRelationsResponseWithKey =
  ScheduleAppointmentEntityWithRelationsResponse & {
    [key: string]: any;
  };

function UpdateItem({ update }: IUpdatesItem) {
  const { t } = useTranslation();
  function differenceBetweenObjects(
    original: ScheduleAppointmentEntityWithRelationsResponseWithKey,
    updated: ScheduleAppointmentEntityWithRelationsResponseWithKey
  ) {
    const diff: { status?: string; [key: string]: any } = {};
    const keys = Object.keys(original);
    keys.forEach((key: string) => {
      if (original[key] !== updated[key]) {
        diff[key] = updated[key];
      }
    });
    if (!Object.keys(diff).length) {
      return { status: "ConfirmedByApplication" };
    }
    return diff;
  }

  return (
    <UpdatesItem>
      <UpdatesItemTitle>{`${t("booking.schedule_appointment.updated_status")} ${
        differenceBetweenObjects(update.original, update.updated).status
      }`}</UpdatesItemTitle>
      <UpdatesItemText>
        {t("booking.schedule_appointment.updated_status_message")}
      </UpdatesItemText>
    </UpdatesItem>
  );
}

export default UpdateItem;
