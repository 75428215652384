import omit from "lodash/omit";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect, useMemo } from "react";
import { Modal } from "src/UI/Modal";
import { SvgProps } from "src/UI/Svg";
import { useAppAction, useAppState } from "src/store/app/hooks";
import { EModals } from "src/store/app/types";
import { LangFlags } from "src/constants/constants";
import FormItemComponent from "src/components/FormsComponents/FormItemComponent/FormItemComponent";
import { FormFieldEntityWithRelationsResponse } from "src/graphql/generated";
import { CreateEditFormValues } from "src/types/CreateEditForm";
import { FormsList } from "./styled";

function PreviewFormModal() {
  const { i18n } = useTranslation();
  const { modals } = useAppState();
  const { onCloseModal, onShowCreateEditForm } = useAppAction();
  const methods = useForm<CreateEditFormValues>({});

  const { reset, watch } = methods;

  useEffect(() => {
    if (modals[EModals.PreviewForm].data) {
      reset(modals[EModals.PreviewForm].data);
    }
  }, [modals[EModals.PreviewForm].data]);

  const [fields, name] = watch(["fields", "name"]);

  const Flag = useMemo(() => {
    const flag = LangFlags.find((i) => i.code === i18n.resolvedLanguage);
    return flag ? flag.flag : null;
  }, []) as React.FunctionComponent<SvgProps>;

  const handleCloseModal = () => {
    onCloseModal(EModals.PreviewForm);

    if (modals[EModals.PreviewForm].data?.isShowCreateEditModalAfterClose) {
      onShowCreateEditForm({
        isEditForm: modals[EModals.PreviewForm].data.isEditForm,
        formValue: omit(modals[EModals.PreviewForm].data, "defaultServiceId"),
        defaultServiceId: modals[EModals.PreviewForm].data.defaultServiceId,
      });
    }
  };

  if (!modals[EModals.PreviewForm].active || !fields) return null;

  return (
    <Modal
      onCloseAction={handleCloseModal}
      modal={EModals.CreateEditForm}
      title={name}
      titleChildren={<Flag />}
    >
      <FormProvider {...methods}>
        <FormsList>
          {fields.map((field) => (
            <FormItemComponent
              isPreview
              key={field.id}
              field={field as FormFieldEntityWithRelationsResponse}
            />
          ))}
        </FormsList>
      </FormProvider>
    </Modal>
  );
}

export default PreviewFormModal;
