import { useTranslation } from "react-i18next";
import { getMonth, getYear } from "date-fns";
import { FormProvider, useForm } from "react-hook-form";
import {
  AggregatedDoctorEntityResponse,
  AggregatedScheduleAppointmentEntityWithRelationsByClientResponse,
  AggregatedScheduleWaitlistEntityByClientResponse,
  ClinicEntityResponse,
  ClinicSortFields,
  LanguageIsoCodes,
  ServiceEntityWithRelationsResponse,
  ServiceSortFields,
  SlotsGroupedByDateResponse,
  SortingOrders,
  useAvailableSlotsLazyQuery,
  useFindAndCountManyClinicsLazyQuery,
  useFindManyDoctorTypesByLanguageIsoCodeLazyQuery,
  useFindManyServicesLazyQuery,
  useFindPatientByIdLazyQuery,
} from "src/graphql/generated";
import { useEffect, useMemo, useState } from "react";
import {
  useFindAndCountManyDoctorsByClinicId,
  useFindManyFilesByIds,
} from "src/graphql/hooks/useQueries";
import { useClinicsAction, useClinicsState } from "src/store/clinics/hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { createVisitSchema } from "src/validation/schemas/createVisitSchema";
import { toast } from "react-toastify";
import {
  useCloseScheduleWaitlistByClient,
  useCreateScheduleWaitlistByClient,
  useCreateVisitByClient,
  useCreateVisitByDoctor,
  useRescheduleAppointmentByClient,
} from "src/graphql/hooks/useMutations";
import { useAuthContext } from "src/context/AuthContext";
import {
  COUNT_SCHEDULE_APPOINTMENTS_BY_STATUSES_BY_CLIENT,
  COUNT_SCHEDULE_APPOINTMENTS_BY_STATUSES_BY_DOCTOR,
  FIND_AND_COUNT_MANY_APPOIINTMENTS_WITH_RELATIONS_BY_CLIENT,
  FIND_AND_COUNT_MANY_APPOIINTMENTS_WITH_RELATIONS_BY_DOCTOR,
  FIND_AND_COUNT_MANY_SCHEDULE_WAITLISTS_BY_CLIENT,
  FIND_MANY_SCHEDULE_APPOINTMENT_UPDATES_BY_ID_BY_CLIENT,
  GET_FIND_MANY_AVAILABLE_SLOTS,
} from "src/graphql/queries";
import { useBookingAction, useBookingState } from "src/store/booking/hooks";
import { transformBeforeReschedule } from "src/connectors/Visits/transformBeforeReschedule";
import { Divider } from "src/UI/Divider";
import { theme } from "src/theme";
import { createWaitListSchema } from "src/validation/schemas/createWaitListSchema";
import { useLocation } from "react-router-dom";
import { transformBeforeConvertWaitingAppointment } from "src/connectors/Visits/transformBeforeConvertWaitingAppointment";
import { usePatientsAction } from "src/store/patients/hooks";
import { allClinicsItemId } from "src/constants/constants";
import { useGetPatientsLazyLoading } from "src/hooks/patients/useGetPatientsLazyLoading";
import { Form, PageWrapper, Tab, Tabs } from "./styles";
import DoctorOptComponent from "./DoctorOptComponent/DoctorOptComponent";
import PatientOptComponent from "./PatientOptComponent/PatientOptComponent";
import ClinicOptComponent from "./ClinicOptComponent/ClinicOptComponent";
import LeftSideComponent from "./LeftSideComponent/LeftSideComponent";
import RightSideComponent from "./RightSideComponent/RightSideComponent";
import { EViewsTypes, FormValues } from "./types";

type TDoctorEntity = AggregatedDoctorEntityResponse;
type TClinicEntity = ClinicEntityResponse;
type TServiceEntity = ServiceEntityWithRelationsResponse;

const AddVisitPage = () => {
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const viewTypeParam = queryParams.get("viewType");

  const { currentClinicId } = useClinicsState();
  const { onSetClinicId } = useClinicsAction();

  const { rescheduleAppointment, rescheduleWaitlist } = useBookingState();
  const { onSetRescheduleAppointment, onSetRescheduleWaitlist } =
    useBookingAction();
  const { onSetPatient } = usePatientsAction();

  const [clinics, setClinics] = useState<TClinicEntity[]>([]);
  const [doctors, setDoctors] = useState<TDoctorEntity[]>([]);
  const [services, setServices] = useState<TServiceEntity[]>([]);
  const [slots, setSlots] = useState<SlotsGroupedByDateResponse[]>([]);
  const [dateFilter, setDateFilter] = useState(new Date());
  const [filterView, setFilterView] = useState<EViewsTypes>(
    !viewTypeParam ? EViewsTypes.Booking : EViewsTypes.WaitList
  );

  const { currentUserData, isDoctor, isClient } = useAuthContext();

  const [getPatient] = useFindPatientByIdLazyQuery();

  const [getServices] = useFindManyServicesLazyQuery();

  const { patientsList, searchPatient, fetchMore, setSearchPatient } =
    useGetPatientsLazyLoading({});

  const [createVisitByClient, { loading: createVisitByClientLoading }] =
    useCreateVisitByClient();
  const [createVisitByDoctor, { loading: createVisitByDoctorLoading }] =
    useCreateVisitByDoctor();
  const [createWaitList, { loading: createWaitListLoading }] =
    useCreateScheduleWaitlistByClient();
  const [closeScheduleWaitlist, { loading: closeScheduleWaitlistLoading }] =
    useCloseScheduleWaitlistByClient();

  const [
    resecheduleAppointmentMutation,
    { loading: rescheduleAppointmentLoading },
  ] = useRescheduleAppointmentByClient();

  const methods = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(
      filterView === EViewsTypes.Booking
        ? createVisitSchema
        : createWaitListSchema
    ),
    defaultValues: {
      doctorId: null,
      clinicId: null,
      serviceId: null,
      roomId: null,
      patientId: null,
      minuteFrom: null,
      minuteTo: null,
      dateFrom: null,
      dateTo: null,
      date: null,
    },
  });

  const {
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
    reset,
  } = methods;

  const dataForm = watch();

  const [getFindAndCountManyClinicsLazyQuery] =
    useFindAndCountManyClinicsLazyQuery();

  const [findDoctorTypesByLocaleLazyQuery, { data: doctorTypesData }] =
    useFindManyDoctorTypesByLanguageIsoCodeLazyQuery();

  const doctorsPhotoIds = doctors
    ?.map((doctor) => doctor?.avatarFileId)
    .filter((id) => id);

  const { data: logoFiles } = useFindManyFilesByIds({
    variables: {
      schema: {
        ids: doctorsPhotoIds,
      },
    },
    skip: !doctorsPhotoIds,
  });

  const [getSlots, { loading: availableSlotsLoading }] =
    useAvailableSlotsLazyQuery({
      variables: {
        schema: {
          doctorId: dataForm?.doctorId as string,
          serviceId: dataForm?.serviceId as string,
          year: getYear(dateFilter),
          month: getMonth(dateFilter),
        },
      },
      onCompleted: (response) => {
        setSlots(response?.findManyAvailableSlots?.availableSlots);
      },
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    if (dataForm?.doctorId && dataForm?.serviceId) {
      getSlots();
    }
  }, [dataForm?.doctorId, dataForm?.serviceId, dataForm?.clinicId]);

  useEffect(() => {
    if (!rescheduleWaitlist) return;
    reset(
      transformBeforeConvertWaitingAppointment(
        rescheduleWaitlist as AggregatedScheduleWaitlistEntityByClientResponse
      )
    );
    if (rescheduleWaitlist) {
      getPatient({
        variables: {
          schema: {
            id: rescheduleWaitlist?.patientId,
          },
        },
        onCompleted: (response) => {
          onSetPatient(response.findPatientById);
        },
      });
    }
    setDateFilter(new Date(rescheduleWaitlist?.dateFrom));
  }, [rescheduleWaitlist]);

  useEffect(() => {
    if (!rescheduleAppointment) return;
    reset(
      transformBeforeReschedule(
        rescheduleAppointment as AggregatedScheduleAppointmentEntityWithRelationsByClientResponse
      )
    );
    if (rescheduleAppointment) {
      getPatient({
        variables: {
          schema: {
            id: rescheduleAppointment?.patientId,
          },
        },
        onCompleted: (response) => {
          onSetPatient(response.findPatientById);
        },
      });
    }
    setDateFilter(new Date(rescheduleAppointment?.schedule.dateFrom));
  }, [rescheduleAppointment]);

  // Clinics
  useEffect(() => {
    getFindAndCountManyClinicsLazyQuery({
      variables: {
        schema: {
          sortOrder: SortingOrders.Descending,
          sortField: ClinicSortFields.CreatedAt,
        },
      },
      onCompleted: (response) => {
        setClinics(response?.findAndCountManyClinics?.clinics);
      },
    });
  }, []);

  // Services
  useEffect(() => {
    if (!dataForm.clinicId || !dataForm?.doctorId) return;
    getServices({
      variables: {
        schema: {
          clinicId: dataForm.clinicId,
          sortField: ServiceSortFields.CreatedAt,
          sortOrder: SortingOrders.Descending,
        },
      },
      onCompleted: (response) => {
        const selectedDoctorServices =
          response.findManyServices?.services?.filter((service) =>
            service?.doctorIds?.includes(dataForm?.doctorId as string)
          );
        setServices(selectedDoctorServices);
      },
    });
  }, [dataForm.clinicId, dataForm?.doctorId]);

  // Doctors
  useFindAndCountManyDoctorsByClinicId({
    variables: {
      schema: {
        clinicId: dataForm.clinicId,
      },
    },
    onCompleted: (response) => {
      setDoctors(response?.findAndCountManyDoctorsByClinicId?.doctors);
    },
    fetchPolicy: "network-only",
    skip:
      !dataForm.clinicId || isDoctor || dataForm.clinicId === allClinicsItemId,
  });

  useEffect(() => {
    if (rescheduleAppointment) {
      onSetClinicId(rescheduleAppointment.schedule.clinicId);
    } else {
      setValue("clinicId", currentClinicId);
    }
    setSearchPatient("");
  }, [currentClinicId, rescheduleAppointment]);

  useEffect(() => {
    if (isDoctor) setValue("doctorId", currentUserData?.doctorId);
  }, [isDoctor, currentUserData]);

  useEffect(() => {
    if (!i18n) return;
    findDoctorTypesByLocaleLazyQuery({
      variables: {
        schema: {
          languageISOCode: i18n.language.toUpperCase() as LanguageIsoCodes,
        },
      },
      fetchPolicy: "network-only",
    });
  }, [i18n.language]);

  useEffect(() => {
    return () => {
      onSetRescheduleAppointment(null);
      onSetRescheduleWaitlist(null);
      onSetPatient(null);
    };
  }, []);

  const cabinetsOpts = useMemo(() => {
    const selectedService = services?.find(
      (service) => service?.id === dataForm?.serviceId
    );

    return (
      selectedService?.clinicRooms?.map((cabinet) => ({
        item: cabinet.name,
        value: cabinet.id,
      })) || []
    );
  }, [services, dataForm?.serviceId]);

  const servicesOpts = useMemo(() => {
    return services.map((service) => ({
      item: service.name,
      value: service.id,
    }));
  }, [services]);

  const patientsOpts = useMemo(() => {
    const patientsOptsArray = patientsList?.patients?.map((patient: any) => ({
      item: <PatientOptComponent key={patient.id} patient={patient} />,
      value: patient.id,
    }));
    return patientsOptsArray || [];
  }, [patientsList]);

  const clinicsOpts = useMemo(() => {
    return clinics.map((clinic) => ({
      item: <ClinicOptComponent key={clinic.id} clinic={clinic} />,
      value: clinic.id,
    }));
  }, [clinics]);

  const doctorsOpts = useMemo(() => {
    return doctors.map((doctor) => ({
      item: (
        <DoctorOptComponent
          key={doctor.doctorId}
          doctor={doctor}
          logoFiles={logoFiles}
          doctorTypesData={doctorTypesData}
        />
      ),
      value: doctor.doctorId,
    }));
  }, [doctors, logoFiles, doctorTypesData]);

  useEffect(() => {
    if (errors.minuteTo) toast.error(t(errors?.minuteTo?.message as string));
    if (errors.minuteFrom)
      toast.error(t(errors?.minuteFrom?.message as string));
    if (errors.date) toast.error(t(errors?.date?.message as string));
  }, [errors.minuteTo, errors.minuteFrom, errors.date]);

  const createVisit = (formData: FormValues) => {
    if (rescheduleAppointment) {
      resecheduleAppointmentMutation({
        variables: {
          schema: {
            appointmentId: formData.appointmentId,
            date: formData.date,
            minuteFrom: formData.minuteFrom,
            minuteTo: formData.minuteTo,
          },
        },
        onCompleted: () => {
          toast.success(t("app.successful"));
          setValue("minuteFrom", null);
          setValue("minuteTo", null);
          setValue("date", null);
          onSetRescheduleAppointment(null);
          setDateFilter(new Date());
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          GET_FIND_MANY_AVAILABLE_SLOTS,
          FIND_MANY_SCHEDULE_APPOINTMENT_UPDATES_BY_ID_BY_CLIENT,
          FIND_AND_COUNT_MANY_APPOIINTMENTS_WITH_RELATIONS_BY_CLIENT,
          COUNT_SCHEDULE_APPOINTMENTS_BY_STATUSES_BY_CLIENT,
        ],
      });
    }

    if (
      isClient &&
      filterView === EViewsTypes.Booking &&
      !rescheduleAppointment
    ) {
      createVisitByClient({
        variables: {
          schema: {
            clinicId: formData.clinicId,
            date: formData.date,
            minuteFrom: formData.minuteFrom,
            minuteTo: formData.minuteTo,
            patientId: formData.patientId,
            serviceId: formData.serviceId,
            doctorId: formData.doctorId,
            ...(!!formData.roomId && { roomId: formData.roomId }),
          },
        },
        onCompleted: () => {
          toast.success(t("app.successful"));
          setValue("minuteFrom", null);
          setValue("minuteTo", null);
          setValue("date", null);
          if (rescheduleWaitlist) {
            closeScheduleWaitlist({
              variables: { schema: { id: rescheduleWaitlist?.id } },
            });
            onSetRescheduleWaitlist(null);
          }
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          GET_FIND_MANY_AVAILABLE_SLOTS,
          FIND_MANY_SCHEDULE_APPOINTMENT_UPDATES_BY_ID_BY_CLIENT,
          FIND_AND_COUNT_MANY_APPOIINTMENTS_WITH_RELATIONS_BY_CLIENT,
          COUNT_SCHEDULE_APPOINTMENTS_BY_STATUSES_BY_CLIENT,
        ],
      });
    }

    if (
      isClient &&
      filterView === EViewsTypes.WaitList &&
      !rescheduleAppointment
    ) {
      createWaitList({
        variables: {
          schema: {
            clinicId: formData.clinicId,
            dateFrom: formData.dateFrom,
            dateTo: formData.dateTo,
            doctorId: formData.doctorId,
            minuteFrom: formData.minuteFrom,
            minuteTo: formData.minuteTo,
            patientId: formData.patientId,
            serviceId: formData.serviceId,
          },
        },
        onCompleted: () => {
          toast.success(t("app.successful"));
          setValue("minuteFrom", null);
          setValue("minuteTo", null);
          setValue("dateFrom", null);
          setValue("dateTo", null);
        },
        awaitRefetchQueries: true,
        refetchQueries: [FIND_AND_COUNT_MANY_SCHEDULE_WAITLISTS_BY_CLIENT],
      });
    }

    if (isDoctor && !rescheduleAppointment) {
      createVisitByDoctor({
        variables: {
          schema: {
            clinicId: formData.clinicId,
            date: formData.date,
            minuteFrom: formData.minuteFrom,
            minuteTo: formData.minuteTo,
            patientId: formData.patientId,
            serviceId: formData.serviceId,
            ...(!!formData.roomId && { roomId: formData.roomId }),
          },
        },
        onCompleted: () => {
          toast.success(t("app.successful"));
          setValue("minuteFrom", null);
          setValue("minuteTo", null);
          setValue("date", null);
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          GET_FIND_MANY_AVAILABLE_SLOTS,
          FIND_AND_COUNT_MANY_APPOIINTMENTS_WITH_RELATIONS_BY_DOCTOR,
          COUNT_SCHEDULE_APPOINTMENTS_BY_STATUSES_BY_DOCTOR,
        ],
      });
    }
  };

  const handleToggleFilterViewType = (type: EViewsTypes) => () => {
    setFilterView(type);
  };

  return (
    <PageWrapper>
      {isClient && !rescheduleAppointment && (
        <>
          <Tabs>
            <Tab
              isActive={filterView === EViewsTypes.Booking}
              onClick={handleToggleFilterViewType(EViewsTypes.Booking)}
            >
              {t("activity.title.date")}
            </Tab>
            <Tab
              isActive={filterView === EViewsTypes.WaitList}
              onClick={handleToggleFilterViewType(EViewsTypes.WaitList)}
            >
              {t("waitlist.modals.new_waitlist.buttons.add")}
            </Tab>
          </Tabs>
          <Divider
            margin="26px 0 25px 0"
            color={theme.colors.borderInputDefault}
          />
        </>
      )}
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(createVisit)}>
          <LeftSideComponent
            servicesOpts={servicesOpts}
            patientsOpts={patientsOpts}
            clinicsOpts={clinicsOpts}
            doctorsOpts={doctorsOpts}
            cabinetsOpts={cabinetsOpts}
            setSearchPatient={setSearchPatient}
            searchPatient={searchPatient}
            fetchMore={fetchMore}
            services={services}
            filterView={filterView}
            data={patientsList}
          />
          {dataForm.clinicId && dataForm.doctorId && dataForm.serviceId && (
            <RightSideComponent
              slots={slots}
              dateFilter={dateFilter}
              filterView={filterView}
              setDateFilter={setDateFilter}
              loading={availableSlotsLoading || rescheduleAppointmentLoading}
              buttonLoading={
                createVisitByClientLoading ||
                createVisitByDoctorLoading ||
                createWaitListLoading ||
                closeScheduleWaitlistLoading
              }
            />
          )}
        </Form>
      </FormProvider>
    </PageWrapper>
  );
};

export default AddVisitPage;
