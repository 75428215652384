import { useDispatch, useSelector } from "react-redux";
import { useCallback, useMemo } from "react";
import { AppDispatch, RootState } from "../index";
import {
  selectChat,
  setClinic,
  setDoctor,
  setLocation,
  setService,
  setSlot,
  setPatientInfo,
  setFormFields,
  setRescheduleAppointment,
  setDate,
  setWaitListSlot,
  setRescheduleWaitlist,
} from "./bookingSlice";
import {
  Location,
  PatientInfo,
  SelectedBookingClinic,
  SelectedBookingFormFields,
  SelectedBookingDoctor,
  SelectedBookingService,
  SelectedBookingSlot,
  SelectedRescheduleAppointment,
  SelectedDate,
  SelectedWaitListSlot,
  SelectedRescheduleWaitlist,
} from "./types";

export const getBooking = (state: RootState) => state.booking;

export function useBookingAction() {
  const dispatch = useDispatch<AppDispatch>();

  const onSelectChat = useCallback(() => {
    dispatch(selectChat());
  }, [dispatch]);

  const onSetLocation = useCallback(
    (location: Location) => {
      dispatch(setLocation(location));
    },
    [dispatch]
  );

  const onSetClinic = useCallback(
    (clinicObj: SelectedBookingClinic) => {
      dispatch(setClinic(clinicObj));
    },
    [dispatch]
  );

  const onSetService = useCallback(
    (service: SelectedBookingService) => {
      dispatch(setService(service));
    },
    [dispatch]
  );

  const onSetFormFields = useCallback(
    (fields: SelectedBookingFormFields) => {
      dispatch(setFormFields(fields));
    },
    [dispatch]
  );

  const onSetPatientInfo = useCallback(
    (patientInfo: PatientInfo) => {
      dispatch(setPatientInfo(patientInfo));
    },
    [dispatch]
  );

  const onSetDoctor = useCallback(
    (doctor: SelectedBookingDoctor) => {
      dispatch(setDoctor(doctor));
    },
    [dispatch]
  );

  const onSetSlot = useCallback(
    (slot: SelectedBookingSlot) => {
      dispatch(setSlot(slot));
    },
    [dispatch]
  );

  const onSetWaitListSlot = useCallback(
    (slot: SelectedWaitListSlot) => {
      dispatch(setWaitListSlot(slot));
    },
    [dispatch]
  );

  const onSetRescheduleAppointment = useCallback(
    (rescheduleAppointment: SelectedRescheduleAppointment) => {
      dispatch(setRescheduleAppointment(rescheduleAppointment));
    },
    [dispatch]
  );

  const onSetRescheduleWaitlist = useCallback(
    (rescheduleAppointment: SelectedRescheduleWaitlist) => {
      dispatch(setRescheduleWaitlist(rescheduleAppointment));
    },
    [dispatch]
  );

  const onSetDate = useCallback(
    (currentDate: SelectedDate) => {
      dispatch(setDate(currentDate));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      onSetLocation,
      onSelectChat,
      onSetClinic,
      onSetService,
      onSetFormFields,
      onSetPatientInfo,
      onSetDoctor,
      onSetSlot,
      onSetWaitListSlot,
      onSetRescheduleAppointment,
      onSetRescheduleWaitlist,
      onSetDate,
    }),
    [
      onSetLocation,
      onSelectChat,
      onSetFormFields,
      onSetClinic,
      onSetService,
      onSetDoctor,
      onSetSlot,
      onSetWaitListSlot,
      onSetRescheduleAppointment,
      onSetRescheduleWaitlist,
      onSetDate,
    ]
  );
}

export function useBookingState() {
  return useSelector(getBooking);
}
