import { useEffect } from "react";
import { useAuthContext } from "src/context/AuthContext";
import { IEvent } from "src/pages/DashboardPages/CalendarPage/types";
import { useCalendarAction, useCalendarState } from "src/store/calendar/hooks";

export function useSetDoctorsGroupByEventsListEffect(events: IEvent[]) {
  const { filters } = useCalendarState();
  const { onSetDoctorsGroup } = useCalendarAction();
  const { isClient } = useAuthContext();

  useEffect(() => {
    if (!filters?.doctorIds?.length && isClient) {
      const uniqueDoctors = events?.reduce((acc, event) => {
        const doctorId: string | undefined = event?.eventData?.doctor?.doctorId;

        if (doctorId) {
          if (!acc.some((item) => item.value === doctorId)) {
            acc.push({
              value: doctorId,
              key: `${event?.eventData?.doctor?.firstName} ${event?.eventData?.doctor?.lastName}`,
            });
          }
        }

        return acc;
      }, [] as { value: string; key: string }[]);

      onSetDoctorsGroup(uniqueDoctors);
    }
  }, [filters, isClient, events]);
}
