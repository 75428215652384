import { useEffect, useState } from "react";
import { FieldError, useFormContext } from "react-hook-form";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { FormItemComponentCustomInput } from "./FormItemComponentCustomInput";
import { DateWrapper, ErrorMessage } from "./styled";

interface IFormItemComponentDateProps {
  disabled?: boolean;
  selected: any;
  placeholder: string;
  fieldOrder: number;
}

export function FormItemComponentDate({
  disabled,
  selected,
  placeholder,
  fieldOrder,
}: IFormItemComponentDateProps) {
  const { t, i18n } = useTranslation();
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const fields = watch("fields");

  const [parsedDateValue, setParsedDateValue] = useState<{
    time: boolean;
  } | null>(null);

  useEffect(() => {
    if (fields[fieldOrder]?.value) {
      const dateValues =
        typeof fields[fieldOrder].value === "string"
          ? JSON.parse(fields[fieldOrder].value)
          : fields[fieldOrder].value;

      setParsedDateValue(dateValues);
    }
  }, [fields[fieldOrder].translations[0], i18n.resolvedLanguage]);

  const handleChangeDate = (date: Date | null) => {
    const formattedDate = parsedDateValue?.time
      ? format(date as Date, "yyyy-MM-dd HH:mm")
      : format(date as Date, "yyyy-MM-dd");

    setValue(`fields.${fieldOrder}.fillValue`, formattedDate);
  };

  const dateFormat = parsedDateValue?.time
    ? "dd MMM yyyy h:mm aa"
    : "yyyy-MM-dd";

  return (
    <DateWrapper>
      <DatePicker
        disabled={disabled}
        selected={selected}
        onChange={handleChangeDate}
        customInput={<FormItemComponentCustomInput />}
        placeholderText={placeholder}
        showTimeInput={parsedDateValue?.time}
        dateFormat={dateFormat}
        locale={i18n.resolvedLanguage}
      />

      {((errors.fields as any)?.[fieldOrder] as FieldError) && (
        <ErrorMessage>
          {(errors.fields as any)?.[fieldOrder]?.message &&
            t((errors.fields as any)?.[fieldOrder]?.message)}
        </ErrorMessage>
      )}
    </DateWrapper>
  );
}
