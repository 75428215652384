import styled from "styled-components";

export const DoctorWrapStiled = styled.div`
  display: flex;
  gap: 15px;

  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: column;
  }
`;

export const DoctorLeftWrapStiled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: fit-content;
`;

export const DoctorRightWrapStiled = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;
