import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 20px;
  gap: 20px;
  display: flex;
  align-items: center;
  align-content: center;
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  max-width: 988px;
  flex-direction: column;
`;

export const Title = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.colors.black};
`;

export const CloseButton = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

export const List = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
`;

export const FieldItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 20px;
  gap: 15px;
  background: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  border-radius: 5px;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  width: 100%;
  max-width: calc(100% / 4 - (60px / 4));
  color: ${({ theme }) => theme.colors.black};
  svg {
    width: 40px;
    height: 40px;
    path {
      stroke: ${({ theme }) => theme.colors.grayC4};
    }
  }
`;
