import React, { useEffect, useRef } from "react";
import mapboxgl, { Map as MapboxMap, NavigationControl } from "mapbox-gl";

import { Location } from "src/store/clinics/types";
import { useBookingState } from "src/store/booking/hooks";
import { createRoot } from "react-dom/client";

import { MapCover } from "./styles";
import { Feature, FeatureCollection } from "./types";
import { SelectedClinicMarker } from "../MapComponents";

interface MapProps {
  markers: FeatureCollection;
  currentLocation: Location;
  onSelectMarker?: (id: string) => void;
  horizontalOffset?: number;
  verticalOffset?: number;
}

const Map: React.FC<MapProps> = ({
  markers,
  currentLocation,
  onSelectMarker,
  horizontalOffset = 0,
  verticalOffset = 0,
}) => {
  const { currentClinic } = useBookingState();
  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<MapboxMap | null>(null);
  const markersRef = useRef<mapboxgl.Marker[]>([]);

  const addMarker = (
    coords: [number, number],
    isActive?: boolean,
    onSelect?: () => void
  ) => {
    const map = mapRef.current;
    const markerWrapper = document.createElement("div");
    const markerImage = document.createElement("img");
    markerWrapper.appendChild(markerImage);
    markerImage.src = `/map/pin.png`;

    if (isActive) {
      if (currentClinic) {
        createRoot(markerWrapper).render(
          <SelectedClinicMarker currentClinic={currentClinic} />
        );
      } else {
        markerWrapper.className = "pulsing-marker";
      }
    }

    if (map) {
      const marker = new mapboxgl.Marker(markerWrapper)
        .setLngLat(coords)
        .addTo(map);

      if (onSelect) {
        marker.getElement().addEventListener("click", onSelect);
      }

      markersRef.current.push(marker);
    }
  };

  useEffect(() => {
    if (!mapContainerRef.current) {
      return;
    }

    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX as string;
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v12",
    });

    const navControl = new NavigationControl();
    map.addControl(navControl, "bottom-right");

    mapRef.current = map;

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
      }
    };
  }, []);

  useEffect(() => {
    const map = mapRef.current;
    const isCorrectLocation =
      !!currentLocation?.center[0] && !!currentLocation?.center[1];
    if (map) {
      if (markersRef.current.length > 0) {
        markersRef.current.forEach((marker) => {
          marker.remove();
        });

        markersRef.current = [];
      }
      markers?.features?.forEach((store: Feature) => {
        const isCurrentMarker =
          store.geometry.coordinates?.[0] === currentLocation?.center[0] &&
          store.geometry.coordinates?.[1] === currentLocation?.center[1];
        if (isCorrectLocation && isCurrentMarker) {
          addMarker(store.geometry.coordinates, true);
        } else {
          addMarker(store.geometry.coordinates, false, () => {
            if (onSelectMarker) onSelectMarker(store?.id);
          });
        }
      });
    }
  }, [markers, currentLocation]);

  const goToMarker = (center: [number, number], isInitialZoom?: boolean) => {
    if (mapRef.current) {
      const adjustedCenter: [number, number] = [
        center[0] + horizontalOffset,
        center[1] + verticalOffset,
      ];
      mapRef.current.flyTo({
        center: adjustedCenter,
        zoom: isInitialZoom ? 0 : 15,
      });
    }
  };

  useEffect(() => {
    const map = mapRef.current;
    const isCorrectLocation =
      !!currentLocation?.center[0] && !!currentLocation?.center[1];

    if (isCorrectLocation && map) {
      goToMarker(currentLocation.center, false);
    } else {
      goToMarker([0, 0], true);
    }
  }, [currentLocation]);

  return <MapCover ref={mapContainerRef} />;
};

export default Map;
