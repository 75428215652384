import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addClinicValidationSchema } from "src/validation/schemas/addClinicSchema";
import {
  useCreateClinic,
  useUpdateClinicById,
} from "src/graphql/hooks/useMutations";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { updateClinicValidationSchema } from "src/validation/schemas/updateClinicSchema";
import { GET_COUNT_CLINICS } from "src/graphql/queries";
import { PATHS } from "src/constants/constants";
import { useTranslation } from "react-i18next";

import { transformCreateAndEditClinic } from "src/connectors/Clinics/transformCreateAndEditClinic";
import { transformBeforeEditClinic } from "src/connectors/Clinics/transformBeforeEditClinic";
import { useClinicsAction, useClinicsState } from "src/store/clinics/hooks";

import {
  AddCabinetsView,
  AddLocationView,
  WorktimeView,
} from "src/views/MapViews";
import { useEffect } from "react";
import {
  ClinicSortFields,
  SortingOrders,
  useFindAndCountManyClinicsLazyQuery,
  useFindClinicByIdLazyQuery,
} from "src/graphql/generated";
import { Column, Form, Row } from "./styles";
import { FormValues } from "../types";
import { initialValues, sortParams } from "../staticData";

function CreateBranch() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { clinicId } = useParams();
  const { onSetLocation, onSetClinicId } = useClinicsAction();
  const { currentLocation, currentClinicId } = useClinicsState();

  const [getFindAndCountManyClinicsLazyQuery] =
    useFindAndCountManyClinicsLazyQuery();

  useEffect(() => {
    getFindAndCountManyClinicsLazyQuery({
      variables: {
        schema: {
          sortOrder: SortingOrders.Descending,
          sortField: ClinicSortFields.CreatedAt,
        },
      },
      onCompleted: (response) => {
        if (
          !response?.findAndCountManyClinics?.clinics?.length &&
          !pathname.includes(PATHS.create)
        ) {
          navigate(`${PATHS.cp}${PATHS.map}`);
        }
      },
    });
  }, []);

  useEffect(() => {
    if (currentLocation && clinicId)
      navigate(
        `${PATHS.cp}${PATHS.map}${PATHS.update}/${currentClinicId || clinicId}`
      );
  }, [currentLocation]);

  const [addClinic, { loading: loadingCreate }] = useCreateClinic({
    refetchQueries: [
      {
        query: GET_COUNT_CLINICS,
        variables: {
          schema: {
            ...sortParams,
          },
        },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: (result) => {
      const clinic = result?.createClinic;
      toast.success(t("app.successful"));
      onSetLocation({
        center: [
          clinic?.geographicalCoordinates?.longitude,
          clinic?.geographicalCoordinates?.latitude,
        ],
      });
      onSetClinicId(clinic?.id);
      navigate(`${PATHS.cp}${PATHS.map}${PATHS.update}/${clinic?.id}`);
    },
  });

  const [updateClinic, { loading: loadingUpdate }] = useUpdateClinicById({
    refetchQueries: [
      {
        query: GET_COUNT_CLINICS,
        variables: {
          schema: {
            ...sortParams,
          },
        },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted: (result) => {
      const clinic = result?.updateClinicById;
      toast.success(t("app.successful"));
      onSetLocation({
        center: [
          clinic?.geographicalCoordinates?.longitude,
          clinic?.geographicalCoordinates?.latitude,
        ],
      });
      onSetClinicId(clinic?.id);
      navigate(`${PATHS.cp}${PATHS.map}`);
    },
  });

  const methods = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(
      clinicId ? updateClinicValidationSchema : addClinicValidationSchema
    ),
    defaultValues: initialValues,
  });

  const { handleSubmit, reset } = methods;

  const [getClinicById] = useFindClinicByIdLazyQuery();

  useEffect(() => {
    if (clinicId) {
      getClinicById({
        variables: {
          schema: {
            id: clinicId as string,
          },
        },
        fetchPolicy: "network-only",
        onCompleted: (response) => {
          reset(transformBeforeEditClinic(response?.findClinicById));
        },
      });
    }
  }, [clinicId]);

  const formHandler = (formData: FormValues) => {
    const params = transformCreateAndEditClinic(formData);

    if (clinicId) {
      updateClinic({
        variables: {
          schema: { id: clinicId, fields: params },
        },
      });
    } else {
      addClinic({
        variables: {
          schema: {
            ...params,
            ...(formData.photo && {
              logoFileId: formData.photo,
            }),
          },
        },
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(formHandler)}>
        <Column>
          <AddLocationView loading={loadingCreate || loadingUpdate} />
          <WorktimeView />
        </Column>
        <Column>
          <Row>
            <AddCabinetsView />
            {/* <AddGroupsView /> */}
          </Row>
          {/* <AdvertisingPixelsAddView /> */}
          {/* <ReviewView /> */}
          {/* <LinkedView /> */}
        </Column>
      </Form>
    </FormProvider>
  );
}

export default CreateBranch;
