import { theme } from "src/theme";
import styled from "styled-components";

export const MarkerStyled = styled.div`
  min-width: auto;
  display: flex;
  margin-top: -59px;
  margin-left: 180px;
`;

export const ImageWrapStyled = styled.div`
  position: relative;
  width: 65px;
  height: 65px;
  border: 4px solid;
  border-color: ${() => theme.colors.green};
  background-color: ${() => theme.colors.white};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    display: block;
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: ${() => theme.colors.green};
    top: calc(100% - 7px);
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    z-index: -1;
  }
`;

export const ImageStyled = styled.img`
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
`;

export const MarkerInfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 210px;
  background-color: ${() => theme.colors.white};
  padding: 10px 20px 10px 40px;
  margin-left: -30px;
  border-radius: 10px;
`;

export const MarkerAddresStyled = styled.div`
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 25.6px;
`;

export const MarkerPhoneStyled = styled.div`
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  color: ${() => theme.colors.darkGrey};
  font-size: 12px;
  line-height: 19.2px;
`;
