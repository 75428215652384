import Image from "./Image";
import Video from "./Video";
import { MediaProps } from "../types";

function Media(props: MediaProps) {
  let media = null;
  if (!props.block.getEntityAt(0)) {
    return media;
  }
  const entity = props.contentState.getEntity(props.block.getEntityAt(0));
  const { src } = entity.getData();
  const type = entity.getType();

  if (type === "IMAGE") {
    media = <Image src={src} />;
  }

  if (type === "EMBEDDED_LINK") {
    media = <Video src={src} />;
  }

  return media;
}
export default Media;
