import { useEffect, useState } from "react";
import { FieldError, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  DistributionSystemEvents,
  useFindTemplateByClientLazyQuery,
} from "src/graphql/generated";
import { useClinicsState } from "src/store/clinics/hooks";
import {
  useTemplatesAction,
  useTemplatesState,
} from "src/store/templates/hooks";
import { useTemplateDefault } from "src/hooks/templates";
import { ITemplateCreateEdit } from "src/types/templates";
import { ScreenLoader } from "src/UI/ScreenLoader";
import { transformTemplateToFormCreateEdit } from "src/connectors/Templates/transformTemplates";
import {
  StyledDropdownInputRHF,
  StyledTemplatesComponentWrapper,
} from "./styled";

const distributionSystemEventsTranslation = {
  [DistributionSystemEvents.AppointmentCanceledByClient]:
    "templates.action.distribution_system_events.appointment_canceled_by_client",
  [DistributionSystemEvents.AppointmentCanceledByPatient]:
    "templates.action.distribution_system_events.appointment_canceled_by_patient",
  [DistributionSystemEvents.AppointmentConfirmedByClient]:
    "templates.action.distribution_system_events.appointment_confirmed_by_client",
  [DistributionSystemEvents.AppointmentConfirmedByPatient]:
    "templates.action.distribution_system_events.appointment_confirmed_by_patient",
  [DistributionSystemEvents.AppointmentCreatedByClient]:
    "templates.action.distribution_system_events.appointment_created_by_client",
  [DistributionSystemEvents.AppointmentCreatedByPatient]:
    "templates.action.distribution_system_events.appointment_created_by_patient",
  [DistributionSystemEvents.AppointmentMissed]:
    "templates.action.distribution_system_events.appointment_missed",
  [DistributionSystemEvents.AppointmentRescheduledByClient]:
    "templates.action.distribution_system_events.appointment_rescheduled_by_client",
  [DistributionSystemEvents.AppointmentRescheduledByPatient]:
    "templates.action.distribution_system_events.appointment_rescheduled_by_patient",
  [DistributionSystemEvents.WaitlistCreatedByClient]:
    "templates.action.distribution_system_events.waitlist_created_by_client",
  [DistributionSystemEvents.WaitlistCreatedByPatient]:
    "templates.action.distribution_system_events.waitlist_created_by_patient",
  [DistributionSystemEvents.WaitlistTimeslotAvailable]:
    "templates.action.distribution_system_events.waitlist_timeslot_available",
  [DistributionSystemEvents.AppointmentRemindedBefore48Hours]:
    "templates.action.distribution_system_events.appointment_reminded_before_48_hours",
  [DistributionSystemEvents.WaitlistConfirmedByPatient]:
    "templates.action.distribution_system_events.waitlist_confirmed_by_patient",
  [DistributionSystemEvents.AppointmentSuccessful1Hour]:
    "templates.action.distribution_system_events.appointment_successful_1_hour",
  [DistributionSystemEvents.AppointmentSuccessful6Months]:
    "templates.action.distribution_system_events.appointment_successful_6_months",
};

const distributionSystemEventsList = Object.keys(
  DistributionSystemEvents
) as Array<keyof typeof DistributionSystemEvents>;

function TemplatesComponentEvent() {
  const { t } = useTranslation();
  const {
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useFormContext<ITemplateCreateEdit>();
  const selectedEvent = watch("selectedEvent");
  const [prevSelectedEvent, setPrevSelectedEvent] =
    useState<DistributionSystemEvents | null>(null);
  const [isLoadingDefaultValues, setIsLoadingDefaultValues] = useState(false);
  const { currentClinicId: clinicId } = useClinicsState();

  const { getDefaultValues } = useTemplateDefault();
  const { onSetSelectedTemplate, onResetSelectedTemplate } =
    useTemplatesAction();
  const { selectedTemplateData } = useTemplatesState();

  const [getTemplate] = useFindTemplateByClientLazyQuery({
    fetchPolicy: "network-only",
  });

  const setDefaultTemplates = async (event: DistributionSystemEvents) => {
    setIsLoadingDefaultValues(true);

    const currentTemplate = await getTemplate({
      variables: {
        schema: {
          clinicId,
          event,
        },
      },
    });

    if (currentTemplate.data?.findTemplateByClient) {
      const resetFormData = transformTemplateToFormCreateEdit(
        currentTemplate.data?.findTemplateByClient
      );

      onSetSelectedTemplate(currentTemplate.data.findTemplateByClient);
      reset(resetFormData);
      setIsLoadingDefaultValues(false);
      setValue("selectedEvent", event);

      return;
    }

    const defaultTemplate = await getDefaultValues(event);

    reset(defaultTemplate);
    setValue("selectedEvent", event);
    setIsLoadingDefaultValues(false);
  };

  useEffect(() => {
    if (selectedEvent) {
      if (prevSelectedEvent !== null && selectedEvent !== prevSelectedEvent) {
        onResetSelectedTemplate();
        setDefaultTemplates(selectedEvent);
      }

      if (selectedEvent !== prevSelectedEvent && !selectedTemplateData) {
        setDefaultTemplates(selectedEvent);
      }

      setPrevSelectedEvent(selectedEvent);
    }
  }, [selectedEvent]);

  useEffect(() => {
    if (!selectedEvent && selectedTemplateData) {
      const selectedTemplateFormData =
        transformTemplateToFormCreateEdit(selectedTemplateData);

      setTimeout(() => reset(selectedTemplateFormData), 0);
    }
  }, [selectedEvent, selectedTemplateData]);

  useEffect(() => {
    if (clinicId && selectedEvent) {
      onResetSelectedTemplate();
      setDefaultTemplates(selectedEvent);
    }
  }, [clinicId]);

  const templateOptions = distributionSystemEventsList.map((option) => {
    const translation = distributionSystemEventsTranslation[option];

    return {
      item: translation ? t(translation) : option,
      value: option,
    };
  });

  return (
    <>
      {isLoadingDefaultValues && <ScreenLoader />}

      <StyledTemplatesComponentWrapper>
        <StyledDropdownInputRHF
          name="selectedEvent"
          value={selectedEvent}
          placeholder={t("templates.action.select_event.placeholder")}
          options={templateOptions}
          label=""
          adaptedForMobile={false}
          errorMessage={errors.selectedEvent as FieldError}
          isDisabled={isLoadingDefaultValues}
        />
      </StyledTemplatesComponentWrapper>
    </>
  );
}

export default TemplatesComponentEvent;
