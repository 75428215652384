import styled from "styled-components";

export const InputBlock = styled.div<{ isError: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;

  .react-tel-input {
    height: 100%;
  }

  .country-list {
    overflow-y: auto;
  }

  .form-control {
    border-bottom: 1px solid
      ${({ theme, isError }) =>
        theme.colors[isError ? "red" : "borderInputDefault"]} !important;
  }

  .form-control:focus {
    border-color: ${({ theme, isError }) =>
      theme.colors[isError ? "red" : "green"]} !important;
  }
`;

export const ErrorMessage = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: block;
  text-align: left;
  color: ${({ theme }) => theme.colors.red};
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.grayC4};
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  left: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
`;
