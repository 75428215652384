import React from "react";

import {
  FindAndCountManyLastSmsByClientQuery,
  PatientEntityResponse,
  useFindAndCountManyLastSmsByClientQuery,
} from "src/graphql/generated";
import { useTranslation } from "react-i18next";
import { useFetchMore } from "src/hooks/useFetchMore";
import { defaultLastSmsLimit } from "src/constants/constants";
import { useClinicsState } from "src/store/clinics/hooks";
import { Loading } from "src/UI/Loading";
import ChatComponent from "../ChatComponent/ChatComponent";
import { List, LoadingWrap } from "./styled";

export interface IChatListComponentProps {
  chats: FindAndCountManyLastSmsByClientQuery;
  fetchMore: (
    options: Parameters<
      ReturnType<typeof useFindAndCountManyLastSmsByClientQuery>["fetchMore"]
    >[0]
  ) => Promise<unknown>;
}

function ChatListComponent({ chats, fetchMore }: IChatListComponentProps) {
  const { i18n } = useTranslation();
  const { currentClinicId: clinicId } = useClinicsState();

  const { node, isLoading } = useFetchMore(() =>
    fetchMore({
      variables: {
        schema: {
          clinicId,
          offset: chats.findAndCountManyLastSmsByClient.sms.length,
          limit: defaultLastSmsLimit,
        },
      },
    })
  );

  return (
    <List>
      {chats.findAndCountManyLastSmsByClient.sms.map((chat) => (
        <ChatComponent
          key={`Chat-${chat.id}`}
          patient={chat.patient as PatientEntityResponse}
          createdAt={chat.createdAt}
          content={chat.content}
          resolvedLanguage={i18n.resolvedLanguage}
        />
      ))}
      {isLoading ? (
        <LoadingWrap>
          <Loading padding="0" />
        </LoadingWrap>
      ) : null}
      {chats.findAndCountManyLastSmsByClient.count >
      chats.findAndCountManyLastSmsByClient.sms.length ? (
        <div ref={node} />
      ) : null}
    </List>
  );
}

export default ChatListComponent;
