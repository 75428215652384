import React, { FunctionComponent } from "react";
import { Svg, SvgProps } from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M4.8 2.30039C4.80356 2.36322 4.8268 2.42334 4.86641 2.47224C4.90603 2.52115 4.96002 2.55635 5.02075 2.57287C5.08148 2.5894 5.14586 2.5864 5.2048 2.56432C5.26373 2.54223 5.31422 2.50217 5.34913 2.44981C5.38404 2.39744 5.4016 2.33543 5.39932 2.27253C5.39704 2.20964 5.37504 2.14905 5.33643 2.09935C5.29782 2.04965 5.24456 2.01335 5.18418 1.99559C5.12381 1.97783 5.05937 1.97951 5 2.00039H4C3.46957 2.00039 2.96086 2.2111 2.58579 2.58617C2.21071 2.96125 2 3.46995 2 4.00039V9.00039C2 10.5917 2.63214 12.1178 3.75736 13.243C4.88258 14.3682 6.4087 15.0004 8 15.0004C9.5913 15.0004 11.1174 14.3682 12.2426 13.243C13.3679 12.1178 14 10.5917 14 9.00039V4.00039C14 3.46995 13.7893 2.96125 13.4142 2.58617C13.0391 2.2111 12.5304 2.00039 12 2.00039H11C10.9803 2.02008 10.9647 2.04347 10.954 2.06921C10.9434 2.09494 10.9379 2.12253 10.9379 2.15039C10.9379 2.17824 10.9434 2.20583 10.954 2.23157C10.9647 2.2573 10.9803 2.28069 11 2.30039C11.0197 2.32008 11.0431 2.33571 11.0688 2.34637C11.0946 2.35703 11.1221 2.36252 11.15 2.36252C11.1779 2.36252 11.2054 2.35703 11.2312 2.34637C11.2569 2.33571 11.2803 2.32008 11.3 2.30039"
      stroke="#A5A5A5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 15V16C8 17.5913 8.63214 19.1174 9.75736 20.2426C10.8826 21.3679 12.4087 22 14 22V22C15.5913 22 17.1174 21.3679 18.2426 20.2426C19.3679 19.1174 20 17.5913 20 16V12"
      stroke="#A5A5A5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 12C21.1046 12 22 11.1046 22 10C22 8.89543 21.1046 8 20 8C18.8954 8 18 8.89543 18 10C18 11.1046 18.8954 12 20 12Z"
      stroke="#A5A5A5"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Icon;
