import { Heading } from "src/UI/Heading";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 20px;
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  flex: 1;
  width: 100%;
`;

export const HeadingStyled = styled(Heading)`
  font-size: 24px;
  line-height: 120%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  width: 100%;
`;

export const BlockWrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  gap: 10px;
  width: 100%;
`;

export const Block = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  width: 100%;
  background: ${({ theme }) => theme.colors.lightBiege};
  border-radius: 10px;
`;

export const BlockLabel = styled.div`
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 700;
`;

export const BlockValue = styled.div`
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.green};
`;
