import React, { Dispatch, FC, SetStateAction } from "react";

import { format, parse } from "date-fns";
import { getFNSLocaleByLang } from "src/utils";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "src/hooks/useMediaQuery";
import { breakpointMap } from "src/theme/base";
import {
  SliderItemDateStyled,
  SliderItemDayStyled,
  SliderItemStyled,
} from "./styles";

interface PageProps {
  date: string;
  selectedDate?: string;
  setSelectedDate: Dispatch<SetStateAction<string>>;
}

const SliderItemComponent: FC<PageProps> = ({
  date,
  selectedDate,
  setSelectedDate,
}) => {
  const isMobile = useMediaQuery(breakpointMap.sm);
  const { i18n } = useTranslation();
  const itemDate = parse(date, "yyyy-MM-dd", new Date());

  const handleSelectItem = () => {
    setSelectedDate(date);
  };

  return (
    <SliderItemStyled
      onClick={handleSelectItem}
      isSelected={selectedDate === date}
    >
      <SliderItemDayStyled>
        {format(itemDate, "EEE", {
          locale: getFNSLocaleByLang(i18n.resolvedLanguage),
        })}
      </SliderItemDayStyled>
      <SliderItemDateStyled>
        {format(itemDate, isMobile ? "d MMM" : ", d MMM", {
          locale: getFNSLocaleByLang(i18n.resolvedLanguage),
        })}
      </SliderItemDateStyled>
    </SliderItemStyled>
  );
};

export default SliderItemComponent;
