import EmptyText from "src/components/EmptyText/EmptyText";
import styled from "styled-components";

export const FormContainer = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 20px;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.black};
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
`;

export const StyledEmptyText = styled(EmptyText)`
  height: 100%;
  font-size: 24px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
`;
