import React, { ReactNode } from "react";
import { RowItemStyled } from "./styles";

interface RowItemProps {
  item: string | ReactNode;
  isSelected: boolean;
  isActive: boolean;
  value: string | number | object;
  onSelect?: (value: string | number | object) => void;
  onShow?: () => void;
}

function RowItem({
  item,
  value,
  onSelect,
  isSelected,
  isActive,
  onShow,
}: RowItemProps) {
  const handleSelect = () => {
    if (onSelect && !isSelected) {
      onSelect(value);
    } else if (onShow) {
      onShow();
    }
  };
  return (
    <RowItemStyled
      className="dropdown-item"
      isActive={isActive}
      isSelected={isSelected}
      onClick={handleSelect}
    >
      {item}
    </RowItemStyled>
  );
}

export default RowItem;
