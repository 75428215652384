import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ChevronIcon, LogoFullIcon } from "src/UI/Svg";
import { Text } from "src/UI/Text";
import { Heading } from "src/UI/Heading";
import { InputComponent } from "src/components/AuthComponents";
import { EButtonVariants } from "src/UI/Button";
import {
  Breadcrumb,
  ButtonStyled,
  FormFA,
  FormWrapper,
  TitleBlock,
  WrapperNav,
} from "../styled";

interface FormValues {
  old_password: string;
  new_password: string;
  repeat_password: string;
}

function ResetPassword() {
  const { t } = useTranslation();

  const {
    register,
    formState: { errors, isValid },
    watch,
    handleSubmit,
  } = useForm<FormValues>({
    mode: "onChange",
  });
  const data = watch();

  const navigate = useNavigate();
  const formHandler = () => {
    navigate("/cp/dashboard");
  };

  return (
    <>
      <WrapperNav>
        <LogoFullIcon />
      </WrapperNav>
      <FormWrapper>
        <Breadcrumb to="/auth/login">
          <ChevronIcon />
          <Text>{t("common.back")}</Text>
        </Breadcrumb>
        <FormFA onSubmit={handleSubmit(formHandler)}>
          <TitleBlock>
            <Heading as="h2">{t("reset.title")}</Heading>
          </TitleBlock>
          <InputComponent
            placeholder={t("reset.old")}
            inputType="password"
            val={data.old_password}
            errorMessage={errors.old_password}
            register={register("old_password", {
              required: true,
              minLength: {
                value: 8,
                message: t("auth.reg.password_error"),
              },
            })}
          />
          <InputComponent
            placeholder={t("reset.new")}
            inputType="password"
            val={data.new_password}
            errorMessage={errors.new_password}
            register={register("new_password", {
              required: true,
              minLength: {
                value: 8,
                message: t("auth.reg.password_error"),
              },
            })}
          />
          <InputComponent
            placeholder={t("reset.repeat")}
            inputType="password"
            val={data.repeat_password}
            errorMessage={errors.repeat_password}
            register={register("repeat_password", {
              required: true,
              minLength: {
                value: 8,
                message: t("auth.reg.password_repeat_error"),
              },
              validate: (v) => {
                return v.length >= 8 && v === data.new_password;
              },
            })}
          />
          <ButtonStyled variant={EButtonVariants.Default} disabled={!isValid}>
            {t("reset.submit")}
          </ButtonStyled>
        </FormFA>
      </FormWrapper>
    </>
  );
}

export default ResetPassword;
