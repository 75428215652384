import styled from "styled-components";

export const SearchWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 554px;
  z-index: 1;
`;

export const SearchBlock = styled.div`
  position: relative;
  width: 100%;
  height: 48px;
  z-index: 1;
`;

export const Input = styled.input<{ isShow: boolean }>`
  width: 100%;
  height: 100%;
  outline: none;
  padding: 17px 24px;
  font-size: 16px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.black};
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  border-color: ${({ theme }) => theme.colors.borderInputDefault};
  box-shadow: ${({ isShow }) =>
    isShow ? `none` : "0 5px 25px rgba(0, 0, 0, 0.03)"};
  font-weight: 700;
  border-radius: 56px;
  &::placeholder {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray};
  }
`;

export const Icon = styled.button`
  min-height: 24px;
  min-width: 24px;
  border: none;
  outline: none;
  display: flex;
  padding: 6px;
  background: ${({ theme }) => theme.colors.lightBiege};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 6px;
  border-radius: 50%;
`;

export const LoadingWrap = styled.div`
  min-height: 24px;
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
