import { Button } from "src/UI/Button";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  background: ${({ theme }) => theme.colors.background};
  border-radius: 10px;
  padding-inline: 20px;
`;

interface IPatientRowProps {
  $isSelected?: boolean;
}

export const PatientRow = styled.tr<IPatientRowProps>`
  position: relative;
  transform-style: preserve-3d;

  &:after {
    content: "";
    position: absolute;
    height: 100%;
    left: -20px;
    right: -20px;
    background-color: ${({ $isSelected, theme }) =>
      $isSelected ? theme.colors.lightBiege : "transparent"};
    transform: translateZ(-1px);
  }

  &:hover {
    &:after {
      background-color: ${({ theme }) => theme.colors.lightBiege};
    }
  }

  &:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  }

  td:first-child {
    max-width: 230px;
  }

  td {
    padding-block: 20px;
  }
`;

export const FormTbody = styled.tbody`
  tr:last-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  }
`;

export const StyledButton = styled(Button)`
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  width: auto;
  height: auto;
  color: ${({ theme }) => theme.colors.black};

  &:not(:disabled):hover {
    background-color: transparent;
  }
`;
