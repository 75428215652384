import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { CrossIcon, DoctorIcon } from "src/UI/Svg";
import {
  AggregatedScheduleWaitlistEntityByClientResponse,
  ClinicEntityResponse,
  FileEntityResponse,
} from "src/graphql/generated";
import { getCurrencySimbolByCode } from "src/utils";
import { TScheduleWaitingAppointment } from "src/types";
import { format } from "date-fns";
import { SelectedRescheduleWaitlist } from "src/store/booking/types";
import {
  CardStyled,
  Content,
  DescriptionWrapper,
  DoctorOptInfoWrap,
  DoctorOptWrap,
  DoctorWrapper,
  DoctorWrapperRow,
  DotSeparator,
  IconWrapper,
  ImageStyled,
  InfoDescriptionWrapper,
  OptItemSubTitle,
  OptItemTitle,
  Price,
  PriceLabel,
  PriceWrapper,
  ServiceDescription,
  ServiceName,
} from "../styled";
import ScheduleAppointmentDateComponent from "./ScheduleAppointmentDateComponent";
import { IData, TDateValue } from "../types";

interface IDoctorInfoCardComponent {
  appointment: TScheduleWaitingAppointment;
  clinic: ClinicEntityResponse | null;
  files: FileEntityResponse[];
  isClosedStatus: boolean;
  data: IData;
  setData: Dispatch<SetStateAction<IData>>;
  onSetRescheduleWaitlist: (
    rescheduleAppointment: SelectedRescheduleWaitlist
  ) => void;
}

function DoctorInfoCardComponent({
  appointment,
  files,
  clinic,
  isClosedStatus,
  data,
  setData,
  onSetRescheduleWaitlist,
}: IDoctorInfoCardComponent) {
  const { t } = useTranslation();

  const doctor = appointment?.doctor;
  const service = appointment?.service;

  const onChange = (key: string, val: TDateValue) => {
    setData((prevState) => ({
      ...prevState,
      [key]: val,
    }));

    onSetRescheduleWaitlist({
      ...(appointment as AggregatedScheduleWaitlistEntityByClientResponse),
      ...(data?.minuteFrom && { minuteFrom: data?.minuteFrom }),
      ...(data?.minuteTo && { minuteTo: data?.minuteTo }),
      ...(data?.dateFrom && { dateFrom: format(data?.dateFrom, "yyyy-MM-dd") }),
      ...(data?.dateTo && { dateTo: format(data?.dateTo, "yyyy-MM-dd") }),
      [key]:
        key === "dateFrom" || key === "dateTo"
          ? format(val as Date, "yyyy-MM-dd")
          : val,
    });
  };

  const getFileUrlById = (id: string | undefined | null) => {
    const getFile = files?.find((file) => file.id === id);
    return getFile?.url;
  };

  return (
    <CardStyled title={t("booking.add_service.label.delay")}>
      <Content>
        <DoctorWrapper>
          <DoctorWrapperRow>
            <DoctorOptWrap>
              <IconWrapper>
                {getFileUrlById(clinic?.logoFileId) ? (
                  <ImageStyled
                    src={getFileUrlById(clinic?.logoFileId)}
                    alt="#"
                  />
                ) : (
                  <CrossIcon />
                )}
              </IconWrapper>
              <DoctorOptInfoWrap>
                <OptItemTitle>{clinic?.name}</OptItemTitle>
                <OptItemSubTitle>{clinic?.address}</OptItemSubTitle>
              </DoctorOptInfoWrap>
            </DoctorOptWrap>
          </DoctorWrapperRow>
          <DoctorWrapperRow>
            <DoctorOptWrap>
              <IconWrapper>
                {getFileUrlById(doctor?.avatarFileId) ? (
                  <ImageStyled
                    src={getFileUrlById(doctor?.avatarFileId)}
                    alt="#"
                  />
                ) : (
                  <DoctorIcon />
                )}
              </IconWrapper>
              <DoctorOptInfoWrap>
                <OptItemTitle>{`${doctor?.firstName} ${doctor?.lastName}`}</OptItemTitle>
                {(!!doctor?.phoneNumber || !!doctor?.email) && (
                  <OptItemSubTitle>
                    {doctor?.phoneNumber}
                    {!!doctor?.phoneNumber && !!doctor?.email && (
                      <DotSeparator />
                    )}
                    {doctor?.email}
                  </OptItemSubTitle>
                )}
              </DoctorOptInfoWrap>
            </DoctorOptWrap>

            <InfoDescriptionWrapper>
              <PriceWrapper>
                <PriceLabel>{t("booking.add_service.label.delay")}</PriceLabel>
                <Price>{`${service?.durationInMinutes} ${t(
                  "booking.services.actions.min"
                )}`}</Price>
              </PriceWrapper>
              <PriceWrapper>
                <PriceLabel>{t("booking.add_service.label.price")}</PriceLabel>
                <Price>
                  {service?.price?.toLocaleString().replace(",", " ")}{" "}
                  {getCurrencySimbolByCode(service?.currencyCode as string)}
                </Price>
              </PriceWrapper>
            </InfoDescriptionWrapper>
          </DoctorWrapperRow>
          {!isClosedStatus ? (
            <ScheduleAppointmentDateComponent
              data={data}
              onChange={onChange}
              setData={setData}
            />
          ) : null}
        </DoctorWrapper>
        <DescriptionWrapper>
          <ServiceName>{service?.name}</ServiceName>
          <ServiceDescription>{service?.description}</ServiceDescription>
        </DescriptionWrapper>
      </Content>
    </CardStyled>
  );
}
export default DoctorInfoCardComponent;
