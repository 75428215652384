import {
  TemplatesComponentsEvent,
  TemplatesComponentActions,
  TemplatesComponentSubmitActions,
  TemplatesComponentActiveTemplateToggle,
  TemplatesComponentAddActions,
} from "src/components/TemplatesComponents";
import { useTemplatesState } from "src/store/templates/hooks";
import { useTemplateContext } from "src/context/TemplatesContext";
import { Wrapper } from "./styled";

function TemplatesEventActionsView() {
  const { selectedTemplate, isShowAddNewAction } = useTemplateContext();
  const { selectedTemplateData } = useTemplatesState();

  return (
    <Wrapper>
      <TemplatesComponentsEvent />

      {selectedTemplate?.selectedEvent && (
        <>
          {selectedTemplateData && <TemplatesComponentActiveTemplateToggle />}

          <TemplatesComponentActions />

          {isShowAddNewAction && <TemplatesComponentAddActions />}

          <TemplatesComponentSubmitActions />
        </>
      )}
    </Wrapper>
  );
}

export default TemplatesEventActionsView;
