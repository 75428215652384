import React, { FunctionComponent } from "react";
import { Svg, SvgProps } from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
  <Svg width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
    <path
      d="M33.332 6.66699H6.66536C4.82442 6.66699 3.33203 8.15938 3.33203 10.0003V30.0003C3.33203 31.8413 4.82442 33.3337 6.66536 33.3337H33.332C35.173 33.3337 36.6654 31.8413 36.6654 30.0003V10.0003C36.6654 8.15938 35.173 6.66699 33.332 6.66699Z"
      stroke="#C4C4C4"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.6654 11.667L21.7154 21.167C21.2008 21.4894 20.6059 21.6603 19.9987 21.6603C19.3915 21.6603 18.7966 21.4894 18.282 21.167L3.33203 11.667"
      stroke="#C4C4C4"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Icon;
