import { Button } from "src/UI/Button";
import styled from "styled-components";

export const ListStyled = styled.div<{ isShow: boolean }>`
  position: absolute;
  width: 100%;
  background: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.03);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
  min-height: 56px;
  max-height: 190px;
  padding-top: 56px;
  overflow-y: auto;
  visibility: ${({ isShow }) => (isShow ? "visible" : "hidden")};
  opacity: ${({ isShow }) => (isShow ? "1" : "0")};
`;

export const ListStyledInner = styled.div`
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
`;

export const ShowAllBlock = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 7px;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.green};
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 16px;
    font-size: 12px;
    gap: 5px;
  }
`;

export const LoadingWrapStyled = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EmptyTextStyled = styled.div`
  color: ${({ theme }) => theme.colors.gray};
`;

export const EmptyWrap = styled.div`
  width: 100%;
  padding: 15px;
  display: flex;
  align-items: center;
`;

export const AddButton = styled(Button)`
  padding: 7px;
  max-width: unset;
  width: fit-content;
  border-radius: 50px;
  margin-right: 10px;
`;
