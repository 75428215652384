import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFindAndCountManyClinicsByClientId } from "src/graphql/hooks/useQueries";
import { CountedClinicsResponse } from "src/graphql/generated";
import ClinicsList from "./ClinicsList/ClinicsList";
import { WrapStyled } from "./styles";

interface PageProps {
  loading: boolean;
  fetchMore: (
    options: Parameters<
      ReturnType<typeof useFindAndCountManyClinicsByClientId>["fetchMore"]
    >[0]
  ) => Promise<unknown>;
  data: CountedClinicsResponse | null;
}

function ClinicsPage({ loading, fetchMore, data }: PageProps) {
  const { i18n } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const langCode = queryParams.get("langCode");

  useEffect(() => {
    i18n.changeLanguage(langCode as string);
  }, []);

  return (
    <WrapStyled>
      <ClinicsList loading={loading} fetchMore={fetchMore} data={data} />
    </WrapStyled>
  );
}
export default ClinicsPage;
