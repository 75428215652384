import styled from "styled-components";

export const DropdownInputStyled = styled.div<{
  isRegister: boolean;
  customWidth?: string;
  isDisabled: boolean;
}>`
  width: ${({ isRegister }) => (isRegister ? "100%" : "auto")};
  position: relative;
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: ${({ customWidth }) => customWidth || "180px"};
  background: ${({ isDisabled }) => (isDisabled ? "#fafafa" : "transparent")};
  pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "auto")};
`;

export const Label = styled.label<{ isFocused: boolean }>`
  color: ${({ isFocused, theme }) =>
    theme.colors[isFocused ? "grayC4" : "gray"]};
  position: absolute;
  top: ${({ isFocused }) => (isFocused ? "0" : "50%")};
  transform: translateY(-50%);
  left: 0;
  font-weight: 500;
  font-size: ${({ isFocused }) => (isFocused ? "12px" : "16px")};
  line-height: ${({ isFocused }) => (isFocused ? "100%" : "140%")};
`;

export const RowStyled = styled.div<{
  showMenu: boolean;
  isFocused: boolean;
  isRegister: boolean;
}>`
  font-style: normal;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  gap: 4px;
  background: none;
  border-radius: 0;
  color: ${({ theme }) => theme.colors.black};
  white-space: nowrap;

  width: 100%;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.black};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  padding: ${({ isRegister }) => (isRegister ? "26px 0" : "15px 0")};

  &:focus {
    border-color: ${({ theme }) => theme.colors.green};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray};
  }
`;

export const IconStyled = styled.div<{ showMenu: boolean }>`
  margin-left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ showMenu }) =>
    showMenu
      ? `svg {
    transform: rotateZ(-180deg);
  };`
      : ""}
`;

export const BtnsRowStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
`;

export const IconButton = styled.div`
  display: flex;
  align-items: center;
`;

export const Menu = styled.div<{ showMenu: boolean }>`
  visibility: ${({ showMenu }) => (showMenu ? "visible" : "hidden")};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  gap: 2px;
  padding: 4px 0;
  top: 100%;
  left: 0;
  box-sizing: border-box;
  right: 0;
  transition: all 0.25s ease-out;
  opacity: ${({ showMenu }) => (showMenu ? 1 : 0)};
  z-index: 100;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  border-top: none;
  border-radius: 0 0 5px 5px;
  height: ${({ showMenu }) => (showMenu ? "fit-content" : 0)};
  max-height: 300px;
  width: 100%;
  overflow-y: auto;
`;

export const SelectedValue = styled.div<{
  adaptedForMobile: boolean;
  isRegister: boolean;
}>`
  font-style: normal;
  font-weight: 500;
  min-height: 22px;
  font-size: 16px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  max-width: calc(100% - 20px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Placeholder = styled.div<{
  adaptedForMobile: boolean;
  isRegister: boolean;
}>`
  font-style: normal;
  font-weight: 500;
  min-height: 22px;
  font-size: 16px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.gray};
  cursor: pointer;
`;
// TODO commeted because it media query maybe be needs in the future
// ${({ theme }) => theme.mediaQueries.ll} {
//   font-size: ${({ adaptedForMobile }) =>
//     adaptedForMobile ? "12px" : "16px"};
//   min-height: ${({ adaptedForMobile }) =>
//     adaptedForMobile ? "18px" : "22px"};

export interface ErrorMessageProps {
  isRegister: boolean;
  top?: string;
}

export const ErrorMessage = styled.div<ErrorMessageProps>`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: right;
  color: ${({ theme }) => theme.colors.red};
  position: absolute;
  top: ${({ top }) => (top ? top : "0")};
  ${({ isRegister }) => {
    return isRegister ? "left: 0;" : "right: 0;";
  }}
`;
// TODO commeted because it media query maybe be needs in the future
// ${({ theme }) => theme.mediaQueries.ll} {
//   font-size: 12px;
// }

export const RegisterErrorMessage = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: right;
  color: ${({ theme }) => theme.colors.red};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

export const StyledInput = styled.input`
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  color: transparent;
  border: none;
  outline: none;
`;
