import styled from "styled-components";

export const HeadingStyled = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 79px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: ${({ color }) => `${color} !important` || "initial"};
`;

export const WrapperStyled = styled.div`
  height: 100%;
  width: 100%;
  max-width: 267px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 15px 0 15px;
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  align-items: center;
  gap: 10px;

  min-width: 32px;
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.colors.lightBiege};
  border-radius: 56px;
`;

export const ImageStyled = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  border-radius: 50%;
`;

export const UserOptionStyled = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: 100%;
  svg:first-child {
    margin-right: 6px;
    path {
      fill: ${({ theme }) => theme.colors.gray};
      stroke: ${({ theme }) => theme.colors.gray};
    }
  }
`;

export const ColumnStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: calc(100% - 36px);
`;

export const TextNameStyled = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.black};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TextTypeStyled = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.gray};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Text = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: ${({ theme }) => theme.colors.gray};
  margin-left: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
