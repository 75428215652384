import { gql } from "@apollo/client";

export const DELETE_SHEDULE_BY_ID_BY_CLIENT = gql`
  mutation DeleteScheduleByIdByClient(
    $schema: DeleteScheduleByIdByClientSchema!
  ) {
    deleteScheduleByIdByClient(schema: $schema) {
      deleted
    }
  }
`;
