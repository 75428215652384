import React from "react";
import styled from "styled-components";
import { CheckBoxProps } from "./types";
import { CheckIcon } from "../Svg";

const CheckBoxStyled = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 10px;
  position: relative;
`;

const Label = styled.label<{ isChecked: boolean; isDisabled: boolean }>`
  display: flex;
  width: 20px;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 20px;
  background: ${({ theme, isChecked }) =>
    theme.colors[isChecked ? "green" : "white"]};
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.03);
  border-radius: 3px;
  cursor: ${({ isDisabled }) => (isDisabled ? "default" : "pointer")};
  svg path {
    stroke: ${({ theme }) => theme.colors.white};
  }
`;

const Input = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  display; none;
`;

const Text = styled.p<{ isDisabled: boolean }>`
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.black};
  margin: 0;
  cursor: ${({ isDisabled }) => (isDisabled ? "default" : "pointer")};
`;

function CheckBox({
  value,
  onChange,
  id,
  label,
  disabled = false,
}: CheckBoxProps) {
  const onChangeHandler = () => {
    if (!disabled) {
      onChange();
    }
  };
  return (
    <CheckBoxStyled>
      <Input
        type="checkbox"
        disabled={disabled}
        checked={value}
        onChange={() => {}}
        id={id}
      />
      <Label
        htmlFor={id}
        isChecked={value}
        isDisabled={disabled}
        onClick={onChangeHandler}
      >
        <CheckIcon width={14} height={14} />
      </Label>
      {label && (
        <Text isDisabled={disabled} onClick={onChangeHandler}>
          {label}
        </Text>
      )}
    </CheckBoxStyled>
  );
}

export default CheckBox;
