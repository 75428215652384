import React, { FunctionComponent } from "react";
import { Svg, SvgProps } from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
  <Svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.6667 1.99902H3.33333C2.59695 1.99902 2 2.59602 2 3.33245V12.6664C2 13.4029 2.59695 13.9999 3.33333 13.9999H12.6667C13.403 13.9999 14 13.4029 14 12.6664V3.33245C14 2.59602 13.403 1.99902 12.6667 1.99902Z"
      stroke="#A5A5A5"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.99935 7.33189C6.73573 7.33189 7.33268 6.7349 7.33268 5.99847C7.33268 5.26203 6.73573 4.66504 5.99935 4.66504C5.26297 4.66504 4.66602 5.26203 4.66602 5.99847C4.66602 6.7349 5.26297 7.33189 5.99935 7.33189Z"
      stroke="#A5A5A5"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.999 9.99965L11.9417 7.94217C11.6917 7.69219 11.3526 7.55176 10.999 7.55176C10.6455 7.55176 10.3064 7.69219 10.0564 7.94217L3.99902 13.9999"
      stroke="#A5A5A5"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Icon;
