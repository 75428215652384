import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { EditIcon } from "src/UI/Svg";
import {
  CountedPatientsResponse,
  PatientEntityResponse,
  PatientGenders,
} from "src/graphql/generated";
import { PATHS } from "src/constants/constants";
import { useAuthContext } from "src/context/AuthContext";

import AvatarComponent from "src/components/AvatarComponent/AvatarComponent";
import PhoneTypographyComponent from "src/components/PhoneTypographyComponent/PhoneTypographyComponent";
import { getFormatDateByLocale } from "src/utils";
import { Loading } from "src/UI/Loading";
import { Actions, ButtonIcon, EmptyTextStyled, Table, Wrapper } from "./styled";

interface PatientsTableViewProps {
  patientsData: CountedPatientsResponse;
  patientsLoading: boolean;
  // handleRemovePatientById: (id: string) => () => void;
}

export interface EditDataProps {
  distribution: boolean;
  isEmail?: boolean;
  id: string;
}

function PatientsTableView({
  patientsData,
  patientsLoading,
}: // handleRemovePatientById,
PatientsTableViewProps) {
  const { t, i18n } = useTranslation();

  const { isDoctor } = useAuthContext();

  // TODO temporary comment
  // const queryByRole = isDoctor
  //   ? GET_FIND_AND_COUNT_MANY_PATIENTS_BY_DOCTOR
  //   : GET_FIND_AND_COUNT_MANY_PATIENTS_BY_CLIENT;

  // const [updatePatientByIdMutation, { loading: updatePatientLoading }] =
  //   useUpdatePatientById();

  // const onEdit =
  //   ({ distribution, id, isEmail = false }: EditDataProps) =>
  //   () => {
  //     updatePatientByIdMutation({
  //       variables: {
  //         schema: {
  //           id,
  //           languageISOCode: i18n.language.toUpperCase() as LanguageIsoCodes,
  //           ...(isEmail
  //             ? { emailDistribution: !distribution }
  //             : { smsDistribution: !distribution }),
  //         },
  //       },

  //       onCompleted() {
  //         toast.success(t("app.successful"));
  //       },
  //       refetchQueries: [queryByRole],
  //     });
  //   };

  const getGender = (gender: PatientGenders) => {
    const genderOpt = [
      {
        item: t("common.gender_male"),
        value: PatientGenders.Male,
      },
      {
        item: t("common.gender_female"),
        value: PatientGenders.Female,
      },
    ];
    const selectGender = genderOpt?.find(
      (gen: { item: string; value: PatientGenders }) => gen.value === gender
    );

    return selectGender?.item;
  };

  return (
    <Wrapper>
      {patientsLoading ? (
        <Loading padding="0" />
      ) : patientsData?.count ? (
        <Table>
          <thead>
            <tr>
              <th> </th>
              <th>{t("patients.add.labels.patient")}</th>
              <th>{t("patients.add.labels.birth")}</th>
              <th>{t("patients.add.labels.phone")}</th>
              <th>{t("patients.add.labels.email")}</th>
              <th>{t("patients.add.labels.gender")}</th>
              <th>{t("add_doctor.label.passport")}</th>
            </tr>
          </thead>
          <tbody>
            {patientsData?.patients?.map((item: PatientEntityResponse) => (
              <tr key={`Form-${item.id}`}>
                <td>
                  <AvatarComponent
                    firstName={item.firstName}
                    lastName={item.lastName}
                  />
                </td>
                <td>
                  {item.firstName} {item.lastName}
                </td>
                <td>
                  {item.dateOfBirth
                    ? getFormatDateByLocale(
                        item.dateOfBirth as string,
                        i18n?.resolvedLanguage
                      )
                    : item.dateOfBirth}
                </td>
                <td>
                  <PhoneTypographyComponent phone={item?.phoneNumber} />
                </td>
                <td>{item.email}</td>
                <td>{getGender(item.gender as PatientGenders)}</td>
                <td>{item.passportNumber}</td>
                <td>
                  <Actions>
                    {/* // TODO temporary comment */}
                    {/* <CheckBox
                      disabled={!item.email || updatePatientLoading}
                      value={item.emailDistribution}
                      onChange={onEdit({
                        distribution: item.emailDistribution,
                        isEmail: true,
                        id: item.id,
                      })}
                      label={t("patients.unsubscribe.labels.email")}
                    />
                    <CheckBox
                      disabled={updatePatientLoading}
                      value={item.smsDistribution}
                      onChange={onEdit({
                        distribution: item.smsDistribution,

                        id: item.id,
                      })}
                      label={t("patients.unsubscribe.labels.sms")}
                    /> */}
                    {!isDoctor ? (
                      <ButtonIcon>
                        <Link
                          to={`${PATHS.cp}${PATHS.patients}${PATHS.patient}/${item.id}`}
                        >
                          <EditIcon />
                        </Link>
                      </ButtonIcon>
                    ) : null}
                    {/* TODO temporary comment
                    <ButtonIcon onClick={handleRemovePatientById(item.id)}>
                      <DeleteIcon />
                    </ButtonIcon> */}
                  </Actions>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTextStyled>{t("app.patients_not_added")}</EmptyTextStyled>
      )}
    </Wrapper>
  );
}

export default PatientsTableView;
