import * as yup from "yup";

export const passwordRepeatValidationSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, "auth.reg.password_error_min")
    .required("validation.field_required"),
  passwordRepeat: yup.lazy((value) =>
    value
      ? yup.string().oneOf([yup.ref("password")], "auth.reg.password_must_mach")
      : yup.string().notRequired()
  ),
});
