import React, { ChangeEvent, ReactNode, useState } from "react";

import { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CheckIcon, EyeIcon } from "src/UI/Svg";
import {
  ErrorMessage,
  IconPassword,
  IconWrapper,
  InputBlock,
  InputStyled,
  InputWrapper,
} from "./styles";

interface InputProps {
  children?: ReactNode;
  placeholder: string;
  val: string;
  inputType: string;
  errorMessage?: FieldError;
  register: any;
  handleChange?: (value: any) => void;
}

const InputComponent = ({
  children,
  val,
  placeholder,
  errorMessage,
  register: { onChange, name, ...registerRest },
  inputType,
  handleChange,
}: InputProps) => {
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleChangeOwn = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e);

    if (handleChange) handleChange(e.target.value);
  };

  return (
    <InputWrapper>
      <InputBlock>
        {errorMessage && (
          <ErrorMessage>
            {errorMessage?.message && t(errorMessage.message)}
          </ErrorMessage>
        )}
        <InputStyled
          {...registerRest}
          name={name}
          onChange={handleChangeOwn}
          isError={!!errorMessage}
          type={isShowPassword ? "text" : inputType}
          placeholder={placeholder}
        />
        {inputType === "email"
          ? val &&
            val !== "" &&
            !errorMessage && (
              <IconWrapper>
                <CheckIcon />
              </IconWrapper>
            )
          : inputType === "password" && (
              <IconPassword
                isShow={isShowPassword}
                onClick={() => setIsShowPassword(!isShowPassword)}
              >
                <EyeIcon />
              </IconPassword>
            )}
      </InputBlock>
      {children}
    </InputWrapper>
  );
};

export default InputComponent;
