import { gql } from "@apollo/client";

export const CALCULATE_CLINIC_CONSTRAINTS_BY_CLIENT = gql`
  query CalculateClinicConstraintsByClient(
    $schema: CalculateClinicConstraintsByClientSchema!
  ) {
    calculateClinicConstraintsByClient(schema: $schema) {
      mailLimit
      mailSent
      smsSent
      smsLimit
    }
  }
`;
