import { useEffect } from "react";
import { useFindServiceByIdLazyQuery } from "src/graphql/generated";
import { useBookingAction } from "src/store/booking/hooks";

export const useServiceEffect = (serviceId: string | null) => {
  const { onSetService } = useBookingAction();
  const [getServiceById] = useFindServiceByIdLazyQuery();

  useEffect(() => {
    if (serviceId) {
      getServiceById({
        variables: {
          schema: {
            id: serviceId,
          },
        },
        onCompleted(response) {
          if (response) {
            onSetService({ service: response.findServiceById });
          }
        },
      });
    }
  }, [serviceId]);
};
