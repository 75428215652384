import { Button } from "src/UI/Button";
import styled from "styled-components";

export const ButtonsWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 30px;
  gap: 5px;

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: column;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    padding-right: 0;
  }
`;

export const ButtonStyled = styled(Button)`
  padding: 8px 10px;
  font-size: 16px;
  line-height: 100%;
  width: fit-content;
  border-radius: 5px;
  outline: none !important;
  min-width: 95px;
`;

export const TextStyled = styled.p`
  margin-right: 10px;
`;
