import styled from "styled-components";

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  gap: 16px;
  flex-direction: column;
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 12px;
  }
`;

export const InputBlock = styled.div`
  position: relative;
  width: 100%;
`;

export const SuggestionItem = styled.div`
  padding: 7px 14px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.greenHover};
  }
`;

export const Suggestions = styled.div`
  position: absolute;
  z-index: 5;
  background: white;
  top: 100%;
  width: 100%;
  left: 0;
  max-height: 300px;
  overflow: auto;
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  border-radius: 0 0 5px 5px;
  padding: 4px 0;
`;

export const ErrorMessage = styled.div`
  position: absolute;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: right;
  color: ${({ theme }) => theme.colors.red};
  top: 0;
  left: 0;
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 12px;
  }
`;

export const InputStyled = styled.input<{ isError: boolean }>`
  width: 100%;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.black};
  padding: 32px 0;
  border-bottom: 1px solid
    ${({ theme, isError }) =>
      theme.colors[isError ? "red" : "borderInputDefault"]};

  &:focus {
    border-color: ${({ theme, isError }) =>
      theme.colors[isError ? "red" : "green"]};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray};
  }
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 26px 0;
  }
`;
