import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HeaderCover } from "src/UI/Header";
import { LangSelectComponent } from "src/components/LangSelectComponets";
import { LogoFullIcon } from "src/UI/Svg";
import { Heading } from "src/UI/Heading";
import { InputComponent } from "src/components/AuthComponents";
import { EButtonVariants } from "src/UI/Button";
import {
  useSignIn,
  useChangeMail,
  useConfirmMail,
} from "src/graphql/hooks/useMutations";
import { toast } from "react-toastify";
import { useAuthContext } from "src/context/AuthContext";
import {
  IS_COMPANY_EXIST,
  IS_PINCODE_EXIST,
  JWT,
  PATHS,
  PERMISSION_2FA,
} from "src/constants/constants";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginValidationSchema } from "src/validation/schemas/loginSchema";
import { Loading } from "src/UI/Loading";
import { useClinicsAction, useClinicsState } from "src/store/clinics/hooks";
import { client } from "src/graphql/client";
import Cookies from "js-cookie";
import {
  BlockUnderInput,
  ButtonStyled,
  FooterLink,
  FooterLinkRight,
  FooterLinks,
  ForgotLink,
  Form,
  FormWrapper,
  WrapperNav,
} from "./styled";

interface FormValues {
  email: string;
  password: string;
}
function LoginPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const { pathname } = useLocation();
  const confirmationHash = query.get("confirmationHash");
  const changeEmailHash = query.get("changeHash");
  const { currentLocation } = useClinicsState();
  const [confirmMail] = useConfirmMail();
  const [changeMail] = useChangeMail();
  const { onSuccessAuth, refetchUser } = useAuthContext();
  const { onSetClinicId, onSetLocation } = useClinicsAction();

  const isChangeAccountPath = pathname === `${PATHS.vr}${PATHS.addAccount}`;

  useEffect(() => {
    if (confirmationHash)
      confirmMail({
        variables: {
          schema: {
            confirmationHash,
          },
        },
        onCompleted() {
          toast.success(t("auth.reg.email_confirmed"));
        },
        onError(error) {
          toast.error(error.message);
        },
      });
  }, [confirmationHash]);
  useEffect(() => {
    if (changeEmailHash)
      changeMail({
        variables: {
          schema: {
            changeHash: changeEmailHash,
          },
        },
        onCompleted() {
          toast.success(t("auth.reg.email_updated"));
        },
        onError(error) {
          toast.error(error.message);
        },
      });
  }, [changeEmailHash]);

  const methods = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(loginValidationSchema),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    register,
    formState: { errors, isValid },
    watch,
    handleSubmit,
  } = methods;

  const [signInMutation, { loading }] = useSignIn();

  const data = watch();

  const loginHandler = () => {
    const currentClinicId = localStorage.getItem("currentClinicId");

    const cookieJWT = Cookies.get(JWT);
    const cookiePermission2fa = Cookies.get(PERMISSION_2FA);
    const cookieIsCompanyExist = Cookies.get(IS_COMPANY_EXIST);
    const cookieIsPinCodeExist = Cookies.get(IS_PINCODE_EXIST);

    if (isChangeAccountPath) {
      localStorage.removeItem("currentClinicId");
      onSetClinicId("");
      onSetLocation(null);
      Cookies.remove(JWT);
      Cookies.remove(PERMISSION_2FA);
      Cookies.remove(IS_COMPANY_EXIST);
      Cookies.remove(IS_PINCODE_EXIST);
      client.clearStore();
    }

    signInMutation({
      variables: {
        schema: {
          email: data.email,
          password: data.password,
        },
      },
      onError: (error) => {
        if (error) {
          toast.error(error.message);
        }
        if (isChangeAccountPath) {
          localStorage.setItem("currentClinicId", currentClinicId as string);
          onSetLocation(currentLocation);
          onSetClinicId(currentClinicId as string);
          Cookies.set(JWT, cookieJWT as string);
          Cookies.set(PERMISSION_2FA, cookiePermission2fa as string);
          Cookies.set(IS_COMPANY_EXIST, cookieIsCompanyExist as string);
          Cookies.set(IS_PINCODE_EXIST, cookieIsPinCodeExist as string);
          refetchUser();
        }
      },
      onCompleted: (response) => {
        if (response.localSignIn.twoFactorAuthenticationEnabled) {
          onSuccessAuth(true, false);

          navigate(`${PATHS.vr}${PATHS.auth2fa}`);
        } else {
          onSuccessAuth(true, true);
          refetchUser();

          navigate(`${PATHS.vr}${PATHS.create2fa}`);
        }
      },
    });
  };
  return (
    <>
      <WrapperNav>
        <LogoFullIcon />
      </WrapperNav>
      <FormWrapper>
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(loginHandler)}>
            <HeaderCover>
              <Heading as="h2">{t("auth.login.title")}</Heading>
              <LangSelectComponent />
            </HeaderCover>
            <InputComponent
              placeholder={t("auth.login.email")}
              inputType="email"
              val={data.email}
              errorMessage={errors.email}
              register={register("email")}
            />
            <InputComponent
              placeholder={t("auth.login.password")}
              inputType="password"
              val={data.password}
              errorMessage={errors.password}
              register={register("password")}
            >
              {!isChangeAccountPath ? (
                <BlockUnderInput>
                  <ForgotLink
                    to={`${PATHS.auth}${PATHS.restore}${PATHS.password}`}
                  >
                    {t("auth.login.forgot")}
                  </ForgotLink>
                </BlockUnderInput>
              ) : null}
            </InputComponent>

            <ButtonStyled
              variant={EButtonVariants.Default}
              disabled={!isValid || loading}
            >
              {loading ? <Loading type="secondary" padding="0" /> : null}
              {t("auth.login.sign_in_button")}
            </ButtonStyled>
          </Form>
        </FormProvider>
      </FormWrapper>
      {!isChangeAccountPath ? (
        <FooterLinks>
          <FooterLink to={`${PATHS.auth}${PATHS.registration}`}>
            {t("auth.login.account")}
          </FooterLink>
          <FooterLinkRight to={`${PATHS.auth}${PATHS.registration}`}>
            {t("auth.login.sign_up")}
          </FooterLinkRight>
        </FooterLinks>
      ) : null}
    </>
  );
}
export default LoginPage;
