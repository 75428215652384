import { useAuthContext } from "src/context/AuthContext";
import { SearchTypes } from "src/graphql/generated";
import { useSearchByDoctorFetchMore } from "./useSearchByDoctorFetchMore";
import { useSearchByClientFetchMore } from "./useSearchByClientFetchMore";

interface IUseSearchByRoleFetchMore {
  filter: SearchTypes;
  query: string | null;
}

export function useSearchByRoleFetchMore({
  filter,
  query,
}: IUseSearchByRoleFetchMore) {
  const { isDoctor } = useAuthContext();

  const {
    data: clientData,
    loading: clientLoading,
    node: clientNode,
    isLoading: clientIsLoading,
  } = useSearchByClientFetchMore({ filter, query });

  const {
    data: doctorData,
    loading: doctorLoading,
    node: doctorNode,
    isLoading: doctorIsLoading,
  } = useSearchByDoctorFetchMore({ filter, query });

  const data = isDoctor
    ? doctorData?.searchByDoctor
    : clientData?.searchByClient;
  const loading = isDoctor ? doctorLoading : clientLoading;
  const node = isDoctor ? doctorNode : clientNode;
  const isLoading = isDoctor ? doctorIsLoading : clientIsLoading;

  return {
    data,
    loading,
    node,
    isLoading,
  };
}
