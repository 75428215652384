import React, { FunctionComponent } from "react";
import { Svg, SvgProps } from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <g clipPath="url(#)">
      <path
        d="M11.9999 0C9.41389 0 7.31055 2.10392 7.31055 4.68995V7.11969C7.31055 9.70591 9.41389 11.8098 11.9999 11.8098C14.5861 11.8098 16.6893 9.70591 16.6893 7.11969V4.68995C16.6893 2.10392 14.5861 0 11.9999 0Z"
        fill="#0BA495"
      />
      <path
        d="M22.3422 17.9217C20.8406 15.3628 18.4404 13.4656 15.5839 12.5798C15.5176 12.5592 15.446 12.5767 15.3967 12.6254C14.0678 13.9356 12.4166 14.9578 12.0038 15.2048C11.5632 14.9231 9.70716 13.7133 8.60375 12.6254C8.5546 12.5767 8.48241 12.5592 8.41655 12.5798C5.55961 13.4658 3.15963 15.363 1.65859 17.9219C1.62422 17.9805 1.62422 18.0532 1.65859 18.1118C3.79016 21.744 7.75281 24.0004 12.0002 24.0004C16.2478 24.0004 20.2106 21.744 22.3422 18.1118C22.3768 18.0531 22.3768 17.9803 22.3422 17.9217ZM18.3782 19.3206C18.3782 19.4241 18.2797 19.5032 18.1764 19.5032H16.9063C16.8029 19.5032 16.69 19.5921 16.69 19.6958V21.0093C16.69 21.1125 16.6347 21.1915 16.5312 21.1915H15.3905C15.287 21.1915 15.1895 21.1125 15.1895 21.0093V19.6958C15.1891 19.5923 15.1184 19.5032 15.015 19.5032H13.6902C13.5867 19.5032 13.5011 19.4239 13.5011 19.3206V18.1898C13.5011 18.0863 13.5867 18.0028 13.6902 18.0028H15.015C15.1184 18.0028 15.1891 17.9183 15.1891 17.8146V16.4996C15.1891 16.3961 15.2866 16.3145 15.3901 16.3145H16.5185C16.6218 16.3145 16.6898 16.3961 16.6898 16.4996V17.8184C16.6898 17.9221 16.79 18.0026 16.8937 18.0026H18.1764C18.2797 18.0026 18.3782 18.0901 18.3782 18.1936V19.3206Z"
        fill="#0BA495"
      />
    </g>
    <defs>
      <clipPath id="">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </Svg>
);

export default Icon;
