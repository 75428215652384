import { gql } from "@apollo/client";

export const CREATE_SCHEDULE = gql`
  mutation CreateSchedule($schema: CreateScheduleSchema!) {
    createSchedule(schema: $schema) {
      id
      doctorId
      clinicId
      roomId
      eventType
      layoutType
      daysOfWeek
      dateFrom
      dateTo
      minuteFrom
      minuteTo
      createdAt
    }
  }
`;
