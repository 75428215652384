import { gql } from "@apollo/client";

export const CREATE_SCHEDULE_WAITLIST_BY_CLIENT = gql`
  mutation CreateScheduleWaitlistByClient(
    $schema: CreateScheduleWaitlistByClientSchema!
  ) {
    createScheduleWaitlistByClient(schema: $schema) {
      id
      hash
      dateTo
      createdAt
      clinicId
      dateFrom
      minuteTo
      patientId
      serviceId
      doctorId
      minuteFrom
      status
      uncommittedPatientId
    }
  }
`;
