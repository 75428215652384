import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutsideClick } from "src/hooks/useOutsideClick";
import { SearchIcon } from "src/UI/Svg";
import {
  SearchByClientUnion,
  SearchByDoctorUnion,
  useAutocompleteByClientLazyQuery,
  useAutocompleteByDoctorLazyQuery,
} from "src/graphql/generated";
import { toast } from "react-toastify";
import { useAuthContext } from "src/context/AuthContext";
import { Loading } from "src/UI/Loading";
import ResultListComponent from "../ResultListComponent/ResultListComponent";
import { Icon, Input, LoadingWrap, SearchBlock, SearchWrapper } from "./styled";

function SearchComponent() {
  const { t } = useTranslation();
  const { isDoctor } = useAuthContext();
  const [search, setSearch] = useState<string>("");
  const [isShow, setIsShow] = useState<boolean>(false);
  const form = useRef(null);

  const [
    autocompleteLazyByClient,
    {
      data: autocompleteLazyByClientData,
      loading: autocompleteLazyByClientLoading,
    },
  ] = useAutocompleteByClientLazyQuery();

  const [
    autocompleteLazyByDoctor,
    {
      data: autocompleteLazyByDoctorData,
      loading: autocompleteLazyByDoctorLoading,
    },
  ] = useAutocompleteByDoctorLazyQuery();

  useEffect(() => {
    if (search.length && !isDoctor) {
      autocompleteLazyByClient({
        variables: { schema: { query: search } },
        onError(error) {
          toast.error(error.message);
        },
      });
    }
    if (search.length && isDoctor) {
      autocompleteLazyByDoctor({
        variables: { schema: { query: search } },
        onError(error) {
          toast.error(error.message);
        },
      });
    }
  }, [search]);

  const autocompleteResultByRole = isDoctor
    ? autocompleteLazyByDoctorData?.autocompleteByDoctor.result
    : autocompleteLazyByClientData?.autocompleteByClient.result;

  const loading =
    autocompleteLazyByClientLoading || autocompleteLazyByDoctorLoading;

  useOutsideClick({
    ref: form,
    handler: () => setIsShow(() => false),
  });

  useEffect(() => {
    setIsShow(!!search.length);
  }, [search]);

  const handleClose = () => {
    setSearch("");
    setIsShow((prev) => !prev);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <SearchWrapper ref={form}>
      <SearchBlock>
        <Input
          isShow={isShow}
          placeholder={t("search.placeholder") as string}
          value={search}
          onChange={handleChange}
        />
        <Icon>
          {loading ? (
            <LoadingWrap>
              <Loading padding="0" />
            </LoadingWrap>
          ) : (
            <SearchIcon />
          )}
        </Icon>
      </SearchBlock>
      <ResultListComponent
        isShow={isShow}
        onClose={handleClose}
        autocompleteResultByRole={
          autocompleteResultByRole as
            | SearchByClientUnion[]
            | SearchByDoctorUnion[]
        }
        search={search}
        setIsShow={setIsShow}
      />
    </SearchWrapper>
  );
}

export default SearchComponent;
