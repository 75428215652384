import { gql } from "@apollo/client";

export const COUNT_SCHEDULE_APPOINTMENTS_BY_STATUSES_BY_CLIENT = gql`
  query CountScheduleAppointmentsByStatusesByClient(
    $schema: CountScheduleAppointmentsByStatusesByClientSchema
  ) {
    countScheduleAppointmentsByStatusesByClient(schema: $schema) {
      all
      canceled
      confirmedByApplication
      confirmedByPatient
      missed
      new
      successful
    }
  }
`;
