import styled from "styled-components";

export const SelectWrapper = styled.div`
  background: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: drop-shadow(0px 5px 25px rgba(33, 44, 65, 0.03));
  border-radius: 40px;
  position: relative;
  z-index: 10;
  user-select: none;
`;

export const DefaultState = styled.div`
  text-align: center;
  padding: 8px;
  cursor: pointer;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 5px 25px 0px #212c4108;
  z-index: 1;
`;

export const LangList = styled.div<{ isAuthLayout: boolean }>`
  position: absolute;
  left: 50%;
  top: 0;
  width: ${({ isAuthLayout }) => (isAuthLayout ? "100%" : "52px")};
  padding: ${({ isAuthLayout }) => (isAuthLayout ? "0" : "0 8px 8px 8px")};
  padding-top: 40px;
  transform: translateX(-50%);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  overflow: hidden;
`;

export const LangItem = styled.div<{
  isSelected: boolean;
  isAuthLayout: boolean;
}>`
  width: ${({ isAuthLayout }) => (isAuthLayout ? "100%" : "35px")};
  height: ${({ isAuthLayout }) => (isAuthLayout ? "auto" : "35px")};
  border-radius: ${({ isAuthLayout }) => (isAuthLayout ? "0" : "50%")};
  padding: 5px 0;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme, isSelected }) =>
    theme.colors[isSelected ? "greenHover" : "white"]};
`;
