import { useAuthContext } from "src/context/AuthContext";
import {
  SearchTypes,
  useSearchByClientQuery,
  useSearchByDoctorQuery,
} from "src/graphql/generated";

interface IUseSearchCountByRole {
  query: string | null;
}

export function useSearchCountByRole({ query }: IUseSearchCountByRole) {
  const { isDoctor, currentUserData } = useAuthContext();

  const { data: patientSearchDataByClient } = useSearchByClientQuery({
    variables: {
      schema: {
        offset: 0,
        limit: 1,
        type: SearchTypes.Patient,
        query: query as string,
      },
    },
    skip: isDoctor || !query || !currentUserData,
  });

  const { data: appointmentSearchDataByClient } = useSearchByClientQuery({
    variables: {
      schema: {
        offset: 0,
        limit: 1,
        type: SearchTypes.Appointment,
        query: query as string,
      },
    },
    skip: isDoctor || !query || !currentUserData,
  });

  const { data: patientSearchDataByDoctor } = useSearchByDoctorQuery({
    variables: {
      schema: {
        offset: 0,
        limit: 1,
        type: SearchTypes.Patient,
        query: query as string,
      },
    },
    skip: !isDoctor || !query || !currentUserData,
  });

  const { data: appointmentSearchDataByDoctor } = useSearchByDoctorQuery({
    variables: {
      schema: {
        offset: 0,
        limit: 1,
        type: SearchTypes.Appointment,
        query: query as string,
      },
    },
    skip: !isDoctor || !query || !currentUserData,
  });

  const patientCountByRole = isDoctor
    ? patientSearchDataByDoctor?.searchByDoctor.count
    : patientSearchDataByClient?.searchByClient.count;

  const appointmentCountByRole = isDoctor
    ? appointmentSearchDataByDoctor?.searchByDoctor.count
    : appointmentSearchDataByClient?.searchByClient.count;

  return {
    patientsCount: patientCountByRole,
    appointmentsCount: appointmentCountByRole,
  };
}
