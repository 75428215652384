import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import {
  AppointmentSortFields,
  AppointmentStatuses,
  ScheduleAppointmentsCountByStatusesResponse,
  SortingOrders,
} from "src/graphql/generated";
import { IAppointmentsFilters, TScheduleAppointmentByRole } from "src/types";

import { ReminderTableComponent } from "src/components/DashboardComponents/ReminderComponent";
import { endOfDay, format } from "date-fns";
import { useScheduleAppointmentsByRole } from "src/hooks/appointments/pagination/useScheduleAppointmentsByRole";
import { useCountStatusesByRole } from "src/hooks/appointments/useCountStatusesByRole";
import { TEMPORARY_REMINDERS_LIMIT } from "src/constants/constants";
import { getMinutesFromStartDay } from "src/utils";
import { Header, HeadingStyled, TabsStyled, Wrapper } from "./styled";

function RemindersView() {
  const { t } = useTranslation();

  const currentDate = new Date();

  const endOfDayCurrentDate = endOfDay(currentDate);

  const minuteFrom = getMinutesFromStartDay(currentDate);
  const minuteTo = getMinutesFromStartDay(endOfDayCurrentDate);

  const [filters, setFilters] = useState<IAppointmentsFilters>({
    sortField: AppointmentSortFields.ScheduleTimeFrom,
    sortOrder: SortingOrders.Ascending,
    dateFrom: format(currentDate, "yyyy-MM-dd"),
    dateTo: format(currentDate, "yyyy-MM-dd"),
    minuteFrom,
    minuteTo,
    status: "All" as AppointmentStatuses,
  });

  const RemindersScheduleAppointmentStatuseswithT: {}[] = [
    { All: t("appointments.tabs.all") },
    { New: t("appointments.tabs.new") },
    { ConfirmedByPatient: t("appointments.tabs.confirmed_by_patient") },
    {
      ConfirmedByApplication: t("appointments.tabs.confirmed_by_application"),
    },
  ];

  const appointmentsStatuses = RemindersScheduleAppointmentStatuseswithT.map(
    (status) => {
      const arrStatus = Object.entries(status)[0];
      if (arrStatus[0] === "All") {
        return {
          key: "All",
          value: arrStatus[1] as string,
        };
      }
      return {
        key: arrStatus[0] as string,
        value: arrStatus[1] as string,
      };
    }
  );

  const { data, loading: scheduleAppointmentsByRoleLoading } =
    useScheduleAppointmentsByRole({
      filters,
      limit: TEMPORARY_REMINDERS_LIMIT,
    });

  const { countStatusesByRole, countStatusesByRoleLoading } =
    useCountStatusesByRole({ filters });

  const loading =
    countStatusesByRoleLoading || scheduleAppointmentsByRoleLoading;

  return (
    <Wrapper>
      <Header>
        <HeadingStyled as="h3">{t("reminder.title")}</HeadingStyled>
      </Header>
      <TabsStyled
        headers={appointmentsStatuses}
        contents={
          <ReminderTableComponent
            remindersAppoitments={
              data?.appointments as TScheduleAppointmentByRole[]
            }
            loading={loading}
          />
        }
        filters={filters}
        setFilters={setFilters}
        countStatusesByRole={
          countStatusesByRole as ScheduleAppointmentsCountByStatusesResponse & {
            [key: string]: number;
          }
        }
      />
    </Wrapper>
  );
}

export default RemindersView;
