import React from "react";
import styled from "styled-components";
import ListWrapperComponent from "./ListWrapperComponent";
import RowCheckbox from "./RowCheckbox";

const ListWrapperStyled = styled(ListWrapperComponent)`
  & > div {
    gap: 0;
  }
`;

const services: { item: string; value: string }[] = [
  {
    item: "Badanie nowego pacjenta",
    value: "870 PLN",
  },
  {
    item: "Badanie w nagłych wypadkach",
    value: "370 PLN",
  },
  {
    item: "Konsultacje z CoastClear Aligners",
    value: "670 PLN",
  },
  {
    item: "Czyszczenie profilaktyczne",
    value: "1 000 PLN",
  },
  {
    item: "Czyszczenie perio",
    value: "1 870 PLN",
  },
  {
    item: "Stała opieka",
    value: "270 PLN",
  },
  {
    item: "Wybielanie zębów",
    value: "2 870 PLN",
  },
  {
    item: "Higiena jamy ustnej",
    value: "1 870 PLN",
  },
];
function SelectServicesComponent() {
  return (
    <ListWrapperStyled length={services.length}>
      {services.map((loc, id) => (
        <RowCheckbox key={`Loc-${id}`} {...loc} />
      ))}
    </ListWrapperStyled>
  );
}

export default SelectServicesComponent;
