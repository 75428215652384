import { gql } from "@apollo/client";

export const FIND_FILES_BY_IDS = gql`
  query FindManyFilesByIds($schema: FindManyFilesByIdsSchema!) {
    findManyFilesByIds(schema: $schema) {
      files {
        createdAt
        extension
        id
        size
        mimetype
        originalName
        resourceKey
        url
        usageStatus
        userId
      }
    }
  }
`;
