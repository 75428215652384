import { ApolloProvider } from "@apollo/client";
import { ToastContainer } from "react-toastify";
import ResetCSS from "./styles/ResetCSS";
import GlobalStyle from "./styles/Global";
import "mapbox-gl/dist/mapbox-gl.css";
import { Alert } from "./UI/Alert";
import { client } from "./graphql/client";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./context/AuthContext";
import { Router } from "./router/Router";

function App() {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <ResetCSS />
        <GlobalStyle />
        <Alert />
        <Router />
        <ToastContainer style={{ zIndex: 10001 }} />
      </AuthProvider>
    </ApolloProvider>
  );
}

export default App;
