import { useTranslation } from "react-i18next";
import { FormAnswerEntityWithRelationsResponse } from "src/graphql/generated";
import {
  TAppointmentByClient,
  TData,
  TScheduleAppointmentByRole,
} from "src/types";
import { useAppAction } from "src/store/app/hooks";
import { EButtonVariants } from "src/UI/Button";
import { getDateFromScheduleAppointmentDate } from "src/utils";
import { useAuthContext } from "src/context/AuthContext";
import { PatientAppointmentsStatus } from "./PatientAppointmentsStatus";
import {
  ClinicName,
  PatientAppointmentsTableStyled,
  StyledButton,
} from "./styled";
import { PatientAppointmentsDate } from "./PatientAppointmentsDate";

interface IPatientAppointmentsTableProps {
  data: TScheduleAppointmentByRole[];
}

function PatientAppointmentsTable({ data }: IPatientAppointmentsTableProps) {
  const { t } = useTranslation();
  const { isClient } = useAuthContext();

  const {
    onShowScheduleAppointmentModal,
    onShowPatientFilledFormPreviewModal,
  } = useAppAction();

  const handleOpenPreviewAppointment =
    (appointmentItem: TScheduleAppointmentByRole) => async () => {
      onShowScheduleAppointmentModal({
        data: {
          appointment: appointmentItem,
        },
      });
    };

  const handleOpenPreviewFilledForm =
    (answer: FormAnswerEntityWithRelationsResponse) => () => {
      const activityAnswers: TData = {
        patientForm: {
          answer,
          fields: answer.form.fields,
        },
        date: new Date(answer?.createdAt),
        customTitle: answer.form.name,
      };

      onShowPatientFilledFormPreviewModal({
        tDataPreview: [activityAnswers],
      });
    };

  return (
    <PatientAppointmentsTableStyled>
      <thead>
        <tr>
          <th>
            {t("waitlist.table.header.date")} {t("app.schedule_time")}
          </th>
          {isClient && <th>{t("waitlist.table.header.doctor")}</th>}
          <th>{t("waitlist.table.header.status")}</th>
          <th>{t("app.booking.patient.confirmed_clinic_name")}</th>
          <th>{t("forms.patients.headers.form_name")}</th>
          <th>{t("forms.forms.headers.submitted")}</th>
        </tr>
      </thead>

      <tbody>
        {data?.map((appointmentItem) => (
          <tr key={appointmentItem?.id}>
            <td>
              <StyledButton
                type="button"
                variant={EButtonVariants.Text}
                onClick={handleOpenPreviewAppointment(
                  appointmentItem as TScheduleAppointmentByRole
                )}
              >
                {appointmentItem?.schedule.dateFrom &&
                appointmentItem?.schedule.minuteFrom ? (
                  <PatientAppointmentsDate
                    date={getDateFromScheduleAppointmentDate(
                      appointmentItem?.schedule.dateFrom,
                      appointmentItem?.schedule.minuteFrom
                    )}
                  />
                ) : null}
              </StyledButton>
            </td>

            {isClient && (
              <td>
                {(appointmentItem as TAppointmentByClient)?.doctor?.firstName}{" "}
                {(appointmentItem as TAppointmentByClient)?.doctor?.lastName}
              </td>
            )}

            <td>
              <PatientAppointmentsStatus status={appointmentItem?.status} />
            </td>

            <td>
              <ClinicName>{appointmentItem?.clinic?.name}</ClinicName>
            </td>

            {!appointmentItem?.answer && (
              <td colSpan={2}>{t("patients.visit.no_filled_forms")}</td>
            )}

            {appointmentItem?.answer && (
              <>
                <td>
                  <StyledButton
                    type="button"
                    variant={EButtonVariants.Text}
                    onClick={handleOpenPreviewFilledForm(
                      appointmentItem?.answer
                    )}
                  >
                    {appointmentItem?.answer.form.name}
                  </StyledButton>
                </td>

                <td>
                  {appointmentItem?.answer && (
                    <PatientAppointmentsDate
                      date={appointmentItem?.answer.createdAt}
                    />
                  )}
                </td>
              </>
            )}
          </tr>
        ))}
      </tbody>
    </PatientAppointmentsTableStyled>
  );
}

export default PatientAppointmentsTable;
