import { PatientEntityResponse } from "src/graphql/generated";
import AvatarComponent from "src/components/AvatarComponent/AvatarComponent";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PATHS } from "src/constants/constants";
import { Dispatch, SetStateAction } from "react";
import { InfoBlock, Item, List, Name, UserBlock } from "./styled";

interface IPatientSearchComponent {
  item: PatientEntityResponse;
  setIsShow?: Dispatch<SetStateAction<boolean>>;
}

function PatientSearchComponent({ item, setIsShow }: IPatientSearchComponent) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleOpenPatientPage = () => {
    navigate({
      pathname: `${PATHS.cp}${PATHS.patients}${PATHS.patient}/${item.id}`,
    });
    if (setIsShow) setIsShow(false);
  };

  return (
    <UserBlock onClick={handleOpenPatientPage}>
      <AvatarComponent
        firstName={item.firstName as string}
        lastName={item.lastName as string}
        size={56}
      />
      <InfoBlock>
        <Name>{`${item.firstName} ${item.lastName}`}</Name>
        <List>
          <Item>{t("app.patient")}</Item>
          <Item>
            <span />
            {item.phoneNumber}
          </Item>
          <Item>
            <span />
            {item.email}
          </Item>
        </List>
      </InfoBlock>
    </UserBlock>
  );
}

export default PatientSearchComponent;
