import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginPage from "./LoginPage";
import RegistrationPage from "./RegistrationPage";
import RestorePassword from "./RestorePages/RestorePassword";
import NewPassword from "./RestorePages/NewPassword";
import ResetPassword from "./RestorePages/ResetPassword";
import RestoreEmail from "./RestorePages/RestoreEmail";
import CheckEmail from "./CheckEmail";
import NotFoundPage from "../DashboardPages/NotFoundPage";

function Auth() {
  return (
    <Routes>
      <Route path="/confirm-account" element={<LoginPage />} />
      <Route path="/change-email" element={<LoginPage />} />
      <Route path="/registration" element={<RegistrationPage />} />
      <Route path="/restore/password" element={<RestorePassword />} />
      <Route path="/restore-password" element={<NewPassword />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/restore/reset" element={<ResetPassword />} />
      <Route path="/restore/email" element={<RestoreEmail />} />
      <Route path="/check-email" element={<CheckEmail />} />
      <Route key="NotFound" path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default Auth;
