import React from "react";
import { DialogsView, MessengerAreaView } from "src/views/MessengerViews";
import { Wrapper } from "./styled";

function MessengerPage() {
  return (
    <Wrapper>
      <DialogsView />
      <MessengerAreaView />
    </Wrapper>
  );
}

export default MessengerPage;
