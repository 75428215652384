import styled from "styled-components";

export const ListStyled = styled.div<{ isShow: boolean }>`
  position: absolute;
  width: 100%;
  background: ${({ theme }) => theme.colors.background};
  background: #ffffff;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.03);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  top: 0;
  left: 0;
  right: 0;
  z-index: 0;
  visibility: ${({ isShow }) => (isShow ? "visible" : "hidden")};
  opacity: ${({ isShow }) => (isShow ? "1" : "0")};
  transition: 0.3s;
  padding-top: 56px;
  padding-bottom: 16px;
`;

export const ActionItemStiled = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  font-weight: 700;
  gap: 8px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 8px 20px;
  transition: 0.3s;
  color: ${({ theme }) => theme.colors.gray};
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 12px;
    adding: 8px 16px;
  }
  &:hover {
    background: #e7f6f5;
  }
`;
