import { CardComponent } from "src/components/UserComponents";
import styled from "styled-components";

export const CardStyled = styled(CardComponent)`
  max-width: 734px;
  z-index: 1;
  height: 100%;
  overflow: auto;
  padding-bottom: 40px;

  & > div:first-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    max-width: 100%;
  }
`;

export const LoadingWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

export const List = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  padding: 20px;
`;

export const EmptyTextStyled = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.gray};
`;

export const NodeStyled = styled.div`
  width: 100%;
`;
