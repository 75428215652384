import { gql } from "@apollo/client";

export const FIND_AND_COUNT_MANY_DOCTORS_BY_CLINIC_ID = gql`
  query FindAndCountManyDoctorsByClinicId(
    $schema: FindAndCountManyDoctorsByClinicIdSchema!
  ) {
    findAndCountManyDoctorsByClinicId(schema: $schema) {
      count
      doctors {
        createdAt
        description
        doctorTypeId
        id
        medicalCertificateNumber
        passportNumber
        phoneNumber
        promoCode
        activated
        avatarFileId
        dateOfBirth
        doctorCreatedAt
        doctorId
        email
        firstName
        lastName
        roles
      }
    }
  }
`;
