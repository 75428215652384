import { gql } from "@apollo/client";

export const GET_FIND_DOCTOR_CLINICS_BY_DOCTOR_ID = gql`
  query FindManyDoctorClinicsByDoctorId(
    $schema: FindManyDoctorClinicsByDoctorIdSchema!
  ) {
    findManyDoctorClinicsByDoctorId(schema: $schema) {
      clinics {
        id
        companyId
        name
        address
        houseNumber
        index
        city
        logoFileId
        licenseNumber
        geographicalCoordinates {
          latitude
          longitude
        }
        code
        phoneNumber
        description
        hiddenFromPatients
        createdAt
        rooms {
          id
          clinicId
          name
        }
        workingDays {
          id
          clinicId
          day
          fromMinute
          toMinute
        }
      }
    }
  }
`;
