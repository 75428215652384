import { FC, useEffect } from "react";
import { format } from "date-fns";
import { getFNSLocaleByLang } from "src/utils";
import { useTranslation } from "react-i18next";
import { EButtonVariants } from "src/UI/Button";
import { useBookingAction } from "src/store/booking/hooks";
import { useAppAction } from "src/store/app/hooks";
import { useSearchParams } from "react-router-dom";
import { ButtonStyled, ButtonsWrapperStyled, TextStyled } from "./styles";
import { ComponentProps } from "./types";

const AddToWaitListCmponent: FC<ComponentProps> = ({
  slots,
  doctor,
  onSetCurrentStep,
}) => {
  const { i18n, t } = useTranslation();
  const { onShowAddToWaitListModal } = useAppAction();
  const { onSetDoctor, onSetSlot } = useBookingAction();
  const [query] = useSearchParams();
  const waitingApptHash = query.get("waitingApptHash") as string;

  useEffect(() => {
    if (waitingApptHash) {
      onShowAddToWaitListModal({
        accept: () => {
          onSetCurrentStep((prev) => prev + 1);
          onSetSlot(null);
        },
      });
    }
  }, [waitingApptHash]);

  const handleAddWaitList = () => {
    onSetDoctor({ doctor });
    onShowAddToWaitListModal({
      accept: () => {
        onSetCurrentStep((prev) => prev + 1);
        onSetSlot(null);
      },
    });
  };

  return (
    <ButtonsWrapperStyled>
      <TextStyled>
        {slots?.[0]?.slots?.[0]?.from &&
          `${t("app.registration_from")} ${format(
            new Date(slots?.[0]?.slots?.[0]?.from),
            "d MMMM yyyy",
            {
              locale: getFNSLocaleByLang(i18n.resolvedLanguage),
            }
          )}`}
      </TextStyled>
      <ButtonStyled
        variant={EButtonVariants.Default}
        type="button"
        onClick={handleAddWaitList}
      >
        {t("app.get_on_waiting_list")}
      </ButtonStyled>
    </ButtonsWrapperStyled>
  );
};

export default AddToWaitListCmponent;
