import { useEffect } from "react";
import { toast } from "react-toastify";
import {
  allClinicsItemId,
  localStorageVisitsPageNumberName,
} from "src/constants/constants";
import { useAuthContext } from "src/context/AuthContext";
import {
  AppointmentSortFields,
  AppointmentStatuses,
  SortingOrders,
  useFindAndCountManyScheduleAppointmentsWithRelationsByDoctorLazyQuery,
} from "src/graphql/generated";
import { useClinicsState } from "src/store/clinics/hooks";
import { IAppointmentsFilters } from "src/types";
import { usePagination } from "../../usePagination";

interface IGetAppointmentsByRole {
  filters: IAppointmentsFilters;
  limit: number;
}

export function useScheduleAppointmentsByDoctor({
  filters,
  limit,
}: IGetAppointmentsByRole) {
  const { isDoctor, currentUserData } = useAuthContext();
  const { currentClinicId: clinicId } = useClinicsState();

  const [getScheduleAppointmentsByDoctorLazy, { data, loading }] =
    useFindAndCountManyScheduleAppointmentsWithRelationsByDoctorLazyQuery();

  const { offset, currentPage, pages, setCurrentPage } = usePagination({
    limit,
    count:
      data?.findAndCountManyScheduleAppointmentsWithRelationsByDoctor.count ||
      0,
    localStorageKey: localStorageVisitsPageNumberName,
  });

  useEffect(() => {
    if (isDoctor && clinicId && currentUserData) {
      getScheduleAppointmentsByDoctorLazy({
        variables: {
          schema: {
            offset,
            limit,
            sortOrder: filters.sortOrder || SortingOrders.Descending,
            sortField: filters.sortField || AppointmentSortFields.CreatedAt,
            ...(clinicId !== allClinicsItemId && { clinicId }),
            ...(filters.status !== ("All" as AppointmentStatuses) && {
              status: filters.status,
            }),
            ...(filters.dateFrom && {
              dateFrom: filters.dateFrom.split("T")[0],
            }),
            ...(filters.dateTo && { dateTo: filters.dateTo.split("T")[0] }),
            ...(filters.minuteFrom && { minuteFrom: filters.minuteFrom }),
            ...(filters.minuteTo && { minuteTo: filters.minuteTo }),
          },
        },
        fetchPolicy: "no-cache",
        onError: (error) => {
          if (error) toast.error(error.message);
        },
      });
    }
  }, [filters, currentPage, isDoctor, clinicId]);

  return {
    data,
    loading,
    currentPage,
    pages,
    setCurrentPage,
  };
}
