import { useTranslation } from "react-i18next";

import { EButtonVariants } from "src/UI/Button";
import { PlusIcon } from "src/UI/Svg";
import { FC } from "react";
import { NavigateAction } from "react-big-calendar";
import { PATHS } from "src/constants/constants";
import { useNavigate } from "react-router-dom";
import { ButtonStyled, Wrapper } from "./styles";
import SettingComponent from "../SettingComponent/SettingComponent";

interface ComponentProps {
  onNavigate: (navigate: NavigateAction, date?: Date | undefined) => void;
}
const FiltersComponent: FC<ComponentProps> = ({ onNavigate }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleAddAppointment = () => {
    navigate(`${PATHS.cp}${PATHS.booking}${PATHS.settings}${PATHS.addVisit}`);
  };

  return (
    <Wrapper>
      <SettingComponent onNavigate={onNavigate} />
      <ButtonStyled
        onClick={handleAddAppointment}
        variant={EButtonVariants.Default}
      >
        <PlusIcon />
        {t("calendar.button")}
      </ButtonStyled>
    </Wrapper>
  );
};

export default FiltersComponent;
