import { useTranslation } from "react-i18next";

import { WorktimeComponent } from "src/components/MapComponents";
import { useParams } from "react-router-dom";
import { CardStyled, Content, Wrap } from "./styled";

function WorktimeView() {
  const { t } = useTranslation();
  const { clinicId } = useParams();

  return (
    <CardStyled
      title={t("map.add_location.work_time.title")}
      disableBlock={!clinicId}
    >
      <Content>
        <Wrap>
          <WorktimeComponent />
        </Wrap>
      </Content>
    </CardStyled>
  );
}

export default WorktimeView;
