import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 0 10px;
  gap: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault}; ;
`;
export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  padding: 0 30px;
`;

export const Unsubscribed = styled.button`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 7px;
  font-weight: 500;
  width: fit-content;
  height: fit-content;
  font-size: 16px;
  border: none;
  padding: 8px 10px;
  border-radius: 5px;
  line-height: 100%;
  background: ${({ theme }) => theme.colors.borderInputDefault};
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  svg path {
    stroke: ${({ theme }) => theme.colors.black};
    stroke-width: 2;
  }
`;
