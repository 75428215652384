import * as yup from "yup";

export const createServiceValidationSchema = yup.object().shape({
  categoryId: yup.string().required("validation.field_required"),
  categoryName: yup.string().max(100, "validation.field_max_100"),
  currencyCode: yup
    .string()
    .max(4, "app.max_length_symbols")
    .notRequired()
    .nullable(),
  description: yup.string().notRequired().max(300, "validation.field_max_300"),
  durationInMinutes: yup
    .number()
    .nullable()
    .transform((value) => (value ? value : null))
    .required("validation.field_required")
    .min(5, "validation.duration_min_5")
    .max(30, "validation.duration_max_30"),
  price: yup
    .number()
    .nullable()
    .transform((value) => (value ? value : null))
    .required("validation.field_required")
    .min(1, "validation.price_min_1")
    .max(10000, "validation.price_max_10000"),
  icd10Code: yup.string().notRequired(),
  icd9Code: yup.string().notRequired(),
  hiddenFromPatients: yup.boolean().required("validation.field_required"),
  name: yup
    .string()
    .min(2, "validation.field_min_2")
    .max(100, "validation.field_max_100"),
});
