import { EButtonVariants } from "src/UI/Button";
import { Loading } from "src/UI/Loading";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Buttons, FooterButton } from "./styled";

interface PatientFormButtonsProps {
  updatePatientLoading: boolean;
}

function PatientFormButtons({ updatePatientLoading }: PatientFormButtonsProps) {
  const [showLoading, setShowLoading] = useState(false);

  const { t } = useTranslation();

  const handleSetLoading = (state: boolean) => () => {
    setShowLoading(state);
  };

  useEffect(() => {
    if (!updatePatientLoading) handleSetLoading(false)();
  }, [updatePatientLoading]);

  return (
    <Buttons>
      <FooterButton
        onClick={handleSetLoading(true)}
        variant={EButtonVariants.Default}
        disabled={showLoading && updatePatientLoading}
        type="submit"
      >
        {showLoading && updatePatientLoading ? (
          <Loading type="secondary" padding="0" />
        ) : null}
        {t("user.person_data.data.buttons.save")}
      </FooterButton>
    </Buttons>
  );
}

export default PatientFormButtons;
