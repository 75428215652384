import { useState } from "react";
import { useTranslation } from "react-i18next";
import Layout from "src/components/PatientBookingComponents/Layout/Layout";
import { useBookingAction, useBookingState } from "src/store/booking/hooks";
import { useCreateScheduleAppointmentByPatient } from "src/graphql/hooks/useMutations";
import { useFindBlankFormByServiceId } from "src/graphql/hooks/useQueries";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CHECK_PHONE_NUMBER_ERROR_MESSAGE,
  EMAIL_ALREADY_TAKEN_ERROR_MESSAGE,
  PATHS,
  availablesPhoneCodes,
} from "src/constants/constants";
import { toast } from "react-toastify";
import { transformReqSchemaCreateWaitingOrder } from "src/connectors/PatientBooking/transformReqSchemaCreateWaitingOrder";
import { transformReqSchemaCreateScheduleAppoint } from "src/connectors/PatientBooking/transformReqSchemaCreateScheduleAppoint";
import {
  FormFieldTypes,
  LanguageIsoCodes,
  useCreateScheduleWaitlistByPatientMutation,
} from "src/graphql/generated";
import { breakpointMap } from "src/theme/base";
import { useMediaQuery } from "src/hooks/useMediaQuery";
import { Step, Stepper, Content, StepperWrapper } from "./styled";
import { Step1, Step2, Step3 } from "./Steps";
import { IFieldStep3, PatientBookingInfo } from "./types";
import AppointmentCardComponent from "./AppointmentCardComponent/AppointmentCardComponent";

function StepsPage() {
  const isLaptop = useMediaQuery(breakpointMap.xxl);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const convertWaitlistHash = queryParams.get("convertWaitlistHash");
  const hash = queryParams.get("hash");

  const [currentStep, setCurrentStep] = useState(0);
  const {
    currentClinic,
    patientInfo,
    currentDoctor,
    currentSlot,
    currentFormFields,
    currentService,
    currentDate,
    currentDateWaitListSlot,
  } = useBookingState();

  const { onSetFormFields } = useBookingAction();

  const [createAppointByPatientMutation, { loading: appointLoading }] =
    useCreateScheduleAppointmentByPatient();

  const [createWaitlistByPatientMutation, { loading: waitlisLoading }] =
    useCreateScheduleWaitlistByPatientMutation();

  const loading = appointLoading || waitlisLoading;

  const { data: blankFormData } = useFindBlankFormByServiceId({
    variables: { schema: { serviceId: currentService?.service.id } },
    skip: !currentService?.service.id,
  });

  const createAppointmentHandler = (
    patientInfoSelected?: PatientBookingInfo,
    languageISOCode?: string,
    fields?: IFieldStep3[]
  ) => {
    const formattedFields = fields?.map((field) => {
      if (field.type === FormFieldTypes.Phone) {
        return {
          ...field,
          value: availablesPhoneCodes.includes(field.fillValue as string)
            ? ""
            : `+${field.fillValue}`,
        };
      }
      if (field.type === FormFieldTypes.Checkbox) {
        return { ...field, value: JSON.stringify(field.fillValue) };
      }
      return { ...field, value: field.fillValue as string };
    });

    const formAnswers = formattedFields?.map((field) => ({
      fieldId: field.id,
      order: field.order,
      value: field.value,
    }));

    if (currentDateWaitListSlot) {
      createWaitlistByPatientMutation({
        variables: {
          schema: transformReqSchemaCreateWaitingOrder(
            currentDateWaitListSlot,
            currentClinic,
            currentDoctor,
            currentService,
            formattedFields,
            patientInfoSelected,
            formAnswers,
            languageISOCode as LanguageIsoCodes
          ),
        },
        onCompleted: (response) => {
          toast.success(t("app.successful"));
          const searchParams = new URLSearchParams({
            waitingApptHash: response.createScheduleWaitlistByPatient.hash,
          });
          navigate({
            pathname: `${PATHS.patientBooking}${PATHS.detailsAppointment}`,
            search: searchParams.toString(),
          });
        },
        onError: (error) => {
          if (error) toast.error(error.message);
          if (error.message === EMAIL_ALREADY_TAKEN_ERROR_MESSAGE) {
            toast.warning(CHECK_PHONE_NUMBER_ERROR_MESSAGE);
          }
        },
      });
    } else {
      createAppointByPatientMutation({
        variables: {
          schema: transformReqSchemaCreateScheduleAppoint(
            currentDate,
            currentSlot,
            currentClinic,
            currentDoctor,
            currentService,
            formattedFields,
            patientInfoSelected,
            formAnswers,
            languageISOCode as LanguageIsoCodes,
            convertWaitlistHash
          ),
        },
        onCompleted: (response) => {
          onSetFormFields({ fields: fields as IFieldStep3[] });
          toast.success(t("app.successful"));

          const searchParams = new URLSearchParams({
            hash: response.createScheduleAppointmentByPatient.hash,
          });

          navigate({
            pathname: `${PATHS.patientBooking}${PATHS.detailsAppointment}`,
            search: searchParams.toString(),
          });
        },
      });
    }
  };

  const isActiveAndCompleteHandler = (step: number) => {
    switch (step) {
      case 0:
        return true;
      case 1:
        if (
          patientInfo?.patientInfo &&
          Object.keys(patientInfo?.patientInfo as object).length &&
          !hash
        )
          return true;
        return false;
      case 2:
        if (
          currentFormFields?.fields &&
          currentFormFields?.fields.length &&
          !hash
        )
          return true;
        return false;

      default:
        return false;
    }
  };

  const onSetStepHandler = (step: number) => () => {
    if (currentStep > step || isActiveAndCompleteHandler(step)) {
      setCurrentStep(step);
    }
  };

  const prevStepHandler = () => setCurrentStep((prev) => prev - 1);

  const stepContent = blankFormData
    ? [
        <Step1 onSetCurrentStep={setCurrentStep} />,
        <Step2
          prevStepHandler={prevStepHandler}
          isHasFormBlank={!!blankFormData}
          setCurrentStep={setCurrentStep}
          createAppointmentHandler={createAppointmentHandler}
          loading={loading}
        />,
        <Step3
          prevStepHandler={prevStepHandler}
          createAppointmentHandler={createAppointmentHandler}
          loading={loading}
        />,
      ]
    : [
        <Step1 onSetCurrentStep={setCurrentStep} />,
        <Step2
          prevStepHandler={prevStepHandler}
          isHasFormBlank={!!blankFormData}
          setCurrentStep={setCurrentStep}
          createAppointmentHandler={createAppointmentHandler}
          loading={loading}
        />,
      ];

  const stepHeaders = blankFormData
    ? [
        t("app.booking.patient.step1"),
        t("app.booking.patient.step2"),
        blankFormData.findBlankFormByServiceId.name,
      ]
    : [t("app.booking.patient.step1"), t("app.booking.patient.step2")];

  return (
    <StepperWrapper isFullWidth={currentStep >= 1}>
      <Layout currentClinic={currentClinic}>
        <Stepper>
          {isLaptop ? (
            <div>{stepHeaders[currentStep]}</div>
          ) : (
            stepHeaders.map((item, i) => (
              <Step
                key={item}
                onClick={onSetStepHandler(i)}
                isActive={currentStep >= i || isActiveAndCompleteHandler(i)}
              >
                <span>{i + 1}</span>
                {item}
              </Step>
            ))
          )}
        </Stepper>
        <Content>{stepContent[currentStep]}</Content>
      </Layout>
      {currentStep === 1 && !isLaptop ? <AppointmentCardComponent /> : null}
    </StepperWrapper>
  );
}

export default StepsPage;
