import { FC, useEffect, useState } from "react";
import { ToolbarProps, View, NavigateAction } from "react-big-calendar";
import { ChevronIcon, ChevronRightIcon } from "src/UI/Svg";
import { FiltersComponent } from "src/components/CalendarComponents";
import { useTranslation } from "react-i18next";
import { EButtonVariants } from "src/UI/Button";
import {
  ChevronButton,
  ChevronsBlock,
  DropdownStyled,
  Header,
  HeadingStyled,
  Left,
} from "./styles";

const ToolbarComponent: FC<ToolbarProps> = ({
  label,
  date,
  view,
  views,
  onNavigate,
  onView,
}) => {
  const { t } = useTranslation();

  const viewsOpts = (views as View[]).map((v: View) => ({
    item: t(`statistic.graphic.tabs.${v}`),
    value: v,
  }));

  const [selectedView, setSelectedView] = useState<View>(viewsOpts[0].value);

  if (!onNavigate || !onView) {
    return null;
  }

  const handleNavigate = (action: NavigateAction) => () => {
    onNavigate(action, date);
  };

  const handleSelectView = (val: View) => {
    onView(val as View);
    setSelectedView(val);
  };

  useEffect(() => {
    handleSelectView(view);
  }, [view]);

  const today = new Date();
  const isTodayOrFuture = date >= today;

  return (
    <Header>
      <Left>
        <HeadingStyled as="h2">{t("calendar.title")}</HeadingStyled>
        <DropdownStyled
          isShowOnlyPlaceholder={false}
          value={selectedView}
          placeholder=""
          onSelect={(val) => handleSelectView(val)}
          options={viewsOpts}
          label=""
        />
        <ChevronsBlock>
          <ChevronButton
            onClick={handleNavigate("PREV")}
            variant={EButtonVariants.White}
            disabled={!isTodayOrFuture}
          >
            <ChevronIcon />
          </ChevronButton>
          <ChevronButton
            onClick={handleNavigate("NEXT")}
            variant={EButtonVariants.White}
          >
            <ChevronRightIcon />
          </ChevronButton>
        </ChevronsBlock>
        <HeadingStyled as="h2">{label}</HeadingStyled>
      </Left>
      <FiltersComponent onNavigate={onNavigate} />
    </Header>
  );
};

export default ToolbarComponent;
