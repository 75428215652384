import { Button } from "src/UI/Button";
import DropdownInputRHF from "src/UI/DropdownInput/DropdownInputRHF";
import { Heading } from "src/UI/Heading";
import styled from "styled-components";

export const LeftWrapper = styled.div`
  width: calc(100% - 404px);
  max-width: calc(100% - 404px);
  gap: 20px;
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
`;

export const HeadingStyled = styled(Heading)`
  font-size: 24px;
  line-height: 100%;
`;

export const Content = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 16px;
    gap: 16px;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 20px;
`;

export const NewPatientComponentWrap = styled.div`
  margin-top: 36px;
`;

export const RowItem = styled.div`
  max-width: calc(50% - 10px);
  width: 100%;
`;

export const StyledDropdownInputRHF = styled(DropdownInputRHF)`
  flex-grow: 1;
  width: 100%;
`;

export const EmptyTextStyled = styled.div`
  width: 100%;
  padding: 15px;
  color: ${({ theme }) => theme.colors.gray};
`;

export const ServiceInfoWrap = styled.div`
  width: 100%;
  display: flex;
  margin-top: 36px;
  align-items: flex-end;
`;

export const ServiceDescr = styled.div`
  margin-top: 8px;
  font-size: 16px;
  line-height: 22px;
`;

export const ServicePriceWrap = styled.div`
  display: flex;
  align-items: flex-end;
  margin-left: 36px;
`;

export const ServicePrice = styled.div`
  display: flex;
  line-height: 1;
`;

export const ServiceCurrencie = styled.div`
  display: flex;
  margin-left: 6px;
`;

export const ServiceDescrWrap = styled.div`
  width: 100%;
  max-width: 918px;
`;

export const ServiceInfoTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  text-align: left;
  color: ${({ theme }) => theme.colors.grayC4};
  margin-right: 8px;
`;

export const ButtonHeaderStyled = styled(Button)`
  padding: 8px 10px;
  font-size: 16px;
  line-height: 100%;
  width: fit-content;
  border-radius: 5px;

  svg {
    path {
      stroke: ${({ theme }) => theme.colors.white};
    }
  }
`;
