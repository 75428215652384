import React, { FunctionComponent } from "react";
import { Svg, SvgProps } from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
  <Svg
    width="30"
    height="40"
    viewBox="0 0 30 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 40H26C28.2091 40 30 38.2091 30 36V8L22 0H4C1.79086 0 0 1.79086 0 4V36C0 38.2091 1.79086 40 4 40Z"
      fill="#467BE3"
    />
    <g style={{ mixBlendMode: "multiply" }}>
      <path d="M22 0L30 8H24C22.8954 8 22 7.10457 22 6V0Z" fill="#467BE3" />
    </g>
  </Svg>
);

export default Icon;
