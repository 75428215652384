import { gql } from "@apollo/client";

export const GET_COUNT_CLINICS = gql`
  query FindAndCountManyClinics($schema: FindAndCountManyClinics!) {
    findAndCountManyClinics(schema: $schema) {
      clinics {
        description
        address
        city
        code
        companyId
        createdAt
        geographicalCoordinates {
          latitude
          longitude
        }
        houseNumber
        id
        index
        licenseNumber
        logoFileId
        name
        phoneNumber
        hiddenFromPatients
        rooms {
          clinicId
          id
          name
        }
        workingDays {
          clinicId
          fromMinute
          id
          toMinute
          day
        }
      }
      count
    }
  }
`;
