import { CheckIcon } from "src/UI/Svg";
import { Label, RowItemStyled, RowItemTextStyled } from "./styled";

interface RowItemProps {
  item: string;
  isSelected: boolean;
  isActive: boolean;
  value: string;
  onSelect?: (value: string) => void;
  onShow?: () => void;
}

function RowItem({
  item,
  value,
  onSelect,
  isSelected,
  isActive,
  onShow,
}: RowItemProps) {
  return (
    <RowItemStyled
      isActive={isActive}
      isSelected={isSelected}
      onClick={() => {
        if (onSelect) {
          onSelect(value);
        } else if (onShow) {
          onShow();
        }
      }}
    >
      <RowItemTextStyled>{item}</RowItemTextStyled>
      <Label isChecked={isSelected}>
        <CheckIcon width={14} height={14} />
      </Label>
    </RowItemStyled>
  );
}

export default RowItem;
