import styled from "styled-components";
import { Button } from "src/UI/Button";

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  flex-direction: column;
`;

export const Content = styled.div`
  display: grid;
  width: 100%;
  padding: 20px;
  gap: 10px;
  grid-template-columns: 100%;
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 16px;
  }
`;

export const TimetableWrapper = styled.div`
  display: grid;
  grid-template-columns: 100%;
  gap: 10px;
`;

export const ButtonStyled = styled(Button)`
  width: auto;
  padding: 8px 10px;
  font-size: 16px;
  line-height: 100%;
  border-radius: 5px;

  svg path {
    stroke: ${({ theme }) => theme.colors.white};
  }
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 12px;
    padding: 7px 8px;
  }
`;
