import { useEffect } from "react";
import {
  useFindClinicByIdLazyQuery,
  useFindManyFilesByIdsLazyQuery,
} from "src/graphql/generated";
import { useBookingAction, useBookingState } from "src/store/booking/hooks";

export function useClinicEffect(clinicId: string | null | undefined) {
  const { onSetService, onSetLocation, onSetClinic } = useBookingAction();
  const { currentClinic } = useBookingState();
  const [getClinicById] = useFindClinicByIdLazyQuery();
  const [getFile] = useFindManyFilesByIdsLazyQuery();

  useEffect(() => {
    if (!currentClinic && clinicId) {
      getClinicById({
        variables: { schema: { id: clinicId } },
        onCompleted: (response) => {
          if (response) {
            onSetLocation({
              center: [
                response?.findClinicById?.geographicalCoordinates?.longitude,
                response?.findClinicById?.geographicalCoordinates?.latitude,
              ],
            });

            if (!response?.findClinicById?.logoFileId) {
              onSetClinic({ clinic: response?.findClinicById, logoUrl: null });
            } else {
              getFile({
                variables: {
                  schema: { ids: [response?.findClinicById?.logoFileId] },
                },
                onCompleted: (responseFile) => {
                  onSetClinic({
                    clinic: response?.findClinicById,
                    logoUrl: responseFile?.findManyFilesByIds?.files?.[0]?.url,
                  });
                },
              });
            }
          }
        },
      });
    }
  }, [
    currentClinic,
    clinicId,
    onSetLocation,
    onSetClinic,
    getFile,
    onSetService,
  ]);
}
