import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useFindManyDoctorClinicsByDoctorId } from "src/graphql/hooks/useQueries";
import DoctorView from "src/views/BookingsViews/DoctorView/DoctorView";
import {
  AggregatedDoctorEntityResponse,
  ClinicEntityResponse,
  ClinicSortFields,
  DoctorTypeEntityResponse,
  LanguageIsoCodes,
  SortingOrders,
  useFindAndCountManyClinicsLazyQuery,
  useFindDoctorByIdLazyQuery,
  useFindManyDoctorTypesByLanguageIsoCodeLazyQuery,
} from "src/graphql/generated";
import { useClinicsState } from "src/store/clinics/hooks";
import { toast } from "react-toastify";
import { t } from "i18next";
import { TMultiselectRHFOpts } from "src/UI/Input/multiselectRHF/MultiselectRHF";
import { useTranslation } from "react-i18next";
import ServicesView from "./ServicesView/ServicesView";

const Wrapper = styled.div`
  display: flex;
  gap: 20px;
  padding-bottom: 200px;
`;

const EditDoctorPage = () => {
  const { i18n } = useTranslation();
  const { doctorId } = useParams();
  const { currentClinicId } = useClinicsState();
  const [doctorClinicsIds, setDoctorClinicsIds] = useState<string[]>([]);
  const [clinicsOpts, setClinicsOpts] = useState<TMultiselectRHFOpts>([]);
  const [doctor, setDoctor] = useState<AggregatedDoctorEntityResponse | null>(
    null
  );
  const [typesOpts, setTypesOpts] = useState<{ value: string; item: string }[]>(
    []
  );

  const [getDoctor] = useFindDoctorByIdLazyQuery();
  const [getClinics] = useFindAndCountManyClinicsLazyQuery();
  const [getType] = useFindManyDoctorTypesByLanguageIsoCodeLazyQuery();

  useFindManyDoctorClinicsByDoctorId({
    variables: {
      schema: {
        doctorId,
      },
    },
    onCompleted: (response) => {
      const clinicsIdsArray =
        response?.findManyDoctorClinicsByDoctorId?.clinics?.map(
          (clinic: ClinicEntityResponse) => clinic?.id
        );

      setDoctorClinicsIds(clinicsIdsArray);
    },
  });

  useEffect(() => {
    if (!i18n) return;
    getType({
      variables: {
        schema: {
          languageISOCode: i18n.language.toUpperCase() as LanguageIsoCodes,
        },
      },
      fetchPolicy: "network-only",
      onCompleted(response) {
        const typesOptsList =
          response?.findManyDoctorTypesByLanguageISOCode?.doctorTypes?.map(
            (type: DoctorTypeEntityResponse) => ({
              value: type.doctorTypeTranslations[0].doctorTypeId,
              item: type.doctorTypeTranslations[0].name,
            })
          );
        setTypesOpts(typesOptsList);
      },
    });
  }, [i18n.language]);

  useEffect(() => {
    if (doctorId) {
      getDoctor({
        variables: { schema: { doctorId } },
        fetchPolicy: "no-cache",
        onCompleted(response) {
          setDoctor(response?.findDoctorById);
        },
        onError(error) {
          toast.error(error.message);
        },
      });
    }
  }, [doctorId, currentClinicId]);

  useEffect(() => {
    getClinics({
      variables: {
        schema: {
          sortOrder: SortingOrders.Descending,
          sortField: ClinicSortFields.CreatedAt,
        },
      },
      onCompleted(response) {
        const clinicsListOpts = response?.findAndCountManyClinics?.clinics?.map(
          (clinic: ClinicEntityResponse) => {
            return {
              item: clinic.name,
              value: clinic.id,
              shortItem: clinic.name,
            };
          }
        );
        setClinicsOpts(clinicsListOpts);
      },
    });
  }, []);

  const isWorking = doctorClinicsIds?.includes(currentClinicId);

  return (
    <Wrapper>
      {!isWorking ? (
        <>{t("app.edit_doctor_page_error")}</>
      ) : (
        <>
          <DoctorView
            doctor={doctor}
            doctorClinicsIds={doctorClinicsIds}
            clinicsOpts={clinicsOpts}
            typesOpts={typesOpts}
          />
          <ServicesView doctorId={doctorId as string} />
        </>
      )}
    </Wrapper>
  );
};

export default EditDoctorPage;
