import { gql } from "@apollo/client";

export const CREATE_VISIT_BY_CLIENT = gql`
  mutation CreateScheduleAppointmentByClient(
    $schema: CreateScheduleAppointmentByClientSchema!
  ) {
    createScheduleAppointmentByClient(schema: $schema) {
      createdAt
      id
      patientId
      scheduleId
      status
    }
  }
`;
