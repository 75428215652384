import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CardComponent } from "src/components/UserComponents";
import { ChevronDownIcon } from "src/UI/Svg";
import { ScheduleComponent } from "src/components/BookingComponents";

import { AggregatedDoctorEntityResponse } from "src/graphql/generated";
import { FormProvider, useForm } from "react-hook-form";
import { InputAutosizeRHF, InputRHF, PhoneInputSmallRHF } from "src/UI/Input";
import { theme } from "src/theme";
import { transformBeforeEditDoctor } from "src/connectors/Doctors/transformBeforeEditDoctor";
import { TMultiselectRHFOpts } from "src/UI/Input/multiselectRHF/MultiselectRHF";
import DropdownInputRHF from "src/UI/DropdownInput/DropdownInputRHF";
import { EButtonVariants } from "src/UI/Button";
import {
  FIND_AND_COUNT_MANY_DOCTORS_BY_CLINIC_ID,
  GET_FIND_DOCTOR_BY_ID,
  GET_FIND_DOCTOR_CLINICS_BY_DOCTOR_ID,
} from "src/graphql/queries";
import { toast } from "react-toastify";
import {
  useUpdateDoctorAvatarFileIdByClient,
  useUpdateDoctorByIdByClient,
  useUpdateDoctorClinicsByClient,
  useUploadFile,
} from "src/graphql/hooks/useMutations";
import { yupResolver } from "@hookform/resolvers/yup";
import { editDoctorValidationSchema } from "src/validation/schemas/editDoctorValidationSchema";
import { useAuthContext } from "src/context/AuthContext";
import {
  BottomWrapStyled,
  Content,
  FieldsWrap,
  FooterButton,
  FormWrapper,
  InputsRow,
  MultiselectRHFStyled,
  UserInfo,
  UserInfoWrap,
  Wrapper,
} from "./styles";
import DoctorPhotoComponent from "./DoctorPhotoComponent";

interface FormValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  description: string;
  location: string;
  medicalCertificateNumber: string;
  passportNumber: string;
  promoCode: string;
  clinicsIds: string[];
  doctorTypeId: string;
}

interface ComponentProps {
  doctor: AggregatedDoctorEntityResponse | null;
  doctorClinicsIds: string[];
  clinicsOpts: TMultiselectRHFOpts;
  typesOpts: { value: string; item: string }[];
}

const DoctorView: FC<ComponentProps> = ({
  doctor,
  doctorClinicsIds,
  clinicsOpts,
  typesOpts,
}) => {
  const { t } = useTranslation();
  const { localUsers } = useAuthContext();
  const methods = useForm<FormValues>({
    resolver: yupResolver(editDoctorValidationSchema),
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      doctorTypeId: "",
      phoneNumber: "",
      description: "",
      location: "",
      medicalCertificateNumber: "",
      passportNumber: "",
      promoCode: "",
      clinicsIds: [],
    },
  });

  const {
    watch,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const data = watch();

  const [
    updateDoctorByIdByClient,
    { loading: updateDoctorByIdByClientLoading },
  ] = useUpdateDoctorByIdByClient({
    refetchQueries: [
      FIND_AND_COUNT_MANY_DOCTORS_BY_CLINIC_ID,
      GET_FIND_DOCTOR_CLINICS_BY_DOCTOR_ID,
      GET_FIND_DOCTOR_BY_ID,
    ],
    awaitRefetchQueries: true,
  });

  const [updateDoctorClinics] = useUpdateDoctorClinicsByClient({
    refetchQueries: [
      FIND_AND_COUNT_MANY_DOCTORS_BY_CLINIC_ID,
      GET_FIND_DOCTOR_CLINICS_BY_DOCTOR_ID,
      GET_FIND_DOCTOR_BY_ID,
    ],
    awaitRefetchQueries: true,
    onCompleted: (response) => {
      if (response) {
        toast.success(t("app.successful"));
      }
    },
    onError: (error) => {
      if (error) toast.error(error.message);
      if (doctor) reset(transformBeforeEditDoctor(doctor, doctorClinicsIds));
    },
  });

  const [uploadFile, { loading: uploadFileLoading }] = useUploadFile();
  const [updateAvatar, { loading: updateAvatarLoading }] =
    useUpdateDoctorAvatarFileIdByClient();

  useEffect(() => {
    if (doctor) reset(transformBeforeEditDoctor(doctor, doctorClinicsIds));
  }, [doctorClinicsIds, doctor]);

  const onSave = (formData: FormValues) => {
    updateDoctorByIdByClient({
      variables: {
        schema: {
          doctorId: doctor?.doctorId,
          doctorTypeId: formData.doctorTypeId,
          firstName: formData.firstName,
          lastName: formData.lastName,
          phoneNumber: `+${formData.phoneNumber}`,
          medicalCertificateNumber: formData.medicalCertificateNumber,
          ...(formData.description
            ? { description: formData.description }
            : { description: null }),
          ...(formData.passportNumber
            ? {
                passportNumber: formData.passportNumber,
              }
            : {
                passportNumber: null,
              }),
        },
      },
    });
    updateDoctorClinics({
      variables: {
        schema: {
          doctorId: doctor?.doctorId,
          clinics: formData.clinicsIds?.map((clinicId) => ({
            clinicId,
            hiddenFromPatients: false,
          })),
        },
      },
    });
  };

  const handleChangePhoto = (photo: File | null) => {
    if (photo) {
      uploadFile({
        variables: { schema: { file: photo } },
        onCompleted: (response) => {
          updateAvatar({
            variables: {
              schema: {
                doctorId: doctor?.doctorId,
                avatarFileId: response.uploadFile.id,
              },
            },
            onCompleted: () => {
              toast.success(t("app.successful"));
              const updatedUsers = localUsers?.map((user) => {
                if (user.id === doctor?.id) {
                  return { ...user, avatarFileId: response.uploadFile.id };
                }
                return user;
              });
              localStorage.setItem("users", JSON.stringify([...updatedUsers]));
            },
          });
        },
      });
    }
  };

  return (
    <Wrapper>
      <FormProvider {...methods}>
        <FormWrapper onSubmit={handleSubmit(onSave)}>
          <CardComponent
            title={t("edit_doctor.title")}
            headerChildren={
              <MultiselectRHFStyled
                name="clinicsIds"
                placeholder={t("map.title")}
                options={clinicsOpts}
                label=""
                customIcon={
                  <ChevronDownIcon
                    size="20px"
                    strokeWidth="0.7"
                    color={theme.colors.green}
                  />
                }
                isShowOnlyPlaceholder
              />
            }
          >
            <Content>
              <UserInfoWrap>
                <UserInfo>
                  <DoctorPhotoComponent
                    onChange={handleChangePhoto}
                    value={doctor?.avatarFileId || null}
                    loading={uploadFileLoading || updateAvatarLoading}
                  />
                  <FieldsWrap>
                    <InputsRow>
                      <InputRHF
                        val={data.firstName}
                        label={t("add_doctor.label.name") as string}
                        register={register("firstName")}
                        inputType="text"
                        errorMessage={errors.firstName}
                      />
                      <InputRHF
                        val={data.lastName}
                        label={t("add_doctor.label.surname") as string}
                        register={register("lastName")}
                        inputType="text"
                        errorMessage={errors.lastName}
                      />
                    </InputsRow>
                    <InputsRow>
                      <PhoneInputSmallRHF
                        label={t("add_doctor.label.phone") || ""}
                        value={data.phoneNumber}
                        name="phoneNumber"
                      />
                      <InputRHF
                        val={data.passportNumber as string}
                        label={t("add_doctor.label.passport") as string}
                        register={register("passportNumber")}
                        inputType="text"
                        errorMessage={errors.passportNumber}
                      />
                    </InputsRow>
                    <InputsRow>
                      <DropdownInputRHF
                        name="doctorTypeId"
                        value={data.doctorTypeId}
                        placeholder=""
                        options={typesOpts}
                        label={t("add_doctor.label.specialisation" as string)}
                        adaptedForMobile={false}
                        errorMessage={errors.doctorTypeId}
                        customIcon={
                          <ChevronDownIcon
                            size="20px"
                            strokeWidth="0.7"
                            color={theme.colors.green}
                          />
                        }
                      />
                      <InputRHF
                        val={data.medicalCertificateNumber}
                        label={t("add_doctor.label.certificate") as string}
                        register={register("medicalCertificateNumber")}
                        inputType="text"
                        errorMessage={errors.medicalCertificateNumber}
                      />
                    </InputsRow>
                  </FieldsWrap>
                </UserInfo>
                <BottomWrapStyled>
                  <InputAutosizeRHF
                    val={data.description as string}
                    label={t("add_doctor.label.description") as string}
                    register={register("description")}
                    inputType="text"
                    errorMessage={errors.description}
                  />

                  <FooterButton
                    variant={EButtonVariants.Default}
                    disabled={updateDoctorByIdByClientLoading}
                  >
                    {t("common.save") as string}
                  </FooterButton>
                </BottomWrapStyled>
              </UserInfoWrap>
            </Content>
          </CardComponent>
          <ScheduleComponent doctorId={doctor?.doctorId as string} />
        </FormWrapper>
      </FormProvider>
    </Wrapper>
  );
};

export default DoctorView;
