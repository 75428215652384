import { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FieldsListComponent } from "src/components/FormsComponents";
import NewFormComponent from "src/components/FormsComponents/NewFomComponent/NewFormComponent";
import { EButtonVariants } from "src/UI/Button";
import { CreateEditFormValues } from "src/types/CreateEditForm";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Loading } from "src/UI/Loading";
import { PlusIcon } from "src/UI/Svg";
import { toast } from "react-toastify";
import { Buttons, Footer, FooterButton } from "./styled";

interface ICreateEditFormFieldsProps {
  loading: boolean;
  disabledSubmit: boolean;
  isEditForm: boolean;
  submitFormId: string;
}

export const CreateEditFormFields = ({
  disabledSubmit,
  loading,
  isEditForm,
  submitFormId,
}: ICreateEditFormFieldsProps) => {
  const methods = useFormContext<CreateEditFormValues>();
  const { t, i18n } = useTranslation();
  const [isShowFieldList, setIsShowFieldList] = useState(false);

  const {
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = methods;

  const [fields] = watch(["fields"]);

  useEffect(() => {
    if (errors.fields?.message) {
      toast.error(t(errors.fields.message as string));
    }
  }, [errors.fields?.message]);

  const handleMoveField = (
    formId: number,
    dragIndex: number,
    hoverIndex: number
  ) => {
    if (!fields) return;

    const fieldsDrag = fields.map((field) => {
      if (field.id === formId) {
        const hoverField = fields[hoverIndex];
        const dragField = fields[dragIndex];

        fields[dragIndex] = hoverField;
        return dragField;
      }
      return field;
    });

    if (dragIndex < hoverIndex) {
      fieldsDrag[dragIndex] = fields[hoverIndex];
    }

    setValue("fields", fieldsDrag);
  };

  const onRemove = (id: number) => {
    setValue(
      "fields",
      fields.filter((item) => item.id !== id)
    );
  };

  const handleSetShowFieldList = (val: boolean) => () => {
    setIsShowFieldList(val);
  };

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        {fields.map((field, index) => (
          <NewFormComponent
            key={field?.order}
            type={field.type}
            order={index}
            moveForm={(dragIndex: number, hoverIndex: number) =>
              handleMoveField(field.id as number, dragIndex, hoverIndex)
            }
            onRemove={onRemove}
            id={field.id as number}
            isHideDeleteButton={isEditForm}
          />
        ))}
      </DndProvider>

      {isShowFieldList && (
        <FieldsListComponent
          onClose={handleSetShowFieldList(false)}
          languageISOCode={i18n.resolvedLanguage.toUpperCase()}
        />
      )}

      <Footer>
        <FooterButton
          onClick={handleSetShowFieldList(true)}
          variant={EButtonVariants.Border}
          type="button"
        >
          <PlusIcon />
          {t("forms.modals.custom.buttons.add")}
        </FooterButton>

        <Buttons>
          <FooterButton
            variant={EButtonVariants.Default}
            disabled={disabledSubmit || isSubmitting}
            type="submit"
            form={submitFormId}
          >
            {loading && <Loading padding="0" />}
            {isEditForm
              ? t("forms.modals.custom.buttons.edit")
              : t("forms.modals.custom.buttons.create")}
          </FooterButton>
        </Buttons>
      </Footer>
    </>
  );
};
