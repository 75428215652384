import { gql } from "@apollo/client";

export const GET_FIND_SCHEDULES_BY_DOCTOR = gql`
  query FindManySchedulesByDoctor($schema: FindManySchedulesByDoctorSchema!) {
    findManySchedulesByDoctor(schema: $schema) {
      schedules {
        id
        doctorId
        clinicId
        roomId
        eventType
        layoutType
        daysOfWeek
        dateFrom
        dateTo
        minuteFrom
        minuteTo
        createdAt
      }
    }
  }
`;
