import {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useOutsideClick } from "src/hooks/useOutsideClick";
import { CloseIcon, SearchIcon } from "src/UI/Svg";
import { FieldError, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CountedPatientsResponse } from "src/graphql/generated";
import {
  ClearButton,
  ErrorMessage,
  Icon,
  Input,
  SearchBlock,
  SearchWrapper,
  SelectedElem,
} from "./styles";
import ResultListComponent from "./ResultListComponent/ResultListComponent";

interface SearchComponentProps {
  name: string;
  placeholder: string;
  value: string | number | object | null | undefined;
  options?: Array<{ item: string | ReactNode; value: string | number }>;
  defaultSearchValue?: string;
  handleInputChange?: (val: string) => void;
  handleSearchAddEntity?: () => void;
  handleSelect?: () => void;
  errorMessage?: FieldError;
  isClearable?: boolean;
  fetchMore: () => Promise<unknown>;
  data: CountedPatientsResponse | null;
}

const SearchComponentRHF = ({
  options,
  name,
  value,
  placeholder,
  errorMessage,
  defaultSearchValue,
  handleInputChange,
  handleSearchAddEntity,
  handleSelect,
  isClearable = true,
  fetchMore,
  data,
}: SearchComponentProps) => {
  const [search, setSearch] = useState<string>(defaultSearchValue || "");
  const [isShow, setIsShow] = useState<boolean>(false);
  const { t } = useTranslation();
  const form = useRef(null);
  const { setValue, trigger } = useFormContext();

  useOutsideClick({
    ref: form,
    handler: () => setIsShow(() => false),
  });

  const selectItem = useMemo(() => {
    return (
      options?.filter(
        (item) => JSON.stringify(item.value) === JSON.stringify(value)
      )[0] || null
    );
  }, [options, value]);

  const [selectItemItem] = useMemo(() => {
    if (selectItem) return [selectItem.item, selectItem.value];
    return [placeholder, "-"];
  }, [selectItem, placeholder]);

  const onSelectHandler = useCallback(
    (val: string | number) => {
      setValue(name, val);
      setIsShow(false);
      trigger(name);
      if (handleSelect) handleSelect();
    },
    [setValue]
  );

  const onInputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    if (handleInputChange) handleInputChange(event.target.value);
  };

  const onClearHandler = () => {
    setSearch("");
    setValue(name, "");
    setIsShow(true);
    trigger(name);
  };

  useEffect(() => {
    if (!defaultSearchValue) setSearch("");
  }, [defaultSearchValue]);

  return (
    <SearchWrapper ref={form}>
      <SearchBlock isShow={isShow}>
        {selectItem ? (
          <SelectedElem>
            {selectItemItem}
            {isClearable ? (
              <ClearButton onClick={onClearHandler}>
                <CloseIcon />
              </ClearButton>
            ) : null}
          </SelectedElem>
        ) : (
          <>
            <Icon>
              <SearchIcon />
            </Icon>
            <Input
              placeholder={placeholder}
              value={search}
              onChange={(event) => onInputChangeHandler(event)}
              onFocus={() => setIsShow(true)}
            />
          </>
        )}
      </SearchBlock>

      <>
        <ResultListComponent
          resultList={options || []}
          isShow={isShow}
          onSelect={onSelectHandler}
          onSearchAddEntity={handleSearchAddEntity}
          fetchMore={fetchMore}
          data={data}
        />

        {errorMessage && (
          <ErrorMessage>
            {errorMessage?.message && t(errorMessage.message)}
          </ErrorMessage>
        )}
      </>
    </SearchWrapper>
  );
};

export default SearchComponentRHF;
