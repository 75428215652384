import { Button } from "src/UI/Button";
import { CommonTable } from "src/UI/CommonTable";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StatusWrapper = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ color }) => color};
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;

  & svg {
    min-width: 8px;
  }
`;

export const StatusText = styled.div`
  white-space: pre-line;
  color: inherit;
`;

export const PatientFilledFormsItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #ededed;
  border-radius: 10px;
`;

export const PatientAppointmentsTableStyled = styled(CommonTable)`
  table-layout: fixed;

  td {
    padding: 30px 5px;
    word-wrap: break-word;
  }
`;

export const StyledButton = styled(Button)`
  font-size: 16px;
  font-weight: 400;
  max-width: 175px;
  padding: 0;
  width: auto;
  height: auto;
  color: ${({ theme }) => theme.colors.black};
  justify-content: start;
  text-align: left;

  &:not(:disabled):hover {
    background-color: transparent;
  }
`;

export const DateWrapper = styled.div`
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const DateItem = styled.span`
  color: inherit;
`;

export const ClinicName = styled.div`
  max-width: 100px;
`;

export const StyledFetchMoreDiv = styled.div`
  width: 10px;
  height: 10px;
`;
