import { IReview } from "../../types";
import { user } from "../../mock/search";

export const reviews: IReview[] = [
  {
    id: 0,
    user: {
      ...user,
      name: "Leszek",
      soname: "Kowalski",
    },
    social: "Facebook",
    content:
      "Nie polecam. Podejście doktora bardzo nie przyjemne i aroganckie. Myślałem, że to wynikało z gorszego dnia, ale na kolejnej wizycie powtórka i to samo. Zrobiona korona na zębie, ale do dziś odczuwam dyskomfort i ból, mimo, że informowałem lekarza, że ząb nadal jest za wysoko i ociera to powiedział, że on uważa, że jest dobrze. Gdyby było dobrze, to bym nie czuł, że jest za wysoko... Niestety, ale kontakt z pacjentem bardzo nieprofesjonalny i zniechęcający. Mimo, że rzadko piszę opinie to w tym przypadku zrobiłem to, bo uważam że, inni powinni wiedzieć jak wygląda podejście do pacjenta, zanim skorzystają z usług Doktora Pogorzelskiego.",
    stars: "3.0",
    date: "Godzinę temu",
    childReview: [
      {
        id: 3,
        user: {
          ...user,
          name: "Go Clients Tech",
          soname: "",
        },
        content: `Dzień dobry ,
Jestem zaskoczony tą opinią gdyż żaden pacjent nie zgłaszał mi takiego problemu . Starałem się
szukać Michałów i przeglądać karty pacjentów ale nic takiego nie znalazłem . Staram się aby moi pacjenci byli zadowoleni i nigdy nie zostawiam nikogo z problemem ( zwłaszcza takim o jakim Pan pisze niezmiernie łatwym do wyeliminowania )
Zgłosiłem opinię do portalu gdyż nie wiem czy faktycznie pochodzi od mojego pacjenta . Jeśli jednak tak to przecież można to naprawić co chętnie uczynię`,
        date: "Godzinę temu",
      },
    ],
  },
  {
    id: 1,
    user: {
      ...user,
      name: "Alan",
      soname: "Górecki",
    },
    social: "Google",
    stars: "5.0",
    date: "7 sierpnia 2022 r. 8:30",
  },
  {
    id: 2,
    user: {
      ...user,
      name: "Sonja",
      soname: "Ozimek",
    },
    social: "Facebook",
    content:
      "Very competent Dentist. Highly professional. The environment is calm and quite. Everybody focused on there work. I got the impression that the whole team works very well together. Everyone is nice and tries to fulfill the needs of the costumer. I am very happy with the treatment. Highly recommended.",
    stars: "5.0",
    date: "Godzinę temu",
  },
  {
    id: 4,
    user: {
      ...user,
      name: "Patryk",
      soname: "Mróz",
    },
    social: "Facebook",
    content:
      "Przyjechałam do Doktora aż ze Śląska. Założenie implantów przez doktora to było mistrzostwo świata, czułam się bardzo dobrze w trakcie i po samym zabiegu. Nie spuchłam, nie miałam żadnych dolegliwości, normalnie wróciłam do pracy więc byłam bardzo pozytywnie zaskoczona. Warto było pokonać kilkaset kilometrów żeby trafić do tego specjalisty.",
    stars: "5.0",
    date: "7 sierpnia 2022 r. 8:30",
  },
  {
    id: 5,
    user: {
      ...user,
      name: "Kacper",
      soname: "Malinowski",
    },
    social: "Google",
    stars: "5.0",
    date: "7 sierpnia 2022 r. 8:30",
  },
  {
    id: 6,
    user: {
      ...user,
      name: "Natan",
      soname: "Przybylski",
    },
    social: "Facebook",
    content:
      "Jakiś czas temu zakładałam u Pana Pogorzelskiego korony na zęby. Jestem mega zadowolona z efektów. Przez palenie moje zęby uległy zniszczeniu i wstydziłam się je pokazywać. Teraz uśmiecham się praktycznie cały czas. Polecam :)",
    stars: "5.0",
    date: "Godzinę temu",
    childReview: [
      {
        id: 3,
        user: {
          ...user,
          name: "Go Clients Tech",
          soname: "",
        },
        content: `Ogromnie dziękuję`,
        date: "Godzinę temu",
      },
    ],
  },
  {
    id: 7,
    user: {
      ...user,
      name: "Amir",
      soname: "Kozłowski",
    },
    social: "Facebook",
    content:
      "Chcę podziękować dr Pogorzelskiemu za wspaniałą pracę jaką wykonał. Ukruszył mi się ząb w widocznym miejscu. Nie dość, że zostałam przyjęta bardzo szybko na wizytę to mogę się znów uśmiechać. Mam założona porcelanową koronę, która wiernie odwzorowuje kolorystycznie resztę moich zębów. Szybko, sprawnie z uzyskaniem oczekiwanego rezultatu, nowoczesny sprzęt i przemiły lekarz. Szczerze polecam :)",
    stars: "5.0",
    date: "7 sierpnia 2022 r. 8:30",
  },
  {
    id: 8,
    user: {
      ...user,
      name: "Amadeusz",
      soname: "Pawlak",
    },
    social: "Google",
    stars: "5.0",
    date: "7 sierpnia 2022 r. 8:30",
  },
  {
    id: 9,
    user: {
      ...user,
      name: "Ryszard",
      soname: "Wojciechowski",
    },
    social: "Facebook",
    content:
      "Pierwsza wizyta. Profesjonalne podejście do tematu, miła atmosfera, punktualnie i z pełnym zaangażowaniem zarówno po stronie lekarza jak i pomocy pielęgniarskiej - polecam, pozdrawiam i do zobaczenia",
    stars: "5.0",
    date: "Godzinę temu",
  },
  {
    id: 10,
    user: {
      ...user,
      name: "Martin",
      soname: "Wiśniewski",
    },
    social: "Facebook",
    content:
      "Polecam serdecznie, wyleczyłam u Pana swoje wszystkie zęby. Bardzo się bałam dentysty, ale Pan Cezary ma dobre podejście do pacjenta. Polecam",
    stars: "5.0",
    date: "7 sierpnia 2022 r. 8:30",
  },
];
