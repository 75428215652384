import { useTranslation } from "react-i18next";
import {
  FormFieldAnswerResponse,
  FormFieldEntityWithRelationsResponse,
} from "src/graphql/generated";
import { getTranslationsBySelectedLang } from "src/utils/form";

interface IUseGetFieldTranslationProps {
  fields: Array<FormFieldEntityWithRelationsResponse>;
  answer: FormFieldAnswerResponse;
}

export const useGetFieldTranslation = ({
  fields,
  answer,
}: IUseGetFieldTranslationProps) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const field = fields.find((fieldItem) => fieldItem.order === answer.order);

  const fieldTranslation =
    field?.translations &&
    getTranslationsBySelectedLang(currentLanguage, field?.translations);

  return fieldTranslation;
};
