import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EButtonVariants } from "src/UI/Button";
import { CalendarIcon } from "src/UI/Svg";
import {
  AppointmentStatuses,
  ScheduleEntityResponse,
} from "src/graphql/generated";
import { Loading } from "src/UI/Loading";
import { EModals } from "src/store/app/types";
import { useAppAction } from "src/store/app/hooks";

import { getDateWithoutTimeZoneOffset } from "src/utils";
import { useAuthContext } from "src/context/AuthContext";
import { useUpdateStatus } from "src/hooks/appointments/useUpdateStatus";
import { ButtonStyled, ButtonWrapper, Buttons } from "./styled";

interface IScheduleAppointmentUpdateStatusComponent {
  setAppointmentToStore: () => void;
  id: string;
  status: AppointmentStatuses;
  schedule: ScheduleEntityResponse;
}

function ScheduleAppointmentUpdateStatusComponent({
  id,
  status,
  setAppointmentToStore,
  schedule,
}: IScheduleAppointmentUpdateStatusComponent) {
  const { t } = useTranslation();
  const { isDoctor } = useAuthContext();
  const { onCloseModal } = useAppAction();
  const [isAppointmentInPast, setIsAppointmentInPast] = useState(false);

  useEffect(() => {
    if (!schedule?.dateFrom) return;
    const originalDate = schedule?.dateFrom && new Date(schedule?.dateFrom);

    const newDate = new Date(
      originalDate.getTime() + (schedule?.minuteFrom as number) * 60000
    );
    const formattedDate = getDateWithoutTimeZoneOffset(newDate.toISOString());

    const dateNow = Date.now();

    if (dateNow > formattedDate) {
      setIsAppointmentInPast(true);
    } else {
      setIsAppointmentInPast(false);
    }
  }, [id]);

  const closeModal = useCallback(() => {
    onCloseModal(EModals.ScheduleAppointment);
  }, [onCloseModal]);

  const {
    updateStatusHandler,
    confirmAppointmentLoading,
    cancelAppointmentLoading,
    missedAppointmentLoading,
    updateStatusLoading,
  } = useUpdateStatus({ id, closeModal });

  const isConfirmedStatus =
    status === AppointmentStatuses.ConfirmedByApplication ||
    status === AppointmentStatuses.ConfirmedByPatient;

  const isVisibleCancelAndResheduleButton =
    !isDoctor && !isAppointmentInPast && isConfirmedStatus;

  const isVisibleMissedButton = isAppointmentInPast && isConfirmedStatus;

  const isVisibleConfirmButton =
    !isDoctor && status === AppointmentStatuses.New && !isAppointmentInPast;

  const isVisibleCancelButtonNeStatus =
    !isDoctor && status === AppointmentStatuses.New && isAppointmentInPast;

  const isVisibleCompleteButton =
    !isDoctor && isAppointmentInPast && isConfirmedStatus;

  if (
    (!isAppointmentInPast && isDoctor) ||
    status === AppointmentStatuses.Successful
  )
    return null;

  return (
    <Buttons>
      {isVisibleCancelAndResheduleButton ? (
        <ButtonWrapper>
          <ButtonStyled
            variant={EButtonVariants.Red}
            type="button"
            onClick={updateStatusHandler(AppointmentStatuses.Canceled)}
          >
            {cancelAppointmentLoading ? <Loading padding="0" /> : null}
            {t("booking.schedule_appointment.cancel_visit")}
          </ButtonStyled>
          <ButtonStyled
            variant={EButtonVariants.Text}
            type="button"
            onClick={setAppointmentToStore}
          >
            <CalendarIcon height="16" width="16" />{" "}
            {t("booking.schedule_appointment.reshedule_visit")}
          </ButtonStyled>
        </ButtonWrapper>
      ) : null}
      <ButtonWrapper>
        {isVisibleMissedButton ? (
          <ButtonStyled
            variant={EButtonVariants.Border}
            type="button"
            onClick={updateStatusHandler(AppointmentStatuses.Missed)}
          >
            {missedAppointmentLoading ? <Loading padding="0" /> : null}
            {t("booking.schedule_appointment.missed_visit")}
          </ButtonStyled>
        ) : null}
        {isVisibleConfirmButton ? (
          <ButtonStyled
            variant={EButtonVariants.Default}
            type="button"
            onClick={updateStatusHandler(
              AppointmentStatuses.ConfirmedByApplication
            )}
          >
            {confirmAppointmentLoading ? <Loading padding="0" /> : null}
            {t("booking.schedule_appointment.confirm_visit")}
          </ButtonStyled>
        ) : null}
        {isVisibleCancelButtonNeStatus ? (
          <ButtonStyled
            variant={EButtonVariants.Red}
            type="button"
            onClick={updateStatusHandler(AppointmentStatuses.Canceled)}
          >
            {cancelAppointmentLoading ? <Loading padding="0" /> : null}
            {t("booking.schedule_appointment.cancel_visit")}
          </ButtonStyled>
        ) : null}
        {isVisibleCompleteButton ? (
          <ButtonStyled
            variant={EButtonVariants.Default}
            type="button"
            onClick={updateStatusHandler(AppointmentStatuses.Successful)}
          >
            {updateStatusLoading ? <Loading padding="0" /> : null}
            {t("booking.schedule_appointment.complete_visit")}
          </ButtonStyled>
        ) : null}
      </ButtonWrapper>
    </Buttons>
  );
}
export default ScheduleAppointmentUpdateStatusComponent;
