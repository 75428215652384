import { useTranslation } from "react-i18next";
import StatisticItemComponent from "src/components/DashboardComponents/StatisticComponents/StatisticItemComponent/StatisticItemComponent";
import { IAppointmentsFilters } from "src/types";
import { useCountStatusesByRole } from "src/hooks/appointments/useCountStatusesByRole";
import { format } from "date-fns";
import {
  AppointmentSortFields,
  DistributionSystemEvents,
  useCalculateAverageScheduleAppointmentRateByClientQuery,
  useCalculateAverageScheduleAppointmentRateByDoctorQuery,
  useCountNewPatientsByClientQuery,
  useCountNewPatientsByDoctorQuery,
  useFindSentTemplateCountByEventByClientQuery,
  useFindSentTemplateCountByEventByDoctorQuery,
} from "src/graphql/generated";
import { useAuthContext } from "src/context/AuthContext";
import { useClinicsState } from "src/store/clinics/hooks";
import { toast } from "react-toastify";
import { useMemo } from "react";
import { allClinicsItemId } from "src/constants/constants";
import { List } from "./styled";

function StatisticView() {
  const { isDoctor, currentUserData } = useAuthContext();
  const { currentClinicId: clinicId } = useClinicsState();
  const { t } = useTranslation();

  const currentDate = useMemo(() => new Date(), []);

  const thirtyDaysAgo = new Date(currentDate);
  thirtyDaysAgo.setDate(currentDate.getDate() - 30);

  const filters: IAppointmentsFilters = {
    sortField: AppointmentSortFields.ScheduleTimeFrom,
    dateFrom: format(thirtyDaysAgo, "yyyy-MM-dd"),
    dateTo: format(currentDate, "yyyy-MM-dd"),
  };

  const {
    data: remindersCountByClientData,
    loading: remindersCountByClientLoading,
  } = useFindSentTemplateCountByEventByClientQuery({
    variables: {
      schema: {
        ...(clinicId !== allClinicsItemId && { clinicId }),
        event: DistributionSystemEvents.AppointmentRemindedBefore48Hours,
      },
    },
    skip: isDoctor || !clinicId || !currentUserData,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });

  const {
    data: remindersCountByDoctorData,
    loading: remindersCountByDoctorLoading,
  } = useFindSentTemplateCountByEventByDoctorQuery({
    variables: {
      schema: {
        ...(clinicId !== allClinicsItemId && { clinicId }),
        event: DistributionSystemEvents.AppointmentRemindedBefore48Hours,
      },
    },
    skip: !isDoctor || !clinicId || !currentUserData,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });

  const { data: newPatientsByClientData, loading: newPatientsByClientLoading } =
    useCountNewPatientsByClientQuery({
      variables: {
        schema: { ...(clinicId !== allClinicsItemId && { clinicId }) },
      },
      skip: isDoctor || !clinicId || !currentUserData,
      onError: (error) => {
        if (error) toast.error(error.message);
      },
    });

  const { data: newPatientsByDoctorData, loading: newPatientsByDoctorLoading } =
    useCountNewPatientsByDoctorQuery({
      variables: {
        schema: { ...(clinicId !== allClinicsItemId && { clinicId }) },
      },
      skip: !isDoctor || !clinicId || !currentUserData,
      onError: (error) => {
        if (error) toast.error(error.message);
      },
    });

  const {
    data: calculateAverageRateByClientData,
    loading: calculateAverageRateByByClientLoading,
  } = useCalculateAverageScheduleAppointmentRateByClientQuery({
    variables: {
      schema: { ...(clinicId !== allClinicsItemId && { clinicId }) },
    },
    skip: isDoctor || !clinicId || !currentUserData,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });

  const {
    data: calculateAverageRateByDoctorData,
    loading: calculateAverageRateByByDoctorLoading,
  } = useCalculateAverageScheduleAppointmentRateByDoctorQuery({
    variables: {
      schema: { ...(clinicId !== allClinicsItemId && { clinicId }) },
    },
    skip: !isDoctor || !clinicId || !currentUserData,
    onError: (error) => {
      if (error) toast.error(error.message);
    },
  });

  const { countStatusesByRole, countStatusesByRoleLoading } =
    useCountStatusesByRole({ filters });

  const newPatientsCountByRole = isDoctor
    ? newPatientsByDoctorData?.countNewPatientsByDoctor.count
    : newPatientsByClientData?.countNewPatientsByClient.count;

  const averageRateByRole = isDoctor
    ? calculateAverageRateByDoctorData
        ?.calculateAverageScheduleAppointmentRateByDoctor.rate
    : calculateAverageRateByClientData
        ?.calculateAverageScheduleAppointmentRateByClient.rate;

  const remindersCountByRole = isDoctor
    ? remindersCountByDoctorData?.findSentTemplateCountByEventByDoctor.sent
    : remindersCountByClientData?.findSentTemplateCountByEventByClient.sent;

  const statisticData = [
    {
      title: t("statistic.patients"),
      value: newPatientsCountByRole,
      loading: newPatientsByDoctorLoading || newPatientsByClientLoading,
    },
    {
      title: t("statistic.appointments"),
      value: countStatusesByRole?.all,
      loading: countStatusesByRoleLoading,
    },
    {
      title: t("statistic.reminders"),
      value: remindersCountByRole,
      loading: remindersCountByClientLoading || remindersCountByDoctorLoading,
    },
    {
      title: t("statistic.satisfaction"),
      value: averageRateByRole,
      loading:
        calculateAverageRateByByClientLoading ||
        calculateAverageRateByByDoctorLoading,
    },
    {
      title: t("statistic.canceled"),
      value: countStatusesByRole?.canceled,
      loading: countStatusesByRoleLoading,
    },
  ];

  return (
    <List>
      {statisticData.map((item) => (
        <StatisticItemComponent
          key={item.title}
          value={item.value}
          title={item.title}
          loading={item.loading}
        />
      ))}
    </List>
  );
}

export default StatisticView;
