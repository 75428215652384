import ChatUserComponent from "../ChatUserComponent/ChatUserComponent";
import { Row, Wrapper } from "./styled";
import { IChatPatientComponentProps } from "../types";

function HeaderChatComponent({ patient }: IChatPatientComponentProps) {
  // const { t } = useTranslation();
  return (
    <Wrapper>
      <Row>
        <ChatUserComponent patient={patient} />
        {/* <Unsubscribed>
          <MessageIcon height={16} width={16} />
          {t("messenger.unsubscribed")}
        </Unsubscribed> */}
        {/* <ActionsComponent /> */}
      </Row>
    </Wrapper>
  );
}

export default HeaderChatComponent;
