import * as yup from "yup";

export const editServiceCategoryValidationSchema = yup.object().shape({
  category: yup.object().shape({
    id: yup.string().notRequired(),
    name: yup
      .string()
      .min(2, "validation.field_min_2")
      .max(100, "validation.field_max_100")
      .required("validation.field_required"),
  }),
});
