import styled from "styled-components";

export const VariableContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const VariableItem = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`;
