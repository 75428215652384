import { Heading } from "src/UI/Heading";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  min-height: 100vh;
  padding: 20px 32px 32px 32px;
  z-index: 1;
`;

export const SideBar = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 25px;
  justify-content: space-between;
`;

export const ImageStyled = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  border-radius: 50%;
`;

export const IconWrapper = styled.div`
  min-width: 38px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  align-items: center;
  gap: 10px;

  width: 38px;
  height: 38px;
  background: ${({ theme }) => theme.colors.lightBiege};
  border-radius: 50%;
`;

export const ClinicWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

export const HeadingStyled = styled(Heading)`
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
