import { useMemo } from "react";

const useEditorToolbarOptions = (uploadCallback: any) => {
  const toolbarOptions = useMemo(
    () => ({
      options: ["inline", "textAlign", "colorPicker", "list", "image", "link"],
      inline: {
        options: ["bold", "italic", "underline"],
        bold: { icon: "/images/editor/bold.svg", title: "bold" },
        italic: {
          icon: "/images/editor/italic.svg",
          title: "italic",
        },
        underline: {
          icon: "/images/editor/underline.svg",
          title: "underline",
        },
      },
      textAlign: {
        options: ["left", "center", "right", "justify"],
        left: { icon: "/images/editor/align-left.svg", title: "left" },
        center: { icon: "/images/editor/align-center.svg", title: "center" },
        right: { icon: "/images/editor/align-right.svg", title: "right" },
        justify: { icon: "/images/editor/align-justify.svg", title: "justify" },
      },
      colorPicker: {
        icon: "/images/editor/colorPicker.svg",
        title: "colorPicker",
      },
      list: {
        options: ["ordered", "unordered"],
        ordered: { icon: "/images/editor/list-ordered.svg", title: "ordered" },
        unordered: { icon: "/images/editor/list.svg", title: "unordered" },
      },
      image: {
        icon: "/images/editor/image-plus.svg",
        urlEnabled: true,
        uploadEnabled: true,
        alignmentEnabled: true,
        uploadCallback,
        previewImage: true,
        inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
        alt: { present: false, mandatory: false },
        defaultSize: {
          height: "auto",
          width: "auto",
        },
      },
      link: {
        options: ["link"],
        link: { icon: "/images/editor/link.svg", title: "link" },
      },
    }),
    [uploadCallback]
  );

  return toolbarOptions;
};

export default useEditorToolbarOptions;
