import { Button } from "src/UI/Button";
import styled from "styled-components";

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 16px;
`;

export const ButtonStyled = styled(Button)`
  padding: 8px 10px;
  font-size: 16px;
  line-height: 100%;
  text-wrap: nowrap;
  width: fit-content;
  border-radius: 5px;
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-bottom: 16px;
`;

export const PageWrapper = styled.div`
  width: 100%;
`;
