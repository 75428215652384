import { Button } from "src/UI/Button";
import { Dropdown } from "src/UI/Dropdown";
import { Heading } from "src/UI/Heading";
import styled from "styled-components";

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 20px;
`;

export const HeadingStyled = styled(Heading)`
  font-size: 24px;
  line-height: 100%;
  white-space: nowrap;
`;

export const HeadingGray = styled(HeadingStyled)`
  color: ${({ theme }) => theme.colors.gray};
`;

export const Left = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  gap: 16px;
`;

export const DropdownStyled = styled(Dropdown)`
  border-radius: 5px;
  width: fit-content;
  min-width: 100px;

  & > div:first-child {
    padding: 8px;
  }
`;

export const ChevronsBlock = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  gap: 8px;
`;

export const ChevronButton = styled(Button)`
  border: none;
  background: none;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 0;
  width: fit-content;
  svg {
    width: 24px;
    height: 24px;
    path {
      stroke: ${({ theme }) => theme.colors.green};
    }
  }
`;
