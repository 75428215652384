import React from "react";
import { ActivityView, PatientAppointmentsView } from "src/views/PatientsVies";
import { useFindPatientById } from "src/graphql/hooks/useQueries";
import { useParams } from "react-router-dom";
import { Loading } from "src/UI/Loading";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { addPatientSchema } from "src/validation/schemas/addPatientSchema";
import { transformBeforeEditPatient } from "src/connectors/Patients/transformBeforeEditPatient";
import { LanguageIsoCodes, PatientGenders } from "src/graphql/generated";
import { Maybe } from "yup";
import { useTranslation } from "react-i18next";
import { useUpdatePatientById } from "src/graphql/hooks/useMutations";
import { toast } from "react-toastify";
import { FIND_PATIENT_BY_ID } from "src/graphql/queries";
import { formatDateToDateISO } from "src/utils";
import { Container, Wrapper } from "./styled";

export interface FormValues {
  id: string;
  first_name: string;
  last_name: string;
  dateOfBirth?: Date | null;
  gender?: Maybe<PatientGenders>;
  email: string | null;
  comment?: Maybe<string>;
  phone: string;
  passportNumber?: Maybe<string>;
  emailDistribution?: boolean;
  smsDistribution?: boolean;
  isAllergy?: string;
  allergyComment?: string;
}

function PatientPage() {
  const { id } = useParams();
  const { t, i18n } = useTranslation();

  const methods = useForm<FormValues>({
    mode: "onChange",
    resolver: yupResolver(addPatientSchema),
    defaultValues: {
      id: "",
      first_name: "",
      last_name: "",
      dateOfBirth: null,
      gender: undefined,
      email: null,
      comment: "",
      phone: "",
      passportNumber: "",
      isAllergy: "",
      allergyComment: "",
    },
  });

  const [updatePatientByIdMutation, { loading: updatePatientLoading }] =
    useUpdatePatientById();

  const { reset, handleSubmit } = methods;

  const { loading } = useFindPatientById({
    variables: { schema: { id } },
    fetchPolicy: "cache-and-network",
    onCompleted: (response) => {
      reset(transformBeforeEditPatient(response.findPatientById));
    },
  });

  const onSave = (formData: FormValues) => {
    updatePatientByIdMutation({
      variables: {
        schema: {
          comment: formData.comment || "",
          ...(formData.dateOfBirth && {
            dateOfBirth: formatDateToDateISO(formData.dateOfBirth as Date),
          }),
          firstName: formData.first_name,
          id: formData.id,
          gender: formData.gender,
          lastName: formData.last_name,
          passportNumber: formData.passportNumber || "",
          languageISOCode: i18n.language.toUpperCase() as LanguageIsoCodes,
          phoneNumber: `+${formData.phone}`,
          email: formData.email,
          allergens: formData.allergyComment || "",
          allergic: formData.isAllergy === "yes" ? true : false,
          // TODO: temporary
          emailDistribution: true,
          smsDistribution: true,
        },
      },
      refetchQueries: [
        {
          query: FIND_PATIENT_BY_ID,
          variables: { schema: { id } },
        },
      ],

      onCompleted() {
        toast.success(t("app.successful"));
      },
    });
  };

  return (
    <Container>
      <Wrapper>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSave)}>
            {!loading ? (
              <>
                <ActivityView updatePatientLoading={updatePatientLoading} />
                {/* TODO: temporary comment
          <RightView /> */}
              </>
            ) : (
              <Loading type="primary" padding="0" />
            )}
          </form>
        </FormProvider>
      </Wrapper>

      <Wrapper>
        <PatientAppointmentsView />
      </Wrapper>
    </Container>
  );
}

export default PatientPage;
