import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PatientEntityResponse } from "src/graphql/generated";

export interface PatientsState {
  newPatient: PatientEntityResponse | null;
}

const initialState: PatientsState = {
  newPatient: null,
};

export const patientsSlice = createSlice({
  name: "patients",
  initialState,
  reducers: {
    setPatient: (
      state,
      action: PayloadAction<PatientEntityResponse | null>
    ) => {
      state.newPatient = action.payload;
    },
  },
});

export const { setPatient } = patientsSlice.actions;
export default patientsSlice.reducer;
