import styled from "styled-components";

export const ClinicOptWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const OptItemTitle = styled.div`
  width: 100%;
`;

export const OptItemSubTitle = styled.div`
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  text-align: left;
  color: ${({ theme }) => theme.colors.gray};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
