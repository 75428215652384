import styled from "styled-components";

export const Wrapper = styled.div<{ isSelectedChat: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: ${({ isSelectedChat }) =>
    isSelectedChat ? "flex-start" : "center"};
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.background};
  width: 100%;
  flex: 1;
`;

export const EmptyBlock = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;

  svg path {
    stroke: ${({ theme }) => theme.colors.gray};
  }
`;
export const Text = styled.p`
  max-width: 206px;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray};
`;

export const Form = styled.form`
  width: 100%;
`;
