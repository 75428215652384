import { gql } from "@apollo/client";

export const CREATE_DOCTOR_INVITATION = gql`
  mutation CreateDoctorInvitation($schema: CreateDoctorInvitationSchema!) {
    createDoctorInvitation(schema: $schema) {
      createdAt
      description
      doctorTypeId
      id
      medicalCertificateNumber
      passportNumber
      phoneNumber
      promoCode
      userId
    }
  }
`;
