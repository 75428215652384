import { useAuthContext } from "src/context/AuthContext";

import { IAppointmentsFilters } from "src/types";
import {
  AppointmentSortFields,
  AppointmentStatuses,
  SortingOrders,
  useFindAndCountManyScheduleAppointmentsWithRelationsByDoctorQuery,
} from "src/graphql/generated";
import {
  ALL_APPOINTMENT_STATUS,
  allClinicsItemId,
  defaultVisitsLimit,
} from "src/constants/constants";
import { useClinicsState } from "src/store/clinics/hooks";
import { toast } from "react-toastify";
import { useFetchMore } from "src/hooks/useFetchMore";

interface IGetAppointmentsByClientFetchMore {
  filters: IAppointmentsFilters;
  isReminders?: false;
}

export function useScheduleAppointmentsByDoctorFetchMore({
  filters,
  isReminders,
}: IGetAppointmentsByClientFetchMore) {
  const { isDoctor, currentUserData } = useAuthContext();
  const { currentClinicId: clinicId } = useClinicsState();

  const { data, loading, fetchMore } =
    useFindAndCountManyScheduleAppointmentsWithRelationsByDoctorQuery({
      variables: {
        schema: {
          offset: 0,
          ...(!isReminders && { limit: defaultVisitsLimit }),
          sortOrder: filters.sortOrder || SortingOrders.Ascending,
          sortField: filters.sortField || AppointmentSortFields.CreatedAt,
          ...(clinicId !== allClinicsItemId && { clinicId }),
          ...(filters.status !==
            (ALL_APPOINTMENT_STATUS as AppointmentStatuses) && {
            status: filters.status,
          }),
          ...(filters.dateFrom && { dateFrom: filters.dateFrom }),
          ...(filters.dateTo && { dateTo: filters.dateTo }),
          ...(filters.minuteFrom && { minuteFrom: filters.minuteFrom }),
          ...(filters.minuteTo && { minuteTo: filters.minuteTo }),
          ...(filters.patientId && { patientId: filters.patientId }),
        },
      },
      onError: (error) => {
        if (error) toast.error(error.message);
      },
      skip: !clinicId || !isDoctor || !currentUserData,
      fetchPolicy: "network-only",
    });

  const { node, isLoading } = useFetchMore(() =>
    fetchMore({
      variables: {
        schema: {
          offset:
            data?.findAndCountManyScheduleAppointmentsWithRelationsByDoctor
              .appointments.length,
          ...(!isReminders && { limit: defaultVisitsLimit }),
          sortOrder: filters.sortOrder || SortingOrders.Ascending,
          sortField: filters.sortField || AppointmentSortFields.CreatedAt,
          ...(clinicId !== allClinicsItemId && { clinicId }),
          ...(filters.status !==
            (ALL_APPOINTMENT_STATUS as AppointmentStatuses) && {
            status: filters.status,
          }),
          ...(filters.dateFrom && { dateFrom: filters.dateFrom }),
          ...(filters.dateTo && { dateTo: filters.dateTo }),
          ...(filters.minuteFrom && { minuteFrom: filters.minuteFrom }),
          ...(filters.minuteTo && { minuteTo: filters.minuteTo }),
          ...(filters.patientId && { patientId: filters.patientId }),
        },
      },
    })
  );

  return {
    data,
    loading,
    node,
    isLoading,
  };
}
