import styled from "styled-components";

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  align-items: center;
  overflow: hidden;
  gap: 10px;

  width: 56px;
  height: 56px;
  background: ${({ theme }) => theme.colors.lightBiege};
  border-radius: 56px;

  svg {
    width: 32px;
    height: 32px;
  }
`;

export const DoctorInfoWrapStiled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DoctorNameStyled = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
`;

export const DoctorSpecialisationStyled = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.gray};
`;

export const ImageStyled = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
