import styled from "styled-components";

export const List = styled.div`
  display: flex;
  width: 100%;
  max-height: 100%;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
`;
export const LoadingWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;
