import {
  ClientEntityResponse,
  DoctorEntityResponse,
  UserEntityResponse,
} from "src/graphql/generated";
import { IUserStorageItem } from "src/store/app/types";

export type AdditionalKeys = {
  doctorId?: string;
  userId?: string;
  role: string;
};

export type CombinedUserType = UserEntityResponse &
  Partial<ClientEntityResponse> &
  Partial<DoctorEntityResponse>;

export type CombinedUserTypeWithAdditionalKeys = CombinedUserType &
  AdditionalKeys;

type AuthStateType = {
  isAuth: boolean;
  isAuth2Fa: boolean;
  isInit: boolean;
  isCompanyExists: boolean;
  loading: boolean;
  isPinCodeExists: boolean;
  currentUserData:
    | CombinedUserTypeWithAdditionalKeys
    | UserEntityResponse
    | undefined
    | null;
  localUsers: IUserStorageItem[];
};

type ActionType =
  | { type: "SUCCESS_AUTH"; payload: { stateAuth: boolean; state2FA: boolean } }
  | { type: "INIT" }
  | {
      type: "SET_USER";
      payload: UserEntityResponse | undefined | null;
    }
  | {
      type: "SET_LOCAL_USERS";
      payload: IUserStorageItem[];
    }
  | {
      type: "UPDATE_USER";
      payload: CombinedUserTypeWithAdditionalKeys;
    }
  | {
      type: "SET_IS_EXISTS_COMPANY";
      payload: boolean;
    }
  | {
      type: "SET_IS_EXISTS_PIN";
      payload: boolean;
    }
  | {
      type: "SET_LOADING";
      payload: boolean;
    };

export const actionTypes = {
  SUCCESS_AUTH: "SUCCESS_AUTH",
  INIT: "INIT",
  SET_USER: "SET_USER",
  UPDATE_USER: "UPDATE_USER",
  SET_IS_EXISTS_COMPANY: "SET_IS_EXISTS_COMPANY",
  SET_IS_EXISTS_PIN: "SET_IS_EXISTS_PIN",
  SET_LOCAL_USERS: "SET_LOCAL_USERS",
  SET_LOADING: "SET_LOADING",
} as const;

export function authReducer(state: AuthStateType, action: ActionType) {
  switch (action.type) {
    case actionTypes.INIT: {
      return { ...state, isInit: true };
    }
    case actionTypes.SUCCESS_AUTH: {
      return {
        ...state,
        isAuth: action.payload.stateAuth,
        isAuth2Fa: action.payload.state2FA,
        isInit: true,
      };
    }
    case actionTypes.SET_USER: {
      return {
        ...state,
        currentUserData: action.payload,
      };
    }
    case actionTypes.UPDATE_USER: {
      return {
        ...state,
        currentUserData: {
          ...state.currentUserData,
          ...action.payload,
        },
      };
    }
    case actionTypes.SET_IS_EXISTS_COMPANY: {
      return {
        ...state,
        isCompanyExists: action.payload,
      };
    }
    case actionTypes.SET_IS_EXISTS_PIN: {
      return {
        ...state,
        isPinCodeExists: action.payload,
      };
    }
    case actionTypes.SET_LOCAL_USERS: {
      return {
        ...state,
        localUsers: action.payload,
      };
    }
    case actionTypes.SET_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}
