import { gql } from "@apollo/client";

export const CREATE_VISIT_BY_DOCTOR = gql`
  mutation CreateScheduleAppointmentByDoctor(
    $schema: CreateScheduleAppointmentByDoctorSchema!
  ) {
    createScheduleAppointmentByDoctor(schema: $schema) {
      createdAt
      id
      patientId
      scheduleId
      status
    }
  }
`;
