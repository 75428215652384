import styled from "styled-components";

export const MultiselectStyled = styled.div`
  position: relative;
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 180px;
`;

export const RowStyled = styled.div<{ showMenu: boolean }>`
  font-style: normal;
  width: 100%;
  font-weight: 500;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 8px 10px;
  font-size: 16px;
  line-height: 100%;
  gap: 4px;
  background: ${({ theme, showMenu }) =>
    theme.colors[showMenu ? "white" : "lightBiege"]};
  border: 1px solid
    ${({ theme, showMenu }) =>
      showMenu ? theme.colors.borderInputDefault : "transparent"};
  border-radius: ${({ showMenu }) => (showMenu ? "5px 5px 0 0" : "5px")};
  color: ${({ theme }) => theme.colors.black};
`;

export const IconStyled = styled.div<{ showMenu: boolean }>`
  margin-left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ showMenu }) =>
    showMenu
      ? `svg {
    transform: rotateZ(-180deg);
  };`
      : ""}
`;
export const Menu = styled.div<{ showMenu: boolean }>`
  visibility: ${({ showMenu }) => (showMenu ? "visible" : "hidden")};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  gap: 2px;
  padding: 4px 0;
  top: 100%;
  left: 0;
  box-sizing: border-box;
  right: 0;
  transition: height 0.25s ease-out;
  opacity: ${({ showMenu }) => (showMenu ? 1 : 0)};
  z-index: 100;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  border-top: none;
  border-radius: 0 0 5px 5px;
  height: ${({ showMenu }) => (showMenu ? "fit-content" : 0)};
  max-height: 300px;
  width: 100%;
  overflow-y: auto;
`;

export const Placeholder = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
`;

export const RowItemTextStyled = styled.p`
  cursor: pointer;
`;

export const RowItemStyled = styled.div<{
  isActive: boolean;
  isSelected: boolean;
}>`
  font-style: normal;
  padding: 7px 14px;
  font-weight: 500;
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 100%;
  color: ${({ theme, isActive, isSelected }) =>
    theme.colors[isActive || isSelected ? "green" : "black"]};
  cursor: pointer;
  &:hover {
    background-color: #e7f6f5;
    color: ${({ theme }) => theme.colors.green};
  }
`;

export const Label = styled.label<{ isChecked: boolean; isDisabled?: boolean }>`
  display: flex;
  width: 20px;
  min-width: 20px;
  margin-left: 10px;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 20px;
  background: ${({ theme, isChecked }) =>
    theme.colors[isChecked ? "green" : "white"]};
  border: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.03);
  border-radius: 3px;
  cursor: ${({ isDisabled }) => (isDisabled ? "default" : "pointer")};
  svg path {
    stroke: ${({ theme }) => theme.colors.white};
  }
`;
