import { useEffect, useState } from "react";

interface IPagination {
  limit: number;
  count?: number;
  localStorageKey?: string;
}

export const usePagination = ({
  count,
  limit,
  localStorageKey,
}: IPagination) => {
  const [currentPage, setCurrentPage] = useState(0);

  const pages = count ? Array.from(Array(Math.ceil(count / limit)).keys()) : [];

  const offset = currentPage * limit;

  useEffect(() => {
    if (!localStorageKey) return;

    const localStoragePageNumber = Number(
      localStorage.getItem(localStorageKey)
    );

    setCurrentPage(localStoragePageNumber);
  }, [localStorageKey]);

  useEffect(() => {
    if (!localStorageKey) return;

    localStorage.setItem(localStorageKey, String(currentPage));
  }, [currentPage]);

  useEffect(() => {
    if (!localStorageKey) return;

    const localStoragePageNumber = Number(
      localStorage.getItem(localStorageKey)
    );

    if (pages.length && localStoragePageNumber >= pages.length) {
      localStorage.setItem(localStorageKey, String(pages.length - 1));
      setCurrentPage(pages.length - 1);
    }
  }, [pages.length]);

  return {
    offset,
    pages,
    currentPage,
    setCurrentPage,
  };
};
