import { IUser } from "../types";

export const user: IUser = {
  name: "Artur",
  soname: "Wilk",
  email: "a@goclients.pl",
  phone: "+48 723 670 019",
};

// temporarily until the API is connecting
export const mockSearchDropdown: Array<any> = [
  {
    ...user,
    specialization: "Stomatolog Chirurg",
  },
  {
    ...user,
    image: "account.png",
    patient_id: 0,
    type: "Appointment",
  },
  {
    patient: { ...user, patient_id: 1 },
    procedure: "Wizyta u dentysty",
    date: new Date(1669663766000),
    type: "Patient",
  },
];
