import { gql } from "@apollo/client";

export const RESCHEDULE_APPOINTMENT_BY_CLIENT = gql`
  mutation RescheduleAppointmentByClient(
    $schema: RescheduleScheduleAppointmentByClientSchema!
  ) {
    rescheduleAppointmentByClient(schema: $schema) {
      rescheduled
    }
  }
`;
