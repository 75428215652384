import { FC } from "react";
import { LangFlags } from "src/constants/constants";
import { WidgetComponent } from "src/components/BookingComponents";
import { Heading, List, Wrapper } from "./styled";

interface ComponentProps {
  links: { path: string; name: string | null }[];
}

const WidgetsListView: FC<ComponentProps> = ({ links }) => {
  const generatedLinks = LangFlags.map((lang) => {
    const Icon = lang.flag;

    return (
      <div key={Math.random()}>
        <Heading>
          <Icon />
          Go Clients Tech {lang.code.toUpperCase()}
        </Heading>
        <List>
          {links?.map((link) => {
            return (
              <WidgetComponent
                key={Math.random()}
                link={`${link.path}&langCode=${lang.code}`}
                name={link.name}
                lang={lang.code}
              />
            );
          })}
        </List>
      </div>
    );
  });

  return <Wrapper>{generatedLinks}</Wrapper>;
};

export default WidgetsListView;
