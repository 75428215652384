import { gql } from "@apollo/client";

export const UPDATE_CLINIC_WORKING_DAY_BY_CLINIC_ID = gql`
  mutation UpdateClinicWorkingDaysByClinicId(
    $schema: UpdateClinicWorkingDaysByClinicIdSchema!
  ) {
    updateClinicWorkingDaysByClinicId(schema: $schema) {
      updated
    }
  }
`;
