import styled from "styled-components";

export const Dashboard = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  gap: 20px;
  background: ${({ theme }) => theme.colors.backgroundDashboard};
  overflow: hidden;
`;

export const PageStyled = styled.div`
  display: flex;
  width: calc(100% - 106px);
  padding: 32px 54px 32px 56px;
  flex-direction: column;
  gap: 32px;
  margin-left: 106px;
  // ${({ theme }) => theme.mediaQueries.ll} {
  //   padding-left: 40px;
  //   padding-right: 40px;
  // }
`;
