import { gql } from "@apollo/client";

export const CREATE_COMPANY = gql`
  mutation CreateCompany($schema: CreateCompanySchema!) {
    createCompany(schema: $schema) {
      address
      city
      countryId
      createdAt
      dateOfRegistrationInKrs
      email
      id
      name
      nip
      ownerId
      postCode
      regon
      street
    }
  }
`;
