export enum EViewsTypes {
  Booking = "BOOKING",
  WaitList = "WAITLIST",
}

export interface FormValues {
  clinicId?: string | null;
  doctorId?: string | null;
  serviceId?: string | null;
  roomId?: string | null;
  patientId?: string | null;
  minuteFrom?: number | null;
  minuteTo?: number | null;
  date?: string | null;
  dateFrom: string | null;
  dateTo: string | null;
  appointmentId?: string | null;
}
