import { enUS, pl } from "date-fns/locale";
import { AppointmentStatuses, LanguageIsoCodes } from "src/graphql/generated";
import { ESupportedLangs } from "src/types/enum";
import { TFunction } from "i18next";

export const getDateFnsLocale = (selectedLocal: string) => {
  switch (selectedLocal) {
    case ESupportedLangs.pl:
      return pl;
    case ESupportedLangs.en:
      return enUS;
    default:
      return enUS;
  }
};

export const getAppointmetStatusWithTranslation = (
  translate: TFunction<"translation", undefined>,
  status?: AppointmentStatuses
) => {
  switch (status) {
    case AppointmentStatuses.New:
      return translate("appointments.tabs.new");
    case AppointmentStatuses.Missed:
      return translate("appointments.tabs.missed");
    case AppointmentStatuses.Canceled:
      return translate("appointments.tabs.canceled");
    case AppointmentStatuses.Successful:
      return translate("appointments.tabs.successful");
    case AppointmentStatuses.ConfirmedByPatient:
      return translate("appointments.tabs.confirmed_by_patient");
    case AppointmentStatuses.ConfirmedByApplication:
      return translate("appointments.tabs.confirmed_by_application");

    default:
      return "-";
  }
};

export const getLanguageIsoCode = (language: string): LanguageIsoCodes => {
  return LanguageIsoCodes.En.toLocaleLowerCase() ===
    language.toLocaleLowerCase()
    ? LanguageIsoCodes.En
    : LanguageIsoCodes.Pl;
};
