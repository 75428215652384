import { useTranslation } from "react-i18next";
import { useClinicsState } from "src/store/clinics/hooks";
import {
  PatientEntityResponse,
  useFindAndCountManyLastSmsByClientQuery,
} from "src/graphql/generated";
import { Loading } from "src/UI/Loading";
import { toast } from "react-toastify";
import { useMessengerAction } from "src/store/messenger/hooks";
import { defaultLastSmsLimit } from "src/constants/constants";
import EmptyText from "src/components/EmptyText/EmptyText";
import { ChatListComponent } from "../../../components/MessengerComponents";

import { Header, HeadingStyled, LoadingWrap, Wrapper } from "./styled";

function DialogsView() {
  const { t } = useTranslation();
  const { currentClinicId: clinicId } = useClinicsState();
  const { onSelectChat } = useMessengerAction();

  const {
    data: chatsData,
    loading: chatsLoading,
    fetchMore,
  } = useFindAndCountManyLastSmsByClientQuery({
    variables: { schema: { clinicId, offset: 0, limit: defaultLastSmsLimit } },
    onCompleted(response) {
      if (response.findAndCountManyLastSmsByClient.sms.length) {
        onSelectChat(
          response.findAndCountManyLastSmsByClient.sms[0]
            .patient as PatientEntityResponse
        );
      } else {
        onSelectChat(null);
      }
    },
    onError(error) {
      toast.error(error.message);
    },
    skip: !clinicId,
  });

  return (
    <Wrapper>
      <Header>
        <HeadingStyled as="h3">{t("messenger.title")}</HeadingStyled>
      </Header>
      {chatsLoading ? (
        <LoadingWrap>
          <Loading padding="0" />
        </LoadingWrap>
      ) : chatsData?.findAndCountManyLastSmsByClient?.sms.length ? (
        <ChatListComponent chats={chatsData} fetchMore={fetchMore} />
      ) : (
        <EmptyText text={t("app.chats_not_added")} />
      )}
    </Wrapper>
  );
}

export default DialogsView;
