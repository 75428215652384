import { useDispatch, useSelector } from "react-redux";
import { useCallback, useMemo } from "react";
import { PatientEntityResponse } from "src/graphql/generated";

import { AppDispatch, RootState } from "../index";

import { setPatient } from "./patientsSlice";

export const getPatients = (state: RootState) => state.patients;

export function usePatientsAction() {
  const dispatch = useDispatch<AppDispatch>();

  const onSetPatient = useCallback(
    (patient: PatientEntityResponse | null) => {
      dispatch(setPatient(patient));
    },
    [dispatch]
  );

  return useMemo(
    () => ({
      onSetPatient,
    }),
    [onSetPatient]
  );
}

export function usePatientsState() {
  return useSelector(getPatients);
}
