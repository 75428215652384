import React from "react";
import { useTranslation } from "react-i18next";
import { ScheduleAppointmentUpdateEntityResponse } from "src/graphql/generated";
import { Loading } from "src/UI/Loading";
import { EmptyTextStyled, TabsStyled, UpdatesListWrapper } from "./styled";
import UpdateItem from "./ScheduleAppointmentUpdatesItemComponent";

interface IUScheduleAppointmentUpdatesComponent {
  updates: ScheduleAppointmentUpdateEntityResponse[];
  loading: boolean;
}

function ScheduleAppointmentUpdatesComponent({
  updates,
  loading,
}: IUScheduleAppointmentUpdatesComponent) {
  const { t } = useTranslation();

  return loading ? (
    <Loading padding="0" />
  ) : (
    <TabsStyled
      headers={[
        <>
          {t("booking.schedule_appointment.changes")}{" "}
          <span>{updates?.length}</span>
        </>,
      ]}
      contents={[
        <UpdatesListWrapper>
          {updates?.length ? (
            updates?.map((update) => (
              <UpdateItem key={update.id} update={update} />
            ))
          ) : (
            <EmptyTextStyled>
              {t("booking.schedule_appointment.no_changes")}
            </EmptyTextStyled>
          )}
        </UpdatesListWrapper>,
        <>{t("booking.schedule_appointment.messages")}</>,
      ]}
    />
  );
}
export default ScheduleAppointmentUpdatesComponent;
