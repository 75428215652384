import { FC, useMemo, useState } from "react";
import { useCalendarAction } from "src/store/calendar/hooks";
import { endOfDay, formatISO, startOfDay } from "date-fns";
import { FormProvider, useForm } from "react-hook-form";
import {
  AppointmentStatuses,
  ClinicRoomEntityResponse,
} from "src/graphql/generated";
import { useGetDoctorsAgregated } from "src/hooks/doctors/useGetDoctorsAgregated";
import { useCabinetsList } from "src/hooks/clinics/useCabinetsList";
import { IDoctorsAgregated } from "src/types";
import { useGetPatientsLazyLoading } from "src/hooks/patients/useGetPatientsLazyLoading";
import { useClinicsState } from "src/store/clinics/hooks";
import { Form, Wrapper } from "./styles";
import SettingFormComponent from "./SettingFormComponent/SettingFormComponent";
import { ComponentProps, FormValues } from "./types";
import PatientOptComponent from "./PatientOptComponent/PatientOptComponent";

const SettingComponent: FC<ComponentProps> = ({ onNavigate }) => {
  const { currentClinicId: clinicId } = useClinicsState();
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const { onSetFilters, onSetCabinetsGroup, onSetDoctorsGroup } =
    useCalendarAction();

  const methods = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      roomIds: [],
      doctorIds: [],
      patientId: "",
      status: "",
      from: null,
      to: null,
    },
  });
  const { handleSubmit, reset } = methods;

  // Doctors
  const { doctorsList } = useGetDoctorsAgregated();
  const doctorsOpts = useMemo(
    () =>
      doctorsList.map((doctor: IDoctorsAgregated) => ({
        value: doctor?.doctorEntity?.doctorId,
        key: `${doctor?.doctorEntity?.firstName} ${doctor?.doctorEntity?.lastName}`,
      })),
    [doctorsList]
  );

  // Cabinets
  const { cabinetsList } = useCabinetsList();
  const cabinetsOpts = useMemo(
    () =>
      cabinetsList.map((cabinet: ClinicRoomEntityResponse) => ({
        value: cabinet.id,
        key: cabinet.name,
      })),
    [cabinetsList]
  );

  // Patients
  const { patientsList, searchPatient, fetchMore, setSearchPatient } =
    useGetPatientsLazyLoading({ filters: { clinicId } });
  const patientsOpts = useMemo(() => {
    const patientsOptsArray = patientsList?.patients?.map((patient: any) => ({
      item: <PatientOptComponent key={patient.id} patient={patient} />,
      value: patient.id,
    }));
    return patientsOptsArray || [];
  }, [patientsList]);

  // Statuses
  const statusOpts = Object.keys(AppointmentStatuses).map((key) => ({
    item: key,
    value: key,
  }));

  const resetFilters = () => {
    onSetFilters({
      roomIds: [],
      doctorIds: [],
      patientId: "",
      status: "",
    });
    setSearchPatient("");
    reset();
    onSetDoctorsGroup([]);
    onSetCabinetsGroup([]);
    setShowMenu(false);
  };

  const applyFilters = (formData: FormValues) => {
    if (formData?.from) {
      onNavigate("DATE", formData.from);
      onSetFilters({ dateFrom: formatISO(startOfDay(formData.from)) });
    }
    if (formData?.to) {
      onSetFilters({ dateTo: formatISO(endOfDay(formData.to)) });
    }
    onSetFilters({ roomIds: formData.roomIds });
    onSetFilters({ doctorIds: formData.doctorIds });
    onSetFilters({ patientId: formData.patientId });
    onSetFilters({ status: formData.status });

    const selectedCabinetsList = cabinetsOpts.filter((cabinet) =>
      formData?.roomIds.includes(cabinet.value)
    );
    const selectedDoctorsList = doctorsOpts.filter((doctor) =>
      formData?.doctorIds.includes(doctor.value)
    );

    onSetDoctorsGroup(selectedDoctorsList);
    onSetCabinetsGroup(selectedCabinetsList);
    setShowMenu(false);
  };

  return (
    <Wrapper>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(applyFilters)}>
          <SettingFormComponent
            showMenu={showMenu}
            doctorsOpts={doctorsOpts}
            cabinetsOpts={cabinetsOpts}
            patientsOpts={patientsOpts}
            statusOpts={statusOpts}
            setShowMenu={setShowMenu}
            onResetFilters={resetFilters}
            fetchMore={fetchMore}
            setSearchPatient={setSearchPatient}
            patientsList={patientsList}
            searchPatient={searchPatient}
          />
        </Form>
      </FormProvider>
    </Wrapper>
  );
};

export default SettingComponent;
