import React from "react";
import PhoneInput from "react-phone-input-2";
import { PhoneBlock } from "./styled";

interface IPhoneTypographyComponent {
  phone: string;
  isShowFlag?: boolean;
}

function PhoneTypographyComponent({
  phone,
  isShowFlag = false,
}: IPhoneTypographyComponent) {
  return (
    <PhoneBlock isShowFlag={isShowFlag}>
      <PhoneInput value={phone} disabled />
    </PhoneBlock>
  );
}

export default PhoneTypographyComponent;
