import styled from "styled-components";

export const Form = styled.form`
  width: 100%;
  gap: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 1;
`;

export const Column = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 842px;
`;

export const Row = styled.div`
  gap: 20px;
  display: flex;
`;
