import { gql } from "@apollo/client";

export const UPDATE_SCHEDULE_APPOINTMENT_MISSED_STATUS_BY_CLIENT = gql`
  mutation UpdateScheduleAppointmentMissedStatusByClient(
    $schema: UpdateScheduleAppointmentMissedSchema!
  ) {
    updateScheduleAppointmentMissedStatusByClient(schema: $schema) {
      updated
    }
  }
`;
