import { parsePhoneNumber } from "awesome-phonenumber";
import {
  GeocoderContext,
  LocationValue,
} from "src/UI/Input/geocoderInputRHF/InputGeocoderRHF";
import * as yup from "yup";

export const addClinicValidationSchema = yup.object().shape({
  title: yup
    .string()
    .min(2, "validation.field_min_2")
    .max(100, "validation.field_max_100")
    .required("validation.field_required"),
  clinicCode: yup.string().max(100, "validation.field_max_100").notRequired(),
  licenseNumber: yup
    .string()
    .max(100, "validation.field_max_100")
    .test("check-min-length-2", "validation.field_min_2", (value) => {
      if (value && value.length < 2) return false;
      return true;
    }),
  description: yup
    .string()
    .max(300, "validation.field_max_300")
    .test("check-min-length-5", "validation.field_min_5", (value) => {
      if (value && value.length < 5) return false;
      return true;
    }),
  phone: yup
    .string()
    .required("validation.field_required")
    .test("is-valid", "validation.invalid_phone_number_required", (val) => {
      return parsePhoneNumber(`+${val}` as string).possible;
    }),
  photo: yup.string(),
  location: yup
    .mixed()
    .nullable()
    .test("check-require", "validation.field_required", (value) => {
      const placeTypes = [
        "address",
        "street",
        "intersection",
        "poi",
        "editPlace",
      ];
      if (value) {
        const isCorrectAddress = (value as LocationValue)?.place_type?.some(
          (type: string) => placeTypes.includes(type)
        );

        const index = (value as LocationValue)?.context?.find(
          (it: GeocoderContext) => it.id.includes("postcode")
        )?.text;

        const locationIndex = index || (value as LocationValue)?.index;

        if (!locationIndex) {
          throw new yup.ValidationError(
            "app.geocoder.not_index_error",
            null,
            `location`
          );
        } else if (!isCorrectAddress) {
          throw new yup.ValidationError(
            "app.geocoder.not_correct_address_error",
            null,
            `location`
          );
        }
      }
      return !!value;
    }),
});
