import { gql } from "@apollo/client";

export const CREATE_PATIENT = gql`
  mutation CreatePatient($schema: CreatePatientSchema!) {
    createPatient(schema: $schema) {
      activated
      comment
      companyId
      createdAt
      dateOfBirth
      email
      emailDistribution
      firstName
      gender
      id
      lastName
      phoneNumber
      smsDistribution
      passportNumber
      status
    }
  }
`;
