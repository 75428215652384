import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "src/UI/Modal";
import {
  AggregatedScheduleAppointmentEntityWithRelationsByClientResponse,
  AggregatedScheduleAppointmentEntityWithRelationsByDoctorResponse,
  AppointmentStatuses,
  FileEntityResponse,
  ScheduleEntityResponse,
} from "src/graphql/generated";
import { useAppAction, useAppState } from "src/store/app/hooks";
import { EModals } from "src/store/app/types";
import AvatarComponent from "src/components/AvatarComponent/AvatarComponent";
import {
  getCurrencySimbolByCode,
  getDateFromScheduleAppointment,
} from "src/utils";
import { useAuthContext } from "src/context/AuthContext";
import {
  useFindManyFilesByIds,
  useFindManyScheduleAppointmentUpdatesByIdByClient,
} from "src/graphql/hooks/useQueries";
import { DoctorIcon } from "src/UI/Svg";
import { useBookingAction } from "src/store/booking/hooks";
import { useNavigate } from "react-router-dom";
import { PATHS } from "src/constants/constants";
import { Loading } from "src/UI/Loading";
import {
  CardStyled,
  Content,
  DoctorOptInfoWrap,
  DoctorOptWrap,
  DoctorWrapper,
  DotSeparator,
  IconWrapper,
  ImageStyled,
  InfoDescriptionWrapper,
  PriceWrapper,
  OptItemSubTitle,
  OptItemTitle,
  Price,
  PriceLabel,
  DescriptionWrapper,
  Wrapper,
  CardHeader,
  PatientContent,
  ServiceName,
  ServiceDescription,
} from "./styled";
import ScheduleAppointmentUpdatesComponent from "./ScheduleAppointmentUpdatesComponent";
import ScheduleAppointmentUpdateStatusComponent from "./ScheduleUpdatedStatusComponent";

interface TypeByDoctor
  extends AggregatedScheduleAppointmentEntityWithRelationsByDoctorResponse {
  doctor?: any;
}

function SheduleAppointmentModal() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { isDoctor, currentUserData } = useAuthContext();
  const { onCloseModal } = useAppAction();
  const { onSetRescheduleAppointment } = useBookingAction();
  const { modals } = useAppState();
  const [appointment, setAppointment] = useState<
    | AggregatedScheduleAppointmentEntityWithRelationsByClientResponse
    | TypeByDoctor
    | null
  >(null);

  const { data: appointmentsUpdatesData, loading: appointmentsUpdatesLoading } =
    useFindManyScheduleAppointmentUpdatesByIdByClient({
      variables: { schema: { appointmentId: appointment?.id } },
      skip: !appointment?.id || isDoctor,
    });

  const doctor = isDoctor ? currentUserData : appointment?.doctor;

  const { data: logoFiles, loading } = useFindManyFilesByIds({
    variables: {
      schema: {
        ids: [doctor?.avatarFileId],
      },
    },
    skip: !doctor?.avatarFileId || !modals[EModals.ScheduleAppointment].active,
  });

  const avatarFile = logoFiles?.findManyFilesByIds?.files?.find(
    (file: FileEntityResponse) => file.id === doctor?.avatarFileId
  );

  useEffect(() => {
    if (modals[EModals.ScheduleAppointment]?.data?.appointment) {
      setAppointment(modals[EModals.ScheduleAppointment]?.data?.appointment);
    }
  }, [modals[EModals.ScheduleAppointment]?.data?.appointment]);

  const closeModal = useCallback(() => {
    onCloseModal(EModals.ScheduleAppointment);
  }, [onCloseModal]);

  const setAppointmentToStore = () => {
    onSetRescheduleAppointment(
      appointment as AggregatedScheduleAppointmentEntityWithRelationsByClientResponse
    );
    navigate(`${PATHS.cp}${PATHS.booking}${PATHS.settings}${PATHS.addVisit}`);
    closeModal();
  };

  if (!modals[EModals.ScheduleAppointment].active) return null;

  return (
    <Modal modal={EModals.ScheduleAppointment} onCloseAction={closeModal}>
      <Wrapper>
        <CardStyled
          title={t("booking.add_service.label.delay")}
          headerChildren={
            <CardHeader>
              {appointment?.schedule.dateFrom &&
              appointment?.schedule.minuteFrom
                ? getDateFromScheduleAppointment(
                    appointment?.schedule.dateFrom,
                    appointment?.schedule.minuteFrom,
                    i18n
                  )
                : null}
            </CardHeader>
          }
        >
          <Content>
            <DoctorWrapper>
              <DoctorOptWrap>
                <IconWrapper>
                  {!loading ? (
                    avatarFile ? (
                      <ImageStyled src={avatarFile.url} alt="#" />
                    ) : (
                      <DoctorIcon />
                    )
                  ) : (
                    <Loading padding="0" />
                  )}
                </IconWrapper>
                <DoctorOptInfoWrap>
                  <OptItemTitle>{`${doctor?.firstName} ${doctor?.lastName}`}</OptItemTitle>
                  {(!!doctor?.phoneNumber || !!doctor?.email) && (
                    <OptItemSubTitle>
                      {doctor?.phoneNumber}
                      {!!doctor?.phoneNumber && !!doctor?.email && (
                        <DotSeparator />
                      )}
                      {doctor?.email}
                    </OptItemSubTitle>
                  )}
                </DoctorOptInfoWrap>
              </DoctorOptWrap>

              <InfoDescriptionWrapper>
                <PriceWrapper>
                  <PriceLabel>
                    {t("booking.add_service.label.delay")}
                  </PriceLabel>
                  <Price>{`${appointment?.service?.durationInMinutes} ${t(
                    "booking.services.actions.min"
                  )}`}</Price>
                </PriceWrapper>
                <PriceWrapper>
                  <PriceLabel>
                    {t("booking.add_service.label.price")}
                  </PriceLabel>
                  <Price>
                    {appointment?.service?.price
                      ?.toLocaleString()
                      .replace(",", " ")}{" "}
                    {getCurrencySimbolByCode(
                      appointment?.service?.currencyCode as string
                    )}
                  </Price>
                </PriceWrapper>
              </InfoDescriptionWrapper>
            </DoctorWrapper>
            <DescriptionWrapper>
              <ServiceName>{appointment?.service?.name}</ServiceName>
              <ServiceDescription>
                {appointment?.service?.description}
              </ServiceDescription>
            </DescriptionWrapper>
          </Content>
        </CardStyled>
        <CardStyled
          title={t("history.patient")}
          headerChildren={
            <CardHeader>{appointment?.patient.passportNumber}</CardHeader>
          }
        >
          <PatientContent>
            <DoctorWrapper>
              <AvatarComponent
                firstName={appointment?.patient.firstName as string}
                lastName={appointment?.patient.lastName as string}
                size={56}
              />
              <DoctorOptInfoWrap>
                <OptItemTitle>{`${appointment?.patient.firstName} ${appointment?.patient.lastName}`}</OptItemTitle>
                {(!!appointment?.patient.phoneNumber ||
                  !!appointment?.patient.email) && (
                  <OptItemSubTitle>
                    {appointment?.patient.phoneNumber}
                    {!!appointment?.patient.phoneNumber &&
                      !!appointment?.patient.email && <DotSeparator />}
                    {appointment?.patient.email}
                  </OptItemSubTitle>
                )}
              </DoctorOptInfoWrap>
            </DoctorWrapper>
            <ScheduleAppointmentUpdateStatusComponent
              setAppointmentToStore={setAppointmentToStore}
              id={appointment?.id as string}
              status={appointment?.status as AppointmentStatuses}
              schedule={appointment?.schedule as ScheduleEntityResponse}
            />
          </PatientContent>
        </CardStyled>
        {!isDoctor ? (
          <CardStyled
            title={t("booking.schedule_appointment.changes_and_messages")}
            isBottomBorderNone
          >
            <ScheduleAppointmentUpdatesComponent
              updates={
                appointmentsUpdatesData
                  ?.findManyScheduleAppointmentUpdatesByIdByClient
                  ?.appointmentUpdates
              }
              loading={appointmentsUpdatesLoading}
            />
          </CardStyled>
        ) : null}
      </Wrapper>
    </Modal>
  );
}

export default SheduleAppointmentModal;
