import { useTranslation } from "react-i18next";
import { PointIcon } from "src/UI/Svg";
import {
  AggregatedScheduleWaitlistEntityByClientResponse,
  WaitlistStatuses,
} from "src/graphql/generated";
import { getFormatDateByLocale } from "src/utils";
import { theme } from "src/theme";
import { useAppAction } from "src/store/app/hooks";
import { Loading } from "src/UI/Loading";
import {
  EmptyTextStyled,
  LinkStyled,
  StatusWrapper,
  Table,
  Wrapper,
} from "./styled";

interface ITableListComponent {
  waitLists: AggregatedScheduleWaitlistEntityByClientResponse[];
  loading: boolean;
}

function TableListComponent({ waitLists, loading }: ITableListComponent) {
  const { t, i18n } = useTranslation();
  const { onShowScheduleWaitingAppointmentModal } = useAppAction();

  const getWaitListStatus = (status: WaitlistStatuses) => {
    switch (status) {
      case WaitlistStatuses.Completed:
        return (
          <StatusWrapper color={theme.colors.green}>
            <PointIcon fill={theme.colors.green} />
            {WaitlistStatuses.Completed}
          </StatusWrapper>
        );

      case WaitlistStatuses.Pending:
        return (
          <StatusWrapper color={theme.colors.darkGrey}>
            <PointIcon fill={theme.colors.darkGrey} />
            {WaitlistStatuses.Pending}
          </StatusWrapper>
        );

      case WaitlistStatuses.PendingUnconfirmed:
        return (
          <StatusWrapper color={theme.colors.darkGrey}>
            <PointIcon fill={theme.colors.darkGrey} />
            {WaitlistStatuses.PendingUnconfirmed}
          </StatusWrapper>
        );

      case WaitlistStatuses.Closed:
        return (
          <StatusWrapper color={theme.colors.red}>
            <PointIcon fill={theme.colors.red} />
            {WaitlistStatuses.Closed}
          </StatusWrapper>
        );

      default:
        break;
    }
  };

  const handleShowModal =
    (waitListItem: AggregatedScheduleWaitlistEntityByClientResponse) => () => {
      onShowScheduleWaitingAppointmentModal({
        data: {
          appointment: waitListItem,
        },
      });
    };

  return (
    <Wrapper>
      {loading ? (
        <Loading padding="0" />
      ) : waitLists?.length ? (
        <Table>
          <thead>
            <tr>
              <th>{t("waitlist.table.header.patient_name")}</th>
              <th>{t("waitlist.table.header.patient_cellphone")}</th>
              <th>{t("waitlist.table.header.service")}</th>
              <th>{t("waitlist.table.header.doctor")}</th>
              <th>{t("waitlist.table.header.date")}</th>
              <th>{t("waitlist.table.header.status")}</th>
              <th>{t("waitlist.table.header.date_created")}</th>
            </tr>
          </thead>
          <tbody>
            {waitLists?.map((waitListItem) => (
              <tr key={waitListItem.id}>
                <td>
                  <LinkStyled onClick={handleShowModal(waitListItem)}>
                    {waitListItem.patient.firstName}{" "}
                    {waitListItem.patient.lastName}
                  </LinkStyled>
                </td>
                <td>{waitListItem.patient.phoneNumber}</td>
                <td>{waitListItem.service.name}</td>
                <td>
                  {waitListItem.doctor?.firstName}{" "}
                  {waitListItem.doctor?.lastName}
                </td>
                <td>
                  {getFormatDateByLocale(
                    waitListItem.dateTo as string,
                    i18n?.resolvedLanguage
                  )}
                </td>
                <td>{getWaitListStatus(waitListItem.status)}</td>
                <td>
                  {getFormatDateByLocale(
                    waitListItem.createdAt as string,
                    i18n?.resolvedLanguage,
                    i18n?.resolvedLanguage !== "pl"
                      ? "d MMM yyyy 'at' h:mm a"
                      : "d MMM yyyy HH:mm"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <EmptyTextStyled>{t("app.waitlsts_not_added")}</EmptyTextStyled>
      )}
    </Wrapper>
  );
}

export default TableListComponent;
