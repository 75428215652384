import React, { useMemo } from "react";
import {
  PatientEntityResponse,
  SmsEntityResponse,
} from "src/graphql/generated";
import AvatarComponent from "src/components/AvatarComponent/AvatarComponent";
import { DoctorIcon } from "src/UI/Svg";
import { getFormatDateByLocale } from "src/utils";
import {
  Content,
  DateBlock,
  IconWrapper,
  ImageStyled,
  InfoBlock,
  Message,
  Name,
  Text,
  Wrapper,
} from "./styled";

interface IMessageComponentProps {
  message: SmsEntityResponse;
  avatarUrl: string | null;
  selectedChat: PatientEntityResponse | null;
  resolvedLanguage: string;
}

function MessageComponent({
  message,
  avatarUrl,
  selectedChat,
  resolvedLanguage,
}: IMessageComponentProps) {
  const isNotMyMessage = useMemo(
    () => !message.clinicToPatient,
    [message.clinicToPatient]
  );

  return (
    <Wrapper isNotMyMessage={isNotMyMessage}>
      <Message isNotMyMessage={isNotMyMessage}>
        <Content isNotMyMessage={isNotMyMessage}>
          <InfoBlock isNotMyMessage={isNotMyMessage}>
            {isNotMyMessage ? (
              <Name>
                {selectedChat?.firstName} {selectedChat?.lastName}
              </Name>
            ) : null}
            <Text>{message.content}</Text>
          </InfoBlock>
          <DateBlock>
            {getFormatDateByLocale(
              new Date(message.createdAt),
              resolvedLanguage,
              resolvedLanguage !== "pl"
                ? "d MMM yyyy 'at' h:mm a"
                : "d MMM yyyy HH:mm"
            )}
          </DateBlock>
        </Content>
        {!isNotMyMessage ? (
          <IconWrapper>
            {avatarUrl ? (
              <ImageStyled src={avatarUrl} alt="#" />
            ) : (
              <DoctorIcon />
            )}
          </IconWrapper>
        ) : (
          <AvatarComponent
            firstName={selectedChat?.firstName as string}
            lastName={selectedChat?.lastName as string}
            size={40}
          />
        )}
      </Message>
    </Wrapper>
  );
}

export default MessageComponent;
