import { useTranslation } from "react-i18next";
import { EButtonVariants } from "src/UI/Button";
import {
  CheckBoxRHF,
  InputAutosizeRHF,
  InputGeocoderRHF,
  InputRHF,
  PhoneInputSmallRHF,
} from "src/UI/Input";
import { FieldError, useFormContext } from "react-hook-form";
import {
  useDeleteClinicLogoFileId,
  useUpdateClinicLogoFileId,
  useUploadFile,
} from "src/graphql/hooks/useMutations";
import { toast } from "react-toastify";
import { useClinicsAction } from "src/store/clinics/hooks";

import { useNavigate, useParams } from "react-router-dom";
import { PATHS } from "src/constants/constants";
import { Loading } from "src/UI/Loading";
import { AddPhotoComponent } from "src/components/MapComponents";
import { GET_COUNT_CLINICS } from "src/graphql/queries";
import { sortParams } from "src/pages/DashboardPages/MapPages/staticData";
import { useAppAction } from "src/store/app/hooks";
import { LocationGeo } from "src/pages/DashboardPages/MapPages/types";
import {
  ButtonStyled,
  Buttons,
  CardStyled,
  Content,
  ErrorPhotoStyled,
  FieldsWrapper,
  FormBlock,
  FormFooter,
  FormTitle,
  PhotoWrapStiled,
  RowInput,
  Text,
  Wrapper,
} from "./styled";

interface IProps {
  loading: boolean;
}

function AddLocationView({ loading }: IProps) {
  const { clinicId } = useParams();
  const { onSetLocation } = useClinicsAction();
  const { onConfirmOpen } = useAppAction();
  const { t } = useTranslation();
  const [uploadLogo] = useUploadFile();
  const [updateLogo] = useUpdateClinicLogoFileId();
  const [removeLogo] = useDeleteClinicLogoFileId();

  const navigate = useNavigate();

  const {
    watch,
    register,
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();

  const data = watch();

  const handleRemovePhoto = () => {
    if (data.photo) {
      onConfirmOpen({
        accept: () => {
          if (clinicId) {
            removeLogo({
              variables: {
                schema: {
                  id: clinicId,
                },
              },
              onCompleted: () => {
                toast.success(t("app.successful"));
                setValue("photo", null);
              },
              refetchQueries: [
                {
                  query: GET_COUNT_CLINICS,
                  variables: {
                    schema: {
                      ...sortParams,
                    },
                  },
                },
              ],
            });
          }
          setValue("photo", null);
        },
      });
    }
  };

  const handleChangePhoto = (photo: File | null) => {
    if (photo) {
      uploadLogo({
        variables: { schema: { file: photo } },
        onCompleted: (response) => {
          if (clinicId) {
            updateLogo({
              variables: {
                schema: {
                  id: clinicId,
                  logoFileId: response.uploadFile.id,
                },
              },
              onCompleted: () => {
                toast.success(t("app.successful"));
              },
              refetchQueries: [
                {
                  query: GET_COUNT_CLINICS,
                  variables: {
                    schema: {
                      ...sortParams,
                    },
                  },
                },
              ],
            });
          } else {
            setValue("photo", response.uploadFile.id);
            trigger("photo");
          }
        },
      });
    }
  };

  const handleSelectLocation = (val: { result: LocationGeo }) => {
    onSetLocation({
      center: [
        val?.result?.geometry?.coordinates[0],
        val?.result?.geometry?.coordinates[1],
      ],
    });
  };

  const goBack = () => {
    navigate(`${PATHS.cp}${PATHS.map}`);
  };

  return (
    <CardStyled
      title={t(
        clinicId ? "booking.widgets.tabs.institution" : "map.add_location.title"
      )}
    >
      <Content>
        <PhotoWrapStiled>
          <AddPhotoComponent
            onChange={handleChangePhoto}
            onRemove={handleRemovePhoto}
            id="location-photo"
            value={data.photo}
            buttonText={t("map.add_location.add_photo")}
            text=""
            name="photo"
          />
          {errors?.photo && (
            <ErrorPhotoStyled>
              {t(errors?.photo?.message as string)}
            </ErrorPhotoStyled>
          )}
        </PhotoWrapStiled>

        <FieldsWrapper>
          <FormBlock>
            <FormTitle>{t("map.add_location.main_data.title")}</FormTitle>
            <RowInput>
              <InputRHF
                val={data.title}
                label={t("map.add_location.main_data.labels.title") as string}
                inputType="text"
                errorMessage={errors.title as FieldError}
                register={register("title")}
              />
            </RowInput>
            <RowInput>
              <InputGeocoderRHF
                label={t("map.add_location.main_data.labels.address") as string}
                name="location"
                errorMessage={errors.location as FieldError}
                onSelect={handleSelectLocation}
              />
            </RowInput>
            <InputAutosizeRHF
              val={data.description}
              label={
                t("map.add_location.main_data.labels.description") as string
              }
              inputType="text"
              errorMessage={errors.description as FieldError}
              register={register("description")}
            />
            <InputAutosizeRHF
              val={data.licenseNumber}
              label={
                t("map.add_location.main_data.labels.licenseNumber") as string
              }
              inputType="text"
              errorMessage={errors.licenseNumber as FieldError}
              register={register("licenseNumber")}
            />
            <InputRHF
              val={data.clinicCode}
              label={
                t("map.add_location.main_data.labels.clinicCode") as string
              }
              inputType="text"
              errorMessage={errors.clinicCode as FieldError}
              register={register("clinicCode")}
            />
            <PhoneInputSmallRHF
              label={t("auth.reg.phone") || ""}
              value={data.phone}
              name="phone"
            />
          </FormBlock>

          <FormFooter>
            <Wrapper>
              <CheckBoxRHF id="check-geh" name="hiddenFromPatients" />
              <Text htmlFor="check-geh">{t("app.hidden_from_patients")}</Text>
            </Wrapper>
            <Buttons>
              <ButtonStyled variant={EButtonVariants.Text} onClick={goBack}>
                {t("map.add_location.buttons.discacrd")}
              </ButtonStyled>
              <ButtonStyled
                variant={EButtonVariants.Default}
                type="submit"
                disabled={loading}
              >
                {loading && <Loading type="secondary" padding="0" />}
                {t("map.add_location.buttons.save")}
              </ButtonStyled>
            </Buttons>
          </FormFooter>
        </FieldsWrapper>
      </Content>
    </CardStyled>
  );
}

export default AddLocationView;
