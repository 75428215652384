import styled from "styled-components";
import { ColorsKey } from "src/theme/types";
import { EPatientStatus } from "src/types";
import { Button } from "src/UI/Button";
import { FiltersTabs } from "src/UI/FiltersTabs";
import { Heading } from "src/UI/Heading";

export const Wrapper = styled.div`
  width: 100%;
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Header = styled.div`
  width: 100%;
  align-items: flex-start;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 20px;
  // ${({ theme }) => theme.mediaQueries.ll} {
  //   padding: 16px;
  // }
`;

export const HeadingStyled = styled(Heading)`
  font-size: 24px;
  line-height: 100%;
  // ${({ theme }) => theme.mediaQueries.ll} {
  //   font-size: 20px;
  // }
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-content: center;
  justify-content: space-between;
  gap: 8px;

  // ${({ theme }) => theme.mediaQueries.ll} {
  //   padding: 16px;
  //   gap: 6px;
  // }
`;

export const ButtonStyled = styled(Button)`
  padding: 8px 10px;
  font-size: 16px;
  line-height: 100%;
  width: fit-content;

  // ${({ theme }) => theme.mediaQueries.ll} {
  //   padding: 6px 8px;
  //   font-size: 12px;
  // }
`;

export const TabsStyled = styled(FiltersTabs)`
  gap: 5px;
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 20px;

  thead {
  }

  th {
    padding: 20px 5px 5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.grayC4};
    text-align: left;
  }

  td {
    padding: 30px 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.black};
    text-align: left;
  }
`;

export const LinkStyled = styled.div`
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  text-decoration-line: underline;
  color: ${({ theme }) => theme.colors.green};
`;

export const status: { [stat in EPatientStatus]: ColorsKey } = {
  [EPatientStatus.Default]: "darkGrey",
  [EPatientStatus.Error]: "red",
  [EPatientStatus.Success]: "green",
};

export const StatusBlock = styled.div<{ stat: EPatientStatus }>`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 7px;
  color: ${({ theme, stat }) => theme.colors[status[stat]]};
  span {
    display: flex;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: ${({ theme, stat }) => theme.colors[status[stat]]};
  }
`;

export const EmptyTextStyled = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.gray};
  padding: 20px;
`;

export const PatientStatusWrapper = styled.div<{ isNewStatus: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${({ isNewStatus, theme }) =>
    isNewStatus ? theme.colors.green : theme.colors.darkGrey};
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;
`;
