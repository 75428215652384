import { Button } from "src/UI/Button";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 20px;
  padding: 20px;
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 16px;
    padding: 16px;
  }
`;

export const FormWrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 20px;
  padding: 20px;
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 16px;
    padding: 16px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
  ${({ theme }) => theme.mediaQueries.ll} {
    gap: 8px;
  }
`;

export const FooterButton = styled(Button)`
  padding: 15px 20px;
  font-size: 20px;
  line-height: 100%;
  gap: 10px;
  white-space: nowrap;
  width: fit-content;
  ${({ theme }) => theme.mediaQueries.ll} {
    padding: 12px 16px;
    gap: 8px;
    font-size: 16px;
  }
`;

export const InputsRow = styled.div`
  width: 100%;
  height: 53px;
  display: flex;
  align-items: stretch;
  align-content: center;
  justify-content: flex-start;
  gap: 20px;

  & > div {
    width: 100%;
  }
  ${({ theme }) => theme.mediaQueries.ll} {
    font-size: 16px;
  }
`;
