import React from "react";

import { useTranslation } from "react-i18next";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  registerables as registerablesJS,
} from "chart.js";
import {
  AppointmentStatuses,
  useCountAndGroupScheduleAppointmentsByDateByClientQuery,
  useCountAndGroupScheduleAppointmentsByDateByDoctorQuery,
} from "src/graphql/generated";
import { useClinicsState } from "src/store/clinics/hooks";
import { useAuthContext } from "src/context/AuthContext";
import { allClinicsItemId } from "src/constants/constants";
import { toast } from "react-toastify";
import { Loading } from "src/UI/Loading";
import { parseISO } from "date-fns";
import {
  ChartStyled,
  ChartWrapper,
  Header,
  HeadingStyled,
  Wrapper,
} from "./styled";
import { options, datasetsOptions } from "./options";

ChartJS.register(...registerablesJS);
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function GraphicView() {
  const { t } = useTranslation();
  const { isDoctor, currentUserData } = useAuthContext();
  const { currentClinicId: clinicId } = useClinicsState();

  const {
    data: countsAndDatesByClientData,
    loading: countsAndDatesByClientLoading,
  } = useCountAndGroupScheduleAppointmentsByDateByClientQuery({
    variables: {
      schema: {
        status: AppointmentStatuses.Successful,
        ...(clinicId !== allClinicsItemId && { clinicId }),
      },
    },
    onError: (error) => {
      if (error) toast.error(error.message);
    },
    skip: isDoctor || !clinicId || !currentUserData,
  });

  const {
    data: countsAndDatesByDoctorData,
    loading: countsAndDatesByDoctorLoading,
  } = useCountAndGroupScheduleAppointmentsByDateByDoctorQuery({
    variables: {
      schema: {
        status: AppointmentStatuses.Successful,
        ...(clinicId !== allClinicsItemId && { clinicId }),
      },
    },
    onError: (error) => {
      if (error) toast.error(error.message);
    },
    skip: !isDoctor || !clinicId || !currentUserData,
  });

  const countsAndDatesByRoleData = isDoctor
    ? countsAndDatesByDoctorData?.countAndGroupScheduleAppointmentsByDateByDoctor
    : countsAndDatesByClientData?.countAndGroupScheduleAppointmentsByDateByClient;

  const dates = countsAndDatesByRoleData?.counts.map((count) => {
    const dateObject = parseISO(count.date);
    const day = dateObject.getDate();
    return day;
  });

  const countsData = countsAndDatesByRoleData?.counts.map(
    (count) => count.count
  );

  const loading =
    countsAndDatesByClientLoading || countsAndDatesByDoctorLoading;

  const data: ChartData = {
    labels: dates,
    datasets: [
      {
        data: countsData || [],
        ...datasetsOptions,
      },
    ],
  };

  if (loading) return <Loading padding="0" />;

  return (
    <Wrapper>
      <Header>
        <HeadingStyled as="h3">{t("statistic.graphic.title")}</HeadingStyled>
      </Header>
      <ChartWrapper>
        <ChartStyled type="line" options={options} data={data} />
      </ChartWrapper>
    </Wrapper>
  );
}

export default GraphicView;
