import { useState, useEffect } from "react";
import {
  EditorState,
  convertToRaw,
  ContentState,
  ContentBlock,
  RawDraftContentState,
} from "draft-js";
import { Editor, EditorProps } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraftjs from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTranslation } from "react-i18next";
import useEditorToolbarOptions from "src/hooks/editor/useEditorToolbarOptions";
import {
  FileUsageStatuses,
  useUploadFileMutation,
} from "src/graphql/generated";
import { toast } from "react-toastify";
import { Wrapper } from "./styles";
import Media from "./editorComponents/Media";
import { MediaProps } from "./types";

function mediaBlockRenderer(block: ContentBlock) {
  if (block.getType() === "atomic") {
    return {
      component: Media,
      editable: false,
    };
  }

  return null;
}

const getInitialContentState = (htmlString: string | undefined) => {
  const cBlock = htmlToDraftjs(htmlString as string);
  if (cBlock) return ContentState.createFromBlockArray(cBlock.contentBlocks);
  return EditorState.createEmpty();
};

const getInitialEditorState = (htmlString: string | undefined) => {
  const contentState = getInitialContentState(htmlString);
  return EditorState.createWithContent(contentState as ContentState);
};

interface ExtendedEditorProps extends EditorProps {
  blockRendererFn?: (block: ContentBlock) => {
    component: (props: MediaProps) => JSX.Element | null;
    editable: boolean;
  } | null;

  onChange: (contentState: RawDraftContentState) => void;
  value: string;
}

function DraftEditor({ value, onChange, ...rest }: ExtendedEditorProps) {
  const { i18n } = useTranslation();

  const [editorState, setEditorState] = useState(getInitialEditorState(value));

  const [uploadFile] = useUploadFileMutation();

  const uploadCallback = (file: File) => {
    return new Promise((resolve, reject) => {
      uploadFile({
        variables: {
          schema: { file, usageStatus: FileUsageStatuses.Fulfilled },
        },
        onCompleted: (response) => {
          if (response) resolve({ data: { link: response.uploadFile.url } });
        },
        onError: (error) => {
          if (error) toast.error(error.message);
          reject(error);
        },
      });
    });
  };

  const toolbarOptions = useEditorToolbarOptions(uploadCallback);

  const onEditorStateChange = (eState: EditorState) => {
    setEditorState(eState);
    // @ts-ignore
    onChange(draftToHtml(convertToRaw(eState.getCurrentContent())));
  };

  useEffect(() => {
    if (value !== draftToHtml(convertToRaw(editorState.getCurrentContent())))
      setEditorState(getInitialEditorState(value));
  }, [value]);

  return (
    <Wrapper>
      <Editor
        blockRendererFn={mediaBlockRenderer}
        editorState={editorState}
        toolbarClassName="go-draft-toolbar"
        wrapperClassName="go-draft-wrapper"
        editorClassName="go-draft-editor"
        onEditorStateChange={onEditorStateChange}
        toolbar={toolbarOptions}
        localization={{
          locale: i18n.resolvedLanguage,
        }}
        {...rest}
      />
    </Wrapper>
  );
}
export default DraftEditor;
