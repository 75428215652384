import { parseISO } from "date-fns";
import { PatientEntityResponse } from "src/graphql/generated";

export const transformPatientInfo = (patient: PatientEntityResponse) => {
  return {
    firstName: patient?.firstName,
    lastName: patient?.lastName,
    email: patient?.email,
    gender: patient?.gender || "",
    phoneNumber: patient?.phoneNumber,
    postCode: patient?.postCode || "",
    dateOfBirth: patient?.dateOfBirth
      ? parseISO(patient.dateOfBirth as string)
      : null,
    address: {
      description: patient?.address || "",
      type: "string",
    },
    passportNumber: patient?.passportNumber || "",
    comment: patient?.comment || "",
  };
};
