import { gql } from "@apollo/client";

export const CREATE_SERVICE_CATEGORY = gql`
  mutation CreateServiceCategory($schema: CreateServiceCategorySchema!) {
    createServiceCategory(schema: $schema) {
      id
      name
    }
  }
`;
