import styled from "styled-components";

export const Row = styled.div`
  display: flex;
`;

export const Icon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-right: 10px;
  background-color: ${({ theme }) => theme.colors.white};
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
`;

export const DoctorName = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ theme }) => theme.colors.black};
`;
