import { Dropdown } from "src/UI/Dropdown";
import styled from "styled-components";

export const InputBlock = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;

  & .react-datepicker-wrapper {
    display: flex;
  }
  & .react-datepicker-popper {
    z-index: 2;
  }
`;

export const InputStyled = styled.div<{ isFocused: boolean }>`
  width: 100%;
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 16px;
  min-height: 53px;
  line-height: 140%;
  color: ${({ theme, isFocused }) =>
    theme.colors[isFocused ? "black" : "gray"]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderInputDefault};
  padding: 15px 0 15px;

  &:focus {
    border-color: ${({ theme }) => theme.colors.green};
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray};
  }
`;
// TODO commeted because it media query maybe be needs in the future
// ${({ theme }) => theme.mediaQueries.ll} {
//   padding: ${({ isFocused }) => (isFocused ? "17px" : "12px")} 0 12px;
//   font-size: 12px;
//   min-height: 43px;
// }

export const Label = styled.div<{ isFocused: boolean }>`
  color: ${({ isFocused, theme }) =>
    theme.colors[isFocused ? "grayC4" : "gray"]};
  position: absolute;
  top: ${({ isFocused }) => (isFocused ? "0" : "50%")};
  transform: translateY(-50%);
  left: 0;
  font-weight: 500;
  font-size: ${({ isFocused }) => (isFocused ? "12px" : "16px")};
  line-height: ${({ isFocused }) => (isFocused ? "100%" : "140%")};
`;

export const IconStyled = styled.div`
  margin-left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
`;

export const ErrorMessage = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: right;
  color: ${({ theme }) => theme.colors.red};
  position: absolute;
  top: 0;
  right: 0;

  z-index: 2;
`;

export const DropdownStyled = styled(Dropdown)`
  min-width: 100px;

  & .dropdown {
    max-height: 200px;
  }
`;

export const HeadingWrapStyled = styled.div`
  margin: 10px;
  display: flex;
  justifycontent: center;
  gap: 5px;
`;

// TODO commeted because it media query maybe be needs in the future
//  ${({ theme }) => theme.mediaQueries.ll} {
//     font-size: 12px;
//   }
