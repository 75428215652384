import { CountedAggregatedScheduleAppointmentEntitiesWithRelationsByClientResponse } from "src/graphql/generated";
import { generateColor, getDateFromMinutesStartDay } from "src/utils";

export const transformClientResponseToEventsArray = (
  responseAppointments: CountedAggregatedScheduleAppointmentEntitiesWithRelationsByClientResponse
) => {
  if (responseAppointments) {
    const events = responseAppointments?.appointments?.map((event) => {
      const date = new Date(event?.schedule?.dateFrom);
      const timeFrom = getDateFromMinutesStartDay(event?.schedule?.minuteFrom);
      const timeTo = getDateFromMinutesStartDay(event?.schedule?.minuteTo);

      const mergedDateStart = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        (timeFrom as Date).getHours(),
        (timeFrom as Date).getMinutes(),
        (timeFrom as Date).getSeconds(),
        (timeFrom as Date).getMilliseconds()
      );
      const mergedDateTo = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        (timeTo as Date).getHours(),
        (timeTo as Date).getMinutes(),
        (timeTo as Date).getSeconds(),
        (timeTo as Date).getMilliseconds()
      );

      const uniqueColor = generateColor(event.doctor.doctorId);

      return {
        id: event?.id,
        title: `${event?.patient?.firstName} ${event?.patient?.lastName}`,
        start: mergedDateStart,
        end: mergedDateTo,
        eventData: event,
        color: uniqueColor,
      };
    });
    return events || [];
  }
  return [];
};
