import React, { ReactNode } from "react";

import { Controller, FieldError, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { NumericFormat } from "react-number-format";
import { ErrorMessage, InputBlock, InputWrapper } from "./styles";

interface InputProps {
  children?: ReactNode;
  placeholder?: string;
  name: string;
  errorMessage?: FieldError;
  disabled?: boolean;
}

const InputNumberFormatComponent = React.forwardRef(
  (
    { children, name, placeholder, errorMessage, disabled }: InputProps,
    ref
  ) => {
    const { t } = useTranslation();

    const { control } = useFormContext();

    return (
      <InputWrapper>
        <InputBlock isError={!!errorMessage}>
          {errorMessage && (
            <ErrorMessage>
              {errorMessage?.message && t(errorMessage.message)}
            </ErrorMessage>
          )}
          <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
              <NumericFormat
                getInputRef={ref}
                value={value}
                onChange={onChange}
                valueIsNumericString
                disabled={disabled}
                placeholder={placeholder}
              />
            )}
          />
        </InputBlock>
        {children}
      </InputWrapper>
    );
  }
);

export default InputNumberFormatComponent;
